/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],
    message: '',
    patientEmrData: {},
    vitalSignsData: {},
    labData: [],
    isLabDetails: false,
    labTestData:[],
    labTestList:[],
    isLabRangeChange:false,
    isLabRangeListChange:false
}

export default (state = initState, action = {}) => {

    switch (action.type) {
        case actionTypes.GET_EMR_DATA:
            return {
                ...state,
                message: 'Emr data send successfully',
            }

        case actionTypes.SET_EMR_DATA:
            return {
                ...state,
                message: 'Vital Signs Saved Successfully',
                patientEmrData: action.payload,
                vitalSignsData: {}
            }

        case actionTypes.SET_VITAL_SIGNS_DATA:
            return {
                ...state,
                message: 'Emr created Successfully',
                vitalSignsData: action.payload
            }

        case actionTypes.SET_LAB_DATA:
           // console.log(action.payload, "88888")
            return {
                ...state,
                message: 'Lab Data Saved Successfully',
                labData: action.payload,
                isLabDetails: !state.isLabDetails

            }

            case actionTypes.GET_LAB_TEST_LIST_REQUEST:
            return {
                ...state,
                isLabRangeChange:false,
                isLabRangeListChange:false,
                message: 'lab data reset successfully',
            }

            case actionTypes.SET_LAB_TEST_DATA:
                return {
                    ...state,
                    isLabRangeChange:true,
                    labTestData:action.payload,
                    message: 'Lab Test Data set successfully',
                }

                case actionTypes.SET_LAB_TEST_LIST_DATA:
                    return {
                        ...state,
                        isLabRangeListChange:true,
                        labTestList:action.payload,
                        message: 'Lab Test List set successfully',
                    }

        default:
            return state
    }
}
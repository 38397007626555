/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isPatientList: false,
  isPatientListError: false,
  PatientList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {


    //EDUCATOR List
    case actionTypes.GET_PATIENT_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isPatientList: false,
        isPatientListError: false,
      }
    case actionTypes.GET_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        message: 'Educator Listed Successfully',
        PatientList: action.payload.response,
        isPatientList: true,
        isPatientListError: false,
      }
    case actionTypes.GET_PATIENT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientList: false,
        isPatientListError: true
      }


    default:
      return state
  }
}

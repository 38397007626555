/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl, Table
} from 'react-bootstrap';

import { NavLink } from 'react-router-dom';

const HomaIRCalculator = () => {
    const [formData, setFormData] = useState({});
    const [calculate, setCalculate] = useState(0.0);
    const [required, setRequired] = useState({});

    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }
    const calculateFun = (e) => {
        e.preventDefault();
        let { glucose, insulin } = formData;
        if (!glucose || !insulin) {
            alert("All (*) fields are mandatory");
            return false;
        }

        let result = 0;
        let static_value_1 = 405;

        result = glucose * insulin / static_value_1;

        setCalculate(result.toFixed(8));
    }

    return (
        <div className="main-content color-notations">
            <Grid fluid>
                <Card
                    content={
                        <Row>

                            <Col md={4}>
                                <img src="../../../images/Homa-IR-Calculator.png" class="w-100 mx-h-40" alt="Homa IR" />
                            </Col>
                            <Col md={2}>
                                <ul className="nav calc-navs">
                                    <li>
                                        <NavLink to={'bmi-calculator'} className="nav-link " >
                                            <span className="sidebar-mini">BMI</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'eGFR-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">eGFR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'homair-calculator'} className="nav-link active" >
                                            <span className="sidebar-mini">Homa IR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link " >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [3-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link " >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [5-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'bone-age-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Bone Age Calculator</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'fib-4-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Fib 4</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <Form horizontal>
                                    <h4>Homa IR Indices Calculator</h4>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>

                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Glucose Value (mg/dl) <span className="star">*</span>
                                                    </Col>
                                                    <FormControl autocomplete="off" type="number" name="glucose" id="glucose" value={formData.glucose || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose && required.glucose === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>

                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Insulin Value (pIU/ml) <span className="star">*</span>
                                                    </Col>
                                                    <FormControl autocomplete="off" type="number" minLength="6" maxLength="20" name="insulin" id="insulin" value={formData.insulin || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin && required.insulin === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <b>Healthy Range : 1.0 (0.5-1.4)</b>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-primary">Calculate</button>
                                                </Col>
                                            </FormGroup>
                                            {calculate > 0 &&
                                                <FormGroup className="bmi-count">
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        Result
                                                    </Col>
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        {calculate}
                                                    </Col>
                                                </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    }
                />
            </Grid>
        </div>
    );
}


export default withRouter(HomaIRCalculator);

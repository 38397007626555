/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import $ from "jquery";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { addSpecializationAction } from "Admin/actions/specialization";
require("datatables.net-responsive");
$.DataTable = require("datatables.net-bs");

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  Name: "required",
};
let mess = {
  required: "This field is required",
  alpha: "This field may only contain letters",
  numeric: "This field must be a number",
  email: "This field must be a valid email address.",
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddSpecilization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      showModal: false,
      isButtonDisabled: false,
      isLoading: false,
      formArr: [],
      formData: {
        Name: "",
        description: "",
        status: "active",
      },
      isLogin: true,
    };
  }

  componentDidMount() {
    //this.props.addSpecializationAction(this.state.formData);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.AddSpecilization && nextProps.AddSpecilization.status) {
      this.props.handleClick("success", nextProps.AddSpecilization.status);
      this.props.history.push("/specialization-list");
    }
  }

  AddAction(evt) {
    this.setState({ isButtonDisabled: true });
    this.setState({ isLoading: true });
    evt.preventDefault();
    if (this.allValidate(false)) {
      this.props.addSpecializationAction(this.state.formData);
    }
    setTimeout(() => {
      // Once the action is complete, enable the button again
      this.setState({ isButtonDisabled: false });
      this.setState({ isLoading: false });
    }, 100);
    this.props.onClickAction()
      .then(() => {
        // After the action is completed, reset the loading state to false
        this.setState({ isLoading: false });
      })
      .catch(() => {
        // Handle any errors and reset loading state
        this.setState({ isLoading: false });
      });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let formData = this.state.formData;
    formData[e.target.name] = e.target.value;

    this.setState({ formData: formData });
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    const { isButtonDisabled } = this.state;
    const { isLoading } = this.state;

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <legend className="line-removes">
                    <Button
                      className="go-back-btn-1"
                      bsStyle="info"
                      onClick={(e) => {
                        this.goBackFun(e);
                      }}
                    >
                      Back
                    </Button>
                  </legend>
                }
                content={
                  <Form horizontal className="add-specialization_form">
                    <fieldset>
                      <FormGroup>
                        <Col sm={12}>
                          <Row>
                            <Col md={6}>
                              <ControlLabel>
                                Name <span className="star">*</span>
                              </ControlLabel>
                              <FormControl
                                type="text"
                                name="Name"
                                id="Name"
                                placeholder="Name"
                                autoComplete="off"
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                              />
                              <span className="errorMsg">
                                {this.state.formArr.Name &&
                                  validation.errors.first("Name")}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <Col sm={12}>
                          <Row>
                            <Col md={6}>
                              <ControlLabel>Description</ControlLabel>
                              <FormControl
                                type="text"
                                name="description"
                                id="description"
                                placeholder="Description"
                                autoComplete="off"
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <Col sm={12}>
                          <Row>
                            <Col md={6}>
                              <ControlLabel>Status</ControlLabel>
                              <select
                                className="form-control"
                                name="status"
                                value={this.state.formData.singleSelect}
                                onChange={(e) => {
                                  this.handleChange(e);
                                }}
                              >
                                <option value="active">Enable</option>
                                <option value="inactive">Disable</option>
                              </select>
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                    </fieldset>
                    <fieldset>
                      <FormGroup>
                        <ControlLabel className="col-sm-2"></ControlLabel>
                        <Col sm={12} className="btn_wrap">
                          <Row>
                            <Col md={12}>
                              <button
                                type="button"
                                onClick={this.AddAction.bind(this)}
                                disabled={isLoading || this.props.disabled}
                                className="btn-fill btn-wd btn btn-info"
                              >
                                {isLoading ? (
                                  <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                ) : (
                                  this.props.children
                                )}
                                Submit
                              </button>
                              &nbsp;
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                    </fieldset>
                  </Form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    msg: state.specilization.message,
    AddSpecilization: state.specilization.isAddSpeciliData,
    isAddSpecilization: state.specilization.isAddSpecilizatio,
    isAddSpecilizationError: state.specilization.isAddSpecilizationError,
  };
}
export default withRouter(
  connect(mapStateToProps, { addSpecializationAction })(AddSpecilization)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import { changePasswordAction } from 'Front/actions/settings';
import SweetAlert from 'react-bootstrap-sweetalert';
import history from '../../../routes/history';
import { appConstants } from 'Front/_constants/app.constants.js';
class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passFormData: {
                new_password: '',
                old_password: '',
                confirm_password: ''
            },
            passValidation: {
                new_password: false,
                old_password: false,
                confirm_password: false,
                confirm_password_match: false,
                character_limit: false,
            },
            alert: null,
        };
        this.hideAlert = this.hideAlert.bind(this);

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isChangePassword !== this.props.isChangePassword) {
            this.successAlert();
        }
        if (nextProps.isChangePasswordError !== this.props.isChangePasswordError) {
            this.errorAlert();
        }
    }


    checkValidation() {

        let field1 = this.state.passValidation;
        let valid = 1;

        if (this.state.passFormData.new_password === '') {
            field1['new_password'] = true;
            valid = 0;
        } else {
            field1['new_password'] = false;
        }

        if (this.state.passFormData.old_password === '') {
            field1['old_password'] = true;
            valid = 0;
        } else {
            field1['old_password'] = false;
        }

        if (this.state.passFormData.confirm_password === '') {
            field1['confirm_password'] = true;
            valid = 0;
        } else {
            field1['confirm_password'] = false;
        }

        if ((this.state.passFormData.new_password !== this.state.passFormData.confirm_password) && this.state.passFormData.confirm_password !== '') {
            valid = 0;
            field1['confirm_password_match'] = true;
        } else {
            field1['confirm_password_match'] = false;
        }

        if ((this.state.passFormData.new_password.length < 6) && this.state.passFormData.new_password !== '') {
            valid = 0;
            field1['character_limit'] = true;
        } else if ((this.state.passFormData.new_password.length > 12) && this.state.passFormData.new_password !== '') {
            valid = 0;
            field1['character_limit'] = true;
        } else if (this.state.passFormData.new_password !== '' && !this.state.passFormData.new_password.match(appConstants.PswValidCharecters)) {
            valid = 0;
            field1['character_limit'] = true;
        } else {
            field1['character_limit'] = false;
        }

        this.setState({ passValidation: field1 });

        return valid;

    }

    handleChange(e) {
        e.preventDefault();

        let field = this.state.formData;

        field[e.target.name] = e.target.value;


        this.setState({ formData: field });

    };


    updatePassword(evt) {
        evt.preventDefault();

        let valid = this.checkValidation();

        if (valid === 1) {
            this.props.changePasswordAction(this.state.passFormData);
        }
    }


    handlePassChange(e) {
        e.preventDefault();
        let field = this.state.passFormData;
        let field2 = this.state.passValidation;
        field[e.target.name] = e.target.value;
        field2[e.target.name] = false;

        this.setState({
            passFormData: field,
            passValidation: field2
        });

        this.checkValidation();

    };


    successAlert() {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Success"
                    //onConfirm={() => this.hideAlert()}
                    //onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Password changed successfully.
                </SweetAlert>
            ),
            passFormData: {
                new_password: '',
                old_password: '',
                confirm_password: ''
            }

        });

        let _this = this;
        setTimeout(function () {
            _this.hideAlert();
        }, 3000);

    }

    errorAlert() {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error"
                    onConfirm={() => this.hideAlert2()}
                    onCancel={() => this.hideAlert2()}
                    confirmBtnBsStyle="info"
                >
                    Wrong old Password or something went wrong :)
                </SweetAlert>
            )
        });

        let _this = this;
        setTimeout(function () {
            _this.hideAlert();
        }, 3000);
    }


    Logout() {
        localStorage.removeItem('_id')
        localStorage.removeItem('userName')
        localStorage.removeItem('userType')
        localStorage.removeItem('email')
        localStorage.removeItem('status')
        localStorage.removeItem('token')
        localStorage.removeItem('degree')
        localStorage.removeItem('firstName')
        localStorage.removeItem('lastName')
        history.push({ pathname: '/' });
    }

    hideAlert() {
        this.setState({
            alert: null,
            // passFormData: {
            //     new_password: '',
            //     old_password: '',
            //     confirm_password: ''
            // }
        });

    }

    hideAlert2() {
        this.setState({
            alert: null,
        });
    }

    render() {

        return (
            <div className="change-password">
                {this.state.alert}
                <div className="row">
                    <div className="col-md-6 col-sm-12">

                        <Form horizontal>
                            {/* <h4>Change Password</h4> */}

                            <FormGroup>
                                <Col sm={12}>
                                    <Col componentClass={ControlLabel}>
                                        Old Password <span className="star">*</span>
                                    </Col>
                                    <FormControl type="password" name="old_password" id="old_password" value={this.state.passFormData.old_password} onChange={e => { this.handlePassChange(e); }} />
                                    <span className="errorMsg" style={{ display: this.state.passValidation.old_password ? 'block' : 'none' }}>This is required field</span>
                                </Col>

                            </FormGroup>

                            <FormGroup>
                                <Col sm={12}>
                                    <Col componentClass={ControlLabel}>
                                        New Password <span className="star">*</span>
                                    </Col>
                                    <FormControl type="password" minLength="6" maxLength="20" name="new_password" id="new_password" value={this.state.passFormData.new_password} onChange={e => { this.handlePassChange(e); }} />
                                    <span className="errorMsg" style={{ display: this.state.passValidation.new_password ? 'block' : 'none' }}>This is required field</span>
                                    <span className="errorMsg" style={{ display: this.state.passValidation.character_limit ? 'block' : 'none' }}>Wrong password format!</span>
                                    <small>[6 to 12 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character]</small>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col sm={12}>
                                    <Col componentClass={ControlLabel}>
                                        Confirm New Password <span className="star">*</span>
                                    </Col>
                                    <FormControl type="password" minLength="6" maxLength="20" name="confirm_password" id="confirm_password" value={this.state.passFormData.confirm_password} onChange={e => { this.handlePassChange(e); }} />
                                    <span className="errorMsg" style={{ display: this.state.passValidation.confirm_password ? 'block' : 'none' }}>This is required field</span>
                                    <span className="errorMsg" style={{ display: this.state.passValidation.confirm_password_match ? 'block' : 'none' }}>Password not matching</span>
                                    <small>[6 to 12 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character]</small>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col sm={12}>
                                    <button type="submit" onClick={this.updatePassword.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

        isChangePassword: state.settings.isChangePassword,
        isChangePasswordError: state.settings.isChangePasswordError,
        ChangePassword: state.settings.ChangePassword,

    }
}
export default withRouter(connect(mapStateToProps, { changePasswordAction })(ChangePassword));

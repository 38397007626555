/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import {
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import * as API from "Front/api/emr";
import { Item } from "react-bootstrap/lib/Breadcrumb";
import Complaints from "./Complaints";
import { ACTIONS } from "./Complaints";
import InlargeIc from "../../../../assets/img/icons/inlarge-ic.svg";

const ComplaintsEnlarge = ({
  setModal,
  ModalStatus,
  ComplaintsEnlarge,
  patientListCount,
  myListCount,
  selectedCategoryId,
  complaintPatientId,
  complaintAppointmentId,
  flagComplaint,
  setComplaintsEnlarge,
  complaints,
  data,
  dispatch,
}) => {
  const [symptomsCategoryList, setSymptomsCategoryList] = useState([]);
  const [symptomsSubCategoryList, setSymptomsSubCategoryList] = useState([]);
  const [flag, setFlag] = useState("");
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [isComplaintEnlarge, setIsComplaintEnlarge] = useState(false);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState(data);
  const [complaintCount, setComplaintCount] = useState(false);
  const [selectedCategoryIdEnlarge, setSelectedCategoryIdEnlarge] = useState();

  let subCategoryCheked = [];
  let patientId = complaintPatientId;
  let appointmentId = complaintAppointmentId;
  let symptomSubcategoryId = "";
  let flagList = "";
  // **********************Function for accessing symptoms categories****************************//

  const getSymptomsCategory = async () => {
    let resp = await API.symptomsCategory();
    if (
      resp.data &&
      resp.data.status === "Success" &&
      resp.data.masterSymptomsCategoryList.data.length > 0
    ) {
      setSymptomsCategoryList(resp.data.masterSymptomsCategoryList.data);
      symptomSubcategoryId = selectedCategoryId;
      activateTab("");
      let type = "subcategory";
      if (selectedCategoryId === patientId && patientListCount === true) {
        type = "patient";
      } else if (selectedCategoryId === clinicId && myListCount === true) {
        type = "clinic";
      }
      getSymptomsSubCategory("", type);
    }
  };

  //*****************************************************************************************//

  // **********************Function for accessing subcategory based on selected category****************************//

  const getSymptomsSubCategory = async (e, type) => {
    clearSubCategoryList();
    let catId = selectedCategoryId;
    if (e) {
      catId = e.target.id;
    }

    let req = "";
    if (type === "master") {
      req = {
        patientId: patientId,
        clinicId: clinicId,
        symptomsCategoryId: symptomSubcategoryId,
      };
    } else if (type === "patient") {
      activateTab(e);
      req = {
        patientId: patientId,
        clinicId: "",
        symptomsCategoryId: "",
      };
    } else if (type === "clinic") {
      activateTab(e);
      req = {
        patientId: "",
        clinicId: clinicId,
        symptomsCategoryId: "",
      };
    } else if (type === "subcategory") {
      activateTab(e);

      req = {
        patientId: "",
        clinicId: "",
        symptomsCategoryId: catId,
      };
    }
    let resp = await API.symptomsSubCategory(req);
    if (resp.data && resp.data.status === "Success") {
      subCategoryCheked = [];
      flagList = resp.data.flag;
      if (resp.data.flag === "patientSymptomsList") {
        setSymptomsSubCategoryList(resp.data.patientSymptomsList);
        setFlag("patientSymptomsList");
        subCategoryCheked = resp.data.patientSymptomsList;
      } else if (resp.data.flag === "clinicSymptomsList") {
        setSymptomsSubCategoryList(resp.data.clinicSymptomsList);
        setFlag("clinicSymptomsList");
        subCategoryCheked = resp.data.clinicSymptomsList;
      } else if (resp.data.flag === "masterSymptomsCategoryList") {
        setSymptomsSubCategoryList(
          resp.data.masterSymptomsSubCategoryList.data
        );
        setFlag("masterSymptomsCategoryList");
        subCategoryCheked = resp.data.masterSymptomsSubCategoryList.data;
      }
      findSubCategory();
    }
  };

  //*****************************************************************************************//

  //*************************************Function for activating seleted tab on menu bar****************************************************//

  const activateTab = (e) => {
    let listCatgeory = document.getElementsByName("category");
    let ele = "";
    if (!e) {
      if (selectedCategoryId === patientId && patientListCount === true) {
        ele = document.getElementById("cat" + patientId);

        if (ele !== null) ele.classList.add("current");
      } else if (selectedCategoryId === clinicId && myListCount === true) {
        ele = document.getElementById("cat" + clinicId);
        if (ele !== null) ele.classList.add("current");
      } else {
        ele = document.getElementById("cat" + selectedCategoryId);
        if (ele !== null) ele.classList.add("current");
      }
    } else {
      setSelectedCategoryIdEnlarge(e.target.id);

      let subcatId = "cat" + e.target.getAttribute("id");
      for (var subCategory of listCatgeory) {
        if (subcatId === subCategory.getAttribute("id")) {
          subCategory.classList.add("current");
        } else {
          subCategory.classList.remove("current");
        }
      }
    }
  };

  //*****************************************************************************************//

  // *********************************************Functions for add or delete complaint ************************************************//

  const addOrDeleteComplaints = (e) => {
    if (e.target.checked) {
      let symptomType = "master";
      let flagComplaintExist = false;
      if (flag === "clinicSymptomsList") {
        symptomType = "clinic";
      } else if (flag === "patientSymptomsList") {
        symptomType = "patient";
      }

      formData.map((itm) => {
        if (itm.symptomsSubCategoryId === e.target.getAttribute("subcatid")) {
          itm.isDeleted = false;
          flagComplaintExist = true;
        }
      });
      rows.push({
        symptomsSubCategoryId: e.target.getAttribute("subcatid"),
        symptomType: symptomType,
        complaint: e.target.value,
        duration: { no: "", period: "" },
        patientId: patientId,
        appointmentId: appointmentId,
        isDeleted: false,
      });

      if (!flagComplaintExist) {
        formData.push({
          patientId: patientId,
          duration: { no: "", period: "" },
          symptomsType: symptomType,
          complaint: e.target.value,
          symptomsSubCategoryId: e.target.getAttribute("subcatid"),
          appointmentId: appointmentId,
          isDeleted: false,
        });
      }

      setRows([...rows]);
      setFormData([...formData]);
    } else {
      deleteComplaint(e);
    }
    setComplaintCount(false);
  };

  const deleteComplaint = (e) => {
    let subCatId = e.target.getAttribute("subcatid");

    let chkbox_subact = document.getElementsByName("subcat");
    for (var chk of chkbox_subact) {
      if (chk.getAttribute("subcatid") === subCatId) {
        chk.checked = false;
      }
    }

    rows.map((itm, idx) => {
      if (itm.symptomsSubCategoryId === subCatId) {
        rows.splice(idx, 1);
        return;
      }
    });

    formData.map((itm, idx) => {
      if (itm.symptomsSubCategoryId === subCatId) {
        itm.isDeleted = true;
      }
    });

    setRows([...rows]);
    setFormData([...formData]);
  };

  //*****************************************************************************************//

  // **********************Functions for selecting checkboxes or radionbuttons ****************************//

  const findSubCategory = () => {
    if (rows.length > 0) {
      rows.map((row) => {
        if (subCategoryCheked.length > 0) {
          subCategoryCheked.map((subcat) => {
            if (flagList === "patientSymptomsList") {
              if (subcat.symptomsSubCategoryId === row.symptomsSubCategoryId) {
                checkSubCategory(subcat.symptomsSubCategoryId);
              }
            } else {
              if (subcat._id === row.symptomsSubCategoryId) {
                checkSubCategory(subcat._id);
              }
            }
          });
        }
      });
      setSymptomsSubCategoryList(subCategoryCheked);
    }
  };

  const checkSubCategory = (subcatId) => {
    let chkSubCategory = document.getElementsByName("subcat");
    for (var subCategory of chkSubCategory) {
      if (subcatId === subCategory.getAttribute("subcatid")) {
        subCategory.checked = true;
      }
    }
  };

  //*****************************************************************************************//

  // **********************Function for hiding Modal PopUp****************************//

  const hideModalOnSave = () => {
    setRows([]);
    setSymptomsCategoryList([]);
    clearSubCategoryList();
    setIsComplaintEnlarge(false);
    setModal(false);
  };

  const hideModal = () => {
    setRows([]);
    setSymptomsCategoryList([]);
    clearSubCategoryList();
    setIsComplaintEnlarge(false);
    setModal(false);

    dispatch({
      type: ACTIONS.CLOSE_ENLARGE,
      payload: { selectedCategoryId: selectedCategoryId },
    });
  };

  //*****************************************************************************************//

  // **********************Function for adding complaints to the table in the complaint page****************************//

  const submitHandler = (e) => {
    e.preventDefault();
    if (rows.length > 0) {
      let payloadData = {
        complaints: rows,
        data: formData,
        selectedCategoryId: selectedCategoryIdEnlarge,
      };
      dispatch({
        type: ACTIONS.ADD_ENLARGE_COMPLAINT,
        payload: { ...payloadData },
      });
      hideModalOnSave();
    } else {
      setComplaintCount(true);
    }
  };
  //*****************************************************************************************//

  const clearSubCategoryList = () => {
    setSymptomsSubCategoryList([]);
  };

  const showComplaintFun = () => {
    setRows([]);
    setComplaintsEnlarge(true);
    setModal(true);
    setIsComplaintEnlarge(true);
    setRows([...complaints]);
    setFormData([...data]);
  };

  useEffect(() => {
    setSelectedCategoryIdEnlarge(selectedCategoryId);
    if (isComplaintEnlarge) {
      getSymptomsCategory();
    }
  }, [isComplaintEnlarge]);

  return (
    <span>
      <a href="#" className="btn btn-link" onClick={(e) => showComplaintFun()}>
        View All Complaints
      </a>
      {/* <a href='#'> <img src={InlargeIc} alt='Inlarge' onClick={e => showComplaintFun()} /> </a> */}
      <Modal
        className="suggestion-box-modal"
        show={ModalStatus}
        onHide={hideModal}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            Complaints
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="suggetion-box-enlarge">
          <div className="system-lists">
            <h5>Systems</h5>
            <ul>
              <li name="category" id={"cat" + patientId}>
                <a
                  id={patientId}
                  onClick={(e) => {
                    getSymptomsSubCategory(e, "patient");
                  }}
                >
                  Patient List
                </a>
              </li>
              <li name="category" id={"cat" + clinicId}>
                <a
                  id={clinicId}
                  onClick={(e) => {
                    getSymptomsSubCategory(e, "clinic");
                  }}
                >
                  My List
                </a>
              </li>
              {symptomsCategoryList &&
                symptomsCategoryList.map((item) => {
                  return (
                    <>
                      <li name="category" id={"cat" + item._id}>
                        <a
                          id={item._id}
                          onClick={(e) => {
                            getSymptomsSubCategory(e, "subcategory");
                          }}
                        >
                          {item.name}
                        </a>
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
          <div className="suggestion-lists">
            <div className="selected-complaints">
              <div className="selected-complaint-left">
                <h5>Complaints Selected</h5>
                {complaintCount && (
                  <div>
                    <p style={{ color: "red" }}>Please select any complaint</p>
                  </div>
                )}
                <ul>
                  {rows &&
                    rows.map((row) => {
                      return (
                        <li>
                          {row.complaint}

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              deleteComplaint(e);
                            }}
                            subcatid={row.symptomsSubCategoryId}
                          >
                            ✖
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="text-right">
                <Button
                  className="btn btn-primary btn-fill"
                  onClick={(e) => submitHandler(e)}
                >
                  Add
                </Button>
              </div>
            </div>
            <div className="suggestion-list">
              {symptomsSubCategoryList &&
                symptomsSubCategoryList.map((item) => {
                  return (
                    <div class="form-check form-check-emr">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={
                          flag === "masterSymptomsCategoryList"
                            ? item.name
                            : item.symptomsSubCategoryName
                        }
                        onChange={(e) => {
                          addOrDeleteComplaints(e);
                        }}
                        subcatid={
                          flag === "patientSymptomsList"
                            ? item.symptomsSubCategoryId
                            : item._id
                        }
                        name="subcat"
                        id={`subcat${flag === "patientSymptomsList"
                            ? item.symptomsSubCategoryId
                            : item._id
                          }`}
                      />
                      <label
                        class="form-check-label"
                        for={`subcat${flag === "patientSymptomsList"
                            ? item.symptomsSubCategoryId
                            : item._id
                          }`}
                      >
                        {flag === "masterSymptomsCategoryList"
                          ? item.name
                          : item.symptomsSubCategoryName}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </span>
  );
};

export default ComplaintsEnlarge;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Col,
  Row,
  Modal,
} from "react-bootstrap";
import history from "../../routes/history";
import Chat from "./Chat";
import PatientRegistration from "Front/views/Home/PatientRegistrationPopup.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { LinkContainer } from "react-router-bootstrap";
import { getChatCountAction, resetIsReduceChatCoutnAction } from "Front/actions/master";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { appConstants } from "Front/_constants/app.constants.js";
//import reloadicon from 'Front/assets/img/reloadicon.png';
import homeicons from "Front/assets/img/homeicons.png";
import searchpt from "Front/assets/img/searchpt.png";
import formresgister from "Front/assets/img/formresgister.png";
import appointment from "Front/assets/img/appointment.png";
import ReportsIC from "Front/assets/img/reports-icon.png";
import chaticon from "Front/assets/img/chaticon.png";
import settingicon from "Front/assets/img/settingicon.png";
//import notificationicon from 'Front/assets/img/notificationicon.png';
import Calculatoricon from "Front/assets/img/calculatoricon.png";
import logouticon from "Front/assets/img/logouticon.png";
//import ReportIcon from 'Front/assets/img/ReportIcon.png';
import insulinCal from "Front/assets/img/insulin-img.png";
import performenceReportIcon from "Front/assets/img/PerformaceReport.png";

import LibraryIc from "Front/assets/img/library-icon.png";

//import libraryIcon from 'Front/assets/img/library.png';
import ReportsPatientALL from "Front/views/TaskScreen/ReportsPatientALL";
import $ from "jquery";
import EventEmitter from "Front/actions/events.js";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { resetVisitListAction, visitListAction } from "../../actions/home";


class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatModal: false,
      reportsModel: false,
      report_count: localStorage.getItem("report_count")
        ? localStorage.getItem("report_count")
        : 0,
      libraryCount: localStorage.getItem("libraryCount")
        ? localStorage.getItem("libraryCount")
        : 0,
      settingsMenuOpen: false,
      logoutMenuOpen: false,
      _notificationSystem: null,
      showInternetSpeed: false
    };

    this.onDismiss = this.onDismiss.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.openChatModel = this.openChatModel.bind(this);
    this.hideChatModel = this.hideChatModel.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.openPatientModal = this.openPatientModal.bind(this);

    this.openReportsModel = this.openReportsModel.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.reloadHome = this.reloadHome.bind(this);

    let _this = this;

    appConstants.socket.on("report_count", function (report_count) {
      localStorage.setItem("report_count", report_count);
      _this.setState({ report_count });
    });

    appConstants.socket.on("newDocument", function (data) {
      let specializationIdsArr = JSON.parse(
        localStorage.getItem("specializationIds")
      );

      let intersection = specializationIdsArr.filter((element) =>
        data.specializationIds.includes(element)
      );

      if (intersection.length > 0) {
        let lc =
          parseInt(
            localStorage.getItem("libraryCount")
              ? localStorage.getItem("libraryCount")
              : 0
          ) + 1;
        localStorage.setItem("libraryCount", lc);
        _this.setState({ libraryCount: lc });
      }
    });

    appConstants.socket.on("countUpdate", function (data) {
      if (data.report_count) {
        localStorage.setItem("report_count", data.report_count);
        _this.setState({ report_count: data.report_count });
      }

      if (data.libraryCount || data.libraryCount == 0) {
        localStorage.setItem("libraryCount", data.libraryCount);

        _this.setState({ libraryCount: data.libraryCount });
      }
    });
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.getChatCountAction();

    let _this = this;

    setInterval(() => {
      _this.setState({ showInternetSpeed: true })
    }, 60 * 1000)

    setInterval(() => {
      _this.setState({ showInternetSpeed: false })
    }, 65 * 1000)
    appConstants.socket.on("logout", function (data) {
      setTimeout(function () {
        _this.Logout();
      }, 1000);
    });
    this.handleMenu();
  }

  handleMenu() {
    var obj = this;
    $("body").click(function (e) {
      if (
        !$(e.target).hasClass("logout-dropdown") &&
        $(e.target).parents(".logout-dropdown").length == 0 &&
        !$(e.target).hasClass("setting-menu") &&
        $(e.target).parents(".setting-menu").length == 0
      ) {
        obj.setState({ settingsMenuOpen: false });
        obj.setState({ logoutMenuOpen: false });
      }
    });

    $("body").delegate(".dropdown li", "click", function () {
      obj.setState({ settingsMenuOpen: false });
      obj.setState({ logoutMenuOpen: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps, "nextProps")

    if (nextProps.isReduceChatCount !== this.props.isReduceChatCount) {
      localStorage.setItem('AllCount', nextProps.allChatCount)
      //console.log(nextProps, "nextProps")
    }

    if (
      nextProps.isGetChatCount !== this.props.isGetChatCount &&
      this.props.isGetChatCount === false
    ) {
      let chatCounts = nextProps.GetChatCount.chatCounts;
      let totalCount = 0;
      let AllChatCount = 0;
      let forwardedChatCount = 0;
      let byEducatorChatCount = 0;
      for (let i in chatCounts) {
        if (chatCounts[i]._id === "chat") {
          // if (nextProps.isReduceChatCount) {
          //   totalCount = localStorage.getItem("AllCount") - chatCounts[i].count;
          // } else totalCount = totalCount + chatCounts[i].count;
          totalCount = totalCount + chatCounts[i].count;
          AllChatCount = chatCounts[i].count;
        } else if (chatCounts[i]._id === "forwarded") {
          totalCount = totalCount + chatCounts[i].count;
          forwardedChatCount = chatCounts[i].count;
        }
        //  else if (chatCounts[i]._id === "byEducator") {
        //   totalCount = totalCount + chatCounts[i].count;
        //   byEducatorChatCount = chatCounts[i].count;
        // }
      }
      localStorage.setItem("AllChatCount", AllChatCount);
      localStorage.setItem("forwardedChatCount", forwardedChatCount);
      localStorage.setItem("byEducatorChatCount", byEducatorChatCount);
      //console.log("inside header Links", totalCount)
      localStorage.setItem("AllCount", totalCount);
      // if (nextProps.isReduceChatCount)
      //   this.props.resetIsReduceChatCoutnAction()
    }
  }

  Logout() {
    localStorage.removeItem("_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("email");
    localStorage.removeItem("status");
    localStorage.removeItem("token");
    localStorage.removeItem("degree");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.clear();
    history.push({ pathname: "/login" });
  }

  navigateTo(page) {
    history.push({ pathname: `/${page}` });
    this.mobileSidebarToggle();
  }

  refreshPage(e) {
    e.preventDefault();
    window.location.reload(true);
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.successAlert("Patient successfully registered");
  }

  successAlert(msg) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       //onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 3000);

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  openReportsModel() {
    this.setState({ reportsModel: true });
    this.props.reportsModel(true);
    this.mobileSidebarToggle();
  }

  openChatModel() {
    this.setState({ chatModal: true });
    this.props.chatModel(true);
    this.mobileSidebarToggle();
  }

  hideChatModel() {
    this.props.getChatCountAction();
    this.setState({ chatModal: false });
    this.props.chatModel(false);
  }

  mobileSidebarToggle() {
    document.documentElement.classList.toggle("nav-open");
  }

  openPatientModal() {
    this.setState({ patientModal: true });
  }

  openReportsModel() {
    this.setState({ reportsModel: false });
  }

  reloadHome() {
    let today = new Date()
    let search_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.props.visitListAction(search_date, "", "", "reloadHome");
    //EventEmitter.dispatch("reload_home", {});
  }

  render() {
    const reportModal = (
      <ReportsPatientALL
        visitInfo={this.state.taskScreenData}
        hideButton="true"
        modelStatus={this.state.reportsModel}
        funModelStatus={this.openReportsModel}
      />
    );

    return (
      <div className="front-nav">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        {localStorage.getItem("is_report_folder_facility") &&
          localStorage.getItem("is_report_folder_facility") === "yes" &&
          reportModal}
        <Nav pullRight className="ico-width">
          {/* <NavItem eventKey={3} onClick={this.refreshPage.bind(this)}>
                         <img src={reloadicon} alt="img" />
                          <br/>
                        <span>Refresh</span>
                    </NavItem> */}
          <LinkContainer
            to="/dashboard"
            onClick={(e) => {
              this.reloadHome();
            }}
          >
            <NavItem
              eventKey={4}
              onClick={(e) => {
                this.mobileSidebarToggle();
              }}
            >
              <img src={homeicons} alt="img" />
              <br />
              <span>Home</span>
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/patient-search">
            <NavItem
              eventKey={5}
              onClick={(e) => {
                // this.props.resetVisitListAction();
                this.mobileSidebarToggle();
              }}
            >
              <img src={searchpt} alt="img" />
              <br />
              {/*<span>Pt Search</span>*/}
              <span>Search</span>
            </NavItem>
          </LinkContainer>

          {localStorage.getItem("userType") !== "receptionist" && (
            <NavItem eventKey={6} onClick={() => this.openPatientModal()}>
              <img src={formresgister} alt="img" />
              <br />
              <span>Register</span>
            </NavItem>
          )}
          <LinkContainer to="/appointment">
            <NavItem
              eventKey={4}
              onClick={(e) => {
                // this.props.resetVisitListAction();
                this.mobileSidebarToggle();
              }}
            >
              <img src={appointment} alt="img" />
              <br />
              <span>Appointments</span>
            </NavItem>
          </LinkContainer>
          {localStorage.getItem("userType") === "doctor" && (
            <NavItem
              style={{ minWidth: "85px" }}
              eventKey={6}
              onClick={() => {
                history.push("/clinic-performance-report")
                // this.props.resetVisitListAction();
              }}
            >
              <img src={performenceReportIcon} alt="img" />
              <br />
              <span>Clinic Performance</span>
            </NavItem>
          )}

          {localStorage.getItem("userType") !== "receptionist" &&
            localStorage.getItem("is_report_folder_facility") === "yes" && (
              <NavItem
                style={{ minWidth: "85px" }}
                eventKey={6}
                onClick={() => this.setState({ reportsModel: true })}
              >
                <img src={ReportsIC} alt="img" />
                <br />
                <span>Shared Reports</span>
                <span className="chatCount">
                  {this.state.report_count || 0}
                </span>
              </NavItem>
            )}
          {localStorage.getItem("insulinCalculator") &&
            localStorage.getItem("insulinCalculator") === "yes" &&
            localStorage.getItem("userType") === "doctor" && (
              <LinkContainer to="/insulin-patient">
                <NavItem
                  eventKey={5}
                  onClick={(e) => {
                    // this.props.resetVisitListAction();
                    this.mobileSidebarToggle();
                  }}
                >
                  <img src={insulinCal} alt="img" style={{ height: 42 }} />
                  <br />
                  <span>Insulin</span>
                </NavItem>
              </LinkContainer>
            )}
          {localStorage.getItem("userType") !== "receptionist" && (
            <LinkContainer to="/clinic-document">
              <NavItem eventKey={6}
              // onClick={(e) => {
              //   this.props.resetVisitListAction();
              //  }} 
              >
                <img src={LibraryIc} alt="img" />
                <br />
                <span>Library</span>
                <span className="chatCount">
                  {this.state.libraryCount || 0}
                </span>
              </NavItem>
            </LinkContainer>
          )}

          <LinkContainer to="/bmi-calculator">
            <NavItem
              eventKey={3}
              onClick={(e) => {
                this.navigateTo.bind(this, "bmi-calculator");
                //this.props.resetVisitListAction();
              }}
            >
              <img src={Calculatoricon} alt="img" />
              <br />
              <span>Calculator</span>
            </NavItem>
          </LinkContainer>
          {localStorage.getItem("userType") === "doctor" && (
            <LinkContainer to="/settings">
              <NavItem
                eventKey={4}
                onClick={(e) => {
                  this.navigateTo.bind(this, "settings");
                  // this.props.resetVisitListAction();
                }}
              >
                <img src={settingicon} alt="img" />
                <br />
                Settings
              </NavItem>
            </LinkContainer>
          )}
          {/* <NavDropdown
                        eventKey={2}
                        title={(
                            <div className='setting-menu'>
                                <img src={settingicon} alt="img" /><br />
                                Settings
                                <b className="hidden-md hidden-lg caret"></b>

                            </div>
                        )} noCaret id="basic-nav-dropdown-1" className={`setting-menu ${this.state.settingsMenuOpen ? "open" : ""}`} onClick={() => this.setState({ 'settingsMenuOpen': !this.state.settingsMenuOpen })}>

                       
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'change-password')} eventKey={2.2}>Doctor Password</MenuItem>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'manage-request')} eventKey={2.3}>Document Upload</MenuItem>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'educator-message')} eventKey={2.4}>Educator Message Master</MenuItem>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'notification')} eventKey={2.5}>Notification Master</MenuItem>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'user-list')} eventKey={2.6}>Users Master</MenuItem>
                        }

                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem onClick={this.navigateTo.bind(this, 'preference-to-set')} eventKey={2.7}>App Related Settings</MenuItem>
                        }
                        {localStorage.getItem('userType') !== 'receptionist' &&
                            <MenuItem eventKey={2.8} onClick={this.navigateTo.bind(this, 'color-notations')}>
                                <span>Colour Notations </span>
                            </MenuItem>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <MenuItem eventKey={2.9} onClick={this.navigateTo.bind(this, 'report')} >
                                <span>Performance Report</span>
                            </MenuItem>
                        }
                        <MenuItem eventKey={2.11} onClick={this.navigateTo.bind(this, 'support-ticket')} >
                            <span>Support Ticket</span>
                        </MenuItem>
                        <MenuItem eventKey={2.12} onClick={this.navigateTo.bind(this, 'billing-cycle')} >
                            <span>Consultation</span>
                        </MenuItem>

                        <MenuItem eventKey={2.13} onClick={this.navigateTo.bind(this, 'settings')} >
                            <span>Settings</span>
                        </MenuItem>

                    </NavDropdown> */}

          {localStorage.getItem("is_chat_facility") === "yes" && (
            <NavItem eventKey={7} onClick={this.openChatModel.bind(this)}>
              <img src={chaticon} alt="img" />
              <br />
              <span>Chat</span>
              {((this.props && this.props.AllCount !== 0) ||
                (localStorage.getItem("AllCount") &&
                  localStorage.getItem("AllCount") !== 0)) && (
                  <span className="chatCount">
                    {localStorage.getItem("AllCount") < 0 ? 0 : localStorage.getItem("AllCount")}
                  </span>
                )}
            </NavItem>
          )}

          <NavItem eventKey={3}>
            <div className="doctor-info user-name-log">
              <div
                className="logout-dropdown"
                onClick={() =>
                  this.setState({ logoutMenuOpen: !this.state.logoutMenuOpen })
                }
              >
                <span>
                  {localStorage.getItem("title") !== "undefined"
                    ? localStorage.getItem("title")
                    : ""}{" "}
                  {localStorage.getItem("firstName")}{" "}
                  {localStorage.getItem("lastName")}
                </span>
                <span>Clinic ID: {localStorage.getItem("clinicNumber")}</span>
              </div>
              <NavDropdown
                eventKey={2}
                title={<i className="fa fa-caret-down right-car-down"></i>}
                noCaret
                id="basic-nav-dropdown-1"
                className={`tran-nonee logout-menu ${this.state.logoutMenuOpen ? "open" : ""
                  }`}
              >
                <MenuItem
                  onClick={this.Logout.bind(this)}
                  className="logout-icon"
                >
                  <img src={logouticon} alt="img" /> Logout
                </MenuItem>
              </NavDropdown>
            </div>
          </NavItem>
        </Nav>
        <Modal
          className="pa-chat-md"
          show={this.state.chatModal}
          onHide={this.hideChatModel.bind(this)}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Row>
              <Col md={12}>
                <Chat activeAllChat={true} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          // backdrop="static"
          className="pa-patient-registration"
          show={this.state.patientModal}
          onHide={() => this.setState({ patientModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="Patient-headers">
            <Modal.Title id="example-modal-sizes-title-lg">
              Patient Registration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <Row>
              <Col md={12}>
                <PatientRegistration
                  parentComponent="headerlinks"
                  onDismiss={this.onDismiss}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isGetChatCount: state.master.isGetChatCount,
    isGetChatCountError: state.master.isGetChatCountError,
    GetChatCount: state.master.GetChatCount,
    isReduceChatCount: state.master.isReduceChatCount,
    PatientChatList: state.master.PatientChatList,
    allChatCount: state.master.allChatCount
  };
}

export default withRouter(
  connect(mapStateToProps, { getChatCountAction, resetVisitListAction, visitListAction, resetIsReduceChatCoutnAction })(HeaderLinks)
);

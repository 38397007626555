/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken, getRequestWithToken } from "./helper"

export const addAdminNotification = (data) => postRequestWithToken(data, 'admin/add-admin-Notification');
export const updateAdminNotification = (data) => postRequestWithToken(data, 'admin/update-admin-notification');
export const adminNotificationList = (data) => postRequestWithToken(data, 'admin/admin-notification-list');
export const removeAdminNotification = (data) => postRequestWithToken(data, '/admin/remove-admin-notification');
export const adminNotificationActiveStatus = (data) => getRequestWithToken(data, `admin/admin-notification-active-status?adminNotificationId=${data.adminNotificationId}&status=${data.status}`);
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import {
  Row,
  Grid,
  Form,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";

const BoneAgeCalculator = () => {
  const [formData, setFormData] = useState({});
  const [required, setRequired] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bonAge, setBonAge] = useState(0.0);
  const [rusScore, setRusScore] = useState(0.0);

  const showDatasetModal = () => {
    setIsModalOpen(true);
  };


  let radius = 0;
  let ulna = 0;
  let metacarpal1 = 0;
  let metacarpal3 = 0;
  let metacarpal5 = 0;
  let proximal1 = 0;
  let proximal3 = 0;
  let proximal5 = 0;
  let middle3 = 0;
  let middle5 = 0;
  let distal1 = 0;
  let distal3 = 0
  let distal5 = 0;

  let data = new Array;
  data["boy"] = ["radius", "ulna", "metacarpal1", "metacarpal3", "metacarpal5", "proximal1", "proximal3", "proximal5", "middle3", "middle5", "distal1", "distal2", "distal5"];
  data["girl"] = ["radius", "ulna", "metacarpal1", "metacarpal3", "metacarpal5", "proximal1", "proximal3", "proximal5", "middle3", "middle5", "distal1", "distal2", "distal5"];

  data["boy"]["radius"] = [0, 16, 21, 30, 39, 59, 87, 138, 213];
  data["boy"]["ulna"] = [0, 27, 30, 32, 40, 58, 107, 181, 0];

  data["boy"]["metacarpal1"] = [0, 6, 9, 14, 21, 26, 36, 49, 67];
  data["boy"]["metacarpal3"] = [0, 4, 5, 9, 12, 19, 31, 43, 52];
  data["boy"]["metacarpal5"] = [0, 4, 6, 9, 14, 18, 29, 43, 52];

  data["boy"]["proximal1"] = [0, 7, 8, 11, 17, 26, 38, 52, 67];
  data["boy"]["proximal3"] = [0, 4, 4, 9, 15, 23, 31, 40, 53];
  data["boy"]["proximal5"] = [0, 4, 5, 9, 15, 21, 30, 39, 51];

  data["boy"]["middle3"] = [0, 4, 6, 9, 15, 22, 32, 43, 52];
  data["boy"]["middle5"] = [0, 6, 7, 9, 15, 23, 32, 42, 49];

  data["boy"]["distal1"] = [0, 5, 6, 11, 17, 26, 38, 46, 66];
  data["boy"]["distal3"] = [0, 4, 6, 8, 13, 18, 28, 34, 49];
  data["boy"]["distal5"] = [0, 5, 6, 9, 13, 18, 27, 34, 48];

  //Girl
  data["girl"]["radius"] = [0, 23, 30, 44, 56, 78, 114, 160, 218];
  data["girl"]["ulna"] = [0, 30, 33, 37, 45, 74, 118, 173, 0];

  data["girl"]["metacarpal1"] = [0, 8, 12, 18, 24, 31, 43, 53, 67];
  data["girl"]["metacarpal3"] = [0, 5, 8, 12, 16, 23, 37, 47, 53];
  data["girl"]["metacarpal5"] = [0, 6, 9, 12, 17, 23, 35, 48, 52];

  data["girl"]["proximal1"] = [0, 9, 11, 14, 20, 31, 44, 56, 67];
  data["girl"]["proximal3"] = [0, 5, 7, 12, 19, 27, 37, 44, 54];
  data["girl"]["proximal5"] = [0, 6, 7, 12, 18, 26, 35, 42, 51];

  data["girl"]["middle3"] = [0, 6, 8, 12, 18, 27, 36, 45, 52];
  data["girl"]["middle5"] = [0, 7, 8, 12, 18, 28, 35, 43, 49];

  data["girl"]["distal1"] = [0, 7, 9, 15, 22, 33, 48, 51, 68];
  data["girl"]["distal3"] = [0, 7, 8, 11, 15, 22, 33, 37, 49];
  data["girl"]["distal5"] = [0, 7, 8, 11, 15, 22, 32, 36, 47];

  data["boneage"] = [];
  data["boneage"]["girl"] = [];
  data["boneage"]["boy"] = [];

  data["boneage"]["boy"]["42"] = "2";
  data["boneage"]["boy"]["46"] = "2.1";
  data["boneage"]["boy"]["50"] = "2.2";
  data["boneage"]["boy"]["55"] = "2.3";
  data["boneage"]["boy"]["60"] = "2.4";
  data["boneage"]["boy"]["66"] = "2.5";
  data["boneage"]["boy"]["70"] = "2.6";
  data["boneage"]["boy"]["75"] = "2.7";
  data["boneage"]["boy"]["80"] = "2.8";
  data["boneage"]["boy"]["86"] = "2.9";
  data["boneage"]["boy"]["91"] = "3.0";
  data["boneage"]["boy"]["94"] = "3.1";
  data["boneage"]["boy"]["98"] = "3.2";
  data["boneage"]["boy"]["101"] = "3.3";
  data["boneage"]["boy"]["105"] = "3.4";
  data["boneage"]["boy"]["108"] = "3.5";
  data["boneage"]["boy"]["112"] = "3.6";
  data["boneage"]["boy"]["120"] = "3.7";
  data["boneage"]["boy"]["124"] = "3.8";
  data["boneage"]["boy"]["129"] = "3.9";
  data["boneage"]["boy"]["132"] = "4.0";
  data["boneage"]["boy"]["134"] = "4.1";
  data["boneage"]["boy"]["137"] = "4.2";
  data["boneage"]["boy"]["140"] = "4.3";
  data["boneage"]["boy"]["143"] = "4.4";
  data["boneage"]["boy"]["146"] = "4.5";
  data["boneage"]["boy"]["149"] = "4.6";
  data["boneage"]["boy"]["152"] = "4.7";
  data["boneage"]["boy"]["155"] = "4.8";
  data["boneage"]["boy"]["158"] = "4.9";
  data["boneage"]["boy"]["161"] = "5.0";
  data["boneage"]["boy"]["164"] = "5.1";
  data["boneage"]["boy"]["166"] = "5.2";
  data["boneage"]["boy"]["169"] = "5.3";
  data["boneage"]["boy"]["172"] = "5.4";
  data["boneage"]["boy"]["175"] = "5.5";
  data["boneage"]["boy"]["177"] = "5.6";
  data["boneage"]["boy"]["180"] = "5.7";
  data["boneage"]["boy"]["183"] = "5.8";
  data["boneage"]["boy"]["186"] = "5.9";
  data["boneage"]["boy"]["189"] = "6.0";
  data["boneage"]["boy"]["191"] = "6.1";
  data["boneage"]["boy"]["194"] = "6.2";
  data["boneage"]["boy"]["197"] = "6.3";
  data["boneage"]["boy"]["200"] = "6.4";
  data["boneage"]["boy"]["202"] = "6.5";
  data["boneage"]["boy"]["205"] = "6.6";
  data["boneage"]["boy"]["208"] = "6.7";
  data["boneage"]["boy"]["211"] = "6.8";
  data["boneage"]["boy"]["214"] = "6.9";
  data["boneage"]["boy"]["216"] = "7.0";
  data["boneage"]["boy"]["219"] = "7.1";
  data["boneage"]["boy"]["222"] = "7.2";
  data["boneage"]["boy"]["225"] = "7.3";
  data["boneage"]["boy"]["228"] = "7.4";
  data["boneage"]["boy"]["231"] = "7.5";
  data["boneage"]["boy"]["234"] = "7.6";
  data["boneage"]["boy"]["237"] = "7.7";
  data["boneage"]["boy"]["240"] = "7.8";
  data["boneage"]["boy"]["243"] = "7.9";
  data["boneage"]["boy"]["246"] = "8.0";
  data["boneage"]["boy"]["250"] = "8.1";
  data["boneage"]["boy"]["253"] = "8.2";
  data["boneage"]["boy"]["256"] = "8.3";
  data["boneage"]["boy"]["259"] = "8.4";
  data["boneage"]["boy"]["262"] = "8.5";
  data["boneage"]["boy"]["265"] = "8.6";
  data["boneage"]["boy"]["268"] = "8.7";
  data["boneage"]["boy"]["272"] = "8.8";
  data["boneage"]["boy"]["275"] = "8.9";
  data["boneage"]["boy"]["279"] = "9.0";
  data["boneage"]["boy"]["283"] = "9.1";
  data["boneage"]["boy"]["287"] = "9.2";
  data["boneage"]["boy"]["291"] = "9.3";
  data["boneage"]["boy"]["295"] = "9.4";
  data["boneage"]["boy"]["299"] = "9.5";
  data["boneage"]["boy"]["303"] = "9.6";
  data["boneage"]["boy"]["308"] = "9.7";
  data["boneage"]["boy"]["312"] = "9.8";
  data["boneage"]["boy"]["316"] = "10";
  data["boneage"]["boy"]["321"] = "10.1";
  data["boneage"]["boy"]["325"] = "10.2";
  data["boneage"]["boy"]["330"] = "10.3";
  data["boneage"]["boy"]["334"] = "10.4";
  data["boneage"]["boy"]["339"] = "10.5";
  data["boneage"]["boy"]["344"] = "10.6";
  data["boneage"]["boy"]["348"] = "10.7";
  data["boneage"]["boy"]["353"] = "10.8";
  data["boneage"]["boy"]["358"] = "10.9";
  data["boneage"]["boy"]["363"] = "11";
  data["boneage"]["boy"]["369"] = "11.1";
  data["boneage"]["boy"]["375"] = "11.2";
  data["boneage"]["boy"]["381"] = "11.3";
  data["boneage"]["boy"]["387"] = "11.4";
  data["boneage"]["boy"]["394"] = "11.5";
  data["boneage"]["boy"]["400"] = "11.6";
  data["boneage"]["boy"]["406"] = "11.7";
  data["boneage"]["boy"]["413"] = "11.8";
  data["boneage"]["boy"]["420"] = "11.9";
  data["boneage"]["boy"]["427"] = "12";
  data["boneage"]["boy"]["434"] = "12.1";
  data["boneage"]["boy"]["441"] = "12.2";
  data["boneage"]["boy"]["448"] = "12.3";
  data["boneage"]["boy"]["455"] = "12.4";
  data["boneage"]["boy"]["462"] = "12.5";
  data["boneage"]["boy"]["470"] = "12.6";
  data["boneage"]["boy"]["478"] = "12.7";
  data["boneage"]["boy"]["485"] = "12.8";
  data["boneage"]["boy"]["493"] = "12.9";
  data["boneage"]["boy"]["501"] = "13";
  data["boneage"]["boy"]["511"] = "13.1";
  data["boneage"]["boy"]["520"] = "13.2";
  data["boneage"]["boy"]["530"] = "13.3";
  data["boneage"]["boy"]["540"] = "13.4";
  data["boneage"]["boy"]["550"] = "13.5";
  data["boneage"]["boy"]["560"] = "13.6";
  data["boneage"]["boy"]["570"] = "13.7";
  data["boneage"]["boy"]["581"] = "13.8";
  data["boneage"]["boy"]["592"] = "13.9";
  data["boneage"]["boy"]["603"] = "14";
  data["boneage"]["boy"]["615"] = "14.1";
  data["boneage"]["boy"]["628"] = "14.2";
  data["boneage"]["boy"]["641"] = "14.3";
  data["boneage"]["boy"]["655"] = "14.4";
  data["boneage"]["boy"]["668"] = "14.5";
  data["boneage"]["boy"]["682"] = "14.6";
  data["boneage"]["boy"]["697"] = "14.7";
  data["boneage"]["boy"]["711"] = "14.8";
  data["boneage"]["boy"]["726"] = "14.9";
  data["boneage"]["boy"]["741"] = "15";
  data["boneage"]["boy"]["755"] = "15.1";
  data["boneage"]["boy"]["769"] = "15.2";
  data["boneage"]["boy"]["783"] = "15.3";
  data["boneage"]["boy"]["798"] = "15.4";
  data["boneage"]["boy"]["813"] = "15.5";
  data["boneage"]["boy"]["824"] = "15.6";
  data["boneage"]["boy"]["843"] = "15.7";
  data["boneage"]["boy"]["859"] = "15.8";
  data["boneage"]["boy"]["875"] = "15.9";
  data["boneage"]["boy"]["891"] = "16";
  data["boneage"]["boy"]["912"] = "16.1";
  data["boneage"]["boy"]["933"] = "16.2";
  data["boneage"]["boy"]["955"] = "16.3";
  data["boneage"]["boy"]["977"] = "16.4";
  data["boneage"]["boy"]["1000"] = "16.5";


  data["boneage"]["girl"]["126"] = "2";
  data["boneage"]["girl"]["130"] = "2.1";
  data["boneage"]["girl"]["134"] = "2.2";
  data["boneage"]["girl"]["139"] = "2.3";
  data["boneage"]["girl"]["143"] = "2.4";
  data["boneage"]["girl"]["148"] = "2.5";
  data["boneage"]["girl"]["153"] = "2.6";
  data["boneage"]["girl"]["158"] = "2.7";
  data["boneage"]["girl"]["163"] = "2.8";
  data["boneage"]["girl"]["168"] = "2.9";
  data["boneage"]["girl"]["174"] = "3.0";
  data["boneage"]["girl"]["178"] = "3.1";
  data["boneage"]["girl"]["182"] = "3.2";
  data["boneage"]["girl"]["186"] = "3.3";
  data["boneage"]["girl"]["191"] = "3.4";
  data["boneage"]["girl"]["195"] = "3.5";
  data["boneage"]["girl"]["200"] = "3.6";
  data["boneage"]["girl"]["204"] = "3.7";
  data["boneage"]["girl"]["209"] = "3.8";
  data["boneage"]["girl"]["214"] = "3.9";
  data["boneage"]["girl"]["219"] = "4.0";
  data["boneage"]["girl"]["222"] = "4.1";
  data["boneage"]["girl"]["225"] = "4.2";
  data["boneage"]["girl"]["228"] = "4.3";
  data["boneage"]["girl"]["231"] = "4.4";
  data["boneage"]["girl"]["234"] = "4.5";
  data["boneage"]["girl"]["238"] = "4.6";
  data["boneage"]["girl"]["241"] = "4.7";
  data["boneage"]["girl"]["244"] = "4.8";
  data["boneage"]["girl"]["248"] = "4.9";
  data["boneage"]["girl"]["251"] = "5.0";
  data["boneage"]["girl"]["255"] = "5.1";
  data["boneage"]["girl"]["258"] = "5.2";
  data["boneage"]["girl"]["262"] = "5.3";
  data["boneage"]["girl"]["265"] = "5.4";
  data["boneage"]["girl"]["269"] = "5.5";
  data["boneage"]["girl"]["273"] = "5.6";
  data["boneage"]["girl"]["277"] = "5.7";
  data["boneage"]["girl"]["281"] = "5.8";
  data["boneage"]["girl"]["284"] = "5.9";
  data["boneage"]["girl"]["288"] = "6";
  data["boneage"]["girl"]["292"] = "6.1";
  data["boneage"]["girl"]["296"] = "6.2";
  data["boneage"]["girl"]["301"] = "6.3";
  data["boneage"]["girl"]["305"] = "6.4";
  data["boneage"]["girl"]["309"] = "6.5";
  data["boneage"]["girl"]["313"] = "6.6";
  data["boneage"]["girl"]["318"] = "6.7";
  data["boneage"]["girl"]["322"] = "6.8";
  data["boneage"]["girl"]["327"] = "6.9";
  data["boneage"]["girl"]["331"] = "7";
  data["boneage"]["girl"]["335"] = "7.1";
  data["boneage"]["girl"]["339"] = "7.2";
  data["boneage"]["girl"]["343"] = "7.3";
  data["boneage"]["girl"]["347"] = "7.4";
  data["boneage"]["girl"]["351"] = "7.5";
  data["boneage"]["girl"]["355"] = "7.6";
  data["boneage"]["girl"]["359"] = "7.7";
  data["boneage"]["girl"]["363"] = "7.8";
  data["boneage"]["girl"]["367"] = "7.9";
  data["boneage"]["girl"]["372"] = "8.0";
  data["boneage"]["girl"]["377"] = "8.1";
  data["boneage"]["girl"]["382"] = "8.2";
  data["boneage"]["girl"]["387"] = "8.3";
  data["boneage"]["girl"]["393"] = "8.4";
  data["boneage"]["girl"]["398"] = "8.5";
  data["boneage"]["girl"]["404"] = "8.6";
  data["boneage"]["girl"]["409"] = "8.7";
  data["boneage"]["girl"]["415"] = "8.8";
  data["boneage"]["girl"]["421"] = "8.9";
  data["boneage"]["girl"]["427"] = "9";
  data["boneage"]["girl"]["434"] = "9.1";
  data["boneage"]["girl"]["441"] = "9.2";
  data["boneage"]["girl"]["448"] = "9.3";
  data["boneage"]["girl"]["455"] = "9.4";
  data["boneage"]["girl"]["462"] = "9.5";
  data["boneage"]["girl"]["470"] = "9.6";
  data["boneage"]["girl"]["478"] = "9.7";
  data["boneage"]["girl"]["485"] = "9.8";
  data["boneage"]["girl"]["493"] = "9.9";
  data["boneage"]["girl"]["501"] = "10";
  data["boneage"]["girl"]["509"] = "10.1";
  data["boneage"]["girl"]["518"] = "10.2";
  data["boneage"]["girl"]["526"] = "10.3";
  data["boneage"]["girl"]["535"] = "10.4";
  data["boneage"]["girl"]["543"] = "10.5";
  data["boneage"]["girl"]["552"] = "10.6";
  data["boneage"]["girl"]["561"] = "10.7";
  data["boneage"]["girl"]["570"] = "10.8";
  data["boneage"]["girl"]["579"] = "10.9";
  data["boneage"]["girl"]["589"] = "11";
  data["boneage"]["girl"]["597"] = "11.1";
  data["boneage"]["girl"]["605"] = "11.2";
  data["boneage"]["girl"]["614"] = "11.3";
  data["boneage"]["girl"]["622"] = "11.4";
  data["boneage"]["girl"]["631"] = "11.5";
  data["boneage"]["girl"]["640"] = "11.6";
  data["boneage"]["girl"]["649"] = "11.7";
  data["boneage"]["girl"]["658"] = "11.8";
  data["boneage"]["girl"]["667"] = "11.9";
  data["boneage"]["girl"]["676"] = "12";
  data["boneage"]["girl"]["685"] = "12.1";
  data["boneage"]["girl"]["695"] = "12.2";
  data["boneage"]["girl"]["705"] = "12.3";
  data["boneage"]["girl"]["714"] = "12.4";
  data["boneage"]["girl"]["724"] = "12.5";
  data["boneage"]["girl"]["735"] = "12.6";
  data["boneage"]["girl"]["745"] = "12.7";
  data["boneage"]["girl"]["755"] = "12.8";
  data["boneage"]["girl"]["766"] = "12.9";
  data["boneage"]["girl"]["776"] = "13";
  data["boneage"]["girl"]["787"] = "13.1";
  data["boneage"]["girl"]["798"] = "13.2";
  data["boneage"]["girl"]["809"] = "13.3";
  data["boneage"]["girl"]["820"] = "13.4";
  data["boneage"]["girl"]["832"] = "13.5";
  data["boneage"]["girl"]["843"] = "13.6";
  data["boneage"]["girl"]["855"] = "13.7";
  data["boneage"]["girl"]["867"] = "13.8";
  data["boneage"]["girl"]["879"] = "13.9";
  data["boneage"]["girl"]["891"] = "14";
  data["boneage"]["girl"]["902"] = "14.1";
  data["boneage"]["girl"]["912"] = "14.2";
  data["boneage"]["girl"]["923"] = "14.3";
  data["boneage"]["girl"]["933"] = "14.4";
  data["boneage"]["girl"]["944"] = "14.5";
  data["boneage"]["girl"]["955"] = "14.6";
  data["boneage"]["girl"]["966"] = "14.7";
  data["boneage"]["girl"]["978"] = "14.8";
  data["boneage"]["girl"]["989"] = "14.9";
  data["boneage"]["girl"]["1000"] = "15";



  const get_boneAgeKey = (total, gender) => {

    var mykeys = Object.keys(data["boneage"][gender]);
    let prev = 0;
    let next = 0;
    for (var val = 0; val < mykeys.length; val++) {
      prev = mykeys[val];
      next = mykeys[(val + 1)];
      if (!next)
        return prev;
      else if (prev > total)
        return prev;
      else if (prev < total && total < next)
        return prev;
    }
  }


  const calculateFun = (e) => {
    e.preventDefault();
    let total = 0;
    let gender = "boy";
    let radios_gender = document.getElementsByName("gender");
    for (var radio of radios_gender) {
      if (radio.checked) {
        gender = radio.value;
      }
    }
    if (gender) {
      for (var i = 0; i < data[gender].length; i++) {
        var ele = data[gender][i];

        let dataValue = 0;
        let str = "tool_6_" + ele;

        var key = 0;
        var radios = document.getElementsByName(str);
        for (var radio of radios) {
          if (radio.checked) {
            key = radio.value;
          }
        }
        if (key !== 0) {
          key = parseInt(key) - parseInt(1);
          dataValue = data[gender][ele][key];


        } else {
          dataValue = 0;
        }
        total = parseInt(total) + parseInt(dataValue);

      }
      setRusScore(total);
      //$("#tool_6_rus_score").val(total);   
      //console.log("sum=="+total);
      if (data["boneage"][gender][total]) {
        setBonAge(data["boneage"][gender][total]);

        // $("#tool_6_bone_age").val(boneAge);

      } else {
        var boneAgeKey = get_boneAgeKey(total, gender);
        var boneAge = data["boneage"][gender][boneAgeKey];
        setBonAge(boneAge);
      }
      // console.log("boneAge==="+boneAge);
    } else {
      alert("Select Gender");
    }
  }

  const inputHandler = (e) => {
    let saveFormData = formData;
    if (e.target.type === "radio") {
      saveFormData[e.target.name] = e.target.checked;
    }
    saveFormData[e.target.name] = e.target.value;

    setFormData({ ...formData, ...saveFormData });
    setRequired({ ...required, ...saveFormData });

  };

  return (
    <div className="main-content color-notations">
      <Grid fluid>
        <Card
          content={
            <Row>
              <Col md={12}>
                <h4>RUS Calculator</h4>
                <Col md={8}>
                  <div class="form-group">
                    <table class="table table-bordered bone_table">
                      <tbody>
                        <th>Image</th>
                        <th>A</th>
                        <th>B</th>
                        <th>C</th>
                        <th>D</th>
                        <th>E</th>
                        <th>F</th>
                        <th>G</th>
                        <th>H</th>
                        <th>I</th>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_radius" id="tool_6_btn_radius">Radius</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_radius" id="tool_6_radius_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_ulna" id="tool_6_btn_ulna">Ulna</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_ulna" id="tool_6_ulna_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td colspan="9"><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-parent" name="tool_6_btn_metacarpal" id="tool_6_btn_metacarpal">Metacarpal</button></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-primary btn-xs btn-child" name="tool_6_btn_metacarpal1" id="tool_6_btn_metacarpal1">I</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal1" id="tool_6_metacarpal1_9" value="9" /></td>

                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-child" name="tool_6_btn_metacarpal3" id="tool_6_btn_metacarpal3">III</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal3" id="tool_6_metacarpal3_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_metacarpal5" id="tool_6_btn_metacarpal5">V</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_metacarpal5" id="tool_6_metacarpal5_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td colspan="9"><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-parent" name="tool_6_btn_proximal" id="tool_6_btn_proximal">Proximal phalanax</button></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-primary btn-xs btn-child" name="tool_6_btn_proximal1" id="tool_6_btn_proximal1">I</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal1" id="tool_6_proximal1_9" value="9" /></td>

                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-child" name="tool_6_btn_proximal3" id="tool_6_btn_proximal3">III</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal3" id="tool_6_proximal3_9" value="9" /></td>
                        </tr>                                               <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-child" name="tool_6_btn_proximal5" id="tool_6_btn_proximal5">V</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_proximal5" id="tool_6_proximal5_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td colspan="9"><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-parent" name="tool_6_btn_middle3" id="tool_6_btn_middle3">Middle phalanax</button></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_middle3" id="tool_6_btn_middle3">III</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle3" id="tool_6_middle3_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-child" name="tool_6_btn_middle5" id="tool_6_btn_middle5">V</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_middle5" id="tool_6_middle5_9" value="9" /></td>

                        </tr>
                        <tr>
                          <td colspan="9"><button onClick={showDatasetModal} type="button" class="btn btn-fill  btn-primary btn-xs btn-parent" name="tool_6_btn_distal" id="tool_6_btn_distal">Distal phalanax</button></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-primary btn-xs btn-child" name="tool_6_btn_distal1" id="tool_6_btn_distal1">I</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal1" id="tool_6_distal1_9" value="9" /></td>

                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_distal3" id="tool_6_btn_distal3">III</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal3" id="tool_6_distal3_9" value="9" /></td>
                        </tr>
                        <tr>
                          <td><button onClick={showDatasetModal} type="button" class="btn btn-fill btn-primary btn-xs btn-child" name="tool_6_btn_distal5" id="tool_6_btn_distal5">V</button></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_1" value="1" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_2" value="2" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_3" value="3" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_4" value="4" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_5" value="5" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_6" value="6" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_7" value="7" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_8" value="8" /></td>
                          <td> <input type="radio" onChange={(e) => { inputHandler(e); }} name="tool_6_distal5" id="tool_6_distal5_9" value="9" /></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col md={4}>
                  <Form horizontal>
                    <Row>
                      <Col sm={12} className="bone-age-box">
                        <FormGroup>
                          <Col
                            componentClass={ControlLabel}
                            className="col-sm-12"
                          >
                            Select Gender
                          </Col>
                          <Col className="col-sm-3 control-label">
                            {" "}
                            <input
                              type="radio"
                              name="gender"
                              id="gender_boy"
                              value="boy"
                            />
                            Boy
                          </Col>
                          <Col className="col-sm-3 control-label text-unset">
                            <input
                              type="radio"
                              name="gender"
                              id="gender_girl"
                              value="girl"
                            />
                            Girl
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={12} className="bone-age-box">
                        <FormGroup>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>First Name</Col>
                            <FormControl
                              type="text"
                              name="firstname"
                              id="firstname"
                              autocomplete="off"
                              value={formData.firstname || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                            <span
                              className="errorMsg"
                              style={{
                                display:
                                  !formData.firstname && required.firstname === ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              This is required field
                            </span>
                          </Col>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>Last Name</Col>
                            <FormControl
                              type="text"
                              name="lastname"
                              id="lastname"
                              autocomplete="off"
                              value={formData.lastname || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                            <span
                              className="errorMsg"
                              style={{
                                display:
                                  !formData.lastname && required.lastname === ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              This is required field
                            </span>
                          </Col>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>Age(YY)</Col>
                            <FormControl
                              type="text"
                              name="age"
                              id="age"
                              autocomplete="off"
                              value={formData.age || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                            <span
                              className="errorMsg"
                              style={{
                                display:
                                  !formData.age && required.lastname === ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              This is required field
                            </span>
                          </Col>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>Month(MM)</Col>
                            <FormControl
                              type="text"
                              name="month"
                              id="month"
                              autocomplete="off"
                              value={formData.month || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                            <span
                              className="errorMsg"
                              style={{
                                display:
                                  !formData.month && required.month === ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              This is required field
                            </span>
                          </Col>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>DOB</Col>
                            <FormControl
                              type="text"
                              name="dob"
                              id="dob"
                              autocomplete="off"
                              value={formData.dob || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                            <span
                              className="errorMsg"
                              style={{
                                display:
                                  !formData.dob && required.dob === ""
                                    ? "block"
                                    : "none",
                              }}
                            >
                              This is required field
                            </span>
                          </Col>
                          <Col sm={6}>
                            <Col componentClass={ControlLabel}>Bone Age</Col>
                            <FormControl
                              type="text"
                              name="boneage"
                              id="boneage"
                              disabled="disabled"
                              value={bonAge || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup>
                          <Col sm={3}>
                            <button
                              type="button"
                              onClick={calculateFun}
                              className="btn-fill btn-wd btn btn-primary"
                            >
                              Calculate
                            </button>
                          </Col>
                          <Col sm={3}></Col>
                        </FormGroup>
                      </Col>
                      <Col sm={12}>
                        <FormGroup>
                          <Col sm={3}>RUS Score</Col>
                          <Col sm={7}>
                            <FormControl
                              type="text"
                              name="rus_score"
                              id="rus_score"
                              disabled="disabled"
                              value={rusScore || ""}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Col>
            </Row>
          }
        />
      </Grid>
    </div>
  );
};

export default BoneAgeCalculator;

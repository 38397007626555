/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import {
    Row, Col, FormControl, Grid, Modal
} from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from '../../api/notifications';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import moment from 'moment';
import NotificationSystem from 'react-notification-system';
import { style } from "Admin/variables/Variables.jsx";


const NotificationList = () => {
    let history = useHistory();
    let { mode } = useParams()
    const [formData, setFormData] = useState({ limit: 10, page: 0, direction: '', })
    const [isLoading, setIsLoading] = useState(false)
    const [notificationList, setNotifiactionList] = useState([]);
    const [modalStatus, setModalStatus] = useState(false)
    const [sortData, setSortData] = useState(false);
    const [addNotification, setAddNotification] = useState({})
    const [totalCount, setTotalCount] = useState(0)
    const [scroll, setScroll] = useState(0);

    const notificationSystem = useRef(null)


    const getNotificationListAction = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.adminNotificationList({ "limit": formData.limit, "offset": formData.page, "order": "createdAt", direction: formData.direction, "search": from.search });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            setIsLoading(false);
            setNotifiactionList(resp.data.data);
            setTotalCount(resp.data.count);
        }

    }
    const handleToastifyMessage = (data) => {
        let msg = ""
        if (data === "add")
            msg = "Notification Added Successfully"
        if (data === "edit")
            msg = "Notification Edit Successfully"
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }


    useEffect(() => {
        if (history?.location?.from === "add" || history?.location?.from === "edit")
            handleToastifyMessage(history?.location?.from)
        getNotificationListAction();
    }, []);

    const handleChange = (e, field = '') => {
        let saveFormData = formData;
        saveFormData[field] = e;
        saveFormData['page'] = 0;
        setFormData({ ...formData, ...saveFormData });
        getNotificationListAction();
    }

    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const statusChangedHandler = async (event) => {
        let resp = await API.adminNotificationActiveStatus({ adminNotificationId: event._id, status: event.enabled === "1" ? "0" : "1" })
        if (resp.data.status === "Success") {
            let msg = ""
            msg = resp.data.msg;
            const notification = notificationSystem.current;
            notification.addNotification({
                title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
                message: <div>
                    {msg}
                </div>,
                level: 'success',
                position: 'tr',
                autoDismiss: 2,
            });
            getNotificationListAction();
        }
    }

    const Action = (cell, row, enumObject, rowIndex) => {
        return (<span><Link to={{ pathname: `/admin/edit-notification/` + row._id, state: { row } }}  ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={() => statusChangedHandler(row)}><i className={(row.enabled === "1") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
        </span>)
    }

    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getNotificationListAction();

        let scrollBar = 50;  
        if(scroll == page &&  sizePerPage < scrollBar ) {
          eval('$(".ps").scrollTop(0)');
        } else if( totalCount < page * sizePerPage){
          eval('$(".ps").scrollTop(0)');
        }
        setScroll(page)
    }

    function sortHandle(){ 
        let data = formData;
        data['direction'] =  sortData? "desc": "asc";
        setFormData({ ...formData, ...data });
        setSortData(!sortData )
        getNotificationListAction();
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }



    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption(),
    };

    const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
        return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
    };


    return (<div className="main-content " >
        <div>
            <NotificationSystem ref={notificationSystem} style={style} />
        </div>
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <Card
                        content={
                            <div className="fresh-datatables language-list-table">
                                <Row>
                                    <Col md={6}>
                                        <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-notification")}>Add Notification</Button>
                                    </Col>
                                   
                                </Row>&nbsp;
                                <BootstrapTable
                                    deleteRow={false}
                                    key={notificationList}
                                    data={notificationList}
                                    search={false}
                                    multiColumnSearch={true}
                                    pagination={true}
                                    options={options}
                                    striped hover condensed scrollTop={'Bottom'}
                                    remote={true}
                                    fetchInfo={{ dataTotalSize: totalCount }}
                                    // sort={true}
                                    trStyle={rowStyleFormat}
                                >

                                    <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' isKey searchable={false}>Id</TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ 'data-attr': '#' }} thStyle={{ width: '5%', textAlign: 'center' }} tdStyle={{ width: '5%', textAlign: 'center' }} dataField='sn' dataFormat={srNo}>S.No.</TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ 'data-attr': 'adminNotification' }} thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} dataField='adminNotification' >Notification 
                                    {
                                     !sortData?
                                     <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                                     <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                                    }
                                    </TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ "data-attr": "title" }} thStyle={{ width: "10%", textAlign: "center" }} tdStyle={{ width: "10%", textAlign: "center" }} dataField="createdAt" dataFormat={getDrugCreationDate} > Date</TableHeaderColumn>
                                    <TableHeaderColumn thStyle={{ width: '6%', textAlign: 'center' }} tdStyle={{ width: '6%', textAlign: 'center' }} tdAttr={{ 'data-attr': '_id' }} dataField='' dataFormat={Action}>Action</TableHeaderColumn>

                                </BootstrapTable>
                            </div>
                        }
                    />
                </Col>
            </Row>
        </Grid></div>);
}

export default withRouter(NotificationList)
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
export const setEmrRequest = (response) => ({
    type: actionTypes.SET_EMR_DATA,
    payload: response
})

export const getEmrRequest = () => ({
    type: actionTypes.GET_EMR_DATA,
})

export const setVitalSignsRequest = (response) => ({
    type: actionTypes.SET_VITAL_SIGNS_DATA,
    payload: response
})



export const emrSetAction = (data) => {

    return dispatch => {
        dispatch(setEmrRequest(data))
    }
}

export const emrSetVitalSignsAction = (data) => {

    return dispatch => {
        dispatch(setVitalSignsRequest(data))
    }
}

export const emrGetAction = () => {
    return dispatch => {
        dispatch(getEmrRequest())
    }
}
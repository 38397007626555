/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],
    message: '',
    isDietList: false,
    isDietListError: false,
    dietList: false,
    isDietPrint: false,
    isDietPrintError: false,
    printDiet: false,
    isDietPrintColor: false,
    isDietPrintColorError: false,
    printDietColor: false
}

export default (state = initState, action = {}) => {
    switch (action.type) {
        // Diet list
        case actionTypes.GET_DIET_LIST_SUCCESS:
            return {
                ...state,
                message: 'Diet List successfully',
                dietList: action.payload.response,
                isDietList: true,
                isDietListError: false,
            }
        case actionTypes.GET_DIET_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isDietList: false,
                isDietListError: true
            }

        // Print diet
        case actionTypes.PRINT_DIET_REQUEST:
            return {
                ...state,
                message: '',
                isDietPrint: false,
                isDietPrintError: false,
            }
        case actionTypes.PRINT_DIET_SUCCESS:
            return {
                ...state,
                message: 'Diet print successfull',
                printDiet: action.payload.response,
                isDietPrint: true,
                isDietPrintError: false,
            }
        case actionTypes.PRINT_DIET_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isDietPrint: false,
                isDietPrintError: true,
            }

        // Print diet color change
        case actionTypes.PRINT_DIET_COLOR_CHANGE_REQUEST:
            return {
                ...state,
                message: '',
                isDietPrintColor: false,
                isDietPrintColorError: false,
            }
        case actionTypes.PRINT_DIET_COLOR_CHANGE_SUCCESS:
            return {
                ...state,
                message: 'Diet print successfull',
                printDietColor: action.payload.response,
                isDietPrintColor: true,
                isDietPrintColorError: false,
            }
        case actionTypes.PRINT_DIET_COLOR_CHANGE_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isDietPrintColor: false,
                isDietPrintColorError: true,
            }

        // Share diet
        case actionTypes.SHARE_DIET_REQUEST:
            return {
                ...state,
                message: '',
                isDietShare: false,
                isDietPrintError: false,
            }
        case actionTypes.SHARE_DIET_SUCCESS:
            return {
                ...state,
                message: 'Diet share successfull',
                shareDiet: action.payload.response,
                isDietShare: true,
                isDietPrintError: false,
            }
        case actionTypes.SHARE_DIET_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isDietShare: false,
                isDietPrintError: true,
            }

        case actionTypes.CANCEL_DIET_SUCCESS:
            return {
                ...state,
                message: 'Diet cancel successfull',
                cancelDiet: action.payload.response,
                isDietCancel: true

            }

        default:
            return state
    }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from "react-router-dom";
import {
    Row, Col, FormControl, Grid, Modal
} from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/appointment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import moment from 'moment';
import AddAppointment from './AddAppointment';
import PatientRegistration from '../../Home/PatientRegistrationPopup.jsx';
import { confirmAlert } from 'react-confirm-alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import * as PF from "Front/views/Home/PublicFunction.jsx";
//import controllerDoughnut from 'chart.js/src/controllers/controller.doughnut';
import Calendar from '../../../components/Calendar';

const Appointment = () => {

    const [formData, setFormData] = useState({ page: 0, limit: 10, order: 'date_time', direction: 'asc' });
    const [appointmentList, setAppointmentList] = useState([]);

    const [totalCount, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [alertBox, setAlert] = useState(null);

    const [modalStatus, setModalStatus] = useState(false);
    const [editData, setEditData] = useState({});
    const [addAppointment, setAddAppointment] = useState(false);

    // const [modalStatus, setModalStatus] = useState(false); 
    const [addData, setAddData] = useState({});
    const [addappointment, setPatientRegistration] = useState(false);
    const [modalStatusAddReg, setModalStatusAddReg] = useState(false);

    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const notificationSystem = useRef(null)

    const getListAction = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.getAppointmentList(from.limit ? from : formData);
        setIsLoading(false);
        setAppointmentList(resp.data.data.data);
        setTotal(resp.data.data.count);
    }


    useEffect(() => {
        formData['date'] = moment().format("YYYY-MM-DD")
        getListAction();
    }, []);

    const handleChange = (e, field = '') => {
        let saveFormData = formData;
        saveFormData[field] = e;
        setFormData({ ...formData, ...saveFormData });
        getListAction(saveFormData);

    }

    const handleClearBtn = (e) => {
        let data = { page: 0, limit: 10, order: 'date_time', direction: 'asc' };
        setFormData(data);
        setIsUpdated(!isUpdated);
        getListAction(data);
    }

    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }


    const appDate = (cell, row, enumObject, rowIndex) => {
        return moment(row.date).format('DD-MMM-YYYY');
    }



    const editAppointPopup = (row) => {
        setModalStatus(true);
        let tempEdit = row;
        tempEdit["patientClinicId"] = (typeof (row.patientClinicId) === 'undefined' ? '' : row.patientClinicId)
        tempEdit["patientId"] = (typeof (row.patientId) === 'undefined' ? '' : row.patientId)
        setEditData({ ...tempEdit });
        setAddAppointment(false);
    }

    const addPatientRegistrationpopup = async (row) => {
        let addDataTemp = addData;
        if (row.patientClinicId) {
            let res = await API.patientList({ patient_id: row.patientClinicId });
            if (res.data && res.data.status === "Success" && res.data.data.data) {
                addDataTemp = await res.data.data.data[0];
            }
            addDataTemp['appointmentId'] = row._id;
        } else {
            addDataTemp = row;
            let name = row.name.split(" ");
            let firstName = [...name];
            if (firstName.length > 1) {
                firstName.pop();
            }

            addDataTemp["firstName"] = firstName.join(' ');
            // addDataTemp["firstName"] = row.name?row.name:firstName.join(' ');
            addDataTemp['lastName'] = (name.length > 1 ? name[name.length - 1] : '')
            addDataTemp['email'] = row.email ? row.email : '';
            addDataTemp['phone'] = row.phone ? row.phone : ''
            addDataTemp['remark'] = row.remark ? row.remark : ''
            addDataTemp['dob'] = row.dob;
            addDataTemp['city'] = row.city ? row.city : ''
            addDataTemp['appointmentId'] = row._id;

            delete addDataTemp['_id'];
        }
        addDataTemp['econsultation'] = (row.type === 'online') ? true : false;
        if (row.age != undefined && row.age != null) {
            addDataTemp['age'] = row.age
            if (row.dob != undefined) {
                addDataTemp['age'] = PF.getAgeByDob(row?.dob)
            }
        }
        setAddData({ ...addDataTemp });
        setModalStatusAddReg(true);
        setPatientRegistration(false);
    }

    const Action = (cell, row, enumObject, rowIndex) => {
        return <div className='d-flex align-items-center'> <a className='star' onClick={e => addPatientRegistrationpopup(row)}><i style={{ fontWeight: '600', fontSize: '16px' }} class="pe-7s-plus"></i></a>
            <a className='star' onClick={e => editAppointPopup(row)}><i class="fa fa-edit"></i></a>
            <a className='star' onClick={e => DeleteReportConfirm(row._id)}><i class="fa fa-close fa-app-color" aria-hidden="true"></i></a></div>
            ;
    }


    const DeleteReport = async (id) => {
        let resp = await API.deleteAppointment({ id: id });
        if (resp.status === 200) {
            successAlert('Successfully deleted.');
            getListAction();
        }

    }

    const DeleteReportConfirm = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteReport(id)
                },
                {
                    label: 'No',
                    //onClick: () => this.resetFormData()
                }
            ]
        })
    };

    const successAlert = (msg) => {
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }

    const hideAlert = () => {
        setAlert(null);
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getListAction(data);
    }

    const patientUpdate = (data) => {
        if (data) {
            // let patientData = this.state.patientList
            // var localindex_index = patientData.map(function(el) { 
            //     return el._id;
            // }).indexOf(data.patient_id); 
            // if(localindex_index != -1){
            //      patientData[localindex_index]['name'] = data.title + " " + data.first_name +" "+data.last_name;
            //      patientData[localindex_index]['age'] = parseInt(data.age);
            //      patientData[localindex_index]['city'] = data.city;
            //      patientData[localindex_index]['first_name'] = data.first_name;
            //      patientData[localindex_index]['last_name'] = data.last_name;
            //      patientData[localindex_index]['title'] = data.title;
            //      patientData[localindex_index]['gender'] = data.gender;
            //      this.setState({ ...this.state.patientList, patientData });
            //      this.onPageChange(formData.page, formData.limit);

            // }
            onPageChange(formData.page + 1, formData.limit);
        }
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }

    const isPatientUpdate = (data) => {
        if (data) {
            let appointmentData = appointmentList
            var localindex_index = appointmentData.map(function (el) {
                return el._id;
            }).indexOf(data.appointmentId);

            if (localindex_index != -1) {
                appointmentData[localindex_index]['name'] = data.first_name + " " + data.last_name;
                appointmentData[localindex_index]['age'] = data.age;
                appointmentData[localindex_index]['city'] = data.city;
                appointmentData[localindex_index]['first_name'] = data.first_name;
                appointmentData[localindex_index]['last_name'] = data.last_name;
                appointmentData[localindex_index]['title'] = data.title;
                appointmentData[localindex_index]['gender'] = data.gender;
                appointmentData[localindex_index]['_id'] = data.appointmentId;
                appointmentData[localindex_index]['phone'] = data.phone;
                setAppointmentList(appointmentData);
                //onPageChange(formData.page+1,formData.limit);
            }
        }
    }

    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption(),
    };

    const getAge = (cell, row, enumObject, rowIndex) => {
        let age = ""
        if (row?.age && row?.dob) {
            age = PF.getAgeByDob(row.dob);
        } else if (row?.age) {
            age = row.age
        }
        return age
    }
    return (<div className="main-content patient-search" style={{ padding: '15px 0px' }}>
        <Grid fluid>
            {alertBox}
            <NotificationSystem ref={notificationSystem} style={style} />
            <Row>
                <Col md={12}>
                    <Card
                        content={
                            <div className="fresh-datatables table-responsive-new-withoutScroll">
                                <Row className="search-section">

                                    <Col sm={6} md={2} className="form-group">
                                        <FormControl type="text" autocomplete="off" name="search" id="search" value={formData.search ? formData.search : ''} onChange={e => { handleChange(e.target.value, 'search'); }} placeholder="Search here ..." />

                                    </Col>

                                    <Col sm={6} md={2} className="form-group">

                                        <Calendar selectedDate={formData.date ? moment(formData.date).format('YYYY-MM-DD') : moment(todayDate).format('YYYY-MM-DD')} handleChange={e => handleChange(moment(e).format('YYYY-MM-DD'), 'date')} />

                                    </Col>

                                    <Col sm={6} md={2} className="form-group btn-hold">
                                        <Button type="button" onClick={e => handleClearBtn(e)}>Clear</Button>

                                    </Col>

                                    <Col sm={6} md={6} className="form-group btn-hold text-right">
                                        <AddAppointment
                                            getListAction={e => getListAction()}
                                            data={{ ...editData }}
                                            setModal={setModalStatus}
                                            ModalStatus={modalStatus}
                                            addAppointment={addAppointment}
                                            setAddAppointment={setAddAppointment}
                                            patientUpdate={e => patientUpdate(e)}
                                        // patientUpdate={(patient) => { isPatientUpdate(patient) }}
                                        />
                                    </Col>

                                    {modalStatusAddReg &&
                                        <Col sm={6} md={2} className="form-group btn-hold pull-right">
                                            <Modal className="pa-patient-registration" show={modalStatusAddReg} onHide={() => setModalStatusAddReg(false)} dialogClassName="modal-lg">
                                                <Modal.Header closeButton className="bg-thumbcolor">
                                                    <Modal.Title id="example-modal-sizes-title-lg">{addData._id ? "Edit Patient" : "Patient Registration"} {(addData.patientClinicId) ? `( ${addData.patientClinicId} )` : ''} </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="Knowledge-Share card">
                                                    <Row>
                                                        <Col md={12}>
                                                            <PatientRegistration parent={"history"} onDismiss={() => { successAlert("Appointment Edit Successfully"); setModalStatusAddReg(false) }} patientData={addData} successAlertMsg={successAlert} appointmentData={addData} patientUpdate={(data) => { isPatientUpdate(data) }} />
                                                        </Col>
                                                    </Row>
                                                </Modal.Body>
                                            </Modal>
                                        </Col>
                                    }

                                </Row>

                                <div className="fresh-datatables dashboard">

                                    <BootstrapTable
                                        deleteRow={false}
                                        key={appointmentList}
                                        data={appointmentList}
                                        search={false}
                                        pagination={true}
                                        options={options}
                                        striped hover condensed scrollTop={'Bottom'}
                                        remote={true}
                                        fetchInfo={{ dataTotalSize: totalCount }}
                                        sort={true}
                                        trStyle={rowStyleFormat}
                                    //ref={this.onBootstrapTableRef}
                                    >
                                        <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='patientId' dataSort={true} isKey={true} searchable={false}>Id</TableHeaderColumn>

                                        <TableHeaderColumn tdAttr={{ 'data-attr': '#' }} thStyle={{ width: '3%' }} tdStyle={{ width: '3%' }} dataField='sn' dataFormat={srNo}>S.No.</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} tdAttr={{ 'data-attr': 'Id' }} dataField='patientClinicId'>Patient ID</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdAttr={{ 'data-attr': 'Name' }} dataField='name'>Patient Name</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'AGE' }} dataFormat={getAge} dataField='age'>AGE</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'GENDER' }} dataField='gender'>GENDER</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'CITY' }} dataField='city'>CITY</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} tdAttr={{ 'data-attr': 'Initial Insulin Dose' }} dataField='date' dataFormat={appDate}>Date</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} tdAttr={{ 'data-attr': 'Insulin Dose Taken' }} dataField='time'>Time</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} tdAttr={{ 'data-attr': 'Last 2 Glucose Value' }} dataField='refereedBy'>Referred by doctor</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{}} tdStyle={{}} tdAttr={{ 'data-attr': 'Initial Insulin Dose' }} dataField='phone'>Contact number</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Appointment Type' }} dataField='type'>Appointment Type</TableHeaderColumn>

                                        <TableHeaderColumn thStyle={{ width: '6%' }} tdStyle={{ width: '6%' }} tdAttr={{ 'data-attr': 'Patient ID,' }} dataField='' dataFormat={Action}>Action</TableHeaderColumn>

                                    </BootstrapTable>
                                </div>
                            </div>
                        }
                    />
                </Col>
            </Row>
        </Grid></div>);
}
export default withRouter(Appointment);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import banner_img from 'Front/assets/img/banner_2.png';
import Pattern_1 from 'Front/assets/img/Pattern_1.png';
import Pattern_2 from 'Front/assets/img/Pattern_2.png';
import Pattern_3 from 'Front/assets/img/Pattern_3.png';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';
class Aahar extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (

      <Media>
        <div className="PracticeAid-ban">
          <div className="banner_content">
            <h2>PracticeAid</h2>
          </div>
        </div>

        <Grid>
          <div className="about-us">
            <h2>PracticeAid</h2>
          </div>

          <div className="PracticeAid-pera">
            <p> <span>PracticeAid </span> is a mobile application built on iOS and Android platforms. The application connects doctors and patients for quick and effortless communication.</p>
          </div>

          <Row>
            <Col sm={4} md={4} lg={4} className="screen1">
              <img width={'100%'} height={'100%'} src={Pattern_1} alt="Pattern_1" />
            </Col>
            <Col sm={4} md={4} lg={4} className="screen1">
              <img width={'100%'} height={'100%'} src={Pattern_2} alt="Pattern_2" />
            </Col>
            <Col sm={4} md={4} lg={4} className="screen1">
              <img width={'100%'} height={'100%'} src={Pattern_3} alt="Pattern_3" />
            </Col>
          </Row>


          <div className="solutionHighlights">
            <h4>Solution Highlights:</h4>
            <ul>
              <li><i className="glyphicon glyphicon-ok"></i> Easy appointments and reminders.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Live patient’s status inside the medical center.</li>
              <ul>
                <li><li><i className="glyphicon glyphicon-menu-right"></i> Billing, Consultation, Pathology and Investigation</li></li>
              </ul>
              <li><i className="glyphicon glyphicon-ok"></i> Diet chart – documenting the calorie intake.</li>
            </ul>
          </div>

        </Grid>
        <div className="middle-spc"></div>
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Aahar));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import 'Front/views/DietChart/style.css';
import moment from 'moment';
import { appConstants } from 'Front/_constants/app.constants.js';
import { Row, Col, Form } from 'react-bootstrap';
import { PrintDietChart } from 'Front/components/DietChartTable/PrintDietChart.jsx';

export class DietChartTable extends Component {

    render() {

        let firstTime = 0;
        let totalDiff = 0;
        const state = this.props.data;
        let options = state.options;
        let lang = state.dietLanguage;
        const _this = this;
        let objCalorie = {
            1: { calorieTxt: "Total Calorie", carb: "Total Carbohydrate", prot: "Total Protien", fat: "Total Fat" },
            2: { calorieTxt: "कुल कैलोरी", carb: "कुल कार्बोहाइड्रेट", prot: "कुल प्रोटीन", fat: "कुल वसा" },
            3: { calorieTxt: "एकूण कॅलरी", carb: "एकूण कार्बोहायड्रेट", prot: "एकूण प्रथिने", fat: "एकूण चरबी" },
            4: { calorieTxt: "કુલ કેલરી", carb: "કુલ કાર્બોહાઇડ્રેટ", prot: "કુલ પ્રોટીન", fat: "કુલ ચરબી" },
            5: { calorieTxt: "മൊത്തം കലോറി", carb: "മൊത്തം കാർബോഹൈഡ്രേറ്റ്", prot: "മൊത്തം പ്രോട്ടീൻ", fat: "മൊത്തം കൊഴുപ്പ്" },
            6: { calorieTxt: "மொத்த கலோரி", carb: "மொத்த கார்போஹைட்ரேட்", prot: "மொத்த புரதம்", fat: "மொத்த கொழுப்பு" },
            7: { calorieTxt: "ಒಟ್ಟು ಕ್ಯಾಲೋರಿ", carb: "ಒಟ್ಟು ಕಾರ್ಬೋಹೈಡ್ರೇಟ್", prot: "ಒಟ್ಟು ಪ್ರೋಟೀನ್", fat: "ಒಟ್ಟು ಕೊಬ್ಬು" },
            8: { calorieTxt: "మొత్తం క్యాలరీ", carb: "మొత్తం కార్బోహైడ్రేట్", prot: "మొత్తం ప్రోటీన్", fat: "మొత్తం కొవ్వు" },
            9: { calorieTxt: "মোট ক্যালোরি", carb: "সম্পূর্ণ কার্বোহাইড্রেট", prot: "মোট প্রোটিন", fat: "মোট চর্বি" },
            10: { calorieTxt: "ਕੁੱਲ ਕੈਲੋਰੀ", carb: "ਕੁੱਲ ਕਾਰਬੋਹਾਈਡਰੇਟ", prot: "ਕੁੱਲ ਪ੍ਰੋਟੀਨ", fat: "ਕੁੱਲ ਚਰਬੀ" }
        }
        //let graphTime = '';
        let TData = [];
        //let TData = [{ cal:0,c:0,f:0,p:0 },{ cal:0,c:0,f:0,p:0 },{ cal:0,c:0,f:0,p:0 }];
        //let carbohydrate = 0;
        //let fat = 0;
        //let protein = 0;

        let logo;
        if (localStorage.getItem('diet_print_format') === 'yes') {
            if (localStorage.getItem('logo') !== '') {
                logo = appConstants.s3UploadUrl + localStorage.getItem('logo')
            } else {
                logo = "images/logo.png"
            }
        }
        //console.log(state,"stateeeee1")
        function formatTime(showTimeObj) {
            var hours = parseInt(showTimeObj.getHours(), 10);
            var min = parseInt(showTimeObj.getMinutes(), 10);
            var mid = 'am';
            if (hours === 0) {
                hours = 12;
            }
            else if (hours > 12) {
                hours = hours - 12;
                mid = 'pm';
            }

            if (hours < 10) {
                hours = "0" + hours;
            }

            if (min < 10) {
                min = "0" + min;
            }

            return hours + ":" + min + " " + mid;
        }

        function timeName(cell, row) {
            let txt = "<div>" + cell[1] + "</div>";
            if (cell[lang] !== undefined) {
                txt = "<div>" + cell[lang] + "</div>";
            }
            if (state) {
                if (state.dietTimePercent[row._id]) {
                    let showTime = state.dietTimePercent[row._id].time;
                    if (firstTime === 1) {

                        if (!state.mealTime) {
                            _this.props.first_mealtime(state.dietTimePercent[row._id].time);
                            state.mealTime = state.dietTimePercent[row._id].time;
                        }

                        showTime = state.mealTime;
                        let meailTimeObj = new Date(moment(state.mealTime, ["h:mm A"])); //moment().format("2019-01-01 "+state.mealTime);

                        let currentTimeObj = new Date(moment(state.dietTimePercent[row._id].time, ["h:mm A"]));
                        totalDiff = meailTimeObj - currentTimeObj;
                    }
                    else if (firstTime > 1) {
                        let currentTimeObj = new Date(moment(state.dietTimePercent[row._id].time, ["h:mm A"]));
                        let showTimeObj = new Date(currentTimeObj.getTime() + totalDiff);
                        showTime = formatTime(showTimeObj);
                    }
                    firstTime++;

                    txt += "<div>" + showTime + "</div>";

                    //graphTime = showTime;

                    let calorie = state.dietTimePercent[row._id].percent * state.selectCalorie / 100;

                    let orangePercent = state.dietTimePercent[row._id].percent;

                    let style = "background: -webkit-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: -moz-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: -ms-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";

                    txt += "<div style='" + style + "'>";
                    txt += calorie;
                    txt += " KCAL</div>";
                }
            }

            return <div dangerouslySetInnerHTML={{ __html: txt }} />;
        }

        function dietChatShow(cell) {
            let dietTxt = [];
            //let classValue = '';
            for (let x in options[cell]) {
                let row = '';
                if (options[cell] && options[cell].length > 0) {
                    if (!TData[x]) {
                        TData[x] = { cal: 0, c: 0, f: 0, p: 0 };
                    }
                    TData[x]['cal'] = (TData[x].cal ? TData[x].cal : 0) + options[cell][x].calorie;
                    TData[x]['c'] = (TData[x].c ? TData[x].c : 0) + options[cell][x].carbohydrate;
                    TData[x]['f'] = (TData[x].f ? TData[x].f : 0) + options[cell][x].fat;
                    TData[x]['p'] = (TData[x].p ? TData[x].p : 0) + options[cell][x].protein;
                }

                if (options[cell] && options[cell].length >= 3) {
                    row = "<div class='dietWidth1'>";
                    row += options[cell][x].foodText;
                    row += "</div>";

                } else if (options[cell] && options[cell].length === 2) {
                    row = "<div class='dietWidth2'>";
                    row += options[cell][x].foodText;
                    row += "</div>";
                } else {
                    row = "<div>";
                    row += options[cell][x].foodText;
                    row += "</div>";
                }

                dietTxt.push(row);
            }

            return <div style={{ "padding": "0", "margin": "0" }} dangerouslySetInnerHTML={{ __html: dietTxt.join("<div class='OR'>OR</div>") }} />;
        }

        let lastTime = "";
        for (const row of state.timeList) {
            if (options[row._id]) {
                lastTime = row._id;
            }
        }

        function getTotalCalorieText() {
            let txt = "Total Calorie"
            if (state?.dietLanguage)
                txt = objCalorie[state?.dietLanguage]["calorieTxt"]

            return <div>{txt}<br />
                {state?.selectCalorie} KCAL
            </div>
        }

        return (

            <div>
                {/* {console.log(state,"stateeeee3")}     */}
                <div id="printable1" className="dietChartTable">
                    <span className="visit_date">Visit Date: {moment(state.shareDate).format('DD-MM-YYYY')}</span>
                    <div className="only-print" hidden={state.printHeader}>

                        <h1>
                            <center>
                                <span><img src={logo} alt="Logo" width="auto" height="25" /></span>
                                <span className="logoname"> {state.clinicName}</span>
                            </center>
                        </h1>
                        <div><center>{state.clinicAddress} . Ph. : {state.clinicPhone} · {state.clinicEmail}</center></div>
                    </div>
                    <table className="table pt-info-box">
                        <tr>
                            <th style={{ width: "10%" }}>Name</th>
                            <th style={{ width: '90%' }}>{state.patient.name + "-" + state.patient.patientId}&nbsp;&nbsp;&nbsp;|<span> Height: {state.patient.height}</span> |<span> Weight: {state.patient.weight}</span> |<span> BMI: {state.patient.bmi}</span> |<span> Age: {state.patient.age}</span></th>
                        </tr>
                    </table>
                    <table className="table">
                        <tr>
                            <th style={{ width: '10%' }}>Time</th>
                            <th style={{ width: '45%' }}>{state.treatmentName}</th>
                            <th style={{ width: '45%' }}>{state.selectCalorie} KCAL</th>
                        </tr>
                    </table>
                    <table className="table ww-b">
                        {state.timeList.map(row => {
                            if (options[row._id] && options[row._id].length > 0) {

                                return <tr hidden={!row.show} key={row._id}>
                                    <td style={{ width: "10%", textAlign: "right", paddingRight: "5px" }}>{timeName(row.name, row)}</td>
                                    <td className="dietChart none-b" style={{ width: '90%' }}>{dietChatShow(row._id)}
                                        {/* {lastTime === row._id ?
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                {TData.map(item => {
                                                    return <div className={TData.length === 3 ? 'cald3' : 'cald2'}>Carb: {item.c.toFixed(2)}({Math.round((item.c * 400) / item.cal)}) Prot: {item.p.toFixed(2)}({Math.round((item.p * 400) / item.cal)}) Fat: {item.f.toFixed(2)}({Math.round((item.f * 900) / item.cal)})</div>
                                                })}
                                            </div> : <></>
                                        } */}
                                    </td>
                                </tr>
                            } else { return ''; }
                        })}
                        <tr>
                            <td style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }}>{getTotalCalorieText()}</td>
                            <td style={{ fontWeight: "bold", fontSize: "14px", paddingLeft: "7px" }} className="get-diet-details">
                                {TData.map((item, idx) => {
                                    return <>
                                        <div className="get-diet-details-inner-div" >
                                            <div style={{ paddingLeft: '0px' }}>{state?.dietLanguage != undefined ? objCalorie[state?.dietLanguage]["carb"] : objCalorie[1]["carb"]}: <span style={{ fontWeight: "300" }}>{item.c.toFixed(2)}({Math.round((item.c * 400) / item.cal)}%)</span></div>
                                            <div style={{ paddingLeft: '0px' }}>{state?.dietLanguage != undefined ? objCalorie[state?.dietLanguage]["prot"] : objCalorie[1]["prot"]}: <span style={{ fontWeight: "300" }}>{item.p.toFixed(2)}({Math.round((item.p * 400) / item.cal)}%)</span></div>
                                            <div style={{ paddingLeft: '0px' }}>{state?.dietLanguage != undefined ? objCalorie[state?.dietLanguage]["fat"] : objCalorie[1]["fat"]}: <span style={{ fontWeight: "300" }}>{item.f.toFixed(2)}({Math.round((item.f * 900) / item.cal)}%)</span></div>
                                        </div>
                                    </>
                                })
                                }
                            </td>

                        </tr>
                    </table>
                    <ul style={{ 'padding-top': '30px' }}>

                        {state.suggestions.map(row => {
                            if (row !== 'hindi' && row !== 'english2hhh') {
                                return row === "" ? "" : <li key={row}>{row}</li>
                            } else { return ''; }
                        })}
                    </ul>

                </div>
                {/* {console.log(state, "stateeeee4")} */}
                <div className="graphBox">
                    <Form horizontal>
                        <Row>
                            <Col sm={12} className="mange-spctop graph-box">
                                <Row>{state?.mealTime &&
                                    <iframe src={appConstants.paAppURL + "diet_graph?id=" + state.treatmentId + "&calorie=" + state.selectCalorie + "&lang=" + state.dietLanguage + "&time=" + state.mealTime} width="100%" height="600px" title="deit graph">
                                    </iframe>}
                                </Row><br></br>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div style={{ 'display': 'none' }}><PrintDietChart printData={state} logo={logo} timeName={this.timeName} dietChatShow={this.dietChatShow} /> </div>


            </div>
        );
    }
}

export default DietChartTable;

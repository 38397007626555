/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';
import * as API from '../../../../api/emr'


const AddEditLabTestRange = ({ testRangeData, mode, testReportId, onDismiss, labTestList }) => {
    let history = useHistory();

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)



    const inputHandler = (e) => {
        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        // if (post.order && post.order != '') {
        //     errArr.order = '';
        // } else { errArr.order = 'error'; errflag = false; }

        // if (post.name && post.name != '') {
        //     errArr.name = '';
        // } else { errArr.name = 'error'; errflag = false; }

        // if (post.unit && post.unit != '') {
        //     errArr.unit = '';
        // } else { errArr.unit = 'error'; errflag = false; }

        // if (post.range && post.range != '') {
        //     errArr.range = '';
        // } else { errArr.range = 'error'; errflag = false; }


        setErrors({ ...errArr });
        return errflag;
    }


    useEffect(() => {
        //console.log(mode, testRangeData, "props")

        let saveFormData = formData;
        // saveFormData["rangeFrom"] = "";
        // saveFormData["rangeTo"] = "";

        if (mode === "edit" || mode === "addAdmin") {
            //setMode("edit");
            saveFormData["rangeFrom"] = testRangeData.rangeFrom;
            saveFormData["rangeTo"] = testRangeData.rangeTo;
            // saveFormData["unit"] = testRangeData.unit;
            saveFormData["ageFrom"] = testRangeData.ageFrom;
            saveFormData["ageTo"] = testRangeData.ageTo;
            saveFormData["gender"] = testRangeData.gender
            saveFormData["ageType"] = testRangeData.ageType


            setFormData({ ...formData, ...saveFormData });
        }

    }, [])

    const submitHandler = async (event) => {
        event.preventDefault();

        if (validate()) {
            setIsLoading(false);
            let sentData = new FormData();
            if (formData.rangeFrom != undefined)
                sentData.append('rangeFrom', formData.rangeFrom)
            else sentData.append('rangeFrom', "")

            if (formData.rangeTo != undefined)
                sentData.append('rangeTo', formData.rangeTo)
            else sentData.append('rangeTo', "")

            if (formData.ageFrom != undefined)
                sentData.append('ageFrom', formData.ageFrom)
            else sentData.append('ageFrom', "")

            if (formData.ageTo != undefined)
                sentData.append('ageTo', formData.ageTo)
            else sentData.append('ageTo', "")

            // if (formData.unit != undefined)
            //     sentData.append('unit', formData.unit)
            // else sentData.append('unit', "")

            if (formData.gender != undefined)
                sentData.append('gender', formData.gender)
            else sentData.append('gender', "")

            if (formData.ageType != undefined)
                sentData.append('ageType', formData.ageType)
            else sentData.append('ageType', "")

            let resp = ""
            if (mode === "add" || mode === "addAdmin") {
                sentData.append('testReportId', testReportId)
                sentData.append('clinicId', localStorage.getItem("clinicId"))
                if (mode === "addAdmin")
                    sentData.append('adminAddedId', testRangeData._id)
                resp = await API.addLabTestRange(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    let tempData = { ...resp.data.data }
                    onDismiss("Test Range Added Successfully", tempData)
                    //history.push({ pathname: "/admin/lab-test-list", from: "add" })
                }
            } else {
                sentData.append('id', testRangeData._id)
                sentData.append('adminAddedId', testRangeData.adminAddedId)
                let tempData = { ...formData }
                tempData._id = testRangeData._id
                tempData.adminAddedId = testRangeData.adminAddedId
                resp = await API.updateLabTestRange(sentData);

                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    onDismiss("Test Range Updated Successfully", tempData)
                }
            }
            setIsLoading(false);
        }
    }


    return (
        <div className="">
            <Form horizontal className="add-supportsystem_form">
                <FormGroup>
                    <Col sm={12}>
                        <Row>
                            <Col md={6}>
                                <ControlLabel>Range From</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="rangeFrom"
                                    id="rangeFrom"
                                    placeholder="Range From"
                                    onChange={e => { inputHandler(e); }}
                                    autocomplete="off"
                                    value={formData.rangeFrom ? formData.rangeFrom : ''}
                                    className={'insulin-input ' + (errors.rangeFrom ? errors.rangeFrom : '')}
                                />
                                {/* {isSpecialCharacter &&
                                    <span className="star">Special Characters Are Not Allowed</span>
                                } */}

                            </Col>
                            <Col md={6}>
                                <ControlLabel>Range To</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="rangeTo"
                                    id="rangeTo"
                                    placeholder="Range To"
                                    onChange={e => { inputHandler(e); }}
                                    autocomplete="off"
                                    value={formData.rangeTo ? formData.rangeTo : ''}
                                    className={'insulin-input ' + (errors.rangeTo ? errors.rangeTo : '')}
                                />
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={12}>
                        <Row>
                            <Col md={6}>
                                <ControlLabel>Age From</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="ageFrom"
                                    id="ageFrom"
                                    placeholder="Age From"
                                    onChange={e => { inputHandler(e); }}
                                    autocomplete="off"
                                    value={formData.ageFrom ? formData.ageFrom : ''}
                                    className={'insulin-input ' + (errors.ageFrom ? errors.ageFrom : '')}
                                />
                            </Col>
                            <Col md={6}>
                                <ControlLabel>Age To</ControlLabel>
                                <FormControl
                                    type="text"
                                    name="ageTo"
                                    id="ageTo"
                                    placeholder="Age To"
                                    onChange={e => { inputHandler(e); }}
                                    autocomplete="off"
                                    value={formData.ageTo ? formData.ageTo : ''}
                                    className={'insulin-input ' + (errors.ageTo ? errors.ageTo : '')}
                                />
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={12}>
                        <Row>
                            <Col md={6}>
                                <ControlLabel>Age Type</ControlLabel>
                                <select value={formData.ageType} name="ageType" onChange={e => { inputHandler(e); }} id="ageType" className='form-control'>
                                    <option value="">Select</option>
                                    <option value="month">Month</option>
                                    <option value="year">Year</option>
                                </select>
                            </Col>
                            <Col md={6}>
                                <ControlLabel>Gender</ControlLabel>
                                <select value={formData.gender} name="gender" onChange={e => { inputHandler(e); }} id="gender" className='form-control'>
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <ControlLabel className="col-sm-2">
                    </ControlLabel>
                    <Col sm={12} className="btn_wrap">
                        <Row>
                            <Col md={12}>
                                <button type="button" className="btn-fill btn-wd btn btn-primary" onClick={e => { submitHandler(e) }}>Submit</button>&nbsp;

                            </Col>
                        </Row>
                    </Col>
                </FormGroup>
            </Form>

        </div>
    )
}

export default AddEditLabTestRange
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/comment'
/* Add Comment list and Add form */

export const addCommentRequest = () => ({
    type: actionTypes.GET_ADD_COMMENT_REQUEST
})
export const addCommentSuccess = (response) => ({
    type: actionTypes.GET_ADD_COMMENT_SUCCESS,
    payload: {
        response,
    }
})
export const addCommentError = (error) => ({
    type: actionTypes.GET_ADD_COMMENT_ERROR,
    payload: {
        error,
    }
})
export const addCommentAction = (data) => {
    return dispatch => {
        dispatch(addCommentRequest())
        return API.addComment(data)
            .then(response => {

                dispatch(addCommentSuccess(response.data))
            })
            .catch(error => {
                dispatch(addCommentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Comment list and Add form */
export const updateCommentRequest = () => ({
    type: actionTypes.GET_UPDATE_COMMENT_REQUEST
})
export const updateCommentSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_COMMENT_SUCCESS,
    payload: {
        response,
    }
})
export const updateCommentError = (error) => ({
    type: actionTypes.GET_UPDATE_COMMENT_ERROR,
    payload: {
        error,
    }
})
export const updateCommentAction = (data) => {
    return dispatch => {
        dispatch(updateCommentRequest())

        return API.updateComment(data)
            .then(response => {

                dispatch(updateCommentSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateCommentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Comment list  */
export const CommentListRequest = () => ({
    type: actionTypes.GET_COMMENT_LIST_REQUEST
})
export const CommentListSuccess = (response) => ({
    type: actionTypes.GET_COMMENT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const CommentListError = (error) => ({
    type: actionTypes.GET_COMMENT_LIST_ERROR,
    payload: {
        error
    }
})
export const commentListAction = (data, search) => {
    return dispatch => {
        dispatch(CommentListRequest());
        return API.commentList(data)
            .then(response => {
                dispatch(CommentListSuccess(response.data))
            })
            .catch(error => {
                dispatch(CommentListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Comment Status Change  */
export const CommentChangeStatusRequest = () => ({
    type: actionTypes.GET_COMMENT_CHANGE_STATUS_REQUEST
})
export const CommentChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_COMMENT_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const CommentChangeStatusError = (error) => ({
    type: actionTypes.GET_COMMENT_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const commentChangeStatusAction = (data) => {

    return dispatch => {
        dispatch(CommentChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeCommentStatus(FormData)
            .then(response => {

                dispatch(CommentChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(CommentChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


export const AutoSelectStatusRequest = () => ({
    type: actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_REQUEST
})
export const AutoSelectStatusSuccess = (response) => ({
    type: actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const AutoSelectStatusError = (error) => ({
    type: actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_ERROR,
    payload: {
        error
    }
})
export const autoSelectStatusAction = (data) => {

    return dispatch => {
        dispatch(AutoSelectStatusRequest())
        if (data.auto_select) {
            data.auto_select = false;
        } else {
            data.auto_select = true;
        }
        const FormData = {
            id: data._id,
            auto_select: data.auto_select
        }
        return API.changeAutoSelectStatus(FormData)
            .then(response => {

                dispatch(AutoSelectStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(AutoSelectStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import {
  userListAction,
  getSpecializationsAction,
  updateUserAction,
  changeUserStatusAction,
  sendMailAction,
} from "Front/actions/master";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import moment from "moment";
import { titleOptions } from "Front/variables/Variables.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { changeUserPasswordAction } from "Front/actions/settings";
import { appConstants } from "Front/_constants/app.constants.js";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

var isUserUpdated = false;

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  email: "required|email",
  title: "required",
  first_name: "required",
  phone: "required|digits_between:10,12",
  status: "required",
};
let mess = {
  required: "This field is required",
};
let validation = [];

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formArr: [],
      userId: "",
      UserList: [],
      SpecializationList: [],
      isLoading: true,
      alert: null,
      _notificationSystem: null,
      show: false,
      editUserModal: false,
      changePasswordUserModal: false,
      sendMailUserModal: false,
      email_id: "",
      formData: {
        id: "",
        title: "",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        degree: "",
        status: "",
      },
      multipleSelect: [null],
      specializationObj: [],
      successMsg: "User sucessfully updated.",

      passFormData: {
        new_password: "",
        confirm_password: "",
      },
      passValidation: {
        new_password: false,
        confirm_password: false,
        confirm_password_match: false,
        character_limit: false,
      },
      emailError: false,
      emailErrorMsg: "",
      alert: null,
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.successDelete = this.successDelete.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  checkValidation() {
    let field1 = this.state.passValidation;
    let valid = 1;

    if (this.state.passFormData.new_password === "") {
      field1["new_password"] = true;
      valid = 0;
    } else {
      field1["new_password"] = false;
    }

    if (this.state.passFormData.confirm_password === "") {
      field1["confirm_password"] = true;
      valid = 0;
    } else {
      field1["confirm_password"] = false;
    }

    if (
      this.state.passFormData.new_password !==
      this.state.passFormData.confirm_password &&
      this.state.passFormData.confirm_password !== ""
    ) {
      valid = 0;
      field1["confirm_password_match"] = true;
    } else {
      field1["confirm_password_match"] = false;
    }

    if (
      this.state.passFormData.new_password.length < 6 &&
      this.state.passFormData.new_password !== ""
    ) {
      valid = 0;
      field1["character_limit"] = true;
    } else if (
      this.state.passFormData.new_password !== "" &&
      !this.state.passFormData.new_password.match(
        appConstants.PswValidCharecters
      )
    ) {
      valid = 0;
      field1["character_limit"] = true;
    } else {
      field1["character_limit"] = false;
    }

    this.setState({ passValidation: field1 });

    return valid;
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.userListAction(this.props);
    //this.props.getSpecializationsAction(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isSendMail !== this.props.isSendMail &&
      this.props.isSendMail === false
    ) {
      this.sendMailAlert();
    }

    if (nextProps.isUserList !== this.props.isUserList) {
      let userArr = [];

      if (
        nextProps.UserList.data &&
        nextProps.UserList.data.data &&
        nextProps.UserList.data.data.length > 0
      ) {
        nextProps.UserList.data.data.map((v) => {
          if (v._id !== localStorage.getItem("_id")) {
            userArr.push(v);
          }
        });
      }

      //  this.setState({ UserList: nextProps.UserList.data.data });
      this.setState({ UserList: userArr });
    }

    if (nextProps.isChangeStatus !== this.props.isChangeStatus) {
      this.successDelete();
      this.props.userListAction(this.props);
    }

    if (nextProps.isUpdateUser !== this.props.isUpdateUser) {
      this.setState({
        editUserModal: false,
        successMsg: "User sucessfully updated.",
      });
      this.props.userListAction(this.props);
      if (isUserUpdated) {
        this.successAlert("User sucessfully updated.");
        isUserUpdated = false;
      }

    }

    if (nextProps.isSpecialization !== this.props.isSpecialization) {
      let specializationObj = [];
      for (let x in nextProps.SpecializationList.data) {
        specializationObj[nextProps.SpecializationList.data[x]._id] =
          nextProps.SpecializationList.data[x];
      }

      this.setState({
        SpecializationList: nextProps.SpecializationList.data,
        specializationObj: specializationObj,
      });
    }

    if (nextProps.isChangeUserPassword !== this.props.isChangeUserPassword) {
      let params = { userId: this.state.passFormData.id };
      appConstants.socket.emit("logout", params);

      this.props.userListAction(this.props);
      this.successAlert();
    }

    if (
      nextProps.isChangeUserPasswordError !==
      this.props.isChangeUserPasswordError
    ) {
      this.errorAlert();
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  get_age_by_dob(cell, row, enumObject, rowIndex) {
    if (row.dob !== "") {
      var dob = moment(row.dob, "YYYY-MM-DD").subtract(1, "days");
      var now = moment(new Date()); //todays date
      var end = moment(dob); // another date
      var duration = moment.duration(now.diff(end));
      var years = duration.years();
      var months = duration.months();
      return years + (months > 0 ? "." + months : "");
    } else {
      return "";
    }
  }

  isApp(cell, row, enumObject, rowIndex) {
    if (row.app === 0) {
      return "";
    } else {
      return <i class="fa fa-check" aria-hidden="true"></i>;
    }
  }

  successDelete() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.state.successMsg}
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);


  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  warningWithConfirmMessage(action, id, e) {
    let msg = "";
    let successMsg = "";
    if (action === "active") {
      successMsg = "User sucessfully activated.";
      msg = "You want to enable this user!";
    } else {
      successMsg = "User sucessfully disabled.";
      msg = "You want to disable this user!";
    }
    this.setState({ successMsg: successMsg });

    const params = {
      status: action,
      id: id,
    };

    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.props.changeUserStatusAction(params)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          showCancel
        >
          {msg}
        </SweetAlert>
      ),
    });

  }

  editAction(rowData, e) {
    let formData = this.state.formData;
    formData["email"] = rowData.email;
    formData["title"] = rowData.title;
    formData["first_name"] = rowData.firstName;
    formData["last_name"] = rowData.lastName;
    formData["phone"] = rowData.phone;
    formData["degree"] = rowData.degree;
    formData["status"] = rowData.status;
    formData["id"] = rowData._id;

    this.setState({ editUserModal: true, formData: formData });
  }

  changePassword(rowData, e) {
    let passData = this.state.passFormData;
    passData["id"] = rowData._id;
    passData["new_password"] = "";
    passData["confirm_password"] = "";
    this.setState({ passFormData: passData, changePasswordUserModal: true });

    let passValidation = {
      new_password: false,
      confirm_password: false,
      confirm_password_match: false,
      character_limit: false,
    };
    this.setState({ passValidation: passValidation });
  }

  sendUserDetail(rowData, e) {
    this.setState({
      email_id: "",
      userId: rowData._id,
      sendMailUserModal: true,
      emailError: false,
    });
  }

  handleEmailChange(e) {
    e.preventDefault();
    this.setState({ emailError: false, emailErrorMsg: "" });
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.value.match(mailformat)) {
      this.setState({ email_id: e.target.value });
    } else {
      this.setState({ emailError: true, emailErrorMsg: "Incorrect email id!" });
    }
  }

  sendMail(evt) {
    this.setState({ emailError: false, emailErrorMsg: "" });
    evt.preventDefault();

    if (this.state.email_id != "") {
      let data = { email_id: this.state.email_id, userId: this.state.userId };
      this.props.sendMailAction(data);

      this.setState({ sendMailUserModal: false });
    } else {
      this.setState({
        emailError: true,
        emailErrorMsg: "Email Id is required.",
      });
    }
  }

  actionButton(cell, row, enumObject, rowIndex) {
    let icon = "";
    let style = "";
    let statusText = "";
    let action = "";

    if (row.status === "active") {
      icon = <i className="fa fa-check"></i>;
      style = "success";

      statusText = "Enable";
      //statusText = 'Disable'
      action = "inactive";
    } else {
      icon = <i className="fa fa-ban"></i>;
      style = "danger";

      statusText = "Disable";
      //statusText = 'Enable'
      action = "active";
    }

    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="status">{statusText}</Tooltip>}
        >
          <Button
            simple
            icon
            bsstyle={style}
            onClick={this.warningWithConfirmMessage.bind(this, action, row._id)}
          >
            {icon}
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit">Send</Tooltip>}
        >
          <Link
            to={{ pathname: ``, state: this.state.patient }}
            bsstyle="success"
            onClick={this.sendUserDetail.bind(this, row)}
          >
            <i className="fa fa-envelope"></i>
          </Link>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit">Edit</Tooltip>}
        >
          <Link
            to={{ pathname: ``, state: this.state.patient }}
            bsstyle="success"
            onClick={this.editAction.bind(this, row)}
          >
            <i className="fa fa-edit"></i>
          </Link>
        </OverlayTrigger>

        {/* <OverlayTrigger placement="top" overlay={<Tooltip id="edit">Edit</Tooltip>}>
                <Link to={{ pathname: ``, state: this.state.patient }} bsStyle="success" onClick={this.changePassword.bind(this, row)}><i className="fa fa-edit"></i></Link>
            </OverlayTrigger> */}
      </div>
    );
  }

  changePasswordAct(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <Link
          to={{ pathname: ``, state: this.state.patient }}
          bsstyle="success"
          onClick={this.changePassword.bind(this, row)}
        >
          User Password
        </Link>
      </span>
    );
  }

  serialNumber(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  handleChange(e) {
    e.preventDefault();

    let field = this.state.formData;

    field[e.target.name] = e.target.value;

    this.setState({ formData: field });
  }

  handleSpecializationsChange(value) {
    let multipleSelect = this.state.multipleSelect;

    multipleSelect = value;

    this.setState({ multipleSelect: multipleSelect });

    let formData = this.state.formData;

    let catArr = [];

    if (value && value.length) {
      value.map((key, i) => {
        catArr.push({ id: key.value, name: key.label });
        return "";
      });
    }

    formData.specializations = catArr;

    this.setState({ formData: formData });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  updateUser(evt) {
    evt.preventDefault();
    const _this = this;
    if (this.allValidate(false)) {
      if (!isUserUpdated) {
        _this.props.updateUserAction(this.state.formData);
        isUserUpdated = true;
      }
    }
    //return validation.errors()
  }

  handlePassChange(e) {
    e.preventDefault();
    let field = this.state.passFormData;
    let field2 = this.state.passValidation;
    field[e.target.name] = e.target.value;
    field2[e.target.name] = false;

    this.setState({
      passFormData: field,
      passValidation: field2,
    });

    this.checkValidation();
  }

  updatePassword(evt) {
    evt.preventDefault();
    let valid = this.checkValidation();
    if (valid === 1) {
      this.props.changeUserPasswordAction(this.state.passFormData);
    }
    this.setState({ changePasswordUserModal: false });
  }

  successAlert(msg) {

    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       Password changed successfully!
    //     </SweetAlert>
    //   ),
    //   passFormData: {
    //     new_password: "",
    //     confirm_password: "",
    //   },
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 3000);
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  errorAlert() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          //onConfirm={() => this.hideAlert2()}
          //onCancel={() => this.hideAlert2()}
          confirmBtnBsStyle="info"
        >
          Wrong old Password or something went wrong :)
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert2();
    }, 3000);
  }

  sendMailAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          //onConfirm={() => this.hideAlert()}
          //onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          User Password detail successfully sent!
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  displayName(cell, row, enumObject, rowIndex) {
    return row.title + " " + row.firstName + " " + row.lastName;
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    let SpecializationsOptions = [];

    if (this.state.SpecializationList && this.state.SpecializationList.length) {
      this.state.SpecializationList.map((key, i) => {
        SpecializationsOptions.push({ value: key._id, label: key.name });
        return "";
      });
    }

    const options = {
      page: 1, // which page you want to show as default
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      paginationPosition: "bottom", // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      noDataText: this._setTableOption(),
    };
    return (
      <div className="settings-userlist list_dropss">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        {/* <h4 className='title'>User List</h4> */}
        <div className="fresh-datatables table-responsive row">
          <BootstrapTable
            deleteRow={false}
            data={this.state.UserList}
            search={true}
            multiColumnSearch={true}
            pagination={false}
            options={options}
            striped
            hover
            condensed
            scrollTop={"Bottom"}
          >
            <TableHeaderColumn
              hidden={true}
              tdAttr={{ "data-attr": "_id" }}
              dataField="_id"
              isKey
              searchable={false}
            >
              Id
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "40px", minWidth: "40px" }}
              tdStyle={{ width: "40px", minWidth: "40px" }}
              style={"minw"}
              tdAttr={{ "data-attr": "#" }}
              dataField="sn"
              dataFormat={this.serialNumber.bind(this)}
            >
              #
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{
                width: "100px",
                minWidth: "100px",
                textTransform: "capitalize",
              }}
              tdAttr={{ "data-attr": "Name" }}
              dataField=""
              dataFormat={this.displayName.bind(this)}
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{ width: "100px", minWidth: "100px" }}
              tdAttr={{ "data-attr": "User Name" }}
              dataField="userName"
              dataSort={true}
            >
              User Name
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{ width: "100px", minWidth: "100px" }}
              tdAttr={{ "data-attr": "Password" }}
              dataField="show_password"
              dataSort={true}
            >
              Password
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{
                width: "100px",
                minWidth: "100px",
                textTransform: "capitalize",
              }}
              tdAttr={{ "data-attr": "User Type" }}
              dataField="userType"
              dataSort={true}
            >
              User Type
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{ width: "100px", minWidth: "100px" }}
              tdAttr={{ "data-attr": "Phone No." }}
              dataField="phone"
            >
              Phone No.
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "120px", minWidth: "80px" }}
              tdStyle={{ width: "120px", minWidth: "80px" }}
              tdAttr={{ "data-attr": "Email id" }}
              dataField="email"
            >
              Email id
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "100px", minWidth: "100px" }}
              tdStyle={{ width: "100px", minWidth: "100px" }}
              tdAttr={{ "data-attr": "Degree" }}
              dataField="degree"
            >
              Qualification
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "80px", minWidth: "80px" }}
              tdStyle={{ width: "80px", minWidth: "80px" }}
              tdAttr={{ "data-attr": "STATUS" }}
              dataField="status"
            >
              STATUS
            </TableHeaderColumn>
            <TableHeaderColumn
              thStyle={{ width: "140px", minWidth: "140px" }}
              tdStyle={{ width: "140px", minWidth: "140px" }}
              tdAttr={{ "data-attr": "  change password" }}
              dataField="change password"
              dataFormat={this.changePasswordAct.bind(this)}
            >
              CHANGE PASSWORD
            </TableHeaderColumn>

            <TableHeaderColumn
              thStyle={{ width: "80px", minWidth: "80px" }}
              tdStyle={{ width: "80px", minWidth: "80px" }}
              tdAttr={{ "data-attr": "Action" }}
              dataFormat={this.actionButton.bind(this)}
            >
              Action
            </TableHeaderColumn>
          </BootstrapTable>
        </div>

        <Modal
          show={this.state.editUserModal}
          onHide={() => this.setState({ editUserModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit User
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Form horizontal>
              <Row>
                <Col sm={6} md={5} mdOffset={1}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Title <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={8}>
                      <select
                        className="form-control"
                        name="title"
                        onChange={(event) => this.handleChange(event)}
                        value={this.state.formData.title}
                      >
                        <option value="">Select title</option>
                        {titleOptions.map(function (item) {
                          return (
                            <option key={item.value} value={item.label}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                      <span className="errorMsg">
                        {this.state.formArr.title &&
                          validation.errors.first("title")}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      First Name <span className="star">*</span>
                    </Col>

                    <Col sm={12} md={8}>
                      <FormControl
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={this.state.formData.first_name}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.first_name &&
                          validation.errors.first("first_name")}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Last Name <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={8}>
                      <FormControl
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={this.state.formData.last_name}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.last_name &&
                          validation.errors.first("last_name")}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Email <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={8}>
                      <FormControl
                        type="text"
                        name="email"
                        id="email"
                        value={this.state.formData.email}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.email &&
                          validation.errors.first("email")}
                      </span>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={6} md={5}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Phone <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={8}>
                      <FormControl
                        type="text"
                        name="phone"
                        maxLength={12}
                        id="phone"
                        value={this.state.formData.phone}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.phone &&
                          validation.errors.first("phone")}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Qualification
                    </Col>
                    <Col sm={12} md={8}>
                      <FormControl
                        type="text"
                        name="degree"
                        id="degree"
                        value={this.state.formData.degree}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.degree &&
                          validation.errors.first("degree")}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Status <span className="star">*</span>
                    </Col>
                    <Col sm={12} md={8}>
                      <select
                        className="form-control"
                        name="status"
                        id="status"
                        value={this.state.formData.status}
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      >
                        <option value="active">Enable</option>
                        <option value="inactive">Disable</option>
                      </select>
                      <span className="errorMsg">
                        {this.state.formArr.status &&
                          validation.errors.first("status")}
                      </span>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={11} mdOffset={1}>
                  <FormGroup>
                    <Col sm={12}>
                      <button
                        type="button"
                        className="btn-fill btn-wd btn btn-primary"
                        onClick={this.updateUser.bind(this)}
                      >
                        Save
                      </button>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn pull-right btn-secondary"
              onClick={() => this.setState({ editUserModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.changePasswordUserModal}
          onHide={() => this.setState({ changePasswordUserModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Change User Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Form horizontal>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      New Password <span className="star">*</span>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="password"
                        minLength="6"
                        maxLength="12"
                        name="new_password"
                        id="new_password"
                        value={this.state.passFormData.new_password}
                        onChange={(e) => {
                          this.handlePassChange(e);
                        }}
                      />
                      <span
                        className="errorMsg"
                        style={{
                          display: this.state.passValidation.new_password
                            ? "block"
                            : "none",
                        }}
                      >
                        This is required field
                      </span>
                      <span
                        className="errorMsg"
                        style={{
                          display: this.state.passValidation.character_limit
                            ? "block"
                            : "none",
                        }}
                      >
                        Wrong password format!
                      </span>
                      <div>
                        [6 to 12 characters which contain at least one lowercase
                        letter, one uppercase letter, one numeric digit, and one
                        special character.]
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Confirm New Password <span className="star">*</span>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="password"
                        minLength="6"
                        maxLength="12"
                        name="confirm_password"
                        id="confirm_password"
                        value={this.state.passFormData.confirm_password}
                        onChange={(e) => {
                          this.handlePassChange(e);
                        }}
                      />
                      <span
                        className="errorMsg"
                        style={{
                          display: this.state.passValidation.confirm_password
                            ? "block"
                            : "none",
                        }}
                      >
                        This is required field
                      </span>
                      <span
                        className="errorMsg"
                        style={{
                          display: this.state.passValidation
                            .confirm_password_match
                            ? "block"
                            : "none",
                        }}
                      >
                        Password not matching
                      </span>
                      <div>
                        [6 to 12 characters which contain at least one lowercase
                        letter, one uppercase letter, one numeric digit, and one
                        special character.]
                      </div>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={3}></Col>
                    <Col sm={6}>
                      <button
                        type="submit"
                        disabled={
                          this.state.passValidation.confirm_password_match ||
                          this.state.passValidation.confirm_password ||
                          this.state.passValidation.new_password ||
                          this.state.passValidation.character_limit
                        }
                        onClick={this.updatePassword.bind(this)}
                        className="btn-fill btn-wd btn btn-primary"
                      >
                        Save
                      </button>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-secondary pull-right"
              onClick={() => this.setState({ changePasswordUserModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.sendMailUserModal}
          onHide={() => this.setState({ sendMailUserModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Send user Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Form horizontal>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Email-Id<span className="star">*</span>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="email"
                        name="email_Id"
                        id="email_Id"
                        value={this.state.email_Id}
                        onChange={(e) => {
                          this.handleEmailChange(e);
                        }}
                      />
                      <span
                        className="errorMsg"
                        style={{
                          display: this.state.emailError ? "block" : "none",
                        }}
                      >
                        {this.state.emailErrorMsg}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={3}></Col>
                    <Col sm={6}>
                      <button
                        type="submit"
                        disabled={this.state.emailError}
                        onClick={this.sendMail.bind(this)}
                        className="btn-fill btn-wd btn btn-primary"
                      >
                        Send
                      </button>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn pull-right"
              onClick={() => this.setState({ sendMailUserModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUserList: state.master.isUserList,
    isUserListError: state.master.isUserListError,
    UserList: state.master.UserList,

    isSpecialization: state.master.isSpecialization,
    isSpecializationError: state.master.isSpecializationError,
    SpecializationList: state.master.SpecializationList,

    isUpdateUser: state.master.isUpdateUser,
    isUpdateUserError: state.master.isUpdateUserError,
    UpdateUser: state.master.UpdateUser,

    isSendMail: state.master.isSendMail,
    isSendMailError: state.master.isSendMailError,
    SendMail: state.master.SendMail,

    isChangeStatus: state.master.isChangeStatus,
    isChangeStatusError: state.master.isChangeStatusError,
    ChangeStatus: state.master.ChangeStatus,

    isChangeUserPassword: state.settings.isChangeUserPassword,
    isChangeUserPasswordError: state.settings.isChangeUserPasswordError,
    ChangeUserPassword: state.settings.ChangeUserPassword,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    userListAction,
    getSpecializationsAction,
    updateUserAction,
    changeUserStatusAction,
    changeUserPasswordAction,
    sendMailAction,
  })(UserList)
);

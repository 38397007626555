/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
  OverlayTrigger,
  Tooltip, Nav, MenuItem, Modal, Row, Col, FormGroup
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { addVisitAction } from 'Front/actions/patient';
import { patientStatusChangeAction } from 'Front/actions/home';
import { confirmAlert } from 'react-confirm-alert';
import { appConstants } from 'Front/_constants/app.constants.js';
import moment from 'moment';
import PatientRegistration from "Front/views/Home/PatientRegistrationPopup.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

let errorMsg = '';
let isOnline = false;
var addVisitCalled = false;
class DropDownSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        patient_id: "",
        height: "",
        weight: "",
        remark: "",
        status: (this.props.patient && this.props.patient.status),
        id: (this.props.patient && this.props.patient.id),
        econsultation: false,
      },
      ptStatus: 'active',
      listStatusapi: false,
      errorMsgModal: false,
      _notificationSystem: null,
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.isPatientUpdate = this.isPatientUpdate.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.setState({ ptStatus: this.props.patient && this.props.patient.status });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isAddVisit !== this.props.isAddVisit && addVisitCalled) {
      addVisitCalled = false;
      let url = "/";
      if (this.props.history?.location?.pathname === "/") url = "/dashboard";
      this.props.history.push({ pathname: url, econsultation: isOnline });
      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem('clinicId'),
        }
        appConstants.socket.emit('updateTaskScreen', params);

      }, 1000);
    }

    if (nextProps.isAddVisitError !== this.props.isAddVisitError) {
      if (nextProps.addVisit.errors) {
        nextProps.addVisit.errors.map((key, i) => {
          return this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
        });
      }
    }

    let status = this.state.formData.status;
    if (nextProps.isPatientStatus !== this.props.isPatientStatus) {
      this.setState({ ptStatus: status });
    }
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.successAlert("Patient Successfully Updated");
    //  this.props.patientUpdate(this.props.patient)
  }

  successAlert(msg) {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  addVisit(patient) {
    let field = this.state.formData;
    field['patient_id'] = patient.id;
    field['height'] = patient.height ? patient.height : '';
    field['weight'] = patient.weight ? patient.weight : '';
    field['remark'] = '';
    this.setState({ formData: field });

    if (patient.addVisit && (moment(patient.addVisit).startOf('day').format() === moment().startOf('day').format())) {
      errorMsg = "This patient is already in today's list.";
      this.setState({ errorMsgModal: !this.state.errorMsgModal })
    } else if (moment(patient.createdAt).startOf('day').format() === moment().startOf('day').format()) {
      errorMsg = "This patient is already in today's list.";
      this.setState({ errorMsgModal: !this.state.errorMsgModal })
    } else {
      errorMsg = '';
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div class="react-confirm-alert-overlay undefined">
              <div class="react-confirm-alert">
                <div class="react-confirm-alert-body w-500">
                  <Row>
                    <Col sm={12}>
                      <h1>Confirm to add visit</h1>
                      <div>
                        <h5> Are you sure to do this.</h5>
                        {this.state.formData.econsultation}
                        <FormGroup className="e-consultation">
                          <form className="consultation-radio">
                            <input type="radio" id="OPD-Consultation" name="econsultation" value={false} onClick={e => { this.handleChange(e); }} />
                            <label for="OPD-Consultation">OPD Consultation</label><br />
                            <input type="radio" id="online-consultation" name="econsultation" value={true} onClick={e => { this.handleChange(e); }} />
                            <label for="online-consultation">Online Consultation</label>
                          </form>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col sm={12}>
                      <FormGroup>
                        <div class="react-confirm-alert-button-group">
                          <button onClick={() => {
                            this.props.addVisitAction(this.state.formData)
                            this.resetFormData()
                            onClose();
                            addVisitCalled = true;
                          }} >Yes</button>
                          <button onClick={onClose}>No</button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          );
        },
        title: 'Confirm to add visit',
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.addVisitAction(this.state.formData)
          },
          {
            label: 'No',
            onClick: () => this.resetFormData()
          }
        ]
      })
      if (field.econsultation === false)
        document.getElementById("OPD-Consultation").checked = true;
    }
  }

  // editButton(cell, row, enumObject, rowIndex) {
  //   return (<p><Link to={{ pathname: `update-specialization/`, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
  //     &nbsp;&nbsp; &nbsp;&nbsp;
  //     <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
  //   </p>)
  // }

  editButton(aa) {
    return (<p> <Link to={{ pathname: `/patient-edit`, state: { aa } }} bsStyle="success"><i className="fa fa-edit"></i></Link>
    </p>)
  }

  changePatientStatus(patient) {
    let field = this.state.formData;

    let newstatus = {};
    if (patient.status == 'active') {
      newstatus = 'inactive';
    } else {
      newstatus = 'active';
    }

    field['patient_id'] = patient.id;
    field['status'] = newstatus;
    this.setState({ formData: field, listStatusapi: true });
    confirmAlert({
      title: 'Confirm to change status',
      message: 'Are you sure you want to change the status?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.patientStatusChangeAction(this.state.formData)
        },
        {
          label: 'No',
          onClick: () => this.resetFormData()
        }
      ]
    })
  }

  resetFormData() {
    let field = this.state.formData;
    field['patient_id'] = '';
    field['height'] = '';
    field['weight'] = '';
    field['remark'] = '';
    this.setState({ formData: field });
  }

  handleChange(e) {
    let { formData } = this.state;
    if (e.target.type === "radio") {
      if (e.target.value === "true") {
        document.getElementById("online-consultation").checked = true;
        document.getElementById("OPD-Consultation").checked = false;

      } else {
        document.getElementById("OPD-Consultation").checked = true;
        document.getElementById("online-consultation").checked = false;

      }
    }
    formData[e.target.name] = (e.target.type === 'checkbox') ? !formData[e.target.name] : e.target.value;

    if (e.target.name === "econsultation") {
      if (e.target.value === "true") isOnline = true;
      if (e.target.value === "false") isOnline = false;
    }
    this.setState({ ...formData });
  };

  isPatientUpdate(data) {
    this.props.patientUpdate(data)
  }

  render() {
    return (<div className='d-flex align-items-center'>
      {this.state.alert}
      <NotificationSystem ref="notificationSystem" style={style} />
      <OverlayTrigger placement="top" overlay={<Tooltip id="status">Change Status</Tooltip>}>

        <Button simple icon bsStyle="status-color" onClick={this.changePatientStatus.bind(this, this.state.formData)}>
          <i className={(this.state.ptStatus === "active") ? ('fa fa-check') : ('fa fa-ban')} aria-hidden="true"></i>
        </Button>

      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="edit">Edit</Tooltip>}>
        {/* <Link to={{ pathname: `patient-edit`, state: this.props.patient }} bsStyle="success"><i className="fa fa-edit"></i></Link> */}
        <a onClick={() => this.setState({ patientModal: true })}>
          <i className="fa fa-edit"></i>
        </a>
      </OverlayTrigger>
      <Nav>
        <MenuItem eventKey={4.3} onClick={this.addVisit.bind(this, this.props.patient)}><i className="pe-7s-plus"></i> Add Visit</MenuItem>
      </Nav>

      <Modal
        className="pa-patient-registration"
        show={this.state.patientModal}
        onHide={() => this.setState({ patientModal: false })}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            Patient Registration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share card">
          <Row>
            <Col md={12}>
              <PatientRegistration
                onDismiss={this.onDismiss}
                patientData={this.props.patient}
                patientUpdate={(patient) => { this.isPatientUpdate(patient) }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row>
        <Col sm={12} className="pd00 error-modal">
          <Modal show={this.state.errorMsgModal} onHide={() => this.setState({ errorMsgModal: false })} dialogClassName="" className="Modal_downsearch">
            <Modal.Header closeButton className="bg-thumbcolor-box">
              <Modal.Title id="example-modal-sizes-title-lg">   </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Knowledge-Share">
              <Row className="modal_margin">
                <Col md={12}>
                  <div className="error-icon"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div><div className="err-text">{errorMsg}</div>
                </Col>
                <Col md={12}>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>

    </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addVisit: state.patient.addVisit,
    isAddVisit: state.patient.isAddVisit,
    isAddVisitError: state.patient.isAddVisitError,

    PatientStatus: state.home.PatientStatus,
    isPatientStatus: state.home.isPatientStatus,
    isPatientStatusError: state.home.isPatientStatusError,
  }
}
export default withRouter(connect(mapStateToProps, { addVisitAction, patientStatusChangeAction })(DropDownSearch));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Col, Button } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import { dietListAction } from 'Front/actions/diet';
import { unitListAction, timeListAction } from 'Front/actions/master';
import { printDietAction, printDietColorChangeAction } from 'Front/actions/diet.js';
import 'Front/views/DietChart/style.css';
import { DietChartTable } from 'Front/components/DietChartTable/DietChartTable.jsx';
//import * as PF from "Front/views/Home/PublicFunction.jsx"
import { appConstants } from 'Front/_constants/app.constants.js';
let dietListApi = false;
let timeListApi = false;
let unitListApi = false;
let unitObjData = {};
let userType = localStorage.getItem('userType');
class DietChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            patient: {},
            calorie: null,
            selectCalorie: null,
            calorieType: null,
            treatmentList: [],
            treatmentType: null,
            dietOption: null,
            mealType: "",
            dietLanguage: "",
            treatmentId: "",
            treatmentName: "",
            dietList: null,
            dietListApi: false,
            options: {},
            timeList: [],
            dietTimePercent: {},
            comments: [],
            suggestions: [],
            startTimeDelay: 0,
            printHeader: true,
            clinicName: '',
            clinicAddress: '',
            clinicEmail: '',
            clinicPhone: '',
            mealTime: "",
            color: '',
            dietId: '',
            newColor: '',
            langTitle: '',

        }

        this.first_mealtime = this.first_mealtime.bind(this);
    }

    componentDidMount() {

        dietListApi = false;
        timeListApi = false;
        unitListApi = false;

        let mealTimeData = this.props.state && this.props.state.mealTime ? this.props.state.mealTime : '';

        this.setState({ mealTime: mealTimeData });

        //if(this.props.state.dietLanguage === 'hindi'){
        if (this.props.state.dietLanguage === '2') {
            this.setState({ langTitle: 'प्रथम आहार' });
        } else if (this.props.state.dietLanguage === '1') {
            this.setState({ langTitle: 'First Meal' });
        }
        // console.log(this.props.state,"this.props.state")  
        this.setState(this.props.state);

        if (this.props.unitList && this.props.unitList.data) {
            this.createUnitObj(this.props.unitList.data.data);
        }
        else {
            this.props.unitListAction();
        }

        this.setState({ printHeader: (localStorage.getItem('diet_print_format') === 'yes' ? false : true) });
        this.setState({ clinicName: localStorage.getItem('clinicName') });
        this.setState({ clinicAddress: localStorage.getItem('Address') });
        this.setState({ clinicPhone: localStorage.getItem('Phone') });
        this.setState({ clinicEmail: localStorage.getItem('clinicEmail') });
        if (localStorage.getItem('userType') === 'educator') {
            if (this.props.state.color === 'blue') {
                this.setState({ newColor: 'green', color: 'green' });
            } else if (this.props.state.color === 'red' || this.props.state.color === "") {
                this.setState({ color: 'blue' })
            }
        } else {
            this.setState({ color: 'red' })
        }

        let _this = this;
        setTimeout(function () {
            _this.props.printDietAction(_this.state);
        }, 500);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.unitList && nextProps.unitList.data && !unitListApi) {
            this.createUnitObj(nextProps.unitList.data.data);
        }

        if (nextProps.dietList && nextProps.dietList.data && !dietListApi) {
            this.getDietList(nextProps.dietList);
        }

        if (nextProps.timeList && nextProps.timeList.data && !timeListApi) {
            this.createTimeList(nextProps.timeList.data);
        }

        if (nextProps.isDietPrint !== this.props.isDietPrint && this.props.isDietPrint === false) {
            setTimeout(function () {
                let params = {
                    clinicId: localStorage.getItem('clinicId'),
                }
                appConstants.socket.emit('updateTaskScreen', params);

            }, 1000);
        }

        if (nextProps.isDietPrintColor !== this.props.isDietPrintColor && this.props.isDietPrintColor === false) {
            setTimeout(function () {
                let params = {
                    clinicId: localStorage.getItem('clinicId'),
                }
                appConstants.socket.emit('updateTaskScreen', params);

            }, 1000);
        }
    }

    handelSelectChange(name, value) {
        let data = {};
        data[name] = value.value;
        this.setState(data);
    }

    handelChange(e, name) {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
    }

    getDietList(dietList) {
        if (
            dietList &&
            dietList.filter &&
            (dietList.filter.treatmentId === this.props.state.treatmentId) &&
            (dietList.filter.veg === this.props.state.mealType) &&
            (dietList.filter.calorie === this.props.state.selectCalorie)
        ) {
            this.createDiet(dietList.data.data);
        }
        else if (!dietListApi) {
            let filter = {
                treatmentId: this.props.state.treatmentId,
                veg: this.props.state.mealType,
                calorie: this.props.state.selectCalorie
            };
            this.props.dietListAction(filter);
        }
    }

    createDiet(dietList) {
        let lang = this.props.state.dietLanguage;
        let x;

        let foods = {};
        for (x in dietList.foods) {
            let row = dietList.foods[x];

            foods[row._id] = row;
        }

        let foodGroup = {}
        for (x in dietList.food_group) {
            let row = dietList.food_group[x];

            let foodText = "";

            if (row.foodType === 'group') {
                let foodArr = [];
                for (let y in row.group) {
                    if (foods[row.group[y].foodId]) {

                        let foodRow = foods[row.group[y].foodId];
                        let text = foodRow.name[lang] ? foodRow.name[lang] : foodRow.name[1];
                        text += " " + row.group[y].qty;
                        let unit = "";
                        if (unitObjData[foodRow.unitId] && unitObjData[foodRow.unitId].name) {
                            unit = unitObjData[foodRow.unitId].name[lang] ? unitObjData[foodRow.unitId].name[lang] : unitObjData[foodRow.unitId].name[1];
                        }

                        text += " " + unit;

                        let descArr = [];
                        let info = foodRow.info[lang] ? foodRow.info[lang] : foodRow.info[1];
                        if (info) {
                            descArr.push(info);
                        }

                        let desc1 = foodRow.description[lang] ? foodRow.description[lang] : foodRow.description[1];
                        if (desc1) {
                            descArr.push(desc1);
                        }

                        let descTxt = "";
                        if (descArr.length) {
                            descTxt = "( " + descArr.join(' , ') + ")";
                        }

                        text += " " + descTxt;

                        foodArr.push(text);
                    }
                }

                foodText = foodArr.join(' <br>● ');
                //console.log(foodText,"foodtext")
            }
            else {
                foodText = row.custom[lang] ? row.custom[lang] : row.custom[1];
            }

            row.foodText = '● ' + foodText;
            foodGroup[row._id] = row;
        }

        let options = {};
        for (x in dietList.options) {
            let row = dietList.options[x];
            if (!options[row.dietTimeId]) {
                options[row.dietTimeId] = [];
            }

            if (options[row.dietTimeId].length < this.props.state.dietOption) {
                if (foodGroup[row.foodGroupId]) {
                    options[row.dietTimeId].push(foodGroup[row.foodGroupId]);
                }
            }
        }
        //console.log(options,"options");
        this.setState({ options: options }, () => {
            if (this.props.timeList && this.props.timeList.data) {
                this.createTimeList(this.props.timeList.data);
            }
            else if (!timeListApi) {
                this.props.timeListAction();
            }
        });

        dietListApi = true;
    }

    createUnitObj(unitList) {
        let unitObj = {};
        for (let x in unitList) {
            unitObj[unitList[x]._id] = unitList[x];
        }
        unitObjData = unitObj;
        this.getDietList(this.props.dietList);
        unitListApi = true;
    }

    createTimeList(timeListOld) {
        let timeList = [];
        for (let x in timeListOld.data) {
            if (this.state.options[timeListOld.data[x]._id]) {
                timeListOld.data[x].show = true;
            }
            else {
                timeListOld.data[x].show = false;
            }
            timeList.push(timeListOld.data[x]);
        }
        //console.log(timeList,"timeList")  
        this.setState({ timeList });

        let dietTimePercent = {};
        for (let x in timeListOld.plan) {
            if (timeListOld.plan[x].treatmentId === this.state.treatmentId) {
                dietTimePercent[timeListOld.plan[x].dietTimeId] = timeListOld.plan[x];
            }
        }
        //console.log(dietTimePercent,"dietTimePercent")
        this.setState({ dietTimePercent });
        timeListApi = true;

        this.setState({ lowCalorieFood: timeListOld.lowCalorieFood });

        let selectedComments = [];

        if (this.props.state.selectedComments && this.props.state.selectedComments.length) {

            if (userType != "doctor") {
                if (timeListOld && timeListOld.comments && timeListOld.comments.length > 0) {
                    if (this.props.state.selectedComments && this.props.state.selectedComments.length > 0) {
                        //console.log(this.props.state.selectedComments,timeListOld,"this.props.state.selectedComments")
                        timeListOld.comments.map((obj, key) => {
                            this.props.state.selectedComments.map((item, idx) => {
                                if (item.comment_id === obj._id) {
                                    //console.log(item,"item")
                                    let comment = {}
                                    comment.type = obj.type;
                                    comment.auto_select = obj.auto_select;
                                    comment["text"] = item
                                    selectedComments.push(comment)
                                }
                            })
                        })
                    }
                }
            } else {
                selectedComments = this.props.state.selectedComments;
            }

        }
        else {
            selectedComments = timeListOld.comments;
        }
        //console.log(selectedComments,"sc")
        this.setState({ comments: selectedComments });

        let lang = this.state.dietLanguage;
        let suggestions = [];
        for (let x in timeListOld.lowCalorieFood) {
            let row = timeListOld.lowCalorieFood[x];

            suggestions.push(row.name[lang] ? row.name[lang] : row.name[1]);

        }

        for (let x in selectedComments) {
            let row = selectedComments[x];
            if (this.props.state.selectedComments && this.props.state.selectedComments.length) {
                if (userType != "doctor") {
                    suggestions.push(row.text[lang] ? row.text[lang] : row[1]);
                } else {
                    suggestions.push(row[lang] ? row[lang] : row[1]);
                }
            } else {
                if (row.type.toLowerCase() === this.state.treatmentType.toLowerCase() && row.auto_select) {
                    if (userType != "doctor") {
                        suggestions.push(row.text[lang] ? row.text[lang] : row.text[1]);
                    } else {
                        suggestions.push(row[lang] ? row[lang] : row[1]);
                    }
                }
            }

        }
        //console.log(suggestions,"suggestionsdddddd")
        this.setState({ suggestions });
    }

    print() {

        // let logo;
        // if(localStorage.getItem('diet_print_format') == 'yes')
        // {
        //     if(localStorage.getItem('logo') != '')
        //     {
        //         logo = appConstants.s3UploadUrl+localStorage.getItem('logo')
        //     }else{
        //         logo = "images/logo.png"
        //     }
        // }

        let html = document.getElementById("printable").innerHTML;

        var newWin = window.open('', 'Print-Window');
        newWin.document.open();
        let printStyle = "margin:" + (localStorage.getItem('print_header_margin') ? localStorage.getItem('print_header_margin') : 5) + "mm";
        printStyle += " " + (localStorage.getItem('right_margin') ? localStorage.getItem('right_margin') : 5) + "mm";
        printStyle += " " + (localStorage.getItem('print_bottom_margin') ? localStorage.getItem('print_bottom_margin') : 5) + "mm";
        printStyle += " " + (localStorage.getItem('left_margin') ? localStorage.getItem('left_margin') : 5) + "mm";

        //     let printStyleWithHeader = "margin:20mm";
        //   //  printStyleWithHeader += " "+(localStorage.getItem('right_margin')?localStorage.getItem('right_margin'):0)+"mm";
        //     printStyleWithHeader += " "+(localStorage.getItem('right_margin')?10:10)+"mm";
        //     printStyleWithHeader += " "+(localStorage.getItem('print_bottom_margin')?localStorage.getItem('print_bottom_margin'):0)+"mm";
        //     //printStyleWithHeader += " "+(localStorage.getItem('left_margin')?localStorage.getItem('left_margin'):0)+"mm";
        //     printStyleWithHeader += " "+(localStorage.getItem('left_margin')?10:10)+"mm";

        if (localStorage.getItem('diet_print_format') === 'yes') {

            newWin.document.write('<html moznomarginboxes mozdisallowselectionprint><body onload="window.print()" ><style>@media print{ .visit_date{display:block;font-size:14px;margin-Bottom:10px;text-align:right;font-weight:700;} .logo_header{ position:absolute; } body { font-size: 12px; color:#333; } .OR{ float:left; width: 7%; text-align: center;font-weight:700; }.dietWidth2{ display: block; float: left; width: 45%; } .page-break{ display: block; page-break-before: always; } table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto } thead { display:table-header-group } tfoot { display:table-footer-group } td{ page-break-inside:avoid; page-break-after:auto } @page { size: A4;  ' + printStyle + '; } th { font-size: 12px; padding: 5px; vertical-align: middle; border: 1px solid #000; } td { font-size: 12px; padding: 15px; vertical-align: middle; border: 1px solid #000; padding-left: 5px !important; } .table {font-size: 12px; width: 100%; max-width: 100%; border-spacing: 0; border-collapse: collapse;} h1{margin-bottom:5px!important;} .only-print{margin-bottom:-10px !important;} .dietWidth1 { width:25%; display: inline-block; vertical-align:top;} .dietWidth5 { width:46.5%; float:left;vertical-align:top;  } table.pt-info-box { display: table;} ul{ padding-top: 0px !important;} .cald2{border: 1px solid;width: 49%;float: left;padding: 2px 2px 2px 2px;} .cald3{border: 1px solid;width: 31%;float: left;padding: 2px 2px 2px 2px;} }</style>' + html + '</body></html>');
        } else {

            newWin.document.write('<html moznomarginboxes mozdisallowselectionprint><body onload="window.print()" ><style>@media print{ .visit_date{display:block;font-size:14px;margin-Bottom:10px;text-align:right;font-weight:700;} .logo_header{ position:absolute; } body { font-size: 12px; color:#333; } .OR{float:left; width: 7%; text-align: center;font-weight:700; }.dietWidth2{ float: left; width: 45%; display: block; } .page-break{ display: block; page-break-before: always; } table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto } thead { display:table-header-group } tfoot { display:table-footer-group } @page { size: A4; ' + printStyle + ';} th { font-size: 12px; padding: 5px; vertical-align: middle; border: 1px solid #000; } td { font-size: 12px; padding: 5px; vertical-align: middle; border: 1px solid #000; padding-left: 5px !important;} .table {font-size: 12px; width: 100%; max-width: 100%; border-spacing: 0; border-collapse: collapse;} h1{margin-bottom:5px!important;} .only-print{margin-bottom:-10px !important; } .dietWidth1 { width:25%; display: inline-block; vertical-align:top; margin:0 10px;} .dietWidth5 { width:46.5%; float:left;vertical-align:top;} table.pt-info-box { display: table;} ul{ padding-top: 5px !important;} .cald2{border: 1px solid;width: 49%;float: left;padding: 2px 2px 2px 2px;} .cald3{border: 1px solid;width: 31%;float: left;padding: 2px 2px 2px 2px;} }</style>' + html + '</body></html>');
        }



        //newWin.document.write('<html moznomarginboxes mozdisallowselectionprint><body onload="window.print()"><style>@media print{body{background:green;}}</style>'+html+'</body></html>')


        newWin.document.close();
        setTimeout(function () {
            newWin.close();
        }, 10);

        if (this.state.newColor === 'blue' && localStorage.getItem('userType') === 'educator') {
            let _this = this;
            //console.log(this.props.state,"this.props.state")
            let colorData = {
                newColor: this.state.newColor,
                dietId: this.props.dietId,
                patient: this.props.state && this.props.state.patientId
            }

            _this.props.printDietColorChangeAction(colorData);
        }
    }

    first_mealtime(time) {
        //console.log(time,"time")
        this.setState({ mealTime: time });
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.mealTime;
        field = e.target.value;
        //console.log(field,"time2")
        this.setState({ mealTime: field });

        this.props.onClickTime(e.target.value);
    };

    render() {
        //console.log(this.state,"sptatppdsppppp")
        const { langTitle } = this.state;

        return (
            <div>
                <Grid fluid>
                    <Card content={
                        <div>
                            <Col sm={12}>
                                <div className="no-print" style={{ marginBottom: '15px' }}>
                                    <Button onClick={this.props.backPage.bind(this)} className="btn-fill btn btn-primary">Back</Button>{'  '}
                                    <Button onClick={e => { this.print() }} className="btn-fill btn btn-primary">Print</Button>
                                    <span style={{ float: 'right', marginTop: '7px' }} className="col-sm-7">
                                        {/*<span className="fmeal_class flash-text">प्रथम आहार</span> @ */}
                                        <span className="fmeal_class flash-text">{langTitle}</span>
                                        <select name="first_mealtime" id="first_mealtime" value={this.state.mealTime} onChange={e => { this.handleChange(e); }}>
                                            <option value="07:00 am">07:00 AM</option>
                                            <option value="08:00 am">08:00 AM</option>
                                            <option value="09:00 am" selected="selected">09:00 AM</option>
                                            <option value="10:00 am">10:00 AM</option>
                                            <option value="11:00 am">11:00 AM</option>
                                        </select>
                                    </span>
                                </div>
                                {/* {console.log(this.state,"datatata")} */}
                                {this.state && this.state.selectCalorie &&

                                    <DietChartTable data={this.state} first_mealtime={this.first_mealtime} />
                                }
                                <div className="no-print">
                                    {/*<Button className="pull-right" onClick={this.props.backPage.bind(this)}>Back</Button>*/}
                                </div>
                            </Col>
                        </div>
                    } />
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dietList: state.diet.dietList,
        unitList: state.master.unitList,
        timeList: state.master.timeList,

        printDiet: state.diet.printDiet,
        isDietPrint: state.diet.isDietPrint,
        isDietPrintError: state.diet.isDietPrintError,

        isDietPrintColor: state.diet.isDietPrintColor,
        isDietPrintColorError: state.diet.isDietPrintColorError,
        printDietColor: state.diet.printDietColor
    }
}
export default withRouter(connect(mapStateToProps, { dietListAction, unitListAction, timeListAction, printDietAction, printDietColorChangeAction })(DietChart));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { updateTreatmentTypeAction } from 'Admin/actions/treatment_type';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name: 'required',
    type: 'required',
    range_from: 'numeric',
    range_to: 'numeric',
    order: 'numeric',

};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateTreatmentType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cardHidden: true,
            formArr: [],
            nameError: null,
            typeError: null,

            formData: {
                id: this.props.location.state.row._id,
                type: this.props.location.state.row.type,
                name: this.props.location.state.row.name,
                range_from: this.props.location.state.row.range_from,
                range_to: this.props.location.state.row.range_to,
                order: this.props.location.state.row.order,

            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isUpdateTreatmentTypeError !== this.props.isUpdateTreatmentTypeError) {
            if (nextProps.msg.errors) {
                nextProps.msg.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }

        if (nextProps.isUpdateTreatmentType !== this.props.isUpdateTreatmentType) {
            this.props.handleClick('success', nextProps.UpdateTreatmentType.msg)
            this.props.history.push('/admin/treatment-type')
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }


    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    updateTreatmentType(evt) {
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let field = this.state.formData;

            _this.setState({ showProcessing: true });
            _this.props.updateTreatmentTypeAction(field);
        }
        //validation.errors();
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Name  <span className="star">*</span>
                                            </Col>
                                            <Col sm={6} >

                                                <FormControl type="text" name="name" id="name" placeholder="Treatment Type" value={_this.state.formData.name} onChange={(event) => { _this.handleChange(event) }} />
                                                <span className="errorMsg">
                                                    {this.state.nameError}
                                                    {this.state.formArr.name && validation.errors.first('name')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Type <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="type" placeholder="Type" value={_this.state.formData.type} onChange={(event) => this.handleChange(event)}>
                                                    <option value="">Select Type</option>
                                                    <option value="diabetic">Diabetic</option>
                                                    <option value="non-diabetic">Non Diabetic</option>

                                                </select>

                                                <span className="errorMsg">
                                                    {this.state.typeError}
                                                    {this.state.formArr.type && validation.errors.first('type')}</span>

                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Calorie Range
                                            </Col>
                                            <Col sm={3} >

                                                <FormControl type="text" name="range_from" id="range_from" placeholder="Calorie range from" value={_this.state.formData.range_from} onChange={(event) => { _this.handleChange(event) }} />
                                                <span className="errorMsg">
                                                    {this.state.range_fromError}
                                                    {this.state.formArr.range_from && validation.errors.first('range_from')}</span>
                                            </Col>
                                            <Col sm={3} >

                                                <FormControl type="text" name="range_to" id="range_to" placeholder="Calorie range to" value={_this.state.formData.range_to} onChange={(event) => { _this.handleChange(event) }} />
                                                <span className="errorMsg">
                                                    {this.state.range_toError}
                                                    {this.state.formArr.range_to && validation.errors.first('range_to')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Order
                                            </Col>
                                            <Col sm={6} >

                                                <FormControl type="text" name="order" id="order" placeholder="Order" value={_this.state.formData.order} onChange={(event) => { _this.handleChange(event) }} />
                                                <span className="errorMsg">
                                                    {this.state.orderError}
                                                    {this.state.formArr.order && validation.errors.first('order')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.updateTreatmentType.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.treatmentType.message,

        UpdateTreatmentType: state.treatmentType.UpdateTreatmentType,
        isUpdateTreatmentType: state.treatmentType.isUpdateTreatmentType,
        isUpdateTreatmentTypeError: state.treatmentType.isUpdateTreatmentTypeError,

    }
}
export default withRouter(connect(mapStateToProps, { updateTreatmentTypeAction })(UpdateTreatmentType));

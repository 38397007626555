/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { uploadFileAction } from 'Admin/actions/link';
import { updateLinkAction } from 'Admin/actions/link';
import { clinicListAction } from 'Admin/actions/clinic';
import { specializationListAction } from 'Admin/actions/specialization';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import { appConstants } from 'Front/_constants/app.constants.js';
import ReactPlayer from 'react-player';
import { postRequestWithToken } from "../../api/helper.js"

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    title: 'required'
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clinicList: [],
            specializationList: [],
            specialization: null,
            category: null,
            cardHidden: true,
            formArr: [],
            clinicError: null,
            specializationError: null,
            titleError: null,
            fileError: null,
            descriptionError: null,
            orderError: null,
            categoryError: null,
            oldspecializations: this.props.location.state.row,
            formData: {
                title: this.props.location.state.row.title,
                clinic_name: this.props.location.state.row.clinicName,
                clinic_id: this.props.location.state.row.clinicId,
                description: this.props.location.state.row.description,
                order: this.props.location.state.row.order,
                link: this.props.location.state.row.link,
                status: this.props.location.state.row.status,
                specializations: [],
                file: this.props.location.state.row.image,
                imageFile: appConstants.s3UploadUrl + this.props.location.state.row.image,
                id: this.props.location.state.row._id,
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
            modelViewData: {},
            s3url: '111',
            linkModel: false,
            clinicHeaderView: this.props && this.props.location && this.props.location.clinicDetail && this.props.location.clinicDetail._id ? this.props.location.clinicDetail._id : "",
        }
    }

    componentWillMount() {
        var oldspecializations = this.state.oldspecializations.specializations;
        var x = null
        let Spec = [];
        if (oldspecializations) {
            for (x in oldspecializations) {
                let obj = {};
                obj.label = oldspecializations[x].name;
                obj.value = oldspecializations[x].id;
                Spec.push(obj);
            }
            //this.state.specialization
            this.setState({ specialization: Spec })
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.clinicListAction(data);
        this.props.specializationListAction(data);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isClinicList !== this.props.isClinicList) {
            this.setState({
                clinicList: nextProps.ClinicList.data.data
            });
        }

        if (nextProps.isSpecializationList && nextProps.isSpecializationList !== this.props.isSpecializationList) {
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                this.state.specializationList = nextProps.SpecializationList.data.data.map((key, i) => {
                    return { value: key._id, label: key.name };
                });
            }
        }

        if (nextProps.isUpdateLinkError !== this.props.isUpdateLinkError) {
            if (nextProps.UpdateLinkMessage.errors) {
                nextProps.UpdateLinkMessage.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }

        if (nextProps.isUploadFile !== this.props.isUploadFile) {
            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            field['imageFile'] = appConstants.s3UploadUrl + nextProps.uploadFile.file_name;

            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg })
                });
            }
        }

        if (nextProps.isUpdateLink !== this.props.isUpdateLink) {


            //if(this.state.formData.status == "active"){
            let params = { clinic_id: this.state.formData.clinic_id, type: "my-clinic" };
            appConstants.socket.emit('master-update', params);
            //}

            this.props.handleClick('success', 'Link updated successfully')
            if (this.props.location && this.props.location.clinicDetail) {
                this.props.history.push(`/admin/link-list`, this.props.location.clinicDetail);
            } else {
                this.props.history.push('/admin/link-list')
            }
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleClinic(event) {
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinic_id'] = event.target.value
        formData['clinic_name'] = event.target[index].text
        this.setState({ formData: formData });
    }

    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];
        this.props.uploadFileAction(file);
        reader.readAsDataURL(file);
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addLink(evt) {
        evt.preventDefault();
        if (this.state.specialization != null) {
            var spArr = this.state.specialization.map((key, i) => {
                return { id: key.value, name: key.label };
            });
            this.state.formData.specializations = spArr;
        }
        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true });
            _this.props.updateLinkAction(this.state);
        }
        //validation.errors()
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    openLinkModel(row) {
        let arr = row.file.split('.');
        let output = arr.pop();
        if (output == "mp4") {
            row.type = "video";
        } else if (output == "pdf") {
            row.type = "pdf";

        } else {
            row.type = "image";
        }

        const fetchS3SecureUrl = async (row) => {
            const response = await postRequestWithToken({ filePath: row.file }, 's3SignedUrl');
            row.displayFile = response.data.url;
            this.setState({ modelViewData: row, linkModel: true })
        };
        if (this.state.linkModel === false) {
            fetchS3SecureUrl(row);
        }
    }

    render() {

        const { modelViewData } = this.state;

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.clinicHeaderView &&
                                <ClinicHeader componentData={{ clinicId: this.props.location.clinicDetail._id }} />
                            }
                            <Card
                                title={<legend className="line-removes">
                                    {/* <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button> */}
                                </legend>}
                                content={
                                    <Form horizontal>
                                        {!this.state.clinicHeaderView &&
                                            <div>

                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                        Clinic:
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormControl type="text" disabled={true} value={this.state.formData.clinic_name} readOnly />

                                                        {/*<select className="form-control" name="clinic" value={this.state.formData.clinic_id} onChange={(event) => this.handleClinic(event)}>
                                                            <option value="">Select Clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}</option>
                                                            })}
                                                        </select>*/}
                                                        {this.state.clinicError}
                                                    </Col>
                                                </FormGroup>

                                            </div>
                                        }
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Title <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>

                                                <FormControl type="text" disabled={this.state.clinicHeaderView} name="title" id="title" value={this.state.formData.title} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.titleError}
                                                    {this.state.formArr.title && validation.errors.first('title')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Link
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="link" id="link" disabled={this.state.clinicHeaderView} value={this.state.formData.link} onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                File
                                            </Col>
                                            <Col sm={6}>
                                                <a href="javascript:void(0)" onClick={this.openLinkModel.bind(this, this.state.formData)}>{this.state.formData.file}</a>
                                                {/* <img src={this.state.formData.imageFile} alt="Logo" width="140px" height="140px" /> */}
                                                {/* <input type="hidden" name="id" value={this.state.formData.id} onChange={e => { this.fileChangedHandler(e, "id") }}></input>
                                                <input type="file" name="file" onChange={e => { this.fileChangedHandler(e, "file") }}></input>{this.state.formData.file} */}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" disabled={this.state.clinicHeaderView} componentClass="textarea" name="description" value={this.state.formData.description} className="form-control" defaultValue="" onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>


                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Status
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="status" value={this.state.formData.status} onChange={e => { this.handleChange(e); }}>

                                                    <option value="requested">Requested</option>
                                                    <option value="active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.addLink.bind(this)} className="btn-fill btn-wd btn btn-primary">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>

                                    </Form>
                                }
                            />
                        </Col>
                        <Col>
                            <Modal show={this.state.linkModel} onHide={() => this.setState({ linkModel: false })} dialogClassName="modal-md">
                                <Modal.Header>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <Modal.Title><h5>{modelViewData.title}</h5></Modal.Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body className="Knowledge-Share card">
                                    <Row>
                                        <Col md={12}>
                                            <div>

                                                <div>
                                                    <Row>
                                                        <Col md={12}>
                                                            {/*  <img src={this.state.s3url + this.state.formData.file} width="70%" height="100%" /> */}
                                                            {modelViewData && modelViewData.type && modelViewData.type == "video" &&
                                                                // <ReactPlayer className='react-player' width='100%' height='100%' url={this.state.s3url + modelViewData.file} playing controls="true" volume="1" />
                                                                <ReactPlayer className='react-player' width='100%' height='100%' url={this.state.modelViewData.displayFile} playing controls="true" volume="1" />

                                                            }
                                                            {modelViewData && modelViewData.type && modelViewData.type == "image" &&
                                                                <img src={this.state.modelViewData.displayFile} width="70%" height="100%" />
                                                                //  <img src={this.state.s3url + modelViewData.displayFile} width="70%" height="100%" />
                                                            }
                                                            {modelViewData && modelViewData.type && modelViewData.type == "pdf" &&
                                                                <a href={this.state.modelViewData.displayFile} target="_blank"> Download pdf</a>
                                                                //    <a href={this.state.s3url + modelViewData.file} target="_blank"> Download pdf</a>
                                                            }

                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={() => this.setState({ linkModel: false })} className="btn-fill btn-wd btn btn-secondary">Close</button>&nbsp;
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.link.message,
        UpdateLink: state.link.UpdateLink,
        isUpdateLink: state.link.isUpdateLink,
        isUpdateLinkError: state.link.isUpdateLinkError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        SpecializationList: state.specilization.SpecializationList,
        isSpecializationList: state.specilization.isSpecializationList,
        isSpecializationListError: state.specilization.isSpecializationListError,

        isUploadFile: state.link.isUploadFile,
        isUploadFileError: state.link.isUploadFileError,
        uploadFile: state.link.uploadFile,
    }
}
export default withRouter(connect(mapStateToProps, { updateLinkAction, clinicListAction, specializationListAction, uploadFileAction })(UpdateLink));

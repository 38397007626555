/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap/";

const DoctorInfo = () => {
  const [editForm, setEditForm] = useState(false);
  return (
    <>
      {!editForm && (
        <div className="doctor-info">
          {/* <h4>Doctor Info</h4> */}
          <div className="doctor-info-inner">
            <div className="dr-image">
              <img src="../../../images/Dr-Sunil-M-Jain.jpg" alt="Dr. image" />
            </div>
            <div className="dr-information">
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Name:</p>
                </div>
                <div className="col-sm-8">
                  <p>Dr. M Sunil Jain</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Email address:</p>
                </div>
                <div className="col-sm-8">
                  <p>sunilmjain@gmail.com</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Contact number:</p>
                </div>
                <div className="col-sm-8">
                  <p>+91 9302443344</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Clinic Name:</p>
                </div>
                <div className="col-sm-8">
                  <p>Totall hospital</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Speciality:</p>
                </div>
                <div className="col-sm-8">
                  <p>Endocrinologist (MBBS, MD, DM)</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <p className="lable">Degrees:</p>
                </div>
                <div className="col-sm-8">
                  <p>MBBS, MD(Medicine), DM(Endocrinologist)</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <p className="lable">Bio:</p>
                </div>
                <div className="col-sm-12">
                  <p>
                    He is in clinical practice since 1995 and has given a new
                    dimension to diabetes and hormone care in India. Author of
                    many titles, Dr. Jain has many publications in medical
                    journals of world repute.
                  </p>
                </div>
              </div>
            </div>
            <div className="edit-info">
              <button
                type="submit"
                onClick={() => {
                  setEditForm(true);
                }}
                className="btn-fill btn-wd btn btn-info"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
      {editForm && (
        <div className="edit-doctor-info">
          <div>
            <button
              type="submit"
              onClick={() => {
                setEditForm(false);
              }}
              className="btn btn-info"
            >
              Back
            </button>
          </div>
          {/* <h4>Edit Doctor Info</h4> */}
          <div className="edit-doctor-info-inner">
            <Form>
              <div className="row">
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="name">Dr. Name:</label>
                    <input type="text" class="form-control" id="name" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="email">Email address:</label>
                    <input type="email" class="form-control" id="email" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="contact">Contact number:</label>
                    <input type="number" class="form-control" id="contact" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="clinic-name">Clinic Name:</label>
                    <input type="text" class="form-control" id="clinic-name" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="speciality">Speciality:</label>
                    <input type="text" class="form-control" id="speciality" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="degrees">Degrees:</label>
                    <select className="form-control">
                      <option value="active">MBBS</option>
                      <option value="active">MD</option>
                      <option value="active">DM</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div class="form-group">
                    <label for="dr-image">Dr. Image:</label>
                    <input type="file" class="form-control" id="dr-image" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div class="form-group">
                    <div class="form-group">
                      <label for="clinic-image">Clinic Image:</label>
                      <input
                        type="file"
                        class="form-control"
                        id="clinic-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div class="form-group">
                    <label for="bio">Bio:</label>
                    <textarea type="text" class="form-control" id="bio" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <button
                    type="submit"
                    className="btn-fill btn-wd btn btn-info"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default DoctorInfo;

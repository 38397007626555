/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState, useRef } from "react";
import { Grid, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import GlucoseIcon from "../../assets/img/icons/glucose-diary.svg";
import DietIcon from "../../assets/img/icons/diet.svg";
import KnowledgeIcon from "../../assets/img/icons/knowledge-share.svg";
import ReportsIcon from "../../assets/img/icons/reports.svg";
import InsulinIcon from "../../assets/img/icons/insulin.svg";
import CardIcon from "../../assets/img/icons/card-icon.svg";
import Breadcrum from "../../components/common/Breadcrum";
import AdSlider from "./AdSlider";
import Welcome_User from "../../components/common/Welcome_User";
import PatientComplaint from "./PatientComplaint";
import PatientDiagnosis from "./PatientDiagnosis";
import PatientInvestigation from "./PatientInvestigation";
import PatientReports from "./PatientReports";
import PatientPrescription from "./PatientPrescription";
import EmptyDetails from "./EmptyDetails";
import VitalSigns from "./VitalSigns";
import PatientInformation from "./patientInformation";
import PrescriptionSnapShot from "./PrescriptionSnapShot";
import TaskScreenPopupNew from "../TaskScreen/TaskScreenPopupNew";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import moment from "moment";
import * as actionTypes from "Front/actions/actionTypes";
import EditIc from "../../assets/img/icons/prescription.png";
import * as API from "Front/api/emr";
import { patientVisitDetail } from "Front/api/home";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  FormGroup,
  Modal,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Reports from "../TaskScreen/Reports";
import InsulinDose from "../TaskScreen/InsulinDose";
import GlucoseDiaryChart from "./GlucoseDiaryChart";
import { useDispatch, useSelector } from "react-redux";
import * as PF from "Front/views/Home/PublicFunction.jsx";

const PatientDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((store) => store.taskScreen);

  const emrReduxdata = useSelector((store) => store.emr);
  const homeReduxData = useSelector((store) => store.home);
  const [activeScheduleTab, setActiveScheduleTab] = useState(false);
  const [taskScreen, setTaskScreen] = useState(false);
  const [alert, setAlert] = useState(null);
  const [addAppointment, setAddAppointment] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [appointmentData, setAppointmentData] = useState({});
  const [patientVisits, setPatientVisits] = useState({});
  const [isChangePrescription, setIsChangePrescription] = useState(true);
  const [isInsulinCalculator, setIsInsulinCalculator] = useState(false);
  const [isOnDismiss, setIsOnDismiss] = useState(false);
  const notificationSystem = useRef(null);
  const [isFromPatientSearch, setIsFromPatientSearch] = useState(false);
  const [glucoseReadByDoctor, setGlucoseReadByDoctor] = useState(false);
  const [reportReadByDoctor, setReportReadByDoctor] = useState(false);
  const [testData, setTestData] = useState(data.visitData);
  const [isOpenScreen, setIsOpenScreen] = useState("");
  const handleScheduleTab = (activeTabStatus) => {
    setActiveScheduleTab(activeTabStatus);
  };

  const handleModal = async (data) => {
    let visits = patientVisits;
    visits.isOpenScreen = data;
    setIsOpenScreen(data);
    setPatientVisits(visits);
    setIsOnDismiss(false);
    setTaskScreen(true);
  };

  const onDismiss = () => {
    setTaskScreen(false);
    setIsOnDismiss(true);

    //history.push(`/patient-details`);
  };

  const setTaskScreenData = () => {
    //console.log("taskScreenDataaaa")
    let response = JSON.parse(localStorage.getItem("patientVisit"));
    //console.log(response,"sds")
    //console.log(dispatch({type: actionTypes.GET_TASK_SCREEN_DATA}))
    //setPatientVisits(obj)
  };

  useEffect(() => {
    if (patientVisits) {
      let tempData = {};
      tempData.age =patientVisits.age!=undefined? patientVisits.dob!=undefined ? PF.getAgeByDob(patientVisits.dob): patientVisits.age:"";
      tempData.city = patientVisits.city;
      tempData.clinicId = patientVisits.clinicId;
      tempData.patientClinicId = patientVisits.patientClinicId;
      tempData.deleted = patientVisits.deleted;
      tempData.gender = patientVisits.gender;
      tempData.name = patientVisits.firstName;
      tempData.patientClinicId = patientVisits.patientClinicId;
      tempData.patientId = patientVisits.pId;
      tempData.phone = patientVisits.phone;
      tempData.refereedBy = "";
      tempData.title = "";
      tempData.time = "";
      tempData.type = "offline";
      tempData.date = patientVisits.next_visit_date
        ? moment(patientVisits.next_visit_date).format("YYYY-MM-DD")
        : "";
      setAppointmentData(tempData);
      setTaskScreenData();
    }

    //setPatientVisits()
  }, [patientVisits]);

  useEffect(() => {
    if (isOnDismiss) setPatientVisits(data?.visitData);
    if (data?.visitData.isTaskScreenDataSaved && isOnDismiss) {
      let msg = "";
      if (isOpenScreen == "dietScreen") msg = "Task Saved Successfully";
      if (isOpenScreen == "knowledgeScreen")
        msg = "Knowledge Shared Successfully";
      const notification = notificationSystem.current;
      notification.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: <div>{msg}</div>,
        level: "success",
        position: "tr",
        autoDismiss: 2,
      });
    }


  }, [isOnDismiss]);

  const getVisitDetail = async () => {
    let visitlist = data.visitData.activeTab === "offline" ? homeReduxData.VisitList : data.visitData.activeTab === "online" ? homeReduxData.eVisitList : homeReduxData.outPatientList

    let patient = visitlist.find(obj => obj.id === data.visitData.id);
    if(patient!=undefined){
    if (!patient.isVisitDetailApiCalled) {
      let search_date = history.location.state.search_date
      let response = await patientVisitDetail({
        "visitId": data.visitData.id,
        "search_date": search_date
      });
      if (response.data.statusCode == 200) {

        let res = response.data.data.data[0]
        if (res) {
          let visitData = data.visitData
          visitData.activeTab = data.visitData.activeTab
          //  visitData.emrVisitId=data.visitData.emrVisitId
          //  visitData.pId=data.visitData.pId
          visitData.lastDiet = res.patient_data[0].lastDiet;
          visitData.language = res.patient_data[0].language;
          visitData.patientStatus = res.patientStatus;
          visitData.flag = res.flag;
          visitData.taskList = res.Tasks;
          visitData.documentList = res.Documents;
          visitData.dietHistory = res.dietHistory;
          visitData.complete_task = res.complete_tasks_count;
          visitData.share = res.doc_count + " / " + res.read_docs_count;
          visitData.read_share = res.read_docs_count;
          visitData.task = res.task_count + " / " + res.complete_tasks_count;
          visitData.validPrescription = res.validPrescription;
          visitData.reportCount = res.hasOwnProperty("reportCount")
            ? res.reportCount
            : visitData.reportCount;
          visitData.nextDate = res.patient_data[0].nextDate;
          visitData.next_visit_date = res.patient_data[0].nextDate;
          setPatientVisits({ ...visitData });
          dispatch({
            type: actionTypes.UPDATE_PATIENT_VISIT_DETAIL,
            payload: visitData,
          });
        }
      }
    } else {

      setPatientVisits(patient);
    }
  } else {
    setPatientVisits({...data?.visitData});
    //console.log(data,emrReduxdata,"checkkkkkkkkk")
  }
  };

  useEffect(() => {
    getVisitDetail();
  }, []);

  const changePrescription = async (value) => {
    setIsChangePrescription(value);
  };

  const handleInsulinCalculator = async (value) => {
    setIsInsulinCalculator(value);
  };

  useEffect(() => {
    (async () => {
      let response = await API.glucoseReportStatus({
        patientId: data.visitData.pId,
      });
      if (response.data.statusCode == 200) {
        setGlucoseReadByDoctor(response.data.data.glucoseReadByDoctor);
        setReportReadByDoctor(response.data.data.reportReadByDoctor);
      }
    })();
  }, []);

  return (
    <>
      {patientVisits && (
        <div className="main-content patient-details-main  dashboard-2-0">
          <div>
            <NotificationSystem ref={notificationSystem} style={style} />
          </div>
          <Grid fluid>
            <div className="patient-details">
              <Breadcrum
                ParentComponent="PatientDetails"
                data={appointmentData}
                setModal={setModalStatus}
                ModalStatus={modalStatus}
                addAppointment={addAppointment}
                setAddAppointment={setAddAppointment}
              />
              {patientVisits && patientVisits.pId && (
                <PatientInformation patientId={patientVisits.pId} />
              )}

              <div className="dashboard-grid">
                <div className="grid-column">
                  <div className="table-card">
                    <div className="table-card-header">
                      {patientVisits &&
                        patientVisits.patientStatus === "new" && (
                          <EmptyDetails patientVisitData={patientVisits} />
                        )}
                      {patientVisits && (
                        <div style={{ float: "right" }}>
                          {patientVisits.patientStatus === "old" &&
                            patientVisits.flag === "no" ? (
                            <>
                              <Link
                                className="aside-cards-style"
                                to={`prescription/${patientVisits.emrVisitId}/${patientVisits.pId}/${patientVisits.language}`}
                              >
                                <img src={EditIc} alt="Edit" />
                                Edit
                              </Link>
                            </>
                          ) : patientVisits.patientStatus === "old" &&
                            patientVisits.flag === "yes" ? (
                            <>
                              <Link
                                className="aside-cards-style"
                                to={`prescription/${patientVisits.emrVisitId}/${patientVisits.pId}/${patientVisits.language}`}
                              >
                                <img src={EditIc} alt="Edit" />
                                Edit
                              </Link>
                              {/* <Link
                        className="btn btn-fill btn-primary"
                        style={{ float: "right", display: "block" }}
                        to={`print/${patientVisits.id}/${patientVisits.pId}/brand/${patientVisits.language}`}
                      >
                        Print
                      </Link> */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>

                    {patientVisits &&
                      patientVisits.patientStatus === "old" &&
                      isChangePrescription && (
                        <div className="patient-details">
                          <PrescriptionSnapShot
                            patientVisitData={patientVisits}
                            setIsInsulinCalculator={setIsInsulinCalculator}
                          />
                        </div>
                      )}
                  </div>
                </div>

                <div className="grid-column aside-panel">
                  <div className="aside-cards">
                    <div
                      className="aside-card"
                      onClick={() => handleModal("dietScreen")}
                    >
                      <div className="card-icon">
                        <img src={DietIcon} alt="" />
                      </div>
                      <h4>
                        Diet Advise{" "}
                        <span>
                          <img src={CardIcon} alt="" />
                        </span>
                      </h4>
                    </div>

                    <div
                      className="aside-card"
                      onClick={() => handleModal("knowledgeScreen")}
                    >
                      <div className="card-icon">
                        <img src={KnowledgeIcon} alt="" />
                      </div>
                      <h4>
                        Knowledge Share{" "}
                        <span>
                          <img src={CardIcon} alt="" />
                        </span>
                      </h4>
                      {/* <div className="card-badge">New</div> */}

                      {/* <TaskScreenPopupNew isOpenScreen={'knowledgeScreen'}/> */}
                    </div>
                    <Reports
                      visitInfo={patientVisits}
                      readStatus={reportReadByDoctor}
                    />
                    {patientVisits && (
                      <GlucoseDiaryChart
                        patientData={patientVisits}
                        readStatus={glucoseReadByDoctor}
                      />
                      // {this.state.newNotification && (
                      //   <div className="card-badge">New</div>
                      // )}
                    )}

                    {/* {localStorage.getItem("is_report_folder_facility") &&
                  localStorage.getItem("is_report_folder_facility") ===
                    "yes" && (
                    <Reports
                      visitInfo={history.location.state.patientVisitData}
                    />
                  )}
                {localStorage.getItem("userType") &&
                  localStorage.getItem("userType") === "doctor" && (
                    <InsulinDose visitInfo={history.location.state.patientVisitData} />
                  )} */}
                    {isInsulinCalculator && (
                      <InsulinDose
                        visitInfo={patientVisits}
                        changePrescriptionPrint={changePrescription}
                      />
                    )}
                    <Modal
                      backdrop="static"
                      className={
                        isOpenScreen === "knowledgeScreen"
                          ? "knowledge-share-modal pa-task-screen"
                          : "pa-task-screen"
                      }
                      show={taskScreen}
                      onHide={() => {
                        setTaskScreen(false);
                        setIsOnDismiss(false);
                      }}
                      dialogClassName="modal-lg"
                    >
                      <button
                        type="button"
                        className="close"
                        onClick={() => {
                          setTaskScreen(false);
                          setIsOnDismiss(false);
                        }}
                      >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                      </button>
                      <Modal.Body className="Knowledge-Share ">
                        <Row>
                          <Col md={12}>
                            <TaskScreenPopupNew
                              taskScreenData={patientVisits}
                              isOpenScreen={isOpenScreen}
                              onDismiss1={onDismiss}
                              setIsOnDismiss={setIsOnDismiss}
                            />
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default PatientDetails;

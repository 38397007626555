/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/clinic_document'


export const ADD_CLINIC_DOCUMENT_REQUEST = "ADD_CLINIC_DOCUMENT_REQUEST"
export const ADD_CLINIC_DOCUMENT_SUCCESS = "ADD_CLINIC_DOCUMENT_SUCCESS"
export const ADD_CLINIC_DOCUMENT_ERROR = "ADD_CLINIC_DOCUMENT_ERROR"
export const GET_CLINIC_DOCUMENT_LIST_REQUEST = "GET_CLINIC_DOCUMENT_LIST_REQUEST"
export const GET_CLINIC_DOCUMENT_LIST_SUCCESS = "GET_CLINIC_DOCUMENT_LIST_SUCCESS"
export const GET_CLINIC_DOCUMENT_LIST_ERROR = "GET_CLINIC_DOCUMENT_LIST_ERROR"

//-- Add CLINIC_DOCUMENT
export const addClinicDocumentRequest = () => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_REQUEST
})
export const addClinicDocumentSuccess = (response) => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_SUCCESS,
    payload: {
        response,
    }
})
export const addClinicDocumentError = (error) => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_ERROR,
    payload: {
        error
    }
})
export const addClinicDocumentAction = (data) => {
    return dispatch => {
        dispatch(addClinicDocumentRequest())
        const FormData = {
            name: data.Name,
            description: data.description,
            status: data.status,
        }
        return API.addClinicDocument(FormData)
            .then(response => {

                dispatch(addClinicDocumentSuccess(response.data))
            })
            .catch(error => {
                dispatch(addClinicDocumentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//-- List CLINIC_DOCUMENT
export const clinicDocumentListRequest = () => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_LIST_REQUEST
})
export const clinicDocumentListSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const clinicDocumentListError = (error) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_LIST_ERROR,
    payload: {
        error
    }
})
export const clinicDocumentListAction = (data) => {

    return dispatch => {
        dispatch(clinicDocumentListRequest())
        // const FormData = {
        //     limit:10,
        //     offset:0,
        //     order:"name",
        //     direction:"asc"
        // }
        // if(data.formData){
        //     FormData.status = 'active';
        // }

        return API.clinicDocumentList(data)
            .then(response => {
                dispatch(clinicDocumentListSuccess(response.data))
            })
            .catch(error => {
                dispatch(clinicDocumentListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Update CLINIC_DOCUMENT and Add form */

export const updateClinicDocumentRequest = () => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_REQUEST
})
export const updateClinicDocumentSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_SUCCESS,
    payload: {
        response,
    }
})
export const updateClinicDocumentError = (error) => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_ERROR,
    payload: {
        error,
    }
})
export const updateClinicDocumentAction = (data) => {
    return dispatch => {
        dispatch(updateClinicDocumentRequest())
        const FormData = {
            name: data.Name,
            description: data.description,
            status: data.status,
            id: data.id
        }
        return API.updateClinicDocument(FormData)
            .then(response => {
                dispatch(updateClinicDocumentSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateClinicDocumentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* CLINIC_DOCUMENT Status Change  */
export const ClinicDocumentChangeStatusRequest = () => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_REQUEST
})
export const ClinicDocumentChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const ClinicDocumentChangeStatusError = (error) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const clinicDocumentchangestatusAction = (data) => {
    return dispatch => {
        dispatch(ClinicDocumentChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeClinicDocumentStatus(FormData)
            .then(response => {
                dispatch(ClinicDocumentChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(ClinicDocumentChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

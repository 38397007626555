/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/master'
//Country
export const countryListRequest = () => ({
    type: actionTypes.GET_COUNTRY_LIST_REQUEST
})
export const countryListSuccess = (response) => ({
    type: actionTypes.GET_COUNTRY_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const countryListError = (error) => ({
    type: actionTypes.GET_COUNTRY_LIST_ERROR,
    payload: {
        error
    }
})

export const countryListAction = (data) => {
    return dispatch => {
        dispatch(countryListRequest())
        const FormData = {
            limit: 10,
            offset: 0,
            order: "name",
            direction: "asc",
        }
        return API.countryList(FormData)
            .then(response => {

                dispatch(countryListSuccess(response.data))
            })
            .catch(error => {
                dispatch(countryListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//State
export const stateListRequest = () => ({
    type: actionTypes.GET_STATE_LIST_REQUEST
})
export const stateListSuccess = (response) => ({
    type: actionTypes.GET_STATE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const stateListError = (error) => ({
    type: actionTypes.GET_STATE_LIST_ERROR,
    payload: {
        error
    }
})
export const stateListAction = (data) => {
    return dispatch => {
        dispatch(stateListRequest())
        const FormData = {
            // limit:10,
            // offset:0,
            order: "name",
            direction: "asc",
            countryId: data
        }
        return API.stateList(FormData)
            .then(response => {
                dispatch(stateListSuccess(response.data))
            })
            .catch(error => {
                dispatch(stateListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
//plans
export const planListRequest = () => ({
    type: actionTypes.GET_PLAN_LIST_REQUEST
})
export const planListSuccess = (response) => ({
    type: actionTypes.GET_PLAN_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const planListError = (error) => ({
    type: actionTypes.GET_PLAN_LIST_ERROR,
    payload: {
        error
    }
})

export const planListAction = (data) => {
    return dispatch => {
        dispatch(planListRequest())
        const FormData = data;
        return API.planList(FormData)
            .then(response => {

                dispatch(planListSuccess(response.data))
            })
            .catch(error => {
                dispatch(planListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
// Add Plan
export const addPlanRequest = () => ({
    type: actionTypes.POST_ADD_PLAN_REQUEST
})
export const addPlanSuccess = (response) => ({
    type: actionTypes.GET_ADD_PLAN_SUCCESS,
    payload: {
        response,
    }
})
export const addPlanError = (error) => ({
    type: actionTypes.GET_ADD_PLAN_ERROR,
    payload: {
        error
    }
})

export const addPlanAction = (data) => {
    return dispatch => {
        dispatch(addPlanRequest())
        const FormData = {
            plan: data.formData.plan_name,
            amount: data.formData.amount,
            duration: data.formData.duration,
            status: data.formData.status,
        }
        return API.addPlan(FormData)
            .then(response => {

                dispatch(addPlanSuccess(response.data))
            })
            .catch(error => {
                dispatch(addPlanError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

// Update Plan
export const updatePlanRequest = () => ({
    type: actionTypes.POST_UPDATE_PLAN_REQUEST
})
export const updatePlanSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_PLAN_SUCCESS,
    payload: {
        response,
    }
})
export const updatePlanError = (error) => ({
    type: actionTypes.GET_UPDATE_PLAN_ERROR,
    payload: {
        error
    }
})

export const updatePlanAction = (data) => {
    return dispatch => {
        dispatch(updatePlanRequest())
        const FormData = {
            plan: data.formData.plan_name,
            amount: data.formData.amount,
            duration: data.formData.duration,
            status: data.formData.status,
            id: data.formData.id,
        }
        return API.updatePlan(FormData)
            .then(response => {

                dispatch(updatePlanSuccess(response.data))
            })
            .catch(error => {
                dispatch(updatePlanError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/*  Plan Status Change  */
export const PlanChangeStatusRequest = () => ({
    type: actionTypes.GET_PLAN_CHANGE_STATUS_REQUEST
})
export const PlanChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_PLAN_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const PlanChangeStatusError = (error) => ({
    type: actionTypes.GET_PLAN_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const planchangestatusAction = (data) => {
    return dispatch => {
        dispatch(PlanChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changePlanStatus(FormData)
            .then(response => {
                dispatch(PlanChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(PlanChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}




//Add Knowledge
export const addKnowledgeRequest = () => ({
    type: actionTypes.GET_ADD_KNOWLEDGE_REQUEST
})
export const addKnowledgeSuccess = (response) => ({
    type: actionTypes.GET_ADD_KNOWLEDGE_SUCCESS,
    payload: {
        response,
    }
})
export const addKnowledgeError = (error) => ({
    type: actionTypes.GET_ADD_KNOWLEDGE_ERROR,
    payload: {
        error
    }
})

export const addKnowledgeAction = (data) => {

    return dispatch => {
        dispatch(addKnowledgeRequest());
        let formData = new FormData();
        for (let index in data) {
            formData.append(index, data[index]);
        }
        return API.addKnowledge(formData)

            .then(response => {
                dispatch(addKnowledgeSuccess(response.data))
            })
            .catch(error => {

                dispatch(addKnowledgeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}



//Update Knowledge
export const updateKnowledgeRequest = () => ({
    type: actionTypes.GET_UPDATE_KNOWLEDGE_REQUEST
})
export const updateKnowledgeSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_KNOWLEDGE_SUCCESS,
    payload: {
        response,
    }
})
export const updateKnowledgeError = (error) => ({
    type: actionTypes.GET_UPDATE_KNOWLEDGE_ERROR,
    payload: {
        error
    }
})

export const updateKnowledgeAction = (data) => {
    return dispatch => {
        dispatch(updateKnowledgeRequest())
        let formData = data.formData;
        return API.updateKnowledge(formData)

            .then(response => {
                dispatch(updateKnowledgeSuccess(response.data))
            })
            .catch(error => {

                dispatch(updateKnowledgeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/*  Knowledge Status Change  */
export const KnowledgeChangeStatusRequest = () => ({
    type: actionTypes.GET_KNOWLEDGE_CHANGE_STATUS_REQUEST
})
export const KnowledgeChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_KNOWLEDGE_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const KnowledgeChangeStatusError = (error) => ({
    type: actionTypes.GET_KNOWLEDGE_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const knowledgechangestatusAction = (data) => {
    return dispatch => {
        dispatch(KnowledgeChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeKnowledgeStatus(FormData)
            .then(response => {
                dispatch(KnowledgeChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(KnowledgeChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Knowledge List
export const KnowledgeListRequest = () => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_REQUEST
})
export const KnowledgeListSuccess = (response) => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const KnowledgeListError = (error) => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_ERROR,
    payload: {
        error
    }
})
export const knowledgeListAction = (data, search) => {

    return dispatch => {
        dispatch(KnowledgeListRequest())
        var FormData = {};
        if (data) {
            FormData = data
        } else {
            // var FormData = {
            //     limit:10,
            //     offset:0,
            //     order:"name",
            //     direction:"asc"
            // }
            if (search) {
                FormData = search;
                FormData.clinic_id = FormData.clinicId
                // FormData.clinic_id =  search.clinicId;
                // FormData.specialization_id =  search.specialization_id;
                // FormData.type =  search.type;

            }
        }

        return API.knowledgeList(FormData)
            .then(response => {
                dispatch(KnowledgeListSuccess(response.data))
            })
            .catch(error => {
                dispatch(KnowledgeListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
//Upload File
export const uploadFileRequest = () => ({
    type: actionTypes.POST_UPLOAD_FILE_REQUEST
})
export const uploadFileSuccess = (response) => ({
    type: actionTypes.GET_UPLOAD_FILE_SUCCESS,
    payload: {
        response,
    }
})
export const uploadFileError = (error) => ({
    type: actionTypes.GET_UPLOAD_FILE_ERROR,
    payload: {
        error
    }
})
export const uploadFileAction = (data) => {
    return dispatch => {
        dispatch(uploadFileRequest())
        // const FormData = {
        //     file:data
        // }

        let formData = new FormData();
        formData.append('file', data);
        return API.uploadFile(formData)
            .then(response => {
                dispatch(uploadFileSuccess(response.data))
            })
            .catch(error => {
                dispatch(uploadFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Multi Upload File
export const uploadMultiFileRequest = () => ({
    type: actionTypes.UPLOAD_MULTIFILE_REQUEST
})
export const uploadMultiFileSuccess = (response) => ({
    type: actionTypes.UPLOAD_MULTIFILE_SUCCESS,
    payload: {
        response,
    }
})
export const uploadMultiFileError = (error) => ({
    type: actionTypes.UPLOAD_MULTIFILE_ERROR,
    payload: {
        error
    }
})
export const uploadMultiFileAction = (data) => {
    return dispatch => {
        dispatch(uploadMultiFileRequest())

        let formData = new FormData();
        for (let x in data) {
            formData.append('file', data[x]);
        }


        return API.uploadMultiFile(formData)
            .then(response => {
                dispatch(uploadMultiFileSuccess(response.data))
            })
            .catch(error => {
                dispatch(uploadMultiFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Category List
export const categoryListRequest = () => ({
    type: actionTypes.GET_CATEGORY_LIST_REQUEST
})
export const categoryListSuccess = (response) => ({
    type: actionTypes.GET_CATEGORY_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const categoryListError = (error) => ({
    type: actionTypes.GET_CATEGORY_LIST_ERROR,
    payload: {
        error
    }
})

export const categoryListAction = (data) => {
    return dispatch => {
        dispatch(categoryListRequest())
        const FormData = {
            limit: 10,
            offset: 0,
            order: "name",
            direction: "asc"
        }
        return API.categoryList(FormData)
            .then(response => {

                dispatch(categoryListSuccess(response.data))
            })
            .catch(error => {
                dispatch(categoryListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//Update Term & Condition
export const updateTermconditionRequest = () => ({
    type: actionTypes.GET_UPDATE_TERMCONDITION_REQUEST
})
export const updateTermconditionSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_TERMCONDITION_SUCCESS,
    payload: {
        response,
    }
})
export const updateTermconditionError = (error) => ({
    type: actionTypes.GET_UPDATE_TERMCONDITION_ERROR,
    payload: {
        error
    }
})

export const updateTermconditionAction = (data) => {
    return dispatch => {
        dispatch(updateTermconditionRequest())
        let formData = data;
        return API.updateTermcondition(formData)
            .then(response => {
                dispatch(updateTermconditionSuccess(response.data))
            })
            .catch(error => {

                dispatch(updateTermconditionError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//Termcondition List
export const termconditionListRequest = () => ({
    type: actionTypes.GET_TERMCONDITION_LIST_REQUEST
})
export const termconditionListSuccess = (response) => ({
    type: actionTypes.GET_TERMCONDITION_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const termconditionListError = (error) => ({
    type: actionTypes.GET_TERMCONDITION_LIST_ERROR,
    payload: {
        error
    }
})

export const termconditionListAction = (data) => {
    return dispatch => {
        dispatch(termconditionListRequest())
        const FormData = {
            limit: 10,
            offset: 0,
            order: "name",
            direction: "asc"
        }
        return API.termconditionList(FormData)
            .then(response => {

                dispatch(termconditionListSuccess(response.data))
            })
            .catch(error => {
                dispatch(categoryListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//Dashboard
export const dashboardRequest = () => ({
    type: actionTypes.GET_DASHBOARD_REQUEST
})
export const dashboardSuccess = (response) => ({
    type: actionTypes.GET_DASHBOARD_SUCCESS,
    payload: {
        response,
    }
})
export const dashboardError = (error) => ({
    type: actionTypes.GET_DASHBOARD_ERROR,
    payload: {
        error
    }
})

export const dashboardAction = (data) => {
    return dispatch => {
        dispatch(dashboardRequest())
        return API.dashboard()
            .then(response => {
                dispatch(dashboardSuccess(response.data))
            })
            .catch(error => {
                dispatch(dashboardError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//get Pattern list
export const patternListRequest = () => ({
    type: actionTypes.GET_PATTERN_LIST_REQUEST
})
export const patternListSuccess = (response) => ({
    type: actionTypes.GET_PATTERN_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const patternListError = (error) => ({
    type: actionTypes.GET_PATTERN_LIST_ERROR,
    payload: {
        error
    }
})

export const patternListAction = (data) => {
    return dispatch => {
        dispatch(patternListRequest())
        const FormData = {
            city: "indore"
        }
        return API.patternList(data)
            .then(response => {

                dispatch(patternListSuccess(response.data))
            })
            .catch(error => {
                dispatch(patternListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//file list
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddRegion: false,
  isAddRegionError: false,
  AddRegion: false,
  isUpdateRegion: false,
  isUpdateRegionError: false,
  UpdateRegion: false,
  isRegionChangeStatus: false,
  isRegionChangeStatusError: false,
  RegionChangeStatus: false,
  isRegionList: false,
  isRegionListError: false,
  RegionList: false,

  isRegionImageStatus: false,
  isRegionImageStatusError: false,
  RegionImageStatus: false,
  // isUploadFile:false,
  // isUploadFileError: false,
  // uploadFile: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_REGION_REQUEST:
      return {
        ...state,
        message: '',
        isAddRegion: false,
        isAddRegionError: false,
      }
    case actionTypes.GET_ADD_REGION_SUCCESS:
      return {
        ...state,
        message: 'Region Added Successfully',
        AddRegion: action.payload.response,
        isAddRegion: true,
        isAddRegionError: false,
      }
    case actionTypes.GET_ADD_REGION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddRegion: false,
        isAddRegionError: true
      }

    //Region Update

    case actionTypes.GET_UPDATE_REGION_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateRegion: false,
        isUpdateRegionError: false,
      }
    case actionTypes.GET_UPDATE_REGION_SUCCESS:
      return {
        ...state,
        message: 'Region Updated Successfully',
        UpdateRegion: action.payload.response,
        isUpdateRegion: true,
        isUpdateRegionError: false,
      }
    case actionTypes.GET_UPDATE_REGION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateRegion: false,
        isUpdateRegionError: true
      }

    //Region List
    case actionTypes.GET_REGION_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isRegionList: false,
        isRegionListError: false,
      }
    case actionTypes.GET_REGION_LIST_SUCCESS:
      return {
        ...state,
        message: '',
        RegionList: action.payload.response,
        isRegionList: true,
        isRegionListError: false,
      }
    case actionTypes.GET_REGION_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isRegionList: false,
        isRegionListError: true
      }


    //Region Status Change
    case actionTypes.GET_REGION_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isRegionChangeStatus: false,
        isRegionChangeStatusError: false,
      }
    case actionTypes.GET_REGION_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Region Status Changed',
        RegionChangeStatus: action.payload.response,
        isRegionChangeStatus: true,
        isRegionChangeStatusError: false,
      }
    case actionTypes.GET_REGION_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isRegionChangeStatus: false,
        isRegionChangeStatusError: true
      }


    default:
      return state


  }
}

/********* Show list of Link */




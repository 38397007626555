/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isReportList: false,
  isReportListError: false,
  ReportList: false,

  isUploadReport: false,
  isUploadReportError: false,
  uploadReport: false,

  isDownloadReportList: false,
  isDownloadReportListError: false,
  DownloadReportList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isReportList: false,
        isReportListError: false,
      }
    case actionTypes.GET_REPORT_LIST_SUCCESS:
      return {
        ...state,
        message: 'Report List successfully',
        ReportList: action.payload.response,
        isReportList: true,
        isReportListError: false,
      }
    case actionTypes.GET_REPORT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isReportList: false,
        isReportListError: true
      }

    case actionTypes.GET_DATA_UPLOAD_REPORT_REQUEST:
      return {
        ...state,
        message: '',
        isUploadReport: false,
        isUploadReportError: false,
        uploadReport: false,
      }
    case actionTypes.GET_DATA_UPLOAD_REPORT_SUCCESS:
      return {
        ...state,
        message: 'Report List successfully',
        uploadReport: action.payload.response,
        isUploadReport: true,
        isUploadReportError: false,
      }
    case actionTypes.GET_DATA_UPLOAD_REPORT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUploadReport: false,
        isUploadReportError: true
      }
      case actionTypes.GET_DOWNLOAD_REPORT_LIST_REQUEST:
        return {
          ...state,
          message: '',
          isDownloadReportList: false,
          isDownloadReportListError: false,
        }

     case actionTypes.GET_DOWNLOAD_REPORT_LIST_SUCCESS:
      return {
        ...state,
        message: 'Report List successfully',
        DownloadReportList: action.payload.response,
        isDownloadReportList: true,
        isDownloadReportListError: false,
      }
      case actionTypes.GET_DOWNLOAD_REPORT_LIST_ERROR:
        return {
          ...state,
          response: action.payload.error,
          message: action.payload.error,
          isDownloadReportList: false,
          isDownloadReportListError: true
        }
  
    default:
      return state
  }
}

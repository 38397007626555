/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Highcharts from 'highcharts'
import PieChart from 'highcharts-react-official'
import * as HighchartsExporting from "highcharts/modules/exporting";
import * as HighchartsExportData from "highcharts/modules/export-data";

const GlyFracDiabetesCalculator = () => {
    const [formData, setFormData] = useState({});
    const [calculateResult1, setCalculateResult1] = useState(0.0);
    const [calculateResult2, setCalculateResult2] = useState(0.0);
    const [calculateResult3, setCalculateResult3] = useState(0.0);
    const [calculateResult4, setCalculateResult4] = useState(0.0);
    const [calculateResult5, setCalculateResult5] = useState(0.0);
    const [calculateResult6, setCalculateResult6] = useState(0.0);
    const [calculateResult7, setCalculateResult7] = useState(0.0);
    const [required, setRequired] = useState({});
    const [options, setOptions] = useState({});
    const [isPieChart, setIsPieChart] = useState(false);
    let res6 = 0.0;
    let res7 = 0.0;
    var showPieChart = false;
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);

    const glyChart = () => {


        let chartOptions = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
                renderTo: "PieChartContainer"
            },
            title: {
                text: "Gly Frac. Diabetes Chart"
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    colors: ['#FF0000', '#00FF00', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: Highcharts.defaultOptions.exporting.buttons.contextButton.menuItems.slice(1, 7)
                    }
                },
                filename: 'Chart'
            },
            series: [
                {
                    name: "Contribution",
                    colorByPoint: true,

                    data: [
                        {
                            name: '% Contribution by Fasting',
                            y: res6

                        },
                        {
                            name: '% Contribution by Past Meal',
                            y: res7

                        }
                    ]
                }
            ]
        };

        let chartRefresh = new Highcharts.Chart(chartOptions);
        chartRefresh.series[0].setData(chartOptions.series[0].data, true);

        setOptions(chartOptions);



    }


    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }

    const calculateFun = (e) => {
        e.preventDefault();
        let smpg = parseFloat(formData.smpg);
        let hba1c = parseFloat(formData.hba1c);

        if (!smpg || !hba1c) {
            alert("All (*) fields are mandatory");
            return false;
        }
        let result_1 = 0;
        let result_2 = 0;
        let result_3 = 0;
        let result_4 = 0;
        let result_5 = 0;
        let result_6 = 0;
        let result_7 = 0;

        result_1 = (2.8 * hba1c) - 46.7;
        result_2 = (result_1 + 46.7) / 28.7;
        result_3 = 4.41;
        result_4 = ((smpg + 46.7) / 28.7) - result_3;
        result_5 = smpg - 5.0;
        result_6 = (result_4 / result_5) * 100;
        result_7 = 100 - result_6;

        setCalculateResult1(parseFloat(result_1.toFixed(8)));
        setCalculateResult2(parseFloat(result_2.toFixed(8)));
        setCalculateResult3(parseFloat(result_3.toFixed(8)));
        setCalculateResult4(parseFloat(result_4.toFixed(8)));
        setCalculateResult5(parseFloat(result_5.toFixed(8)));
        setCalculateResult6(parseFloat(result_6.toFixed(8)));
        setCalculateResult7(parseFloat(result_7.toFixed(8)));
        setIsPieChart(true);
        res6 = parseFloat(result_6.toFixed(8));
        res7 = parseFloat(result_7.toFixed(8));
        showPieChart = true;
        glyChart();
    }

    // useEffect(() => {
    //     glyChart();
    // }, [calculateResult6,calculateResult7]);

    return (
        <div className="main-content color-notations">
            <Grid fluid>
                <Card
                    content={
                        <Row>
                            <Col sm={4}>
                                <div id="PieChartContainer">

                                    {showPieChart && (

                                        <PieChart highcharts={Highcharts} options={options} />
                                    )}
                                </div>
                                {!isPieChart && (
                                    <img src="../../../images/GlyFracDiabetes-Calculator.png" class="w-100 mx-h-40" alt="Gly Frac. Diabetes" />
                                )}
                            </Col>
                            <Col md={2}>
                                <ul className="nav calc-navs">
                                    <li>
                                        <NavLink to={'bmi-calculator'} className="nav-link " >
                                            <span className="sidebar-mini">BMI</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'eGFR-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">eGFR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'homair-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Homa IR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link active" >
                                            <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [3-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [5-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'bone-age-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Bone Age Calculator</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'fib-4-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Fib 4</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <Form horizontal>
                                    <h4>Gly Frac. Diabetes Calculator</h4>

                                    <FormGroup>
                                        <Col componentClass={ControlLabel} className='col-sm-3'>
                                            Avge FPG as per SMPG <span className="star">*</span>
                                        </Col>
                                        <Col className='col-sm-4' sm={5}>
                                            <FormControl type="number" autocomplete="off" name="smpg" id="smpg" placeholder="Avge FPG as per SMPG" value={formData.smpg || ''} onChange={e => { inputHandler(e) }} />
                                            <span className="errorMsg" style={{ display: (!formData.smpg && required.smpg === '') ? 'block' : 'none' }}>This is required field</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} className='col-sm-3'>
                                            Total HbA1c <span className="star">*</span>
                                        </Col>
                                        <Col className='col-sm-4' sm={5} >
                                            <FormControl type="number" autocomplete="off" minLength="6" maxLength="20" name="hba1c" id="hba1c" placeholder="Total HbA1c" value={formData.hba1c || ''} onChange={e => { inputHandler(e) }} />
                                            <span className="errorMsg" style={{ display: (!formData.hba1c && required.hba1c === '') ? 'block' : 'none' }}>This is required field</span>
                                        </Col>
                                    </FormGroup>


                                    <FormGroup >
                                        <Col componentClass={ControlLabel} className='col-sm-3'>
                                            Average Glucose
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_1" id="result_1" placeholder="Average Glucose" value={calculateResult1 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            Average HbA1c for FPG
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_2" id="result_2" placeholder="Average HbA1c for FPG" value={calculateResult2 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            HbA1c for 80 mg Fasting
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_3" id="result_3" placeholder="HbA1c for 80 mg Fasting" value={calculateResult3 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                        <Col componentClass={ControlLabel} sm={5}>
                                            {'<'} Fix for 80 mg
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            Diff HbA1c for Fasting Status
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_4" id="result_4" placeholder="Diff HbA1c for Fasting Status " value={calculateResult4 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            How High is Total HbA1c
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_5" id="result_5" placeholder="How High is Total HbA1c " value={calculateResult5 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                        <Col componentClass={ControlLabel} sm={5}>
                                            5 {'->'} Fix
                                        </Col>

                                    </FormGroup>

                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            % Contribution by Fasting
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_6" id="result_6" placeholder="% Contribution by Fasting" value={calculateResult6 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={3}>
                                            % Contribution by Past Meal
                                        </Col>
                                        <Col sm={4}>
                                            <FormControl type="text" maxLength="10" name="result_7" id="result_7" placeholder="% Contribution by Past Meal" value={calculateResult7 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />

                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <Col sm={12}>
                                            <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-primary">Calculate</button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>

                        </Row>
                    }
                />
            </Grid>
        </div>
    );
}


export default withRouter(GlyFracDiabetesCalculator)

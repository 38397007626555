/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Form,
  Grid,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import * as API from "Front/api/master";
import Loading from "react-loading";
import Radio from "Front/elements/CustomRadio/CustomRadio.jsx";
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";

const PrescriptionSetting = () => {
  const [preferenceTypes, setPreferenceTypes] = useState({

    // is_print_complaint: localStorage.getItem("is_print_complaint")
    //   ? localStorage.getItem("is_print_complaint") : "yes",

    // is_print_diagnosis: localStorage.getItem("is_print_diagnosis")
    //   ? localStorage.getItem("is_print_diagnosis") : "yes",

    is_print_vital_complaint_diagnosis: localStorage.getItem(
      "is_print_vital_complaint_diagnosis"
    )
      ? localStorage.getItem("is_print_vital_complaint_diagnosis")
      : "yes",

    is_print_doctor_signature: localStorage.getItem("is_print_doctor_signature")
      ? localStorage.getItem("is_print_doctor_signature")
      : "yes",

    is_prescription_share_on_app: localStorage.getItem(
      "is_prescription_share_on_app"
    )
      ? localStorage.getItem("is_prescription_share_on_app")
      : "no",
    is_prescription_edit_by_educator: localStorage.getItem(
      "is_prescription_edit_by_educator"
    )
      ? localStorage.getItem("is_prescription_edit_by_educator")
      : "no",
    is_prescription_edit_by_receptionist: localStorage.getItem(
      "is_prescription_edit_by_receptionist"
    )
      ? localStorage.getItem("is_prescription_edit_by_receptionist")
      : "no",
    is_brand_with_ingredients_disable: localStorage.getItem(
      "is_brand_with_ingredients_disable"
    )
      ? localStorage.getItem("is_brand_with_ingredients_disable")
      : "no",
    prescription_print_margin_top: localStorage.getItem(
      "prescription_print_margin_top"
    )
      ? localStorage.getItem("prescription_print_margin_top")
      : 20,
    prescription_print_margin_bottom: localStorage.getItem(
      "prescription_print_margin_bottom"
    )
      ? localStorage.getItem("prescription_print_margin_bottom")
      : 10,
    prescription_print_margin_left: localStorage.getItem("prescription_print_margin_left")
      ? localStorage.getItem("prescription_print_margin_left")
      : 10,
    // prescriptionPadSize: localStorage.getItem("prescriptionPadSize")
    //   ? localStorage.getItem("prescriptionPadSize")
    //   : "A4"
  });

  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const notificationSystem = useRef(null);

  const inputHandler = (e, name) => {
    let saveFormData = preferenceTypes;

    saveFormData[e.target.name] = e.target.value;
    setPreferenceTypes({ ...preferenceTypes, ...saveFormData });
  };

  const saveData = async () => {
    setIsLoading(true);
    let data = preferenceTypes;
    let resp = await API.prescriptionSetting(data);
    //console.log(resp);
    if (resp) {
      //localStorage.setItem("is_print_diagnosis", preferenceTypes.is_print_diagnosis)

      //localStorage.setItem("is_print_complaint", preferenceTypes.is_print_complaint)

      //localStorage.setItem("prescriptionPadSize", preferenceTypes.prescriptionPadSize)

      localStorage.setItem(
        "is_print_vital_complaint_diagnosis",
        preferenceTypes.is_print_vital_complaint_diagnosis
      );
      localStorage.setItem(
        "is_print_doctor_signature",
        preferenceTypes.is_print_doctor_signature
      );
      localStorage.setItem(
        "is_prescription_share_on_app",
        preferenceTypes.is_prescription_share_on_app
      );
      localStorage.setItem(
        "is_prescription_edit_by_educator",
        preferenceTypes.is_prescription_edit_by_educator
      );
      localStorage.setItem(
        "is_prescription_edit_by_receptionist",
        preferenceTypes.is_prescription_edit_by_receptionist
      );
      localStorage.setItem(
        "is_brand_with_ingredients_disable",
        preferenceTypes.is_brand_with_ingredients_disable
      );
      localStorage.setItem(
        "prescription_print_margin_top",
        preferenceTypes.prescription_print_margin_top
      );
      localStorage.setItem(
        "prescription_print_margin_bottom",
        preferenceTypes.prescription_print_margin_bottom
      );
      localStorage.setItem(
        "prescription_print_margin_left",
        preferenceTypes.prescription_print_margin_left
      );

      //setSuccessMsg("Data inserted successfully.");
      const notification = notificationSystem.current;
      let msg = "Data inserted successfully.";
      notification.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: <div>{msg}</div>,
        level: "success",
        position: "tr",
        autoDismiss: 2,
      });
      setTimeout(function () {
        setSuccessMsg("");
        // window.location.reload();
      }, 3000);
      //successAlert('Insulin initial dose set successfully');
      setIsLoading(false);
    } else {
      setSuccessMsg("Something went wrong.");
      setTimeout(function () {
        setSuccessMsg("");
      }, 3000);
    }
  };



  return (
    <>
      <div className="Prefrences-set">
        <NotificationSystem ref={notificationSystem} style={style} />
        <Form>
          <div className="card">
            {/* <h4 className="title">Prescription Settings</h4> */}
            <div className="content">
              <div className="row">
                <div className="col-sm-8">Print Vital Sign/ Complaint / Diagnosis</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="1"
                        option="yes"
                        name="is_print_vital_complaint_diagnosis"
                        label="Yes"
                        checked={
                          preferenceTypes.is_print_vital_complaint_diagnosis === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                        defaultChecked
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="2"
                        option="no"
                        name="is_print_vital_complaint_diagnosis"
                        label="No"
                        checked={
                          preferenceTypes.is_print_vital_complaint_diagnosis === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {/* <div className="row">
                <div className="col-sm-8">Print Complaint</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="13"
                        option="yes"
                        name="is_print_complaint"
                        label="Yes"
                        checked={
                          preferenceTypes.is_print_complaint === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                        defaultChecked
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="14"
                        option="no"
                        name="is_print_complaint"
                        label="No"
                        checked={
                          preferenceTypes.is_print_complaint === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Print Diagnosis</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="15"
                        option="yes"
                        name="is_print_diagnosis"
                        label="Yes"
                        checked={
                          preferenceTypes.is_print_diagnosis === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                        defaultChecked
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="16"
                        option="no"
                        name="is_print_diagnosis"
                        label="No"
                        checked={
                          preferenceTypes.is_print_diagnosis === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr /> */}
              <div className="row">
                <div className="col-sm-8">Print Name of Doctor For Signature</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="3"
                        option="yes"
                        name="is_print_doctor_signature"
                        label="Yes"
                        checked={
                          preferenceTypes.is_print_doctor_signature === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                        defaultChecked
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="4"
                        option="no"
                        name="is_print_doctor_signature"
                        label="No"
                        checked={
                          preferenceTypes.is_print_doctor_signature === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Share on App</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="5"
                        option="yes"
                        name="is_prescription_share_on_app"
                        label="Yes"
                        checked={
                          preferenceTypes.is_prescription_share_on_app === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="6"
                        option="no"
                        name="is_prescription_share_on_app"
                        label="No"
                        checked={
                          preferenceTypes.is_prescription_share_on_app === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Edit By Educator</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="7"
                        option="yes"
                        name="is_prescription_edit_by_educator"
                        label="Yes"
                        checked={
                          preferenceTypes.is_prescription_edit_by_educator === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="8"
                        option="no"
                        name="is_prescription_edit_by_educator"
                        label="No"
                        checked={
                          preferenceTypes.is_prescription_edit_by_educator === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Edit By Receptionist</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="9"
                        option="yes"
                        name="is_prescription_edit_by_receptionist"
                        label="Yes"
                        checked={
                          preferenceTypes.is_prescription_edit_by_receptionist === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="10"
                        option="no"
                        name="is_prescription_edit_by_receptionist"
                        label="No"
                        checked={
                          preferenceTypes.is_prescription_edit_by_receptionist === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-10">
                      Drug Show By Brand Name
                    </div>
                    <div className="col-sm-2">
                      <Radio
                        number="11"
                        option="yes"
                        name="is_brand_with_ingredients_disable"
                        checked={
                          preferenceTypes.is_brand_with_ingredients_disable ===
                            "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                  </div>

                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-10">
                      Drug Show By Brand + Ingredients
                    </div>
                    <div className="col-sm-2">
                      <Radio
                        number="12"
                        option="no"
                        name="is_brand_with_ingredients_disable"
                        checked={
                          preferenceTypes.is_brand_with_ingredients_disable ===
                            "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Print Margin on Top in % ( For A4 Size Paper ) {preferenceTypes.prescription_print_margin_top > 0 ? <>{(preferenceTypes.prescription_print_margin_top * 2.97).toFixed(2)} MM</> : ''}</div>
                <div className="col-sm-4">
                  <div className="row">
                    <FormGroup>
                      <Col sm={12}>
                        <FormControl type="number" name="prescription_print_margin_top" id="prescription_print_margin_top" value={preferenceTypes.prescription_print_margin_top} className={preferenceTypes.prescription_print_margin_top > 40 ? 'limit-exceed' : ''} onChange={e => { inputHandler(e); }} />
                      </Col>
                    </FormGroup>
                    {preferenceTypes.prescription_print_margin_top > 40 &&
                      <p style={{ color: "red", "margin-left": "20px" }}>Maximum margin on top 40%</p>
                    }
                  </div>
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Print Margin on Bottom in % ( For A4 Size Paper ) {preferenceTypes.prescription_print_margin_bottom > 0 ? <>{(preferenceTypes.prescription_print_margin_bottom * 2.97).toFixed(2)} MM</> : ''}</div>
                <div className="col-sm-4">
                  <div className="row">
                    <FormGroup>
                      <Col sm={12}>
                        <FormControl type="number" name="prescription_print_margin_bottom" id="prescription_print_margin_bottom" value={preferenceTypes.prescription_print_margin_bottom} className={preferenceTypes.prescription_print_margin_bottom > 30 ? 'limit-exceed' : ''} onChange={e => { inputHandler(e); }} />
                      </Col>
                    </FormGroup>
                    {preferenceTypes.prescription_print_margin_bottom > 30 &&
                      <p style={{ color: "red", "margin-left": "20px" }}>Maximum margin on bottom 30%</p>
                    }

                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">Prescription Print Margin on Left in % ( For A4 Size Paper ) {preferenceTypes.prescription_print_margin_left > 0 ? <>{(preferenceTypes.prescription_print_margin_left * 2.1).toFixed(2)} MM</> : ''}</div>
                <div className="col-sm-4">
                  <div className="row">
                    <FormGroup>
                      <Col sm={12}>
                        <FormControl type="number" name="prescription_print_margin_left" id="prescription_print_margin_left" value={preferenceTypes.prescription_print_margin_left} className={preferenceTypes.prescription_print_margin_left > 30 ? 'limit-exceed' : ''} onChange={e => { inputHandler(e); }} />
                      </Col>
                    </FormGroup>
                    {preferenceTypes.prescription_print_margin_left > 30 &&
                      <p style={{ color: "red", "margin-left": "20px" }}>Maximum margin on left 30%</p>
                    }
                  </div>
                </div>
              </div>
              <hr />
              {/*<div className="row">
                <div className="col-sm-8">Prescription Print Paper Size</div>
                <div className="col-sm-4">
                  <div className="row">
                    <FormGroup>
                      <Col sm={12}>
                        <select class="form-control" id="prescription_paper_size" name="prescriptionPadSize" value={preferenceTypes.prescriptionPadSize} onChange={(e) => { inputHandler(e) }}>
                          <option value="PrescriptionPad">Prescription Pad</option>
                          <option value="A4">A4</option>
                          <option value="A5">A5</option>
                          <option value="A6">A6</option>
                          <option value="B5">B5</option>

                        </select>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </div> */}

            </div>





            <div className="row">
              <Col sm={12} md={6}>
                <button
                  type="button"
                  className="btn-fill btn-wd btn btn-primary"
                  onClick={() => saveData()}
                  disabled={preferenceTypes.prescription_print_margin_top > 40 || preferenceTypes.prescription_print_margin_bottom > 30 || preferenceTypes.prescription_print_margin_left > 30 ? true : false}
                >
                  Save
                </button>
              </Col>
              <Col mdOffset={2} sm={12} md={6}>
                {/* {successMsg !== "" && (
                                   <p className="alert-heading" style={{ color: "#155724" }}>
                                     {successMsg}
                                   </p>
                                  )} */}
              </Col>
              <Col sm={12} md={6}>
                {isLoading ? (
                  <Loading
                    type="bars"
                    color="#000000"
                    style={{ margin: "0px auto", width: "40px" }}
                  />
                ) : (
                  ""
                )}
              </Col>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default PrescriptionSetting;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const EGFRCalculator = () => {
    const [formData, setFormData] = useState({ gender: 'Male' });
    const [calculate, setCalculate] = useState(0.0);
    const [required, setRequired] = useState({});

    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }
    const calculateFun = (e) => {
        e.preventDefault();
        let { gender, age, creatinine } = formData;
        if (!gender || !age || !creatinine) {
            alert("All (*) fields are mandatory");
            return false;
        }

        let result = 0;
        let static_value_1 = (gender === 'Male') ? 0.9 : 0.7; //0.9 for male
        let static_value_2 = (gender === 'Male') ? 1 : 1; //1 for male
        let static_value_3 = (gender === 'Male') ? -0.411 : -0.329; //-0.411 for male

        let static_value_4 = (gender === 'Male') ? 0.9 : 0.7; //0.9 for male
        let static_value_5 = (gender === 'Male') ? 1 : 1; //1 for male
        let static_value_6 = (gender === 'Male') ? -1.209 : -1.209; //-1.209 for male

        let static_value_7 = (gender === 'Male') ? 0.993 : 0.993; //0.993 for male
        let static_value_8 = (gender === 'Male') ? 141.0 : 141.0; //141.0 for male

        let static_value_9 = (gender === 'Male') ? 1 : 1.018; //1 for male

        let exp = Math.pow((Math.min((creatinine / static_value_1), static_value_2)), static_value_3);

        let m = Math.pow((Math.max((creatinine / static_value_4), static_value_5)), static_value_6);

        let temp = Math.pow(static_value_7, age);

        let fix = static_value_8;

        result = fix * exp * m * temp * static_value_9;

        setCalculate(result.toFixed(2));

    }

    return (
        <div className="main-content color-notations">
            <Grid fluid>
                <Card
                    content={
                        <Row>

                            <Col md={4}>
                                <img src="../../../images/eGFR-Calculator.png" class="w-100 mx-h-40" alt="eGFR" />
                            </Col>
                            <Col md={2}>
                                <ul className="nav calc-navs">
                                    <li>
                                        <NavLink to={'bmi-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">BMI</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'eGFR-calculator'} className="nav-link active" >
                                            <span className="sidebar-mini">eGFR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'homair-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Homa IR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [3-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [5-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'bone-age-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Bone Age Calculator</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'fib-4-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Fib 4</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <Form horizontal>
                                    <h4>Calculate eGFR using CKD - EPI Formula</h4>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Gender<span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="select" name="gender" onChange={e => { inputHandler(e); }}>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </FormControl>
                                                    <span className="errorMsg" style={{ display: (!formData.gender && required.gender === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Age<span className="star">*</span>
                                                    </Col>
                                                    <FormControl autocomplete="off" type="number" name="age" id="age" value={formData.age || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.age && required.age === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>

                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Creatinine <span className="star">*</span>
                                                    </Col>
                                                    <FormControl autocomplete="off" type="number" minLength="6" maxLength="20" name="creatinine" id="creatinine" value={formData.creatinine || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.creatinine && required.creatinine === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-info">Calculate</button>
                                                </Col>
                                            </FormGroup>
                                            {calculate > 0 &&
                                                <FormGroup className="bmi-count">
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        eGFR
                                                    </Col>
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        {calculate}
                                                    </Col>
                                                </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    }
                />
            </Grid>
        </div>
    );
}


export default withRouter(EGFRCalculator);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Modal, Form, FormGroup, FormControl } from 'react-bootstrap';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/master';

import SweetAlert from 'react-bootstrap-sweetalert';
import Datetime from "react-datetime";
import moment from 'moment';

const ReportTitle = ({ item, getReport, parent }) => {

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [modalStatus, setModelStatus] = useState(false);
    const [validated, setValidated] = useState(false);
    const [titleType, setTitleType] = useState(['Lab', 'CGMS', 'X-Ray', 'MRI', 'Other']);

    const [alert, setAlert] = useState(null);
    const [reportDate, setReportDate] = useState(moment(new Date()).format("DD-MM-YYYY"))
    const [report_date, set_ReportDate] = useState(null)

    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {

        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.name != '') {
            errArr.name = '';
            errflag = true;
        } else { errArr.name = 'error'; errflag = false; }

        if (post.name == 'Other' && post.other == '') {
            errArr.other = 'error'; errflag = false;

        } else { errArr.other = ''; errflag = true; }

        setErrors({ ...errArr });

        return errflag;

    }
    const submitHandler = async (event) => {
        event.preventDefault();

        if (validate()) {
            let data = formData;
            if (data.name == 'Other') {
                data.name = data.other;
            }

            let resp = await API.updateReportTitle(data);
            if (resp) {

                successAlert('Title updated successfully.');
                getReport();
            }
        }

    }
    const successAlert = (msg) => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setAlert(null)}
                confirmBtnBsStyle="info"

            >
                {msg}
            </SweetAlert>
        );
        setModelStatus(false);
        setTimeout(function () {
            setAlert(null);
        }, 2000);
    }


    useEffect(() => {
        if (modalStatus === true) {

            let from = { reportId: item._id, name: '', other: '' };

            if (titleType.includes(item.category)) {
                from.name = item.category;
            } else if (item.category != '') {
                from.name = 'Other';
                from.other = item.category;
            }

            setFormData(from);

        } else {
            setFormData({});
        }
    }, [modalStatus]);

    const handleReportDate = async (date) => {


        let selectedDate = moment(date).format('YYYY-MM-DD');
        set_ReportDate(selectedDate)
        setReportDate(moment(date).format('DD-MM-YYYY'))


    }


   // console.log(parent, "parent")

    return (
        <span>
            {item.category}&nbsp;
            <a onClick={() => setModelStatus(true)}><i className="fa fa-edit"></i></a>
            <Modal show={modalStatus} onHide={() => setModelStatus(false)} dialogClassName="modal-lg">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">Edit Title</Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share">
                    <Row>
                        <Col md={12}>
                            <Form horizontal noValidate validated={validated} onSubmit={e => submitHandler(e)}>
                                <Col sm={12} className="mange-spctop noniborder">

                                    <FormGroup>

                                        <Col sm={4}>
                                            <FormControl componentClass="select" onChange={e => { inputHandler(e); }} name="name" placeholder="Title" value={formData.name ? formData.name : ''} className={'insulin-input ' + (errors.name ? errors.name : '')} required>
                                                <option value="">Select</option>
                                                {titleType.map((row) => {
                                                    return <option value={row} key={row}>{row}</option>
                                                })}

                                            </FormControl>
                                            {formData.name && formData.name === 'Other' &&
                                                <FormControl type="text" name="other" value={formData.other ? formData.other : ''} onChange={e => { inputHandler(e); }} placeholder="Name" className={'insulin-input ' + (errors.other ? errors.other : '')} required />
                                            }

                                        </Col>

                                        {parent != undefined ?
                                            <Col sm={4}>
                                                <Datetime
                                                    timeFormat={false}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    inputProps={{ placeholder: "DD-MM-YYYY" }}
                                                    maxDate={new Date()}
                                                    name={"testReportDate"}
                                                    value={reportDate}
                                                    onChange={e => handleReportDate(e)}
                                                    closeOnSelect

                                                />
                                            </Col>
                                            : <></>

                                        }

                                        <Col sm={2}>
                                            <Button type="submit" className="btn-fill btn btn-primary pull-right">Update</Button>
                                        </Col>

                                    </FormGroup>

                                </Col>
                            </Form>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-fill btn btn-secondary pull-right" onClick={() => setModelStatus(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
}
export default withRouter(ReportTitle);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { postRequestWithToken } from "./helper"

export const addFaq = (data) => postRequestWithToken(data, 'add-faq');

export const addKnowledge = (data) => postRequestWithToken(data, 'add-knowledge');

export const addClinicLink = (data) => postRequestWithToken(data, 'add-clinic-link');

export const addEducatorMessage = (data) => postRequestWithToken(data, 'add-educator-message');

export const changePassword = (data) => postRequestWithToken(data, 'change-password');

export const changeUserPassword = (data) => postRequestWithToken(data, 'change-user-password');

export const addClinicHoliday = (data) => postRequestWithToken(data, 'add-clinic-holiday');

export const holidayDelete = (data) => postRequestWithToken(data, 'holiday-delete');

export const addContactUs = (data) => postRequestWithToken(data, 'contact-us');

export const KnowledgeShowClinic = (data) => postRequestWithToken(data, 'knowledge-show-clinic-status');

export const LinkShowClinic = (data) => postRequestWithToken(data, 'link-show-clinic-status');

export const MessageShowClinic = (data) => postRequestWithToken(data, 'message-show-clinic-status');

export const ClinicHolidayHistory = (data) => postRequestWithToken(data, 'holiday-list');

export const updateClinicWeekOff = (data) => postRequestWithToken(data, 'week-off-update');

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import moment from "moment";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { uploadFileAction, uploadMultiFileAction } from "Front/actions/master";
import * as API from "Front/api/master";
import {
  addFaqAction,
  addKnowledgeAction,
  addClinicLinkAction,
  addEducatorMessageAction,
  KnowledgeShowClinicAction,
  LinkShowClinicAction,
  MessageShowClinicAction,
} from "Front/actions/settings";
import Checkbox from "Front/elements/CustomCheckbox/CustomCheckbox.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import "react-select/dist/react-select.css";
import $ from "jquery";
import {
  educatorMsgListAction,
  categoryListAction,
  knowledgeListAction,
  printShareDocumentAction,
  myClinicListAction,
  knowledgeMsgListAction,
  s3SiginedUrlArrayActionUploadDocumentClinic,
  s3SiginedUrlArrayActionUploadDocumentAdmin
} from "Front/actions/home";
import faqSampleImage from "../../../assets/img/aahar.jpg";
import { appConstants } from "Front/_constants/app.constants.js";
import loadingImg from "../../../assets/img/loading.gif";
import ReactPlayer from "react-player";
//import { languageOptions} from 'Admin/variables/Variables.jsx';
import { languageListAction } from "Front/actions/language";

import { confirmAlert } from "react-confirm-alert";
import ViewBox from "../../../components/ViewBox";
import ImageComponent from "Front/components/common/common.js";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

let videoFileError = [];
let clinicFileError = [];
var isKnowledgeMsgListAdded = false;

class ManageRequest extends Component {
  constructor(props) {
    //console.log(props);
    super(props);
    this.state = {
      formArr: [],
      patient_idError: null,
      titleError: null,
      first_nameError: null,
      last_nameError: null,
      genderError: "",
      dobError: null,
      ageError: null,
      emailError: null,
      heightError: null,
      weightError: null,
      cityError: null,
      remarkError: null,
      formData: {
        faqItems: [
          {
            question: { english: "", hindi: "" },
            answer: { english: "", hindi: "" },
          },
        ],
        //mediaItems: [{ title: "", category: { id: "", name: "" }, file: "" }],
        mediaItems: [{ title: [], category: { id: "", name: "" }, file: [] }],
        clinicItems: [{ name: "", link: "", description: "", file: "" }],
        educatorItems: [{ name: "", description: "" }],
        educatorMessage: [],
        faqfile: "",
      },
      showProcessing: false,
      fileUploadSucess: false,
      radio: "1",
      radioVariant: "1",
      knowledgeModal: false,
      progressBarModal: false,
      addFaq: false,
      addVideoDocument: false,
      addMyClinic: false,
      addMyEducatorMessage: false,
      manageRequestTitle: "",
      educator_msg_list: [],
      myClinic_msg_list: [],
      knowledge_msg_list: [],
      alert: null,
      multipleSelect: [null],
      categoryList: [],
      checkboxOptions: [],
      msg: "This field is required",
      languageOptions: [],
      languageList: [],
      formValidation: {
        faqfile: false,
        faqItems: [{ qEng: true, qHindi: true, aEng: true, aHindi: true }],
        mediaItems: [{ title: true, category: true, file: true }],
        //clinicItems:[{ name:true, link:true, description:true, file:true}],
        clinicItems: [{ name: true, description: true, file: true }],
        educatorItems: [{ name: true, description: true }],
      },
      modelViewData: {},
      fileStatus: false,
      documentButtonStatus: true,
      s3url: appConstants.s3UploadUrl,
      addMore: true,
      viewBoxData: { show: false, type: "image", path: "" },
      hideVideoDoc: false,
      hideMyClinic: false,
      moreThanOneDot: false
    };
    this.s3UrlIntervalKnowledgeFlag = null;
    this.s3UrlIntervalMyClinicFlag = null;
    this.handleDob = this.handleDob.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleClinicShowStatus = this.handleClinicShowStatus.bind(this);
    this.imageProgressBar = this.imageProgressBar.bind(this);
    this.editKnowledge = this.editKnowledge.bind(this);
    this.reSetFormData = this.reSetFormData.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.hideViewBox = this.hideViewBox.bind(this);
    this.getViewButton = this.getViewButton.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.languageListAction({
      limit: 100,
      offset: 0,
      order: "lanId",
      direction: "asc",
    });

    if (this.props.location.pathname === "/educator-message") {
      this.props.educatorMsgListAction(this.state);
    } else {
      this.props.categoryListAction(this.state);

      if (
        sessionStorage.getItem("categoryList") &&
        sessionStorage.getItem("categoryList") !== ""
      ) {
        this.setState({
          categoryList: JSON.parse(sessionStorage.getItem("categoryList")),
        });
      } else {
        this.props.categoryListAction(this.state);
      }

      //this.props.printShareDocumentAction(this.state);
      this.props.knowledgeListAction(this.state);
      this.props.myClinicListAction(this.state);
      this.props.knowledgeMsgListAction(this.state);
    }

    let _this = this;
    appConstants.socket.on("master-update", function (data) {
      //  console.log(data);
      setTimeout(function () {
        if (
          data.type === "knowledge" &&
          this.props.location.pathname !== "/educator-message"
        ) {
          _this.props.knowledgeListAction(_this.state);
          _this.props.knowledgeMsgListAction(_this.state);
        } else if (
          data.type === "educator" &&
          this.props.location.pathname === "/educator-message"
        ) {
          _this.props.educatorMsgListAction(_this.state);
        } else if (
          data.type === "my-clinic" &&
          this.props.location.pathname !== "/educator-message"
        ) {
          _this.props.myClinicListAction(_this.state);
        }
      }, 1000);
    });
    this.s3UrlIntervalMyClinicFlag = setInterval(() => {
      if (this.state.myClinic_msg_list.length > 0) {
        let myClinicData = this.state.myClinic_msg_list;
        let payload = [];
        for (let i in myClinicData) {
          let arr = myClinicData[i].image.split(".");
          let output = arr.pop();
          if (output === "pdf") {
            let obj = {
              filePath: myClinicData[i].image,
              id: myClinicData[i]._id,
            };
            payload.push(obj)
          }
        }
        this.props.s3SiginedUrlArrayActionUploadDocumentClinic({ imageArray: payload });
      }
    }, 810000);

    this.s3UrlIntervalKnowledgeFlag = setInterval(() => {
      if (this.state.knowledge_msg_list.length > 0) {
        let knowledgeData = this.state.knowledge_msg_list;
        let payload = [];
        for (let i in knowledgeData) {
          let arr = knowledgeData[i].image.split(".");
          let output = arr.pop();
          if (output === "pdf") {
            let obj = {
              filePath: knowledgeData[i].image,
              id: knowledgeData[i]._id,
            };
            payload.push(obj)
          }
        }
        this.props.s3SiginedUrlArrayActionUploadDocumentAdmin({ imageArray: payload });
      }
    }, 810000);
  }

  componentWillUnmount() {
    clearInterval(this.s3UrlIntervalMyClinicFlag);
    clearInterval(this.s3UrlIntervalKnowledgeFlag);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLanguageList !== this.props.isLanguageList &&
      this.props.isLanguageList === false
    ) {
      this.setState({
        languageList: nextProps.LanguageList.data,
      });

      let list = nextProps.LanguageList.data;
      let languageOptions = [];
      for (let i in list) {
        languageOptions.push({ value: list[i].lanId, label: list[i].name });
      }

      this.setState({
        languageOptions: languageOptions,
      });

      let languages = localStorage.language
        ? localStorage.language.split(",")
        : [];

      if (languages.length > 0) {
        let languageList = languageOptions.filter((key, i) => {
          return languages.includes(key.value.toString());
        });

        this.setState({ languageList: languageList });
      }
    }

    if (
      nextProps.isEducatorMsgList !== this.props.isEducatorMsgList &&
      this.props.isEducatorMsgList === false
    ) {
      let messageData = nextProps.EducatorMsgList.messageList;
      for (let i in messageData) {
        if (messageData[i].showClinics.length > 0) {
          let local_index = messageData[i].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index !== -1) {
            messageData[i].clinicShowStatus = false;
          } else {
            messageData[i].clinicShowStatus = true;
          }
        }
      }
      this.setState({ educator_msg_list: messageData });
    }

    if (
      nextProps.isMyClinicList !== this.props.isMyClinicList &&
      this.props.isMyClinicList === false
    ) {
      let myClinicData = nextProps.myClinicList.data.data;
      let payload = [];
      for (let i in myClinicData) {
        let arr = myClinicData[i].image.split(".");
        let output = arr.pop();
        if (output === "pdf") {
          let obj = {
            filePath: myClinicData[i].image,
            id: myClinicData[i]._id,
          };
          payload.push(obj)
        }

        if (myClinicData[i].showClinics.length > 0) {
          let local_index = myClinicData[i].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index !== -1) {
            myClinicData[i].clinicShowStatus = false;
          } else {
            myClinicData[i].clinicShowStatus = true;
          }
        }
      }
      if (payload.length > 0)
        this.props.s3SiginedUrlArrayActionUploadDocumentClinic({ imageArray: payload });
      this.setState({ myClinic_msg_list: myClinicData });
    }




    if (
      nextProps.isMsgKnowledgeList !== this.props.isMsgKnowledgeList &&
      this.props.isMsgKnowledgeList === false
    ) {
      let knowledgeData = nextProps.msgKnowledgeList.data.data;
      let payload = [];
      for (let i in knowledgeData) {
        let arr = knowledgeData[i].image.split(".");
        let output = arr.pop();
        if (output === "pdf") {
          let obj = {
            filePath: knowledgeData[i].image,
            id: knowledgeData[i]._id,
          };
          payload.push(obj)
        }

        if (knowledgeData[i].showClinics.length > 0) {
          let local_index = knowledgeData[i].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index !== -1) {
            knowledgeData[i].clinicShowStatus = false;
          } else {
            knowledgeData[i].clinicShowStatus = true;
          }
        }
      }
      if (payload.length > 0)
        this.props.s3SiginedUrlArrayActionUploadDocumentAdmin({ imageArray: payload });
      this.setState({ knowledge_msg_list: knowledgeData });
    }

    if (nextProps.isS3SignedUrlArrUploadDocumentClinic !== this.props.isS3SignedUrlArrUploadDocumentClinic) {
      this.setState({ myClinic_msg_list: [] });
      let myClinicMsgList = this.state.myClinic_msg_list
      for (let i in myClinicMsgList) {
        let arr = myClinicMsgList[i].image.split(".");
        let output = arr.pop();
        if (output === "pdf") {
          for (let j in nextProps.s3SignedUrlArrClinic) {
            if (nextProps.s3SignedUrlArrClinic[j].id === myClinicMsgList[i]._id) {
              myClinicMsgList[i]["s3Path"] = nextProps.s3SignedUrlArrClinic[j].s3Path
            }
          }
        }
      }



      this.setState({ myClinic_msg_list: myClinicMsgList });
    }

    if (nextProps.isS3SignedUrlArrUploadDocumentAdmin !== this.props.isS3SignedUrlArrUploadDocumentAdmin) {
      this.setState({ knowledge_msg_list: [] });
      let knowledgeMsgList = this.state.knowledge_msg_list
      for (let i in knowledgeMsgList) {
        let arr = knowledgeMsgList[i].image.split(".");
        let output = arr.pop();
        if (output === "pdf") {
          for (let j in nextProps.s3SignedUrlArrAdmin) {
            if (nextProps.s3SignedUrlArrAdmin[j].id === knowledgeMsgList[i]._id) {
              knowledgeMsgList[i]["s3Path"] = nextProps.s3SignedUrlArrAdmin[j].s3Path
            }
          }
        }
      }

      this.setState({ knowledge_msg_list: knowledgeMsgList });
    }


    if (nextProps.isUploadFile !== this.props.isUploadFile) {
      const params = {
        file: nextProps.uploadFile.file_name,
        faq_data: this.state.formData.faqItems,
      };
      this.props.addFaqAction(params);
    }

    let formData = this.state.formData;

    if (this.state.addVideoDocument) {
      if (
        nextProps.isMultiUploadFile !== this.props.isMultiUploadFile &&
        this.props.isMultiUploadFile === false
      ) {
        this.imageProgressBar();

        let uploadedFiles = nextProps.MultiUploadFile.file_names;
        let f = 0;

        for (let k in formData["mediaItems"]) {
          if (
            formData["mediaItems"][k].file &&
            formData["mediaItems"][k].file.length > 0
          ) {
            let nkowFiles = formData["mediaItems"][k].file;

            for (let x in nkowFiles) {
              if (nkowFiles[x]) {
                formData["mediaItems"][k].file[x] = uploadedFiles[f];
                f++;
              }
            }
          }
        }

        /*nextProps.MultiUploadFile.file_names.map((key, i) => {
                    formData['mediaItems'][i].file = key;
                    return '';
                });*/

        this.setState({ formData: formData });

        const params = {
          knowledge_data: formData.mediaItems,
        };

        if (
          nextProps.MultiUploadFile &&
          nextProps.MultiUploadFile.status === "Success"
        ) {
          this.props.addKnowledgeAction(params);
          this.setState({ showProcessing: false });
        }
      }

      this.setState({ showProcessing: false });
    } else if (this.state.addMyClinic) {
      // clinicFileError = [];

      if (
        nextProps.isMultiUploadFile !== this.props.isMultiUploadFile &&
        this.props.isMultiUploadFile === false
      ) {
        this.imageProgressBar();
        nextProps.MultiUploadFile.file_names.map((key, i) => {
          formData["clinicItems"][i].file = key;
          return "";
        });

        this.setState({ formData: formData });
        const params = {
          clinic_link_data: this.state.formData.clinicItems,
        };

        if (
          nextProps.MultiUploadFile &&
          nextProps.MultiUploadFile.status === "Success"
        ) {
          this.props.addClinicLinkAction(params);
          this.setState({ showProcessing: false });
        }
      }

      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isMultiUploadFileError !== this.props.isMultiUploadFileError
    ) {
      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isAddEducatorMsg !== this.props.isAddEducatorMsg &&
      this.props.isAddEducatorMsg === false
    ) {
      this.successAlert();
      this.props.educatorMsgListAction(this.state);
      this.setState({
        formData: {
          //mediaItems: [{ title: "", category: { id: "", name: "" }, file: "" }],
          mediaItems: [{ title: [], category: { id: "", name: "" }, file: [] }],
          clinicItems: [{ name: "", link: "", description: "", file: "" }],
          educatorItems: [{ name: "", description: "" }],
          educatorMessage: [],
          faqfile: "",
        },
        multipleSelect: [],
      });
    }

    if (
      nextProps.isAddClinicLink !== this.props.isAddClinicLink &&
      this.props.isAddClinicLink === false
    ) {
      let _this = this;

      this.setState({
        formData: {
          //mediaItems: [{ title: "", category: { id: "", name: "" }, file: "" }],
          mediaItems: [{ title: [], category: { id: "", name: "" }, file: [] }],
          clinicItems: [{ name: "", link: "", description: "", file: "" }],
          educatorItems: [{ name: "", description: "" }],
          educatorMessage: [],
          faqfile: "",
        },
        multipleSelect: [],
      });

      setTimeout(function () {
        _this.successAlert();
      }, 1000);
      this.props.myClinicListAction(this.state);
    }

    if (nextProps.isAddFaq !== this.props.isAddFaq) {
      this.successAlert();
    }

    if (nextProps.isAddKnowledge !== this.props.isAddKnowledge) {
      //this.successAlert();
      let _this = this;
      this.setState({
        formData: {
          //mediaItems: [{ title: "", category: { id: "", name: "" }, file: "" }],
          mediaItems: [{ title: [], category: { id: "", name: "" }, file: [] }],
          clinicItems: [{ name: "", link: "", description: "", file: "" }],
          educatorItems: [{ name: "", description: "" }],
          educatorMessage: [],
          faqfile: "",
        },
        multipleSelect: [],

        formValidation: {
          faqfile: false,
          faqItems: [{ qEng: true, qHindi: true, aEng: true, aHindi: true }],
          mediaItems: [{ title: true, category: true, file: true }],
          clinicItems: [{ name: true, description: true, file: true }],
          educatorItems: [{ name: true, description: true }],
        },
      });
      setTimeout(function () {
        if (isKnowledgeMsgListAdded) {
          _this.successAlert();
          isKnowledgeMsgListAdded = false;
        }
      }, 1000);
      this.props.knowledgeMsgListAction(this.state);
    }

    if (nextProps.isCategoryList !== this.props.isCategoryList) {
      this.setState({ categoryList: nextProps.CategoryList.data.data });
      sessionStorage.setItem(
        "categoryList",
        JSON.stringify(nextProps.CategoryList.data.data)
      );
    }

    //=============
    if (nextProps.isKnowledgeList !== this.props.isKnowledgeList) {
      this.setState({ knowledgeList: nextProps.KnowledgeList.data.data });

      // let docList = this.state.patientInfo.documentList;
      // let videos = [];
      // let documents = [];
      // for (let i in docList) {
      //     if (docList[i].docType === 'document') {
      //         documents.push({ id: docList[i].documentId, name: docList[i].documentName, link: docList[i].image });
      //     } else {
      //         videos.push({ id: docList[i].documentId, name: docList[i].documentName, link: docList[i].image });
      //     }
      // }

      // this.setState({ documents: documents });
      // this.setState({ videos: videos });
    }

    if (nextProps.isPrintShareDocument !== this.props.isPrintShareDocument) {
      this.successAlert();
    }
    //console.log(nextProps);
    if (nextProps.isAddEducatorMsgError !== this.props.isAddEducatorMsgError) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Maximum 12 Educator messages can be added"
            //onConfirm={() => this.hideAlert()}
            //onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          ></SweetAlert>
        ),
      });
      /*nextProps.AddEducatorMsg.errors.map((key, i) => {
              this.setState({ [(key.param) + "Error"]: key.msg })
            });*/

      let _this = this;
      setTimeout(function () {
        _this.hideAlert();
      }, 3000);
    }
  }

  editKnowledge(row) {
    let arr = row.image.split(".");
    let output = arr.pop();
    if (output === "mp4") {
      row.type = "video";
    } else if (output === "pdf") {
      row.type = "pdf";
    } else {
      row.type = "image";
    }
    this.setState({
      viewBoxData: { show: false, type: row.type, path: row.image, ...row },
    });

    if (row.type != "pdf") {
      let tempData = { ...row }
      tempData.type = row.type
      tempData.path = row.image
      const url = `/view-box`;
      var newWindow = window.open(url);
      newWindow.params = tempData;
    }

  }

  imageProgressBar() {
    var i = 0;
    let _this = this;

    if (i === 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      var width = 1;
      var id = setInterval(frame, 10);
      function frame() {
        if (width >= 100) {
          clearInterval(id);
          i = 0;
          if (width === 100) {
            _this.setState({ progressBarModal: false });
          }
        } else {
          width++;
          elem.style.width = width + "%";
        }
      }
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;

    if (e.target.name === "age") {
      // var today = new Date();
      field["dob"] = moment().subtract(e.target.value, "years"); //(today.getFullYear()-e.target.value) + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    }
    this.setState({ formData: field });
  }

  handleFileChange(e) {
    e.preventDefault();
    let formData = this.state.formData;
    let formValidation = this.state.formValidation;
    formData["faqfile"] = e.target.files[0];

    formValidation["faqfile"] = false;
    formValidation["faqItems"] = [
      { qEng: true, qHindi: true, aEng: true, aHindi: true },
    ];
    this.setState({ formData: formData, formValidation: formValidation });
  }
  handleDob(date) {
    date = new Date(date);
    let field = this.state.formData;
    field["dob"] =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    field["age"] = "";
    this.setState({ formData: field });
  }

  addMoreField(e, type) {
    e.preventDefault();

    let formData = this.state.formData;
    let formValidation = this.state.formValidation;
    if (type === "faq") {
      formData["faqItems"].push({
        question: { english: "", hindi: "" },
        answer: { english: "", hindi: "" },
      });
      formValidation["faqItems"].push({
        qEng: true,
        qHindi: true,
        aEng: true,
        aHindi: true,
      });
    } else if (type === "document") {
      //formData['mediaItems'].push({ title: "", category: { id: "", name: "" }, file: "" });
      formData["mediaItems"].push({
        title: [],
        category: { id: "", name: "" },
        file: [],
      });
      formValidation["mediaItems"].push({
        title: true,
        category: true,
        file: true,
      });
    } else if (type === "clinic") {
      formData["clinicItems"].push({
        name: "",
        link: "",
        description: "",
        file: "",
      });
      //formValidation['clinicItems'].push({ name:true, link:true, description:true, file:true});
      formValidation["clinicItems"].push({
        name: true,
        description: true,
        file: true,
      });
    } else if (type === "educator") {
      formData["educatorItems"].push({ name: "", description: "" });
      formValidation["educatorItems"].push({ name: true, description: true });
    }

    this.setState({ formData: formData, formValidation: formValidation });
  }

  removeField(e, idx, type) {
    e.preventDefault();
    let faqItems = this.state.formData;
    let formValidation = this.state.formValidation;
    let multipleSelect = this.state.multipleSelect;

    if (type === "removeFaq") {
      faqItems.faqItems.splice(idx, 1);
      formValidation.faqItems.splice(idx, 1);
    } else if (type === "removeDocument") {
      faqItems.mediaItems.splice(idx, 1);
      formValidation.mediaItems.splice(idx, 1);
      videoFileError[idx] = "";
      multipleSelect.splice(idx, 1);
      this.setState({ fileStatus: false });

      //alert(1);
      //fruits.pop();

      // let fileStatus = false;
      // for(let x in videoFileError){
      //     if(videoFileError[x]){
      //       fileStatus = true;
      //     }
      // }
      // this.setState({ fileStatus: fileStatus });
    } else if (type === "removeClinic") {
      faqItems.clinicItems.splice(idx, 1);
      formValidation.clinicItems.splice(idx, 1);
      clinicFileError[idx] = "";
      this.setState({ fileStatus: false });
    } else if (type === "removeEducator") {
      faqItems.educatorItems.splice(idx, 1);
      formValidation.educatorItems.splice(idx, 1);
    }

    this.setState({
      formData: faqItems,
      formValidation: formValidation,
    });
  }

  manageBox(action, row = {}) {
    let formData = this.state.formData;
    let formValidation = this.state.formValidation;
    formData["faqItems"] = [
      {
        question: {
          english: "",
          hindi: "",
        },
        answer: {
          english: "",
          hindi: "",
        },
      },
    ];

    formValidation = {
      faqfile: false,
      faqItems: [{ qEng: true, qHindi: true, aEng: true, aHindi: true }],
      mediaItems: [{ title: true, category: true, file: true }],
      //clinicItems:[{ name:true, link:true, description:true, file:true}],
      clinicItems: [{ name: true, description: true, file: true }],
      educatorItems: [{ name: true, description: true }],
    };

    if (action === "addFaq") {
      this.setState({
        knowledgeModal: true,
        addFaq: true,
        addVideoDocument: false,
        addMyClinic: false,
        addMyEducatorMessage: false,
        manageRequestTitle: "FAQ",
        formData: formData,
        formValidation: formValidation,
        addMore: true,
      });
    } else if (action === "addVideoDocument") {
      let addMore = true;

      if (row && row._id) {
        //console.log(row);
        addMore = false;
        formData.mediaItems[0].title = row.title;
        formData.mediaItems[0].category = row.category;
        formData.mediaItems[0].image = row.image;
        formData.mediaItems[0]._id = row._id;

        let multipleSelect = this.state.multipleSelect;

        let catArr = [];

        if (row.category && row.category.length) {
          row.category.map((key, i) => {
            catArr.push({ value: key.id, label: key.name });
            return "";
          });
        }

        multipleSelect[0] = catArr;
        this.setState({ fileStatus: false });
        this.setState({ multipleSelect: multipleSelect });

        this.setState({ formData: formData });
      }

      this.setState({
        knowledgeModal: true,
        addFaq: false,
        addVideoDocument: true,
        addMyClinic: false,
        addMyEducatorMessage: false,
        manageRequestTitle: "Videos & Documents",
        formData: formData,
        formValidation: formValidation,
        addMore: addMore,
      });
    } else if (action === "addMyClinic") {
      let addMore = true;

      if (row && row.title) {
        addMore = false;
        formData.clinicItems[0].name = row.title;
        formData.clinicItems[0].link = row.link;
        formData.clinicItems[0].image = row.image;
        formData.clinicItems[0].description = row.description;
        formData.clinicItems[0]._id = row._id;

        this.setState({ formData: formData });
      }

      this.setState({
        knowledgeModal: true,
        addFaq: false,
        addVideoDocument: false,
        addMyClinic: true,
        addMyEducatorMessage: false,
        manageRequestTitle: "My Clinic",
        formData: formData,
        formValidation: formValidation,
        addMore: addMore,
      });
    } else if (action === "addMyEducatorMessage") {
      this.setState({
        knowledgeModal: true,
        addFaq: false,
        addVideoDocument: false,
        addMyClinic: false,
        addMyEducatorMessage: true,
        manageRequestTitle: "Educator Message",
        formData: formData,
        formValidation: formValidation,
        addMore: true,
      });
    }
  }
  //video document
  handleQuestionChange(e, idx, type) {
    e.preventDefault();

    let faqItems = this.state.formData;
    let formValidation = this.state.formValidation;

    if (type === "questionEnglish") {
      faqItems.faqItems[idx].question.english = e.target.value;
      //faqItemsError = false;
      formValidation["faqfile"] = false;
    } else if (type === "questionHindi") {
      faqItems.faqItems[idx].question.hindi = e.target.value;
      //faqItemsError = false;
      formValidation["faqfile"] = false;
    } else if (type === "answerEnglish") {
      faqItems.faqItems[idx].answer.english = e.target.value;
      //faqItemsError = false;
      formValidation["faqfile"] = false;
    } else if (type === "answerHindi") {
      faqItems.faqItems[idx].answer.hindi = e.target.value;
      //faqItemsError = false;
      formValidation["faqfile"] = false;
    }

    this.setState({ formData: faqItems });
  }

  handleDocumentChange(e, idx, lang, type) {
    e.preventDefault();
    videoFileError = [];
    let formData = this.state.formData;
    let formValidation = this.state.formValidation;
    lang = lang.toString();
    if (type === "file") {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2);
      if (size > 200) {
        alert("Please select image size less than 200 MB");
        e.target.value = "";
        return false;
      }

      // var fileType = e.target.files[0].type;
      // fileType = fileType.split("/");
      //if(fileType[1] === "mp4" || fileType[1] === "jpeg"  || fileType[1] === "jpg"  || fileType[1] === "png"){
      formData.mediaItems[idx].file[lang] = e.target.files[0];

      formValidation["mediaItems"][idx].file = true;
      formValidation["mediaItems"][idx].title = true;
      var fileName = e.target.files[0].name;
      fileName = fileName.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-");
      formData.mediaItems[idx].title[lang] = fileName[0];
      fileName = fileName.split(".");
      let extensions = fileName[1];
      if (
        extensions === "jpg" ||
        extensions === "png" ||
        extensions === "jpeg" ||
        extensions === "mp4" ||
        extensions === "pdf"
      ) {
        //  if (formData.mediaItems[idx].file && formData.mediaItems[idx].file.size <= 15652261) {
        videoFileError[idx] = "";
        // } else {
        //     videoFileError[idx] = 'Upload video should not be larger than 15 MB.';
        // }

        let fileStatus = false;
        for (let x in videoFileError) {
          if (videoFileError[x]) {
            fileStatus = true;
          }
        }
        this.setState({ fileStatus: fileStatus });
      } else {
        videoFileError[idx] = "Please select valid file.";
        this.setState({ fileStatus: true });
      }
    } else if (type === "title") {
      formData.mediaItems[idx].title[lang] = e.target.value;
      if (e.target.value === "")
        formValidation["mediaItems"][idx].title = false;
      else formValidation["mediaItems"][idx].title = true;
    }

    this.setState({ formData: formData, formValidation: formValidation });
  }

  handleClinicChange(e, idx, type) {
    e.preventDefault();
    //clinicFileError = [];
    let formData = this.state.formData;

    if (type === "file") {
      if (e.target.files[0] !== undefined) {
        formData.clinicItems[idx].file = e.target.files[0];
        var fileName = e.target.files[0].name;
        let dotCnt = 0;
        for (let i = 0; i < fileName.length; i++) {
          if (fileName[i] === ".")
            dotCnt++
        }
        fileName = fileName.split(".");
        let extensions = fileName[1];
        if (
          extensions === "jpg" ||
          extensions === "png" ||
          extensions === "jpeg" ||
          extensions === "mp3" ||
          extensions === "pdf" ||
          extensions === "mp4"
        ) {
          // if (formData.clinicItems[idx].file && formData.clinicItems[idx].file.size <= 15652261) {
          clinicFileError[idx] = "";
          // } else {
          //     clinicFileError[idx] = 'Upload video should not be larger than 15 MB.';
          // }
          let fileStatus = false;
          for (let x in videoFileError) {
            if (videoFileError[x]) {
              fileStatus = true;
            }
          }
          this.setState({ fileStatus: fileStatus });
        } else {
          if (dotCnt > 1)
            clinicFileError[idx] = "There Should Be Only One Dot(.) In The File Name";
          else clinicFileError[idx] = "Please select valid file.";
          this.setState({ fileStatus: true });
        }
      } else {
        formData.clinicItems[idx].file = "";
        clinicFileError[idx] = ""
      }

    } else if (type === "name") {
      formData.clinicItems[idx].name = e.target.value;
    } else if (type === "link") {
      formData.clinicItems[idx].link = e.target.value;
    } else if (type === "description") {
      formData.clinicItems[idx].description = e.target.value;
    }

    this.setState({ formData: formData });
  }

  handleEducatorChange(e, idx, type) {
    //e.preventDefault();
    let formData = this.state.formData;

    if (type === "name") {
      formData.educatorItems[idx].name = e.target.value;
    } else if (type === "description") {
      formData.educatorItems[idx].description = e.target.value;
    } else if (type === "message") {
      let checkboxOptions = this.state.checkboxOptions;
      let formData = this.state.formData;

      if (e.target.checked === true) {
        formData.educatorMessage.push({
          id: e.target.id,
          message: e.target.name,
        });
        checkboxOptions[idx] = true;
      } else {
        checkboxOptions[idx] = false;

        formData.educatorMessage.map((key, i) => {
          if (key.id === e.target.id) {
            delete formData.educatorMessage[i];
          }
          return "";
        });
      }

      this.setState({ checkboxOptions: checkboxOptions, formData: formData });
    }
    this.setState({ formData: formData });
  }

  handleDocumentCategoryChange(value, idx) {
    let multipleSelect = this.state.multipleSelect;
    let formValidation = this.state.formValidation;

    if (value.length !== 0) formValidation["mediaItems"][idx].category = true;
    else formValidation["mediaItems"][idx].category = false;

    multipleSelect[idx] = value;
    this.setState({ multipleSelect: multipleSelect });

    let mediaItems = this.state.formData;
    let catArr = [];

    if (value && value.length) {
      value.map((key, i) => {
        catArr.push({ id: key.value, name: key.label });
        return "";
      });
    }

    mediaItems.mediaItems[idx].category = catArr;

    this.setState({ formData: mediaItems, formValidation: formValidation });
  }

  hideViewBox = () => {
    this.setState({ viewBoxData: { show: false, type: "image", path: "" } });
  };

  addAction(e) {
    e.preventDefault();

    if (this.state.addFaq) {
      let formValidation = this.state.formValidation.faqItems;
      let faqValid = this.state.formValidation;
      let formData = this.state.formData;

      let validation = true;

      for (let i in formValidation) {
        if (formData.faqItems[i].question.english === "") {
          faqValid["faqItems"][i].qEng = false;
          validation = false;
          faqValid["faqfile"] = false;
        }
        if (formData.faqItems[i].question.hindi === "") {
          faqValid["faqItems"][i].qHindi = false;
          validation = false;
          faqValid["faqfile"] = false;
        }
        if (formData.faqItems[i].answer.english === "") {
          faqValid["faqItems"][i].aEng = false;
          validation = false;
          faqValid["faqfile"] = false;
        }
        if (formData.faqItems[i].answer.hindi === "") {
          faqValid["faqItems"][i].aHindi = false;
          validation = false;
          faqValid["faqfile"] = false;
        }
      }

      this.setState({ formValidation: faqValid });

      if (validation) {
        this.setState({ knowledgeModal: false });
        const params = {
          faq_data: this.state.formData.faqItems,
        };

        this.props.addFaqAction(params);

        let formValidation = this.state.formValidation;
        formValidation["faqfile"] = false;
        this.setState({ formValidation: formValidation });
      }

      if (this.state.formData.faqfile !== "" && validation === false) {
        this.props.uploadFileAction(this.state.formData);
        this.setState({ knowledgeModal: false });
      } else {
        let formValidation = this.state.formValidation;
        formValidation["faqfile"] = true;
        this.setState({ formValidation: formValidation });
      }
    } else if (this.state.addVideoDocument) {
      let formValidation = this.state.formValidation.mediaItems;
      let faqValid = this.state.formValidation;
      let formData = this.state.formData;
      let validation = true;
      // this.setState({ showProcessing: true });
      //console.log(formData, "formData");
      for (let i in formValidation) {
        if (
          formData.mediaItems[i].title.length === 0 ||
          formData.mediaItems[i].title[1] === ""
        ) {
          faqValid["mediaItems"][i].title = false;
          validation = false;
        }
        if (
          formData.mediaItems[i].file.length === 0 ||
          formData.mediaItems[i].title[1] === ""
        ) {
          faqValid["mediaItems"][i].file = false;
          validation = false;
        }
        if (
          formData.mediaItems[i].category.id === "" ||
          formData.mediaItems[i].category.name === ""
        ) {
          faqValid["mediaItems"][i].category = false;
          validation = false;
        }
      }

      //console.log(faqValid, "faqValid");
      this.setState({ formValidation: faqValid });

      if (validation === true) {
        let fileArr = [];
        this.state.formData.mediaItems.map((key, i) => {
          let files = key.file;
          files.map((file, ii) => {
            fileArr.push(file);
            return "";
          });
          return "";
        });
        this.setState({ knowledgeModal: false, progressBarModal: true });
        //console.log(fileArr)
        if (fileArr && fileArr.length > 0) {
          //console.log(fileArr)
          if (!isKnowledgeMsgListAdded) {
            this.props.uploadMultiFileAction(fileArr);
            this.setState({ showProcessing: true });
            isKnowledgeMsgListAdded = true;
          }
        }
      }
    } else if (this.state.addMyClinic) {
      let formValidation = this.state.formValidation.clinicItems;
      let faqValid = this.state.formValidation;
      let formData = this.state.formData;
      let validation = true;
      // this.setState({ showProcessing: true});

      for (let i in formValidation) {
        if (formData.clinicItems[i].name === "") {
          faqValid["clinicItems"][i].name = false;
          validation = false;
        }
        if (formData.clinicItems[i].file === "") {
          faqValid["clinicItems"][i].file = false;
          validation = false;
        }
      }
      this.setState({ formValidation: faqValid });

      if (validation === true) {
        let fileArr = [];
        //let withFile = [];
        //let withOutFile = [];
        this.state.formData.clinicItems.map((key, i) => {
          if (key.file === "") {
            //console.log('key.file 1', key.file)
          } else {
            fileArr.push(key.file);
          }
          return "";
        });

        const params = {
          clinic_link_data: this.state.formData.clinicItems,
        };

        this.setState({ knowledgeModal: false });
        //this.props.uploadMultiFileAction(fileArr);
        if (fileArr && fileArr.length > 0) {
          if (
            clinicFileError &&
            clinicFileError.length > 0 &&
            clinicFileError[0] === ""
          ) {
            this.setState({ progressBarModal: true });
            this.props.uploadMultiFileAction(fileArr);
          }
        } else {
          this.setState({ showProcessing: true });
          this.props.addClinicLinkAction(params);
        }
      }
    } else if (this.state.addMyEducatorMessage) {
      let formValidation = this.state.formValidation.educatorItems;
      let faqValid = this.state.formValidation;
      let formData = this.state.formData;
      let validation = true;

      for (let i in formValidation) {
        if (formData.educatorItems[i].name === "") {
          faqValid["educatorItems"][i].name = false;
          validation = false;
        }
        // if (formData.educatorItems[i].description === '') {
        //     faqValid['educatorItems'][i].description = false;
        //     validation = false;
        // }
      }
      this.setState({ formValidation: faqValid });

      if (validation === true) {
        const params = {
          educator_message: this.state.formData.educatorItems,
          message: this.state.formData.educatorMessage,
        };
        this.setState({ knowledgeModal: false });
        this.props.addEducatorMessageAction(params);
      }
    }
  }

  reSetFormData() {
    this.setState({ knowledgeModal: false });
    this.setState({
      formData: {
        faqItems: [
          {
            question: { english: "", hindi: "" },
            answer: { english: "", hindi: "" },
          },
        ],
        //mediaItems: [{ title: "", category: { id: "", name: "" }, file: "" }],
        mediaItems: [{ title: [], category: { id: "", name: "" }, file: [] }],
        clinicItems: [{ name: "", link: "", description: "", file: "" }],
        educatorItems: [{ name: "", description: "" }],
        educatorMessage: [],
        faqfile: "",
      },
    });
    this.setState({
      formValidation: {
        faqfile: false,
        faqItems: [{ qEng: true, qHindi: true, aEng: true, aHindi: true }],
        mediaItems: [{ title: true, category: true, file: true }],
        //clinicItems:[{ name:true, link:true, description:true, file:true}],
        clinicItems: [{ name: true, description: true, file: true }],
        educatorItems: [{ name: true, description: true }],
      },
    });

    this.setState({ multipleSelect: [] });
    clinicFileError = [];
  }

  successAlert() {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       //onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       File Saved Successfullys.
    //     </SweetAlert>
    //   ),
    // });

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>File Saved Successfully</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  handleClinicShowStatus(e, index) {
    let data = this.state.knowledge_msg_list;
    if (data[index].clinicShowStatus) {
      data[index]["clinicShowStatus"] = false;
    } else {
      data[index]["clinicShowStatus"] = true;
    }
    let knowledge_data = {
      _id: data[index]._id,
      clinicShowStatus: data[index].clinicShowStatus,
      clinicId: data[index].clinicId,
    };
    this.setState({ knowledge_msg_list: data });
    this.props.KnowledgeShowClinicAction(knowledge_data);
  }

  handleLinkShowStatus(e, index) {
    let data = this.state.myClinic_msg_list;
    if (data[index].clinicShowStatus) {
      data[index]["clinicShowStatus"] = false;
    } else {
      data[index]["clinicShowStatus"] = true;
    }
    let link_data = {
      _id: data[index]._id,
      clinicShowStatus: data[index].clinicShowStatus,
      clinicId: data[index].clinicId,
    };
    this.setState({ myClinic_msg_list: data });
    this.props.LinkShowClinicAction(link_data);
  }

  handleMessageShowStatus(e, index) {
    let data = this.state.educator_msg_list;

    let count = 0;
    if (data[index].clinicShowStatus) {
      data[index]["clinicShowStatus"] = false;
    } else {
      for (let j in data) {
        if (data[j].clinicShowStatus) {
          count++;
        }
      }
      if (count < 12) {
        data[index]["clinicShowStatus"] = true;
      }
    }
    if (count < 12) {
      let message_data = {
        _id: data[index]._id,
        clinicShowStatus: data[index].clinicShowStatus,
        clinicId: data[index].clinicId,
      };
      this.setState({ educator_msg_list: data });
      this.props.MessageShowClinicAction(message_data);

    } else {
      data[index]["clinicShowStatus"] = false;

      this.setState({ educator_msg_list: data });

      //alert("You have selected 12 messages.");

      // this.setState({
      //   alert: (
      //     <SweetAlert
      //       warning
      //       style={{ display: "block", marginTop: "-100px" }}
      //       title="Error!"
      //       //onConfirm={() => this.hideAlert()}
      //       //onCancel={() => this.hideAlert()}
      //       confirmBtnBsStyle="info"
      //     >
      //       Already 12 message available in this clinic.
      //     </SweetAlert>
      //   ),
      // });

      this.state._notificationSystem.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: (
          <div>
            {/* <p>{from}</p> */}
            <span>Already 12 message available in this clinic.</span>
          </div>
        ),
        level: "error",
        position: "tr",
        autoDismiss: 2,
      });
      let _this = this;
      setTimeout(function () {
        _this.hideAlert();
      }, 3000);
    }
  }

  deleteMessage(e) {
    let id = e.target.id;
    confirmAlert({
      title: "Confirm to out",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteMessageAction(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteMessageAction(id) {
    API.deleteEducatorMsgAction({ educatorMessageId: id });

    let { educator_msg_list } = this.state;

    educator_msg_list = educator_msg_list.filter((item) => item._id !== id);

    this.setState({ educator_msg_list: educator_msg_list });
  }

  getViewButton(ele) {
    let arr = ele.image.split(".");
    let output = arr.pop();
    return output === "pdf" ?


      <span className="img-showw">
        <a
          target="_blank"
          href={ele.s3Path}
          onClick={(e) => {
            this.editKnowledge(ele);
          }}
        >
          {" "}
          View File
        </a>
      </span> : <span className="img-showw">
        <a
          onClick={(e) => {
            this.editKnowledge(ele);
          }}
        >
          {" "}
          View File
        </a>
      </span>
  }

  render() {
    let selectOptions = [];
    if (this.state.categoryList && this.state.categoryList.length) {
      this.state.categoryList.map((key, i) => {
        selectOptions.push({ value: key._id, label: key.name });
        return "";
      });
    }



    return (
      <div className="upload-document-wrapper">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        <div
          className="actionProcess"
          style={{ display: this.state.showProcessing ? "block" : "none" }}
        >
          <img src={loadingImg} alt="Loading" width="40px" />
          <center>Please Wait</center>
        </div>

        <Modal
          show={this.state.progressBarModal}
          onHide={() => this.setState({ progressBarModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                <div id="myProgress">
                  <div id="myBar"></div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Card
          title={
            this.props.location.pathname === "/educator-message"
              ? ""
              : "*Upload your document/video by click on Upload file button"
          }
          content={
            <Row>
              <Col sm={12} className="pd00">
                <div className="hm-blk manage-bbox"></div>
              </Col>
              <Modal
                // backdrop="static"
                show={this.state.knowledgeModal}
                onHide={() => this.reSetFormData()}
                dialogClassName="modal-lg"
              >
                <Modal.Header
                  closeButton
                  className={
                    this.state.manageRequestTitle == "Videos & Documents"
                      ? "bg-thumbcolor video-document-color"
                      : "bg-thumbcolor"
                  }
                >
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {this.state.manageRequestTitle}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share">
                  <Row>
                    <span
                      className="star"
                      style={{ fontWeight: "bold", paddingLeft: "16px" }}
                    >
                      {this.state.addVideoDocument
                        ? "* Maximum limit of file is 200 MB"
                        : ""}
                    </span>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form horizontal>
                        {this.state.addFaq && (
                          <Col sm={12}>
                            <FormGroup>
                              <Col componentClass={ControlLabel} sm={3}>
                                FAQ File <span className="star">*</span>
                              </Col>
                              <Col sm={6}>
                                <input
                                  className="form-control"
                                  type="file"
                                  name="faqfile"
                                  id="faqfile"
                                  onChange={(e) => {
                                    this.handleFileChange(e);
                                  }}
                                />
                                <span
                                  className="errorMsg"
                                  style={{
                                    display: this.state.formValidation.faqfile
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  {this.state.msg}
                                </span>
                              </Col>
                              <Col sm={3} className="sampleImage">
                                <a href={faqSampleImage} download>
                                  <span>Sample image</span>
                                </a>
                              </Col>
                            </FormGroup>
                            <Col sm={12} className="or-align">
                              <span> or </span>
                            </Col>

                            {this.state.formData.faqItems.map((item, idx) => (
                              <FormGroup
                                key={
                                  this.state.formValidation.faqItems[idx].qEng
                                }
                              >
                                <Col sm={6} md={3}>
                                  Question English{" "}
                                  <span className="star">*</span>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleQuestionChange(
                                        e,
                                        idx,
                                        "questionEnglish"
                                      );
                                    }}
                                  />
                                  <span
                                    className="errorMsg"
                                    style={{
                                      display:
                                        !this.state.formValidation.faqItems[idx]
                                          .qEng &&
                                          !this.state.formData.faqItems[idx]
                                            .question.english
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {this.state.msg}
                                  </span>
                                </Col>
                                <Col sm={6} md={3}>
                                  Question Hindi <span className="star">*</span>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleQuestionChange(
                                        e,
                                        idx,
                                        "questionHindi"
                                      );
                                    }}
                                  />
                                  <span
                                    className="errorMsg"
                                    style={{
                                      display:
                                        !this.state.formValidation.faqItems[idx]
                                          .qHindi &&
                                          !this.state.formData.faqItems[idx]
                                            .question.hindi
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {this.state.msg}
                                  </span>
                                </Col>
                                <Col sm={6} md={3}>
                                  Answer English <span className="star">*</span>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleQuestionChange(
                                        e,
                                        idx,
                                        "answerEnglish"
                                      );
                                    }}
                                  />
                                  <span
                                    className="errorMsg"
                                    style={{
                                      display:
                                        !this.state.formValidation.faqItems[idx]
                                          .aEng &&
                                          !this.state.formData.faqItems[idx]
                                            .answer.english
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {this.state.msg}
                                  </span>
                                </Col>
                                <Col sm={6} md={3}>
                                  Answer Hindi <span className="star">*</span>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleQuestionChange(
                                        e,
                                        idx,
                                        "answerHindi"
                                      );
                                    }}
                                  />
                                  {idx !== 0 && (
                                    <span
                                      className="pull-right manage-req"
                                      onClick={(e) => {
                                        this.removeField(e, idx, "removeFaq");
                                      }}
                                    >
                                      Remove
                                    </span>
                                  )}
                                  <span
                                    className="errorMsg"
                                    style={{
                                      display:
                                        !this.state.formValidation.faqItems[idx]
                                          .aHindi &&
                                          !this.state.formData.faqItems[idx]
                                            .answer.hindi
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {this.state.msg}
                                  </span>
                                </Col>
                              </FormGroup>
                            ))}

                            <Button
                              bsStyle="primary"
                              className="pull-right btn-fill btn-wd"
                              onClick={(e) => {
                                this.addMoreField(e, "faq");
                              }}
                            >
                              Add More
                            </Button>
                          </Col>
                        )}

                        {this.state.addVideoDocument && (
                          <Row>
                            <Col sm={12}>
                              {this.state.addMore === true && (
                                <Button
                                  bsStyle="primary"
                                  className="pull-right btn-fill btn-wd request-btn"
                                  onClick={(e) => {
                                    this.addMoreField(e, "document");
                                  }}
                                >
                                  Add More
                                </Button>
                              )}
                            </Col>
                            <Col sm={12}>
                              {this.state.formData.mediaItems &&
                                this.state.formData.mediaItems.length > 0 &&
                                this.state.formData.mediaItems.map(
                                  (item, idx) => (
                                    <FormGroup key={idx}>
                                      <Col sm={8}>
                                        {this.state.languageList.map(
                                          (itemLan, ij) =>
                                            ij === 0 && (
                                              <Row>
                                                <Col sm={6}>
                                                  {/* File ({itemLan.label})<span className="star">*</span> */}
                                                  Filesss{" "}
                                                  <span className="star">
                                                    *
                                                  </span>
                                                  <input
                                                    className="form-control"
                                                    type="file"
                                                    accept=".jpg,.png,.jpeg,.mp3,.mp4,.pdf"
                                                    onChange={(e) => {
                                                      this.handleDocumentChange(
                                                        e,
                                                        idx,
                                                        itemLan.value,
                                                        "file"
                                                      );
                                                    }}
                                                  />
                                                  <span
                                                    className="errorMsg"
                                                    style={{
                                                      display: !this.state
                                                        .formValidation
                                                        .mediaItems[idx]?.file
                                                        ? "block"
                                                        : "none",
                                                    }}
                                                  >
                                                    {this.state.msg}
                                                  </span>
                                                  <span className="errorMsg">
                                                    {videoFileError[idx]}
                                                  </span>
                                                </Col>
                                                <Col sm={6}>
                                                  {/* Title ({itemLan.label})<span className="star">*</span> */}
                                                  Title{" "}
                                                  <span className="star">
                                                    *
                                                  </span>
                                                  <FormControl
                                                    type="text"
                                                    value={
                                                      this.state.formData
                                                        .mediaItems[idx].title[
                                                        itemLan.value
                                                      ]
                                                        ? this.state.formData
                                                          .mediaItems[idx]
                                                          .title[
                                                        itemLan.value
                                                        ]
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      this.handleDocumentChange(
                                                        e,
                                                        idx,
                                                        itemLan.value,
                                                        "title"
                                                      );
                                                    }}
                                                  />
                                                  <span
                                                    className="errorMsg"
                                                    style={{
                                                      display: !this.state
                                                        .formValidation
                                                        .mediaItems[idx]?.title
                                                        ? "block"
                                                        : "none",
                                                    }}
                                                  >
                                                    {this.state.msg}
                                                  </span>
                                                </Col>
                                              </Row>
                                            )
                                        )}
                                      </Col>

                                      <Col sm={4}>
                                        Category <span className="star">*</span>
                                        <Select
                                          placeholder="Multiple Select"
                                          closeOnSelect={false}
                                          multi={true}
                                          name="multipleSelect"
                                          value={this.state.multipleSelect[idx]}
                                          options={selectOptions}
                                          onChange={(value) => {
                                            this.handleDocumentCategoryChange(
                                              value,
                                              idx
                                            );
                                          }}
                                        />
                                        {idx !== 0 && (
                                          <span
                                            className="pull-right manage-req"
                                            onClick={(e) => {
                                              this.removeField(
                                                e,
                                                idx,
                                                "removeDocument"
                                              );
                                            }}
                                          >
                                            Remove
                                          </span>
                                        )}
                                        <span
                                          className="errorMsg"
                                          style={{
                                            display: !this.state.formValidation
                                              .mediaItems[idx]?.category
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {this.state.msg}
                                        </span>
                                      </Col>
                                    </FormGroup>
                                  )
                                )}
                              <div>
                                <span>
                                  NOTE : For uploading video you can use only <b>mp4</b> format file.
                                </span>
                              </div>
                              <div>
                                <span>
                                  For uploading image you can use only <b>jpg</b>, <b>png</b>, <b>jpeg</b> format file.
                                </span>
                              </div>
                              <div>
                                <span>
                                  For uploading file you can use <b>pdf format</b>.
                                </span>
                              </div>
                              {/*  <div><span>Upload video should not be larger than 15 MB.</span></div>
                                                                         <Button bsStyle="success" className="pull-right btn-fill btn-wd" onClick={e => { this.addMoreField(e, 'document'); }}>Add More</Button> */}
                            </Col>
                          </Row>
                        )}

                        {this.state.addMyClinic && (
                          <Row>
                            <Col sm={12}>
                              {this.state.addMore === true && (
                                <Button
                                  bsStyle="primary"
                                  className="pull-right btn-fill btn-wd request-btn"
                                  onClick={(e) => {
                                    this.addMoreField(e, "clinic");
                                  }}
                                >
                                  Add More
                                </Button>
                              )}
                            </Col>
                            <Col sm={12}>
                              {this.state.formData.clinicItems &&
                                this.state.formData.clinicItems.length > 0 &&
                                this.state.formData.clinicItems.map(
                                  (item, idx) => (
                                    <FormGroup key={idx}>
                                      <Col sm={6} md={6}>
                                        Title of Document{" "}
                                        <span className="star">*</span>
                                        <FormControl
                                          type="text"
                                          value={
                                            this.state.formData.clinicItems[idx]
                                              .name
                                          }
                                          onChange={(e) => {
                                            this.handleClinicChange(
                                              e,
                                              idx,
                                              "name"
                                            );
                                          }}
                                        />
                                        <span
                                          className="errorMsg"
                                          style={{
                                            display:
                                              !this.state.formValidation
                                                .clinicItems[idx].name &&
                                                !this.state.formData.clinicItems[
                                                  idx
                                                ].name
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          {this.state.msg}
                                        </span>
                                      </Col>
                                      <Col sm={6} md={3}>
                                        File <span className="star">*</span>{" "}
                                        {/* File<b className="pull-right orr">OR</b> */}
                                        <input
                                          className="form-control"
                                          type="file"
                                          accept=".jpg,.png,.jpeg,.mp3,.mp4,.pdf"
                                          onChange={(e) => {
                                            this.handleClinicChange(
                                              e,
                                              idx,
                                              "file"
                                            );
                                          }}
                                        />
                                        <span
                                          className="errorMsg"
                                          style={{
                                            display:
                                              !this.state.formValidation
                                                .clinicItems[idx].file &&
                                                !this.state.formData.clinicItems[
                                                  idx
                                                ].file
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          {this.state.msg}
                                        </span>
                                        <span
                                          className="errorMsg"
                                          style={{
                                            display: clinicFileError[idx]
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          {clinicFileError[idx]}
                                        </span>
                                        {idx !== 0 && (
                                          <span
                                            className="pull-right manage-req"
                                            onClick={(e) => {
                                              this.removeField(
                                                e,
                                                idx,
                                                "removeClinic"
                                              );
                                            }}
                                          >
                                            Remove
                                          </span>
                                        )}
                                      </Col>
                                      {/* <Col sm={6} md={3}>
                                                                                        Link<b className="pull-right orr">OR</b>
                                                                                        <FormControl type="text" value={this.state.formData.clinicItems[idx].link} onChange={e => { this.handleClinicChange(e, idx, 'link'); }} />
                                                                                    </Col>
                                                                                    
                                                                                    <Col sm={6} md={3}>
                                                                                        Description
                                                                                    <FormControl type="text" value={this.state.formData.clinicItems[idx].description} onChange={e => { this.handleClinicChange(e, idx, 'description'); }} />
                                                                                        {idx !== 0 &&
                                                                                            <span className="pull-right manage-req" onClick={e => { this.removeField(e, idx, 'removeClinic'); }}>Remove</span>
                                                                                        }
                                                                                    </Col> */}
                                    </FormGroup>
                                  )
                                )}
                              <div>
                                <span>
                                  NOTE : For uploading video you can use only <b>mp4</b> format file.
                                </span>
                              </div>
                              <div>
                                <span>
                                  For uploading image you can use only <b>jpg</b>, <b>png</b>, <b>jpeg</b> format file.
                                </span>
                              </div>
                              <div>
                                <span>
                                  For uploading file you can use <b>pdf format</b>.
                                </span>
                              </div>
                              {/*<div><span>Upload video should not be larger than 15 MB.</span></div>
                                                                       <Button bsStyle="success" className="pull-right btn-fill btn-wd" onClick={e => { this.addMoreField(e, 'clinic'); }}>Add More</Button> */}
                            </Col>
                          </Row>
                        )}

                        {this.state.addMyEducatorMessage && (
                          <Row>
                            <Col sm={12}>
                              <Button
                                bsStyle="primary"
                                className="pull-right btn-fill btn-wd"
                                onClick={(e) => {
                                  this.addMoreField(e, "educator");
                                }}
                              >
                                Add More
                              </Button>
                            </Col>
                            <Col sm={12}>
                              {this.state.formData.educatorItems &&
                                this.state.formData.educatorItems.length > 0 &&
                                this.state.formData.educatorItems.map(
                                  (item, idx) => (
                                    <FormGroup key={idx}>
                                      <Col sm={12}>
                                        Message <span className="star">*</span>
                                        <FormControl
                                          type="text"
                                          maxLength="100"
                                          value={
                                            this.state.formData.educatorItems[
                                              idx
                                            ].name
                                          }
                                          onChange={(e) => {
                                            this.handleEducatorChange(
                                              e,
                                              idx,
                                              "name"
                                            );
                                          }}
                                        />
                                        <span
                                          className="errorMsg"
                                          style={{
                                            display:
                                              !this.state.formValidation
                                                .educatorItems[idx].name &&
                                                !this.state.formData
                                                  .educatorItems[idx].name
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          {this.state.msg}
                                          {this.state.educator_messageError}
                                        </span>
                                      </Col>
                                      {/*} <Col sm={6}>
                                                                                        Description
                                                                                    <FormControl type="text" maxLength="100" value={this.state.formData.educatorItems[idx].description} onChange={e => { this.handleEducatorChange(e, idx, 'description'); }} />
                                                                                        {idx !== 0 &&
                                                                                            <span className="pull-right manage-req" onClick={e => { this.removeField(e, idx, 'removeEducator'); }}>Remove</span>
                                                                                        }
                                                                                    </Col> */}
                                    </FormGroup>
                                  )
                                )}
                              {/* <Button bsStyle="success" className="pull-right btn-fill btn-wd" onClick={e => { this.addMoreField(e, 'educator'); }}>Add More</Button> */}
                              {/*<FormGroup>
                                                                            <Col sm={12} className="mange-spctop">
                                                                                <Row>
                                                                                    {
                                                                                        this.state.educator_msg_list.map((key, i) => {

                                                                                            return (<Col sm={6} key={key._id}><Checkbox
                                                                                                isChecked={this.state.checkboxOptions[i]}
                                                                                                number={key._id}
                                                                                                label={key.message}
                                                                                                name={key.message}
                                                                                                onClick={e => { this.handleEducatorChange(e, i, 'message'); }}
                                                                                            /></Col>)


                                                                                        })
                                                                                    }
                                                                                </Row><br></br>
                                                                            </Col>
                                                                        </FormGroup> */}
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  {this.state.addVideoDocument || this.state.addMyClinic ? (
                    <Button
                      className="btn-fill btn btn-primary pull-right"
                      disabled={this.state.fileStatus}
                      onClick={(e) => {
                        this.addAction(e);
                      }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className="btn-fill btn btn-primary pull-right"
                      onClick={(e) => {
                        this.addAction(e);
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
              {this.props.location.pathname !== "/educator-message" && (
                <Col md={6}>
                  <div className="dd-none">Video & Document List</div>
                  <div
                    className="hm-icons video-document-list"
                    style={{ backgroundColor: "#053C64" }}
                  >
                    <span>
                      <h4>Videos &amp; Documents</h4>
                    </span>
                    {/* <span><img src="../../../images/t1-d.png" width="70px" alt="" /></span><br></br><br></br> */}
                    <span
                      className="upload-document"
                      onClick={(e) => this.manageBox("addVideoDocument")}
                    >
                      Upload File
                    </span>
                  </div>

                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Button
                          className="btn-fill btn btn-primary"
                          onClick={(e) => {
                            let status = this.state.hideVideoDoc ? false : true;
                            this.setState({ hideVideoDoc: status });
                          }}
                        >
                          Uploaded Files{""}
                          {/* ▵ */}
                          {!this.state.hideVideoDoc && <span> ▿</span>}
                        </Button>
                      </Row>
                      <Row className="manage-Req-dr">
                        {this.state.knowledge_msg_list.map((key, i) => {
                          if (
                            (key.status === "active" &&
                              key.addedType === "admin") ||
                            key.addedType === "clinic"
                          ) {
                            return (
                              <Col key={i} sm={12}>
                                {!this.state.hideVideoDoc && (
                                  <div className="my-clinic-label">
                                    {key.status === "active" && (
                                      <Checkbox
                                        isChecked={key.clinicShowStatus}
                                        name={key._id}
                                        number={key._id}
                                        id={key._id}
                                        label={key.title}
                                        onClick={(e) => {
                                          this.handleClinicShowStatus(e, i);
                                        }}
                                      />
                                    )}
                                    {key.status !== "active" && (
                                      <span htmlFor={i}>{key.title}</span>
                                    )}

                                    {/* {(key.addedType === 'admin' || key.clinicId === null || !key.clinicId) ? <span> (Admin) </span> : ""}
                                                                                {(key.status === 'active') ?
                                                                                    <span className="my-clinic-status active-color">Active</span>
                                                                                    :
                                                                                    ((key.status === 'inactive') ?
                                                                                        <span className="my-clinic-status inactive-color">Inactive</span>
                                                                                        :(<span><span style={{cursor:"pointer"}} className="my-clinic-status request-color" onClick={(e) => this.manageBox('addVideoDocument', key)}>Edit</span>&nbsp;<span className="my-clinic-status request-color">Pending &nbsp;</span></span>)
                                                                                    )

                                                                                } */}

                                    {key.addedType === "admin" ||
                                      key.clinicId === null ||
                                      !key.clinicId ? (
                                      <span> (Admin) </span>
                                    ) : (
                                      ""
                                    )}
                                    {key.clinicShowStatus ? (
                                      <span className="my-clinic-status active-color">
                                        Active
                                      </span>
                                    ) : key.status !== "inactive" ? (
                                      <span className="my-clinic-status inactive-color">
                                        Inactive
                                      </span>
                                    ) : (
                                      <span>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="my-clinic-status request-color"
                                          onClick={(e) =>
                                            this.manageBox(
                                              "addVideoDocument",
                                              key
                                            )
                                          }
                                        >
                                          Edit
                                        </span>
                                        &nbsp;
                                        <span className="my-clinic-status request-color">
                                          Pending &nbsp;
                                        </span>
                                      </span>
                                    )}

                                    <br />
                                    {key.image ? (
                                      this.getViewButton(key)
                                    ) : (
                                      ""
                                    )}
                                    <hr></hr>
                                  </div>
                                )}
                              </Col>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Row>
                      <br></br>
                    </Col>
                  </FormGroup>
                </Col>
              )}

              {this.props.location.pathname !== "/educator-message" && (
                <Col md={6}>
                  <div
                    className="hm-icons my-clinic"
                    style={{ backgroundColor: "#006dbd" }}
                  >
                    <span>
                      <h4>My Clinic</h4>
                    </span>
                    <span
                      className="upload-document"
                      onClick={(e) => this.manageBox("addMyClinic")}
                    >
                      Upload File
                    </span>
                    {/* <span><img src="../../../images/training.png" width="70px" alt="" /></span> */}
                  </div>

                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Button
                          className="btn-fill btn btn-primary"
                          onClick={(e) => {
                            let status = this.state.hideMyClinic ? false : true;
                            this.setState({ hideMyClinic: status });
                          }}
                        >
                          Uploaded Files{" "}
                          {!this.state.hideMyClinic && <span> ▿</span>}
                        </Button>
                      </Row>
                      <Row className="manage-Req-dr">
                        {this.state.myClinic_msg_list.map((key, i) => {
                          if (
                            (key.status === "active" &&
                              key.addedType === "admin") ||
                            key.addedType === "clinic"
                          ) {
                            return (
                              <Col sm={12} key={i}>
                                {!this.state.hideMyClinic && (
                                  <div className="my-clinic-label">
                                    {key.status === "active" && (
                                      <Checkbox
                                        isChecked={key.clinicShowStatus}
                                        name={key._id}
                                        number={key._id}
                                        id={key._id}
                                        label={key.title}
                                        onClick={(e) => {
                                          this.handleLinkShowStatus(e, i);
                                        }}
                                      />
                                    )}
                                    {key.status !== "active" && (
                                      <span htmlFor={i}>{key.title}</span>
                                    )}

                                    {key.addedType === "admin" ||
                                      key.clinicId === null ||
                                      !key.clinicId ? (
                                      <span> (Admin) </span>
                                    ) : (
                                      ""
                                    )}

                                    {/* {(key.status === 'active') ?
                                                                                <span className="my-clinic-status active-color">Active</span>
                                                                                :
                                                                                ((key.status === 'Inactive') ?
                                                                                    <span className="my-clinic-status inactive-color">Inactive</span>
                                                                                    :
                                                                                    <span><span style={{cursor:"pointer"}}  className="my-clinic-status request-color" onClick={(e) => this.manageBox('addMyClinic', key)}>Edit</span>&nbsp;
                                                                                    <span className="my-clinic-status request-color">Pending &nbsp;</span>
                                                                                    </span>
                                                                                )

                                                                            } */}

                                    {key.clinicShowStatus ? (
                                      <span className="my-clinic-status active-color">
                                        Active
                                      </span>
                                    ) : key.status !== "Inactive" ? (
                                      <span className="my-clinic-status inactive-color">
                                        Inactive
                                      </span>
                                    ) : (
                                      <span>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="my-clinic-status request-color"
                                          onClick={(e) =>
                                            this.manageBox("addMyClinic", key)
                                          }
                                        >
                                          Edit
                                        </span>
                                        &nbsp;
                                        <span className="my-clinic-status request-color">
                                          Pending &nbsp;
                                        </span>
                                      </span>
                                    )}
                                    <br />
                                    {key.image ? (
                                      this.getViewButton(key)
                                    ) : key.link ? (
                                      <a href={key.link} target="_blank">
                                        View File
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    <hr></hr>
                                  </div>
                                )}
                              </Col>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Row>
                      <br></br>
                    </Col>
                  </FormGroup>
                </Col>
              )}

              {this.props.location.pathname === "/educator-message" && (
                <Col md={12}>
                  <div
                    className="hm-icons educator-message"
                    style={{ backgroundColor: "#41a2c9" }}
                  >
                    <span>
                      {/* <span><img src="../../../images/general.png" width="70px" alt="" /></span><br></br><br></br> */}
                      <h4>Educator Message Master</h4>
                    </span>
                    <span
                      className="upload-document"
                      onClick={(e) => this.manageBox("addMyEducatorMessage")}
                    >
                      Upload Message
                    </span>
                  </div>
                  <FormGroup>
                    <Col sm={12}>
                      <Row className="">
                        {this.state.educator_msg_list.map((key, i) => {
                          if (
                            (key.status === "active" &&
                              key.addedType === "admin") ||
                            key.addedType === "clinic"
                          ) {
                            return (
                              <Col key={i} sm={12}>
                                <div className="my-clinic-label educator-message-checkb">
                                  {key.status === "active" && (
                                    <div className={"checkbox"}>
                                      <input
                                        type="checkbox"
                                        onClick={(e) => {
                                          this.handleMessageShowStatus(e, i);
                                        }}
                                        name={key._id}
                                        key={key._id}
                                        id={key._id}
                                        checked={key.clinicShowStatus}
                                      />
                                      <label htmlFor={key._id}>
                                        {key.message}
                                      </label>
                                    </div>
                                  )}
                                  {key.status !== "active" && (
                                    <span htmlFor={i}>{key.message}</span>
                                  )}

                                  {/*(key.public) ? <span> (Admin) </span> : "" */}
                                  {key.addedType === "admin" ? (
                                    <span> (Admin) </span>
                                  ) : (
                                    ""
                                  )}

                                  {key.clinicShowStatus === true ? (
                                    <span className="my-clinic-status act-inact active-color">
                                      Active
                                    </span>
                                  ) : key.clinicShowStatus === false ? (
                                    <span className="my-clinic-status act-inact inactive-color">
                                      Inactive
                                    </span>
                                  ) : (
                                    <span className="my-clinic-status act-inact request-color">
                                      Pending
                                    </span>
                                  )}
                                  {key.addedType !== "admin" ? (
                                    <span
                                      style={{
                                        margin: "6px",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) => this.deleteMessage(e)}
                                      id={key._id}
                                      className="my-clinic-status inactive-color"
                                    >
                                      {" "}
                                      Delete{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <hr></hr>
                                </div>
                              </Col>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
              )}

              <Col>
                {this.state.viewBoxData && this.state.viewBoxData.show && (
                  <ViewBox
                    data={this.state.viewBoxData}
                    hideViewBox={(e) => this.hideViewBox()}
                  />
                )}
                <Modal
                  show={this.state.videoModel}
                  onHide={() => this.setState({ videoModel: false })}
                  dialogClassName="modal-md"
                >
                  <Modal.Header>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12}>
                            <Modal.Title>
                              <h5>{this.state.modelViewData.title}</h5>
                            </Modal.Title>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Modal.Header>
                  <Modal.Body className="Knowledge-Share card img-ccent">
                    <Row>
                      <Col md={12}>
                        <div>
                          {this.state.modelViewData &&
                            this.state.modelViewData.type &&
                            this.state.modelViewData.type === "video" && (
                              <div>
                                <Row>
                                  <Col md={12}>
                                    <ReactPlayer
                                      className="react-player"
                                      width="100%"
                                      height="100%"
                                      url={
                                        this.state.s3url +
                                        this.state.modelViewData.image
                                      }
                                      playing
                                      controls="true"
                                      volume="1"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )}
                          {this.state.modelViewData &&
                            this.state.modelViewData.type &&
                            this.state.modelViewData.type === "image" && (
                              <div>
                                <Row>
                                  <Col md={12}>
                                    <ImageComponent
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      data={this.state.modelViewData.image}
                                      setViewBoxData={(e) => {
                                        return true;
                                      }}
                                      width="70%"
                                      height="100%"
                                      alt=""
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )}

                          {this.state.modelViewData &&
                            this.state.modelViewData.type &&
                            this.state.modelViewData.type === "pdf" && (
                              <div>
                                <Row>
                                  <Col md={12}>
                                    <a
                                      href={
                                        this.state.s3url +
                                        this.state.modelViewData.image
                                      }
                                      target="_blank"
                                    >
                                      {" "}
                                      Download pdf
                                    </a>

                                    <object data={`${this.state.s3url}${this.state.modelViewData.image}#toolbar=0`} style={{ width: "100%", height: "600px" }}></object>

                                    {/* <iframe
                                      src={
                                        this.state.s3url +
                                        this.state.modelViewData.image +
                                        "#toolbar=0"
                                      }
                                      style={{ width: "100%", height: "75vh" }}
                                    ></iframe> */}
                                  </Col>
                                </Row>
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => this.setState({ videoModel: false })}
                      className="btn-fill btn-wd btn btn-default"
                    >
                      Close
                    </button>
                    &nbsp;
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    EducatorMsgList: state.home.EducatorMsgList,
    isEducatorMsgList: state.home.isEducatorMsgList,
    isEducatorMsgListError: state.home.isEducatorMsgListError,

    isUploadFile: state.master.isUploadFile,
    isUploadFileError: state.master.isUploadFileError,
    uploadFile: state.master.uploadFile,

    isAddFaq: state.settings.isAddFaq,
    isAddFaqError: state.settings.isAddFaqError,
    AddFaq: state.settings.AddFaq,

    CategoryList: state.home.categoryList,
    isCategoryList: state.home.isCategoryList,
    isCategoryListError: state.home.isCategoryListError,

    isMultiUploadFile: state.master.isMultiUploadFile,
    isMultiUploadFileError: state.master.isMultiUploadFileError,
    MultiUploadFile: state.master.MultiUploadFile,

    isAddKnowledge: state.settings.isAddKnowledge,
    isAddKnowledgeError: state.settings.isAddKnowledgeError,
    AddKnowledge: state.settings.AddKnowledge,

    isAddClinicLink: state.settings.isAddClinicLink,
    isAddClinicLinkError: state.settings.isAddClinicLinkError,
    AddClinicLink: state.settings.AddClinicLink,

    isAddEducatorMsg: state.settings.isAddEducatorMsg,
    isAddEducatorMsgError: state.settings.isAddEducatorMsgError,
    AddEducatorMsg: state.settings.AddEducatorMsg,

    PrintShareDocument: state.home.printShareDocument,
    isPrintShareDocument: state.home.isPrintShareDocument,
    isPrintShareDocumentError: state.home.isPrintShareDocumentError,

    KnowledgeList: state.home.knowledgeList,
    isKnowledgeList: state.home.isKnowledgeList,
    isKnowledgeListError: state.home.isKnowledgeListError,

    myClinicList: state.home.myClinicList,
    isMyClinicList: state.home.isMyClinicList,
    isMyClinicListError: state.home.isMyClinicListError,

    msgKnowledgeList: state.home.msgKnowledgeList,
    isMsgKnowledgeList: state.home.isMsgKnowledgeList,
    isMsgKnowledgeListError: state.home.isMsgKnowledgeListError,

    KnowledgeClinicShow: state.settings.KnowledgeClinicShow,
    isKnowledgeClinicShow: state.settings.isKnowledgeClinicShow,
    isKnowledgeClinicShowError: state.settings.isKnowledgeClinicShowError,

    LanguageList: state.language.LanguageList,
    isLanguageList: state.language.isLanguageList,
    isLanguageListError: state.language.isLanguageListError,

    s3SignedUrlArrClinic: state.home.s3SignedUrlArrClinic,
    isS3SignedUrlArrUploadDocumentClinic: state.home.isS3SignedUrlArrUploadDocumentClinic,
    isS3SignedUrlArrErrorUploadDocumentClinic: state.home.isS3SignedUrlArrErrorUploadDocumentClinic,

    s3SignedUrlArrAdmin: state.home.s3SignedUrlArrAdmin,
    isS3SignedUrlArrUploadDocumentAdmin: state.home.isS3SignedUrlArrUploadDocumentAdmin,
    isS3SignedUrlArrErrorUploadDocumentAdmin: state.home.isS3SignedUrlArrErrorUploadDocumentAdmin

  };
}
export default withRouter(
  connect(mapStateToProps, {
    knowledgeListAction,
    printShareDocumentAction,
    addEducatorMessageAction,
    addClinicLinkAction,
    addKnowledgeAction,
    uploadMultiFileAction,
    categoryListAction,
    addFaqAction,
    uploadFileAction,
    educatorMsgListAction,
    myClinicListAction,
    knowledgeMsgListAction,
    KnowledgeShowClinicAction,
    LinkShowClinicAction,
    MessageShowClinicAction,
    languageListAction,
    s3SiginedUrlArrayActionUploadDocumentClinic,
    s3SiginedUrlArrayActionUploadDocumentAdmin
  })(ManageRequest)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddFood: false,
  isAddFoodError: false,
  AddFood: false,
  isUpdateFood: false,
  isUpdateFoodError: false,
  UpdateFood: false,
  isFoodChangeStatus: false,
  isFoodChangeStatusError: false,
  FoodChangeStatus: false,
  isFoodList: false,
  isFoodListError: false,
  FoodList: false,
  isFoodCartList: false,
  isFoodCartListError: false,
  FoodCartList: false,
  isUploadFile: false,
  isUploadFileError: false,
  uploadFile: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_FOOD_REQUEST:
      return {
        ...state,
        message: '',
        isAddFood: false,
        isAddFoodError: false,
      }
    case actionTypes.GET_ADD_FOOD_SUCCESS:
      return {
        ...state,
        message: 'Food Added Successfully',
        AddFood: action.payload.response,
        isAddFood: true,
        isAddFoodError: false,
      }
    case actionTypes.GET_ADD_FOOD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddFood: false,
        isAddFoodError: true
      }

    //Food Update

    case actionTypes.GET_UPDATE_FOOD_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateFood: false,
        isUpdateFoodError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_SUCCESS:
      return {
        ...state,
        message: 'Food Updated Successfully',
        UpdateFood: action.payload.response,
        isUpdateFood: true,
        isUpdateFoodError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateFood: false,
        isUpdateFoodError: true
      }

    //Food List
    case actionTypes.GET_FOOD_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isFoodList: false,
        isFoodListError: false,
      }
    case actionTypes.GET_FOOD_LIST_SUCCESS:
      return {
        ...state,
        message: 'Food List',
        FoodList: action.payload.response,
        isFoodList: true,
        isFoodListError: false,
      }
    case actionTypes.GET_FOOD_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isFoodList: false,
        isFoodListError: true
      }

    //Food Cart List
    case actionTypes.GET_FOOD_CART_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isFoodCartList: false,
        isFoodCartListError: false,
      }
    case actionTypes.GET_FOOD_CART_LIST_SUCCESS:
      return {
        ...state,
        message: 'Food Cart List',
        FoodCartList: action.payload.response,
        isFoodCartList: true,
        isFoodCartListError: false,
      }
    case actionTypes.GET_FOOD_CART_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isFoodCartList: false,
        isFoodCartListError: true
      }

    //Food Status Change
    case actionTypes.GET_FOOD_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isFoodChangeStatus: false,
        isFoodChangeStatusError: false,
      }
    case actionTypes.GET_FOOD_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Food Status Changed',
        FoodChangeStatus: action.payload.response,
        isFoodChangeStatus: true,
        isFoodChangeStatusError: false,
      }
    case actionTypes.GET_FOOD_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isFoodChangeStatus: false,
        isFoodChangeStatusError: true
      }





    default:
      return state
  }
}

/********* Show list of Food */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Modal
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as PF from "Front/views/Home/PublicFunction.jsx"
import { appConstants } from 'Front/_constants/app.constants.js';
import { postRequestWithToken } from "../../api/helper.js"
import ReactPlayer from 'react-player';
class VideoDocumentView extends Component {
    constructor(props) {
        super(props);
        let extension = this.props.link ? this.props.link.split('.').pop() : '';
        this.state = {
            ViewModal: this.props.ViewModal,
            patientName: this.props.pvDetail.name,
            patientId: this.props.pvDetail.patientId,
            id: this.props.id,
            type: this.props.type,
            link: this.props.link,
            autoplay: false,
            icon: (this.props.type === 'image' ? (extension == 'pdf' || extension == 'PDF' ? 'fa fa-file-pdf-o pull-right' : 'fa fa-image pull-right') : 'fa fa-youtube-play pull-right'),
            secureLink: '',
            gets3data: '',
        };

        this.handleView = this.handleView.bind(this);
        this.renderIcon = this.renderIcon.bind(this);
    }

    handleView = (type, link) => {
        const fetchUserEmail = async () => {
            const response = await postRequestWithToken({ filePath: link }, 's3SignedUrl');
            this.setState({
                secureLink: response.data.url,
                gets3data: 1
            });
        };
        if (this.state.gets3data === '') {
            fetchUserEmail();
        }
        if (type === 'image') {
            var extension = link ? link.split('.').pop() : '';
            if (extension === 'pdf') {
                return (<a target={'_blank'} href={this.state.secureLink}><i className={this.state.icon} onClick={e => { this.viewDocument(e); }}></i></a>
                );
            } else {
                return (<img alt="" className="abc" src={this.state.secureLink} />);
            }
        } else {

            return (<ReactPlayer className='react-player' width='100%' height='100%' url={this.state.secureLink} playing controls="true" volume="1" />);
        }
    }

    viewDocument(e) {
        let extension = this.props.link ? this.props.link.split('.').pop() : '';
        if (extension == 'pdf' || extension == 'PDF')
            this.setState({ gets3data: '' });
        else this.setState({ ViewModal: true, gets3data: '' });
        if (this.props.status === 1) {
            this.props.ChangeStatus(this.state.id, 'document');
        }
    }

    renderIcon() {
        let extension = this.props.link ? this.props.link.split('.').pop() : '';
        let str = <i className={this.state.icon}></i>

        if ((extension == 'pdf' || extension == 'PDF')) {
            str = this.handleView(this.props.type, this.props.link)
        }

        return str;
    }

    render() {
        if (this.props.name !== '') {
            return (
                <div className="no-padding pull-right t">
                    <div className="no-padding col-sm-12" onClick={e => { this.viewDocument(e); }}>
                        {this.props.name}
                        {this.renderIcon()}
                    </div>
                    {this.state.ViewModal === true &&
                        <Modal show={this.state.ViewModal} onHide={() => this.setState({ ViewModal: false })} >
                            <Modal.Header closeButton className="bg-thumbcolor">
                                <Modal.Title id="example-modal-sizes-title-lg" className="spc-btom">({this.state.patientName} - {this.state.patientId})</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="Knowledge-Share">
                                {this.handleView(this.state.type, this.state.link)}
                            </Modal.Body>
                        </Modal>
                    }
                </div>
            );
        } else {
            return (
                <div className="pull-right">
                    <div onClick={e => { this.viewDocument(e); }}>
                        {this.renderIcon()}
                    </div>
                    {this.state.ViewModal === true &&
                        <Modal show={this.state.ViewModal} onHide={() => this.setState({ ViewModal: false })} >
                            <Modal.Header closeButton className="bg-thumbcolor">
                                <Modal.Title id="example-modal-sizes-title-lg ">({this.state.patientName} - {this.state.patientId})</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="Knowledge-Share">
                                {this.handleView(this.state.type, this.state.link)}
                            </Modal.Body>
                        </Modal>
                    }
                </div>
            );
        }
    }
}

export default withRouter(VideoDocumentView);

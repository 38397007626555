/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from "./actionTypes";
import * as API from "Front/api/commonRequest";

export const addCommentRequest = () => ({
    type: actionTypes.GET_ADD_COMMENT_REQUEST,
});
export const addCommentSuccess = (response) => ({
    type: actionTypes.GET_ADD_COMMENT_SUCCESS,
    payload: {
        response,
    },
});
export const addCommentError = (error) => ({
    type: actionTypes.GET_ADD_COMMENT_ERROR,
    payload: {
        error,
    },
});

//---Upload files
export const fileUploadAction = (data) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.FILE_UPLOAD_REQUEST, payload: data });
        let formData = new FormData();
        formData.append("file", data);
        return API.uploadFile(formData)
            .then((response) => {
                dispatch({ type: actionTypes.FILE_UPLOAD_SUCCESS, payload: response });
            })
            .catch((error) => {
                dispatch({ type: actionTypes.FILE_UPLOAD_ERROR, payload: error });
            });
    };
};

//---add Clinic Invitation
export const addClinicInvitationAction = (data) => {

    return (dispatch) => {
        dispatch({
            type: actionTypes.ADD_CLINIC_INVITATION_REQUEST,
            payload: data,
        });
        return API.addClinicInvitation(data)
            .then((response) => {
                dispatch({
                    type: actionTypes.ADD_CLINIC_INVITATION_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ADD_CLINIC_INVITATION_ERROR,
                    payload: error,
                });
            });
    };
};

export const addCommentAction = (data) => {
    return (dispatch) => {
        dispatch(addCommentRequest());
        return API.addComment(data)
            .then((response) => {
                dispatch(addCommentSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    addCommentError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};
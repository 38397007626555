/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { withRouter } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import {
  Row, Col,
  Modal, Form, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import * as API from 'Front/api/emr';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import NotificationSystem from 'react-notification-system';
import { style } from "Admin/variables/Variables.jsx";
import { ACTIONS } from './Prescription';


const AddDoseTime = ({ setModalStatusDoseTime, patientId, doseTimeList, selectedDoseTimeFrequency, modalStatusDoseTime, doseTimeSlotMedian, drugId, dispatch, doseTimeMode, doseTimeSlotList, doseFrequencyList, drugs, freqValue, setFreqValue }) => {

  const [arrTimeSlot, setArrTimeSlot] = useState([])
  const [selectedFrequency, setSelectedFrequency] = useState([])
  const [doseFrequency, setDoseFrequency] = useState("")
  const [mode, setMode] = useState("")
  const [doseTimeCount, setDoseTimeCount] = useState(false)
  const hideModal = () => {
    setMode("add")
    setModalStatusDoseTime(false)
  }

  const showDoseTime = () => {
    setArrTimeSlot([])
    setSelectedFrequency([])
    setModalStatusDoseTime(true)
    setDoseTimeCount(false)
    setArrTimeSlot(doseTimeList)
    setMode(doseTimeMode)
  }

  const showSelectedInstruction = (row) => {
    let ele = document.getElementById(row._id)
    if (ele !== null) {
      ele.checked = true
      setSelectedFrequency(row)
    }

    arrTimeSlot.map((itm, idx) => {
      ele = document.querySelector("[id='" + itm.doseTimeSlotId + "']");
      if (ele !== null)
        ele.checked = true
    }
    )

  }

  const checkDoseInstructon = (row) => {
    uncheckInstruction()
    let arr = []
    setSelectedFrequency(row)
    row.doseTimeMapingId.map((val, idx) => {
      if (val !== null) {
        let ele = document.querySelector("[doseTimeSlotId='" + val + "']");
        if (ele !== null) {
          arr.push({
            "doseTimeSlotName": ele.value,
            "doseTimeSlotId": ele.getAttribute("id"),
            "doseTimeSlotMapingId": ele.getAttribute("doseTimeSlotId") === '' ? null : ele.getAttribute("doseTimeSlotId"),
            "customizeFrequency": [],
            "sequence": ele.getAttribute("sequence")
          })
          setArrTimeSlot([...arr])
          ele.checked = true
        }
        setDoseTimeCount(false)
      }
    })

  }

  const addOrDeleteTimeSlot = (e) => {
    if (e.target.checked) {
      let arr = []
      arr.push({
        "doseTimeSlotName": e.target.value,
        "doseTimeSlotId": e.target.getAttribute("id"),
        "doseTimeSlotMapingId": e.target.doseTimeSlotId === '' ? null : e.target.doseTimeSlotId,
        "sequence": e.target.getAttribute("sequence"),
        "customizeFrequency": []
      })
      setArrTimeSlot([...arr, ...arrTimeSlot])
      setDoseTimeCount(false)
    } else {
      deleteTimeSlot(e)
    }

  }


  const deleteTimeSlot = (e) => {
    let arr = arrTimeSlot;
    arr.map((itm, idx) => {
      if (itm.doseTimeSlotId === e.target.getAttribute("id")) {
        arr.splice(idx, 1);
        return;
      }
    })
    let noOfDoseSelector = ""
    drugs.map((drug) => {
      if (drug.drugId === drugId) {
        noOfDoseSelector = drug.noOfDoseSelector
      }
    })

    for (let i = 1; i <= noOfDoseSelector; i++) {
      let key = ""
      key = drugId + "-" + e.target.getAttribute("id") + "-" + i
      let temp = freqValue
      delete temp[key]
      setFreqValue({ ...temp })
    }
    setArrTimeSlot([...arr])
  }





  const submitHandler = () => {
    if (arrTimeSlot.length > 0) {
      let arr = arrTimeSlot
      let temp = arr.sort((firstItem, secondItem) => firstItem.sequence - secondItem.sequence);
      let doseTimeData = {}
      doseTimeData.doseFrequencyId = selectedFrequency._id
      doseTimeData.doseTimeMapingName = selectedFrequency.doseTimeMapingName
      doseTimeData.doseFrequency = selectedFrequency.doseFrequency
      doseTimeData.doseTimeSlot = arrTimeSlot
      let noOfDoseSelector = ""
      drugs.map((drug) => {
        if (drug.drugId === drugId) {
          noOfDoseSelector = drug.noOfDoseSelector
        }
      })
      let payloadData = {
        "drugId": drugId,
        "doseTime": doseTimeData,
        "selectedFrequency": selectedFrequency,
        "noOfDoseSelector": noOfDoseSelector
      }
      dispatch({ type: ACTIONS.ADD_DRUG_DOSETIME, payload: payloadData })
      hideModal()
    } else {
      setDoseTimeCount(true)
    }

  }

  const uncheckInstruction = () => {
    let chkInstructions = document.getElementsByName("instruction");
    for (var instruction of chkInstructions) {
      instruction.checked = false
    }
  }

  useEffect(() => {
    if (modalStatusDoseTime)
      showDoseTime()
  }, [modalStatusDoseTime])

  useEffect(() => {
    if (doseTimeMode === "edit") {
      drugs.map((drug) => {
        if (drug.drugId === drugId) {
          showSelectedInstruction(drug.selectedFrequency)
        }
      })
    }

  }, [mode])


  return (
    <span >
      <Modal show={modalStatusDoseTime} onHide={hideModal} dialogClassName="modal-xl">
        <Modal.Header closeButton className="bg-thumbcolor">

          <Modal.Title id="example-modal-sizes-title-lg">Dose Time</Modal.Title>
        </Modal.Header>
        <Modal.Body className="suggetion-box-enlarge add-dose-time checkbox-space">

          <div class="dropdown">

            <ul class="" aria-labelledby="dropdownMenu1" style={{ opacity: '1', visibility: 'visible' }}>
              <div className='row'>
                <div className='col-lg-4'>
                  <div className='dropdown-col' id='doseTime'>

                    {doseFrequencyList && doseFrequencyList.map(item => {


                      return (<div class="form-check form-check-emr">
                        <input type="radio" onClick={e => checkDoseInstructon(item)} id={item._id} name="doseTimeFrequency" value="Netflix" />
                        <label for={item._id}>

                          {
                            item.doseFrequency.join(" - ")
                          }
                        </label>
                      </div>)

                    })}



                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='dropdown-col checkbox-group'>
                    <fieldset id='instructions'>

                      {doseTimeSlotList && doseTimeSlotList.map((item, idx) => {
                        { idx++ }
                        if (idx <= doseTimeSlotMedian) {
                          return (<div class="form-check form-check-emr">
                            <input class="form-check-input" type="checkbox" key={idx} sequence={item.sequencing} value={item.doseTimeSlotName} onClick={e => addOrDeleteTimeSlot(e)} doseTimeSlotId={item.doseTimeSlotMapingId} name="instruction" id={item._id} />
                            <label class="form-check-label" for={item._id}>
                              {item.doseTimeSlotName}
                            </label>
                          </div>)
                        }


                      })}
                    </fieldset>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='dropdown-col checkbox-group'>
                    <fieldset id='instructions'>

                      {doseTimeSlotList && doseTimeSlotList.map((item, idx) => {
                        { idx++ }
                        if (idx > doseTimeSlotMedian) {
                          return (<div class="form-check form-check-emr">
                            <input class="form-check-input" type="checkbox" key={idx} sequence={item.sequencing} value={item.doseTimeSlotName} doseTimeSlotId={item.doseTimeSlotMapingId} onClick={e => addOrDeleteTimeSlot(e)} subcatid="" name="instruction" id={item._id} />
                            <label class="form-check-label" for={item._id}>
                              {item.doseTimeSlotName}
                            </label>
                          </div>)
                        }
                      })}

                    </fieldset>
                  </div>
                </div>
              </div>
            </ul>
            {doseTimeCount &&
              <div style={{ 'color': 'red', 'textAlign': 'center' }}>
                <p style={{ 'color': 'red' }}>Please select any value</p>
              </div>
            }
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={submitHandler} className="btn">Save</Button>
        </Modal.Footer>
      </Modal>


    </span>
  )
}

export default AddDoseTime
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import {
    getRequest,
    postRequest,
    getRequestWithToken,
    postRequestWithToken,
    postRequestWithTokenFile
} from "./helper";

export const generalInformation = (data) =>
    postRequestWithToken(data, "emrpatientDetail");

export const complainPatientData = (data) =>
    postRequestWithToken(data, "complainPatientData");

export const symptomsCategory = (data) =>
    postRequestWithToken(data, "symptomsCategory");

export const symptomsSubCategory = (data) =>
    postRequestWithToken(data, "symptomsSubCategory");

export const addPatientSymptoms = (data) =>
    postRequestWithToken(data, "addPatientSymptoms");

export const editComplaint = (data) =>
    postRequestWithToken(data, "editComplaint");

export const addClinicSymptoms = (data) =>
    postRequestWithToken(data, "addClinicSymptoms");

export const addDiabticDuration = (data) =>
    postRequestWithToken(data, "addDiabticDuration");

export const diagonsisCategory = (data) =>
    postRequestWithToken(data, "diagonsisCategory");

export const diagonsisSubCategory = (data) =>
    postRequestWithToken(data, "diagonsisSubCategory");

export const diagonsisList = (data) =>
    postRequestWithToken(data, "diagonsisList");

export const addDiagonsis = (data) =>
    postRequestWithToken(data, "addDiagonsis");

export const addClinicDiagonsis = (data) =>
    postRequestWithToken(data, "addClinicDiagonsis");

export const editDiagonsis = (data) =>
    postRequestWithToken(data, "editDiagonsis");

export const patientDiagonsisData = (data) =>
    postRequestWithToken(data, "patientDiagonsisData");

export const drugSearch = (data) => postRequestWithToken(data, "drugSearch");

export const drugInstruction = (data) =>
    postRequestWithToken(data, "drugInstruction");

export const instructionList = (data) =>
    postRequestWithToken(data, "instructionList");

export const addCustomInstruction = (data) =>
    postRequestWithToken(data, "addCustomInstruction");

export const drugCategoryList = (data) =>
    postRequestWithToken(data, "drugCategoryList");

export const drugCompanyList = (data) =>
    postRequestWithToken(data, "drugCompanyList");

export const addDrug = (data) => postRequestWithToken(data, "addDrug");

export const doseTimeSlotList = (data) =>
    postRequestWithToken(data, "doseTimeSlotList");

export const doseFrequencyList = (data) =>
    postRequestWithToken(data, "doseFrequencyList");

export const addPatientPrescription = (data) =>
    postRequestWithToken(data, "addPatientPrescription");

export const patientDrugData = (data) =>
    postRequestWithToken(data, "patientDrugData");

export const recommendedPrescription = (data) =>
    postRequestWithToken(data, "recommendedPrescription");

export const addRecommendation = (data) =>
    postRequestWithToken(data, "addRecommendation");

export const prescriptionPrint = (data) =>
    postRequestWithToken(data, "prescriptionPrint");

export const nextVisit = (data) => postRequestWithToken(data, "next-visit");

export const vitalSignData = (data) =>
    postRequestWithToken(data, "vitalSignData");

export const addVitalSign = (data) =>
    postRequestWithToken(data, "addVitalSign");

export const getAllVisitList = (data) =>
    postRequestWithToken(data, "allVisits");

export const patientHistory = (data) =>
    postRequestWithToken(data, "patientEmrHistory");

export const languageList = (data) =>
    postRequestWithToken(data, "admin/language-list");

export const sharePrescription = (data) =>
    postRequestWithToken(data, "prescription-share");

export const glucoseReportStatus = (data) =>
    postRequestWithToken(data, "read-status");

export const isPrescriptionPrint = (data) =>
    postRequestWithToken(data, "isPrintPrescription");

export const complainSearch = (data) =>
    postRequestWithToken(data, "complainSearch");

export const addReport = (data) =>
    postRequestWithTokenFile(data, "add-report");

 export const labTestFrontList = (data) =>
    postRequestWithToken(data, "testreport-list");

export const labTestRangeFrontList = (data) =>
    postRequestWithToken(data, "testreportrange-detail");

export const addLabTestRange = (data) =>
    postRequestWithToken(data, "add-testreportrange");

export const updateLabTestRange = (data) =>
    postRequestWithToken(data, "update-testreportrange");

export const changeTestReportRangeStatus = (data) =>
    postRequestWithToken(data, "testreportrange-status");

export const deleteLabTestRange = (data) =>
    postRequestWithToken(data, "testreportrange-delete");
    
    
    

    
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
import * as API from 'Front/api/settings'

//Add Faq
export const addFaqRequest = () => ({
    type: actionTypes.ADD_FAQ_REQUEST
})
export const addFaqSuccess = (response) => ({
    type: actionTypes.ADD_FAQ_SUCCESS,
    payload: {
        response,
    }
})
export const addFaqError = (error) => ({
    type: actionTypes.ADD_FAQ_ERROR,
    payload: {
        error
    }
})
export const addFaqAction = (data) => {
    return dispatch => {
        dispatch(addFaqRequest())

        return API.addFaq(data)
            .then(response => {
                dispatch(addFaqSuccess(response.data))
            })
            .catch(error => {
                dispatch(addFaqError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Add Knowledge
export const addKnowledgeRequest = () => ({
    type: actionTypes.ADD_KNOWLEDGE_REQUEST
})
export const addKnowledgeSuccess = (response) => ({
    type: actionTypes.ADD_KNOWLEDGE_SUCCESS,
    payload: {
        response,
    }
})
export const addKnowledgeError = (error) => ({
    type: actionTypes.ADD_KNOWLEDGE_ERROR,
    payload: {
        error
    }
})
export const addKnowledgeAction = (data) => {
    return dispatch => {
        dispatch(addKnowledgeRequest())

        return API.addKnowledge(data)
            .then(response => {
                dispatch(addKnowledgeSuccess(response.data))
            })
            .catch(error => {
                dispatch(addKnowledgeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//Add Clinic Link
export const addClinicLinkRequest = () => ({
    type: actionTypes.ADD_CLINIC_LINK_REQUEST
})
export const addClinicLinkSuccess = (response) => ({
    type: actionTypes.ADD_CLINIC_LINK_SUCCESS,
    payload: {
        response,
    }
})
export const addClinicLinkError = (error) => ({
    type: actionTypes.ADD_CLINIC_LINK_ERROR,
    payload: {
        error
    }
})
export const addClinicLinkAction = (data) => {
    return dispatch => {
        dispatch(addClinicLinkRequest())

        return API.addClinicLink(data)
            .then(response => {
                dispatch(addClinicLinkSuccess(response.data))
            })
            .catch(error => {
                dispatch(addClinicLinkError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//add Educator Message
export const addEducatorMessageRequest = () => ({
    type: actionTypes.ADD_EDUCATOR_MSG_REQUEST
})
export const addEducatorMessageSuccess = (response) => ({
    type: actionTypes.ADD_EDUCATOR_MSG_SUCCESS,
    payload: {
        response,
    }
})
export const addEducatorMessageError = (error) => ({
    type: actionTypes.ADD_EDUCATOR_MSG_ERROR,
    payload: {
        error
    }
})
export const addEducatorMessageAction = (data) => {
    return dispatch => {
        dispatch(addEducatorMessageRequest())

        return API.addEducatorMessage(data)
            .then(response => {
                dispatch(addEducatorMessageSuccess(response.data))
            })
            .catch(error => {
                dispatch(addEducatorMessageError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Change Password Doctor
export const changePasswordRequest = () => ({
    type: actionTypes.CHANGE_PASSWORD_REQUEST
})
export const changePasswordSuccess = (response) => ({
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: {
        response,
    }
})
export const changePasswordError = (error) => ({
    type: actionTypes.CHANGE_PASSWORD_ERROR,
    payload: {
        error
    }
})
export const changePasswordAction = (data) => {
    return dispatch => {
        dispatch(changePasswordRequest())

        return API.changePassword(data)
            .then(response => {
                dispatch(changePasswordSuccess(response.data))
            })
            .catch(error => {
                dispatch(changePasswordError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//Change Password educator/reseption
export const changeUserPasswordRequest = () => ({
    type: actionTypes.CHANGE_USER_PASSWORD_REQUEST
})
export const changeUserPasswordSuccess = (response) => ({
    type: actionTypes.CHANGE_USER_PASSWORD_SUCCESS,
    payload: {
        response,
    }
})
export const changeUserPasswordError = (error) => ({
    type: actionTypes.CHANGE_USER_PASSWORD_ERROR,
    payload: {
        error
    }
})
export const changeUserPasswordAction = (data) => {
    return dispatch => {
        dispatch(changeUserPasswordRequest())

        return API.changeUserPassword(data)
            .then(response => {
                dispatch(changeUserPasswordSuccess(response.data))
            })
            .catch(error => {
                dispatch(changeUserPasswordError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//add Clinic Holiday
export const addClinicHolidayRequest = () => ({
    type: actionTypes.ADD_CLINIC_HOLIDAY_REQUEST
})
export const addClinicHolidaySuccess = (response) => ({
    type: actionTypes.ADD_CLINIC_HOLIDAY_SUCCESS,
    payload: {
        response,
    }
})
export const addClinicHolidayError = (error) => ({
    type: actionTypes.ADD_CLINIC_HOLIDAY_ERROR,
    payload: {
        error
    }
})
export const addClinicHolidayAction = (data) => {
    return dispatch => {
        dispatch(addClinicHolidayRequest())

        return API.addClinicHoliday(data)
            .then(response => {
                dispatch(addClinicHolidaySuccess(response.data))
            })
            .catch(error => {
                dispatch(addClinicHolidayError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//add CONTACT US
export const addContactUsRequest = () => ({
    type: actionTypes.ADD_CONTACT_US_REQUEST
})
export const addContactUsSuccess = (response) => ({
    type: actionTypes.ADD_CONTACT_US_SUCCESS,
    payload: {
        response,
    }
})
export const addContactUsError = (error) => ({
    type: actionTypes.ADD_CONTACT_US_ERROR,
    payload: {
        error
    }
})
export const addContactUsAction = (data) => {
    return dispatch => {
        dispatch(addContactUsRequest())

        return API.addContactUs(data)
            .then(response => {
                dispatch(addContactUsSuccess(response.data))
            })
            .catch(error => {
                dispatch(addContactUsError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const KnowledgeShowClinicRequest = () => ({
    type: actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_REQUEST
})
export const KnowledgeShowClinicSuccess = (response) => ({
    type: actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const KnowledgeShowClinicError = (error) => ({
    type: actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_ERROR,
    payload: {
        error
    }
})
export const KnowledgeShowClinicAction = (data) => {
    return dispatch => {
        dispatch(KnowledgeShowClinicRequest())
        const FormData = {
            id: data._id,
            clinicShowStatus: data.clinicShowStatus,
            clinicId: data.clinicId,
        }
        return API.KnowledgeShowClinic(FormData)
            .then(response => {
                dispatch(KnowledgeShowClinicSuccess(response.data))
            })
            .catch(error => {
                dispatch(KnowledgeShowClinicError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


export const LinkShowClinicRequest = () => ({
    type: actionTypes.GET_LINK_CLINIC_SHOW_STATUS_REQUEST
})
export const LinkShowClinicSuccess = (response) => ({
    type: actionTypes.GET_LINK_CLINIC_SHOW_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const LinkShowClinicError = (error) => ({
    type: actionTypes.GET_LINK_CLINIC_SHOW_STATUS_ERROR,
    payload: {
        error
    }
})
export const LinkShowClinicAction = (data) => {
    return dispatch => {
        dispatch(LinkShowClinicRequest())
        const FormData = {
            id: data._id,
            clinicShowStatus: data.clinicShowStatus,
            clinicId: data.clinicId,
        }
        return API.LinkShowClinic(FormData)
            .then(response => {
                dispatch(LinkShowClinicSuccess(response.data))
            })
            .catch(error => {
                dispatch(LinkShowClinicError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const MessageShowClinicRequest = () => ({
    type: actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_REQUEST
})
export const MessageShowClinicSuccess = (response) => ({
    type: actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const MessageShowClinicError = (error) => ({
    type: actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_ERROR,
    payload: {
        error
    }
})
export const MessageShowClinicAction = (data) => {
    return dispatch => {
        dispatch(MessageShowClinicRequest())
        const FormData = {
            id: data._id,
            clinicShowStatus: data.clinicShowStatus,
            clinicId: data.clinicId,
        }
        return API.MessageShowClinic(FormData)
            .then(response => {
                dispatch(MessageShowClinicSuccess(response.data))
            })
            .catch(error => {
                dispatch(MessageShowClinicError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const ClinicHolidayHistoryRequest = () => ({
    type: actionTypes.GET_CLINIC_HOLIDAY_HISTORY_REQUEST
})
export const ClinicHolidayHistorySuccess = (response) => ({
    type: actionTypes.GET_CLINIC_HOLIDAY_HISTORY_SUCCESS,
    payload: {
        response,
    }
})
export const ClinicHolidayHistoryError = (error) => ({
    type: actionTypes.GET_CLINIC_HOLIDAY_HISTORY_ERROR,
    payload: {
        error
    }
})
export const ClinicHolidayHistoryAction = (data) => {
    return dispatch => {
        dispatch(ClinicHolidayHistoryRequest())
        const FormData = {
        }
        return API.ClinicHolidayHistory(data)
            .then(response => {
                dispatch(ClinicHolidayHistorySuccess(response.data))
            })
            .catch(error => {
                dispatch(ClinicHolidayHistoryError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';

import Loading from 'react-loading';
import { LowCalorieFoodListAction, LowCalorieFoodStatusAction, LowCalorieFoodDeleteAction } from 'Admin/actions/low_calorie_food';
import { languageListAction } from 'Admin/actions/language';
import { appConstants } from 'Admin/_constants/app.constants';

class LowCalorieFoodList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            lowCalorieFoodList: [],
            isLoading: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                foodCategoryId: "",
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "1"
            },
            limitForm: {},
        }


        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.LowCalorieFoodListAction(this.state.formData);
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.languageListAction(data);
    }

    componentWillReceiveProps(nextProps) {
        let listData = [];
        let i = 0;

        if (nextProps.isLowCalorieFoodList !== this.props.isLowCalorieFoodList) {

            this.setState({
                lowCalorieFoodList: nextProps.LowCalorieFoodList.data.data,
                totalCount: nextProps.LowCalorieFoodList.data.count
            });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {

            this.setState({ languageList: nextProps.LanguageList.data });
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }


    // updateData(cell, row, enumObject, rowIndex) {
    //     // return (<p><Link to={{ pathname: `update-food/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
    //     //     &nbsp;&nbsp; &nbsp;&nbsp;
    //     //     <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
    //     // </p>)
    // }



    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.LowCalorieFoodStatusAction(event);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-food";
        this.clicked = "";
    }

    handleChange(status, id) {
        if (status == 'active') {
            status = 'inactive';
        } else {
            status = 'active';
        }
        var lcfData = this.state.lowCalorieFoodList
        let event = {
            id: id,
            status: status
        }
        this.props.LowCalorieFoodStatusAction(event);
        var localindex_index = this.state.lowCalorieFoodList.map(function (el) {
            return el._id;
        }).indexOf(id);
        if (localindex_index != -1) {
            lcfData[localindex_index].status = status;
            this.setState({ lowCalorieFoodList: lcfData });
        }

    }

    // updateData(data){
    //      return (<p><Link to={{ pathname: `update-low-calorie-food/` + data._id, state: { data } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
    //          &nbsp;&nbsp; &nbsp;&nbsp;
    //          <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
    //      </p>)
    //   return( <Link to={{ pathname: `update-low-calorie-food/` + data._id }} >Update</Link>)
    // }

    toggleEdit(data) {

        this.setState({
            userRow: data
        });
    }

    deleteData(id) {
        if (window.confirm('Are you sure?')) {
            let data = {
                id: id
            }
            this.props.LowCalorieFoodDeleteAction(data);

            var lcfData = this.state.lowCalorieFoodList
            var localindex_index = lcfData.map(function (el) {
                return el._id;
            }).indexOf(id);
            if (localindex_index != -1) {
                lcfData.splice(localindex_index, 1);
                this.setState({ lowCalorieFoodList: lcfData });
            }
        }
    }

    handleLanguage(event) {
        let formData = this.state.formData
        formData['lang'] = event.target.value
        this.setState({ formData: formData });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        let _this = this
        let Language = this.state.formData.lang
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={

                                    <div className="row">
                                        <Col md={9}>
                                            <Button className="m-btmm" bsStyle="info" fill pullRight onClick={() => this.props.history.replace('add-low-calorie-food')}>Add Low Calorie Food</Button>
                                        </Col>

                                        <Col md={3}>
                                            <Col componentClass={ControlLabel}>
                                                Language
                                            </Col>

                                            <FormControl componentClass="select" name="langname" value={this.state.formData.lang} onChange={e => { this.handleLanguage(e); }}>
                                                <option value="">Select Language</option>
                                                {this.state.languageList.map(function (item) {
                                                    return <option key={item._id} value={item.lanId}>{appConstants.ucFirst(item.name)}</option>
                                                })}
                                            </FormControl>
                                        </Col>
                                        <div className="clearfix"></div>
                                        <div className="low-ff">
                                            {this.state.lowCalorieFoodList.map(function (data) {
                                                return (

                                                    <Col md={3}>
                                                        <div className="inline-bb">
                                                            <div>
                                                                <div>
                                                                    {data.name[Language]}</div>
                                                                <div className="checq-bo">
                                                                    <input type="checkbox" name="status" id={data._id} onChange={(event) => { _this.handleChange(data.status, data._id) }} defaultChecked={data.status == 'active'} />
                                                                </div>

                                                                <button type="button" className="btn btn-primary" ><Link to={{ pathname: `update-low-calorie-food/` + data._id, state: { data } }} onClick={(event) => { _this.toggleEdit(data) }} >Update</Link></button>
                                                                <button type="button" className="btn btn-primary" onClick={(event) => { _this.deleteData(data._id) }}>Delete</button>

                                                            </div>
                                                        </div>
                                                    </Col>)

                                            })}
                                        </div>
                                    </div>

                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

        LowCalorieFoodList: state.lowCalorieFood.LowCalorieFoodList,
        isLowCalorieFoodList: state.lowCalorieFood.isLowCalorieFoodList,
        isLowCalorieFoodListError: state.lowCalorieFood.isLowCalorieFoodListError,

        FoodChangeStatus: state.food.FoodChangeStatus,
        isFoodChangeStatus: state.food.isFoodChangeStatus,
        isFoodChangeStatusError: state.food.isFoodChangeStatusError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { LowCalorieFoodListAction, LowCalorieFoodStatusAction, LowCalorieFoodDeleteAction, languageListAction })(LowCalorieFoodList));

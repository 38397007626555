/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { forwardRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/emr";
import { prescriptionPrintBottomLineOptions, valuesInPxOptoins } from "Front/variables/Variables.jsx";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import InsulinDose from "../../../TaskScreen/InsulinDose";
import * as PF from "Front/views/Home/PublicFunction.jsx";

const ComponentToPrint = forwardRef((props, ref) => {
  const emrData = useSelector((store) => store.emr);
  let userType = localStorage.getItem("userType");
  let { appointmentId, patientId, serachType, printLanguage } = useParams();
  let history = useHistory();
  let convertToMl = 0;
  const data = useSelector((store) => store.taskScreen);
  const [onPageLoad, setOnPageLoad] = useState(true);
  const [patinetData, setPatientData] = useState({});
  const [vitalData, setVitalData] = useState({});
  const [compalintData, setComplaintData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [recommendedPrescriptionData, setRecommendedPrescriptionData] =
    useState([]);
  const [
    recommendedPrescriptionSettingData,
    setRecommendedPrescriptionSettingData,
  ] = useState([]);
  const [patientPresciptionData, setPatientPresciptionData] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [phoneNo, setPhoneNo] = useState("");
  const [isVitalDataExist, setIsVitalDataExist] = useState(false);
  const [instructionPrintData, setInstructionPrintData] = useState([]);
  const [patientVisit, setPatientVisit] = useState(
    JSON.parse(localStorage.getItem("patientVisit"))
  );
  const [printMarginTop, setPrintMarginTop] = useState("");
  const [printMarginBottom, setPrintMarginBottom] = useState("");
  const [printMarginLeft, setPrintMarginLeft] = useState("");
  const [printMarginRight, setPrintMarginRight] = useState("");
  const [doseTimePrintList, setDoseTimePrintList] = useState([]);
  const [patientVisits, setPatientVisits] = useState(data.visitData);
  const [isInsulinCalculator, setIsInsulinCalculator] = useState(false);
  const notificationSystem = useRef(null);
  const [presciptionDate, setPrescriptionDate] = useState(
    moment(new Date()).format("DD-MMM-YYYY")
  );
  const [doctorData, setDocotrData] = useState({})
  let is_brand_with_ingredients_disable =
    localStorage.getItem("is_brand_with_ingredients_disable") == "yes"
      ? "brand"
      : "barndwithingredients";


  const getPrescriptionPrint = async () => {
    let resp = await API.prescriptionPrint({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      if (resp.data.vitalData.length > 0) {
        setVitalData(resp.data?.vitalData[0]);
        setIsVitalDataExist(true);
      }
      if (resp.data.patientData.length > 0)
        setPatientData(resp.data?.patientData[0]);
      setComplaintData(resp.data?.complainData);
      setDiagnosisData(resp.data?.diagonsisData);
      if (resp.data?.recommendedPrescriptionSettingData.length > 0)
        setRecommendedPrescriptionSettingData(
          resp.data?.recommendedPrescriptionSettingData[0]
            .recommendedPrescription
        );
      if (resp.data?.recommendedPrescription.length > 0)
        setRecommendedPrescriptionData(resp.data?.recommendedPrescription);
      let presciptionData = resp.data?.patientPresciptionData;
      if (presciptionData && presciptionData.length > 0) {
        setPrescriptionDate(
          moment(presciptionData[0].updatedAt).format("DD-MMM-YYYY")
        );
        for (let i in presciptionData) {
          if (presciptionData[i].insulinCalculator) {
            setIsInsulinCalculator(true);
          }
        }
      }
      setPatientPresciptionData(resp.data?.patientPresciptionData);
      if (resp.data?.clinicData.length > 0) {
        setClinicData(resp.data?.clinicData[0]);
        setPhoneNo(resp.data?.clinicData[0].address.phone);
        let paperSize = localStorage.getItem("prescriptionPadSize") ? localStorage.getItem("prescriptionPadSize") : "A4"
        paperSize = "A4";//remove this line for different paper size functionality
        setPrintMarginTop(convertPercentageToPx("height", resp.data?.clinicData[0].prescription_print_margin_top, paperSize) + "px")
        setPrintMarginBottom(convertPercentageToPx("height", resp.data?.clinicData[0].prescription_print_margin_bottom, paperSize) + "px")
        setPrintMarginLeft(convertPercentageToPx("width", resp.data?.clinicData[0].prescription_print_margin_left, paperSize) + "px")
        setPrintMarginRight(convertPercentageToPx("width", 10, paperSize) + "px")

      }
      if (resp.data?.instructionPrintData.length > 0)
        setInstructionPrintData(resp.data.instructionPrintData);
      if (resp.data?.instructionPrintData.length > 0)
        setDoseTimePrintList(resp.data.doseTimeList);
      if (resp.data?.doctorData)
        setDocotrData(resp.data?.doctorData)
    }
  };

  const calculateBmi = (height, weight) => {
    let height_in_m = height / 100;
    if (height_in_m > 0 && weight > 0) {
      let bmi = weight / (height_in_m * height_in_m);
      return bmi.toFixed(2);
    } else {
      return;
    }
  };

  const convertPercentageToPx = (dimension, val, paperSize) => {
    let values = valuesInPxOptoins.find((obj) => obj.paperSize === paperSize)
    let widthInPx = values.widthInPx
    let heightInPx = values.heightInPx
    let result = 0
    result = dimension === "width" ? ((widthInPx * val) / 100) : ((heightInPx * val) / 100)
    return result
  }

  useEffect(() => {
    if (onPageLoad) {
      getPrescriptionPrint();
      //hanldePaperSize(localStorage.getItem("prescriptionPadSize"))
    }
    setOnPageLoad(false);
  }, []);






  const goToHome = async () => {
    history.push({
      pathname: "/",
      goToHome: patinetData._id,
      econsultation: patientVisit?.econsultation,
      activeTab: patientVisit?.activeTab,
      navigateFrom: "PrescriptionPrint"
    });
  };

  const sharePrescription = async () => {
    let resp = await API.sharePrescription({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (resp.data.status === "Success" && resp.data.statusCode == 200) {
      let msg = "Prescription Shared Successfully";
      const notification = notificationSystem.current;
      notification.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: <div>{msg}</div>,
        level: "success",
        position: "tr",
        autoDismiss: 2,
      });
    }
  };


  const changePrescription = async () => {
    getPrescriptionPrint();
  };

  const hanldePaperSize = (paperSize) => {
    //console.log(clinicData)
    setPrintMarginTop(convertPercentageToPx("height", clinicData.prescription_print_margin_top, paperSize) + "px")
    setPrintMarginBottom(convertPercentageToPx("height", clinicData.prescription_print_margin_bottom, paperSize) + "px")
    setPrintMarginLeft(convertPercentageToPx("width", clinicData.prescription_print_margin_left, paperSize) + "px")
    setPrintMarginRight(convertPercentageToPx("width", 10, paperSize) + "px")
  }

  const marginTopCss =
    "@media print {.pagebreak-avoid {page-break-inside: avoid !important;page-break-after: avoid !important;page-break-before: avoid !important;break-inside: avoid !important;}.print-table {width: 85%;}.component-to-print-itm{page-break-inside: avoid !important;}@page {  margin-top:" +
    printMarginTop +
    ";margin-bottom:" +
    printMarginBottom +
    ";margin-left:" +
    printMarginLeft +
    ";margin-right:" + printMarginRight + "}}";

  //console.log(printMarginTop, printMarginBottom, printMarginLeft, printMarginRight, "printvalues")
  return (
    <span ref={ref}>
      <style scoped>{marginTopCss}</style>

      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <div id="printable" data-template-type="html" className="ui-sortable" style={{ width: '100%' }}>
        <div
          className={"pad-y print-table marginTop marginBottom"}
          style={{
            fontFamily: "Roboto",
            fontSize: "14px",
            position: "relative",
            width: '100%'
          }}
        >

          {props && (
            <div className="title_header" id="divPreview">
              {userType === "doctor" ? (
                <NavLink
                  className="btn btn-fill btn-secondary "
                  to={`/Prescription/${appointmentId}/${patientId}/${printLanguage}`}
                >
                  <span>Back</span>
                </NavLink>
              ) : userType === "receptionist" &&
                localStorage.getItem("is_prescription_edit_by_receptionist") ===
                "yes" ? (
                <NavLink
                  className="btn btn-fill btn-secondary "
                  to={`/Prescription/${appointmentId}/${patientId}/${printLanguage}`}
                >
                  <span>Back</span>
                </NavLink>
              ) : userType === "educator" &&
                localStorage.getItem("is_prescription_edit_by_educator") ===
                "yes" ? (
                <NavLink
                  className="btn btn-fill btn-secondary "
                  to={`/Prescription/${appointmentId}/${patientId}/${printLanguage}`}
                >
                  <span>Back</span>
                </NavLink>
              ) : (
                ""
              )}
              <h5
                style={{ fontWeight: "500", fontSize: "14px", marginTop: "0", padding: "10px" }}
              >
                Print Preview
              </h5>
            </div>
          )}
          {clinicData &&
            clinicData.is_prescription_share_on_app === "yes" &&
            patinetData &&
            patinetData?.app &&
            userType === "doctor" && (
              <Button
                id="share"
                className="btn btn-fill btn-primary"
                onClick={() => {
                  sharePrescription();
                }}
              >
                Share
              </Button>
            )}
          {clinicData &&
            clinicData.is_prescription_share_on_app === "yes" &&
            patinetData &&
            patinetData?.app &&
            userType === "educator" &&
            localStorage.getItem("is_prescription_edit_by_educator") ===
            "yes" && (
              <Button
                id="share"
                className="btn btn-fill btn-primary"
                onClick={() => {
                  sharePrescription();
                }}
              >
                Share
              </Button>
            )}
          {clinicData &&
            clinicData.is_prescription_share_on_app === "yes" &&
            patinetData &&
            patinetData?.app &&
            userType === "receptionist" &&
            localStorage.getItem("is_prescription_edit_by_receptionist") ===
            "yes" && (
              <Button
                id="share"
                className="btn btn-fill btn-primary"
                onClick={() => {
                  sharePrescription();
                }}
              >
                Share
              </Button>
            )}

          {patientPresciptionData &&
            patientPresciptionData.length > 0 &&
            isInsulinCalculator &&
            userType === "doctor" && (
              <div
                id="divInsulinDose"
                class={
                  clinicData.is_prescription_share_on_app === "yes" &&
                    patinetData &&
                    patinetData?.app &&
                    userType === "doctor"
                    ? "is-share"
                    : ""
                }
              >
                <InsulinDose
                  visitInfo={patientVisits}
                  callType="prescriptionPrint"
                  prescriptionPrint={patientPresciptionData}
                  changePrescriptionPrint={changePrescription}
                />
              </div>
            )}

          {patientPresciptionData &&
            patientPresciptionData.length > 0 &&
            isInsulinCalculator &&
            userType === "educator" &&
            localStorage.getItem("is_prescription_edit_by_educator") ===
            "yes" && (
              <div
                id="divInsulinDose"
                class={
                  clinicData.is_prescription_share_on_app === "yes" &&
                    patinetData &&
                    patinetData?.app &&
                    userType === "educator" &&
                    localStorage.getItem("is_prescription_edit_by_educator") ===
                    "yes"
                    ? "is-share"
                    : ""
                }
              >
                <InsulinDose
                  visitInfo={patientVisits}
                  callType="prescriptionPrint"
                  prescriptionPrint={patientPresciptionData}
                  changePrescriptionPrint={changePrescription}
                />
              </div>
            )}

          {patientPresciptionData &&
            patientPresciptionData.length > 0 &&
            isInsulinCalculator &&
            userType === "receptionist" &&
            localStorage.getItem("is_prescription_edit_by_receptionist") ===
            "yes" && (
              <div
                id="divInsulinDose"
                class={
                  clinicData.is_prescription_share_on_app === "yes" &&
                    patinetData &&
                    patinetData?.app &&
                    userType === "receptionist" &&
                    localStorage.getItem(
                      "is_prescription_edit_by_receptionist"
                    ) === "yes"
                    ? "is-share"
                    : ""
                }
              >
                <InsulinDose
                  visitInfo={patientVisits}
                  callType="prescriptionPrint"
                  prescriptionPrint={patientPresciptionData}
                  changePrescriptionPrint={changePrescription}
                />
              </div>
            )}
          {/* <div id="divPaperSize" style={{ display: "inline-block", position: "absolute", top: "0", right: "197px" }}>
            <form>
              <div class="form-group" style={{ marginBottom: "0" }}>
                <select class="form-control" selected="a4" onChange={(e) => { hanldePaperSize(e.target.value) }}>
                  <option value="prescriptionPad">Prescription Pad</option>
                  <option value="a4">A4</option>
                  <option value="a5">A5</option>
                  <option value="a6">A6</option>
                  <option value="b5">B5</option>

                </select>
              </div>
            </form>
          </div> */}

          <Button
            id="btnOut"
            className="out-button btn btn-fill btn-secondary"
            onClick={() => goToHome()}
          >
            Go To Home
          </Button>

          <div style={{ margin: "0px auto", border: "0", width: "100%" }}>
            <div>
              <div className="thead">
                <div className="px-0" style={{ border: "0", padding: "0" }}>
                  <div
                    className="bg-header"
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "6px",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        border: "0",
                        borderRadius: "6px",
                        fontWeight: "600",
                        color: "black",
                        fontWeight: "normal",
                        width: "100%",
                        backgroundColor: "#d1d0d0",
                      }}
                      className=""
                    >
                      <div>
                        <div className="thead-cols">
                          <span
                            className="txt-black px-5 py-0"
                            style={{
                              textAlign: "left",
                              color: "black",
                              padding: "4px 10px",
                              border: "0",
                              fontWeight: "600",
                            }}
                          >
                            Name:
                            <span className="txt-black">
                              {patinetData.title}. {patinetData.firstName}{" "}
                              {patinetData.lastName}
                            </span>
                          </span>
                          <span
                            className="txt-black px-5 py-0"
                            style={{
                              border: "0",
                              color: "black",
                              padding: "2px 15px",
                              fontWeight: "600",
                            }}
                          >
                            Age: {patinetData.age != undefined ? patinetData.dob != undefined ? PF.getAgeByDob(patinetData.dob) : patinetData.age : ""}{" "}
                            <span className="txt-black">Y</span> /{" "}
                            {patinetData.gender === "Male" ? " M" : " F"}{" "}
                          </span>
                          <span
                            className="txt-black px-5 py-0"
                            style={{
                              border: "0",
                              textAlign: "right",
                              color: "black",
                              padding: "4px 10px",
                              fontWeight: "600",
                            }}
                          >
                            Date:
                            {
                              <span className="txt-black">
                                {presciptionDate}{" "}
                              </span>
                            }
                          </span>
                        </div>
                        <div className="thead-cols">
                          <span
                            colSpan={2}
                            className="txt-black px-5 py-0"
                            style={{
                              border: "0",
                              textAlign: "left",
                              color: "black",
                              padding: "4px 10px",
                              fontWeight: "600",
                            }}
                          >
                            City:
                            <span className="txt-black px-5 py-0">
                              {patinetData.city}
                            </span>
                          </span>
                          <span
                            className="txt-black px-5 py-0"
                            style={{
                              border: "0",
                              textAlign: "right",
                              color: "black",
                              padding: "4px 10px",
                              fontWeight: "600",
                            }}
                          >
                            Patient Id:{" "}
                            <span className="txt-black">
                              {patinetData.patientClinicId}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {clinicData &&
                clinicData.is_print_vital_complaint_diagnosis === "yes" && (
                  <>
                    <div>
                      <span style={{ border: "0" }}>
                        <div
                          style={{
                            fontWeight: "normal",
                            width: "100%",
                            border: "0",
                            margin: "4px 0",
                          }}
                        >
                          <div>
                            <div className="vital-signs-wraper">
                              {vitalData.height !== 0 && (
                                <span style={{ border: "0" }}>
                                  <strong>Height</strong>:{" "}
                                  <span>
                                    {isVitalDataExist
                                      ? vitalData.height
                                      : patinetData.height}
                                  </span>{" "}
                                  CM
                                </span>
                              )}
                              {vitalData.weight !== 0 && (
                                <span style={{ border: "0" }}>
                                  <strong>Weight</strong>:{" "}
                                  <span>
                                    {isVitalDataExist
                                      ? vitalData.weight
                                      : patinetData.weight}
                                  </span>{" "}
                                  KG
                                </span>
                              )}
                              {vitalData.height !== 0 &&
                                vitalData.weight !== 0 && (
                                  <span style={{ border: "0" }}>
                                    <strong>BMI</strong>:{" "}
                                    <span>
                                      {isVitalDataExist
                                        ? calculateBmi(
                                          vitalData.height,
                                          vitalData.weight
                                        )
                                        : calculateBmi(
                                          patinetData.height,
                                          patinetData.weight
                                        )}
                                    </span>
                                  </span>
                                )}
                              {vitalData.bp !== "0/0" &&
                                vitalData.bp !== "/" && (
                                  <span style={{ border: "0" }}>
                                    <strong>BP</strong>:{" "}
                                    <span>{vitalData.bp}</span> mmHg
                                  </span>
                                )}
                              {vitalData.pulse !== 0 && (
                                <span style={{ border: "0" }}>
                                  <strong>Pulse</strong>:{" "}
                                  <span>{vitalData.pulse}</span>/min
                                </span>
                              )}
                              {vitalData.temprature !== 0 && (
                                <span style={{ border: "0" }}>
                                  <strong>Temprature</strong>:{" "}
                                  <span>{vitalData.temprature}</span> F
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </span>
                    </div>
                    <div>
                      <span style={{ border: "0" }}>
                        <div
                          style={{
                            fontWeight: "normal",
                            width: "100%",
                            border: "0",
                          }}
                        >
                          <div>
                            {compalintData.length > 0 && (
                              <div className="complaints-list">
                                <span style={{ border: "0" }}>
                                  <strong>Complaint</strong>:{" "}
                                  <span>
                                    {compalintData.map((complaint, idx) => (
                                      <span>
                                        {complaint.symptomsSubCategoryName}
                                        {compalintData.length - 1 !== idx
                                          ? " | "
                                          : ""}
                                      </span>
                                    ))}
                                  </span>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </span>
                    </div>
                    <div>
                      <span style={{ border: "0" }}>
                        <div
                          style={{
                            fontWeight: "normal",
                            width: "100%",
                            border: "0",
                          }}
                        >
                          <div>
                            {diagnosisData.length > 0 && (
                              <div>
                                <span style={{ border: "0" }}>
                                  <strong>Diagnosis</strong>:
                                  <span>
                                    {diagnosisData.map((diagnosis, idx) => (
                                      <span>
                                        {diagnosis.diagonsisSubCategoryName}
                                        {diagnosisData.length - 1 !== idx
                                          ? " | "
                                          : ""}
                                      </span>
                                    ))}
                                  </span>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </span>
                    </div>
                  </>
                )}
              <div>
                <span className="py-15" style={{ border: "0" }}>
                  <div style={{ width: "100%", margin: "4px 0", border: "0" }}>
                    <div>
                      <div>
                        <span style={{ fontSize: "18px", border: "0" }}>
                          <strong>Rx</strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="recommendations-list">
                {recommendedPrescriptionSettingData.map((itm, idx) =>
                  itm.recommendedType.map((obj, key) => {
                    if (obj.top !== undefined && obj.top == 1) {
                      return (
                        <span className="flex-itm">
                          <span className="fs-8">●</span>{" "}
                          {recommendedPrescriptionData.map((obj1, key1) => {
                            if (obj1._id === itm.recommendedPrescriptionId) {
                              return obj1.recommendedLanguage.hasOwnProperty(
                                printLanguage
                              )
                                ? obj1.recommendedLanguage[printLanguage]
                                : obj1.recommendedLanguage[1];
                            }
                          })}
                        </span>
                      );
                    }
                  })
                )}
              </div>
              <div>
                <span style={{ border: "0" }}>
                  <div
                    className="p-td-10 table-row-border"
                    style={{ width: "100%", border: "0" }}
                  >
                    <div style={{ verticalAlign: "top" }}>
                      {patientPresciptionData.map((itm, idx) => {
                        return (
                          <div className="drug-list-item component-to-print-itm">
                            <span
                              className="drug-details"
                              style={{ border: "0", width: "100%" }}
                            >
                              <div className="t-drug-type">
                                <strong>
                                  {++idx}. {itm.drugType}</strong>−{" "}
                                {is_brand_with_ingredients_disable === "brand"
                                  ? <strong> {itm.drugNameWithDose} </strong>
                                  : is_brand_with_ingredients_disable === "barndwithingredients"
                                    ? <><strong>{itm.drugNameWithDose}</strong>
                                      {itm.drugNameWithIngredients ?
                                        <div className="ingredients-item">
                                          ({itm.drugNameWithIngredients})
                                        </div> : ""
                                      }
                                    </>
                                    : itm.drugNameWithIngredients}{" "}
                                {" "}
                              </div>
                              <div className="dose-instructions">
                                <div className="t-dose-time">
                                  {itm.doseTimeSlot.map((ele, key) => {
                                    return (
                                      <div>
                                        {ele.customizeFrequency.map(
                                          (subEle, subKey) => {
                                            if (itm.drugType === "SYP")
                                              convertToMl = subEle.key;
                                            return (
                                              <span>
                                                {subEle.key === "." ? (
                                                  <strong>{subEle.key}</strong>
                                                ) : (
                                                  subEle.key
                                                )}
                                              </span>
                                            );
                                          }
                                        )}
                                        {itm.drugType === "INJ" ||
                                          itm.drugType === "INSULIN"
                                          ? " U"
                                          : itm.drugType === "SYP"
                                            ? " TSF (" +
                                            5 * eval(convertToMl) +
                                            " ml)"
                                            : ""}
                                        {doseTimePrintList.map((obj, key2) => {
                                          if (
                                            ele.doseTimeSlotId &&
                                            ele.doseTimeSlotId !== null &&
                                            obj._id === ele.doseTimeSlotId
                                          ) {
                                            if (
                                              obj.doseTimeSlotLanguage !==
                                              undefined
                                            ) {

                                              return (
                                                <span>
                                                  (
                                                  {obj.doseTimeSlotLanguage.hasOwnProperty(
                                                    printLanguage
                                                  )
                                                    ? obj.doseTimeSlotLanguage[
                                                    printLanguage
                                                    ]
                                                    : obj.doseTimeSlotLanguage[1]}
                                                  )
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span>
                                                  ({ele.doseTimeSlotName})
                                                </span>
                                              );
                                            }
                                          }
                                        })}
                                      </div>
                                    );
                                  })}
                                </div>
                                <span
                                  className="drug-instrns"
                                  style={{ textAlign: "left", border: "0" }}
                                >
                                  {itm.instructionName.map((ele1, key1) => {
                                    return (
                                      <span className="t-insdivuction">
                                        {instructionPrintData.map((obj, key2) => {
                                          if (
                                            ele1.instructionId &&
                                            ele1.instructionId !== null &&
                                            obj._id === ele1.instructionId
                                          ) {
                                            if (
                                              ele1.instructionType === "patient"
                                            ) {
                                              if (
                                                obj.instructionName ===
                                                ele1.instructionName
                                              ) {
                                                if (
                                                  obj.instructionInDiffLanguage !==
                                                  undefined
                                                ) {
                                                  return (
                                                    <span>
                                                      (
                                                      {obj.instructionInDiffLanguage.hasOwnProperty(
                                                        printLanguage
                                                      )
                                                        ? obj
                                                          .instructionInDiffLanguage[
                                                        printLanguage
                                                        ]
                                                        : obj
                                                          .instructionInDiffLanguage[1]}
                                                      )
                                                    </span>
                                                  );
                                                }
                                              } else {
                                                return (
                                                  <span>
                                                    ({ele1.instructionName})
                                                  </span>
                                                );
                                              }
                                            } else {
                                              if (
                                                obj.instructionInDiffLanguage !==
                                                undefined
                                              ) {
                                                return (
                                                  <span>
                                                    (
                                                    {obj.instructionInDiffLanguage.hasOwnProperty(
                                                      printLanguage
                                                    )
                                                      ? obj
                                                        .instructionInDiffLanguage[
                                                      printLanguage
                                                      ]
                                                      : obj
                                                        .instructionInDiffLanguage[1]}
                                                    )
                                                  </span>
                                                );
                                              } else {
                                                return (
                                                  <span>
                                                    ({ele1.instructionName})
                                                  </span>
                                                );
                                              }
                                            }
                                          }
                                        })}
                                      </span>
                                    );
                                  })}
                                  <span className="t-duration">
                                    {itm.duration.no !== "" ? <strong> X</strong> : ""}{" "}
                                    {itm.duration.no} {itm.duration.period}{" "}
                                  </span>
                                </span>
                              </div>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="clearfix pagebreak"></div>
                </span>
              </div>
              <div className="bottom-recommendations-list">
                {recommendedPrescriptionSettingData.map((itm, idx) => (
                  <div style={{ border: "0" }}>
                    {itm.recommendedType.map((obj, key) => {
                      if (obj.bottom !== undefined && obj.bottom == 1) {
                        return (
                          <span className="flex-itm">
                            <span className="fs-8">●</span>{" "}
                            {recommendedPrescriptionData.map((obj1, key1) => {
                              if (obj1._id === itm.recommendedPrescriptionId) {
                                return obj1.recommendedLanguage.hasOwnProperty(
                                  printLanguage
                                )
                                  ? obj1.recommendedLanguage[printLanguage]
                                  : obj1.recommendedLanguage[1];
                              }
                            })}
                          </span>
                        );
                      }
                    })}
                  </div>
                ))}
              </div>
              <div className="dr-sign-area"></div>
              <div className="doctor-signs">
                <span style={{ border: "0", marginTop: "15px" }}>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      bottom: "0",
                      border: "0",
                    }}
                  >
                    {clinicData &&
                      clinicData.is_print_doctor_signature === "yes" && (
                        <div>
                          <span style={{ border: "0" }}>
                            <div style={{ width: "100%", border: "0" }}>
                              <div>
                                <div>
                                  <span
                                    style={{ border: "0", fontSize: "16px" }}
                                  >
                                    {doctorData && doctorData?.title}
                                    <b>.</b> {clinicData.doctorName}{" "}
                                    {clinicData.mci_number && (
                                      <sapn>
                                        (MCI No : {clinicData.mci_number})
                                      </sapn>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </span>
                        </div>
                      )}
                    <div style={{ textAlign: "center", lineHeight: "1" }}>
                      <span
                        className="mt-15"
                        style={{
                          fontWeight: "600",
                          marginTop: "20px",
                          border: "0",
                          display: "inline-block",
                          fontSize: "16px",
                        }}
                      >
                        {prescriptionPrintBottomLineOptions.map((obj, key) => {
                          if (obj.printLanguage === printLanguage) {
                            return <span>{obj.forAppointement} :</span>;
                          }
                        })}{" "}
                        {phoneNo}
                      </span>
                      {patinetData.nextDate && (
                        <span className="next-visit">
                          <span
                            className="py-15"
                            style={{ border: "0", fontSize: "16px" }}
                          >
                            <span
                              style={{ border: "0" }}
                              className="investigation-advised"
                            >
                              <strong>
                                {prescriptionPrintBottomLineOptions.map(
                                  (obj, key) => {
                                    if (obj.printLanguage === printLanguage) {
                                      return <span>{obj.nextVisitDate} :</span>;
                                    }
                                  }
                                )}
                                <span>
                                  {" "}
                                  {moment(patinetData.nextDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              </strong>
                            </span>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </span>
              </div>
            </div>
            <div
              className="bottomFixed"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                lineHeight: "1.1",
                fontSize: "16px",
              }}
            >
              {prescriptionPrintBottomLineOptions.map((obj, key) => {
                if (obj.printLanguage === printLanguage) {
                  return <span>{obj.bottomLine}</span>;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </span>
  );
});

export default ComponentToPrint;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { updatePlanAction } from 'Admin/actions/master';
import loadingImg from 'Admin/assets/img/loading.gif';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    plan_name: 'required',
    amount: 'required',
    duration: 'required',

};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdatePlan extends Component {
    constructor(props) {
        super(props);
        this.vForm = this.refs.vForm;
        this.state = {
            formArr: [],
            isLogin: true,
            showProcessing: false,
            formData: {
                plan_name: this.props.location.state.row.planName,
                amount: this.props.location.state.row.amount,
                duration: this.props.location.state.row.duration,
                status: this.props.location.state.row.status,
                id: this.props.location.state.row._id,
            },
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isUpdatePlanError !== this.props.isUpdatePlanError) {
            this.setState({ showProcessing: false });
            if (nextProps.updatePlanResponse.errors) {
                nextProps.updatePlanResponse.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: key.msg })
                });
            }
        }

        if (nextProps.isUpdatePlan !== this.props.isUpdatePlan && nextProps.updatePlanResponse.status === 'Success' && this.state.formData.plan_name !== '') {
            this.props.handleClick('success', nextProps.updatePlanResponse.msg)
            this.props.history.push(`/admin/plan-list`)
        }
    }

    addPlan(evt) {
        evt.preventDefault();
        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true });
            _this.props.updatePlanAction(this.state);
        }
        //validation.errors()
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Form horizontal>
                                <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                                    <img src={loadingImg} alt="Loading" width="40px" />
                                    <center>Logging In - Please Wait</center>
                                </div>
                                <Card
                                    title={<legend className="line-removes">
                                        <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                    </legend>}
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={2}>
                                                    <Col componentClass={ControlLabel} >
                                                        Plan Name <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="plan_name" value={this.state.formData.plan_name} onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">{this.state.plan_nameError}{this.state.formArr.plan_name && validation.errors.first('plan_name')}</span>
                                                </Col>
                                                <Col sm={2}>
                                                    <Col componentClass={ControlLabel} >
                                                        Amount <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="amount" value={this.state.formData.amount} onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg" refs="amount">{this.state.amountError}{this.state.formArr.amount && validation.errors.first('amount')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Duration (In Days) <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="duration" value={this.state.formData.duration} onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg" refs="amount">{this.state.durationError}{this.state.formArr.duration && validation.errors.first('duration')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Status
                                                    </Col>
                                                    <FormControl componentClass="select" name="status" value={this.state.formData.status} onChange={e => { this.handleChange(e); }}>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </FormControl>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    }
                                    ftTextCenter
                                    legend={
                                        <div>
                                            <Button fill bsStyle="info" type="button" onClick={this.addPlan.bind(this)}>Submit</Button>&nbsp;
                                        </div>
                                    }
                                />
                            </Form>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

        isUpdatePlan: state.master.isUpdatePlan,
        isUpdatePlanError: state.master.isUpdatePlanError,
        updatePlanResponse: state.master.updatePlan,

    }
}
// export default AddPlan;
export default withRouter(connect(mapStateToProps, { updatePlanAction })(UpdatePlan));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/instruction'
/* Add Instruction list and Add form */

export const addInstructionRequest = () => ({
    type: actionTypes.GET_ADD_INSTRUCTION_REQUEST
})
export const addInstructionSuccess = (response) => ({
    type: actionTypes.GET_ADD_INSTRUCTION_SUCCESS,
    payload: {
        response,
    }
})
export const addInstructionError = (error) => ({
    type: actionTypes.GET_ADD_INSTRUCTION_ERROR,
    payload: {
        error,
    }
})
export const addInstructionAction = (data) => {
    return dispatch => {
        dispatch(addInstructionRequest())
        return API.addInstruction(data)
            .then(response => {

                dispatch(addInstructionSuccess(response.data))
            })
            .catch(error => {
                dispatch(addInstructionError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Instruction list and Add form */
export const updateInstructionRequest = () => ({
    type: actionTypes.GET_UPDATE_INSTRUCTION_REQUEST
})
export const updateInstructionSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_INSTRUCTION_SUCCESS,
    payload: {
        response,
    }
})
export const updateInstructionError = (error) => ({
    type: actionTypes.GET_UPDATE_INSTRUCTION_ERROR,
    payload: {
        error,
    }
})
export const updateInstructionAction = (data) => {
    return dispatch => {
        dispatch(updateInstructionRequest())

        return API.updateInstruction(data)
            .then(response => {

                dispatch(updateInstructionSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateInstructionError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Instruction list  */
export const InstructionListRequest = () => ({
    type: actionTypes.GET_INSTRUCTION_LIST_REQUEST
})
export const InstructionListSuccess = (response) => ({
    type: actionTypes.GET_INSTRUCTION_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const InstructionListError = (error) => ({
    type: actionTypes.GET_INSTRUCTION_LIST_ERROR,
    payload: {
        error
    }
})
export const instructionListAction = (data, search) => {
    return dispatch => {
        dispatch(InstructionListRequest());
        return API.instructionList(data)
            .then(response => {
                dispatch(InstructionListSuccess(response.data))
            })
            .catch(error => {
                dispatch(InstructionListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Instruction Status Change  */
export const InstructionChangeStatusRequest = () => ({
    type: actionTypes.GET_INSTRUCTION_CHANGE_STATUS_REQUEST
})
export const InstructionChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_INSTRUCTION_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const InstructionChangeStatusError = (error) => ({
    type: actionTypes.GET_INSTRUCTION_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const instructionChangeStatusAction = (data) => {

    return dispatch => {
        dispatch(InstructionChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeInstructionStatus(FormData)
            .then(response => {

                dispatch(InstructionChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(InstructionChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
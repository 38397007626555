/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { uploadFileAction } from 'Admin/actions/link';
import { updateFoodCategoryAction } from 'Admin/actions/food_category';



let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name: 'required',
    //file: 'required',

};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateFoodCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {

            cardHidden: true,
            formArr: [],
            nameError: null,
            fileError: null,
            formData: {
                name: this.props.location.state.row.name,
                file: this.props.location.state.row.icon,
                id: this.props.location.state.row._id,
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isUpdateFoodCategoryError !== this.props.isUpdateFoodCategoryError) {
            if (nextProps.UpdateFoodCategoryMessage.errors) {
                nextProps.UpdateFoodCategoryMessage.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }

        if (nextProps.isUploadFile !== this.props.isUploadFile) {
            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg })
                });
            }
        }

        if (nextProps.isUpdateFoodCategory !== this.props.isUpdateFoodCategory) {
            this.props.handleClick('success', nextProps.UpdateFoodCategory.msg)
            this.props.history.push('/admin/food-category-list')
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };


    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];
        this.props.uploadFileAction(file);
        reader.readAsDataURL(file);
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    updateFoodCategory(evt) {
        evt.preventDefault();
        const _this = this;
        if (this.allValidate(false)) {
            let field = this.state.formData;
            field.icon = field.file;
            this.setState({ formData: field });
            _this.setState({ showProcessing: true });
            _this.props.updateFoodCategoryAction(this.state);
        }
        //validation.errors()
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {


        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Title <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>

                                                <FormControl type="text" name="name" id="name" placeholder="Name" value={this.state.formData.name} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.nameError}
                                                    {this.state.formArr.name && validation.errors.first('name')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                File
                                            </Col>
                                            <Col sm={6}>
                                                <input type="hidden" name="id" value={this.state.formData.id} onChange={e => { this.fileChangedHandler(e, "id") }}></input>
                                                <input type="file" name="file" onChange={e => { this.fileChangedHandler(e, "file") }}></input>{this.state.formData.file}
                                            </Col>
                                        </FormGroup>


                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.updateFoodCategory.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>

                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.foodCategory.message,

        UpdateLink: state.link.UpdateLink,
        isUpdateLink: state.link.isUpdateLink,
        isUpdateLinkError: state.link.isUpdateLinkError,

        UpdateFoodCategory: state.foodCategory.UpdateFoodCategory,
        isUpdateFoodCategory: state.foodCategory.isUpdateFoodCategory,
        isUpdateFoodCategoryError: state.foodCategory.isUpdateFoodCategoryError,

        isUploadFile: state.link.isUploadFile,
        isUploadFileError: state.link.isUploadFileError,
        uploadFile: state.link.uploadFile,
    }
}
export default withRouter(connect(mapStateToProps, { updateFoodCategoryAction, uploadFileAction })(UpdateFoodCategory));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
    response: [],
    message: '',
    isAddTreatment: false,
    isAddTreatmentError: false,
    AddTreatment: false,
    isUpdateTreatment: false,
    isUpdateTreatmentError: false,
    UpdateTreatment: false,
    isTreatmentChangeStatus: false,
    isTreatmentChangeStatusError: false,
    TreatmentChangeStatus: false,
    isTreatmentList: false,
    isTreatmentListError: false,
    TreatmentList: false,
    TreatmentListType: false,
    isTreatmentListType: false,
    isTreatmentListTypeError: false,
    isTreatmentChangeOrder: false,
    isTreatmentChangeOrderError: false

}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_ADD_TREATMENT_REQUEST:
            return {
                ...state,
                message: '',
                isAddTreatment: false,
                isAddTreatmentError: false,
            }
        case actionTypes.GET_ADD_TREATMENT_SUCCESS:
            return {
                ...state,
                message: 'Treatment Added Successfully',
                AddTreatment: action.payload.response,
                isAddTreatment: true,
                isAddTreatmentError: false,
            }
        case actionTypes.GET_ADD_TREATMENT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isAddTreatment: false,
                isAddTreatmentError: true
            }

        //Treatment Update

        case actionTypes.GET_UPDATE_TREATMENT_REQUEST:
            return {
                ...state,
                message: '',
                isUpdateTreatment: false,
                isUpdateTreatmentError: false,
            }
        case actionTypes.GET_UPDATE_TREATMENT_SUCCESS:
            return {
                ...state,
                message: 'Treatment Updated Successfully',
                UpdateTreatment: action.payload.response,
                isUpdateTreatment: true,
                isUpdateTreatmentError: false,
            }
        case actionTypes.GET_UPDATE_TREATMENT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUpdateTreatment: false,
                isUpdateTreatmentError: true
            }

        //Treatment List
        case actionTypes.GET_TREATMENT_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isTreatmentList: false,
                isTreatmentListError: false,
            }
        case actionTypes.GET_TREATMENT_LIST_SUCCESS:
            return {
                ...state,
                message: 'Treatment List',
                TreatmentList: action.payload.response,
                isTreatmentList: true,
                isTreatmentListError: false,
            }
        case actionTypes.GET_TREATMENT_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTreatmentList: false,
                isTreatmentListError: true
            }


        //Treatment List
        case actionTypes.GET_TREATMENT_LIST_TYPE_REQUEST:
            return {
                ...state,
                message: '',
                isTreatmentListType: false,
                isTreatmentListTypeError: false,
            }
        case actionTypes.GET_TREATMENT_LIST_TYPE_SUCCESS:
            return {
                ...state,
                message: 'Treatment List',
                TreatmentListType: action.payload.response,
                isTreatmentListType: true,
                isTreatmentListTypeError: false,
            }
        case actionTypes.GET_TREATMENT_LIST_TYPE_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTreatmentListType: false,
                isTreatmentListTypeError: true
            }


        //Treatment Status Change
        case actionTypes.GET_TREATMENT_CHANGE_STATUS_REQUEST:
            return {
                ...state,
                message: '',
                isTreatmentChangeStatus: false,
                isTreatmentChangeStatusError: false,
            }
        case actionTypes.GET_TREATMENT_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                message: 'Treatment Status Changed',
                TreatmentChangeStatus: action.payload.response,
                isTreatmentChangeStatus: true,
                isTreatmentChangeStatusError: false,
            }
        case actionTypes.GET_TREATMENT_CHANGE_STATUS_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTreatmentChangeStatus: false,
                isTreatmentChangeStatusError: true
            }

        case actionTypes.TREATMENT_CHANGE_ORDER_REQUEST:
            return {
                ...state,
                message: '',
                isTreatmentChangeOrder: false,
                isTreatmentChangeOrderError: false,
            }

        case actionTypes.TREATMENT_CHANGE_ORDER_SUCCESS:
            return {
                ...state,
                message: 'Treatment Order Changed Successfully',
                isTreatmentChangeOrder: true,
                isTreatmentChangeOrderError: false,
            }
        case actionTypes.GET_ADD_TREATMENT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTreatmentChangeOrder: false,
                isTreatmentChangeOrderError: true
            }

        default:
            return state
    }
}

/********* Show list of Treatment */
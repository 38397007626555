/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  loginCheck: false,
  isLogin: false,
  isLoginError: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_SIGNIN_USER_REQUEST:
      return {
        ...state,
        message: '',
        isLogin: false,
        isLoginError: false,
      }
    case actionTypes.GET_SIGNIN_USER_SUCCESS:
      return {
        ...state,
        response: action.payload.response,
        message: 'Logged in successfully',
        LoginData: action.payload.response,
        isLogin: true,
        isLoginError: false,
      }
    case actionTypes.GET_SIGNIN_USER_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLogin: false,
        isLoginError: true
      }


    default:
      return state
  }
}

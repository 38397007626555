/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken, getRequestWithToken } from "./helper";

export const clinicList = (data) =>
    postRequestWithToken(data, "admin/clinic-list");
export const clinicDetail = (data) =>
    postRequestWithToken(data, "admin/clinic-detail");
export const addClinic = (data) =>
    postRequestWithToken(data, "admin/add-clinic");
export const addClinicCsv = (data) =>
    postRequestWithToken(data, "admin/add-clinic-csv");
export const updateClinic = (data) =>
    postRequestWithToken(data, "admin/edit-clinic");
export const changeClinicStatus = (data) =>
    postRequestWithToken(data, "admin/change-clinic-status");
export const editClinic = (data) =>
    postRequestWithToken(data, "admin/edit-clinic");
export const clinicDeleted = (data) =>
    postRequestWithToken(data, "admin/clinic-delete");
export const sendLoginDetail = (data) =>
    postRequestWithToken(data, "admin/send-login-detail");
export const ImageRemove = (data) =>
    postRequestWithToken(data, "admin/remove-image");

export const ChangeDemo = (data) =>
    postRequestWithToken(data, "admin/change-demo-clinicid");

export const clinicSubscription = (data) =>
    postRequestWithToken(data, "admin/subscription-list");
export const addClinicSubscription = (data) =>
    postRequestWithToken(data, "admin/add-subscription");
export const updateSubscription = (data) =>
    postRequestWithToken(data, "admin/edit-subscription");
export const changeSubscriptionStatus = (data) =>
    postRequestWithToken(data, "admin/change-subscription-status");

//--- Clinic Invitation
export const clinicInvitationList = (data) =>
    postRequestWithToken(data, "admin/invitation-list");
export const addClinicInvitation = (data) =>
    postRequestWithToken(data, "admin/add-invitation");
export const editClinicInvitation = (data) =>
    postRequestWithToken(data, "admin/update-invitation");
export const invitationSendMail = (data) =>
    postRequestWithToken(data, "admin/invitation-send-mail");

// Clinic Performance
export const clinicPerformanceList = (data) =>
    postRequestWithToken(data, "admin/clinic-performance-list");

// Clinic Simple List
export const clinicSimpleList = (data) =>
    postRequestWithToken(data, "admin/clinic-dropdown-list");
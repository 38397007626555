/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddFoodCategory: false,
  isAddFoodCategoryError: false,
  AddFoodCategory: false,
  isUpdateFoodCategory: false,
  isUpdateFoodCategoryError: false,
  UpdateFoodCategory: false,
  isFoodCategoryChangeStatus: false,
  isFoodCategoryChangeStatusError: false,
  FoodCategoryChangeStatus: false,
  isFoodCategoryList: false,
  isFoodCategoryListError: false,
  FoodCategoryList: false,
  // isUploadFile:false,
  // isUploadFileError: false,
  // uploadFile: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_FOOD_CATEGORY_REQUEST:
      return {
        ...state,
        message: '',
        isAddFoodCategory: false,
        isAddFoodCategoryError: false,
      }
    case actionTypes.GET_ADD_FOOD_CATEGORY_SUCCESS:
      return {
        ...state,
        message: 'Food Category Added Successfully',
        AddFoodCategory: action.payload.response,
        isAddFoodCategory: true,
        isAddFoodCategoryError: false,
      }
    case actionTypes.GET_ADD_FOOD_CATEGORY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddFoodCategory: false,
        isAddFoodCategoryError: true
      }

    //Food Category Update

    case actionTypes.GET_UPDATE_FOOD_CATEGORY_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateFoodCategory: false,
        isUpdateFoodCategoryError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_CATEGORY_SUCCESS:
      return {
        ...state,
        message: 'Food Category Updated Successfully',
        UpdateFoodCategory: action.payload.response,
        isUpdateFoodCategory: true,
        isUpdateFoodCategoryError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_CATEGORY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateFoodCategory: false,
        isUpdateFoodCategoryError: true
      }

    //Link List
    case actionTypes.GET_FOOD_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isFoodCategoryList: false,
        isFoodCategoryListError: false,
      }
    case actionTypes.GET_FOOD_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        message: 'Food Category List',
        FoodCategoryList: action.payload.response,
        isFoodCategoryList: true,
        isFoodCategoryListError: false,
      }
    case actionTypes.GET_FOOD_CATEGORY_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isFoodCategoryList: false,
        isFoodCategoryListError: true
      }


    //Food Category Status Change
    case actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isFoodCategoryChangeStatus: false,
        isFoodCategoryChangeStatusError: false,
      }
    case actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Food Category Status Changed',
        FoodCategoryChangeStatus: action.payload.response,
        isFoodCategoryChangeStatus: true,
        isFoodCategoryChangeStatusError: false,
      }
    case actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isFoodCategoryChangeStatus: false,
        isFoodCategoryChangeStatusError: true
      }


    //Upload file
    //   case actionTypes.POST_UPLOAD_FILE_REQUEST:
    //   return {
    //     ...state,
    //     message: '',
    //     isUploadFile: false,
    //     isUploadFileError: false,
    //   }
    // case actionTypes.GET_UPLOAD_FILE_SUCCESS:
    //   return {
    //     ...state,
    //     message: 'File Uploaded',
    //     uploadFile: action.payload.response,
    //     isUploadFile: true,
    //     isUploadFileError: false,
    //   }
    // case actionTypes.GET_UPLOAD_FILE_ERROR:
    //   return {
    //     ...state,
    //     uploadFile: action.payload.error,
    //     message: action.payload.error,
    //     isUploadFile: false,
    //     isUploadFileError: true
    //   }


    default:
      return state
  }
}

/********* Show list of Link */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/treatment_type'
/* Add TreatmentType list and Add form */

export const addTreatmentTypeRequest = () => ({
    type: actionTypes.GET_ADD_TREATMENT_TYPE_REQUEST
})
export const addTreatmentTypeSuccess = (response) => ({
    type: actionTypes.GET_ADD_TREATMENT_TYPE_SUCCESS,
    payload: {
        response,
    }
})
export const addTreatmentTypeError = (error) => ({
    type: actionTypes.GET_ADD_TREATMENT_TYPE_ERROR,
    payload: {
        error,
    }
})
export const addTreatmentTypeAction = (data) => {
    return dispatch => {
        dispatch(addTreatmentTypeRequest())
        return API.addTreatmentType(data)
            .then(response => {

                dispatch(addTreatmentTypeSuccess(response.data))
            })
            .catch(error => {
                dispatch(addTreatmentTypeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update TreatmentType list and Add form */
export const updateTreatmentTypeRequest = () => ({
    type: actionTypes.GET_UPDATE_TREATMENT_TYPE_REQUEST
})
export const updateTreatmentTypeSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_TREATMENT_TYPE_SUCCESS,
    payload: {
        response,
    }
})
export const updateTreatmentTypeError = (error) => ({
    type: actionTypes.GET_UPDATE_TREATMENT_TYPE_ERROR,
    payload: {
        error,
    }
})
export const updateTreatmentTypeAction = (data) => {
    return dispatch => {
        dispatch(updateTreatmentTypeRequest())

        return API.updateTreatmentType(data)
            .then(response => {

                dispatch(updateTreatmentTypeSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateTreatmentTypeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* TreatmentType list  */
export const TreatmentTypeListRequest = () => ({
    type: actionTypes.GET_TREATMENT_TYPE_LIST_REQUEST
})
export const TreatmentTypeListSuccess = (response) => ({
    type: actionTypes.GET_TREATMENT_TYPE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const TreatmentTypeListError = (error) => ({
    type: actionTypes.GET_TREATMENT_TYPE_LIST_ERROR,
    payload: {
        error
    }
})
export const treatmentTypeListAction = (data, search) => {
    return dispatch => {
        dispatch(TreatmentTypeListRequest());
        return API.treatmentTypeList(data)
            .then(response => {
                dispatch(TreatmentTypeListSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentTypeListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* TreatmentType Status Change  */
export const TreatmentTypeChangeStatusRequest = () => ({
    type: actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_REQUEST
})
export const TreatmentTypeChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const TreatmentTypeChangeStatusError = (error) => ({
    type: actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const treatmentTypeChangeStatusAction = (data) => {

    return dispatch => {
        dispatch(TreatmentTypeChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeTreatmentTypeStatus(FormData)
            .then(response => {
                dispatch(TreatmentTypeChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentTypeChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


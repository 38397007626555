/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { instructionListAction, instructionChangeStatusAction } from 'Admin/actions/instruction';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { languageListAction } from 'Admin/actions/language';
import { appConstants } from 'Admin/_constants/app.constants';

var count = 1;

class InstructionList extends Component {
    constructor(props) {

        super(props);
        const answer_array = this.props.location.pathname.split('/');

        this.state = {
            languageList: [],
            instructionList: [],
            isLoading: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                //treatmentTypeId: this.props.location.state.row._id,
                treatmentTypeId: answer_array[3] ? answer_array[3] : '',
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "english"
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
    }

    componentDidMount() {
        this.props.instructionListAction(this.state.formData);
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.languageListAction(data);
    }

    componentWillReceiveProps(nextProps) {
        let listData = [];
        let i = 0;

        if (nextProps.isInstructionList !== this.props.isInstructionList) {

            this.setState({
                instructionList: nextProps.InstructionList.data.data,
                totalCount: nextProps.InstructionList.data.count
            });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {

            this.setState({ languageList: nextProps.LanguageList.data });
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }

    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    editButton(cell, row, enumObject, rowIndex) {
        return (<p><Link to={{ pathname: `/admin/update-instruction/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>

        </p>)
    }





    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.instructionChangeStatusAction(event);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }




    handleLanguage(event) {
        let formData = this.state.formData
        formData['lang'] = event.target.value
        this.setState({ formData: formData });
    }

    handleInstruction(cell, row, enumObject, rowIndex) {
        return row.instruction[this.state.formData.lang]
    }

    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        this.props.instructionListAction(formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        }

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.instructionListAction(data)
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                this.setState({ sizePerPage: 10 })
                this.props.instructionListAction(data);
            }
        } else {
            this.componentDidMount();
        }
        this.setState({ searchText: text });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {


        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
        };

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        {/* <Row>
                                            <Col md={6}>
                                                <Button bsStyle="info" fill pullRight onClick={() => this.props.history.replace('/admin/add-instruction/'+ this.state.formData.treatmentTypeId )}>Add Instruction</Button>
                                            </Col>
                                        </Row>&nbsp; */}

                                        <Row>

                                            <Col md={3}>
                                                <Col componentClass={ControlLabel}>
                                                    Language
                                                </Col>

                                                <FormControl componentClass="select" name="langname" onChange={e => { this.handleLanguage(e); }}>
                                                    <option value="">Select Language</option>
                                                    {this.state.languageList.map(function (item) {
                                                        return <option key={item._id} value={item.name}>{appConstants.ucFirst(item.name)}</option>
                                                    })}
                                                </FormControl>
                                            </Col>
                                        </Row>&nbsp;
                                        <BootstrapTable
                                            selectRow="{selectRowProp}"
                                            deleteRow={false}
                                            data={this.state.instructionList}
                                            search={true}
                                            multiColumnSearch={true}
                                            pagination={true}
                                            options={options}
                                            striped
                                            hover
                                            condensed
                                            scrollTop={'Bottom'}
                                            remote={true}
                                            fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                        >
                                            <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'instruction' }} dataFormat={this.handleInstruction.bind(this)} dataSort={true} >Instruction</TableHeaderColumn>


                                            <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' dataFormat={appConstants.ucFirst} dataSort={true}>Status</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} dataSort={false}>Action</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        InstructionList: state.instruction.InstructionList,
        isInstructionList: state.instruction.isInstructionList,
        isInstructionListError: state.instruction.isInstructionListError,

        InstructionChangeStatus: state.instruction.InstructionChangeStatus,
        isInstructionChangeStatus: state.instruction.isInstructionChangeStatus,
        isInstructionChangeStatusError: state.instruction.isInstructionChangeStatusError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { instructionListAction, instructionChangeStatusAction, languageListAction })(InstructionList));

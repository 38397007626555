/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { clinicDocumentListAction } from 'Admin/actions/clinic_document';
import { clinicDocumentchangestatusAction } from 'Admin/actions/clinic_document';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { Button } from 'react-bootstrap';
import moment from 'moment';

class ClinicDocumentList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      status: "",
      clinicDocumentList: [],
      isLoading: true,
      asc_desc: true,
      userRow: null,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: '',
      formData: {
        direction: 'desc',
        order: "createdAt",
        offset: 0,
        limit: 10
      }
    }

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
  }
  componentDidMount() {
    this.props.clinicDocumentListAction(this.state.formData)
  }

  componentWillReceiveProps(nextProps) {
    let date1 = [];

    if (nextProps.isClinicDocumentList !== this.props.isClinicDocumentList) {

      // if(nextProps.ClinicDocumentList && nextProps.ClinicDocumentList.data && nextProps.ClinicDocumentList.data.data){
      //     date1 = nextProps.ClinicDocumentList.data.data;
      // }
      if (nextProps.ClinicDocumentList.data) {
        this.setState({ clinicDocumentList: nextProps.ClinicDocumentList.data.data, totalCount: nextProps.ClinicDocumentList.data.count });
      }
    }
    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  navigateto() {
    // if (this.clicked !== "Button") {
    window.location.href = "Admin/add-clinic-document";
    // }
    // reset
    this.clicked = "";
  }

  editButton(cell, row, enumObject, rowIndex) {
    return (<span><Link to={{ pathname: `update-clinic-document/`, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
    </span>)
  }

  onUserCount(cell, row, enumObject, rowIndex) {

    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  toggleEdit(event) {
    this.setState({
      userRow: event
    });
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.status == 'active') {
      newstatus = 'inactive';
    } else {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.clinicDocumentchangestatusAction(event);
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    if (this.state.searchText === "") {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    }

    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    this.props.clinicDocumentListAction(data);
    
    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['direction'] = this.state.formData.direction;
        data['order'] = this.state.formData.order;
        data['offset'] = this.state.formData.offset;
        data['limit'] = this.state.formData.limit;
        data['search'] = text;
        this.setState({ sizePerPage: 10 })
        this.setState({ currentPage: 1 })
        this.props.clinicDocumentListAction(data);
      }
    } else {
      setTimeout(() => {
        this.componentDidMount();
      }, 100);
    }
    this.setState({ searchText: text })
  }

  clearSearch(e) {
    this.setState({ searchText: '' });
    this.componentDidMount();
  }

  sortHandle() {
    this.setState({ asc_desc: !this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc ? "asc" : "desc"
    this.setState({ formData: data });
    this.props.clinicDocumentListAction(data);
  }

  render() {

    const selectRowProp = { mode: 'checkbox' };
    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      noDataText: this._setTableOption(),
    };

    const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
      return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
    };

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={6}>
                        <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => this.props.history.replace('add-clinic-document')}>Add Library Document</Button>
                      </Col>
                    </Row>&nbsp;
                    <div className='search-box-admin'>
                      <div className='search'>
                        <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                        <span className='input-group-btn'>
                          <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button>
                        </span>
                      </div>
                    </div>
                    <BootstrapTable
                      selectRow="{selectRowProp}"
                      deleteRow={false}
                      data={this.state.clinicDocumentList}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={'Bottom'}
                      remote={true}
                      fetchInfo={{ dataTotalSize: this.state.totalCount }}
                    >
                      <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '5%', textAlign: 'center' }} tdStyle={{ width: '5%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Client Document' }} dataField='name'  >Document Name
                        {
                          this.state.asc_desc ?
                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                      </TableHeaderColumn>
                      <TableHeaderColumn tdAttr={{ "data-attr": "title" }} thStyle={{ width: "10%", textAlign: "center" }} tdStyle={{ width: "10%", textAlign: "center" }} dataField="createdAt" dataFormat={getDrugCreationDate} > Date</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)}  >Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ClinicDocumentList: (state.clinicDocument) ? state.clinicDocument.ClinicDocumentList : [],
    isClinicDocumentList: (state.clinicDocument) ? state.clinicDocument.isClinicDocumentList : [],
    isClinicDocumentListError: (state.clinicDocument) ? state.clinicDocument.isClinicDocumentListError : [],
    ClinicDocumentChangeStatus: (state.clinicDocument) ? state.clinicDocument.ClinicDocumentChangeStatus : [],
    isClinicDocumentChangeStatus: (state.clinicDocument) ? state.clinicDocument.isClinicDocumentChangeStatus : [],
    isClinicDocumentChangeStatusError: (state.clinicDocument) ? state.clinicDocument.isClinicDocumentChangeStatusError : [],
  }
}
export default withRouter(connect(mapStateToProps, { clinicDocumentListAction, clinicDocumentchangestatusAction })(ClinicDocumentList));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';

class CustomRadio extends Component {
    render() {
        const { number, label, option, name, disabled, ...rest } = this.props;
        return (
            <div className="radio">
                <input id={number} name={name} type="radio" disabled={disabled} value={option}  {...rest} />
                <label htmlFor={number}>
                    {label}
                </label>
            </div>
        );
    }
}

export default CustomRadio;

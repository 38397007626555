/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";

import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { updateClinicDocumentAction } from "Admin/actions/clinic_document";
import * as API from "Admin/api/clinic_document";
import { appConstants } from "Front/_constants/app.constants.js";
import Loading from "react-loading";
require("datatables.net-responsive");
$.DataTable = require("datatables.net-bs");
const PreferenceToSet = (props) => {
  //console.log(props);
  const [formData, setFormData] = useState({});
  const [specializationList, setSpecializationList] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [specializationIds, SetSpecializationIds] = useState([]);
  const [clinicDocumentId, setClinicDocumentId] = useState("");
  const [errors, setErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [documentFiles, setDocumentFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputHandler = (e) => {
    e.preventDefault();
    let saveFormData = formData;
    let errArr = errors;
    let imageExt = [];
    if (e.target.type === "file") {

      setDocumentFile(true);
      for (let i = 0; i < e.target.files.length; i++) {
        imageExt[i] = isImage(e.target.files[i].name);
      }
      if (imageExt.includes(false)) {
        errArr.documentFile = "error";
      } else {
        errArr.documentFile = "";
      }
      setErrors({ ...errArr });
      saveFormData[e.target.name] = e.target.files;

    } else {
      saveFormData[e.target.name] = e.target.value;
    }
    setFormData({ ...formData, ...saveFormData });
  };
  const goBackFun = (e) => {
    props.history.replace(sessionStorage.getItem("prvUrl"));
  };
  const validate = () => {
    let post = formData;
    let errArr = errors;
    if (documentFiles) {
      if (post.documentFile !== "undefined" && post.documentFile.length > 0) {
        errArr.documentFile = "";
      } else {
        errArr.documentFile = "error";
      }
    }
    if (post.name !== "undefined" && post.name !== "") {
      errArr.name = "";
    } else {
      errArr.name = "error";
    }
    setErrors({ ...errArr });
  };

  const submitHandler = async (event) => {
    validate();
    setIsLoading(true);
    event.preventDefault();
    let form = new FormData();
    form.append("name", formData.name);
    form.append("clinicDocumentId", clinicDocumentId);
    for (const i in specializationIds) {
      form.append("specializationIds", specializationIds[i]);
    }
    if (documentFiles) {
      for (let i = 0; i < formData.documentFile.length; i++) {
        form.append(
          "file",
          formData.documentFile[i],
          formData.documentFile[i].name
        );
      }
    }
    let resp = await API.updateClinicDocument(form);
    if (resp) {
      //setIsLoading(false);
      //setSuccessMsg("Data Added successfully.");
      setFormData({});
      props.handleClick("success", "Document updated successfully.");
      props.history.push(`/admin/clinic-document-list`);

      // setTimeout(function () {
      //   setSuccessMsg("");
      //   window.location.href = "/admin/clinic-document-list";
      // }, 3000);
    } else {
      setIsLoading(false);
      setSuccessMsg("Something went wrong.");
      setTimeout(function () {
        setSuccessMsg("");
      }, 3000);
    }
    setIsLoading(false);
  };

  const isImage = (icon) => {
    const ext = ["jpg", "png", "jpeg", "mp4", "pdf", "PDF"];
    return ext.some((el) => icon.endsWith(el));
  };

  const handleSpecialization = (value) => {
    let multipleSelect = specialization;
    multipleSelect = value;
    setSpecialization(multipleSelect);
    let data = multipleSelect;
    const tempClinic = [...new Set(data && data.map((item) => item.value))];
    let dataSpecialization = {
      specialization_ids: tempClinic,
    };
    SetSpecializationIds(tempClinic);
  };

  const specializationListAction = async (page) => {
    let resp = await API.specializationList();
    let data = resp.data.data;
    const tempList = [...new Set(data && data.map((item) => item))].map(
      function (item) {
        return {
          label: item.name,
          value: item._id,
        };
      }
    );
    setSpecializationList(tempList);
  };
  const getDeatils = async (page) => {
    let rowData = props.location.state.row;
    if (rowData) {

      if (rowData.specializationIds) {
        let strSpecialzation = Object.values(rowData.specializationIds).join(
          ","
        );
        let dataSpecializationList = strSpecialzation.split(",");
        setSpecialization(dataSpecializationList);
        SetSpecializationIds(dataSpecializationList);
      }

      let saveFormData = formData;
      saveFormData["name"] = rowData.name;
      saveFormData["documentFile"] = appConstants.s3UploadUrl + rowData.path;
      setFormData({ ...formData, ...saveFormData });

      setClinicDocumentId(rowData._id);
    }
  };

  useEffect(() => {
    specializationListAction();
    getDeatils();
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      goBackFun(e);
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form
                  horizontal
                  noValidate
                  validated={validated}
                  onSubmit={(e) => submitHandler(e)}
                  className="add-specialization_form"
                >
                  <fieldset>
                    <FormGroup>
                      <Col md={6}>
                        <Col componentClass={ControlLabel}>Specialization</Col>
                        <Select
                          placeholder="Select specializations"
                          name="specialization"
                          id="specialization"
                          closeOnSelect={false}
                          multi={true}
                          value={specialization}
                          options={specializationList}
                          onChange={(e) => {
                            handleSpecialization(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>

                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document Name</ControlLabel>
                            <FormControl
                              type="text"
                              name="name"
                              id="description"
                              placeholder="Description (Max 250 words)"
                              autoComplete="off"
                              value={formData.name}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={"form-control " + (errors.name || "")}
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document upload</ControlLabel>
                            <FormControl
                              type="file"
                              //key={formData.documentFile}
                              //value={formData.documentFile}
                              name="documentFile"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              placeholder="File"
                              className={
                                "form-control " + (errors.documentFile || "")
                              }
                              required
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <Button
                              type="submit"
                              className="btn-fill btn btn-primary"
                              disabled={isLoading}
                            >
                              Save
                            </Button>
                            <Col md={12}>
                              {successMsg !== "" && (
                                <p
                                  className="alert-heading"
                                  style={{ color: "#155724" }}
                                >
                                  {successMsg}
                                </p>
                              )}
                            </Col>
                            &nbsp;
                          </Col>
                          {isLoading ? (
                            <Loading
                              type="bars"
                              color="#000000"
                              style={{ margin: "0px auto", width: "40px" }}
                            />
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    msg: state.clinicDocument.message,
    UpdateClinicDocument: state.clinicDocument.isUpdateClinicDocument,
    isUpdateClinicDocument: state.clinicDocument.isUpdateClinicDocument,
    isUpdateClinicDocumentError:
      state.clinicDocument.isUpdateClinicDocumentError,
  };
}
export default withRouter(
  connect(mapStateToProps, { updateClinicDocumentAction })(PreferenceToSet)
);

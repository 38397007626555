/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';

class PatientHeader extends Component {
    render() {
        return (
            <div className="title text-left">{this.props.pvDetail.name + "-" + this.props.pvDetail.patientId}&nbsp;&nbsp;&nbsp;&nbsp;|<span>Height: {this.props.pvDetail.height}</span>|<span>Weight: {this.props.pvDetail.weight} </span>|<span>BMI: {this.props.pvDetail.bmi}</span>|<span>Age: {this.props.pvDetail.age}</span>
            </div>
        );
    }
}

export default PatientHeader;

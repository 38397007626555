/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import * as API from "../../api/icons";
import * as AdminAPI from "../../../Admin/api/icons";
import Card from "Admin/components/Card/Card.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

const AddEditIcons = (props) => {
  let history = useHistory();

  const [formData, setFormData] = useState({});
  const [imageData, setImageData] = useState();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formDataFile, setFormDataFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [mode, setMode] = useState("add");
  const [iconsId, setIconsId] = useState("");
  const [fileNotPng, setFileNotPng] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputHandler = (e) => {
    //setIsSpecialChar(false)
    let saveFormData = formData;
    let str = e.target.value.replace(/\s+/g, " ");
    saveFormData[e.target.name] = str.trimStart();
    setFormData({ ...formData, ...saveFormData });
    validate();
  };
  const inputHandlerDrop = (e) => {
    let saveFormData = formData;
    let str = e.target.value;
    saveFormData[e.target.name] = str;
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    //console.log(post, "post");
    if (post.title && post.title != "") {
      errArr.title = "";
    } else {
      errArr.title = "error";
      errflag = false;
    }

    if (post.description && post.description != "") {
      errArr.description = "";
    } else {
      errArr.description = "error";
      errflag = false;
    }

    if (post.type && post.type != "") {
      errArr.type = "";
    } else {
      errArr.type = "error";
      errflag = false;
    }

    if (mode === "add") {
      if (post.image && post.image != "") {
        errArr.image = "";
      } else {
        errArr.image = "error";
        errflag = false;
      }
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let saveFormData = formData;
    formData.image = imageData;
    formData.id = iconsId;
    saveFormData["title"] = formData.title?.trim();
    setFormData({ ...formData, ...saveFormData });
    if (validate()) {
      setIsDisabled(true);
      setIsLoading(false);
      // let sentData = new FormData();
      // sentData.append("title", formData.title);
      // sentData.append("description", formData.description);
      // sentData.append("type", formData.type);
      // sentData.append("image", formData.image);

      let resp = "";
      if (mode === "add") {
        resp = await API.addIcons(saveFormData);
        setIsDisabled(false);
        if (resp.status == 200) {
          history.push({ pathname: "/admin/icons-list", from: "add" });
        }
      } else {
        // sentData.append("id", iconsId);
        resp = await API.updateIcons(saveFormData);
        setIsDisabled(false);
        if (resp.status == 200) {
          history.push({ pathname: "/admin/icons-list", from: "edit" });
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let row = props.location.state;
    let saveFormData = formData;
    saveFormData["title"] = "";

    if (row !== undefined && row) {
      setMode("edit");
      setIconsId(row._id);
      //console.log(row, "row");
      setImageData(row.image);
      setFormData({ ...row });
    }
  }, []);

  const fileChangedHandler = async (event, elename) => {
    let file = event.target.files[0];
    // let Data = { ...formData };
    // Data["image"] = file.name;
    // setSubmitData({ ...Data })
    // setFormData({ ...formData, tempFile: image });
    let reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ image: reader.result });
    };
    if (file) {
      let fileData = new FormData();
      fileData.append("file", file);
      let res = await AdminAPI.uploadFiles(fileData);
      if (res.data.statusCode == 200) {
        setImageData(res.data.file_name);
        let tempData = formData;
        tempData["image"] = res.data.file_name;
        setFormData({ ...tempData });
        let errArr = errors;
        errArr.image = "";
        setErrors({ ...errArr });
      }

      // reader.readAsDataURL(file);
    }
    // setFormData({ ...submitData });
  };

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Col md={6}>
                          <ControlLabel>
                            Title<span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="title"
                            id="tile"
                            placeholder="Title"
                            maxLength={50}
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                            autocomplete="off"
                            value={formData.title ? formData.title : ""}
                            className={
                              "insulin-input " +
                              (errors.title ? errors.title : "")
                            }
                          />
                          {isSpecialCharacter && (
                            <span className="star">
                              Special Characters Are Not Allowed
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Col md={6}>
                          <ControlLabel>
                            Description<span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            rows="3"
                            componentClass="textarea"
                            name="description"
                            id="description"
                            placeholder="Description"
                            value={
                              formData.description ? formData.description : ""
                            }
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                          />
                          {errors?.description && (
                            <span className="star">This Field is Required</span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={6}>
                      <Col componentClass={ControlLabel}>
                        Type <span className="star">*</span>
                      </Col>
                      <FormControl
                        componentClass="select"
                        name="type"
                        value={formData.type ? formData.type : ""}
                        className={
                          "insulin-input " + (errors.type ? errors.type : "")
                        }
                        onChange={(e) => {
                          inputHandlerDrop(e);
                        }}
                      >
                        <option value="">Select type</option>
                        <option value="a">A</option>
                        <option value="b">B</option>
                        <option value="c">C</option>
                      </FormControl>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Col md={6}>
                          <ControlLabel>Order</ControlLabel>
                          <FormControl
                            type="Number"
                            name="tagOrder"
                            id="tagOrder"
                            placeholder="Tag Order"
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                            value={formData.tagOrder ? formData.tagOrder : ""}
                          // className={
                          //     "insulin-input " +
                          //     (errors.title ? errors.title : "")
                          // }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={3}>
                      <FormControl
                        type="file"
                        name="image"
                        accept=".png,.jpg "
                        onChange={(e) => {
                          fileChangedHandler(e);
                        }}
                      />
                      <span>{formData.image}</span>
                      {errors.image && (
                        <span className="star">This Field is Required</span>
                      )}
                      {/*<input type="file" name="file" onChange={e => { this.fileChangedHandler(e) }}></input> */}
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-2"></ControlLabel>
                    <Col sm={12} className="btn_wrap">
                      <Row>
                        <Col md={12}>
                          <button
                            type="button"
                            className="btn-fill btn-wd btn btn-primary"
                            onClick={(e) => {
                              submitHandler(e);
                            }}
                            disabled={isDisabled}
                          >
                            {isDisabled && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                            Submit
                          </button>
                          &nbsp;
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AddEditIcons;

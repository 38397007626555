/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from "./actionTypes";
import * as API from "Front/api/diet";

//-- Diet List
export const dietListSuccess = (response) => ({
  type: actionTypes.GET_DIET_LIST_SUCCESS,
  payload: {
    response,
  },
});
export const dietListError = (error) => ({
  type: actionTypes.GET_DIET_LIST_ERROR,
  payload: {
    error,
  },
});
export const dietListAction = (filter) => {
  return (dispatch) => {
    return API.dietList(filter)
      .then((response) => {
        let data = {
          filter: filter,
          data: response.data,
        };
        dispatch(dietListSuccess(data));
      })
      .catch((error) => {
        dispatch(
          dietListError(
            error.response !== undefined
              ? error.response.data
              : "Internet Connection Error"
          )
        );
      });
  };
};

//-- Print diet
export const printDietRequest = () => ({
  type: actionTypes.PRINT_DIET_REQUEST,
});

export const printDietSuccess = (response) => ({
  type: actionTypes.PRINT_DIET_SUCCESS,
  payload: {
    response,
  },
});
export const printDietError = (error) => ({
  type: actionTypes.PRINT_DIET_ERROR,
  payload: {
    error,
  },
});
export const printDietAction = (data) => {
  return (dispatch) => {
    dispatch(printDietRequest());

    let submitData = {
      patientId: data.patient.pId ? data.patient.pId : data.patientId,
      treatmentId: data.treatmentId,
      treatmentName: data.treatmentName,
      treatmentType: data.treatmentType,
      calorie: data.selectCalorie ? data.selectCalorie : data.calorie,
      dietType: data.mealType,
      optionCount: data.dietOption,
      dietLanguage: data.dietLanguage,
      startTimeDelay: data.startTimeDelay,
      color: data.color,
      visitId: data.visitId,
    };

    return API.printDiet(submitData)
      .then((response) => {
        dispatch(printDietSuccess(response));
      })
      .catch((error) => {
        dispatch(
          printDietError(
            error.response !== undefined
              ? error.response.data
              : "Internet Connection Error"
          )
        );
      });
  };
};

//-- Print diet color chnage action
export const printDietColorChangeRequest = () => ({
  type: actionTypes.PRINT_DIET_COLOR_CHANGE_REQUEST,
});

export const printDietColorChangeSuccess = (response) => ({
  type: actionTypes.PRINT_DIET_COLOR_CHANGE_SUCCESS,
  payload: {
    response,
  },
});
export const printDietColorChangeError = (error) => ({
  type: actionTypes.PRINT_DIET_COLOR_CHANGE_ERROR,
  payload: {
    error,
  },
});
export const printDietColorChangeAction = (data) => {
  return (dispatch) => {
    dispatch(printDietColorChangeRequest());

    let submitData = {
      color: data.newColor,
      _id: data.dietId,
      patientId: data.patient,
    };

    return API.dietColorChange(submitData)
      .then((response) => {
        dispatch(printDietColorChangeSuccess(response));
      })
      .catch((error) => {
        dispatch(
          printDietColorChangeError(
            error.response !== undefined
              ? error.response.data
              : "Internet Connection Error"
          )
        );
      });
  };
};

export const sharetDietRequest = () => ({
  type: actionTypes.SHARE_DIET_REQUEST,
});

export const sharetDietSuccess = (response) => (
  //onsole.log(response, "console.log(response)"),
  {
    type: actionTypes.SHARE_DIET_SUCCESS,
    payload: {
      response,
    },
  }
);
export const sharetDietError = (error) => ({
  type: actionTypes.SHARE_DIET_ERROR,
  payload: {
    error,
  },
});
export const sharetDietAction = (data) => {
  return (dispatch) => {
    dispatch(sharetDietRequest());

    return API.shareDiet(data)
      .then((response) => {
        dispatch(sharetDietSuccess(response));
      })
      .catch((error) => {
        dispatch(
          sharetDietError(
            error.response !== undefined
              ? error.response.data
              : "Internet Connection Error"
          )
        );
      });
  };
};

export const cancelDietRequest = () => ({
  type: actionTypes.CANCEL_DIET_REQUEST,
});

export const cancelDietSuccess = (response) => ({
  type: actionTypes.CANCEL_DIET_SUCCESS,
  payload: {
    response,
  },
});
export const cancelDietError = (error) => ({
  type: actionTypes.CANCEL_DIET_ERROR,
  payload: {
    error,
  },
});
export const cancelDietAction = (data) => {
  return (dispatch) => {
    dispatch(cancelDietRequest());

    return API.cancelDiet(data)
      .then((response) => {
        dispatch(cancelDietSuccess(response));
      })
      .catch((error) => {
        dispatch(
          cancelDietError(
            error.response !== undefined
              ? error.response.data
              : "Internet Connection Error"
          )
        );
      });
  };
};

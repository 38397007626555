/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import * as API from "../../api/advertisement";
import Card from "Admin/components/Card/Card.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

const AddEditAdvertisement = (props) => {
  let history = useHistory();

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formDataFile, setFormDataFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [mode, setMode] = useState("add");
  const [advertisementId, setAdvertisementId] = useState("");
  const [fileNotPng, setFileNotPng] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputHandler = (e) => {
    //setIsSpecialChar(false)
    let saveFormData = formData;
    let str = e.target.value.replace(/\s+/g, " ");
    saveFormData[e.target.name] = str.trimStart();
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.title && post.title != "") {
      errArr.title = "";
    } else {
      errArr.title = "error";
      errflag = false;
    }

    if (post.file && post.file != "") {
      errArr.file = "";
    } else {
      errArr.file = "error";
      errflag = false;
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let saveFormData = formData;
    saveFormData["title"] = formData.title?.trim();
    setFormData({ ...formData, ...saveFormData });
    if (validate()) {
      setIsDisabled(true);
      setIsLoading(false);
      let sentData = new FormData();
      sentData.append("title", formData.title);

      sentData.append("file", formDataFile);
      let resp = "";
      if (mode === "add") {
        resp = await API.addAdvertisement(sentData);
        setIsDisabled(false);
        if (resp.data.statusCode == 200) {
          history.push({ pathname: "/admin/advertisement-list", from: "add" });
        }
      } else {
        sentData.append("id", advertisementId);
        resp = await API.updateAdvertisement(sentData);
        if (resp.data.statusCode == 200) {
          history.push({ pathname: "/admin/advertisement-list", from: "edit" });
        }
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let row = props.location.state;
    let saveFormData = formData;
    saveFormData["title"] = "";

    if (row !== undefined && row) {
      // console.log(row,"row")
      setMode("edit");
      setAdvertisementId(row._id);
      setFormData({ ...props.location.state });
    }
  }, []);

  const changeHandlerFile = (event) => {
    if (event.target.files.length > 0) {
      if ((event.target.files[0].type === "image/png") === true) {
        let formDataTemp = event.target.files[0];
        setFormDataFile(formDataTemp);
        setFormData({ file: event.target.files, ...formData });
        validate();
      } else {
        event.target.value = null;
        setFileNotPng(true);
        setTimeout(function () {
          setFileNotPng(false);
        }, 6000);
      }
    }
  };

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <FormGroup>
                    <Col sm={12}>
                      <Row>
                        <Col md={6}>
                          <ControlLabel>
                            Title<span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="title"
                            id="tile"
                            placeholder="Title"
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                            autocomplete="off"
                            value={formData.title ? formData.title : ""}
                            className={
                              "insulin-input " +
                              (errors.title ? errors.title : "")
                            }
                          />
                          {isSpecialCharacter && (
                            <span className="star">
                              Special Characters Are Not Allowed
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col sm={6}>
                      <Row>
                        <Col md={2}>
                          <ControlLabel>
                            File<span className="star">*</span>
                          </ControlLabel>
                        </Col>
                        <Col md={8}>
                          <FormControl
                            placeholder="Upload File"
                            aria-label="File"
                            aria-describedby="basic-addon1"
                            onChange={(e) => {
                              changeHandlerFile(e);
                            }}
                            onBlur={(e) => {
                              changeHandlerFile(e);
                            }}
                            name="file"
                            type="file"
                            accept=".png"
                            allowedFileTypes={["image/png"]}
                          />
                          <span>{formData.imgPath}</span>
                          {errors.file && (
                            <span className="star">This Field is Required</span>
                          )}
                          {fileNotPng && (
                            <span className="star">
                              Only Png Files Are Allowed
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel className="col-sm-2"></ControlLabel>
                    <Col sm={12} className="btn_wrap">
                      <Row>
                        <Col md={12}>
                          <button
                            type="button"
                            className="btn-fill btn-wd btn btn-primary"
                            onClick={(e) => {
                              submitHandler(e);
                            }}
                            disabled={isDisabled}
                          >
                            {isDisabled && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                            Submit
                          </button>
                          &nbsp;
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AddEditAdvertisement;

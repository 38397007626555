/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper"

export const addClinicDocument = (data) => postRequestWithTokenFile(data, "admin/add-clinic-document");
export const updateClinicDocument = (data) => postRequestWithTokenFile(data, 'admin/update-clinic-document');
export const changeClinicDocumentStatus = (data) => postRequestWithToken(data, 'admin/change-status-clinic-document');
export const clinicDocumentList = (data) => postRequestWithToken(data, 'admin/clinic-document-list');
export const specializationList = (data) => postRequestWithToken(data, 'admin/specialization-list');
export const clinicList = (data) => postRequestWithToken(data, 'admin/search-clinic-by-specializations')


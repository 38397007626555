import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "../../../../api/emr"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

const MasterSymptomSubCategoryList = () => {
  let history = useHistory();
  const [formData, setFormData] = useState({ limit: 10, page: 0, direction: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [symptomSubCategoryList, setSymptomSubCategoryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [clinicWsie, setClinicWise] = useState(false);
  const [sortData, setSortData] = useState(false);
  const notificationSystem = useRef(null);

  const getsymptomSubCategoryList = async (from = {}) => {
    setIsLoading(true);
    let resp = {}
    if (from?.target !== undefined && from?.target?.checked) {
      setClinicWise(true)
      setFormData({ limit: 10, page: 0 });
      resp = await API.masterSymptomSubCategoryList({ "limit": formData.limit, "offset": 0, "order": "createdAt", "direction": formData.direction, "search": from.search, flag: "clinic" });

    } else {
      setClinicWise(false)
      setFormData({ limit: 10, page: 0 });
      resp = await API.masterSymptomSubCategoryList({ "limit": formData.limit, "offset": 0, "order": "createdAt", "search": from.search, "direction": formData.direction });
    }

    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setSymptomSubCategoryList(resp.data.data.data);
      setTotalCount(resp.data.data.count);
    }

  }

  const getsymptomSubCategorySearchSortList = async (from = {}) => {
    setIsLoading(true);
    let resp = {}
    if (clinicWsie)
      resp = await API.masterSymptomSubCategoryList({ "limit": formData.limit, "offset": formData.page, "direction": formData.direction, "order": "createdAt", "search": from.search, flag: "clinic" });
    else resp = await API.masterSymptomSubCategoryList({ "limit": formData.limit, "offset": formData.page, "direction": formData.direction, "order": "createdAt", "search": from.search, });

    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setSymptomSubCategoryList(resp.data.data.data);
      setTotalCount(resp.data.data.count);
    }

  }



  const handleToastifyMessage = (data) => {
    let msg = ""
    if (data === "add")
      msg = "Complaint Added Successfully"
    if (data === "edit")
      msg = "Complaint Edit Successfully"
    if (data === "delete")
      msg = "Complaint Deleted Successfully"
    if (data === "status")
      msg = "Status Changed Successfully"
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
      message: <div>
        {msg}
      </div>,
      level: 'success',
      position: 'tr',
      autoDismiss: 2,
    });
  }


  const srNo = (cell, row, enumObject, rowIndex) => {
    return (rowIndex + 1 + (formData.limit * formData.page))
  }

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  // const actionButton = (cell, row, enumObject, rowIndex) => { 
  //     return (<><Link to={{ pathname: `/admin/edit-drug-category/`+ row._id, state: {row} }}>  Edit </Link></>)
  //   }

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return (<span>{row.clinicId === undefined ? <><Link to={{ pathname: `/admin/edit-symptom-sub-category/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;&nbsp; &nbsp;&nbsp;</> : ""}

      <i className={(row.enabled === "1") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row, rowIndex)} aria-hidden="true"></i>
      &nbsp;&nbsp; &nbsp;&nbsp;
      <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i>
    </span>)
  }

  const statusChangedHandler = async (row, index) => {
    let resp = {}
    if (clinicWsie)
      resp = await API.statusChangeSymptomsSubCategory({ "id": row._id, "status": row.enabled === "1" ? "0" : "1", "flag": "clinic" });
    else resp = await API.statusChangeSymptomsSubCategory({ "id": row._id, "status": row.enabled === "1" ? "0" : "1" });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      handleToastifyMessage('status')
      let subCategoryList = symptomSubCategoryList;
      let count = totalCount + 1;
      if (row.enabled === "1") {
        subCategoryList[index].enabled = "0"
      } else {
        subCategoryList[index].enabled = "1"
      }
      setSymptomSubCategoryList(subCategoryList);
      setTotalCount(count);
    }
  }

  const getConfirmFun = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteSubCategory(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }


  const deleteSubCategory = async (id) => {
    let resp = {}
    if (clinicWsie)
      resp = await API.deleteSymptomSubCategory({ "id": id, "flag": "clinic" });
    else resp = await API.deleteSymptomSubCategory({ "id": id });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      handleToastifyMessage('delete')
      setSymptomSubCategoryList(symptomSubCategoryList.filter(item => item._id !== id));
    }
  };

  const onPageChange = async (page, sizePerPage, from = {}) => {
    let data = formData;
    data['page'] = page - 1;
    data['limit'] = sizePerPage;

    setFormData({ ...formData, ...data });
    getsymptomSubCategorySearchSortList(data)
    let scrollBar = 50;  
    if(scroll == page &&  sizePerPage < scrollBar ) {
      eval('$(".ps").scrollTop(0)');
    } else if( totalCount < page * sizePerPage){
      eval('$(".ps").scrollTop(0)');
    }
    setScroll(page)
  }

  const onSearchChange = (e, field = "") => {

    let saveFormData = formData;
    saveFormData[field] = e.trimStart();
    saveFormData["page"] = 0;
    setFormData({ ...formData, ...saveFormData });
    getsymptomSubCategorySearchSortList(saveFormData);
  }

  function sortHandle() {
    let data = formData;
    data['direction'] = sortData ? "desc" : "asc";
    setFormData({ ...formData, ...data });
    setSortData(!sortData)
    getsymptomSubCategorySearchSortList(data);

  }
  const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
      return { backgroundColor: '#f3f1b0' };
    }
  }


  const options = {
    //onSortChange: this.onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption()

  };

  useEffect(() => {
    if (history?.location?.from === "add" || history?.location?.from === "edit")
      handleToastifyMessage(history?.location?.from)
    getsymptomSubCategoryList()
  }, [])


  const getDrugCreationDate = (cell, row) => {
    if (row?.createdAt)
      return (<span>{moment(row?.createdAt).format("DD-MMM-YYYY")}</span>)
    else
      return ""
  };

  return (
    <div className="main-content">
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <Grid fluid>

        <Row>
          <Col md={12}>
            <Card
              content={
                <div className="fresh-datatables">
                  <Row>
                    <Col md={6}>
                      <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-symptom-sub-category")}>Add Complaint</Button>
                      <div style={{ display: "inline-block", marginLeft: "20px" }} className="form-check">
                        <input type="checkbox" name="CustomSymptoms" id="CustomSymptoms" onChange={e => { getsymptomSubCategoryList(e); }} defaultChecked={false} />
                        <label style={{ marginLeft: "10px" }} className="form-check-label" for="CustomSymptoms">
                          Clinic Wise Complaint
                        </label>
                      </div>
                    </Col>
                    <Col sm={6} className="form-group">
                      <FormControl
                        type="text"
                        autocomplete="off"
                        name="search"
                        id="search"
                        value={formData.search ? formData.search : ""}
                        onChange={(e) => {
                          onSearchChange(e.target.value.replace(/\s+/g, " "), "search");
                        }}
                        placeholder="Search here ..."
                      />
                    </Col>
                  </Row>
                  <div className='table-responsive'>
                    <BootstrapTable
                      deleteRow={false}
                      key={symptomSubCategoryList}
                      data={symptomSubCategoryList}
                      search={false}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={"Bottom"}
                      remote={true}
                      fetchInfo={{ dataTotalSize: totalCount }}
                      trStyle={rowStyleFormat}
                    >
                      <TableHeaderColumn
                        hidden={true}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="_id"
                        isKey
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "#" }}
                        thStyle={{ width: "5%", textAlign: "center" }}
                        tdStyle={{ width: "5%", textAlign: "center" }}
                        dataField="sn"
                        dataFormat={srNo}
                      >
                        S.No.
                      </TableHeaderColumn>
                      {clinicWsie ?
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "15%", textAlign: "center" }}
                          tdStyle={{ width: "15%", textAlign: "center" }}
                          dataField="symptomsSubCategoryName"
                        >
                          Complaint
                          {
                            !sortData ?
                              <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                              <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                          }
                        </TableHeaderColumn> :
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "20%", textAlign: "center" }}
                          tdStyle={{ width: "20%", textAlign: "center" }}
                          dataField="name"
                        >
                          Complaint
                          {
                            !sortData ?
                              <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                              <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                          }
                        </TableHeaderColumn>
                      }

                      {clinicWsie &&
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "10%", textAlign: "center" }}
                          tdStyle={{ width: "10%", textAlign: "center" }}
                          dataField="createdAt"
                          dataFormat={getDrugCreationDate}
                        >
                          Date
                        </TableHeaderColumn>}

                      {clinicWsie &&
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "10%", textAlign: "center" }}
                          tdStyle={{ width: "10%", textAlign: "center" }}
                          dataField="doctorName"
                        >
                          Doctor Name
                        </TableHeaderColumn>
                      }

                      {clinicWsie &&
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "10%", textAlign: "center" }}
                          tdStyle={{ width: "10%", textAlign: "center" }}
                          dataField="clinicIdNumber"
                        >
                          Clinic Id
                        </TableHeaderColumn>
                      }

                      {clinicWsie &&
                        <TableHeaderColumn
                          tdAttr={{ "data-attr": "title" }}
                          thStyle={{ width: "15%", textAlign: "center" }}
                          tdStyle={{ width: "15%", textAlign: "center" }}
                          dataField="clinicName"
                        >
                          Clinic
                        </TableHeaderColumn>
                      }



                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="symptomsCategoryName"
                      >
                        Category
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ width: "6%", textAlign: "center" }}
                        tdStyle={{ width: "6%", textAlign: "center" }}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField=""
                        dataFormat={actionButton}
                      >
                        Action
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>

  )
}

export default MasterSymptomSubCategoryList
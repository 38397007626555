/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

//import React, { Component } from 'react';
import moment from 'moment';
import { appConstants } from '../../_constants';

let funs = {};

funs.formatDate = (date1) => {
  let iso_date = date1;

  // if(!date1.iso_date){
  //   iso_date = date1.iso_date;
  // }

  if (!iso_date) {
    return moment().format("DD MMM YYYY");
  }
  return moment(iso_date).format("DD MMM YYYY");
}


export default funs;

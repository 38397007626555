/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import Card from "Admin/components/Card/Card.jsx";
import * as API from "../../../../api/emr";

const AddEditSymptomSubCategory = (props) => {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [mode, setMode] = useState("add");
  const [symptomSubCategoryId, setSymptomSubCategoryId] = useState("");
  const [subCategoryAlreadyExist, setSubCategoryAlreadyExist] = useState(false);
  const [subCategoryAlreadyExistMsg, setSubCategoryAlreadyExistMsg] =
    useState(false);
  const [symptomCategoryList, setSymptomCategoryList] = useState([]);

  const inputHandler = (e) => {
    let saveFormData = formData;

    if (e.target.name === "symptomsCategoryId") {
      if (e.target.value != "") {
        let tempObj = {};
        tempObj = symptomCategoryList.find((x) => x._id === e.target.value);
        saveFormData["symptomsCategoryName"] = tempObj.name;
        saveFormData["symptomsCategoryId"] = e.target.value;
      } else {
        saveFormData["symptomsCategoryName"] = "";
        saveFormData["symptomsCategoryId"] = "";
      }
    } else {
      let str = e.target.value.replace(/\s+/g, " ");
      saveFormData[e.target.name] = str.trimStart();
    }

    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.symptomsCategoryId && post.symptomsCategoryId != "") {
      errArr.symptomsCategoryId = "";
    } else {
      errArr.symptomsCategoryId = "error";
      errflag = false;
    }

    if (post.symptomsSubCategoryName && post.symptomsSubCategoryName != "") {
      errArr.symptomsSubCategoryName = "";
    } else {
      errArr.symptomsSubCategoryName = "error";
      errflag = false;
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (validate()) {
      let sentData = new FormData();
      sentData.append(
        "symptomsSubCategoryName",
        formData.symptomsSubCategoryName
      );
      sentData.append("symptomsCategoryId", formData.symptomsCategoryId);
      sentData.append("symptomsCategoryName", formData.symptomsCategoryName);

      let resp = "";
      if (mode === "add") {
        resp = await API.addSymptomSubCategory(sentData);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setSubCategoryAlreadyExist(true);
            setSubCategoryAlreadyExistMsg(resp.data.msg);
          } else
            history.push({
              pathname: "/admin/symptom-sub-category-list",
              from: "add",
            });
        }
      } else {
        sentData.append("symptomsSubCategoryId", symptomSubCategoryId);
        resp = await API.updateSymptomSubCategory(sentData);
        setIsLoading(false);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setSubCategoryAlreadyExist(true);
            setSubCategoryAlreadyExistMsg(
              resp.data.msg + " " + resp.data.symptomsCategoryName
            );
          } else
            history.push({
              pathname: "/admin/symptom-sub-category-list",
              from: "edit",
            });
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getSymptomCategoryList = async (row) => {
    let resp = await API.symptomCategoryListWithoutPagination();
    if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {

      setSymptomCategoryList(resp.data.data);
      let saveFormData = formData;
      saveFormData["symptomsCategoryId"] = "";
      saveFormData["symptomsSubCategoryName"] = "";
      saveFormData["symptomsCategoryName"] = "";
      if (row !== undefined && row) {
        setMode("edit");
        setSymptomSubCategoryId(row._id);
        saveFormData["symptomsCategoryId"] = row.symptomsCategoryId;
        saveFormData["symptomsSubCategoryName"] = row.name;
        let tempObj = resp.data.data.find(
          (x) => x.name === row.symptomsCategoryName
        );
        saveFormData["symptomsCategoryName"] = tempObj.name;

        setFormData({ ...formData, ...saveFormData });
      }
    }
  };

  useEffect(() => {
    let row = props.location.state?.row;
    getSymptomCategoryList(row);
  }, []);
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>
                              Complaint<span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                              type="text"
                              name="symptomsSubCategoryName"
                              id="symptomsSubCategoryName"
                              placeholder="Complaint"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              autocomplete="off"
                              value={
                                formData.symptomsSubCategoryName
                                  ? formData.symptomsSubCategoryName
                                  : ""
                              }
                              className={
                                "insulin-input " +
                                (errors.symptomsSubCategoryName
                                  ? errors.symptomsSubCategoryName
                                  : "")
                              }
                            />
                            {subCategoryAlreadyExist && (
                              <span className="star">
                                {subCategoryAlreadyExistMsg}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>
                              Category<span className="star">*</span>
                            </ControlLabel>
                            <select
                              className={
                                "insulin-input form-control " +
                                (errors.symptomsCategoryId
                                  ? errors.symptomsCategoryId
                                  : "")
                              }
                              name="symptomsCategoryId"
                              id="symptomsCategoryId"
                              closeOnSelect={false}
                              multi={true}
                              value={formData.symptomsCategoryId}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            >
                              <option value="">Select Category</option>
                              {symptomCategoryList.map(function (item, key) {
                                return (
                                  <option value={item._id}>{item.name}</option>
                                );
                              })}
                            </select>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <button
                              type="button"
                              className="btn-fill btn-wd btn btn-primary"
                              onClick={(e) => {
                                submitHandler(e);
                              }}
                              disabled={isLoading}
                            >
                              {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                              Submit
                            </button>
                            &nbsp;
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AddEditSymptomSubCategory;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
//import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { addTreatmentAction } from 'Admin/actions/treatment';
import { treatmentTypeListAction } from 'Admin/actions/treatment_type';
import { dietTimeListAction } from 'Admin/actions/diet_time';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { appConstants } from 'Admin/_constants/app.constants';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name: 'required',
    treatmentTypeId: 'required',
    instruction: 'required',

};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddTreatment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treatmentTypeList: [],
            dietTimeList: [],
            dietTimeData: [],
            cardHidden: true,
            isLoading: false,
            formArr: [],
            treatmentTypeError: null,
            instructionError: null,
            nameError: null,

            formData: {
                name: "",
                treatmentTypeId: "",
                instruction: "",
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }


    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.treatmentTypeListAction(data);
        let list = {
            direction: 'asc',
            order: "orderBy",
            offset: 0,
            limit: 10,

        }
        this.props.dietTimeListAction(list)

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isTreatmentTypeList !== this.props.isTreatmentTypeList) {

            this.setState({
                treatmentTypeList: nextProps.TreatmentTypeList.data.data,
                totalCount: nextProps.TreatmentTypeList.data.count
            });
        }

        if (nextProps.isDietTimeList !== this.props.isDietTimeList) {
            for (let i in nextProps.DietTimeList.data.data) {
                let value = {};
                value.percent = 0;
                value.time = "05:00 am";
                value.optional = false;
                value.dietTimeId = nextProps.DietTimeList.data.data[i]._id;
                value.name = nextProps.DietTimeList.data.data[i].name;
                this.state.dietTimeData.push(value);

            }
            this.setState({
                dietTimeList: nextProps.DietTimeList.data.data,
            });
        }



        if (nextProps.isAddTreatmentError !== this.props.isAddTreatmentError) {
            if (nextProps.msg.errors) {
                nextProps.msg.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }
        if (nextProps.isAddTreatment !== this.props.isAddTreatment) {
            this.props.handleClick('success', nextProps.AddTreatment.msg)
            this.props.history.push('/admin/treatment')
        }
    }



    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        //field.text[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }


    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addTreatment(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let treatment_data = {}
            treatment_data = this.state.formData;
            treatment_data.dietTime = this.state.dietTimeData;
            _this.setState({ showProcessing: true });
            _this.props.addTreatmentAction(treatment_data);
        }
        //validation.errors()
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }
    handleName(cell, row, enumObject, rowIndex) {
        return row.name['1'] + " :-"
    }

    handleTime(cell, row, enumObject, rowIndex) {
        return (<FormControl componentClass="select" name="time" id="time" onChange={e => { this.handleChangeDietValue(e, rowIndex); }}>
            {appConstants.time().map(function (item) {
                return <option value={item}>{item}</option>
            })}
        </FormControl>)
    }


    handlePercent(cell, row, enumObject, rowIndex) {
        return (<FormControl type="text" name="percent" placeholder="Diet in %" id={"percent_" + row.dietTimeId} value={row.percent} onChange={e => { this.handleChangeDietValue(e, rowIndex); }} />)
    }

    handleOptional(cell, row, enumObject, rowIndex) {
        return (<input type="checkbox" name="optional" id={"optional_" + row.dietTimeId} onChange={(event) => { this.handleChangeCheckbox(row.optional, rowIndex); }} defaultChecked={row.optional} />)
    }

    handleChangeCheckbox(optional, index) {
        if (optional) {
            optional = false;
        } else {
            optional = true;
        }
        let field = this.state.dietTimeData;
        field[index]['optional'] = optional;
        this.setState({ dietTimeData: field });
    }

    handleChangeDietValue(e, index) {
        e.preventDefault();
        let field = this.state.dietTimeData;
        field[index][e.target.name] = e.target.value;
        this.setState({ dietTimeData: field });

    }


    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;

        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Sub Treatment Name <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="name" id="name" placeholder=" Sub Treatment Name" onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.nameError}
                                                    {this.state.formArr.name && validation.errors.first('name')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Treatment  <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="treatmentTypeId" onChange={(event) => this.handleChange(event)}>
                                                    <option value="">Select Treatment Type</option>
                                                    {this.state.treatmentTypeList.map(function (item) {
                                                        return <option key={item._id} value={item._id}>{item.name}</option>
                                                    })}
                                                </select>

                                                <span className="errorMsg">
                                                    {this.state.treatmentTypeError}
                                                    {this.state.formArr.treatmentTypeId && validation.errors.first('treatmentTypeId')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="instruction" id="instruction" placeholder="Instruction" onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.instructionError}
                                                    {this.state.formArr.instruction && validation.errors.first('instruction')}</span>
                                            </Col>
                                        </FormGroup>
                                        <div className="fresh-datatables">
                                            <BootstrapTable
                                                selectRow="{selectRowProp}"
                                                // deleteRow={false}
                                                data={this.state.dietTimeData}
                                            // multiColumnSearch={true}
                                            // pagination={true}

                                            // striped
                                            // hover
                                            // condensed
                                            // scrollTop={'Bottom'}
                                            // remote={true}
                                            // fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'name' }} dataField='name' dataFormat={this.handleName.bind(this)} >Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'dietTimeId' }} dataField='dietTimeId' dataFormat={this.handlePercent.bind(this)} >Percent Calorie</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'dietTimeId' }} dataField='dietTimeId' dataFormat={this.handleTime.bind(this)} >Time</TableHeaderColumn>
                                                {/* <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'dietTimeId' }} dataField='dietTimeId' dataFormat={this.handleOptional.bind(this)} >Option</TableHeaderColumn> */}
                                            </BootstrapTable>
                                        </div>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.addTreatment.bind(this)}
                                                    className="btn-fill btn-wd btn btn-primary"
                                                    disabled={isLoading || this.props.disabled}
                                                >
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Save
                                                </button>&nbsp;
                                            </Col>
                                        </FormGroup>


                                    </Form>


                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.treatment.message,

        AddTreatment: state.treatment.AddTreatment,
        isAddTreatment: state.treatment.isAddTreatment,
        isAddTreatmentError: state.treatment.isAddTreatmentError,

        TreatmentTypeList: state.treatmentType.TreatmentTypeList,
        isTreatmentTypeList: state.treatmentType.isTreatmentTypeList,
        isTreatmentTypeListError: state.treatmentType.isTreatmentTypeListError,

        DietTimeList: state.dietTime.DietTimeList,
        isDietTimeList: state.dietTime.isDietTimeList,
        isDietTimeListError: state.dietTime.isDietTimeListError,

    }
}
export default withRouter(connect(mapStateToProps, { addTreatmentAction, treatmentTypeListAction, dietTimeListAction })(AddTreatment));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { withRouter, useParams } from "react-router-dom";
import ComponentToPrint from "./ComponentToPrint";
import * as API from "Front/api/emr";

const Print = () => {
  const componentRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  let { appointmentId, patientId } = useParams();
  const reactToPrintContent = React.useCallback(() => {
    const collection = document.getElementsByClassName("footer");
    collection[0].style.display = "none";
    document.getElementById("divPreview").style.display = "none";
    document.getElementById("btnPrint").style.display = "none";
    document.getElementById("btnOut").style.display = "none";
    //document.getElementById('divPaperSize').style.display = 'none';
    let eleInsuline = document.getElementById("divInsulinDose");
    if (eleInsuline != null) eleInsuline.style.display = "none";
    let ele = document.getElementById("share");
    if (ele != null) ele.style.display = "none";
    return componentRef.current;
  }, [componentRef.current]);

  const handleBeforePrint = React.useCallback(async () => {
    let resp = await API.isPrescriptionPrint({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    setIsPrinting(true);
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    const collection = document.getElementsByClassName("footer");
    collection[0].style.display = "block";
    document.getElementById("divPreview").style.display = "block";
    document.getElementById("btnPrint").style.display = "block";
    document.getElementById("btnOut").style.display = "block";
    // document.getElementById('divPaperSize').style.display = 'inline-block';

    let eleInsuline = document.getElementById("divInsulinDose");
    if (eleInsuline != null) eleInsuline.style.display = "block";
    let ele = document.getElementById("share");
    if (ele != null) ele.style.display = "block";
  }, []);

  const reactToPrintTrigger = React.useCallback(() => {
    return <button id="btnPrint">Print</button>;
  }, []);

  return (
    <div className="table-print-pos-rel">
      <ComponentToPrint props={isPrinting} ref={componentRef} />
      <div className="print-btn-wrapper">
        <ReactToPrint
          content={reactToPrintContent}
          trigger={reactToPrintTrigger}
          onBeforePrint={handleBeforePrint}
          onAfterPrint={handleAfterPrint}
        />
      </div>
    </div>
  );
};

export default withRouter(Print);

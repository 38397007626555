/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { knowledgeListAction, knowledgechangestatusAction } from 'Admin/actions/master';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { clinicListAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import ReactPlayer from 'react-player';
import { appConstants } from 'Admin/_constants/app.constants.js';
import { deleteKnowldge } from "Admin/api/master";
import SecurityUrlComponent from '../../components/Common/SecurityUrlComponent';
import moment from 'moment';


class KnowledgeList extends Component {
    constructor(props) {
        super(props);
        var type = 'admin';
        let _id_clinic;

        if (this.props && this.props.location && this.props.location.state && this.props.location.state._id) {
            type = 'all';
            _id_clinic = this.props.location.state._id;
            localStorage.setItem('_id_clinic', this.props.location.state._id);
        }

        if (this.props && this.props.location && this.props.location.state && this.props.location.state.status) {
            type = 'all';
        }


        this.state = {
            clinicList: [],
            knowledgeList: [],
            isLoading: true,
            asc_desc: true,
            userRow: null,
            modelViewData: {},
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                //  clinicId: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
                clinicId: _id_clinic,
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                type: type ? type : (this.props && this.props.location && this.props.location.state),
                //status: this.props && this.props.location && this.props.location.state && this.props.location.state.status

            },
            limitForm: {},
            //clinicView: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
            clinicView: _id_clinic,
            s3url: appConstants.s3UploadUrl,
        }

        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);

    }

    componentDidMount() {

        let formData = this.state.formData;
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.status) {
            formData['type'] = 'all'

        }
        this.props.knowledgeListAction('', this.state.formData);
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        this.props.ClinicSimpleListAction();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isKnowledgeList !== this.props.isKnowledgeList) {
            this.setState({
                knowledgeList: nextProps.KnowledgeList.data.data,
                totalCount: nextProps.KnowledgeList.data.count
            });
        }

        // if (nextProps.isClinicList !== this.props.isClinicList) {
        //     this.setState({
        //         clinicList: nextProps.ClinicList.data.data
        //     });
        // }

        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }

        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }
    }

    onUserCount(cell, row, enumObject, rowIndex) {

        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    async deleteKNowledge(id) {

        if (window.confirm("Are you sure?")) {
            let resp = await deleteKnowldge({ id: id });
            if (resp.status === 200) {
                this.props.knowledgeListAction('', this.state.formData);
            } else {

            }

        }

    }

    editButton(cell, row, enumObject, rowIndex) {

        if (this.state.formData.type === 'all') {
            return (<span><Link to={{ pathname: `/admin/update-knowledge/` + row._id, state: { row, type: row.addedType ? row.addedType : 'all' }, clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i class="fa fa-trash" aria-hidden="true" onClick={e => this.deleteKNowledge(row._id)}></i>
            </span>)
        } else {
            return (<span><Link to={{ pathname: `/admin/update-knowledge/` + row._id, state: { row, type: 'admin' } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <i class="fa fa-trash" aria-hidden="true" onClick={e => this.deleteKNowledge(row._id)}></i>
            </span>)
        }
    }

    displayClinicName(cell, row, enumObject, rowIndex) {
        return row.clinicName == '' ? 'All Clinic' : row.clinicName;
    }

    displayClinicId(cell, row, enumObject, rowIndex) {
        return row.clinicNumber == '' ? 'All Clinic' : row.clinicNumber;
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.knowledgechangestatusAction(event);
    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    handleClinic(event) {
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinicId'] = event.target.value
        formData['clinicName'] = event.target[index].text
        this.setState({ formData: formData });
    }

    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        //this.props.educatorListAction('',formData);
        this.props.knowledgeListAction('', formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        data['type'] = this.state.formData.type;
        data['clinic_id'] = this.state.formData.clinicId;
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;

        }
        data['status'] = this.state.formData.status;
        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.knowledgeListAction(data)

        if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
            eval('$(".ps").scrollTop(0)');
        }
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['type'] = this.state.formData.type;
                data['search'] = text;
                data['status'] = this.state.formData.status;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.knowledgeListAction(data);
            }
        } else {
            setTimeout(() => {       
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    clearSearch(e) {
        this.setState({ searchText: '' });
        this.componentDidMount();
    }

    sortHandle(){
        this.setState({ asc_desc:!this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc? "asc":"desc"
        this.setState({ formData : data });
        this.props.knowledgeListAction(data);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    addKnowledge(e) {


        this.props.history.push({
            pathname: '/admin/add-knowledge',
            state: {
                clinic_id: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
                clinicName: this.props && this.props.location && this.props.location.state && this.props.location.state.name ? this.props.location.state.name : "",
                clinicDetail: this.props.location.state,
            }
        })
    }

    allView() {
        var data = this.state.formData;
        data['type'] = "all";
        this.setState({ formData: data, filter: true });
        this.props.knowledgeListAction('', this.state.formData);
    }

    adminView() {
        var data = this.state.formData;
        data['type'] = "admin";
        this.setState({ formData: data, filter: false });
        this.props.knowledgeListAction('', this.state.formData);
    }

    editKnowledge(cell, row, enumObject, rowIndex) {

        return (<span>
            <a href="javascript:void(0);" onClick={this.openVideoModel.bind(this, row)}><i className="fa fa-eye" aria-hidden="true"></i></a>
        </span>)
    }

    openVideoModel(row) {

        let arr = row.image.split('.');
        let output = arr.pop();
        if (output == "mp4") {
            row.type = "video";
        } else if (output == "pdf") {
            row.type = "pdf";

        } else {
            row.type = "image";
        }

        this.setState({ modelViewData: row, videoModel: true });
    }

    render() {



        const { modelViewData } = this.state;
        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),

        };

        const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
            return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
        };

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.clinicView &&
                                <ClinicHeader componentData={{ clinicId: this.state.clinicView }} />
                            }
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6}>
                                                <ButtonToolbar>
                                                    <Button className="inacti-button fill pullRight" onClick={(e) => { this.addKnowledge(e) }} >Add Knowledge</Button>

                                                    {!this.state.clinicView &&
                                                        <ButtonToolbar>
                                                            <Button className={this.state.formData.type == 'admin' ? 'acti-button fill' : 'inacti-button fill'} onClick={(e) => { this.adminView(e) }} >Added By Admin</Button>
                                                            <Button className={this.state.formData.type == 'all' ? 'acti-button fill' : 'inacti-button fill'} onClick={(e) => { this.allView(e) }} >View All</Button>
                                                        </ButtonToolbar>
                                                    }
                                                </ButtonToolbar>
                                            </Col>




                                        </Row>&nbsp;
                                        {this.state.filter &&
                                            <div>
                                                <Row>
                                                    <Col md={3}>
                                                        <Col componentClass={ControlLabel}>
                                                            Clinic
                                                        </Col>
                                                        <FormControl componentClass="select" name="clinicName" onChange={e => { this.handleClinic(e); }}>
                                                            <option value="">Select Clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}({item.clinicId})</option>
                                                            })}

                                                        </FormControl>
                                                    </Col>
                                                    <Col md={2}>
                                                        <Button bsStyle="info searchBtn" onClick={e => { this.onSearch(e); }} >Search</Button>
                                                    </Col>
                                                </Row>&nbsp;
                                            </div>
                                        }

                                        <div className="table-resposive clinic-subn">
                                        <div className='search-box-admin'>
                                            <div className='search'>
                                                <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                <span className='input-group-btn'>
                                                <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button> 
                                                </span>
                                            </div>
                                        </div>
                                            <BootstrapTable
                                                data={this.state.knowledgeList}
                                                //selectRow={selectRowProp}
                                                deleteRow={false}
                                                multiColumnSearch={true}
                                                pagination={true}
                                                options={options}
                                                striped
                                                hover
                                                condensed
                                                scrollTop={'Bottom'}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} isKey tdAttr={{ 'data-attr': '_id' }} dataField='_id' searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '6%', textAlign: 'center' }} tdStyle={{ width: '6%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Id' }} dataField='' dataFormat={this.displayClinicId.bind(this)} >Clinic Id
                                                {
                                                    this.state.asc_desc?
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                                                }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn tdAttr={{ "data-attr": "title" }} thStyle={{ width: "20%", textAlign: "center" }} tdStyle={{ width: "20%", textAlign: "center" }} dataField="createdAt" dataFormat={getDrugCreationDate} > Date</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '30%', textAlign: 'center' }} tdStyle={{ width: '30%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'doctorName' }} dataField='doctorName'>Doctor Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '25%', textAlign: 'center' }} tdStyle={{ width: '25%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataFormat={this.displayClinicName.bind(this)} dataField='' >Clinic Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '35%', textAlign: 'center' }} tdStyle={{ width: '35%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Title English' }} dataField='title'>Title English</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '25%', textAlign: 'center' }} tdStyle={{ width: '25%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Title Hindi' }} dataField='title_hindi' >Title Hindi</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editKnowledge.bind(this)}>View</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '13%', textAlign: 'center' }} tdStyle={{ width: '13%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%', textAlign: 'center' }} tdStyle={{ width: '15%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} dataField='' >Action</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                        <Col>
                            <Modal show={this.state.videoModel} onHide={() => this.setState({ videoModel: false })} dialogClassName="modal-md">
                                <Modal.Header>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <Modal.Title><h5>{modelViewData.title}</h5></Modal.Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body className="Knowledge-Share card img-ccent">
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                {modelViewData && modelViewData.type && modelViewData.type == "video" &&
                                                    <div>
                                                        <Row>
                                                            <Col md={12}>
                                                                <SecurityUrlComponent
                                                                    className="react-player"
                                                                    data={modelViewData.image}
                                                                    type="video"
                                                                    width="100%"
                                                                    height="100%"
                                                                    playing controls="true"
                                                                    volume="1"

                                                                />
                                                                {/* <ReactPlayer className='react-player' width='100%' height='100%' url={this.state.s3url + modelViewData.image} playing controls="true" volume="1" /> */}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                                {modelViewData && modelViewData.type && modelViewData.type == "image" &&
                                                    <div>
                                                        <Row>
                                                            <Col md={12}>
                                                                <SecurityUrlComponent
                                                                    className="activeImage admin-active-image"
                                                                    data={modelViewData.image}
                                                                    width="70%"
                                                                    height="100%"
                                                                    type="img"
                                                                />
                                                                {/* <img src={this.state.s3url + modelViewData.image} width="70%" height="100%" /> */}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                                {modelViewData && modelViewData.type && modelViewData.type == "pdf" &&
                                                    <div>
                                                        <Row>
                                                            <Col md={12}>
                                                                <SecurityUrlComponent
                                                                    className="activeImage"
                                                                    data={modelViewData.image}
                                                                    target="_blank"
                                                                    type="anchor"
                                                                />
                                                                {/* <a href={this.state.s3url + modelViewData.image} target="_blank"> Download pdf</a> */}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={() => this.setState({ videoModel: false })} className="btn-fill btn-wd btn btn-secondary">Close</button>&nbsp;
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>

                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        KnowledgeList: state.master.KnowledgeList,
        isKnowledgeList: state.master.isKnowledgeList,
        isKnowledgeListError: state.master.isKnowledgeListError,

        KnowledgeChangeStatus: state.master.KnowledgeChangeStatus,
        isKnowledgeChangeStatus: state.master.isKnowledgeChangeStatus,
        isKnowledgeChangeStatusError: state.master.isKnowledgeChangeStatusError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError
    }
}
export default withRouter(connect(mapStateToProps, { knowledgeListAction, knowledgechangestatusAction, clinicListAction, ClinicSimpleListAction })(KnowledgeList));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/clinic_active'


//-- ClinicActive List
export const clinicActiveRequest = () => ({
    type: actionTypes.GET_CLINIC_ACTIVE_LIST_REQUEST
})
export const clinicActiveSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_ACTIVE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const clinicActiveError = (error) => ({
    type: actionTypes.GET_CLINIC_ACTIVE_LIST_ERROR,
    payload: {
        error
    }
})
export const clinicActiveAction = (data) => {
    return dispatch => {
        dispatch(clinicActiveRequest())
       
        return API.clinicActiveList(data)
            .then(response => {
                dispatch(clinicActiveSuccess(response.data))
            })
            .catch(error => {
                dispatch(clinicActiveError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useEffect, useReducer, useRef } from "react";
import {
  withRouter,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import ComplaintsEnlarge from "./ComplaintsEnlarge";
import axios from "axios";
import AddRareComplaints from "./AddRareComplaints";
import { appConstants } from "Front/_constants/app.constants.js";
import * as API from "Front/api/emr";
import { Row, Col, FormGroup, Grid, FormControl } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from "react-confirm-alert";
import { NavLink } from "react-router-dom";
//import "perfect-scrollbar/dist/css/perfect-scrollbar.min.css";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import {
  selectDrugDurationDayOptions,
  selectDrugDurationTimePeriodOptions,
} from "../../../../variables/Variables";
import { Autosave, useAutosave } from "react-autosave";
import Breadcrum from "../../../../components/common/Breadcrum";
import EditIcon from "../../../../assets/img/icons/edit-ic.svg";
import DeleteIcon from "../../../../assets/img/icons/table-delete-ic.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../../actions/actionTypes";
// **********************Actions declaired for reducer ****************************//
export const ACTIONS = {
  ADD_COMPLAINT: "add-complaint",
  DELETE_COMPLAINT: "delete-complaint",
  ADD_ENLARGE_COMPLAINT: "add-enlarge-complaint",
  UPDATE_DURATION: "update-duration",
  CLOSE_ENLARGE: "close-enlarge",
  RESET: "reset",
  ADD_COMPLAINTS_ALL: "add_complatnts_all",
};
// ********************************************************************************//

const Complaints = () => {
  let history = useHistory();
  const emrData = useSelector((store) => store.emr);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStatusRareComplaints, setModalStatusRareComplaints] =
    useState(false);
  const [complaintsEnlarge, setComplaintsEnlarge] = useState({});
  const [addRareComplaints, setAddRareComplaints] = useState(true);
  const [generalInformation, setGeneralInformation] = useState({
    patientName: emrData?.vitalSignsData
      ? emrData.vitalSignsData.patientName
      : "",
    age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
    height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
    weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
    bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
    bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
    gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
    pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
    temperature: emrData?.vitalSignsData
      ? emrData.vitalSignsData.temperature
      : "",
  });
  const [symptomsCategoryList, setSymptomsCategoryList] = useState([]);
  const [symptomsSubCategoryList, setSymptomsSubCategoryList] = useState([]);
  const [toggleDuration, setToggleDuration] = useState(
    emrData
      ? emrData.vitalSignsData.toggleDuration
        ? emrData.vitalSignsData.toggleDuration
        : false
      : false
  );
  const [flag, setFlag] = useState("patientSymptomsList");
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [day, setDay] = useState("No");
  const [month, setMonth] = useState("D/W/M/Y");
  const [year, setYear] = useState("");
  const [duration, setDuration] = useState(
    emrData ? emrData.vitalSignsData.diabetesDuration : "Year Month Day"
  );
  const [isRareComplaint, setIsRareComplaint] = useState(false);
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState(true);
  const [complaintData, setComplaintData] = useState("");
  const [complaintCount, setComplaintCount] = useState(false);
  const [patientListCount, setPatientListCount] = useState(false);
  const [myListCount, setMyListCount] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const notificationSystem = useRef(null);
  const [isComplaintAddedFromEnlarge, setIsComplaintAddedFromEnlarge] =
    useState(false);
  const [
    selectedSubCategoryIdFromEnlarge,
    setSelectedSubCategoryIdFromEnlarge,
  ] = useState("");
  const [isComplaintPatientData, setIsComplaintPatientData] = useState(false);
  const [complaintDuration, setComplaintDuration] = useState({
    no: "",
    period: "",
  });
  const [autoSave, setAuoSave] = useState(true);
  const [isAutoSaveUse, setIsAutoSaveUse] = useState(false);
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [searchLength, setSearchLength] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [complaintAlredyAdded, setComplaintAlreadyAdded] = useState(false);
  const dispatch1 = useDispatch();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const initialState = {
    complaints: [],
    data: [],
    selectedCategoryId: "",
    interval: false,
  };
  const location = useLocation();
  let complaintList = [];
  let count = 0;
  let { appointmentId, patientId, printingLanguage } = useParams();
  let symptomCategoryId = "";
  let days = "";
  let months = "";
  let years = "";
  let flagList = "";
  let isSaveSymptoms = false;
  const CancelToken = axios.CancelToken;
  let cancel;

  // **********************Function for accessing general information about patient****************************//

  const getGeneralInfo = async () => {
    if (
      emrData.vitalSignsData &&
      Object.keys(emrData.vitalSignsData).length === 0
    ) {
      let resp = await API.generalInformation({ patientId: patientId });
      if (resp.data && resp.data.status === "Success") {
        setGeneralInformation({
          patientName: resp.data.data.name,
          age: resp.data.data.age,
          height: resp.data.data.height,
          weight: resp.data.data.weight,
          bmi: resp.data.data.bmi,
          bp: resp.data.data.bp,
          gender: resp.data.data.gender,
          pulse: resp.data.data.pulse,
          temperature: resp.data.data.temprature,
        });
        if (resp.data.data.diabitic === "yes") {
          let chkToggleDuration = document.getElementById("customSwitch1");
          if (chkToggleDuration !== null) {
            chkToggleDuration.checked = true;
            setToggleDuration(true);
          }

          let strDuration = "";

          if (
            resp.data.data.diabtiesDuration?.years === 0 &&
            resp.data.data.diabtiesDuration?.months === 0 &&
            resp.data.data.diabtiesDuration?.days === 0
          ) {
            strDuration = "";
          }
          if (resp.data.data?.diabtiesDuration?.years > 0) {
            strDuration = resp.data.data.diabtiesDuration?.years + " years ";
          }
          if (resp.data.data.diabtiesDuration?.months > 0) {
            strDuration += resp.data.data.diabtiesDuration?.months + " months ";
          }
          if (resp.data.data.diabtiesDuration?.days > 0) {
            strDuration += resp.data.data.diabtiesDuration?.days + " days ";
          }
          setDuration(strDuration);
        }
      }
    } else {
      if (emrData.vitalSignsData.toggleDuration) {
        let chkToggleDuration = document.getElementById("customSwitch1");
        if (chkToggleDuration !== null) {
          chkToggleDuration.checked = true;
        }
      }
    }
    getSymptomsCategory();
  };

  //*****************************************************************************************//

  // **********************Function for displaying patient complaints****************************//

  const getComplaintPatientData = async () => {
    let resp = await API.complainPatientData({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      if (resp.data.data.length > 0) {
        let temp = {};
        let tempComplaints = [];
        let tempData = [];
        resp.data.data.map((itm, index) => {
          temp = {
            complaint: {
              symptomsSubCategoryId: itm.symptomsSubCategoryId,
              symptomType: itm.symptomsType,
              complaint: itm.symptomsSubCategoryName,
              duration: itm.duration,
              appointmentId: appointmentId,
              patientId: itm.patientId,
              isDeleted: itm.deleted === "0" ? false : true,
            },
            data: {
              patientId: itm.patientId,
              duration: itm.duration,
              symptomsType: itm.symptomsType,
              complaint: itm.symptomsSubCategoryName,
              symptomsSubCategoryId: itm.symptomsSubCategoryId,
              appointmentId: appointmentId,
              isDeleted: itm.deleted === "0" ? false : true,
            },
          };
          tempComplaints.push(temp.complaint);
          tempData.push(temp.data);
        });
        let payloadData = {
          complaint: tempComplaints,
          data: tempData,
        };

        dispatch({ type: ACTIONS.ADD_COMPLAINTS_ALL, payload: payloadData });
      }
    }
  };
  //*****************************************************************************************//

  // **********************Function for accessing symptoms categories****************************//

  const getSymptomsCategory = async () => {
    let resp = await API.symptomsCategory();
    if (
      resp.data &&
      resp.data.status === "Success" &&
      resp.data.masterSymptomsCategoryList.data.length > 0
    ) {
      setSymptomsCategoryList(resp.data.masterSymptomsCategoryList.data);
      symptomCategoryId = resp.data.masterSymptomsCategoryList.data[0]._id;
      getSymptomsSubCategory("", "master");
      setIsSubCategory(true);
    }
  };

  //*****************************************************************************************//

  // **********************Function for accessing subcategory based on selected category****************************//

  const getSymptomsSubCategory = async (e, type) => {
    let catId = symptomCategoryId;
    if (e) {
      catId = e.target.id;
    }
    setSymptomsSubCategoryList([]);

    let req = "";
    if (type === "master") {
      req = {
        patientId: patientId,
        clinicId: clinicId,
        symptomsCategoryId: catId,
      };
    } else if (type === "patient") {
      req = {
        patientId: patientId,
        clinicId: "",
        symptomsCategoryId: "",
      };
    } else if (type === "clinic") {
      req = {
        patientId: "",
        clinicId: clinicId,
        symptomsCategoryId: "",
      };
    } else if (type === "subcategory") {
      req = {
        patientId: "",
        clinicId: "",
        symptomsCategoryId: catId,
      };
    }

    let resp = await API.symptomsSubCategory(req);
    if (resp.data && resp.data.status === "Success") {
      complaintList = [];
      flagList = resp.data.flag;
      if (resp.data.flag === "patientSymptomsList") {
        let radioPatient = document.getElementById(patientId);
        if (radioPatient !== null) radioPatient.checked = true;
        setFlag("patientSymptomsList");
        setSymptomsSubCategoryList(resp.data.patientSymptomsList);
        complaintList = resp.data.patientSymptomsList;
        if (resp.data.patientSymptomsList.length > 0) setPatientListCount(true);
      } else if (resp.data.flag === "clinicSymptomsList") {
        let radioClinic = document.getElementById(clinicId);
        if (radioClinic !== null) radioClinic.checked = true;
        setFlag("clinicSymptomsList");
        setSymptomsSubCategoryList(resp.data.clinicSymptomsList);
        complaintList = resp.data.clinicSymptomsList;
        if (resp.data.clinicSymptomsList.length > 0) setMyListCount(true);
      } else if (resp.data.flag === "masterSymptomsCategoryList") {
        setFlag("masterSymptomsCategoryList");
        selectSymptomsCategory(type);
        setSymptomsSubCategoryList(
          resp.data.masterSymptomsSubCategoryList.data
        );
        complaintList = resp.data.masterSymptomsSubCategoryList.data;
      }

      getSelectedCategory();

      if (!isSaveSymptoms) {
        findSubCategory();
      }
    }
    if (isPageLoad) {
      setIsComplaintPatientData(true);
    }
  };

  //*****************************************************************************************//

  // **********************Functions for selecting checkboxes or radiobuttons ****************************//

  const findSubCategory = () => {
    state.complaints.map((complaint) => {
      if (complaintList.length > 0) {
        complaintList.map((item) => {
          if (flagList === "patientSymptomsList") {
            if (
              item.symptomsSubCategoryId === complaint.symptomsSubCategoryId
            ) {
              checkSubCategory(item.symptomsSubCategoryId);
            }
          } else {
            if (item._id === complaint.symptomsSubCategoryId) {
              checkSubCategory(item._id);
            }
          }
        });
      }
    });
    isSaveSymptoms = false;
    setSymptomsSubCategoryList(complaintList);
  };

  const checkSubCategory = (subcatId) => {
    let chkSubCategory = document.getElementsByName("subcat");
    for (var subCategory of chkSubCategory) {
      if (subcatId === subCategory.getAttribute("id")) {
        subCategory.checked = true;
      }
    }
  };

  const selectSymptomsCategory = (type) => {
    let radioCategory = document.getElementsByName("inlineRadioOptions");

    for (var radio of radioCategory) {
      if (
        radio.value === symptomCategoryId &&
        (type === "master" || type === "clinic")
      ) {
        radio.checked = true;
      }
    }
  };

  const getSelectedCategory = () => {
    let radioCategory = document.getElementsByName("inlineRadioOptions");
    for (var radio of radioCategory) {
      if (radio.checked) setSelectedCategoryId(radio.value);
    }
  };

  //*****************************************************************************************//

  // **********************Reducer Function for managing complex state in complaint page and complaint enlarge page****************************//

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.ADD_COMPLAINT: {
        let flag = false;
        state.data.map((itm) => {
          if (
            itm.symptomsSubCategoryId ===
            action.payload.data.symptomsSubCategoryId
          ) {
            itm.isDeleted = false;
            flag = true;
          }
        });
        return {
          ...state,
          complaints: [...state.complaints, action.payload.complaint],
          data:
            flag === false ? [...state.data, action.payload.data] : state.data,
        };
      }

      case ACTIONS.ADD_COMPLAINTS_ALL: {
        action.payload.complaint.map((itm) => {
          let ele = document.getElementById(itm.symptomsSubCategoryId);
          if (ele !== null) ele.checked = true;
        });
        return {
          ...state,
          complaints: [...action.payload.complaint],
          data: [...action.payload.data],
        };
      }

      case ACTIONS.DELETE_COMPLAINT: {
        state.data.map((itm) => {
          if (
            itm.symptomsSubCategoryId === action.payload.symptomsSubCategoryId
          ) {
            itm.isDeleted = true;
          }
        });

        unCheckSubcategory(action.payload.symptomsSubCategoryId);
        return {
          ...state,
          complaints: state.complaints.filter(
            (complaint) =>
              complaint.symptomsSubCategoryId !==
              action.payload.symptomsSubCategoryId
          ),
        };
      }

      case ACTIONS.ADD_ENLARGE_COMPLAINT: {
        setSelectedSubCategoryIdFromEnlarge(action.payload.selectedCategoryId);
        setIsComplaintAddedFromEnlarge(true);

        return {
          ...state,
          complaints: action.payload.complaints,
          data: action.payload.data,
        };
      }

      case ACTIONS.UPDATE_DURATION: {
        return {
          ...state,
          complaints: state.complaints,
          data: state.data,
        };
      }

      case ACTIONS.CLOSE_ENLARGE: {
        let ele = document.getElementById(action.payload.selectedCategoryId);
        if (ele !== null) ele.checked = true;
        symptomCategoryId = action.payload.selectedCategoryId;
        if (action.payload.selectedCategoryId === patientId)
          getSymptomsSubCategory("", "patient");
        if (action.payload.selectedCategoryId === clinicId)
          getSymptomsSubCategory("", "clinic");
        if (
          action.payload.selectedCategoryId !== clinicId &&
          action.payload.selectedCategoryId !== patientId
        )
          getSymptomsSubCategory("", "subcategory");
        setIsSubCategory(true);
        return {
          ...state,
          complaints: state.complaints,
          data: state.data,
        };
      }

      case ACTIONS.RESET: {
        return {
          complaints: [],
          data: [],
        };
      }

      default:
        return {
          ...state,
          complaints: [],
          data: [],
        };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  function autocomplete(inp, arr) {
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    var currentFocus;

    var a,
      b,
      i,
      val = inp.value;
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items autocompletedropdownmenu");
    inp.parentNode.appendChild(a);

    for (i = 0; i < arr.length; i++) {
      b = document.createElement("DIV");
      b.setAttribute("class", "");
      b.innerHTML += `${arr[i].symptomsSubCategoryName} (${arr[i].symptomsType === "master"
          ? arr[i].symptomsCategoryName
          : "My Clinic"
        })`;
      b.innerHTML +=
        "<input type='hidden' symptomType='" +
        arr[i].symptomsType +
        "'symptomsSubCategoryId='" +
        arr[i].symptomsSubCategoryId +
        "'symptomsCategoryId='" +
        arr[i].symptomsCategoryId +
        "'value='" +
        arr[i].symptomsSubCategoryName +
        "'>";
      b.addEventListener("click", function (e) {
        let subCategoryId = this.getElementsByTagName("input")[0].getAttribute(
          "symptomsSubCategoryId"
        );
        let symptomType =
          this.getElementsByTagName("input")[0].getAttribute("symptomType");
        let complaint =
          this.getElementsByTagName("input")[0].getAttribute("value");
        let categoryId =
          this.getElementsByTagName("input")[0].getAttribute(
            "symptomsCategoryId"
          );
        addComplaintAutoComplete(
          subCategoryId,
          symptomType,
          complaint,
          categoryId
        );
        closeAllLists();
      });
      a.appendChild(b);
    }
  }

  const addComplaintAutoComplete = (
    subCategoryId,
    symptomType,
    complaint,
    categoryId
  ) => {
    if (!complaintValidate(subCategoryId)) {
      let payloadData = {
        complaint: {
          patientId: patientId,
          duration: { no: "", period: "" },
          symptomType: symptomType,
          symptomsSubCategoryId: subCategoryId,
          appointmentId: appointmentId,
          complaint: complaint,
          isDeleted: false,
        },
        data: {
          patientId: patientId,
          duration: { no: "", period: "" },
          symptomsType: symptomType,
          symptomsSubCategoryId: subCategoryId,
          appointmentId: appointmentId,
          complaint: complaint,
          isDeleted: false,
        },
      };
      dispatch({ type: ACTIONS.ADD_COMPLAINT, payload: payloadData });
      symptomCategoryId = categoryId;
      let ele = document.getElementById(categoryId);
      if (ele != null) ele.checked = true;
      getSymptomsSubCategory(
        "",
        symptomType === "master" ? "subcategory" : symptomType
      );
      setTimeout(() => {
        ele = document.getElementById(subCategoryId);
        if (ele != null) ele.checked = true;
        ele = document.getElementById("txtSearchComplaint");
        if (ele != null) ele.value = "";
        ele = document.getElementById("autoCompleteBox");
        //ele.classList.remove("autoCompleteBackDrop");
      }, 300);
    }
  };

  //*****************************************************************************************//

  // **********************************Functions for handling duration **********************************************************//

  const inputHandler = (e, field) => {
    days = day;
    months = month;
    //years = year;

    if (field === "day") {
      days = e.target.value;
      setDay(days);
      if (e.target.value != "No" && month === "D/W/M/Y") {
        months = "Day";
        setMonth("Day");
      }
    } else if (field === "period") {
      months = e.target.value;
      setMonth(months);
      if (e.target.value != "D/W/M/Y" && day === "No") {
        days = "1";
        setDay("1");
      }
    }
    calculateDuration();
  };

  const calculateDuration = async () => {
    let req = {
      patientId: patientId,
      duration: {
        no: Number(days === "" ? "0" : days),
        period: months === "" ? "Day" : months,
      },
    };

    let strDuration = "";
    let resp = await API.addDiabticDuration(req);
    if (resp.data && resp.data.status === "Success") {
      if (resp.data.diabtiesDurationData.diabtiesDuration?.years > 0) {
        strDuration +=
          resp.data.diabtiesDurationData.diabtiesDuration?.years + " years ";
      }
      if (resp.data.diabtiesDurationData.diabtiesDuration?.months > 0) {
        strDuration +=
          resp.data.diabtiesDurationData.diabtiesDuration?.months + " months ";
      }
      if (resp.data.diabtiesDurationData.diabtiesDuration?.days > 0) {
        strDuration +=
          resp.data.diabtiesDurationData.diabtiesDuration?.days + " days ";
      }
      setDuration(strDuration);
      let tempData = emrData.vitalSignsData;
      tempData.patientId = patientId;
      tempData.diabetesDuration = strDuration;
      dispatch1({ type: actionTypes.SET_VITAL_SIGNS_DATA, payload: tempData });

      if (
        resp.data.diabtiesDurationData.diabtiesDuration?.years === 0 &&
        resp.data.diabtiesDurationData.diabtiesDuration?.months === 0 &&
        resp.data.diabtiesDurationData.diabtiesDuration?.days === 0
      ) {
        tempData.toggleDuration = false;
        tempData.diabetesDuration = "Year Month Day";
        dispatch1({
          type: actionTypes.SET_VITAL_SIGNS_DATA,
          payload: tempData,
        });
        setDuration("Year Month Day");
        let chkToggleDuration = document.getElementById("customSwitch1");
        if (chkToggleDuration !== null) chkToggleDuration.checked = false;
        setToggleDuration(false);
      }
    }
  };

  const toggleDiabetesDuration = (e) => {
    if (e.target.checked) {
      let tempData = emrData.vitalSignsData;
      tempData.toggleDuration = true;
      tempData.diabetesDuration = "Year Month Day";
      dispatch1({ type: actionTypes.SET_VITAL_SIGNS_DATA, payload: tempData });
      setToggleDuration(true);
      setDuration("Year Month Day");
    } else {
      years = "";
      months = "";
      days = "";
      setDay("No");
      setMonth("D/W/M/Y");
      setYear("");
      calculateDuration();
      setToggleDuration(false);
    }
  };

  const handleDuration = (e, key) => {
    if (key === "no") {
      let ele = document.getElementById(
        e.target.getAttribute("subcatid") + "-no"
      );
      if (ele != null) ele.classList.remove("duration-required");
    }

    if (key === "period") {
      let ele = document.getElementById(
        e.target.getAttribute("subcatid") + "-period"
      );
      if (ele != null) ele.classList.remove("duration-required");
    }

    state.data.map((itm) => {
      if (itm.symptomsSubCategoryId === e.target.getAttribute("subcatid")) {
        if (key === "no") {
          itm.duration.no = e.target.value === "No" ? "" : e.target.value;
          if (e.target.value != "No" && itm.duration.period === "") {
            itm.duration.period = "Day";
            let ele = document.getElementById(
              e.target.getAttribute("subcatid") + "-period"
            );
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "No" && itm.duration.period != "") {
            itm.duration.period = itm.duration.period;
          } else if (e.target.value === "No") {
            itm.duration.period = "";
          }
        } else if (key === "period") {
          itm.duration.period =
            e.target.value === "D/W/M/Y" ? "" : e.target.value;
          if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
            itm.duration.no = "1";
            let ele = document.getElementById(
              e.target.getAttribute("subcatid") + "-no"
            );
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
            itm.duration.no = itm.duration.no;
          } else if (e.target.value === "D/W/M/Y") {
            itm.duration.no = "";
          }
        }
      }
    });

    state.complaints.map((itm) => {
      if (itm.symptomsSubCategoryId === e.target.getAttribute("subcatid")) {
        if (key === "no") {
          itm.duration.no = e.target.value === "No" ? "" : e.target.value;
          if (e.target.value != "No" && itm.duration.period === "") {
            itm.duration.period = "Day";
            let ele = document.getElementById(
              e.target.getAttribute("subcatid") + "-period"
            );
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "No" && itm.duration.period != "") {
            itm.duration.period = itm.duration.period;
          } else if (e.target.value === "No") {
            itm.duration.period = "";
          }
        } else if (key === "period") {
          itm.duration.period =
            e.target.value === "D/W/M/Y" ? "" : e.target.value;
          if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
            itm.duration.no = "1";
            let ele = document.getElementById(
              e.target.getAttribute("subcatid") + "-no"
            );
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
            itm.duration.no = itm.duration.no;
          } else if (e.target.value === "D/W/M/Y") {
            itm.duration.no = "";
          }
        }
      }
    });
    dispatch({ type: ACTIONS.UPDATE_DURATION });
  };

  //*****************************************************************************************//

  // *********************************************Function for add or delete complaint in the table ************************************************//

  const addOrDeleteComplaints = (e) => {
    if (e.target.checked) {
      let symptomType = "master";

      if (flag === "clinicSymptomsList") {
        symptomType = "clinic";
      } else if (flag === "patientSymptomsList") {
        symptomType = "patient";
      }

      let payloadData = {
        complaint: {
          patientId: patientId,
          duration: { no: "", period: "" },
          symptomType: symptomType,
          symptomsSubCategoryId: e.target.getAttribute("subcatid"),
          appointmentId: appointmentId,
          complaint: e.target.value,
          isDeleted: false,
        },
        data: {
          patientId: patientId,
          duration: { no: "", period: "" },
          symptomsType: symptomType,
          symptomsSubCategoryId: e.target.getAttribute("subcatid"),
          appointmentId: appointmentId,
          complaint: e.target.value,
          isDeleted: false,
        },
      };

      dispatch({ type: ACTIONS.ADD_COMPLAINT, payload: payloadData });
    } else {
      dispatch({
        type: ACTIONS.DELETE_COMPLAINT,
        payload: { symptomsSubCategoryId: e.target.getAttribute("subcatid") },
      });
    }
    setComplaintCount(false);
  };

  //*****************************************************************************************//

  // *********************************************Function for adding rare complaint ************************************************//

  const addRareComplaint = (
    previousSubCatId,
    clinicSymptomsId,
    complaint,
    flag,
    complaintDuration
  ) => {
    setIsRareComplaint(false);
    let msg = "";
    let payloadData = {
      complaint: {
        patientId: patientId,
        symptomsSubCategoryId: clinicSymptomsId,
        symptomType: "clinic",
        complaint: complaint,
        duration:
          flag === "addRareComplaint"
            ? { no: "", period: "" }
            : complaintDuration,
        appointmentId: appointmentId,
        isDeleted: false,
      },
      data: {
        patientId: patientId,
        duration:
          flag === "addRareComplaint"
            ? { no: "", period: "" }
            : complaintDuration,
        symptomsType: "clinic",
        symptomsSubCategoryId: clinicSymptomsId,
        appointmentId: appointmentId,
        complaint: complaint,
        isDeleted: false,
      },
    };
    if (flag === "addRareComplaint") {
      dispatch({ type: ACTIONS.ADD_COMPLAINT, payload: payloadData });
      msg = "Complaint add successfully to mylist";
    } else {
      let idx = state.complaints.findIndex(
        (x) => x.symptomsSubCategoryId === previousSubCatId
      );
      state.complaints[idx] = payloadData.complaint;
      state.data[idx] = payloadData.data;
      msg = "Complaint edit successfully";
    }

    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    setIsRareComplaint(true);
    setComplaintCount(false);
  };

  const editComplaintPopUp = (val, id, e) => {
    let complaintDuration = {};
    state.data.map((itm) => {
      if (itm.symptomsSubCategoryId === id) {
        setComplaintDuration(itm.duration);
      }
    });
    setAddRareComplaints(false);
    setModalStatusRareComplaints(true);
    let formData = {
      symptomsSubCategoryName: val,
      symptomsSubCategoryId: id,
      patientId: patientId,
      appointmentId: appointmentId,
    };
    setComplaintData(formData);
  };

  //*****************************************************************************************//

  // ***************************************Function for unchecking complaints in suggestion box******************************************************//

  function unCheckSubcategory(symptomsSubCategoryId) {
    let chkbox_subact = document.getElementsByName("subcat");
    for (var chk of chkbox_subact) {
      if (chk.id === symptomsSubCategoryId) {
        chk.checked = false;
      }
    }
  }

  //*****************************************************************************************//

  // ******************************************Function for saving complaints***************************************************//

  // const handleSaveSymptoms = (e) => {
  //   if(state.complaints.length>0){
  //         submitHandler()
  //       }else{
  //         setComplaintCount(true)
  //       }
  //    }

  //  const submitHandler= async (e)=>{
  //    let flag=true
  //  state.data.map((complaint)=>{
  //    if(complaint.duration.no!=""&& complaint.duration.period==="")
  //     {
  //      flag=false
  //       let ele= document.getElementById(complaint.symptomsSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.add("duration-required")
  //     }else if(complaint.duration.no==="" && complaint.duration.period!="" ){
  //       flag=false
  //        let ele= document.getElementById(complaint.symptomsSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.add("duration-required")
  //     } else if(complaint.duration.no ==="" && complaint.duration.period==="")
  //     {
  //       //flag=true
  //       let ele= document.getElementById(complaint.symptomsSubCategoryId+"-no")
  //        if(ele!==null)
  //        ele.classList.remove("duration-required")
  //        ele= document.getElementById(complaint.symptomsSubCategoryId+"-period")
  //       if(ele!==null)
  //       ele.classList.remove("duration-required")

  //     }
  //  })

  //  if(flag)
  //  {
  //   let ele =document.getElementById("btnSaveComplaints")
  //   if(ele!==null)
  //      ele.enabled=true
  //   let res = await API.addPatientSymptoms({ symptomsData:state.data  });
  //     if (res.data.status === "Success" && res.data.statusCode === 200) {
  //       isSaveSymptoms=true;
  //       successAlert("Complaints added successfully")
  //      }
  //  }

  //  }

  const submitHandlerAutoSave = async () => {
    let flag = true;
    state.data.map((complaint) => {
      if (complaint.duration.no != "" && complaint.duration.period === "") {
        flag = false;
        let ele = document.getElementById(
          complaint.symptomsSubCategoryId + "-period"
        );
        if (ele !== null) ele.classList.add("duration-required");
      } else if (
        complaint.duration.no === "" &&
        complaint.duration.period != ""
      ) {
        flag = false;
        let ele = document.getElementById(
          complaint.symptomsSubCategoryId + "-no"
        );
        if (ele !== null) ele.classList.add("duration-required");
      } else if (
        complaint.duration.no === "" &&
        complaint.duration.period === ""
      ) {
        //flag=true
        let ele = document.getElementById(
          complaint.symptomsSubCategoryId + "-no"
        );
        if (ele !== null) ele.classList.remove("duration-required");
        ele = document.getElementById(
          complaint.symptomsSubCategoryId + "-period"
        );
        if (ele !== null) ele.classList.remove("duration-required");
      }
    });

    if (flag) {
      let ele = document.getElementById("btnSaveComplaints");
      if (ele !== null) ele.enabled = true;
      let res = await API.addPatientSymptoms({ symptomsData: state.data });
      if (res.data.status === "Success" && res.data.statusCode === 200) {
        isSaveSymptoms = true;
        //successAlert("Complaints added successfully")
      }
    }
  };

  //*****************************************************************************************//

  // ******************************************Function for showing or deleting alert msg for complaints ***************************************************//

  const successAlert = (msg) => {
    setAlertMsg(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => {
          setAlertMsg(null);
          let ele = document.getElementById("btnSaveComplaints");
          if (ele !== null) {
            ele.disabled = false;
          }
        }}
        confirmBtnBsStyle="info"
      >
        {msg}{" "}
      </SweetAlert>
    );

    setTimeout(function () {
      setAlertMsg(null);
      let ele = document.getElementById("btnSaveComplaints");
      if (ele !== null) {
        ele.disabled = false;
      }
    }, 3000);
  };

  const deleteComplaintConfirm = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch({
              type: ACTIONS.DELETE_COMPLAINT,
              payload: { symptomsSubCategoryId: id },
            }),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  //*****************************************************************************************//

  //*****************useEffect has dependecies on  compalint and rarecomplaint for showing later effect after component rendering***********************//

  useEffect(() => {
    if (isPageLoad) {
      getGeneralInfo();
      setIsPageLoad(false);
    } else {
      if (isRareComplaint) {
        getSymptomsSubCategory("", "clinic");
      }
    }
  }, [isRareComplaint, isSubCategory]);

  useEffect(() => {
    if (isComplaintAddedFromEnlarge) {
      let ele = document.getElementById(selectedSubCategoryIdFromEnlarge);
      if (ele !== null) ele.checked = true;
      symptomCategoryId = selectedSubCategoryIdFromEnlarge;
      if (selectedSubCategoryIdFromEnlarge === patientId)
        getSymptomsSubCategory("", "patient");
      if (selectedSubCategoryIdFromEnlarge === clinicId)
        getSymptomsSubCategory("", "clinic");
      if (
        selectedSubCategoryIdFromEnlarge !== clinicId &&
        selectedSubCategoryIdFromEnlarge !== patientId
      )
        getSymptomsSubCategory("", "subcategory");
    }
    setIsComplaintAddedFromEnlarge(false);
  }, [isComplaintAddedFromEnlarge]);

  useEffect(() => {
    if (isComplaintPatientData) getComplaintPatientData();
    setIsComplaintPatientData(false);
  }, [isComplaintPatientData]);

  useEffect(() => {
    if (!isPageLoad) {
      if (autoSave) setAuoSave(false);
      else setAuoSave(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (state.data.length > 0) {
      setIsAutoSaveUse(true);
    }
  }, [state]);

  useAutosave({
    data: autoSave,
    onSave: submitHandlerAutoSave,
    saveOnUnmount: isAutoSaveUse,
  });

  //*****************************************************************************************//

  const searchComplaint = async (e) => {
    setIsSpecialCharacter(false);
    setComplaintAlreadyAdded(false);
    let search = e.target.value.trim();
    if (search.length >= 100) {
      setSearchLength(true);
      return;
    } else setSearchLength(false);
    let sessionToken = localStorage.getItem("token");
    if (search !== "") {
      // if (!/[^a-zA-Z0-9  .]/.test(search)) {
      if (cancel !== undefined) {
        cancel();
      }
      let strUrl = appConstants.paAppURL;
      strUrl += "complainSearch";
      axios
        .post(
          strUrl,
          { search: search },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
            headers: {
              Authorization: sessionToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let ele = document.getElementById("autoCompleteBox");
          if (response.data.searchData.length > 0) {
            setNoDataFound(false);
            //ele.classList.add("autoCompleteBackDrop");
          } else {
            setNoDataFound(true);
            //ele.classList.remove("autoCompleteBackDrop");
          }

          autocomplete(
            document.getElementById("txtSearchComplaint"),
            response.data.searchData
          );
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          }
        });
      // } else {
      //   setIsSpecialCharacter(true);
      // }
    } else {
      closeLists();
      setNoDataFound(false);
      setTimeout(() => {
        let ele = document.getElementById("autoCompleteBox");
        //ele.classList.remove("autoCompleteBackDrop");
      }, 200);
    }
  };

  function closeLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  const complaintValidate = (complaintId) => {
    setComplaintAlreadyAdded(false);
    let flag = false;
    state.complaints.map((complaint) => {
      if (complaint.symptomsSubCategoryId === complaintId) {
        flag = true;
        setComplaintAlreadyAdded(true);
      }
    });
    return flag;
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          let ele = document.getElementById("txtSearchComplaint");
          if (ele != null) ele.value = "";
          closeLists();
          setNoDataFound(false);
          setComplaintAlreadyAdded(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div
      className="main-content emr-wrapper dashboard-2-0"
      style={{ padding: "15px 0px" }}
    >
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <span> {alertMsg}</span>
      <Grid fluid>
        <Breadcrum />
        <div>
          <div className="general-information">
            <div className="card_header">
              <div className="card_header_inner">
                <ul className="patient_details">
                  <li>
                    <a className="user-info-img">
                      {/* <span><img src={GeneralUsrImg} alt="" />  </span> */}
                      {generalInformation.patientName}
                    </a>
                  </li>
                  <span className="flex">
                    <li>
                      <a>
                        <span>Age: </span> {generalInformation.age} Y /{" "}
                        {generalInformation.gender === "Male" ? " M" : " F"}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Height{generalInformation.height > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.height > 0
                          ? generalInformation.height
                          : ""}{" "}
                        {generalInformation.height > 0 ? "CM" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.weight > 0
                          ? generalInformation.weight
                          : ""}{" "}
                        {generalInformation.weight > 0 ? "KG" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                        {generalInformation.bmi > 0
                          ? generalInformation.bmi
                          : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.bp !== "/"
                          ? generalInformation.bp
                          : ""}{" "}
                        {generalInformation.bp !== "/" ? " mmHg" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.pulse > 0
                          ? generalInformation.pulse
                          : ""}{" "}
                        {generalInformation.pulse > 0 ? "/min" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Temperature
                          {generalInformation.temperature > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.temperature > 0
                          ? generalInformation.temperature
                          : ""}{" "}
                        {generalInformation.temperature > 0 ? "F" : ""}{" "}
                      </a>
                    </li>
                    {toggleDuration && (
                      <li>
                        <a>
                          <span>Diabetes Duration: </span>{" "}
                          {emrData
                            ? emrData?.diabetesDuration
                              ? emrData?.diabetesDuration
                              : duration
                            : duration}{" "}
                        </a>
                      </li>
                    )}
                  </span>
                </ul>
                <div className="patient_history_btn">
                  <NavLink
                    to={`/patient-details`}
                    style={{ marginRight: "10px" }}
                    className="btn btn-fill btn-light btn-sm"
                  >
                    Go Back
                  </NavLink>

                  <NavLink
                    to={`/history/${appointmentId}/${patientId}/${printingLanguage}`}
                    className="btn btn-fill btn-light pull-right btn-sm"
                  >
                    All Visit
                  </NavLink>
                </div>
              </div>
            </div>
            {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
              <div className='pending-ic'>
                <img src={PendingIc} alt='' />
              </div>
              <div className="pending-title">
                <h4>Pending Action <strong>3</strong></h4>
              </div>
            </div> */}
          </div>

          <div className="card-content-inner">
            <div className="emr-menu-list">
              <ul>
                <li>
                  <NavLink
                    to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Vital Signs</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Lab Tests</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Investigation</span>
                  </NavLink>
                </li>
                <li className="current">
                  <NavLink
                    to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
                  >
                    <span className="sidebar-mini">Complaint</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
                    className="nav-link"
                  >
                    <span className="sidebar-mini">Diagnosis</span>
                  </NavLink>
                </li>
                {/* <li><a>Investigation Advised</a></li>
                <li><a>All Investigations</a></li> */}
                <li>
                  {/* <NavLink to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`} className="nav-link" >
                    <span className="sidebar-mini">Prescription</span>
                  </NavLink> */}
                  <a
                    onClick={(e) =>
                      history.push({
                        pathname: `/prescription/${appointmentId}/${patientId}/${printingLanguage}`,
                        complaintData: state.complaints,
                      })
                    }
                    className="sidebar-mini"
                  >
                    Prescription
                  </a>
                </li>
              </ul>
            </div>
            <div className="emr-tab-content">
              <Row>
                <Col lg={6} md={12}>
                  <div className="center-header">
                    <div className="daibetes-toggle">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          onChange={(e) => {
                            toggleDiabetesDuration(e);
                          }}
                          id="customSwitch1"
                        />
                        <label class="custom-control-label" for="customSwitch1">
                          Does patient have diabetes?
                        </label>
                      </div>
                    </div>
                    {toggleDuration && (
                      <div className="diabetes-duration">
                        <select
                          className="daysSelect"
                          onChange={(e) => {
                            inputHandler(e, "day");
                          }}
                          componentClass="select"
                          id="diabetesDurationNo"
                          name="diabetesDuration"
                          value={day}
                        >
                          <option value="No">No</option>
                          {selectDrugDurationDayOptions.map(function (option) {
                            return (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>

                        <select
                          className="weekSelect"
                          onChange={(e) => {
                            inputHandler(e, "period");
                          }}
                          componentClass="select"
                          id="diabetesDurationPeriod"
                          name="diabetesDuration"
                          value={month}
                        >
                          <option value="D/W/M/Y">D/W/M/Y</option>
                          {selectDrugDurationTimePeriodOptions.map(function (
                            option
                          ) {
                            return (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="center-content">
                    <h5>Complaints</h5>
                    {complaintCount && (
                      <div>
                        <p style={{ color: "red" }}>
                          Please select any complaint
                        </p>
                      </div>
                    )}
                    {complaintAlredyAdded && (
                      <div style={{ color: "red" }}>
                        <p>This complaint already added.</p>
                      </div>
                    )}
                    <div
                      id="divComplaints"
                      className="center-content-table table-responsive-new"
                    >
                      <table class="table table-bordered complaints-table-style">
                        <thead>
                          <tr>
                            <th scope="col" style={{ minWidth: "40px" }}>
                              #
                            </th>
                            <th scope="col" style={{ minWidth: "150px" }}>
                              Complaints
                            </th>
                            <th scope="col" style={{ minWidth: "60px" }}>
                              Duration
                            </th>
                            <th scope="col" style={{ minWidth: "100px" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.complaints.map((complaint, idx) => (
                            <tr id="addr0" key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{complaint.complaint}</td>
                              <td>
                                <div className="durationSelects">
                                  <select
                                    className="daysSelect"
                                    onChange={(e) => {
                                      handleDuration(e, "no");
                                    }}
                                    subcatid={complaint.symptomsSubCategoryId}
                                    componentClass="select"
                                    id={complaint.symptomsSubCategoryId + "-no"}
                                    name="duration"
                                    value={complaint.duration.no}
                                  >
                                    <option value="No">No</option>
                                    {selectDrugDurationDayOptions.map(function (
                                      option
                                    ) {
                                      return (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <select
                                    className="weekSelect"
                                    onChange={(e) => {
                                      handleDuration(e, "period");
                                    }}
                                    subcatid={complaint.symptomsSubCategoryId}
                                    componentClass="select"
                                    id={
                                      complaint.symptomsSubCategoryId +
                                      "-period"
                                    }
                                    name="duration"
                                    value={complaint.duration.period}
                                  >
                                    <option value="D/W/M/Y">D/W/M/Y</option>
                                    {selectDrugDurationTimePeriodOptions.map(
                                      function (option) {
                                        return (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </td>

                              <td>
                                <span className="actn-btn">
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      editComplaintPopUp(
                                        complaint.complaint,
                                        complaint.symptomsSubCategoryId,
                                        e
                                      )
                                    }
                                  >
                                    <img src={EditIcon} alt="edit" />
                                  </a>
                                  <a href="#" style={{ color: "red" }}>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() =>
                                        deleteComplaintConfirm(
                                          complaint.symptomsSubCategoryId
                                        )
                                      }
                                      subcatid={complaint.symptomsSubCategoryId}
                                      complaint={complaint.complaint}
                                    />
                                  </a>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* <div className="complaints-action">
                      <AddRareComplaints
                        setModalStatusRareComplaints={setModalStatusRareComplaints}
                        modalStatusRareComplaints={modalStatusRareComplaints}
                        addRareComplaints={addRareComplaints}
                        setAddRareComplaints={setAddRareComplaints}
                        props={addRareComplaint}
                        complaintData={complaintData}
                        complaintDuration={complaintDuration}

                      />

                    </div> */}
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="sytems-wrapper">
                    <div
                      id="autoCompleteBox"
                      class="form-group autoComplete search-complaint"
                      style={{ zIndex: "999" }}
                      ref={wrapperRef}
                    >
                      <FormGroup>
                        <FormControl
                          type="text"
                          maxlength="100"
                          autocomplete="off"
                          onChange={(e) => {
                            searchComplaint(e);
                          }}
                          id="txtSearchComplaint"
                          placeholder="Search Complaint"
                          className="form-control "
                        />
                      </FormGroup>
                    </div>
                    {isSpecialCharacter && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        Special Characters are not allowed.
                      </div>
                    )}
                    {searchLength && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        <p>Maximum 100 Characters are allowed.</p>
                      </div>
                    )}
                    {noDataFound && (
                      <div style={{ color: "red", fontSize: "13px" }}>
                        <p>No Data Found.</p>
                      </div>
                    )}
                    <div className="sytems-wrapper-header">
                      <h5>Complaint Selection</h5>
                      <div className="systems-action">
                        <AddRareComplaints
                          setModalStatusRareComplaints={
                            setModalStatusRareComplaints
                          }
                          modalStatusRareComplaints={modalStatusRareComplaints}
                          addRareComplaints={addRareComplaints}
                          setAddRareComplaints={setAddRareComplaints}
                          props={addRareComplaint}
                          complaintData={complaintData}
                          complaintDuration={complaintDuration}
                        />
                        {/* <a href='#'>
                          <img src={InlargeIc} alt='Inlarge' />
                        </a> */}
                      </div>
                    </div>
                    <div className="systems-lists-wrapper">
                      <FormGroup className="systems-lists">
                        <div className="systems-lists-master">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id={patientId}
                              value={patientId}
                              onClick={(e) => {
                                getSymptomsSubCategory(e, "patient");
                              }}
                            />
                            <label class="form-check-label" for={patientId}>
                              Patient List
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id={clinicId}
                              value={clinicId}
                              onClick={(e) => {
                                getSymptomsSubCategory(e, "clinic");
                              }}
                            />
                            <label class="form-check-label" for={clinicId}>
                              My List
                            </label>
                          </div>
                        </div>
                        <div className="systems-lists-looped">
                          {symptomsCategoryList.map((item) => {
                            return (
                              <>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id={item._id}
                                    value={item._id}
                                    onClick={(e) => {
                                      getSymptomsSubCategory(e, "subcategory");
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for={item._id}
                                  >
                                    {item.name}
                                  </label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </FormGroup>
                      {symptomsSubCategoryList.length > 0 && (
                        <div className="suggestion-box">
                          <div className="suggetion-box-header">
                            <h6>Suggestions</h6>
                            <span className="enlarge">
                              <ComplaintsEnlarge
                                setModal={setModalStatus}
                                ModalStatus={modalStatus}
                                ComplaintsEnlarge={complaintsEnlarge}
                                setComplaintsEnlarge={setComplaintsEnlarge}
                                flagComplaint={flag}
                                patientListCount={patientListCount}
                                myListCount={myListCount}
                                selectedCategoryId={selectedCategoryId}
                                complaints={state.complaints}
                                data={state.data}
                                dispatch={dispatch}
                                complaintPatientId={patientId}
                                complaintAppointmentId={appointmentId}
                              />
                            </span>
                          </div>
                          {/* <div className="suggestion-box-action">
                            <span className='enlarge'>
                              <ComplaintsEnlarge
                                setModal={setModalStatus}
                                ModalStatus={modalStatus}
                                ComplaintsEnlarge={complaintsEnlarge}
                                setComplaintsEnlarge={setComplaintsEnlarge}
                                flagComplaint={flag}
                                patientListCount={patientListCount}
                                myListCount={myListCount}
                                selectedCategoryId={selectedCategoryId}
                                complaints={state.complaints}
                                data={state.data}
                                dispatch={dispatch}
                                complaintPatientId={patientId}
                                complaintAppointmentId={appointmentId}
                              /></span>
                            <span className='body-parts'><BodyParts
                              setModalBodyPart={setModalStatusBodyPart}
                              modalStatusBodyPart={modalStatusBodyPart}
                              BodyParts={bodyParts}
                              setBodyParts={setBodyParts} /></span>
                          </div> */}
                          <div className="suggestion-list">
                            {symptomsSubCategoryList.map((item, idx) => {
                              {
                                count++;
                              }
                              if (count <= 30) {
                                return (
                                  <>
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value={
                                          flag === "masterSymptomsCategoryList"
                                            ? item.name
                                            : item.symptomsSubCategoryName
                                        }
                                        onChange={(e) => {
                                          addOrDeleteComplaints(e);
                                        }}
                                        subcatid={
                                          flag === "patientSymptomsList"
                                            ? item.symptomsSubCategoryId
                                            : item._id
                                        }
                                        name="subcat"
                                        id={
                                          flag === "patientSymptomsList"
                                            ? item.symptomsSubCategoryId
                                            : item._id
                                        }
                                      />
                                      <label
                                        class="form-check-label"
                                        for={
                                          flag === "patientSymptomsList"
                                            ? item.symptomsSubCategoryId
                                            : item._id
                                        }
                                        title={
                                          flag === "masterSymptomsCategoryList"
                                            ? item.name
                                            : item.symptomsSubCategoryName
                                        }
                                      >
                                        {flag === "masterSymptomsCategoryList"
                                          ? item.name
                                          : item.symptomsSubCategoryName}
                                      </label>
                                    </div>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default withRouter(Complaints);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper"


export const addLink = (data) => postRequestWithToken(data, 'admin/add-link');
export const updateLink = (data) => postRequestWithToken(data, 'admin/update-link');
export const changeLinkStatus = (data) => postRequestWithToken(data, 'admin/change-link-status');
export const linkList = (data) => postRequestWithToken(data, 'admin/link-list');
export const uploadFile = (data) => postRequestWithTokenFile(data, 'upload-file');
export const uploadPdf = (data) => postRequestWithToken(data, 'upload-pdf');

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

// import LoginPage from 'Admin/views/Pages/LoginPage.jsx';
import RegisterPage from 'Admin/views/Pages/RegisterPage.jsx';
import LockScreenPage from 'Admin/views/Pages/LockScreenPage.jsx';


var pagesRoutes = [
    // { path: "/login", name: "Login Page", mini: "LP", component: LoginPage },
    { path: "/pages/register-page", name: "Register", mini: "RP", component: RegisterPage },
    { path: "/pages/lock-screen-page", name: "Lock Screen Page", mini: "LSP", component: LockScreenPage }

];

export default pagesRoutes;

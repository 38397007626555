/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addLowCalorieFood = (data) => postRequestWithToken(data, 'admin/add-low-calorie-food');
export const updateLowCalorieFood = (data) => postRequestWithToken(data, 'admin/update-low-calorie-food');
export const changeLowCalorieFoodStatus = (data) => postRequestWithToken(data, 'admin/low-calorie-food-change-status');
export const LowCalorieFoodList = (data) => postRequestWithToken(data, 'admin/low-calorie-food-list');
export const deleteLowCalorieFood = (data) => postRequestWithToken(data, 'admin/delete-low-calorie-food');

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
// react component used to create charts
// function that returns a color based on an interval of numbers
import { scaleLinear } from "d3-scale";
// react components used to create a SVG / Vector map
// import {
//     ComposableMap,
//     ZoomableGroup,
//     Geographies,
//     Geography,
// } from "react-simple-maps";

import Card from "Admin/components/Card/Card.jsx";
import StatsCard from "Admin/components/Card/StatsCard.jsx";
import Tasks from "Admin/components/Tasks/Tasks.jsx";
import history from "../../routes/history";
import {
  dataPie,
  dataSales,
  optionsSales,
  responsiveSales,
  dataBar,
  optionsBar,
  responsiveBar,
  table_data,
} from "Admin/variables/Variables.jsx";
import { dashboardAction } from "Admin/actions/master";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import Pattern1 from "Admin/assets/img/clinic-icon.png";
import Pattern2 from "Admin/assets/img/share.png";
import Pattern3 from "Admin/assets/img/total-doctor.png";
import Pattern4 from "Admin/assets/img/Video-documentRequest.png";
import Pattern5 from "Admin/assets/img/DataUploadReport.png";
import Pattern6 from "Admin/assets/img/EducatorMessageRequest.png";
import Pattern7 from "Admin/assets/img/Inactive-clinic.png";
import Pattern8 from "Admin/assets/img/KnowledgeRequest.png";
import Pattern9 from "Admin/assets/img/Subscription-Expiring.png";
import Pattern10 from "Admin/assets/img/clinic.png";
import Pattern11 from "Admin/assets/img/BirthDayWishes.png";
import Pattern16 from "Admin/assets/img/Support-System.png";
import Pattern17 from "Admin/assets/img/calendar.png";
import Pattern18 from "Admin/assets/img/video-file.png";
import Pattern19 from "Admin/assets/img/medium.png";

const colorScale = scaleLinear()
  .domain([0, 1, 6820])
  .range(["#E5E5E5", "#B2B2B2", "#000000"]);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      isLoading: true,

    };
  }

  componentDidMount() {
    this.props.dashboardAction();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isDashboard !== this.props.isDashboard) {
      this.setState({
        dashboardData: nextProps.Dashboard,
      });
    }
  }

  createTableData() {
    var tableRows = [];
    for (var i = 0; i < table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{table_data[i].country}</td>
          <td className="text-right">{table_data[i].count}</td>
          <td className="text-right">{table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  }

  render() {
    // let userCounts = JSON.parse(localStorage.getItem('userCounts'));
    // let reception_count = 0;
    // let doctor_count = 0;
    // let educator_count = 0;
    // for (let i in userCounts) {
    //     if (userCounts[i]._id == "receptionist")
    //         reception_count = userCounts[i].count;
    //     else if (userCounts[i]._id == "doctor")
    //         doctor_count = userCounts[i].count;
    //     else if (userCounts[i]._id == "educator")
    //         educator_count = userCounts[i].count;
    // }
    let latestClinc = this.state.dashboardData.latestClinc;
    let latestKnowledge = this.state.dashboardData.latestKnowledge;
    let latestMyClinic = this.state.dashboardData.latestMyClinic;
    let latestInvitation = this.state.dashboardData.latestInvitation;

    return (
      <div className="main-content dashboard">
        <Grid fluid>
          <Row>
            {/*<Col lg={4} sm={4}>
                            <StatsCard
                                bigIcon={<i className="fa fa-share-square-o text-warning"></i>}
                                statsText="Clinic Invitation"
                                statsValue={<Link to="/admin/clinic-invitation" className="text-warning">{this.state.dashboardData.Clinic}</Link>}
                                statsIcon={<Link to="/admin/clinic-invitation"><i className="fa fa-list-alt"></i></Link>}
                                statsIconText={<Link to="/admin/clinic-invitation">Clinic Invitation</Link>}
                            />
                        </Col>  */}

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="totol-clin">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="Total Clinic"
                statsValue={
                  <Link to="/admin/clinic-list" className="text-danger">
                    {this.state.dashboardData.Clinic}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/clinic-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{ pathname: `/admin/clinic-list`, userType: "doctor" }}
                    className="read-btn-morre totol-cli-btn"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="grad-link">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="Pending Clinic Invitation Request"
                statsValue={
                  <Link to="/admin/clinic-invitation" className="text-warning">
                    {
                      this.state.dashboardData
                        .PendingClinicInvitationRequestCount
                    }
                  </Link>
                }
                statsIcon={<Link to="/admin/clinic-invitation"></Link>}
                statsIconText={
                  <Link
                    to={{
                      pathname: `/admin/clinic-invitation`,
                      state: { send: true, mailRepliedStatus: false },
                    }}
                    className="read-btn-morre "
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="educator-mm">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="Pending Clinic Request"
                statsValue={
                  <Link to="/admin/clinic-invitation" className="text-warning">
                    {this.state.dashboardData.pendingClinicRequestCount}
                  </Link>
                }
                statsIcon={<Link to="/admin/clinic-invitation"></Link>}
                statsIconText={
                  <Link
                    to={{
                      pathname: `/admin/clinic-invitation`,
                      state: { send: true, mailRepliedStatus: true },
                    }}
                    className="read-btn-morre educt-btn"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="inactive-cl">
                    <img src={Pattern7} />
                  </div>
                }
                statsText="Inactive Clinic"
                statsValue={
                  <Link to="/admin/clinic-list" className="text-warning">
                    {this.state.dashboardData.inactiveClinic}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/clinic-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{ pathname: `/admin/clinic-list`, status: "inactive" }}
                    className="read-btn-morre inact-cc"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="subscript-c">
                    <img src={Pattern2} />
                  </div>
                }
                statsText="Library Sharing"
                statsValue={
                  <Link
                    to="/admin/clinic-document-list"
                    className="text-warning"
                  >
                    {this.state.dashboardData.Document}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/clinic-document-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{
                      pathname: `/admin/clinic-document-list`,
                      status: "active",
                      state: "inactive",
                    }}
                    className="read-btn-morre read-btn-morre-sub"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="data-uplod">
                    <img src={Pattern5} />
                  </div>
                }
                statsText="Data Upload Report"
                statsValue={
                  <Link to="/admin/clinic-uploads" className="text-danger">
                    {this.state.dashboardData.totalUploadCount}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/clinic-uploads">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{
                      pathname: `/admin/clinic-uploads`,
                      status: "inactive",
                      userType: "doctor",
                    }}
                    className="read-btn-morre data-uplod-btnn"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="al-clin">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="All Clinic Activity"
                statsValue={
                  <Link to="/admin/report-list" className="text-success">
                    {this.state.dashboardData.totalSharedCount}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/report-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{
                      pathname: `/admin/report-list`,
                      status: "active",
                      userType: "educator",
                    }}
                    className="read-btn-morre al-clin-btnnm"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="Knowledge-rrb">
                          <img src={Pattern9} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Subscription</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Expiring</span>
                      <a onClick={(e) => { this.props.history.push("/admin/clinic-subscription", { flag: "Expiring" }) }}>
                        <span className="lable-value">
                          {this.state.dashboardData.subscriptionExpiringCount}
                        </span>
                      </a>
                    </li>
                    <li>
                      <span className="card-lable">Expired</span>
                      <span className="lable-value">
                        <a onClick={(e) => { this.props.history.push("/admin/clinic-subscription", { flag: "Expired" }) }}>
                          <span className="lable-value">
                            {
                              this.state.dashboardData
                                .totalClinicSubscriptionEnded
                            }
                          </span>
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="nw-rom">
                                 <img src={Pattern9}/>
                                 </div>
                                 }
                                statsText="News Room"
                                statsValue={<Link to="/admin/news-room" className="text-success">{this.state.dashboardData.totalNewsCount}</Link>}
                                statsIcon={<Link to="/admin/news-room">
                                <i className="fa fa-list-alt"></i></Link>}
                                statsIconText={<Link to={{ pathname: `/admin/news-room`, status: "active", state: 'expire' }} className="read-btn-morre read-btn-morre-sub nw-rom-cn">Read more</Link>}
                            />
                        </Col> */}

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="Specialization">
                    <img src={Pattern9} />
                  </div>
                }
                statsText="Specialization"
                statsValue={
                  <Link to="/specialization-list" className="text-success">
                    {this.state.dashboardData.totalSpecializationCount}
                  </Link>
                }
                statsIcon={
                  <Link to="/specialization-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{
                      pathname: `/specialization-list`,
                      status: "active",
                      state: "expire",
                    }}
                    className="read-btn-morre read-btn-morre-sub Specialization-btn"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="Support-System">
                    <img src={Pattern16} />
                  </div>
                }
                statsText="Support Ticket"
                statsValue={
                  <Link to="/support-list" className="text-success">
                    {this.state.dashboardData.totalSupportCount}
                  </Link>
                }
                statsIcon={
                  <Link to="/support-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{
                      pathname: `/support-list`,
                      status: "active",
                      state: "inactive",
                    }}
                    className="read-btn-morre read-btn-morre-sub Support-System-btn"
                  >
                    Read more
                  </Link>
                }
              />
            </Col> */}
            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="Knowledge-rrb">
                          <img src={Pattern16} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Support Ticket</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Total</span>
                      <Link to="/support-list" className="text-success">
                        <span className="lable-value">
                          {this.state.dashboardData.totalSupportCount}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <span className="card-lable">Open Ticket</span>
                      <span className="lable-value">
                        <Link to="/support-list/open">
                          <span className="lable-value">
                            {
                              this.state.dashboardData
                                .totalSupportActiveCount
                            }
                          </span>
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Close Ticket</span>
                      <span className="lable-value">
                        <Link to="/support-list/close">
                          <span className="lable-value">
                            {
                              this.state.dashboardData
                                .totalSupportInActiveCount
                            }
                          </span>
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="educator-mm">
                                 <img src={Pattern9}/>
                                 </div>
                                 }
                                statsText="No Of Patients Who Have Seen News Room"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.totalNumberOfPatientSeenNewsRoom}</Link>}
                            />
                        </Col> */}

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="totol-clin">
                    <img src={Pattern11} />
                  </div>
                }
                statsText="Birthday Wish Report"
                statsValue={
                  <Link
                    to=""
                    style={{ pointerEvents: "none" }}
                    className="text-success"
                  >
                    {this.state.dashboardData.totalNoOfBirthdayWishesSent}
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              {/* <StatsCard
                bigIcon={
                  <div className="Knowledge-rrb">
                    <img src={Pattern16} />
                  </div>
                }
                statsText="No. Of Patients Using Chat"
                statsValue={
                  <Link
                    to=""
                    style={{ pointerEvents: "none" }}
                    className="text-success"
                  >
                    {this.state.dashboardData.totalNumberOfPatientsUsingChat}
                  </Link>
                }
              /> */}
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div className="grad-link">
                          <img src={Pattern16} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Chat</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">No. Of Patients Using Chat </span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfPatientsUsingChat}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">No. Of clinic Using Chat</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfClinicUsingChat}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="totol-clin">
                    <img src={Pattern10} />
                  </div>
                }
                statsText="My Clinic"
                statsValue={
                  <Link
                    to=""
                    style={{ pointerEvents: "none" }}
                    className="text-success"
                  >
                    {
                      this.state.dashboardData
                        .totalNumberOfMyCliniCUploadByClinic
                    }
                  </Link>
                }
              />
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div class="educator-mm">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="No. Of Patients Using Glucose Diary"
                statsValue={
                  <Link
                    to=""
                    style={{ pointerEvents: "none" }}
                    className="text-success"
                  >
                    {this.state.dashboardData.totalNumberOfGlucoseDiaryPatients}
                  </Link>
                }
              />
            </Col>

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="totol-clin">
                    <img src={Pattern1} />
                  </div>
                }
                statsText="No. of insulin calculator is ON sharma ji"
                statsValue={
                  <Link
                    to=""
                    style={{ pointerEvents: "none" }}
                    className="text-success"
                  >
                    {
                      this.state.dashboardData
                        .totalNumberOfInsulinCalculatorIsOn
                    }
                  </Link>
                }
              />
            </Col> */}

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div className="grad-link">
                          <img src={Pattern1} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Insulin Calculator</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">No. Of Clinic ON Insulin Calculator </span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfClinicUsingInsulinCalculator}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Total No. Of Patients ON Insulin Calculator</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfInsulinCalculatorIsOn}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="data-uplod">
                                 <img src={Pattern5}/>
                                 </div>
                                 }
                                statsText="No. Of Patients Sent Chat In Last 30 Days"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.totalNumberOfPatientsUsingChatIn30Days}</Link>}
                            />
                        </Col> */}

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="al-clin">
                                 <img src={Pattern9}/>
                                 </div>
                                 }
                                statsText="Total No. Of Reports Saved In App"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.numberOfReportSavedInApp}</Link>}
                            />
                        </Col> */}

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="Specialization">
                                 <img src={Pattern2}/>
                                 </div>
                                 }
                                statsText="Total No. Of Reports Shared"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.totalNumberOfSharedReport}</Link>}
                            />
                        </Col> */}

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="totol-clin">
                                 <img src={Pattern10}/>
                                 </div>
                                 }
                                statsText="Total No. Of Diet Charts Printed"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.totalNumberOfDietPrint}</Link>}
                            />
                        </Col> */}

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <StatsCard
                                bigIcon={
                                 <div className="subscript-c">
                                 <img src={Pattern2}/>
                                 </div>
                                 }
                                statsText="Total No. Of Diet Charts Shared"
                                statsValue={<Link to="" style={{"pointerEvents":"none"}} className="text-success">{this.state.dashboardData.totalNumberDietShared}</Link>}
                            />
                        </Col> */}

            {/* <Col lg={4} sm={4} className="bg-pend-clinic">
                            <div className="custom-state-card card card-stats">
                                <div  className='content'>
                                    <p>Chat Facility Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Patients Using</span>
                                            <span className='lable-value'>{this.state.dashboardData.totalNumberOfPatientsUsingChat}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Patients Sent Last 30 Days</span>
                                            <span className='lable-value'>{this.state.dashboardData.totalNumberOfPatientsUsingChatIn30Days}</span>
                                        </li>
        
                                    </ul>
                                </div>
                            </div>
                        </Col> */}

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="Support-System">
                          <img src={Pattern1} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Clinic Performance</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Frequent</span>
                      <Link to="/admin/clinic-performance-list/Frequent" className="text-success">
                        <span className="lable-value">
                          {this.state.dashboardData.frequentUserCount}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <span className="card-lable">Occasional</span>
                      <Link to="/admin/clinic-performance-list/Occasional" className="text-success">
                        <span className="lable-value">
                          {this.state.dashboardData.occasionalUserCount}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <span className="card-lable">Never</span>
                      <Link to="/admin/clinic-performance-list/Never" className="text-success">
                        <span className="lable-value">
                          {this.state.dashboardData.neverUserCount}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="al-clin">
                          <img src={Pattern2} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Reports</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Shared</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfSharedReport}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Saved In App</span>
                      <span className="lable-value">
                        {this.state.dashboardData.numberOfReportSavedInApp}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">No. Of Clinic ON Shared Report</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfClinicUsingReportShare}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="data-uplod">
                          <img src={Pattern3} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Diet</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Shared</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfSharedReport}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Printed</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfDietPrint}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Total Diet Chart Printed For Non App Users</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfDietPrintNonAppUser}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="subscript-c">
                          <img src={Pattern4} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>News Room</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Totall</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNewsCount}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Patient Seen</span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalNumberOfPatientSeenNewsRoom
                        }
                      </span>
                    </li>
                  </ul>
                  {/* ? <Link to={{ pathname: `/admin/news-room`, status: "active", state: 'expire' }} className="read-btn-morre totol-cli-btn">Read more</Link> */}
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="inactive-cl">
                          <img src={Pattern6} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>EMR</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Shared</span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalNumberOfPrescriptionShare
                        }
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Printed</span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalNumberOfPrescriptionPrint
                        }
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="educator-mm">
                          <img src={Pattern7} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Patient</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Totall</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalPatients}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">App Users</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalAppUserPatients}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Non App Users</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNonAppUser}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">New</span>
                      <span className="lable-value">
                        {this.state.dashboardData.dailyRegisterCountPatient}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="grad-link">
                          <img src={Pattern8} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Video & Documents Shared</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Video Shared</span>
                      <span className="lable-value">
                        {this.state.dashboardData
                          .totalVideoUploadedByCliniCShareOnApp +
                          this.state.dashboardData
                            .totalVideoUploadedByAdminShareOnApp}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Documents Shared</span>
                      <span className="lable-value">
                        {this.state.dashboardData
                          .totalDocumentUploadedByCliniCShareOnApp +
                          this.state.dashboardData
                            .totalDocumentUploadedByAdminShareOnApp}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="totol-clin">
                          <img src={Pattern18} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Clinic Video & Documents Shared</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Video Shared</span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalVideoUploadedByCliniCShareOnApp
                        }
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Documents Shared</span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalDocumentUploadedByCliniCShareOnApp
                        }
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">
                        Video Shared from Library
                      </span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalVideoUploadedByAdminShareOnApp
                        }
                      </span>
                    </li>

                    <li>
                      <span className="card-lable">
                        Documents Shared from Library
                      </span>
                      <span className="lable-value">
                        {
                          this.state.dashboardData
                            .totalDocumentUploadedByAdminShareOnApp
                        }
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="educator-mm">
                          <img src={Pattern5} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Data Upload</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Video/Document Uploaded By Clinic</span>
                      <span className="lable-value">
                        {this.state.dashboardData.clincUploadDocumentCount}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Video/Document Uploaded By Admin</span>
                      <span className="lable-value">
                        {this.state.dashboardData.adminUploadDocumentCount}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="Knowledge-rrb">
                          <img src={Pattern16} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Images Not Uploaded BY Clinic</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Clinic Logo</span>
                      <Link to="/admin/clinic-list/logo" className="text-success">
                        <span className="lable-value">
                          {this.state.dashboardData.logoImageNotUpload}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <span className="card-lable">Doctor Image</span>
                      <span className="lable-value">
                        <Link to="/admin/clinic-list/doctor">
                          <span className="lable-value">
                            {
                              this.state.dashboardData
                                .doctorImageNotUploaded
                            }
                          </span>
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Clinic Image</span>
                      <span className="lable-value">
                        <Link to="/admin/clinic-list/clinic">
                          <span className="lable-value">
                            {
                              this.state.dashboardData
                                .clinicImageNotUploaded
                            }
                          </span>
                        </Link>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="al-clin">
                          <img src={Pattern17} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Next Visit Date </p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">Patient Given Next Visit Date</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfPatientGivenNextVisitDate}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">Patient Not Given Next Visit Date</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalNumberOfPatientNotGivenNextVisitDate}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={4} className="bg-pend-clinic">
              <div className="custom-state-card card card-stats">
                <div className="content">
                  <div class="row">
                    <div class="col-xs-5">
                      <div class="icon-big text-center icon-warning">
                        <div class="grad-link">
                          <img src={Pattern19} />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-7">
                      <div class="numbers">
                        <p>Star Status And Icons</p>
                      </div>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <span className="card-lable">No. Of Clinics Are Using Star Status</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalClinicsStarStatus}
                      </span>
                    </li>
                    <li>
                      <span className="card-lable">No. Of Clinics Are Using Icons</span>
                      <span className="lable-value">
                        {this.state.dashboardData.totalClinicsTags}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={4} className="bg-pend-clinic">
              <StatsCard
                bigIcon={
                  <div className="subscript-c">
                    <img src={Pattern17} />
                  </div>
                }
                statsText="New Drug"
                statsValue={
                  <Link
                    to="/admin/clinic-document-list"
                    className="text-warning"
                  >
                    {this.state.dashboardData.drugAddedByClinicCount}
                  </Link>
                }
                statsIcon={
                  <Link to="/admin/clinic-document-list">
                    <i className="fa fa-list-alt"></i>
                  </Link>
                }
                statsIconText={
                  <Link
                    to={{ pathname: `/admin/drug-list`, state: { isChecked: true }, userType: "doctor", }}
                    className="read-btn-morre read-btn-morre-sub drug1"
                  >
                    Read more
                  </Link>
                }
              />
            </Col>

            {/*  </Row>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Global Sales by Top Locations"
                                category="All products that were shipped"
                                content={
                                    <Row>
                                        <Col md={5}>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        {this.createTableData()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        <Col md={6} mdOffset={1}>
                                            <ComposableMap style={{ width: "100%", height: "300px" }}>
                                                <ZoomableGroup>
                                                    <Geographies geographyUrl="https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-50m.json">
                                                    {(geographies, projection) => geographies.map(geography => {
                                                        var style;
                                                        switch (geography.id) {
                                                            case "BRA":
                                                            style={default: { fill: colorScale(550) }}
                                                            break;
                                                            case "USA":
                                                            style={default: { fill: colorScale(2920) }}
                                                            break;
                                                            case "AUS":
                                                            style={default: { fill: colorScale(760) }}
                                                            break;
                                                            case "DEU":
                                                            style={default: { fill: colorScale(1300) }}
                                                            break;
                                                            case "GBR":
                                                            style={default: { fill: colorScale(690) }}
                                                            break;
                                                            case "ROU":
                                                            style={default: { fill: colorScale(600) }}
                                                            break;
                                                            default:
                                                            style={default: { fill: colorScale(0) }}
                                                            break;
                                                        }
                                                        return (
                                                            <Geography
                                                                key={ geography.id }
                                                                geography={ geography }
                                                                projection={ projection }
                                                                onClick={ this.handleClick }
                                                                style={style}
                                                            />
                                                        )
                                                    })}
                                                </Geographies>
                                            </ZoomableGroup>
                                        </ComposableMap>
                                    </Col>
                                </Row>
                            }
                        />
                    </Col> */}
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    Dashboard: state.master.Dashboard,
    isDashboard: state.master.isDashboard,
    isDashboardError: state.master.isDashboardError,
  };
}

export default withRouter(
  connect(mapStateToProps, { dashboardAction })(Dashboard)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { getRequest, postRequest, getRequestWithToken, postRequestWithToken } from "./helper"


export const addAppointment = (data) => postRequestWithToken(data, 'add-appointment');

export const getAppointmentList = (data) => postRequestWithToken(data, 'appointment-list');

export const deleteAppointment = (data) => postRequestWithToken(data, 'appointment-delete');

export const updateAppointment = (data) => postRequestWithToken(data, 'update-appointment');

export const patientList = (data) => postRequestWithToken(data, 'patient-list');
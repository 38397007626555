/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],
    message: '',
    visitData: {},
    isHeightWeightValidation: false,

}

export default (state = initState, action = {}) => {

    switch (action.type) {
        case actionTypes.GET_TASK_SCREEN_DATA:
            //console.log(state, "State")
            return {
                ...state,
                message: 'Task Screen Data',
                // visitData: state.visitData,
                // isVisitList: true,
                // isVisitListError: false,
            }

        case actionTypes.SET_TASK_SCREEN_DATA:
            //console.log(action.payload, state.visitData, "taskScreenDataRDX")
            return {
                ...state,
                message: 'Patient Task Sceen Data Saved Successfully',
                visitData: action.payload,

            }

        case actionTypes.GET_TASK_HIEGHT_WEIGHT_DATA:
            //console.log(state, "State")
            return {
                ...state,
                message: 'Task Screen Data',
                // visitData: state.visitData,
                // isVisitList: true,
                // isVisitListError: false,
            }

        case actionTypes.SET_TASK_HEIGHT_WEIGHT_DATA:

            return {
                ...state,
                message: 'Patient Task Sceen Data Saved Successfully',
                isHeightWeightValidation: action.payload,

            }

        default:
            return state
    }
}
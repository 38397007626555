/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { faqListAction, faqchangestatusAction } from 'Admin/actions/faq';
import { linkListAction, linkchangestatusAction } from 'Admin/actions/link';
import { knowledgeListAction, knowledgechangestatusAction } from 'Admin/actions/master';
import { educatorListAction, educatorchangestatusAction } from 'Admin/actions/educator';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { appConstants } from 'Admin/_constants/app.constants.js';
import ReactPlayer from 'react-player';
import ClinicHeader from '../ClinicHeader/ClinicHeader';

class ClinicRequestList extends Component {

  constructor(props) {
    super(props);
    const answer_array = this.props.location.pathname.split('/');
    let _id_clinic;

    if (this.props.location.state && this.props.location.state._id) {
      _id_clinic = this.props.location.state._id;
      localStorage.setItem('_id_clinic', this.props.location.state._id);
    }

    this.state = {
      faqList: [],
      linkList: [],
      knowledgeList: [],
      educatorList: [],
      modelViewData: {},
      isLoading: true,
      userRow: null,
      clinicId: _id_clinic,
      clinicName: answer_array[4] ? answer_array[4] : '',
      s3url: appConstants.s3UploadUrl,
      clinicView: _id_clinic,
      fileType: ""
    }

  }

  componentDidMount() {

    let data = {
      order: "createdAt",
      direction: "desc",
      clinic_id: this.state.clinicId,
      status: "requested",
    }

    this.props.faqListAction(data)
    this.props.linkListAction(data)
    this.props.knowledgeListAction(data)
    this.props.educatorListAction(data)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFaqList !== this.props.isFaqList) {
      this.setState({
        faqList: nextProps.FaqList.data.data
      });

    }

    let requestData = {
      order: "createdAt",
      direction: "desc",
      clinic_id: this.state.clinicId,
      status: "requested",
    }

    let _this = this;

    if (nextProps.isLinkList !== this.props.isLinkList) {
      this.setState({
        linkList: nextProps.LinkList.data.data
      });
    }

    if (nextProps.isKnowledgeList !== this.props.isKnowledgeList) {
      this.setState({
        knowledgeList: nextProps.KnowledgeList.data.data
      });
    }

    if (nextProps.isEducatorList !== this.props.isEducatorList) {
      let arrData = [];

      if (nextProps.EducatorList && nextProps.EducatorList.data && nextProps.EducatorList.data.length > 0) {
        nextProps.EducatorList.data.map(v => {
          if (v.addedType === 'clinic') {
            arrData.push(v);
          }
        })
      }

      this.setState({
        educatorList: arrData
      });

    }

    if (nextProps.isKnowledgeChangeStatus !== this.props.isKnowledgeChangeStatus) {
      setTimeout(function () {
        let params = {
          clinicId: _this.state.clinicId,
          "type": "knowledgeList"
        }
        appConstants.socket.emit('updateData', params);
      }, 1000);

      this.props.knowledgeListAction(requestData)
    }

    if (nextProps.isEducatorChangeStatus !== this.props.isEducatorChangeStatus) {
      setTimeout(function () {
        let params = {
          clinicId: _this.state.clinicId,
          "type": "educatorMsg"
        }
        appConstants.socket.emit('updateData', params);
      }, 1000);
    }

    if (nextProps.isLinkChangeStatus !== this.props.isLinkChangeStatus) {
      setTimeout(function () {
        let params = {
          clinicId: _this.state.clinicId,
          "type": "myClinic"
        }
        appConstants.socket.emit('updateData', params);
      }, 1000);

      this.props.linkListAction(requestData)
    }


    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  editButton(cell, row, enumObject, rowIndex) {
    if (this.props.location.state) {
      return (<span><Link to={{ pathname: `/admin/update-faq/` + row._id, state: { row }, clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
        <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a>
      </span>)
    } else {
      return (<span><Link to={{ pathname: `/admin/update-faq/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
        <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a>
      </span>)
    }
    // <a href="javascript:void(0)" onClick={this.deleteButton.bind(this, row.invitationId)}><i className="fa fa-trash-o" aria-hidden="true"></i></a></p>)
  }

  editLink(cell, row, enumObject, rowIndex) {
    // if (this.props.location.state) {
    // return (<span><Link to={{ pathname: `/admin/update-link/` + row._id, state: { row },clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-eye" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedLink.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    // </span>)
    // } else {
    //   return (<span><Link to={{ pathname: `/admin/update-link/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-eye" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedLink.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    // </span>)
    // }

    return (<span>
      <a href="javascript:void(0)" onClick={this.openVideoModel.bind(this, row, 'linkType')}><i className={(row.status === "requested") ? ('fa fa-eye') : ('')} aria-hidden="true"></i></a>
    </span>)
  }

  editKnowledge(cell, row, enumObject, rowIndex) {


    return (<span>
      {/*<Link to={{ pathname: `/admin/update-knowledge/` + row._id, state: { row, clinicDetail: this.props.location.state },clinicDetail: this.state.clinic_info }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>*/}
      <a href="javascript:void(0)" onClick={this.openVideoModel.bind(this, row, 'knowledgeType')}><i className={(row.status === "requested") ? ('fa fa-eye') : ('')} aria-hidden="true"></i></a>
    </span>)
    // if (this.props.location.state) {
    // return (<span><Link to={{ pathname: `/admin/update-knowledge/` + row._id, state: { row , clinicDetail: this.props.location.state},clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedKnowledge.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    //   <a href="javascript:void(0)" onClick={this.openVideoModel.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-eye') : ('')} aria-hidden="true"></i></a>
    // </span>)
    // } else {
    //   return (<span><Link to={{ pathname: `/admin/update-knowledge/` + row._id, state: { row, clinicDetail: this.props.location.state },clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedKnowledge.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    //   <a href="javascript:void(0)" onClick={this.openVideoModel.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-eye') : ('')} aria-hidden="true"></i></a>
    // </span>)
    // }
  }

  editEducatorMessage(cell, row, enumObject, rowIndex) {

    return (<span><Link to={{ pathname: `/admin/update-educator/` + row._id, state: { row, type: row.addedType ? row.addedType : 'all', pageName: this.props.location.state } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
      {/* <a href="javascript:void(0)" onClick={this.statusChangedEducatorMessage.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    </span>)

    // return (<span>
    //          <Link to={{ pathname: `/admin/update-educator/` + row._id, state: { row },clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
    //          <a href="javascript:void(0)" onClick={this.openVideoModel.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-eye') : ('')} aria-hidden="true"></i></a>
    //        </span>)
    //
    // if (this.props.location.state) {
    // return (<span><Link to={{ pathname: `/admin/update-educator/` + row._id, state: { row },clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-eye" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedEducatorMessage.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    // </span>)
    // } else {
    //   return (<span><Link to={{ pathname: `/admin/update-educator/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-eye" aria-hidden="true"></i></Link>
    //   {/* <a href="javascript:void(0)" onClick={this.statusChangedEducatorMessage.bind(this, row)}><i className={(row.status === "requested") ? ('fa fa-check') : ('')} aria-hidden="true"></i></a> */}
    // </span>)
    // }
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.status == 'requested') {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.faqchangestatusAction(event);
  }

  statusChangedLink(event, elename) {
    let newstatus = {};
    if (event.status == 'requested') {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.linkchangestatusAction(event);
    this.setState({ videoModel: false });
  }

  statusChangedKnowledge(event, elename) {
    let newstatus = {};
    if (event.status == 'requested') {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.knowledgechangestatusAction(event);
    this.setState({ videoModel: false });
  }

  statusChangedEducatorMessage(event, elename) {

    let newstatus = {};
    if (event.status == 'requested') {
      newstatus = 'active';
    }
    let data = {}
    data['_id'] = event._id;
    data['status'] = newstatus;


    event['id'] = event._id;
    event['status'] = newstatus;
    this.props.educatorchangestatusAction(data);

  }

  toggleEdit(event) {
    this.setState({
      userRow: event
    });
  }


  imploadArray(cell, row, enumObject, rowIndex, ref) {
    let data = '';
    data = enumObject.question.english;
    return data;
  }
  addFaq(e) {

    this.props.history.push({
      pathname: '/admin/add-faq',
      state: { clinic_id: this.state.clinicId, clinicName: this.props.location.state.name }
    })
  }


  addLink(e) {
    this.props.history.push({
      pathname: '/admin/add-link',
      state: {
        clinic_id: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
        clinicName: this.props && this.props.location && this.props.location.state && this.props.location.state.name ? this.props.location.state.name : "",
        clinicDetail: this.props.location.state,
      }
    })
  }

  addKnowledge(e) {
    this.props.history.push({
      pathname: '/admin/add-knowledge',
      state: {
        clinic_id: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
        clinicName: this.props && this.props.location && this.props.location.state && this.props.location.state.name ? this.props.location.state.name : "",
        clinicDetail: this.props.location.state,
      }
    })
  }

  addEducatorMessage(e) {
    this.props.history.push({
      pathname: '/admin/add-educator',
      state: {
        clinic_id: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
        clinicName: this.props && this.props.location && this.props.location.state && this.props.location.state.name ? this.props.location.state.name : "",
        clinicDetail: this.props.location.state,
      }
    })
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  openVideoModel(row, request) {

    let arr = row.image.split('.');
    let output = arr.pop();
    if (output == "mp4") {
      row.type = "video";
    } else if (output == "pdf") {
      row.type = "pdf";

    } else {
      row.type = "image";
    }

    row.requestType = request;

    this.setState({ modelViewData: row, videoModel: true });
  }

  render() {
    const { modelViewData } = this.state;

    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',
    };

    return (
      <div className="main-content" style={{ padding: '15px 0px' }}>
        <Grid fluid>

          <Row>
            <Col md={12}>
              {this.state.clinicView &&
                <ClinicHeader componentData={{ clinicId: this.state.clinicView }} />
              }
            </Col>
          </Row>

          <Row>
            {/*<Col md={6}>
                  <Card
                    content={
                      <div className="fresh-datatables">

                        <Row>
                          <Col md={6}>
                              <span className="clinictitle">FAQ</span>
                          </Col>
                          <Col md={6}>
                              <Button bsStyle="info" className="go-back-btn" fill pullRight onClick={(e) => { this.addFaq(e) }}>Add FAQ</Button>
                          </Col>
                        </Row>&nbsp;

                        <BootstrapTable selectRow="{selectRowProp}" deleteRow={false} data={this.state.faqList} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                          <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                          <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'Question English' }} dataField='question' dataFormat={this.imploadArray.bind(this, "question")}>Name </TableHeaderColumn>
                          <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                          <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} >Action</TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    }
                  />
                </Col> */}

            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={6}>
                        <span className="clinictitle">Clinic Link</span>
                      </Col>
                      {/* <Col md={6}>
                        <Button className = "inacti-button"  fill pullRight onClick={(e) => { this.addLink(e) }}>Add Clinic Link</Button>
                      </Col> */}
                    </Row>&nbsp;
                    <BootstrapTable selectRow="{selectRowProp}" deleteRow={false} data={this.state.linkList} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                      <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'title' }} dataField='title'  >Name </TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editLink.bind(this)}>Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                }
              />
            </Col>

            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">

                    <Row>
                      <Col md={6}>
                        <span className="clinictitle">Knowledge</span>
                      </Col>
                      {/*<Col md={6}>
                        <Button className = "inacti-button" fill pullRight onClick={(e) => { this.addKnowledge(e) }}>Add Knowledge</Button>
                      </Col> */}
                    </Row>&nbsp;
                    <BootstrapTable selectRow="{selectRowProp}" deleteRow={false} data={this.state.knowledgeList} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                      <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'title' }} dataField='title'  >Name </TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editKnowledge.bind(this)}>Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">

                    <Row>
                      <Col md={6}>
                        <span className="clinictitle">Educator Message</span>
                      </Col>
                      {/*<Col md={6}>
                        <Button className = "inacti-button"  fill pullRight onClick={(e) => { this.addEducatorMessage(e) }}>Add Educator Message</Button>
                      </Col> */}
                    </Row>&nbsp;

                    <BootstrapTable selectRow="{selectRowProp}" deleteRow={false} data={this.state.educatorList} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                      <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'message' }} dataField='message'  >Message </TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '20%' }} tdStyle={{ width: '20%' }} tdAttr={{ 'data-attr': 'status' }} dataField='status' >Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editEducatorMessage.bind(this)}>Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                }
              />
              <Modal show={this.state.videoModel} onHide={() => this.setState({ videoModel: false })} dialogClassName="modal-md">
                <Modal.Header>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <Modal.Title><h5>{modelViewData.clinicName} {modelViewData.type} request</h5></Modal.Title>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                  <Row>
                    <Col md={12}>
                      <div>
                        <Row>
                          <Col md={12}>

                            {modelViewData && modelViewData.type && modelViewData.type == "image" &&
                              <img src={this.state.s3url + modelViewData.image} width="70%" height="100%" />
                            }
                            {modelViewData && modelViewData.type && modelViewData.type == "video" &&
                              <ReactPlayer className='react-player' width='100%' height='100%' url={this.state.s3url + modelViewData.image} playing controls="true" volume="1" />
                            }
                            {modelViewData && modelViewData.type && modelViewData.type == "pdf" &&
                              <a href={this.state.s3url + modelViewData.image} target="_blank"> Download pdf</a>
                            }
                          </Col>
                        </Row>


                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  {modelViewData && modelViewData.requestType === 'knowledgeType' &&
                    <button type="button" onClick={() => this.statusChangedKnowledge(modelViewData)} className="btn-fill btn-wd btn btn-info">Approve</button>
                  }
                  {/* modelViewData && modelViewData.requestType === 'educatorType' &&
                    <button type="button" onClick={() => this.statusChangedEducatorMessage(modelViewData)} className="btn-fill btn-wd btn btn-info">Approve</button>
                  */}
                  {modelViewData && modelViewData.requestType === 'linkType' &&
                    <button type="button" onClick={() => this.statusChangedLink(modelViewData)} className="btn-fill btn-wd btn btn-info">Approve</button>
                  }
                  <button type="button" onClick={() => this.setState({ videoModel: false })} className="btn-fill btn-wd btn btn-secondary">Close</button>&nbsp;
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>

        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    UserList: state.user.UserList,
    isUserList: state.user.isUserList,
    isUserListError: state.user.isUserListError,

    FaqList: state.faq.FaqList,
    isFaqList: state.faq.isFaqList,
    isFaqListError: state.faq.isFaqListError,

    FaqChangeStatus: state.faq.FaqChangeStatus,
    isFaqChangeStatus: state.faq.isFaqChangeStatus,
    isFaqChangeStatusError: state.faq.isFaqChangeStatusError,

    LinkList: state.link.LinkList,
    isLinkList: state.link.isLinkList,
    isLinkListError: state.link.isLinkListError,

    LinkChangeStatus: state.link.LinkChangeStatus,
    isLinkChangeStatus: state.link.isLinkChangeStatus,
    isLinkChangeStatusError: state.link.isLinkChangeStatusError,

    KnowledgeList: state.master.KnowledgeList,
    isKnowledgeList: state.master.isKnowledgeList,
    isKnowledgeListError: state.master.isKnowledgeListError,

    KnowledgeChangeStatus: state.master.KnowledgeChangeStatus,
    isKnowledgeChangeStatus: state.master.isKnowledgeChangeStatus,
    isKnowledgeChangeStatusError: state.master.isKnowledgeChangeStatusError,

    // EducatorRequestList: state.educator.EducatorRequestList,
    // isEducatorRequestList: state.educator.isEducatorRequestList,
    // isEducatorRequestListError: state.educator.isEducatorRequestListError,

    EducatorList: state.educator.EducatorList,
    isEducatorList: state.educator.isEducatorList,
    isEducatorListError: state.educator.isEducatorListError,

    EducatorChangeStatus: state.educator.EducatorChangeStatus,
    isEducatorChangeStatus: state.educator.isEducatorChangeStatus,
    isEducatorChangeStatusError: state.educator.isEducatorChangeStatusError,

  }

}
export default withRouter(connect(mapStateToProps, { faqListAction, faqchangestatusAction, linkListAction, linkchangestatusAction, knowledgeListAction, knowledgechangestatusAction, educatorListAction, educatorchangestatusAction })(ClinicRequestList));

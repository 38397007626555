/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { postRequestWithToken } from "../../api/helper.js"
import { withRouter } from "react-router-dom";
import ReactPlayer from 'react-player';


const SecurityUrlComponent = (props) => {
    const [imagePath, setImagePath] = useState('')

    useEffect(() => {
        const getSignedUrl = async () => {
            let resp = await postRequestWithToken({ filePath: props.data }, 's3SignedUrl');
            setImagePath(resp.data.url)
        };
        getSignedUrl();
    }, []);
    if (props.type === "img") {
        return (

            < img {...props}
                src={imagePath}
            />
        )
    } else if (props.type === "anchor") {
        return (

            < a {...props}
                href={imagePath}
            >Download pdf</a>
        )

    } else if (props.type === "anchorDownload") {
        return (
            < a {...props}
                href={imagePath}
            >Download</a>
        )
    }
    else if (props.type === "video") {
        return (
            <ReactPlayer
                {...props}
                url={imagePath}
            />
            // < a {...props }
            // href = { imagePath }
            // >Download pdf</a>
        )
    }

}

export default SecurityUrlComponent
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';

import banner_img from 'Front/assets/img/banner_2.png';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import logo from 'Front/assets/img/practice_aid_logo.png';
import { Link } from 'react-router-dom';
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isLogin: true,
      showProcessing: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field
    });
  };
  userLogin(evt) {
    evt.preventDefault();
    const _this = this;
  }
  render() {

    return (
      <Media>

        <div className="banner-contact">
          <div className="banner_content">
            <h2>Digitizing Healthcare</h2>
            <p>Cutting-edge Software Products for Healthcare Industry</p>
          </div>
        </div>
        <Grid>
          <div className="about-us">
            <h2>Privacy Policy</h2>
          </div>

          <Row>
            <Col md={12} sm={12} className="privacy-t privacy-policy">
              <div className='text-div'>
                <h4>1. Introduction</h4>
                <p>PracticeAid Group (“us”, “we”, or “our”) operates <a href='https://practice-aid.com/'>https://practice-aid.com/</a> (hereinafter referred to as “Service”).</p>

                <p>Our Privacy Policy governs your visit to <a href='https://practice-aid.com/'>https://practice-aid.com/</a> and explains how we collect, safeguard, and disclose information that results from your use of our Service.</p>

                <p>We use your data to provide and improve Service. By using the Service, you agree to the collection and use of information in accordance with this policy. </p>
                <p><strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
              </div>


              <div className='text-div'>
                <h4>2. Information Collection and Use</h4>
                <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

              </div>



              <div className='text-div'>
                <h4>3. Types of Data Collected</h4>
                <h6>3.1. Personal Data</h6>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“<i>Personal Data</i>”). Personally identifiable information may include, but is not limited to.</p>

              </div>


              <div className='text-div'>
                <h4>3. Types of Data Collected</h4>
                <h6>3.1. Personal Data</h6>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“<i>Personal Data</i>”). Personally identifiable information may include, but is not limited to.</p>
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Address, Country, State, Province, ZIP/Postal code, City</li>
                  <li>Cookies and Usage Data</li>
                </ul>
                <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications by informing us of your choice by emailing us at <a href='mailto:app.practiceaid@gmail.com'>app.practiceaid@gmail.com</a>.</p>
                <h6>3.2. Usage Data</h6>
                <p>We may also collect information that your browser/device sends automatically whenever you visit our Service or when you access the Service by or through any device (“<i>Usage Data</i>”).</p>
                <p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>When you access Service with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>
                <h6>3.3. Location Data</h6>
                <p>We may use and store information about your location if you give us permission to do so (“<strong>Location Data</strong>”). We use this data to provide features of our Service and to improve and customize our Service.</p>
                <p>You can enable or disable location services when you use our Service at any time by way of your device settings.</p>
                <h6>3.4. Tracking Cookies Data</h6>
                <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                <h6>3.5. Other Data</h6>
                <p>While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at the place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location, and other data.</p>
                <p>We may receive Personal and/or Anonymous Data about you from companies that provide our Applications by way of a co-branded or private-labeled website or companies that advertise their products and/or services through our Applications.  These third-party companies may supply us with Personal Data. We may add this information to the information we have already collected from you.</p>
                <p>Our Applications may collect Personal and/or Anonymous Data about you from a social networking site (“SNS”), in accordance with the terms of use and privacy policy of the third party provider of such SNS, if you log on with your login credentials from the SNS. We may add this information to the information we have already collected from you.</p>
              </div>


              <div className='text-div'>
                <h4>4. Use of Data</h4>
                <p>PracticeAid Group uses the collected data for various purposes, including:</p>
                <ul>
                  <li>To provide and maintain our Service.</li>
                  <li>To notify you about changes to our Service.</li>
                  <li>To allow you to participate in interactive features of our Service when you choose to do so.</li>
                  <li>To provide customer support and handle any complaints or feedback.</li>
                  <li>To gather analysis or valuable information so that we can improve our Service.</li>
                  <li>To monitor the usage of our Service.</li>
                  <li>To detect, prevent and address technical issues.</li>
                  <li>To fulfil any other purpose for which you provide it.</li>
                  <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
                  <li>To provide you with notices about your account and/or subscription, including expiration and renewal notices, email instructions, etc.</li>
                  <li>To provide you with news, special offers, and general information about other goods, services, and events that we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</li>
                  <li>To provide you with personalised content, including through the delivery of targeted interest-based advertisements from us or our partners.</li>
                  <li>In any other way we may describe when you provide the information.</li>
                  <li>For any other purpose with your consent.</li>
                </ul>
              </div>


              <div className='text-div'>
                <h4>5. Retention of Data</h4>
                <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
              </div>



              <div className='text-div'>
                <h4>6. Transfer of Data</h4>
                <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to this.</p>
                <p>PracticeAid Group will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
              </div>




              <div className='text-div'>
                <h4>7. Disclosure of Data</h4>
                <p>We may disclose personal information that we collect, or you provide.</p>
                <h6>7.1. Disclosure for Law Enforcement</h6>
                <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>

                <h6>7.2. Business Transaction</h6>
                <p>If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.</p>


                <h6>7.3. Other cases. We may disclose your information also:</h6>
                <ul>
                  <li>To our subsidiaries and affiliates.</li>
                  <li>To contractors, service providers, and other third parties we use to support our business.</li>
                  <li>To fulfil the purpose for which you provide it.</li>
                  <li>For any other purpose disclosed by us when you provide the information.</li>
                  <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
                </ul>
                <p>In any case other than the above, we will not share your PII with third parties without your prior consent.
                </p>
              </div>




              <div className='text-div'>
                <h4>8. Security of Data</h4>
                <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
              </div>


              <div className='text-div'>
                <h4>9. Your Data Protection Rights </h4>
                <p>Your privacy protection is most important to us & we really take care and offer protection for the information we receive from the users. In this regard, we adhere to the various governing laws such as:</p>
                <ul>
                  <li>The Information Technology Act, 2000 – Section 43A.</li>
                  <li>The Information Technology (Reasonable Security Practices & Procedures and Sensitive Personal Information) Rules, 2011.</li>
                </ul>
                <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <a href='app.practiceaid@gmail.com'>app.practiceaid@gmail.com</a>.</p>
                <p>In certain circumstances, you have the following data protection rights:</p>

                <ul>
                  <li>The right to access, update, or delete the information we have on you.</li>
                  <li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</li>
                  <li>The right to object. You have the right to object to our processing of your Personal Data.</li>
                  <li>The right of restriction. You have the right to request that we restrict the processing of your personal information.</li>
                  <li>The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable, and commonly used format.</li>
                  <li>The right to withdraw consent. You also have the right to withdraw your consent at any time when we rely on your consent to process your personal information.</li>
                </ul>
                <p>Please note that we may ask you to verify your identity before responding to such requests. Please note that we may not provide Service without some necessary data.</p>

              </div>





              <div className='text-div'>
                <h4>10. Service Providers</h4>
                <p>We may employ third-party companies and individuals to facilitate our Service (“<i>Service Providers</i>”), provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.</p>
                <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
              </div>


              <div className='text-div'>
                <h4>11. Analytics</h4>
                <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
              </div>

              <div className='text-div'>
                <h4>12. CI/CD tools</h4>
                <p>We may use third-party Service Providers to automate the development process of our Service.</p>
              </div>



              <div className='text-div'>
                <h4>13. Changes to This Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective, and update the “effective date” at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
              </div>



              <div className='text-div'>
                <h4>14. Contact Us</h4>
                <p>If you have any questions about this Privacy Policy, please email us at <a href='mailto:app.practiceaid@gmail.com. '>app.practiceaid@gmail.com</a>.</p>

              </div>

















            </Col>
          </Row>
        </Grid>
        <div className="middle-spc"></div>
      </Media>


    );
  }
}

function mapStateToProps(state) {

  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Contact));

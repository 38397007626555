/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';


export class UserCard extends Component {
    render() {
        return (
            <div className="card card-user">
                <div className="image">
                    <img src={this.props.bgImage} alt="..." />
                </div>
                <div className="content">
                    <div className="author">
                        <a href="#pablo">
                            <img className="avatar border-gray" src={this.props.avatar} alt="..." />
                            <h4 className="title">
                                {this.props.name}
                                <br />
                                <small>{this.props.userName}</small>
                            </h4>
                        </a>
                    </div>
                    <p className="description text-center">
                        {this.props.description}
                    </p>
                </div>
                <hr />
                <div className="text-center">
                    {this.props.socials}
                </div>
            </div>
        );
    }
}

export default UserCard;

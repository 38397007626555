/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState } from 'react';
import { glucoseDiaryList } from 'Front/api/glucoseDiary';
import moment from 'moment';
import time_glucose from "../../assets/img/time_glucose.png"
import { Modal, Row, Col } from 'react-bootstrap';
import GlucoseIcon from "../../assets/img/icons/glucose-diary.svg";
import CardIcon from "../../assets/img/icons/card-icon.svg";
const GlucoseDiaryChart = ({ patientData, callType = "", readStatus }) => {
    const [chartData, setchartData] = useState([]);
    const [glucoseModal, setGlucoseModal] = useState(false);
    const [checkReadStatus, setCheckReadStatus] = useState(false);
    var obj = {};

    const glucoseDataList = async (patientData) => {
        let resp = await glucoseDiaryList({ patientId: patientData._id });
        let response = resp.data.data;
        var listData = [];
        for (let i in response) {
            if (obj[response[i].glucoseDate]) {
                obj[response[i].glucoseDate][response[i].glucoseTime] = response[i].glucoseValue;
            }
            else {
                var data = {};
                data[response[i].glucoseTime] = response[i].glucoseValue;
                obj[response[i].glucoseDate] = data;
            }
        }
        for (let n in obj) {
            var data = {};
            data['date'] = n;
            data['list'] = obj[n];
            listData.push(data)
        }
        setchartData(listData);
    }

    const glucoseList = () => {
        setGlucoseModal(true)
        glucoseDataList(patientData);
    };
    // useEffect(() => {
    //     glucoseDataList(patientData);
    // }, []);

    useEffect(() => {
        setCheckReadStatus(readStatus)
    }, [readStatus]);

    return (
        <span>
            {callType == "" &&
                <div
                    className="aside-card"
                    onClick={() => {
                        glucoseList()
                        setCheckReadStatus(false)
                    }

                    }
                >

                    <div className="card-icon">
                        <img src={GlucoseIcon} alt="" />
                    </div>
                    <h4>
                        Glucose Diary{" "}
                        <span>
                            <img src={CardIcon} alt="" />
                        </span>
                    </h4>
                    {
                        checkReadStatus &&
                        <div className="card-badge">New</div>
                    }

                </div>
            }
            {callType == "name" &&
                <div
                    className=""
                    onClick={() =>
                        glucoseList()
                    }
                >


                    <div className="cursor-pointer-link">
                        Glucose Diary
                    </div>
                </div>
            }
            {callType == "dropdown" &&
                <div

                    onClick={() =>
                        glucoseList()
                    }
                >
                    GLUCOSE DIARY
                </div>
            }
            <Modal
                className="pa-patient-registration"
                show={glucoseModal}
                onHide={() => setGlucoseModal(false)}
                dialogClassName="modal-full modal-xl"
            >
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {/* Glucose Diary (
                        {patientData.name +
                            " - " +
                            patientData.patientId}
                        ) */}
                        Glucose Diary (
                        {patientData.firstName +
                            " " +
                            patientData.lastName +
                            " - " +
                            patientData.patientClinicId}
                        )
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                    <Row>
                        <Col md={12}>
                            <div className="App">
                                <div className="sugar-log" id="Glucose_Chart_Ajax">
                                    <table className='table table-hover table-bordered table-condensed'>
                                        <thead>
                                            <tr>
                                                <th rowSpan="2" style={{ minWidth: "100px" }}>
                                                    <img src={time_glucose} className="img-responsive"></img>
                                                </th>
                                                <th style={{}} colSpan="0">Fasting</th>
                                                <th style={{}} colSpan="0">2hr After Breakfast</th>
                                                <th style={{}} colSpan="0">Before lunch</th>
                                                <th style={{}} colSpan="0">2hr After Lunch</th>
                                                <th style={{}} colSpan="0">Before Dinner</th>
                                                <th style={{}} colSpan="0">2hr After Dinner</th>
                                                <th style={{}} colSpan="0">3am Late Night</th>
                                                <th style={{}} colSpan="0">Bed Time</th>
                                                <th style={{}} colSpan="2">Other Time</th>
                                            </tr>
                                            <tr>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit(mg/dl)</th>
                                                <th style={{}}>Unit | Time</th>
                                            </tr>
                                        </thead>
                                        {chartData.length > 0 &&
                                            <tbody>
                                                {chartData.map((value, key) => {
                                                    return <tr key={key}>
                                                        <td style={{}}>{moment(value.date).format("Do MMM YY")}</td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list.Fasting > 80 && value.list.Fasting < 131 ?
                                                                "green" : value.list.Fasting > 130 && value.list.Fasting < 181 ?
                                                                    "orange" : value.list.Fasting > 180 || value.list.Fasting <= 80 ? "red" : ""
                                                        }}>
                                                            {value.list.Fasting !== undefined ? value.list.Fasting : ''}
                                                        </td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['2hr After Breakfast'] >= 80 && value.list['2hr After Breakfast'] < 121
                                                                || value.list['2hr After Breakfast'] > 180 && value.list['2hr After Breakfast'] < 251 ?
                                                                "orange" : value.list['2hr After Breakfast'] > 120 && value.list['2hr After Breakfast'] < 181 ?
                                                                    "green" : value.list['2hr After Breakfast'] > 250 || value.list['2hr After Breakfast'] < 80 ? "red" : ""
                                                        }}>
                                                            {value.list['2hr After Breakfast'] !== undefined ? value.list['2hr After Breakfast'] : ''}
                                                        </td>

                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['Before Lunch'] >= 80 && value.list['Before Lunch'] < 131 ?
                                                                "green" : value.list['Before Lunch'] > 130 && value.list['Before Lunch'] < 181 ?
                                                                    "orange" : value.list['Before Lunch'] > 180 || value.list['Before Lunch'] < 80 ? "red" : ""
                                                        }}>
                                                            {value.list['Before Lunch'] !== undefined ? value.list['Before Lunch'] : ''}
                                                        </td>

                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['2hr After Lunch'] >= 80 && value.list['2hr After Lunch'] < 121
                                                                || value.list['2hr After Lunch'] > 180 && value.list['2hr After Lunch'] < 251 ?
                                                                "orange" : value.list['2hr After Lunch'] > 120 && value.list['2hr After Lunch'] < 181 ?
                                                                    "green" : value.list['2hr After Lunch'] > 250 || value.list['2hr After Lunch'] <= 80 ? "red" : ""
                                                        }}>
                                                            {value.list['2hr After Lunch'] !== undefined ? value.list['2hr After Lunch'] : ''}
                                                        </td>

                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['Before Dinner'] >= 80 && value.list['Before Dinner'] < 131 ?
                                                                "green" : value.list['Before Dinner'] > 130 && value.list['Before Dinner'] < 181 ?
                                                                    "orange" : value.list['Before Dinner'] > 180 || value.list['Before Dinner'] < 80 ? "red" : ""
                                                        }}>
                                                            {value.list['Before Dinner'] !== undefined ? value.list['Before Dinner'] : ''}
                                                        </td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['2hr After Dinner'] >= 80 && value.list['2hr After Dinner'] < 121
                                                                || value.list['2hr After Dinner'] > 180 && value.list['2hr After Dinner'] < 251 ?
                                                                "orange" : value.list['2hr After Dinner'] > 120 && value.list['2hr After Dinner'] < 181 ?
                                                                    "green" : value.list['2hr After Dinner'] > 250 || value.list['2hr After Dinner'] <= 80 ? "red" : ""
                                                        }}>
                                                            {value.list['2hr After Dinner'] !== undefined ? value.list['2hr After Dinner'] : ''}
                                                        </td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['3am Late Night'] >= 80 && value.list['3am Late Night'] < 131 ?
                                                                "green" : value.list['3am Late Night'] > 130 && value.list['3am Late Night'] < 181 ?
                                                                    "orange" : value.list['3am Late Night'] > 180 || value.list['3am Late Night'] < 80 ? "red" : ""
                                                        }}>
                                                            {value.list['3am Late Night'] !== undefined ? value.list['3am Late Night'] : ''}
                                                        </td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['Bed Time'] >= 80 && value.list['Bed Time'] < 121
                                                                || value.list['Bed Time'] > 180 && value.list['Bed Time'] < 251 ?
                                                                "orange" : value.list['Bed Time'] > 120 && value.list['Bed Time'] < 181 ?
                                                                    "green" : value.list['Bed Time'] > 250 || value.list['Bed Time'] <= 80 ? "red" : ""
                                                        }}>
                                                            {value.list['Bed Time'] !== undefined ? value.list['Bed Time'] : ''}
                                                        </td>
                                                        <td style={{
                                                            color: 'white',
                                                            backgroundColor: value.list['Other Time'] >= 80 && value.list['Other Time'] < 121
                                                                || value.list['Other Time'] > 180 && value.list['Other Time'] < 251 ?
                                                                "orange" : value.list['Other Time'] > 120 && value.list['Other Time'] < 181 ?
                                                                    "green" : value.list['Other Time'] > 250 || value.list['Other Time'] <= 80 ? "red" : ""
                                                        }}>
                                                            {value.list['Other Time'] !== undefined ? value.list['Other Time'] : ''}
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        }
                                    </table>
                                    {chartData.length == 0 &&
                                        <h3 style={{ "align-items": "center", "text-align": "center", "margin-top": "46px" }} >No Data Found!</h3>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </span>

    );
};

export default GlucoseDiaryChart;
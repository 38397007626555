/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addServingSize = (data) => postRequestWithToken(data, 'admin/add-serving-size');
export const updateServingSize = (data) => postRequestWithToken(data, 'admin/update-serving-size');
export const changeServingSizeStatus = (data) => postRequestWithToken(data, 'admin/change-status-serving-size');
export const changeServingSizeImageStatus = (data) => postRequestWithToken(data, 'admin/change-status-image-show');
export const servingSizeList = (data) => postRequestWithToken(data, 'admin/serving-size-list'); 

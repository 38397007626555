/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/supportSystem";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import moment from "moment";
import AddTicket from "./AddTicket";

const Tickets = () => {
  const [formData, setFormData] = useState({ limit: 10, page: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [addTicket, setAddTicket] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [statusColor, setStatusColor] = useState("");

  const getListAction = async (from = {}) => {
    setIsLoading(true);
    let resp = await API.getTicketList({
      limit: formData.limit,
      offset: formData.page,
      order: "createdAt",
      search: from.search,
    });
    setIsLoading(false);
    setTicketList(resp.data.data);
    setTotalCount(resp.data.count);
  };

  useEffect(() => {
    getListAction();
  }, []);

  const handleChange = (e, field = "") => {
    let saveFormData = formData;
    saveFormData[field] = e;
    saveFormData["page"] = 0;
    setFormData({ ...formData, ...saveFormData });
    getListAction(saveFormData);
  };

  const srNo = (cell, row, enumObject, rowIndex) => {
    return rowIndex + 1 + formData.limit * formData.page;
  };

  const appDate = (cell, row, enumObject, rowIndex) => {
    return moment(row.date).format("DD-MMM-YYYY");
  };

  const Action = (cell, row, enumObject, rowIndex) => {
    return (
      <>
        <Link to={{ pathname: `/support-ticket/${row.ticketId}/`, state: row }}>
          <i class="fa fa-eye" aria-hidden="true"></i>
        </Link>
      </>
    );
  };
  const useraction = (cell, row, enumObject, rowIndex) => {
    let subStrTitle = cell;
    if (cell != "" && cell.length >= 200) {
      subStrTitle = `${cell.substring(0, 200)}...`;
    }
    return (
      <span>
        <Link
          to={{ pathname: `support-ticket/${row.ticketId}/`, state: { row } }}
        >
          {subStrTitle}
        </Link>
        &nbsp;&nbsp; &nbsp;&nbsp;
      </span>
    );
  };

  const _setStatusColor = (cell, row, enumObject, rowIndex) => {
    if (row.status === "open") {
      return <span className="ticket-status-color-red">{row.status}</span>;
    } else {
      return <span className="ticket-status-color-green">{row.status}</span>;
    }
  };

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  };

  const onPageChange = (page, sizePerPage) => {
    let data = formData;

    data["page"] = page - 1;
    data["limit"] = sizePerPage;
    setFormData({ ...formData, ...data });
    getListAction(data);
  };

  const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
      return { backgroundColor: "#f3f1b0" };
    }
  };

  const options = {
    //onSortChange: this.onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption(),
  };
  return (
    <div className="patient-search">
      <div className="fresh-datatables   table-responsive-new-withoutScroll">
        <Row className="search-section">
          <Col sm={6} md={4} className="form-group">
            <FormControl
              type="text"
              autocomplete="off"
              name="search"
              id="search"
              value={formData.search ? formData.search : ""}
              onChange={(e) => {
                handleChange(e.target.value, "search");
              }}
              placeholder="Search here ..."
            />
          </Col>
          <Col sm={6} md={8} className="form-group btn-hold text-right">
            <AddTicket
              getListAction={getListAction}
              setModal={setModalStatus}
              ModalStatus={modalStatus}
              addTicket={addTicket}
              setAddTicket={setAddTicket}
            />
          </Col>
        </Row>
        <div className="fresh-datatables dashboard table-responsive row">
          <BootstrapTable
            deleteRow={false}
            key={ticketList}
            data={ticketList}
            search={false}
            pagination={true}
            options={options}
            striped
            hover
            condensed
            scrollTop={"Bottom"}
            remote={true}
            fetchInfo={{ dataTotalSize: totalCount }}
            sort={true}
            trStyle={rowStyleFormat}
          >
            <TableHeaderColumn
              hidden={true}
              tdAttr={{ "data-attr": "_id" }}
              dataField="patientId"
              dataSort={true}
              isKey={true}
              searchable={false}
            >
              Id
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "#" }}
              thStyle={{ width: "30px", textAlign: "center" }}
              tdStyle={{ width: "30px", textAlign: "center" }}
              dataField="sn"
              dataFormat={srNo}
            >
              S.No.
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Id" }}
              thStyle={{ width: "80px", textAlign: "center" }}
              tdStyle={{ width: "80px", textAlign: "center" }}
              dataField="ticketId"
            >
              Ticket No.
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Name" }}
              thStyle={{ width: "150px", textAlign: "center" }}
              tdStyle={{ width: "150px", textAlign: "center" }}
              dataFormat={useraction}
              dataField="title"
            >
              Title
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Id" }}
              thStyle={{ width: "80px", textAlign: "center" }}
              tdStyle={{ width: "80px", textAlign: "center" }}
              dataField="clinicNumber"
            >
              Clinic ID
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Id" }}
              thStyle={{ width: "80px", textAlign: "center" }}
              tdStyle={{ width: "80px", textAlign: "center" }}
              dataField="clinicName"
            >
              Clinic Name
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Id" }}
              thStyle={{ width: "50px", textAlign: "center" }}
              tdStyle={{ width: "50px", textAlign: "center" }}
              dataField="city"
            >
              City
            </TableHeaderColumn>

            <TableHeaderColumn
              thStyle={{ width: "50px", textAlign: "center" }}
              tdStyle={{ width: "50px", textAlign: "center" }}
              tdAttr={{ "data-attr": "status" }}
              dataFormat={_setStatusColor}
              dataField="status"
            >
              Status
            </TableHeaderColumn>

            <TableHeaderColumn
              thStyle={{ width: "80px", textAlign: "center" }}
              tdStyle={{ width: "80px", textAlign: "center" }}
              tdAttr={{ "data-attr": "createdAt" }}
              dataFormat={appDate}
              dataField="createdAt"
            >
              Create Date
            </TableHeaderColumn>

            <TableHeaderColumn
              tdAttr={{ "data-attr": "Created By" }}
              thStyle={{ width: "80px", textAlign: "center" }}
              tdStyle={{ width: "80px", textAlign: "center" }}
              dataField="authorName"
            >
              Created By
            </TableHeaderColumn>

            <TableHeaderColumn
              thStyle={{ width: "50px", textAlign: "center" }}
              tdStyle={{ width: "50px", textAlign: "center" }}
              tdAttr={{ "data-attr": "Patient ID," }}
              dataField=""
              dataFormat={Action}
            >
              Action
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Tickets);

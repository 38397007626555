/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
//import logo from 'Front/assets/img/practice_aid_logo.png';
import logofooter from "Front/assets/img/practice_aid_logo_white.png";
import erisLogo from 'Front/assets/img/eris.png';
import { Link } from "react-router-dom";
class HomeFooter extends Component {
  render() {
    return (
      //  <footer className={"footer" + (this.props.transparent !== undefined ? " footer-transparent" : "")}>

      <section className="footer_section">
        <div
          className={
            "container" + (this.props.fluid !== undefined ? "-fluid" : "")
          }
        >
          <Grid>
            <Row className="show-grid">
              <Link to="/">
                <img src={logofooter} alt="Logo" />
              </Link>
            </Row>
            {/* 
            <Row className="show-grid">
              <Col md={12} lg={12} className="show-grid addres-t">
                <p style={{ marginBottom: '10px ' }}>Supported by: Eris Life science Pvt. Ltd.</p>
                <p>
                  <img src={erisLogo} alt="Eris" width="100" />
                </p>
              </Col>
            </Row> */}

            <Row>
              <Col md={4} lg={4}>
                <h6 className="show-grid">About PracticeAid!</h6>
                <p>
                  "PracticeAid is a group of professionals with collective
                  expertise in medical and dental science, hospital
                  administration and software development. These experts have
                  come together to work on path-breaking solutions in Healthcare
                  IT and deliver tangible value."
                </p>
                <p style={{ marginBottom: '10px ', marginTop: '15px' }}>Supported by: Eris Life science Pvt. Ltd.</p>
                <p>
                  <img src={erisLogo} alt="Eris" width="100" />
                </p>
              </Col>

              <Col md={4} lg={4} className="footer-align">
                <h6 className="show-grid">Important Links</h6>
                <p>
                  <Link to="/">Home</Link>
                </p>
                {/* <p>
                  <Link to="/our_product">Our Products</Link>
                </p> */}
                <p>
                  <Link to="/about">About Us</Link>
                </p>
                <p>
                  <Link to="/contact">Contact Us</Link>
                </p>
              </Col>

              <Col md={4} lg={4} className="get-in-touch">
                <h6 className="show-grid">Get in Touch</h6>
                <h5>Practiceaid</h5>
                <p className="call">
                  <span style={{ marginTop: '5px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10"></path>
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6"></path>
                    </svg>
                  </span>
                  Scheme No 54, Near Bombay hospital, Indore, Madhya Pradesh 452010
                </p>
                {/* <p className="call">
                  <a href="callto:7999245300">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </span>

                    +91-9644-433-305</a>
                </p> */}
                <p className="call">
                  <a href="https://api.whatsapp.com/send?phone=919644433305" target="_blank">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                    </span>
                    +91-9644-433-305
                  </a>
                </p>
                <p>
                  We are absolutely commited to your satisfaction. Stay
                  connected while on the go from anywhere with push mail.
                </p>
              </Col>
            </Row>
          </Grid>
        </div>
        <div className=" bg-footer">
          <div className="container">
            <Col md={6} lg={6}>
              <p className="copyright">
                &copy; {1900 + new Date().getYear()}
                <a
                  href="http://practice-aid.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  PracticeAid
                </a>
                , Developed by
                <a
                  href="http://synergytop.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="SynergyTop"
                >
                  {" "}
                  SynergyTop
                </a>
              </p>
            </Col>

            <Col md={6} lg={6}>
              <p className="copyright  spc-align">
                <Link to="/privacy-policy">Privacy Policy</Link> |
                <Link to="/terms-condition">Terms and Conditions</Link>
                {/* <a
                  href="http://practice-aid.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQs
                </a> */}
              </p>
            </Col>
          </div>
        </div>
      </section>

      //  </footer>
    );
  }
}
export default HomeFooter;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import {
    Row, Col, Modal, Table, ControlLabel, FormGroup, FormControl, Input
} from 'react-bootstrap';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/master';
import moment from 'moment';
import { appConstants } from 'Front/_constants/app.constants.js';
import ViewBox from '../../components/ViewBox';
import { confirmAlert } from 'react-confirm-alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReportTitle from './ReportTitle';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AnchorComponent from '../../components/common/AnchorComponent.js'
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
const ReportsPatientALL = ({ hideButton = false, modelStatus = false, funModelStatus }) => {

    const [ReportsModel, setReportsModel] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [ViewBoxData, setViewBoxData] = useState({ show: false, type: "image", path: "" });
    const [filterData, setFilterData] = useState({ search: '', hours: 72 });
    const [page] = useState(1);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1, totalRow: 0, sizePerPage: 10 });
    const [alertBox, setAlert] = useState(null);
    const notificationSystem = useRef(null)

    const getReportListAction = async () => {

        let sentData = { ...filterData, page: paginationInfo.currentPage - 1, limit: paginationInfo.sizePerPage };
        let resp = await API.getAllReportList(sentData);
        localStorage.setItem('report_count', resp.data.unreadCount);

        if (resp.status === 200) {

            if (reportList.length === 0) {
                setPaginationInfo({ ...paginationInfo, totalRow: resp.data.data.count });
            }
            setReportList(resp.data.data);
        }

    }

    useEffect(() => {

        if (ReportsModel === true) {

            getReportListAction();
        } else {
            setReportList([]);
            setFilterData({ search: '', hours: 72 })
            setPaginationInfo({ currentPage: 1, totalRow: 0, sizePerPage: 10 })
            if (modelStatus)
                funModelStatus();
        }
    }, [ReportsModel]);

    useEffect(() => {
        if (ReportsModel === true) {
            getReportListAction();
        }

    }, [paginationInfo.currentPage, paginationInfo.sizePerPage]);


    useEffect(() => {
        setReportsModel(modelStatus)
    }, [modelStatus]);


    const getFileIcon = (data, report) => {
        let icon = '';
        switch (data.fileType) {
            case 'jpg' || 'png' || 'jpeg':
                icon = `fa-image`;
                break;
            case 'mp4' || 'avi' || 'flv' || 'wmv':
                icon = 'fa-film';
                break;
            case 'pdf':
                icon = 'fa-file-pdf-o';
                break;
            default:
                icon = 'fa-file-o';
                break;
        }
        let color = (data.read) ? 'green' : 'red';

        return <>
            {data.fileType === "pdf" ? <a className='cursor-pointer-link' target={"_blank"} onClick={e => setReadFile(data, report._id)} style={{ fontSize: 20, padding: 2, color: color }} href={`${appConstants.s3UploadUrl + data.fileName}`} title="click"><i className={` fa ${icon}`} ></i></a> :
                <span className='cursor-pointer-link' onClick={e => setReadFile(data, report._id)} style={{ fontSize: 20, padding: 2, color: color }} href={`${appConstants.s3UploadUrl + data.fileName}`} title="click" target='_blank'><i className={` fa ${icon}`} ></i></span>}
        </>
        // <span className='cursor-pointer-link' onClick={e=>setReadFile(data,report._id)} style={{fontSize:20,padding:2, color:color}} href={`${appConstants.s3UploadUrl+data.fileName}`} title="click" target='_blank'><i className={` fa ${icon}`} ></i></span>;
    };

    const setReadFile = async (file, reportId, path = '') => {


        let res = await API.setReadFile({ fileId: file._id, reportId: reportId, });
        let filtered = [];

        filtered = reportList.data.filter(function (el) { return el._id != reportId; });

        let { data } = reportList;
        const objIndex = data.findIndex(obj => obj._id === reportId);
        let filId = data[objIndex]['files'].findIndex(obj => obj._id === file._id);
        data[objIndex]['files'][filId]['read'] = true;
        setReportList({ data: data });
        setViewBoxData({ show: true, type: "image", title: "Report", path: file.fileName });
        if (file.fileType != "pdf") {
            let tempData = { type: file.fileType, title: "Report", path: file.fileName }
            const url = `/view-box`;
            var newWindow = window.open(url);
            newWindow.params = tempData;

        }

    };
    const handlerChange = async (e) => {

        let data = { ...filterData };
        if (e.target.name === 'hours') {
            data[e.target.name] = e.target.value === "" ? "" : parseInt(e.target.value);
        } else {
            data[e.target.name] = e.target.value;
        }

        setFilterData(data);
        if (e.target.name === "hours")
            data = { ...data, page: 0, limit: 10 }
        let resp = await API.getAllReportList(data);
        localStorage.setItem('report_count', resp.data.unreadCount);

        if (resp.status === 200) {
            if (e.target.name === "hours")
                setPaginationInfo({ currentPage: 1, totalRow: resp.data.data.count, sizePerPage: 10 })
            else setPaginationInfo({ ...paginationInfo, totalRow: resp.data.data.count });

            setReportList(resp.data.data);
        }

    }

    const hideViewBox = () => {
        setViewBoxData({ show: false, type: "image", path: "" });
    };

    const DeleteReport = async (id) => {
        let resp = await API.deleteReport({ reportId: id });
        if (resp.status === 200) {
            successAlert('Successfully deleted.');
            getReportListAction();

        }

    }

    const DeleteReportConfirm = (id) => {

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteReport(id)
                },
                {
                    label: 'No',
                    //onClick: () => this.resetFormData()
                }
            ]
        })
    };

    const successAlert = (msg) => {

        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
        // setAlert(
        //     <SweetAlert
        //         success
        //         style={{ display: "block", marginTop: "-100px", "z-index": 500 }}
        //         title="Success"
        //         onConfirm={() => hideAlert()}
        //         confirmBtnBsStyle="info"
        //     >
        //         {msg}
        //     </SweetAlert>
        // );

        // let _this = this;
        // setTimeout(function () {
        //     hideAlert();
        // }, 3000);

    }


    const hideAlert = () => {
        setAlert(null);
    }



    const onPageChange = (page, sizePerPage) => {
        setPaginationInfo({ ...paginationInfo, currentPage: page, sizePerPage: sizePerPage });
    }


    const patientNoFun = (cell, row) => {
        return row.patient.patientNo;
    }

    const fullNameFun = (cell, row) => {
        return row.patient.firstName + ' ' + row.patient.lastName;
    }

    const titleFun = (cell, row) => {
        return <ReportTitle item={row} getReport={getReportListAction} />;
    }

    const createdAtFun = (cell, row) => {
        return moment(row.createdAt).format('D-MMM-YYYY');
    }

    const viewFun = (cell, row) => {
        if (row && row.files.length > 0) {
            return <>   {row.files.map((d, i) => {
                return getFileIcon(d, row);
            })}
            </>
        }

    }

    const deleteFun = (cell, row) => {
        return <a className="inactive-color" onClick={e => DeleteReportConfirm(row._id)}>Delete</a>
    }

    const options = {
        clearSearch: true,
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
        paginationShowsTotal: true,//paginationInfo.totalRow,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: paginationInfo.sizePerPage,
        onPageChange: onPageChange,
        page: paginationInfo.currentPage,
    };


    return (
        <span>
            {hideButton === false &&
                <button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e => { setReportsModel(true) }}>Reports</button>
            }
            {/* {ViewBoxData && ViewBoxData.show &&
                <ViewBox data={ViewBoxData} hideViewBox={e => hideViewBox()} />
            } */}
            {alertBox}
            <div>
                <NotificationSystem ref={notificationSystem} style={style} />
            </div>
            <Modal show={ReportsModel} onHide={() => setReportsModel(false)} dialogClassName="modal-lg">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">Shared Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                    <Row>
                        <Col md={3}>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel  >Filter by Days</ControlLabel>
                                <FormControl componentClass="select" placeholder="select" name="hours" onChange={e => handlerChange(e)} value={filterData.hours}>
                                    <option value="24">One Day</option>
                                    <option value="72">3 Days</option>
                                    <option value="168">7 Days</option>
                                    <option value="360">15 Days</option>
                                    <option value="720">30 Days</option>
                                    <option value="">All</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel  >Filter by Patient ID</ControlLabel>
                                <FormControl type="text" autocomplete="off" name="patientId" placeholder="Patient ID" onChange={e => handlerChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel  >Filter by Patient First Name</ControlLabel>
                                <FormControl type="text" autocomplete="off" name="firstName" placeholder="First Name" onChange={e => handlerChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup controlId="formControlsSelect">
                                <ControlLabel  >Filter by Patient Last Name</ControlLabel>
                                <FormControl type="text" autocomplete="off" name="lastName" placeholder="Last Name" onChange={e => handlerChange(e)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="fresh-datatables">
                            <BootstrapTable
                                selectRow="{selectRowProp}"
                                deleteRow={false}
                                data={reportList.data}
                                pagination={true}
                                options={options}
                                striped
                                hover
                                condensed
                                scrollTop={'Bottom'}
                                remote={true}
                                fetchInfo={{ dataTotalSize: paginationInfo.totalRow }}
                            >
                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '3%' }} tdStyle={{ width: '3%' }} tdAttr={{ 'data-attr': 'glucoseDate' }} dataFormat={patientNoFun} >PATIENT ID </TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '3%' }} tdStyle={{ width: '3%' }} tdAttr={{ 'data-attr': 'glucoseDate' }} dataFormat={fullNameFun} >PATIENT NAME </TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '3%' }} tdStyle={{ width: '3%' }} tdAttr={{ 'data-attr': 'glucoseDate' }} dataFormat={titleFun} >Title </TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'glucoseValue' }} dataFormat={viewFun}  >VIEW </TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'suggestedDose' }} dataFormat={createdAtFun} >DATE</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'suggestedDose' }} dataFormat={deleteFun} >Action</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-fill btn btn-secondary pull-right" onClick={() => {
                        setReportsModel(false)
                        setPaginationInfo({ currentPage: 1, totalRow: 0, sizePerPage: 10 })
                    }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
}
export default withRouter(ReportsPatientALL);

import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { clinicActiveAction } from 'Admin/actions/clinic_active';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { appConstants } from '../../../Front/_constants/app.constants.js';
import SecurityUrlComponent from '../../components/Common/SecurityUrlComponent.js';

class clinicActiveList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clinicActive: [],
      isLoading: true,
      totalCount: 10,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      formData: {
        direction: 'asc',
        order: "name",
        offset: 0,
        limit: 10,
      }
    }


    this.onLogoImage = this.onLogoImage.bind(this);
    this.onClinicImage = this.onClinicImage.bind(this);
    this.onContactImage = this.onContactImage.bind(this);

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

  }

  componentDidMount() {
    this.props.clinicActiveAction(this.state.formData)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isClinicActiveList !== this.props.isClinicActiveList) {
      this.setState({
        clinicActive: nextProps.clinicActiveList?.data?.data,
        totalCount: nextProps.clinicActiveList.data.count

      });
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  onLogoImage(cell, row, enumObject, rowIndex) {
    if (row && row.logo_image)
      return (
        <SecurityUrlComponent
          className="activeImage"
          data={row.logo_image}
          type="img"
        />)
    //<img className="activeImage" src={appConstants.s3UploadUrl+row.logo_image} />)
  }

  onClinicImage(cell, row, enumObject, rowIndex) {
    if (row && row.background_image)
      return (
        <SecurityUrlComponent
          className="activeImage"
          data={row.background_image}
          type="img"
        />)
    //<img className="activeImage" src={appConstants.s3UploadUrl+row.background_image} />
  }

  onContactImage(cell, row, enumObject, rowIndex) {

    if (row && row.contact_image)
      return (
        <SecurityUrlComponent
          className="activeImage"
          data={row.contact_image}
          type="img"
        />)
    //<img className="activeImage" src={appConstants.s3UploadUrl+row.contact_image} />
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    data['direction'] = this.state.data.direction;
    data['order'] = this.state.data.order;
    data['offset'] = (page === -1) ? this.state.data.offset : page - 1;
    data['limit'] = sizePerPage;

    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    this.props.clinicActiveAction(data);

    if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['direction'] = this.state.formData.direction;
        data['order'] = this.state.formData.order;
        data['offset'] = this.state.formData.offset;
        data['limit'] = this.state.formData.limit;
        data['search'] = text;
        this.props.clinicActiveAction(data);
      }
    } else {
      setTimeout(() => {
        this.componentDidMount();
      }, 100);
    }
    this.setState({ searchText: text });
  }


  render() {

    const options = {
      afterDeleteRow: this.handleDeleteButtonClick,
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      noDataText: this._setTableOption(),
    };

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <div className="table-responsive clinicActive-c">
                      <div className='search-box-admin'>
                        <div className='search'>
                          <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                          <span className='input-group-btn'>
                            <Button onClick={(e) => { this.onSearchChange(e.target.value, "null") }}>Clear</Button>
                          </span>
                        </div>
                      </div>
                      <BootstrapTable deleteRow={false} data={this.state.clinicActive} multiColumnSearch={true} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                        <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '85px', textAlign: 'center' }} tdStyle={{ width: '85px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Id' }} dataField='clinicId' >Clinic Id</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '120px', textAlign: 'center' }} tdStyle={{ width: '120px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataField='name' >Clinic Name</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '120px', textAlign: 'center' }} tdStyle={{ width: '120px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataField='doctorName' >Doctor Name</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '150px', textAlign: 'center' }} tdStyle={{ width: '150px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Contact Details' }} dataField='short_name' >Contact Details</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '120px', textAlign: 'center' }} tdStyle={{ width: '120px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Allow For Mail' }} dataField='is_email_facility' >Allow For Mail</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Logo' }} dataField='' dataFormat={this.onLogoImage} >Logo</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '110px', textAlign: 'center' }} tdStyle={{ width: '110px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Image' }} dataField='' dataFormat={this.onClinicImage} >Clinic Image</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '130px', textAlign: 'center' }} tdStyle={{ width: '130px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Contact Image' }} dataField='' dataFormat={this.onContactImage} >Contact Image</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' >Status</TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {

    clinicActiveList: state.clinicActive.clinicActiveList,
    isClinicActiveList: state.clinicActive.isClinicActiveList,
    isClinicActiveListError: state.clinicActive.isClinicActiveListError,

  }
}
export default withRouter(connect(mapStateToProps, { clinicActiveAction })(clinicActiveList));
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';

import {
    Grid, Row, Col
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import mobile_green from '../../../assets/img/mobile-g.png'
import mobile_red from '../../../assets/img/mobile-r.png'
class ColorNotations extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {

        return (
            <div className="color-notations">
                {/* <h4 className="notation-title">Color Notations</h4> */}

                <div className="flex-box">
                    <Row>
                        <Col sm={6} xs={12}>
                            <div className="notation-card">
                                <div className="notation-header notations-title">Patient ID, Name and age section</div>
                                <div className="notation-list">
                                    <p><span className="notation notation-red"></span> New Patient: Color in S.No area Red</p>
                                    <p><span className="notation notation-green"></span> Follow up patient : Color in S.No area Green</p>
                                    <p><span className="notation notation-yellow"></span> Report Case: Color in S.No area Yellow</p>
                                    <p><span className="notation circle notation-black"></span> App user : Patient name in Black: App user</p>
                                    <p><span className="notation circle notation-blue"></span> Not App user: Patient name in Blue: Non app user</p>
                                    <p><span className="notation circle notation-red">1</span> No of Video shared by doctor, not yet shown by Educator (For Non App user)</p>
                                    <p><span className="notation circle notation-green">1</span> Video shared by doctor and shown to patient by Educator</p>
                                    <p><span className="notation notation-cream"></span> Patient made Out by Doctor: Back ground becomes Cream color</p>
                                    <p><span className="notation notation-black"></span> Patient Out by Educator: Back ground becomes Black color</p>
                                    <p><span className="notation circle notation-green"></span> Actual DOB: Age display in Green color</p>
                                    <p><span className="notation circle notation-red"></span> Calculated DOB: Age written direct in year, display in Red color</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="notation-card">
                                <div className="notation-header notations-title">Patient app related color notation</div>
                                <div className="notation-list">
                                    <p><span className="notation circle notation-green mobile-app"><img src={mobile_green} alt='no app' /></span> App user: User activated on PA App : Mobile icon Green</p>
                                    <p><span className="notation circle notation-red mobile-app"><img src={mobile_red} alt='no app' /></span> Not App user : :User does not have App: Mobile icon Red</p>
                                    <p><span className="notation circle notation-red">1</span> RED : Video / Document shared, not seen by patient</p>
                                    <p><span className="notation circle notation-green">1</span> GREEN: Video / Document shared and seen by patient</p>
                                    <p><span className="notation circle notation-red"><span class="fa fa-cutlery" aria-hidden="true"></span></span> RED: Diet chart shared but not seen by patient</p>
                                    <p><span className="notation circle notation-green"><span class="fa fa-cutlery" aria-hidden="true"></span></span> GREEN : Diet chart shared and seen by patient</p>
                                    <p><span className="notation circle notation-green">8</span> Green: Insulin Calculator</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="notation-card">
                                <div className="notation-header notations-title">Educator task section color notation</div>
                                <div className="notation-list">
                                    <p><span className="notation circle notation-red"></span> Text color Red if Educator has not shown diet chart to patient (Important in Non App user)</p>
                                    <p><span className="notation circle notation-blue"></span> Text color Blue Educator has discussed diet chart with patient</p>
                                    <p><span className="notation circle notation-green"></span> Text color Green Educator has printed diet chart for patient</p>
                                    <p><span className="notation circle notation-pink"></span> Text color Pink Educator has given different chart to patient (Against Doctor advise)</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="notation-card">
                                <div className="notation-header notations-title">Report area on patient name screen</div>
                                <div className="notation-list">
                                    <p><span className="notation circle notation-green"></span> Green: Report shared by patient and seen by doctor</p>
                                    <p><span className="notation circle notation-red"></span> Red: Report shared by patient, not yet seen by doctor</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

    }
}
export default withRouter(connect(mapStateToProps, {})(ColorNotations));

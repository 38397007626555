/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
//import loadingImg from 'Admin/assets/img/loading.gif';
import { addEducatorAction } from 'Admin/actions/educator';
import { clinicListAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import ClinicHeader from '../ClinicHeader/ClinicHeader';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    message: 'required'
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddEducator extends Component {

    constructor(props) {
        super(props);
        var clinicid = "";
        var clinicName = "";
        var dropdownDisable = false;
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.clinic_id) {
            clinicid = this.props.location.state.clinic_id;
            clinicName = this.props.location.state.clinicName;
        }
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.clinicDetail && !this.props.location.state.clinicDetail.status) {
            dropdownDisable = true;
        }
        this.state = {
            clinicList: [],
            cardHidden: true,
            isLoading: false,
            formArr: [],
            formData: {
                message: "",
                description: "",
                clinicId: clinicid,
                clinicName: clinicName,
                status: "active",
            },
            isLogin: true,
            showProcessing: false,
            dropdownDisable: dropdownDisable,
            msgError: '',
            DesError: ''
        }
    }

    componentDidMount() {
        let data = this.state.formData;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        this.props.ClinicSimpleListAction();
    }

    componentWillReceiveProps(nextProps) {

        // if (nextProps.isClinicList !== this.props.isClinicList) {
        //     this.setState({
        //         clinicList: nextProps.ClinicList.data.data
        //     });
        // }

        if (nextProps.isAddEducator !== this.props.isAddEducator && this.props.isAddEducator === false) {

            //this.props.handleClick('success', nextProps.AddEducator.status);
            this.props.handleClick('success', 'Educator added successfully');

            // this.props.history.push('/admin/educator-list')

            if (this.props.location && this.props.location.state.clinicDetail) {
                this.props.history.push(`/admin/educator-list`, this.props.location.state.clinicDetail);
            } else {
                this.props.history.push(`/admin/educator-list`);
            }
        }


        if (nextProps.isAddEducatorError !== this.props.isAddEducatorError) {
            if (nextProps.AddEducator.errors) {

                nextProps.AddEducator.errors.map((key, i) => {
                    this.setState({ msgError: key.msg })
                });
            }
        }

        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }
    }

    addEducator(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        if (this.allValidate(false)) {
            this.props.addEducatorAction(this.state.formData);
        }
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isButtonDisabled: false });
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleClinicChange(event) {

        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinicId'] = event.target.value
        formData['clinicName'] = event.target[index].text
        this.setState({ formData: formData });

    }

    handleChange(e) {
        e.preventDefault();

        if (e.target.value.length === 100) {
            if (e.target.name === 'message') {
                this.setState({ msgError: 'This textarea has a character limit of 100.' })
            } else if (e.target.name === 'description') {
                this.setState({ DesError: 'This textarea has a character limit of 100.' })
            }
        } else {
            this.setState({ msgError: '', DesError: '' })
        }
        let formData = this.state.formData;
        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.dropdownDisable &&
                                <ClinicHeader componentData={{ clinicId: this.props.location.state.clinicDetail._id }} />
                            }
                            <Card
                                title={
                                    <legend className="line-removes">
                                        {!this.state.dropdownDisable &&
                                            <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                        }
                                    </legend>
                                }
                                content={
                                    <Form horizontal>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Message <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="message" maxLength="100" placeholder="Message" onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">{this.state.msgError}{this.state.formArr.message && validation.errors.first('message')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" maxLength="100" bsClass="form-control" name="description" placeholder="Description" onChange={e => { this.handleChange(e); }} defaultValue="" />
                                                <span className="errorMsg">{this.state.DesError}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Status
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="status" value={this.state.value} onChange={(event) => this.handleChange(event)}>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>

                                            </Col>
                                        </FormGroup>
                                        {!this.state.dropdownDisable &&
                                            <div>


                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                        Clinic
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormControl componentClass="select" name="clinicId" disabled={this.state.dropdownDisable} value={this.state.formData.clinicId} onChange={e => { this.handleChange(e); this.handleClinicChange(e); }}>
                                                            <option value="">All clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}({item.clinicId})</option>
                                                            })}
                                                        </FormControl>
                                                    </Col>
                                                </FormGroup>

                                            </div>
                                        }
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.addEducator.bind(this)}
                                                    disabled={isLoading || this.props.disabled}
                                                    className="btn-fill btn-wd btn btn-info">
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Submit
                                                </button> &nbsp;
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        AddEducator: state.educator.AddEducator,
        isAddEducator: state.educator.isAddEducator,
        isAddEducatorError: state.educator.isAddEducatorError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError

    }
}
export default withRouter(connect(mapStateToProps, { addEducatorAction, clinicListAction, ClinicSimpleListAction })(AddEducator));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/attachment'
/* Add attachment  list and Add form */

export const addAttachmentRequest = () => ({
    type: actionTypes.GET_ADD_ATTACHMENT_REQUEST
})
export const addAttachmentSuccess = (response) => ({
    type: actionTypes.GET_ADD_ATTACHMENT_SUCCESS,
    payload: {
        response,
    }
})
export const addAttachmentError = (error) => ({
    type: actionTypes.GET_ADD_ATTACHMENT_ERROR,
    payload: {
        error,
    }
})

export const addAttachmentAction = (data) => {
    return dispatch => {
        dispatch(addAttachmentRequest())
        return API.addAttachment(data)
            .then(response => {

                dispatch(addAttachmentSuccess(response.data))
            })
            .catch(error => {
                dispatch(addAttachmentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update attachment list and Add form */
export const updateAttachmentRequest = () => ({
    type: actionTypes.GET_UPDATE_ATTACHMENT_REQUEST
})
export const updateAttachmentSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_ATTACHMENT_SUCCESS,
    payload: {
        response,
    }
})
export const updateAttachmentError = (error) => ({
    type: actionTypes.GET_UPDATE_ATTACHMENT_ERROR,
    payload: {
        error,
    }
})
export const updateAttachmentAction = (data) => {
    return dispatch => {
        dispatch(updateAttachmentRequest())
        return API.updateAttachment(data)
            .then(response => {
                dispatch(updateAttachmentSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateAttachmentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link list  */
export const AttachmentListRequest = () => ({
    type: actionTypes.GET_ATTACHMENT_LIST_REQUEST
})
export const AttachmentListSuccess = (response) => ({
    type: actionTypes.GET_ATTACHMENT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const AttachmentListError = (error) => ({
    type: actionTypes.GET_ATTACHMENT_LIST_ERROR,
    payload: {
        error
    }
})
export const attachmentListAction = (data, limitStatus) => {
    return dispatch => {
        dispatch(AttachmentListRequest());
        let FormData = '';
        if (data) {
            FormData = data;
        } else {
            FormData = {
                limit: 10,
                offset: 0,
                order: "name",
                direction: "asc",
            }

        }
        if (limitStatus) {
            FormData.limitStatus = limitStatus;
        }

        return API.attachmentList(FormData)
            .then(response => {
                dispatch(AttachmentListSuccess(response.data))
            })
            .catch(error => {
                dispatch(AttachmentListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link Status Change  */
export const AttachmentStatusRequest = () => ({
    type: actionTypes.GET_ATTACHMENT_CHANGE_STATUS_REQUEST
})
export const AttachmentChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_ATTACHMENT_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const AttachmentChangeStatusError = (error) => ({
    type: actionTypes.GET_ATTACHMENT_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const attachmentStatusAction = (data) => {

    return dispatch => {
        dispatch(AttachmentStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeAttachmentStatus(FormData)
            .then(response => {

                dispatch(AttachmentChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(AttachmentChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


export const AttachmentAutoSelectStatusRequest = () => ({
    type: actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_REQUEST
})
export const AttachmentAutoSelectStatusSuccess = (response) => ({
    type: actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const AttachmentAutoSelectStatusError = (error) => ({
    type: actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_ERROR,
    payload: {
        error
    }
})
export const attachmentAutoSelectStatusAction = (data) => {

    return dispatch => {
        dispatch(AttachmentAutoSelectStatusRequest())
        if (data.show_image) {
            data.show_image = false;
        } else {
            data.show_image = true;
        }
        const FormData = {
            id: data._id,
            show_image: data.show_image
        }
        return API.changeAttachmentAutoSelectStatus(FormData)
            .then(response => {

                dispatch(AttachmentAutoSelectStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(AttachmentAutoSelectStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


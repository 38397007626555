/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],
    message: '',
    loginCheck: false,
    isLogin: false,
    isLoginError: false,

    userDetail: false,
    isUserDetail: false,
    isUserDetailError: false,

    forgotPWD: false,
    isForgotPWD: false,
    isForgotPWDError: false,

    resetPWD: false,
    isresetPWD: false,
    isresetPWDError: false,
}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_SIGNIN_USER_REQUEST:
            return {
                ...state,
                message: '',
                isLogin: false,
                isLoginError: false,
            }
        case actionTypes.GET_SIGNIN_USER_SUCCESS:
            return {
                ...state,
                response: action.payload.response,
                message: 'Logged in successfully',
                LoginData: action.payload.response,
                isLogin: true,
                isLoginError: false,
            }
        case actionTypes.GET_SIGNIN_USER_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isLogin: false,
                isLoginError: true
            }


        // user detail

        case actionTypes.GET_USER_DETAIL_REQUEST:
            return {
                ...state,
                message: '',
                isUserDetail: false,
                isUserDetailError: false,
            }
        case actionTypes.GET_USER_DETAIL_SUCCESS:
            return {
                ...state,
                response: action.payload.response,
                message: 'Update successfully',
                UserDetailData: action.payload.response,
                isUserDetail: true,
                isUserDetailError: false,
            }
        case actionTypes.GET_USER_DETAIL_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUserDetail: false,
                isUserDetailError: true
            }


        //Forgot password
        case actionTypes.GET_FORGOT_PASSWARD_REQUEST:
            return {
                ...state,
                message: '',
                isForgotPWD: false,
                isForgotPWDError: false,
            }
        case actionTypes.GET_FORGOT_PASSWARD_SUCCESS:
            return {
                ...state,
                response: action.payload.response,
                message: 'Email has been sent successfully on this email id',
                forgotPWD: action.payload.response,
                isForgotPWD: true,
                isForgotPWDError: false,
            }
        case actionTypes.GET_FORGOT_PASSWARD_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isForgotPWD: false,
                isForgotPWDError: true
            }

        //Reset password
        case actionTypes.GET_RESET_PASSWARD_REQUEST:
            return {
                ...state,
                message: '',
                resetPWD: false,
                isresetPWD: false,
                isresetPWDError: false,
            }
        case actionTypes.GET_RESET_PASSWARD_SUCCESS:
            return {
                ...state,
                response: action.payload.response,
                message: 'Password hasbeen reset successfully.',
                resetPWD: action.payload.response,
                isresetPWD: true,
                isresetPWDError: false,
            }
        case actionTypes.GET_RESET_PASSWARD_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                resetPWD: false,
                isresetPWD: false,
                isresetPWDError: true
            }


        default:
            return state
    }
}
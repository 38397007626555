/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { updateEducatorAction } from 'Admin/actions/educator';
import { clinicListAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import { appConstants } from 'Front/_constants/app.constants.js';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    message: 'required'
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateEducator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clinicList: [],
            cardHidden: true,
            formArr: [],
            formData: {
                message: "",
                description: "",
                clinicId: "",
                clinicName: "",
                status: "",
                id: this.props.location.state.row._id,
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
            clinicHeaderView: this.props && this.props.location && this.props.location.clinicDetail && this.props.location.clinicDetail._id ? this.props.location.clinicDetail._id : "",
            msgError: '',
            DesError: ''
        }

    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        this.props.ClinicSimpleListAction();

        let clinid_id = "";
        let clinicNm = "";

        if (this.props.location.state.row.clinic && this.props.location.state.row.clinic[0]) {
            clinid_id = this.props.location.state.row.clinic[0].clinicId;
            clinicNm = this.props.location.state.row.clinic[0].clinicName;
        } else if (this.props.location.state.row.clinic && this.props.location.state.row.clinic.clinicId) {
            clinid_id = this.props.location.state.row.clinic.clinicId;
            clinicNm = this.props.location.state.row.clinic.clinicName;
        }

        let formData = {
            message: this.props.location.state.row.message,
            description: this.props.location.state.row.description,
            clinicId: clinid_id,
            clinicName: clinicNm,
            status: this.props.location.state.row.status,
            id: this.props.location.state.row._id,
        };

        this.setState({ formData });
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.isClinicList !== this.props.isClinicList) {
        //     this.setState({
        //         clinicList: nextProps.ClinicList.data.data
        //     });
        // }

        if (nextProps.isUpdateEducatorError !== this.props.isUpdateEducatorError && this.props.isUpdateEducatorError === false) {

            if (nextProps.UpdateEducator.errors) {
                nextProps.UpdateEducator.errors.map((key, i) => {

                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }

        }

        if (nextProps.isUpdateEducator !== this.props.isUpdateEducator && this.props.isUpdateEducator === false) {


            //if(this.state.formData.status == "active"){
            let params = { clinic_id: this.state.formData.clinicId, type: "educator" };
            appConstants.socket.emit('master-update', params);
            // }
            //this.props.handleClick('success', nextProps.UpdateEducator.msg)
            this.props.handleClick('success', 'Educator updated successfully')
            if (this.props.location && this.props.location.clinicDetail) {
                this.props.history.push(`/admin/educator-list`, this.props.location.clinicDetail);
            } else if (this.props.location && this.props.location.state && this.props.location.state.pageName) {
                this.props.history.push(`/admin/clinic-request/` + this.state.formData.id, this.props.location.state.pageName);
            }
            else {
                this.props.history.push('/admin/educator-list')
            }
        }

        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }
    }


    addEducator(evt) {
        evt.preventDefault();

        if (this.allValidate(false)) {
            this.props.updateEducatorAction(this.state.formData);
        }
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleClinicChange(event) {
        let formData = this.state.formData;
        formData["clinicName"] = event.target[event.target.selectedIndex].text;
        formData["clinicId"] = event.target.value;
        this.setState({ formData: formData });
    }

    handleChange(e) {
        e.preventDefault();

        if (e.target.value.length === 100) {
            if (e.target.name === 'message') {
                this.setState({ msgError: 'This textarea has a character limit of 100.' })
            } else if (e.target.name === 'description') {
                this.setState({ DesError: 'This textarea has a character limit of 100.' })
            }
        } else {
            this.setState({ msgError: '', DesError: '' })
        }

        let formData = this.state.formData;
        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.clinicHeaderView &&
                                <ClinicHeader componentData={{ clinicId: this.props.location.clinicDetail._id }} />
                            }
                            <Card
                                title={
                                    <legend className="line-removes">
                                        {/* <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button> */}
                                    </legend>
                                }
                                content={
                                    <Form horizontal>
                                        {!this.state.clinicHeaderView &&
                                            <div>


                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                        Clinic:
                                                    </Col>
                                                    <Col sm={6}>
                                                        <FormControl componentClass="select" name="clinicId" value={this.state.formData.clinicId} disabled={this.props.location.state.row && this.props.location.state.row.addedType && this.props.location.state.row.addedType != 'admin' ? true : false} onChange={e => { this.handleChange(e); this.handleClinicChange(e); }}>
                                                            <option value="">All Clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}({item.clinicId})</option>
                                                            })}
                                                        </FormControl>
                                                    </Col>
                                                </FormGroup>
                                            </div>
                                        }
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Message <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="message" maxLength="100" disabled={this.state.clinicHeaderView} value={this.state.formData.message} onChange={e => { this.handleChange(e); }} placeholder="Message" />
                                                <span className="errorMsg">{this.state.msgError}{this.state.formArr.message && validation.errors.first('message')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" maxLength="100" disabled={this.state.clinicHeaderView} bsClass="form-control" name="description" placeholder="Description" value={this.state.formData.description} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">{this.state.DesError}</span>
                                            </Col>
                                        </FormGroup>
                                        {/*(this.props.location.clinicDetail || this.props.location.state.type ==='all') && */}
                                        {this.props.location.state.type === 'all' || this.props.location.state.type === 'clinic' &&
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                    Status
                                                </Col>
                                                <Col sm={6}>
                                                    <select className="form-control" name="status" value={this.state.formData.status} onChange={(event) => this.handleChange(event)}>
                                                        <option value="requested">Requested</option>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    {this.state.statusError}
                                                </Col>
                                            </FormGroup>
                                        }

                                        {/*!this.props.location.clinicDetail || this.props.location.state.type !=='all' && */}
                                        {(this.props.location.state.type === 'admin') &&
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                    Status
                                                </Col>
                                                <Col sm={6}>
                                                    <select className="form-control" name="status" value={this.state.formData.status} onChange={(event) => this.handleChange(event)}>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </select>
                                                    {this.state.statusError}
                                                </Col>
                                            </FormGroup>
                                        }

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.addEducator.bind(this)} className="btn-fill btn-wd btn btn-primary">Submit</button>&nbsp;
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        UpdateEducator: state.educator.UpdateEducator,
        isUpdateEducator: state.educator.isUpdateEducator,
        isUpdateEducatorError: state.educator.isUpdateEducatorError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError
    }
}
export default withRouter(connect(mapStateToProps, { updateEducatorAction, clinicListAction, ClinicSimpleListAction })(UpdateEducator));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as API from "Front/api/home";

const Calendar = ({ selectedDate, handleChange, data = {}, errorColor }) => {
  const [selectedDays, setSelectedDays] = useState(selectedDate);
  const [weekOff, setWeekOff] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const handleOpen = () => {
    //setShow(true);
  };

  const getHolidayList = async () => {
    const resp = await API.holidayList();
    if (resp.status === 200) {
      setHolidayList(resp.data.list ? resp.data.list : []);
      setWeekOff(resp.data.week_off ? resp.data.week_off : []);
    }
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  const renderDay = (props, currentDate) => {
    let list = holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;

      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");

          let newDate = moment(currentDate).format("YYYY-MM-DD");

          if (eventDate === newDate) {
            exit = 1;
            props["title"] = list[x].message;
            if (list[x].type === "leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = " weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate === todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass ? extClass : " red-bg";
      } else if (currentDate2 === 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = weekOff; //JSON.parse(localStorage.getItem('is_week_off'));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(currentDate).day()) {
          extClass = " weekoffPink-bg";
        }
      }
    }
    props["className"] = props["className"] + extClass;

    return <td {...props}>{currentDate.date()}</td>;
  };

  const valid = function (current) {
    if (data && data.valid && data.valid === true) {
      let currentDate = current._d;

      let newDate = moment(currentDate).format("YYYY-MM-DD");

      let list = holidayList;
      let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));

      for (let x in list) {
        let dates = list[x].calendarDate;

        if (dates[0]) {
          for (let j in dates) {
            let eventDate = moment(dates[j]).format("YYYY-MM-DD");

            if (eventDate == newDate) {
              return false;
            }
          }
        }
      }
      //clinic leave admin side
      if (holiday_date.length > 0) {
        for (let p in holiday_date) {
          let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
          if (holidayDate === newDate) {
            return false;
          }
        }
      }
      //end clinic leave admin side

      let weekDays = weekOff;

      if (weekDays.length > 0) {
        for (let i in weekDays) {
          if (weekDays[i].day == current.day()) {
            return false;
          }
          //   else {
          //     return true;
          //   }
        }
      }
    }
    if (data.backDateDisabled) {
      var yesterday = Datetime.moment().subtract(1, "day");
      return current.isAfter(yesterday);
    }

    return true;
    //console.log(current.day() !== 0 && current.day() !== 6);
    //return current.day() !== 0 && current.day() !== 6;
  };

  return (
    <div className="calnder">
      <Datetime
        timeFormat={false}
        inputProps={{ placeholder: "DD/MM/YYYY" }}
        dateFormat={"DD/MM/YYYY"}
        minDate={data.min ? new Date(data.min) : false}
        maxDate={new Date()}
        value={selectedDate ? new Date(selectedDate) : ""}
        renderDay={renderDay}
        onChange={(event) => handleChange(event)}
        className={
          (data.class ? data.class : "") +
          " cs-datepick " +
          (errorColor ? "error" : "")
        }
        isValidDate={valid}
        closeOnSelect={true}
      />
    </div>
  );
};
export default Calendar;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes' //--Anmol
import * as API from 'Admin/api/clinic'

export const clinicInvitationListAction = (data) => {
	return dispatch => {
		dispatch({ type: actionTypes.GET_CLINIC_INVITATION_REQUEST, payload: data })
		return API.clinicInvitationList(data)
			.then(response => {
				dispatch({ type: actionTypes.GET_CLINIC_INVITATION_SUCCESS, payload: response })
			})
			.catch(error => {

				dispatch({ type: actionTypes.GET_CLINIC_INVITATION_ERROR, payload: error })
			})
	}
}

export const addClinicInvitationAction = (data) => {
	return dispatch => {
		dispatch({ type: actionTypes.ADD_CLINIC_INVITATION_REQUEST, payload: data })
		return API.addClinicInvitation(data)
			.then(response => {
				dispatch({ type: actionTypes.ADD_CLINIC_INVITATION_SUCCESS, payload: response })
			})
			.catch(error => {

				dispatch({ type: actionTypes.ADD_CLINIC_INVITATION_ERROR, payload: error })
			})
	}
}

export const editClinicInvitationAction = (data) => {
	return dispatch => {
		dispatch({ type: actionTypes.EDIT_CLINIC_INVITATION_REQUEST, payload: data })
		return API.editClinicInvitation(data)
			.then(response => {
				dispatch({ type: actionTypes.EDIT_CLINIC_INVITATION_SUCCESS, payload: response })
			})
			.catch(error => {
				dispatch({ type: actionTypes.EDIT_CLINIC_INVITATION_ERROR, payload: error })
			})
	}
}

export const invitationSendMailAction = (data) => {
	return dispatch => {
		dispatch({ type: actionTypes.INVITATION_SENDMAIL_REQUEST, payload: data })
		return API.invitationSendMail(data)
			.then(response => {
				dispatch({ type: actionTypes.INVITATION_SENDMAIL_SUCCESS, payload: response })
			})
			.catch(error => {
				dispatch({ type: actionTypes.INVITATION_SENDMAIL_ERROR, payload: error })
			})
	}
}

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { addLowCalorieFoodAction } from 'Admin/actions/low_calorie_food';
import { languageListAction } from 'Admin/actions/language';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name_1: 'required',


};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddLowCalorieFood extends Component {

    constructor(props) {
        super(props);

        this.state = {
            languageList: [],
            cardHidden: true,
            isLoading: false,
            formArr: [],
            name_englishError: null,

            formData: {
                name: {},
                name_1: "",

            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }


    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.languageListAction(data);

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isLanguageList !== this.props.isLanguageList) {
            this.setState({ languageList: nextProps.LanguageList.data });
        }
        if (nextProps.isAddLowCalorieFoodError !== this.props.isAddLowCalorieFoodError) {
            if (nextProps.msg.errors) {
                nextProps.msg.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }
        if (nextProps.isAddLowCalorieFood !== this.props.isAddLowCalorieFood) {

            this.props.handleClick('success', nextProps.AddLowCalorieFood.msg)
            this.props.history.push('/admin/low-calorie-food')
        }
    }



    handleChange(e, language) {
        e.preventDefault();
        let field = this.state.formData;
        field.name[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }



    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addLowCalorieFood(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let field = this.state.formData;
            _this.setState({ showProcessing: true });
            _this.props.addLowCalorieFoodAction(field);
        }
        //validation.errors()
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {
        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;

        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Name  <span className="star">*</span>
                                            </Col>
                                            <Col sm={6} className="spcc">
                                                {this.state.languageList.map(function (lang) {
                                                    return <><label>{lang.name}</label><FormControl className="spc-in" type="text" name={'name_' + lang.lanId} id={'name_' + lang.lanId} placeholder={lang.name} onChange={(event) => { _this.handleChange(event, lang.lanId) }} /></>
                                                })}
                                                <span className="errorMsg">
                                                    {this.state.name_englishError}
                                                    {this.state.formArr.name_1 && validation.errors.first('name_1')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.addLowCalorieFood.bind(this)}
                                                    className="btn-fill btn-wd btn btn-primary"
                                                    disabled={isLoading || this.props.disabled}
                                                >
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Save
                                                </button>&nbsp;
                                            </Col>
                                        </FormGroup>


                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.lowCalorieFood.message,

        AddLowCalorieFood: state.lowCalorieFood.AddLowCalorieFood,
        isAddLowCalorieFood: state.lowCalorieFood.isAddLowCalorieFood,
        isAddLowCalorieFoodError: state.lowCalorieFood.isAddLowCalorieFoodError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { addLowCalorieFoodAction, languageListAction })(AddLowCalorieFood));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { clinicDetailAction } from 'Admin/actions/clinic';
//import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
//import Loading from 'react-loading';
import Common from 'Admin/components/Common/Common';

class ClinicHeader extends Component {

  constructor(props) {
    super(props);
    var isUpdateClinic = false;
    var isSubscription = false;
    var isUser = false;
    var isRequest = false;
    var isKnowledge = false;
    var isMessage = false;
    var isLink = false;
    const answer_array = this.props.location.pathname.split('/');

    //console.log( answer_array)
    if (answer_array[2] === 'update-clinic') {
      isUpdateClinic = true;
    } else if (answer_array[2] === 'add-clinic-subscription') {
      isSubscription = true;
    } else if (answer_array[2] === 'user-list') {
      isUser = true;
    } else if (answer_array[2] === 'add-user') {
      isUser = true;
    } else if (answer_array[2] === 'update-user') {
      isUser = true;
    } else if (answer_array[2] === 'knowledge-list') {
      isKnowledge = true;
    } else if (answer_array[2] === 'educator-list') {
      isMessage = true;
    } else if (answer_array[2] === 'link-list') {
      isLink = true;
    } else if (answer_array[2] === 'clinic-request') {
      isRequest = true;
    }

    this.state = {
      ClinicDetail: [],
      isLoading: true,
      userRow: null,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      //clinicId: this.props.componentData.clinicId,
      clinicId: localStorage.getItem('_id_clinic'),
      isUpdateClinic: isUpdateClinic,
      isSubscription: isSubscription,
      isUser: isUser,
      isRequest: isRequest,
      isKnowledge: isKnowledge,
      isMessage: isMessage,
      isLink: isLink

    }
  }



  componentDidMount() {
    let data = {
      id: this.state.clinicId,
    }
    this.props.clinicDetailAction(data)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isClinicDetail !== this.props.isClinicDetail) {

      this.setState({
        ClinicDetail: nextProps.ClinicDetail.data,
      });
    }
    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  clinicDetail() {
    if (this.state.ClinicDetail) {
      this.props.history.push(`/admin/update-clinic`, this.state.ClinicDetail);
    }

  }

  Subscription() {
    if (this.state.ClinicDetail) {
      this.props.history.push(`/admin/add-clinic-subscription/` + this.state.clinicId, this.state.ClinicDetail);
    }

  }

  // addCalender() {
  //   this.props.history.push(`/admin/add-clinic-calendar`,this.state.ClinicDetail);
  // }

  manageUser() {
    this.props.history.push(`/admin/user-list/` + this.state.clinicId, this.state.ClinicDetail);
  }

  manageRequest() {
    this.props.history.push(`/admin/clinic-request/` + this.state.clinicId, this.state.ClinicDetail);
  }

  knowledgeRequest() {
    this.props.history.push(`/admin/knowledge-list`, this.state.ClinicDetail);
  }

  EducatorMessageRequest() {
    this.props.history.push(`/admin/educator-list`, this.state.ClinicDetail);
  }

  clinicLinkRequest() {
    this.props.history.push(`/admin/link-list`, this.state.ClinicDetail);
  }



  render() {

    return (

      <Grid fluid>
        <Row>
          <div className="headCl">
            <Card
              content={
                <div className="fresh-datatables">
                  <Row>
                    <Col md={12} className="spc-CL">
                      <span className="request-box">
                        <span className="bold-cc"><strong> Name - </strong>
                          {this.state.ClinicDetail.name}
                        </span>
                        <span className="bold-cc"> <strong>ID - </strong>
                          {this.state.ClinicDetail.clinicId}
                        </span>
                        <span className="bold-cc"> <strong>Subscription End - </strong>

                          {this.state.ClinicDetail.subscription_end_date ?
                            Common.formatDate(this.state.ClinicDetail.subscription_end_date)
                            : ''}
                        </span>

                        <span className="bold-cc"> <strong>City - </strong>
                          {this.state.ClinicDetail.address && this.state.ClinicDetail.address.city}
                        </span>


                      </span>
                    </Col>

                    <Col md={12}>
                      <ButtonToolbar>
                        <Button className={this.state.isUpdateClinic ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.clinicDetail(e) }}>Details</Button>
                        <Button className={this.state.isSubscription ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.Subscription(e) }}><div>Subscription</div></Button>
                        {/* <Button bsStyle="info" onClick={(e) => { this.addCalender(e) }}>Set Calender</Button> */}
                        <Button className={this.state.isUser ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.manageUser(e) }}>Users</Button>
                        <Button className={this.state.isRequest ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.manageRequest(e) }}>Requests</Button>
                        <Button className={this.state.isKnowledge ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.knowledgeRequest(e) }}>Knowledge</Button>
                        <Button className={this.state.isMessage ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.EducatorMessageRequest(e) }}>Educator Message</Button>
                        <Button className={this.state.isLink ? 'acti-button' : "inacti-button"} variant="secondary" onClick={(e) => { this.clinicLinkRequest(e) }}>My Clinic</Button>
                      </ButtonToolbar>
                    </Col>

                  </Row>&nbsp;
                </div>
              }
            />
          </div>
        </Row>
      </Grid>

    );
  }
}

function mapStateToProps(state) {
  return {
    ClinicDetail: state.clinic.ClinicDetail,
    isClinicDetail: state.clinic.isClinicDetail,
    isClinicDetailError: state.clinic.isClinicDetailError,

  }

}
export default withRouter(connect(mapStateToProps, { clinicDetailAction })(ClinicHeader));

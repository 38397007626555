/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { postRequestWithToken, getRequestWithToken } from "./helper"

export const visitList = (data) => postRequestWithToken(data, 'visit-list');

export const patientRegistration = (data) => postRequestWithToken(data, 'patient-registration');

export const patientEdit = (data) => postRequestWithToken(data, 'patient-edit');

export const patientOut = (data) => postRequestWithToken(data, 'patient-out');

export const nextVisit = (data) => postRequestWithToken(data, 'next-visit');

export const educatorMsgList = (data) => postRequestWithToken(data, 'educator-message-list');

export const categoryList = (data) => postRequestWithToken(data, 'category-list');

export const knowledgeList = (data) => postRequestWithToken(data, 'knowledge-list');

export const printShareDocument = (data) => postRequestWithToken(data, 'add-documents');

export const taskList = (data) => postRequestWithToken(data, 'document-task-list');

export const changeStatus = (data) => postRequestWithToken(data, 'change-status');

export const holidayList = (data) => postRequestWithToken(data, 'holiday-list');

export const history = (data) => postRequestWithToken(data, 'history');

export const patientDetail = (data) => postRequestWithToken(data, 'patient-detail');

export const changePatientStatus = (data) => postRequestWithToken(data, 'change-patient-status');

export const sendNotification = (data) => postRequestWithToken(data, 'send_notification');

export const clinicList = (data) => postRequestWithToken(data, 'clinic-link-list');

export const knowlageMsgList = (data) => postRequestWithToken(data, 'knowledge-all-statuslist');

export const dietHistoryList = (data) => postRequestWithToken(data, 'patient-diet-history');

export const decodeToken = (data) => postRequestWithToken(data, 'decode-token');

export const advertismentList = (data) => postRequestWithToken(data, 'advertisment-list');

export const patientVisitDetail = (data) => postRequestWithToken(data, 'patient-visit-detail');

export const tagList = (data) => postRequestWithToken(data, 'tag-list');

export const addPatientATypeTag = (data) => postRequestWithToken(data, 'add-patient-a-type-tag');

export const addPatientBTypeTag = (data) => postRequestWithToken(data, 'add-patient-b-type-tag');

export const addPatientCTypeTag = (data) => postRequestWithToken(data, 'add-patient-c-type-tag');

export const addPatientStarStatus = (data) => postRequestWithToken(data, 'add-patient-star-status');

export const s3SignedUrlArray = (data) => postRequestWithToken(data, 's3SignedUrlArray');

export const starStatusList = (data) => getRequestWithToken(data, "star-status-front-list");

export const getPatientLabTestInfo = (data) => postRequestWithToken(data, "get-lab-tests");

export const addPatientLabTests = (data) => postRequestWithToken(data, "add-lab-tests");

export const editPatientLabTests = (data) => postRequestWithToken(data, "update-lab-tests");

export const labTestList = (data) => postRequestWithToken(data, "test-report-front-list");

export const getPatientLabTestReport = (data) => postRequestWithToken(data, "get-patient-test-report");

export const addPatientLabTestReport = (data) => postRequestWithToken(data, "add-patient-test-report");

export const updatePatientLabTestReport = (data) => postRequestWithToken(data, "update-patient-test-report");
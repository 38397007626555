/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddInstruction: false,
  isAddInstructionError: false,
  AddInstruction: false,
  isUpdateInstruction: false,
  isUpdateInstructionError: false,
  UpdateInstruction: false,
  isInstructionChangeStatus: false,
  isInstructionChangeStatusError: false,
  InstructionChangeStatus: false,
  isInstructionList: false,
  isInstructionListError: false,
  InstructionList: false,

}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_INSTRUCTION_REQUEST:
      return {
        ...state,
        message: '',
        isAddInstruction: false,
        isAddInstructionError: false,
      }
    case actionTypes.GET_ADD_INSTRUCTION_SUCCESS:
      return {
        ...state,
        message: 'Instruction Added Successfully',
        AddInstruction: action.payload.response,
        isAddInstruction: true,
        isAddInstructionError: false,
      }
    case actionTypes.GET_ADD_INSTRUCTION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddInstruction: false,
        isAddInstructionError: true
      }

    //Instruction Update

    case actionTypes.GET_UPDATE_INSTRUCTION_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateInstruction: false,
        isUpdateInstructionError: false,
      }
    case actionTypes.GET_UPDATE_INSTRUCTION_SUCCESS:
      return {
        ...state,
        message: 'Instruction Updated Successfully',
        UpdateInstruction: action.payload.response,
        isUpdateInstruction: true,
        isUpdateInstructionError: false,
      }
    case actionTypes.GET_UPDATE_INSTRUCTION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateInstruction: false,
        isUpdateInstructionError: true
      }

    //Instruction List
    case actionTypes.GET_INSTRUCTION_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isInstructionList: false,
        isInstructionListError: false,
      }
    case actionTypes.GET_INSTRUCTION_LIST_SUCCESS:
      return {
        ...state,
        message: 'Instruction List',
        InstructionList: action.payload.response,
        isInstructionList: true,
        isInstructionListError: false,
      }
    case actionTypes.GET_INSTRUCTION_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isInstructionList: false,
        isInstructionListError: true
      }

    //Instruction Status Change
    case actionTypes.GET_INSTRUCTION_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isInstructionChangeStatus: false,
        isInstructionChangeStatusError: false,
      }
    case actionTypes.GET_INSTRUCTION_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Instruction Status Changed',
        InstructionChangeStatus: action.payload.response,
        isInstructionChangeStatus: true,
        isInstructionChangeStatusError: false,
      }
    case actionTypes.GET_INSTRUCTION_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isInstructionChangeStatus: false,
        isInstructionChangeStatusError: true
      }


    default:
      return state
  }
}

/********* Show list of Instruction */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { userDetailAction } from 'Front/actions/login';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import HeaderLinks from './HeaderLinks.jsx'
import { Link } from 'react-router-dom';
// import * as PF from "Front/views/Home/PublicFunction.jsx"
// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

// import dashRoutes from 'Front/routes/dash.jsx';

//import logo from '../../../images/logo.png';

import NotificationSystem from 'react-notification-system';
import { appConstants } from 'Front/_constants/app.constants.js';
import {
    visitListAction, addToVisitListAction, updateVisitListAction,
    updateVisitListKnowledgeAction, updateVisitListTaskAction,
    updateVisitListDietAction,
    UpdateVisitListFromAppAction,
    UpdateVisitListNextVisitDateAction,
    UpdateVisitListPrescriptionAction,
    UpdateVisitListPatientDetailAction,
    UpdateVisitListPatientATagAction,
    UpdateVisitListPatientBTagAction,
    UpdateVisitListPatientCTagAction,
    UpdateVisitListPatientStarStatusAction,
    UpdateVisitListInsulinDoseAction,
    UpdateVisitListReportUnreadCountShareReportAction,
    updateVisitListMessageForEducator,
    updateVisitListPatientRemark,


} from 'Front/actions/home'

import { getChatCountAction, PatientChatListAction, resetIsReduceChatCoutnAction } from 'Front/actions/master'
// style for notifications
import { style } from "Front/variables/Variables.jsx";
import ImageComponent from "Front/components/common/common.js"
import moment from 'moment';
let _notificationSystem = null
class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navExpanded: false,
            _notificationSystem1: null,
        };

        this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);

        this.state = {

            AllCount: localStorage.getItem('AllCount'),
            chatOpen: false
        };

        this.setChatModel = this.setChatModel.bind(this);


        let _this = this;

        appConstants.socket.on('newMsg', function (data) {
            //console.log(data, "777777777777777")
            if (data?.userId === localStorage.getItem('_id')) {
                if (localStorage.getItem('chatModel') === 'false') {
                    let AllCount = localStorage.getItem('AllCount');
                    //console.log(AllCount, "allCount")
                    if ((data?.chat?.senderType === "patient") || (data?.chat?.senderType === "forwarded")) {
                        //console.log("kkkkkkkk")
                        let count = parseInt(AllCount, 10) + 1;
                        localStorage.setItem('AllCount', count)
                        _this.setState({ AllCount: count });
                    } else {

                        let count = localStorage.getItem('AllCount') - data.chatCount
                        _this.props.resetIsReduceChatCoutnAction(count)

                        // _this.props.getChatCountAction("RemovePatientMessageCount");
                    }
                    // _this.handleNotificationClick('warning',data.msg,data.senderName);
                }
            }
        });

        appConstants.socket.on("forwadMessage", function (response) {
            if (response.userId === localStorage.getItem("_id")) {
                let count = parseInt(localStorage.getItem('AllCount')) + 1
                _this.props.resetIsReduceChatCoutnAction(count)
            }
        })

        // appConstants.socket.on("updateTaskScreen", function (responce) {
        // setTimeout(function () {
        //     console.log(responce,"responce")
        //     _this.props.visitListAction();
        // }, 1000);
        // });
        // appConstants.socket.on("addPatient", function (responce) {
        //     setTimeout(function () {
        //     _this.props.visitListAction();
        //     }, 1000);
        // });
        appConstants.socket.on("newPatientVisitData", function (response) {
            if (!_this.props.otherVisitDate) {
                setTimeout(function () {

                    if (response.econsultation) {
                        if (!_this.props.isOnilneApiCalled) {
                            _this.props.visitListAction(moment(new Date()).format("YYYY-MM-DD"), "", "", "online")
                        } else {
                            _this.props.addToVisitListAction(response)
                        }
                    } else {
                        _this.props.addToVisitListAction(response)
                    }
                }, 1000)
            }

        });

        appConstants.socket.on("patientOut", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListAction(response)
            }, 1000)

        });

        appConstants.socket.on("patientDocument", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListKnowledgeAction(response, "Documents")
            }, 1000);

        });


        appConstants.socket.on("documentReadByEducator", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListKnowledgeAction(response, "ReadByEducator")
            }, 1000);

        });

        appConstants.socket.on("patinetTask", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListTaskAction(response, "Task")
            }, 1000);

        });

        appConstants.socket.on("taskReadByEducator", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListTaskAction(response, "ReadByEducator")
            }, 1000);

        });

        appConstants.socket.on("patinetDiet", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListDietAction(response, "Diet")
            }, 1000);
        });


        appConstants.socket.on("dietPrintByEducator", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListDietAction(response, "ReadByEducator")
            }, 1000);
        });


        appConstants.socket.on("dietReadByPatient", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListFromAppAction(response, "DietReadByPatient")
            }, 1000);
        });

        appConstants.socket.on("documentReadByPatient", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListFromAppAction(response, "DocumentReadByPatient")
            }, 1000);

        });

        appConstants.socket.on("nextVisitDate", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListNextVisitDateAction(response)
            }, 1500);
        });

        appConstants.socket.on("precriptionStatus", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPrescriptionAction(response, "prescriptionstatus")
            }, 1000);
        });

        appConstants.socket.on("patientEdit", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPatientDetailAction(response, "patientdetials")
            }, 1000);
        });


        appConstants.socket.on("patinetATagUpdate", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPatientATagAction(response)
            }, 1000);

        });
        appConstants.socket.on("patinetBTagUpdate", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPatientBTagAction(response)
            }, 1000);
        });
        appConstants.socket.on("patinetCTagUpdate", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPatientCTagAction(response)
            }, 1000);
        });
        appConstants.socket.on("patinetStarStatus", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListPatientStarStatusAction(response)
            }, 1000);
        });

        appConstants.socket.on("insulinDoseChange", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListInsulinDoseAction(response)
            }, 1000);
        });


        //  appConstants.socket.on("report_count", function (response) {
        //     console.log('2222222222222222222222222222222222222222222',response);
        //     setTimeout(function () {
        //         _this.props.UpdateVisitListReportUnreadCountAction(response)
        //     }, 1000);
        //  });

        appConstants.socket.on("patientUnReadReport", function (response) {
            setTimeout(function () {
                _this.props.UpdateVisitListReportUnreadCountShareReportAction(response)
            }, 1000);
        });

        //  appConstants.socket.on("delete_report", function (response) {
        //     console.log('2222222222222222222222222222222222222222222',response);
        //     setTimeout(function () {
        //         _this.props.UpdateVisitListReportCountDeleteReportAction(response)
        //     }, 1000);
        //  });

        appConstants.socket.on("messageForEducator", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListMessageForEducator(response)
            }, 1000);
        });

        appConstants.socket.on("patientRemark", function (response) {
            setTimeout(function () {
                _this.props.updateVisitListPatientRemark(response)
            }, 1000);
        });







        appConstants.socket.on("billingCycleUpdate", function (response) {
            if (response.data) {

                localStorage.setItem("billingCycle", response.data[0].numberOfDays);
                let msg = "Billing Cycle Has Been Updated Please Click Reload Button"
                if (localStorage.getItem("userType") !== "doctor") {
                    _notificationSystem.addNotification({
                        title: (
                            <span
                                data-notify="icon"
                                className="pe-7s-check"
                                style={{
                                    fontSize: "30px",
                                    color: "#fff",
                                    padding: "0px !important",
                                    top: "0% !important",
                                }}
                            ></span>
                        ),
                        message: (
                            <div>
                                {/* <p>{from}</p> */}
                                <span>{msg}</span>
                            </div>
                        ),
                        level: "success",
                        position: "tr",
                        autoDismiss: 5,
                    });
                }
            }
            //console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', response.data[0].numberOfDays);
        });


    }

    componentDidMount() {
        _notificationSystem = this.refs.notificationSystem1
        //this.setState({ _notificationSystem1: this.refs.notificationSystem1 });
        this.props.userDetailAction();
        const params = {
            limit: 10,
            skip: 0,
            type: "chat",
        }
        this.props.PatientChatListAction(params);
    }

    componentWillReceiveProps(nextProps) {



        if (nextProps.isUserDetail !== this.props.isUserDetail) {
            if (nextProps.UserDetailData !== undefined && nextProps.UserDetailData.data !== undefined) {
                localStorage.setItem('_id', nextProps.UserDetailData.data._id);
                localStorage.setItem('chatModel', false);

                localStorage.setItem('userName', nextProps.UserDetailData.data.userName);
                localStorage.setItem('userType', nextProps.UserDetailData.data.userType);
                localStorage.setItem('clinicId', nextProps.UserDetailData.data.clinicId);
                localStorage.setItem('clinicName', nextProps.UserDetailData.data.clinicName);
                localStorage.setItem('clinicNumber', nextProps.UserDetailData.data.clinicNumber);
                localStorage.setItem('email', nextProps.UserDetailData.data.email);
                localStorage.setItem('status', nextProps.UserDetailData.data.status);
                localStorage.setItem('degree', nextProps.UserDetailData.data.degree);
                localStorage.setItem('title', nextProps.UserDetailData.data.title);
                localStorage.setItem('firstName', nextProps.UserDetailData.data.firstName);
                localStorage.setItem('lastName', nextProps.UserDetailData.data.lastName);
                localStorage.setItem('profilePhoto', nextProps.UserDetailData.data.image);
            }
            if (nextProps.UserDetailData !== undefined && nextProps.UserDetailData.data !== undefined) {
                localStorage.setItem('logo', nextProps.UserDetailData.data.clinic.logo_image);
                localStorage.setItem('diet_print_format', nextProps.UserDetailData.data.clinic.diet_print_format);
                localStorage.setItem('print_header_margin', nextProps.UserDetailData.data.clinic.print_header_margin);
                localStorage.setItem('print_bottom_margin', nextProps.UserDetailData.data.clinic.bottom_margin);
                localStorage.setItem('left_margin', nextProps.UserDetailData.data.clinic.left_margin);
                localStorage.setItem('right_margin', nextProps.UserDetailData.data.clinic.right_margin);
                localStorage.setItem('clinicAddress', nextProps.UserDetailData.data.clinic.address);
                localStorage.setItem('clinicPhone', nextProps.UserDetailData.data.clinic.phone);
                localStorage.setItem('clinicEmail', nextProps.UserDetailData.data.clinic.email);
                localStorage.setItem('isEmailFacility', nextProps.UserDetailData.data.clinic.is_email_facility);
                localStorage.setItem('is_email_facility', nextProps.UserDetailData.data.clinic.is_email_facility);
                localStorage.setItem('is_chat_facility', nextProps.UserDetailData.data.clinic.is_chat_facility);
                localStorage.setItem('is_report_folder_facility', nextProps.UserDetailData.data.clinic.is_report_folder_facility);
                localStorage.setItem('language', nextProps.UserDetailData.data.clinic.language);
                localStorage.setItem('report_count', nextProps.UserDetailData.data.report_count);
                localStorage.setItem('libraryCount', nextProps.UserDetailData.data.clinic.libraryCount);
                localStorage.setItem('is_calendar_facility', nextProps.UserDetailData.data.clinic.is_calendar_facility);
                localStorage.setItem('birthdayWish', nextProps.UserDetailData.data.clinic.birthdayWish);

                let specializationIds = [];

                let specializationsArr = nextProps.UserDetailData.data.clinic.specializations;

                for (const i in specializationsArr) {
                    specializationIds.push(specializationsArr[i].id);
                }

                localStorage.setItem('specializationIds', JSON.stringify(specializationIds));

                let params = {
                    clinic_id: localStorage.getItem('clinicId'),
                    report_count: localStorage.getItem('report_count'),
                    libraryCount: localStorage.getItem('libraryCount'),
                }
                appConstants.socket.emit('countUpdate', params);
            }
        }



        // if (nextProps.isUserDetailError !== this.props.isUserDetailError) {
        //     // this.setState({ alert: nextProps.loginMessage.msg, showProcessing: false });
        //   }
    }

    // function that makes the sidebar from normal to mini and vice-versa
    handleMinimizeSidebar() {
        document.body.classList.toggle('sidebar-mini');
    }
    // function for responsive that hides/shows the sidebar
    mobileSidebarToggle(e) {
        document.documentElement.classList.toggle('nav-open');
    }

    handleNotificationClick(position, msg = '', from) {

        _notificationSystem.addNotification({
            title: (<span><i class="fa fa-envelope-square" aria-hidden="true"></i></span>),
            message: (
                <div>
                    <p>{from}</p>
                    <span>{msg}</span>
                </div>
            ),
            level: 'info',
            position: 'tr',
            autoDismiss: 10,
        });
    }




    setChatModel(evt) {
        if (evt === true) {
            localStorage.setItem('chatModel', true)
        }
        else {
            localStorage.setItem('chatModel', false)
            let addUser = {
                userId: localStorage.getItem('_id')
            }
            appConstants.socket.emit('screenClose', addUser);
        }
    }

    render() {
        let logo = '';
        if (localStorage.getItem('logo') !== '') {
            logo = appConstants.s3UploadUrl + localStorage.getItem('logo');

        } else {

            // logo = 'images/logo.png'  
        }

        return (


            <Navbar fluid className="front-head" onToggle={true}>
                <NotificationSystem ref="notificationSystem1" style={style} />
                <div className="flccx-header">
                    <Navbar.Header>
                        <Link to="/">
                            {localStorage.getItem('logo') &&
                                <ImageComponent
                                    style={{ "width": "auto", "height": "50px" }}
                                    data={localStorage.getItem('logo')}
                                    setViewBoxData=''
                                    className="logo-h"
                                    alt="Logo"
                                />
                            }
                            <b className="pa-title">
                                <span className="block-namenav">{localStorage.getItem('clinicName')}</span>
                            </b>
                        </Link>
                        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                    </Navbar.Header>

                    <Navbar.Collapse><HeaderLinks AllCount={this.state.AllCount} chatModel={(evt) => { this.setChatModel(evt) }} /></Navbar.Collapse>
                </div>
                {/* <div className="color-bar-btom btm">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3"></div>
                  </div> */}
            </Navbar>


        );
    }
}

function mapStateToProps(state) {
    //console.log(state,"stateinheader")
    return {
        //   loginMessage: state.login.message,
        isUserDetail: state.login.isUserDetail,
        UserDetailData: state.login.UserDetailData,
        isUserDetailError: state.login.isUserDetailError,
        isOnilneApiCalled: state.home.isOnilneApiCalled,
        otherVisitDate: state.home.otherVisitDate,

        isGetChatCount: state.master.isGetChatCount,
        isGetChatCountError: state.master.isGetChatCountError,
        GetChatCount: state.master.GetChatCount,
        isReduceChatCount: state.master.isReduceChatCount,

    }
}
export default withRouter(connect(mapStateToProps, {
    userDetailAction, visitListAction, addToVisitListAction,
    updateVisitListAction, updateVisitListKnowledgeAction,
    updateVisitListTaskAction, updateVisitListDietAction, UpdateVisitListFromAppAction,
    UpdateVisitListNextVisitDateAction,
    UpdateVisitListPrescriptionAction,
    UpdateVisitListPatientDetailAction,
    UpdateVisitListPatientATagAction,
    UpdateVisitListPatientBTagAction,
    UpdateVisitListPatientCTagAction,
    UpdateVisitListPatientStarStatusAction,
    UpdateVisitListInsulinDoseAction,
    UpdateVisitListReportUnreadCountShareReportAction,
    updateVisitListMessageForEducator,
    updateVisitListPatientRemark,
    getChatCountAction,
    PatientChatListAction,
    resetIsReduceChatCoutnAction
})(Header));
//export default Header;
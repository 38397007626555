/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { useState, useEffect } from 'react';
import { withRouter, useParams, useHistory } from "react-router-dom";
import { Grid } from 'react-bootstrap';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/emr';
import moment from 'moment';

const History = () => {

    const [visitList, setvisitList] = useState([]);
    const [patientSymtomsData, setPatientSymtomsData] = useState([]);
    const [patientPrescriptionData, setPatientPrescriptionData] = useState([]);
    const [longTermData, setLongTermData] = useState([]);
    const [intercurrentData, setIntercurrentData] = useState([])
    const [alertMsg, setAlertMsg] = useState(null);
    const [appointment, setAappointment] = useState();
    const [generalInformation, setGeneralInformation] = useState({ "patientName": "", "age": "", "height": "", "weight": "", "bmi": "", "bp": "", "gender": "", "pulse": "", "temperature": "" })
    const [toggleDuration, setToggleDuration] = useState(false);
    const [duration, setDuration] = useState("Year Month Day");
    const [lengthForIndex, setLengthForIndex] = useState('');
    let { patientId } = useParams();
    let history = useHistory();
    const initialState = {
        complaints: [],
        data: [],
        selectedCategoryId: "",
        isNavigation: true,
        interval: false
    }
    const getGeneralInfo = async () => {
        let resp = await API.generalInformation({ patientId: patientId });

        if (resp.data && resp.data.status === "Success") {
            setGeneralInformation({ "patientName": resp.data.data.name, "age": resp.data.data.age, "height": resp.data.data.height, "weight": resp.data.data.weight, "bmi": resp.data.data.bmi, "bp": resp.data.data.bp, "gender": resp.data.data.gender, "pulse": resp.data.data.pulse, "temperature": resp.data.data.temprature })
            if (resp.data.data.diabitic === "yes") {
                let strDuration = "";
                if (resp.data.data.diabtiesDuration?.years === 0 && resp.data.data.diabtiesDuration?.months === 0 && resp.data.data.diabtiesDuration?.days === 0) {
                    strDuration = "Day Month Year"
                    setToggleDuration(false)
                }
                setToggleDuration(true);
                if (resp.data.data.diabtiesDuration?.years > 0) {
                    strDuration = resp.data.data.diabtiesDuration?.years + " years ";
                }
                if (resp.data.data.diabtiesDuration?.months > 0) {
                    strDuration += resp.data.data.diabtiesDuration?.months + " months ";
                }
                if (resp.data.data.diabtiesDuration?.days > 0) {
                    strDuration += resp.data.data.diabtiesDuration?.days + " days ";
                }
                setDuration(strDuration)
            }
        }
    }
    const getAllVisits = async () => {
        let id = patientId;
        let resp = await API.getAllVisitList({ patientId: id });
        setvisitList([...resp.data.allVisits])
        setAappointment(resp.data.allVisits[0]._id)
        selectDateHistory(resp.data.allVisits[0]._id, resp.data.allVisits[0].patientId);
    }
    const selectDateHistory = async (appId, pId) => {
        setAappointment(appId);
        let res = await API.patientHistory({ appointmentId: appId, patientId: pId });
        setPatientSymtomsData([...res.data.patientSymtomsData]);
        setPatientPrescriptionData([...res.data.patientPrescriptionData]);
        setLongTermData([...res.data.longTermData]);
        setLengthForIndex(res.data.longTermData.length);
        setIntercurrentData([...res.data.intercurrentData]);
    }
    const doseTimeForPrescription = (data) => {
        let name = [];
        for (let i in data) {
            name.push(data[i].doseTimeSlotName);
        }
        return name.join(" , ");
    }
    const instructionNameForPrescription = (data) => {
        let name = [];
        for (let i in data) {
            name.push(data[i].instructionName);
        }
        return name.join(' , ');
    }
    useEffect(() => {
        getAllVisits();
        getGeneralInfo();
    }, []);

    return (

        <div className="main-content emr-wrapper dashboard-2-0" style={{ padding: '15px 0px' }}>
            <span > {alertMsg}</span>
            <Grid fluid>
                <div>
                    <div className='general-information'>
                        <div className="card_header">
                            <div className="card_header_inner">
                                <ul className='patient_details'>
                                    <li><a className='user-info-img'>
                                        {/* <span><img src={GeneralUsrImg} alt="" /> </span> */}
                                        {generalInformation.patientName}</a></li>
                                    <span className="flex"> <li><a><span>Age: </span> {generalInformation.age} Y / {generalInformation.gender === "Male" ? " M" : " F"}</a></li>
                                        <li><a><span>Height{generalInformation.height > 0 ? ":" : ""} </span> {generalInformation.height > 0 ? generalInformation.height : ""} {generalInformation.height > 0 ? "CM" : ""}</a></li>
                                        <li><a><span>Weight{generalInformation.weight > 0 ? ":" : ""} </span> {generalInformation.weight > 0 ? generalInformation.weight : ""} {generalInformation.weight > 0 ? "KG" : ""}</a></li>
                                        <li><a><span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span> {generalInformation.bmi > 0 ? generalInformation.bmi : ""}</a></li>
                                        <li><a><span>BP{generalInformation.bp !== "/" ? ":" : ""} </span> {generalInformation.bp !== "/" ? generalInformation.bp : ""} {generalInformation.bp !== "/" ? " mmHg" : ""}</a></li>
                                        <li><a><span>Pulse{generalInformation.pulse > 0 ? ":" : ""} </span> {generalInformation.pulse > 0 ? generalInformation.pulse : ""} {generalInformation.pulse > 0 ? "/min" : ""}</a></li>
                                        <li><a><span>Temperature{generalInformation.temperature > 0 ? ":" : ""} </span> {generalInformation.temperature > 0 ? generalInformation.temperature : ""} {generalInformation.temperature > 0 ? "F" : ""} </a></li>
                                        {toggleDuration &&
                                            <li><a><span>Diabetes Duration: </span> {duration} </a></li>
                                        }
                                    </span>
                                </ul>
                                <div className="patient_history_btn">
                                    <Button type="button" className="btn btn-fill btn-light pull-right btn-sm" onClick={() => history.goBack()}>Go Back</Button>
                                    {/* <NavLink  to={`/vital-sign/${appointmentId}/${patientId}`} className="btn btn-fill btn-light pull-right">Go Back</NavLink> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
                            <div className='pending-ic'>
                                <img src={PendingIc} alt='' />
                            </div>
                            <div className="pending-title">
                                <h4>Pending Action <strong>3</strong></h4>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="card-content-inner">
                    <div className="emr-menu-list">
                        <ul>
                            {visitList.map((item, index) =>
                                <li onClick={e => selectDateHistory(item._id, item.patientId)} className={item._id === appointment ? "current" : ""}><a>{moment(item.createdAt).format('DD MMM YYYY')}</a></li>
                            )}
                        </ul>
                    </div>
                    <div className="history-wrapper">
                        {patientSymtomsData && patientSymtomsData.length > 0 &&
                            <div className="history-col history-col-half" >

                                <h5>Complaints</h5>
                                <div id="divComplaints" className="center-content-table table-responsive-new">
                                    <table class="table table-bordered visit-table complaints-table-style">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: 'center' }}>#</th>
                                                <th scope="col">Complaints</th>
                                                <th scope="col" style={{ textAlign: 'center' }}>Duration</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {patientSymtomsData.map((complaint, index) => (
                                                <tr id="addr0">
                                                    <th scope="row" style={{ textAlign: 'center' }}>{index + 1}</th>
                                                    <td>{complaint.symptomsSubCategoryName}</td>
                                                    <td>{complaint.duration.no} {complaint.duration.period}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        {(longTermData.length > 0 || intercurrentData.length > 0) &&
                            <div className="history-col history-col-half" >
                                <h5>Diagnosis</h5>
                                <div className="center-content-table table-responsive-new">
                                    <table class="table table-bordered visit-table complaints-table-style">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: 'center', minWidth: '40px' }}>#</th>
                                                <th scope="col" style={{ minWidth: '120px' }}>Diagnosis</th>
                                                <th scope="col" style={{ minWidth: '60px' }}>Type</th>
                                                <th scope="col" style={{ minWidth: '100px' }}>Added Date</th>
                                                <th scope="col" style={{ minWidth: '80px' }}>Duration</th>
                                                {/* <th scope="col">Completed</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {longTermData.map((data, index) => (
                                                <tr >
                                                    <td scope="row" style={{ textAlign: 'center', minWidth: '40px' }}>{index + 1}</td>
                                                    <td style={{ minWidth: '120px' }}>{data.diagonsisSubCategoryName}</td>
                                                    <td style={{ minWidth: '60px' }}>LT</td>
                                                    <td style={{ minWidth: '100px' }}>{moment(data.createdAt).format('DD MMM YYYY')}</td>
                                                    <td style={{ minWidth: '80px' }}>{data.duration.no} {data.duration.period}</td>
                                                    {/* <td>Yes</td> */}
                                                </tr>
                                            ))}
                                            {intercurrentData.map((data, index) => (
                                                <tr >
                                                    <td scope="row" style={{ textAlign: 'center' }}>{lengthForIndex + index + 1}</td>
                                                    <td>{data.diagonsisSubCategoryName}</td>
                                                    <td>IC</td>
                                                    <td>{moment(data.createdAt).format('DD MMM YYYY')}</td>
                                                    <td>{data.duration.no} {data.duration.period}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {patientPrescriptionData.length > 0 &&
                            <div className="history-col history-col-full" >
                                <h5>Prescription</h5>
                                <div className="center-content-table table-responsive-new">
                                    <table class="table table-bordered complaints-table-style visit-table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ textAlign: 'center', minWidth: '40px' }}>#</th>
                                                <th scope="col">Brand name</th>
                                                <th scope="col">Dose Time</th>
                                                <th scope="col">Duration</th>
                                                <th scope="col">Instruction</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {patientPrescriptionData.map((data, index) => (
                                                <tr  >
                                                    <td scope="row" style={{ textAlign: 'center', minWidth: '40px' }}>{index + 1}</td>
                                                    <td>{data.drugNameWithDose}</td>

                                                    <td>{doseTimeForPrescription(data.doseTimeSlot)}</td>

                                                    <td>{data.duration.no} {data.duration.period}</td>
                                                    <td>{instructionNameForPrescription(data.instructionName)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}

                    </div>
                    {patientPrescriptionData.length == 0 && patientSymtomsData.length == 0 && intercurrentData.length == 0 &&
                        <div style={{ color: "black", marginTop: "100px", textAlign: "center" }}><h6>No data found!</h6></div>
                    }
                </div>
            </Grid >
        </div >


    )
}

export default withRouter(History);

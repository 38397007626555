/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import {
    getRequest,
    postRequest,
    getRequestWithToken,
    postRequestWithToken,
} from "./helper";

const getAdminNotification = async () => {
    try {
        let url = "admin-notification-list";
        let data;
        return postRequestWithToken(data, url).then((res) => {
            return res.data;
        });
    } catch (error) {
        if (error.response.status === 500) {
            throw Error(error.response.data.message);
        }
        throw Error(error.message);
    }
};

const editAdminNotificationReadStatus = async () => {
    try {
        let url = "admin-notification-read-status";
        let data;
        return postRequestWithToken(data, url).then((res) => {
            return res.data;
        });
    } catch (error) {
        if (error.response.status === 500) {
            throw Error(error.response.data.message);
        }
        throw Error(error.message);
    }
};

const AdminNotificationApi = {
    getAdminNotification,
    editAdminNotificationReadStatus,
};

export default AdminNotificationApi
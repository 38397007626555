/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';

class CustomCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_checked: (props.isChecked ? true : false)
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick() {
        this.setState({ is_checked: !this.state.is_checked });
    }
    render() {
        const { isChecked, number, label, inline, ...rest } = this.props;
        const classes = inline !== undefined ? "checkbox checkbox-inline" : "checkbox";
        return (
            <div className={classes}>
                <input id={number} type="checkbox" onChange={this.handleClick} checked={this.state.is_checked} {...rest} />
                <label htmlFor={number}>{label}
                </label>
            </div>
        );
    }
}

export default CustomCheckbox;

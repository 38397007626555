/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

//import LoginPage from 'Front/views/Login/LoginPage.jsx';
//import Homes from 'Front/views/Homes/HomesPage.jsx';
import Dashboard from 'Front/views/Dashboard/Dashboard.jsx';
// import About from 'Front/views/practiceaidHome/About/About.jsx';
// import Contact from 'Front/views/practiceaidHome/Contact/Contact.jsx';
// import HomePage from 'Front/views/practiceaidHome/HomePage/HomePage.jsx';

import About from 'Front/views/practiceaidHome/About/About.jsx';
import Contact from 'Front/views/practiceaidHome/Contact/Contact.jsx';
import Aahar from 'Front/views/practiceaidHome/Aahar/Aahar.jsx';
import Medzing from 'Front/views/practiceaidHome/Medzing/Medzing.jsx';
import Practiceaid from 'Front/views/practiceaidHome/practiceaid/practiceaid.jsx';
import ourProduct from 'Front/views/practiceaidHome/ourProduct/ourProduct.jsx';
import PrivacyPolicy from 'Front/views/practiceaidHome/PrivacyPolicy/PrivacyPolicy.jsx';
import TermsCondition from 'Front/views/practiceaidHome/TermsCondition/TermsCondition.jsx';
import HomePage from 'Front/views/practiceaidHome/HomePage/HomePage.jsx';
import ClinicRegistration from 'Front/views/practiceaidHome/ClinicRegistration/ClinicRegistration.jsx';


//let userType = localStorage.getItem('userType');
let homesRoutes = [];
homesRoutes = [
  //  { path: "/login", name: "login", mini: "HM", component: LoginPage },
  { path: "/clinic-registration", name: "Clinic Registration", mini: "CR", component: ClinicRegistration },
  { path: "/about", name: "about", mini: "AB", component: About },
  { path: "/contact", name: "contact", mini: "CT", component: Contact },
  { path: "/aahar", name: "aahar", mini: "AH", component: Aahar },
  { path: "/medzing", name: "medzing", mini: "MZ", component: Medzing },
  { path: "/practiceaid", name: "practiceaid", mini: "PA", component: Practiceaid },
  { path: "/our_product", name: "our_product", mini: "OP", component: ourProduct },
  { path: "/privacy-policy", name: "Privacy Policy", mini: "OP", component: PrivacyPolicy },
  { path: "/terms-condition", name: "Terms Condition", mini: "OP", component: TermsCondition },
  { path: "/dashboard", name: "Dashboard", mini: "DB", component: Dashboard },
  { path: "/", name: "Home Page", mini: "OP", component: HomePage },
];

export default homesRoutes;

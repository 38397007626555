/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
    response: [],
    message: '',
    isClinicList: false,
    isClinicListError: false,
    ClinicList: false,

    isAddClinic: false,
    isAddClinicError: false,
    addClinicResponse: '',

    isClinicChangeStatus: false,
    isClinicChangeStatusError: false,
    ClinicChangeStatusResponse: '',

    isUpdateClinic: false,
    isUpdateClinicError: false,
    updateClinicResponse: '',

    isAddClinicCsv: false,
    isAddClinicCsvError: false,
    addClinicCsv: '',

    isClinicDetail: false,
    isClinicDetailError: false,
    ClinicDetail: false,


    isImageRemove: false,
    isImageRemoveError: false,
    ImageRemove: false,

    isClinicDeleted: false,
    isClinicDeletedError: false,
    ClinicDeleted: false,

    isSendLoginDetail: false,
    isSendLoginDetailError: false,
    SendLoginDetail: false,

    isChangeDemo: false,
    isChangeDemoError: false,
    ChangeDemo: false,

    isClinicSimpleList: false,
    isClinicSimpleListError: false,
    ClinicSimpleList: false,

}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_CLINIC_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isClinicList: false,
                isClinicListError: false,
            }
        case actionTypes.GET_CLINIC_LIST_SUCCESS:
            return {
                ...state,
                message: 'Clinic List successfully',
                ClinicList: action.payload.response,
                isClinicList: true,
                isClinicListError: false,
            }
        case actionTypes.GET_CLINIC_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isClinicList: false,
                isClinicListError: true
            }

        case actionTypes.GET_CLINIC_DETAIL_REQUEST:
            return {
                ...state,
                message: '',
                isClinicDetail: false,
                isClinicDetailError: false,
            }
        case actionTypes.GET_CLINIC_DETAIL_SUCCESS:
            return {
                ...state,
                message: 'Clinic Detail successfully',
                ClinicDetail: action.payload.response,
                isClinicDetail: true,
                isClinicDetailError: false,
            }
        case actionTypes.GET_CLINIC_DETAIL_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isClinicDetail: false,
                isClinicDetailError: true
            }

        case actionTypes.POST_ADD_CLINIC_REQUEST:
            return {
                ...state,
                message: '',
                isAddClinic: false,
                isAddClinicError: false,
            }
        case actionTypes.GET_ADD_CLINIC_SUCCESS:
            return {
                ...state,
                message: 'Clinic List successfully',
                addClinicResponse: action.payload.response,
                isAddClinic: true,
                isAddClinicError: false,
            }
        case actionTypes.GET_ADD_CLINIC_ERROR:
            return {
                ...state,
                addClinicResponse: action.payload.error,
                message: action.payload.error,
                isAddClinic: false,
                isAddClinicError: true
            }
        case actionTypes.POST_UPDATE_CLINIC_REQUEST:
            return {
                ...state,
                message: '',
                isUpdateClinic: false,
                isUpdateClinicError: false,
            }
        case actionTypes.GET_UPDATE_CLINIC_SUCCESS:
            return {
                ...state,
                message: 'Clinic Updated successfully',
                updateClinicResponse: action.payload.response,
                isUpdateClinic: true,
                isUpdateClinicError: false,
            }
        case actionTypes.GET_UPDATE_CLINIC_ERROR:
            return {
                ...state,
                updateClinicResponse: action.payload.error,
                message: action.payload.error,
                isUpdateClinic: false,
                isUpdateClinicError: true
            }

        //Clinic Status Change
        case actionTypes.GET_CLINIC_CHANGE_STATUS_REQUEST:
            return {
                ...state,
                message: '',
                isClinicChangeStatus: false,
                isClinicChangeStatusError: false,
            }
        case actionTypes.GET_CLINIC_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                message: 'Clinic Status Changed',
                ClinicChangeStatus: action.payload.response,
                isClinicChangeStatus: true,
                isClinicChangeStatusError: false,
            }
        case actionTypes.GET_CLINIC_CHANGE_STATUS_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isClinicChangeStatus: false,
                isClinicChangeStatusError: true
            }


        //Clinic Image Remove
        case actionTypes.GET_IMAGE_REMOVE_REQUEST:
            return {
                ...state,
                message: '',
                isImageRemove: false,
                isImageRemoveError: false,
            }
        case actionTypes.GET_IMAGE_REMOVE_SUCCESS:
            return {
                ...state,
                message: 'Image Remove Successfully.',
                ImageRemove: action.payload.response,
                isImageRemove: true,
                isImageRemoveError: false,
            }
        case actionTypes.GET_IMAGE_REMOVE_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isImageRemove: false,
                isImageRemoveError: true
            }

        //Clinic Delete
        case actionTypes.GET_CLINIC_DELETED_REQUEST:
            return {
                ...state,
                message: '',
                isClinicDeleted: false,
                isClinicDeletedError: false,
            }
        case actionTypes.GET_CLINIC_DELETED_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully.',
                ClinicDeleted: action.payload.response,
                isClinicDeleted: true,
                isClinicDeletedError: false,
            }
        case actionTypes.GET_CLINIC_DELETED_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isClinicDeleted: false,
                isClinicDeletedError: true
            }

        //Send login detail
        case actionTypes.SEND_LOGIN_DETAIL_REQUEST:
            return {
                ...state,
                message: '',
                isSendLoginDetail: false,
                isSendLoginDetailError: false,
            }
        case actionTypes.SEND_LOGIN_DETAIL_SUCCESS:
            return {
                ...state,
                message: 'Successfully Login Detail Sent.',
                SendLoginDetail: action.payload.response,
                isSendLoginDetail: true,
                isSendLoginDetailError: false,
            }
        case actionTypes.SEND_LOGIN_DETAIL_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isSendLoginDetail: false,
                isSendLoginDetailError: true
            }


        //Clinic demo number change
        case actionTypes.GET_CHANGE_DEMO_REQUEST:
            return {
                ...state,
                message: '',
                isChangeDemo: false,
                isChangeDemoError: false,
            }
        case actionTypes.GET_CHANGE_DEMO_SUCCESS:
            return {
                ...state,
                message: 'Deleted Successfully.',
                ChangeDemo: action.payload.response,
                isChangeDemo: true,
                isChangeDemoError: false,
            }
        case actionTypes.GET_CHANGE_DEMO_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isChangeDemo: false,
                isChangeDemoError: true
            }

        //Clinic csv upload
        case actionTypes.ADD_CLINIC_CSV_REQUEST:
            return {
                ...state,
                message: '',
                addClinicCsv: false,
                isAddClinicCsv: false,
                isAddClinicCsvError: false,
            }
        case actionTypes.ADD_CLINIC_CSV_SUCCESS:
            return {
                ...state,
                message: 'Clinic Add successfully.',
                addClinicCsv: action.payload.response,
                isAddClinicCsv: true,
                isAddClinicCsvError: false,
            }
        case actionTypes.ADD_CLINIC_CSV_ERROR:
            return {
                ...state,
                addClinicCsv: action.payload.error,
                message: action.payload.error,
                isAddClinicCsv: false,
                isAddClinicCsvError: true
            }

        case actionTypes.GET_CLINIC_SIMPLE_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isClinicSimpleList: false,
                isClinicSimpleListError: false,
            }
        case actionTypes.GET_CLINIC_SIMPLE_LIST_SUCCESS:
            return {
                ...state,
                message: 'Clinic List successfully',
                ClinicSimpleList: action.payload.response,
                isClinicSimpleList: true,
                isClinicSimpleListError: false,
            }
        case actionTypes.GET_CLINIC_SIMPLE_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isClinicSimpleList: false,
                isClinicSimpleListError: true
            }


        default:
            return state
    }
}
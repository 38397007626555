/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAction } from "Front/actions/login";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Media,
} from "react-bootstrap";
import banner_img from "Front/assets/img/banner_2.png";
import Card from "Front/components/Card/Card.jsx";
import Button from "Front/elements/CustomButton/CustomButton.jsx";
import logo from "Front/assets/img/practice_aid_logo.png";
import { Link } from "react-router-dom";
import { addContactUsAction } from "Front/actions/settings";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  name: "required",
  email: "required|email",
  phone: "required|numeric",
};
let mess = {
  required: "This field is required",
};
let validation = [];

var isDataAdded = false;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      calenderErr: false,
      showForm: true,
      formArr: [],
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAddContactUs !== this.props.isAddContactUs) {
      if (isDataAdded) {
        this.successAlert();
        isDataAdded = false;
      }
      this.setState({
        formData: { name: "", email: "", phone: "", message: "" },
        formArr: [],
      });
    }

    if (nextProps.isAddContactUsError !== this.props.isAddContactUsError) {
      if (isDataAdded) {
        this.errorAlert();
        isDataAdded = false;
      }
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field,
    });
  }

  userLogin(evt) {
    evt.preventDefault();
    const _this = this;

    if (this.allValidate(false)) {
      if (this.state.formData) {
        if (!isDataAdded) {
          this.props.addContactUsAction(this.state.formData);
          isDataAdded = true;
        }
      } else {
        this.setState({ contactErr: true });
      }
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  successAlert() {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>Data added Successfully!</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  errorAlert() {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-close-circle"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>Something Went Wrong :)</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    let formData = this.state.formData;
    formData["message"] = "";
    this.setState({
      alert: null,
      formData: formData,
    });
    this.setState({ showForm: false });
    let _this = this;
    setTimeout(function () {
      _this.setState({ showForm: true });
    }, 300);
  }

  render() {
    const { formData } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <Media>
        <div className="banner-contact">
          <div className="banner_content">
            <h2>Digitizing Healthcare</h2>
            <p>Cutting-edge Software Products for Healthcare Industry</p>
          </div>
        </div>

        <Row>
          <div
            className={
              "container" + (this.props.fluid !== undefined ? "-fluid" : "")
            }
          >
            <div className="about-us contact-us-section">
              <h2>Contact Us</h2>

              <div className="box-input">
                <p>If you have any questions, inquiries, or feedback regarding PracticeAid, please feel free to get in touch with us.</p>
                <p className="whats-app">
                  <p>WhatsApp us at</p>
                  <a href="https://api.whatsapp.com/send?phone=919644433305" target="_blank">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                      </svg>
                    </span>
                    +91-9644-433-305
                  </a>
                </p>
                <p>We appreciate your interest and will respond to your message as soon as posible.</p>
              </div>
            </div>
          </div>
        </Row>
        {/* 
        <Grid>
          {this.state.alert}
          <NotificationSystem ref="notificationSystem" style={style} />
          <Row>
            <Col md={8} sm={8} mdOffset={2} smOffset={2} className="box-input">
              <form>
                <div
                  className="actionProcess"
                  style={{
                    display: this.state.showProcessing ? "block" : "none",
                  }}
                ></div>
                <Card
                  bsStyle="info"
                  text="white"
                  hidden={this.state.cardHidden}
                  textCenter
                  title="How can we help you?"
                  content={
                    <div>
                      <FormGroup bsSize="large">
                        <FormControl
                          placeholder="Name*"
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="errorMsg">
                          {this.state.formArr.name &&
                            validation.errors.first("name")}
                        </span>
                      </FormGroup>

                      <FormGroup bsSize="large">
                        <FormControl
                          placeholder="E-Mail*"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="errorMsg">
                          {this.state.formArr.email &&
                            validation.errors.first("email")}
                        </span>
                      </FormGroup>

                      <FormGroup bsSize="large">
                        <FormControl
                          placeholder="Phone*"
                          type="text"
                          name="phone"
                          maxLength={10}
                          value={formData.phone}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        <span className="errorMsg">
                          {this.state.formArr.phone &&
                            validation.errors.first("phone")}
                        </span>
                      </FormGroup>

                      <FormGroup
                        controlId="formControlsTextarea"
                        bsSize="large"
                      >
                        <FormControl
                          componentClass="textarea"
                          name="message"
                          placeholder="Message"
                          rows="5"
                          value={formData.message}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                      </FormGroup>
                    </div>
                  }
                  legend={
                    <Button
                      id={"login-btn"}
                      onClick={this.userLogin.bind(this)}
                      bsStyle="info"
                      fill
                      wd
                    >
                      Submit
                    </Button>
                  }
                  ftTextCenter
                />
              </form>
            </Col>
          </Row>


        <div className="middle-spc"></div>
      </Grid> */}
      </Media >
    );
  }
}

function mapStateToProps(state) {
  return {
    isAddContactUs: state.settings.isAddContactUs,
    isAddContactUsError: state.settings.isAddContactUsError,
    AddContactUs: state.settings.AddContactUs,
  };
}

export default withRouter(
  connect(mapStateToProps, { addContactUsAction })(Contact)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';
import * as API from '../../../../api/emr'

const AddEditSymptomCategory = (props) => {
    let history = useHistory();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)
    const [mode, setMode] = useState("add");
    const [symptomCategoryId, setSymptomCategoryId] = useState("")
    const [sequenceAlreadyExist, setSequenceAlreadyExist] = useState(false)
    const [sequenceAlreadyExistMsg, setSequenceAlreadyExistMsg] = useState(false)

    const inputHandler = (e) => {
        if (e.target.name === "sequence" && sequenceAlreadyExist) {
            setSequenceAlreadyExist(false)
            setSequenceAlreadyExistMsg("")
        }
        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.sequence && post.sequence != '') {
            errArr.sequence = '';
        } else { errArr.sequence = 'error'; errflag = false; }

        if (post.symptomsCategoryName && post.symptomsCategoryName != '') {
            errArr.symptomsCategoryName = '';
        } else { errArr.symptomsCategoryName = 'error'; errflag = false; }

        setErrors({ ...errArr });
        return errflag;
    }

    const submitHandler = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        if (validate()) {
            let sentData = new FormData();
            sentData.append('symptomsCategoryName', formData.symptomsCategoryName)
            sentData.append('sequence', formData.sequence)
            let resp = ""
            if (mode === "add") {
                resp = await API.addSymptomCategory(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setSequenceAlreadyExist(true)
                        setSequenceAlreadyExistMsg(resp.data.msg)
                    }
                    else history.push({ pathname: "/admin/symptom-category-list", from: "add" })

                }
            } else {
                sentData.append('id', symptomCategoryId)
                resp = await API.updateSymptomCategory(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setSequenceAlreadyExist(true)
                        setSequenceAlreadyExistMsg(resp.data.msg)
                    } else history.push({ pathname: "/admin/symptom-category-list", from: "edit" })
                }
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        let row = props.location.state?.row;
        let saveFormData = formData;
        saveFormData["sequence"] = "";
        saveFormData["symptomsCategoryName"] = "";

        if (row !== undefined && row) {
            setMode("edit");
            setSymptomCategoryId(row._id);
            saveFormData["sequence"] = row.sequence;
            saveFormData["symptomsCategoryName"] = row.name;

            setFormData({ ...formData, ...saveFormData });
        }

    }, [])

    return (
        <div className="main-content">

            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={<legend className="line-removes">
                                <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { history.goBack() }}>Back</Button>
                            </legend>}
                            content={
                                <Form horizontal className="add-supportsystem_form">

                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Category<span className="star">*</span></ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="symptomsCategoryName"
                                                            id="symptomsCategoryName"
                                                            placeholder="Category"
                                                            onChange={e => { inputHandler(e); }}
                                                            autocomplete="off"
                                                            value={formData.symptomsCategoryName ? formData.symptomsCategoryName : ''}
                                                            className={'insulin-input ' + (errors.symptomsCategoryName ? errors.symptomsCategoryName : '')}
                                                        />
                                                        {isSpecialCharacter &&
                                                            <span className="star">Special Characters Are Not Allowed</span>
                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Sequence<span className="star">*</span></ControlLabel>
                                                        <FormControl
                                                            type="number"
                                                            name="sequence"
                                                            id="sequence"
                                                            placeholder="Sequence"
                                                            onChange={e => { inputHandler(e); }}
                                                            autocomplete="off"
                                                            value={formData.sequence ? formData.sequence : ''}
                                                            className={'insulin-input ' + (errors.sequence ? errors.sequence : '')}
                                                        />
                                                        {sequenceAlreadyExist &&
                                                            <span className="star">{sequenceAlreadyExistMsg}</span>
                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <FormGroup>
                                            <ControlLabel className="col-sm-2">
                                            </ControlLabel>
                                            <Col sm={12} className="btn_wrap">
                                                <Row>
                                                    <Col md={12}>
                                                        <button
                                                            type="button"
                                                            className="btn-fill btn-wd btn btn-primary"
                                                            onClick={e => { submitHandler(e) }}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                                                            Submit
                                                        </button>&nbsp;

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default AddEditSymptomCategory
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken, getRequestWithToken } from "./helper";

export const drugList = (data) => postRequestWithToken(data, "admin/drugList");

export const deleteDrug = (data) =>
    postRequestWithToken(data, "admin/drug-delete");

export const statusChangeDrug = (data) =>
    postRequestWithToken(data, "admin/drug-status");

export const drugCategoryList = (data) =>
    postRequestWithToken(data, "admin/drugCategory-list");

export const drugCategoryListWithoutPagination = (data) =>
    postRequestWithToken(data, "drugCategoryList");

export const drugCompanyListWithoutPagination = (data) =>
    postRequestWithToken(data, "drugCompanyList");

export const addDrug = (data) => postRequestWithToken(data, "admin/addDrug");

export const updateDrug = (data) =>
    postRequestWithToken(data, "admin/update-drug");

export const addDrugCategory = (data) =>
    postRequestWithToken(data, "admin/add-drugCategory");

export const updateDrugCategory = (data) =>
    postRequestWithToken(data, "admin/update-drugCategory");

export const deleteDrugCategory = (data) =>
    postRequestWithToken(data, "admin/drugCategory-delete");

export const statusChangeDrugCategory = (data) =>
    postRequestWithToken(data, "admin/drugCategory-status");

export const drugCompanyList = (data) =>
    postRequestWithToken(data, "admin/drugCompany-list");

export const addDrugCompany = (data) =>
    postRequestWithToken(data, "admin/add-drugCompany");

export const updateDrugCompany = (data) =>
    postRequestWithToken(data, "admin/update-drugCompany");

export const deleteDrugCompany = (data) =>
    postRequestWithToken(data, "admin/drugCompany-delete");

export const statusChangeDrugCompany = (data) =>
    postRequestWithToken(data, "admin/drugCompany-status");

export const recommendationList = (data) =>
    postRequestWithToken(data, "admin/recommendationPrescription-list");

export const deleteRecommendation = (data) =>
    postRequestWithToken(data, "admin/recommendation-delete");

export const addRecommendation = (data) =>
    postRequestWithToken(data, "admin/add-recommendation");

export const updateRecommendation = (data) =>
    postRequestWithToken(data, "admin/update-recommendation");

export const drugInstructionList = (data) =>
    postRequestWithToken(data, "admin/drugInstruction-list");

export const addDrugInstruction = (data) =>
    postRequestWithToken(data, "admin/add-drugInstruction");

export const updateDrugInstruction = (data) =>
    postRequestWithToken(data, "admin/update-drugInstruction");

export const statusChangeInstruction = (data) =>
    postRequestWithToken(data, "admin/drugInstruction-status");

export const deleteDrugInstruction = (data) =>
    postRequestWithToken(data, "admin/drugInstruction-delete");

export const statusChangeRecommendation = (data) =>
    postRequestWithToken(data, "admin/recommendation-status");

export const masterInstructionList = (data) =>
    postRequestWithToken(data, "admin/masterInstructionList");

export const masterSymptomCategoryList = (data) =>
    postRequestWithToken(data, "admin/master-Symptoms-Category-list");

export const addSymptomCategory = (data) =>
    postRequestWithToken(data, "admin/add-masterSymptomCategory");

export const updateSymptomCategory = (data) =>
    postRequestWithToken(data, "admin/update-masterSymptomCategory");

export const statusChangeSymptomsCategory = (data) =>
    postRequestWithToken(data, "admin/master-symptoms-category-status");

export const deleteSymptomCategory = (data) =>
    postRequestWithToken(data, "admin/master-symptoms-category-delete");

export const masterSymptomSubCategoryList = (data) =>
    postRequestWithToken(data, "admin/master-complainSubCategory-list");

export const statusChangeSymptomsSubCategory = (data) =>
    postRequestWithToken(data, "admin/master-complainSubCategory-status");

export const addSymptomSubCategory = (data) =>
    postRequestWithToken(data, "admin/add-master-complain");

export const updateSymptomSubCategory = (data) =>
    postRequestWithToken(data, "admin/update-master-complain");

export const symptomCategoryListWithoutPagination = (data) =>
    postRequestWithToken(data, "admin/masterList-complainSubCategory");

export const deleteSymptomSubCategory = (data) =>
    postRequestWithToken(data, "admin/master-complainSubCategory-delete");

export const diagnosisCategoryList = (data) =>
    postRequestWithToken(data, "admin/diagnosisCategory-list");

export const addDiagnosisCategory = (data) =>
    postRequestWithToken(data, "admin/add-diagnosisCategory");

export const updateDiagnosisCategory = (data) =>
    postRequestWithToken(data, "admin/update-dianosisCategory");

export const statusChangeDiagnosisCategory = (data) =>
    postRequestWithToken(data, "admin/diagnosisCategory-status");

export const deleteDiagnosisCategory = (data) =>
    postRequestWithToken(data, "admin/diagnosisCategory-delete");

export const diagnosisSubCategoryList = (data) =>
    postRequestWithToken(data, "admin/diagnosis-subCategory-list");

export const diagonsisList = (data) =>
    postRequestWithToken(data, "diagonsisList");

export const statusChangeDiagnosisSubCategory = (data) =>
    postRequestWithToken(data, "admin/diagnosis-subCategory-status");

export const deleteDiagnosisSubCategory = (data) =>
    postRequestWithToken(data, "admin/diagnosis-subCategory-delete");

export const daignosisCategoryListWithoutPagination = (data) =>
    postRequestWithToken(data, "admin/diagnosis-subCategory-masterList");

export const addDiagnosisSubCategory = (data) =>
    postRequestWithToken(data, "admin/add-diagnosis-subCategory");

export const editDiagnosisSubCategorySequence = (data) =>
    postRequestWithToken(data, "admin/diagnosis-subCategory-sequenceEdit");

export const updateDiagnosisSubCategory = (data) =>
    postRequestWithToken(data, "admin/update-diagnosis-subCategory");

export const labTestList = (data) =>
    postRequestWithToken(data, "admin/test-report-list");

export const deleteLabTest = (data) =>
    postRequestWithToken(data, "admin/test-report-delete");

export const statusChangeLabTest = (data) =>
    postRequestWithToken(data, "admin/change-test-status");

export const changeLabTestOrder = (data) =>
    postRequestWithToken(data, "admin/change-test-order");

export const addLabTest = (data) =>
    postRequestWithToken(data, "admin/add-test-name");

export const updateLabTest = (data) =>
    postRequestWithToken(data, "admin/update-test-name");

    export const addTestReportRange = (data) =>
    postRequestWithToken(data, "admin/add-testreportrange");

    export const updateTestReportRange = (data) =>
    postRequestWithToken(data, "admin/update-testreportrange");

    export const getLabTestReportRangeList = (data) =>
    postRequestWithToken(data, "admin/testreport-range");

    export const statusChangeLabTestRange = (data) =>
    postRequestWithToken(data, "admin/testreportrange-status");

    export const deleteLabTestRange = (data) =>
    postRequestWithToken(data, "admin/testreportrange-delete");

    

    

    

    
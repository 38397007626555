/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
//let activeTab="offline";
const VisitListButton = ({ visitCount, eVisitCount, outPatientCount, getVisitList, activedTab }) => {
  const [activeTab, setActiveTab] = useState("offline")
  const [opdCount, setOpdCount] = useState(visitCount)
  const [onlineCount, setOnlineCount] = useState(eVisitCount)
  const [outCount, setOutCount] = useState(outPatientCount)

  //console.log(activedTab,"activedTab")
  useEffect(() => {
    setActiveTab(activedTab)
  }, [activedTab])

  useEffect(() => {
    setOpdCount(visitCount)
    setOnlineCount(eVisitCount)
    setOutCount(outPatientCount)
  }, [visitCount, eVisitCount, outPatientCount])


  return (
    <div>
      <button
        class={`btn btn-default btn-OPD ${activeTab === "offline" ? "btn-fill btn-primary" : ""
          }`}
        type="button"
        onClick={(e) => {
          setActiveTab("offline");
          getVisitList("offline");
        }
        }
        style={{ minWidth: "172px" }}
      >
        <span>
          OPD Consultation{" "}
          {opdCount > 0
            ? "(" + opdCount + ")"
            : ""}
        </span>
      </button>
      <button
        style={{ minWidth: "186px" }}
        class={`btn btn-default ${activeTab === "online" ? "btn-fill btn-primary" : ""
          }`}
        type="button"
        onClick={(e) => {
          setActiveTab("online");
          getVisitList("online");
        }
        }
      >
        <span>
          Online Consultation{" "}
          {onlineCount > 0
            ? "(" + onlineCount + ")"
            : ""}
        </span>
      </button>
      <button
        style={{ minWidth: "105px" }}
        class={`btn btn-default ${activeTab === "OutPatientList" ? "btn-fill btn-primary" : ""
          }`}
        type="button"
        onClick={(e) => {
          setActiveTab("OutPatientList")
          getVisitList("OutPatientList");
        }}
      >
        <span>
          Out List{" "}
          {outCount > 0
            ? "(" + outCount + ")"
            : ""}
        </span>
      </button>
    </div>
  )
}

export default VisitListButton
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isDietTimeList: false,
  isDietTimeListError: false,
  DietTimeList: false,

  isDietTimePlanList: false,
  isDietTimePlanListError: false,
  DietTimePlanList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    //DietTime List
    case actionTypes.GET_DIET_TIME_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isDietTimeList: false,
        isDietTimeListError: false,
      }
    case actionTypes.GET_DIET_TIME_LIST_SUCCESS:
      return {
        ...state,
        message: 'DietTime List',
        DietTimeList: action.payload.response,
        isDietTimeList: true,
        isDietTimeListError: false,
      }
    case actionTypes.GET_DIET_TIME_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isDietTimeList: false,
        isDietTimeListError: true
      }

    //DietTimePlan List
    case actionTypes.GET_DIET_TIME_PLAN_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isDietTimePlanList: false,
        isDietTimePlanListError: false,
      }
    case actionTypes.GET_DIET_TIME_PLAN_LIST_SUCCESS:
      return {
        ...state,
        message: 'DietTimePlan List',
        DietTimePlanList: action.payload.response,
        isDietTimePlanList: true,
        isDietTimePlanListError: false,
      }
    case actionTypes.GET_DIET_TIME_PLAN_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isDietTimePlanList: false,
        isDietTimePlanListError: true
      }
    default:
      return state
  }
}

/********* Show list of DietTime */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Front/elements/CustomButton/CustomButton.jsx";
import Checkbox from "Front/elements/CustomCheckbox/CustomCheckbox.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loginAction } from "Front/actions/login";
import loadingImg from "Front/assets/img/loading.gif";

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  username: "required",
  password: "required",
};
let mess = {
  required: "This field is required",
};
let validation = [];

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      formData: {
        username: "",
        password: "",
      },
      isLogin: true,
      showProcessing: false,
      //showProcessing: true,
      passwordShown: false,
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleChange = this.handleChange.bind(this);
    this.onForgotPwd = this.onForgotPwd.bind(this);
  }

  b64EncodeUnicode(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
  }

  UnicodeDecodeB64(str) {
    return decodeURIComponent(escape(window.atob(str)));
  }

  componentDidMount() {
    let formData = this.state.formData;
    formData["username"] = this.UnicodeDecodeB64(this.getCookie("username"));
    formData["password"] = this.getCookie("password");
    this.setState({ formData: formData });
    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLogin !== this.props.isLogin) {
      this.setCookie(
        "username",
        this.b64EncodeUnicode(this.state.formData.username),
        364
      );
      this.setCookie(
        "password",
        this.b64EncodeUnicode(this.state.formData.passwordOld),
        364
      );
      this.setCookie("Cookie2", this.b64EncodeUnicode("username"), 364);
      this.setCookie("Cookie4", this.b64EncodeUnicode("password"), 364);
      localStorage.setItem("_id", nextProps.LoginData.data._id);
      localStorage.setItem("chatModel", false);
      localStorage.setItem("userName", nextProps.LoginData.data.userName);
      localStorage.setItem("userType", nextProps.LoginData.data.userType);
      localStorage.setItem("clinicId", nextProps.LoginData.data.clinicId);
      localStorage.setItem("clinicName", nextProps.LoginData.data.clinicName);
      localStorage.setItem(
        "billingCycle",
        nextProps.LoginData.data.billingCycle
      );
      localStorage.setItem(
        "clinicNumber",
        nextProps.LoginData.data.clinicNumber
      );
      localStorage.setItem("email", nextProps.LoginData.data.email);
      localStorage.setItem("status", nextProps.LoginData.data.status);
      localStorage.setItem("token", nextProps.LoginData.data.token);
      localStorage.setItem("degree", nextProps.LoginData.data.degree);
      localStorage.setItem("title", nextProps.LoginData.data.title);
      localStorage.setItem("firstName", nextProps.LoginData.data.firstName);
      localStorage.setItem("lastName", nextProps.LoginData.data.lastName);
      localStorage.setItem("profilePhoto", nextProps.LoginData.data.image);
      localStorage.setItem("report_count", nextProps.LoginData.data?.report_count);
      localStorage.setItem("loginTime", new Date());
      if (nextProps.LoginData.data.clinic) {
        localStorage.setItem(
          "logo",
          nextProps.LoginData.data.clinic.logo_image
        );
        localStorage.setItem(
          "diet_print_format",
          nextProps.LoginData.data.clinic.diet_print_format
        );
        localStorage.setItem(
          "print_header_margin",
          nextProps.LoginData.data.clinic.print_header_margin
        );
        localStorage.setItem(
          "print_bottom_margin",
          nextProps.LoginData.data.clinic.bottom_margin
        );
        localStorage.setItem(
          "left_margin",
          nextProps.LoginData.data.clinic.left_margin
        );
        localStorage.setItem(
          "right_margin",
          nextProps.LoginData.data.clinic.right_margin
        );
        localStorage.setItem(
          "clinicAddress",
          nextProps.LoginData.data.clinic.address.address +
          "," +
          nextProps.LoginData.data.clinic.address.city
        );
        localStorage.setItem(
          "Address",
          nextProps.LoginData.data.clinic.address.address +
          "," +
          nextProps.LoginData.data.clinic.address.city
        );
        localStorage.setItem(
          "clinicPhone",
          nextProps.LoginData.data.clinic.address.phone
            ? nextProps.LoginData.data.clinic.address.phone
            : nextProps.LoginData.data.clinic.phone
        );
        localStorage.setItem(
          "Phone",
          nextProps.LoginData.data.clinic.phone
            ? nextProps.LoginData.data.clinic.phone
            : nextProps.LoginData.data.clinic.address.phone
        );
        localStorage.setItem(
          "clinicEmail",
          nextProps.LoginData.data.clinic.email
        );
        localStorage.setItem(
          "isEmailFacility",
          nextProps.LoginData.data.clinic.is_email_facility
        );
        localStorage.setItem(
          "is_email_facility",
          nextProps.LoginData.data.clinic.is_email_facility
        );
        localStorage.setItem(
          "is_chat_facility",
          nextProps.LoginData.data.clinic.is_chat_facility
        );
        localStorage.setItem(
          "is_print_vital_complaint_diagnosis",
          nextProps.LoginData.data.clinic.is_print_vital_complaint_diagnosis
        );
        localStorage.setItem(
          "is_print_doctor_signature",
          nextProps.LoginData.data.clinic.is_print_doctor_signature
        );
        localStorage.setItem(
          "is_prescription_share_on_app",
          nextProps.LoginData.data.clinic.is_prescription_share_on_app
        );
        localStorage.setItem(
          "is_prescription_edit_by_educator",
          nextProps.LoginData.data.clinic.is_prescription_edit_by_educator
        );
        localStorage.setItem(
          "is_prescription_edit_by_receptionist",
          nextProps.LoginData.data.clinic.is_prescription_edit_by_receptionist
        );
        localStorage.setItem(
          "is_brand_with_ingredients_disable",
          nextProps.LoginData.data.clinic.is_brand_with_ingredients_disable
            ? nextProps.LoginData.data.clinic.is_brand_with_ingredients_disable
            : "no"
        );
        localStorage.setItem(
          "prescription_print_margin_top",
          nextProps.LoginData.data.clinic.prescription_print_margin_top
        );
        localStorage.setItem(
          "prescription_print_margin_bottom",
          nextProps.LoginData.data.clinic.prescription_print_margin_bottom
        );

        localStorage.setItem('prescription_print_margin_left', nextProps.LoginData.data.clinic.prescription_print_margin_left);

        localStorage.setItem(
          "is_week_off",
          JSON.stringify(nextProps.LoginData.data.clinic.week_off)
        );
        localStorage.setItem(
          "holiday_date",
          JSON.stringify(nextProps.LoginData.data.clinic.holiday_date)
        );
        localStorage.setItem(
          "patientNotificationDay",
          nextProps.LoginData.data.clinic.patientNotificationDay
        );
        localStorage.setItem(
          "educatorDietStatus",
          nextProps.LoginData.data.clinic.educatorDietStatus
        );
        localStorage.setItem(
          "language",
          nextProps.LoginData.data.clinic.language
        );
        localStorage.setItem(
          "insulinCalculator",
          nextProps.LoginData.data.clinic.insulinCalculator
        );
        localStorage.setItem(
          "is_calendar_facility",
          nextProps.LoginData.data.clinic.is_calendar_facility
        );
        localStorage.setItem(
          "is_report_folder_facility",
          nextProps.LoginData.data.clinic.is_report_folder_facility
        );
        localStorage.setItem(
          "birthdayWish",
          nextProps.LoginData.data.clinic.birthdayWish
        );
        localStorage.setItem('is_mobile_no_mandatory',
          nextProps.LoginData.data.clinic.is_mobile_no_mandatory !== undefined ? nextProps.LoginData.data.clinic.is_mobile_no_mandatory : "no"
        );
        // localStorage.setItem(
        //   "is_print_complaint",
        //   nextProps.LoginData.data.clinic.is_print_complaint !== undefined ? nextProps.LoginData.data.clinic.is_print_complaint : "no"
        // );

        // localStorage.setItem(
        //   "is_print_diagnosis",
        //   nextProps.LoginData.data.clinic.is_print_diagnosis !== undefined ? nextProps.LoginData.data.clinic.is_print_diagnosis : "no"
        // );

        // localStorage.setItem(
        //   "prescriptionPadSize",
        //   nextProps.LoginData.data.clinic.prescriptionPadSize !== undefined ? nextProps.LoginData.data.clinic.prescriptionPadSize : "a4"
        // );
      }

      if (
        nextProps.LoginData.data.userType === "admin" &&
        nextProps.LoginData.data.userCounts
      ) {
        if (nextProps.LoginData.data.userCounts.length) {
          let userCounts = JSON.stringify(nextProps.LoginData.data.userCounts);
          localStorage.setItem("userCounts", userCounts);
        }

        localStorage.setItem(
          "clinicCount",
          nextProps.LoginData.data.clinicCount
        );
      }

      if (nextProps.LoginData.data.userType === "admin") {
        window.location.href = "/admin/dashboard";
      } else {
        localStorage.setItem(
          "logo",
          nextProps.LoginData.data.clinic.logo_image
        );
        window.location.href = "/dashboard";
      }
    }

    if (nextProps.isLoginError !== this.props.isLoginError) {
      this.setState({
        alert: nextProps.loginMessage.msg,
        showProcessing: false,
      });
    }


  }

  setCookie(cname, cvalue, exdays) {
    cname =
      cname === "username"
        ? "Cookie1"
        : cname === "password"
          ? "Cookie3"
          : cname;
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
      cname + "=" + cvalue + ";" + expires + ";path=/;Secure;HttpOnly";
  }

  getCookie(cname) {
    cname =
      cname === "username"
        ? "Cookie1"
        : cname === "password"
          ? "Cookie3"
          : cname;
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  onForgotPwd() {
    window.location.href = "/forgot-password";
  }

  userLogin(evt) {
    evt.preventDefault();
    const _this = this;
    if (_this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      let formData = _this.state.formData;
      let password =
        _this.getCookie("password") === formData["password"]
          ? _this.UnicodeDecodeB64(_this.getCookie("password"))
          : formData["password"];
      _this.setState({ formData: { ...formData, passwordOld: password } });
      _this.props.loginAction({ formData: { ...formData, password } });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field,
    });
  }

  onKeyDownSubmit(event) {
    let key = event.which || event.keyCode;
    if (key === 13) {
      document.getElementById("login-btn").click();
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <div
                className="actionProcess logPopshow"
                style={{
                  display: this.state.showProcessing ? "block" : "none",
                }}
              >
                <img src={loadingImg} alt="Loading" width="40px" />
                <center>Logging In - Please Wait</center>
              </div>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>User Name</ControlLabel>
                      <FormControl
                        placeholder="Enter User Name"
                        type="email"
                        id="username"
                        name="username"
                        value={this.state.formData.username}
                        autocomplete="off"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          this.onKeyDownSubmit(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.username &&
                          validation.errors.first("username")}
                      </span>
                    </FormGroup>
                    <FormGroup style={{ position: "relative" }}>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type={this.state.passwordShown ? "text" : "password"}
                        id="password"
                        name="password"
                        value={this.state.formData.password}
                        autocomplete="off"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                        onKeyDown={(e) => {
                          this.onKeyDownSubmit(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.formArr.password &&
                          validation.errors.first("password")}
                      </span>
                      <span className="showPassword">
                        {this.state?.passwordShown &&
                          this.state.passwordShown ? (
                          <i
                            className="fa fa-eye"
                            onClick={() =>
                              this.setState({
                                passwordShown: !this.state.passwordShown,
                              })
                            }
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash"
                            onClick={() =>
                              this.setState({
                                passwordShown: !this.state.passwordShown,
                              })
                            }
                            aria-hidden="true"
                          ></i>
                        )}
                      </span>
                    </FormGroup>
                    <FormGroup>
                      <Row className="flex-property">
                        <Col md={6}>
                          <Checkbox number="1" label="Keep me signed in" />
                        </Col>
                        <Col md={6} className="forgot-pwd">
                          <a onClick={this.onForgotPwd}>Forgot Password?</a>
                        </Col>
                      </Row>
                    </FormGroup>
                    {this.state.alert && (
                      <div className="errorMsg"> {this.state.alert} </div>
                    )}
                  </div>
                }
                legend={
                  <Button
                    id={"login-btn"}
                    onClick={this.userLogin.bind(this)}
                    bsStyle="info"
                    fill
                    wd
                  >
                    Login
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,
  };
}
export default withRouter(connect(mapStateToProps, { loginAction })(LoginPage));

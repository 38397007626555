/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Col, Form, Grid } from "react-bootstrap";
//import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/master";
import Loading from "react-loading";
import Radio from "Front/elements/CustomRadio/CustomRadio.jsx";
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
const PreferenceToSet = () => {
  // console.log(localStorage);
  //const [showResults, setShowResults] = React.useState(false);
  const [preferenceTypes, setPreferenceButton] = useState({
    is_chat_facility: localStorage.getItem("is_chat_facility")
      ? localStorage.getItem("is_chat_facility")
      : "no",
    is_calendar_facility: localStorage.getItem("is_calendar_facility")
      ? localStorage.getItem("is_calendar_facility")
      : "no",
    is_report_folder_facility: localStorage.getItem("is_report_folder_facility")
      ? localStorage.getItem("is_report_folder_facility")
      : "no",
    insulinCalculator: localStorage.getItem("insulinCalculator")
      ? localStorage.getItem("insulinCalculator")
      : "no",
    birthdayWish: localStorage.getItem("birthdayWish")
      ? localStorage.getItem("birthdayWish")
      : "no",
    is_mobile_no_mandatory: localStorage.getItem("is_mobile_no_mandatory")
      ? localStorage.getItem("is_mobile_no_mandatory")
      : "no",
  });
  const [successMsg, setSuccessMsg] = useState("");
  //const [formData, setFormData] = useState({});

  const inputHandler = (e, name) => {
    let saveFormData = preferenceTypes;
    saveFormData[e.target.name] = e.target.value;
    setPreferenceButton({ ...preferenceTypes, ...saveFormData });
  };

  //console.log(preferenceTypes);

  const [isLoading, setIsLoading] = useState(false);
  const notificationSystem = useRef(null)
  const saveData = async () => {
    setIsLoading(true);
    let data = preferenceTypes;
    let resp = await API.preferenceToSet(data);
    //console.log(resp);
    if (resp) {
      //setInsulinModel(false);
      localStorage.setItem(
        "is_mobile_no_mandatory",
        preferenceTypes.is_mobile_no_mandatory
      );
      localStorage.setItem(
        "is_chat_facility",
        preferenceTypes.is_chat_facility
      );
      localStorage.setItem(
        "insulinCalculator",
        preferenceTypes.insulinCalculator
      );
      localStorage.setItem(
        "is_calendar_facility",
        preferenceTypes.is_calendar_facility
      );
      localStorage.setItem(
        "is_report_folder_facility",
        preferenceTypes.is_report_folder_facility
      );
      localStorage.setItem("birthdayWish", preferenceTypes.birthdayWish);
      //setSuccessMsg("Data inserted successfully.");
      const notification = notificationSystem.current;
      let msg = "Data inserted successfully."
      notification.addNotification({
        title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
        message: <div>
          {msg}
        </div>,
        level: 'success',
        position: 'tr',
        autoDismiss: 2,
      });

      setTimeout(function () {
        setSuccessMsg("");
        // window.location.reload();
      }, 3000);
      //successAlert('Insulin initial dose set successfully');
      setIsLoading(false);
    } else {
      setSuccessMsg("Something went wrong.");
      setTimeout(function () {
        setSuccessMsg("");
      }, 3000);
    }
  };

  return (
    <>
      <div className="Prefrences-set">
        <NotificationSystem ref={notificationSystem} style={style} />
        <Form>
          <div className="card">
            {/* <h4 className="title">App Related Settings</h4> */}
            <div className="content">
              <div className="row">
                <div className="col-sm-8">
                  {" "}
                  Chat <small>(for mobile app)</small> *
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="1"
                        option="yes"
                        name="is_chat_facility"
                        label="Yes"
                        checked={
                          preferenceTypes.is_chat_facility === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="2"
                        option="no"
                        name="is_chat_facility"
                        label="No"
                        checked={
                          preferenceTypes.is_chat_facility === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-sm-8">
                  {" "}
                  Report <small>(Share by patient from mobile app)</small> *
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="8"
                        option="yes"
                        name="is_report_folder_facility"
                        label="Yes"
                        checked={
                          preferenceTypes.is_report_folder_facility === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="9"
                        option="no"
                        name="is_report_folder_facility"
                        label="No"
                        checked={
                          preferenceTypes.is_report_folder_facility === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">
                  {" "}
                  Insulin Calculator <small>(for mobile app)</small> *
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="11"
                        option="yes"
                        name="insulinCalculator"
                        label="Yes"
                        checked={
                          preferenceTypes.insulinCalculator === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="12"
                        option="no"
                        name="insulinCalculator"
                        label="No"
                        checked={
                          preferenceTypes.insulinCalculator === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">
                  {" "}
                  Calender <small>(for mobile app)</small> *
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="5"
                        option="yes"
                        name="is_calendar_facility"
                        label="Yes"
                        checked={
                          preferenceTypes.is_calendar_facility === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="6"
                        option="no"
                        name="is_calendar_facility"
                        label="No"
                        checked={
                          preferenceTypes.is_calendar_facility === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8"> Birthday Wish *</div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="14"
                        option="yes"
                        name="birthdayWish"
                        label="Yes"
                        checked={
                          preferenceTypes.birthdayWish === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="15"
                        option="no"
                        name="birthdayWish"
                        label="No"
                        checked={
                          preferenceTypes.birthdayWish === "no" ? "checked" : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-8">
                  {" "}
                  Is Mobile Number Mandatory *
                </div>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-4">
                      <Radio
                        number="16"
                        option="yes"
                        name="is_mobile_no_mandatory"
                        label="Yes"
                        checked={
                          preferenceTypes.is_mobile_no_mandatory === "yes"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "yes")}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        number="17"
                        option="no"
                        name="is_mobile_no_mandatory"
                        label="No"
                        checked={
                          preferenceTypes.is_mobile_no_mandatory === "no"
                            ? "checked"
                            : ""
                        }
                        onClick={(e) => inputHandler(e, "no")}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="row">
            <Col sm={12} md={6}>
              <button
                type="button"
                className="btn-fill btn-wd btn btn-info"
                onClick={() => saveData()}
              >
                Save
              </button>
            </Col>
            <Col mdOffset={2} sm={12} md={6}>
              {/* {successMsg !== "" && (
                <p className="alert-heading" style={{ color: "#155724" }}>
                  {successMsg}
                </p>
              )} */}
            </Col>

            <Col sm={12} md={6}>
              {isLoading ? (
                <Loading
                  type="bars"
                  color="#000000"
                  style={{ margin: "0px auto", width: "40px" }}
                />
              ) : (
                ""
              )}</Col>
          </div>

        </Form>
      </div>
    </>
  );
};
export default withRouter(PreferenceToSet);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js' //--Anmol
const initState = {
  response: [],
  message: '',
  type: '',
  isInvitationList: false,
  isInvitation: false,
  isInvitationError: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    //-- Listing
    case actionTypes.GET_CLINIC_INVITATION_REQUEST:
      return {
        ...state,
        type: 'request',
        response: [],
        message: '',
      }
    case actionTypes.GET_CLINIC_INVITATION_SUCCESS:
      return {
        ...state,
        type: "listing",
        response: action.payload.data,
        message: 'Success',
      }
    case actionTypes.GET_CLINIC_INVITATION_ERROR:
      return {
        ...state,
        response: action.payload.response.data,
        message: 'Error',
      }

    //-- AddInvitation
    case actionTypes.ADD_CLINIC_INVITATION_REQUEST:
      return {
        ...state,
        type: 'request',
        response: [],
        message: '',
        isInvitationList: false,
        isInvitation: false,
        isInvitationError: false,
      }
    case actionTypes.ADD_CLINIC_INVITATION_SUCCESS:
      return {
        ...state,
        type: "addInvitation",
        response: action.payload.data,
        message: 'Success',
        isInvitationList: action.payload.data,
        isInvitation: true,
        isInvitationError: false,
      }
    case actionTypes.ADD_CLINIC_INVITATION_ERROR:
      return {
        ...state,
        response: action.payload.response.data,
        message: 'Error',
        isInvitationList: false,
        isInvitation: false,
        isInvitationError: true,
      }

    //-- EditInvitation
    case actionTypes.EDIT_CLINIC_INVITATION_REQUEST:
      return {
        ...state,
        type: 'request',
        response: [],
        message: '',
        isInvitationList: false,
        isInvitation: false,
        isInvitationError: false,
      }
    case actionTypes.EDIT_CLINIC_INVITATION_SUCCESS:
      return {
        ...state,
        type: "editInvitation",
        response: action.payload.data,
        message: 'Success',
        isInvitationList: action.payload.data,
        isInvitation: true,
        isInvitationError: false,
      }
    case actionTypes.EDIT_CLINIC_INVITATION_ERROR:
      return {
        ...state,
        response: action.payload.response.data,
        message: 'Error',
        isInvitationList: false,
        isInvitation: false,
        isInvitationError: true,
      }

    //-- Invitation Send-Mail
    case actionTypes.INVITATION_SENDMAIL_REQUEST:
      return {
        ...state,
        type: 'request',
        response: [],
        message: '',
      }
    case actionTypes.INVITATION_SENDMAIL_SUCCESS:
      return {
        ...state,
        type: "invitationSendMail",
        response: action.payload.data,
        message: 'Success',
      }
    case actionTypes.INVITATION_SENDMAIL_ERROR:
      return {
        ...state,
        response: action.payload.response.data,
        message: 'Error',
      }

    default:
      return state
  }
}

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
//import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { uploadFileAction } from 'Admin/actions/link';
import { addServingSizeAction } from 'Admin/actions/serving_size';
//import { addFoodCategoryAction } from 'Admin/actions/food_category';
import { languageListAction } from 'Admin/actions/language';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name_1: 'required',
    //ut_1: 'required',
    //utensil: 'required',
    //file: 'required',
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddServingSize extends Component {

    constructor(props) {
        super(props);

        this.state = {
            languageList: [],
            cardHidden: true,
            isLoading: false,
            formArr: [],
            nameError: null,
            name_englishError: null,
            ut_englishError: null,
            fileError: null,
            formData: {
                name: {},
                utensil: {},
                image: ""
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.languageListAction(data);
        //this.props.handleClick('success', "Sucessfully saved");

    }
    componentWillReceiveProps(nextProps) {


        if (nextProps.isAddServingSizeError !== this.props.isAddServingSizeError) {
            if (nextProps.AddServingSizeMessage.errors) {
                nextProps.AddServingSizeMessage.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }

        if (nextProps.isUploadFile !== this.props.isUploadFile) {
            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {
            this.setState({ languageList: nextProps.LanguageList.data });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg })
                });
            }
        }

        if (nextProps.isAddServingSize !== this.props.isAddServingSize) {
            this.props.handleClick('success', nextProps.msg)
            this.props.history.push('/admin/serving-size-list');
        }
    }

    handleChange(e, language) {
        e.preventDefault();
        let field = this.state.formData;
        field.name[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleChangeUtensil(e, language) {
        e.preventDefault();
        let field = this.state.formData;
        field.utensil[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };


    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];
        this.props.uploadFileAction(file);
        reader.readAsDataURL(file);
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addServingSize(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        const _this = this;
        if (this.allValidate(false)) {
            let field = this.state.formData;
            field.image = field.file;
            this.setState({ formData: field });
            _this.setState({ showProcessing: true });
            _this.props.addServingSizeAction(field);
        }
        //validation.errors()
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;
        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                QTY unit name <span className="star">*</span>
                                            </Col>
                                            <Col sm={6} className="spcc">
                                                {this.state.languageList.map(function (lang) {
                                                    return <><label>{lang.name}</label><FormControl className="spc-in" type="text" name={'name_' + lang.lanId} id={'name_' + lang.lanId} placeholder={lang.name} onChange={(event) => { _this.handleChange(event, lang.lanId) }} /></>
                                                })}
                                                <span className="errorMsg">
                                                    {this.state.name_1}
                                                    {this.state.formArr.name_1 && validation.errors.first('name_1')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Utensil name <span className="star">*</span>
                                            </Col>
                                            <Col sm={6} className="spcc">
                                                {this.state.languageList.map(function (lang) {
                                                    return <><label>{lang.name}</label><FormControl className="spc-in" type="text" name={'ut_' + lang.lanId} id={'ut_' + lang.lanId} placeholder={lang.name} onChange={(event) => { _this.handleChangeUtensil(event, lang.lanId) }} /></>
                                                })}
                                                <span className="errorMsg">
                                                    {this.state.ut_1}
                                                    {this.state.formArr.ut_1 && validation.errors.first('ut_1')}</span>
                                            </Col>

                                        </FormGroup>


                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Image <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <input type="file" name="file" onChange={e => { this.fileChangedHandler(e, "file") }}></input>
                                                <span className="errorMsg">
                                                    {this.state.fileError}
                                                    {this.state.formArr.file && validation.errors.first('file')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.addServingSize.bind(this)}
                                                    className="btn-fill btn-wd btn btn-primary"
                                                    disabled={isLoading || this.props.disabled}
                                                >
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Save
                                                </button>&nbsp;
                                            </Col>
                                        </FormGroup>

                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.servingSize.message,

        AddServingSize: state.servingSize.AddServingSize,
        isAddServingSize: state.servingSize.isAddServingSize,
        isAddServingSizeError: state.servingSize.isAddServingSizeError,

        isUploadFile: state.link.isUploadFile,
        isUploadFileError: state.link.isUploadFileError,
        uploadFile: state.link.uploadFile,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,
    }
}
export default withRouter(connect(mapStateToProps, { addServingSizeAction, uploadFileAction, languageListAction })(AddServingSize));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import banner_img from 'Front/assets/img/banner_2.png';
import mdz_main from 'Front/assets/img/mdz-main.png';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';

class Medzing extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <Media>
        <div className="aahar-ban">
          <div class="banner_content">
            <h2>Medzing</h2>
          </div>
        </div>

        <Grid>
          <div class="about-us">
            <h2>Medzing</h2>
          </div>

          <div class="PracticeAid-pera">
            <p>Medzing is an <span>Integrated Clinic Management System </span> that coordinates all activities involved in the management and running of a healthcare facility.</p>
          </div>

          <div class="solutionHighlights">
            <ul>
              <li><i className="glyphicon glyphicon-ok"></i> Solution available on web and mobile platforms.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Coordinates all activities involved in the management and running of a healthcare facility.</li>
            </ul>
          </div>

          <Col>
            <img width={'100%'} height={'100%'} src={mdz_main} alt="medzing" />
          </Col>
          <div class="middle-spc"></div>
          <div class="solutionHighlights">
            <h4>Solution Highlights:</h4>
            <ul>
              <li><i className="glyphicon glyphicon-ok"></i> Reviews patient care, takes appropriate clinical decisions & develop treatment plans.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Improves result management and patient care.</li>
              <ul>
                <li><i className="glyphicon glyphicon-menu-right"></i>Reduced margin of error</li>
              </ul>
              <li><i className="glyphicon glyphicon-ok"></i> Customizable and scalable electronic medical records.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Complete SaaS based Web App for managing Patient’s Appointment, Billing, EMR, Consultation, Tele-Consultation.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Manages various investigation reports such as Pathology, Radiology, etc.</li>
            </ul>
          </div>

          <div class="solutionHighlights">
            <h4>Medzing – Modules</h4>
            <ul>
              <li><i className="glyphicon glyphicon-ok"></i> Appointment- Doctor’s availability, appointment slots.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Billing- Different or concessional billing for old and new patients.</li>
              <ul>
                <li><i className="glyphicon glyphicon-menu-right"></i>Consultation Room – Tags to assign tasks and consultation rooms to patients</li>
              </ul>
              <li><i className="glyphicon glyphicon-ok"></i> Task Management – Tests and investigations advised to patients.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Pathology tests.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Investigation tests.</li>
              <li><i className="glyphicon glyphicon-ok"></i> EMR / Prescription.</li>
              <li><i className="glyphicon glyphicon-ok"></i> IPD Management.</li>
            </ul>
          </div>
        </Grid>
        <div class="middle-spc"></div>
      </Media>
    );
  }
}

function mapStateToProps(state) {

  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Medzing));

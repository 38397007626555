/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FillEMRic from "../../assets/img/icons/prescription.png";

const EmptyDetails = (props) => {
  const data = useSelector(store => store.taskScreen)
  const [dietAdvised, setDietAdvised] = useState("")

  useEffect(() => {
    if (data)
      setDietAdvised(data.visitData.lastDiet[0]?.treatmentName)
  }, [props])
  //console.log(props, "props")
  return (

    <div >
      <div className="patient-details details-empty">
        <div className="details-inners">
          <Link className='aside-cards-style' to={{ pathname: `vital-sign/${props.patientVisitData.id}/${props.patientVisitData.pId}/${props.patientVisitData.language}` }}>
            <img src={FillEMRic} alt="" />
            Fill EMR Details
          </Link>
        </div>
      </div>
      <div style={{ padding: "0px 5px", marginTop: "20px" }}>
        {dietAdvised &&
          <div>
            <span ><strong>Diet Advised</strong>: <span>
            </span>{dietAdvised}</span>
          </div>
        }
      </div>
    </div>


  )
}

export default EmptyDetails

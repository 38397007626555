/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { withRouter, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import {
    Row, Col,
    Modal, Form, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import * as API from 'Front/api/emr';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import NotificationSystem from 'react-notification-system';
import { style } from "Admin/variables/Variables.jsx";
import { ACTIONS } from './Prescription';
import moment from 'moment';
import ImageComponent from '../../../../components/SecurityUrl/ImageComponent';
import { NavLink } from 'react-router-dom';

const PrintPrescription = () => {

    let { appointmentId, patientId, serachType, nextVisitDate } = useParams()
    const [onPageLoad, setOnPageLoad] = useState(true)
    const [patinetData, setPatientData] = useState({})
    const [vitalData, setVitalData] = useState({})
    const [compalintData, setComplaintData] = useState([])
    const [diagnosisData, setDiagnosisData] = useState([])
    const [recommendedPrescriptionSettingData, setRecommendedPrescriptionSettingData] = useState([])
    const [patientPresciptionData, setPatientPresciptionData] = useState([])
    const [clinicData, setClinicData] = useState([])
    const [doctorImage, setDoctorImage] = useState('')
    const [title, setTitle] = useState(localStorage.getItem("title"));
    const [phoneNo, setPhoneNo] = useState("")
    const [valueInMl, setValueInMl] = useState("")
    const [isVitalDataExist, setIsVitalDataExist] = useState(false)
    const ref = useRef();
    const getPrescriptionPrint = async () => {
        let resp = await API.prescriptionPrint({ patientId: patientId, appointmentId: appointmentId });
        if (resp.data.status === "Success" && resp.data.statusCode === 200) {
            if (resp.data.vitalData.length > 0) {
                setVitalData(resp.data?.vitalData[0])
                setIsVitalDataExist(true)
            }
            if (resp.data.patientData.length > 0)
                setPatientData(resp.data?.patientData[0])
            setComplaintData(resp.data?.complainData)
            setDiagnosisData(resp.data?.diagonsisData)
            if (resp.data?.recommendedPrescriptionSettingData.length > 0)
                setRecommendedPrescriptionSettingData(resp.data?.recommendedPrescriptionSettingData[0].recommendedPrescription)
            setPatientPresciptionData(resp.data?.patientPresciptionData)
            if (resp.data?.clinicData.length > 0) {
                setClinicData(resp.data?.clinicData[0])
                setDoctorImage(resp.data?.clinicData[0].logo_image)
                setPhoneNo(resp.data?.clinicData[0].address.phone)
            }
        }
    }

    const getValueInMl = (value) => {
        if (value !== "") {
            let res = value * 5
            setValueInMl(res)
        }
    }
    const calculateBmi = (height, weight) => {

        let height_in_m = height / 100;
        if (height_in_m > 0 && weight > 0) {
            let bmi = weight / (height_in_m * height_in_m);
            return bmi.toFixed(2);
        } else {
            return;
        }
    }

    const getInsulinDoseTime = (ele) => {
        return (
            ele.map((obj) => {
                return (<span>{obj.key}
                </span>);
            })

        )
    }



    const print = () => {
        const collection = document.getElementsByClassName("footer");
        collection[0].style.display = 'none';
        document.getElementById('btnSavePrescription').style.display = 'none';
        document.getElementById('divPreview').style.display = 'none';
        window.print();
        document.getElementById('btnSavePrescription').style.display = 'block';
        document.getElementById('divPreview').style.display = 'block';
        collection[0].style.display = 'block';

    }

    useEffect(() => {
        if (onPageLoad)
            getPrescriptionPrint()
        setOnPageLoad(false)
    }, [])

    return (
        <span>
            <div id="printable" data-template-type="html" className='ui-sortable'>
                <div className='pad-y print-table' style={{ fontFamily: 'Roboto', fontSize: '14px', position: 'relative', width: '100%' }}>
                    {/* <table>
                                <tr>
                                    <td style={{padding: '25px auto'}}></td> 
                                </tr>
                            </table> */}
                    <div className='title_header' id="divPreview" >
                        <NavLink className="btn btn-fill btn-secondary " to={`/Prescription/${appointmentId}/${patientId}`}  >
                            <span >Back11</span>
                        </NavLink>
                        <h5 style={{ fontWeight: '500' }}>Print Preview</h5>
                    </div>
                    <table style={{ margin: "0px auto", border: '0', width: '100%' }}>

                        <tbody>
                            <tr className='thead'>
                                <th className='px-0' style={{ border: '0', padding: '0' }}>
                                    <div className='bg-header'
                                        style={{ backgroundColor: '#a4a4a4', borderRadius: '6px', color: 'black' }}>
                                        <table
                                            style={{ border: '0', borderRadius: '6px', color: 'black', fontWeight: 'normal', width: '100%', padding: '10px', backgroundColor: '#a4a4a4' }}
                                            className="">
                                            <thead>
                                                <tr>
                                                    <td className='txt-black px-5 py-0' style={{ textAlign: 'left', color: 'black', padding: '4px 10px', border: '0' }}>Name:
                                                        <span className='txt-black' >Mr. {patinetData.firstName} {patinetData.lastName}</span></td>
                                                    <td className='txt-black px-5 py-0' style={{ border: '0', color: 'black', padding: '2px 15px' }}>Age: {patinetData.age} <span className='txt-black' >Y</span> / {patinetData.gender === "Male" ? " M" : " F"} </td>
                                                    <td className='txt-black px-5 py-0' style={{ border: '0', textAlign: 'right', color: 'black', padding: '4px 10px' }}>Date:
                                                        {<span className='txt-black' >{moment(new Date()).format('DD-MMM-YYYY')} </span>}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className='txt-black px-5 py-0'
                                                        style={{ border: '0', textAlign: 'left', color: 'black', padding: '4px 10px' }}>City:
                                                        <span className='txt-black px-5 py-0'>{patinetData.city}</span></td>
                                                    <td className='txt-black px-5 py-0' style={{ border: '0', textAlign: 'right', color: 'black', padding: '4px 10px' }}>Patient
                                                        Id: <span className='txt-black'>{patinetData.patientClinicId}</span></td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <td style={{ border: '0' }}>
                                    <table style={{ fontWeight: 'normal', width: '100%', border: '0', margin: '10px 0' }} >
                                        <thead>
                                            <tr>
                                                {vitalData.height !== 0 &&
                                                    <td style={{ border: '0' }}><strong>Height</strong>: <span>{isVitalDataExist ? vitalData.height : patinetData.height}</span> CM</td>
                                                }
                                                {vitalData.weight !== 0 &&
                                                    <td style={{ border: '0' }}><strong>Weight</strong>: <span>{isVitalDataExist ? vitalData.weight : patinetData.weight}</span> KG</td>
                                                }
                                                {vitalData.height !== 0 && vitalData.weight !== 0 &&
                                                    <td style={{ border: '0' }}><strong>BMI</strong>: <span>{isVitalDataExist ? calculateBmi(vitalData.height, vitalData.weight) : calculateBmi(patinetData.height, patinetData.weight)}</span></td>
                                                }
                                                {vitalData.bp !== '0/0' && vitalData.bp !== '/' &&
                                                    <td style={{ border: '0' }}><strong>BP</strong>: <span>{vitalData.bp}</span> mmHg</td>
                                                }
                                                {vitalData.pulse !== 0 &&
                                                    <td style={{ border: '0' }}><strong>Pulse</strong>: <span>{vitalData.pulse}</span>/min</td>
                                                }
                                                {vitalData.temprature !== 0 &&
                                                    <td style={{ border: '0' }}><strong>Temprature</strong>: <span>{vitalData.temprature}</span> F</td>
                                                }

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0' }}>
                                    <table style={{ fontWeight: 'normal', width: '100%', border: '0' }}>
                                        <thead>
                                            <tr>
                                                <td style={{ border: '0' }}><strong>Complaint</strong>: <span>
                                                    {compalintData.map((complaint, idx) => (
                                                        <span>
                                                            {complaint.symptomsSubCategoryName}
                                                            {
                                                                compalintData.length - 1 !== idx ? " | " : ""
                                                            }
                                                        </span>
                                                    )

                                                    )}
                                                </span></td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: '0' }}><strong>Diagnosis</strong>:
                                                    <span>
                                                        {diagnosisData.map((diagnosis, idx) => (
                                                            <span>{diagnosis.diagonsisSubCategoryName}
                                                                {
                                                                    diagnosisData.length - 1 !== idx ? " | " : ""
                                                                }

                                                            </span>
                                                        )

                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td className='py-15' style={{ border: '0' }}>
                                    <table style={{ width: '100%', margin: '5px 0', border: '0' }}>
                                        <thead>
                                            <tr>
                                                <td style={{ fontSize: '18px', border: '0' }}><strong>Rx</strong></td>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td className='p-5 bg-grey mb-10' style={{ fontSize: '12px', border: '0' }}>
                                    <table style={{ width: '100%', border: '0' }}>
                                        <thead>
                                            <tr>
                                                {recommendedPrescriptionSettingData.map((itm, idx) => (
                                                    <td style={{ border: '0' }}>
                                                        {itm.recommendedType.map((obj, key) => {
                                                            if (obj.top !== undefined && obj.top == 1) {
                                                                return (<span className='flex-itm'><span>●</span> {itm.recommendedPrescription}</span>)
                                                            }

                                                        })}
                                                    </td>
                                                )

                                                )}

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '0' }}>
                                    <table className='p-td-10' style={{ width: '100%', border: '0' }}>
                                        <thead style={{ verticalAlign: 'top' }}>
                                            {
                                                patientPresciptionData.map((itm, idx) => {
                                                    return (<tr>
                                                        <td style={{ border: '0', borderBottom: '1px dashed #000', width: '40%' }}>
                                                            <div className="t-drug-type"><strong>{++idx}. {itm.drugType} − {serachType === "brand" ? itm.drugNameWithDose : serachType === "barndwithingredients" ? itm.drugNameWithDose + "(" + itm.drugNameWithIngredients + ")" : itm.drugNameWithIngredients} </strong> </div>
                                                            <div className="t-dose-time">
                                                                {itm.doseTimeSlot.map((ele, key) => {

                                                                    return (
                                                                        <span>{
                                                                            ele.customizeFrequency.map((subEle, subKey) => {

                                                                                return (<span>{subEle.key === "." ? <strong>{subEle.key}</strong> : subEle.key}</span>)

                                                                            })}
                                                                            {itm.drugType === "INJ" || itm.drugType === "INSULIN" ? " U" : itm.drugType === "SYP" ? " TSF" : ""} {ele.doseTimeSlotName}{itm.doseTimeSlot.length - 1 !== key ? " −" : ""} </span>
                                                                    )

                                                                })}
                                                            </div>
                                                        </td>
                                                        <td style={{ textAlign: 'left', border: '0', borderBottom: '1px dashed #000' }}>
                                                            {itm.instructionName.map((ele1, key1) => {
                                                                return (<span className="t-instruction">
                                                                    ({ele1.instructionName})
                                                                </span>)
                                                            })}
                                                            <span className="t-duration">{itm.duration.no !== "" ? "- For" : ""} {itm.duration.no} {itm.duration.period} </span>
                                                        </td>
                                                    </tr>
                                                    )

                                                })
                                            }

                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td className='pt-10' style={{ border: '0' }}>
                                    <table style={{ width: '100%', border: '0' }}>
                                        <thead>
                                            <tr style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {recommendedPrescriptionSettingData.map((itm, idx) => (
                                                    <td style={{ border: '0' }}>
                                                        {itm.recommendedType.map((obj, key) => {
                                                            if (obj.bottom !== undefined && obj.bottom == 1) {
                                                                return (<span className='pl-5 flex-itm'> <span> ● </span>  {itm.recommendedPrescription}</span>)
                                                            }

                                                        })}
                                                    </td>
                                                )

                                                )}

                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            {patinetData.nextDate &&
                                <tr>
                                    <td className='py-15' style={{ border: '0' }}>
                                        <table style={{ width: '100%', border: '0' }}>
                                            <thead>
                                                <tr>

                                                    <td style={{ border: '0' }} className="investigation-advised"><strong>Next Visit Date :
                                                        <span> {moment(patinetData.nextDate).format('DD-MMM-YYYY')}</span></strong></td>

                                                </tr>
                                            </thead>
                                        </table>
                                        <div className="clearfix"></div>
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td style={{ border: '0' }} >
                                    <table style={{ width: '100%', position: 'relative', bottom: '0', border: '0' }}>
                                        <tr>
                                            <td style={{ border: '0' }}>
                                                <table style={{ width: '100%', border: '0' }}>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ border: '0', fontSize: '16px' }}>{title} {clinicData.doctorName}   {clinicData.mci_number && <sapn>(<strong>MCI No : </strong> {clinicData.mci_number})</sapn>}</td>

                                                        </tr>
                                                        {/* {clinicData.mci_number &&
                                                                  <tr>
                                                                     <td style={{border: '0',marginTop:'15px', display:'block'}}></td>
                                                                  </tr>
                                                                } */}

                                                    </thead>
                                                </table>
                                                <div className="clearfix"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '0' }}>
                                                <table style={{ width: '100%', border: '0' }}>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ border: '0', padding: '10px 0' }}>
                                                                {/* {doctorImage &&
                                                                            <ImageComponent
                                                                                data={doctorImage}
                                                                                style={{width: '150px'}}
                                                                            />
                                                                        } */}

                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                                <div className="clearfix"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='mt-15' style={{ fontWeight: '600', marginTop: '20px', border: '0', display: 'block', fontSize: '16px' }}>
                                                {/* The claims with which the time limit has not been written, keep taking them till
                                                        further consultation. <br /> */}
                                                For appointment contact {phoneNo}
                                            </td>
                                        </tr>
                                    </table>
                                    <div className="clearfix"></div>
                                </td>
                            </tr>
                            {/* <Button  style={{float:"Right", border:'0'}}  type="button" id="btnSavePrescription" onClick={() => { print() }} className="btn btn-fill btn-primary">Print</Button> */}

                        </tbody>
                        <br />
                        <br />
                        <br />
                        <div style={{ textAlign: "center", fontWeight: 'bold', fontSize: '16px' }}>Only for the use of the patient named above and only up to the quantity prescribed.</div>
                    </table>
                </div>
            </div>

        </span>
    )
}

export default PrintPrescription
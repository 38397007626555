/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { servingSizeListAction, servingSizeStatusAction, servingSizeImageStatusAction } from 'Admin/actions/serving_size';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import SecurityUrlComponent from '../../../components/Common/SecurityUrlComponent';
// import { appConstants } from '../../../Front/_constants/app.constants.js';
import { appConstants } from 'Admin/_constants/app.constants';

var count = 1;

class ServingSizeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servingSizeList: [],
            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
        this.onservingSizeImage = this.onservingSizeImage.bind(this);
    }

    componentDidMount() {
        this.props.servingSizeListAction(this.state.formData);
    }

    componentWillReceiveProps(nextProps) {
        let listData = [];
        let i = 0;

        if (nextProps.isServingSizeList !== this.props.isServingSizeList) {
            this.setState({
                servingSizeList: nextProps.ServingSizeList.data.data,
                totalCount: nextProps.ServingSizeList.data.count
            });
        }

        // if(nextProps.servingSizeStatusAction && nextProps.isServingSizeChangeStatus){
        //     this.props.handleClick('success',nextProps.msg)
        // }

        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }

    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    editButton(cell, row, enumObject, rowIndex) {
        return (<p><Link to={{ pathname: `update-serving-size/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
            {/*<a href="javascript:void(0)"><i className="fa fa-trash-o" aria-hidden="true"></i></a> */}
        </p>)
    }



    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.servingSizeStatusAction(event);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }


    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        //this.props.educatorListAction('',formData);
        this.props.servingSizeListAction('', formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        }

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.servingSizeListAction(data)
        
        if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
            eval('$(".ps").scrollTop(0)');
          }
    }


    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.servingSizeListAction(data);
            }
        } else {
            setTimeout(() => {      
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    sortHandle(){
        this.setState({ asc_desc:!this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc? "asc":"desc"
        this.setState({ formData : data });
        this.props.servingSizeListAction(data);
    }

    showImage(cell, row, enumObject, rowIndex) {
        if (row.show_image) {
            return (<p><input type="checkbox" checked id={row._id} onChange={this.imageShowStatus.bind(this, row)} /></p>)
        } else {
            return (<p><input type="checkbox" id={row._id} onChange={this.imageShowStatus.bind(this, row)} /></p>)
        }
    }

    imageShowStatus(data) {
        this.props.servingSizeImageStatusAction(data);
    }



    onservingSizeImage(cell, row, enumObject, rowIndex) {

        if (row && row.image) {
            return (
                <SecurityUrlComponent
                    className="activeImage"
                    data={row.image}
                    type="img"
                />)

            //<img className="activeImage" src={appConstants.s3UploadUrl+row.image} />)
        } else {
            return 'No image.'
        }
    }

    unitData(cell, row, enumObject, rowIndex) {
        return row.name && row.name['1'] ? row.name['1'] : '';
    }
    utensilData(cell, row, enumObject, rowIndex) {
        return row.utensil && row.utensil['1'] ? row.utensil['1'] : '';
    }


    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {
        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
        };

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6}>
                                                <Button bsStyle="info" fill pullRight onClick={() => this.props.history.replace('add-serving-size')}>Add Serving Size</Button>
                                            </Col>
                                        </Row>&nbsp;

                                        <div className="table-resposive educator-L">
                                            <div className='search-box-admin'>
                                                <div className='search'>
                                                    <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                        <span className='input-group-btn'>
                                                        <Button onClick={(e) => { this.onSearchChange(e.target.value, "null")}}>Clear</Button> 
                                                        </span>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                selectRow="{selectRowProp}"
                                                deleteRow={false}
                                                data={this.state.servingSizeList}
                                                // search={true}
                                                multiColumnSearch={true}
                                                pagination={true}
                                                options={options}
                                                striped
                                                hover
                                                condensed
                                                scrollTop={'Bottom'}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '3%', textAlign: 'center' }} tdStyle={{ width: '3%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'name' }} dataFormat={this.unitData.bind(this)}   >Unit
                                                {
                                                    this.state.asc_desc?
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                                                }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'utensil' }} dataFormat={this.utensilData.bind(this)} >Utensil</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Image' }} dataField='' dataFormat={this.onservingSizeImage}  >Clinic Image</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'show_image' }} dataFormat={this.showImage.bind(this)} >Show Image</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataFormat={appConstants.ucFirst} dataField='status' >Status</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} >Action</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

        msg: state.servingSize.message,

        ServingSizeChangeStatus: state.servingSize.ServingSizeChangeStatus,
        isServingSizeChangeStatus: state.servingSize.isServingSizeChangeStatus,
        isServingSizeChangeStatusError: state.servingSize.isServingSizeChangeStatusError,

        ServingSizeImageStatus: state.servingSize.ServingSizeImageStatus,
        isServingSizeImageStatus: state.servingSize.isServingSizeImageStatus,
        isServingSizeImageStatusError: state.servingSize.isServingSizeImageStatusError,

        ServingSizeList: state.servingSize.ServingSizeList,
        isServingSizeList: state.servingSize.isServingSizeList,
        isServingSizeListError: state.servingSize.isServingSizeListError,


    }
}
export default withRouter(connect(mapStateToProps, { servingSizeListAction, servingSizeStatusAction, servingSizeImageStatusAction })(ServingSizeList));

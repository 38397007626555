/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/language'

/* Language list  */
export const LanguageListRequest = () => ({
    type: actionTypes.GET_LANGUAGE_LIST_REQUEST
})
export const LanguageListSuccess = (response) => ({
    type: actionTypes.GET_LANGUAGE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const LanguageListError = (error) => ({
    type: actionTypes.GET_LANGUAGE_LIST_ERROR,
    payload: {
        error
    }
})
export const languageListAction = (data) => {
    return dispatch => {
        dispatch(LanguageListRequest())

        return API.languageList(data)
            .then(response => {
                dispatch(LanguageListSuccess(response.data.data))
            })
            .catch(error => {
                dispatch(LanguageListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Language Status Change  */
export const LanguageChangeStatusRequest = () => ({
    type: actionTypes.GET_LANGUAGE_CHANGE_STATUS_REQUEST
})
export const LanguageChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_LANGUAGE_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const LanguageChangeStatusError = (error) => ({
    type: actionTypes.GET_LANGUAGE_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const languagechangestatusAction = (data) => {

    return dispatch => {
        dispatch(LanguageChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeLanguageStatus(FormData)
            .then(response => {

                dispatch(LanguageChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(LanguageChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}



//Upload File
export const uploadFileRequest = () => ({
    type: actionTypes.POST_UPLOAD_FILE_REQUEST
})
export const uploadFileSuccess = (response) => ({
    type: actionTypes.GET_UPLOAD_FILE_SUCCESS,
    payload: {
        response,
    }
})
export const uploadFileError = (error) => ({
    type: actionTypes.GET_UPLOAD_FILE_ERROR,
    payload: {
        error
    }
})
export const uploadFileAction = (data) => {
    return dispatch => {
        dispatch(uploadFileRequest())
        // const FormData = {
        //     file:data
        // }

        let formData = new FormData();
        formData.append('file', data);
        return API.uploadFile(formData)
            .then(response => {
                dispatch(uploadFileSuccess(response.data))
            })
            .catch(error => {
                dispatch(uploadFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}



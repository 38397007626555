/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
  response: [],
  message: '',

  isAddFaq: false,
  isAddFaqError: false,
  AddFaq: false,

  isAddKnowledge: false,
  isAddKnowledgeError: false,
  AddKnowledge: false,

  isAddClinicLink: false,
  isAddClinicLinkError: false,
  AddClinicLink: false,

  isAddEducatorMsg: false,
  isAddEducatorMsgError: false,
  AddEducatorMsg: false,

  isChangePassword: false,
  isChangePasswordError: false,
  ChangePassword: false,

  isChangeUserPassword: false,
  isChangeUserPasswordError: false,
  ChangeUserPassword: false,

  isAddClinicHoliday: false,
  isAddClinicHolidayError: false,
  AddClinicHoliday: false,

  isAddContactUs: false,
  isAddContactUsError: false,
  AddContactUs: false,

  isKnowledgeClinicShow: false,
  isKnowledgeClinicShowError: false,
  KnowledgeClinicShow: false,

  isLinkClinicShow: false,
  isLinkClinicShowError: false,
  LinkClinicShow: false,

  isMessageClinicShow: false,
  isMessageClinicShowError: false,
  MessageClinicShow: false,

  isClinicHolidayHistory: false,
  isClinicHolidayHistoryError: false,
  clinicHolidayHistory: false,

}

export default (state = initState, action = {}) => {
  switch (action.type) {

    //Upload file
    case actionTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        message: '',
        isAddFaq: false,
        isAddFaqError: false,
      }
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        message: 'FAQ Added',
        uploadFile: action.payload.response,
        isAddFaq: true,
        isAddFaqError: false,
      }
    case actionTypes.UPLOAD_FILE_ERROR:
      return {
        ...state,
        AddFaq: action.payload.error,
        message: action.payload.error,
        isAddFaq: false,
        isAddFaqError: true
      }


    //Upload file
    case actionTypes.ADD_KNOWLEDGE_REQUEST:
      return {
        ...state,
        message: '',
        isAddKnowledge: false,
        isAddKnowledgeError: false,
      }
    case actionTypes.ADD_KNOWLEDGE_SUCCESS:
      return {
        ...state,
        message: 'FAQ Added',
        AddKnowledge: action.payload.response,
        isAddKnowledge: true,
        isAddKnowledgeError: false,
      }
    case actionTypes.ADD_KNOWLEDGE_ERROR:
      return {
        ...state,
        AddFaq: action.payload.error,
        message: action.payload.error,
        isAddKnowledge: false,
        isAddKnowledgeError: true
      }

    //Upload file
    case actionTypes.ADD_CLINIC_LINK_REQUEST:
      return {
        ...state,
        message: '',
        isAddClinicLink: false,
        isAddClinicLinkError: false,
      }
    case actionTypes.ADD_CLINIC_LINK_SUCCESS:
      return {
        ...state,
        message: 'FAQ Added',
        AddClinicLink: action.payload.response,
        isAddClinicLink: true,
        isAddClinicLinkError: false,
      }
    case actionTypes.ADD_CLINIC_LINK_ERROR:
      return {
        ...state,
        AddFaq: action.payload.error,
        message: action.payload.error,
        isAddClinicLink: false,
        isAddClinicLinkError: true
      }

    //Upload file
    case actionTypes.ADD_EDUCATOR_MSG_REQUEST:
      return {
        ...state,
        message: '',
        isAddEducatorMsg: false,
        isAddEducatorMsgError: false,
      }
    case actionTypes.ADD_EDUCATOR_MSG_SUCCESS:
      return {
        ...state,
        message: 'Educator Msg Added',
        AddEducatorMsg: action.payload.response,
        isAddEducatorMsg: true,
        isAddEducatorMsgError: false,
      }
    case actionTypes.ADD_EDUCATOR_MSG_ERROR:
      return {
        ...state,
        AddFaq: action.payload.error,
        message: action.payload.error,
        isAddEducatorMsg: false,
        isAddEducatorMsgError: true
      }


    //Change Password Doctor
    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        isChangePassword: false,
        isChangePasswordError: false,
      }
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        message: 'Password Changed Successfully',
        ChangePassword: action.payload.response,
        isChangePassword: true,
        isChangePasswordError: false,
      }
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        ChangePassword: action.payload.error,
        message: action.payload.error,
        isChangePassword: false,
        isChangePasswordError: true
      }

    //Change Password Doctor

    //Change Password User
    case actionTypes.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        message: '',
        isChangeUserPassword: false,
        isChangeUserPasswordError: false,
      }
    case actionTypes.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        message: 'Password Changed Successfully',
        ChangeUserPassword: action.payload.response,
        isChangeUserPassword: true,
        isChangeUserPasswordError: false,
      }
    case actionTypes.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        ChangeUserPassword: action.payload.error,
        message: action.payload.error,
        isChangeUserPassword: false,
        isChangeUserPasswordError: true
      }

    //Change Password Doctor
    case actionTypes.ADD_CLINIC_HOLIDAY_REQUEST:
      return {
        ...state,
        message: '',
        isAddClinicHoliday: false,
        isAddClinicHolidayError: false,
      }
    case actionTypes.ADD_CLINIC_HOLIDAY_SUCCESS:
      return {
        ...state,
        message: 'Clinic holiday added successfully',
        AddClinicHoliday: action.payload.response,
        isAddClinicHoliday: true,
        isAddClinicHolidayError: false,
      }
    case actionTypes.ADD_CLINIC_HOLIDAY_ERROR:
      return {
        ...state,
        ChangePassword: action.payload.error,
        message: action.payload.error,
        isAddClinicHoliday: false,
        isAddClinicHolidayError: true
      }

    // add conatct us
    case actionTypes.ADD_CONTACT_US_REQUEST:
      return {
        ...state,
        message: '',
        isAddContactUs: false,
        isAddContactUsError: false,
      }
    case actionTypes.ADD_CONTACT_US_SUCCESS:
      return {
        ...state,
        message: 'Contact us added successfully',
        AddContactUs: action.payload.response,
        isAddContactUs: true,
        isAddContactUsError: false,
      }
    case actionTypes.ADD_CONTACT_US_ERROR:
      return {
        ...state,
        message: action.payload.error,
        isAddContactUs: false,
        isAddContactUsError: true
      }

    // KnowledgeClinicShow

    case actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isKnowledgeClinicShow: false,
        isKnowledgeClinicShowError: false,
      }
    case actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Knowledge Status Changed',
        KnowledgeClinicShow: action.payload.response,
        isKnowledgeClinicShow: true,
        isKnowledgeClinicShowError: false,
      }
    case actionTypes.GET_KNOWLEDGE_CLINIC_SHOW_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isKnowledgeClinicShow: false,
        isKnowledgeClinicShowError: true
      }


    // LinkClinicShow

    case actionTypes.GET_LINK_CLINIC_SHOW_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isLinkClinicShow: false,
        isLinkClinicShowError: false,
      }
    case actionTypes.GET_LINK_CLINIC_SHOW_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Link Status Changed',
        LinkClinicShow: action.payload.response,
        isLinkClinicShow: true,
        isLinkClinicShowError: false,
      }
    case actionTypes.GET_LINK_CLINIC_SHOW_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLinkClinicShow: false,
        isLinkClinicShowError: true
      }

    // MessageClinicShow

    case actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isMessageClinicShow: false,
        isMessageClinicShowError: false,
      }
    case actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Message Status Changed',
        MessageClinicShow: action.payload.response,
        isMessageClinicShow: true,
        isMessageClinicShowError: false,
      }
    case actionTypes.GET_MESSAGE_CLINIC_SHOW_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isMessageClinicShow: false,
        isMessageClinicShowError: true
      }

    // Clinic holiday history

    case actionTypes.GET_CLINIC_HOLIDAY_HISTORY_REQUEST:
      return {
        ...state,
        message: '',
        isClinicHolidayHistory: false,
        isClinicHolidayHistoryError: false,
        clinicHolidayHistory: false,
      }
    case actionTypes.GET_CLINIC_HOLIDAY_HISTORY_SUCCESS:
      return {
        ...state,
        message: 'Data listed successfully!',
        clinicHolidayHistory: action.payload.response,
        isClinicHolidayHistory: true,
        isClinicHolidayHistoryError: false,
      }
    case actionTypes.GET_CLINIC_HOLIDAY_HISTORY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicHolidayHistory: false,
        isClinicHolidayHistoryError: true
      }


    default:
      return state
  }
}

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Row, Col, Modal, Table
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import VideoDocumentView from 'Front/views/TaskScreen/VideoDocumentView.jsx';
import { categoryListAction } from 'Front/actions/home';
import { knowledgeListAction } from 'Front/actions/home';
import { printShareDocumentAction } from 'Front/actions/home';
import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import { appConstants } from 'Front/_constants/app.constants.js';
import Diet from 'Front/views/Diet/Diet';
import { getBMI } from 'Front/views/Home/PublicFunction.jsx';
var addVisitCalled = false;
class KnowledgeShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            knowledgeModal: false,
            knowledgeList: {},
            categoryList: [],
            patientName: this.props.pvDetail.name,
            patientId: this.props.pvDetail.patientId,
            knowledgeCategory: '',
            currentPatient: '',
            dietTitle: '',
            dietModal: false,
            edType: this.props.edType,
        };

        this.submitTask = this.submitTask.bind(this);
        this.backPage = this.backPage.bind(this);
        this.knowledgeListFun = this.knowledgeListFun.bind(this);

        let _this = this;
        appConstants.socket.on('updateData', function (res) {
            if (res.type === 'knowledgeList') {
                setTimeout(function () {
                    _this.props.knowledgeListAction(this.state);
                }, 1000);
            }
        });
    }

    knowledgeListFun(data) {
        let knowledgeList = {};

        for (let x in data) {
            if (data[x].showClinics.length > 0) {
                let local_index = data[x].showClinics.indexOf(localStorage.getItem('clinicId'));
                if (local_index !== -1) {
                    data[x].clinicShowStatus = false;
                } else {
                    data[x].clinicShowStatus = true;

                }
            }


            for (let y in data[x].category) {
                if (!knowledgeList[data[x].category[y].id]) {
                    knowledgeList[data[x].category[y].id] = [];
                }
                knowledgeList[data[x].category[y].id].push(data[x]);
            }
        }

        this.setState({ knowledgeList });
    }

    componentDidMount() {

        if (this.props.CategoryList) {
            this.setState({ categoryList: this.props.CategoryList.data.data });
        } else {
            this.props.categoryListAction(this.state);
        }


        // if (this.props.KnowledgeList) {
        //
        //     this.knowledgeListFun(this.props.KnowledgeList.data.data);
        // } else {
        //
        this.props.knowledgeListAction(this.state)
        //}

    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.isVisitDetail !== this.props.isVisitDetail) {
            // let tempTaskList=this.state.TaskList
            // tempTaskList.documents=nextProps.visitDetail.Documents
            // tempTaskList.tasks=nextProps.visitDetail.Tasks
            // let tempLastDiet=nextProps.visitDetail.patient_data[0].lastDiet
            // this.setState({ TaskList: tempTaskList,lastDiet:tempLastDiet });


        }

        if (nextProps.isPrintShareDocument !== this.props.isPrintShareDocument && addVisitCalled) {
            addVisitCalled = false
            this.setState({ knowledgeModal: false });
            setTimeout(function () {
                let params = {
                    clinicId: localStorage.getItem('clinicId'),
                }
                appConstants.socket.emit('updateTaskScreen', params);

            }, 1000);
        }

        if (nextProps.isKnowledgeList !== this.props.isKnowledgeList) {
            this.knowledgeListFun(nextProps.KnowledgeList.data.data);
        }

        if (nextProps.isCategoryList !== this.props.isCategoryList) {
            var catListData = nextProps.CategoryList.data.data;
            var catData = [];
            for (let i in catListData) {
                if (catListData[i].name === 'T2') {
                    catData[0] = catListData[i]
                } else if (catListData[i].name === 'T1') {
                    catData[1] = catListData[i];
                } else if (catListData[i].name === 'Insulin Training') {
                    catData[2] = catListData[i];
                } else if (catListData[i].name === 'Pregnancy Diabetes') {
                    catData[3] = catListData[i];
                } else if (catListData[i].name === 'General') {
                    catData[4] = catListData[i];
                }
            }
            this.setState({ categoryList: catListData });
        }
    }

    viewVideoDocument(link, type) {

        return (<VideoDocumentView
            pvDetail={this.props.pvDetail}
            link={link}
            type={type}
            ViewModal={true}
            status={1}
        />);
    }

    documentPrint() {
        this.setState({ knowledgeModal: true });
        let documents = this.props.documents;
        let formData = [];
        formData['patient_id'] = this.props.pvDetail.pId;
        formData['visit_id'] = this.props.pvDetail.id;
        formData['type'] = 'print';
        formData['documents'] = documents;
        formData['videos'] = [];

        this.props.printShareDocumentAction(formData);

        let newWin = window.open('', 'Print-Window');
        newWin.document.open();
        newWin.document.write('<html><body onload="window.print()">');
        for (let x in documents) {

            newWin.document.write('<img style="width:100%;margin:20px;" src="' + appConstants.s3UploadUrl + documents[x].link + '"/>');

        }
        newWin.document.write('</body></html>');
        newWin.document.close();
        setTimeout(function () { newWin.close(); }, 10);

    }

    dietBox(patient) {
        this.setState({ dietModal: true, currentPatient: patient });
        this.setDietTitle(patient);
    }

    setDietTitle(patient) {
        let title = "Patient Diet (" + patient.name + " - " + patient.patientNo + ")";
        title += " | H:" + patient.height;
        title += " | W:" + patient.weight;
        title += " | BMI:" + getBMI(patient.height, patient.weight);
        title += " | Age:" + patient.age;
        this.setState({ dietTitle: title });
    }

    submitTask(e) {
        this.props.submitTask(e);
        this.setState({ knowledgeModal: false });
    }

    backPage() {
        this.setState({ knowledgeModal: false });
    }

    render() {
        const documents = this.props.documents;
        const videos = this.props.videos;

        return (
            <div className="chart_flexs knowledge-share-cards">
                {
                    this.state.categoryList.map((key, i) => {
                        let data;
                        if (key.name) {
                            data = (<a className='knowledge-share-card' onClick={() => this.setState({ knowledgeModal: true, knowledgeCategory: key._id })} key={key._id}>
                                <img alt="" style={{ width: '30px', margin: '0px 8px 0px 0px' }} src={"../../../images/" + key.image} /><span className='knowledge-share-card-title'>{key.name} {i > 1 ? "" : "Diabetes"}</span>
                            </a>
                            )
                        }
                        return data;
                    })
                }
                {/*<Col sm={2} onClick={this.dietBox.bind(this, this.props.pvDetail)}>
                    <img alt="" style={{ width: '30px', margin: '0px 10px 0px 0px' }} src="../../../images/food.png" /><span>Aahaar</span>
                </Col> */}

                <Modal show={this.state.knowledgeModal} onHide={() => this.setState({ knowledgeModal: false })} dialogClassName="modal-lg top-patient">
                    <Modal.Header closeButton className="bg-headershare">
                        <Modal.Title id="example-modal-sizes-title-lg">Patient Knowledge Center({this.props.pvDetail.name} - {this.props.pvDetail.patientId})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="Knowledge-Share table-responsive">


                        <Table className="table table-striped table-hover table-bordered popline-boxes">
                            <thead className="flip-content bordered-palegreen">
                                <tr>
                                    <th >VIDEOS</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className=" right-lines">

                                        {
                                            this.state.knowledgeList && this.state.knowledgeList[this.state.knowledgeCategory] && this.state.knowledgeList[this.state.knowledgeCategory].map((key, i) => {
                                                let data = '';
                                                if (key.title && key.type === 'video') {
                                                    let checked = false;
                                                    for (let x in videos) {
                                                        if (videos[x].id === key._id) {
                                                            checked = true;
                                                        }
                                                    }

                                                    if (key.clinicShowStatus) {
                                                        data = (
                                                            <Row key={key._id} >
                                                                <Col >
                                                                    <div>
                                                                        <Checkbox
                                                                            number={key._id}
                                                                            label={key.title}
                                                                            checked={checked}
                                                                            onClick={e => { this.props.handleKnowledgeVideoChange(key); }}
                                                                        />

                                                                        <VideoDocumentView
                                                                            pvDetail={this.props.pvDetail}
                                                                            link={key.image}
                                                                            name={""}
                                                                            type={'video'}
                                                                            status={0}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }

                                                }
                                                return data;
                                            })
                                        }
                                    </td>

                                </tr>
                            </tbody>
                        </Table>

                        <Table className="table table-striped table-hover table-bordered popline-boxes">
                            <thead className="flip-content bordered-palegreen">
                                <tr>
                                    <th >DOCUMENTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className=" sapcelineheight">
                                        {
                                            this.state.knowledgeList && this.state.knowledgeList[this.state.knowledgeCategory] && this.state.knowledgeList[this.state.knowledgeCategory].map((key, i) => {
                                                let data = '';
                                                if (key.title && key.type === 'image') {
                                                    let checked = false;
                                                    for (let x in documents) {
                                                        if (documents[x].id === key._id) {
                                                            checked = true
                                                        }
                                                    }

                                                    if (key.clinicShowStatus) {
                                                        data = (<Row key={key._id}>
                                                            <Col sm={12}>
                                                                <div>
                                                                    <Checkbox
                                                                        number={key._id}
                                                                        label={key.title}
                                                                        checked={checked}
                                                                        onClick={e => { this.props.handleKnowledgeDocChange(key); }}
                                                                    />

                                                                    <VideoDocumentView
                                                                        pvDetail={this.props.pvDetail}
                                                                        link={key.image}
                                                                        name={""}
                                                                        type={'image'}
                                                                        status={0}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        )
                                                    }
                                                }
                                                return data;
                                            })
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*<Button className="btn-fill btn-wd btn btn-default pull-left" onClick={(e) => { this.documentPrint() }}>Print</Button> */}
                        {(localStorage.getItem('userType') === 'educator' && this.state.edType === 'educatorType') &&
                            <Button className="btn-fill btn btn-primary pull-right m-rspace" onClick={e => { this.submitTask(e); addVisitCalled = true; }}>Submit</Button>
                        }
                        {localStorage.getItem('userType') === 'doctor' &&
                            <Button className="btn-fill btn btn-primary pull-right" onClick={this.backPage.bind(this)}>Save</Button>
                        }
                    </Modal.Footer>
                </Modal>

                {/* Diet model */}
                <Modal className="pa-diet-screen Patient_dite_popup" show={this.state.dietModal} onHide={() => this.setState({ dietModal: false })} dialogClassName="modal-lg">
                    <Modal.Header closeButton className="header-designsahar">
                        <Modal.Title id="example-modal-sizes-title-lg ">{this.state.dietTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col md={12}>
                                <Diet patient={this.state.currentPatient} />
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>
                {/* /Diet model */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        CategoryList: state.home.categoryList,
        isCategoryList: state.home.isCategoryList,
        isCategoryListError: state.home.isCategoryListError,

        isVisitDetail: state.home.isVisitDetail,
        isVisitDetailError: state.home.isVisitDetailError,
        visitDetail: state.home.visitDetail,

        KnowledgeList: state.home.knowledgeList,
        isKnowledgeList: state.home.isKnowledgeList,
        isKnowledgeListError: state.home.isKnowledgeListError,

        PrintShareDocument: state.home.printShareDocument,
        isPrintShareDocument: state.home.isPrintShareDocument,
        isPrintShareDocumentError: state.home.isPrintShareDocumentError,
    }
}
export default withRouter(connect(mapStateToProps, { categoryListAction, knowledgeListAction, printShareDocumentAction })(KnowledgeShare));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import BillingCycle from "../BillingCycle/BillingCycle";
import PreferenceToSet from "../PreferenceToSet/PreferenceToSet";
import PrescriptionSetting from "../PrescriptionSetting/PrescriptionSetting";
import ManageRequest from "../ManageRequest/ManageRequest";
import NotificationList from "../Notification/NotificationList";
import UserList from "../UserList/UserList";
import ChangePassword from "../ChangePassword/ChangePassword";
import DoctorInfo from "../DoctorInfo/DoctorInfo";
import ColorNotations from "../ColorNotations/ColorNotations";
import Tickets from "../SupportSystem/Tickets";
import Tutorials from "../Tutorials/Tutorials";
import Report from "../Report/Report";
import LabelAndStarStatus from "../LabelAndStarStatus/LabelAndStarStatus";

const Settings = (props) => {
  const [activeTab, setActiveTab] = useState("consultationTab");
  const [activeSideButton, setActiveSideButton] = useState(
    "consultationButtonActive"
  );
  return (
    <>
      <div className="main-content settings-wrapper">
        <Grid fluid>
          <Card
            // title={"Settings"}
            content={
              <div className="tabs-wrapper">
                <div className="tabs-btns-group">
                  <button
                    className={
                      activeTab == "consultationTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => {
                      setActiveTab("consultationTab");
                      setActiveSideButton("consultationButtonActive");
                    }}
                  >
                    {/* Consultation Validity Period & Calendar */}
                    Consultation Validity Period
                  </button>
                  <button
                    className={
                      activeTab == "clinicTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => setActiveTab("clinicTab")}
                  >
                    Clinic Preferences
                  </button>
                  <button
                    className={
                      activeTab == "prescriptionTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => setActiveTab("prescriptionTab")}
                  >
                    Prescription Setting
                  </button>
                  <button
                    className={
                      activeTab == "videoDocTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => {
                      setActiveTab("videoDocTab");
                      setActiveSideButton("videoButtonActive");
                    }}
                  >
                    Video/Doc uploads, Notification & Educator Message
                  </button>
                  <button
                    className={
                      activeTab == "clinicPasswordTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => {
                      setActiveTab("clinicPasswordTab");
                      setActiveSideButton("userListButtonActive");
                    }}
                  >
                    Clinic Password Management
                  </button>
                  <button
                    className={
                      activeTab == "userManualTab"
                        ? "btn-tab tab-active"
                        : "btn-tab"
                    }
                    type="button"
                    onClick={() => {
                      setActiveTab("userManualTab");
                      setActiveSideButton("colorButtonActive");
                    }}
                  >
                    User Manual & Support
                  </button>
                </div>
                <div className="tabs-content">
                  <div className="tabs-content-inner">
                    <div className="tabs-inside-tabs">
                      {/* If Consultation validity Period active */}
                      {activeTab == "consultationTab" && (
                        <>
                          <button
                            className={
                              activeSideButton == "consultationButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("consultationButtonActive")
                            }
                          >
                            Consultation Validity Period
                          </button>
                          {/* <button
                            className={
                              activeSideButton == "holidaysButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("holidaysButtonActive")
                            }
                          >
                            Calendar Holidays Absence day entry
                          </button> */}
                        </>
                      )}

                      {/* end */}

                      {/* If document-upload active */}
                      {activeTab == "videoDocTab" && (
                        <>
                          <button
                            className={
                              activeSideButton == "videoButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() => {
                              setActiveSideButton("videoButtonActive");
                              props.location.pathname = "/manage-request";
                            }}
                          >
                            Video/Doc uploads
                          </button>
                          <button
                            className={
                              activeSideButton == "editEducatorButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() => {
                              setActiveSideButton("editEducatorButtonActive");
                              props.location.pathname = "/educator-message";
                            }}
                          >
                            Edit Educator Message
                          </button>
                          <button
                            className={
                              activeSideButton == "notificationButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() => {
                              setActiveSideButton("notificationButtonActive");
                              // props.location.pathname = "/notification";
                            }}
                          >
                            Notification Master
                          </button>
                          {/* <button
                            className={
                              activeSideButton == "chatButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("chatButtonActive")
                            }
                          >
                            Chat message master
                          </button> */}
                        </>
                      )}
                      {/* end */}

                      {/* If clinic password management active */}
                      {activeTab == "clinicPasswordTab" && (
                        <>
                          <button
                            className={
                              activeSideButton == "userListButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("userListButtonActive")
                            }
                          >
                            User List
                          </button>
                          <button
                            className={
                              activeSideButton == "doctorPasswordButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("doctorPasswordButtonActive")
                            }
                          >
                            Doctor Password
                          </button>
                          {/* <button
                            className={
                              activeSideButton == "doctorInfoButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("doctorInfoButtonActive")
                            }
                          >
                            Doctor Info
                          </button> */}
                        </>
                      )}
                      {/* end */}

                      {/* If User Manual & Support active */}
                      {activeTab == "userManualTab" && (
                        <>
                          <button
                            className={
                              activeSideButton == "colorButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("colorButtonActive")
                            }
                          >
                            Colour and Symbol Meaning
                          </button>
                          <button
                            className={
                              activeSideButton == "labelAndStarStatusButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("labelAndStarStatusButtonActive")
                            }
                          >
                            Label And Star Meaning
                          </button>
                          <button
                            className={
                              activeSideButton == "supportButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("supportButtonActive")
                            }
                          >
                            Support Ticket
                          </button>


                          {/* <button
                            className={
                              activeSideButton == "reportButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("reportButtonActive")
                            }
                          >
                           Report
                          </button> */}
                          {/* <button
                            className={
                              activeSideButton == "tutorialsButtonActive"
                                ? "btn-tab tab-active"
                                : "btn-tab"
                            }
                            type="button"
                            onClick={() =>
                              setActiveSideButton("tutorialsButtonActive")
                            }
                          >
                            Tutorials
                          </button> */}
                        </>
                      )}
                      {/* end */}
                    </div>
                    <div className="tabs-inside-tabs-content">
                      {activeTab == "consultationTab" &&
                        activeSideButton == "consultationButtonActive" && (
                          <div className="consultation-validity-period">
                            <BillingCycle />
                          </div>
                        )}

                      {activeTab == "clinicTab" && (
                        <div className="clinic-preferences-tab">
                          <PreferenceToSet />
                        </div>
                      )}

                      {activeTab == "prescriptionTab" && (
                        <div className="prescription-tab">
                          <PrescriptionSetting />
                        </div>
                      )}

                      {activeTab == "videoDocTab" && (
                        <div className="document-upload-tab">
                          {/* for Video/Doc upload */}
                          {activeSideButton == "videoButtonActive" && (
                            <ManageRequest />
                          )}

                          {/* for Edit Educator message */}
                          {activeSideButton == "editEducatorButtonActive" && (
                            <ManageRequest />
                          )}

                          {/* for Notification Master */}
                          {activeSideButton == "notificationButtonActive" && (
                            <NotificationList />
                          )}
                        </div>
                      )}

                      {activeTab == "clinicPasswordTab" && (
                        <div className="clinic-password-management-tab">
                          {/* for user list */}
                          {activeSideButton == "userListButtonActive" && (
                            <UserList />
                          )}

                          {/* for Doctor password */}
                          {activeSideButton == "doctorPasswordButtonActive" && (
                            <ChangePassword />
                          )}

                          {/* for Doctor Info */}
                          {activeSideButton == "doctorInfoButtonActive" && (
                            <DoctorInfo />
                          )}
                        </div>
                      )}

                      {activeTab == "userManualTab" && (
                        <div className="clinic-password-management-tab">
                          {activeSideButton == "colorButtonActive" && (
                            <ColorNotations />
                          )}
                          {activeSideButton == "labelAndStarStatusButtonActive" && (
                            <LabelAndStarStatus />
                          )}
                          {activeSideButton == "supportButtonActive" && (
                            <Tickets />
                          )}

                          {activeSideButton == "reportButtonActive" && (
                            <Report />
                          )}

                          {activeSideButton == "tutorialsButtonActive" && (
                            <Tutorials />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </Grid>
      </div>
    </>
  );
};

export default Settings;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddTreatmentType: false,
  isAddTreatmentTypeError: false,
  AddTreatmentType: false,
  isUpdateTreatmentType: false,
  isUpdateTreatmentTypeError: false,
  UpdateTreatmentType: false,
  isTreatmentTypeChangeStatus: false,
  isTreatmentTypeChangeStatusError: false,
  TreatmentTypeChangeStatus: false,
  isTreatmentTypeList: false,
  isTreatmentTypeListError: false,
  TreatmentTypeList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_TREATMENT_TYPE_REQUEST:
      return {
        ...state,
        message: '',
        isAddTreatmentType: false,
        isAddTreatmentTypeError: false,
      }
    case actionTypes.GET_ADD_TREATMENT_TYPE_SUCCESS:
      return {
        ...state,
        message: 'TreatmentType Added Successfully',
        AddTreatmentType: action.payload.response,
        isAddTreatmentType: true,
        isAddTreatmentTypeError: false,
      }
    case actionTypes.GET_ADD_TREATMENT_TYPE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddTreatmentType: false,
        isAddTreatmentTypeError: true
      }

    //TreatmentType Update

    case actionTypes.GET_UPDATE_TREATMENT_TYPE_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateTreatmentType: false,
        isUpdateTreatmentTypeError: false,
      }
    case actionTypes.GET_UPDATE_TREATMENT_TYPE_SUCCESS:
      return {
        ...state,
        message: 'TreatmentType Updated Successfully',
        UpdateTreatmentType: action.payload.response,
        isUpdateTreatmentType: true,
        isUpdateTreatmentTypeError: false,
      }
    case actionTypes.GET_UPDATE_TREATMENT_TYPE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateTreatmentType: false,
        isUpdateTreatmentTypeError: true
      }

    //TreatmentType List
    case actionTypes.GET_TREATMENT_TYPE_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isTreatmentTypeList: false,
        isTreatmentTypeListError: false,
      }
    case actionTypes.GET_TREATMENT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        message: 'TreatmentType List',
        TreatmentTypeList: action.payload.response,
        isTreatmentTypeList: true,
        isTreatmentTypeListError: false,
      }
    case actionTypes.GET_TREATMENT_TYPE_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isTreatmentTypeList: false,
        isTreatmentTypeListError: true
      }

    //TreatmentType Status Change
    case actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isTreatmentTypeChangeStatus: false,
        isTreatmentTypeChangeStatusError: false,
      }
    case actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'TreatmentType Status Changed',
        TreatmentTypeChangeStatus: action.payload.response,
        isTreatmentTypeChangeStatus: true,
        isTreatmentTypeChangeStatusError: false,
      }
    case actionTypes.GET_TREATMENT_TYPE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isTreatmentTypeChangeStatus: false,
        isTreatmentTypeChangeStatusError: true
      }





    default:
      return state
  }
}

/********* Show list of TreatmentType */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
//import Datetime from 'react-datetime';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { weekOptions, patternFontOptions, appTimeSlot } from 'Admin/variables/Variables.jsx';
import { uploadFileAction, countryListAction, stateListAction, patternListAction } from 'Admin/actions/master';
import { specializationListAction } from 'Admin/actions/specialization';
import { updateClinicAction } from 'Admin/actions/clinic';
import loadingImg from 'Admin/assets/img/loading.gif';
import moment from 'moment';
//import $ from "jquery";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { appConstants } from 'Front/_constants/app.constants.js';
import PatternPreview from './PatternPreview.jsx';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import { languageListAction } from 'Admin/actions/language';
import ImageComponent from '../../components/Common/ImageComponent.js';

var isUpdateApiCalled = false;
let Validator = require('validatorjs');
let formArr = {}
let rules = {
    clinic_name: 'required',
    email: 'required|email',
    pattern: 'required',
    appointment_notification: 'numeric',
    latitude: 'numeric',
    longitude: 'numeric',
    margin_top: 'numeric',
    margin_bottom: 'numeric',
    margin_left: 'numeric',
    margin_right: 'numeric',
    limit: 'numeric',
    slot: 'required',
    doctorName: 'required',
    degree: 'required',
    address11: 'required',
    city: 'required',
    state: 'required',
    pin_code: 'numeric',
    phone: 'required|digits_between:10,12',
    mobile: 'digits_between:10,12',
    appointmentno: 'digits_between:10,12',
    phone22: 'digits_between:10,12',
    mobile22: 'digits_between:10,12',
    appointmentno22: 'digits_between:10,12',

};
let mess = {
    required: 'This field is required',
    email: 'Invalid Email',
    numeric: 'Enter numeric digits',
    digits: 'Enter numeric 10 digits',
    digits_between: 'Phone no. must be between 10 and 12 digits'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();


let newObj = {};
let newObj1 = {};

class UpdateClinic extends Component {
    constructor(props) {
        super(props);

        this.vForm = this.refs.vForm;
        this.state = {
            formArr: [],
            isLogin: true,
            showProcessing: false,
            send_email: '',
            specialization: [],
            week_off: [],
            imagePreviewUrl: '',
            selectedDays: [],
            formData: {},
            stateList: [],
            countryList: [],
            SpecializationList: [],
            country_id: '',
            uploaded_file: '',
            multiInput: false,
            patientNotificationDay: 1,
            educatorDietStatus: 'no',
            endTime1Error: '',
            endTime2Error: '',
            startTime1: '',
            endTime1: '',
            startTime2: '',
            endTime2: '',
            morning_from1: '',
            morning_till1: '',
            evening_from1: '',
            evening_till1: '',
            phone_cb: false,
            mobile_cb: false,
            appointment_cb: false,
            emergency_cb: false,
            phone_cb1: false,
            mobile_cb1: false,
            appointment_cb1: false,
            emergency_cb1: false,
            clinicFont: null,
            addressFont: null,
            limitForm: {},
            start1Error: '',
            start2Error: '',
            end1Error: '',
            end2Error: '',
            morningFormError: '',
            morningTillError: '',
            eveningFormError: '',
            eveningTillError: '',
            clinicView: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
            patternList: [],
            city: "",
            address11: "",
            phone: "",
            mobile: "",
            appointmentno: "",
            phone22: "",
            mobile22: "",
            appointmentno22: "",
            clinic_websiteError: "",
            validationError: false,
            mci_number: "",
            languageOptions: [],
            languageList: [],
            clinic_email: "",
            onPageLoad: false,
            onPageLoadClinic: false,
            onPageLoadDoctor: false,
            saveBtnClicked: false
        }

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.selectMultiData = this.selectMultiData.bind(this);
        this.handleClinicFontChange = this.handleClinicFontChange.bind(this);
        this.handleAddressFontChange = this.handleAddressFontChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.openPatternModal = this.openPatternModal.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleAddress2Change = this.handleAddress2Change.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleCheckboxChange1 = this.handleCheckboxChange1.bind(this);


        if (this.props.location.state && this.props.location.state._id) {
            localStorage.setItem('_id_clinic', this.props.location.state._id);
        }

        /*let _this = this;
         $(document).mouseup(function (e) {
             var container = $(".multiDateP");
 
             if (!container.is(e.target) && container.has(e.target).length === 0) {
                 _this.setState({ multiInput: false });
             }
         });*/


    }

    componentDidMount() {

        this.props.languageListAction({ limit: 100, offset: 0, order: "name", direction: "asc" });

        let holiday_date = this.props.location.state && this.props.location.state.holiday_date ? this.props.location.state.holiday_date : "";

        let dates = [];
        const { selectedDays } = this.state;

        if (this.props && holiday_date) {
            for (let i in holiday_date) {
                if (holiday_date[i] !== null) {
                    let day = new Date(holiday_date[i]);
                    selectedDays.push(day);
                    var s1 = moment(new Date(holiday_date[i])).format('MM-DD-YYYY');

                    dates.push(s1);
                }
            }
        }

        this.setState({ selectedDays });

        let address = this.props.location.state && this.props.location.state.address;


        newObj = {
            "morning_from": address.morning_from ? address.morning_from : '',
            "morning_till": address.morning_till ? address.morning_till : '',
            "evening_from": address.evening_from ? address.evening_from : '',
            "evening_till": address.evening_till ? address.evening_till : '',
            "address": address.address ? address.address : '',
            "city": address.city ? address.city : '',
            "state": address.state ? address.state : '',
            "stateId": address.stateId ? address.stateId : '',
            "country": address.country ? address.country : '',
            "countryId": address.countryId ? address.countryId : '',
            "pin_code": address.pin_code ? address.pin_code : '',
            "latitude": address.latitude ? address.latitude : '',
            "longitude": address.longitude ? address.longitude : '',
            "phone": address.phone ? address.phone : '',
            "mobile": address.mobile ? address.mobile : '',
            "appointmentno": address.appointmentno ? address.appointmentno : '',
            "appointmentNoShow": address.appointmentNoShow ? address.appointmentNoShow : false,
            "phoneNoShow": address.phoneNoShow ? address.phoneNoShow : false,
            "mobileNoShow": address.mobileNoShow ? address.mobileNoShow : false,
        }

        let address2 = this.props.location.state && this.props.location.state.address2;

        newObj1 = {
            "morning_from": address2.morning_from ? address2.morning_from : '',
            "morning_till": address2.morning_till ? address2.morning_till : '',
            "evening_from": address2.evening_from ? address2.evening_from : '',
            "evening_till": address2.evening_till ? address2.evening_till : '',
            "address": address2.address ? address2.address : '',
            "city": address2.city ? address2.city : '',
            "state": address2.state ? address2.state : '',
            "stateId": address2.stateId ? address2.stateId : '',
            "country": address2.country ? address2.country : '',
            "countryId": address2.countryId ? address2.countryId : '',
            "pin_code": address2.pin_code ? address2.pin_code : '',
            "latitude": address2.latitude ? address2.latitude : '',
            "longitude": address2.longitude ? address2.longitude : '',
            "phone": address2.phone ? address2.phone : '',
            "mobile": address2.mobile ? address2.mobile : '',
            "appointmentno": address2.appointmentno ? address2.appointmentno : '',
            "appointmentNoShow": address2.appointmentNoShow ? address2.appointmentNoShow : false,
            "phoneNoShow": address2.phoneNoShow ? address2.phoneNoShow : false,
            "mobileNoShow": address2.mobileNoShow ? address2.mobileNoShow : false,
        }

        var formData = {
            'id': this.props.location.state._id,
            'doctorName': this.props.location.state.doctorName,
            'degree': this.props.location.state.degree,
            'clinic_name': this.props.location.state.name,
            'short_name': this.props.location.state.short_name,
            'clinic_website': this.props.location.state.web,
            'phoneno': this.props.location.state.phone,
            'mobile_no': this.props.location.state.mobile,
            'appointment_no': this.props.location.state.appointmentno,
            'emergency_no': this.props.location.state.emergency,
            'email': this.props.location.state.email,
            'pattern': this.props.location.state.pattern,
            'email_facility': this.props.location.state.is_email_facility,
            'chat_facility': this.props.location.state.is_chat_facility,
            'data_updated': this.props.location.state.data_updated,
            'status': this.props.location.state.status,
            'appointment_notification': this.props.location.state.appointment_reminder,
            'diet_print_format': this.props.location.state.diet_print_format,
            'margin_top': this.props.location.state.print_header_margin,
            'margin_bottom': this.props.location.state.bottom_margin,
            'margin_left': this.props.location.state.left_margin,
            'margin_right': this.props.location.state.right_margin,
            'week_off': this.props.location.state.week_off,
            'specialization': this.props.location.state.specializations,
            'holiday_date': dates.toString(),
            'background_image': this.props.location.state.background_image,
            'contact_image': this.props.location.state.contact_image,
            'logo_image': this.props.location.state.logo_image,
            'doctor_image': this.props.location.state.doctor_image,
            'logo': this.props.location.state.web,
            'description': this.props.location.state.description,
            'insulinCalculator': this.props.location.state.insulinCalculator,
            'clinic_name_font': this.props.location.state.clinic_name_font,
            'address_font': this.props.location.state.address_font,
            'limit': this.props.location.state.limit,
            'slot': this.props.location.state.slot ? this.props.location.state.slot : 15,
            'appointmentNoShow': this.props.location.state.appointmentNoShow,
            'phoneNoShow': this.props.location.state.phoneNoShow,
            'mobileNoShow': this.props.location.state.mobileNoShow,
            'emergencyShow': this.props.location.state.emergencyShow,
            'patientNotificationDay': this.props.location.state.patientNotificationDay,
            'educatorDietStatus': this.props.location.state.educatorDietStatus,
            "mci_number": this.props.location.state.mci_number,
            "birthdayWish": this.props.location.state.birthdayWish,
            "birthdayContent": this.props.location.state.birthdayContent,
            "language": this.props.location.state.language,
            'address': newObj,
            'address2': newObj1,
            "address11": address.address ? address.address : '',
            "city": address.city ? address.city : '',
            "state": address.state ? address.state : '',
            "pin_code": address.pin_code ? address.pin_code : '',
            "phone": address.phone ? address.phone : '',
            "mobile": address.mobile ? address.mobile : '',
            "appointmentno": address.appointmentno ? address.appointmentno : '',
            "pin_code22": address2.pin_code ? address2.pin_code : '',
            "phone22": address2.phone ? address2.phone : '',
            "mobile22": address2.mobile ? address2.mobile : '',
            "clinic_email": this.props.location.state.clinic_email
        }

        this.setState({
            appointment_cb: newObj.appointmentNoShow,
            phone_cb: newObj.phoneNoShow,
            mobile_cb: newObj.mobileNoShow,
            emergency_cb: newObj.emergencyShow,
            appointment_cb1: newObj1.appointmentNoShow,
            phone_cb1: newObj1.phoneNoShow,
            mobile_cb1: newObj1.mobileNoShow,
            emergency_cb1: newObj1.emergencyShow,
        })

        if (this.props.location.state.logo_image !== "") {
            this.setState({ imagePreviewUrl: appConstants.s3UploadUrl + this.props.location.state.logo_image })
        }
        if (this.props.location.state.contact_image !== "") {
            this.setState({ imagePreviewUrlContact: appConstants.s3UploadUrl + this.props.location.state.contact_image })
        }
        if (this.props.location.state.background_image !== "") {
            this.setState({ imagePreviewUrlClinic: appConstants.s3UploadUrl + this.props.location.state.background_image })
        }
        if (this.props.location.state.doctor_image !== "") {
            this.setState({ imagePreviewUrlDoctor: appConstants.s3UploadUrl + this.props.location.state.doctor_image })
        }

        this.setState({ formData: formData });
        let speSel = this.state.specialization;

        if (this.props.location.state && this.props.location.state.specializations && this.props.location.state.specializations.length > 0) {
            this.props.location.state.specializations.map((key, index) =>
                speSel.push({
                    "value": key.id,
                    "label": key.name
                })
            );
        }
        this.setState({ specialization: speSel });

        let week_off = this.state.week_off;

        if (this.props.location.state && this.props.location.state.week_off && this.props.location.state.week_off.length > 0) {
            this.props.location.state.week_off.map((key, index) =>
                week_off.push({
                    "value": key.day,
                    "label": key.name
                })
            );
        }
        this.setState({ week_off: week_off });

        let clinic_name_font = this.props.location.state.clinic_name_font;
        let address_font = this.props.location.state.address_font;
        this.setState({
            clinicFont: { "value": clinic_name_font, "label": clinic_name_font },
            addressFont: { "value": address_font, "label": address_font },
        })

        //let holiday = this.state.holiday_date;

        this.setState({ holiday_date: new Date('2019-02-14') });
        if (this.props.isStateList && this.props.isStateList === true) {
            this.setState({
                stateList: this.props.stateList.data.data
            });
        } else {
            this.props.stateListAction(this.props.location.state.country_id);
        }

        //this.props.countryListAction(this.state);

        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.specializationListAction(data);

        //***** Time validation

        let startTime1 = new Date(moment(this.props.location.state.start_time, ["h:mm A"]));
        let endTime1 = new Date(moment(this.props.location.state.end_time, ["h:mm A"]));
        let startTime2 = new Date(moment(this.props.location.state.start_time2, ["h:mm A"]));
        let endTime2 = new Date(moment(this.props.location.state.end_time2, ["h:mm A"]));

        if ((startTime1).valueOf() > (endTime1).valueOf()) {
            this.setState({ endTime1Error: 'End time is not less then start time.' });
        } else if ((startTime2).valueOf() > (endTime2).valueOf()) {
            this.setState({ endTime2Error: 'End time 2 is not less then start time 2.' });
        } else {
            this.setState({ endTime2Error: '', endTime1Error: '' });
        }

        this.setState({ startTime1: startTime1, endTime1: endTime1, startTime2: startTime2, endTime2: endTime2 });

        if (this.props.location.state.address && this.props.location.state.address.city) {

            let patternArr = { "city": this.props.location.state.address.city };
            if (this.props.location.state.pattern) {
                patternArr['pattern'] = this.props.location.state.pattern;
            }
            this.props.patternListAction(patternArr);

        } else {
            this.props.patternListAction({ "city": "" });
        }

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isLanguageList !== this.props.isLanguageList && this.props.isLanguageList === false) {

            this.setState({
                languageList: nextProps.LanguageList.data
            });

            let list = nextProps.LanguageList.data;
            let languageOptions = [];
            for (let i in list) {
                languageOptions.push({ value: list[i].lanId, label: list[i].name });
            }

            this.setState({
                languageOptions: languageOptions
            });

        }

        if (nextProps.isCountryList !== this.props.isCountryList) {
            this.setState({
                countryList: nextProps.countryList.data.data
            });
        }

        if (nextProps.isStateList !== this.props.isStateList) {
            this.setState({
                stateList: nextProps.stateList.data.data
            });
        }

        if (nextProps.isSpecializationList && nextProps.isSpecializationList !== this.props.isSpecializationList) {
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                this.state.specializationList = nextProps.SpecializationList.data.data.map((key, i) => {
                    return { value: key._id, label: key.name };
                });
            }
        }

        if (nextProps.isUpdateClinic !== this.props.isUpdateClinic) {
            if (isUpdateApiCalled) {
                isUpdateApiCalled = false;
                this.props.handleClick('success', nextProps.updateClinicResponse.msg);
                setTimeout(() => { this.setState({ saveBtnClicked: false }); this.props.history.replace(`/admin/clinic-list`) }, 100);
            }

        }

        if (nextProps.isUpdateClinicError !== this.props.isUpdateClinicError) {
            this.setState({ showProcessing: false });
            if (nextProps.updateClinicResponse.errors) {
                nextProps.updateClinicResponse.errors.map((key, i) => {

                    this.setState({ [(key.param) + "Error"]: key.msg });
                    return '';
                });
            }
        }

        if (nextProps.isUploadFile !== this.props.isUploadFile) {

            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg });
                    return '';
                });
            }
        }

        if (nextProps.isPatternList !== this.props.isPatternList) {

            this.setState({
                patternList: nextProps.patternList.data
            });
        }
    }

    handleDayClick(day, { selected }) {

        const { selectedDays } = this.state;
        const { formData } = this.state;

        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }

        this.setState({ selectedDays });

        let days = '';

        for (let i in selectedDays) {
            var s1 = moment(new Date(selectedDays[i])).format('MM-DD-YYYY');
            if (i !== 0)
                days += ',' + s1;
            else
                days += s1;
        }

        formData['holiday_date'] = days;

        this.setState({ formData: formData });
    }


    updateClinic(evt) {

        evt.preventDefault();
        isUpdateApiCalled = true;
        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true, saveBtnClicked: true });
            _this.props.updateClinicAction(this.state);
        }
        //validation.errors()
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;

        if (e.target.name === 'email') {
            field[e.target.name] = e.target.value ? (e.target.value).trim() : '';

        } else if (e.target.name === 'clinic_website') {
            let domain = e.target.value;
            var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
            if (domain.match(re) || domain === '') {
                this.setState({ clinic_websiteError: '', validationError: false });
            } else {
                this.setState({ validationError: true });
            }

        }

        this.setState({ formData: field });
    };

    websiteValidation(e) {
        e.preventDefault();

        if (e.target.name === 'clinic_website') {
            let domain = e.target.value;
            var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
            if (domain.match(re) || domain === '') {
                this.setState({ clinic_websiteError: '', validationError: false });
            } else {
                this.setState({ clinic_websiteError: 'Enter valid webiste.', validationError: true });
            }
        }
    };

    getNumberValidation(e, name, type) {
        if (name === 'phone') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
                } else {
                    // this.setState({ phoneError1: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ phoneError: null })
                } else {
                    this.setState({ phoneError1: null })
                }
            }
        }
        if (name === 'mobile') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ mobileError: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
                } else {
                    //this.setState({ mobileError1: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ mobileError: null })
                } else {
                    this.setState({ mobileError1: null })
                }
            }
        }
        if (name === 'appointmentno') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ appointmentnoError: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
                } else {
                    //this.setState({ appointmentnoError1: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ appointmentnoError: null })
                } else {
                    this.setState({ appointmentnoError1: null })
                }
            }
        }
    }

    handleAddressChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        this.getNumberValidation(e, e.target.name, 'address1')

        newObj[e.target.name] = e.target.value;
        field['address'] = newObj;
        field[e.target.name === 'address' ? e.target.name + '11' : e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handlePatternCheck(e) {

        e.preventDefault();

        let patternArr = { "city": e.target.value };
        if (this.props.location.state.pattern) {
            patternArr['pattern'] = this.props.location.state.pattern;
        }
        this.props.patternListAction(patternArr);

    };

    handleAddress2Change(e) {
        e.preventDefault();
        let field = this.state.formData;
        this.getNumberValidation(e, e.target.name)
        newObj1[e.target.name] = e.target.value;
        field['address2'] = newObj1;
        field[e.target.name + '22'] = e.target.value;
        this.setState({ formData: field });
    };

    handleCountryChange(event) {
        let data = this.state.formData;
        newObj["country"] = event.target[event.target.selectedIndex].text;
        newObj["countryId"] = event.target.value;
        data['address'] = newObj;

        this.setState({ formData: data });
        this.props.stateListAction(event.target.value);
    }

    handleStateChange(event) {
        let data = this.state.formData;
        newObj['state'] = event.target[event.target.selectedIndex].text;
        newObj['stateId'] = event.target.value;
        data['address'] = newObj;
        this.setState({ formData: data });
    }

    handleState2Change(event) {
        let data = this.state.formData;
        newObj1['state'] = event.target[event.target.selectedIndex].text;
        newObj1['stateId'] = event.target.value;
        data['address2'] = newObj1;
        this.setState({ formData: data });
    }

    handlepatternChange(event) {
        let data = this.state.formData;
        data["pattern"] = event.value;
        this.setState({ formData: data });
    }

    handleLanguageChange(field) {
        this.setState({ languageError: '', validationError: false })
        let multipleSelect = this.state.language;
        multipleSelect = field;
        this.setState({ language: multipleSelect });

        let entry = [];
        let data = this.state.formData;
        field.map((key, index) =>
            entry.push(key.value)
        );
        var localindex_index = entry.map(function (el) {
            return el;
        }).indexOf(1);
        if (localindex_index == -1) {
            this.setState({ languageError: 'English Language is required.', validationError: true })
        }
        data["language"] = entry;
        this.setState({ formData: data });

    }


    validateSlot(newObj, type) {

        if (type === 1) {
            //this.setState({ endTime2Error: '', start2Error: '', startTime2Error:'' });
            this.setState({ start2Error: '' });
            //this.setState({ start1Error: '', endTime1Error: '' });
        }

        if (type === 2) {
            // this.setState({ morningFormError: '', morningTillError: '' });
            //this.setState({ eveningFormError: '', eveningTillError: '', eveningFormError:'' });
            this.setState({ eveningFormError: '' });
        }

        /*if (newObj["morning_from"] && newObj["morning_from"] != '' && newObj["morning_till"] && newObj["morning_till"] != '' && (new Date(newObj["morning_from"])).valueOf() > (new Date(newObj["morning_till"])).valueOf()) {
           
            if(type === 1){
                this.setState({ endTime1Error: 'Morning till is not less then start time.' });
            }else{
                this.setState({ morningTillError: 'Morning till is not less then start time.' });
            }
        } 

        if (newObj["evening_from"] && newObj["evening_from"] != '' && newObj["evening_till"] && newObj["evening_till"] != '' && (new Date(newObj["evening_from"])).valueOf() > (new Date(newObj["evening_till"])).valueOf()) {
           
            if(type === 1){
                this.setState({ endTime2Error: 'Evening Till is not less then start time.' });
            }else{
                this.setState({ eveningTillError: 'Evening Till is not less then start time.' });
            }
        }*/


        if (newObj["evening_from"] && newObj["evening_from"] !== '' && newObj["evening_till"] && newObj["evening_till"] !== '' && newObj["morning_from"] && newObj["morning_from"] !== '' && newObj["morning_till"] && newObj["morning_till"] !== '' && moment(newObj["evening_from"], ["h:mm A"]).format('x') < moment(newObj["morning_till"], ["h:mm A"]).format('x')) {

            if (type === 1) {
                this.setState({ start2Error: 'Evening from is not less then morning till.' });
            } else {
                this.setState({ eveningFormError: 'Evening from is not less then morning till.' });
            }

        }
    }

    handleChangeTime(data, name) {

        //let mytime = moment(new Date(data)).format('h:mm A');
        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'morning_from') {

            newObj[name] = data.target.value;
            data1["morning_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');
            newObj["morning_till"] = data1["morning_till"];
            data1['address'] = newObj;
            this.setState({ formData: data1, startTime1: dateObject });

            this.validateSlot(newObj, 1);

            /*if ((new Date(newObj["morning_from"])).valueOf() > (new Date(newObj["morning_till"])).valueOf()) {
                this.setState({ endTime1Error: 'Morning till is not less then start time.' });
            } else {
                this.setState({ start1Error: '', endTime1Error: '' });
                //this.setState({ start1Error: 'Please select valid date.', endTime1Error: '' });
            }*/

        } else if (name === 'morning_from2') {

            newObj1['morning_from'] = data.target.value;
            data1["morning_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');
            newObj1["morning_till"] = data1["morning_till2"];
            data1['address2'] = newObj1;
            this.setState({ formData: data1, morning_from1: dateObject });
            this.validateSlot(newObj1, 2);
            /*  if ((dateObject).valueOf() > (new Date(newObj["morning_till"])).valueOf()) {
                    this.setState({ morningTillError: 'End time is not less then start time.' });
                } else if (data._isValid) {
                    this.setState({ morningFormError: '', morningTillError: '' });
                } else {
                    this.setState({ morningFormError: 'Please select valid date.', morningTillError: '' });
                }
            */
        }
    }

    handleChangeTime2(data, name) {
        //let mytime = moment(new Date(data)).format('h:mm A');       

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'morning_till') {
            newObj[name] = data.target.value;
            data1['address'] = newObj;
            this.setState({ formData: data1, endTime1: dateObject });

            this.validateSlot(newObj, 1);

            /*if ((this.state.startTime1).valueOf() > (dateObject).valueOf()) {
                this.setState({ endTime1Error: 'Morning till is not less then start time.' });
                this.setState({ start2Error: 'Morning from is not less then end time.' });
            } else {
                this.setState({ endTime1Error: '', start2Error:'' });
            }*/

        } else if (name === 'morning_till2') {
            newObj1['morning_till'] = data.target.value;
            data1['address2'] = newObj1;
            this.setState({ formData: data1, morning_till1: dateObject });
            this.validateSlot(newObj1, 2);

            /*if ((this.state.morning_from1).valueOf() > (dateObject).valueOf()) {
                this.setState({ morningTillError: 'End time is not less then start time.' });
                this.setState({ eveningFormError: 'Start time 2 is not less then end time.' });
            } else if (!data._isValid) {
                this.setState({ morningTillError: 'Please select valid date.' });
            } else {
                this.setState({ morningTillError: '', eveningFormError:'' });
            }*/
        }
    }

    handleChangeTime3(data, name) {

        //let mytime = moment(new Date(data)).format('h:mm A');

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'evening_from') {
            newObj[name] = data.target.value;
            data1["evening_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');

            newObj["evening_till"] = data1["evening_till"];
            data1['address'] = newObj;

            this.setState({ formData: data1, startTime2: dateObject });

            this.validateSlot(newObj, 1);

            /* if ((dateObject).valueOf() > (this.state.endTime2).valueOf()) {
   
                 if ((dateObject).valueOf() < (this.state.endTime1).valueOf()) {
                     this.setState({ start2Error: 'Evening hour from is not less then Morning hour till', endTime2Error:'' });
                 }else {
                     this.setState({ endTime2Error: '', start2Error: '' });
                 }
             }
             else if (data._isValid) {
                 this.setState({ start2Error: '', endTime2Error: '' });
             }
             else {
                 this.setState({ start2Error: 'Please select valid date.', endTime2Error: '' });
             }*/

        } else if (name === 'evening_from2') {

            newObj1['evening_from'] = data.target.value;
            data1["evening_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');

            newObj1["evening_till"] = data1["evening_till2"];
            data1['address2'] = newObj1;
            this.setState({ formData: data1, evening_from1: dateObject });
            this.validateSlot(newObj1, 2);

            /*if ((dateObject).valueOf() > (this.state.evening_till1).valueOf()) {
  
                if ((dateObject).valueOf() < (this.state.morning_till1).valueOf()) {
                    this.setState({ eveningFormError: 'Evening hour from is not less then Morning hour till', eveningTillError:'' });
                }else {
                    this.setState({ eveningTillError: '', eveningFormError: '' });
                }
            }
            else if (data._isValid) {
                this.setState({ eveningFormError: '', eveningTillError: '' });
            }
            else {
                this.setState({ eveningFormError: 'Please select valid date.', eveningTillError: '' });
            }*/
        }
    }

    handleChangeTime4(data, name) {
        //let mytime = moment(new Date(data)).format('h:mm A');       

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'evening_till') {
            newObj[name] = data.target.value;
            data1['address'] = newObj;
            this.setState({ formData: data1, endTime2: dateObject });
            this.validateSlot(newObj, 1);
            /*if ((this.state.startTime2).valueOf() > (dateObject).valueOf()) {
                this.setState({ endTime2Error: 'End time 2 is not less then start time 2.' });
            } else if (!data._isValid) {
                this.setState({ endTime2Error: 'Please select valid date.' });
            } else {
                this.setState({ endTime2Error: '' });
            }*/

        } else if (name === 'evening_till2') {
            newObj1['evening_till'] = data.target.value;
            data1['address2'] = newObj1;
            this.setState({ formData: data1, evening_till1: dateObject });
            this.validateSlot(newObj1, 2);

            /*if ((this.state.evening_from1).valueOf() > (dateObject).valueOf()) {
                this.setState({ eveningTillError: 'End time 2 is not less then start time 2.' });
            } else if (!data._isValid) {
                this.setState({ eveningTillError: 'Please select valid date.' });
            } else {
                this.setState({ eveningTillError: '' });
            }*/

        }

    }

    handleCheckboxChange(e, name) {
        let data = this.state.formData;

        if (name === 'phoneNoShow') {
            this.setState({ phone_cb: e.target.checked });
        }

        if (name === 'mobileNoShow') {
            this.setState({ mobile_cb: e.target.checked });
        }

        if (name === 'appointmentNoShow') {
            this.setState({ appointment_cb: e.target.checked });
        }

        if (name === 'emergencyShow') {
            this.setState({ emergency_cb: e.target.checked });
        }

        newObj[e.target.name] = e.target.checked;
        data['address'] = newObj;
        this.setState({ formData: data });
    }

    handleCheckboxChange1(e, name) {
        let data = this.state.formData;

        if (name === 'phoneNoShow') {
            this.setState({ phone_cb1: e.target.checked });
        }

        if (name === 'mobileNoShow') {
            this.setState({ mobile_cb1: e.target.checked });
        }

        if (name === 'appointmentNoShow') {
            this.setState({ appointment_cb1: e.target.checked });
        }

        if (name === 'emergencyShow') {
            this.setState({ emergency_cb1: e.target.checked });
        }

        newObj1[e.target.name] = e.target.checked;
        data['address2'] = newObj1;
        this.setState({ formData: data });
    }

    handleWeekOff(field) {
        let multipleSelect = this.state.week_off;
        multipleSelect = field;
        this.setState({ week_off: multipleSelect });

        let entry = [];
        let data = this.state.formData;
        field.map((key, index) =>
            entry.push({
                "day": key.value,
                "name": key.label
            })
        );
        if (entry) {
            for (let i in entry) {
                if (entry[i].day === 7) {
                    entry[i].day = 0
                }
            }
        }
        data["week_off"] = entry;
        this.setState({ formData: data });
    }

    handleSpecialization(value) {
        let multipleSelect = this.state.specialization;
        multipleSelect = value;
        this.setState({ specialization: multipleSelect });



        let formData = this.state.formData;
        let catArr = [];
        if (value && value.length) {
            value.map((key, i) => {
                catArr.push({ id: key.value, name: key.label });
                return '';
            });
        }
        formData.specialization = catArr;
        this.setState({ formData: formData });
    }

    handleClinicFontChange(event, name) {
        let data = this.state.formData;
        let clinicFont = this.state.clinicFont;
        data[name] = event && event.value;
        clinicFont = event;
        this.setState({ formData: data, clinicFont });
    }

    handleAddressFontChange(event, name) {

        let data = this.state.formData;
        let addressFont = this.state.addressFont;
        data[name] = event && event.value;
        addressFont = event;
        this.setState({ formData: data, addressFont });
    }

    handleChangeAddress(data) {
        let { formData } = this.state;
        formData = data;

        let country = {};
        if (this.state.countryList && this.state.countryList.length > 0) {
            this.state.countryList.map((v, i) => {
                if (v.short_name === formData.country) {
                    country = { id: v._id, name: v.short_name };
                }
                return '';
            });

            formData.country_id = country.id;
            formData.country = country.name;
            this.setState({ formData });
        }
        this.props.stateListAction(country.id);

    }

    fileChangedHandler(event, elename) {
        event.preventDefault();

        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];


        if (elename === "logo_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrl: reader.result, onPageLoad: true });
            }
        }
        if (elename === "background_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrlClinic: reader.result, onPageLoadClinic: true });
            }
        }
        if (elename === "contact_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrlContact: reader.result, onPageLoad: true });
            }
        }
        if (elename === "doctor_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrlDoctor: reader.result, onPageLoadDoctor: true });
            }
        }

        if (file) {
            this.props.uploadFileAction(file);
            reader.readAsDataURL(file);
        }

    }

    selectMultiData() {
        if (this.state.multiInput === false)
            this.setState({ multiInput: true });
        else
            this.setState({ multiInput: false });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    onDismiss() {
        this.setState({ previewModal: false });
        this.successAlert('Patient successfully registered');
    }

    openPatternModal() {
        this.setState({ previewModal: true });
    }

    imageRemoveConformation(type) {
        let data = this.state.formData;
        if (type === "logo_image") {
            data['logo_image'] = "";
            this.setState({ imagePreviewUrl: "", formData: data });
        }
        if (type === "background_image") {
            data['background_image'] = "";
            this.setState({ imagePreviewUrlClinic: "", formData: data });
        }
        if (type === "contact_image") {
            data['contact_image'] = "";
            this.setState({ imagePreviewUrlContact: "", formData: data });
        }
        if (type === "doctor_image") {
            data['doctor_image'] = "";
            this.setState({ imagePreviewUrlDoctor: "", formData: data });
        }

    }


    onResetTime(e, typ = '') {

        if (typ !== '' && this.state.formData) {
            var formdata = this.state.formData;

            if (typ === 1 && formdata && formdata.address && formdata.address.morning_from) {

                formdata.address.morning_from = '';
                formdata.address.morning_till = '';
                this.setState({ formdata: formdata });
            }

            if (typ === 2 && formdata && formdata.address && formdata.address.evening_from) {

                formdata.address.evening_from = '';
                formdata.address.evening_till = '';
                this.setState({ formdata: formdata });
            }

            if (typ === 1 || typ === 2) {

                this.setState({ start2Error: '' });
            }

            if (typ === 3 || typ === 4) {

                this.setState({ eveningFormError: '' });
            }

            if (typ === 3 && formdata && formdata.address2 && formdata.address2.morning_from) {

                formdata.address2.morning_from = '';
                formdata.address2.morning_till = '';
                this.setState({ formdata: formdata });
            }

            if (typ === 4 && formdata && formdata.address2 && formdata.address2.evening_from) {

                formdata.address2.evening_from = '';
                formdata.address2.evening_till = '';
                this.setState({ formdata: formdata });
            }

        }
    }


    render() {


        const { endTime1Error, endTime2Error, start1Error, start2Error, formData,
            morningTillError, morningFormError, eveningFormError, eveningTillError, validationError } = this.state;

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content spc_btm" style={{ padding: '15px 0px' }}>
                {this.state.sweetAlert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.clinicView &&
                                <ClinicHeader componentData={{ clinicId: this.state.clinicView }} />
                            }
                            <Form horizontal>
                                <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                                    <img src={loadingImg} alt="Loading" width="40px" />
                                    <center>Logging In - Please Wait</center>
                                </div>
                                <Card
                                    title={
                                        <legend>
                                            General information
                                        </legend>
                                    }
                                    content={
                                        <div>

                                            <FormGroup>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        Doctor Name <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="doctorName" onChange={e => { this.handleChange(e); }} value={this.state.formData.doctorName} placeholder="Doctor Name" />

                                                    <span className="errorMsg">{this.state.clinic_nameError}{this.state.formArr.doctorName && validation.errors.first('doctorName')}</span>
                                                </Col>
                                                {/*}
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        Specialization <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="specializations_add" onChange={e => { this.handleChange(e); }} value={this.state.formData.specializations_add} placeholder="Specialization" />
                                                    <span className="errorMsg" refs="specializations_add">{this.state.short_nameError}{this.state.formArr.specializations_add && validation.errors.first('specializations_add')}</span>
                                                </Col>*/}

                                                <Col md={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        Specialization <span className="star">*</span>
                                                    </Col>
                                                    <Select
                                                        placeholder="Select specializations"
                                                        name="specialization"
                                                        id="specialization"
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        value={this.state.specialization}
                                                        options={this.state.specializationList}
                                                        onChange={(value) => { this.setState({ specialization: value }); this.handleSpecialization(value) }}
                                                    />
                                                </Col>

                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        Degree <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="degree" onChange={e => { this.handleChange(e); }} value={this.state.formData.degree} placeholder="Degree" />
                                                    <span className="errorMsg">{this.state.clinic_nameError}{this.state.formArr.degree && validation.errors.first('degree')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        MCI Registration Number
                                                    </Col>
                                                    <FormControl type="text" name="mci_number" onChange={e => { this.handleChange(e); }} value={this.state.formData.mci_number} placeholder="MCI Registation Number" />
                                                    <span className="errorMsg">{this.state.mci_numberError}{this.state.formArr.mci_number && validation.errors.first('mci_number')}</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel} >
                                                        Clinic Name <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="clinic_name" onChange={e => { this.handleChange(e); }} value={this.state.formData.clinic_name} placeholder="Clinic Name" />
                                                    <span className="errorMsg">{this.state.clinic_nameError}{this.state.formArr.clinic_name && validation.errors.first('clinic_name')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Website
                                                    </Col>
                                                    <FormControl type="text" name="clinic_website" onBlur={e => { this.websiteValidation(e); }} onChange={e => { this.handleChange(e); }} value={this.state.formData.clinic_website} placeholder="Website" />
                                                    <span className="errorMsg">{this.state.clinic_websiteError}</span>
                                                </Col>

                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Doctor Email <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="email" onChange={e => { this.handleChange(e); }} value={this.state.formData.email} placeholder="Doctor Email" />
                                                    <span className="errorMsg" refs="short_name">{this.state.emailError}{this.state.formArr.email && validation.errors.first('email')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Status
                                                    </Col>
                                                    <FormControl componentClass="select" name="status" onChange={e => { this.handleChange(e); }} value={this.state.formData.status}>
                                                        <option value="active">Active</option>
                                                        <option value="inactive">Inactive</option>
                                                    </FormControl>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Birthday Wish
                                                    </Col>
                                                    <FormControl componentClass="select" name="birthdayWish" onChange={e => { this.handleChange(e); }} value={this.state.formData.birthdayWish}>
                                                        <option value="yes" >Yes</option>
                                                        <option value="no">No</option>
                                                    </FormControl>
                                                </Col>
                                                <Col sm={3} className="relat-patternP">
                                                    <Col componentClass={ControlLabel}>
                                                        Pattern <span className="star">*</span>
                                                        {this.state.formData.pattern &&
                                                            <a className="patternP" onClick={() => this.openPatternModal()}>Preview </a>
                                                        }
                                                    </Col>
                                                    <Select
                                                        placeholder="Please Select Pattern"
                                                        name="pattern"
                                                        value={this.state.formData.pattern}
                                                        key={this.state.formData.pattern}
                                                        options={this.state.patternList}
                                                        onChange={e => { this.handlepatternChange(e); }}
                                                    />
                                                    <span className="errorMsg">{this.state.patternError}{this.state.formArr.pattern && validation.errors.first('pattern')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Chat
                                                    </Col>
                                                    <FormControl componentClass="select" name="chat_facility" onChange={e => { this.handleChange(e); }} value={this.state.formData.chat_facility}>
                                                        <option value="no" >No</option>
                                                        <option value="yes">Yes</option>
                                                    </FormControl>
                                                    <small>Note:- Do you need a chat facility with your patient.</small>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Patient can Email for Queries
                                                    </Col>
                                                    <FormControl componentClass="select" name="email_facility" onChange={e => { this.handleChange(e); }} value={this.state.formData.email_facility}>
                                                        <option value="no" >No</option>
                                                        <option value="yes">Yes</option>
                                                    </FormControl>
                                                </Col>

                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Birthday Content
                                                    </Col>

                                                    <FormControl rows="4" componentClass="textarea" name="birthdayContent" bsClass="form-control" placeholder="Birthday Content" value={this.state.formData.birthdayContent} onChange={e => { this.handleChange(e); }} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Diet Plan Decision by Educator
                                                    </Col>
                                                    <FormControl componentClass="select" name="educatorDietStatus" onChange={e => { this.handleChange(e); }} value={this.state.formData.educatorDietStatus}>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </FormControl>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Next Visit Notification
                                                    </Col>
                                                    <FormControl componentClass="select" name="patientNotificationDay" onChange={e => { this.handleChange(e); }} value={this.state.formData.patientNotificationDay} placeholder="Print with Format">
                                                        <option value="1">1 day</option>
                                                        <option value="2">2 days</option>
                                                        <option value="3">3 days</option>
                                                        <option value="5">5 days</option>
                                                        <option value="7">7 days</option>
                                                        <option value="10">10 days</option>
                                                    </FormControl>
                                                    <small>Note:- "How many days prior to next expected visit" , a automatic message should go to patient to take appointment with you.</small>
                                                </Col>
                                                <Col sm={3} className="relat-patternP">
                                                    <Col componentClass={ControlLabel}>
                                                        Language <span className="star">*</span>
                                                    </Col>

                                                    <Select
                                                        placeholder="Please Select Language"
                                                        name="pattern"
                                                        value={this.state.formData.language}
                                                        key={this.state.formData.language}
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        options={this.state.languageOptions}
                                                        onChange={e => { this.handleLanguageChange(e); }}
                                                    />
                                                    <span className="errorMsg" refs="short_name">{this.state.languageError}{this.state.formArr.language && validation.errors.first('language')}</span>
                                                    {/* <span className="errorMsg">{this.state.patternError}{this.state.formArr.pattern && validation.errors.first('pattern')}</span> */}
                                                </Col>
                                                {/* <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Description
                                                      </Col>
                                                    <FormControl rows="4" componentClass="textarea" name="description" bsClass="form-control" placeholder="Description" value={this.state.formData.description} onChange={e => { this.handleChange(e); }} />
                                                </Col> */}
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Insulin Calculator
                                                    </Col>
                                                    <FormControl componentClass="select" name="insulinCalculator" onChange={e => { this.handleChange(e); }} value={this.state.formData.insulinCalculator}>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </FormControl>

                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="relativeCss">
                                                <Col sm={4} >
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Logo
                                                        {this.state.imagePreviewUrl &&
                                                            <i className="fa fa-times" onClick={e => { this.imageRemoveConformation('logo_image') }} aria-hidden="true"></i>
                                                        }
                                                    </Col>
                                                    <Col componentClass={ControlLabel}>
                                                        {this.state.onPageLoad === false &&
                                                            this.state.imagePreviewUrl &&
                                                            <ImageComponent
                                                                style={{ "width": "140px", "height": "140px" }}
                                                                data={this.props.location.state.logo_image}
                                                                alt="Logo"
                                                            />
                                                            //<img src={this.state.imagePreviewUrl} alt="Logo" width="140px" height="140px"  ></img>
                                                        }
                                                        {this.state.onPageLoad === true &&
                                                            this.state.imagePreviewUrl &&
                                                            <img src={this.state.imagePreviewUrl} alt="Logo" width="140px" height="140px"  ></img>
                                                        }

                                                        <FormControl type="file" name="logo_image" accept=".png, .jpg, .jpeg" onChange={e => { this.fileChangedHandler(e, "logo_image") }} />
                                                        <span className="errorMsg">{this.state.logo_imageError}</span>

                                                    </Col>
                                                </Col>
                                                <Col sm={4} >
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Image
                                                        {this.state.imagePreviewUrlClinic &&
                                                            <i className="fa fa-times" onClick={e => { this.imageRemoveConformation('background_image') }} aria-hidden="true"></i>
                                                        }
                                                    </Col>
                                                    <Col componentClass={ControlLabel}>
                                                        {this.state.onPageLoadClinic === false &&
                                                            this.state.imagePreviewUrlClinic &&
                                                            <ImageComponent
                                                                style={{ "width": "140px", "height": "140px" }}
                                                                data={this.props.location.state.background_image}
                                                                alt="Logo"
                                                            />
                                                            // <img src={this.state.imagePreviewUrlClinic} alt="Logo" width="140px" height="140px" />
                                                        }
                                                        {this.state.onPageLoadClinic === true &&
                                                            this.state.imagePreviewUrlClinic &&
                                                            <img src={this.state.imagePreviewUrlClinic} alt="Logo" width="140px" height="140px" />
                                                        }

                                                        <FormControl type="file" name="background_image" accept=".png, .jpg, .jpeg" onChange={(e) => this.fileChangedHandler(e, "background_image")} />
                                                        <span className="errorMsg">{this.state.background_imageError}</span>
                                                    </Col>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Doctor Image
                                                        {this.state.imagePreviewUrlDoctor &&
                                                            <i className="fa fa-times" onClick={e => { this.imageRemoveConformation('doctor_image') }} aria-hidden="true"></i>
                                                        }
                                                    </Col>
                                                    <Col componentClass={ControlLabel}>
                                                        {
                                                            this.state.onPageLoadDoctor === false &&
                                                            this.state.imagePreviewUrlDoctor &&
                                                            <ImageComponent
                                                                style={{ "width": "140px", "height": "140px" }}
                                                                data={this.props.location.state.doctor_image}
                                                                alt="Logo"
                                                            />
                                                            // <img src={this.state.imagePreviewUrlDoctor} alt="Logo" width="140px" height="140px" />
                                                        }
                                                        {this.state.onPageLoadDoctor === true &&
                                                            this.state.imagePreviewUrlDoctor &&
                                                            <img src={this.state.imagePreviewUrlDoctor} alt="Logo" width="140px" height="140px" />
                                                        }

                                                        <FormControl type="file" name="doctor_image" accept=".png, .jpg, .jpeg" onChange={(e) => this.fileChangedHandler(e, "doctor_image")} />
                                                        <span className="errorMsg">{this.state.contact_imageError}</span>
                                                    </Col>
                                                </Col>

                                            </FormGroup>
                                        </div>
                                    }
                                />
                                <Card
                                    title={
                                        <legend>Contact Information</legend>
                                    }
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Address <span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="textarea" name="address" value={formData.address && formData.address.address} onChange={e => { this.handleAddressChange(e); }} placeholder="Address" />
                                                    <span className="errorMsg">{this.state.addressError}{this.state.formArr.address11 && validation.errors.first('address11')}</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        City <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="city" onChange={e => { this.handleAddressChange(e); }} onBlur={e => { this.handlePatternCheck(e); }} value={formData.address && formData.address.city} placeholder="City" />
                                                    <span className="errorMsg">{this.state.cityError}{this.state.formArr.city && validation.errors.first('city')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        State <span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="select" name="state" onChange={e => { this.handleAddressChange(e); this.handleStateChange(e); }} value={formData.address && formData.address.stateId}>
                                                        <option value="">Please select</option>
                                                        {this.state.stateList.map(function (item) {
                                                            return <option key={item._id} value={item._id} >{item.name}</option>
                                                        })}</FormControl>
                                                    <span className="errorMsg">{this.state.stateError}{this.state.formArr.state && validation.errors.first('state')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Pin Code
                                                    </Col>
                                                    <FormControl type="text" name="pin_code" onChange={e => { this.handleAddressChange(e); }} maxLength={8} value={formData.address && formData.address.pin_code} placeholder="Pin Code" />
                                                    <span className="errorMsg">{this.state.pin_codeError}{this.state.formArr.pin_code && validation.errors.first('pin_code')}</span>
                                                </Col>

                                            </FormGroup>

                                            <FormGroup>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Latitude
                                                    </Col>
                                                    <FormControl type="text" name="latitude" value={(this.state.formData.address && this.state.formData.address.latitude ? this.state.formData.address.latitude : '')} onChange={e => { this.handleAddressChange(e); }} placeholder="Latitude" />
                                                    <span className="errorMsg">{this.state.latitudeError}{this.state.formArr.latitude && validation.errors.first('latitude')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Longitude
                                                    </Col>
                                                    <FormControl type="text" name="longitude" value={(this.state.formData.address && this.state.formData.address.longitude ? this.state.formData.address.longitude : '')} onChange={e => { this.handleAddressChange(e); }} placeholder="Longitude" />
                                                    <span className="errorMsg">{this.state.longitudeError}{this.state.formArr.longitude && validation.errors.first('longitude')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Email
                                                    </Col>
                                                    <FormControl type="text" name="clinic_email" onChange={e => { this.handleChange(e); }} value={this.state.formData.clinic_email} placeholder="Clinic Email" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>

                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Phone No. for Appointment <span className="star">*</span>

                                                    </Col>
                                                    <FormControl type="text" name="phone" maxLength={12}
                                                        onChange={e => { this.handleAddressChange(e); }}
                                                        value={formData.address && formData.address.phone} placeholder="Phone No. for Appointment" />
                                                    <span className="errorMsg">{this.state.phoneError}{this.state.formArr.phone && validation.errors.first('phone')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Phone No. for Clinic

                                                    </Col>
                                                    <FormControl type="text" name="mobile"
                                                        onChange={e => { this.handleAddressChange(e); }}
                                                        value={formData.address && formData.address.mobile} maxLength={12}
                                                        placeholder="Phone No. for Clinic" />
                                                    <span className="errorMsg">{this.state.mobileError}{this.state.formArr.mobile && validation.errors.first('mobile')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Whatsapp No.

                                                    </Col>
                                                    <FormControl type="text" maxLength={12} name="appointmentno"
                                                        onChange={e => { this.handleAddressChange(e); }}
                                                        value={formData.address && formData.address.appointmentno} placeholder="Whatsapp No." />
                                                    <span className="errorMsg">{this.state.appointmentnoError}{this.state.formArr.appointmentno && validation.errors.first('appointmentno')}</span>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    }
                                />
                                <Card
                                    title={
                                        <legend>Diet Print Format</legend>
                                    }
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Print with Format
                                                    </Col>
                                                    <FormControl componentClass="select" name="diet_print_format" onChange={e => { this.handleChange(e); }} value={this.state.formData.diet_print_format}>
                                                        <option value="no">No</option>
                                                        <option value="yes">Yes</option>
                                                    </FormControl>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Name Font
                                                    </Col>
                                                    <Select
                                                        placeholder="Select Font"
                                                        name="clinic_name_font"
                                                        value={this.state.clinicFont}
                                                        key={this.state.clinicFont}
                                                        options={patternFontOptions}
                                                        disabled={this.state.formData.diet_print_format === 'no'}
                                                        onChange={(e) => { this.handleClinicFontChange(e, 'clinic_name_font'); }}
                                                    />
                                                    <span className="errorMsg">{this.state.formArr.clinic_name_font && validation.errors.first('clinic_name_font')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Address Font
                                                    </Col>
                                                    <Select
                                                        placeholder="Select Font"
                                                        name="address_font"
                                                        value={this.state.addressFont}
                                                        key={this.state.addressFont}
                                                        options={patternFontOptions}
                                                        disabled={this.state.formData.diet_print_format === 'no'}
                                                        onChange={e => { this.handleAddressFontChange(e, 'address_font'); }}
                                                    />
                                                    <span className="errorMsg">{this.state.formArr.address_font && validation.errors.first('address_font')}</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Margin Top
                                                    </Col>
                                                    <FormControl type="text" name="margin_top" onChange={e => { this.handleChange(e); }} value={this.state.formData.margin_top} placeholder="Margin Top" />
                                                    <span className="errorMsg">{this.state.formArr.margin_top && validation.errors.first('margin_top')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Margin Bottom
                                                    </Col>
                                                    <FormControl type="text" name="margin_bottom" onChange={e => { this.handleChange(e); }} value={this.state.formData.margin_bottom} placeholder="Margin Bottom" />
                                                    <span className="errorMsg">{this.state.formArr.margin_bottom && validation.errors.first('margin_bottom')}</span>
                                                </Col>

                                            </FormGroup>
                                        </div>
                                    }
                                />

                                <Card
                                    title={
                                        <legend>Consulting Hours</legend>
                                    }
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour from
                                                    </Col>
                                                    <select className="form-control" name="morning_from" onChange={e => { this.handleChangeTime(e, "morning_from"); }} value={formData.address && formData.address.morning_from}>
                                                        <option value="">Select Morning hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {

                                                                if (moment(item.value, 'h:mm a').format('x') >= moment("6:00 AM", 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else {
                                                                    return '';
                                                                }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{start1Error}{this.state.formArr.start_time && validation.errors.first('start_time')}</span>

                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour till
                                                    </Col>
                                                    <select className="form-control" name="morning_till" onChange={e => { this.handleChangeTime2(e, "morning_till"); }} value={formData.address && formData.address.morning_till}>
                                                        <option value="">Select Morning hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address && formData.address.morning_from ? formData.address.morning_from : '6:00 AM';
                                                                if (moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else {
                                                                    return '';
                                                                }

                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{endTime1Error}{this.state.formArr.end_time && validation.errors.first('end_time')}</span>
                                                </Col>
                                                <Col sm={1}>
                                                    <Button className="btn-clear" bsStyle="info" onClick={(e) => { this.onResetTime(e, 1) }}>Clear</Button>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Slot Time <span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="select" name="slot" value={this.state.formData.slot} onChange={e => { this.handleChange(e); }}>
                                                        <option value="15">15</option>
                                                        <option value="30">30</option>
                                                        <option value="45">45</option>
                                                        <option value="60">60</option>
                                                    </FormControl>
                                                    <span className="errorMsg">{this.state.formArr.slot && validation.errors.first('slot')}</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour from
                                                    </Col>
                                                    <select className="form-control" name="evening_from" onChange={e => { this.handleChangeTime3(e, "evening_from"); }} value={formData.address && formData.address.evening_from}>
                                                        <option value="">Select Evening hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address && formData.address.morning_till ? formData.address.morning_till : '1:00 PM';
                                                                if ((moment(item.value, 'h:mm a').format('x') >= moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{start2Error}{this.state.formArr.start_time2 && validation.errors.first('start_time2')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour till
                                                    </Col>
                                                    <select className="form-control" name="evening_till" onChange={e => { this.handleChangeTime4(e, "evening_till"); }} value={formData.address && formData.address.evening_till}>
                                                        <option value="">Select Evening hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address && formData.address.evening_from ? formData.address.evening_from : '1:00 PM';

                                                                if ((moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{endTime2Error}{this.state.formArr.end_time2 && validation.errors.first('end_time2')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Button className="btn-clear" bsStyle="info" onClick={(e) => { this.onResetTime(e, 2) }}>Clear</Button>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Week Off
                                                    </Col>
                                                    <Select
                                                        placeholder="Select Day"
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        name="week_off"
                                                        value={this.state.week_off}
                                                        options={weekOptions}
                                                        onChange={(value) => { this.handleWeekOff(value) }}
                                                    />
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Holiday Dates
                                                    </Col>
                                                    <div>
                                                        <FormControl type="text" readOnly placeholder="Select Holiday Date" autoComplete="on" name="holiday_date" value={this.state.formData.holiday_date ? this.state.formData.holiday_date : ""} onClick={e => { this.selectMultiData(e); }} />

                                                        <span className="errorMsg">{this.state.formArr.holiday_date && validation.errors.first('holiday_date')}</span>

                                                        <div className="multiDateP" style={{ display: this.state.multiInput ? 'block' : 'none' }}>
                                                            <DayPicker selectedDays={this.state.selectedDays} onDayClick={this.handleDayClick} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </FormGroup>


                                        </div>

                                    }

                                />

                                <Card
                                    title={
                                        <legend>Clinic Information 2</legend>
                                    }
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Address
                                                    </Col>
                                                    <FormControl componentClass="textarea" name="address" value={formData.address2 && formData.address2.address} onChange={e => { this.handleAddress2Change(e); }} placeholder="Address" />
                                                    <span className="errorMsg"></span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        City
                                                    </Col>
                                                    <FormControl type="text" name="city" value={formData.address2 && formData.address2.city} onChange={e => { this.handleAddress2Change(e); }} placeholder="City" />
                                                    <span className="errorMsg"></span>
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        State
                                                    </Col>
                                                    <FormControl componentClass="select" name="state" value={formData.address2 && formData.address2.stateId} onChange={e => { this.handleAddress2Change(e); this.handleState2Change(e); }}>
                                                        <option value="">Please select</option>
                                                        {this.state.stateList.map(function (item) {
                                                            return <option key={item._id} value={item._id}>{item.name}</option>
                                                        })}</FormControl>
                                                    <span className="errorMsg"></span>
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Pin Code
                                                    </Col>
                                                    <FormControl type="text" name="pin_code" value={formData.address2 && formData.address2.pin_code} onChange={e => { this.handleAddress2Change(e); }} maxLength={8} placeholder="Pin Code" />
                                                    <span className="errorMsg"></span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour from
                                                    </Col>
                                                    <select className="form-control" name="morning_from2" onChange={e => { this.handleChangeTime(e, "morning_from2"); }} value={formData.address2 && formData.address2.morning_from}>
                                                        <option value="">Select Morning hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {

                                                                if (moment(item.value, 'h:mm a').format('x') >= moment("6:00 AM", 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{morningFormError}{this.state.formArr.start_time && validation.errors.first('start_time')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour till
                                                    </Col>
                                                    <select className="form-control" name="morning_till2" onChange={e => { this.handleChangeTime2(e, "morning_till2"); }} value={formData.address2 && formData.address2.morning_till}>
                                                        <option value="">Select Morning hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address2 && formData.address2.morning_from ? formData.address2.morning_from : '6:00 AM';
                                                                if (moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{morningTillError}{this.state.formArr.end_time && validation.errors.first('end_time')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Button className="btn-clear" bsStyle="info" onClick={(e) => { this.onResetTime(e, 3) }}>Clear</Button>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour from
                                                    </Col>
                                                    <select className="form-control" name="evening_from2" onChange={e => { this.handleChangeTime3(e, "evening_from2"); }} value={formData.address2 && formData.address2.evening_from}>
                                                        <option value="">Select Evening hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address2 && formData.address2.morning_till ? formData.address2.morning_till : '1:00 PM';
                                                                if ((moment(item.value, 'h:mm a').format('x') >= moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{eveningFormError}{this.state.formArr.start_time2 && validation.errors.first('start_time2')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour till
                                                    </Col>
                                                    <select className="form-control" name="evening_till2" onChange={e => { this.handleChangeTime4(e, "evening_till2"); }} value={formData.address2 && formData.address2.evening_till}>
                                                        <option value="">Select Evening hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address2 && formData.address2.evening_from ? formData.address2.evening_from : '1:00 PM';
                                                                if ((moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') > moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                }
                                                            })
                                                        }
                                                    </select>

                                                    <span className="errorMsg">{eveningTillError}{this.state.formArr.end_time2 && validation.errors.first('end_time2')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Button className="btn-clear" bsStyle="info" onClick={(e) => { this.onResetTime(e, 4) }}>Clear</Button>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Phone No. for Appointment

                                                    </Col>
                                                    <FormControl type="text" name="phone" maxLength={12}
                                                        onChange={e => { this.handleAddress2Change(e); }}
                                                        value={formData.address2 && formData.address2.phone}
                                                        placeholder="Phone No. for Appointment" />
                                                    <span className="errorMsg">{this.state.phoneError1}{this.state.formArr.phone22 && validation.errors.first('phone22')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Phone No. for Clinic

                                                    </Col>
                                                    <FormControl type="text" name="mobile"
                                                        onChange={e => { this.handleAddress2Change(e); }}
                                                        value={formData.address2 && formData.address2.mobile}
                                                        maxLength={12} placeholder="Phone No. for Clinic" />
                                                    <span className="errorMsg">{this.state.mobileError1}{this.state.formArr.mobile22 && validation.errors.first('mobile22')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col className="inline-g" componentClass={ControlLabel}>
                                                        Whatsapp No.

                                                    </Col>
                                                    <FormControl type="text" maxLength={12} name="appointmentno"
                                                        onChange={e => { this.handleAddress2Change(e); }}
                                                        value={formData.address2 && formData.address2.appointmentno} placeholder="Whatsapp No." />
                                                    <span className="errorMsg">{this.state.appointmentnoError1}{this.state.formArr.appointmentno22 && validation.errors.first('appointmentno22')}</span>
                                                </Col>

                                            </FormGroup>
                                        </div>
                                    }
                                    ftTextCenter
                                    legend={<div>
                                        <Button fill bsStyle="info" type="button" onClick={this.updateClinic.bind(this)} disabled={endTime1Error || endTime2Error || start1Error || start2Error || eveningFormError || validationError || this.state.saveBtnClicked}>
                                            Update
                                            {this.state.saveBtnClicked && (
                                                <i class="fa fa-spinner fa-spin"></i>
                                            )}
                                        </Button>&nbsp;
                                    </div>
                                    }
                                />
                            </Form>
                        </Col>
                    </Row>
                </Grid>

                <Modal className="pa-patient-registration" show={this.state.previewModal} onHide={() => this.setState({ previewModal: false })} dialogClassName="modal-md">
                    <Modal.Header closeButton className="Patient-headers">
                        <Modal.Title id="example-modal-sizes-title-lg">Pattern Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="Knowledge-Share card">
                        <Row>
                            <Col md={12}>
                                <PatternPreview onDismiss={this.onDismiss} pattern={this.state.formData.pattern} />
                            </Col>
                        </Row>
                    </Modal.Body>

                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

        stateList: state.master.stateList,
        isStateList: state.master.isStateList,
        isStateListError: state.master.isStateListError,

        countryList: state.master.countryList,
        isCountryList: state.master.isCountryList,
        isCountryListError: state.master.isCountryListError,

        patternList: state.master.patternList,
        isPatternList: state.master.isPatternList,
        isPatternListError: state.master.isPatternListError,

        SpecializationList: state.specilization.SpecializationList,
        isSpecializationList: state.specilization.isSpecializationList,
        isSpecializationListError: state.specilization.isSpecializationListError,

        isUpdateClinic: state.clinic.isUpdateClinic,
        isUpdateClinicError: state.clinic.isUpdateClinicError,
        updateClinicResponse: state.clinic.updateClinicResponse,

        isUploadFile: state.master.isUploadFile,
        isUploadFileError: state.master.isUploadFileError,
        uploadFile: state.master.uploadFile,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
// export default UpdateClinic;
export default withRouter(connect(mapStateToProps, { countryListAction, stateListAction, updateClinicAction, specializationListAction, uploadFileAction, patternListAction, languageListAction })(UpdateClinic));
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from '../../../../api/emr'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
import AddEditLabTestRange from "./AddEditLabTestRange";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../../../actions/actionTypes'


const LabTestRangeList = (props) => {
    //console.log(props.labTestData, "yyyyyyy")
    let history = useHistory();
    const [formData, setFormData] = useState({ limit: 10, page: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [labTestRangeList, setLabTestRangeList] = useState([...props.labTestData.testReportRangeClinic]);
    const notificationSystemLabRange = useRef(null);
    const [totalCount, setTotalCount] = useState(0);
    const [labTestRangeModal, setLabTestRangeModal] = useState(false)
    const [testRangeData, setTestRangeData] = useState({})
    const [mode, setMode] = useState("")
    const dispatchLabTestRedux = useDispatch();

    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const getLabTestRangeList = async () => {
        setIsLoading(true);

        let clinicArray = props.labTestData.testReportRangeClinic
        let adminArray = props.labTestData.testReportRangeAdmin
        //console.log(clinicArray, adminArray, "uuuuuuuuuuuu")
        let list = []
        for (let i in adminArray) {
            let obj = adminArray[i]
            obj.mode = "addAdmin"
            list.push(obj)
        }

        for (let j in clinicArray) {
            if (clinicArray[j].adminAddedId) {
                let idx = adminArray.findIndex(x => x._id === clinicArray[j].adminAddedId);
                if (idx != -1) {
                    list = list.filter((ele) => ele._id != clinicArray[j].adminAddedId)
                }
            }
            let obj = clinicArray[j]
            obj.mode = "edit"
            list.push(obj)
        }

        //console.log(list, "listttttttttttt")

        setLabTestRangeList([...list])
        setIsLoading(false)
        return list
        // for(let i in adminArray){
        //     for(let j in clinicArray){
        //         let inserted=false
        //         if(clinicArray[j].adminAddedId){
        //             if(clinicArray[j].adminAddedId===adminArray[i]._id){
        //                 list.push(clinicArray[j])
        //                 inserted=true
        //             }
        //         }
        //         if(!inserted)
        //         list.push(clinicArray[j])
        //     }
        // }
        // props.labTestData.testReportRangeAdmin.map((ele) => {
        //     var localindex_index = props.labTestData.testReportRangeClinic
        //         .map(function (el) {
        //             return el.adminAddedId;
        //         })
        //         .indexOf(obj._id);
        //     if (localindex_index != -1) {
        //         localData = labTestList[localindex_index];
        //     }
        // })

    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
        let rangeSaved = false
        return (<span>

            {/* <Link to={{ pathname: `/admin/edit-lab-test/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link> */}
            {!row.autoCalculate ?
                <><Link title="Edit Lab Test" onClick={(e) => { setTestRangeData({ ...row }); setLabTestRangeModal(true); setMode(row.mode) }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                    &nbsp;&nbsp; &nbsp;&nbsp;</>
                : ""
            }
            {/* <i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row, rowIndex)} aria-hidden="true"></i>
            &nbsp;&nbsp; &nbsp;&nbsp; */}
            {row.mode === "edit" &&
                <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i>
            }
        </span>)
    }


    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        //getLabTestList(data);
    }

    const onSearchChange = (e, field = "") => {

        let saveFormData = formData;
        saveFormData[field] = e.trimStart();
        saveFormData["page"] = 0;
        setFormData({ ...formData, ...saveFormData });
        //getLabTestList(saveFormData);
    }


    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption()

    };

    const deleteLabTestRange = async (id) => {
        let resp = await API.deleteLabTestRange({ "id": id });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            handleToastifyMessage('Record Deleted Sucessfully')
            let tempList = labTestRangeList.filter(item => item._id !== id)
            if (tempList.length === 0) {
                let adminArray = props.labTestData.testReportRangeAdmin
                let list = []
                for (let i in adminArray) {
                    let obj = adminArray[i]
                    obj.mode = "addAdmin"
                    list.push(obj)
                }
                setLabTestRangeList([...list]);
            } else setLabTestRangeList([...tempList]);

            let tempData = props.labTestData
            tempData.testReportRangeClinic = tempList
            let tempLabTestListData = props.labTestList
            var localindex_index = props.labTestList
                .map(function (el) {
                    return el._id;
                })
                .indexOf(tempData._id);
            if (localindex_index != -1) {
                tempLabTestListData[localindex_index] = tempData
            }
            setTimeout(() => {
                dispatchLabTestRedux({
                    type: actionTypes.SET_LAB_TEST_LIST_DATA,
                    payload: tempLabTestListData
                });
            }, 500)
        }
    };

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }

    const statusChangedHandler = async (row, index) => {
        let resp = await API.changeTestReportRangeStatus({ "id": row._id, "status": row.status === "inactive" ? "active" : "inactive" });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            handleToastifyMessage('Status Chnaged Sucessfully')
            let list = labTestRangeList;
            let count = totalCount + 1;
            if (row.status === "active") {
                list[index].status = "inactive"
            } else {
                list[index].status = "active"
            }
            setLabTestRangeList([...list]);
            setTotalCount(count);
            let tempData = props.labTestData
            tempData.testReportRangeClinic = list
            let tempLabTestListData = props.labTestList
            var localindex_index = props.labTestList
                .map(function (el) {
                    return el._id;
                })
                .indexOf(tempData._id);
            if (localindex_index != -1) {
                tempLabTestListData[localindex_index] = tempData
            }


            setTimeout(() => {
                dispatchLabTestRedux({
                    type: actionTypes.SET_LAB_TEST_LIST_DATA,
                    payload: tempLabTestListData
                });
            }, 500)
        }
    }

    const getConfirmFun = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteLabTestRange(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const handleToastifyMessage = (data) => {
        const notification = notificationSystemLabRange.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {data}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }

    const onDismiss = (msg, obj) => {
        setLabTestRangeModal(false)
        let tempList = labTestRangeList
        if (mode === "add") {
            obj.mode = "edit"
            tempList.unshift(obj)
            setLabTestRangeList([...tempList])
        } else if (mode === "addAdmin") {
            let localData = {}
            var localindex_index = tempList
                .map(function (el) {
                    return el._id;
                })
                .indexOf(obj.adminAddedId);
            if (localindex_index != -1) {
                obj.mode = "edit"
                tempList[localindex_index] = obj
            }
        } else {
            let localData = {}
            var localindex_index = tempList
                .map(function (el) {
                    return el._id;
                })
                .indexOf(obj._id);
            if (localindex_index != -1) {
                obj.mode = "edit"
                tempList[localindex_index] = obj
            }

        }

        //console.log(tempList, "ttttttttttt")
        setLabTestRangeList([...tempList])

        let tempData = props.labTestData
        tempData.testReportRangeClinic = tempList
        let tempLabTestListData = props.labTestList
        var localindex_index = props.labTestList
            .map(function (el) {
                return el._id;
            })
            .indexOf(tempData._id);
        if (localindex_index != -1) {
            tempLabTestListData[localindex_index] = tempData
        }
        handleToastifyMessage(msg)

        setTimeout(() => {
            dispatchLabTestRedux({
                type: actionTypes.SET_LAB_TEST_LIST_DATA,
                payload: tempLabTestListData
            });
        }, 500)
        //getLabTestRangeList()
    }


    useEffect(() => {
        getLabTestRangeList()
    }, [])


    //console.log(labTestRangeList, "888888888888")
    return (
        <div className="main-content">
            <div>
                <NotificationSystem ref={notificationSystemLabRange} style={style} />
            </div>
            <div className="fresh-datatables">
                <Row>
                    {/* <Col md={6}>
                                            <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => { setLabTestRangeModal(true); setTestRangeData({}); setMode("add") }}>Add Range</Button>
                                        </Col> */}
                    {/* <Col sm={6} className="form-group">
                                            <FormControl
                                                type="text"
                                                autocomplete="off"
                                                name="search"
                                                id="search"
                                                value={formData.search ? formData.search : ""}
                                                onChange={(e) => {
                                                    onSearchChange(e.target.value, "search");
                                                }}
                                                placeholder="Search here ..."
                                            />
                                        </Col> */}
                </Row>
                <div className='table-responsive'>
                    <BootstrapTable
                        deleteRow={false}
                        key={labTestRangeList}
                        data={labTestRangeList}
                        search={false}
                        multiColumnSearch={true}
                        pagination={false}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={"Bottom"}
                        remote={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        sort={false}
                        trStyle={rowStyleFormat}
                    >
                        <TableHeaderColumn
                            hidden={true}
                            tdAttr={{ "data-attr": "_id" }}
                            dataField="_id"
                            dataSort={true}
                            isKey
                            searchable={false}
                        >
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "#" }}
                            thStyle={{ width: "5%", textAlign: "center" }}
                            tdStyle={{ width: "5%", textAlign: "center" }}
                            dataField="sn"
                            dataFormat={srNo}
                        >
                            S.No.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="rangeFrom"
                        >
                            Range From
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "shortName" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="rangeTo"
                        >
                            Range To
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="ageFrom"
                        >
                            Age From
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="ageTo"
                        >
                            Age To
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="ageType"
                        >
                            Age Type
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField="gender"
                        // dataFormat={getSequence}
                        >
                            Gender
                        </TableHeaderColumn>

                        {/* <TableHeaderColumn
                                                tdAttr={{ "data-attr": "title" }}
                                                thStyle={{ width: "10%", textAlign: "center" }}
                                                tdStyle={{ width: "10%", textAlign: "center" }}
                                                dataField="unit"
                                            // dataFormat={getSequence}
                                            >
                                                Unit
                                            </TableHeaderColumn> */}

                        <TableHeaderColumn
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            tdAttr={{ "data-attr": "_id" }}
                            dataField=""
                            dataFormat={actionButton}
                        >
                            Action
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
            <Modal
                show={labTestRangeModal}
                onHide={() => setLabTestRangeModal(false)}
                dialogClassName="modal-md"
                className="Modal_downsearch test-range-modal"
            >
                <Modal.Header
                    closeButton
                    className="bg-thumbcolor"
                >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {mode === "add" ? "Add Lab Test Range" : "Edit Lab Test Range"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddEditLabTestRange
                        testRangeData={testRangeData}
                        mode={mode}
                        testReportId={props.labTestData._id}
                        onDismiss={onDismiss}
                        labTestList={props.labTestList}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LabTestRangeList
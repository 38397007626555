/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/supportSystem";
import SweetAlert from "react-bootstrap-sweetalert";
const AddTicket = ({
  getListAction,
  data,
  setModal,
  ModalStatus,
  addTicket,
  setAddTicket,
}) => {
  const [formData, setFormData] = useState({ ...data });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(null);
  const [formDataFile, setFormDataFile] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [fileNotPng, setFileNotPng] = useState(false);

  const inputHandler = (e) => {
    setIsSpecialChar(false);
    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.title && post.title != "") {
      errArr.title = "";
    } else {
      errArr.title = "error";
      errflag = false;
    }

    if (post.description && post.description != "") {
      errArr.description = "";
    } else {
      errArr.description = "error";
      errflag = false;
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let saveFormData = formData;
    saveFormData["title"] = formData.title?.trim();
    saveFormData["description"] = formData.description?.trim();
    setFormData({ ...formData, ...saveFormData });
    let regxSpecialChar = /[*|\":<>[\]{}`\\()';@&$!~`#%^_+=?,./]/;
    if (regxSpecialChar.test(formData.title)) {

      setIsSpecialChar(true);
      return;
    }
    if (validate()) {
      setIsLoading(false);
      let sentData = new FormData();
      sentData.append("title", formData.title);
      sentData.append("description", formData.description);
      sentData.append("file", formDataFile);
      let resp = await API.addTicket(sentData);
      successAlert("You have successfully added a ticket");
      getListAction();
      setIsLoading(false);
    }
  };

  const changeHandlerFile = (event) => {
    if (event.target.files.length > 0) {
      if ((event.target.files[0].type === "image/png") === true) {
        let formDataTemp = event.target.files[0];

        setFormDataFile(formDataTemp);
        setFormData({ file: event.target.files, ...formData });

      } else {
        event.target.value = null;
        setFileNotPng(true);
        setTimeout(function () {
          setFileNotPng(false);
        }, 6000);
      }
    }
  };

  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        //onCancel={() =>setModal(false)}
        confirmBtnBsStyle="info"
      >
        {msg}
      </SweetAlert>
    );
    setModal(false);
    setTimeout(function () {
      setAlert(null);
    }, 3000);
  };

  useEffect(() => {
    if (ModalStatus === true && addTicket === false) {
      setFormData({ ...data });
    }
  }, [ModalStatus]);

  const addTicketFun = () => {
    setAddTicket(true);
    setModal(true);
    setIsSpecialChar(false);
    setFileNotPng(false);
    setErrors({});
    setFormData({});
  };

  return (
    <span>
      {alert}
      <button
        type="button"
        className="btn-fill btn btn-primary btn btn-default mangeBtns"
        onClick={(e) => addTicketFun()}
      >
        Add New Ticket
      </button>
      <Modal
        show={ModalStatus}
        onHide={() => setModal(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {addTicket === true ? "Add New Ticket" : "Edit Ticket"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share">
          <Row>
            <Col md={12}>
              <Form
                horizontal
                noValidate
                validated={validated}
                onSubmit={(e) => submitHandler(e)}
              >
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Title<span className="star">*</span>
                    </Col>
                    <Col sm={12} md={6}>
                      <Row>
                        <Col sm={12} md={9}>
                          <FormControl
                            type="text"
                            autocomplete="off"
                            name="title"
                            value={formData.title ? formData.title : ""}
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                            placeholder="Title"
                            className={
                              "insulin-input " +
                              (errors.title ? errors.title : "")
                            }
                          />
                          {isSpecialCharacter && (
                            <span className="star">
                              Special Characters Are Not Allowed
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Description<span className="star">*</span>
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        rows="3"
                        autocomplete="off"
                        componentClass="textarea"
                        name="description"
                        bsClass="form-control unmandt-f"
                        value={formData.description ? formData.description : ""}
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        placeholder="Description"
                        className={
                          "" + (errors.description ? errors.description : "")
                        }
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={12} md={4}>
                      Attachment
                    </Col>
                    <Col sm={12} md={6}>
                      <FormControl
                        placeholder="Upload File"
                        aria-label="File"
                        aria-describedby="basic-addon1"
                        onChange={(e) => {
                          changeHandlerFile(e);
                        }}
                        onBlur={(e) => {
                          changeHandlerFile(e);
                        }}
                        name="file"
                        type="file"
                        accept=".png"
                        allowedFileTypes={["image/png"]}
                      />
                      {fileNotPng && (
                        <span className="star">Only Png Files Are Allowed</span>
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12} md={12}>
                  <Col componentClass={ControlLabel} sm={12} md={4}></Col>
                  <Col sm={12} md={6}>
                    <Button
                      type="submit"
                      className="btn-fill btn btn-primary"
                      disabled={isLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Col>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill btn btn-secondary pull-right"
            onClick={() => setModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};
export default withRouter(AddTicket);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/


import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';
import * as API from '../../../../api/emr'

const AddEditLabTest = (props) => {
    let history = useHistory();

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)
    const [mode, setMode] = useState("add");
    const [labTestId, setlabTestId] = useState("")
    const [orderAlreadyExist, setOrderAlreadyExist] = useState(false)
    const [orderAlreadyExistMsg, setOrderAlreadyExistMsg] = useState(false)
    const [onlyNumberValidationRangeFrom, setOnlyNumberValidationRangeFrom] = useState(false)
    const [onlyNumberValidationRangeTo, setOnlyNumberValidationRangeTo] = useState(false)

    document.addEventListener("keydown", function (event) {
        // Check if the keyCode is 8 (backspace)
        if (event.target.name === "validationRangeFrom") {
            if (onlyNumberValidationRangeFrom || event.keyCode != 8)
                return;
            if (event.keyCode === 8) {
                setOnlyNumberValidationRangeFrom(false)
            }
            return;
        }

        if (event.target.name === "validationRangeTo") {
            if (onlyNumberValidationRangeTo || event.keyCode != 8)
                return;
            if (event.keyCode === 8) {
                setOnlyNumberValidationRangeTo(false)
            }
            return;
        }

    });

    const inputHandler = (e) => {
        if (e.target.name === "order" && orderAlreadyExist) {
            setOrderAlreadyExist(false)
            setOrderAlreadyExistMsg("")
        }

        if (e.target.name === "validationRangeFrom") {
            let val = e.target.value.replace(/\s+/g, " ");
            val = val.trimStart();
            let regex = new RegExp(/^[0-9.]*$/);
            if (!regex.test(val)) {
                setOnlyNumberValidationRangeFrom(true)
                return;
            } else setOnlyNumberValidationRangeFrom(false)
        }

        if (e.target.name === "validationRangeTo") {
            let val = e.target.value.replace(/\s+/g, " ");
            val = val.trimStart();
            let regex = new RegExp(/^[0-9.]*$/);

            if (!regex.test(val)) {
                if (!regex.test(val)) {
                    setOnlyNumberValidationRangeTo(true)
                    return;
                } else setOnlyNumberValidationRangeTo(false)
            }
        }



        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
        validate();


    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.order && post.order != '') {
            errArr.order = '';
        } else { errArr.order = 'error'; errflag = false; }

        if (post.name && post.name != '') {
            errArr.name = '';
        } else { errArr.name = 'error'; errflag = false; }

        // if (post.unit && post.unit != '') {
        //     errArr.unit = '';
        // } else { errArr.unit = 'error'; errflag = false; }

        // if (post.range && post.range != '') {
        //     errArr.range = '';
        // } else { errArr.range = 'error'; errflag = false; }




        setErrors({ ...errArr });
        return errflag;
    }

    const submitHandler = async (event) => {
        setIsLoading(true);
        event.preventDefault();

        if (validate()) {
            let sentData = new FormData();
            sentData.append('name', formData.name)
            sentData.append('order', formData.order)
            // if (formData.range != undefined)
            //     sentData.append('range', formData.range)
            // else sentData.append('range', "")
            if (formData.unit != undefined)
                sentData.append('unit', formData.unit)
            else sentData.append('unit', "")

            if (formData.shortName != undefined)
                sentData.append('shortName', formData.shortName)
            else sentData.append('shortName', "")

            if (formData.validationRangeFrom != undefined)
                sentData.append('validationRangeFrom', formData.validationRangeFrom)
            else sentData.append('validationRangeFrom', "")

            if (formData.validationRangeTo != undefined)
                sentData.append('validationRangeTo', formData.validationRangeTo)
            else sentData.append('validationRangeTo', "")

            //console.log(formData, "submit")
            let resp = ""
            if (mode === "add") {
                resp = await API.addLabTest(sentData);
                setIsLoading(false);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    history.push({ pathname: "/admin/lab-test-list", from: "add" })
                }
            } else {
                sentData.append('id', labTestId)
                resp = await API.updateLabTest(sentData);
                setIsLoading(false);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    history.push({ pathname: "/admin/lab-test-list", from: "edit" })
                }
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        let row = props.location.state?.row;
        let saveFormData = formData;
        saveFormData["order"] = "";
        saveFormData["name"] = "";

        if (row !== undefined && row) {
            setMode("edit");
            // console.log(row, "rowww")
            setlabTestId(row._id);
            saveFormData["order"] = row.order;
            saveFormData["name"] = row.name;
            saveFormData["unit"] = row.unit;
            // saveFormData["range"] = row.range;
            saveFormData["shortName"] = row.shortName;
            saveFormData["validationRangeFrom"] = row.validationRangeFrom;
            saveFormData["validationRangeTo"] = row.validationRangeTo;

            setFormData({ ...formData, ...saveFormData });
        }

    }, [])

    return (
        <div className="main-content">

            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={<legend className="line-removes">
                                <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { history.goBack() }}>Back</Button>
                            </legend>}
                            content={
                                <Form horizontal className="add-supportsystem_form">
                                    <FormGroup>
                                        <Col sm={12}>
                                            <Row>
                                                <Col md={6}>
                                                    <ControlLabel>Test<span className="star">*</span></ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        placeholder="Test"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.name ? formData.name : ''}
                                                        className={'insulin-input ' + (errors.name ? errors.name : '')}
                                                    />
                                                    {/* {isSpecialCharacter &&
                                                            <span className="star">Special Characters Are Not Allowed</span>
                                                        } */}

                                                </Col>
                                                <Col md={6}>
                                                    <ControlLabel>Short Name</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="shortName"
                                                        id="shortName"
                                                        placeholder="Short Name"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.shortName ? formData.shortName : ''}
                                                        className={'insulin-input ' + (errors.shortName ? errors.shortName : '')}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col sm={12}>
                                            <Row>
                                                <Col md={6}>
                                                    <ControlLabel>Unit</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="unit"
                                                        id="unit"
                                                        placeholder="Unit"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.unit ? formData.unit : ''}
                                                        className={'insulin-input ' + (errors.unit ? errors.unit : '')}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <ControlLabel>Order<span className="star">*</span></ControlLabel>
                                                    <FormControl
                                                        type="number"
                                                        name="order"
                                                        id="order"
                                                        placeholder="Order"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.order ? formData.order : ''}
                                                        className={'insulin-input ' + (errors.order ? errors.order : '')}
                                                    />
                                                    {orderAlreadyExist &&
                                                        <span className="star">{orderAlreadyExistMsg}</span>
                                                    }

                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                    {/* <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Range</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="range"
                                                            id="range"
                                                            placeholder="Range"
                                                            onChange={e => { inputHandler(e); }}
                                                            autocomplete="off"
                                                            value={formData.range ? formData.range : ''}
                                                            className={'insulin-input ' + (errors.range ? errors.range : '')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset> */}
                                    <FormGroup>
                                        <Col sm={12}>
                                            <Row>
                                                <Col md={6}>
                                                    <ControlLabel>Validation Range From</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="validationRangeFrom"
                                                        id="validationRangeFrom"
                                                        placeholder="Range From"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.validationRangeFrom ? formData.validationRangeFrom : ''}
                                                        className={'insulin-input ' + (errors.validationRangeFrom ? errors.validationRangeFrom : '')}
                                                    />
                                                    {onlyNumberValidationRangeFrom &&
                                                        <span className="star">Only numbers are allowed</span>
                                                    }
                                                </Col>
                                                <Col md={6}>
                                                    <ControlLabel>Validation Range To</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="validationRangeTo"
                                                        id="validationRangeTo"
                                                        placeholder="Range To"
                                                        onChange={e => { inputHandler(e); }}
                                                        autocomplete="off"
                                                        value={formData.validationRangeTo ? formData.validationRangeTo : ''}
                                                        className={'insulin-input ' + (errors.validationRangeTo ? errors.validationRangeTo : '')}
                                                    />
                                                    {onlyNumberValidationRangeTo &&
                                                        <span className="star">Only numbers are allowed</span>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">
                                        </ControlLabel>
                                        <Col sm={12} className="btn_wrap">
                                            <Row>
                                                <Col md={12}>
                                                    <button
                                                        type="button"
                                                        disabled={isLoading || onlyNumberValidationRangeFrom || onlyNumberValidationRangeTo}
                                                        className="btn-fill btn-wd btn btn-primary"
                                                        onClick={e => { submitHandler(e) }}>Submit{isLoading && <i class="fa fa-spinner fa-spin"></i>}</button>&nbsp;

                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default AddEditLabTest
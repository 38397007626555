/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Row, Col,
    Nav, NavItem,
    Tab, Modal, FormControl, FormGroup, ControlLabel, Radio, NavDropdown, MenuItem
} from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { chatUserListAction, getPatientChatAction, forwardChatAction, readChatAction, getChatCountAction, chatTemplateListAction, editChatTemplateAction, deleteChatTemplateAction } from 'Front/actions/master';
import { appConstants } from 'Front/_constants/app.constants.js';
import { getBMI, getAgeByDob } from 'Front/views/Home/PublicFunction.jsx';
import Diet from 'Front/views/Diet/Diet';
import moment from 'moment';
import TaskScreen from 'Front/views/TaskScreen/TaskScreenPopup.jsx';
import loadingImg from '../../assets/img/loader.gif';
import Loading from 'react-loading';
import { confirmAlert } from 'react-confirm-alert';

var $ = require("jquery");
let deletedChatId = '';
let forwardChatId = '';
let endUserChat = false;
let endUserList = false;
let existPatient = false;
var chat_color = "";
var googleLoad = false;

let google = window.google;

google.load("elements", "1", {
    packages: "transliteration"
});

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatModal: false,
            ChatUserList: [],
            GetPatientChat: [],
            activeTab: 'chat',
            activeUser: [],
            activateUser: this.props.chatPatientId ? this.props.chatPatientId : '',
            activateUserDetails: this.props.chatPatientDetails ? this.props.chatPatientDetails : '',
            activeCount: 0,
            chatCount: 0,
            forwardChatId: '',
            chatPage: 0,
            userPage: 0,
            isUptNewChatUser: true,
            AllChatCount: 0,
            forwardedChatCount: 0,
            byEducatorChatCount: 0,
            ReadchatCount: [],
            formData: {
                patient_id: "",
                city: "",
                first_name: "",
                last_name: "",
                direction: "desc",
                order: "createdAt",
                offset: 0,
                limit: 10,
                message_hindi: '',
                message_english: '',
                selected_type: 'selected',
            },
            activeNew: false,
            activeNewChat: false,
            lastChatId: null,
            showProcessing: false,
            newTab: false,
            settingsMenuOpen: false,
            saveChatTemplate: false,
            chatTemplateModal: false,
            editChatTemplateModal: false,
            ChatTemplateList: [],
            selectedTemplate: {
                msg: "",
                templateId: ""
            },
            validateChatTemplate: false,
            activeUserChatCount: 0,
        };

        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.handleChatTemplate = this.handleChatTemplate.bind(this);
        this.getChatTemplateList = this.getChatTemplateList.bind(this);
        this.editChatTemplate = this.editChatTemplate.bind(this);
        this.inputHandler = this.inputHandler.bind(this);
        this.handleSubmitChatTemplate = this.handleSubmitChatTemplate.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        //this.popOver=this.popOver.bind(this)

        let _this = this;

        appConstants.socket.on('forwadMessage', function (data) {

            if (localStorage.getItem("userType") == "doctor") {
                _this.setState({ forwardedChatCount: (_this.state.forwardedChatCount + 1) });

                if (_this.state.activeTab === 'forwarded') {
                    let ChatUserList = _this.state.ChatUserList;

                    for (let i in ChatUserList) {
                        if (ChatUserList[i]._id === data.patientId) {
                            ChatUserList[i].chatCount += 1;
                            ChatUserList[i].msg = data.msg;
                            break;
                        }
                    }

                    _this.setState({ ChatUserList: ChatUserList });
                }
            }
        });

        appConstants.socket.on('chatTemplate', function (data) {

            let hasTemplate = _this.state.ChatTemplateList.find(template => template['_id'] === data._id)
            if (hasTemplate === undefined) {
                let tempList = _this.state.ChatTemplateList
                tempList.push(data)
                _this.setState({ ChatTemplateList: tempList })
            }
        });



        appConstants.socket.on('newMsg', function (data) {
            //.log('newMsg', data);

            if (data.chat.senderType === "doctor") {
                chat_color = 'drChatColor'
            } else if (data.chat.senderType === "educator") {
                chat_color = 'edChatColor'
            }
            else if (data.chat.senderType === 'patient') {
                chat_color = 'ptChatColor'
            }


            //if(localStorage.getItem('chatModel') === 'true'){
            ////console.log('in');
            if (data.chat.patientId === _this.state.activateUser) {
                //console.log("active user")
                let GetPatientChat = _this.state.GetPatientChat;
                let senderName = data.chat.senderName;
                // if(data.senderType === "doctor"){
                //   senderName = "Dr. "+data.senderName;
                // }
                const newChat = {
                    clinicId: data.chat.clinicId,
                    clinicSendId: data.chat.clinicSendId,
                    createdAt: data.chat.createdAt,
                    deleted: data.chat.deleted,
                    forwarded: data.chat.forwarded,
                    msg: data.chat.msg,
                    patientId: data.chat.patientId,
                    patientReadStatus: data.chat.patientReadStatus,
                    senderName: senderName,
                    senderType: data.chat.senderType,
                    type: data.chat.type,
                    updatedAt: data.chat.updatedAt,
                    __v: data.chat.__v,
                    _id: data.chat._id
                }

                //('2 days before', newChat)

                GetPatientChat.push(newChat);

                if ((_this.state.activeTab === 'forwarded' && data.chat.forwarded) || (_this.state.activeTab === 'chat' && (data.chat.senderType === "patient"))) {

                    let ReadchatCount = _this.state.ReadchatCount;
                    ReadchatCount[data.chat.patientId] = true;
                    _this.setState({ ReadchatCount: ReadchatCount });

                    if (_this.state.ChatUserList) {
                        let ChatUserList = _this.state.ChatUserList;
                        let chatCount = 0
                        for (let i in ChatUserList) {
                            if (ChatUserList[i]._id === data.chat.patientId) {
                                ChatUserList[i].chatCount += 1;
                                ChatUserList[i].msg = data.chat.msg;
                                break;
                            }
                        }
                        //console.log(ChatUserList, "testttttttt")

                        _this.setState({ ChatUserList, ChatUserList });
                    }
                }

                if (data.chat.senderType === "patient") {
                    _this.setState({ AllChatCount: (_this.state.AllChatCount + 1) });
                }

                _this.setState({ GetPatientChat: GetPatientChat });


                setTimeout(function () {
                    if (localStorage.getItem('chatModel') === 'true') {
                        var objDiv = document.getElementById("scrolldiv");
                        if (objDiv != null)
                            objDiv.scrollTop = objDiv?.scrollHeight;
                    }
                }, 300);

            } else {

                let ReadchatCount = _this.state.ReadchatCount;
                ReadchatCount[data.chat.patientId] = true;
                _this.setState({ ReadchatCount: ReadchatCount });


                if ((_this.state.activeTab === 'forwarded' && data.chat.forwarded) || (_this.state.activeTab === 'chat' && (data.chat.senderType === "patient"))) {
                    if (_this.state.ChatUserList) {
                        let ChatUserList = _this.state.ChatUserList;

                        for (let i in ChatUserList) {
                            if (ChatUserList[i]._id === data.chat.patientId) {
                                ChatUserList[i].chatCount += 1;
                                ChatUserList[i].msg = data.chat.msg;
                                break;
                            }
                        }

                        _this.setState({ ChatUserList: ChatUserList });
                    }
                }

                // if (data.chat.senderType === "doctor" || data.chat.senderType === "patient") {
                //     ////console.log(_this.state.AllChatCount)
                //     _this.setState({ AllChatCount: (_this.state.AllChatCount + 1) });
                // }
                // else if (data.chat.senderType === 'educator') {
                //     _this.setState({ AllChatCount: (_this.state.AllChatCount + 1) });
                //     _this.setState({ byEducatorChatCount: (_this.state.byEducatorChatCount + 1) });
                // }

                if (data.chat.senderType === "patient") {
                    ////console.log(_this.state.AllChatCount)
                    _this.setState({ AllChatCount: (_this.state.AllChatCount + 1) });
                }
                // else if (data.chat.senderType === 'educator') {
                //     _this.setState({ AllChatCount: (_this.state.AllChatCount + 1) });
                //     _this.setState({ byEducatorChatCount: (_this.state.byEducatorChatCount + 1) });
                // }

                let chatC = localStorage.getItem('AllCount');

                //localStorage.setItem('AllCount', parseInt(chatC) + 1);

            }

            //}


        });


        $(document).ready(function () {
            $('.scrolldiv').scroll(function () {
                if ($(".scrolldiv").scrollTop() === 0 && localStorage.newTab === 'false') {
                    //console.log(_this.state);
                    let chatPage = _this.state.chatPage + 1;
                    _this.state.activeNew = false;
                    ////console.log('scrolldiv');
                    _this.setState({ chatPage: chatPage, activeNew: false });

                    if (endUserChat === false) {
                        const params2 = {
                            limit: 10,
                            lastChatId: _this.state.lastChatId,
                            patientId: _this.state.activateUser,
                        }

                        //console.log('getPatientChatAction3');
                        _this.props.getPatientChatAction(params2);
                    }
                }

            });


            $('.chat_user_list_box').scroll(function () {
                if ($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
                    let userPage = _this.state.userPage + 1;
                    _this.setState({ userPage: userPage });
                    if (endUserList === false) {
                        const params2 = {
                            limit: 10,
                            skip: userPage,
                            type: _this.state.activeTab,
                        }
                        _this.props.chatUserListAction(params2);
                    }
                }
            });
        });
    }



    componentDidMount() {
        existPatient = false;
        this.getChatTemplateList();
        if (this.props && this.props.activeAllChat) {
            //console.log('bhim');
            //this.setState({activeNewChat:true});
        }

        this.setState({ showProcessing: true })

        const params1 = {
            limit: 10,
            skip: 0,
            type: this.state.activeTab,
        }
        this.props.chatUserListAction(params1);

        this.props.getChatCountAction();
        localStorage.setItem('newTab', false);
        // setTimeout(() => {
        //     ////console.log(this.state);
        //     const params = {
        //         type: this.state.activeTab,
        //         patientId: this.state.activateUser,
        //     }
        //     this.props.readChatAction(params);
        // }, 6000);
        setTimeout(() => {
            this.props.getChatCountAction();
        }, 7000);
    }



    componentWillMount() {

        // if(localStorage.getItem('AllChatCount'))
        //     this.setState({AllChatCount:parseInt(localStorage.getItem('AllChatCount'))});
        //
        // if(localStorage.getItem('forwardedChatCount'))
        //     this.setState({forwardedChatCount:parseInt(localStorage.getItem('forwardedChatCount'))});
        //
        // if(localStorage.getItem('byEducatorChatCount'))
        //     this.setState({byEducatorChatCount:parseInt(localStorage.getItem('byEducatorChatCount'))});
    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.isChatTemplateList && nextProps.ChatTemplateList?.data) {
            this.setState({ ChatTemplateList: nextProps.ChatTemplateList?.data })
        } else {
            if (nextProps.isEditChatTemplate && nextProps.editChatTemplate?.status === "Success" && nextProps.editChatTemplate?.statusCode === 200) {
                let tempList = this.state.ChatTemplateList
                tempList.map((itm, key) => {
                    if (itm._id === this.state.selectedTemplate.templateId) {
                        itm.msg = this.state.selectedTemplate.msg;
                    }
                })
                this.setState({ ChatTemplateList: tempList })
            }
            if (nextProps.isDeleteChatTemplate && nextProps.deleteChatTemplate?.status === "Success" && nextProps.deleteChatTemplate?.statusCode === 200) {
                let tempList = this.state.ChatTemplateList.filter(chat => chat._id !== deletedChatId)
                this.setState({ ChatTemplateList: tempList })
            }

        }




        //if(nextProps.GetChatCount && nextProps.GetChatCount.chatCounts){
        if (nextProps.isGetChatCount !== this.props.isGetChatCount && this.props.isGetChatCount === false) {
            let chatCounts = nextProps.GetChatCount.chatCounts;

            let totalCount = 0;
            let AllChatCount = 0;
            let forwardedChatCount = 0;
            let byEducatorChatCount = 0;

            for (let i in chatCounts) {
                if (chatCounts[i]._id === 'chat') {
                    // console.log(chatCounts[i].count, "insidechat")
                    totalCount = totalCount + chatCounts[i].count;
                    AllChatCount = chatCounts[i].count;
                }
                else if (chatCounts[i]._id === 'forwarded') {
                    //console.log(chatCounts[i].count, "insideforwarded")
                    totalCount = totalCount + chatCounts[i].count;
                    forwardedChatCount = chatCounts[i].count;
                }
                // else if (chatCounts[i]._id === 'byEducator') {
                //     totalCount = totalCount + chatCounts[i].count;
                //     byEducatorChatCount = chatCounts[i].count;
                // }
            }
            localStorage.setItem('AllChatCount', AllChatCount)
            this.setState({ AllChatCount: AllChatCount });

            localStorage.setItem('forwardedChatCount', forwardedChatCount)
            this.setState({ forwardedChatCount: forwardedChatCount });
            localStorage.setItem('byEducatorChatCount', byEducatorChatCount)
            this.setState({ byEducatorChatCount: byEducatorChatCount });
            //console.log(totalCount, "inside chat")
            localStorage.setItem('AllCount', totalCount)
        }

        if (nextProps.isChatForward !== this.props.isChatForward && this.props.isChatForward === false) {
            document.getElementById("forwadIcon_" + forwardChatId).innerHTML = "<small>Forwarded to doctor</small>";
            document.getElementById("forwadIcon_" + forwardChatId).style.pointerEvents = "none";
            let forwardCount = this.state.forwardedChatCount + 1
            this.setState({ forwardedChatCount: forwardCount })
        }


        if (nextProps.isChatUserList !== this.props.isChatUserList && this.props.isChatUserList === false) {

            let ChatUsers = nextProps.ChatUserList.list;
            if (ChatUsers.length === 0) {
                endUserList = true;
            }

            // if(ChatUsers.length !== 0){

            let usersArr;

            let ChatCount = this.state.chatCount;

            if (this.state.userPage !== 0 && this.state.userPage !== 1 && ChatUsers.length > 0) {

                let ChatUserList = this.state.ChatUserList;

                for (let i in ChatUsers) {
                    ChatUserList.push(ChatUsers[i]);
                }

                usersArr = ChatUserList;
                ChatCount = nextProps.ChatUserList.list.length;

            } else if (this.state.userPage !== 0 && ChatUsers.length === 0) {

                usersArr = this.state.ChatUserList;
                ChatCount = this.state.chatCount;

            } else {
                let reverseA = this.state.ChatUserList;
                if (reverseA.length && ChatUsers.length)
                    usersArr = reverseA.concat(ChatUsers);
                else
                    usersArr = ChatUsers;

                ChatCount = nextProps.ChatUserList.list.length;
            }

            let activeUser = this.state.activeUser;

            const filteredArray = Object.values(usersArr.reduce((unique, o) => {
                if (!unique[o.patientId]) unique[o.patientId] = o;

                return unique;
            }, {}));

            let users = filteredArray;
            usersArr = filteredArray;

            let ReadchatCount = this.state.ReadchatCount;

            for (let i in users) {

                if (users[i].chatCount !== 0)
                    ReadchatCount[users[i].patientId] = true;
                else
                    ReadchatCount[users[i].patientId] = false;


                if (ChatUsers.length !== 0) {
                    if (this.props.chatPatientId && users[i].patientId === this.props.chatPatientId) {
                        activeUser[users[i].patientId] = true;

                    } else {
                        activeUser[users[i].patientId] = false;
                    }

                }

                if (this.props.chatPatientId === users[i].patientId) {
                    existPatient = true;
                }
            }

            if (this.props && this.props.chatPatientDetails && existPatient === false) {
                usersArr.unshift({ patientId: this.props.chatPatientId, msg: '..', chatCount: 0, patient: [{ firstName: this.props.chatPatientDetails.firstName, lastName: '' }] })
            }
            else {
                if (usersArr && usersArr[0] && usersArr[0].patient && usersArr[0].patient[0]) {
                    this.setState({ activateUserDetails: usersArr[0].patient[0] });
                }

            }

            let activateUser;
            if (ChatUsers.length === 0) {
                activateUser = this.state.activateUser;
            } else {
                activateUser = usersArr[0].patientId
            }

            this.setState({ activateUser: activateUser, ChatUserList: usersArr, activeCount: 1, chatCount: ChatCount, ReadchatCount: ReadchatCount });

            if (this.props && this.props.chatPatientId) {
                this.setState({ activateUser: this.props.chatPatientId })
                activateUser = this.props.chatPatientId
            }

            if (ChatUsers.length !== 0) {
                const params2 = {
                    limit: 10,
                    skip: this.state.chatPage,
                    patientId: activateUser,
                }
                ////console.log('getPatientChatAction1')                
                this.props.getPatientChatAction(params2);

                let activeUser1 = this.state.activeUser;

                activeUser1[activateUser] = true;

                this.setState({ activeUser: activeUser1 });

                let addUser = {
                    userId: localStorage.getItem('_id'),
                    screen: activateUser,
                }
                //appConstants.socket.emit('currentScreen', addUser);

                // setTimeout(() => {
                //     ////console.log(this.state);
                //     const params = {
                //         type: this.state.activeTab,
                //         patientId: this.state.activateUser,
                //     }
                //     this.props.readChatAction(params);
                // }, 2000);
                setTimeout(() => {
                    this.props.getChatCountAction();
                }, 4000);

            }

            // }

            let _this = this;

            setTimeout(function () {
                _this.setState({ showProcessing: false })

            }, 1000);
        }


        if (nextProps.isGetPatientChat !== this.props.isGetPatientChat && this.props.isGetPatientChat === false) {
            //console.log(this.state);
            localStorage.setItem('newTab', false);
            //////console.log(nextProps.GetPatientChat);
            let chatArr = nextProps.GetPatientChat.list;
            if (nextProps.GetPatientChat.list.length === 0) {
                endUserChat = true;
            } else {
                let index = nextProps.GetPatientChat.list.length - 1;
                this.setState({ lastChatId: nextProps.GetPatientChat.list[index]._id })
            }

            if (nextProps.GetPatientChat.list.length > 0) {
                if (this.props && this.props.chatPatientId && this.state.chatPage === 0) {

                    var reverse = chatArr.reverse();

                    this.setState({ GetPatientChat: reverse });

                } else {
                    //console.log('innnn');
                    let GetPatientChat = this.state.GetPatientChat;
                    var reverseR;
                    if (this.state.chatPage === 0) {
                        reverseR = chatArr.reverse();
                    }
                    else {
                        reverseR = chatArr;
                    }

                    var combine;
                    if (this.state.activeNew === false && this.state.activeNewChat === false) {
                        //console.log('concat');
                        combine = reverseR.concat(GetPatientChat);
                    }
                    else { //console.log('reverseR');
                        combine = reverseR;
                    }

                    this.setState({ GetPatientChat: combine });

                }


                if (this.state.chatPage === 0) {
                    setTimeout(function () {
                        ////console.log('bhim');
                        var objDiv = document.getElementById("scrolldiv");
                        if (objDiv.scrollHeight) {
                            objDiv.scrollTop = objDiv.scrollHeight;
                        }
                    }, 300);

                } else {
                    setTimeout(function () {

                        $(".scrolldiv").scrollTop(100);

                    }, 500);
                }
            }
        }

        if (nextProps.isReadChat !== this.props.isReadChat && this.props.isReadChat === false) {
            let ReadchatCount = this.state.ReadchatCount;
            ReadchatCount[this.state.activateUser] = false;

            let ChatUserList = this.state.ChatUserList;
            let chatCount = 0
            for (let i in ChatUserList) {
                if (ChatUserList[i]._id === this.state.activateUser) {
                    chatCount = ChatUserList[i].chatCount
                    ChatUserList[i].chatCount = 0;
                    break;
                }
            }
            if (this.state.activeTab === "chat") {
                let AllChatCount = this.state.AllChatCount - chatCount;
                AllChatCount = AllChatCount < 0 ? 0 : AllChatCount
                localStorage.setItem('AllChatCount', AllChatCount);
                this.setState({ AllChatCount: AllChatCount });
            } else {
                let forwardChatCount = this.state.forwardedChatCount - chatCount
                this.setState({ forwardedChatCount: forwardChatCount });
            }


            // this.setState({ ChatUserList: ChatUserList });
            this.setState({ ReadchatCount: ReadchatCount, ChatUserList: ChatUserList, activeUserChatCount: chatCount });
        }
    }

    sendMessageAction() {
        //console.log(this.state.ChatUserList, "hhhhhhh")
        var message = document.getElementById('message').value;
        if (message !== '') {
            let Name = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
            let ChatUserList = this.state.ChatUserList;
            let patientChat = ChatUserList.find((ele) => ele._id === this.state.activateUser)
            let data = JSON.stringify(patientChat)
            let obj = JSON.parse(data)
            const params = {
                msg: message,
                patientId: this.state.activateUser,
                notification_token: this.state.activateUserDetails.notification_token,
                clinicId: localStorage.getItem('clinicId'),
                senderId: localStorage.getItem('_id'),
                userType: localStorage.getItem('userType'),
                senderName: Name,
                saveChatTemplate: this.state.saveChatTemplate,
                chatCount: obj.chatCount,
                isForwad: this.state.activeTab === "chat" ? false : true
            }
            // console.log(params, "params")
            appConstants.socket.emit('sendToPatient', params);
            document.getElementById('message').value = '';
            if (this.state.saveChatTemplate) {
                this.setState({ saveChatTemplate: false })
            }
            setTimeout(function () {
                ////console.log('sned');
                var objDiv = document.getElementById("scrolldiv");
                if (objDiv != null)
                    objDiv.scrollTop = objDiv?.scrollHeight;

            }, 300);

            setTimeout(() => {
                ////console.log(this.state);
                const params = {
                    type: this.state.activeTab,
                    patientId: this.state.activateUser,
                }
                this.props.readChatAction(params);
            }, 500);
        }


    }

    sendChatTemplateAction(row) {

        var message = row.msg;
        if (message !== '') {
            let Name = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');

            const params = {
                msg: message,
                patientId: this.state.activateUser,
                notification_token: this.state.activateUserDetails.notification_token,
                clinicId: localStorage.getItem('clinicId'),
                senderId: localStorage.getItem('_id'),
                userType: localStorage.getItem('userType'),
                senderName: Name,
                saveChatTemplate: this.state.saveChatTemplate
            }

            appConstants.socket.emit('sendToPatient', params);
            document.getElementById('message').value = '';

            setTimeout(function () {
                ////console.log('sned');
                var objDiv = document.getElementById("scrolldiv");
                objDiv.scrollTop = objDiv.scrollHeight;

            }, 300);
            this.setState({ chatTemplateModal: false })
        }


    }

    sendChatTemplate(row) {
        this.sendChatTemplateAction(row)

    }



    handleChatTemplate() {
        this.setState({ saveChatTemplate: !this.state.saveChatTemplate })
    }

    inputHandler = (e) => {

        if (e.target.value === "")
            this.setState({ validateChatTemplate: true })
        else this.setState({ validateChatTemplate: false })
        let data = {}
        let { msg, templateId } = this.state.selectedTemplate
        data.msg = msg;
        data.templateId = templateId;
        data.msg = e.target.value
        this.setState({ selectedTemplate: data })
    }



    editChatTemplate(row) {
        let data = {}
        data.msg = row.msg
        data.templateId = row._id
        this.setState({ selectedTemplate: data, editChatTemplateModal: true })
    }

    handleDeleteTemplate(row) {

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deletedChatId = '';
                        deletedChatId = row._id;
                        this.props.deleteChatTemplateAction({ templateId: row._id })
                        this.setState({ editChatTemplateModal: false })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    handleSubmitChatTemplate() {
        if (this.state.selectedTemplate.msg === "") {
            this.setState({ validateChatTemplate: true })
            return
        }
        this.props.editChatTemplateAction(this.state.selectedTemplate)
        this.setState({ editChatTemplateModal: false })
    }




    getChatTemplateList() {
        //console.log("getChatTemplateList")
        this.props.chatTemplateListAction({});
    }



    changeUserTab(e, type) {

        this.setState({ showProcessing: true })
        endUserChat = false;
        endUserList = false;
        this.setState({ chatPage: 0, userPage: 0, ChatUserList: [], isUptNewChatUser: true });

        const params = {
            limit: 10,
            skip: 0,
            type: type,
        }
        this.setState({ activeTab: type })
        this.props.chatUserListAction(params);
    }

    forwardMessage(e, chat_id, patientId, msg) {
        forwardChatId = chat_id;
        const params = {
            chat_id: chat_id
        }

        this.props.forwardChatAction(params);

        let forwardMessage = {
            clinicId: localStorage.getItem('clinicId'),
            patientId: patientId,
            chatId: chat_id,
            msg: msg
        };
        appConstants.socket.emit('forwadMessage', forwardMessage);

        if (this.state.GetPatientChat && this.state.GetPatientChat.length) {

            let PatientChat = this.state.GetPatientChat.map((key, i) => {
                if (key._id == chat_id) {
                    key.forwarded = true;
                }
                return key;
            });
            this.setState({ GetPatientChat: PatientChat })
        }
    }

    sendMessage(e) {
        this.sendMessageAction();
    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.sendMessageAction();
        }
    }

    activateUser(patientId, chatCount, patient, e) {
        //console.log('activateUser', chatCount);
        this.setState({ activateUserDetails: patient });
        endUserChat = false;
        endUserList = false;
        this.setState({ isUptNewChatUser: true, chatPage: 0, userPage: 0, GetPatientChat: [], activeNew: true, activeNewChat: false });
        localStorage.setItem('newTab', true);
        let ReadchatCount = this.state.ReadchatCount;

        // if (ReadchatCount[patientId] === true) {

        //     let chatC = localStorage.getItem('AllCount');
        //     let allCount = parseInt(chatC) - chatCount
        //     allCount = allCount < 0 ? 0 : allCount
        //     localStorage.setItem('AllCount', allCount);
        //     const params = {
        //         type: this.state.activeTab,
        //         patientId: patientId,
        //     }
        //     this.props.readChatAction(params);
        // }

        const params = {
            limit: 10,
            skip: 0,
            patientId: patientId,
        }
        //console.log('getPatientChatAction2');
        //console.log(this.state);

        this.props.getPatientChatAction(params);

        let activeUser = this.state.activeUser;

        for (let i in activeUser) {
            if (activeUser[i] !== patientId)
                activeUser[i] = false;

        }
        activeUser[patientId] = true;
        this.setState({ activeUser: activeUser, activateUser: patientId });

        setTimeout(function () {
            var objDiv = document.getElementById("scrolldiv");
            objDiv.scrollTop = objDiv.scrollHeight;

        }, 300);


        let addUser = {
            userId: localStorage.getItem('_id'),
            screen: patientId,
        }
        // appConstants.socket.emit('currentScreen', addUser);
        // if (this.state.activeTab === 'chat') {
        //     let AllChatCount = this.state.AllChatCount - chatCount;
        //     AllChatCount = AllChatCount < 0 ? 0 : AllChatCount

        //     localStorage.setItem('AllChatCount', AllChatCount);
        //     this.setState({ AllChatCount: AllChatCount });
        // }
        // else if (this.state.activeTab === 'byEducator') {
        //     let byEducatorChatCount = this.state.byEducatorChatCount - chatCount;
        //     byEducatorChatCount = byEducatorChatCount < 0 ? 0 : byEducatorChatCount

        //     localStorage.setItem('byEducatorChatCount', byEducatorChatCount);
        //     this.setState({ byEducatorChatCount });
        // }
        // else if (this.state.activeTab === 'forwarded') {
        //     let forwardedChatCount = this.state.forwardedChatCount - chatCount;
        //     forwardedChatCount = forwardedChatCount < 0 ? 0 : forwardedChatCount
        //     localStorage.setItem('forwardedChatCount', forwardedChatCount);
        //     this.setState({ forwardedChatCount });
        // }

        /**  */
        // if(data.senderType === "doctor" || data.senderType==="patient"){
        //   _this.setState({AllChatCount:(_this.state.AllChatCount+1)});
        // }
        // else if(data.senderType === 'educator'){
        //   _this.setState({AllChatCount:(_this.state.AllChatCount+1)});
        //   _this.setState({byEducatorChatCount:(_this.state.byEducatorChatCount+1)});
        // }
        //
        // let chatC = localStorage.getItem('AllCount');
        //
        // localStorage.setItem('AllCount',parseInt(chatC)+1);
        /** */

        // let ChatUserList = this.state.ChatUserList;

        // for (let i in ChatUserList) {
        //     if (ChatUserList[i]._id === patientId) {
        //         ChatUserList[i].chatCount = 0;
        //         break;
        //     }
        // }

        // this.setState({ ChatUserList: ChatUserList });
    }


    dietBox(e, patient) {

        this.setState({ dietModal: true, currentPatient: patient });
        this.setDietTitle(patient);
    }

    setDietTitle(patient) {
        let title = "Patient Diet (" + patient.firstName + " " + patient.lastName + " - " + patient.patientNo + ")";
        title += " | H:" + patient.height;
        title += " | W:" + patient.weight;
        title += " | BMI:" + getBMI(patient.height, patient.weight);
        title += " | Age:" + getAgeByDob(patient.dob);
        this.setState({ dietTitle: title });
    }

    onDismiss() {
        this.setState({ dietModal: false });
    }




    render() {

        function onLoad() {

            var options = {
                sourceLanguage: google.elements.transliteration.LanguageCode.ENGLISH,
                destinationLanguage: [google.elements.transliteration.LanguageCode.HINDI],
                shortcutKey: 'ctrl+g',
                transliterationEnabled: false
            };

            var control = new google.elements.transliteration.TransliterationControl(options);
            //console.log(control);

            control.makeTransliteratable('message');
        }
        setTimeout(function () {
            if (googleLoad === false) {
                googleLoad = true;
                google.setOnLoadCallback();
                onLoad();
            }

        }, 5000);


        let ChatUserList = '';

        let PatientChat = '';
        let PatientDetail = '';
        let chatInput = '';
        let noChatFound = <b style={{ margin: '37%' }}>No Messages found!</b>;

        if (this.state.ChatUserList && this.state.ChatUserList.length && (this.state.activeTab === 'byEducator' || this.state.activeTab === 'forwarded' || this.state.activeTab === 'chat')) {

            ChatUserList = this.state.ChatUserList.map((key, i) => {
                if (key.patientId === this.state.activateUser)

                    if (this.props && this.props.chatPatientDetails && key.msg === '..') {
                        PatientDetail = <Row><Col md={9}><b>{this.props.chatPatientDetails.firstName}&nbsp;{this.props.chatPatientDetails.lastName} - {this.props.chatPatientDetails.patientClinicId} | H {this.props.chatPatientDetails.height !== 0 ? ":" : ""} {this.props.chatPatientDetails.height !== 0 ? `${this.props.chatPatientDetails.height} CM` : ""} | W {this.props.chatPatientDetails.weight !== 0 ? ":" : ""}{this.props.chatPatientDetails.weight !== 0 ? `${this.props.chatPatientDetails.weight} KG` : ""}<br />BMI {(this.props.chatPatientDetails.height !== 0 && this.props.chatPatientDetails.weight !== 0) ? ":" : ""} {(this.props.chatPatientDetails.height !== 0 && this.props.chatPatientDetails.weight !== 0) ? getBMI(this.props.chatPatientDetails.height, this.props.chatPatientDetails.weight) : ""} | City {this.props.chatPatientDetails.city != "" ? ":" : ""}  {this.props.chatPatientDetails.city} | Age : {this.props.chatPatientDetails.age} </b></Col><Col md={3}>
                            {/*<Button className="btn btn-warning btn-sm pull-right" onClick={(e) => this.dietBox(e, this.props.chatPatientDetails)}>Diet Plan Details</Button> */}
                        </Col></Row>
                    } else {
                        PatientDetail = <Row><Col md={9}><b>{key.patient[0].firstName}&nbsp;{key.patient[0].lastName} - {key.patient[0].patientClinicId} | H {key.patient[0].height !== 0 ? ":" : ""}{key.patient[0].height !== 0 ? `${key.patient[0].height} CM` : ""} | W {key.patient[0].weight !== 0 ? ":" : ""} {key.patient[0].weight !== 0 ? `${key.patient[0].weight} KG` : ""}<br />BMI {(key.patient[0].height !== 0 && key.patient[0].weight !== 0) ? ":" : ""} {(key.patient[0].height !== 0 && key.patient[0].weight !== 0) ? getBMI(key.patient[0].height, key.patient[0].weight) : ""} | City {key.patient[0].city != "" ? ":" : ""} {key.patient[0].city} | Age : {parseInt(getAgeByDob(key.patient[0].dob))} </b></Col><Col md={3}>
                            {/*<Button className="btn btn-warning btn-sm pull-right" onClick={(e) => this.dietBox(e, key.patient[0])}  >Diet Plan Details</Button> */}
                        </Col></Row>
                    }

                chatInput = <div><Row><Col md={7} style={{ marginTop: '10px' }}>
                    <FormControl placeholder="Type Message.." autocomplete="off" type="text" name="message" id="message" onChange={e => { let str = e.target.value.replace(/\s+/g, " "); document.getElementById('message').value = str.trimStart(); }} onKeyPress={this._handleKeyPress} />
                    <small>Note:- ctrl+g for hindi, ctrl+g for english.</small>
                </Col>
                    <Col md={3} style={{ marginTop: '10px' }}>
                        <Button type="button" onClick={() => this.setState({ chatTemplateModal: true })} className="btn btn-fill btn-primary" >More Templates</Button>
                        <Modal className="pa-diet-screen" show={this.state.chatTemplateModal} onHide={() => this.setState({ chatTemplateModal: false })} dialogClassName="modal-xs modal-dialog-centered">
                            <Modal.Header closeButton className="bg-thumbcolor">
                                <Modal.Title id="example-modal-sizes-title-lg">Chat Templates</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='chat-template'>
                                {this.state.ChatTemplateList.length > 0 && this.state.ChatTemplateList.map((chat, idx) => (
                                    <div id="addr0" key={idx}>
                                        <span className='chat-temp-count'>{++idx}.</span>
                                        <div className='template-col'>
                                            {chat?.msg}
                                        </div>
                                        <div className='act-btns'>
                                            <span className='actn-btn'>
                                                <a href='#' onClick={e => this.editChatTemplate(chat)} ><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                <a href='#' style={{ color: 'red' }}><i onClick={() => this.handleDeleteTemplate(chat)} class="fa fa-trash" aria-hidden="true"></i></a>
                                                <a href='#' style={{ color: 'blue' }}><i onClick={() => this.sendChatTemplateAction(chat)} class="fa fa-paper-plane" aria-hidden="true"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </Modal.Body>
                        </Modal>
                    </Col>
                    <Col md={2} style={{ marginTop: '10px' }}>
                        <Button className="btn btn-primary pull-right" onClick={e => { this.sendMessage(e); }}>Send</Button>
                    </Col>
                </Row>
                    <div>
                        <div style={{ marginTop: '10px', width: 'auto' }}>
                            <label>
                                <input class="form-check-input" onClick={e => { this.handleChatTemplate(); }} checked={this.state.saveChatTemplate} type="checkbox" id="chkFutureReference" name="chkFutureReference" style={{ marginRight: '10px' }} />
                                Please select the chekbox for future reference.
                            </label>
                        </div>
                    </div>
                </div>

                return (<div className={this.state.activeUser[key.patientId] && this.state.isUptNewChatUser ? 'chat_user_list chat_msg active' : 'chat_user_list chat_color deactive'} data-id="15" key={key.chatId + "-a"}>
                    <span className="chat-apa" data-id="15" chat-id={key.chatId} onClick={this.activateUser.bind(this, key.patientId, key.chatCount, key.patient[0])}>
                        <span className="name">{key.patient[0].firstName}&nbsp;{key.patient[0].lastName}</span>
                        <span className="msg">{key.msg}</span>
                        <span className="msgCount" style={{ display: this.state.ReadchatCount[key._id] && key.chatCount ? 'block' : 'none' }}>{key.chatCount}</span>
                    </span>
                </div>)

            })

            noChatFound = '';
        }

        if (this.state.GetPatientChat && this.state.GetPatientChat.length) {
            let lastSend = false;
            let lastForwadChatId = "";

            PatientChat = this.state.GetPatientChat.map((key, i) => {

                if (lastSend !== key.clinicSendId)
                    lastSend = true;
                else
                    lastSend = false

                let selfChat = '';
                let float = '';
                if (key.clinicSendId === localStorage.getItem('_id')) {
                    selfChat = 'self-chat';
                    float = 'float-right';
                } else {
                    selfChat = 'patient-chat';
                    float = 'float-left';
                }

                // var time = new Date(key.createdAt);
                // var date = new Date(time);
                // var dateTime = date.getHours()+':'+date.getMinutes();

                var time = new Date(key.createdAt);
                var dateTime = moment(time).format('llll');


                let senderName = '';


                if (lastSend === true) {
                    senderName = key.senderName + ','

                    if (key.senderType === "doctor") {
                        senderName = "Dr. " + key.senderName + ','
                    }
                }

                if (key.senderType === "doctor") {
                    chat_color = 'drChatColor'
                } else if (key.senderType === "educator") {
                    chat_color = 'edChatColor'
                }
                else if (key.senderType === 'patient') {
                    chat_color = 'ptChatColor'
                }

                lastSend = key.clinicSendId;

                let textForward = '';
                let forwardAction = false;

                if (key.forwarded === true) {
                    textForward = <small>Forwarded to doctor</small>;
                    forwardAction = false;
                } else {
                    textForward = <i className="fa fa-share"></i>;
                    forwardAction = true;
                }


                let forAction = '';
                if (key.type === "toClinic" && (localStorage.getItem('userType') === 'educator' || localStorage.getItem('userType') === 'receptionist')) {
                    lastForwadChatId = key._id;
                    forAction = <span className="forwadIcon" id={"forwadIcon_" + key._id} style={{ pointerEvents: forwardAction ? 'all' : 'none', display: "none" }} onClick={e => { this.forwardMessage(e, key._id, key.patientId, key.msg); }}>{textForward}</span>
                }

                return (<div className={float + " chatbox"} key={key._id + 'bb'}><small className="chatTime">{senderName}&nbsp;{dateTime.toString()}</small><div className={selfChat + " chat_user_list chat_msg " + chat_color} data-id="11">
                    <span className="chat-apa" data-id="11" chat-id="20">
                        <span className="name"></span>
                        <span className="msg">{key.msg}</span>
                        <span className="msgCount" style={{ display: this.state.ReadchatCount[key._id] ? 'block' : 'none' }}>{key.chatCount}</span>
                    </span>
                </div>{forAction}</div>)
            })

            setTimeout(function () {
                var forwadIcon = document.getElementsByClassName("forwadIcon");
                for (let x in forwadIcon) {
                    if (forwadIcon[x].style) {
                        forwadIcon[x].style.display = "none";
                    }
                }

                if (document.getElementById("forwadIcon_" + lastForwadChatId)) {
                    document.getElementById("forwadIcon_" + lastForwadChatId).style.display = "inline-block";
                }
            }, 500);

        }

        return (

            <Row>
                <Col md={4}>
                    <Tab.Container id="nav-with-icons" defaultActiveKey="all">
                        <div>
                            <div className="nav-container pa-chat">
                                <Nav bsStyle="tabs" bsClass="nav" style={{ float: 'none' }}>
                                    <NavItem eventKey="all" onClick={e => { this.changeUserTab(e, 'chat'); }}>
                                        All<br />Messages<span style={{ float: 'right' }}>&nbsp;({this.state.AllChatCount})</span>
                                    </NavItem>
                                    {/* <NavItem eventKey="educator" onClick={e => { this.changeUserTab(e, 'byEducator'); }}>
                                        Educator<br />Response<span style={{ float: 'right' }}>&nbsp;({this.state.byEducatorChatCount})</span>
                                    </NavItem> */}
                                    <NavItem eventKey="forward" onClick={e => { this.changeUserTab(e, 'forwarded'); }}>
                                        Forward to<br />Doctor<span style={{ float: 'right' }}>&nbsp;({this.state.forwardedChatCount})</span>
                                    </NavItem>

                                </Nav>
                            </div>
                            <Tab.Content className="chat_user_list_box">
                                <Tab.Pane eventKey="all">
                                    <Card
                                        content={
                                            <div className="chat_list chat_list_nav chat_list_all chat-list-pa active">
                                                {ChatUserList}
                                            </div>
                                        }
                                    />
                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="educator">
                                    <Card

                                        content={
                                            <div className="chat_list chat_list_nav chat_list_educator chat-list-pa">
                                                {ChatUserList}
                                            </div>
                                        }
                                    />
                                </Tab.Pane> */}
                                <Tab.Pane eventKey="forward">
                                    <Card
                                        content={
                                            <div className="chat_list chat_list_nav chat_list_forward chat-list-pa">
                                                {ChatUserList}
                                            </div>
                                        }
                                    />
                                </Tab.Pane>

                            </Tab.Content>
                        </div>
                    </Tab.Container>

                </Col>
                <Col md={8}>

                    <Row className="chat-wind-body">

                        <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                            <img className="loader-break" src={loadingImg} alt="Loading" width="40px" />
                            {/*<center> Please Wait</center> */}
                        </div>

                        {PatientDetail && this.state.isUptNewChatUser &&
                            <div>{PatientDetail}</div>
                        }

                        <div className="scrolldiv" id="scrolldiv">
                            {noChatFound}
                            <div>

                                <Col md={12}>

                                    <div className="pa_chat_window chat_list chat_list_nav chat-list-pa active" id="chat_list">
                                        <div>{PatientChat}</div>
                                    </div>
                                </Col>
                            </div>
                        </div>
                        {chatInput}
                    </Row>

                </Col>


                <Modal show={this.state.notificationModal} onHide={() => this.setState({ notificationModal: false })} >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">Send Notification to Patient</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="send-notification form-horizontal">
                        <Row>
                            <Col md={12}>
                                <br />
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={4}>
                                        Message English <span className="star">*</span>
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl rows="3" componentClass="textarea" name="message_english" bsClass="form-control" defaultValue={this.state.formData.message_english} onChange={e => { this.handleChangeNotification(e); }} />
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={4}>
                                        Message Hindi <span className="star">*</span>
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl rows="3" componentClass="textarea" name="message_hindi" bsClass="form-control" defaultValue={this.state.formData.message_hindi} onChange={e => { this.handleChangeNotification(e); }} />
                                    </Col>

                                </FormGroup>
                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={4}>
                                        Select Type <span className="star">*</span>
                                    </Col>
                                    <Col sm={4}>
                                        <Radio
                                            number={'all'}
                                            key={'all'}
                                            option={'all'}
                                            name={"selected_type"}
                                            checked={(this.state.formData.selected_type === 'all') ? true : false}
                                            onClick={e => { this.handleNotificationType('all'); }}
                                            label={'All Patient'}
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <Radio
                                            number={'selected'}
                                            key={'selected'}
                                            option={'selected'}
                                            name={"selected_type"}
                                            checked={(this.state.formData.selected_type === 'selected') ? true : false}
                                            onClick={e => { this.handleNotificationType('selected'); }}
                                            label={'Selected Patient'}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup>
                                    <Col componentClass={ControlLabel} sm={4}>

                                    </Col>
                                    <Col sm={8}>
                                        <Button className="btn-fill btn btn-warning pull-right" onClick={() => this.setState({ notificationModal: false })}>Send</Button>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>


                <Modal className="pa-diet-screen" show={this.state.dietModal} onHide={() => this.setState({ dietModal: false })} dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">{this.state.dietTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <Diet patient={this.state.currentPatient} />

                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal className="pa-diet-screen" show={this.state.editChatTemplateModal} onHide={() => this.setState({ editChatTemplateModal: false })} dialogClassName="modal-md">
                    <Modal.Header closeButton className='bg-thumbcolor'>
                        <Modal.Title id="example-modal-sizes-title-lg">Edit Template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div class="form-group">
                                    <FormControl type="text" onChange={e => { this.inputHandler(e); }} autocomplete="off" value={this.state.selectedTemplate.msg} name="chatTemplate" placeholder="chatTemplate" />
                                </div>
                                {this.validateChatTemplate &&
                                    <div style={{ 'color': 'red' }}><p>This field is required.</p></div>
                                }
                                <Button onClick={e => { this.handleSubmitChatTemplate(e) }} className="btn btn-fill btn-primary">Save</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                {/*<Modal className="pa-task-screen" show={this.state.dietModal} onHide={() => this.setState({ dietModal: false })} dialogClassName="modal-lg">
                    <button type="button" className="close" onClick={() => this.setState({ taskScreen: false })} ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                    <Modal.Body className="Knowledge-Share ">
                        <Row>
                            <Col md={12}>
                                <TaskScreen taskScreenData={this.state.currentPatient} onDismiss={this.onDismiss} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal> */}

            </Row>
        );
    }
}

function mapStateToProps(state) {

    return {
        isChatUserList: state.master.isChatUserList,
        isChatUserListError: state.master.isChatUserList,
        ChatUserList: state.master.ChatUserList,

        isGetPatientChat: state.master.isGetPatientChat,
        isGetPatientChatError: state.master.isGetPatientChatError,
        GetPatientChat: state.master.GetPatientChat,

        isChatForward: state.master.isChatForward,
        isChatForwardError: state.master.isChatForwardError,
        ChatForward: state.master.ChatForward,

        isReadChat: state.master.isReadChat,
        isReadChatError: state.master.isReadChatError,
        ReadChat: state.master.ReadChat,

        isGetChatCount: state.master.isGetChatCount,
        isGetChatCountError: state.master.isGetChatCountError,
        GetChatCount: state.master.GetChatCount,

        isChatTemplateList: state.master.isChatTemplateList,
        isChatTemplateListError: state.master.isChatTemplateListError,
        ChatTemplateList: state.master.ChatTemplateList,

        isEditChatTemplate: state.master.isEditChatTemplate,
        isEditChatTemplateError: state.master.isEditChatTemplateError,
        editChatTemplate: state.master.editChatTemplate,

        isDeleteChatTemplate: state.master.isDeleteChatTemplate,
        isDeleteChatTemplateError: state.master.isDeleteChatTemplateError,
        deleteChatTemplate: state.master.deleteChatTemplate,

    }

}
export default withRouter(connect(mapStateToProps, { readChatAction, chatUserListAction, getPatientChatAction, forwardChatAction, getChatCountAction, chatTemplateListAction, editChatTemplateAction, deleteChatTemplateAction })(Chat));

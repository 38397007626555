/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { nextVisitAction, holidayListAction } from "Front/actions/home";
import moment from "moment";
import { next_visit_days } from "Front/variables/Variables.jsx";
//import Calendar from 'react-calendar';
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { appConstants } from "Front/_constants/app.constants.js";
import { patientOutAction } from "Front/actions/home";
//import { confirmAlert } from 'react-confirm-alert';
var isNextVisitCalled = false;
var isPatientOutCalled = false;
class NextVisit extends Component {
  constructor(props) {

    super(props);
    this.state = {
      formArr: [],
      // date: this.props.nextVisitData.next_visit_date !== null ? new Date(this.props.nextVisitData.next_visit_date) : new Date(),
      date:
        this.props.nextVisitData.next_visit_date !== null
          ? new Date(this.props.nextVisitData.next_visit_date)
          : "",
      numberOfDays: this.calculate_number_of_day(
        this.props.nextVisitData.next_visit_date
      ),
      next_visit_dateError: null,
      holidayList: [],
      formData: {
        id: this.props.nextVisitData.id,
        next_visit_date:
          this.props.nextVisitData.next_visit_date !== null
            ? moment(this.props.nextVisitData.next_visit_date).format(
              "YYYY-MM-DD"
            )
            : null,
        patient_id: this.props.nextVisitData.pId,
        notificationDay: localStorage.getItem("patientNotificationDay")
          ? Number(localStorage.getItem("patientNotificationDay"))
          : 1,
      },
      showProcessing: false,
      patientInfo: {
        id: this.props.nextVisitData.id,
        patientOutStatus: this.props.patientOut,
      },
      erroMsg: "",
      disabledDays: [],
    };

    this.onChange = this.onChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.checkNextVisit = this.checkNextVisit.bind(this);
  }

  componentDidMount() {
    this.props.holidayListAction();
    // if(this.props.HolidayList){
    //     this.setState({ holidayList:this.props.HolidayList.list })
    // }else{
    //     this.props.holidayListAction()
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isNextVisit !== this.props.isNextVisit &&
      nextProps.NextVisitMessage &&
      nextProps.NextVisitMessage.status === "Success" &&
      nextProps.NextVisitMessage.msg &&
      this.state.formData.first_name !== ""
    ) {
      if (isNextVisitCalled) {
        isNextVisitCalled = false;
        this.props.onDismissNextVisit();
      }

      if (this.props.patientOut) {
        if (!isPatientOutCalled) {
          isPatientOutCalled = true;
          this.props.patientOutFun(nextProps.nextVisitData.id);
        }
      }

      // setTimeout(function () {
      //   let params = { clinicId: localStorage.getItem("clinicId") };
      //   appConstants.socket.emit("addPatient", params);
      // }, 1000);
    }

    if (nextProps.isNextVisitError !== this.props.isNextVisitError) {
      if (nextProps.NextVisitMessage.errors) {
        nextProps.NextVisitMessage.errors.map((key, i) => {
          return this.setState({
            [key.param + "Error"]: (
              <small className="text-danger">{key.msg}</small>
            ),
          });
        });
      }
    }

    if (nextProps.isHolidayList !== this.props.isHolidayList) {
      this.setState({ holidayList: nextProps.HolidayList.list });
      let hlist = nextProps.HolidayList.list;
      let disabledDays = [];
      for (let i in hlist) {
        let dateL = hlist[i].calendarDate;
        for (let j in dateL) {
          disabledDays.push(new Date(dateL[j]));
        }
      }

      this.setState({ disabledDays: disabledDays });
    }
  }

  checkNextVisit(new_date) {
    let isOK = 1;

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    let isWeekDate = false;

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(new_date).day()) {
          isWeekDate = true;
        }
      }
    }
    if (new Date(new_date).getDay() === 0 || isWeekDate) {
      isOK = 0;
      new_date = moment(new_date).add(2, "days");
    }
    var checkHoliday = this.addEventClass(new_date._d);

    //this.setState({ date: new Date(new_date) });

    if (checkHoliday === "gray") {
      //new_date = moment(new_date).format('YYYY-MM-DD');
      //this.setState({ erroMsg: "", formData: field });
    } else {
      if (checkHoliday === "green-bg") {
        //this.setState({ erroMsg: "", formData: field });
      } else {
        //this.setState({ date: null});
        //this.setState({ erroMsg: "Clinic Holiday", formData: field });
        //field['next_visit_date'] = moment(new_date).format('YYYY-MM-DD');
        isOK = 0;
        new_date = moment(new_date).add(1, "days");

        //new_date = moment(new_date).format('YYYY-MM-DD');
      }
    }

    if (isOK === 1) {
      return new_date;
    } else {
      return this.checkNextVisit(new_date);
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    var new_date = moment().add(e.target.value, "days");

    new_date = this.checkNextVisit(new_date);

    this.setState({ date: new Date(new_date), numberOfDays: e.target.value });

    field["next_visit_date"] = moment(new_date).format("YYYY-MM-DD");
    this.setState({ erroMsg: "", formData: field });
  }
  handleChange2(e) {
    e.preventDefault();
    let field = this.state.formData;
    var new_date = moment(new Date(), "DD-MM-YYYY").add(e.target.value, "days");

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    let isWeekDate = false;

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(new_date).day()) {
          isWeekDate = true;
        }
      }
    }
    if (new Date(new_date).getDay() === 0 || isWeekDate) {
      new_date = moment(new_date, "DD-MM-YYYY").add(2, "days");
    }
    var checkHoliday = this.addEventClass(new_date._d);
    this.setState({ date: new Date(new_date) });

    if (checkHoliday === "gray") {
      field["next_visit_date"] = moment(new_date).format("YYYY-MM-DD");
      this.setState({ erroMsg: "", formData: field });
    } else {
      if (checkHoliday === "green-bg") {
        this.setState({ erroMsg: "", formData: field });
      } else {
        this.setState({ date: null });
        this.setState({ erroMsg: "Clinic Holiday", formData: field });
        field["next_visit_date"] = moment(new_date).format("YYYY-MM-DD");
      }
    }
  }

  onChange(date, modifiers = {}) {
    if (modifiers.disabled) {
      return;
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(date).day()) {
          return false;
        }
      }
    }

    if (moment(date).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
      this.setState({ date });
      let field = this.state.formData;

      if (date.getDay() === 0) {
        var new_date = moment(date, "DD-MM-YYYY").add(1, "days");
        field["next_visit_date"] = moment(new_date).format("YYYY-MM-DD");
      } else {
        field["next_visit_date"] = moment(date).format("YYYY-MM-DD");
        this.setState({ formData: field });
      }
    }
  }

  handleDayClick(day, { selected }) {
    let calenderDate = moment(day).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");
    let disabledDays = this.state.disabledDays;

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(calenderDate).day()) {
          return false;
        }
      }
    }

    if (calenderDate >= todayDate && !disabledDays.includes(calenderDate)) {
      const { selectedDays } = this.state;
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
      this.setState({ selectedDays });

      let data = this.state.formData;
      let dateArr = [];
      for (let i in selectedDays) {
        let mytime = moment(new Date(selectedDays[i])).format("MM-DD-YYYY");

        dateArr.push(mytime);
      }

      data["calendar_date"] = dateArr;

      this.setState({ formData: data, calenderErr: false });
    }
  }

  renderDay(day) {
    const currentDate = day;

    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(currentDate).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            if (list[x].type === "Leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = "weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate == todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass ? extClass : " red-bg";
      } else if (currentDate2 == 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(currentDate).day()) {
          extClass = "weekoffPink-bg";
        }
      }
    }

    const cellStyle = {
      height: 30,
      width: 30,
      position: "relative",
      borderRadius: 15,
    };

    const dateStyle = {
      // color: 'lightgray',
      bottom: 0,
      right: 0,
      fontSize: 14,
      paddingTop: 5,
    };

    return (
      <div className={extClass} style={cellStyle}>
        <div style={dateStyle}> {day.getDate()} </div>
      </div>
    );
  }

  nextVisit(evt) {
    evt.preventDefault();
    this.setState({ showProcessing: true });
    let patientInfo = this.state.patientInfo;
    let erroMsg = this.state.erroMsg;
    if (!this.state.formData.next_visit_date && !patientInfo.patientOutStatus) {
      erroMsg = "Please select next visit date.";
    } else {
      erroMsg = "";
    }
    this.setState({ erroMsg });

    if (this.state.formData.next_visit_date === null && erroMsg === "") {
      this.props.patientOutFun(patientInfo.id, this.props.econsultation);
    } else if (erroMsg === "") {
      if (this.props.patientOut) {
        isPatientOutCalled = true;
        this.props.patientOutAction(patientInfo.id, this.props.econsultation);
      }
      isNextVisitCalled = true;
      let data = this.state.formData;
      data.activeTab = this.props.activeTab
      this.props.nextVisitAction(this.state.formData);
    }
  }

  nextVisitCancled(e) {
    e.preventDefault();
    this.setState({ showProcessing: true });
    let patientInfo = this.state.patientInfo;
    this.props.patientOutFun(patientInfo.id, this.props.econsultation);
  }

  calculate_number_of_day(date = null) {
    if (date !== null) {
      var a = moment(date);
      var b = moment(new Date());

      return a.diff(b, "days") + 1;
    } else {
      return 0;
    }
  }

  addEventClass(date, view) {
    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(date).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    // week off start
    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));
    // week off day color
    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day === date.getDay()) {
          return " weekoffPink-bg";
        }
      }
    }

    let exit = 0;
    let exit1 = 0;
    let currentDate = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(date).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            if (list[x].type === "leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(date).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;
          //return 'red-bg';
          // return 'holiday-bg';
          return " weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate === todayDate) {
      exit = 1;
      currentDate = 1;

      return "green-bg";
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        return "red-bg";
        //return 'weekoffPink-bg';
      } else if (currentDate === 1) {
        return "green-bg";
      } else {
        // return 'blue-bg';
        return "red-bg";
      }
    } else {
      return "gray";
    }
    // }
  }

  addTitle(date, view) {
    let list = this.state.holidayList;

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));
    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day === date.getDay()) {
          return true;
        }
      }
    }

    let exit = 0;
    let title = "";
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(date).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            title = list[x].message;
          }
        }
      }
    }
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(date).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          title = "clinic holiday";
        }
      }
    }

    if (exit === 1) {
      return <span title={title}>... </span>;
    } else {
      return "";
    }
  }

  render() {
    const numberOfDays = this.state.numberOfDays;

    return (
      <div className="">
        <Row>
          <Col sm={6} className="next-calender">
            {/* <Calendar
                                calendarType={"US"}
                                onChange={this.onChange}
                                minDate={new Date()}
                                value={this.state.date}
                                tileClassName={({ date, view }) => this.addEventClass(date, view)}
                                tileContent={({ date, view }) => this.addTitle(date, view)}
                                tileDisabled={({ date, view }) => this.addTitle(date, view)}
                            /> */}

            <DayPicker
              month={
                new Date(
                  this.state.date
                    ? moment(this.state.date).format("YYYY, M, D")
                    : moment().format("YYYY, M, D")
                )
              }
              selectedDays={this.state.date ? this.state.date : ""}
              onDayClick={this.onChange}
              renderDay={this.renderDay}
              disabledDays={[
                ...this.state.disabledDays,
                {
                  before: new Date(),
                },
              ]}
            />

            {this.state.next_visit_dateError}
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Col componentClass={ControlLabel}>Next Visit Date</Col>
              <Col>
                <select
                  className="form-control"
                  name="title"
                  value={this.state.numberOfDays}
                  onChange={(event) => this.handleChange(event)}
                >
                  <option value=""> Select </option>
                  {next_visit_days.map(function (item) {
                    //let selected = item.value === numberOfDays ? 'selected' : '';
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                <span className="errorMsg">{this.state.erroMsg}</span>
                <h4 className="mt-10">
                  {this.state.formData.next_visit_date !== null
                    ? moment(this.state.formData.next_visit_date).format(
                      "DD-MM-YYYY"
                    )
                    : ""}
                </h4>
              </Col>
            </FormGroup>
            <div>
              <button
                type="button"
                onClick={this.nextVisit.bind(this)}
                className="visit-btn btn-fill btn-wd btn btn-primary"
              >
                {this.state.patientInfo &&
                  this.state.patientInfo.patientOutStatus
                  ? "Out"
                  : "Save"}
              </button>
              {/* this.state.patientInfo && this.state.patientInfo.patientOutStatus &&
                                <button type="button" onClick={this.nextVisitCancled.bind(this)} className="btn-fill btn-wd btn btn-info">Out</button>
                             */}
            </div>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={4} smOffset={2}></Col>
          </FormGroup>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    HolidayList: state.home.HolidayList,
    isHolidayList: state.home.isHolidayList,
    isHolidayListError: state.home.isHolidayListError,

    NextVisitMessage: state.home.NextVisit,
    isNextVisit: state.home.isNextVisit,
    isNextVisitError: state.home.isNextVisitError,

    // PatientOut: state.home.PatientOut,
    // isPatientOut: state.home.isPatientOut,
    // isPatientOutError: state.home.isPatientOutError,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    nextVisitAction,
    holidayListAction,
    patientOutAction,
  })(NextVisit)
);

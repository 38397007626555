/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import Card from "Admin/components/Card/Card.jsx";
import * as API from "../../../Admin/api/advertisement";

const AppVersion = () => {
  let history = useHistory();
  const [iosVersion, setIosVersion] = useState();
  const [androidVersion, setAndroidVersion] = useState();
  const [iosPlus, setIosPlus] = useState(false);
  const [androidPlus, setAndroidPlus] = useState(false);

  const plusHandler = (type) => {
    let version = type == 'ios' ? iosVersion : androidVersion
    version = parseFloat(version) + .1;
    type == 'ios' ? setIosVersion(version.toFixed(1)) : setAndroidVersion(version.toFixed(1))
  }

  const submitHandler = async () => {
    let data = {
      iosVersion: iosVersion,
      androidVersion: androidVersion
    }
    let resp = await API.editAppVersion(data);
    if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
      alert('success')
    }
  }

  useEffect(() => {
    (async () => {
      let resp = await API.appVersion();
      if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
        setIosVersion(resp?.data?.data[0].latestIOSVersion)
        setAndroidVersion(resp?.data?.data[0].latestAndroidVersion)
      }
    })();
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={3}>
                            <ControlLabel>
                              IOS Version<span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                              type="number"
                              name="iosVersion"
                              id="iosVersionId"
                              value={iosVersion}
                            />
                          </Col>
                          <Col md={3}>
                            <button
                              type="button"
                              className="btn-fill btn-wd btn btn-primary"
                              disabled={iosPlus}
                              onClick={() => {
                                setIosPlus(true)
                                plusHandler('ios')
                              }}
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>

                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={3}>
                            <ControlLabel>
                              Android Version<span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                              type="Number"
                              name="androidVersion"
                              id="androidVersionId"
                              value={androidVersion}
                            />
                          </Col>
                          <Col md={3}>
                            <button
                              type="button"
                              className="btn-fill btn-wd btn btn-primary"
                              disabled={androidPlus}
                              onClick={() => {
                                setAndroidPlus(true)
                                plusHandler()
                              }}
                            >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <button
                              type="button"
                              className="btn-fill btn-wd btn btn-primary"
                              onClick={(e) => {
                                submitHandler(e);
                              }}
                            >
                              Submit
                            </button>
                            &nbsp;
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default AppVersion

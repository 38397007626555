/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Button, ButtonToolbar, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { userListAction, userStatusAction } from 'Admin/actions/user';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
var statusChangedCalled = false;
class UserList extends Component {

  constructor(props) {
    super(props);
    const answer_array = this.props.location.pathname.split('/');
    let _id_clinic;
    if (this.props.location.state && this.props.location.state._id) {
      _id_clinic = this.props.location.state._id;
      localStorage.setItem('_id_clinic', this.props.location.state._id);
    }

    let status = this.props.location.status ? this.props.location.status : ""
    let userType = this.props.location.userType ? this.props.location.userType : ""


    this.state = {
      userList: [],
      asc_desc: true,
      isLoading: true,
      userRow: null,
      // clinicId: answer_array[3] ? answer_array[3],
      // clinicId: _id_clinic,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      //  direction: 'desc',
      // order: "createdAt",
      // offset: 0,
      // limit: 10,
      status: status,
      userType: userType,
      _notificationSystem: null,
      formData: {
        clinicId: _id_clinic,
        direction: 'desc',
        order: "createdAt",
        offset: 0,
        limit: 10,
      }
    }

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
    this.sizePerPageListChange = this.sizePerPageListChange.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.sortHandle = this.sortHandle.bind(this);
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return <div className="no-data"><h5>No data found!</h5></div>
    }
  }

  editButton(cell, row, enumObject, rowIndex) {
    if (this.state.clinicId) {
      return (<span><Link to={{ pathname: `/admin/update-user/` + row._id, state: { row, clinic_id: this.state.clinicId, clinicDetail: this.props.location.state }, clinicRedirect: true, clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
        <a href="javascript:void(0);" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
      </span>)
    } else {
      return (<span><Link to={{ pathname: `/admin/update-user/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
        <a href="javascript:void(0);" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
      </span>)
    }

    // <a href="javascript:void(0)" onClick={this.deleteButton.bind(this, row.invitationId)}><i className="fa fa-trash-o" aria-hidden="true"></i></a></p>)
  }

  onUserCount(cell, row, enumObject, rowIndex) {
    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  statusChangedHandler(event, elename) {

    let newstatus = {};
    if (event.status == 'active') {
      newstatus = 'inactive';
    } else {
      newstatus = 'active';
    }
    event['id'] = event._id;
    event['status'] = newstatus;
    statusChangedCalled = true;
    this.props.userStatusAction(event);
  }

  toggleEdit(event) {
    this.setState({
      userRow: event
    });
  }

  componentDidMount() {

    let data = {
      limit: this.state.formData.limit,
      //offset: 0,
      offset: this.state.formData.offset,
      // order: "_id",
      order: this.state.formData.order,
      direction: this.state.formData.direction,
      clinic_id: this.state.formData.clinicId,
      status: this.state.status,
      userType: this.state.userType,
    }

    let _this = this;
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    // setTimeout(function () {
    //   _this.props.userListAction(data)
    // }, 1000);

    this.props.userListAction(data)
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isUserList !== this.props.isUserList) {
      this.setState({
        userList: nextProps.UserList.data && nextProps.UserList.data.data,
        totalCount: nextProps.UserList.data && nextProps.UserList.data.count
      });
    }
    if (nextProps.isUserStatus !== this.props.isUserStatus && statusChangedCalled) {
      statusChangedCalled = false
      this.successAlert("Status Changed Successfully")
    }
    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  addUser(e) {

    this.props.history.push({
      pathname: '/admin/add-user',
      state: { clinic_id: this.state.formData.clinicId, clinicDetail: this.props.location.state }
    })
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    
    data['clinic_id'] = this.state.formData.clinicId ? this.state.formData.clinicId : '';
    if (this.state.searchText === "") {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    }

    data['userType'] = this.state.userType;
    data['status'] = this.state.status;

    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    this.props.userListAction(data)

    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['clinic_id'] = this.state.formData.clinicId ? this.state.formData.clinicId : '';
        data['direction'] = this.state.formData.direction;
        data['order'] = this.state.formData.order;
        data['offset'] = 0;
        data['limit'] = 10;
        data['status'] = this.state.status;
        data['userType'] = this.state.userType;
        data['search'] = text;
        this.setState({ sizePerPage: this.state.sizePerPage })
        this.props.userListAction(data);
      }
    } else {
     setTimeout(() => {
      
       this.componentDidMount();
     }, 200);
    }
    this.setState({ searchText: text });
  }

  clearSearch(e) {
    this.setState({ searchText: '' });
    this.componentDidMount();
  }

  subscriptionList() {
    this.props.history.push(`/admin/add-clinic-subscription/` + this.state.clinicId);
  }

  clinicList() {
    this.props.history.push(`/admin/clinic-list`);
  }

  successAlert(msg) {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

  }


  handleStatus(e) {
    this.setState({ currentPage: 1 })
    this.setState({ status: e.target.value });
    let data = {
      limit: 10,
      // offset: 0,
      offset: 0,
      // order: "createdAt",
      order: this.state.formData.order,
      direction: this.state.formData.direction,
      clinic_id: this.state.formData.clinicId,
      status: e.target.value,
      userType: this.state.userType,
    }

    this.props.userListAction(data)
  }

  handleUserType(e) {
    e.preventDefault();
    this.setState({ userType: e.target.value });
    let data = {
      limit: 10,
      offset: 0,
      // order: "createdAt",
      // offset: this.state.offset,
      order: this.state.formData.order,
      direction:this.state.formData.direction,
      clinic_id: this.state.formData.clinicId,
      status: this.state.status,
      userType: e.target.value,
    }
    this.setState({ sizePerPage: 10 })
    this.setState({ currentPage: 1 })

    this.props.userListAction(data)
  }

  sortHandle(){ 
    this.setState({ asc_desc:!this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc? "asc":"desc"
    this.setState({ formData  : data });
    this.props.userListAction(data);
}

  sizePerPageListChange(sizePerPage) {
    // const {page,userType,status} =this.state;
    //

    //
    // let newSizePerPage = '';
    // if(this.state.sizePerPage < sizePerPage){
    //   newSizePerPage = this.state.sizePerPage;
    // }else{
    //   newSizePerPage = sizePerPage;
    // }
    //
    // this.setState({
    //     sizePerPage:newSizePerPage,
    // })

  }

  render() {
    const selectRowProp = { mode: 'checkbox' };
    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      //onSearchChange: this.onSearchChange,
      onSizePerPageList: this.sizePerPageListChange,
      noDataText: this._setTableOption(),
    };

    return (
      <div className="main-content" style={{ padding: '15px 0px' }}>
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Row>
            <Col md={12}>
              {this.state.clinicId &&
                <ClinicHeader componentData={{ clinicId: this.state.clinicId }} />
              }
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={3}>
                        <ButtonToolbar>
                          <Button className="inacti-button" onClick={(e) => { this.addUser(e) }}>Add User
                          </Button>

                          {/* <Button className={this.state.status == 'active' ? 'acti-button' : 'inacti-button pull-right'} fill  >Active</Button>
                              <Button className={this.state.status == 'inactive' ? 'acti-button' : 'inacti-button pull-right '} fill >Inactive</Button>   */}
                        </ButtonToolbar>
                      </Col>

                      <Col md={8}>
                        <Row>
                          <Col md={3}>
                            <FormControl className="m-10" componentClass="select" name="userType" id="userType" value={this.state.userType} onChange={e => { this.handleUserType(e); }}>
                              <option value="">User Type</option>
                              <option value="doctor">Doctor</option>
                              <option value="educator">Educator</option>
                              <option value="receptionist">Receptionist</option>
                            </FormControl>
                          </Col>

                          <Col md={3}>
                            <FormControl className="m-10" componentClass="select" name="userType" id="userType" value={this.state.status} onChange={e => { this.handleStatus(e); }}>
                              <option value="">All</option>
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                            </FormControl>
                          </Col>

                          <Col md={5}>
                            <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                          </Col>
                          <Col md={1}>
                            <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button> 
                          </Col>

                          {/*<ButtonToolbar className="pull-right right-rr">
                          <Button className={this.state.status == 'active' ? 'acti-button' : 'inacti-button'} fill onClick={(e) => { this.handleStatus('active') }}>Active</Button>
                          <Button className={this.state.status == 'inactive' ? 'acti-button' : 'inacti-button'} fill onClick={(e) => { this.handleStatus('inactive') }}  >Inactive</Button>
                        </ButtonToolbar> */}
                        </Row>
                      </Col>


                      {this.state.clinicId &&

                        <Col md={6}>
                          {/* <Button className="go-back-btn" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button> */}
                        </Col>
                      }
                    </Row>&nbsp;
                    <div className="table-resposive educator-L">
                      <BootstrapTable
                        //selectRow="{selectRowProp}"
                        deleteRow={false}
                        data={this.state.userList}
                        search={false}
                        multiColumnSearch={true}
                        pagination={true}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={'Bottom'}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.state.totalCount }}
                      >
                        <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' isKey searchable={false}>Id</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '3%', textAlign: 'center' }} tdStyle={{ width: '3%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '10.5%', textAlign: 'center' }} tdStyle={{ width: '10.5%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'User Name' }} dataField='userName' >Username
                          {
                            this.state.asc_desc?
                            <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                            <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                          }
                        </TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '11%', textAlign: 'center' }} tdStyle={{ width: '11%', textAlign: 'center', textTransform: "capitalize" }} tdAttr={{ 'data-attr': 'First Name' }} dataField='firstName'  >firstName</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '10.5%', textAlign: 'center' }} tdStyle={{ width: '10.5%', textAlign: 'center', textTransform: "capitalize" }} tdAttr={{ 'data-attr': 'Last Name' }} dataField='lastName'  >lastName</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '12%', textAlign: 'center' }} tdStyle={{ width: '12%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Email' }} dataField='email'  >Email</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '9.5%', textAlign: 'center' }} tdStyle={{ width: '9.5%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Phone' }} dataField='phone'  >Phone</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center', textTransform: "capitalize" }} tdAttr={{ 'data-attr': 'Group' }} dataField='userType'  >Group</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic' }} dataField='clinicName'  >Clinic</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status'  >Status</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '6%', textAlign: 'center' }} tdStyle={{ width: '6%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} >Action</TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    UserList: state.user.UserList,
    isUserList: state.user.isUserList,
    isUserListError: state.user.isUserListError,

    userStatus: state.user.userStatus,
    isUserStatus: state.user.isUserStatus,
    isUserStatusError: state.user.isUserStatusError,

  }

}
export default withRouter(connect(mapStateToProps, { userListAction, userStatusAction })(UserList));

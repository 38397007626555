/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import $ from 'jquery';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { addSupportSystemAction } from 'Admin/actions/supportsystem.js';
require('datatables.net-responsive');
$.DataTable = require('datatables.net-bs');

let Validator = require('validatorjs');
let formArr = {};
let rules = {
    // file: 'required',
    title: 'required',
    description: 'required'
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddSupportSystem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,
            showModal: false,
            isLoading: false,
            formArr: [],
            formData: {
                title: "",
                description: "",
                file: ""
            },
            isLogin: true,
            formDataFile: ''
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.addSupportSystem && nextProps.isAddSupportSystem) {
            this.props.handleClick('success', nextProps.addSupportSystem.status)
            this.props.history.push('/support-list')
        }
    }

    AddAction(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        if (this.allValidate(false)) {
            let formDatasent = new FormData();
            let { formDataFile, formData } = this.state;
            formDatasent.append('title', formData.title)
            formDatasent.append('description', formData.description)
            formDatasent.append('file', formDataFile)
            this.props.addSupportSystemAction(formDatasent);
        }
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isButtonDisabled: false });
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleChange(e) {
        e.preventDefault();
        let { formData, formDataFile } = this.state;
        if (e.target.name === "file") {
            if (e.target.files[0].type === 'image/png') {
                let file = e.target.files[0];
                formData[e.target.name] = e.target.files;
                formDataFile = file;
                this.setState({ formData, formDataFile })
            }
        } else {
            formData[e.target.name] = e.target.value;
        }
        this.setState({ formData: formData });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal className="add-supportsystem_form">

                                        <fieldset>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <ControlLabel>Title <span className="star">*</span></ControlLabel>
                                                            <FormControl
                                                                type="text"
                                                                name="title"
                                                                id="title"
                                                                placeholder="Title"
                                                                onChange={e => { this.handleChange(e); }}
                                                            />
                                                            <span className="errorMsg">{this.state.formArr.title && validation.errors.first('title')}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                        <fieldset>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <ControlLabel>Description<span className="star">*</span></ControlLabel>
                                                            <FormControl
                                                                rows="3"
                                                                componentClass="textarea"
                                                                name="description"
                                                                id="description"
                                                                placeholder="Description"
                                                                onChange={e => { this.handleChange(e); }}
                                                            />
                                                            <span className="errorMsg">{this.state.formArr.description && validation.errors.first('description')}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                        <fieldset>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <Row>
                                                        <Col md={6}>
                                                            <ControlLabel>Attachment</ControlLabel>
                                                            <FormControl
                                                                placeholder="Upload File"
                                                                aria-label="File"
                                                                aria-describedby="basic-addon1"
                                                                onChange={e => { this.handleChange(e); }}
                                                                name="file"
                                                                type="file"
                                                                accept=".png"
                                                                allowedFileTypes={['image/png']}
                                                            />
                                                            {/* <span className="errorMsg">{this.state.formArr.file && validation.errors.first('file')}</span> */}
                                                        </Col>
                                                        <Col sm={3}></Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                        <fieldset>
                                            <FormGroup>
                                                <ControlLabel className="col-sm-2">
                                                </ControlLabel>
                                                <Col sm={12} className="btn_wrap">
                                                    <Row>
                                                        <Col md={12}>
                                                            <button
                                                                type="button"
                                                                onClick={this.AddAction.bind(this)} className="btn-fill btn-wd btn btn-primary"
                                                                disabled={isLoading || this.props.disabled}
                                                            >
                                                                {isLoading ? (
                                                                    <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                                ) : (
                                                                    this.props.children
                                                                )}
                                                                Submit
                                                            </button>&nbsp;

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.supportsystem.message,
        addSupportSystem: (state.supportsystem.addSupportSystem) ? state.supportsystem.addSupportSystem : [],
        isAddSupportSystem: (state.supportsystem.addSupportSystem) ? state.supportsystem.isAddSupportSystem : false,
        isAddSupportSystemError: (state.addSupportSystem) ? state.addSupportSystem.isAddSupportSystemError : [],


    }
}
export default withRouter(connect(mapStateToProps, { addSupportSystemAction })(AddSupportSystem));

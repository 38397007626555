/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';

import { NavLink } from 'react-router-dom';
import Fibrosis4 from './Fibrosis4';

const BmiCalculator = () => {
    const [formData, setFormData] = useState({});
    const [calculateBmi, setCalculateBmi] = useState(0.0);
    const [required, setRequired] = useState({});

    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }

    const calculateFun = (e) => {
        e.preventDefault();
        let height_in_m = formData.height / 100;
        if (height_in_m > 0 && formData.weight > 0) {
            let bmi = formData.weight / (height_in_m * height_in_m);
            setCalculateBmi(bmi.toFixed(2));
        } else {
            setCalculateBmi(0.0)
        }
    }

    const glyChart = (val1, val2) => {


    }

    return (
        <div className="main-content color-notations">
            <Grid fluid>
                <Card
                    content={
                        <Row>
                            <Col md={4}>
                                <img src="../../../images/BMI-Calculator.png" class="w-100 mx-h-40" alt="BMI" />
                            </Col>
                            <Col md={2}>
                                <ul className="nav calc-navs">
                                    <li>
                                        <NavLink to={'bmi-calculator'} className="nav-link active" >
                                            <span className="sidebar-mini">BMI</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'eGFR-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">eGFR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'homair-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Homa IR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [3-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indices [5-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'bone-age-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Bone Age Calculator</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'fib-4-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Fibrosis-4</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <Form horizontal>
                                    <h4>BMI Calculator</h4>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>

                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Height (cm.) <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="number" autocomplete="off" name="height" id="height" value={formData.height || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.height && required.height === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>

                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Weight (Kg.) <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="number" autocomplete="off" minLength="6" maxLength="20" name="weight" id="weight" value={formData.weight || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.weight && required.weight === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-primary">Calculate</button>
                                                </Col>
                                            </FormGroup>
                                            {calculateBmi > 0 &&
                                                <FormGroup className="bmi-count">
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        BMI
                                                    </Col>
                                                    <Col componentClass={ControlLabel} sm={6}>
                                                        {calculateBmi}
                                                    </Col>
                                                </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    }
                />
            </Grid>
        </div>
    );
}


export default withRouter(BmiCalculator);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper"


export const addAttachment = (data) => postRequestWithToken(data, 'admin/add-file');
export const updateAttachment = (data) => postRequestWithToken(data, 'admin/update-file');
export const changeAttachmentStatus = (data) => postRequestWithToken(data, 'admin/file-change-status');
export const changeAttachmentAutoSelectStatus = (data) => postRequestWithToken(data, 'admin/file-auto-select-status');
export const attachmentList = (data) => postRequestWithToken(data, 'admin/file-list');

export const addNewsRoom = (data) => postRequestWithTokenFile(data, 'admin/add-news');
export const newsRoomList = (data) => postRequestWithToken(data, 'admin/news-list');
export const editNewsRoom = (data) => postRequestWithTokenFile(data, 'admin/update-news');
export const deleteNews = (data) => postRequestWithToken(data, 'admin/delete-news');
export const sharedNewsRoom = (data) => postRequestWithToken(data, 'admin/change-status-news');



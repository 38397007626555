/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { forgotPasswordAction } from 'Front/actions/login';
import loadingImg from 'Front/assets/img/loading.gif';

// var successMag = '';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
  //userName: 'required',
};
let mess = {
  required: 'This field is required',
};
let validation = [];


class ForgotPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      formData: {
        userName: "",
        host: ""
      },
      successMag: "",
      errMag: "",
      showProcessing: false,
    }

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleChange = this.handleChange.bind(this);
    this.onKeyDownSubmit = this.onKeyDownSubmit.bind(this);
  }

  componentDidMount() {

    setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 700);
  }

  componentWillReceiveProps(nextProps) {

    this.setState({ successMag: '', errMag: '' });

    if (nextProps.isForgotPWD !== this.props.isForgotPWD && this.props.isForgotPWD === false) {

      let successMag = "Link has been sent on Registered Email id.";
      let formData = { userName: "", host: "" };
      this.setState({ showProcessing: false, successMag: successMag, formData: formData });

    }

    if (nextProps.isForgotPWDError !== this.props.isForgotPWDError && this.props.isForgotPWDError === false) {
      this.setState({ showProcessing: false, errMag: nextProps.loginMessage.message });
    }

  }

  uesrForgotPwd(evt) {
    evt.preventDefault();

    const _this = this;
    let formData = _this.state.formData;

    let url = window.location.protocol + "//" + window.location.hostname + "/";
    if (window.location.hostname === 'localhost') {
      url = "http://" + window.location.hostname + ":3000/";
    }

    let data = {
      userName: formData.userName,
      host: url
    }


    if (formData.userName != '') {
      _this.setState({ showProcessing: true });
      _this.props.forgotPasswordAction(data);
    } else {
      this.setState({ errMag: "The username is required!" });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = []
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ errMag: "" });
    this.setState({
      formData: field
    });
  };

  onKeyDownSubmit(e) {
    if (e.key === 'Enter' && e.keyCode === 13) {
      e.preventDefault();
      this.uesrForgotPwd(e);
    }
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <Grid>
        <Row>
          <Col md={4} switchm={6} mdOffset={4} smOffset={3}>
            <form>

              <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                <img src={loadingImg} alt="Loading" width="40px" />
                <center>Logging In - Please Wait</center>
              </div>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Forgot Password"
                content={
                  <div>
                    {this.state.successMag ?
                      <div className="successMsg"> {this.state.successMag} </div>
                      :
                      <div className="errorMsg"> {this.state.errMag} </div>
                    }
                    <FormGroup>
                      <ControlLabel>Username</ControlLabel>
                      <FormControl
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        id="userName"
                        name="userName"
                        onChange={e => { this.handleChange(e); }}
                        onKeyDown={e => { this.onKeyDownSubmit(e); }}

                      />
                      <span className="errorMsg">{this.state.formArr.userName && validation.errors.first('userName')}</span>
                    </FormGroup>

                  </div>
                }
                legend={
                  <Button onClick={this.uesrForgotPwd.bind(this)} bsStyle="info" fill wd>Submit</Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,

    // isLogin: state.login.isLogin,
    // LoginData: state.login.LoginData,
    // isLoginError: state.login.isLoginError,

    forgotPWD: state.login.forgotPWD,
    isForgotPWD: state.login.isForgotPWD,
    isForgotPWDError: state.login.isForgotPWDError,

  }
}
export default withRouter(connect(mapStateToProps, { forgotPasswordAction })(ForgotPasswordPage));

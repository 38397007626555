/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
//import { connect } from 'react-redux';
//import { withRouter } from 'react-router-dom';
import Pattern1 from 'Admin/assets/img/Pattern1.jpg';
import Pattern2 from 'Admin/assets/img/Pattern2.jpg';
import Pattern3 from 'Admin/assets/img/Pattern3.jpg';
import Pattern4 from 'Admin/assets/img/Pattern4.jpg';
import Pattern5 from 'Admin/assets/img/Pattern5.jpg';
import Pattern6 from 'Admin/assets/img/Pattern6.jpg';
import Pattern7 from 'Admin/assets/img/Pattern7.jpg';
import Pattern8 from 'Admin/assets/img/Pattern8.jpg';

class PatternPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      previewImage: '',
      patternName: this.props.pattern ? Number(this.props.pattern) : '',
    };


  }

  componentDidMount() {
    let patternName = this.state.patternName;

    if (patternName === 1) {
      this.setState({ previewImage: Pattern1 });

    } else if (patternName === 2) {
      this.setState({ previewImage: Pattern2 });

    } else if (patternName === 3) {
      this.setState({ previewImage: Pattern3 });

    } else if (patternName === 4) {
      this.setState({ previewImage: Pattern4 });

    } else if (patternName === 5) {
      this.setState({ previewImage: Pattern5 });

    } else if (patternName === 6) {
      this.setState({ previewImage: Pattern6 });

    } else if (patternName === 7) {
      this.setState({ previewImage: Pattern7 });

    } else if (patternName === 8) {
      this.setState({ previewImage: Pattern8 });

    }

  }

  render() {

    return (
      <Row>
        <Col sm={12} className="patienSearchBox">
          <FormGroup>
            <Col sm={12}>
              <div className="patternBox">
                <img className="patternImage" src={this.state.previewImage} alt="img" />
              </div>
            </Col>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

// function mapStateToProps(state) {
//     return {}
// }

export default PatternPreview;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import {
    Row, Col, Modal, Table
} from 'react-bootstrap';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/master';
import moment from 'moment';
import { appConstants } from 'Front/_constants/app.constants.js';
import ViewBox from '../../components/ViewBox';
import { confirmAlert } from 'react-confirm-alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReportTitle from './ReportTitle';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AnchorComponent from '../../components/common/AnchorComponent.js'
import ReportsIcon from '../../assets/img/icons/reports.svg';
import CardIcon from '../../assets/img/icons/card-icon.svg';
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "Front/actions/actionTypes";

const Reports = ({ visitInfo, hideButton = false, modelStatus = false, funModelStatus, readStatus }) => {

    const [ReportsModel, setReportsModel] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [page] = useState(1);
    const [ViewBoxData, setViewBoxData] = useState({ show: false, type: "image", path: "" });
    const [alertBox, setAlert] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState({ currentPage: 1, totalRow: 0, sizePerPage: 10 });
    const notificationSystem = useRef(null)
    const [checkReadStatus, setCheckReadStatus] = useState(false);
    const dispatch = useDispatch();

    const getReportListAction = async (data = {}) => {
        let resp = await API.getReportList({ patientId: visitInfo.pId, page: (Object.keys(data).length === 0 && data.constructor === Object) ? paginationInfo.currentPage - 1 : data.currentPage - 1, limit: (Object.keys(data).length === 0 && data.constructor === Object) ? paginationInfo.sizePerPage : data.sizePerPage });
        if (resp) {
            if (reportList.length === 0)
                setPaginationInfo({ ...paginationInfo, totalRow: resp.data.data.count });

            setReportList(resp.data.data.data);
        }
    }

    useEffect(() => {
        if (ReportsModel === true) {
            getReportListAction()
        } else {
            setReportList([]);
            if (modelStatus)
                funModelStatus();
        }
    }, [ReportsModel]);

    useEffect(() => {
        setCheckReadStatus(readStatus)
    }, [readStatus]);

    useEffect(() => {
        setReportsModel(modelStatus)
    }, [modelStatus]);

    const getFileIcon = (data, report) => {
        let icon = '';
        switch (data.fileType) {
            case 'jpg' || 'png' || 'jpeg':
                icon = `fa-image`;
                break;
            case 'mp4' || 'avi' || 'flv' || 'wmv':
                icon = 'fa-film';
                break;
            case 'pdf':
                icon = 'fa-file-pdf-o';
                break;
            default:
                icon = 'fa-file-o';
                break;
        }

        let color = (data.read) ? 'green' : 'red';

        // return <> <AnchorComponent
        //     style={{ "font-size": "20px", "padding": "2px", "color": color }}
        //     data={data.fileName}
        //     className='cursor-pointer-link'
        //     setReadFile={setReadFile}
        //     title="click"
        //     file={data}
        //     id={report._id}
        //     target='_blank'
        // >
        //     <i className={`fa ${icon}`} ></i>
        // </AnchorComponent>
        // </>

        return <>
            {data.fileType === "pdf" ? <a className='cursor-pointer-link' target={"_blank"} onClick={e => setReadFile(data, report._id)} style={{ fontSize: 20, padding: 2, color: color }} href={`${appConstants.s3UploadUrl + data.fileName}`} title="click"><i className={` fa ${icon}`} ></i></a> :
                <span className='cursor-pointer-link' onClick={e => setReadFile(data, report._id)} style={{ fontSize: 20, padding: 2, color: color }} href={`${appConstants.s3UploadUrl + data.fileName}`} title="click" target='_blank'><i className={` fa ${icon}`} ></i></span>}
        </>

        // <span className='cursor-pointer-link'  onClick={e=>setReadFile(data,report._id)} style={{"font-size":"20px","padding":"2px", "color":color}} href={`${appConstants.s3UploadUrl+data.fileName}`} title="click" target='_blank'></span>;
    };

    const setReadFile = async (file, reportId, path = '') => {
        let res = await API.setReadFile({ fileId: file._id, reportId: reportId, });

        const objIndex = reportList.findIndex(obj => obj._id === reportId);


        let filId = reportList[objIndex]['files'].findIndex(obj => obj._id === file._id);
        if (!reportList[objIndex]['files'][filId]['read']) {
            dispatch({
                type: actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_VIEW_REPORT_REQUEST,
                payload: { patientId: visitInfo.pId },
            });
        }
        reportList[objIndex]['files'][filId]['read'] = true;
        reportList[objIndex]['read'] = true;
        let newReportList = reportList;
        setReportList([...reportList]);

        setViewBoxData({ show: true, type: "image", title: "Report", path: file.fileName });

        var local_index = newReportList
            .map(function (el) {
                return el.read;
            })
            .indexOf(false);
        if (local_index != -1) {
            setCheckReadStatus(true);
        } else {
            setCheckReadStatus(false);
        }

        if (file.fileType != "pdf") {
            let tempData = { type: file.fileType, title: "Report", path: file.fileName }
            const url = `/view-box`;
            var newWindow = window.open(url);
            newWindow.params = tempData;

        }
        // window.open(
        //     appConstants.s3UploadUrl+file.fileName,
        //     '_blank' 
        // );
    };

    const hideViewBox = () => {

        setViewBoxData({ show: false, type: "image", path: "" });
    };

    const DeleteReport = async (id) => {
        const report = reportList.find(obj => obj._id === id);

        //let isRead=reportList[objIndex]['files'][filId]['read']
        let resp = await API.deleteReport({ reportId: id });

        if (resp.status === 200) {
            dispatch({
                type: actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_DELETE_REPORT_REQUEST,
                payload: { patientId: visitInfo.pId, isRead: report.read },
            });
            successAlert('Report Successfully Deleted.');

            getReportListAction();

        }

    }

    const DeleteReportConfirm = (id) => {

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteReport(id)
                },
                {
                    label: 'No',
                    //onClick: () => this.resetFormData()
                }
            ]
        })
    };

    const successAlert = (msg) => {
        setReportsModel(false)
        setPaginationInfo({});
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }


    const hideAlert = () => {
        setAlert(null);
    }

    // useEffect(()=>{
    //     if(ReportsModel === true){
    //         getReportListAction();   
    //     }
    // },[paginationInfo]);

    // useEffect(()=>{
    //     setCheckReadStatus(false)
    //     readStatus = false;
    //     console.log('SSSSS',readStatus)
    //     },[]);

    const onPageChange = (page, sizePerPage) => {
        setPaginationInfo({ ...paginationInfo, currentPage: page, sizePerPage: sizePerPage });
        getReportListAction({ ...paginationInfo, currentPage: page, sizePerPage: sizePerPage })
    }

    const titleFun = (cell, row) => {
        return <ReportTitle item={row} getReport={getReportListAction} />;
    }

    const createdAtFun = (cell, row) => {
        return moment(row.createdAt).format('D-MMM-YYYY');
    }
    const viewFun = (cell, row) => {
        if (row && row.files.length > 0) {
            return <>   {row.files.map((d, i) => {
                return getFileIcon(d, row);
            })}
            </>
        }

    }

    const deleteFun = (cell, row) => {
        return <a className="inactive-color" onClick={e => DeleteReportConfirm(row._id)}>Delete</a>
    }

    const options = {
        clearSearch: true,
        pageStartIndex: 1,
        paginationSize: 3,
        prePage: 'Prev',
        nextPage: 'Next',
        firstPage: 'First',
        lastPage: 'Last',
        paginationShowsTotal: paginationInfo.totalRow,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: paginationInfo.sizePerPage,
        onPageChange: onPageChange,
        page: paginationInfo.currentPage,
    };

    return (
        <span>
            {hideButton === false &&
                //<button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e => { setReportsModel(true) }}>Reports</button>
                <div className='aside-card' onClick={e => { setReportsModel(true) }}>
                    <div className='card-icon'>
                        <img src={ReportsIcon} alt='' />
                    </div>
                    <h4>Reports <span><img src={CardIcon} alt='' /></span></h4>
                    {
                        checkReadStatus &&
                        <div className="card-badge">New</div>
                    }
                </div>
            }

            {/* {ViewBoxData && ViewBoxData.show &&
                <ViewBox data={ViewBoxData} hideViewBox={e => hideViewBox()} />
            } */}
            {alertBox}
            <NotificationSystem ref={notificationSystem} style={style} />
            <Modal show={ReportsModel} onHide={() => { setReportsModel(false); setPaginationInfo({}) }} dialogClassName="modal-lg">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">Reports</Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                    <Row>
                        <Col md={12} className="fresh-datatables">
                            <BootstrapTable
                                selectRow="{selectRowProp}"
                                deleteRow={false}
                                data={reportList}
                                pagination={true}
                                options={options}
                                striped
                                hover
                                condensed
                                scrollTop={'Bottom'}
                                remote={true}
                                fetchInfo={{ dataTotalSize: paginationInfo.totalRow }}
                            >
                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '3%' }} tdStyle={{ width: '3%' }} tdAttr={{ 'data-attr': 'glucoseDate' }} dataFormat={titleFun} dataField='glucoseDate'>Title</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'glucoseValue' }} dataFormat={viewFun}  >VIEW </TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'suggestedDose' }} dataFormat={createdAtFun} >DATE</TableHeaderColumn>
                                <TableHeaderColumn thStyle={{ width: '5%' }} tdStyle={{ width: '5%' }} tdAttr={{ 'data-attr': 'suggestedDose' }} dataFormat={deleteFun} >Action</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-fill btn-secondary pull-right" onClick={() => {
                        setReportsModel(false)
                        setPaginationInfo({ currentPage: 1, totalRow: 0, sizePerPage: 10 })
                    }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
}
export default withRouter(Reports);

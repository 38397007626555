/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import login from './login'
import clinic from './clinic'
import clinicSubscription from './clinic_subscription'
import user from './user'
import master from './master'
import educator from './educator'
import faq from './faq'
import specilization from './specilization'
import clinicCalendar from './clinic_calendar'
import clinicActive from './clinic_active'
import link from './link'
import language from './language'
import patient from './patient'
import report from './report'
import foodCategory from './foodCategory'
import servingSize from './servingSize'
import food from './food'
import region from './region'
import attachment from './attachment'
import lowCalorieFood from './lowCalorieFood'
import comment from './comment'
import treatmentType from './treatmentType'
import instruction from './instruction'
import dietTime from './dietTime'
import treatment from './treatment'
import readyCreatedMeal from './readyCreatedMeal'
import clinicInvitation from './clinic_invitation' //-Anmol
import clinicDocumentUpload from './clinicDocumentUpload'
import clinicDocument from './clinicDocument' //-Anmol
import supportsystem from './supportsystem'
const reducer = combineReducers({
   router: routerReducer,
   login,
   clinic,
   user,
   master,
   specilization,
   educator,
   faq,
   clinicSubscription,
   clinicCalendar,
   clinicActive,
   link,
   language,
   patient,
   report,
   foodCategory,
   servingSize,
   food,
   region,
   attachment,
   lowCalorieFood,
   comment,
   treatmentType,
   instruction,
   dietTime,
   treatment,
   readyCreatedMeal,
   clinicInvitation,
   clinicDocumentUpload,
   clinicDocument,
   supportsystem
})

export default reducer

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  FormGroup,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import $ from "jquery";
import InternetSpeedTeller from "../../../Front/components/common/InternetSpeedTeller";
class HeaderLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logOutMenuOpen: false,
    };

    this.handleMenu = this.handleMenu.bind(this);
  }

  componentDidMount() {
    this.handleMenu();
  }

  handleMenu() {
    var obj = this;
    $("body").click(function (e) {
      if (
        !$(e.target).hasClass("dropdown") &&
        $(e.target).parents(".dropdown").length == 0
      ) {
        obj.setState({ settingsMenuOpen: false });
        obj.setState({ logoutMenuOpen: false });
      }
    });

    $("body").delegate(".dropdown li", "click", function () {
      obj.setState({ settingsMenuOpen: false });
      obj.setState({ logoutMenuOpen: false });
    });
  }

  Logout() {
    localStorage.removeItem("_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("email");
    localStorage.removeItem("status");
    localStorage.removeItem("token");

    window.location.href = "/";
  }

  render() {
    return (
      <div>
        {/*<Navbar.Form pullLeft className="navbar-search-form">
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><i className="fa fa-search"></i></InputGroup.Addon>
                            <FormControl type="text" placeholder="Search..." />
                        </InputGroup>
                    </FormGroup>
                </Navbar.Form> */}
        <Nav pullRight className="admin-nav-wrapper">
          {/* <InternetSpeedTeller /> */}
          <NavDropdown
            eventKey={4}
            title={
              <div>
                <i className="fa fa-list"></i>
                <p className="hidden-md hidden-lg">
                  More
                  <b className="caret"></b>
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
            className={`tran-nonee ${this.state.logoutMenuOpen ? "open" : ""}`}
            onClick={() =>
              this.setState({ logoutMenuOpen: !this.state.logoutMenuOpen })
            }
          >
            <MenuItem onClick={this.Logout.bind(this)} eventKey={4.5}>
              <div className="text-danger">
                <i className="pe-7s-close-circle"></i> Log out
              </div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;

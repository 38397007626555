/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { useState, useEffect } from 'react';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/emr';
import { Modal, FormGroup } from 'react-bootstrap';
import { ACTIONS } from './Diagnosis';


const DiagnosisEnlarge = ({ setModalDiagnosisEnlarge, ModalStatusDiagnosisEnlarge, DiagnosisEnlarge, diagnosisPatientId, diagnosisAppointmentId, dispatch, LongTerm, InterCurrent, LongTermData, InterCurrentData, oldAppointmentId }) => {
  const [diagonsisCategoryList, setDiagonsisCategoryList] = useState([])
  const [diagnosisSubcategoryList, setDiagnosisSubcategoryList] = useState([]);
  const [rowsLongTerm, setRowsLongTerm] = useState([]);
  const [rowsInterCurrent, setRowsInterCurrent] = useState([]);
  const [isPageLoad, setIsPageLoad] = useState(false)
  const [selectedCategoryIdEnlarge, setSelectedCategoryIdEnlarge] = useState()
  const [diagnosisCount, setDiagnosisCount] = useState(false)
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"))
  const [flag, setFlag] = useState("");
  const [dataLongTerm, setDataLongTerm] = useState([]);
  const [dataInterCurrent, setDataInterCurrent] = useState([]);


  let patientId = diagnosisPatientId;
  let appointmentId = diagnosisAppointmentId;
  let diagonsisCategoryId = DiagnosisEnlarge.diagonsisCategoryId
  let arrSubCategoryCheked = []
  let mode = "cancel"

  const showDiagnosisFun = () => {
    setRowsLongTerm([...LongTerm])
    setRowsInterCurrent([...InterCurrent])
    setDataLongTerm([...LongTermData])
    setDataInterCurrent([...InterCurrentData])
    setModalDiagnosisEnlarge(true);
    setDataLongTerm([...LongTermData])
    setDataInterCurrent([...InterCurrentData])
    getDiagonsisCategory(DiagnosisEnlarge.type, true)
    setIsPageLoad(true)

  }

  const getDiagonsisCategory = async (type, onPageLoad) => {

    let req = { "type": type }
    let catType = ""
    let resp = await API.diagonsisCategory(req);
    if (resp.data && resp.data.status === "Success") {

      let radioCategory = document.getElementById(type + "Enlarge");
      if (radioCategory !== null)
        radioCategory.checked = true

      setDiagonsisCategoryList(resp.data.diagonsisCategoryList.data)
      if (onPageLoad) {
        activateTab(DiagnosisEnlarge.diagonsisCategoryId)
        diagonsisCategoryId = DiagnosisEnlarge.diagonsisCategoryId
        catType = "master"
      } else {
        let ele = document.getElementById("cat" + selectedCategoryIdEnlarge);
        if (ele !== null) {
          activateTab(selectedCategoryIdEnlarge)
          diagonsisCategoryId = selectedCategoryIdEnlarge
        } else {
          activateTab(resp.data.diagonsisCategoryList.data[0]._id)
          diagonsisCategoryId = resp.data.diagonsisCategoryList.data[0]._id
        }

      }

      catType = diagonsisCategoryId === patientId ? "patient" : diagonsisCategoryId === clinicId ? "clinic" : "category"
      getDiagnosisSubCategory("", catType)
    }
  }

  const getDiagnosisSubCategory = async (e, catType) => {

    let type = "longTerm"
    let radioCategory = document.getElementById("longTermEnlarge")
    if (radioCategory !== null) {
      type = radioCategory.checked === true ? "longTerm" : "intercurrent"
    }
    setDiagnosisSubcategoryList([])

    if (e) {
      diagonsisCategoryId = e.target.id
      activateTab(diagonsisCategoryId)
    }
    let req = ""

    if (catType === "master") {
      req = {
        "type": type,
        "diagonsisCategoryId": diagonsisCategoryId,
        "patientId": patientId,
        "clinicId": clinicId
      }

    } else if (catType === "patient") {
      req = {
        "type": type,
        "diagonsisCategoryId": "",
        "patientId": patientId,
        "clinicId": ""
      }
    } else if (catType === "clinic") {
      req = {
        "type": type,
        "diagonsisCategoryId": "",
        "patientId": "",
        "clinicId": clinicId
      }

    } else if (catType === "category") {
      req = {
        "type": type,
        "diagonsisCategoryId": diagonsisCategoryId,
        "patientId": "",
        "clinicId": ""
      }
    }


    let resp = await API.diagonsisList(req);
    if (resp.data && resp.data.status === "Success") {
      setFlag(resp.data.flag)
      if (resp.data.flag === "patientDiagonsisList") {
        let radioPatient = document.getElementById(patientId);
        if (radioPatient !== null)
          radioPatient.checked = true;
        if (resp.data.patientDiagonsisList.length > 0) {
          setDiagnosisSubcategoryList(resp.data.patientDiagonsisList)
          arrSubCategoryCheked = resp.data.patientDiagonsisList
        }
      } else if (resp.data.flag === "clinicDiagonsisList") {
        let radioClinic = document.getElementById(clinicId);
        if (radioClinic !== null)
          radioClinic.checked = true;
        if (resp.data.clinicDiagonsisList.length > 0) {
          setDiagnosisSubcategoryList(resp.data.clinicDiagonsisList)
          arrSubCategoryCheked = resp.data.clinicDiagonsisList
        }
      } else if (resp.data.flag === "masterDiagonsisCategoryList") {
        if (resp.data.masterDiagonsisCategoryList.data.length > 0) {
          setDiagnosisSubcategoryList(resp.data.masterDiagonsisCategoryList.data)
          arrSubCategoryCheked = resp.data.masterDiagonsisCategoryList.data
        }
      }

      selectSubcategory(resp.data.flag)
    }
  }

  const selectSubcategory = (flagList) => {
    let tempArr = []
    let radioCategory = document.getElementById("longTermEnlarge");
    if (radioCategory !== null) {
      tempArr = radioCategory.checked === true ? LongTerm : InterCurrent
      if (isPageLoad) {
        tempArr = radioCategory.checked === true ? rowsLongTerm : rowsInterCurrent
      }
      tempArr.map(item => {
        if (arrSubCategoryCheked.length > 0) {
          arrSubCategoryCheked.map(subcat => {
            if (flagList === "patientDiagonsisList") {
              if (subcat.diagonsisSubCategoryId === item.diagonsisSubCategoryId) {
                checkedComplaint(subcat.diagonsisSubCategoryId)
              }
            } else {
              if (subcat._id === item.diagonsisSubCategoryId) {
                checkedComplaint(subcat._id)
              }

            }
          })
        }
      })
    }

  }


  const checkedComplaint = (subcatId) => {
    let chkSubCategory = document.getElementsByName("subcat");
    for (var subCategory of chkSubCategory) {
      if (subcatId === subCategory.getAttribute("subcatid")) {
        subCategory.checked = true;
      }
    }
  }

  const addOrDeleteSubcategory = (e) => {
    setDiagnosisCount(false)
    let type = "longTerm"
    let radioCategory = document.getElementById("longTermEnlarge");
    if (radioCategory !== null) {
      type = radioCategory.checked === true ? "longTerm" : "intercurrent"
    }
    let diagonsisType = "master";
    if (flag === "clinicDiagonsisList") {
      diagonsisType = "clinic";
    } else if (flag === "patientDiagonsisList") {
      diagonsisType = "patient";
    }

    if (e.target.checked) {
      if (type === "longTerm") {
        rowsLongTerm.push({
          "diagonsisSubCategoryId": e.target.getAttribute("subcatid"),
          "diagonsisSubCategoryName": e.target.value,
          "patientId": patientId,
          "duration": { "no": "", "period": "" },
          "appointmentId": appointmentId,
          "diagonsisType": diagonsisType,
          "type": "longTerm",
          "isDeleted": false
        })
        dataLongTerm.push({
          "diagonsisSubCategoryId": e.target.getAttribute("subcatid"),
          "diagonsisSubCategoryName": e.target.value,
          "patientId": patientId,
          "duration": { "no": "", "period": "" },
          "appointmentId": appointmentId,
          "diagonsisType": diagonsisType,
          "type": "longTerm",
          "isDeleted": false
        })
        setRowsLongTerm([...rowsLongTerm])
        setDataLongTerm([...dataLongTerm])
      } else {
        rowsInterCurrent.push({
          "diagonsisSubCategoryId": e.target.getAttribute("subcatid"),
          "diagonsisSubCategoryName": e.target.value,
          "patientId": patientId,
          "duration": { "no": "", "period": "" },
          "appointmentId": appointmentId,
          "diagonsisType": diagonsisType,
          "type": "intercurrent",
          "isDeleted": false,
          "discontinue": "-1",
          "closedAt": null,
          "oldAppointmentId": oldAppointmentId
        })
        dataInterCurrent.push({
          "diagonsisSubCategoryId": e.target.getAttribute("subcatid"),
          "diagonsisSubCategoryName": e.target.value,
          "patientId": patientId,
          "duration": { "no": "", "period": "" },
          "appointmentId": appointmentId,
          "diagonsisType": diagonsisType,
          "type": "intercurrent",
          "isDeleted": false,
          "discontinue": "-1",
          "closedAt": null,
          "oldAppointmentId": oldAppointmentId
        })
        setRowsInterCurrent([...rowsInterCurrent])
        setDataInterCurrent([...dataInterCurrent])
      }
    } else {
      deleteDiagnosis(e, type)
    }
  }

  const deleteDiagnosis = (e, type) => {

    let subCatId = e.target.getAttribute("subcatid");


    let chkbox_subact = document.getElementsByName("subcat");
    for (var chk of chkbox_subact) {
      if (chk.getAttribute("subcatid") === subCatId) {
        chk.checked = false;
      }
    }
    if (type === "longTerm") {
      rowsLongTerm.map((itm, idx) => {
        if (itm.diagonsisSubCategoryId === subCatId) {
          rowsLongTerm.splice(idx, 1);
          return;
        }
      })
      dataLongTerm.map((itm, idx) => {
        if (itm.diagonsisSubCategoryId === subCatId) {
          itm.isDeleted = true;
        }
      });
    } else {
      rowsInterCurrent.map((itm, idx) => {
        if (itm.diagonsisSubCategoryId === subCatId) {
          rowsInterCurrent.splice(idx, 1);
          return;
        }
      })
      dataInterCurrent.map((itm, idx) => {
        if (itm.diagonsisSubCategoryId === subCatId) {
          itm.isDeleted = true;
        }
      });
    }
    setRowsLongTerm([...rowsLongTerm]);
    setRowsInterCurrent([...rowsInterCurrent]);
    setDataLongTerm([...dataLongTerm]);
    setDataInterCurrent([...dataInterCurrent]);
  }


  const submitHandler = (e) => {

    e.preventDefault();
    let type = "longTerm"
    let radioCategory = document.getElementById("longTermEnlarge");
    if (radioCategory !== null)
      type = radioCategory.checked === true ? "longTerm" : "interCurrent"
    if (rowsLongTerm.length > 0 || rowsInterCurrent.length > 0) {
      let payloadData = {
        "longTerm": rowsLongTerm,
        "interCurrent": rowsInterCurrent,
        "selectedCategoryId": selectedCategoryIdEnlarge,
        "type": type,
        "longTermData": dataLongTerm,
        "intercurrentData": dataInterCurrent
      }
      dispatch({ type: ACTIONS.UPDATE_DIAGNOSIS, payload: payloadData })
      mode = "save"
      hideModal()
    } else setDiagnosisCount(true)
  }

  const hideModal = () => {
    setDiagonsisCategoryList([])
    setDiagnosisSubcategoryList([])
    setModalDiagnosisEnlarge(false)
    setIsPageLoad(false)
    setRowsLongTerm([])
    setRowsInterCurrent([])

    let payloadData = { "selectedCategoryId": mode === "cancel" ? DiagnosisEnlarge.diagonsisCategoryId : selectedCategoryIdEnlarge }
    if (mode === "cancel")
      dispatch({ type: ACTIONS.CLOSE_DIAGNOSIS_ENLARGE, payload: payloadData })
  }



  const activateTab = (id) => {
    let listDiagnosisCatgeory = document.getElementsByName("diagnosisCategoryName");
    setSelectedCategoryIdEnlarge(id)
    for (var category of listDiagnosisCatgeory) {

      if ("cat" + id === category.getAttribute("id")) {
        category.classList.add('current')
      } else {
        category.classList.remove('current')
      }

    }
  }





  return (

    <span >
      <a href='#' className='btn btn-link' onClick={e => showDiagnosisFun()}>View All Diagnosis</a>
      {/* <a href='#'><img src={InlargeIc} alt='Inlarge' onClick={e => showDiagnosisFun()} /></a> */}
      <Modal className='suggestion-box-modal' show={ModalStatusDiagnosisEnlarge} onHide={hideModal} dialogClassName="modal-xl">
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">Diagnosis</Modal.Title>
        </Modal.Header>
        <Modal.Body className="suggetion-box-enlarge">

          <div className="system-lists">
            <FormGroup className="">
              <div style={{ paddingRight: "10px", width: "auto" }} className="form-check form-check-emr form-check-inline">
                <input className="form-check-input" type="radio" name="catTypeEnlarge" onClick={() => { getDiagonsisCategory("longTerm", false); }} id="longTermEnlarge" value="value1" />
                <label className="form-check-label" for="longTermEnlarge">Long term </label>
              </div>
              <div style={{ paddingRight: "0px", width: "auto" }} className="form-check form-check-emr form-check-inline">
                <input className="form-check-input" type="radio" name="catTypeEnlarge" onClick={() => { getDiagonsisCategory("intercurrent", false); }} id="intercurrentEnlarge" value="value2" />
                <label className="form-check-label" for="intercurrentEnlarge">Intercurrent</label>
              </div>
            </FormGroup>
            <h5>Systems</h5>

            <ul>
              <li name="diagnosisCategoryName" id={"cat" + patientId}><a id={patientId} onClick={(e) => { getDiagnosisSubCategory(e, "patient"); }}>Patient List</a></li>
              <li name="diagnosisCategoryName" id={"cat" + clinicId}><a id={clinicId} onClick={(e) => { getDiagnosisSubCategory(e, "clinic"); }}>My List</a></li>
              {diagonsisCategoryList.map(item => {
                return (<>
                  <li id={"cat" + item._id} name="diagnosisCategoryName" className=''><a id={item._id} onClick={(e) => { getDiagnosisSubCategory(e, "category") }}>{item.name}</a></li>
                </>)
              })
              }

            </ul>

          </div>
          <div className="suggestion-lists">
            <div className="selected-complaints">
              <div className='selected-complaint-left'>
                {diagnosisCount &&
                  <div >
                    <p style={{ 'color': 'red' }}>There is no data to add</p>
                  </div>
                }
                <h5>Diagnosis Selected</h5>
                <ul>
                  {rowsLongTerm.map(item => {
                    return (<>
                      <li name="" style={{ background: '#830707', marginBottom: '8px' }} className=''><a id={item.diagonsisSubCategoryId} style={{ color: '#fff' }}  >{item.diagonsisSubCategoryName}</a>
                        <span style={{ color: '#fff', cursor: "pointer" }} onClick={(e) => { deleteDiagnosis(e, "longTerm") }} subcatid={item.diagonsisSubCategoryId} id={item.diagonsisSubCategoryId} >x</span>
                      </li>
                    </>)
                  })
                  }
                  {rowsInterCurrent.map(item => {
                    return (<>
                      <li name="" style={{ background: 'green', marginBottom: '8px' }} className=''><a id={item.diagonsisSubCategoryId} style={{ color: '#fff' }} >{item.diagonsisSubCategoryName}</a>
                        <span style={{ color: '#fff', cursor: "pointer" }} onClick={(e) => { deleteDiagnosis(e, "intercurrent") }} subcatid={item.diagonsisSubCategoryId} id={item.diagonsisSubCategoryId} >x</span>
                      </li>

                    </>)
                  })
                  }

                </ul>
              </div>
              <div className='text-right'>
                <Button className="btn btn-primary btn-fill" onClick={e => submitHandler(e)}>Add</Button>
              </div>
            </div>
            <div className="suggestion-list">
              {diagnosisSubcategoryList.map(item => {
                return (<>
                  <div class="form-check">
                    <input class="form-check-input" name="subcat" type="checkbox" onChange={(e) => { addOrDeleteSubcategory(e); }} value={flag === "masterDiagonsisCategoryList" ? item.name : item.diagonsisSubCategoryName} subcatid={flag === "patientDiagonsisList" ? item.diagonsisSubCategoryId : item._id} id={`subcatdiagnosis${flag === "patientDiagonsisList" ? item.diagonsisSubCategoryId : item._id}`} />
                    <label class="form-check-label" for={`subcatdiagnosis${flag === "patientDiagonsisList" ? item.diagonsisSubCategoryId : item._id}`}>
                      {flag === "masterDiagonsisCategoryList" ? item.name : item.diagonsisSubCategoryName}
                    </label>
                  </div>
                </>
                )
              })
              }
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </span>
  )
}

export default DiagnosisEnlarge

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as API from 'Admin/api/attachment';
import Card from 'Admin/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import loadingImg from 'Admin/assets/img/loading.gif';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

let Validator = require('validatorjs');

let rules = {
    categoty: 'required',
    titles: 'required',
    descriptions: 'required',
    files: 'required',
};
let mess = {
    required: 'This field is required',
};
let validationData = new Validator([], rules, mess)

let _quillModules = {
    toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
    ]

};

let _quillFormats = ["header",
    "bold", "italic", "underline", "strike", "blockquote",
    "list", "bullet", "indent",
    "link", "image"
];

const AddNewRoom = (props) => {
    const [formData, setFormData] = useState({ titles: {}, descriptions: {}, files: [] });
    const [showProcessing, setShowProcessing] = useState(false);
    const [validation, setValidation] = useState(validationData);
    const [formDataFile, setFormDataFile] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([
        'Recipe',
        'Diabetes technology',
        'New development in medicine in progress',
        'Exercise and yoga and stress',
        'Motivation quotes',
        'Stress management',
        'Healthy eating',
        'Health tips',
        'Vaccination',
        'Misc information'
    ]);

    const callAction = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let validationNew = new Validator(formData, rules, mess);
        setValidation(validationNew);
        if (validate()) {
            setShowProcessing(true);
            let form = new FormData();
            form.append('category', formData.categoty)
            form.append('titles', JSON.stringify(formData.titles))
            form.append('descriptions', JSON.stringify(formData.descriptions));
            form.append('fileEnglish', formDataFile[0]);
            form.append('fileHindi', formDataFile[1]);
            // for (let i = 0 ; i < formData.files.length ; i++) {
            //     form.append("files", formData.files[i],formData.files[i].name);
            // }
            let resp = await API.addNewsRoom(form);
            if (resp.data && resp.data.statusCode === 200) {
                setShowProcessing(false);
                props.handleClick('success', 'Successfully saved.')
                props.history.push(`/admin/news-room`)
                setIsLoading(false);

            } else {
                setShowProcessing(false);
                props.handleClick('success', resp.msg)
            }
        }
        setIsLoading(false);

    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.titles[1] && post.titles[1] != '') {
            errArr.titlesEng = '';
        } else { errArr.titlesEng = 'error'; errflag = false; }

        if (post.titles[2] && post.titles[2] != '') {
            errArr.titlesHindi = '';
        } else { errArr.titlesHindi = 'error'; errflag = false; }

        if (post.descriptions[1] && post.descriptions[1] != '') {
            errArr.descriptionsEng = '';
        } else { errArr.descriptionsEng = 'error'; errflag = false; }

        if (post.descriptions[2] && post.descriptions[2] != '') {
            errArr.descriptionsHindi = '';
        } else { errArr.descriptionsHindi = 'error'; errflag = false; }

        if (formDataFile[0] && formDataFile[0] != '') {
            errArr.fileEng = '';
        } else { errArr.fileEng = 'error'; errflag = false; }

        if (formDataFile[1] && formDataFile[1] != '') {
            errArr.fileHindi = '';
        } else { errArr.fileHindi = 'error'; errflag = false; }

        if (post.categoty && post.categoty != '') {
            errArr.categoty = '';
        } else { errArr.categoty = 'error'; errflag = false; }

        setErrors({ ...errArr });
        return errflag;
    }

    const goBackFun = async (e) => {
        props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    const handleChange = async (e, type, langId) => {
        let saveFormData = formData;
        if (type === "titles") {
            let str = e.target.value.replace(/\s+/g, " ");
            saveFormData.titles[langId] = str.trimStart()


        } else if (type === "desc") {
            saveFormData.descriptions[langId] = e;
        } else {
            saveFormData[e.target.name] = e.target.value;
        }

        setFormData({ ...formData, ...saveFormData });
        validate();

    };

    const changeHandlerFile = (event, langId) => {

        if (event.target.files.length > 0) {
            let tempFile = formDataFile
            if (langId === "1")
                tempFile[0] = event.target.files[0];
            else tempFile[1] = event.target.files[0];
            setFormDataFile(tempFile);
            setFormData({ 'files': tempFile, ...formData });
            validate()
        }
    };

    return (
        <div className="main-content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Form horizontal onSubmit={callAction} >
                            <div className="actionProcess" style={{ display: showProcessing ? 'block' : 'none' }}>
                                <img src={loadingImg} alt="Loading" width="40px" />
                                <center>Logging In - Please Wait</center>
                            </div>
                            <Card
                                title={
                                    <legend>General information
                                        <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { goBackFun(e) }}>Back</Button>
                                    </legend>
                                }
                                content={
                                    <div>
                                        <FormGroup>
                                            <Col sm={6}>
                                                <Col componentClass={ControlLabel} >
                                                    News category <span className="star">*</span>
                                                </Col>
                                                <FormControl componentClass="select" name="categoty" className={'insulin-input ' + (errors.categoty ? errors.categoty : '')} onChange={e => { handleChange(e, "category"); }}>
                                                    <option value="">Please select</option>
                                                    {categoryList.map((fbb, data) => {
                                                        return (<option selected={(fbb === formData.category) ? "selected" : ''} key={fbb} value={fbb}>{fbb}</option>)
                                                    }
                                                    )};
                                                </FormControl>
                                            </Col>
                                        </FormGroup>
                                        <hr />
                                        <Row>

                                            <Col sm={6}>
                                                <h5>English</h5>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News Title  <span className="star">*</span>
                                                        </Col>
                                                        <FormControl type="text" name="titles" value={formData?.titles[1]} className={'insulin-input ' + (errors.titlesEng ? errors.titlesEng : '')} onChange={e => { handleChange(e, "titles", "1"); }} autoComplete="off" placeholder="Title" maxLength={50} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News Detail <span className="star">*</span>
                                                        </Col>
                                                        {/* <FormControl type="textarea" name="description" onChange={e => { handleChange(e); }} value={formData.description}  autoComplete="off" placeholder="Description" /> */}
                                                        <ReactQuill
                                                            //onChange={this.handleChange.bind(this,"term_condition")}
                                                            onChange={e => { handleChange(e, "desc", "1"); }}
                                                            id='desc_1'
                                                            name="description_1"
                                                            theme='snow'
                                                            modules={_quillModules}
                                                            formats={_quillFormats}
                                                            toolbar={false} // Let Quill manage toolbar
                                                            bounds={'._quill'}
                                                        />
                                                        {errors.descriptionsEng &&
                                                            <span className="star">This field is required </span>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News upload <span className="star">*</span>
                                                        </Col>
                                                        <FormControl type="file" accept=".png, .jpg , .pdf , .mp4 " maxLength={12} name="files" onChange={e => { changeHandlerFile(e, "1"); }} autoComplete="off" placeholder="File" />
                                                        {errors.fileEng &&
                                                            <span className="star">This field is required </span>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Col>


                                            <Col sm={6}>
                                                <h5>Hindi</h5>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News Title Hindi <span className="star">*</span>
                                                        </Col>
                                                        <FormControl type="text" name="titles" value={formData?.titles[2]} className={'insulin-input ' + (errors.titlesHindi ? errors.titlesHindi : '')} onChange={e => { handleChange(e, "titles", "2"); }} autoComplete="off" placeholder="Title" maxLength={50} />
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News Detail Hindi <span className="star">*</span>
                                                        </Col>
                                                        {/* <FormControl type="textarea" name="description" onChange={e => { handleChange(e); }} value={formData.description}  autoComplete="off" placeholder="Description" /> */}
                                                        <ReactQuill
                                                            //onChange={this.handleChange.bind(this,"term_condition")}
                                                            onChange={e => { handleChange(e, "desc", "2"); }}
                                                            id='desc_2'
                                                            name="description_2"
                                                            theme='snow'
                                                            modules={_quillModules}
                                                            formats={_quillFormats}
                                                            toolbar={false} // Let Quill manage toolbar
                                                            bounds={'._quill'}
                                                        />
                                                        {errors.descriptionsHindi &&
                                                            <span className="star">This field is required </span>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                                <Col sm={12}>
                                                    <FormGroup>
                                                        <Col componentClass={ControlLabel} >
                                                            News upload <span className="star">*</span>
                                                        </Col>
                                                        <FormControl type="file" accept=".png, .jpg, .pdf , .mp4 " maxLength={12} name="files" onChange={e => { changeHandlerFile(e, "2"); }} autoComplete="off" placeholder="File" />
                                                        {errors.fileHindi &&
                                                            <span className="star">This field is required </span>
                                                        }
                                                    </FormGroup>
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Button
                                            type="submit"
                                            disabled={isLoading}
                                            fill bsStyle="info">{formData && formData.invitationId ? "Update" : "Submit"} {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                                        </Button>
                                    </div>
                                }
                            // ftTextCenter
                            // legend={<div>
                            //     <Button type="submit" fill bsStyle="info">{formData&&formData.invitationId?"Update":"Submit"}</Button>
                            // </div>
                            // }
                            />
                        </Form>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}
export default withRouter(AddNewRoom);


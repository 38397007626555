/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import {
  clinicSubscriptionAction,
  subscriptionchangestatusAction,
} from "Admin/actions/clinic_subscription";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import Common from "Admin/components/Common/Common";
import { Button } from "react-bootstrap";
import moment from "moment";

class ClinicSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClinicSubscription: [],
      isLoading: true,
      asc_desc: true,
      hidden: true,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      formData: {
        direction: "desc",
        order: "doctorName",
        offset: 0,
        limit: 10,
        expireValue:
          this.props &&
            this.props.location &&
            this.props.location.state === "expire"
            ? 2
            : sessionStorage.getItem("prvUrl") ==
              "/admin/add-clinic-subscription" &&
              sessionStorage.getItem("expireValue") &&
              sessionStorage.getItem("expireValue") != ""
              ? sessionStorage.getItem("expireValue")
              : "",
      },

      // expireValue:
      //   this.props &&
      //     this.props.location &&
      //     this.props.location.state === "expire"
      //     ? 2
      //     : sessionStorage.getItem("prvUrl") ==
      //       "/admin/add-clinic-subscription" &&
      //       sessionStorage.getItem("expireValue") &&
      //       sessionStorage.getItem("expireValue") != ""
      //       ? sessionStorage.getItem("expireValue")
      //       : "",
    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
    this.getOutClass = this.getOutClass.bind(this);
    this.onCreateDate = this.onCreateDate.bind(this);

    // console.log('exp', this.props);
    /*if(sessionStorage.getItem("prvUrl") == '/admin/add-clinic-subscription' && sessionStorage.getItem("expireValue") && sessionStorage.getItem("expireValue") != ''){

              this.setState({
                    expireValue: sessionStorage.getItem("expireValue")
                  });
              console.log(this.state);
        }*/
    if (sessionStorage.getItem("prvUrl") == "/admin/dashboard") {
      sessionStorage.setItem(
        "expireValue",
        this.props &&
          this.props.location &&
          this.props.location.state === "expire"
          ? 2
          : ""
      );
    } else if (sessionStorage.getItem("prvUrl") == "/admin/dashboard2") {
    }
  }

  componentDidMount() {
    let tempObj = this.state.formData;
    // if (this.props.location.pathname === "/admin/clinic-subscription-expired") {
    //   tempObj.flag = "exprired";
    // }
    if (this.props.location.state != null) {
      tempObj.flag = this.props.location.state.flag;
    }
    this.props.clinicSubscriptionAction(tempObj);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isClinicSubscriptionList !== this.props.isClinicSubscriptionList
    ) {
      this.setState({
        clinicSubscription: nextProps.clinicSubscriptionList.data.data,
        totalCount: nextProps.clinicSubscriptionList.data.count,
      });
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  editButton(cell, row, enumObject, rowIndex) {
    if (new Date(row.endDate) < new Date()) return "Expired";
    else {
      if (row.status == "active") {
        return (
          <a
            href="javascript:void(0)"
            onClick={this.statusChangedHandler.bind(this, row)}
          >
            Active
          </a>
        );
        // return 'Active'
      } else {
        // return <span onClick={this.statusChangedHandler.bind(this, row)}>Inactive</span>
        return (
          <a
            href="javascript:void(0)"
            onClick={this.statusChangedHandler.bind(this, row)}
          >
            Inactive
          </a>
        );
      }
      // return 'Inactive'
    }
    // return (<p><Link to={{ pathname: `update-subscription/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
    //     &nbsp;&nbsp; &nbsp;&nbsp;
    //     <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status==="active") ? ('fa fa-check') : ('fa fa-remove') } aria-hidden="true"></i></a>
    // <a href="javascript:void(0)"><i className="fa fa-trash-o" aria-hidden="true"></i></a></p>)
  }

  toggleEdit(event) {
    this.setState({
      userRow: event,
    });
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.status == "active") {
      newstatus = "inactive";
    } else {
      newstatus = "active";
    }
    event["id"] = event._id;
    event["status"] = newstatus;
    this.props.subscriptionchangestatusAction(event);
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  checkSubscription(cell, row, enumObject, rowIndex) {
    let endDate = moment(row.endDate).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");

    let remaining_day = moment(row.endDate).diff(moment(), "days");
    if (today > endDate) {
      return <div className="subs-red">{row._id}</div>;
    } else if (remaining_day < 10 && remaining_day > 1) {
      // return <div className="subss-blue">{row._id}</div>
      return <div className="subss-yellow">{row._id}</div>;
    } else {
      // return <div>{row._id}</div>
      return <div className="subss-black">{row._id}</div>;
    }
  }

  getOutClass(cell, row, enumObject, rowIndex) {
    let endDate = moment(row.endDate).format("YYYY-MM-DD");
    let today = moment().format("YYYY-MM-DD");
    let remaining_day = moment(row.endDate).diff(moment(), "days");

    if (today > endDate) {
      //  return row._id + " subs-red" ;

      return <div className="subs-red">{Common.formatDate(endDate)}</div>;
    } else if (remaining_day < 10 && remaining_day > 1) {
      //return row._id + " subss-yellow" ;
      return <div className="subss-yellow">{Common.formatDate(endDate)}</div>;
    } else {
      return <div className="subss-black">{Common.formatDate(endDate)}</div>;
      //return {"style" : "background-color" = "red"}
    }

    // if (row.doctorOut === 'out') {
    //     return row.id + " Dr-Out" ;
    // } else if(row.educatorOut === 'out'){
    //     return row.id + " Ed-Out" ;
    // } else {
    //     return row.id;
    // }
  }

  onUserCount(cell, row, enumObject, rowIndex) {
    return (
      (this.state.currentPage - 1) * this.state.sizePerPage + (rowIndex + 1)
    );
  }

  onCreateDate(cell, row, enumObject, rowIndex) {
    return row && row.clinicCreatedAt
      ? Common.formatDate(row.clinicCreatedAt)
      : "";
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    if (this.state.searchText === "") {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    } else {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    }

    data["expireValue"] = this.state.formData.expireValue;

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    if (this.props.location.state != null) {
      data["flag"] = this.props.location.state.flag;
    }
    this.props.clinicSubscriptionAction(data);

    if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {};
        data["direction"] = this.state.formData.direction;
        data["order"] = this.state.formData.order;
        data["offset"] = this.state.formData.offset;
        data["limit"] = this.state.formData.limit;
        data["search"] = text;
        data["expireValue"] = this.state.formData.expireValue;
        this.setState({ sizePerPage: 10 });
        this.setState({ currentPage: 1 });
        if (this.props.location.state != null) {
          data["flag"] = this.props.location.state.flag;
        }
        this.props.clinicSubscriptionAction(data);
      }
    } else {
      setTimeout(() => {
        this.componentDidMount();
      }, 100);
    }
    this.setState({ searchText: text });
  }

  sortHandle() {
    this.setState({ asc_desc: !this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc ? "asc" : "desc"
    this.setState({ formData: data });
    this.props.clinicSubscriptionAction(data);
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  render() {
    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      // onSearchChange: this.onSearchChange,
      noDataText: this._setTableOption(),
    };

    const Action = (cell, row, enumObject, rowIndex) => {
      return (<span><Link to={{ pathname: `/admin/edit-clinic-subscription/` + row.clinicId, state: { row } }}  ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
        &nbsp;&nbsp; &nbsp;&nbsp;
        {/* <a href="javascript:void(0)" onClick={() => statusChangedHandler(row)}><i className={(row.enabled === "1") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a> */}
      </span>)
    }

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables pad0-tabss">
                    {/* <Row>
                      <Col md={6}>
                        <Button
                          bsStyle="info"
                          className="inacti-button"
                          onClick={() =>
                            this.props.history.replace(
                              "add-clinic-subscription"
                            )
                          }
                        >
                          Add Clinic Subscription
                        </Button>
                      </Col>
                    </Row>{" "} */}
                    &nbsp;
                    <div className="table-resposive educator-L">
                      <div className='search-box-admin'>
                        <div className='search'>
                          <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                          <span className='input-group-btn'>
                            <Button onClick={(e) => { this.onSearchChange(e.target.value, "null") }}>Clear</Button>
                          </span>
                        </div>
                      </div>
                      <BootstrapTable
                        deleteRow={false}
                        data={this.state.clinicSubscription}
                        // search={true}
                        multiColumnSearch={true}
                        pagination={true}
                        // trClassName={this.getOutClass}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={"Bottom"}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.state.totalCount }}
                      >
                        <TableHeaderColumn
                          hidden={this.state.hidden}
                          tdAttr={{ "data-attr": "_id" }}
                          dataField="invitationId"
                          isKey
                          searchable={false}
                        >
                          Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "10%", textAlign: "center" }}
                          tdStyle={{ width: "10%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "#" }}
                          dataField=""
                          dataFormat={this.onUserCount}
                        >
                          #
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ textAlign: "center" }}
                          tdStyle={{ textAlign: "center" }}
                          width="90px"
                          tdAttr={{ "data-attr": "Clinic Id" }}
                          dataField="clinicIdNumber"
                        >
                          Clinic Id
                          {
                            this.state.asc_desc ?
                              <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                              <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                          }
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: '130px', textAlign: 'center' }}
                          tdStyle={{ width: '130px', textAlign: 'center' }}
                          tdAttr={{ 'data-attr': 'Clinic Name' }}
                          dataField='doctorName'
                        >Doctor Name

                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "22%", textAlign: "center" }}
                          tdStyle={{ width: "22%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "Clinic Name" }}
                          dataField="clinicName"
                        >
                          Clinic Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "15%", textAlign: "center" }}
                          tdStyle={{ width: "15%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "Plan" }}
                          dataField="planName"
                        >
                          Plan
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          width="170px"
                          thStyle={{ textAlign: "center" }}
                          tdStyle={{ textAlign: "center" }}
                          tdAttr={{ "data-attr": "Clinic Registration date" }}
                          dataField="clinicCreatedAt"
                          dataFormat={this.onCreateDate}
                        >
                          Clinic Registration date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "20%", textAlign: "center" }}
                          tdStyle={{ width: "20%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "Start Date" }}
                          dataField="startDate"
                          dataFormat={Common.formatDate}
                        >
                          Start Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "20%", textAlign: "center" }}
                          tdStyle={{ width: "20%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "End Date" }}
                          dataField="endDate"
                          dataFormat={this.getOutClass}
                        >
                          End Date
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "15%", textAlign: "center" }}
                          tdStyle={{ width: "15%", textAlign: "center" }}
                          tdAttr={{ "data-attr": "Action" }}
                          dataFormat={this.editButton.bind(this)}
                        >
                          Status
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: '15%', textAlign: 'center' }}
                          tdStyle={{ width: '15%', textAlign: 'center' }}
                          tdAttr={{ 'data-attr': 'clinicId' }}
                          dataField='' dataFormat={Action}>
                          Action
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clinicSubscriptionList: state.clinicSubscription.clinicSubscriptionList,
    isClinicSubscriptionList: state.clinicSubscription.isClinicSubscriptionList,
    isClinicSubscriptionListError:
      state.clinicSubscription.isClinicSubscriptionListError,

    SubscriptionChangeStatus: state.clinicSubscription.SubscriptionChangeStatus,
    isSubscriptionChangeStatus:
      state.clinicSubscription.isSubscriptionChangeStatus,
    isSubscriptionChangeStatusError:
      state.clinicSubscription.isSubscriptionChangeStatusError,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    clinicSubscriptionAction,
    subscriptionchangestatusAction,
  })(ClinicSubscription)
);

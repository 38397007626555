/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';

import banner_img from 'Front/assets/img/banner_2.png';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import logo from 'Front/assets/img/practice_aid_logo.png';
import { Link } from 'react-router-dom';
class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isLogin: true,
      showProcessing: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field
    });
  };
  userLogin(evt) {
    evt.preventDefault();
    const _this = this;

  }
  render() {
    return (
      <Media>
        <div className="banner-contact">
          <div className="banner_content">
            <h2>Digitizing Healthcare</h2>
            <p>Cutting-edge Software Products for Healthcare Industry</p>
          </div>
        </div>
        <Grid>
          <div className="about-us">
            <h2>Terms and Conditions</h2>
          </div>

          <Row>
            <Col md={12} sm={12} className="terms-condition">
              <p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and&nbsp;PracticeAid&nbsp;(“we,” “us” or “our”), concerning your access to and use of our mobile application. You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use. If you do not agree with all of these terms of use, then you are expressly prohibited from using the application and you must discontinue use immediately.</p>
              <p>The information provided on the application is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, the people who use the application from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
              <p>The application is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register.</p>
              <h4><strong>INTELLECTUAL PROPERTY RIGHTS</strong></h4>
              <p>PracticeAid is our proprietary property and all source code, databases, functionality, software, designs, audio, video, text, photographs, and graphics on the application (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the application, Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>

              <p>Provided that you are eligible to use the application, you are granted a limited license to access to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the application, the Content and the Marks.</p>
            </Col>
          </Row>
          <div className="middle-spc"></div>
        </Grid>
      </Media>
    );
  }
}

function mapStateToProps(state) {

  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Contact));

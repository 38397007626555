/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addFood = (data) => postRequestWithToken(data, 'admin/add-food');
export const updateFood = (data) => postRequestWithToken(data, 'admin/update-food');
export const changeFoodStatus = (data) => postRequestWithToken(data, 'admin/food-change-status');
export const foodList = (data) => postRequestWithToken(data, 'admin/food');
export const foodCartList = (data) => postRequestWithToken(data, 'admin/food_list'); 

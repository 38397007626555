/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
import * as API from 'Front/api/language'

/* Language list  */
export const LanguageListRequest = () => ({
    type: actionTypes.GET_LANGUAGE_LIST_REQUEST
})
export const LanguageListSuccess = (response) => ({
    type: actionTypes.GET_LANGUAGE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const LanguageListError = (error) => ({
    type: actionTypes.GET_LANGUAGE_LIST_ERROR,
    payload: {
        error
    }
})
export const languageListAction = (data) => {
    return dispatch => {
        dispatch(LanguageListRequest())

        return API.languageList(data)
            .then(response => {
                dispatch(LanguageListSuccess(response.data.data))
            })
            .catch(error => {
                dispatch(LanguageListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import banner_img from 'Front/assets/img/banner_2.png';
import aahar1 from 'Front/assets/img/aahar1.png';
import aahar2 from 'Front/assets/img/aahar2.png';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';
class Aahar extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (
      <Media>
        <div className="aahar-ban">
          <div className="banner_content">
            <h2>Aahar</h2>
          </div>
        </div>

        <Grid>
          <div className="about-us">
            <h2>Aahar – To Live Heathy</h2>
          </div>

          <div className="PracticeAid-pera">
            <p>
              <span>Aahar</span> – a comprehensive and scientific web solution simplifies the detail-oriented process of creating a diet plan suitable for diabetics. Aahar serves as a personal dietician and provides customized diet plans as per the user’s preference. The primary objective of this web solution is to help mitigate the risks related to unhealthy eating habits and to provide diet pattern matching to treatment for patients with diabetes
            </p>
          </div>

          <Row>
            <Col md={6} lg={6}>
              <img width={'100%'} height={'100%'} src={aahar1} alt="Aahar" />
            </Col>
            <Col md={6} lg={6} className="m-b-img">
              <img width={'100%'} height={'100%'} src={aahar2} alt="Aahar" />
            </Col>
          </Row>

          <div className="solutionHighlights">
            <h4>Why Aahar?</h4>
            <ul>
              <li><i className="glyphicon glyphicon-ok"></i> Aaahar takes in account the type of diabetes the patient is suffering from before creating the diet plan.</li>
              <ul>
                <li><i className="glyphicon glyphicon-menu-right"></i>Type 1, Type 2, Gestational Diabetes.</li>
              </ul>

              <li><i className="glyphicon glyphicon-ok"></i> The meal plans are based on the treatments that the patient is receiving.</li>
              <ul>
                <li><i className="glyphicon glyphicon-menu-right"></i> Oral Antihyperglycemic drugs, Oral hypoglycemic drugs, GLP1 analogs, Insulin therapy.</li>
              </ul>
              <li><i className="glyphicon glyphicon-ok"></i> Customized meal plans based on individual’s need.</li>
              <ul>
                <li><i className="glyphicon glyphicon-menu-right"></i>Plans for weight loss/ maintenance/ weight gain.</li>
                <li><i className="glyphicon glyphicon-menu-right"></i>Considers specialized needs such as High Protein diet and Renal.</li>
                <li><i className="glyphicon glyphicon-menu-right"></i>Calorie meter integrated to calculate calories based on BMI.</li>
              </ul>
              <li><i className="glyphicon glyphicon-ok"></i> The solution can be used in hospitals, medical centers, clinics and health and fitness clubs for optimized diet planning.
              </li>
              <li><i className="glyphicon glyphicon-ok"></i> The meals are distributed in a scientific manner based on the calorie intake for that particular time.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Provides diet guidance in specific conditions like hyperuricemia, renal stone, iron and calcium deficiency , obesity , short stature in kids, liver and kidney diseases , high blood pressure etc.</li>
              <li><i className="glyphicon glyphicon-ok"></i> Language Options – Hindi and English.</li>
            </ul>
          </div>

        </Grid>
        <div className="middle-spc"></div>
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Aahar));

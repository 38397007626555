/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/supportsystem'


export const ADD_SUPPORTSYSTEM_REQUEST = "ADD_SUPPORTSYSTEM_REQUEST"
export const ADD_SUPPORTSYSTEM_SUCCESS = "ADD_SUPPORTSYSTEM_SUCCESS"
export const ADD_SUPPORTSYSTEM_ERROR = "ADD_SUPPORTSYSTEM_ERROR"
export const GET_SUPPORTSYSTEM_LIST_REQUEST = "GET_SUPPORTSYSTEM_LIST_REQUEST"
export const GET_SUPPORTSYSTEM_LIST_SUCCESS = "GET_SUPPORTSYSTEM_LIST_SUCCESS"
export const GET_SUPPORTSYSTEM_LIST_ERROR = "GET_SUPPORTSYSTEM_LIST_ERROR"

//-- Add SupportSystem
export const addSupportSystemRequest = () => ({
    type: actionTypes.ADD_SUPPORTSYSTEM_REQUEST
})
export const addSupportSystemSuccess = (response) => ({
    type: actionTypes.ADD_SUPPORTSYSTEM_SUCCESS,
    payload: {
        response,
    }
})
export const addSupportSystemError = (error) => ({
    type: actionTypes.ADD_SUPPORTSYSTEM_ERROR,
    payload: {
        error
    }
})
export const addSupportSystemAction = (data) => {
    return dispatch => {
        dispatch(addSupportSystemRequest())

        return API.addSupportSystem(data).then(response => {
            dispatch(addSupportSystemSuccess(response.data))
        })
            .catch(error => {
                dispatch(addSupportSystemError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
//-- List SupportSystem
export const supportsystemListRequest = () => ({
    type: actionTypes.GET_SUPPORTSYSTEM_LIST_REQUEST
})
export const supportsystemListSuccess = (response) => ({
    type: actionTypes.GET_SUPPORTSYSTEM_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const supportsystemListError = (error) => ({
    type: actionTypes.GET_SUPPORTSYSTEM_LIST_ERROR,
    payload: {
        error
    }
})
export const supportListAction = (data) => {

    return dispatch => {
        dispatch(supportsystemListRequest())
        // const FormData = {
        //     limit:10,
        //     offset:0,
        //     order:"name",
        //     direction:"asc"
        // }
        // if(data.formData){
        //     FormData.status = 'active';
        // }

        return API.Supportlist(data)
            .then(response => {
                dispatch(supportsystemListSuccess(response.data))
            })
            .catch(error => {
                dispatch(supportsystemListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Update SupportSystem and Add form */

export const updateSupportSystemRequest = () => ({
    type: actionTypes.GET_UPDATE_SUPPORTSYSTEM_REQUEST
})
export const updateSupportSystemSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_SUPPORTSYSTEM_SUCCESS,
    payload: {
        response,
    }
})
export const updateSupportSystemError = (error) => ({
    type: actionTypes.GET_UPDATE_SUPPORTSYSTEM_ERROR,
    payload: {
        error,
    }
})
export const updateSupportSystemAction = (data) => {
    return dispatch => {
        dispatch(updateSupportSystemRequest())
        const FormData = {
            name: data.Name,
            description: data.description,
            status: data.status,
            id: data.id
        }
        return API.updateSupportSystem(FormData)
            .then(response => {
                dispatch(updateSupportSystemSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateSupportSystemError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* SupportSystem Status Change  */
// export const SupportSystemChangeStatusRequest = () => ({
//     type: actionTypes.GET_SUPPORTSYSTEM_CHANGE_STATUS_REQUEST
// })
// export const SupportSystemChangeStatusSuccess = (response) => ({
//     type: actionTypes.GET_SUPPORTSYSTEM_CHANGE_STATUS_SUCCESS,
//     payload: {
//         response,
//     }
// })
// export const SupportSystemChangeStatusError = (error) => ({
//     type: actionTypes.GET_SUPPORTSYSTEM_CHANGE_STATUS_ERROR,
//     payload: {
//         error
//     }
// })
// export const supportsystemchangestatusAction = (data) => {
//     return dispatch => {
//         dispatch(SupportSystemChangeStatusRequest())
//         const FormData = {
//             id:data._id,
//             status:data.status
//         }
//         return API.changeSupportSystemStatus(FormData)
//         .then(response => {
//             dispatch(SupportSystemChangeStatusSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(SupportSystemChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
//         })
//     }
// }

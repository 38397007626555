/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes' //--Anmol
const initState = {
  response: [],
  message: '',
  type: '',
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    //-- Listing 
    case actionTypes.GET_GRAPH_REPORT_REQUEST:
      return {
        ...state,
        type: 'request',
        response: [],
        message: '',
      }
    case actionTypes.GET_GRAPH_REPORT_SUCCESS:
      return {
        ...state,
        type: "getReport",
        response: action.payload.data,
        message: 'Success',
      }
    case actionTypes.GET_GRAPH_REPORT_ERROR:
      return {
        ...state,
        response: action.payload.response.data,
        message: 'Error',
      }

    default:
      return state
  }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import io from 'socket.io-client';
import { languageOptions } from '../variables/Variables.jsx';

export const appConstants = {
    socket: io(process.env.REACT_APP_URL),
    paAppURL: process.env.REACT_APP_URL,
    dateFormat: process.env.REACT_APP_DATE_FORMAT,
    s3UploadUrl: process.env.REACT_APP_S3_UPLOAD_URL,
    GOOGLE_KEY: process.env.REACT_APP_GOOGLE_KEY,
    PswValidCharecters: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,12}$/,
    ucFirst: function (value) {
        if (value[0] && value[1]) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    },
    vegNonVeg: function (value) {

        if (value === "veg" || value === "veg-s") {
            return "Vegetarian";
        } else {
            return "Non Vegetarian";
        }
    },

    diabetic: function (value) {
        if (value === "diabetic") {
            return "Diabetic";
        }
        return "Non Diabetic";
    },

    time: function () {
        let time_array = [
            "05:00 am",
            "05:30 am",
            "06:00 am",
            "06:30 am",
            "07:00 am",
            "07:30 am",
            "08:00 am",
            "08:30 am",
            "09:00 am",
            "09:30 am",
            "10:00 am",
            "10:30 am",
            "11:00 am",
            "11:30 am",
            "12:00 pm",
            "12:30 pm",
            "01:00 pm",
            "01:30 pm",
            "02:00 pm",
            "02:30 pm",
            "03:00 pm",
            "03:30 pm",
            "04:00 pm",
            "04:30 pm",
            "05:00 pm",
            "05:30 pm",
            "06:00 pm",
            "06:30 pm",
            "07:00 pm",
            "07:30 pm",
            "08:00 pm",
            "08:30 pm",
            "09:00 pm",
            "09:30 pm",
            "10:00 pm",
            "10:30 pm",
            "11:00 pm",
        ];
        return time_array
    },

    calorieRange: function () {
        let calorie_array = [{ calorie_from: "0", calorie_to: "39", range: "0-39" },
        { calorie_from: "40", calorie_to: "69", range: "40-69" },
        { calorie_from: "70", calorie_to: "99", range: "70-99" },
        { calorie_from: "100", calorie_to: "129", range: "100-129" },
        { calorie_from: "130", calorie_to: "159", range: "130-159" },
        { calorie_from: "160", calorie_to: "189", range: "160-189" },
        { calorie_from: "190", calorie_to: "219", range: "190-219" },
        { calorie_from: "220", calorie_to: "249", range: "220-249" },
        { calorie_from: "250", calorie_to: "279", range: "250-279" },
        { calorie_from: "280", calorie_to: "309", range: "280-309" },
        { calorie_from: "310", calorie_to: "339", range: "310-339" },
        { calorie_from: "340", calorie_to: "369", range: "340-369" },
        { calorie_from: "370", calorie_to: "399", range: "370-399" },

        { calorie_from: "400", calorie_to: "429", range: "400-429" },
        { calorie_from: "430", calorie_to: "459", range: "430-459" },
        { calorie_from: "460", calorie_to: "489", range: "460-489" },
        { calorie_from: "490", calorie_to: "519", range: "490-519" },
        { calorie_from: "520", calorie_to: "549", range: "520-549" },
        { calorie_from: "550", calorie_to: "579", range: "550-579" },
        { calorie_from: "580", calorie_to: "609", range: "580-609" },
        { calorie_from: "610", calorie_to: "639", range: "610-639" },
        { calorie_from: "640", calorie_to: "669", range: "640-669" },
        { calorie_from: "670", calorie_to: "699", range: "670-699" },
        { calorie_from: "700", calorie_to: "729", range: "700-729" },

        { calorie_from: "730", calorie_to: "759", range: "730-759" },
        { calorie_from: "760", calorie_to: "789", range: "760-789" },
        { calorie_from: "790", calorie_to: "819", range: "790-819" },
        { calorie_from: "820", calorie_to: "849", range: "820-849" },
        { calorie_from: "850", calorie_to: "879", range: "850-879" },
        { calorie_from: "880", calorie_to: "909", range: "880-909" },
        { calorie_from: "910", calorie_to: "939", range: "910-939" },
        { calorie_from: "940", calorie_to: "969", range: "940-969" },
        { calorie_from: "970", calorie_to: "999", range: "970-999" },
        { calorie_from: "1000", calorie_to: "1029", range: "1000-1029" },

        { calorie_from: "1030", calorie_to: "1059", range: "1030-1059" },
        { calorie_from: "1060", calorie_to: "1089", range: "1060-1089" },
        { calorie_from: "1090", calorie_to: "1119", range: "1090-1119" },
        { calorie_from: "1120", calorie_to: "1149", range: "1120-1149" },
        { calorie_from: "1150", calorie_to: "1179", range: "1150-1179" },
        { calorie_from: "1180", calorie_to: "1209", range: "1180-1209" },

        ];
        return calorie_array
    },
    getLanguageName: function (id) {

        let language = languageOptions.filter((item) => { return item.value === id.toString(); });

        return language[0] && language[0].label ? language[0].label : '';

    }
};

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { educatorListAction, educatorchangestatusAction } from 'Admin/actions/educator';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import Common from 'Admin/components/Common/Common';
import { clinicListAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import { specializationListAction } from 'Admin/actions/specialization';
import ClinicHeader from '../ClinicHeader/ClinicHeader';
import moment from 'moment';

class EducatorList extends Component {

    constructor(props) {
        super(props);
        var type = 'admin';
        let _id_clinic;
        if (this.props && this.props.location && this.props.location.state && this.props.location.state._id) {
            type = 'all';
            _id_clinic = this.props.location.state._id;
            localStorage.setItem('_id_clinic', this.props.location.state._id);
        }
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.status) {
            type = 'all';
        }
        this.state = {
            clinicList: [],
            specializationList: [],
            educatorList: [],
            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                //clinicId: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
                clinicId: _id_clinic,
                specialization: "",
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                type: type,
                //status: this.props && this.props.location && this.props.location.state && this.props.location.state.status
            },
            limitForm: {},
            // clinicView: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
            clinicView: _id_clinic,
        }

        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
        this.onClinicName = this.onClinicName.bind(this);
        // this.onClinicDoctorName = this.onClinicDoctorName.bind(this);
        this.onClinicId = this.onClinicId.bind(this);

    }

    componentDidMount() {
        this.props.educatorListAction('', this.state.formData);

        let data = this.state.limitForm;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        this.props.ClinicSimpleListAction();
        this.props.specializationListAction(data);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isEducatorList !== this.props.isEducatorList) {
            let totalCount = 0;
            if (nextProps.EducatorList && nextProps.EducatorList.data && nextProps.EducatorList.data.length > 0) {
                totalCount = nextProps.EducatorList.count;
            }
            this.setState({
                educatorList: nextProps.EducatorList.data,
                totalCount: totalCount,
            });
        }
        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
        // if (nextProps.isClinicList !== this.props.isClinicList) {

        //     this.setState({
        //         clinicList: nextProps.ClinicList.data.data
        //     });
        // }

        if (nextProps.isSpecializationList && nextProps.isSpecializationList !== this.props.isSpecializationList) {
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                this.setState({
                    specializationList: nextProps.SpecializationList.data.data
                });

            }
        }

        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }
    };

    editButton(cell, row, enumObject, rowIndex) {
        // if (this.props.location.state || this.state.formData.type === 'all') {
        if (this.state.formData.type === 'all') {

            return (<span><Link to={{ pathname: `update-educator/` + row._id, state: { row, type: row.addedType ? row.addedType : 'all' }, clinicDetail: this.props.location.state }} onClick={this.toggleEdit.bind(this, row)} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>
                &nbsp;&nbsp; &nbsp;&nbsp;
                {/*<a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a> */}
            </span>)
        } else {
            return (<span><Link to={{ pathname: `update-educator/` + row._id, state: { row, type: 'admin' } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                &nbsp;&nbsp; &nbsp;&nbsp;
                {/*<a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a> */}
            </span>)
        }

    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.educatorchangestatusAction(event);
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    onClinicName(cell, row, enumObject, rowIndex) {
        return (row && row.clinic && row.clinic.clinicName ? row.clinic.clinicName : 'All Clinic');
    }

    onClinicDoctorName(cell, row, enumObject, rowIndex) {
        return (row && row.clinic && row.clinic.doctorName ? row.clinic.doctorName : 'All Clinic');
    }

    onClinicId(cell, row, enumObject, rowIndex) {
        return (row && row.clinic && row.clinic.clinicNumber ? row.clinic.clinicNumber : 'All Clinic');
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-educator";
        this.clicked = "";
    }

    handleClinic(event) {
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinicId'] = event.target.value
        formData['clinicName'] = event.target[index].text
        this.setState({ formData: formData });
    }

    handleSpecialization(event) {
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['specialization_id'] = event.target.value
        formData['specialization_name'] = event.target[index].text
        this.setState({ formData: formData });
    }

    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        this.props.educatorListAction('', formData);
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        data['type'] = this.state.formData.type;
        data['clinic_id'] = this.state.formData.clinicId;
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;

        }
        data['status'] = this.state.formData.status;
        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.educatorListAction(data)
        
        if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
            eval('$(".ps").scrollTop(0)');
        }
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['type'] = this.state.formData.type;
                data['search'] = text;
                data['status'] = this.state.formData.status;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.educatorListAction(data);
            }
        } else {
            setTimeout(() => {       
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    clearSearch(e) {
        this.setState({ searchText: '' });
        this.componentDidMount();
    }

    sortHandle(){
        this.setState({ asc_desc:!this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc? "asc":"desc"
        this.setState({ formData : data });
        this.props.educatorListAction(data);
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    addEduMessage(e) {

        this.props.history.push({
            pathname: '/admin/add-educator',
            state: {
                clinic_id: this.props && this.props.location && this.props.location.state && this.props.location.state._id ? this.props.location.state._id : "",
                clinicName: this.props && this.props.location && this.props.location.state && this.props.location.state.name ? this.props.location.state.name : "",
                clinicDetail: this.props.location.state,
            }
        })
    }

    allView() {
        var data = this.state.formData;
        data['type'] = "all";
        this.setState({ formData: data, filter: true });
        this.props.educatorListAction('', this.state.formData);
    }

    adminView() {
        var data = this.state.formData;
        data['type'] = "admin";
        this.setState({ formData: data, filter: false });
        this.props.educatorListAction('', this.state.formData);
    }

    render() {

        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
        };

        const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
            return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
        };

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.clinicView &&
                                <ClinicHeader componentData={{ clinicId: this.state.clinicView }} />
                            }
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6} className="m-bb-s">
                                                <ButtonToolbar>
                                                    <Button className="inacti-button" fill pullRight onClick={(e) => { this.addEduMessage(e) }} >Add Educator Message</Button>
                                                    {!this.state.clinicView &&
                                                        <ButtonToolbar>
                                                            <Button className={this.state.formData.type == 'admin' ? 'acti-button' : "inacti-button"} fill onClick={(e) => { this.adminView(e) }} >Added By Admin</Button>
                                                            <Button className={this.state.formData.type == 'all' ? 'acti-button' : "inacti-button"} fill onClick={(e) => { this.allView(e) }} >View All</Button>
                                                        </ButtonToolbar>
                                                    }
                                                </ButtonToolbar>
                                            </Col>
                                            {/*  <Col md={6}>
                                              <Button className="go-back-btn" bsStyle="info" onClick={(e)=> {this.goBackFun(e)} }>Back</Button>
                                          </Col> */}
                                        </Row>&nbsp;

                                        {this.state.filter &&
                                            <div>

                                                <Row>
                                                    <Col md={4}>
                                                        <Col componentClass={ControlLabel}>
                                                            Clinic
                                                        </Col>
                                                        <FormControl componentClass="select" name="clinicName" onChange={e => { this.handleClinic(e); }}>
                                                            <option value="">Select Clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}({item.clinicId})</option>
                                                            })}
                                                        </FormControl>
                                                    </Col>

                                                    {/*<Col md={4}>
                                                        <Col componentClass={ControlLabel}>
                                                            Specialization
                                                       </Col>
                                                        <FormControl componentClass="select" name="specialization" onChange={e => { this.handleSpecialization(e); }}>
                                                            <option value="">Select Specialization</option>
                                                            {this.state.specializationList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}</option>
                                                            })}
                                                        </FormControl>
                                                    </Col> */}
                                                    <Col md={2}>
                                                        <Button bsStyle="info searchBtn" onClick={e => { this.onSearch(e); }} >Search</Button>
                                                    </Col>
                                                </Row> &nbsp;
                                            </div>
                                        }
                                        <br />
                                        <div className="table-resposive educator-L">
                                            <div className='search-box-admin'>
                                                <div className='search'>
                                                    <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                    <span className='input-group-btn'>
                                                    <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button> 
                                                    </span>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                selectRow="{selectRowProp}"
                                                deleteRow={false}
                                                data={this.state.educatorList}
                                                multiColumnSearch={true}
                                                pagination={true}
                                                options={options}
                                                striped
                                                hover
                                                condensed
                                                scrollTop={'Bottom'}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '4%', textAlign: 'center' }} tdStyle={{ width: '4%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%', textAlign: 'center' }} tdStyle={{ width: '15%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Id' }} dataField='' dataFormat={this.onClinicId} >Clinic Id
                                                {
                                                    this.state.asc_desc?
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                                                    <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                                                }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn tdAttr={{ "data-attr": "title" }} thStyle={{ width: "15%", textAlign: "center" }} tdStyle={{ width: "15%", textAlign: "center" }} dataField="createdAt" dataFormat={getDrugCreationDate}  > Date</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'doctorName' }} dataField='doctorName' dataFormat={this.onClinicDoctorName} >Doctor Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataField='clinicName' dataFormat={this.onClinicName} >Clinic Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Message' }} dataField='message' >Message</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Description' }} dataField='description' >Description</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '9%', textAlign: 'center' }} tdStyle={{ width: '9%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status'  >Status</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} >Action</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        EducatorList: state.educator.EducatorList,
        isEducatorList: state.educator.isEducatorList,
        isEducatorListError: state.educator.isEducatorListError,

        EducatorChangeStatus: state.educator.EducatorChangeStatus,
        isEducatorChangeStatus: state.educator.isEducatorChangeStatus,
        isEducatorChangeStatusError: state.educator.isEducatorChangeStatusError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        SpecializationList: state.specilization.SpecializationList,
        isSpecializationList: state.specilization.isSpecializationList,
        isSpecializationListError: state.specilization.isSpecializationListError,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError
    }
}
export default withRouter(connect(mapStateToProps, { educatorListAction, educatorchangestatusAction, clinicListAction, specializationListAction, ClinicSimpleListAction })(EducatorList));

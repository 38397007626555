/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useRef } from "react";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { selectDrugTypeOptions } from "../../../../variables/Variables";
import { Row, Col, Modal, Form, FormGroup, FormControl } from "react-bootstrap";
import * as API from "Front/api/emr";
import { ACTIONS } from "./Prescription";
import InstructionList from "./InstructionList";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { appConstants } from "Front/_constants/app.constants.js";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

const AddDrug = ({
  ModalStatusAddDrug,
  SetModalAddDrug,
  patientId,
  drugCategoryList,
  drugCompanyList,
  dispatch,
  drugInstruction,
  appointmentId,
  drugs,
  setDrugAlreadyAdded,
}) => {
  const [isDrugExist, setIsDrugExist] = useState(false);
  const [formData, setFormData] = useState({});
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalStatus, setModalStatus] = useState(false);
  const [drugInstructionList, setDrugInstructionList] = useState([]);
  const [seletedDrugId, setSeletedDrugId] = useState("");
  const [alertMsg, setAlertMsg] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [drugId, setDrugId] = useState("");
  const [searchLength, setSearchLength] = useState(false);
  const [saveButtonText, setSaveButtonTxt] = useState("Add");
  const notificationSystem = useRef(null);
  const CancelToken = axios.CancelToken;
  let cancel;
  let drugSearchData = "";

  const showAddDrug = () => {
    SetModalAddDrug(true);
    setDrugId("");
    setFormData({});
    dispatch({ type: ACTIONS.ADD_DRUG_INSTRUCTION, payload: [] });
    setIsDisabled(false);
    setDrugInstructionList([drugInstruction]);
  };

  const inputHandler = (e) => {
    let saveFormData = formData;
    saveFormData[e.target.name] =
      e.target.name === "noOfDoseSelector"
        ? Number(e.target.value)
        : e.target.value;

    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      let saveFormData = formData;
      saveFormData["instructionName"] = drugInstructionList;
      saveFormData["drugId"] = drugId;
      setFormData({ ...formData, ...saveFormData });
      try {
        let resp = "";
        resp = await API.addDrug(formData);
        if (resp.data.status === "Success" && resp.data.statusCode === 200) {
          saveButtonText === "Add"
            ? successAlert("Drug Added Succeessfully")
            : successAlert("Drug Updated Succeessfully");
          addDrug(resp);
          closeModal();
        }
      } catch (err) {
        setIsDrugExist(true);
      }
    }
  };

  const drugValidate = (drugId) => {
    setDrugAlreadyAdded(false);
    let flag = false;
    let tempDrugs = drugs;
    tempDrugs.map((drug) => {
      if (drug.drugId === drugId) {
        flag = true;
      }
    });
    return flag;
  };

  const addDrug = async (res) => {
    let tempArr = [];
    tempArr = drugInstructionList;
    if (!drugValidate(res.data.drugData._id)) {
      let resp = await API.drugInstruction({
        patientId: patientId,
        drugId: res.data.drugData._id,
        purposeOfFetchingDrugInstruction: "patient",
      });
      if (resp.data && resp.data.status === "Success") {
        tempArr = [];
        let instruction = "";
        resp.data.drugInstructionData.instructionName.map((item, idx) => {
          instruction = {
            idx: idx,
            instructionName: item.instructionName,
            instructionType: item.instructionType,
            instructionId: item.instructionId,
          };
          tempArr.push(instruction);
        });
        let payloadData = {
          drug: {
            isUpdated: true,
            patientId: patientId,
            appointmentId: appointmentId,
            drugNameWithDose: resp.data.drugInstructionData.drugNameWithDose,
            instructionName: tempArr,
            doseTimeData: { doseTimeSlot: [] },
            selectedFrequency: "",
            duration: { no: "", period: "" },
            noOfDoseSelector: resp.data.drugInstructionData.noOfDoseSelector,
            valueTypeOfDoseSelector:
              resp.data.drugInstructionData.valueTypeOfDoseSelector,
            drugType: resp.data.drugInstructionData.drugType,
            drugNameWithIngredients:
              resp.data.drugInstructionData.drugNameWithIngredients,
            drugId: resp.data.drugInstructionData._id,
            isDeleted: false,
          },
        };
        dispatch({ type: ACTIONS.ADD_DRUG, payload: payloadData });
      }
    } else {
      let payloadData = {
        instructions: tempArr,
        drugId: res.data.drugData._id,
        drugNameWithDose: res.data.drugData.drugNameWithDose,
        drugType: res.data.drugData.drugType
      };
      dispatch({ type: ACTIONS.ADD_NEW_DRUG_INSTRUCTION, payload: payloadData });
    }
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.drugNameWithDose && post.drugNameWithDose != "") {
      errArr.drugNameWithDose = "";
    } else {
      errArr.drugNameWithDose = "error";
      errflag = false;
    }

    if (post.drugType && post.drugType != "") {
      errArr.drugType = "";
    } else {
      errArr.drugType = "error";
      errflag = false;
    }

    if (post.drugCategoryId && post.drugCategoryId != "") {
      errArr.drugCategoryId = "";
    } else {
      errArr.drugCategoryId = "error";
      errflag = false;
    }

    if (post.noOfDoseSelector && post.noOfDoseSelector != "") {
      errArr.noOfDoseSelector = "";
    } else {
      errArr.noOfDoseSelector = "error";
      errflag = false;
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const drugSearch = async (e) => {
    setIsDrugExist(false);
    setIsSpecialCharacter(false);
    setIsSearchBoxOpen(true);
    let search = "";
    if (e.target.value.length >= 100) {
      setSearchLength(true);
      return;
    } else {
      setSearchLength(false);
      search = e.target.value.replace(/\s+/g, " ");
      drugSearchData = search;
      document.getElementById("txtsearchdrug").value =
        drugSearchData.trimStart();
    }
    let sessionToken = localStorage.getItem("token");
    let flag = "brand";
    if (search !== "") {
      // if (!/[^a-zA-Z0-9  .]/.test(search)) {

      if (cancel !== undefined) {
        cancel();
      }

      let strUrl = appConstants.paAppURL;
      strUrl += "drugSearch";

      axios
        .post(
          strUrl,
          { search: search, flag: flag },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
            headers: {
              Authorization: sessionToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          autocomplete(
            document.getElementById("txtsearchdrug"),
            response.data.drugData
          );
          if (response.data.drugData.length === 0) {
            clearAll();
            setDrugInstructionList([]);
            setIsDisabled(false);
            let saveFormData = formData;
            saveFormData["drugNameWithDose"] = drugSearchData;
            setFormData({ ...formData, ...saveFormData });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {

          }
        });

      // } else {
      //   setIsSpecialCharacter(true)
      // }
    } else {
      dispatch({ type: ACTIONS.ADD_DRUG_INSTRUCTION, payload: [] });
      closeLists();
    }
  };

  const clearAll = () => {
    let saveFormData = formData;
    saveFormData["drugNameWithDose"] = "";
    saveFormData["drugType"] = "";
    saveFormData["companyName"] = "";
    saveFormData["drugNameWithIngredients"] = "";
    saveFormData["drugCategoryId"] = "";
    saveFormData["companyId"] = "";
    saveFormData["noOfDoseSelector"] = "";
    setFormData({ ...formData, ...saveFormData });
  };

  function closeLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-drug-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  function closeSeachBox() {
    if (isSearchBoxOpen) {
      closeLists();
      clearAll();
      setDrugInstructionList([]);
      setIsDisabled(false);
      let saveFormData = formData;
      saveFormData["drugNameWithDose"] =
        document.getElementById("txtsearchdrug")?.value !== ""
          ? document.getElementById("txtsearchdrug")?.value
          : "";
      setFormData({ ...formData, ...saveFormData });
      setIsSearchBoxOpen(false);
    }
  }

  function autocomplete(inp, arr) {
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-drug-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    var currentFocus;

    var a,
      b,
      i,
      val = inp.value;

    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");

    a.setAttribute("id", inp.id + "autocomplete-drug-list");
    a.setAttribute("class", "autocomplete-drug-items autocompletedropdownmenu");
    inp.parentNode.appendChild(a);

    for (i = 0; i < arr.length; i++) {
      b = document.createElement("DIV");
      b.setAttribute("class", "");
      b.innerHTML += `${arr[i].drugNameWithDose} (${arr[i].drugType})`;
      b.innerHTML +=
        "<input type='hidden' drugType='" +
        arr[i].drugType +
        "' drugId='" +
        arr[i]._id +
        "' value='" +
        arr[i].drugNameWithDose +
        "'>";
      b.addEventListener("click", function (e) {
        inp.value = this.getElementsByTagName("input")[0].value;
        let drugId =
          this.getElementsByTagName("input")[0].getAttribute("drugId");
        getDrugInstructions(
          this.getElementsByTagName("input")[0].getAttribute("drugId")
        );

        closeAllLists();
      });
      a.appendChild(b);
    }
  }

  const successAlert = (msg) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
    // setAlertMsg(<SweetAlert success style={
    //   { display: "block", marginTop: "-100px" }}
    //   title="Success"
    //   onConfirm={

    //     () => {
    //       setAlertMsg(null)
    //     }
    //   }

    //   confirmBtnBsStyle="info"

    // >
    //   {msg} </SweetAlert>
    // );

    setTimeout(function () {
      setAlertMsg(null);
    }, 3000);
  };

  const getDrugInstructions = async (drugId) => {
    let ele = document.getElementById("btnAddOwnDrug");
    if (ele !== null) ele.style.display = "block";
    setSaveButtonTxt("Update");
    let resp = await API.drugInstruction({
      patientId: patientId,
      drugId: drugId,
      purposeOfFetchingDrugInstruction: "clinic",
    });
    if (resp.data && resp.data.status === "Success") {
      let saveFormData = formData;
      saveFormData["drugNameWithDose"] =
        resp.data.drugInstructionData.drugNameWithDose;
      saveFormData["drugType"] = resp.data.drugInstructionData.drugType;
      saveFormData["companyName"] = resp.data.drugInstructionData.companyName;
      saveFormData["drugNameWithIngredients"] =
        resp.data.drugInstructionData.drugNameWithIngredients;
      saveFormData["drugCategoryId"] =
        resp.data.drugInstructionData.drugCategoryId;
      saveFormData["companyId"] = resp.data.drugInstructionData.companyId;
      saveFormData["instructionName"] =
        resp.data.drugInstructionData.instructionName;
      saveFormData["noOfDoseSelector"] =
        resp.data.drugInstructionData.noOfDoseSelector;
      if (resp.data.drugInstructionData.type === "master") {
        setIsDisabled(true);
        // let ele1=document.getElementById("selNoOfDoseSelector")
        // if(ele1!==null)
        //   ele1.disabled=true
      }

      setDrugInstructionList(resp.data.drugInstructionData.instructionName);
      setDrugId(drugId);
      // dispatch({type:ACTIONS.ADD_DRUG_INSTRUCTION,payload:resp.data.drugInstructionData.instructionName})
      let ele = document.getElementById("txtsearchdrug");
      if (ele !== null) ele.value = "";

      setFormData({ ...formData, ...saveFormData });
      validate();
      setSeletedDrugId(drugId);
    }
  };

  const addOwnDrug = () => {
    setIsDisabled(false);
    setIsDrugExist(false);
    setSaveButtonTxt("Add");
    setDrugId("");
    let ele = document.getElementById("txtsearchdrug");
    if (ele !== null) ele.value = "";
    setDrugInstructionList([]);
    clearAll();
  };

  const closeModal = () => {
    setErrors({});
    setSaveButtonTxt("Add");
    setIsDrugExist(false);
    SetModalAddDrug(false);
    setIsSpecialCharacter(false);
    setSearchLength(false);
  };

  const showInstructionList = (e) => {
    setModalStatus(true);
  };

  return (
    <span>
      <div>
        {" "}
        <span>
          {" "}
          {alertMsg}
          <NotificationSystem ref={notificationSystem} style={style} />
        </span>
      </div>
      <Button
        type="button"
        id="btnSaveDrug"
        onClick={(e) => showAddDrug()}
        className="btn-link"
      >
        Add/Edit Drug List
      </Button>
      <Modal
        show={ModalStatusAddDrug}
        onHide={closeModal}
        dialogClassName="modal-md"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {saveButtonText === "Add" ? "Add New Drug" : "Update Drug"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-new-drug">
          <Form noValidate className="add-new-drug-form">
            <div class="form-group add-drug-search-wrapper autoComplete">
              {isSpecialCharacter && (
                <div style={{ color: "red" }}>
                  <p>Special Characters are not allowed.</p>
                </div>
              )}
              {searchLength && (
                <div style={{ color: "red" }}>
                  <p>Maximum 100 Characters are allowed.</p>
                </div>
              )}
              <FormGroup>
                <FormControl
                  type="text"
                  name="drugNameWithDose"
                  maxlength="100"
                  autocomplete="off"
                  onChange={(e) => {
                    drugSearch(e);
                  }}
                  id="txtsearchdrug"
                  placeholder="Search Medicine"
                  className="form-control "
                />
              </FormGroup>

              {/* <input type="text" class="form-control " onChange={(e) => { drugSearch(e) }} maxlength="5"  autocomplete="off" id="txtsearchdrug" placeholder="Search Medicine" /> */}
              {/* <div style={{ 'color': 'red' }}><p>No records found.</p></div> */}
            </div>

            {isDrugExist && (
              <div style={{ color: "red" }}>
                <p>This drug already exist.</p>
              </div>
            )}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    disabled={isDisabled}
                    name="drugNameWithDose"
                    autocomplete="off"
                    value={
                      formData.drugNameWithDose ? formData.drugNameWithDose : ""
                    }
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    placeholder="Name *"
                    className={
                      "insulin-input " +
                      (errors.drugNameWithDose ? errors.drugNameWithDose : "")
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <select
                    name="drugCategoryId"
                    disabled={isDisabled}
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    value={formData.drugCategoryId}
                    className={
                      "insulin-input form-control " +
                      (errors.drugCategoryId ? errors.drugCategoryId : "")
                    }
                  >
                    <option value="">Select Category *</option>
                    {drugCategoryList.map(function (item) {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                  </select>
                </FormGroup>
                <FormGroup>
                  <select
                    name="drugType"
                    disabled={isDisabled}
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    value={formData.drugType}
                    className={
                      "insulin-input form-control " +
                      (errors.drugType ? errors.drugType : "")
                    }
                  >
                    <option value="">Select Type *</option>
                    {selectDrugTypeOptions.map(function (item) {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormControl
                    type="text"
                    disabled={isDisabled}
                    autocomplete="off"
                    name="drugNameWithIngredients"
                    value={
                      formData.drugNameWithIngredients
                        ? formData.drugNameWithIngredients
                        : ""
                    }
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    placeholder="Ingredients with dose"
                    className={
                      "insulin-input " +
                      (errors.drugNameWithIngredients
                        ? errors.drugNameWithIngredients
                        : "")
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <select
                    className="form-control"
                    name="companyId"
                    disabled={isDisabled}
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    value={formData.companyId}
                  >
                    <option value="">Select Company</option>
                    {drugCompanyList.map(function (item) {
                      return (
                        <option value={item._id}>{item.companyName}</option>
                      );
                    })}
                  </select>
                  <span className="errorMsg">
                    {errors.companyId ? errors.companyId : ""}
                  </span>
                </FormGroup>
                <FormGroup>
                  <select
                    name="noOfDoseSelector"
                    disabled={isDisabled}
                    id="selNoOfDoseSelector"
                    onChange={(e) => {
                      inputHandler(e);
                    }}
                    value={formData.noOfDoseSelector}
                    className={
                      "insulin-input form-control " +
                      (errors.noOfDoseSelector ? errors.noOfDoseSelector : "")
                    }
                  >
                    <option selected value="">
                      Select No Of Rows *
                    </option>
                    <option value="1">1 Preferred For Tablet</option>
                    <option value="2">2 Preferred For Syrup</option>
                    <option value="3">3 Preferred For Insulin/GLP-1</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <div>
              <h5>Instructions</h5>
              <ul
                style={{
                  listStyle: "none",
                  padding: "15px",
                  backgroundColor: "#f5f5f5",
                  borderRadius: "4px",
                }}
              >
                {drugInstructionList.map(function (item) {
                  return (
                    <li style={{ marginBottom: "5px" }}>
                      {item.instructionName &&
                        item.instructionName.substring(0, 100)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <InstructionList
            setModal={setModalStatus}
            ModalStatus={modalStatus}
            drugInstruction={drugInstructionList}
            patientId={patientId}
            drugId={seletedDrugId}
            dispatch={dispatch}
            parentComponent={"AddDrug"}
            setDrugInstructionList={setDrugInstructionList}
          />
          <Button
            id="btnAddOwnDrug"
            style={{ float: "left", marginleft: "3px", display: "none" }}
            onClick={(e) => addOwnDrug(e)}
            className="btn"
          >
            Add Own Drug
          </Button>
          <Button className="btn" onClick={(e) => showInstructionList(e)}>
            Add Instruction
          </Button>
          <Button
            onClick={(e) => submitHandler(e)}
            className="btn btn-fill btn-primary"
          >
            {saveButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default AddDrug;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { addUserAction, uploadFileAction } from "Admin/actions/user";
import { clinicListAction } from "Admin/actions/clinic";
import { specializationListAction } from "Admin/actions/specialization";
import { titleOptions } from "../../../Front/variables/Variables.jsx";
//import Select from 'react-select';
import "react-select/dist/react-select.css";
import loadingImg from "Admin/assets/img/loading.gif";
import ClinicHeader from "../ClinicHeader/ClinicHeader";
import { appConstants } from "Admin/_constants/app.constants.js";

let isUserAdded = false;

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  title: "required",
  user_name: "required",
  email: "required|email",
  password: "required",
  confirm_password: "required",
  group: "required",
  clinic_name: "required",
  status: "required",
  first_name: "required|string",
  last_name: "required|string",
  phone: "required|numeric",
};
let mess = {
  required: "This field is required",
  // email: 'Invalid Email',
  numeric: "Enter numeric digits",
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class AddUser extends Component {
  constructor(props) {
    super(props);

    var dropdownDisable = false;
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.clinicDetail
    ) {
      dropdownDisable = true;
    }
    this.vForm = this.refs.vForm;
    this.state = {
      clinicList: [],
      isEditing: false,
      isLoading: false,
      passwordError: null,
      confirm_passwordError: null,
      phoneError: null,
      specializations: null,
      formArr: [],
      showProcessing: false,
      imagePreviewUrl: "",
      formData: {
        file: "",
        title: "",
        user_name: "",
        email: "",
        password: "",
        confirm_password: "",
        group: "",
        clinic: [],
        status: "active",
        first_name: "",
        last_name: "",
        phone: "",
        specializations: "",
        degree: "",
        clinic_name: "",
        clinic_id: "",
        clinicEmail: "",
      },
      limitData: {
        direction: "desc",
        order: "createdAt",
        searchText: "",
      },
      tempFile: "",
      clinicId:
        this.props &&
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.clinic_id
          ? this.props.location.state.clinic_id
          : "",
      clinic: "",
      limitStatus: true,
      dropdownDisable: dropdownDisable,
      userNameAlreadyExist: false

    };

    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.clinicDetail
    ) {
      let formData = this.state.formData;
      formData["clinicEmail"] = this.props.location.state.clinicDetail.email;
      this.setState({ formData });
    }

    // this.setState({clinic: this.props && this.props.location && this.props.location.state && this.props.location.state.clinic_id})
    //
    // if(typeof this.props.location.state !== 'undefined'){
    //   this.setState({
    //       //clinic: this.props.location.state._id,
    //     //  clinic: this.props && this.props.location && this.props.location.state && this.props.location.state.clinic_id,
    //       clinic_name: this.props.location.state.name,
    //       value: this.props.location.state._id
    //   });
    // }
    this.props.clinicListAction(this.state);
    let limitData = this.state.limitData;
    limitData["limitStatus"] = true;
    this.props.specializationListAction(this.state.limitData);
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isAddUserError !== this.props.isAddUserError) {
      this.setState({ showProcessing: false });
      if (nextProps.addUserMessage.errors) {
        nextProps.addUserMessage.errors.map((key, i) => {
          this.setState({ [key.param + "Error"]: key.msg });
        });
      }
    }

    if (
      nextProps.isAddUser !== this.props.isAddUser &&
      nextProps.AddUserData.status === "Success" &&
      this.state.formData.clinic_name !== ""
    ) {
      if (nextProps.AddUserData.msg === "Username is already used") {

        let _this = this;
        this.setState({ showProcessing: false, user_nameError: nextProps.AddUserData.msg });
        if (isUserAdded)
          isUserAdded = false;
      } else {
        let _this = this;
        setTimeout(function () {
          if (isUserAdded) {
            _this.props.handleClick("success", nextProps.AddUserData.msg);
            isUserAdded = false;
          }

          if (_this.state.clinicId === "") {
            _this.props.history.push(`/admin/user-list`);
          } else {
            _this.props.history.push(
              `/admin/user-list/` + _this.state.clinicId,
              _this.props.location.state.clinicDetail
            );
          }
        }, 800);
      }
    }

    if (nextProps.isUploadFile !== this.props.isUploadFile) {
      const _this = this;
      let field = this.state.formData;
      field["file"] = nextProps.uploadFile.file_name;
      this.setState({ formData: field });
    }

    if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
      if (nextProps.uploadFile.errors) {
        let uploaded_file = this.state.uploaded_file;
        nextProps.uploadFile.errors.map((key, i) => {
          this.setState({ [uploaded_file + "Error"]: key.msg });
        });
      }
    }

    if (nextProps.isClinicList !== this.props.isClinicList) {
      this.setState({
        clinicList: nextProps.ClinicList.data.data,
      });

      let formData = this.state.formData;
      let _this = this;

      if (
        nextProps.ClinicList.data.data &&
        nextProps.ClinicList.data.data.length > 0
      ) {
        nextProps.ClinicList.data.data.map(function (item) {
          if (item._id === _this.state.clinicId) {
            formData["clinic_id"] = item._id;
            formData["clinic_name"] = item.name;
            formData["clinic"] = item.name;

            if (item.userCount == 0) {
              let dcName = item.doctorName;
              dcName = dcName.split(" ");
              formData["email"] = item.email;
              formData["first_name"] = dcName[0]
                ? dcName[0] + (dcName.length > 2 ? " " + dcName[1] : "")
                : "";
              formData["last_name"] = dcName[dcName.length - 1]
                ? dcName[dcName.length - 1]
                : "";
              formData["phone"] = item.phone
                ? item.phone
                : item.mobile
                  ? item.mobile
                  : "";
              formData["specializations_add"] = item.specializations_add;
              formData["degree"] = item.degree;
              //formData['user_name'] = item.doctorName;
              formData["group"] = "Doctor";
            } else {
              formData["email"] = "";
              formData["first_name"] = "";
              formData["phone"] = "";
              formData["specializations_add"] = "";
              //formData['user_name'] = '';
              formData["group"] = "";
              formData["degree"] = "";
              formData["last_name"] = "";
            }
          }
        });
      }

      _this.setState({ formData: formData, clinic: formData.clinic_id });
    }

    if (
      nextProps.isSpecializationList &&
      nextProps.isSpecializationList !== this.props.isSpecializationList
    ) {
      if (
        nextProps.SpecializationList &&
        nextProps.SpecializationList.data &&
        nextProps.SpecializationList.data.data &&
        nextProps.SpecializationList.data.data.length > 0
      ) {
        this.state.specializationList =
          nextProps.SpecializationList.data.data.map((key, i) => {
            return { value: key._id, label: key.name };
          });
      }
    }
  }

  fileChangedHandler(event, elename) {
    let file = event.target.files[0];

    let formData = this.state.formData;
    formData["photo"] = file.name;
    this.setState({ tempFile: file, formData: formData });

    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ imagePreviewUrl: reader.result });
    };

    if (file) {
      this.props.uploadFileAction(file);
      reader.readAsDataURL(file);
    }
  }

  handleChange(e) {
    e.preventDefault();

    let field = this.state.formData;

    if (e.target.name === "password") {
      if (
        e.target.value &&
        !e.target.value.match(appConstants.PswValidCharecters)
      ) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else if (e.target.value && e.target.value.length < 6) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else if (e.target.value && e.target.value.length > 12) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else {
        this.setState({ passwordError: null });
      }
    }

    if (e.target.name === "confirm_password") {
      if (e.target.value !== field.password) {
        this.setState({
          confirm_passwordError: (
            <small className="text-danger">Passwords do not match.</small>
          ),
        });
      } else {
        this.setState({ confirm_passwordError: null });
      }
    }

    if (e.target.name === "phone") {
      if (e.target.value && e.target.value.length < 10) {
        this.setState({
          phoneError: (
            <small className="text-danger">
              Phone number has to be 10-12 digits.
            </small>
          ),
        });
      } else {
        this.setState({ phoneError: null });
      }
    }

    if (e.target.name === "user_name") {
      this.setState({ user_nameError: null })
    }
    field[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      field[e.target.name] = e.target.value ? e.target.value.trim() : "";
    }

    this.setState({ formData: field });
  }

  handleSpecialization(field) {
    let entry = [];
    let data = this.state.formData;
    field.map((key, index) =>
      entry.push({
        id: key.value,
        name: key.label,
      })
    );
    data["specializations"] = entry;
    this.setState({ formData: data });
  }

  handleClinic(e) {
    let field = this.state.formData;
    let index = e.target.selectedIndex;
    field["clinicEmail"] =
      this.state.clinicList[index] && this.state.clinicList[index].email
        ? this.state.clinicList[index].email
        : "";
    field["clinic_id"] = e.target.value;
    field["clinic_name"] = e.target[index].text;
    field["clinic"] = e.target[index].text;
    this.setState({ formData: field, clinic: e.target.value });
  }

  addUserHandler(evt) {
    this.setState({ isLoading: true });
    const _this = this;
    if (this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      if (!isUserAdded) {

        isUserAdded = true;
        _this.props.addUserAction(this.state.formData);
      }
    }
    //validation.errors()
    setTimeout(() => {
      // Once the action is complete, enable the button again
      this.setState({ isButtonDisabled: false });
      this.setState({ isLoading: false });
    }, 100);
    this.props.onClickAction()
      .then(() => {
        // After the action is completed, reset the loading state to false
        this.setState({ isLoading: false });
      })
      .catch(() => {
        // Handle any errors and reset loading state
        this.setState({ isLoading: false });
      });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  render() {
    const { passwordError, confirm_passwordError, phoneError, formData } =
      this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    const { isLoading } = this.state;

    // this.state.clinic = formData.clinic_id

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              {this.state.dropdownDisable && (
                <ClinicHeader
                  componentData={{
                    clinicId: this.props.location.state.clinicDetail._id,
                  }}
                />
              )}
              <Form horizontal>
                <div
                  className="actionProcess"
                  style={{
                    display: this.state.showProcessing ? "block" : "none",
                  }}
                >
                  <img src={loadingImg} alt="Loading" width="40px" />
                  <center>Logging In - Please Wait</center>
                </div>
                <Card
                  title={
                    <legend>
                      Account Information
                      {!this.state.dropdownDisable && (
                        <Button
                          className="go-back-btn-2"
                          bsStyle="info"
                          onClick={(e) => {
                            this.goBackFun(e);
                          }}
                        >
                          Back
                        </Button>
                      )}
                    </legend>
                  }
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>
                            Username: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="user_name"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.user_name}
                          />
                          <span className="errorMsg">
                            {this.state.user_nameError}
                            {this.state.formArr.user_name &&
                              validation.errors.first("user_name")}
                          </span>
                        </Col>

                        <Col sm={3}>
                          <ControlLabel>
                            Password: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="password"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.passwordError}
                            {this.state.formArr.password &&
                              validation.errors.first("password")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Confirm Password: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="confirm_password"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.confirm_passwordError}
                            {this.state.formArr.confirm_password &&
                              validation.errors.first("confirm_password")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Email Address: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="email"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.email}
                          />
                          <span className="errorMsg">
                            {this.state.emailError}
                            {this.state.formArr.email &&
                              validation.errors.first("email")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>
                            Group: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            name="group"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.group}
                          >
                            <option value="">Select Group</option>
                            <option value="Educator">Educator</option>
                            <option value="Doctor">Doctor</option>
                            <option value="Receptionist">Receptionist</option>
                          </select>
                          <span className="errorMsg">
                            {this.state.groupError}
                            {this.state.formArr.group &&
                              validation.errors.first("group")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Clinic:<span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            disabled={this.state.dropdownDisable}
                            name="clinic_name"
                            value={this.state.clinic}
                            onChange={(e) => {
                              this.handleClinic(e);
                            }}
                          >
                            <option value="">Select Clinic</option>
                            {this.state.clinicList.map(function (item) {
                              return (
                                <option
                                  key={item._id}
                                  name={item.name}
                                  dataset={item.email}
                                  value={item._id}
                                >
                                  {item.name}({item.clinicId})
                                </option>
                              );
                            })}
                          </select>
                          <span className="errorMsg">
                            {this.state.clinicError}
                            {this.state.formArr.clinic_name &&
                              validation.errors.first("clinic_name")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Status: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.status}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className="errorMsg">
                            {this.state.statusError}
                            {this.state.formArr.status &&
                              validation.errors.first("status")}
                          </span>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />

                <Card
                  title={<legend>Personal Information</legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>Profile Photo:</ControlLabel>

                          {this.state.imagePreviewUrl && (
                            <img
                              src={this.state.imagePreviewUrl}
                              width="100px"
                              height="100px"
                              style={{ margin: "5px" }}
                            />
                          )}
                          <FormControl
                            type="file"
                            name="file"
                            onChange={(e) => {
                              this.fileChangedHandler(e);
                            }}
                          />

                          {/*<input type="file" name="file" onChange={e => { this.fileChangedHandler(e) }}></input> */}
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Title: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            value={formData.title}
                            className="form-control"
                            name="title"
                            onChange={(event) => this.handleChange(event)}
                          >
                            <option value="">Select Title</option>
                            {titleOptions.map(function (item) {
                              return (
                                <option key={item.value} value={item.label}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <span className="errorMsg">
                            {this.state.titleError}
                            {this.state.formArr.title &&
                              validation.errors.first("title")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            First Name: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="first_name"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.first_name}
                          />
                          <small>
                            Note:-Do not write any title in the first name,
                            please select it from the title drop down.
                          </small>
                          <span className="errorMsg">
                            {this.state.first_nameError}
                            {this.state.formArr.first_name &&
                              validation.errors.first("first_name")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Last Name: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="last_name"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.last_name}
                          />
                          <span className="errorMsg">
                            {this.state.last_nameError}
                            {this.state.formArr.last_name &&
                              validation.errors.first("last_name")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}></Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Phone: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="phone"
                            autoComplete="off"
                            maxLength={12}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.phone}
                          />
                          <span className="errorMsg">
                            {this.state.phoneError}
                            {this.state.formArr.phone &&
                              validation.errors.first("phone")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>Specialization:</ControlLabel>
                          <FormControl
                            type="text"
                            name="specializations_add"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.specializations_add}
                          />
                          <span className="errorMsg">
                            {this.state.formArr.specializations_add &&
                              validation.errors.first("specializations_add")}
                          </span>
                        </Col>
                        {/*<Col sm={3}>
                          <label className="control-label">specializations</label>
                          <Select
                            placeholder="Select specializations"
                            name="specializations"
                            id="specializations"
                            closeOnSelect={false}
                            multi={true}
                            value={this.state.specializations}
                            options={this.state.specializationList}
                            onChange={(value) => { this.setState({ specializations: value }); this.handleSpecialization(value) }}
                          />
                        </Col> */}
                        <Col sm={3}>
                          <ControlLabel>Degree:</ControlLabel>
                          <FormControl
                            type="text"
                            name="degree"
                            autoComplete="off"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.degree}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  }
                  ftTextCenter
                  legend={
                    <div>
                      <Button
                        fill
                        bsStyle="info"
                        type="button"
                        onClick={this.addUserHandler.bind(this)}
                        disabled={isLoading || this.props.disabled ||
                          passwordError || confirm_passwordError || phoneError
                        }
                      >
                        {isLoading ? (
                          <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                        ) : (
                          this.props.children
                        )}
                        Submit
                      </Button>
                      &nbsp;
                      {/*<Button fill bsStyle="info" type="button" onClick={() => this.props.history.replace('clinic-list')}>Back to List</Button> */}
                    </div>
                  }
                />
              </Form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addUserMessage: state.user.message,
    isAddUser: state.user.isAddUser,
    AddUserData: state.user.AddUserData,
    isAddUserError: state.user.isAddUserError,

    ClinicList: state.clinic.ClinicList,
    isClinicList: state.clinic.isClinicList,
    isClinicListError: state.clinic.isClinicListError,

    SpecializationList: state.specilization.SpecializationList,
    isSpecializationList: state.specilization.isSpecializationList,
    isSpecializationListError: state.specilization.isSpecializationListError,

    isUploadFile: state.user.isUploadFile,
    isUploadFileError: state.user.isUploadFileError,
    uploadFile: state.user.uploadFile,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    addUserAction,
    clinicListAction,
    specializationListAction,
    uploadFileAction,
  })(AddUser)
);

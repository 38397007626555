/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/clinic_document_upload'


export const ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST = "ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST"
export const ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS = "ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS"
export const ADD_CLINIC_DOCUMENT_UPLOAD_ERROR = "ADD_CLINIC_DOCUMENT_UPLOAD_ERROR"
export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST"
export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS"
export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR"

//-- Add CLINIC_DOCUMENT
export const addClinicDocumentUploadRequest = () => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST
})
export const addClinicDocumentUploadSuccess = (response) => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS,
    payload: {
        response,
    }
})
export const addClinicDocumentUploadError = (error) => ({
    type: actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_ERROR,
    payload: {
        error
    }
})
export const addClinicDocumentUploadAction = (data) => {
    return dispatch => {
        dispatch(addClinicDocumentUploadRequest())
        const FormData = {
            name: data.Name,
            description: data.description,
            status: data.status,
        }
        return API.addClinicDocumentUpload(FormData)
            .then(response => {

                dispatch(addClinicDocumentUploadSuccess(response.data))
            })
            .catch(error => {
                dispatch(addClinicDocumentUploadError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//-- List CLINIC_DOCUMENT
export const clinicDocumentUploadListRequest = () => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST
})
export const clinicDocumentUploadListSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const clinicDocumentUploadListError = (error) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR,
    payload: {
        error
    }
})
export const clinicDocumentUploadListAction = (data) => {

    return dispatch => {
        dispatch(clinicDocumentUploadListRequest())
        // const FormData = {
        //     limit:10,
        //     offset:0,
        //     order:"name",
        //     direction:"asc"
        // }
        // if(data.formData){
        //     FormData.status = 'active';
        // }

        return API.clinicDocumentUploadList(data)
            .then(response => {
                dispatch(clinicDocumentUploadListSuccess(response.data))
            })
            .catch(error => {
                dispatch(clinicDocumentUploadListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Update CLINIC_DOCUMENT and Add form */

export const updateClinicDocumentUploadRequest = () => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_REQUEST
})
export const updateClinicDocumentUploadSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_SUCCESS,
    payload: {
        response,
    }
})
export const updateClinicDocumentUploadError = (error) => ({
    type: actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_ERROR,
    payload: {
        error,
    }
})
export const updateClinicDocumentUploadAction = (data) => {
    return dispatch => {
        dispatch(updateClinicDocumentUploadRequest())
        const FormData = {
            name: data.Name,
            description: data.description,
            status: data.status,
            id: data.id
        }
        return API.updateClinicDocumentUpload(FormData)
            .then(response => {
                dispatch(updateClinicDocumentUploadSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateClinicDocumentUploadError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* CLINIC_DOCUMENT Status Change  */
export const ClinicDocumentUploadChangeStatusRequest = () => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_REQUEST
})
export const ClinicDocumentUploadChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const ClinicDocumentUploadChangeStatusError = (error) => ({
    type: actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const clinicDocumentUploadchangestatusAction = (data) => {
    return dispatch => {
        dispatch(ClinicDocumentUploadChangeStatusRequest())
        const FormData = {
            linkId: data._id,
            clinicShowStatus: data.clinicShowStatus
        }
        return API.changeClinicDocumentUploadStatus(FormData)
            .then(response => {
                dispatch(ClinicDocumentUploadChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(ClinicDocumentUploadChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

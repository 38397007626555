/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/ready_created_meal'
/* Add ReadyCreatedMeal list and Add form */

export const addReadyCreatedMealRequest = () => ({
    type: actionTypes.GET_ADD_READY_CREATED_MEAL_REQUEST
})
export const addReadyCreatedMealSuccess = (response) => ({
    type: actionTypes.GET_ADD_READY_CREATED_MEAL_SUCCESS,
    payload: {
        response,
    }
})
export const addReadyCreatedMealError = (error) => ({
    type: actionTypes.GET_ADD_READY_CREATED_MEAL_ERROR,
    payload: {
        error,
    }
})
export const addReadyCreatedMealAction = (data) => {
    return dispatch => {
        dispatch(addReadyCreatedMealRequest())
        return API.addReadyCreatedMeal(data)
            .then(response => {
                dispatch(addReadyCreatedMealSuccess(response.data))
            })
            .catch(error => {
                dispatch(addReadyCreatedMealError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update ReadyCreatedMeal list and Add form */
export const updateReadyCreatedMealRequest = () => ({
    type: actionTypes.GET_UPDATE_READY_CREATED_MEAL_REQUEST
})
export const updateReadyCreatedMealSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_READY_CREATED_MEAL_SUCCESS,
    payload: {
        response,
    }
})
export const updateReadyCreatedMealError = (error) => ({
    type: actionTypes.GET_UPDATE_READY_CREATED_MEAL_ERROR,
    payload: {
        error,
    }
})
export const updateReadyCreatedMealAction = (data) => {
    return dispatch => {
        dispatch(updateReadyCreatedMealRequest())

        return API.updateReadyCreatedMeal(data)
            .then(response => {
                dispatch(updateReadyCreatedMealSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateReadyCreatedMealError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* ReadyCreatedMeal list  */
export const ReadyCreatedMealListRequest = () => ({
    type: actionTypes.GET_READY_CREATED_MEAL_LIST_REQUEST
})
export const ReadyCreatedMealListSuccess = (response) => ({
    type: actionTypes.GET_READY_CREATED_MEAL_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const ReadyCreatedMealListError = (error) => ({
    type: actionTypes.GET_READY_CREATED_MEAL_LIST_ERROR,
    payload: {
        error
    }
})
export const readyCreatedMealListAction = (data, search) => {
    return dispatch => {
        dispatch(ReadyCreatedMealListRequest());
        // data.status = "";
        return API.readyCreatedMealList(data)
            .then(response => {
                dispatch(ReadyCreatedMealListSuccess(response.data))
            })
            .catch(error => {
                dispatch(ReadyCreatedMealListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* ReadyCreatedMeal Status Change  */
export const ReadyCreatedMealChangeStatusRequest = () => ({
    type: actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_REQUEST
})
export const ReadyCreatedMealChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const ReadyCreatedMealChangeStatusError = (error) => ({
    type: actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const readyCreatedMealchangestatusAction = (data) => {

    return dispatch => {
        dispatch(ReadyCreatedMealChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeReadyCreatedMealStatus(FormData)
            .then(response => {
                dispatch(ReadyCreatedMealChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(ReadyCreatedMealChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Update ReadyCreatedMeal Priority  */
export const updatePriorityRequest = () => ({
    type: actionTypes.GET_UPDATE_PRIORITY_REQUEST
})
export const updatePrioritySuccess = (response) => ({
    type: actionTypes.GET_UPDATE_PRIORITY_SUCCESS,
    payload: {
        response,
    }
})
export const updatePriorityError = (error) => ({
    type: actionTypes.GET_UPDATE_PRIORITY_ERROR,
    payload: {
        error,
    }
})
export const updatePriorityAction = (data) => {
    return dispatch => {
        dispatch(updatePriorityRequest())

        return API.updatePriority(data)
            .then(response => {
                dispatch(updatePrioritySuccess(response.data))
            })
            .catch(error => {
                dispatch(updatePriorityError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const addFoodCartRequest = () => ({
    type: actionTypes.GET_ADD_FOOD_CART_REQUEST
})
export const addFoodCartSuccess = (response) => ({
    type: actionTypes.GET_ADD_FOOD_CART_SUCCESS,
    payload: {
        response,
    }
})
export const addFoodCartError = (error) => ({
    type: actionTypes.GET_ADD_FOOD_CART_ERROR,
    payload: {
        error,
    }
})
export const addFoodCartAction = (data) => {
    return dispatch => {
        dispatch(addFoodCartRequest())
        return API.addFoodCart(data)
            .then(response => {
                dispatch(addFoodCartSuccess(response.data))
            })
            .catch(error => {
                dispatch(addFoodCartError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Update Food card list */
export const updateFoodCartRequest = () => ({
    type: actionTypes.GET_UPDATE_FOOD_CART_REQUEST
})
export const updateFoodCartSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_FOOD_CART_SUCCESS,
    payload: {
        response,
    }
})
export const updateFoodCartError = (error) => ({
    type: actionTypes.GET_UPDATE_FOOD_CART_ERROR,
    payload: {
        error,
    }
})
export const updateFoodCartAction = (data) => {
    return dispatch => {
        dispatch(updateFoodCartRequest())

        return API.updateFoodCart(data)
            .then(response => {
                dispatch(updateFoodCartSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateFoodCartError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState } from "react";
import CloseIc from "../../assets/img/icons/close-ic.svg";
import AdminNotificationApi from "../../api/adminNotificationApi";
import moment from "moment";

const AdminNotification = (props) => {
  const [adminNotificationList, setAdminNotificationList] = useState([]);
  useEffect(() => {
    (async () => {
      let response = await AdminNotificationApi.getAdminNotification();
      if (response.statusCode == 200) {
        setAdminNotificationList(response.adminNotificationList);
      }
      await AdminNotificationApi.editAdminNotificationReadStatus();
    })();
  }, []);

  return (
    <div className="side-modal side-modal-wrapper">
      <div className="note-modal admin-notification-modal">
        <div className="side-modal-header">
          <h2>Admin Notifications</h2>
          <div className="side-modal-header-action">
            <span className="close-btn">
              <img
                src={CloseIc}
                onClick={() => props.modal(false)}
                alt="close"
              />
            </span>
          </div>
        </div>
        <div className="side-modal-content">
          <div className="content-items-wrapper">
            {adminNotificationList.length > 0 && (
              <>
                {adminNotificationList.map((data, index) => (
                  <div
                    style={{ listStyle: "none" }}
                    className="content-item"
                    key={index}
                  >
                    <div className="content-item-col">
                      <span className="notification-count">{index + 1}</span>
                      <div className="notification_titl">
                        <h4>{data.adminNotification}</h4>
                        <span className="text-mute">
                          {moment(data.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {adminNotificationList.length == 0 && (
              <div
                style={{
                  color: "black",
                  marginTop: "100px",
                  textAlign: "center",
                }}
              >
                <h6>No data found!</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNotification;

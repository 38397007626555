/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { updateCommentAction } from 'Admin/actions/comments';
import { languageListAction } from 'Admin/actions/language';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    text_1: 'required',
    type: 'required',

};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateComment extends Component {

    constructor(props) {
        super(props);

        this.state = {

            languageList: [],

            cardHidden: true,
            formArr: [],
            text_englishError: null,
            typeError: null,

            formData: {
                id: this.props.location.state.row._id,
                type: this.props.location.state.row.type,
                text_1: this.props.location.state.row.text[1],
                text: this.props.location.state.row.text
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.languageListAction(data);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isLanguageList !== this.props.isLanguageList) {
            this.setState({ languageList: nextProps.LanguageList.data });
        }

        if (nextProps.isUpdateCommentError !== this.props.isUpdateCommentError) {
            if (nextProps.msg.errors) {
                nextProps.msg.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }


        if (nextProps.isUpdateComment !== this.props.isUpdateComment) {
            this.props.handleClick('success', nextProps.UpdateComment.msg)
            this.props.history.push('/admin/comment-list')
        }
    }

    handleChange(e, language) {
        e.preventDefault();
        let field = this.state.formData;
        field.text[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }

    handleChangeType(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }


    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    updateComment(evt) {
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let field = this.state.formData;

            _this.setState({ showProcessing: true });
            _this.props.updateCommentAction(field);
        }
        //validation.errors()
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Name  <span className="star">*</span>
                                            </Col>
                                            <Col sm={6} className="spcc">

                                                {this.state.languageList.map(function (lang) {
                                                    return <><label>{lang.name}</label><FormControl className="spc-in" type="text" name={'text_' + lang.lanId} id={'text_' + lang.lanId} placeholder={lang.name} value={_this.state.formData.text[lang.lanId]} onChange={(event) => { _this.handleChange(event, lang.lanId) }} /></>
                                                })}
                                                <span className="errorMsg">
                                                    {this.state.text_englishError}
                                                    {this.state.formArr.text_1 && validation.errors.first('text_1')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Type <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="type" placeholder="Type" value={_this.state.formData.type} onChange={(event) => this.handleChangeType(event)}>
                                                    <option value="">Select Type</option>
                                                    <option value="diabetic">Diabetic</option>
                                                    <option value="non-diabetic">Non Diabetic</option>

                                                </select>

                                                <span className="errorMsg">
                                                    {this.state.typeError}
                                                    {this.state.formArr.type && validation.errors.first('type')}</span>

                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.updateComment.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.comment.message,

        UpdateComment: state.comment.UpdateComment,
        isUpdateComment: state.comment.isUpdateComment,
        isUpdateCommentError: state.comment.isUpdateCommentError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { updateCommentAction, languageListAction })(UpdateComment));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddClinicDocumentUpload: false,
  isAddClinicDocumentUploadError: false,
  AddClinicDocumentUpload: false,

  isUpdateClinicDocumentUpload: false,
  isUpdateClinicDocumentUploadError: false,
  UpdateClinicDocumentUpload: false,

  isClinicDocumentUploadChangeStatus: false,
  isClinicDocumentUploadChangeStatusError: false,
  ClinicDocumentUploadChangeStatus: false,

  isClinicDocumentUploadList: false,
  isClinicDocumentUploadListError: false,
  ClinicDocumentUploadList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST:
      return {
        ...state,
        message: '',
        isisAddClinicDocumenUpload: false,
        isisAddClinicDocumenUploadError: false,
      }
    case actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        message: 'Data Added Successfully',
        isAddClinicDocumUploadData: action.payload.response,
        isAddClinicDocumenUpload: true,
        isAddClinicDocumenUploadError: false,
      }
    case actionTypes.ADD_CLINIC_DOCUMENT_UPLOAD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddClinicDocumenUpload: false,
        isAddClinicDocumenUploadError: true
      }

    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateClinicDocumentUpload: false,
        isUpdateClinicDocumenUploadError: false,
      }
    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        message: 'Data Updated Successfully',
        isUpdateClinicDocumentUploadData: action.payload.response,
        isUpdateClinicDocumentUpload: true,
        isUpdateClinicDocumenUploadError: false,
      }
    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateSClinicDocumentUpload: false,
        isUpdateClinicDocumenUploadError: true
      }

    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isClinicDocumentUploadList: false,
        isClinicDocumentUploadListError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS:
      return {
        ...state,
        message: 'Clinic Dcument Upload List',
        ClinicDocumentUploadList: action.payload.response,
        isClinicDocumentUploadList: true,
        isClinicDocumentUploadListError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicDocumentUploadList: false,
        isClinicDocumentUploadListError: true
      }

    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isClinicDocumentUploadChangeStatus: false,
        isClinicDocumentUploadChangeStatusError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Clinic Document Upload List',
        ClinicDocumentUploadChangeStatus: action.payload.response,
        isClinicDocumentUploadChangeStatus: true,
        isClinicDocumentUploadChangeStatusError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicDocumentUploadChangeStatus: false,
        isClinicDocumentUploadChangeStatusError: true
      }


    default:
      return state
  }
}

/********* Show list of CLINIC_DOCUMENT */

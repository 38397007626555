/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Modal, Table, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { historyAction } from "Front/actions/home";
import moment from "moment";
import VideoDocumentView from "Front/views/TaskScreen/VideoDocumentView.jsx";
import InsulinDose from "./InsulinDose";
import Reports from "./Reports";
import PatientRegistration from "Front/views/Home/PatientRegistrationPopup.jsx";
import GlucoseDiaryChart from "Front/views/Home/GlucoseDiaryChart.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { taskScreenHeightWeightRequireForCaloryAction } from 'Front/actions/taskScreen';

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      historyModal: false,
      patientModal: this.props.isOpenModal ? this.props.isOpenModal : false,
      glucoseModal: false,
      dateList: [],
      documents: [],
      videos: [],
      tasks: [],
      _notificationSystem: null,
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (this.state.historyModal) {
      this.props.historyAction(this.props.pvDetail.pId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isHistory !== this.props.isHistory) {
      let docList = nextProps.History.documents;
      let taskList = nextProps.History.tasks;
      let dietLists = nextProps.History.diet;

      var dateList = [];
      var dateSList = [];
      let videos = [];
      let documents = [];
      let tasks = [];
      let diets = [];
      let newDate = "";
      let oldDate = "";
      let j = 0;

      for (let x in docList) {
        newDate = moment(docList[x].createdAt).format("YYYY-MM-DD");
        if (newDate !== oldDate) {
          oldDate = newDate;
          dateSList[j] = newDate;
          dateList[j] = moment(docList[x].createdAt).format(
            "YYYY-MM-DD h:mm a"
          );
          j++;
        }

        if (docList[x].docType === "document") {
          documents.push(docList[x]);
        } else {
          videos.push(docList[x]);
        }
      }

      oldDate = newDate = "";
      for (let y in taskList) {
        newDate = moment(taskList[y].createdAt).format("YYYY-MM-DD");
        if (newDate !== oldDate) {
          oldDate = newDate;

          if (dateSList.indexOf(newDate) < 0) {
            dateSList[j] = newDate;
            dateList[j] = moment(taskList[y].createdAt).format(
              "YYYY-MM-DD h:mm a"
            );
            j++;
          }
        }
        tasks.push(taskList[y]);
      }

      oldDate = newDate = "";
      for (let z in dietLists) {
        newDate = moment(dietLists[z].createdAt).format("YYYY-MM-DD");

        if (newDate !== oldDate) {
          oldDate = newDate;
          diets[newDate] = [];
          if (dateSList.indexOf(newDate) < 0) {
            dateSList[j] = newDate;
            dateList[j] = moment(dietLists[z].createdAt).format(
              "YYYY-MM-DD h:mm a"
            );
            j++;
          }
        }
        diets[newDate].push(dietLists[z]);
      }

      dateList.sort();
      dateList.reverse();
      this.setState({ dateList: dateList });
      this.setState({ documents: documents });
      this.setState({ videos: videos });
      this.setState({ tasks: tasks });
      this.setState({ dietList: diets });
      //console.log(diets,"diets")
    }
  }

  history() {
    this.setState({ historyModal: true });
    this.props.historyAction(this.props.pvDetail.pId);
  }

  dietDetail(lastDiets) {
    if (lastDiets["1"]) {
      if (
        lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
        lastDiets["0"].treatmentId === lastDiets["1"].treatmentId
      ) {
        return (
          <span className="green font-11">{lastDiets["0"].treatmentName}</span>
        );
      }
      if (
        lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
        lastDiets["0"].treatmentId !== lastDiets["1"].treatmentId
      ) {
        if (lastDiets["0"].addedByType === "doctor") {
          return (
            <span className="font-11">
              <span>{lastDiets["0"].treatmentName}</span>
              <br />
              <span className="blue">{lastDiets["1"].treatmentName}</span>
            </span>
          );
        } else {
          return (
            <span className="font-11">
              <span>{lastDiets["1"].treatmentName}</span>
              <br />
              <span className="blue">{lastDiets["0"].treatmentName}</span>
            </span>
          );
        }
      } else {
        return (
          <span className="red font-11 ">{lastDiets["0"].treatmentName}</span>
        );
      }
    } else {
      if (lastDiets["0"] && lastDiets["0"].addedByType === "educator") {
        let dietColor = lastDiets["0"].color;
        return (
          <span className={dietColor + " font-11"}>
            {lastDiets["0"].treatmentName}
          </span>
        );
      } else if (lastDiets["0"]) {
        return (
          <span className="red font-11 reme-cursor">
            {lastDiets["0"].treatmentName}
          </span>
        );
      } else {
        return "";
      }
    }
  }

  dietCalorieDetail(lastDiets) {
    //console.log(lastDiets,"lastDietsCalorie")
    return (
      <span className=" font-13">
        <b>{lastDiets["0"].calorie}</b>
      </span>
    );
  }

  dietTypeDetail(lastDiets) {
    //console.log(lastDiets,"lastDietsDietType")
    return (
      <span className=" font-13">
        <b>{lastDiets["0"].dietType}</b>
      </span>
    );
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          <span>"Patient Edit Successfully"</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  successAlert(msg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          //onConfirm={() => this.hideAlert()}
          //onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {msg}
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" style={style} />
        {!this.props.searchScreen && (
          <span>
            {/* {(localStorage.getItem("userType") == "doctor")?
                <Link className="btn-fill btn btn-primary btn btn-default mangeBtns" to={{ pathname:this.props.pvDetail.patientStatus==="old"?this.props.pvDetail.flag==="yes"?`print/${this.props.pvDetail.id}/${this.props.pvDetail.pId}/brand/${this.props.pvDetail.language}`:`prescription/${this.props.pvDetail.id}/${this.props.pvDetail.pId}/${this.props.pvDetail.language}`:`vital-sign/${this.props.pvDetail.id}/${this.props.pvDetail.pId}/${this.props.pvDetail.language}`}}>EMR</Link>:
                this.props.pvDetail.patientStatus==="old"?this.props.pvDetail.flag==="yes"?<Link className="btn-fill btn btn-primary btn btn-default mangeBtns" to={{ pathname:`print/${this.props.pvDetail.id}/${this.props.pvDetail.pId}/brand/${this.props.pvDetail.language}`}}>EMR</Link>:"":""
                 } */}
            {/* <button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e=>{this.setState({glucoseModal:true})}}><i class="fa fa-book" aria-hidden="true"></i></button> */}
            {!this.props.isOpenModal &&
              <>
                <button
                  type="button"
                  className="btn-fill btn btn-primary btn btn-default mangeBtns"
                  onClick={(e) => {
                    this.setState({ patientModal: true });
                  }}
                >
                  Edit Patient Info
                </button>
                {/* {localStorage.getItem('is_report_folder_facility') && localStorage.getItem('is_report_folder_facility') === 'yes' &&
                       <Reports visitInfo={this.props.pvDetail}/>
                    } */}

                {/* {localStorage.getItem('userType') && localStorage.getItem('userType') === 'doctor' &&
                       <InsulinDose visitInfo={this.props.pvDetail}/>
                    } */}
                <Button
                  className="btn-fill btn btn-primary btn btn-default mangeBtns"
                  onClick={() => this.history()}
                >
                  Knowledge Share History
                </Button>
              </>
            }
          </span>
        )}
        {this.props.searchScreen && (
          <>
            <i className="pe-7s-clock" onClick={() => this.history()}></i>
            <span onClick={() => this.history()}>Knowledge History</span>
          </>
        )}

        <Modal
          className="history-modal"
          show={this.state.historyModal}
          onHide={() => this.setState({ historyModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Knowledge Share History (
              {this.props.pvDetail.patientId + " - " + this.props.pvDetail.name}
              )
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table className="table table-striped table-hover table-bordered">
              <thead className="flip-content bordered-palegreen">
                <tr>
                  <th>DOCUMENTS</th>
                  <th>VIDEOS</th>
                  <th>TASK</th>
                  <th>DIET</th>
                  <th>CALORIE</th>
                  <th>DIET TYPE</th>
                </tr>
              </thead>

              {this.state.dateList.map((value, key) => {
                return (
                  <tbody key={value}>
                    <tr>
                      <th colSpan="6">
                        {moment(value, "YYYY-MM-DD h:mm a").format(
                          "DD-MM-YYYY h:mm a"
                        )}
                      </th>
                    </tr>
                    <tr>
                      <td className="left-con">
                        {this.state.documents.map((document, docKey) => {
                          let newDate = moment(
                            document.createdAt,
                            "YYYY-MM-DD"
                          ).format("YYYY-MM-DD");
                          let status =
                            document.addedByType === "educator"
                              ? "pink"
                              : document.status === "read"
                                ? "green"
                                : "red";
                          let data;
                          if (
                            newDate ===
                            moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                          ) {
                            data = (
                              <div className={status} key={document._id}>
                                <VideoDocumentView
                                  pvDetail={this.props.pvDetail}
                                  link={document.image}
                                  name={document.documentName}
                                  type={"image"}
                                  status={0}
                                />
                              </div>
                            );
                          }
                          return data;
                        })}
                      </td>
                      <td className="left-con">
                        {this.state.videos.map((video, vidKey) => {
                          let newDate = moment(
                            video.createdAt,
                            "YYYY-MM-DD"
                          ).format("YYYY-MM-DD");
                          let data;
                          if (
                            newDate ===
                            moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                          ) {
                            let status =
                              video.addedByType === "educator"
                                ? "pink"
                                : video.status === "read"
                                  ? "green"
                                  : "red";
                            data = (
                              <div className={status} key={video._id}>
                                <VideoDocumentView
                                  pvDetail={this.props.pvDetail}
                                  link={video.image}
                                  name={video.documentName}
                                  type={"video"}
                                  status={0}
                                />
                              </div>
                            );
                          }
                          return data;
                        })}
                      </td>
                      <td>
                        <ul style={{ margin: "0" }}>
                          {this.state.tasks.map((task, taskKey) => {
                            let newDate = moment(
                              task.createdAt,
                              "YYYY-MM-DD"
                            ).format("YYYY-MM-DD");
                            let status =
                              task.status === "complete" ? "green" : "red";
                            let data;
                            if (
                              newDate ===
                              moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                            ) {
                              data = (
                                <li
                                  style={{ listStyle: "none" }}
                                  className={status}
                                  key={task._id}
                                >
                                  {task.taskName}
                                </li>
                              );
                            }
                            return data;
                          })}
                        </ul>
                      </td>
                      <td>
                        {this.state.dietList[
                          moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                        ]
                          ? this.dietDetail(
                            this.state.dietList[
                            moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                            ]
                          )
                          : ""}
                      </td>
                      <td>
                        {this.state.dietList[
                          moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                        ]
                          ? this.dietCalorieDetail(
                            this.state.dietList[
                            moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                            ]
                          )
                          : ""}
                      </td>
                      <td>
                        {this.state.dietList[
                          moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                        ]
                          ? this.dietTypeDetail(
                            this.state.dietList[
                            moment(value, "YYYY-MM-DD").format("YYYY-MM-DD")
                            ]
                          )
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-secondary"
              simple
              onClick={() => this.setState({ historyModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="pa-patient-registration"
          show={this.state.patientModal}
          onHide={() => { this.props.taskScreenHeightWeightRequireForCaloryAction(false); this.setState({ patientModal: false }) }}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Edit Patient Info
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <Row>
              <Col md={12}>
                {this.props.getCalorieList !== undefined ?
                  <PatientRegistration
                    onDismiss={this.onDismiss}
                    patientData={this.props.pvDetail}
                    parent={"history"}
                    getCalorieList={(e) => this.props.getCalorieList(e)}
                  /> :
                  <PatientRegistration
                    onDismiss={this.onDismiss}
                    patientData={this.props.pvDetail}
                    parent={"history"}
                  />
                }

              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          className="pa-patient-registration"
          show={this.state.glucoseModal}
          onHide={() => this.setState({ glucoseModal: false })}
          dialogClassName="modal-full modal-xl"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Glucose Diary (
              {this.props.pvDetail.name + " - " + this.props.pvDetail.patientId}
              )
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <Row>
              <Col md={12}>
                <GlucoseDiaryChart
                  onDismiss={this.onDismiss}
                  patientData={this.props.pvDetail}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    History: state.home.History,
    isHistory: state.home.isHistory,
    isHistoryError: state.home.isHistoryError,
    isHeightWeightValidation: state.isHeightWeightValidation,
  };
}
export default withRouter(connect(mapStateToProps, { historyAction, taskScreenHeightWeightRequireForCaloryAction })(History));

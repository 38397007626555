/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isLanguageList: false,
  isLanguageListError: false,
  LanguageList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {

    //Language List
    case actionTypes.GET_LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isLanguageList: false,
        isLanguageListError: false,
      }
    case actionTypes.GET_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        message: 'LANGUAGE List',
        LanguageList: action.payload.response,
        isLanguageList: true,
        isLanguageListError: false,
      }
    case actionTypes.GET_LANGUAGE_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLanguageList: false,
        isLanguageListError: true
      }

    default:
      return state
  }
}

/********* Show list of Language */




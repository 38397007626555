/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/treatment'
/* Add Treatment list and Add form */

export const addTreatmentRequest = () => ({
    type: actionTypes.GET_ADD_TREATMENT_REQUEST
})
export const addTreatmentSuccess = (response) => ({
    type: actionTypes.GET_ADD_TREATMENT_SUCCESS,
    payload: {
        response,
    }
})
export const addTreatmentError = (error) => ({
    type: actionTypes.GET_ADD_TREATMENT_ERROR,
    payload: {
        error,
    }
})
export const addTreatmentAction = (data) => {
    return dispatch => {
        dispatch(addTreatmentRequest())
        return API.addTreatment(data)
            .then(response => {
                dispatch(addTreatmentSuccess(response.data))
            })
            .catch(error => {
                dispatch(addTreatmentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Treatment list and Add form */
export const updateTreatmentRequest = () => ({
    type: actionTypes.GET_UPDATE_TREATMENT_REQUEST
})
export const updateTreatmentSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_TREATMENT_SUCCESS,
    payload: {
        response,
    }
})
export const updateTreatmentError = (error) => ({
    type: actionTypes.GET_UPDATE_TREATMENT_ERROR,
    payload: {
        error,
    }
})
export const updateTreatmentAction = (data) => {
    return dispatch => {
        dispatch(updateTreatmentRequest())

        return API.updateTreatment(data)
            .then(response => {
                dispatch(updateTreatmentSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateTreatmentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Treatment list  */
export const TreatmentListRequest = () => ({
    type: actionTypes.GET_TREATMENT_LIST_REQUEST
})
export const TreatmentListSuccess = (response) => ({
    type: actionTypes.GET_TREATMENT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const TreatmentListError = (error) => ({
    type: actionTypes.GET_TREATMENT_LIST_ERROR,
    payload: {
        error
    }
})
export const treatmentListAction = (data, search) => {
    return dispatch => {
        dispatch(TreatmentListRequest());
        return API.treatmentList(data)
            .then(response => {
                dispatch(TreatmentListSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Treatment Status Change  */
export const TreatmentChangeStatusRequest = () => ({
    type: actionTypes.GET_TREATMENT_CHANGE_STATUS_REQUEST
})
export const TreatmentChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_TREATMENT_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const TreatmentChangeStatusError = (error) => ({
    type: actionTypes.GET_TREATMENT_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const treatmentChangeStatusAction = (data) => {

    return dispatch => {
        dispatch(TreatmentChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeTreatmentStatus(FormData)
            .then(response => {

                dispatch(TreatmentChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Treatment list with Type  */
export const TreatmentListTypeRequest = () => ({
    type: actionTypes.GET_TREATMENT_LIST_TYPE_REQUEST
})
export const TreatmentListTypeSuccess = (response) => ({
    type: actionTypes.GET_TREATMENT_LIST_TYPE_SUCCESS,
    payload: {
        response,
    }
})
export const TreatmentListTypeError = (error) => ({
    type: actionTypes.GET_TREATMENT_LIST_TYPE_ERROR,
    payload: {
        error
    }
})
export const treatmentListTypeAction = (data, search) => {
    return dispatch => {
        dispatch(TreatmentListTypeRequest());
        return API.treatmentListType(data)
            .then(response => {
                dispatch(TreatmentListTypeSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentListTypeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


export const TreatmentChangeOrderRequest = () => ({
    type: actionTypes.TREATMENT_CHANGE_ORDER_REQUEST
})

export const TreatmentChangeOrderSuccess = (response) => ({
    type: actionTypes.TREATMENT_CHANGE_ORDER_SUCCESS,
    payload: {
        response,
    }
})

export const TreatmentChangeOrderError = (error) => ({
    type: actionTypes.TREATMENT_CHANGE_ORDER_ERROR,
    payload: {
        error
    }
})

export const treamentChangeOrderAction = (data) => {
    return dispatch => {
        dispatch(TreatmentChangeOrderRequest())
        return API.treatmentChangeOrder(data)
            .then(response => {
                dispatch(TreatmentChangeOrderSuccess(response.data))
            })
            .catch(error => {
                dispatch(TreatmentChangeOrderError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddComment: false,
  isAddCommentError: false,
  AddComment: false,
  isUpdateComment: false,
  isUpdateCommentError: false,
  UpdateComment: false,
  isCommentChangeStatus: false,
  isCommentChangeStatusError: false,
  CommentChangeStatus: false,
  isCommentList: false,
  isCommentListError: false,
  CommentList: false,

  isCommentAutoSelectStatus: false,
  isCommentAutoSelectStatusError: false,
  CommentAutoSelectStatus: false,

}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_COMMENT_REQUEST:
      return {
        ...state,
        message: '',
        isAddComment: false,
        isAddCommentError: false,
      }
    case actionTypes.GET_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        message: 'Comment Added Successfully',
        AddComment: action.payload.response,
        isAddComment: true,
        isAddCommentError: false,
      }
    case actionTypes.GET_ADD_COMMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddComment: false,
        isAddCommentError: true
      }

    //Comment Update

    case actionTypes.GET_UPDATE_COMMENT_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateComment: false,
        isUpdateCommentError: false,
      }
    case actionTypes.GET_UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        message: 'Comment Updated Successfully',
        UpdateComment: action.payload.response,
        isUpdateComment: true,
        isUpdateCommentError: false,
      }
    case actionTypes.GET_UPDATE_COMMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateComment: false,
        isUpdateCommentError: true
      }

    //Comment List
    case actionTypes.GET_COMMENT_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isCommentList: false,
        isCommentListError: false,
      }
    case actionTypes.GET_COMMENT_LIST_SUCCESS:
      return {
        ...state,
        message: 'Comment List',
        CommentList: action.payload.response,
        isCommentList: true,
        isCommentListError: false,
      }
    case actionTypes.GET_COMMENT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isCommentList: false,
        isCommentListError: true
      }

    //Comment Status Change
    case actionTypes.GET_COMMENT_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isCommentChangeStatus: false,
        isCommentChangeStatusError: false,
      }
    case actionTypes.GET_COMMENT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Comment Status Changed',
        CommentChangeStatus: action.payload.response,
        isCommentChangeStatus: true,
        isCommentChangeStatusError: false,
      }
    case actionTypes.GET_COMMENT_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isCommentChangeStatus: false,
        isCommentChangeStatusError: true
      }

    case actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isCommentAutoSelectStatus: false,
        isCommentAutoSelectStatusError: false,
      }
    case actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Comment Auto Select Status Changed',
        CommentAutoSelectStatus: action.payload.response,
        isCommentAutoSelectStatus: true,
        isCommentAutoSelectStatusError: false,
      }
    case actionTypes.GET_COMMENT_AUTO_SELECT_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isCommentAutoSelectStatus: false,
        isCommentAutoSelectStatusError: true
      }





    default:
      return state
  }
}

/********* Show list of Comment */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Nav,
  NavDropdown,
  MenuItem,
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { addVisitAction } from "Front/actions/patient";
import { confirmAlert } from "react-confirm-alert";
import PatientRegistration from "Front/views/Home/PatientRegistrationPopup.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { patientStatusChangeAction } from "Front/actions/home";
import moment from "moment";
import { appConstants } from "Front/_constants/app.constants.js";
import History from "Front/views/TaskScreen/History.jsx";
import Reports from "Front/views/TaskScreen/Reports";
import GlucoseDiaryChart from "../Home/GlucoseDiaryChart";
import { taskScreenAction } from "Front/actions/taskScreen";
import { emrSetAction } from "Front/actions/emr";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import $ from "jquery";
let errorMsg = "";
let isOnline = false;
let userType = localStorage.getItem("userType");
var addVisitCalled = false;
class DropDownSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        patient_id: "",
        height: "",
        weight: "",
        remark: "",
        econsultation: false,
        econsultations: false,
        status: this.props.patient && this.props.patient.status,
        id: this.props.patient && this.props.patient.id,
        reportsModel: false,
        patientInfo: {},
        settingsMenuOpen: false,
        currentMenu: "",
      },
      _notificationSystem: null,
      password: this.props.patient.password,
      patientName:
        this.props.patient.firstName +
        " " +
        (this.props.patient.lastName ? this.props.patient.lastName : ""),
      patientId: this.props.patient.patientId,
      listStatusapi: false,
      errorMsgModal: false,
      ptStatus: "active",
    };
    this.onDismiss = this.onDismiss.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.funModelStatus = this.funModelStatus.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.isPatientUpdate = this.isPatientUpdate.bind(this);
    isOnline = false;
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.setState({
      ptStatus: this.props.patient && this.props.patient.status,
    });
    this.handleMenu();
  }

  handleMenu() {
    var obj = this;

    $("body").delegate(".more-menu", "click", function () {
      if (obj.state.currentMenu != this) {
        $(obj.state.currentMenu).removeClass("open");
        obj.setState({ currentMenu: this });
      }
    });

    $("body").click(function (e) {
      if (
        !$(e.target).hasClass("more-menu") &&
        $(e.target).parents(".more-menu").length == 0
      ) {
        obj.setState({ settingsMenuOpen: false });
      }
    });

    $("body").delegate(".more-menu li", "click", function () {
      obj.setState({ settingsMenuOpen: false });
    });
  }

  showPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.isAddVisit !== this.props.isAddVisit && addVisitCalled) {
      addVisitCalled = false;
      let url = "/";
      if (this.props.history?.location?.pathname === "/") url = "/dashboard";
      this.props.history.push({ pathname: url, econsultation: isOnline });

      // setTimeout(function () {
      //   let params = {
      //     clinicId: localStorage.getItem("clinicId"),
      //     econsultation:isOnline
      //   };
      //   appConstants.socket.emit("updateTaskScreen", params);
      // }, 1000);
    }

    if (nextProps.isAddVisitError !== this.props.isAddVisitError) {
      if (nextProps.addVisit.errors) {
        nextProps.addVisit.errors.map((key, i) => {
          return this.setState({
            [key.param + "Error"]: (
              <small className="text-danger">{key.msg}</small>
            ),
          });
        });
      }
    }

    let status = this.state.formData.status;
    if (nextProps.isPatientStatus !== this.props.isPatientStatus) {
      this.setState({ ptStatus: status });
    }
  }

  handleChange(e) {
    let { formData } = this.state;

    if (e.target.type === "radio") {
      if (e.target.value === "true") {
        document.getElementById("online-consultation").checked = true;
        document.getElementById("OPD-Consultation").checked = false;
        //console.log(e.target.value,"online-consultation");
      } else {
        document.getElementById("OPD-Consultation").checked = true;
        document.getElementById("online-consultation").checked = false;
        //console.log(e.target.value,"OPD-Consultation");
      }
    }
    formData["econsultation"] = e.target.value;

    // if (e.target.name === "econsultation") {
    //   if (e.target.value === "true") isOnline = true;
    //   if (e.target.value === "false") isOnline = false;
    // }
    if (e.target.name === "econsultation") {
      if (e.target.value === "true") isOnline = true;
      if (e.target.value === "false") isOnline = false;
    } else {
      if (e.target.value === "false") isOnline = false;
      if (e.target.value === "true") isOnline = true;
    }
    this.setState({ ...formData });
  }

  addVisit(patient) {
    //this.props.setAddToVistListAction()
    let field = this.state.formData;
    field["patient_id"] = patient.id;
    field["height"] = patient.height ? patient.height : "";
    field["weight"] = patient.weight ? patient.weight : "";
    field["remark"] = "";
    this.setState({ formData: field });

    if (
      patient.addVisit &&
      moment(patient.addVisit).startOf("day").format() ===
      moment().startOf("day").format()
    ) {
      this.successAlert("This patient is already in today's list");
    } else if (
      moment(patient.createdAt).startOf("day").format() ===
      moment().startOf("day").format()
    ) {
      this.successAlert("This patient is already in today's list");
    } else {
      errorMsg = "";
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div class="react-confirm-alert-overlay undefined">
              <div class="react-confirm-alert">
                <div class="react-confirm-alert-body w-300">
                  <Row>
                    <Col sm={12}>
                      <h1>Confirm to add visit</h1>
                      <div>
                        <h5>Are you sure to do this.</h5>
                        {this.state.formData.econsultation}
                        <FormGroup className="e-consultation">
                          <form className="consultation-radio">
                            <div className="form-group">
                              <input
                                type="radio"
                                id="OPD-Consultation"
                                name="econsultations"
                                value={false}
                                checked={true}
                                onClick={(e) => {
                                  this.handleChange(e);
                                }}
                              />
                              <label for="OPD-Consultation">
                                OPD Consultation
                              </label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="online-consultation"
                                name="econsultation"
                                value={true}
                                onClick={(e) => {
                                  this.handleChange(e);
                                }}
                              />
                              <label for="online-consultation">
                                Online Consultation
                              </label>
                            </div>
                          </form>
                        </FormGroup>
                      </div>
                    </Col>
                    {/* <Col sm={12}>
                                <FormGroup className="e-consultation">
                                    <Col>
                                        <FormControl type="checkbox"  name="econsultation" bsClass="form-control"   onClick={e => { this.handleChange(e); }} />
                                    </Col>                          
                                    <Col componentClass={ControlLabel} >
                                       Online Consultation
                                    </Col>
                                </FormGroup>
                                </Col> */}
                    <Col sm={12}>
                      <FormGroup>
                        <div class="react-confirm-alert-button-group">
                          <button
                            onClick={() => {
                              this.props.addVisitAction(this.state.formData);
                              this.resetFormData();
                              onClose();
                              addVisitCalled = true;
                            }}
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => {
                              this.resetFormData();
                              onClose();
                              isOnline = false;
                            }}
                          >
                            No
                          </button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          );
        },
        title: "Confirm to add visit",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.props.addVisitAction(this.state.formData),
          },
          {
            label: "No",
            onClick: () => this.resetFormData(),
          },
        ],
      });
      // if (field.econsultation === false)
      //   document.getElementById("OPD-Consultation").checked = true;
    }
  }

  changePatientStatus(patient) {
    let field = this.state.formData;

    let newstatus = {};
    if (patient.status === "active") {
      newstatus = "inactive";
    } else {
      newstatus = "active";
    }

    field["patient_id"] = patient.id;
    field["status"] = newstatus;
    this.setState({ formData: field, listStatusapi: true });
    confirmAlert({
      title: "Confirm to change status",
      message: "Are you sure you want to change the status?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.patientStatusChangeAction(this.state.formData),
        },
        {
          label: "No",
          onClick: () => this.resetFormData(),
        },
      ],
    });
  }

  resetFormData() {
    let field = this.state.formData;
    field["patient_id"] = "";
    field["height"] = "";
    field["weight"] = "";
    field["remark"] = "";
    field["econsultation"] = false;
    field["econsultations"] = false;
    this.setState({ formData: field });
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.successAlert("Patient Successfully Updated");
  }

  successAlert(msg) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       //onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  funModelStatus() {
    this.setState({ reportsModel: false });
  }

  getReport(patient) {
    this.setState({ reportsModel: true, patientInfo: patient });
  }

  emr(patient) {
    // console.log(patient, "patient");
  }
  isPatientUpdate(data) {
    this.props.patientUpdate(data);
  }
  render() {
    const reportModal = (
      <Reports
        visitInfo={this.state.patientInfo}
        hideButton="true"
        modelStatus={this.state.reportsModel}
        funModelStatus={this.funModelStatus}
      />
    );

    return (
      <div className="d-flex align-items-center">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        {localStorage.getItem("is_report_folder_facility") &&
          localStorage.getItem("is_report_folder_facility") === "yes" &&
          reportModal}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="status">Change Status</Tooltip>}
        >
          <Button
            simple
            icon
            onClick={this.changePatientStatus.bind(this, this.state.formData)}
          >
            <i
              className={
                this.state.ptStatus === "active" ? "fa fa-check" : "fa fa-ban"
              }
              aria-hidden="true"
            ></i>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit">Edit</Tooltip>}
        >
          <a onClick={() => this.setState({ patientModal: true })}>
            <i className="fa fa-edit"></i>
          </a>
        </OverlayTrigger>
        <Nav>
          <NavDropdown
            eventKey={4}
            title={
              <div>
                More<b className="caret"></b>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-3"
            bsClass="dropdown-with-icons dropdown"
            className={`more-menu ${this.state.settingsMenuOpen ? "open" : ""}`}
            onClick={() =>
              this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })
            }
          >
            {userType != "receptionist" && (
              <MenuItem
                eventKey={4.0}
                onClick={() => this.setState({ patientModal: true })}
              >
                <i className="fa fa-edit"></i> EDIT
              </MenuItem>
            )}
            <MenuItem
              eventKey={4.3}
              onClick={this.addVisit.bind(this, this.props.patient)}
            >
              <i className="pe-7s-plus"></i> ADD VISIT
            </MenuItem>
            {userType != "receptionist" && (
              <MenuItem
                eventKey={4.2}
                onClick={this.props.taskScreenModal.bind(
                  this,
                  this.props.taskScreen
                )}
              >
                <i className="pe-7s-next-2"></i> DIET & SHARE
              </MenuItem>
            )}
            {localStorage.getItem("is_chat_facility") === "yes" && (
              <MenuItem
                eventKey={4.4}
                data-id={this.props.chatPatientId}
                onClick={this.props.chatBox.bind(
                  this,
                  this.props.chatPatientId,
                  this.props.chatPatientDetails
                )}
              >
                <i className="pe-7s-chat"></i> CHAT
              </MenuItem>
            )}
            {userType != "receptionist" && (
              <MenuItem>
                {/* <i className="pe-7s-clock"></i>{" "} */}
                <History searchScreen={true} pvDetail={this.props.taskScreen} />
              </MenuItem>
            )}
            {this.props.taskScreen.notification_token &&
              userType != "receptionist" && (
                <MenuItem
                  eventKey={4.6}
                  onClick={(e) => {
                    this.props.openNotificationModal2(this.props.taskScreen);
                  }}
                >
                  <i className="pe-7s-bell"></i> NOTIFICATION
                </MenuItem>
              )}
            {userType != "receptionist" && (
              <MenuItem
                eventKey={4.7}
                onClick={this.getReport.bind(this, this.props.patient)}
              >
                <i className="pe-7s-plus"></i>REPORTS
              </MenuItem>
            )}
            {userType === "doctor" ? (
              <li>
                <Link
                  className="menuitem"
                  onClick={(e) => {
                    localStorage.setItem(
                      "is_Navigate_From_Doctor_Screen",
                      false
                    );
                    this.props.taskScreenAction(this.props.patient);
                    this.props.emrSetAction({
                      patientId: this.props.patient.id,
                      isEmr:
                        this.props.patient.patientStatus === "old" &&
                          this.props.patient.flag === "yes"
                          ? true
                          : false,
                    });
                  }}
                  to={
                    this.props.patient.patientStatus === "old"
                      ? this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() ===
                          "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/brand/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                      : this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() === "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                  }
                >
                  <i className="pe-7s-plus"></i> PRESCRIPTION
                </Link>{" "}
              </li>
            ) : userType === "receptionist" &&
              localStorage.getItem("is_prescription_edit_by_receptionist") ===
              "yes" ? (
              <li>
                <Link
                  className="menuitem"
                  onClick={(e) => {
                    localStorage.setItem(
                      "is_Navigate_From_Doctor_Screen",
                      false
                    );
                    this.props.taskScreenAction(this.props.patient);
                    this.props.emrSetAction({
                      patientId: this.props.patient.id,
                      isEmr:
                        this.props.patient.patientStatus === "old" &&
                          this.props.patient.flag === "yes"
                          ? true
                          : false,
                    });
                  }}
                  to={
                    this.props.patient.patientStatus === "old"
                      ? this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() ===
                          "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/brand/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                      : this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() === "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                  }
                >
                  <i className="pe-7s-plus"></i> PRESCRIPTION
                </Link>{" "}
              </li>
            ) : userType === "educator" &&
              localStorage.getItem("is_prescription_edit_by_educator") ===
              "yes" ? (
              <li>
                <Link
                  className="menuitem"
                  onClick={(e) => {
                    localStorage.setItem(
                      "is_Navigate_From_Doctor_Screen",
                      false
                    );
                    this.props.taskScreenAction(this.props.patient);
                    this.props.emrSetAction({
                      patientId: this.props.patient.id,
                      isEmr:
                        this.props.patient.patientStatus === "old" &&
                          this.props.patient.flag === "yes"
                          ? true
                          : false,
                    });
                  }}
                  to={
                    this.props.patient.patientStatus === "old"
                      ? this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() ===
                          "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/brand/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                      : this.props.patient.flag === "yes"
                        ? this.props.patient.validPrescription.toString() === "1"
                          ? `print/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                          : `prescription/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                        : `vital-sign/${this.props.patient.emrVisitId}/${this.props.patient.id}/${this.props.patient.language}`
                  }
                >
                  <i className="pe-7s-plus"></i> PRESCRIPTION
                </Link>{" "}
              </li>
            ) : (
              ""
            )}
            {userType != "receptionist" && (
              <MenuItem eventKey={4.9}>
                {" "}
                <i className="pe-7s-plus"></i>
                <GlucoseDiaryChart
                  patientData={this.props.patient}
                  callType="dropdown"
                />
              </MenuItem>
            )}
            {/* <MenuItem eventKey={4.9} onClick={() => this.setState({ glucoseModal: true })}><i className="pe-7s-plus"></i>GLUCOSE DIARY</MenuItem> */}
            <MenuItem eventKey={5} onClick={() => this.showPassword()}>
              <i className="pe-7s-plus"></i>
              <span className="password">PASSWORD</span>
            </MenuItem>
          </NavDropdown>
        </Nav>

        <Modal
          className="pa-patient-registration"
          show={this.state.patientModal}
          onHide={() => this.setState({ patientModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Patient Registration
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <Row>
              <Col md={12}>
                <PatientRegistration
                  onDismiss={this.onDismiss}
                  patientData={this.props.patient}
                  patientUpdate={(patient) => {
                    this.isPatientUpdate(patient);
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {/* <Modal className="pa-patient-registration" show={this.state.glucoseModal} onHide={() => this.setState({ glucoseModal: false })} dialogClassName="modal-full modal-xl">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">Glucose Chart </Modal.Title>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                    <Row>
                        <Col md={12}>
                            <GlucoseDiaryChart patientData={this.props.patient} />

                        </Col>
                    </Row>
                </Modal.Body>
            </Modal> */}
        {this.state.glucoseModal && (
          <div>
            <Row>
              <Col md={12}>
                <GlucoseDiaryChart
                  patientData={this.props.patient}
                  callType="dropdown"
                />
              </Col>
            </Row>
          </div>
        )}

        <Modal
          className="pa-patient-password"
          show={this.state.showPassword}
          onHide={() => this.setState({ showPassword: false })}
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              User Password{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            {/* <Row className='clinic-user-password'>
                        <Col>
                            <b>User Name: </b>{this.state.patientName}
                        </Col>
                        <Col>
                            <b>Password: </b>{this.state.password}
                        </Col>

                    </Row> */}
            <div className="clinic-user-password">
              <div>
                <b>Patient Id: </b>
                <span>{this.state.patientId}</span>
              </div>
              <div>
                <b>User Name: </b>
                <span>{this.state.patientName}</span>
              </div>
              <div>
                <b>Password: </b>
                <span>{this.state.password}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Row>
          <Col sm={12} className="pd00 error-modal">
            <Modal
              show={this.state.errorMsgModal}
              onHide={() => this.setState({ errorMsgModal: false })}
              dialogClassName=""
              className="Modal_downsearch"
            >
              <Modal.Header closeButton className="bg-thumbcolor-box">
                <Modal.Title id="example-modal-sizes-title-lg"> </Modal.Title>
              </Modal.Header>
              <Modal.Body className="Knowledge-Share">
                <Row className="modal_margin">
                  <Col md={12}>
                    <div className="error-icon">
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="err-text">{errorMsg}</div>
                  </Col>
                  <Col md={12}></Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addVisit: state.patient.addVisit,
    isAddVisit: state.patient.isAddVisit,
    isAddVisitError: state.patient.isAddVisitError,

    PatientStatus: state.home.PatientStatus,
    isPatientStatus: state.home.isPatientStatus,
    isPatientStatusError: state.home.isPatientStatusError,

    patientEmrData: state.emr.patientEmrData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addVisitAction,
    patientStatusChangeAction,
    taskScreenAction,
    emrSetAction,
  })(DropDownSearch)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddReadyCreatedMeal: false,
  isAddReadyCreatedMealError: false,
  AddReadyCreatedMeal: false,
  isUpdateReadyCreatedMeal: false,
  isUpdateReadyCreatedMealError: false,
  UpdateReadyCreatedMeal: false,
  isReadyCreatedMealChangeStatus: false,
  isReadyCreatedMealChangeStatusError: false,
  ReadyCreatedMealChangeStatus: false,
  isReadyCreatedMealList: false,
  isReadyCreatedMealListError: false,
  ReadyCreatedMealList: false,
  isUploadFile: false,
  isUploadFileError: false,
  uploadFile: false,
  isAddFoodCart: false,
  isAddFoodCartError: false,
  AddFoodCart: false,
  isUpdateFoodCart: false,
  isUpdateFoodCartError: false,
  UpdateFoodCart: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_READY_CREATED_MEAL_REQUEST:
      return {
        ...state,
        message: '',
        isAddReadyCreatedMeal: false,
        isAddReadyCreatedMealError: false,
      }
    case actionTypes.GET_ADD_READY_CREATED_MEAL_SUCCESS:
      return {
        ...state,
        message: 'ReadyCreatedMeal Added Successfully',
        AddReadyCreatedMeal: action.payload.response,
        isAddReadyCreatedMeal: true,
        isAddReadyCreatedMealError: false,
      }
    case actionTypes.GET_ADD_READY_CREATED_MEAL_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddReadyCreatedMeal: false,
        isAddReadyCreatedMealError: true
      }

    //ReadyCreatedMeal Update

    case actionTypes.GET_UPDATE_READY_CREATED_MEAL_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateReadyCreatedMeal: false,
        isUpdateReadyCreatedMealError: false,
      }
    case actionTypes.GET_UPDATE_READY_CREATED_MEAL_SUCCESS:
      return {
        ...state,
        message: 'ReadyCreatedMeal Updated Successfully',
        UpdateReadyCreatedMeal: action.payload.response,
        isUpdateReadyCreatedMeal: true,
        isUpdateReadyCreatedMealError: false,
      }
    case actionTypes.GET_UPDATE_READY_CREATED_MEAL_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateReadyCreatedMeal: false,
        isUpdateReadyCreatedMealError: true
      }

    //ReadyCreatedMeal List
    case actionTypes.GET_READY_CREATED_MEAL_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isReadyCreatedMealList: false,
        isReadyCreatedMealListError: false,
      }
    case actionTypes.GET_READY_CREATED_MEAL_LIST_SUCCESS:
      return {
        ...state,
        message: 'ReadyCreatedMeal List',
        ReadyCreatedMealList: action.payload.response,
        isReadyCreatedMealList: true,
        isReadyCreatedMealListError: false,
      }
    case actionTypes.GET_READY_CREATED_MEAL_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isReadyCreatedMealList: false,
        isReadyCreatedMealListError: true
      }

    //ReadyCreatedMeal Status Change
    case actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isReadyCreatedMealChangeStatus: false,
        isReadyCreatedMealChangeStatusError: false,
      }
    case actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'ReadyCreatedMeal Status Changed',
        ReadyCreatedMealChangeStatus: action.payload.response,
        isReadyCreatedMealChangeStatus: true,
        isReadyCreatedMealChangeStatusError: false,
      }
    case actionTypes.GET_READY_CREATED_MEAL_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isReadyCreatedMealChangeStatus: false,
        isReadyCreatedMealChangeStatusError: true
      }

    //ReadyCreatedMeal  Update

    case actionTypes.GET_UPDATE_PRIORITY_REQUEST:
      return {
        ...state,
        message: '',
        isUpdatePriority: false,
        isUpdatePriorityError: false,
      }
    case actionTypes.GET_UPDATE_PRIORITY_SUCCESS:
      return {
        ...state,
        message: 'Priority Updated Successfully',
        UpdatePriority: action.payload.response,
        isUpdatePriority: true,
        isUpdatePriorityError: false,
      }
    case actionTypes.GET_UPDATE_PRIORITY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdatePriority: false,
        isUpdatePriorityError: true
      }

    //Add Food Cart
    case actionTypes.GET_ADD_FOOD_CART_REQUEST:
      return {
        ...state,
        message: '',
        isAddFoodCart: false,
        isAddFoodCartError: false,
      }
    case actionTypes.GET_ADD_FOOD_CART_SUCCESS:
      return {
        ...state,
        message: 'FoodCart Added Successfully',
        AddFoodCart: action.payload.response,
        isAddFoodCart: true,
        isAddFoodCartError: false,
      }
    case actionTypes.GET_ADD_FOOD_CART_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddFoodCart: false,
        isAddFoodCartError: true
      }


    //Update Food Cart
    case actionTypes.GET_UPDATE_FOOD_CART_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateFoodCart: false,
        isUpdateFoodCartError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_CART_SUCCESS:
      return {
        ...state,
        message: 'FoodCart Update Successfully',
        UpdateFoodCart: action.payload.response,
        isUpdateFoodCart: true,
        isUpdateFoodCartError: false,
      }
    case actionTypes.GET_UPDATE_FOOD_CART_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateFoodCart: false,
        isUpdateFoodCartError: true
      }

    default:
      return state
  }
}

/********* Show list of ReadyCreatedMeal */




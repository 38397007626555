/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react'
import * as API from "../../../../api/emr"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormGroup, ControlLabel, FormControl, Grid, Row, Col, Form } from "react-bootstrap";
import { Button } from 'react-bootstrap/lib/InputGroup';
import Card from "Front/components/Card/Card.jsx";
import { selectDrugTypeOptions } from '../../../../../Front/variables/Variables';
import { useHistory } from "react-router-dom";
const EditDiagnosisSubCategorySequence = (props) => {
    let history = useHistory();
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [DiagonsisSubCategoryList, setDiagonsisSubCategoryList] = useState();
    const [type, setType] = useState("longTerm");
    let row = props.location.state?.row;
    const dragStart = (e, position) => {
        dragItem.current = position;

    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        const copyListItems = [...DiagonsisSubCategoryList];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setDiagonsisSubCategoryList(copyListItems);
    };

    const resetOrder = async () => {
        let listData = DiagonsisSubCategoryList;
        let count = 1;
        let Data = [];
        for (let i in listData) {
            listData[i].sequence = count;
            Data.push({ sequence: listData[i].sequence, _id: listData[i]._id, name: listData[i].name })
            count++;
        }
        let res = await API.editDiagnosisSubCategorySequence({ "diagnosisSubCategoryList": Data });
        if (res && res.data.statusCode == 200) {
            history.push({ pathname: "/admin/diagnosis-category-list", from: "sequence" })
        }
    };

    const getDiagonsisCategory = async (value) => {
        setType(value)
        let data = {
            clinicId: "",
            // diagonsisCategoryId: "63285a930aca40a8ebd4061a",
            diagonsisCategoryId: row._id,
            patientId: "",
            type: value
        }
        let resp = await API.diagonsisList(data);
        if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
            setDiagonsisSubCategoryList(resp?.data?.masterDiagonsisCategoryList.data)
        }
    }

    useEffect(() => {
        (async () => {
            if (row !== undefined && row) {
                let data = {
                    clinicId: "",
                    // diagonsisCategoryId: "63285a930aca40a8ebd4061a",
                    diagonsisCategoryId: row._id,
                    patientId: "",
                    type: "longTerm"
                }
                let resp = await API.diagonsisList(data);
                if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
                    setDiagonsisSubCategoryList(resp?.data?.masterDiagonsisCategoryList.data)
                }
            }
        })();
    }, []);
    return (
        <div className="main-content">
            <Grid fluid>
                <Row>
                    {
                        DiagonsisSubCategoryList && DiagonsisSubCategoryList.length > 0 &&
                        <Col md={12}>
                            <Card title={<legend className="line-removes">
                                <div className='reset-bottom-btn'>
                                    <Button className="inacti-button btn btn-info" bsStyle="info" style={{ fontSize: '14px' }} onClick={(e) => { history.goBack() }}>Back</Button>
                                </div>
                            </legend>}

                                content={
                                    <div>
                                        <FormGroup className="" style={{ textAlign: "center" }}>
                                            <div style={{ paddingRight: "10px", width: "auto" }} className="form-check form-check-emr form-check-inline">
                                                <input className="form-check-input" type="radio" name="catTypeEnlarge" checked={type === "longTerm"} onClick={() => { getDiagonsisCategory("longTerm"); }} id="longTermEnlarge" value="value1" />
                                                <label className="form-check-label" for="longTermEnlarge"><b>Long term</b>  </label>
                                            </div>
                                            <div style={{ paddingRight: "0px", width: "auto" }} className="form-check form-check-emr form-check-inline">
                                                <input className="form-check-input" type="radio" name="catTypeEnlarge" checked={type === "intercurrent"} onClick={() => { getDiagonsisCategory("intercurrent"); }} id="intercurrentEnlarge" value="value2" />
                                                <label className="form-check-label" for="intercurrentEnlarge"><b>Intercurrent</b></label>
                                            </div>
                                        </FormGroup>
                                        <div className='drag-drop-wrapper'>
                                            {
                                                DiagonsisSubCategoryList &&
                                                DiagonsisSubCategoryList.map((item, index) => (
                                                    <div className='drag-first-div' style={{ height: "50px", width: "100%", textAlign: "center", maxWidth: '25% !important' }}
                                                        onDragStart={(e) => dragStart(e, index)}
                                                        onDragEnter={(e) => dragEnter(e, index)}
                                                        onDragEnd={drop}
                                                        key={index}
                                                        draggable>
                                                        <div className='item-list'>
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='reset-bottom-btn'>
                                            <Button className='inacti-button btn btn-info' bsStyle="info" style={{ fontSize: '14px' }} onClick={() => resetOrder()}>Reset</Button>
                                        </div>
                                    </div>
                                }

                            />
                        </Col>
                    }
                    {DiagonsisSubCategoryList && DiagonsisSubCategoryList.length == 0 &&
                        <Col md={12}>
                            <Card title={<legend className="line-removes" >
                                <div className='reset-bottom-btn'>
                                    <Button className="inacti-button btn btn-info" bsStyle="info" style={{ fontSize: '14px' }} onClick={(e) => { history.goBack() }}>Back</Button>
                                </div>

                            </legend>}

                                content={
                                    <div style={{ textAlign: "center" }}>
                                        <b>No Data Found.</b>
                                    </div>
                                }
                            />
                        </Col>
                    }
                </Row>
            </Grid>
        </div>
    );
};
export default EditDiagnosisSubCategorySequence
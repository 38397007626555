/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/low_calorie_food'
/* Add Serving  list and Add form */

export const addLowCalorieFoodRequest = () => ({
    type: actionTypes.GET_ADD_LOW_CALORIE_FOOD_REQUEST
})
export const addLowCalorieFoodSuccess = (response) => ({
    type: actionTypes.GET_ADD_LOW_CALORIE_FOOD_SUCCESS,
    payload: {
        response,
    }
})
export const addLowCalorieFoodError = (error) => ({
    type: actionTypes.GET_ADD_LOW_CALORIE_FOOD_ERROR,
    payload: {
        error,
    }
})

export const addLowCalorieFoodAction = (data) => {
    return dispatch => {
        dispatch(addLowCalorieFoodRequest())
        return API.addLowCalorieFood(data)
            .then(response => {
                dispatch(addLowCalorieFoodSuccess(response.data))
            })
            .catch(error => {
                dispatch(addLowCalorieFoodError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Link list and Add form */
export const updateLowCalorieFoodRequest = () => ({
    type: actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_REQUEST
})
export const updateLowCalorieFoodSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_SUCCESS,
    payload: {
        response,
    }
})
export const updateLowCalorieFoodError = (error) => ({
    type: actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_ERROR,
    payload: {
        error,
    }
})
export const updateLowCalorieFoodAction = (data) => {
    return dispatch => {
        dispatch(updateLowCalorieFoodRequest())

        return API.updateLowCalorieFood(data)
            .then(response => {
                dispatch(updateLowCalorieFoodSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateLowCalorieFoodError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link list  */
export const LowCalorieFoodListRequest = () => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_LIST_REQUEST
})
export const LowCalorieFoodListSuccess = (response) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const LowCalorieFoodListError = (error) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_LIST_ERROR,
    payload: {
        error
    }
})
export const LowCalorieFoodListAction = (data, limitStatus) => {
    return dispatch => {
        dispatch(LowCalorieFoodListRequest());
        let FormData = '';
        if (data) {
            FormData = data;
        } else {
            FormData = {
                limit: 10,
                offset: 0,
                order: "name",
                direction: "asc",
            }

        }
        if (limitStatus) {
            FormData.limitStatus = limitStatus;
        }

        return API.LowCalorieFoodList(FormData)
            .then(response => {
                dispatch(LowCalorieFoodListSuccess(response.data))
            })
            .catch(error => {
                dispatch(LowCalorieFoodListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link Status Change  */
export const LowCalorieFoodStatusRequest = () => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_REQUEST
})
export const LowCalorieFoodChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const LowCalorieFoodChangeStatusError = (error) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const LowCalorieFoodStatusAction = (data) => {
    return dispatch => {
        dispatch(LowCalorieFoodStatusRequest())
        return API.changeLowCalorieFoodStatus(data)
            .then(response => {

                dispatch(LowCalorieFoodChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(LowCalorieFoodChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


/* Link Status Change  */
export const LowCalorieFoodDeleteRequest = () => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_DELETE_REQUEST
})
export const LowCalorieFoodDeleteSuccess = (response) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_DELETE_SUCCESS,
    payload: {
        response,
    }
})
export const LowCalorieFoodDeleteError = (error) => ({
    type: actionTypes.GET_LOW_CALORIE_FOOD_DELETE_ERROR,
    payload: {
        error
    }
})
export const LowCalorieFoodDeleteAction = (data) => {
    return dispatch => {
        dispatch(LowCalorieFoodDeleteRequest())
        return API.deleteLowCalorieFood(data)
            .then(response => {
                dispatch(LowCalorieFoodDeleteSuccess(response.data))
            })
            .catch(error => {
                dispatch(LowCalorieFoodDeleteError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//Upload File
// export const uploadFileRequest = () => ({
//     type: actionTypes.POST_UPLOAD_FILE_REQUEST
// })
// export const uploadFileSuccess = (response) => ({
//     type: actionTypes.GET_UPLOAD_FILE_SUCCESS,
//     payload: {
//         response,
//     }
// })
// export const uploadFileError = (error) => ({
//     type: actionTypes.GET_UPLOAD_FILE_ERROR,
//     payload: {
//         error
//     }
// })
// export const uploadFileAction = (data) => {
//     return dispatch => {
//         dispatch(uploadFileRequest())
//         // const FormData = {
//         //     file:data
//         // }

//         let formData = new FormData();
//         formData.append('file',data);
//         return API.uploadFile(formData)
//         .then(response => {
//             dispatch(uploadFileSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(uploadFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
//         })
//     }
// }

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
import * as API from 'Front/api/login'


//-- Login
export const loginUserRequest = () => ({
    type: actionTypes.GET_SIGNIN_USER_REQUEST
})
export const loginUserSuccess = (response) => ({
    type: actionTypes.GET_SIGNIN_USER_SUCCESS,
    payload: {
        response,
    }
})
export const loginUserError = (error) => ({
    type: actionTypes.GET_SIGNIN_USER_ERROR,
    payload: {
        error
    }
})
export const loginAction = (data) => {
    return dispatch => {
        dispatch(loginUserRequest())
        const FormData = {
            username: data.formData.username,
            password: data.formData.password
        }
        return API.signInUser(FormData)
            .then(response => {

                dispatch(loginUserSuccess(response.data))
            })
            .catch(error => {
                dispatch(loginUserError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//-- Forgot Password
export const forgotPasswordRequest = () => ({
    type: actionTypes.GET_FORGOT_PASSWARD_REQUEST
})
export const forgotPasswordSuccess = (response) => ({
    type: actionTypes.GET_FORGOT_PASSWARD_SUCCESS,
    payload: {
        response,
    }
})
export const forgotPasswordError = (error) => ({
    type: actionTypes.GET_FORGOT_PASSWARD_ERROR,
    payload: {
        error
    }
})
export const forgotPasswordAction = (data) => {
    return dispatch => {
        dispatch(forgotPasswordRequest())

        return API.forgotPassword(data)
            .then(response => {
                dispatch(forgotPasswordSuccess(response.data))
            })
            .catch(error => {
                dispatch(forgotPasswordError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

//-- Reset Password
export const resetPasswordRequest = () => ({
    type: actionTypes.GET_RESET_PASSWARD_REQUEST
})
export const resetPasswordSuccess = (response) => ({
    type: actionTypes.GET_RESET_PASSWARD_SUCCESS,
    payload: {
        response,
    }
})
export const resetPasswordError = (error) => ({
    type: actionTypes.GET_RESET_PASSWARD_ERROR,
    payload: {
        error
    }
})
export const resetPasswordAction = (data) => {
    return dispatch => {
        dispatch(resetPasswordRequest())

        return API.resetPassword(data)
            .then(response => {
                dispatch(resetPasswordSuccess(response.data))
            })
            .catch(error => {
                dispatch(resetPasswordError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


//-- User Detail
export const userDetailRequest = () => ({
    type: actionTypes.GET_USER_DETAIL_REQUEST
})
export const userDetailSuccess = (response) => ({
    type: actionTypes.GET_USER_DETAIL_SUCCESS,
    payload: {
        response,
    }
})
export const userDetailError = (error) => ({
    type: actionTypes.GET_USER_DETAIL_ERROR,
    payload: {
        error
    }
})
export const userDetailAction = (data) => {
    return dispatch => {
        dispatch(userDetailRequest())

        return API.UserDetail()
            .then(response => {
                dispatch(userDetailSuccess(response.data))
            })
            .catch(error => {
                dispatch(userDetailError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
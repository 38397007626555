/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import Card from "Admin/components/Card/Card.jsx";
import * as API from '../../../../api/emr'
import { languageList } from "../../../../api/language";
import { Right } from "react-bootstrap/lib/Media";

const AddEditDrugInstruction = (props) => {
  let history = useHistory();

  const [formData, setFormData] = useState({
    instructionInDiffLanguage: {},
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [mode, setMode] = useState("add");
  const [instructionId, setInstructionId] = useState("");
  const [languageArr, setLanguageArr] = useState([]);
  const [isInstructionAlreadyExist, setIsInstructionAlreadyExist] =
    useState(false);
  const [istructionAlreadyExistMsg, setInstructionAlreadyExistMsg] =
    useState("");

  const getLanguageList = async () => {
    let resp = await languageList({ limitStatus: true });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      setLanguageArr(resp.data.data.data);
    }
  };

  const inputHandler = (e, language) => {
    if (isInstructionAlreadyExist) {
      setIsInstructionAlreadyExist(false);
      setInstructionAlreadyExistMsg("");
    }
    let saveFormData = formData;

    if (e.target.name === "recommendedType")
      saveFormData[e.target.name] = e.target.value;
    else {
      let str = e.target.value.replace(/\s+/g, " ");
      saveFormData.instructionInDiffLanguage[language] = str.trimStart();
      saveFormData[e.target.name] = str.trimStart();
    }
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;

    if (post.instructionInDiffLanguage_1 && post.instructionInDiffLanguage_1 != "") {
      errArr.instructionInDiffLanguage_1 = "";
    } else {
      errArr.instructionInDiffLanguage_1 = "error";
      errflag = false;
    }
    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (validate()) {
      let sentData = new FormData();
      sentData.append(
        "instructionInDiffLanguage",
        JSON.stringify(formData.instructionInDiffLanguage)
      );
      let resp = "";
      if (mode === "add") {
        resp = await API.addDrugInstruction(sentData);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setIsInstructionAlreadyExist(true);
            setInstructionAlreadyExistMsg(resp.data.msg);
          } else
            history.push({
              pathname: "/admin/drug-instruction-list",
              from: "add",
            });
        }
      } else {
        sentData.append("instructionId", instructionId);
        resp = await API.updateDrugInstruction(sentData);
        setIsLoading(false);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setIsInstructionAlreadyExist(true);
            setInstructionAlreadyExistMsg(resp.data.msg);
          } else
            history.push({
              pathname: "/admin/drug-instruction-list",
              from: "edit",
            });
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getLanguageList();
    let row = props.location.state?.row;
    let saveFormData = formData;
    if (row !== undefined && row) {
      setMode("edit");
      setInstructionId(row._id);
      saveFormData["instructionInDiffLanguage"] = row.instructionInDiffLanguage;
      saveFormData["instructionInDiffLanguage_1"] = row.instructionInDiffLanguage[1];
      setFormData({ ...formData, ...saveFormData });
    }
  }, []);
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>

            <Card
              title={
                <Row>
                  <Col md={12} style={{ textAlign: 'Right' }}>
                    <Button
                      // className="go-back-btn-1"
                      bsStyle="info"
                      onClick={(e) => {
                        history.goBack();
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <fieldset style={{ width: '100%' }}>
                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={2}>
                        Instruction <span className="star">*</span>
                      </Col>
                      <Col sm={6} className="spcc">
                        {languageArr.map(function (lang, key) {
                          return (
                            <><label>{lang.name}</label>
                              <FormControl
                                key={lang.lanId}
                                autoComplete="off"
                                type="text"
                                value={
                                  formData.instructionInDiffLanguage[lang.lanId]
                                    ? formData.instructionInDiffLanguage[lang.lanId]
                                    : ""
                                }
                                name={"instructionInDiffLanguage_" + lang.lanId}
                                id={"instructionInDiffLanguage_" + lang.lanId}
                                placeholder={lang.name}
                                onChange={(event) => {
                                  inputHandler(event, lang.lanId);
                                }}
                                className={
                                  "spc-in insulin-input " +
                                  (lang.lanId === 1
                                    ? errors.instructionInDiffLanguage_1
                                      ? errors.instructionInDiffLanguage_1
                                      : ""
                                    : "")
                                }
                              /></>
                          );
                        })}
                      </Col>
                    </FormGroup>
                  </fieldset>

                  <div className="col-sm-10">
                    <legend className="line-removes" style={{ textAlign: 'center', fontSize: '18px' }}>
                      {isInstructionAlreadyExist &&
                        <span className="star">{istructionAlreadyExistMsg}</span>
                      }
                    </legend>
                  </div>

                  <fieldset style={{ width: '100%' }}>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Col md={12}>
                          <button
                            type="button"
                            className="btn-fill btn-wd btn btn-primary"
                            onClick={(e) => {
                              submitHandler(e);
                            }}
                            disabled={isLoading}
                          >
                            {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                            Submit
                          </button>
                          &nbsp;
                        </Col>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AddEditDrugInstruction
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import loadingImg from 'Admin/assets/img/loading.gif';
import { addClinicInvitationAction, editClinicInvitationAction } from 'Admin/actions/clinic_invitation';
import $ from "jquery";


let Validator = require('validatorjs');
let formArr = {}
let rules = {
    clinicName: 'required',
    email: 'required|email',
    doctorName: 'required',
    phone: 'required|digits_between:10,12',
    city: 'required',
};
let mess = {
    required: 'This field is required',
    email: 'Invalid Email',
    numeric: 'Enter numeric digits'
};
let validation = [];


class AddInvitationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formArr: [],
            showProcessing: false,
            isLoading: false,
            formData: {},
            emailError: "",
            clinicNameError: "",
        }

        validation = new Validator(this.state, rules, mess);
        validation.passes();
        validation.fails();

        this.callAction = this.callAction.bind(this)
    }

    componentDidMount() {
        if (this.props && this.props.location && this.props.location.state) {
            let formData = this.state.formData;
            formData.invitationId = this.props.location.state._id;
            formData.clinicName = this.props.location.state.clinicName;
            formData.email = this.props.location.state.email;
            formData.address = this.props.location.state.address;
            formData.phone = this.props.location.state.phone;
            formData.city = this.props.location.state.city;
            formData.doctorName = this.props.location.state.doctorName;
            this.setState({ formData: formData });
            $('.navbar-brand').html('Update Invitations');
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.responseData && nextProps.responseData.statusCode === 200) {
            this.setState({ showProcessing: false });
            this.props.handleClick('success', nextProps.responseData.msg)
            this.props.history.push(`/admin/clinic-invitation`)
        }

        if (nextProps.isInvitationError !== this.props.isInvitationError) {
            //console.log(nextProps)
            this.setState({ showProcessing: false });
            if (nextProps.responseData.errors) {
                nextProps.responseData.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: key.msg });
                });
            }
        }
        //console.log(this.state);
        // if(nextProps && nextProps.responseData && nextProps.responseData.code === 500){
        //     this.props.handleClick('errors', nextProps.responseData.msg)
        // }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    handleChange(e) {
        e.preventDefault();
        const { formData } = this.state;

        if (e.target.name === 'phone') {
            if (e.target.value && e.target.value.length != 10) {
                //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
            } else {
                this.setState({ phoneError: null })
            }
        }

        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData })
    };

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({ formArr });
        }
        if (validation.passes()) { return 1; }
    }

    callAction(e) {
        this.setState({ isLoading: true });
        e.preventDefault();
        const { formData } = this.state;

        this.setState({ emailError: "" });
        this.setState({ clinicNameError: "" });

        if (this.allValidate(false)) {
            this.setState({ showProcessing: true });
            if (formData && formData.invitationId) {
                this.props.editClinicInvitationAction(formData);
            } else {
                this.props.addClinicInvitationAction(formData);
            }
        }
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isButtonDisabled: false });
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { formData } = this.state;
        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Form horizontal onSubmit={this.callAction.bind(this)} >
                                <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                                    <img src={loadingImg} alt="Loading" width="40px" />
                                    <center>Logging In - Please Wait</center>
                                </div>
                                <Card
                                    title={
                                        <legend>General information
                                            <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                        </legend>
                                    }
                                    content={
                                        <div>
                                            <FormGroup>
                                                <Col sm={6}>
                                                    <Col componentClass={ControlLabel} >
                                                        Clinic Name <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" autocomplete="off" name="clinicName" value={this.state.formData.clinicName} onChange={e => { this.handleChange(e); }} autoComplete="off" placeholder="Clinic Name" />
                                                    <span className="errorMsg">{this.state.clinicNameError}{this.state.formArr.clinicName && validation.errors.first('clinicName')}</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <Col componentClass={ControlLabel} >
                                                        Clinic Email <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="email" name="email" onChange={e => { this.handleChange(e); }} value={this.state.formData.email} autoComplete="off" placeholder="Clinic Email" />
                                                    <span className="errorMsg" refs="email">{this.state.emailError}{this.state.formArr.email && validation.errors.first('email')}</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <Col componentClass={ControlLabel} >
                                                        Doctor Name <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" autocomplete="off" name="doctorName" onChange={e => { this.handleChange(e); }} value={this.state.formData.doctorName} autoComplete="off" placeholder="Doctor Name" />
                                                    <span className="errorMsg">{this.state.doctorNameError}{this.state.formArr.doctorName && validation.errors.first('doctorName')}</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <Col componentClass={ControlLabel} >
                                                        Clinic Contact <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" autocomplete="off" maxLength={12} name="phone" onChange={e => { this.handleChange(e); }} value={this.state.formData.phone} autoComplete="off" placeholder="Clinic Contact" />
                                                    <span className="errorMsg" refs="phone">{this.state.phoneError}{this.state.formArr.phone && validation.errors.first('phone')}</span>
                                                </Col>

                                                <Col sm={6}>
                                                    <Col componentClass={ControlLabel} >
                                                        City <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" autocomplete="off" name="city" onChange={e => { this.handleChange(e); }} value={this.state.formData.city} autoComplete="off" placeholder="City" />
                                                    <span className="errorMsg" refs="city">{this.state.formArr.city && validation.errors.first('city')}</span>
                                                </Col>
                                            </FormGroup>

                                        </div>
                                    }
                                    ftTextCenter
                                    legend={<div>
                                        <Button
                                            type="submit"
                                            disabled={isLoading || this.props.disabled}
                                            fill bsStyle="info">  {isLoading ? (
                                                <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                            ) : (
                                                this.props.children
                                            )}
                                            {formData && formData.invitationId ? "Update" : "Submit"}
                                        </Button>
                                    </div>
                                    }
                                />
                            </Form>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        responseType: state.clinicInvitation.type,
        responseData: state.clinicInvitation.response,

        isInvitationList: state.clinicInvitation.isInvitationList,
        isInvitation: state.clinicInvitation.isInvitation,
        isInvitationError: state.clinicInvitation.isInvitationError,
    }
}

export default withRouter(connect(mapStateToProps, { addClinicInvitationAction, editClinicInvitationAction })(AddInvitationPage));

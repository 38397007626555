/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Modal,
} from "react-bootstrap";
import * as API from "Front/api/billingCycle";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

const BillingCycle = () => {
  let clinicId = localStorage.clinicId;
  const [billingDay, setBillingDay] = useState("");
  const [alert, setAlert] = useState(null);
  const notificationSystem = useRef(null);

  const fetchBillingDay = async () => {
    let data = {
      clinicId,
    };
    let res = await API.getBillingCycle(data);
    setBillingDay(
      res.data.data.length > 0 ? res.data.data[0].numberOfDays : 14
    );
  };

  useEffect(() => {
    fetchBillingDay();
  }, []);

  let items = [];
  for (let i = 1; i <= 30; i++) {
    items.push(<option value={i}>{i}</option>);
  }

  const successAlert = (msg) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    setTimeout(function () {
      setAlert(null);
    }, 3000);
  };

  const handleSelect = async (e) => {
    setBillingDay(e.target.value);
    let numberOfDays = e.target.value,
      paymentAmount = "Yes";
    let paymentSetting = [
      {
        numberOfDays,
        paymentAmount,
      },
    ];
    let data = {
      clinicId,
      paymentSetting,
    };
    let resp = await API.addBillingCycle(data);
    if (resp.data.status === "Success") {
      successAlert("You have successfully Update Validity Period");
    }
  };

  return (
    <div className="preference-to-set">
      {alert}
      <NotificationSystem ref={notificationSystem} style={style} />
      <div className="card">
        <h4 className="title">
          Consultation Validity Period To Charge Fee
          <FormControl
            style={{ width: "auto" }}
            componentClass="select"
            placeholder="select"
            name="billing"
            onChange={handleSelect}
            value={billingDay}
          >
            {items}
          </FormControl>
          Days
        </h4>
        {/* <FormGroup controlId="formControlsSelect">
     
        </FormGroup> */}
        <div className="content"></div>
      </div>
    </div>
  );
};
export default withRouter(BillingCycle);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken, getRequestWithToken } from "./helper";

export const addIcons = (data) => {
    return postRequestWithTokenFile(data, "admin/add-tag");
}
export const updateIcons = (data) => {
    return postRequestWithTokenFile(data, "admin/update-tag");
}
export const iconsList = (data) =>
    postRequestWithToken(data, "admin/tag-list");
export const iconsChangeStatus = (data) =>
    postRequestWithToken(
        data,
        `admin/tag-status`
    );

export const iconsDelete = (data) => {
    return postRequestWithToken(data, "admin/tag-delete");
}

export const uploadFiles = (data) => {
    return postRequestWithTokenFile(data, 'upload-file');
}



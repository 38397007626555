/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Carousel,
} from "react-bootstrap";
import Card from "Admin/components/Card/Card.jsx";
import * as API from "../../../../api/emr";

const AddEditDiagnosisSubCategory = (props) => {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isSpecialCharacter, setIsSpecialChar] = useState(false);
  const [mode, setMode] = useState("add");
  const [diagnosisSubCategoryId, setDiagnosisSubCategoryId] = useState("");
  const [subCategoryAlreadyExist, setSubCategoryAlreadyExist] = useState(false);
  const [subCategoryAlreadyExistMsg, setSubCategoryAlreadyExistMsg] =
    useState(false);
  const [daignosisCategoryList, setDaignosisCategoryList] = useState([]);

  const inputHandler = (e) => {
    let saveFormData = formData;

    if (e.target.name === "diagnosisCategoryId") {
      if (e.target.value != "") {
        let tempObj = {};
        tempObj = daignosisCategoryList.find((x) => x._id === e.target.value);
        saveFormData["diagnosisCategoryName"] = tempObj.name;
        saveFormData["diagnosisCategoryId"] = e.target.value;
      } else {
        saveFormData["diagnosisCategoryName"] = "";
        saveFormData["diagnosisCategoryId"] = "";
      }
    }

    if (e.target.name === "diagnosisSubCategoryName") {
      let str = e.target.value.replace(/\s+/g, " ");
      saveFormData[e.target.name] = str.trimStart();
    }

    if (e.target.name === "type") {
      saveFormData[e.target.name] = e.target.value;
    }

    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.diagnosisCategoryId && post.diagnosisCategoryId != "") {
      errArr.diagnosisCategoryId = "";
    } else {
      errArr.diagnosisCategoryId = "error";
      errflag = false;
    }

    if (post.diagnosisSubCategoryName && post.diagnosisSubCategoryName != "") {
      errArr.diagnosisSubCategoryName = "";
    } else {
      errArr.diagnosisSubCategoryName = "error";
      errflag = false;
    }

    if (post.type && post.type != "") {
      errArr.type = "";
    } else {
      errArr.type = "error";
      errflag = false;
    }

    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (validate()) {
      let sentData = new FormData();
      sentData.append(
        "diagnosisSubCategoryName",
        formData.diagnosisSubCategoryName
      );
      sentData.append("diagnosisCategoryId", formData.diagnosisCategoryId);
      sentData.append("diagonsisCategoryName", formData.diagonsisCategoryName);
      sentData.append("type", formData.type);

      let resp = "";
      if (mode === "add") {
        resp = await API.addDiagnosisSubCategory(sentData);
        setIsLoading(false);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setSubCategoryAlreadyExist(true);
            setSubCategoryAlreadyExistMsg(resp.data.msg);
          } else
            history.push({
              pathname: "/admin/diagnosis-sub-category-list",
              from: "add",
            });
        }
      } else {
        sentData.append("id", diagnosisSubCategoryId);
        resp = await API.updateDiagnosisSubCategory(sentData);
        setIsLoading(false);
        if (resp.data.statusCode == 200 && resp.data.status === "Success") {
          if (resp.data.flag && resp.data.flag === "existing") {
            setSubCategoryAlreadyExist(true);
            setSubCategoryAlreadyExistMsg(
              resp.data.msg + " " + resp.data.diagnosisSubCategoryName
            );
          } else
            history.push({
              pathname: "/admin/diagnosis-sub-category-list",
              from: "edit",
            });
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getdaignosisCategoryList = async (row) => {
    let resp = await API.daignosisCategoryListWithoutPagination();
    if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
      setDaignosisCategoryList(resp.data.data);
      let saveFormData = formData;
      saveFormData["diagnosisCategoryId"] = "";
      saveFormData["diagnosisSubCategoryName"] = "";
      if (row !== undefined && row) {
        setMode("edit");
        setDiagnosisSubCategoryId(row._id);
        saveFormData["diagnosisCategoryId"] = row.diagonsisCategoryId;
        saveFormData["diagnosisSubCategoryName"] = row.name;
        saveFormData["type"] = row.type;
        let tempObj = resp.data.data.find(
          (x) => x.name === row.diagonsisCategoryName
        );
        saveFormData["diagonsisCategoryName"] = tempObj.name;

        setFormData({ ...formData, ...saveFormData });
      }
    }
  };

  useEffect(() => {
    let row = props.location.state?.row;
    getdaignosisCategoryList(row);
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form horizontal className="add-supportsystem_form">
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>
                              Diagnosis<span className="star">*</span>
                            </ControlLabel>
                            <FormControl
                              type="text"
                              name="diagnosisSubCategoryName"
                              id="diagnosisSubCategoryName"
                              placeholder="Diagnosis"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              autocomplete="off"
                              value={
                                formData.diagnosisSubCategoryName
                                  ? formData.diagnosisSubCategoryName
                                  : ""
                              }
                              className={
                                "insulin-input " +
                                (errors.diagnosisSubCategoryName
                                  ? errors.diagnosisSubCategoryName
                                  : "")
                              }
                            />
                            {subCategoryAlreadyExist && (
                              <span className="star">
                                {subCategoryAlreadyExistMsg}
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>
                              Category<span className="star">*</span>
                            </ControlLabel>
                            <select
                              className={
                                "insulin-input form-control " +
                                (errors.diagnosisCategoryId
                                  ? errors.diagnosisCategoryId
                                  : "")
                              }
                              name="diagnosisCategoryId"
                              id="diagnosisCategoryId"
                              value={formData.diagnosisCategoryId}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            >
                              <option value="">Select Category</option>
                              {daignosisCategoryList.map(function (item, key) {
                                return (
                                  <option value={item._id}>{item.name}</option>
                                );
                              })}
                            </select>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Type</ControlLabel>
                            <select
                              className={
                                "insulin-input form-control " +
                                (errors.type ? errors.type : "")
                              }
                              name="type"
                              id="type"
                              value={formData.type}
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                            >
                              <option value="">Select Type</option>
                              <option value="longTerm">Long Term</option>
                              <option value="intercurrent">Intercurrent</option>
                            </select>
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <button
                              type="button"
                              className="btn-fill btn-wd btn btn-primary"
                              onClick={(e) => {
                                submitHandler(e);
                              }}
                              disabled={isLoading}
                            >
                              {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                              Submit
                            </button>
                            &nbsp;
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default AddEditDiagnosisSubCategory;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { foodListAction, foodCartListAction } from 'Admin/actions/food';

import Loading from 'react-loading';
import { foodCategoryListAction } from 'Admin/actions/food_category';
import { languageListAction } from 'Admin/actions/language';
import { appConstants } from 'Admin/_constants/app.constants';
import SecurityUrlComponent from '../../../../components/Common/SecurityUrlComponent';
import ImageComponent from "Front/components/common/common.js"
var count = 1;

class CreateMeal extends Component {
    constructor(props) {

        super(props);
        var UpdateData = {};
        if (this.props.location.state && this.props.location.state.modelViewData) {
            UpdateData = this.props.location.state.modelViewData;
        }

        this.state = {
            foodCategoryList: [],
            languageList: [],
            foodList: [],
            foodCart: [],
            foodCartItem: [],
            UpdateData: UpdateData,
            isLoading: true,
            userRow: null,
            searchText: "",
            formData: {
                foodCategoryId: "",
                direction: 'desc',
                order: "createdAt",
                // offset: 0,
                // limit: 10,
                lang: "1",
                status: "active",
                veg: "",
                search: "",
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
    }

    componentDidMount() {

        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.foodCategoryListAction(data);
        this.props.languageListAction(data);

        //this.props.foodListAction(this.state.formData);
        let foodId = [];
        if (this.state.UpdateData.group && this.state.UpdateData.group.length > 0) {

            for (let i in this.state.UpdateData.group) {
                foodId.push(this.state.UpdateData.group[i].foodId);
            }
            //this.props.foodCartListAction({foodId:foodId});
        }
        let formData = this.state.formData;
        formData['foodId'] = foodId;
        formData['limit'] = 1000;
        this.props.foodListAction(formData);
    }

    componentWillReceiveProps(nextProps) {

        let UpdateData = {};
        let fdCard2 = this.state.foodCart;
        let fdCard = [...fdCard2];

        if (nextProps.isFoodList !== this.props.isFoodList) {

            let List = nextProps.FoodList.data.data;
            UpdateData = this.state.UpdateData;

            let foodCart = this.state.foodCartItem;

            for (let i in List) {
                // let foodCart = this.state.foodCartItem
                var localindex_index = foodCart.map(function (el) {
                    return el._id;
                }).indexOf(List[i]._id);
                if (localindex_index != -1) {
                    List[i]['add_cart'] = true;
                } else {
                    List[i]['add_cart'] = false;
                }

                if (UpdateData.group && fdCard.length === 0) {
                    for (let j in UpdateData.group) {
                        if (UpdateData.group[j].foodId == List[i]._id) {
                            //List[i]['qty'] = UpdateData.group[j].qty;
                            List[i]['insertQty'] = UpdateData.group[j].qty;
                            List[i]['newQty'] = UpdateData.group[j].qty;
                            this.handleChangeAddCart(List[i]);
                        }
                    }
                }

            }

            this.setState({
                foodList: List,
                totalCount: nextProps.FoodList.data.count,
                foodCartItem: foodCart
            });
        }


        if (nextProps.isFoodCategoryList !== this.props.isFoodCategoryList) {
            this.setState({ foodCategoryList: nextProps.FoodCategoryList.data.data });

        }

        // if (nextProps.isFoodCartList !== this.props.isFoodCartList) {
        //      if(nextProps.FoodCartList.data.data && nextProps.FoodCartList.data.data.length > 0){
        //          for(let i in nextProps.FoodCartList.data.data){
        //             nextProps.FoodCartList.data.data[i].add_cart = true;
        //          }

        //      }
        //     this.setState({
        //         foodCart: nextProps.FoodCartList.data.data
        //     });
        // }


        if (nextProps.isLanguageList !== this.props.isLanguageList) {

            this.setState({ languageList: nextProps.LanguageList.data });
        }

        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }



    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-food";
        this.clicked = "";
    }

    handleChangeCategory(data) {

        let formData = this.state.formData;
        formData['foodCategoryId'] = data._id;
        formData['search'] = "";
        if (formData.foodId) {
            delete formData.foodId;
        }

        this.setState({ formData: formData });
        this.props.foodListAction(this.state.formData);

        // let index = event.target.selectedIndex;
        // let formData = this.state.formData;
        // formData['foodCategoryId'] = event.target.value
        // this.setState({ formData: formData });
    }

    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        this.props.foodListAction(formData)
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                data['veg'] = this.state.formData.veg;
                this.setState({ sizePerPage: 10 })
                this.props.foodListAction(data);
            }
        } else {
            this.componentDidMount();
        }
        this.setState({ searchText: text });
    }

    handleChangeAddCart(row) {

        if (row.add_cart == false) {
            row.add_cart = true;
        } else {
            row.add_cart = false;
        }

        var foodListData = this.state.foodList
        var localindex = foodListData.map(function (el) {
            return el._id;
        }).indexOf(row._id);
        if (localindex != -1) {
            foodListData[localindex].add_cart = row.add_cart;
            this.setState({ foodList: foodListData });
        }

        var foodCartItem = this.state.foodCartItem;
        var localindex_index = this.state.foodCartItem.map(function (el) {
            return el._id;
        }).indexOf(row._id);
        if (localindex_index != -1) {
            foodCartItem.splice(localindex_index, 1);
        } else {
            foodCartItem.push(row);
        }

        this.setState({ foodCartItem: foodCartItem });

        var foodCartData = this.state.foodCart;

        var localindex_index = this.state.foodCart.map(function (el) {
            return el.categoryName;
        }).indexOf(row.categoryData[0].name);
        if (localindex_index != -1) {

            if (this.state.foodCart[localindex_index].foodCardList) {
                let card_item = this.state.foodCart[localindex_index].foodCardList
                var card_index = card_item.map(function (el) {
                    return el._id;
                }).indexOf(row._id);
                if (card_index != -1) {
                    foodCartData[localindex_index].foodCardList.splice(card_index, 1);
                    if (foodCartData[localindex_index].foodCardList.length == 0) {
                        foodCartData.splice(localindex_index, 1)
                    }
                } else {
                    foodCartData[localindex_index].foodCardList.push(row)
                }
            }

        } else {
            let food_card = [];
            food_card['categoryName'] = row.categoryData[0].name;
            food_card['foodCardList'] = [];
            food_card['foodCardList'].push(row)
            foodCartData.push(food_card);

        }

        this.setState({ foodCart: foodCartData });

    }


    handleChangeSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        formData['search'] = e.target.value
        this.props.foodListAction(formData)
    }

    handleChangeReset() {
        //this.setState({ foodList: [] });
        this.setState({ foodCart: [] });
        this.setState({ foodCartItem: [] });
    }

    handleChangeFoodCard() {
        if (this.state.foodCartItem.length > 0) {
            this.props.history.push({
                state: { "foodCart": this.state.foodCart, UpdateData: this.state.UpdateData }
            });
            this.props.history.location.pathname = "/admin/food-cart";
            // this.props.history.push({
            //      pathname: "food-cart",
            //     state: { "foodCart": this.state.foodCart }
            // });

        }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {
        let _this = this;
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <Col componentClass={ControlLabel}>
                                                        Food Category
                                                    </Col>
                                                </div>

                                                {this.state.foodCategoryList.map(function (item) {
                                                    return (
                                                        <ImageComponent
                                                            style={{ "width": "36px", "height": "36px" }}
                                                            data={item.icon}
                                                            setViewBoxData={(event) => { _this.handleChangeCategory(item) }}
                                                            className="category-img"
                                                            alt={item.name}
                                                        />
                                                    )
                                                })}

                                            </Col>

                                        </Row>&nbsp;
                                        <Row>
                                            <Col md={12}>
                                                <Col componentClass={ControlLabel}>
                                                    Food List
                                                </Col>
                                                <div className="full-inputs">
                                                    <input type="text" name="search" value={this.state.formData.search} onChange={(event) => { _this.handleChangeSearch(event) }} />
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="low-ff">
                                                    <Col md={8}>
                                                        {this.state.foodList.map(function (data) {

                                                            return (
                                                                <Col md={3} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                                    <div className="inline-bb">
                                                                        <div>
                                                                            <div>
                                                                                {data.name['1']}</div>
                                                                            <div>{data.calorie + ' kcal/' + data.qty + " "}{data.servingSizeData[0] ? data.servingSizeData[0].name['1'] : ""}</div>
                                                                            <div>{appConstants.vegNonVeg(data.veg)}</div>
                                                                            <div className="checq-bo">
                                                                                <input type="checkbox" name={"add_cart" + data._id} id={data._id} onChange={(event) => { _this.handleChangeAddCart(data) }} checked={data.add_cart} />
                                                                                <div>{data.add_cart}</div>
                                                                            </div>

                                                                            {/* <div>{data.info.english}</div> */}
                                                                            <div>{data.info ? data.info['1'] : ''}</div>

                                                                        </div>
                                                                    </div>
                                                                </Col>)
                                                        })}
                                                    </Col>
                                                    <Col md={4}>
                                                        <Col className="food-label" componentClass={ControlLabel}>
                                                            Food Cart
                                                        </Col>
                                                        <Button className="m-btmm" bsStyle="info" onClick={() => { _this.handleChangeReset() }}>Reset</Button>
                                                        <Col md={8}>
                                                            {this.state.foodCart.map(function (foodCart) {
                                                                return (
                                                                    foodCart.foodCardList.map(function (data) {
                                                                        return (<div className="inline-bb">
                                                                            <div>
                                                                                <div>
                                                                                    {data.name['1']}</div>
                                                                                <div>{data.calorie + ' kcal/' + data.qty + " "}{data.servingSizeData[0] ? data.servingSizeData[0].name['1'] : ""}</div>

                                                                            </div>
                                                                        </div>)

                                                                    }))
                                                            })}
                                                        </Col>
                                                        <Button className="m-btmm" bsStyle="info" onClick={() => { _this.handleChangeFoodCard() }}>Add to food cart</Button>
                                                    </Col>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        FoodList: state.food.FoodList,
        isFoodList: state.food.isFoodList,
        isFoodListError: state.food.isFoodListError,

        FoodCartList: state.food.FoodCartList,
        isFoodCartList: state.food.isFoodCartList,
        isFoodCartListError: state.food.isFoodCartListError,

        FoodChangeStatus: state.food.FoodChangeStatus,
        isFoodChangeStatus: state.food.isFoodChangeStatus,
        isFoodChangeStatusError: state.food.isFoodChangeStatusError,

        FoodCategoryList: state.foodCategory.FoodCategoryList,
        isFoodCategoryList: state.foodCategory.isFoodCategoryList,
        isFoodCategoryListError: state.foodCategory.isFoodCategoryListError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { foodListAction, foodCartListAction, foodCategoryListAction, languageListAction })(CreateMeal));

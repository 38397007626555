/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import HeaderLinks from "./HeaderLinks.jsx";
import { Link } from "react-router-dom";
//import * as PF from "Front/views/Home/PublicFunction.jsx"
import NotificationSystem from "react-notification-system";
import { appConstants } from "Front/_constants/app.constants.js";
import { style } from "Front/variables/Variables.jsx";
import logo from "Front/assets/img/practice_aid_logo_white.png";
import InternetSpeedTeller from "../common/InternetSpeedTeller.jsx";
class HomeHeader extends Component {
  constructor(props) {
    super(props);
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
      AllCount: localStorage.getItem("AllCount"),
      chatOpen: false,
    };

    this.setChatModel = this.setChatModel.bind(this);

    let _this = this;

    appConstants.socket.on("newMsg", function (data) {
      if (localStorage.getItem("chatModel") === "false") {
        let AllCount = localStorage.getItem("AllCount");
        let count = parseInt(AllCount, 10) + 1;
        localStorage.setItem("AllCount", count);
        _this.setState({ AllCount: count });
        _this.handleNotificationClick("warning", data.msg, data.senderName);
      }
    });
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
  }

  // function that makes the sidebar from normal to mini and vice-versa
  handleMinimizeSidebar() {
    document.body.classList.toggle("sidebar-mini");
  }
  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }

  handleNotificationClick(position, msg = "", from) {
    this.state._notificationSystem.addNotification({
      title: (
        <span>
          <i class="fa fa-envelope-square" aria-hidden="true"></i>
        </span>
      ),
      message: (
        <div>
          <p>{from}</p>
          <span>{msg}</span>
        </div>
      ),
      level: "info",
      position: "tr",
      autoDismiss: 10,
    });
  }

  setChatModel(evt) {
    if (evt === true) {
      localStorage.setItem("chatModel", true);
    } else {
      localStorage.setItem("chatModel", false);
      let addUser = {
        userId: localStorage.getItem("_id"),
      };
      appConstants.socket.emit("screenClose", addUser);
    }
  }

  render() {
    // let logo;
    // if(localStorage.getItem('logo') != '')
    // {
    //     logo = appConstants.s3UploadUrl+localStorage.getItem('logo');

    // }else{

    //     logo = 'images/logo.png'
    // }

    return (
      <Navbar className="front-head navi-bar">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Navbar.Header>
          <Link to="/">
            <img className="frontpage-logo" src={logo} alt="Logo" />
          </Link>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>

        {/* Here we import the links that appear in navbar */}
        {/* { window.innerWidth > 992 ? (<Navbar.Collapse><HeaderLinks AllCount={this.state.AllCount} chatModel={(evt) => {this.setChatModel(evt)}}  /></Navbar.Collapse>):null } */}
        <Navbar.Collapse>
          <HeaderLinks
            AllCount={this.state.AllCount}
            chatModel={(evt) => {
              this.setChatModel(evt);
            }}
          />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default HomeHeader;

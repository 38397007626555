/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
import * as API from 'Front/api/comment'


export const addCommentRequest = () => ({
    type: actionTypes.ADD_COMMENT_MSG_REQUEST
})
export const addCommentSuccess = (response) => ({
    type: actionTypes.ADD_COMMENT_MSG_SUCCESS,
    payload: {
        response,
    }
})
export const addCommentError = (error) => ({
    type: actionTypes.ADD_COMMENT_MSG_ERROR,
    payload: {
        error,
    }
})




export const addCommentAction = (data) => {
    return dispatch => {
        dispatch(addCommentRequest())
        return API.addComment(data)
            .then(response => {

                dispatch(addCommentSuccess(response.data))
            })
            .catch(error => {
                dispatch(addCommentError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, FormControl, ControlLabel, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import logo from 'Front/assets/img/practice_aid_logo.png';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import 'react-select/dist/react-select.css';
import { appTimeSlot } from 'Admin/variables/Variables.jsx';
import { addClinicInvitationAction, fileUploadAction } from '../../actions/commonRequest';
import { countryListAction, stateListAction, getSpecializationsAction } from '../../actions/master';
import loadingImg from '../../../Admin/assets/img/loading.gif';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import PatternPreview from './PatternPreview.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
let Validator = require('validatorjs');
let formArr = {}
let rules = {
    clinic_name: 'required',
    email: 'required|email',
    degree: 'required',
    doctorName: 'required',
    specializations_add: 'required',
    address10: 'required',
    city: 'required',
    state: 'required',
    pin_code: 'numeric',
    phone: 'required|digits_between:10,12',
    mobile: 'digits_between:10,12',
    appointmentno: 'digits_between:10,12',
    phone22: 'digits_between:10,12',
    mobile22: 'digits_between:10,12',
    appointmentno22: 'digits_between:10,12',
};
let mess = {
    required: 'This field is required',
    email: 'Invalid Email',
    numeric: 'Enter numeric digits',
    digits: 'The field must be 10 digits.',
    digits_between: 'Phone no. must be between 10 and 12 digits'
};
let validation = [];


let newObj = {};
let newObj1 = {};

class InvitationRequestPage extends Component {
    constructor(props) {
        super(props);
        this.vForm = this.refs.vForm;
        this.state = {
            formArr: [],
            alertMsg: '',
            alertStatus: null,
            showProcessing: false,
            week_off: null,
            specializations_add: null,
            imagePreviewUrl: '',
            imagePreviewUrlClinic: '',
            imagePreviewUrlDoctor: '',
            alert: null,
            formData: {
                week_off: [],
                specializations: [],
                holiday_date: [],
                logo_image: '',
                background_image: '',
                doctor_image: '',
                appointmentno: '',
                mci_number: "",
                address: {
                    evening_till: "",
                    evening_from: "",
                    morning_till: "",
                    morning_from: moment().startOf('day').add(8, 'hours').format('h:mm A'),
                    pin_code: "",
                    state: "",
                    stateId: "",
                    country: "",
                    countryId: "",
                    city: "",
                    address: "",
                    phone: "",
                    mobile: "",
                    appointmentno: "",
                    appointmentNoShow: false,
                    phoneNoShow: false,
                    mobileNoShow: false,

                },
                address2: {
                    evening_till: "",
                    evening_from: "",
                    morning_till: "",
                    morning_from: "",
                    pin_code: "",
                    state: "",
                    stateId: "",
                    country: "",
                    countryId: "",
                    city: "",
                    address: "",
                    phone: "",
                    mobile: "",
                    appointmentno: "",
                    appointmentNoShow: false,
                    phoneNoShow: false,
                    mobileNoShow: false,
                }
            },
            startTime1: moment().startOf('day').add(8, 'hours'),
            endTime1: '',
            startTime2: '',
            endTime2: '',
            morning_from1: moment().startOf('day').add(8, 'hours'),
            morning_till1: '',
            evening_from1: '',
            evening_till1: '',
            stateList: [],
            countryList: [],
            SpecializationList: [],
            country_id: '',
            uploaded_file: '',
            multiInput: false,
            selectedDays: [],
            limitForm: {},
            start1Error: '',
            start2Error: '',
            end1Error: '',
            end2Error: '',
            morningFormError: '',
            morningTillError: '',
            eveningFormError: '',
            eveningTillError: '',
            phone22: "",
            mobile22: "",
            appointmentno22: "",
            websiteError: "",
            validationError: false,
            clinic_email: ''
        }

        validation = new Validator(this.state, rules, mess);
        validation.passes();
        validation.fails();

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.selectMultiData = this.selectMultiData.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.openPatternModal = this.openPatternModal.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleAddress2Change = this.handleAddress2Change.bind(this);

        /*let _this = this;
        $(document).mouseup(function (e) {
            var container = $(".multiDateP");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                _this.setState({ multiInput: false });
            }
        });*/
    }

    componentDidMount() {
        let formData1 = this.state.formData;

        newObj = {
            "morning_from": '',
            "morning_till": '',
            "evening_from": '',
            "evening_till": '',
            "address": '',
            "city": '',
            "state": '',
            "stateId": '',
            "country": '',
            "countryId": '',
            "pin_code": '',
            "phone": '',
            "mobile": '',
            "appointmentno": '',
            "appointmentNoShow": false,
            "phoneNoShow": false,
            "mobileNoShow": false,
        }
        newObj1 = {
            "morning_from": '',
            "morning_till": '',
            "evening_from": '',
            "evening_till": '',
            "address": '',
            "city": '',
            "state": '',
            "stateId": '',
            "country": '',
            "countryId": '',
            "pin_code": '',
            "phone": '',
            "mobile": '',
            "appointmentno": '',
            "appointmentNoShow": false,
            "phoneNoShow": false,
            "mobileNoShow": false,
        }

        formData1['address'] = newObj;
        formData1['address2'] = newObj1;

        this.setState({ formData: formData1 });


        if (this.props.location && this.props.location.pathname && this.props.location.pathname !== '' && this.props.location.pathname.substr(this.props.location.pathname.lastIndexOf('/') + 1) !== 'clinic-invitation-request') {
            const { formData } = this.state;
            let pathname = this.props.location.pathname;
            formData['invitation_id'] = pathname.substr(pathname.lastIndexOf('/') + 1);
        } else {
            this.props.history.push({ pathname: '/' });
        }
        this.props.countryListAction();
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.getSpecializationsAction(data);
        this.setState({ imagePreviewUrl: "" });
    }

    componentWillReceiveProps(nextProps) {
        let formData = this.state.formData;
        if (nextProps.isCountryList !== this.props.isCountryList && this.props.isCountryList === false) {
            this.setState({
                countryList: nextProps.countryList.data.data
            });
            let country = {};
            if (nextProps.countryList.data && nextProps.countryList.data.data && nextProps.countryList.data.data.length > 0) {
                nextProps.countryList.data.data.map((v, i) => {
                    if (v.short_name === 'India') {
                        country = { id: v._id, name: v.short_name };
                    }
                    return '';
                });
                formData.country_id = country.id;
                formData.country = country.name;

                newObj["country"] = country.id;
                newObj["countryId"] = country.name;
                newObj1["country"] = country.id;
                newObj1["countryId"] = country.name;
                formData['address'] = newObj;
                formData['address2'] = newObj1;

                this.setState({ formData: formData });
            }
            this.props.stateListAction(country.id);
        }

        if (nextProps.isStateList !== this.props.isStateList && this.props.isStateList === false) {
            this.setState({
                stateList: nextProps.stateList.data.data
            });
        }
        if (nextProps.isSpecialization !== this.props.isSpecialization) {



            let entry = [];
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                nextProps.SpecializationList.data.data.map((key, index) =>
                    entry.push({
                        "value": key._id,
                        "label": key.name
                    })

                );
            }


            this.setState({ SpecializationList: entry });
        }
        if (nextProps && nextProps.responseType === 'fileUpload' && nextProps.responseData && nextProps.responseData.statusCode === 200) {
            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.responseData.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            this.setState({
                showProcessing: false,
                alertMsg: 'Error in file upload.',
                alertStatus: 'danger',
            })
        }

        if (nextProps && nextProps.responseType === 'addInvitation' && nextProps.responseData && nextProps.responseData.statusCode === 200) {
            this.setState({
                alertMsg: nextProps.responseData.msg,
                alertStatus: 'success',
                showProcessing: false,
                formData: {
                    invitation_id: '',
                    email: '',
                    clinic_name: '',
                    short_name: '',
                    doctorName: '',
                    degree: '',
                    web: '',
                    pattern: '',
                    phone: '',
                    pin_code: '',
                    state: '',
                    state_id: '',
                    city: '',
                    address10: '',
                    mobile: '',
                    week_off: [],
                    specializations: [],
                    specializations_add: '',
                    holiday_date: [],
                    address: {},
                    address2: {},
                    clinic_email: ''
                },
                formArr: [],
                week_off: null,
                specializations: null,
                imagePreviewUrl: ""
            });
            window.scrollTo(0, 0);
            this.successAlert('We will update you on the email.');
            // setTimeout(() => {
            //     window.location.reload();
            // }, 5000);
        }
        if (nextProps && nextProps.responseData && nextProps.responseData.statusCode === 500) {
            if (nextProps.responseData.errors) {
                nextProps.responseData.errors.map((key, i) => {
                    this.setState({
                        [(key.param) + "Error"]: key.msg,
                        alertMsg: key.msg,
                        alertStatus: 'danger',
                        showProcessing: false,
                    })
                    return '';
                });
            } else {
                this.setState({
                    showProcessing: false,
                    alertMsg: nextProps.responseData.msg,
                    alertStatus: 'danger',
                })
                setTimeout(() => {
                    //window.location.reload();
                }, 5000);
            }
            window.scrollTo(0, 0);
        }
    }

    onDismiss() {
        this.setState({ previewModal: false });

    }

    openPatternModal() {
        this.setState({ previewModal: true });
    }

    addClinic(evt) {
        evt.preventDefault();
        let formData = this.state.formData;
        formData['patientNotificationDay'] = formData && formData.patientNotificationDay ? Number(formData.patientNotificationDay) : 1;



        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true });
            _this.props.addClinicInvitationAction(formData);
        }
        //validation.errors()
    }

    successAlert(msg) {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Thank You"
                    //onConfirm={() => this.hideAlert()}
                    //onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    {msg}
                </SweetAlert>
            )
        });

        let _this = this;
        setTimeout(function () {
            _this.hideAlert();
        }, 3000);
    }

    hideAlert() {
        this.setState({
            alert: false
        });
        window.location.reload();
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({ formArr });
        }
        if (validation.passes()) { return 1; }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        if (e.target.name === 'email') {
            field[e.target.name] = e.target.value ? (e.target.value).trim() : '';
        } else if (e.target.name === 'web') {
            let domain = e.target.value;
            var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
            if (domain.match(re) || domain === '') {
                this.setState({ websiteError: '', validationError: false });
            } else {
                this.setState({ validationError: true });
            }
        }
        this.setState({ formData: field });
    };

    websiteValidation(e) {
        e.preventDefault();

        if (e.target.name === 'web') {
            let domain = e.target.value;
            var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
            if (domain.match(re) || domain === '') {
                this.setState({ websiteError: '', validationError: false });
            } else {
                this.setState({ websiteError: 'Enter valid webiste.', validationError: true });
            }
        }
    };

    getNumberValidation(e, name, type) {
        if (name === 'phone') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
                } else {
                    //this.setState({ phoneError1: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ phoneError: null })
                } else {
                    this.setState({ phoneError1: null })
                }
            }
        }
        if (name === 'mobile') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ mobileError: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
                } else {
                    //this.setState({ mobileError1: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ mobileError: null })
                } else {
                    this.setState({ mobileError1: null })
                }
            }
        }
        if (name === 'appointmentno') {
            if (e.target.value && e.target.value.length !== 10) {
                if (type === 'address1') {
                    //this.setState({ appointmentnoError: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
                } else {
                    //this.setState({ appointmentnoError1: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
                }
            } else {
                if (type === 'address1') {
                    this.setState({ appointmentnoError: null })
                } else {
                    this.setState({ appointmentnoError1: null })
                }
            }
        }
    }

    handleAddressChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        this.getNumberValidation(e, e.target.name, 'address1')

        newObj[e.target.name] = e.target.value;
        field['address'] = newObj;
        if (e.target.name === 'address') {

            field['address10'] = e.target.value;
        } else {
            field[e.target.name] = e.target.value;
        }


        this.setState({ formData: field });
    };

    handleAddress2Change(e) {
        e.preventDefault();
        let field = this.state.formData;
        this.getNumberValidation(e, e.target.name)

        newObj1[e.target.name] = e.target.value;
        field['address2'] = newObj1;
        field[e.target.name + '22'] = e.target.value;
        this.setState({ formData: field });
    };

    handleCountryChange(event) {
        let data = this.state.formData;
        newObj["country"] = event.target[event.target.selectedIndex].text;
        newObj["countryId"] = event.target.value;
        data['address'] = newObj;
        this.setState({ formData: data });
        this.props.stateListAction(event.target.value);
    }

    handleStateChange(event) {
        let data = this.state.formData;
        newObj['state'] = event.target[event.target.selectedIndex].text;
        newObj['stateId'] = event.target.value;
        data['address'] = newObj;
        this.setState({ formData: data });
    }

    handleState2Change(event) {
        let data = this.state.formData;
        newObj1['state'] = event.target[event.target.selectedIndex].text;
        newObj1['stateId'] = event.target.value;
        data['address2'] = newObj1;
        this.setState({ formData: data });
    }

    handlepatternChange(event) {
        let data = this.state.formData;
        data["pattern"] = event && event.value ? event.value : '';
        this.setState({ formData: data });
    }

    validateSlot(newObj, type) {

        if (type === 1) {
            this.setState({ start2Error: '' });
        }

        if (type === 2) {
            this.setState({ eveningFormError: '' });
        }

        if (newObj["evening_from"] && newObj["evening_from"] !== '' && newObj["evening_till"] && newObj["evening_till"] !== '' && newObj["morning_from"] && newObj["morning_from"] !== '' && newObj["morning_till"] && newObj["morning_till"] !== '' && moment(newObj["evening_from"], ["h:mm A"]).format('x') < moment(newObj["morning_till"], ["h:mm A"]).format('x')) {

            if (type === 1) {
                this.setState({ start2Error: 'Evening from is not less then morning till.' });
            } else {
                this.setState({ eveningFormError: 'Evening from is not less then morning till.' });
            }

        }
    }

    handleChangeTime(data, name) {

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'morning_from') {

            newObj[name] = data.target.value;
            data1["morning_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');
            newObj["morning_till"] = data1["morning_till"];
            data1['address'] = newObj;
            this.setState({ formData: data1, startTime1: dateObject });

            this.validateSlot(newObj, 1);

        } else if (name === 'morning_from2') {

            newObj1['morning_from'] = data.target.value;
            data1["morning_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');
            newObj1["morning_till"] = data1["morning_till2"];
            data1['address2'] = newObj1;
            this.setState({ formData: data1, morning_from1: dateObject });
            this.validateSlot(newObj1, 2);
        }
    }

    handleChangeTime2(data, name) {

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'morning_till') {
            newObj[name] = data.target.value;
            data1['address'] = newObj;
            this.setState({ formData: data1, endTime1: dateObject });

            this.validateSlot(newObj, 1);

        } else if (name === 'morning_till2') {
            newObj1['morning_till'] = data.target.value;
            data1['address2'] = newObj1;
            this.setState({ formData: data1, morning_till1: dateObject });
            this.validateSlot(newObj1, 2);
        }
    }

    handleChangeTime3(data, name) {

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'evening_from') {
            newObj[name] = data.target.value;
            data1["evening_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');

            newObj["evening_till"] = data1["evening_till"];
            data1['address'] = newObj;

            this.setState({ formData: data1, startTime2: dateObject });

            this.validateSlot(newObj, 1);

        } else if (name === 'evening_from2') {

            newObj1['evening_from'] = data.target.value;
            data1["evening_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');

            newObj1["evening_till"] = data1["evening_till2"];
            data1['address2'] = newObj1;
            this.setState({ formData: data1, evening_from1: dateObject });
            this.validateSlot(newObj1, 2);
        }
    }

    handleChangeTime4(data, name) {

        let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
        let data1 = this.state.formData;
        data1[name] = data.target.value;

        if (name === 'evening_till') {
            newObj[name] = data.target.value;
            data1['address'] = newObj;
            this.setState({ formData: data1, endTime2: dateObject });
            this.validateSlot(newObj, 1);

        } else if (name === 'evening_till2') {
            newObj1['evening_till'] = data.target.value;
            data1['address2'] = newObj1;
            this.setState({ formData: data1, evening_till1: dateObject });
            this.validateSlot(newObj1, 2);
        }
    }

    /* handleChangeTime(data, name) {
         let mytime = moment(new Date(data)).format('h:mm A');
         let dateObject = new Date(moment(mytime, ["h:mm A"]));
         let data1 = this.state.formData;
         data1[name] = mytime;
         if(name === 'morning_from'){
           newObj[name] = mytime;
           data1["morning_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');
           newObj["morning_till"] = data1["morning_till"];
           data1['address'] = newObj;
           this.setState({ formData: data1, startTime1: dateObject });
 
           if ((dateObject).valueOf() > (new Date(newObj["morning_till"])).valueOf()) {
                this.setState({ endTime1Error: 'End time is not less then start time.' });
           } else if (data._isValid) {
               this.setState({ start1Error: '', endTime1Error: '' });
           } else {
               this.setState({ start1Error: 'Please select valid date.', endTime1Error: '' });
           }
 
         }else if(name === 'morning_from2'){
           newObj1['morning_from'] = mytime;
           data1["morning_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');
           newObj1["morning_till"] = data1["morning_till2"];
           data1['address2'] = newObj1;
           this.setState({ formData: data1, morning_from1: dateObject });
 
           if ((dateObject).valueOf() > (new Date(newObj["morning_till"])).valueOf()) {
                this.setState({ morningTillError: 'End time is not less then start time.' });
           } else if (data._isValid) {
               this.setState({ morningFormError: '', morningTillError: '' });
           } else {
               this.setState({ morningFormError: 'Please select valid date.', morningTillError: '' });
           }
 
         }
     }
 
     handleChangeTime2(data, name) {
         let mytime = moment(new Date(data)).format('h:mm A');
         let dateObject = new Date(moment(mytime, ["h:mm A"]));
         let data1 = this.state.formData;
         data1[name] = mytime;
 
 
         if(name === 'morning_till'){
           newObj[name] = mytime;
 
           data1['address'] = newObj;
           this.setState({ formData: data1, endTime1: dateObject });
           if ((this.state.startTime1).valueOf() > (dateObject).valueOf()) {
               this.setState({ endTime1Error: 'End time is not less then start time.' });
               this.setState({ start2Error: 'Start time 2 is not less then end time.' });
           } else if (!data._isValid) {
               this.setState({ endTime1Error: 'Please select valid date.' });
           } else {
               this.setState({ endTime1Error: '', start2Error:'' });
           }
 
 
         }else if(name === 'morning_till2'){
           newObj1['morning_till'] = mytime;
           data1['address2'] = newObj1;
           this.setState({ formData: data1, morning_till1: dateObject });
           if ((this.state.morning_from1).valueOf() > (dateObject).valueOf()) {
               this.setState({ morningTillError: 'End time is not less then start time.' });
               this.setState({ eveningFormError: 'Start time 2 is not less then end time.' });
           } else if (!data._isValid) {
               this.setState({ morningTillError: 'Please select valid date.' });
           } else {
               this.setState({ morningTillError: '', eveningFormError:'' });
           }
         }
     }
 
     handleChangeTime3(data, name) {
         let mytime = moment(new Date(data)).format('h:mm A');
         let dateObject = new Date(moment(mytime, ["h:mm A"]));
         let data1 = this.state.formData;
         data1[name] = mytime;
 
 
         if(name === 'evening_from'){
           newObj[name] = mytime;
           data1["evening_till"] = moment(dateObject).add(1, 'hours').format('h:mm A');
 
           newObj["evening_till"] = data1["evening_till"];
           data1['address'] = newObj;
 
           this.setState({ formData: data1, startTime2: dateObject });
           if ((dateObject).valueOf() > (this.state.endTime2).valueOf()) {
 
               if ((dateObject).valueOf() < (this.state.endTime1).valueOf()) {
                   this.setState({ start2Error: 'Start time 2 is not less then end time.', endTime2Error:'' });
               }else {
                   this.setState({ endTime2Error: '', start2Error: '' });
               }
           }
           else if (data._isValid) {
               this.setState({ start2Error: '', endTime2Error: '' });
           }
           else {
               this.setState({ start2Error: 'Please select valid date.', endTime2Error: '' });
           }
 
 
         }else if(name === 'evening_from2'){
           newObj1['evening_from'] = mytime;
           data1["evening_till2"] = moment(dateObject).add(1, 'hours').format('h:mm A');
 
           newObj1["evening_till"] = data1["evening_till2"];
           data1['address2'] = newObj1;
           this.setState({ formData: data1, evening_from1: dateObject });
           if ((dateObject).valueOf() > (this.state.evening_till1).valueOf()) {
 
               if ((dateObject).valueOf() < (this.state.morning_till1).valueOf()) {
                   this.setState({ eveningFormError: 'Start time 2 is not less then end time.', eveningTillError:'' });
               }else {
                   this.setState({ eveningTillError: '', eveningFormError: '' });
               }
           }
           else if (data._isValid) {
               this.setState({ eveningFormError: '', eveningTillError: '' });
           }
           else {
               this.setState({ eveningFormError: 'Please select valid date.', eveningTillError: '' });
           }
         }
     }
 
     handleChangeTime4(data, name) {
         let mytime = moment(new Date(data)).format('h:mm A');
         let dateObject = new Date(moment(mytime, ["h:mm A"]));
         let data1 = this.state.formData;
         data1[name] = mytime;
 
         if(name === 'evening_till'){
           newObj[name] = mytime;
           data1['address'] = newObj;
           this.setState({ formData: data1, endTime2: dateObject });
           if ((this.state.startTime2).valueOf() > (dateObject).valueOf()) {
               this.setState({ endTime2Error: 'End time 2 is not less then start time 2.' });
           } else if (!data._isValid) {
               this.setState({ endTime2Error: 'Please select valid date.' });
           } else {
               this.setState({ endTime2Error: '' });
           }
 
         }else if(name === 'evening_till2'){
           newObj1['evening_till'] = mytime;
           data1['address2'] = newObj1;
           this.setState({ formData: data1, evening_till1: dateObject });
           if ((this.state.evening_from1).valueOf() > (dateObject).valueOf()) {
               this.setState({ eveningTillError: 'End time 2 is not less then start time 2.' });
           } else if (!data._isValid) {
               this.setState({ eveningTillError: 'Please select valid date.' });
           } else {
               this.setState({ eveningTillError: '' });
           }
 
         }
 
     }*/

    handleWeekOff(field) {
        let entry = [];
        let data = this.state.formData;
        field.map((key, index) =>
            entry.push({
                "day": key.value,
                "name": key.label
            })
        );
        if (entry) {
            for (let i in entry) {
                if (entry[i].day === 7) {
                    entry[i].day = 0
                }
            }
        }
        data["week_off"] = entry;
        this.setState({ formData: data });
    }

    handleSpecialization(field) {
        let entry = [];
        let data = this.state.formData;
        field.map((key, index) =>
            entry.push({
                "id": key.value,
                "name": key.label
            })
        );
        data["specializations_add"] = entry;
        data["specializations"] = entry;
        this.setState({ formData: data });
    }

    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];

        if (elename === "logo_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrl: reader.result });
            }
        }
        if (elename === "background_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrlClinic: reader.result });
            }
        }
        if (elename === "doctor_image") {
            reader.onloadend = () => {
                this.setState({ imagePreviewUrlDoctor: reader.result });
            }
        }
        if (file) {
            this.props.fileUploadAction(file);
            reader.readAsDataURL(file);
        }
    }

    selectMultiData() {
        if (this.state.multiInput === false)
            this.setState({ multiInput: true });
        else
            this.setState({ multiInput: false });
    }

    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        let dateArr = [];
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        this.setState({ selectedDays });
        for (let i in selectedDays) {
            var s1 = [selectedDays[i].getMonth() + 1, selectedDays[i].getDate(), selectedDays[i].getFullYear()].join('-');
            dateArr.push(s1);
        }
        let formData = this.state.formData;
        formData['holiday_date'] = dateArr;
        this.setState({ formData: formData });
    }


    onResetTime(e, typ = 0) {

        if (typ > 0 && this.state.formData) {
            var formdata = this.state.formData;

            if (typ === 1 && formdata && formdata.address && formdata.address.morning_from) {

                formdata.address.morning_from = '';
                formdata.address.morning_till = '';

                this.setState({ formdata: formdata });
            }

            if (typ === 2 && formdata && formdata.address && formdata.address.evening_from) {

                formdata.address.evening_from = '';
                formdata.address.evening_till = '';
                this.setState({ formdata: formdata });
            }

            if (typ === 1 || typ === 2) {

                this.setState({ start2Error: '' });
            }

            if (typ === 3 || typ === 4) {

                this.setState({ eveningFormError: '' });
            }

            if (typ === 3 && formdata && formdata.address2 && formdata.address2.morning_from) {

                formdata.address2.morning_from = '';
                formdata.address2.morning_till = '';
                this.setState({ formdata: formdata });
            }

            if (typ === 4 && formdata && formdata.address2 && formdata.address2.evening_from) {

                formdata.address2.evening_from = '';
                formdata.address2.evening_till = '';
                this.setState({ formdata: formdata });
            }

        }
    }

    render() {
        const { start1Error, start2Error, endTime1Error, endTime2Error, formData, morningTillError, morningFormError, eveningFormError, eveningTillError, alertStatus, alertMsg, validationError } = this.state;


        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        return (
            <div className="main-content invitationfrm">
                <div className="container">
                    <Link to="/"><img className="frontpage-logo" src={logo} alt="Logo" /></Link>
                </div>
                {alertStatus !== null &&
                    <div class={alertStatus === 'success' ? 'alert alert-success' : 'alert alert-danger'} role="alert">
                        {alertMsg}.
                    </div>
                }
                {this.state.alert}
                <Grid container="true">
                    <Row>
                        <Col md={12}>
                            <Form horizontal className="spc_btm">
                                <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                                    <img src={loadingImg} alt="Loading" width="40px" />
                                    <center>Logging In - Please Wait</center>
                                </div>
                                <div className="form_panel">
                                    <legend>General information</legend>
                                    <FormGroup>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel} >
                                                Doctor Name <span className="star">*</span>
                                            </Col>
                                            <FormControl type="text" name="doctorName" onChange={e => { this.handleChange(e); }} placeholder="Doctor Name" value={formData.doctorName} autoComplete="off" />
                                            <span className="errorMsg">{this.state.clinic_nameError}{this.state.formArr.doctorName && validation.errors.first('doctorName')}</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel} >
                                                Clinic Name <span className="star">*</span>
                                            </Col>
                                            <FormControl type="text" name="clinic_name" onChange={e => { this.handleChange(e); }} placeholder="Clinic Name" value={formData.clinic_name} autoComplete="off" />
                                            <span className="errorMsg">{this.state.clinic_nameError}{this.state.formArr.clinic_name && validation.errors.first('clinic_name')}</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel} >
                                                Degree <span className="star">*</span>
                                            </Col>
                                            <FormControl type="text" name="degree" onChange={e => { this.handleChange(e); }} placeholder="Degree" value={formData.degree} autoComplete="off" />
                                            <span className="errorMsg">{this.state.formArr.degree && validation.errors.first('degree')}</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel} >
                                                MCI Registration Number
                                            </Col>
                                            <FormControl type="text" name="mci_number" onChange={e => { this.handleChange(e); }} placeholder="MCI Registation Number" value={formData.mci_number} autoComplete="off" />
                                            <span className="errorMsg">{this.state.formArr.mci_number && validation.errors.first('mci_number')}</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel} >
                                                Specialization <span className="star">*</span>
                                            </Col>
                                            <Select
                                                placeholder="Select specializations"
                                                name="specializations"
                                                id="specializations"
                                                closeOnSelect={false}
                                                multi={true}
                                                value={this.state.specializations}
                                                options={this.state.SpecializationList}
                                                onChange={(value) => { this.setState({ specializations: value }); this.handleSpecialization(value) }}
                                            />
                                            <span className="errorMsg">{this.state.formArr.specializations_add && validation.errors.first('specializations_add')}</span>
                                        </Col>

                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel}>
                                                Website
                                            </Col>
                                            <FormControl type="text" name="web" onBlur={e => { this.websiteValidation(e); }} onChange={e => { this.handleChange(e); }} placeholder="Website" value={formData.web} autoComplete="off" />
                                            <span className="errorMsg">{this.state.websiteError}</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Col componentClass={ControlLabel}>
                                                Doctor Email  <span className="star">*</span>
                                            </Col>
                                            <FormControl type="text" name="email" onChange={e => { this.handleChange(e); }} placeholder="Doctor Email" value={formData.email} autoComplete="off" />
                                            <span className="errorMsg" refs="short_name">{this.state.emailError}{this.state.formArr.email && validation.errors.first('email')}</span>
                                        </Col>

                                        <Col sm={6} className="invitation_file_upload" style={{ clear: "both" }}>
                                            <Col componentClass={ControlLabel}>
                                                Clinic Image
                                            </Col>
                                            <Col componentClass={ControlLabel}>
                                                {this.state.imagePreviewUrlClinic &&
                                                    <img src={this.state.imagePreviewUrlClinic} width="100px" height="100px" style={{ margin: "5px" }} alt="" />
                                                }
                                                <FormControl type="file" name="background_image" onChange={e => { this.fileChangedHandler(e, "background_image") }} />
                                                <span className="errorMsg">{this.state.background_imageError}</span>
                                            </Col>
                                        </Col>

                                        <Col sm={6} className="invitation_file_upload">
                                            <Col componentClass={ControlLabel}>
                                                Doctor Image
                                            </Col>
                                            <Col componentClass={ControlLabel}>
                                                {this.state.imagePreviewUrlDoctor &&
                                                    <img src={this.state.imagePreviewUrlDoctor} width="100px" height="100px" style={{ margin: "5px" }} alt="" />
                                                }
                                                <FormControl type="file" name="doctor_image" onChange={e => { this.fileChangedHandler(e, "doctor_image") }} />
                                                <span className="errorMsg">{this.state.contact_imageError}</span>
                                            </Col>
                                        </Col>


                                        <Col sm={6} className="invitation_file_upload">
                                            <Col componentClass={ControlLabel}>
                                                Clinic Logo
                                            </Col>
                                            <Col componentClass={ControlLabel}>
                                                {this.state.imagePreviewUrl &&
                                                    <img src={this.state.imagePreviewUrl} width="100px" height="100px" style={{ margin: "5px" }} alt="" />
                                                }
                                                <FormControl type="file" name="logo_image" onChange={e => { this.fileChangedHandler(e, "logo_image") }} />
                                                <span className="errorMsg">{this.state.logo_imageError}</span>
                                            </Col>
                                        </Col>

                                    </FormGroup>
                                </div>
                                <div className="form_panel">
                                    <legend>Contact Information</legend>
                                    <FormGroup>
                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Address <span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="textarea" name="address" autoComplete="off" onChange={e => { this.handleAddressChange(e); }} placeholder="Address" value={formData.address.address} />
                                                    <span className="errorMsg">{this.state.addressError}{this.state.formArr.address10 && validation.errors.first('address10')}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <Col sm={4}>
                                            <Col componentClass={ControlLabel}>
                                                Address <span className="star">*</span>
                                            </Col>
                                            <FormControl componentClass="textarea" name="address" autoComplete="off" onChange={e => { this.handleAddressChange(e); }} placeholder="Address" value={formData.address.address}/>
                                            <span className="errorMsg">{this.state.addressError}{this.state.formArr.address10 && validation.errors.first('address10')}</span>
                                        </Col> */}
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        City <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="city" value={formData.address.city} onChange={e => { this.handleAddressChange(e); }} placeholder="City" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.cityError}{this.state.formArr.city && validation.errors.first('city')}</span>
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        State <span className="star">*</span>
                                                    </Col>
                                                    <FormControl componentClass="select" name="state" onChange={e => { this.handleAddressChange(e); this.handleStateChange(e); }}>
                                                        <option value="">Please select</option>
                                                        {this.state.stateList.map(function (item) {
                                                            return <option key={item._id} value={item._id}>{item.name}</option>
                                                        })}</FormControl>
                                                    <span className="errorMsg">{this.state.stateError}{this.state.formArr.state && validation.errors.first('state')}</span>
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Pin Code
                                                    </Col>
                                                    <FormControl type="text" name="pin_code" value={formData.address.pin_code} onChange={e => { this.handleAddressChange(e); }} maxLength={8} placeholder="Pin Code" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.pin_codeError}{this.state.formArr.pin_code && validation.errors.first('pin_code')}</span>
                                                </Col>

                                            </Row>



                                            <Row>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour from
                                                    </Col>


                                                    <select className="form-control" name="morning_from" onChange={e => { this.handleChangeTime(e, "morning_from"); }} value={formData.address && formData.address.morning_from}>
                                                        <option value="">Select Morning hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {

                                                                if (moment(item.value, 'h:mm a').format('x') >= moment("6:00 AM", 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{start1Error}{this.state.formArr.morning_from && validation.errors.first('morning_from')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour till
                                                    </Col>


                                                    <select className="form-control" name="morning_till" onChange={e => { this.handleChangeTime2(e, "morning_till"); }} value={this.state.formData.address.morning_till}>
                                                        <option value="">Select Morning hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = formData.address && formData.address.morning_from ? formData.address.morning_from : '6:00 AM';
                                                                if (moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }

                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{endTime1Error}{this.state.formArr.morning_till && validation.errors.first('morning_till')}</span>
                                                </Col>
                                                <Col sm={4} className="clear_btn">
                                                    <Col componentClass={ControlLabel}>
                                                        <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.onResetTime(e, 1) }}>Clear</Button>
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour from
                                                    </Col>


                                                    <select className="form-control" name="evening_from" onChange={e => { this.handleChangeTime3(e, "evening_from"); }} value={this.state.formData.address.evening_from}>
                                                        <option value="">Select Evening hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {

                                                                let m1 = newObj['morning_till'] ? newObj['morning_till'] : '1:00 PM';

                                                                if ((moment(item.value, 'h:mm a').format('x') >= moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{start2Error}{this.state.formArr.evening_from && validation.errors.first('evening_from')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour till
                                                    </Col>

                                                    <select className="form-control" name="evening_till" onChange={e => { this.handleChangeTime4(e, "evening_till"); }} value={this.state.formData.address.evening_till}>
                                                        <option value="">Select Evening hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = newObj['evening_from'] ? newObj['evening_from'] : '1:00 PM';

                                                                if ((moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{endTime2Error}{this.state.formArr.evening_till && validation.errors.first('evening_till')}</span>
                                                </Col>
                                                <Col sm={4} className="clear_btn">
                                                    <Col componentClass={ControlLabel}>
                                                        <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.onResetTime(e, 2) }}>Clear</Button>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={4} style={{ clear: "both" }}>
                                                    <Col componentClass={ControlLabel}>
                                                        Phone No. for Appointment <span className="star">*</span>
                                                    </Col>
                                                    <FormControl type="text" name="phone" value={formData.address.phone ? formData.address.phone : formData.phone} maxLength={12} onChange={e => { this.handleAddressChange(e); }} placeholder="Phone No. for Appointment" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.phoneError}{this.state.formArr.phone && validation.errors.first('phone')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Phone No. for Clinic
                                                    </Col>
                                                    <FormControl type="text" name="mobile" value={formData.address.mobile ? formData.address.mobile : formData.mobile} maxLength={12} onChange={e => { this.handleAddressChange(e); }} placeholder="Phone No. for Clinic" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.mobileError}{this.state.formArr.mobile && validation.errors.first('mobile')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Whatsapp No.
                                                    </Col>
                                                    <FormControl type="text" maxLength={12} name="appointmentno" value={formData.address.appointmentno} onChange={e => { this.handleAddressChange(e); }} placeholder="Whatsapp No." autoComplete="off" />
                                                    <span className="errorMsg">{this.state.appointmentnoError}{this.state.formArr.appointmentno && validation.errors.first('appointmentno')}</span>
                                                </Col>
                                                <Col sm={3}>
                                                    <Col componentClass={ControlLabel}>
                                                        Clinic Email
                                                    </Col>
                                                    <FormControl type="text" name="clinic_email" onChange={e => { this.handleChange(e); }} value={this.state.formData.clinic_email} placeholder="clinic_email" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </div>
                                <div className="form_panel">
                                    <legend>Contact Information Clinic 2 (if Applicable)</legend>
                                    <FormGroup>

                                        <Col sm={12}>
                                            <Row>
                                                <Col sm={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        Address
                                                    </Col>
                                                    <FormControl componentClass="textarea" name="address" onChange={e => { this.handleAddress2Change(e); }} placeholder="Address" value={formData.address2.address} autoComplete="off" />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        City
                                                    </Col>
                                                    <FormControl type="text" name="city" value={formData.address2.city} onChange={e => { this.handleAddress2Change(e); }} placeholder="City" autoComplete="off" />
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        State
                                                    </Col>
                                                    <FormControl componentClass="select" name="state" onChange={e => { this.handleAddress2Change(e); this.handleState2Change(e); }}>
                                                        <option value="">Please Select</option>
                                                        {this.state.stateList.map(function (item) {
                                                            return <option key={item._id} value={item._id}>{item.name}</option>
                                                        })}</FormControl>
                                                </Col>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Pin Code
                                                    </Col>
                                                    <FormControl type="text" name="pin_code" value={formData.address2.pin_code} onChange={e => { this.handleAddress2Change(e); }} maxLength={8} placeholder="Pin Code" autoComplete="off" />
                                                </Col>

                                            </Row>


                                            <Row>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour from
                                                    </Col>

                                                    <select className="form-control" name="morning_from2" onChange={e => { this.handleChangeTime(e, "morning_from2"); }} value={formData.address && formData.address2.morning_from}>
                                                        <option value="">Select Morning hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {

                                                                if (moment(item.value, 'h:mm a').format('x') >= moment("6:00 AM", 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{morningFormError}{this.state.formArr.morning_from && validation.errors.first('morning_from')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Morning hour till
                                                    </Col>

                                                    <select className="form-control" name="morning_till2" onChange={e => { this.handleChangeTime2(e, "morning_till2"); }} value={this.state.formData.address2.morning_till}>
                                                        <option value="">Select Morning hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = newObj1['morning_from'] ? newObj1['morning_from'] : '6:00 AM';
                                                                if (moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("5:00 PM", 'h:mm a').format('x')) {
                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{morningTillError}{this.state.formArr.morning_till && validation.errors.first('morning_till')}</span>
                                                </Col>
                                                <Col sm={4} className="clear_btn">
                                                    <Col componentClass={ControlLabel}>
                                                        <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.onResetTime(e, 3) }}>Clear</Button>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour from
                                                    </Col>

                                                    <select className="form-control" name="evening_from2" onChange={e => { this.handleChangeTime3(e, "evening_from2"); }} value={this.state.formData.address2.evening_from}>
                                                        <option value="">Select Evening hour from</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = newObj1['morning_till'] ? newObj1['morning_till'] : '1:00 PM';
                                                                if ((moment(item.value, 'h:mm a').format('x') >= moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') >= moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{eveningFormError}{this.state.formArr.evening_from && validation.errors.first('evening_from')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Evening hour till
                                                    </Col>

                                                    <select className="form-control" name="evening_till2" onChange={e => { this.handleChangeTime4(e, "evening_till2"); }} value={this.state.formData.address2.evening_till}>
                                                        <option value="">Select Evening hour till</option>
                                                        {
                                                            appTimeSlot.map(function (item) {
                                                                let m1 = newObj1['evening_from'] ? newObj1['evening_from'] : '1:00 PM';
                                                                if ((moment(item.value, 'h:mm a').format('x') > moment(m1, 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') > moment('1:00 PM', 'h:mm a').format('x') && moment(item.value, 'h:mm a').format('x') <= moment("11:30 PM", 'h:mm a').format('x')) || "12:00 AM" === item.value) {

                                                                    return <option value={item.value}>{item.label}</option>
                                                                } else { return ''; }
                                                            })
                                                        }
                                                    </select>
                                                    <span className="errorMsg">{eveningTillError}{this.state.formArr.evening_till && validation.errors.first('evening_till')}</span>
                                                </Col>
                                                <Col sm={4} className="clear_btn">
                                                    <Col componentClass={ControlLabel}>
                                                        <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.onResetTime(e, 4) }}>Clear</Button>
                                                    </Col>
                                                </Col>
                                            </Row>

                                            <Row>

                                                <Col sm={4} style={{ clear: "both" }}>
                                                    <Col componentClass={ControlLabel}>
                                                        Phone No. for Appointment
                                                    </Col>
                                                    <FormControl type="text" name="phone" value={formData.address2.phone} maxLength={12} onChange={e => { this.handleAddress2Change(e); }} placeholder="Phone No. for Appointment" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.phoneError1}{this.state.formArr.phone22 && validation.errors.first('phone22')}</span>
                                                </Col>
                                                <Col sm={4}>
                                                    <Col componentClass={ControlLabel}>
                                                        Phone No. for Clinic
                                                    </Col>
                                                    <FormControl type="text" name="mobile" value={formData.address2.mobile} maxLength={12} onChange={e => { this.handleAddress2Change(e); }} placeholder="Phone No. for Clinic" autoComplete="off" />
                                                    <span className="errorMsg">{this.state.mobileError1}{this.state.formArr.mobile22 && validation.errors.first('mobile22')}</span>
                                                </Col>
                                                <Col sm={4} >
                                                    <Col componentClass={ControlLabel}>
                                                        Whatsapp No.
                                                    </Col>
                                                    <FormControl type="text" maxLength={12} name="appointmentno" value={formData.address2.appointmentno} onChange={e => { this.handleAddress2Change(e); }} placeholder="Whatsapp No." autoComplete="off" />
                                                    <span className="errorMsg">{this.state.appointmentnoError1}{this.state.formArr.appointmentno22 && validation.errors.first('appointmentno22')}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                </div>
                                <div className="form_panel">
                                    <legend>Additional Options to Select</legend>
                                    <FormGroup>
                                        <Col sm={4}>
                                            <Col componentClass={ControlLabel}>
                                                Patient can Email for Queries

                                            </Col>
                                            <FormControl componentClass="select" name="is_email_facility" onChange={e => { this.handleChange(e); }}>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </FormControl>
                                        </Col>
                                        <Col sm={4}>
                                            <Col componentClass={ControlLabel}>
                                                Chat                                                                               </Col>
                                            <FormControl componentClass="select" name="is_chat_facility" onChange={e => { this.handleChange(e); }}>
                                                <option value="No">No</option>
                                                <option value="Yes">Yes</option>
                                            </FormControl>
                                            <small>Note:- Do you need a chat facility with your patient.</small>
                                        </Col>

                                        <Col sm={4}>
                                            <Col componentClass={ControlLabel}>
                                                {/*Next Visit Notification */}
                                                Appointment Notification Time in Days
                                            </Col>
                                            <FormControl componentClass="select" name="patientNotificationDay" onChange={e => { this.handleChange(e); }} placeholder="Print with Format">
                                                <option value="1">1 day</option>
                                                <option value="2">2 days</option>
                                                <option value="3">3 days</option>
                                                <option value="5">5 days</option>
                                                <option value="7">7 days</option>
                                                <option value="10">10 days</option>
                                            </FormControl>
                                            <small>Note:- "How many days prior to next expected visit" , a automatic message should go to patient to take appointment with you.</small>
                                        </Col>

                                    </FormGroup>
                                </div>
                                <Button fill bsStyle="info" className="invitation_form_btn" type="button" disabled={start2Error || eveningFormError || validationError} onClick={this.addClinic.bind(this)}>Submit</Button>
                            </Form>
                        </Col>
                    </Row>
                </Grid>

                <Modal className="pa-patient-registration" show={this.state.previewModal} onHide={() => this.setState({ previewModal: false })} dialogClassName="modal-md">
                    <Modal.Header closeButton className="Patient-headers">
                        <Modal.Title id="example-modal-sizes-title-lg">Pattern Preview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="Knowledge-Share card">
                        <Row>
                            <Col md={12}>
                                <PatternPreview onDi873smiss={this.onDismiss} pattern={this.state.formData.pattern} />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        stateList: state.master.stateList,
        isStateList: state.master.isStateList,
        isStateListError: state.master.isStateListError,

        countryList: state.master.countryList,
        isCountryList: state.master.isCountryList,
        isCountryListError: state.master.isCountryListError,

        SpecializationList: state.master.SpecializationList,
        isSpecialization: state.master.isSpecialization,
        isSpecializationError: state.master.isSpecializationError,

        isUploadFile: state.master.isUploadFile,
        isUploadFileError: state.master.isUploadFileError,
        uploadFile: state.master.uploadFile,

        responseType: state.commonRequest.type,
        responseData: state.commonRequest.response,
    }
}

export default withRouter(connect(mapStateToProps, {
    countryListAction, stateListAction, fileUploadAction,
    getSpecializationsAction, addClinicInvitationAction
})(InvitationRequestPage));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState } from 'react'
import * as API from "../../api/report"

const ReportsDetail = (props) => {
    const [reportData, setReportData] = useState({})
    const getReportDetail = async (id) => {
        let startDate = null
        let endDate = null
        let payload = { id: props.moreDetails.clinic_id }
        if (props.formData.start_date != "")
            payload.start_date = props.formData.start_date

        if (props.formData.end_date != "")
            payload.end_date = props.formData.end_date
        let res = await API.reportsDetail(payload)
        if (res.data)
            setReportData({ ...res.data.data })

    }


    useEffect(() => {
        getReportDetail()
    }, [])
    return (
        <div>
            <table className='table'>
                <thead>
                    <tr>
                        <th style={{ minWidth: "30px", width: '20%' }}>

                            DOCUMENTS (T/S/V)
                        </th>
                        <th style={{ minWidth: "90px", width: '20%' }}>
                            VIDEO (T/S/V)
                        </th>
                        <th style={{ minWidth: "140px", width: '20%' }}>
                            TASK (T/S)
                        </th>
                        <th style={{ minWidth: "220px", width: '40%' }}>
                            DIET CHART (T/P/S/E)
                        </th>
                        <th style={{ minWidth: "220px", width: '40%' }}>
                            App
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td>{reportData.docTCount} / {reportData.docShareCount} / {reportData.docviewCount}</td>
                        <td>{reportData.shareVideoTCount} / {reportData.videoShareCount} / {reportData.videoViewCount}</td>
                        <td>{reportData.taskTCount} / {reportData.taskCount}</td>
                        <td>{reportData.dietShareTCount} / {reportData.dietPrintCount} / {reportData.dietShareCount} / {reportData.dietEmailCount}</td>
                        <td>{reportData.appCount}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ReportsDetail
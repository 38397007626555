/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { visitListAction } from "Front/actions/home";
import {
  patientOutAction,
  holidayListAction,
  tagListAction,
  s3SiginedUrlArrayAction,
  setVisitListActiveTabAction,
  updateVisitListAction,
  visitDetailAction,
  updateVisitDetailAction,
  UpdateVisitListS3SiginedUrlArrayAction,
  UpdateVisitListS3SiginedUrlArrayOnlineListAction,
  UpdateVisitListS3SiginedUrlArrayOutListAction,
  getOnlineVisitListAction,
  starStatusListAction,
} from "Front/actions/home";
import { taskScreenAction } from "Front/actions/taskScreen";
import { emrSetAction } from "Front/actions/emr";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "react-loading";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import EducatorTaskScreen from "Front/views/TaskScreen/EducatorTaskScreen.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import mobileImg from "../../assets/img/mobile-r.png";
import mobilegreenImg from "../../assets/img/mobile-g.png";
import NextVisit from "Front/views/Home/NextVisitPopup.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import Calendar from "Front/components/Calendar";
import PrescriptionGreenIc from "../../assets/img/icons/PrescriptionGreen.png";
import DietIc from "../../assets/img/icons/diet.png";
import PasswordIc from "../../assets/img/icons/password.png";
import DeleteIc from "../../assets/img/icons/out-btn.png";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import VisitListButton from "./VisitListButton";
import EducatorVisitListTable from "./EducatorVisitListTable";
import MoreIc from "../../assets/img/icons/more.svg";
import PatientMoreDetailEducator from "./PatientMoreDetailEducator";
import EventEmitter from "Front/actions/events.js";
class EducatorScreen extends Component {
  constructor(props) {
    super(props);

    let outList = this.props.VisitList.filter((obj) => obj.doctorOut != "in");
    let inList = this.props.VisitList.filter((obj) => obj.doctorOut === "in");
    let visitListData = [];
    if (outList.length > 0) {
      visitListData = [...outList, ...inList];
    } else {
      visitListData = inList;
    }
    this.state = {
      visitList: this.props.otherVisitDate ? [] : visitListData,
      allvisitList: this.props.otherVisitDate ? [] : visitListData,
      moreDetails: false,
      moreDetailsData: {},
      // visitList: this.props.otherVisitDate?[]:this.props.VisitList,
      // allvisitList: this.props.otherVisitDate?[]:this.props.VisitList,
      search: this.props.search,
      search_date: this.props.search_date,
      taskContent: "",
      row: {},
      outId: "",
      alert: null,
      holidayList: [],
      nextVisit: false,
      nextVisitData: [],
      econsultation:
        this.props.history?.location?.econsultation === true ? true : false,
      patientPasswordData: {},
      dietChatModal: false,
      dietChartTableData: {
        visitId: "",
        patientId: "",
        app: "",
        notification_token: "",
        remark: "",
        type: "",
        treatmentType: "",
        optionCount: "",
        dietType: "",
        dietLanguage: "",
        treatmentId: "",
        treatmentName: "",
        startTimeDelay: 0,
        dietId: "",
        color: "",
        patient: {},
        dietOption: 2,
        mealType: "veg-s",
        selectCalorie: 0,
        email: "",
        firstName: "",
        comments: [],
        file: [],
        mealTime: "09:00 am",
        ptVisitDate: "",
        shareDate: "",
        starStatus: "",
      },
      dietId: "",
      _notificationSystem: null,
      outPatientCount: this.props.otherVisitDate
        ? 0
        : this.props.outPatientList.length > 0
          ? this.props.outPatientList.length
          : 0,
      eVisitCount: this.props.otherVisitDate
        ? 0
        : this.props.eVisitList.length > 0
          ? this.props.eVisitList.length
          : 0,
      visitCount: this.props.otherVisitDate
        ? 0
        : this.props.VisitList.length > 0
          ? this.props.VisitList.length
          : 0,
      activeTab:
        this.props.history?.location?.econsultation === true
          ? "online"
          : "offline",
    };
    this.renderDay = this.renderDay.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onDismissNextVisit = this.onDismissNextVisit.bind(this);
    this.getRedirect = this.getRedirect.bind(this);
    this.backPage = this.backPage.bind(this);
    this.changeMealTime = this.changeMealTime.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.password = this.password.bind(this);
    this.getVisitList = this.getVisitList.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.taskScreen = this.taskScreen.bind(this);
    this.nameContent = this.nameContent.bind(this);
    this.get_age_by_dob = this.get_age_by_dob.bind(this);
    this.isApp = this.isApp.bind(this);
    this.action = this.action.bind(this);
    this.dietDetail = this.dietDetail.bind(this);
    this.tagsContent = this.tagsContent.bind(this);
    this.moreDetailsButton = this.moreDetailsButton.bind(this);
    this.s3UrlIntervalFlag = null;
    EventEmitter.subscribe(
      "reload_home",
      this.fetchReloadDashboardFun.bind(this)
    );
    this.onDismissMoreDetail = this.onDismissMoreDetail.bind(this);
  }

  componentDidMount() {
    if (!this.props.isTagList) this.props.tagListAction();

    if (!this.props.isStarStatusList) this.props.starStatusListAction();

    if (this.props.history?.location?.goToHome) {
      // let visitData =   !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let visitData = [];
      if (this.props.history?.location?.activeTab === "OutPatientList") {
        visitData = this.props.outPatientList;
        this.setState({ activeTab: "OutPatientList" });
      } else if (this.props.history?.location?.activeTab === "online") {
        visitData = this.props.eVisitList;
        this.setState({ activeTab: "online" });
      } else {
        visitData = this.state.visitList;
        this.setState({ activeTab: "offline" });
      }
      // let visitData =  this.props.history?.location?.activeTab === 'OutPatientList' ? this.state.outPatientList : !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let homePid = this.props.history?.location?.goToHome;
      var localindex_index = visitData
        .map(function (el) {
          return el.pId;
        })
        .indexOf(homePid);
      if (localindex_index != -1) {
        let localData = visitData[localindex_index];
        visitData.splice(localindex_index, 1);
        visitData.unshift(localData);
      }
      this.setState({ visitList: visitData });
    }

    this.s3UrlIntervalFlag = setInterval(() => {
      if (this.props.TagList.length > 0) {
        let payload = [];
        for (let i in this.props.TagList) {
          let obj = {
            filePath: this.props.TagList[i].image,
            id: this.props.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }
    }, 810000);

    let callVisit = true;
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.holidayListAction();
    let _this = this;
    const search_date = this.props.search_date;
    // appConstants.socket.on("updateTaskScreen", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //     _this.props.visitListAction(search_date);
    //   }, 1000);
    // });

    // appConstants.socket.on("addPatient", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //     _this.props.visitListAction(search_date);
    //   }, 1000);
    // });
    if (callVisit) {
      if (this.props.otherVisitDate) {
        if (this.state.econsultation)
          this.props.getOnlineVisitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD"),
            "",
            ""
          );
        else
          this.props.visitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD"),
            "",
            "",
            "searchDate"
          );
      } else {
        if (
          this.props.VisitList.length === 0 &&
          !this.props.isOfflineApiCalled
        ) {
          this.props.visitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD")
          );
        } else {
          if (this.props.TagList.length > 0) {
            let payload = [];
            for (let i in this.props.TagList) {
              let obj = {
                filePath: this.props.TagList[i].image,
                id: this.props.TagList[i]._id,
              };
              payload.push(obj);
            }

            this.props.s3SiginedUrlArrayAction({ imageArray: payload });
          }
          if (this.state.isLoading === true) {
            this.setState({ isLoading: false });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.s3UrlIntervalFlag);
  }

  componentWillReceiveProps(nextProps) {
    let { allvisitList } = this.state;

    let outList = nextProps.VisitList.filter((obj) => obj.doctorOut != "in");
    let inList = nextProps.VisitList.filter((obj) => obj.doctorOut === "in");
    let visitListData = [];
    if (outList.length > 0) {
      visitListData = [...outList, ...inList];
    } else {
      visitListData = inList;
    }

    let eoutList = nextProps.eVisitList.filter((obj) => obj.doctorOut != "in");
    let einList = nextProps.eVisitList.filter((obj) => obj.doctorOut === "in");
    let evisitListData = [];
    if (eoutList.length > 0) {
      evisitListData = [...eoutList, ...einList];
    } else {
      evisitListData = einList;
    }

    if (nextProps.isReloadHome !== this.props.isReloadHome) {
      this.setState({
        activeTab: "offline",
        search_date: nextProps.search_date,
        visitList: nextProps.VisitList,
        econsultation: false,
      });
      if (nextProps.TagList.length > 0) {
        let payload = [];
        for (let i in nextProps.TagList) {
          let obj = {
            filePath: nextProps.TagList[i].image,
            id: nextProps.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }
    }

    // if(nextProps.isReloadHome !== this.props.isReloadHome){
    //   console.log(nextProps,"insidereload")
    //    this.setState({activeTab:"offline",search_date:nextProps.search_date,visitList:nextProps.VisitList,econsultation: false})
    //    if (nextProps.TagList.length > 0) {
    //     let payload = []
    //     for (let i in nextProps.TagList) {
    //       let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
    //       payload.push(obj)
    //     }
    //     this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
    //   }
    // }

    // if(nextProps.isVisitListUpdateS3SignedUrlOnlineList !== this.props.isVisitListUpdateS3SignedUrlOnlineList){
    //   this.setState({visitList:nextProps.eVisitList})
    // }

    // if(nextProps.isVisitListUpdateS3SignedUrlOutList !== this.props.isVisitListUpdateS3SignedUrlOutList){
    //   console.log(nextProps,"nextpropsoutlist")
    //   this.setState({visitList:nextProps.outPatientList})
    // }

    if (
      nextProps.isVisitListUpdateS3SignedUrl !==
      this.props.isVisitListUpdateS3SignedUrl
    ) {
      let vistList =
        this.state.activeTab === "offline"
          ? nextProps.VisitList
          : this.state.activeTab === "online"
            ? nextProps.eVisitList
            : nextProps.outPatientList;
      this.setState({ visitList: nextProps.VisitList });
    }

    if (nextProps.isS3SignedUrlArr !== this.props.isS3SignedUrlArr) {
      this.props.UpdateVisitListS3SiginedUrlArrayAction();
    }

    if (nextProps.isTagList !== this.props.isTagList) {
      if (nextProps.TagList.length > 0) {
        let payload = []
        for (let i in nextProps.TagList) {
          let obj = { "filePath": nextProps.TagList[i].image, "id": nextProps.TagList[i]._id }
          payload.push(obj)
        }
        this.props.s3SiginedUrlArrayAction({ "imageArray": payload })
      }
    }

    if (nextProps.isPrescriptionUpdated !== this.props.isPrescriptionUpdated) {
      let visitList =
        this.state.activeTab === "offline"
          ? visitListData
          : this.state.activeTab === "online"
            ? nextProps.eVisitList
            : nextProps.outPatientList;
      this.setState({ visitList: [...visitList] });
    }

    if (nextProps.isVisitDetail !== this.props.isVisitDetail) {
      let visitlist =
        this.state.activeTab === "offline"
          ? visitListData
          : this.state.activeTab === "online"
            ? nextProps.eVisitList
            : nextProps.outPatientList;
      let visitData = visitlist.find(
        (obj) => obj.id === nextProps.visitDetail._id
      );
      if (visitData != undefined) {
        visitData.activeTab = this.state.activeTab;
        visitData.lastDiet = nextProps.visitDetail.patient_data[0].lastDiet;
        visitData.language = nextProps.visitDetail.patient_data[0].language;
        visitData.patientStatus = nextProps.visitDetail.patientStatus;
        visitData.flag = nextProps.visitDetail.flag;
        visitData.taskList = nextProps.visitDetail.Tasks;
        visitData.taskList1 = nextProps.visitDetail.Tasks;
        visitData.documentList = nextProps.visitDetail.Documents;
        visitData.dietHistory = nextProps.visitDetail.dietHistory;
        visitData.complete_task = nextProps.visitDetail.complete_tasks_count;
        visitData.share =
          nextProps.visitDetail.doc_count +
          " / " +
          nextProps.visitDetail.read_docs_count;
        visitData.read_share = nextProps.visitDetail.read_docs_count;
        visitData.task =
          nextProps.visitDetail.task_count +
          " / " +
          nextProps.visitDetail.complete_tasks_count;
        visitData.validPrescription = nextProps.visitDetail.validPrescription;
        visitData.reportCount = nextProps.visitDetail.hasOwnProperty(
          "reportCount"
        )
          ? nextProps.visitDetail.reportCount
          : visitData.reportCount;
        visitData.nextDate = nextProps.visitDetail.patient_data[0].nextDate;
        visitData.next_visit_date =
          nextProps.visitDetail.patient_data[0].nextDate;
        this.props.updateVisitDetailAction(visitData);
      }
    }
    // if (nextProps.isTaskListUpdated !== this.props.isTaskListUpdated){

    //   if (this.state.row) {
    //     let stateRow = this.state.row;
    //     for (let x in nextProps.VisitList) {
    //       if (nextProps.VisitList[x].id === stateRow.id) {
    //         this.handleTaskScreen(nextProps.VisitList[x],"taskListupdate");
    //       }
    //     }
    //   }
    // }

    if (nextProps.isVisitListUpdated !== this.props.isVisitListUpdated) {
      this.state.activeTab === "online"
        ? this.setState({
          visitList: evisitListData,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        })
        : this.state.activeTab === "offline"
          ? this.setState({
            visitList: visitListData,
            visitCount: nextProps.visitCount,
            eVisitCount: nextProps.eVisitCount,
            outPatientCount: nextProps.outPatientCount,
          })
          : this.setState({
            visitList: nextProps.outPatientList,
            visitCount: nextProps.visitCount,
            eVisitCount: nextProps.eVisitCount,
            outPatientCount: nextProps.outPatientCount,
          });
    }

    if (nextProps.isAddToVisitList !== this.props.isAddToVisitList) {
      //this.state.activeTab==="online"?this.setState({visitList:evisitListData,visitCount:nextProps.visitCount,eVisitCount:nextProps.eVisitCount,outPatientCount:nextProps.outPatientCount}):this.setState({visitList:visitListData,visitCount:nextProps.visitCount,eVisitCount:nextProps.eVisitCount,outPatientCount:nextProps.outPatientCount});

      if (nextProps.TagList.length > 0) {
        let payload = [];
        for (let i in nextProps.TagList) {
          let obj = {
            filePath: nextProps.TagList[i].image,
            id: nextProps.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }

      if (this.state.activeTab === "online") {
        this.setState({
          visitList: evisitListData,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        });
      }

      if (this.state.activeTab === "offline") {
        this.setState({
          visitList: visitListData,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        });
      }
    }

    if (nextProps.isHolidayList !== this.props.isHolidayList) {
      this.setState({ holidayList: nextProps.HolidayList.list });
    }

    if (nextProps.isVisitList !== this.props.isVisitList) {
      // let j = 0;
      // let visitList;

      // let blackArr = [];
      // let yellowArr = [];
      // let whiteArr = [];
      //console.log(nextProps,"nextprops")
      // let vData = nextProps.VisitList.data.data;
      // for (let x in vData) {
      //   let key = vData[x];
      //   let patient = key.patient_data[0];
      //   if (patient == undefined) {
      //     continue;
      //   }
      //   let h = patient.height;
      //   let w = patient.weight;
      //   let g = patient.gender;
      //   let row = {
      //     id: key._id,
      //     _id: patient._id,
      //     sn: ++j,
      //     pId: patient._id,
      //     patientNo: patient.patientNo,
      //     patientId: patient.patientClinicId,
      //     name:
      //       patient.title + " " + patient.firstName + " " + patient.lastName,
      //     firstName: patient.firstName,
      //     lastName: patient.lastName,
      //     age: PF.getAgeByDob(patient.dob),
      //     age_color: patient.age_color,
      //     city: patient.city,
      //     share: key.doc_count + " / " + key.read_docs_count,
      //     read_share: key.read_docs_count,
      //     task: key.task_count + " / " + key.complete_tasks_count,
      //     complete_task: key.complete_tasks_count,
      //     diet: 0,
      //     in_time: moment(key.createdAt).format("hh:mm A"),
      //     next_visit_date: patient.nextDate,
      //     app: patient.app,
      //     email: patient.email,
      //     status: key.status,
      //     height: h,
      //     weight: w,
      //     bmi: PF.getBMI(h, w),
      //     ibw: PF.getIBW(h, w, g),
      //     c1: PF.getMaintainWeight(h, w, g),
      //     c2: PF.getLooseWeight(h, w, g),
      //     c3: PF.getGainWeight(h, w, g),
      //     segCalorie: PF.getCalorie(h, w, g),
      //     calorieType: PF.getCalorieType(h, w, g),
      //     created_date: moment(key.createdAt).format("YYYY-MM-DD"),
      //     remark: key.remark,
      //     patientRemark: patient.remark,
      //     taskList: key.Tasks,
      //     documentList: key.Documents,
      //     educatorOut: key.educatorOut,
      //     doctorOut: key.doctorOut,
      //     lastDiet: patient.lastDiet,
      //     notification_token: patient.notification_token,
      //     createDate: patient.createdAt,
      //     econsultation: key.econsultation,
      //     reportCount: key.reportCount,
      //     lastVisitDate: key.lastVisitDate,
      //     billingCycle: key.billingCycle,
      //     ...patient,
      //   };

      //   row.remark = patient.remark;
      //   row.flag = key.flag;
      //   row.patientStatus = key.patientStatus;
      //   row.language = patient.language;
      //   row.validPrescription = key.validPrescription;
      //   row.emrVisitId = key._id;
      //   if (row.educatorOut === "out") {
      //     yellowArr.push(row);
      //   } else if (row.doctorOut === "out") {
      //     blackArr.push(row);
      //   } else {
      //     whiteArr.push(row);
      //   }
      // }

      //visitList = whiteArr.concat(blackArr);
      //visitList = visitList.concat(yellowArr);
      // visitList = blackArr.concat(whiteArr);
      //visitList = visitList.concat(yellowArr);

      //allvisitList = visitList;

      // this.setState({ visitList, allvisitList }, () =>
      //   this.sortvisitListData(this.state.econsultation)
      // );
      if (this.state.activeTab === "online") {
        this.setState({
          eVisitCount: nextProps.eVisitList.length,
          visitList: evisitListData,
          econsultation: true,
        });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }

      if (this.state.activeTab === "offline") {
        this.setState({
          visitCount: nextProps.VisitList.length,
          visitList: visitListData,
          econsultation: false,
        });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }

      if (this.state.activeTab === "OutPatientList") {
        this.setState({
          outPatientCount: nextProps?.outPatientList?.length,
          visitList: nextProps.outPatientList,
        });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }

      this.setState({ showProcessing: false });
      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }

      if (this.state.row) {
        let stateRow = this.state.row;
        for (let x in nextProps.VisitList) {
          if (nextProps.VisitList[x].id === stateRow.id) {
            this.handleTaskScreen(nextProps.VisitList[x], "visitlist");
          }
        }
      }
    }

    if (nextProps.isPatientOut !== this.props.isPatientOut) {
      // let element = document.getElementsByClassName(this.state.outId);
      this.setState({ outId: "" });

      // let visitList = this.state.visitList;
      // for(let x in visitList){
      //     if(this.state.outId === visitList[x].id){
      //         visitList[x].educatorOut = 'out';
      //     }
      // }
      //this.successAlert("Patinet Out Successfully.");
      //this.props.visitListAction(this.state.search_date);

      // setTimeout(function () {
      //   let params = {
      //     clinicId: localStorage.getItem("clinicId"),
      //   };
      //   appConstants.socket.emit("updateTaskScreen", params);
      // }, 1000);
      //this.props.updateVisitListAction(nextProps.PatientOut.visitId,this.state.activeTab==="online"?true:false)
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  fetchReloadDashboardFun() {
    let _this = this;
    const search_date = _this.props.search_date;
    _this.setState({ search_date: search_date, activeTab: "offline" });
    setTimeout(function () {
      _this.props.visitListAction(search_date, "", "", "searchDate");
    }, 1000);
  }

  getIndex(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  isApp2(cell, row) {
    let app = "";

    if (row.app) {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-check check" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          {/*<span className='badge mobile-green-clr'><span className="fa fa-mobile" aria-hidden="true"></span></span>*/}
          <span className="badge1">
            <img className="clr-m" src={mobilegreenImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    } else {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-times cross" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          {/*<span className='badge mobile-red-clr'><span className="fa fa-mobile" aria-hidden="true"></span></span>*/}
          <span className="badge1">
            <img className="clr-m" src={mobileImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    }

    return app;
  }

  // dietDetail(cell,row) {
  //     console.log(row,"RowED")
  //     //let dietHistory = row.dietHistory;
  //     let lastDiets = row.lastDiet;
  //     //let dietColor0 = "red";
  //     //let dietColor1 = "red";
  //     // if (lastDiets[0]) {
  //     //     if (lastDiets[0]['color'] === 'blue') {
  //     //         dietColor0 = "blue";
  //     //     }
  //     //     else if (lastDiets[0]['color'] === 'blue') {
  //     //         dietColor0 = "green";
  //     //     }
  //     // }

  //     // if (lastDiets[1]) {
  //     //     if (lastDiets[1]['color'] === 'blue') {
  //     //         dietColor1 = "blue";
  //     //     }
  //     //     else if (lastDiets[1]['color'] === 'blue') {
  //     //         dietColor1 = "green";
  //     //     }
  //     // }
  //     let dietContent = "";
  //     if (lastDiets && lastDiets['1']) {
  //         let date1 = moment(lastDiets['0'].createdAt).format('YYYY-MM-DD');
  //         let date2 = moment(lastDiets['1'].createdAt).format('YYYY-MM-DD');
  //         let today = moment().format('YYYY-MM-DD');

  //         if (today === date1 && today === date2) {

  //             if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId === lastDiets['1'].treatmentId) {

  //                 dietContent = <OverlayTrigger placement="bottom"
  //                     overlay={<Tooltip id="Name">
  //                         <b>SHARED BY:<br />
  //                             {

  //                                 (<span className={(lastDiets['1'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['1'].addedByType} - {lastDiets['1'].treatmentName}/ {lastDiets['1'].treatmentType}/ {moment(lastDiets['1'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)

  //                             }
  //                         </b>
  //                     </Tooltip>}>
  //                     {(lastDiets['1'].addedByType === 'doctor') ?

  //                         <span className="badge bgred drs-left"><span className="fa fa-history" aria-hidden="true"></span></span>

  //                         :
  //                         <span className="badge bgred drs-left"><span className="fa fa-history" aria-hidden="true"></span></span>
  //                     }
  //                 </OverlayTrigger>

  //             } else if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId !== lastDiets['1'].treatmentId) {

  //                 dietContent = <OverlayTrigger placement="bottom"
  //                     overlay={<Tooltip id="Name">
  //                         <b>SHARED BY:<br />
  //                             {

  //                                 (<span className={(lastDiets['1'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['1'].addedByType} - {lastDiets['1'].treatmentName}/ {lastDiets['1'].treatmentType}/ {moment(lastDiets['1'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)

  //                             }
  //                         </b>
  //                     </Tooltip>}>
  //                     {(lastDiets['1'].addedByType === 'doctor') ?
  //                         <span className="badge bgred drs-left" ><span className="fa fa-history" aria-hidden="true"></span></span>

  //                         :
  //                         <span className="badge bgred drs-left" ><span className="fa fa-history" aria-hidden="true"></span></span>

  //                     }
  //                 </OverlayTrigger>

  //             } else {

  //                 dietContent = <OverlayTrigger placement="bottom"
  //                     overlay={<Tooltip id="Name">
  //                         <b>SHARED BY:<br />
  //                             {

  //                                 (<span className={(lastDiets['1'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['1'].addedByType} - {lastDiets['1'].treatmentName}/ {lastDiets['1'].treatmentType}/ {moment(lastDiets['1'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)

  //                             }
  //                         </b>
  //                     </Tooltip>}>

  //                     <span className="badge bgred drs-left"><span className="fa fa-history" aria-hidden="true"></span></span>

  //                 </OverlayTrigger>

  //             }

  //         } else if (today === date1 && today !== date2) {

  //             dietContent = <OverlayTrigger placement="bottom"
  //                 overlay={<Tooltip id="Name">
  //                     <b>SHARED BY:<br />
  //                         {
  //                             (<span className={(lastDiets['1'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['1'].addedByType} - {lastDiets['1'].treatmentName}/ {lastDiets['1'].treatmentType}/ {moment(lastDiets['1'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)

  //                         }
  //                     </b>
  //                 </Tooltip>}>
  //                 {/*<span className={lastDiets['0'].color + " font-11"}>{lastDiets['0'].treatmentName}</span>*/}
  //                 {/*<span className={lastDiets['0'].color + " font-11"}>{lastDiets['0'].treatmentName}}</span>*/}
  //                 {(lastDiets['0'] && lastDiets['0'].addedByType === 'educator') ?
  //                     <span className="badge bgred drs-left" ><span className="fa fa-history" aria-hidden="true"></span></span>
  //                     :
  //                     <span className="badge bgred drs-left" ><span className="fa fa-history" aria-hidden="true"></span></span>

  //                 }
  //             </OverlayTrigger>

  //         } else {
  //             dietContent = '';
  //         }
  //     } else if (lastDiets && lastDiets['0']) {
  //         let date1 = moment(lastDiets['0'].createdAt).format('YYYY-MM-DD');
  //         let today = moment().format('YYYY-MM-DD');
  //         if (lastDiets['0'] && lastDiets['0'].addedByType === 'educator' && today === date1) {

  //             dietContent = <OverlayTrigger placement="bottom"
  //                 overlay={<Tooltip id="Name">
  //                     <b>SHARED BY:<br />
  //                         {
  //                             (<span className={(lastDiets['0'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['0'].addedByType} - {lastDiets['0'].treatmentName}/ {lastDiets['0'].treatmentType}/ {moment(lastDiets['0'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)
  //                         }
  //                     </b>
  //                 </Tooltip>}>
  //                 {/*<span className={lastDiets['0'].color + " font-11"}>{lastDiets['0'].treatmentName}</span>*/}
  //                 <span className="badge bgred drs-left" onClick={() => this.getRedirect(row.pId)}><span className="fa fa-history" aria-hidden="true"></span></span>

  //             </OverlayTrigger>

  //         } else if (today === date1) {

  //             dietContent = <OverlayTrigger placement="bottom"
  //                 overlay={<Tooltip id="Name">
  //                     <b>SHARED BY:<br />
  //                         {
  //                             (<span className={(lastDiets['0'].addedByType === "educator" ? 'pink' : 'red')}><b>1. </b>{lastDiets['0'].addedByType} - {lastDiets['0'].treatmentName}/ {lastDiets['0'].treatmentType}/ {moment(lastDiets['0'].createdAt).format('MMMM Do YYYY, h:mm:ss a')}<br /></span>)

  //                         }
  //                     </b>
  //                 </Tooltip>}>
  //                 {/*<span className={lastDiets['0'].color + " font-11"}>{lastDiets['0'].treatmentName}</span>*/}
  //                 <span className="badge bgred drs-left" onClick={() => this.getRedirect(row.pId)}><span className="fa fa-history" aria-hidden="true"></span></span>

  //             </OverlayTrigger>

  //         } else {
  //             dietContent = '';
  //         }
  //     } else {
  //         dietContent = '';
  //     }

  //     return dietContent;

  // }

  dietDetail(cell, row, enumObject, rowIndex) {
    //console.log(row, "rowrrr")

    let dietContent = "";
    let lastDiets = row.lastDiet;
    if (lastDiets && lastDiets["1"]) {
      let date1 = moment(lastDiets["0"].createdAt).format("YYYY-MM-DD");
      let date2 = moment(lastDiets["1"].createdAt).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");

      if (today === date1 && today === date2) {
        if (
          lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
          lastDiets["0"].treatmentId === lastDiets["1"].treatmentId
        ) {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              {lastDiets["1"].addedByType === "doctor" ? (
                <span className={lastDiets["1"].color + " font-11"}>
                  {lastDiets["1"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              ) : (
                <span className={lastDiets["0"].color + " font-11"}>
                  {lastDiets["0"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              )}
            </OverlayTrigger>
          );
        } else if (
          lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
          lastDiets["0"].treatmentId !== lastDiets["1"].treatmentId
        ) {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              {lastDiets["1"].addedByType === "doctor" ? (
                <span className={lastDiets["1"].color + " font-11"}>
                  {lastDiets["1"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              ) : (
                <span className={lastDiets["0"].color + " font-11"}>
                  {lastDiets["0"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              )}
            </OverlayTrigger>
          );
        } else {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              <span className={lastDiets["0"].color + " font-11"}>
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            </OverlayTrigger>
          );
        }
      } else if (today === date1 && today !== date2) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["1"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["1"].addedByType} -{" "}
                      {lastDiets["1"].treatmentName}/{" "}
                      {lastDiets["1"].treatmentType}/{" "}
                      {moment(lastDiets["1"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            {lastDiets["0"] && lastDiets["0"].addedByType === "educator" ? (
              <span className={lastDiets["0"].color + " font-11"}>
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            ) : (
              <span className={lastDiets["0"].color + " font-11"}>
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            )}
          </OverlayTrigger>
        );
      } else {
        dietContent = "";
      }
    } else if (lastDiets && lastDiets["0"]) {
      let date1 = moment(lastDiets["0"].createdAt).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");
      if (
        lastDiets["0"] &&
        lastDiets["0"].addedByType === "educator" &&
        today === date1
      ) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["0"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["0"].addedByType} -{" "}
                      {lastDiets["0"].treatmentName}/{" "}
                      {lastDiets["0"].treatmentType}/{" "}
                      {moment(lastDiets["0"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            <span className={lastDiets["0"].color + " font-11"}>
              {lastDiets["0"].treatmentName}
              <span
                className="badge bgred drs-left"
                onClick={() => this.getRedirect(row.pId)}
              >
                <span className="fa fa-history" aria-hidden="true"></span>
              </span>
            </span>
          </OverlayTrigger>
        );
      } else if (today === date1) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["0"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["0"].addedByType} -{" "}
                      {lastDiets["0"].treatmentName}/{" "}
                      {lastDiets["0"].treatmentType}/{" "}
                      {moment(lastDiets["0"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            <span className={lastDiets["0"].color + " font-11"}>
              {lastDiets["0"].treatmentName}
              <span
                className="badge bgred drs-left"
                onClick={() => this.getRedirect(row.pId)}
              >
                <span className="fa fa-history" aria-hidden="true"></span>
              </span>
            </span>
          </OverlayTrigger>
        );
      } else {
        dietContent = "";
      }
    } else {
      dietContent = "";
    }

    let html = <span>{dietContent}</span>;
    return html;
  }

  isApp(cell, row) {
    // let lastDiet =
    //   row && row.lastDiet && row.lastDiet.length > 0
    //     ? row.lastDiet[0].readStatus
    //     : "";

    let app = "";

    if (row.app) {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-check check" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          <span className="">
            <img className="clr-m" src={mobilegreenImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    } else {
      app = (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="remove">
              <span className="fa fa-times cross" aria-hidden="true"></span>
            </Tooltip>
          }
        >
          <span className="">
            <img className="clr-m" src={mobileImg} alt="" />
          </span>
        </OverlayTrigger>
      );
    }

    return app;

    //var readStatus = 'read';
    // var patientReadStatus = "read";
    // for (let i in row.documentList) {
    //   if (row.documentList[i].patientRead === "unread") {
    //     patientReadStatus = "unread";
    //   }
    // }
    //let dietDetail = this.dietDetail(row);
    // var html = "";
    // if (lastDiet && lastDiet.length > 0) {
    //   if (lastDiet === "unread") {
    //     row["remarkHtml"] = "not seen by patient";
    //     if (row.remarkHtml !== "") {
    //       html = (
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
    //         >
    //           <span className="badge bgred drs-left  mobile-red-clr">
    //             <span className="fa fa-cutlery" aria-hidden="true"></span>
    //           </span>
    //         </OverlayTrigger>
    //       );
    //     }
    //   } else {
    //     row["remarkHtml"] = "seen by patient";
    //     if (row.remarkHtml !== "") {
    //       html = (
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
    //         >
    //           <span className="badge bgred drs-left mobile-green-clr">
    //             <span className="fa fa-cutlery" aria-hidden="true"></span>
    //           </span>
    //         </OverlayTrigger>
    //       );
    //     }
    //   }
    // }

    // if (row.documentList["0"]) {
    //   return (
    //     <div
    //       className="doc-label"
    //       onClick={() =>
    //         this.setState({ taskScreen: true, taskScreenData: row })
    //       }
    //     >
    //       {app}
    //       {/* <OverlayTrigger
    //         placement="bottom"
    //         overlay={
    //           <Tooltip id="Name">
    //             <b>
    //               SHARE:
    //               <br />
    //               {row.documentList.map((value, key) => {
    //                 return (
    //                   <span
    //                     className={
    //                       value.patientRead === "read"
    //                         ? "green"
    //                         : value.addedByType === "educator"
    //                         ? "pink"
    //                         : "red"
    //                     }
    //                   >
    //                     <b>{key + 1}. </b>
    //                     {value.documentName}
    //                     <br />
    //                   </span>
    //                 );
    //               })}
    //             </b>
    //           </Tooltip>
    //         }
    //       >
    //         <span
    //           className={
    //             patientReadStatus === "read" ? "badge bggreen" : "badge bgred"
    //           }
    //         >
    //           {row.documentList.length}
    //         </span>
    //       </OverlayTrigger> */}
    //       {html}
    //     </div>
    //   );
    // } else {
    //   return (
    //     <div
    //       className="doc-label"
    //       onClick={() =>
    //         this.setState({ taskScreen: true, taskScreenData: row })
    //       }
    //     >
    //       {app}
    //       {html}
    //     </div>
    //   );
    // }
  }

  backPage() {
    this.setState({ dietChatModal: false });
  }

  changeMealTime(time) {
    let formData = this.state.dietChartTableData;
    formData["mealTime"] = time;
    this.setState({ formData });
  }

  outButton(cell, row) {
    let html = "";
    if (
      row.educatorOut !== "out" &&
      row.doctorOut === "out" &&
      this.state.activeTab != "OutPatientList"
    ) {
      let readStatus = "read";
      for (let i in row.documentList) {
        if (row.documentList[i].status === "unread") {
          readStatus = "unread";
        }
      }

      for (let i in row.taskList) {
        if (row.taskList[i].status === "uncomplete") {
          readStatus = "unread";
        }
      }

      for (let i in row.lastDiet) {
        if (
          row.lastDiet[i].color === "red" &&
          moment().format("YYYY-MM-DD") ===
          moment(row.lastDiet[i].createdAt).format("YYYY-MM-DD")
        ) {
          readStatus = "unread";
        }
      }

      html = (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="remove">
              Out{readStatus === "unread" ? "" : "(No Work Pending)"}
            </Tooltip>
          }
        >
          <Button
            simple
            bsStyle={readStatus === "unread" ? "danger" : "success"}
            bsSize="xs"
            onClick={this.patientVisitOut.bind(this, row.id)}
          >
            <img
              title="Out Patient"
              className="table-action-ic"
              src={DeleteIc}
              alt="delete"
            />
          </Button>
        </OverlayTrigger>
      );
    }
    return html;
  }

  get_age_by_dob(cell, row, enumObject, rowIndex) {
    // return (row && row.age)?parseInt(row.age):'';
    return (
      <div className={"ageColor_" + row.age_color}>
        {row && row.age === "" ? "" : parseInt(row.age, 10)}
      </div>
    );
  }

  patientVisitOut(id) {
    this.setState({ outId: id });
    //console.log(this.state.econsultation,"999999")
    confirmAlert({
      title: "Confirm to out",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let msg = "Patient Out Successfully";
            this.successAlert(msg);
            this.props.patientOutAction(id, this.state.econsultation);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  getOutClass(cell, row) {
    if (row.educatorOut === "out") {
      return row.id + " Ed-Out";
    } else if (row.doctorOut === "out") {
      return row.id + " Dr-Out";
    } else {
      return row.id;
    }
  }

  getPatientOutClass(cell, row) {
    let { doctorOut, educatorOut, id, lastVisitDate, billingCycle, addVisit } =
      row;
    // if (doctorOut === 'out' && educatorOut === 'out') {
    //     return id + " Ed-Out";
    // }
    // else {
    let date1 = new Date(lastVisitDate);
    let date2 = new Date(addVisit);
    var paetientColor = "";
    if (billingCycle) {
      billingCycle = JSON.parse(billingCycle);
    }
    date1 = date1.setDate(date1.getDate() + billingCycle);
    date1 = moment(date1).format("YYYY-MM-DD");
    date2 = moment(date2).format("YYYY-MM-DD");
    if (lastVisitDate == null) {
      paetientColor = "register-patient";
    }
    // else if(new Date(date1) > new Date(date2)){
    else if (date1 > date2) {
      paetientColor = "reported-patient";
    } else {
      paetientColor = "followup-patient";
    }
    return id + " " + paetientColor;
    // }
  }

  nextButton(cell, row, enumObject, rowIndex) {
    return (
      <>
        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="NextVisit">Next Visit</Tooltip>}
        >
          <span
            style={{ display: "inline-block", marginRight: "6px" }}
            onClick={() =>
              this.setState({
                nextVisit: true,
                nextVisitData: row,
                patientOut: false,
              })
            }
          >
            <img className="table-action-ic" src={ScheduleIc} alt="" />{" "}
            {row.next_visit_date !== null && row.next_visit_date
              ? moment(row.next_visit_date).format("DD-MM-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
        {row.lastDiet.length != 0 && (
          <span style={{ display: "inline-block", marginRight: "6px" }}>
            {this.diet(cell, row)}
          </span>
        )}
        {row.patientStatus === "old" && row.flag === "yes" && (
          <span style={{ display: "inline-block", marginRight: "3px" }}>
            {this.emr(cell, row)}
          </span>
        )} */}
        <span style={{ display: "inline-block", marginRight: "3px" }}>
          {this.password(cell, row)}
        </span>
        {this.outButton(cell, row)}
        {this.moreDetailsButton(cell, row)}
      </>
    );
  }

  moreDetailsButton(cell, row) {
    return (
      <Link
        onClick={() => {
          let emrData = {};
          this.props.taskScreenAction(row);
          if (this.props.patientEmrData) {
            if (this.props.patientEmrData.patientId === row._id)
              emrData = {
                patientId: this.props.patientEmrData.patientId,
                isEmr: this.props.patientEmrData.isEmr,
              };
          }
          this.props.emrSetAction(emrData);
          let objStarStatus = {};
          let strPatientStarStatus = "";
          if (row?.patientStarStatus)
            objStarStatus = this.props.starStatusList.find(
              (obj) => obj._id === row.patientStarStatus
            );
          if (objStarStatus) {
            strPatientStarStatus = objStarStatus.title;
          }
          this.setState({
            moreDetails: true,
            moreDetailsData: row,
            starStatus: strPatientStarStatus,
          });
        }}
      >
        <img
          title="More Details"
          className="table-action-ic"
          src={MoreIc}
          alt="more"
        />
      </Link>
    );
  }

  onDismissNextVisit() {
    this.setState({ nextVisit: false });
    // this.successAlert("Next Visit Successfully Submitted");
    let msg = "";
    msg = this.state.patientOut
      ? "Patient Out Successfully"
      : "Next Visit Successfully Submitted";
    this.successAlert(msg);
  }

  successAlert(msg) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 3000);

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  taskScreen(cell, row, enumObject, rowIndex) {
    return (
      <Link
        to="#"
        onClick={(e) => {
          this.handleTaskScreen(row, "taskScreen");
        }}
      >
        {row.patientId}
      </Link>
    );
  }

  handleTaskScreen(row) {
    this.setState({ row: row });
    if (!row.isVisitDetailApiCalled) {
      let payload = row;
      payload.activeTab = this.state.activeTab;
      row.search_date = this.state.search_date;
      this.props.visitDetailAction(row);
    }
  }

  nameContent(cell, row, enumObject, rowIndex) {
    let colorCls = "";

    if (row.doctorOut === "out" && row.educatorOut === "out") {
      colorCls = "pt-black-color";
    } else {
      colorCls = "pt-blue-color";
    }

    let _row = row;
    _row.activeTab = this.state.activeTab;
    let strPatientStarStatus = "";
    // row.patientStarStatus.toString() === "0" ? "" :
    //   row.patientStarStatus.toString() === "1" ? "***" :
    //     row.patientStarStatus.toString() === "2" ? "#" :
    //       row.patientStarStatus.toString() === "3" ? "@" :
    //         row.patientStarStatus.toString() === "4" ? "*****" :
    //           <span style={{ "color": "red" }}>***</span>
    let objStarStatus = {};
    if (row?.patientStarStatus)
      objStarStatus = this.props.starStatusList.find(
        (obj) => obj._id === row.patientStarStatus
      );
    if (objStarStatus) strPatientStarStatus = objStarStatus.title;

    var remarkDr = "";

    if (row.messageForEducator && row.messageForEducator !== "") {
      remarkDr = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.messageForEducator}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">DR</span></span>
      </OverlayTrigger>
    }

    var remarkP = "";

    if (row.patientRemark && row.patientRemark !== "") {
      remarkP = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">R</span></span>
      </OverlayTrigger>
    }



    return (
      <Link
        className="black-txt"
        to="#"
        onClick={(e) => {
          this.handleTaskScreen(row);
        }}
      >
        <div className="doc-label">
          <div className={!row.app ? colorCls : ""}>
            {row.name}
            {strPatientStarStatus}
            {(row.patientRemark != "" || row.messageForEducator) &&
              <span style={{ paddingLeft: "15px", display: "flex" }}>
                {remarkDr != "" && <span> {remarkDr}</span>}
                {row.patientRemark != "" &&
                  <span>{remarkP}</span>
                }
              </span>
            }
          </div>
        </div>
      </Link>
    );
    // let app ="";

    // if (row.app) {
    //     app = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove"><span className="fa fa-check check" aria-hidden="true"></span></Tooltip>}>
    //               <span className='badge1 badge2'><img className="clr-m" src={mobilegreenImg}  alt=""/></span>
    //           </OverlayTrigger>;
    // } else {
    //      app = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove"><span className="fa fa-times cross" aria-hidden="true"></span></Tooltip>}>
    //               <span className='badge1 badge2'><img className="clr-m" src={mobileImg}  alt=""/></span>
    //            </OverlayTrigger>;
    // }

    // var readStatus = "read";
    // var patientReadStatus = "read";
    // for (let i in row.documentList) {
    //   if (row.documentList[i].status === "unread") {
    //     readStatus = "unread";
    //   }
    //   if (row.documentList[i].patientRead === "unread") {
    //     patientReadStatus = "unread";
    //   }
    // }

    // var html = "";
    // row["remarkHtml"] = row && row.remark ? row.remark : "";
    // if (row.remarkHtml !== "") {
    //   html = (
    //     <OverlayTrigger
    //       placement="bottom"
    //       overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
    //     >
    //       <span className="dr_msg bgred badge">
    //         <span className="glyphicon" aria-hidden="true">
    //           Dr
    //         </span>
    //       </span>
    //     </OverlayTrigger>
    //   );
    // }
    // var remarkP = "";

    // if (
    //   row.patientRemark &&
    //   row.patientRemark !== "" &&
    //   row.created_date === moment(row.createDate).format("YYYY-MM-DD")
    // ) {
    //   remarkP = (
    //     <OverlayTrigger
    //       placement="bottom"
    //       overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}
    //     >
    //       <span className="badge bgred drs-left">
    //         <span className="glyphicon" aria-hidden="true">
    //           R
    //         </span>
    //       </span>
    //     </OverlayTrigger>
    //   );
    // }

    // let colorCls = "";

    // if (row.doctorOut === "out" && row.educatorOut === "out") {
    //   colorCls = "pt-black-color";
    // } else {
    //   colorCls = "pt-blue-color";
    // }

    // if (row.documentList["0"]) {
    //   return (
    //     <Link
    //       className="black-txt"
    //       to="#"
    //       onClick={(e) => {
    //         this.handleTaskScreen(row,"name content1");
    //       }}
    //     >
    //       <div className="doc-label">
    //         <div className={!row.app ? colorCls : ""}>{row.name}</div> {html}
    //         {remarkP}
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={
    //             <Tooltip id="Name">
    //               <b>
    //                 SHARE:
    //                 <br />
    //                 {row.documentList.map((value, key) => {
    //                   return (
    //                     <span
    //                       key={key}
    //                       className={
    //                         value.status === "read" ||
    //                           value.patientRead === "read"
    //                           ? "green"
    //                           : value.addedByType === "educator"
    //                             ? "pink"
    //                             : "red"
    //                       }
    //                     >
    //                       <b>{key + 1}. </b>
    //                       {value.documentName}
    //                       <br />
    //                     </span>
    //                   );
    //                 })}
    //               </b>
    //             </Tooltip>
    //           }
    //         >
    //           <span
    //             className={
    //               readStatus === "read" || patientReadStatus === "read"
    //                 ? "badge bggreen"
    //                 : "badge bgred"
    //             }
    //           >
    //             <span
    //               className="glyphicon glyphicon-share-alt"
    //               aria-hidden="true"
    //             ></span>
    //           </span>
    //         </OverlayTrigger>
    //       </div>
    //     </Link>
    //   );
    // } else {
    //   return (
    //     <Link
    //       className="black-txt"
    //       to="#"
    //       onClick={(e) => {
    //         this.handleTaskScreen(row,"name content2");
    //       }}
    //     >
    //       <div className="doc-label">
    //         <div className={!row.app ? colorCls : ""}>
    //           {row.name}
    //           {remarkP}
    //         </div>
    //       </div>
    //     </Link>
    //   );
    // }
  }

  handleSearch(data) {
    let search_date = moment(data).format("YYYY-MM-DD");
    this.setState({
      search_date: search_date,
      visitCount: 0,
      eVisitCount: 0,
      outPatientCount: 0,
      activeTab: "offline",
    });
    this.props.visitListAction(search_date, "", "", "searchDate");
  }

  action(cell, row, enumObject, rowIndex) {
    return this.nextButton(cell, row, enumObject, rowIndex);
  }

  emr(cell, row, enumObject, rowIndex) {
    let html = "";
    //   if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {

    //     }

    if (row.patientStatus === "old") {
      if (row.flag === "yes") {
        html = (
          <span
            onClick={() =>
              localStorage.setItem("patientVisit", JSON.stringify(row))
            }
          >
            <Link
              to={{
                pathname: `print/${row.id}/${row.pId}/brand/${row.language}`,
              }}
            >
              <img
                alt="Prescription"
                className="table-action-ic"
                title="Prescription"
                src={PrescriptionGreenIc}
              ></img>
            </Link>
          </span>
        );
      } else {
        html = <span></span>;
      }
    }
    return html;
  }

  diet(cell, row) {
    let html = "";
    row.lastDiet.length != 0
      ? (html = (
        <Link onClick={(e) => this.getRedirect(row)}>
          <img
            className="table-action-ic"
            alt="Diet"
            title="Diet"
            src={DietIc}
          ></img>
        </Link>
      ))
      : (html = "");
    return html;
  }

  getRedirect(row) {
    let tempDietChartTableData = {};
    let tempPatienData = {
      name: row.name,
      patientId: row._id,
      age: row.age,
      weight: row.weight,
      height: row.height,
      gender: row.gender,
      patientClinicId: row.patientClinicId,
    };
    tempDietChartTableData.visitId = row.id;
    tempDietChartTableData.patientId = row._id;
    tempDietChartTableData.patientClinicId = row.patientClinicId;
    tempDietChartTableData.app = row.app;
    tempDietChartTableData.notification_token = row.notification_token;
    tempDietChartTableData.remark = row.patientRemark;
    tempDietChartTableData.type = "";
    tempDietChartTableData.treatmentType = row.lastDiet[0].treatmentType;
    tempDietChartTableData.optionCount = row.lastDiet[0].optionCount;
    tempDietChartTableData.dietType = row.lastDiet[0].dietType;
    tempDietChartTableData.dietLanguage = row.lastDiet[0].dietLanguage;
    tempDietChartTableData.treatmentId = row.lastDiet[0].treatmentId;
    tempDietChartTableData.treatmentName = row.lastDiet[0].treatmentName;
    tempDietChartTableData.startTimeDelay = row.lastDiet[0].startTimeDelay;
    tempDietChartTableData.dietId = row.lastDiet[0]._id;
    tempDietChartTableData.color = row.lastDiet[0].color;
    tempDietChartTableData.patient = tempPatienData;
    tempDietChartTableData.dietOption = 2;
    tempDietChartTableData.mealType = row.lastDiet[0].dietType;
    tempDietChartTableData.selectCalorie = row.lastDiet[0].calorie;
    tempDietChartTableData.email = row.email;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.comments = row.lastDiet[0].comments;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.mealTime = row.lastDiet[0].mealTime;
    tempDietChartTableData.ptVisitDate = row.lastDiet[0].createdAt;
    tempDietChartTableData.shareDate = row.lastDiet[0].isShareDate;
    tempDietChartTableData.dietLanguage = row.language ? row.language : "1";
    tempDietChartTableData.selectedComments = row.lastDiet[0].comments;
    this.setState({
      dietChartTableData: tempDietChartTableData,
      dietId: row.lastDiet[0]._id,
      dietChatModal: true,
    });
    //this.props.history.push('/diet-history', { row: id });
  }

  password(cell, row) {
    let html = "";
    html = (
      <Link className="" onClick={(e) => this.showPassword(cell, row)}>
        <img
          className="table-action-ic"
          alt="Password"
          title="Password"
          src={PasswordIc}
        ></img>
      </Link>
    );
    return html;
  }

  showPassword(cell, row) {
    let tempObj = {
      patientId: row.patientId,
      userName: `${row.firstName} ${row.lastName}`,
      password: row.show_password,
    };
    this.setState({
      showPassword: !this.state.showPassword,
      patientPasswordData: tempObj,
    });
  }

  renderDay(props, currentDate, selectedDate) {
    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(currentDate).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            props["title"] = list[x].message;
            if (list[x].type === "leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = " weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate === todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass ? extClass : " red-bg";
      } else if (currentDate2 === 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day === currentDate.day()) {
          extClass = " weekoffPink-bg";
        }
      }
    }
    props["className"] = props["className"] + extClass;

    return <td {...props}>{currentDate.date()}</td>;
  }

  sortvisitListData(econsStatus) {
    let { allvisitList, econsultation, eVisitCount, visitCount } = this.state;
    econsultation = econsStatus;
    let visitList = [];
    if (econsStatus === true) {
      visitList = allvisitList.filter((item) => item.econsultation);
      eVisitCount = visitList.filter((item) => item.doctorOut === "in").length;
      visitCount = allvisitList.filter(
        (item) => !item.econsultation && item.doctorOut === "in"
      ).length;
    }

    if (econsStatus === false) {
      visitList = allvisitList.filter((item) => !item.econsultation);
      visitCount = visitList.filter((item) => item.doctorOut === "in").length;
      eVisitCount = allvisitList.filter(
        (item) => item.econsultation && item.doctorOut === "in"
      ).length;
    }

    if (this.props.history?.location?.goToHome) {
      let homePid = this.props.history?.location?.goToHome;
      var localindex_index = visitList
        .map(function (el) {
          return el.pId;
        })
        .indexOf(homePid);
      if (localindex_index != -1) {
        let localData = visitList[localindex_index];
        visitList.splice(localindex_index, 1);
        visitList.unshift(localData);
      }
    }

    this.setState({
      ...this.state,
      econsultation,
      visitList,
      eVisitCount,
      visitCount,
    });
  }

  search_date(props) {
    return (
      <FormGroup className="mop-0 m-0">
        <div className="form-liness-outline w550 sm-wwi btn-Mng">
          <VisitListButton
            visitCount={this.props.visitCount}
            eVisitCount={this.props.eVisitCount}
            outPatientCount={this.props.outPatientCount}
            getVisitList={this.getVisitList}
            activedTab={this.state.activeTab}
          />
          <Calendar
            selectedDate={this.state.search_date}
            handleChange={(e) => this.handleSearch(e, "date")}
          />
          {props.searchField}
          {props.clearBtn}
        </div>
      </FormGroup>
    );
  }

  async getVisitList(mode) {
    this.setState({ activeTab: mode });
    if (mode === "offline") {
      let outList = this.props.VisitList.filter((obj) => obj.doctorOut != "in");
      let inList = this.props.VisitList.filter((obj) => obj.doctorOut === "in");
      let visitListData = [];
      if (outList.length > 0) {
        visitListData = [...outList, ...inList];
      } else {
        visitListData = inList;
      }
      (!this.props.isOfflineApiCalled && this.props.VisitList.length) === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD")
        )
        : // this.setState({visitList:this.props.VisitList,econsultation:false,isLoading:false})
        this.setState({
          visitList: visitListData,
          econsultation: false,
          isLoading: false,
        });
    }

    if (mode === "online") {
      let outList = this.props.eVisitList.filter(
        (obj) => obj.doctorOut != "in"
      );
      let inList = this.props.eVisitList.filter(
        (obj) => obj.doctorOut === "in"
      );
      let evisitListData = [];
      if (outList.length > 0) {
        evisitListData = [...outList, ...inList];
      } else {
        evisitListData = inList;
      }
      !this.props.isOnilneApiCalled && this.props.eVisitList.length === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD"),
          "",
          "",
          "online"
        )
        : this.setState({
          visitList: evisitListData,
          econsultation: true,
          isLoading: false,
        });
    }

    if (mode === "OutPatientList") {
      //console.log(this.props.isOutListApiCalled,this.props.outPatientList,"llllllllllll")
      (!this.props.isOutListApiCalled && this.props.outPatientList.length) === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD"),
          "",
          "",
          "OutPatientList"
        )
        : this.setState({
          visitList: this.props.outPatientList,
          isLoading: false,
        });
    }
  }

  tagsContent(cell, row, enumObject, rowIndex) {
    let html = "";
    html = (
      <span className="doc-label-flex doc-label" style={{ flexWrap: "wrap" }}>
        {row.patientATag &&
          row.patientATag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}
        {row.patientBTag &&
          row.patientBTag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}
        {row.patientCTag &&
          row.patientCTag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}

        {/* {row.patientCTag && row.patientCTag.map((obj,key)=>{
       let date1 = moment(obj.createdAt);
       let date2=moment(row.createDate);
       let dateDiffrence=0
       if(date1<date2)
          dateDiffrence =date2.diff(date1, 'days')
      else dateDiffrence =date1.diff(date2, 'days') 
       
        console.log(date1,date2,dateDiffrence,row.name,"3 days")
       if(dateDiffrence<=3){
          filteredCtagArr.push(obj)
          return (<img  title={obj.tagName} src={obj.s3Path}></img>)
       }
          
      //return (<img  src={obj.s3Path}></img>)
      })
     } */}
      </span>
    );

    return html;
  }

  onDismissMoreDetail() {
    this.setState({
      moreDetails: false,
      // moreDetailsData: {},
    });
    this.successAlert("Data Saved Successfully");
  }

  render() {
    const options = {
      noDataText: this._setTableOption(),
      searchPanel: (props) => this.search_date(props),
      searchPosition: "left",
      clearSearch: true,
    };

    return (
      <Row>
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Col
          sm={12}
          md={7}
          className="bg-boxess-doctor w-sm12 table-responsive table-responsive-new table_doctor_login "
        >
          <EducatorVisitListTable
            visitList={this.state.visitList}
            options={options}
            getIndex={this.getIndex}
            getPatientOutClass={this.getPatientOutClass}
            taskScreen={this.taskScreen}
            getOutClass={this.getOutClass}
            tagsContent={this.tagsContent}
            nameContent={this.nameContent}
            get_age_by_dob={this.get_age_by_dob}
            isApp={this.isApp}
            action={this.action}
          />
        </Col>
        <Col sm={12} md={5} className="no-padding-right" id="task-content">
          <EducatorTaskScreen row={this.state.row} />
        </Col>
        <Modal
          backdrop="static"
          className="pa-next-visit"
          show={this.state.nextVisit}
          onHide={() => this.setState({ nextVisit: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Set Next Visit
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                <NextVisit
                  nextVisitData={this.state.nextVisitData}
                  patientOutFun={this.patientVisitOut}
                  patientOut={this.state.patientOut}
                  onDismissNextVisit={this.onDismissNextVisit}
                  econsultation={this.state.econsultation}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          className="pa-patient-password"
          show={this.state.showPassword}
          onHide={() =>
            this.setState({ showPassword: false, patientPasswordData: {} })
          }
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              User Password
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <div className="clinic-user-password">
              <div>
                <b>Patient Id: </b>
                <span>{this.state.patientPasswordData.patientId}</span>
              </div>
              <div>
                <b>Patient Name: </b>
                <span>{this.state.patientPasswordData.userName}</span>
              </div>
              <div>
                <b>Password: </b>
                <span>{this.state.patientPasswordData.password}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          backdrop="static"
          className="pa-next-visit modal-more-info"
          show={this.state.moreDetails}
          onHide={() => this.setState({ moreDetails: false })}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              <div className="row">
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="title">
                    {`${this.state.moreDetailsData.firstName}
                                            - 
                                        ${this.state.moreDetailsData.patientId} \u00A0\u00A0`}
                    {this.state.starStatus !== "" && (
                      <>
                        |
                        <span
                          style={{
                            display: "inline-block",
                            margin: "0 5px",
                          }}
                        >
                          {this.state.starStatus}
                        </span>{" "}
                      </>
                    )}
                    |
                    <span
                      style={{
                        display: "inline-block",
                        margin: "0 5px",
                      }}
                    >
                      Age
                      {this.state.moreDetailsData &&
                        this.state.moreDetailsData?.age != 0
                        ? ":"
                        : ""}{" "}
                      {parseFloat(this.state.moreDetailsData.age).toFixed()}
                      {` Y / ${this.state.moreDetailsData.gender === "Male" ? "M" : "F"
                        }`}
                    </span>
                    {this.state.moreDetailsData.phone !== "" && (
                      <>
                        |
                        <span
                          style={{
                            display: "inline-block",
                            margin: "0 5px",
                          }}
                        >
                          contact: {this.state.moreDetailsData.phone}
                        </span>{" "}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share ">
            <PatientMoreDetailEducator
              moreDetails={this.state.moreDetailsData}
              search_date={this.state.search_date}
              onDismissMoreDetail={this.onDismissMoreDetail}
            />
          </Modal.Body>
        </Modal>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    VisitList: state.home.VisitList,
    isVisitList: state.home.isVisitList,
    isVisitListError: state.home.isVisitListError,
    eVisitList: state.home.eVisitList,
    outPatientList: state.home.outPatientList,
    visitListActiveTab: state.home.visitListActiveTab,
    otherVisitDate: state.home.otherVisitDate,
    isVisitListUpdated: state.home.isVisitListUpdated,
    visitCount: state.home.visitCount,
    eVisitCount: state.home.eVisitCount,
    outPatientCount: state.home.outPatientCount,
    isAddToVisitList: state.home.isAddToVisitList,
    isTaskListUpdated: state.home.isTaskListUpdated,
    isPrescriptionUpdated: state.home.isPrescriptionUpdated,
    isOutListApiCalled: state.home.isOutListApiCalled,
    isOnilneApiCalled: state.home.isOnilneApiCalled,
    isOfflineApiCalled: state.home.isOfflineApiCalled,
    isStarStatusList: state.home.isStarStatusList,
    isStarStatusListError: state.home.isStarStatusListError,
    starStatusList: state.home.starStatusList,
    isReloadHome: state.home.isReloadHome,
    patientEmrData: state.emr.patientEmrData,

    isVisitDetail: state.home.isVisitDetail,
    isVisitDetailError: state.home.isVisitDetailError,
    visitDetail: state.home.visitDetail,

    PatientOut: state.home.PatientOut,
    isPatientOut: state.home.isPatientOut,
    isPatientOutError: state.home.isPatientOutError,

    HolidayList: state.home.HolidayList,
    isHolidayList: state.home.isHolidayList,
    isHolidayListError: state.home.isHolidayListError,

    isTagList: state.home.isTagList,
    isTagListError: state.home.isTagListError,
    TagList: state.home.TagList,

    isS3SignedUrlArr: state.home.isS3SignedUrlArr,
    isVisitListUpdateS3SignedUrlOnlineList:
      state.home.isVisitListUpdateS3SignedUrlOnlineList,
    isVisitListUpdateS3SignedUrlOutList:
      state.home.isVisitListUpdateS3SignedUrlOutList,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    visitListAction,
    patientOutAction,
    holidayListAction,
    taskScreenAction,
    emrSetAction,
    setVisitListActiveTabAction,
    updateVisitListAction,
    visitDetailAction,
    updateVisitDetailAction,
    tagListAction,
    s3SiginedUrlArrayAction,
    UpdateVisitListS3SiginedUrlArrayAction,
    UpdateVisitListS3SiginedUrlArrayOnlineListAction,
    UpdateVisitListS3SiginedUrlArrayOutListAction,
    getOnlineVisitListAction,
    starStatusListAction,
  })(EducatorScreen)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { withRouter } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import {
  Modal
} from 'react-bootstrap';
import * as API from 'Front/api/emr';
import { ACTIONS } from './Prescription';


const AddRecommendationOnPrescription = ({ setModalStatusRecommendationOfPrescription, patientId, appointmentId, modalStatusRecommendationOfPrescription, dispatch, selectedRecommendation }) => {
  const [recommendationList, setRecommendationList] = useState([])
  const [arrRecommendation, setArrRecommendation] = useState([])
  const [onPageLoad, setOnPageLoad] = useState(false)

  const showRecommendationFun = () => {
    setModalStatusRecommendationOfPrescription(true);
    getRecommendedPrescription()
  }

  const getRecommendedPrescription = async () => {
    let resp = await API.recommendedPrescription({ patientId: patientId, appointmentId: appointmentId });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      setRecommendationList(resp.data.recommendedPrescriptionData.data)
      setArrRecommendation(resp.data.recommendedPrescription)
      setOnPageLoad(true)
    }

  }

  const findRecommendation = () => {
    recommendationList.map(recommendation => {

      arrRecommendation.map(item => {

        if (item.recommendedPrescriptionId === recommendation._id) {
          checkRecommendation(item.recommendedPrescriptionId)
        }
        setArrRecommendation([...arrRecommendation])
      })

    })
  }

  const checkRecommendation = (recommendedPrescriptionId) => {

    let chkRecommendations = document.getElementsByName("recommendation");
    for (var itm of chkRecommendations) {
      if (recommendedPrescriptionId === itm.getAttribute("id")) {
        itm.checked = true;
      }
    }
  }

  const addOrDeleteRecommendation = (e, row) => {

    if (e.target.checked) {
      let arr = [];
      arr.push({
        "recommendedPrescriptionId": row._id,
        "recommendedPrescription": row.recommendedPrescription,
        "recommendedType": row.recommendedType
      })

      setArrRecommendation([...arr, ...arrRecommendation])

    } else {
      deleteRecommendation(e)
    }

  }

  const deleteRecommendation = (e) => {
    let arr = arrRecommendation;
    arr.map((itm, idx) => {
      if (itm.recommendedPrescriptionId === e.target.id) {
        arr.splice(idx, 1);
        return;
      }
    })
    setArrRecommendation([...arr])
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    let resp = await API.addRecommendation({ recommendedData: [{ patientId: patientId, appointmentId: appointmentId, recommendedPrescription: arrRecommendation }] });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      dispatch({ type: ACTIONS.ADD_RECOMMENDATION, payload: { "recommendation": arrRecommendation } })
    }
    hideModal()
  }

  const hideModal = () => {
    setModalStatusRecommendationOfPrescription(false)
  }

  useEffect(() => {
    if (onPageLoad)
      findRecommendation()
    setOnPageLoad(false)
  }, [onPageLoad])

  return (
    <span >
      <Button type="button" id="btnShowRecommendation" onClick={e => showRecommendationFun()} className="btn btn-fill btn-primary">Recommendation On Prescription</Button>
      <Modal show={modalStatusRecommendationOfPrescription} onHide={hideModal} dialogClassName="modal-xl">
        <Modal.Header closeButton className="bg-thumbcolor">

          <Modal.Title id="example-modal-sizes-title-lg">Recommendation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="suggetion-box-enlarge">

          <div className="suggestion-lists">
            <div className="selected-complaints">
              <h5>Recommendation Selected</h5>
            </div>
            <div className="suggestion-list">
              {
                recommendationList.map((itm, idx) => {
                  return (<div class="form-check form-check-emr">
                    <input class="form-check-input" id={itm._id} type="checkbox" value={itm.recommendedPrescription} onChange={(e) => { addOrDeleteRecommendation(e, itm) }} name="recommendation" />
                    <label class="form-check-label" for={itm._id}>
                      {itm.recommendedPrescription.substring(0, 100)}
                    </label>
                  </div>)

                })
              }

            </div>
            <div className='row'>
              <div className='col-sm-6' style={{ marginTop: '15px' }}>

              </div>
              <hr></hr>
            </div>
            <div className='text-right'>
              <Button onClick={e => submitHandler(e)} className="btn btn-primary btn-fill right">Add</Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </span>
  )
}

export default AddRecommendationOnPrescription
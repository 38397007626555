/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import {
  addClinicHolidayAction,
  ClinicHolidayHistoryAction,
} from "Front/actions/settings";
import * as API from "Front/api/settings";
//import icocalender from 'Front/assets/img/ico-calender.png';
import moment from "moment";
import Radio from "Front/elements/CustomRadio/CustomRadio.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
//import loadingImg from '../../../assets/img/loading.gif';
import { confirmAlert } from "react-confirm-alert";
import Loading from "react-loading";
import {
  weekOptions,
  patternFontOptions,
  appTimeSlot,
} from "Admin/variables/Variables.jsx";
import Select from "react-select";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
var notificationCreated = false;
class ClinicCalender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        calendar_date: "",
        message: "",
        clinic_id: localStorage.getItem("_id"),
        clinic_name: localStorage.getItem("clinicName"),
        status: "active",
        //type:"other",
        type: "Leave",
        holidayList: [],
        cDates: "",
      },
      calenderErr: false,
      messageErr: false,
      showForm: true,
      selectedDays: [],
      disabledDays: [],
      holidayList: [],
      historyHolidayList: [],
    };
    this.handleDayClick = this.handleDayClick.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.deleteHolyDay = this.deleteHolyDay.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.ClinicHolidayHistoryAction();

    let formData = this.state.formData;

    formData["cDates"] = `"06-08-2020", "06-09-2020", "06-16-2020"`;

    this.setState({ formData });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isClinicHolidayHistory !== this.props.isClinicHolidayHistory
    ) {
      this.setState({ holidayList: nextProps.clinicHolidayHistory.list });

      let newList = [];
      let disabledDays = [];
      let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));

      if (holiday_date.length > 0) {
        for (let p in holiday_date) {
          disabledDays.push(moment(holiday_date[p]).format("YYYY-MM-DD"));
        }
      }

      if (
        nextProps.clinicHolidayHistory &&
        nextProps.clinicHolidayHistory.list &&
        nextProps.clinicHolidayHistory.list.length > 0
      ) {
        if (
          nextProps.clinicHolidayHistory.week_off &&
          nextProps.clinicHolidayHistory.week_off.length > 0
        ) {
          let week = [];

          nextProps.clinicHolidayHistory.week_off.map((key, index) =>
            week.push({
              value: key.day,
              label: key.name,
            })
          );
          this.setState({ week_off: week });
        }

        nextProps.clinicHolidayHistory.list.map((v, i) => {
          if (v && v.calendarDate && v.calendarDate.length > 0) {
            v.calendarDate.map((s) => {
              disabledDays.push(moment(s).format("YYYY-MM-DD"));
              newList.push({
                createdAt: v.createdAt,
                cDate: s,
                message: v.message,
                type: v.type,
                id: i + 1,
                _id: v._id,
              });
            });
          }
        });

        this.setState({ disabledDays: disabledDays });
      } else {
        this.setState({ disabledDays: disabledDays });
      }

      newList = newList.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.setState({ historyHolidayList: newList });
    }

    if (nextProps.isAddClinicHoliday !== this.props.isAddClinicHoliday && notificationCreated) {
      notificationCreated = false;
      this.setState({
        formData: {
          calendar_date: "",
          message: "",
          clinic_id: localStorage.getItem("_id"),
          clinic_name: localStorage.getItem("clinicName"),
          status: "active",
          type: "Leave",
          holidayList: [],
          cDates: "",
        },
        selectedDays: [],
      });

      this.successAlert("Clinic holiday successfully added.");
      this.props.ClinicHolidayHistoryAction();
    }

    if (
      nextProps.isAddClinicHolidayError !== this.props.isAddClinicHolidayError
    ) {
      this.errorAlert();
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    if (e.target.name === "message" && e.target.value == "") {
      this.setState({ messageErr: true });
    } else {
      this.setState({ messageErr: false });
    }
    this.setState({ formData: field });
  }

  handleRadio(event) {
    const target = event.target;
    let field = this.state.formData;
    field[target.name] = target.value;
    this.setState({ formData: field });
  }

  /*handleDayClick(day, { selected }) {
       const { selectedDays } = this.state;
       if (selected) {
         const selectedIndex = selectedDays.findIndex(selectedDay =>
           DateUtils.isSameDay(selectedDay, day)
         );
         selectedDays.splice(selectedIndex, 1);
       } else {
         selectedDays.push(day);
       }
       this.setState({ selectedDays });
 
       let data = this.state.formData;
          let dateArr = [];
          for(let i in selectedDays){
 
             let mytime = moment(new Date(selectedDays[i])).format('MM-DD-YYYY');
 
             dateArr.push(mytime);
          }
 
          data["calendar_date"]  = dateArr;
 
 
          this.setState({formData: data,calenderErr:false});
     }*/

  handleDayClick(day, { selected }) {
    let calenderDate = moment(day).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");
    let disabledDays = this.state.disabledDays;

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(calenderDate).day()) {
          return false;
        }
      }
    }

    if (calenderDate >= todayDate && !disabledDays.includes(calenderDate)) {
      const { selectedDays } = this.state;
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
      this.setState({ selectedDays });

      let data = this.state.formData;
      let dateArr = [];
      for (let i in selectedDays) {
        let mytime = moment(new Date(selectedDays[i])).format("MM-DD-YYYY");

        dateArr.push(mytime);
      }

      data["calendar_date"] = dateArr;

      this.setState({ formData: data, calenderErr: false });
    }
  }

  handleWeekOff(field) {
    let multipleSelect = this.state.week_off;
    multipleSelect = field;
    this.setState({ week_off: multipleSelect });

    let entry = [];
    let data = this.state.formData;
    field.map((key, index) =>
      entry.push({
        day: key.value,
        name: key.label,
      })
    );
    if (entry) {
      for (let i in entry) {
        if (entry[i].day === 7) {
          entry[i].day = 0;
        }
      }
    }
    data["week_off"] = entry;
    this.setState({ formData: data });
  }
  renderDay(day) {
    const currentDate = day;

    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(currentDate).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            if (list[x].type === "Leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = "weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate == todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass ? extClass : " red-bg";
      } else if (currentDate2 == 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    if (weekDays.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(currentDate).day()) {
          extClass = "weekoffPink-bg";
        }
      }
    }

    const cellStyle = {
      height: 30,
      width: 30,
      position: "relative",
      borderRadius: 15,
    };

    const dateStyle = {
      color: "lightgray",
      bottom: 0,
      right: 0,
      fontSize: 14,
      paddingTop: 5,
    };
    return (
      <div className={extClass} style={cellStyle}>
        <div style={dateStyle}> {day.getDate()} </div>
      </div>
    );
  }

  getIndex(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  getDate(cell, row, rowIndex) {
    return moment(row && row.createdAt).format("DD-MM-YYYY");
  }

  getHolidayDate(cell, row, rowIndex) {
    return moment(row && row.cDate).format("DD-MM-YYYY");
  }

  DeleteConfirm(id) {
    this.deleteHolyDay(id);
    // confirmAlert({
    //     title: 'Confirm to Delete',
    //     message: 'Are you sure you want to delete?',
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: () => this.deleteHolyDay(id)
    //         },
    //         {
    //             label: 'No',
    //             //onClick: () => this.resetFormData()
    //         }
    //     ]
    // })
  }

  async deleteHolyDay(id) {
    let resp = await API.holidayDelete({ id: id });

    this.props.ClinicHolidayHistoryAction();
    this.successAlert("Successfully deleted.");
  }

  async updateWeekOff() {


    let entry = [];
    let data = this.state.formData;
    let field = this.state.week_off ? this.state.week_off : [];
    field.map((key, index) =>
      entry.push({
        day: key.value,
        name: key.label,
      })
    );
    if (entry) {
      for (let i in entry) {
        if (entry[i].day === 7) {
          entry[i].day = 0;
        }
      }
    }

    localStorage.setItem("is_week_off", JSON.stringify(entry));

    let resp = await API.updateClinicWeekOff({ week_off: entry });
    this.successAlert("Clinic week off successfully updated.");
  }

  actionDelete(cell, row, rowIndex) {
    return <a onClick={(e) => this.DeleteConfirm(row._id)}>Delete</a>;
  }

  saveHoliday() {
    if (
      this.state.formData.calendar_date !== "" &&
      this.state.formData.message !== ""
    ) {
      notificationCreated = true;
      this.props.addClinicHolidayAction(this.state.formData);
    } else {
      if (this.state.formData.message === "") {
        this.setState({ messageErr: true });
      }

      if (this.state.formData.calendar_date === "") {
        this.setState({ calenderErr: true });
      }
    }
  }

  successAlert(msg) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       //onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 1000);
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 1000);
  }

  errorAlert() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          //onConfirm={() => this.hideAlert()}
          //onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Something went wrong :)
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    let formData = this.state.formData;

    formData["message"] = "";

    this.setState({
      alert: null,
      formData: formData,
    });

    this.setState({ showForm: false });
    let _this = this;
    setTimeout(function () {
      _this.setState({ showForm: true });
    }, 300);
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  render() {
    const options = {
      noDataText: this._setTableOption(),
      //searchPanel: (props) => (this.search_date(props)),
      searchPosition: "left",
      clearSearch: true,

      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      //  paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: "bottom", // defau
    };

    return (
      <div className="clinic-calenders">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        <div>
          {this.state.showForm && (
            <div>
              <Form>
                <Row>
                  <Col md={12} lg={6} xl={5}>
                    <div>
                      <Col componentClass={ControlLabel} className="up-zero">
                        Calendar Date <span className="star">*</span>
                      </Col>
                      <Col>
                        <div className="icon-calender">
                          <DayPicker
                            selectedDays={this.state.selectedDays}
                            onDayClick={this.handleDayClick}
                            renderDay={this.renderDay}
                          />
                        </div>
                        <span
                          className="errorMsg"
                          style={{
                            display: this.state.calenderErr ? "block" : "none",
                          }}
                        >
                          This is required field
                        </span>
                      </Col>
                    </div>
                  </Col>
                  <Col md={12} lg={6} xl={7}>
                    <FormGroup>
                      <Col componentClass={ControlLabel}>
                        Reason For Leave <span className="star">*</span>
                      </Col>
                      <Col>
                        <FormControl
                          rows="2"
                          componentClass="textarea"
                          name="message"
                          bsClass="form-control"
                          defaultValue=""
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        <span
                          className="errorMsg"
                          style={{
                            display: this.state.messageErr ? "block" : "none",
                          }}
                        >
                          This is required field
                        </span>
                      </Col>
                    </FormGroup>
                    <div className="form-action">
                      <button
                        type="button"
                        onClick={this.saveHoliday.bind(this)}
                        className="btn-fill btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                    <FormGroup>
                      <Col componentClass={ControlLabel}>Week Off</Col>
                      <Col>
                        <Select
                          placeholder="Select Day"
                          closeOnSelect={false}
                          multi={true}
                          name="week_off"
                          value={this.state.week_off}
                          options={weekOptions}
                          onChange={(value) => {
                            this.handleWeekOff(value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <div className="form-action">
                      <button
                        type="button"
                        onClick={(e) => this.updateWeekOff()}
                        className="btn-fill btn-block btn btn-primary mr-2"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </div>
        <div>
          {this.state.showForm && (
            <div>
              {/* <h5>Holidays History</h5> */}
              <h5>Details of Holidays</h5>

              <Row>
                <Col
                  sm={12}
                  className="educator-L table-w holidays-history-table"
                >
                  <BootstrapTable
                    data={this.state.historyHolidayList}
                    search={true}
                    multiColumnSearch={true}
                    options={options}
                    striped
                    hover
                    condensed
                    pagination={true}
                  >
                    <TableHeaderColumn
                      hidden={true}
                      tdAttr={{ "data-attr": "id" }}
                      dataField="id"
                      dataSort={true}
                      isKey
                      searchable={false}
                    >
                      Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "5%" }}
                      tdStyle={{ width: "5%" }}
                      thAttr={{ "data-attr": "#" }}
                      dataField="sn"
                      dataFormat={this.getIndex.bind(this)}
                    >
                      #
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "10%", minWidth: "125px" }}
                      tdStyle={{ width: "10%", minWidth: "125px" }}
                      tdAttr={{ "data-attr": "HOLIDAY DATE" }}
                      dataField="cDate"
                      dataFormat={this.getHolidayDate.bind(this)}
                    >
                      {" "}
                      HOLIDAY DATE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "50%", minWidth: "200px" }}
                      tdStyle={{ width: "50%", minWidth: "200px" }}
                      tdAttr={{ "data-attr": "MESSAGE" }}
                      dataField="message"
                    >
                      MESSAGE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "8%" }}
                      tdStyle={{ width: "8%" }}
                      tdAttr={{ "data-attr": "DATE" }}
                      dataField="createdAt"
                      dataFormat={this.getDate.bind(this)}
                    >
                      APPLIED DATE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "5%" }}
                      tdStyle={{ width: "5%" }}
                      tdAttr={{ "data-attr": "TYPE" }}
                      dataField="type"
                    >
                      TYPE
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "5%" }}
                      tdStyle={{ width: "5%" }}
                      tdAttr={{ "data-attr": "TYPE" }}
                      dataField=""
                      dataFormat={this.actionDelete.bind(this)}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAddClinicHoliday: state.settings.isAddClinicHoliday,
    isAddClinicHolidayError: state.settings.isAddClinicHolidayError,
    AddClinicHoliday: state.settings.AddClinicHoliday,

    clinicHolidayHistory: state.settings.clinicHolidayHistory,
    isClinicHolidayHistory: state.settings.isClinicHolidayHistory,
    isClinicHolidayHistoryError: state.settings.isClinicHolidayHistoryError,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    addClinicHolidayAction,
    ClinicHolidayHistoryAction,
  })(ClinicCalender)
);

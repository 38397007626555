/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken, getRequestWithToken } from "./helper";

export const addAdvertisement = (data) =>
  postRequestWithTokenFile(data, "admin/add-advertisment");
export const updateAdvertisement = (data) =>
  postRequestWithTokenFile(data, "admin/update-advertisment");
export const advertisementList = (data) =>
  postRequestWithToken(data, "admin/advertisment-list");
export const advertisementChangeStatus = (data) =>
  postRequestWithToken(
    data,
    `admin/change-advertisment-status?id=${data.id}&status=${data.status}`
  );
export const appVersion = (data) =>
  getRequestWithToken(data, "mobile/latest-app-version");

export const editAppVersion = (data) =>
  postRequestWithToken(data, "mobile/edit-app-version");

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component, } from 'react';
import { withRouter } from 'react-router-dom';
class CustomCheckbox extends Component {

  render() {
    const { isChecked, number, label, status, inline, ...rest } = this.props;
    const classes = inline !== undefined ? "checkbox checkbox-inline" : "checkbox";

    return (
      <div className={classes}>
        <input id={number} type="checkbox" checked={this.props.isChecked} {...rest} />
        <label htmlFor={number}>{label}</label>
        {status &&
          <div>
            {(status === 'active') ?
              <span className="my-clinic-status active-color">Active</span>
              :
              <span className="my-clinic-status request-color">Pending</span>
            }
          </div>
        }
      </div>
    );
  }
}

export default withRouter(CustomCheckbox);

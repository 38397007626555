/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import HeaderLinks from './HeaderLinks.jsx'

// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

import dashRoutes from 'Admin/routes/dash.jsx';

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    }

    componentWillMount() {

    }

    makeBrand() {
        var name;
        var nameDone = false;
        dashRoutes.map((prop, key) => {
            if (!nameDone) {
                if (prop.collapse) {
                    if (prop.collapse && prop.views && prop.views.length) {
                        prop.views.map((prop, key) => {
                            if (prop.collapse && prop.views && prop.views.length) {
                                prop.views.map((prop, key) => {
                                    if (prop.path === this.props.location.pathname) {
                                        name = prop.name;
                                        nameDone = true;
                                    }

                                    if (this.props.location.pathname.indexOf(prop.path) !== -1) {
                                        name = prop.name;
                                    }
                                    return null;
                                });
                            }
                            else {
                                if (prop.path === this.props.location.pathname) {
                                    name = prop.name;
                                    nameDone = true;
                                }

                                if (this.props.location.pathname.indexOf(prop.path) !== -1) {
                                    name = prop.name;
                                }
                            }
                            return null;
                        })
                    }
                } else {
                    if (prop.redirect) {
                        if (prop.path === this.props.location.pathname) {
                            name = prop.name;
                        }
                    } else {
                        if (prop.path === this.props.location.pathname) {
                            name = prop.name;
                        }
                    }
                }
            }
            return null;
        })
        return name;
    }
    // function that makes the sidebar from normal to mini and vice-versa
    handleMinimizeSidebar() {
        document.body.classList.toggle('sidebar-mini');
    }
    // function for responsive that hides/shows the sidebar
    mobileSidebarToggle(e) {
        document.documentElement.classList.toggle('nav-open');
    }
    render() {

        //var wl=window.location.pathname;
        if (sessionStorage.getItem("curUrl") === null) {
            sessionStorage.setItem("curUrl", window.location.pathname);
        } else {
            if (sessionStorage.getItem("curUrl") !== window.location.pathname) {
                sessionStorage.setItem("prvUrl", sessionStorage.getItem("curUrl"));
                sessionStorage.setItem("curUrl", window.location.pathname);
            }
        }

        return (
            <Navbar fluid>
                <div className="navbar-minimize">
                    <button id="minimizeSidebar" className="btn btn-default btn-fill btn-round btn-icon" onClick={this.handleMinimizeSidebar}>
                        <i className="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
                        <i className="fa fa-navicon visible-on-sidebar-mini"></i>
                    </button>
                </div>
                <Navbar.Header>
                    <Navbar.Brand>
                        {/* Here we create navbar brand, based on route name */}
                        <a href="#pablo">{this.makeBrand()}</a>
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                </Navbar.Header>

                {/* Here we import the links that appear in navbar */}
                {window.innerWidth > 992 ? (<Navbar.Collapse><HeaderLinks /></Navbar.Collapse>) : null}

            </Navbar>
        );
    }
}

export default Header;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import LoginPage from "Front/views/Login/LoginPage.jsx";
import ForgotPasswordPage from "Front/views/Login/ForgotPasswordPage.jsx";
import ResetPassword from "Front/views/Login/ResetPassword.jsx";
import PatientSearch from "Front/views/PatientSearch/PatientSearch.jsx";
import InsulinPatient from "Front/views/PatientSearch/InsulinPatient.jsx";
import ClinicDocument from "Front/views/Pages/ClinicDocument.jsx";

//import ReceptionistPatientSearch from 'Front/views/PatientSearch/ReceptionistPatientSearch.jsx';
import PatientRegistration from "Front/views/Home/PatientRegistration.jsx";
import Dashboard from "Front/views/Dashboard/Dashboard.jsx";
import ColorNotations from "Front/views/Pages/ColorNotations/ColorNotations.jsx";

import ClinicCalender from "Front/views/Pages/ClinicCalender/ClinicCalender.jsx";
import ChangePassword from "Front/views/Pages/ChangePassword/ChangePassword.jsx";
import ManageRequest from "Front/views/Pages/ManageRequest/ManageRequest.jsx";
import UserList from "Front/views/Pages/UserList/UserList.jsx";
import Report from "Front/views/Pages/Report/Report.jsx";
import Notification from "Front/views/Pages/Notification/NotificationList.jsx";
import DietHistory from "Front/views/PatientSearch/DietHistory.jsx";
import PreferenceToSet from "Front/views/Pages/PreferenceToSet/PreferenceToSet.jsx";
import Appointment from "Front/views/Pages/Appointment/Appointment.jsx";

import BmiCalculator from "Front/views/Calculators/BmiCalculator.jsx";
import EGFRCalculator from "Front/views/Calculators/EGFRCalculator.jsx";
import HomaIRCalculator from "Front/views/Calculators/HomaIRCalculator.jsx";
import InsulineSensitivityIndex3PointsCalculator from "Front/views/Calculators/InsulineSensitivityIndex3PointsCalculator.jsx";
import InsulineSensitivityIndex5PointsCalculator from "Front/views/Calculators/InsulineSensitivityIndex5PointsCalculator.jsx";
import BoneAgeCalculator from "Front/views/Calculators/BoneAgeCalculator.jsx";
import GlyFracDiabetesCalculator from "Front/views/Calculators/GlyFracDiabetesCalculator.jsx";
import Tickets from "Front/views/Pages/SupportSystem/Tickets.jsx";
import TicketView from "Front/views/Pages/SupportSystem/TicketView.jsx";

import Complaints from "Front/views/Pages/Emr/Complaints/Complaints.jsx";
import Diagnosis from "Front/views/Pages/Emr/Diagnosis/Diagnosis.jsx";
import Prescription from "Front/views/Pages/Emr/Prescription/Prescription.jsx";
import PrintPrescription from "Front/views/Pages/Emr/Prescription/PrintPrescription.jsx";
import Print from "../views/Pages/Emr/Prescription/Print.jsx";
import VitalSign from "Front/views/Pages/Emr/VitalSign/VitalSign.jsx";
import History from "Front/views/Pages/Emr/History/History.jsx";
import BillingCycle from "../views/Pages/BillingCycle/BillingCycle.jsx";
import PatientDetails from "../views/Home/PatientDetails.js";
import PrescriptionSnapShot from "../views/Home/PrescriptionSnapShot.js";
import Settings from "../views/Pages/Settings/Settings.jsx";
import ClinicPerfomanceReport from "../views/Pages/Report/ClinicPerformanceReport.jsx";
import LabTestDetail from "../views/Pages/Emr/LabTests/LabTestDetail";
import Fibrosis4 from "../views/Calculators/Fibrosis4.jsx";
import Investigation from "../views/Pages/Emr/Investigation/Investigation.jsx"
import PrintLabDetail from "../views/Pages/Emr/LabTests/PrintLabDetail.jsx"
import MyClinicViewBox from "../../Front/components/MyClinicViewBox"
let userType = localStorage.getItem("userType");
let pagesRoutes = [];

if (userType === "receptionist") {
  pagesRoutes = [
    {
      path: "/support-ticket/:id",
      name: "Support Cicket",
      icon: "pe-7s-graph",
      component: TicketView,
    },
    {
      path: "/support-ticket",
      name: "Support Cicket",
      icon: "pe-7s-graph",
      component: Tickets,
    },
    {
      path: "/appointment",
      name: "Appointment",
      icon: "pe-7s-graph",
      component: Appointment,
    },
    {
      path: "/clinic-calenders",
      name: "Clinic Calender",
      icon: "pe-7s-graph",
      component: ClinicCalender,
    },
    {
      path: "/change-password",
      name: "Change Password",
      icon: "pe-7s-graph",
      component: ChangePassword,
    },
    { path: "/dashboard", name: "Dashboard", mini: "DB", component: Dashboard },
    {
      path: "/patient-search",
      name: "Patient Search",
      mini: "PS",
      component: PatientSearch,
    }, //ReceptionistPatientSearch
    {
      path: "/patient-edit",
      name: "Patient Edit",
      mini: "PE",
      component: PatientRegistration,
    },
    {
      path: "/notification",
      name: "Notification",
      icon: "pe-7s-graph",
      component: Notification,
    },
    {
      path: "/clinic-document",
      name: "Clinic Document",
      icon: "pe-7s-graph",
      mini: "CD",
      component: ClinicDocument,
    },
    {
      path: "/preference-to-set",
      name: "Preference To Set",
      icon: "pe-7s-graph",
      component: PreferenceToSet,
    },
    {
      path: "/billing-cycle",
      name: "Preference To Set",
      icon: "pe-7s-graph",
      component: BillingCycle,
    },
    {
      path: "/fib-4-calculator",
      name: "FIB4 Calculator",
      icon: "pe-7s-graph",
      component: Fibrosis4,
    },
    {
      path: "/bmi-calculator",
      name: "BMI Calculator",
      icon: "pe-7s-graph",
      component: BmiCalculator,
    },
    {
      path: "/eGFR-calculator",
      name: "eGFR Calculator",
      icon: "pe-7s-graph",
      component: EGFRCalculator,
    },
    {
      path: "/complaints/:appointmentId/:patientId/:printingLanguage",
      name: "Complaints",
      icon: "pe-7s-graph",
      component: Complaints,
    },
    {
      path: "/diagnosis/:appointmentId/:patientId/:printingLanguage",
      name: "Diagnosis",
      icon: "pe-7s-graph",
      component: Diagnosis,
    },

    {
      path: "/prescription/:appointmentId/:patientId/:printingLanguage",
      name: "Prescription",
      icon: "pe-7s-graph",
      component: Prescription,
    },
    // { path: "/prescription/:appointmentId/:patientId", name: "Prescription", icon: "pe-7s-graph", component: Prescription },
    {
      path: "/printPrescription/:appointmentId/:patientId/:serachType",
      name: "Print Prescription",
      icon: "pe-7s-graph",
      component: PrintPrescription,
    },
    {
      path: "/print/:appointmentId/:patientId/:serachType/:printLanguage",
      name: "Print",
      icon: "pe-7s-graph",
      component: Print,
    },
    {
      path: "/vital-sign/:appointmentId/:patientId/:printingLanguage",
      name: "Vital Sign",
      icon: "pe-7s-graph",
      component: VitalSign,
    },
    {
      path: "/print-lab-detail/:appointmentId/:patientId/:serachType/:printLanguage",
      name: "PrintLabDetail",
      icon: "pe-7s-graph",
      component: PrintLabDetail,
    },
    {
      path: "/print-labs/:appointmentId/:patientId/:printingLanguage",
      name: "Print Lab Detail",
      icon: "pe-7s-graph",
      component: PrintLabDetail,
    },
    {
      path: "/investigation/:appointmentId/:patientId/:printingLanguage",
      name: "investigation",
      icon: "pe-7s-graph",
      component: Investigation,
    },
    {
      path: "/patient-details",
      name: "Patient Details",
      icon: "pe-7s-graph",
      mini: "PD",
      component: PatientDetails,
    },
    {
      path: "/prescription-snapshot",
      name: "Patient Details",
      icon: "pe-7s-graph",
      mini: "PD",
      component: PrescriptionSnapShot,
    },
    {
      path: "/history/:appointmentId/:patientId",
      name: "History",
      icon: "pe-7s-graph",
      component: History,
    },
    {
      path: "/homair-calculator",
      name: "HomeIR Calculator",
      icon: "pe-7s-graph",
      component: HomaIRCalculator,
    },
    {
      path: "/insuline-sensitivity-index-3-points-calculator",
      name: "InsulineSensitivityIndex3Points Calculator",
      icon: "pe-7s-graph",
      component: InsulineSensitivityIndex3PointsCalculator,
    },
    {
      path: "/insuline-sensitivity-index-5-points-calculator",
      name: "InsulineSensitivityIndex5Points Calculator",
      icon: "pe-7s-graph",
      component: InsulineSensitivityIndex5PointsCalculator,
    },
    {
      path: "/settings",
      name: "settings",
      icon: "pe-7s-graph",
      component: Settings,
    },
    {
      path: "/bone-age-calculator",
      name: "BoneAge Calculator",
      icon: "pe-7s-graph",
      component: BoneAgeCalculator,
    },
    {
      path: "/gly-frac-diabetes-calculator",
      name: "GlyFracDiabetes Calculator",
      icon: "pe-7s-graph",
      component: GlyFracDiabetesCalculator,
    },
    {
      path: "/diet-history",
      name: "Diet History",
      icon: "pe-7s-graph",
      component: DietHistory,
    },
    { path: "/login", name: "Login Page", mini: "LP", component: LoginPage },
    {
      path: "/view-box",
      name: "ViewBox",
      icon: "pe-7s-graph",
      component: MyClinicViewBox,
    },
    { path: "/", name: "Dashboard", mini: "DB", component: Dashboard },
  ];
} else {
  pagesRoutes = [

    {
      path: "/support-ticket/:id",
      name: "Support Cicket",
      icon: "pe-7s-graph",
      component: TicketView,
    },
    {
      path: "/support-ticket",
      name: "Support Cicket",
      icon: "pe-7s-graph",
      component: Tickets,
    },
    {
      path: "/appointment",
      name: "Appointment",
      icon: "pe-7s-graph",
      component: Appointment,
    },
    {
      path: "/color-notations",
      name: "Color Notations",
      icon: "pe-7s-graph",
      component: ColorNotations,
    },
    {
      path: "/fib-4-calculator",
      name: "FIB4 Calculator",
      icon: "pe-7s-graph",
      component: Fibrosis4,
    },
    {
      path: "/bmi-calculator",
      name: "BMI Calculator",
      icon: "pe-7s-graph",
      component: BmiCalculator,
    },
    {
      path: "/eGFR-calculator",
      name: "eGFR Calculator",
      icon: "pe-7s-graph",
      component: EGFRCalculator,
    },
    {
      path: "/homair-calculator",
      name: "HomeIR Calculator",
      icon: "pe-7s-graph",
      component: HomaIRCalculator,
    },
    {
      path: "/insuline-sensitivity-index-3-points-calculator",
      name: "InsulineSensitivityIndex3Points Calculator",
      icon: "pe-7s-graph",
      component: InsulineSensitivityIndex3PointsCalculator,
    },
    {
      path: "/insuline-sensitivity-index-5-points-calculator",
      name: "InsulineSensitivityIndex5Points Calculator",
      icon: "pe-7s-graph",
      component: InsulineSensitivityIndex5PointsCalculator,
    },
    {
      path: "/bone-age-calculator",
      name: "BoneAge Calculator",
      icon: "pe-7s-graph",
      component: BoneAgeCalculator,
    },
    {
      path: "/gly-frac-diabetes-calculator",
      name: "GlyFracDiabetes Calculator",
      icon: "pe-7s-graph",
      component: GlyFracDiabetesCalculator,
    },
    {
      path: "/clinic-calenders",
      name: "Clinic Calender",
      icon: "pe-7s-graph",
      component: ClinicCalender,
    },
    {
      path: "/preference-to-set",
      name: "Preference To Set",
      icon: "pe-7s-graph",
      component: PreferenceToSet,
    },
    {
      path: "/billing-cycle",
      name: "Preference To Set",
      icon: "pe-7s-graph",
      component: BillingCycle,
    },
    {
      path: "/change-password",
      name: "Change Password",
      icon: "pe-7s-graph",
      component: ChangePassword,
    },
    {
      path: "/manage-request",
      name: "Manage Request",
      icon: "pe-7s-graph",
      component: ManageRequest,
    },
    {
      path: "/educator-message",
      name: "Educator Message",
      icon: "pe-7s-graph",
      component: ManageRequest,
    },
    {
      path: "/user-list",
      name: "User List",
      icon: "pe-7s-graph",
      component: UserList,
    },
    { path: "/login", name: "Login Page", mini: "LP", component: LoginPage },
    {
      path: "/forgot-password",
      name: "Forgot Paasword Page",
      mini: "FP",
      component: ForgotPasswordPage,
    },
    {
      path: "/reset-password/:verificationToken/:email",
      name: "Reset Paasword Page",
      mini: "RP",
      component: ResetPassword,
    },
    { path: "/dashboard", name: "Dashboard", mini: "DB", component: Dashboard },
    {
      path: "/complaints/:appointmentId/:patientId/:printingLanguage",
      name: "Complaints",
      icon: "pe-7s-graph",
      component: Complaints,
    },
    {
      path: "/diagnosis/:appointmentId/:patientId/:printingLanguage",
      name: "Diagnosis",
      icon: "pe-7s-graph",
      component: Diagnosis,
    },
    {
      path: "/prescription/:appointmentId/:patientId/:printingLanguage",
      name: "Prescription",
      icon: "pe-7s-graph",
      component: Prescription,
    },
    {
      path: "/printPrescription/:appointmentId/:patientId/:serachType",
      name: "Print Prescription",
      icon: "pe-7s-graph",
      component: PrintPrescription,
    },
    {
      path: "/print/:appointmentId/:patientId/:serachType/:printLanguage",
      name: "Print",
      icon: "pe-7s-graph",
      component: Print,
    },
    {
      path: "/vital-sign/:appointmentId/:patientId/:printingLanguage",
      name: "Vital Sign",
      icon: "pe-7s-graph",
      component: VitalSign,
    },
    {
      path: "/investigation/:appointmentId/:patientId/:printingLanguage",
      name: "investigation",
      icon: "pe-7s-graph",
      component: Investigation,
    },
    {
      path: "/print-labs/:appointmentId/:patientId/:printingLanguage",
      name: "Print Lab Detail",
      icon: "pe-7s-graph",
      component: PrintLabDetail,
    },
    {
      path: "/history/:appointmentId/:patientId",
      name: "History",
      icon: "pe-7s-graph",
      component: History,
    },
    {
      path: "/patient-search",
      name: "PatientSearch",
      mini: "PS",
      component: PatientSearch,
    },
    {
      path: "/insulin-patient",
      name: "PatientSearch",
      mini: "PS",
      component: InsulinPatient,
    },
    { path: "/report", name: "Report", icon: "pe-7s-graph", component: Report },
    {
      path: "/notification",
      name: "Notification",
      icon: "pe-7s-graph",
      component: Notification,
    },
    {
      path: "/diet-history",
      name: "Diet History",
      icon: "pe-7s-graph",
      component: DietHistory,
    },
    {
      path: "/clinic-document",
      name: "Clinic Document",
      icon: "pe-7s-graph",
      mini: "CD",
      component: ClinicDocument,
    },
    {
      path: "/settings",
      name: "settings",
      icon: "pe-7s-graph",
      component: Settings,
    },
    {
      path: "/patient-details",
      name: "Patient Details",
      icon: "pe-7s-graph",
      mini: "PD",
      component: PatientDetails,
    },
    {
      path: "/prescription-snapshot",
      name: "Patient Details",
      icon: "pe-7s-graph",
      mini: "PD",
      component: PrescriptionSnapShot,
    },
    {
      path: "/clinic-performance-report",
      name: "clinic Performance Report",
      icon: "pe-7s-graph",
      mini: "CPR",
      component: ClinicPerfomanceReport,
    },
    {
      path: "/view-box",
      name: "ViewBox",
      icon: "pe-7s-graph",
      component: MyClinicViewBox,
    },

    { path: "/", name: "Dashboard", mini: "DB", component: Dashboard },
  ];
}

export default pagesRoutes;

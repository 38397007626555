/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react'
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from 'Front/api/emr';
import {
    Row, Col,
    Modal, Form, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';


const AddDiagnosis = ({ setModalStatusAddDiagnosis, modalStatusAddDiagnosis, addDiagnosis, setAddDiagnosis, props, diagnosisData, diagnosisDuration }) => {

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isDiagnosisExist, setIsDiagnosisExist] = useState(false)
    const [stringLimit, setStringLimit] = useState(false)
    const [AlreadyExistMessage, SetAlreadyExistMessage] = useState("")

    const addDiagnosisFun = () => {
        setModalStatusAddDiagnosis(true);
        setFormData({})
        setStringLimit(false)
    }

    const inputHandler = (e) => {
        let saveFormData = formData;
        if (e.target.name === "Category")
            saveFormData["diagonsisCategoryId"] = e.target.value
        else {
            if (e.target.value.length > 100)
                setStringLimit(true)
            else {
                setStringLimit(false)
                let str = e.target.value.replace(/\s+/g, " ").substring(0, 100);
                saveFormData[e.target.name] = str.trimStart()
                setFormData({ ...formData, ...saveFormData });
            }
        }
        setIsDiagnosisExist(false)
        validate();
    }

    const validate = () => {

        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.diagonsisSubCategoryName && post.diagonsisSubCategoryName != '') {
            errArr.diagonsisSubCategoryName = '';
        } else { errArr.diagonsisSubCategoryName = 'This field is required.'; errflag = false; }

        if (post.type && post.type != '') {
            errArr.type = '';
        } else { errArr.type = 'This field is required.'; errflag = false; }

        setErrors({ ...errArr });
        return errflag;

    }


    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            submitHandler(e)
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        let subCatId = ""
        let subCatName = ""
        let saveFormData = formData;
        saveFormData["diagonsisSubCategoryName"] = formData.diagonsisSubCategoryName?.trim();
        setFormData({ ...formData, ...saveFormData });
        if (validate()) {
            let resp = ""

            if (addDiagnosis === true) {
                resp = await API.addClinicDiagonsis(formData);
            } else {
                let req = {
                    "patientId": diagnosisData.patientId,
                    "symptomsSubCategoryId": diagnosisData.symptomsSubCategoryId,
                    "diagonsisSubCategoryName": formData.diagonsisSubCategoryName,
                    "diagonsisSubCategoryId": diagnosisData.diagonsisSubCategoryId,
                    "appointmentId": diagnosisData.appointmentId,
                    "type": formData.type
                }
                resp = await API.editDiagonsis(req);
            }

            if (resp.data.status === "Success" && resp.data.statusCode === 200) {
                if (addDiagnosis === true) {
                    if (resp.data.flag === "existing") {
                        SetAlreadyExistMessage(`${resp.data.msg} ${resp.data.diagonsisCategoryName}.`)
                        setIsDiagnosisExist(true)
                        return;

                    } else {
                        subCatId = resp.data.clinicDiagonsisData._id
                        subCatName = resp.data.clinicDiagonsisData.diagonsisSubCategoryName
                    }
                }
                else {
                    if (resp.data.flag === "add") {
                        subCatId = resp.data.subCategoryData._id
                        subCatName = resp.data.subCategoryData.diagonsisSubCategoryName
                    } else if (resp.data.flag === "edit") {

                        subCatId = resp.data.clinicDiagonsisId
                        subCatName = formData.diagonsisSubCategoryName
                    } else if (resp.data.flag === "existing") {
                        SetAlreadyExistMessage(`${resp.data.msg} ${resp.data.diagonsisCategoryName}.`)
                        setIsDiagnosisExist(true)
                        return;
                    }
                }

                closeModal();
                props(diagnosisData.diagonsisSubCategoryId, subCatId, subCatName, formData.type, addDiagnosis === true ? "addDiagnosis" : resp.data.flag, diagnosisDuration)
            }

        }
    }

    const closeModal = () => {
        setErrors({})
        setIsDiagnosisExist(false)
        setModalStatusAddDiagnosis(false)
        setAddDiagnosis(true)
        setStringLimit(false)
    }

    useEffect(() => {
        if (!addDiagnosis) {
            setFormData(diagnosisData)
        }
    })

    return (
        < >
            <Button type="button" className="btn-link" onClick={e => addDiagnosisFun()}>Add New Diagnosis</Button>
            <Modal show={modalStatusAddDiagnosis} onHide={closeModal} dialogClassName="modal-md modal-dialog-centered">
                <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">{addDiagnosis === true ? "Add Diagnosis" : "Edit Diagnosis"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="body-parts">
                    <Row>
                        <Col md={12}>
                            <Form horizontal noValidate onKeyDown={e => onKeyDownHandler(e)}>
                                {isDiagnosisExist &&
                                    <div style={{ 'color': 'red' }}><p>{AlreadyExistMessage}</p></div>
                                }
                                <Col sm={12} md={12}>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={12} md={4}>
                                            Diagnosis<span className="star">*</span>
                                        </Col>
                                        <Col sm={12} md={8}>
                                            <FormControl type="text" name="diagonsisSubCategoryName" autocomplete="off" value={formData.diagonsisSubCategoryName ? formData.diagonsisSubCategoryName : ''} onChange={e => { inputHandler(e); }} placeholder="Name" />
                                            <span className="errorMsg">
                                                {errors.diagonsisSubCategoryName ? errors.diagonsisSubCategoryName : ''}</span>
                                            {stringLimit &&
                                                <div style={{ 'color': 'red' }}><p>Only 100 Characters are allowed.</p></div>
                                            }
                                        </Col>
                                    </FormGroup>


                                </Col>
                                <Col sm={12} md={12}>
                                    <FormGroup>
                                        <Col componentClass={ControlLabel} sm={12} md={4}>
                                            Type<span className="star">*</span>
                                        </Col>
                                        <Col sm={12} md={8}>
                                            <select className="form-control" disabled={addDiagnosis === true ? false : true} name="type" onChange={e => { inputHandler(e); }} value={formData.type} >
                                                <option value="">Select Type</option>
                                                <option value="longTerm">Long Term</option>
                                                <option value="intercurrent">Intercurrent</option>
                                            </select>
                                            <span className="errorMsg">
                                                {errors.type ? errors.type : ''}</span>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={e => submitHandler(e)} className="btn btn-fill btn-primary">{addDiagnosis === true ? "Add To My List" : "Save"}</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddDiagnosis
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useEffect, useReducer } from "react";
import {
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import {
  Row,
  Col,
  Form,
  FormControl,
  FormGroup,
  ControlLabel,
  InputGroup,
  Grid,
  Modal,
} from "react-bootstrap";
import * as API from "Front/api/emr";
import { NavLink } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import Calendar from "../../../../components/Calendar.js";
import InstructionList from "./InstructionList.jsx";
import AddDrug from "./AddDrug.jsx";
import AddDoseTime from "./AddDoseTime.jsx";
import axios from "axios";
import Loading from "react-loading";
import Select, { isSearchable, isClearable } from "react-select";
import PrintPrescription from "./PrintPrescription.jsx";
import AddRecommendationOnPrescription from "./AddRecommendatioOnPresciptioon.jsx";
import VitalSign from "../VitalSign/VitalSign.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import {
  selectDrugFrequencyOptions,
  selectDrugFrequencyInsulinOptions,
  selectDrugDurationDayOptions,
  selectDrugDurationTimePeriodOptions,
} from "../../../../variables/Variables";
import $ from "jquery";
import { languageListAction } from "Front/actions/language.js";
import { Autosave, useAutosave } from "react-autosave";
// import { useDispatch } from "react-redux";
import Breadcrum from "../../../../components/common/Breadcrum.js";
import GeneralUsrImg from "../../../../assets/img/default-avatar.png";
import PendingIc from "../../../../assets/img/icons/pending-action.svg";
import EditIcon from "../../../../assets/img/icons/edit-ic.svg";
import DeleteIcon from "../../../../assets/img/icons/table-delete-ic.svg";
import InlargeIc from "../../../../assets/img/icons/inlarge-ic.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../../../../actions/actionTypes";
import { nextVisit } from "Front/api/home";
import { taskScreenAction } from "Front/actions/taskScreen";
import * as PF from "Front/views/Home/PublicFunction.jsx";

export const ACTIONS = {
  ADD_DRUG: "add-drug",
  ADD_INSTRUCTION: "add instruction",
  ADD_DRUG_INSTRUCTION: "add drug instruction",
  EDIT_DRUG_INSTRUCTION: "edit drug instruction",
  CLOSE_INSTRUCTION: "close instruction",
  ADD_DRUG_DOSETIME: "add_drug_dosetime",
  UPDATE_CUSTOMIZE_FREQUENCY: "update_customize_frequency",
  DELETE_DOSE_TIME_SLOT: "delete_dose_time_slot",
  DELETE_DRUG: "delete_drug",
  DRUG_DURATION: "drug_duration",
  RESET: "reset",
  ADD_DRUGS_ALL: "add_drugs_all",
  ADD_RECOMMENDATION: "add_recommendation",
  ADD_NEW_DRUG_INSTRUCTION: "add_new_drug_instruction",
};

const Prescription = () => {
  const emrData = useSelector((store) => store.emr);
  const taskScreenData = useSelector((store) => store.taskScreen);
  const [generalInformation, setGeneralInformation] = useState({
    patientName: emrData?.vitalSignsData
      ? emrData.vitalSignsData.patientName
      : "",
    age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
    height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
    weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
    bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
    bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
    gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
    pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
    temperature: emrData?.vitalSignsData
      ? emrData.vitalSignsData.temperature
      : "",
  });
  const [duration, setDuration] = useState(
    emrData ? emrData.vitalSignsData.diabetesDuration : "Year Month Day"
  );
  const [drugInstruction, setDrugInstruction] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStatusDoseTime, setModalStatusDoseTime] = useState(false);
  const [modalStatusAddDrug, setModalStatusAddDrug] = useState(false);
  const [serachType, setSearchType] = useState("brand");
  const [isSpecialCharacter, setIsSpecialCharacter] = useState(false);
  const [seletedDrugId, setSeletedDrugId] = useState("");
  const [instrucrtionMode, setInstructionMode] = useState("");
  const [drugAlreadyAdded, setDrugAlreadyAdded] = useState(false);
  const [insulineAlreadyAdded, setInsulineAlreadyAdded] = useState(false);
  const [drugCategoryList, setDrugCategoryList] = useState([]);
  const [drugCompanyList, setDrugCompanyList] = useState([]);
  const [doseFrequencyList, setDoseFrequencyList] = useState([]);
  const [doseTimeSlotList, setDoseTimeSlotList] = useState([]);
  const [doseTimeList, setDoseTimeList] = useState([]);
  const [doseTimeSlotMedian, setDoseTimeSlotMedian] = useState(0);
  const [selectedDoseTimeFrequency, setSelectedDosetimeFrequnecy] = useState(
    {}
  );
  const [doseTimeMode, setDoseTimeMose] = useState("");
  const [prescriptionCount, setPrescriptionCount] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [freqValue, setFreqValue] = useState({});
  const [isPageLoad, setIsPageLoad] = useState(true);
  const [isPageLoadPrint, setIsPageLoadPrint] = useState(true);
  const [recommendations, setRecommendations] = useState([]);
  const [isDisplayLoader, setIsDisplayLoader] = useState(true);
  const [modalStatusPrintPrescription, setModalStatusPrintPrescription] =
    useState(false);
  const [isPrintButtonDisable, setIsPrintButtonDisable] = useState(false);
  const [toggleDuration, setToggleDuration] = useState(
    emrData
      ? emrData.vitalSignsData.toggleDuration
        ? emrData.vitalSignsData.toggleDuration
        : false
      : false
  );
  const [searchLength, setSearchLength] = useState(false);
  const [
    modalStatusRecommendationOfPrescription,
    setModalStatusRecommendationOfPrescription,
  ] = useState(false);
  const [printLanguageOptions, setPrintLanguageOptions] = useState([]);
  const [printLanguage, setPrintLanguage] = useState("1");
  const [autoSave, setAuoSave] = useState(true);
  const [compalintData, setComplaintData] = useState([]);
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [isAutoSaveUse, setIsAutoSaveUse] = useState(false);
  const [nextVisitDate, setNextVisitDate] = useState("");
  const [patinetData, setPatientData] = useState({});
  const [btnClicked, setBtnClicked] = useState(false);
  const dispatchViatlSignRedux = useDispatch();
  let printPreviewButtonClicked = false;
  let printButtonDisable = 0;
  let userType = localStorage.getItem("userType");
  let isPrescriptionEditByReceptionist = localStorage.getItem(
    "is_prescription_edit_by_receptionist"
  );
  let isPrescriptionEditByEducator = localStorage.getItem(
    "is_prescription_edit_by_educator"
  );
  // const dispatch1=useDispatch()
  const CancelToken = axios.CancelToken;
  let cancel;
  let history = useHistory();
  const [isPrescriptionUpdationDateSameAsToday, setIsPrescriptionUpdationDateSameAsToday] = useState(false)

  const initialState = {
    drugs: [],
    data: [],
    drugInstruction: [],
    doseTimeArr: [],
    recommendationArr: [],
    patientPrescriptionData: [],
  };

  let { appointmentId, patientId, printingLanguage } = useParams();

  const location = useLocation();

  const dispatchPatientPrescriptionDataRedux = useDispatch();

  const getPatientGeneralInfo = async () => {
    if (
      emrData.vitalSignsData &&
      Object.keys(emrData.vitalSignsData).length === 0
    ) {
      let resp = await API.generalInformation({ patientId: patientId });

      if (resp.data && resp.data.status === "Success") {
        setGeneralInformation({
          patientName: resp.data.data.name,
          age: resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "",
          height: resp.data.data.height,
          weight: resp.data.data.weight,
          bmi: resp.data.data.bmi,
          bp: resp.data.data.bp,
          gender: resp.data.data.gender,
          pulse: resp.data.data.pulse,
          temperature: resp.data.data.temprature,
        });
        let strDuration = "";
        if (resp.data.data.diabitic === "yes") {
          if (
            resp.data.data.diabtiesDuration?.years === 0 &&
            resp.data.data.diabtiesDuration?.months === 0 &&
            resp.data.data.diabtiesDuration?.days === 0
          ) {
            strDuration = "Day Month Year";
            setToggleDuration(false);
          }
          setToggleDuration(true);
          if (resp.data.data.diabtiesDuration?.years > 0) {
            strDuration = resp.data.data.diabtiesDuration?.years + " years ";
          }
          if (resp.data.data.diabtiesDuration?.months > 0) {
            strDuration += resp.data.data.diabtiesDuration?.months + " months ";
          }
          if (resp.data.data.diabtiesDuration?.days > 0) {
            strDuration += resp.data.data.diabtiesDuration?.days + " days ";
          }
          setDuration(strDuration);
        }
        let tempdata = {};
        tempdata.patientName =
          resp.data.data.firstname + " " + resp.data.data.lastName;
        tempdata.patientId = patientId;
        tempdata.age = resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "";
        tempdata.height = resp.data.data.height;
        tempdata.weight = resp.data.data.weight;
        tempdata.bmi = resp.data.data.bmi;
        tempdata.bp = resp.data.data.bp;
        tempdata.gender = resp.data.data.gender;
        tempdata.pulse = resp.data.data.pulse;
        tempdata.temperature = resp.data.data.temprature;
        tempdata.diabetesDuration = strDuration;
        tempdata.toggleDuration =
          resp.data.data.diabitic === "yes" ? true : false;
        dispatchViatlSignRedux({
          type: actionTypes.SET_VITAL_SIGNS_DATA,
          payload: tempdata,
        });
      }
    }
    patientDrugData();
  };

  // $('.rdtDisabled').click(function(event){
  //   event.preventDefault();
  //   event.stopPropagation();
  // })

  const patientDrugData = async () => {
    let resp = await API.patientDrugData({
      patientId: patientId,
      appointmentId: appointmentId,
    });

    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      setIsDisplayLoader(false);
      if (resp.data.patientPrescriptionData.length > 0) {
        let temp = {};
        let tempDrugs = [];
        let tempData = [];
        let tempKey = freqValue;
        resp.data.patientPrescriptionData.map((itm, index) => {

          // if (index === 0) {
          //   let today = new Date();
          //   let todayDate = moment(today).format("DD-MM-YYYY")
          //   let dateToCheck = moment(itm.updatedAt).format("DD-MM-YYYY");
          //   //console.log(todayDate, dateToCheck, todayDate === dateToCheck, "dateRes")
          //   if (todayDate === dateToCheck) {
          //     setIsPrescriptionUpdationDateSameAsToday(true)
          //   }

          // }
          let doseTimeMapingId = [];
          if (appointmentId !== itm.appointmentId) {
            printButtonDisable = 1;
            // $('.print-button').attr('disabled',true);
            // $('.print-button').addClass('donotclick');
          }

          itm.doseTimeSlot.map((obj) => {
            if (obj.doseTimeSlotMapingId !== null)
              doseTimeMapingId.push(obj.doseTimeSlotMapingId);
            obj.customizeFrequency.map((i) => {
              tempKey[i.idx] = {
                label: i.key,
                value: i.key,
                required: i.key ? "" : "1",
              };
              setFreqValue({ ...tempKey });
            });
          });
          let selectedFrequency = {
            _id: itm.doseFrequencyId,
            doseTimeMapingName: itm.doseTimeMapingName,
            doseFrequency: itm.doseFrequency,
            doseTimeMapingId: doseTimeMapingId,
          };
          temp = {
            drug: {
              patientId: itm.patientId,
              appointmentId: appointmentId,
              drugNameWithDose: itm.drugNameWithDose,
              instructionName: itm.instructionName,
              doseTimeData: {
                doseTimeSlot: itm.doseTimeSlot,
                doseTimeMapingName: itm.doseTimeMapingName,
                doseFrequency: itm.doseFrequency,
                doseFrequencyId: itm.doseFrequencyId,
              },
              duration: itm.duration,
              drugType: itm.drugType,
              drugNameWithIngredients: itm.drugNameWithIngredients,
              drugId: itm.drugId,
              noOfDoseSelector: itm.noOfDoseSelector,
              selectedFrequency: selectedFrequency,
              valueTypeOfDoseSelector: itm.valueTypeOfDoseSelector,
              isDeleted: itm.deleted === "0" ? false : true,
              insulinCalculator: itm.insulinCalculator,
              isUpdated: false,
            },
          };
          tempDrugs.push(temp.drug);
        });
        let payloadData = {
          drug: tempDrugs,
        };
        dispatch({ type: ACTIONS.ADD_DRUGS_ALL, payload: payloadData });
      } else {
        printButtonDisable = 1;
      }
    }
  };

  const getDrugCategoryList = async () => {
    let resp = await API.drugCategoryList();
    if (resp.data && resp.data.status === "Success") {
      setDrugCategoryList(resp.data.drugCategoryData.data);
    }
  };

  const getDrugCompanyList = async () => {
    let resp = await API.drugCompanyList();
    if (resp.data && resp.data.status === "Success") {
      setDrugCompanyList(resp.data.drugCompanyData.data);
    }
  };

  const getPrintLanguageList = async () => {
    // let resp= dispatchRedux(languageListAction({ limit:100,offset:0,order:"lanId",direction:"asc"}))
    // console.log("resp",resp)
    let resp = await API.languageList({
      limit: 100,
      offset: 0,
      order: "lanId",
      direction: "asc",
    });
    if (resp.data && resp.data.status === "Success") {
      let list = [];
      list = resp.data.data.data;
      let languageArr = [];
      for (let i in list) {
        languageArr.push({ value: list[i].lanId, label: list[i].name });
      }
      let languages = localStorage.language
        ? localStorage.language.split(",")
        : [];
      let languageList = []
      if (languages.length > 0) {
        languageList = languageArr.filter((key, i) => {
          return languages.includes(key.value.toString());
        });
      }
      setPrintLanguageOptions(languageList);
    }
  };

  function closeLists(elmnt) {
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      x[i].parentNode.removeChild(x[i]);
    }
  }

  const drugSearch = async (e) => {
    setInsulineAlreadyAdded(false);
    setDrugAlreadyAdded(false);
    setIsSpecialCharacter(false);
    let search = e.target.value.trim();
    if (search.length >= 100) {
      setSearchLength(true);
      return;
    } else setSearchLength(false);
    let sessionToken = localStorage.getItem("token");
    let flag = "brand";
    let radioSearchType = document.getElementsByName("search-type");
    for (var radio of radioSearchType) {
      if (radio.checked) {
        flag = radio.value;
      }
    }
    if (search !== "") {
      // if (!/[^a-zA-Z0-9  .]/.test(search)) {
      if (cancel !== undefined) {
        cancel();
      }
      let strUrl = appConstants.paAppURL;
      strUrl += "drugSearch";
      axios
        .post(
          strUrl,
          { search: search, flag: flag },
          {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
            headers: {
              Authorization: sessionToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          autocomplete(
            document.getElementById("txtsearchmedicine"),
            response.data.drugData
          );
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          }
        });
      // } else {
      //   setIsSpecialCharacter(true);
      // }
    } else {
      closeLists();
    }
  };

  const drugValidate = (drugId) => {
    setDrugAlreadyAdded(false);
    let flag = false;
    state.drugs.map((drug) => {
      if (drug.drugId === drugId) {
        flag = true;
        setDrugAlreadyAdded(true);
      }
    });
    return flag;
  };

  const getDrugInstructions = async (drugId) => {
    setInsulineAlreadyAdded(false);
    if (!drugValidate(drugId)) {
      let resp = await API.drugInstruction({
        patientId: patientId,
        drugId: drugId,
        purposeOfFetchingDrugInstruction: "patient",
      });
      if (resp.data && resp.data.status === "Success") {
        setPrescriptionCount(false);
        //setIsPrintButtonDisable(true)
        let radioSearchType = document.getElementsByName("search-type");
        for (var radio of radioSearchType) {
          if (radio.checked) {
            let val =
              radio.id === "radioBrand"
                ? "brand"
                : radio.id === "radioBrandWithIngredients"
                  ? "barndwithingredients"
                  : "ingredientsonly";
            setSearchType(val);
          }
        }
        let tempArr = [];
        let instruction = "";
        resp.data.drugInstructionData.instructionName.map((item, idx) => {
          instruction = {
            idx: idx,
            instructionName: item.instructionName,
            instructionType: item.instructionType,
            instructionId: item.instructionId,
          };
          tempArr.push(instruction);
        });
        let payloadData = {
          drug: {
            isUpdated: true,
            patientId: patientId,
            appointmentId: appointmentId,
            drugNameWithDose: resp.data.drugInstructionData.drugNameWithDose,
            instructionName: tempArr,
            doseTimeData: { doseTimeSlot: [] },
            selectedFrequency: "",
            duration: { no: "", period: "" },
            noOfDoseSelector: resp.data.drugInstructionData.noOfDoseSelector,
            valueTypeOfDoseSelector:
              resp.data.drugInstructionData.valueTypeOfDoseSelector,
            drugType: resp.data.drugInstructionData.drugType,
            drugNameWithIngredients:
              resp.data.drugInstructionData.drugNameWithIngredients,
            drugId: resp.data.drugInstructionData._id,
            isDeleted: false,
            insulinCalculator: resp.data.drugInstructionData.insulinCalculator,
          },
        };
        if (resp.data.drugInstructionData.insulinCalculator) {
          var local_index = state.drugs
            .map(function (el) {
              return el.insulinCalculator;
            })
            .indexOf(true);
          if (local_index == -1) {
            dispatch({ type: ACTIONS.ADD_DRUG, payload: payloadData });
            let ele = document.getElementById("txtsearchmedicine");
            if (ele !== null) ele.value = "";
          } else {
            setInsulineAlreadyAdded(true);
          }
        } else {
          dispatch({ type: ACTIONS.ADD_DRUG, payload: payloadData });
          let ele = document.getElementById("txtsearchmedicine");
          if (ele !== null) ele.value = "";
        }
      }
    }
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.ADD_DRUG: {
        return {
          ...state,
          drugs: [...state.drugs, action.payload.drug],
          data: [...state.data, action.payload.drug],
        };
      }

      case ACTIONS.ADD_INSTRUCTION: {
        state.drugs.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.isUpdated = true;
            drug.instructionName = action.payload.instructions;
          }
        });

        state.data.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.isUpdated = true;
            drug.instructionName = action.payload.instructions;
          }
        });

        return {
          ...state,
          drugs: state.drugs,
          data: state.data,
        };
      }

      case ACTIONS.ADD_DRUGS_ALL: {
        return {
          ...state,
          drugs: [...action.payload.drug],
          data: [...action.payload.drug],
        };
      }

      case ACTIONS.EDIT_DRUG_INSTRUCTION: {
        return {
          ...state,
          drugInstruction: action.payload.instructions,
        };
      }

      case ACTIONS.ADD_DRUG_DOSETIME: {
        let ele = document.getElementById(action?.payload.drugId);
        if (ele !== null)
          if (action?.payload.doseTime.doseTimeSlot.length > 0)
            ele.classList.remove("change-btn-color");

        state.drugs.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.doseTimeData = action.payload.doseTime;
            drug.selectedFrequency = action.payload.selectedFrequency;
            drug.isUpdated = true;
          }
        });

        state.data.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.doseTimeData = action.payload.doseTime;
            drug.selectedFrequency = action.payload.selectedFrequency;
            drug.isUpdated = true;
          }
        });

        return {
          ...state,
          doseTimeArr: action.payload.doseTime.doseTimeSlot,
        };
      }

      case ACTIONS.ADD_DRUG_INSTRUCTION: {
        return {
          ...state,
          drugInstruction: action.payload,
        };
      }

      case ACTIONS.UPDATE_CUSTOMIZE_FREQUENCY: {
        return {
          ...state,
        };
      }

      case ACTIONS.CLOSE_INSTRUCTION: {
        return {
          ...state,
          drugInstruction: action.payload,
        };
      }

      case ACTIONS.DELETE_DOSE_TIME_SLOT: {
        state.drugs.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isUpdated = true;
            drug.doseTimeData.doseTimeSlot.map((item, idx) => {
              if (item.doseTimeSlotId === action.payload.doseTimeSlotId) {
                for (let i = 1; i <= action.payload.count; i++) {
                  let key = "";
                  key =
                    action.payload.drugId +
                    "-" +
                    action.payload.doseTimeSlotId +
                    "-" +
                    i;
                  let temp = freqValue;
                  delete temp[key];
                  setFreqValue({ ...temp });
                }
                drug.doseTimeData.doseTimeSlot.splice(idx, 1);
              }
            });
          }
        });

        state.data.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isUpdated = true;
            drug.doseTimeData.doseTimeSlot.map((item, idx) => {
              if (item.doseTimeSlotId === action.payload.doseTimeSlotId) {
                drug.doseTimeData.doseTimeSlot.splice(idx, 1);
              }
            });
          }
        });

        return {
          ...state,
        };
      }

      case ACTIONS.DRUG_DURATION: {
        state.drugs.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isUpdated = true;
            if (action.payload.key === "no") {
              drug.duration.no =
                action.payload.value === "No" ? "" : action.payload.value;
              if (action.payload.value != "No" && drug.duration.period === "") {
                drug.duration.period = "Day";
                let ele = document.getElementById(
                  action.payload.drugId + "-period"
                );
                if (ele != null) ele.classList.remove("duration-required");
              } else drug.duration.period = drug.duration.period;
            } else if (action.payload.key === "period") {
              drug.duration.period =
                action.payload.value === "D/W/M/Y" ? "" : action.payload.value;
              if (
                action.payload.value != "D/W/M/Y" &&
                drug.duration.no === ""
              ) {
                drug.duration.no = "1";
                let ele = document.getElementById(
                  action.payload.drugId + "-no"
                );
                if (ele != null) ele.classList.remove("duration-required");
              } else drug.duration.no = drug.duration.no;
            }
          }
        });

        state.data.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isUpdated = true;
            if (action.payload.key === "no") {
              drug.duration.no =
                action.payload.value === "No" ? "" : action.payload.value;
              if (action.payload.value != "No" && drug.duration.period === "") {
                drug.duration.period = "Day";
                let ele = document.getElementById(
                  action.payload.drugId + "-period"
                );
                if (ele != null) ele.classList.remove("duration-required");
              } else drug.duration.period = drug.duration.period;
            } else if (action.payload.key === "period") {
              drug.duration.period =
                action.payload.value === "D/W/M/Y" ? "" : action.payload.value;
              if (
                action.payload.value != "D/W/M/Y" &&
                drug.duration.no === ""
              ) {
                drug.duration.no = "1";
                let ele = document.getElementById(
                  action.payload.drugId + "-no"
                );
                if (ele != null) ele.classList.remove("duration-required");
              } else drug.duration.no = drug.duration.no;
            }
          }
        });

        return {
          ...state,
        };
      }

      case ACTIONS.DELETE_DRUG: {
        state.drugs.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isUpdated = true;
            drug.isDeleted = true;
            drug.doseTimeData.doseTimeSlot.map((item, idx) => {
              item.customizeFrequency.map((itm, idx) => {
                for (let i = 1; i <= drug.noOfDoseSelector; i++) {
                  let key = "";
                  key = drug.drugId + "-" + item.doseTimeSlotId + "-" + i;
                  let temp = freqValue;
                  delete temp[key];
                  setFreqValue({ ...temp });
                }
              });
            });
          }
        });
        state.data.map((drug) => {
          if (drug.drugId === action.payload.drugId) {
            drug.isDeleted = true;
            drug.isUpdated = true;
          }
        });

        return {
          ...state,
          drugs: state.drugs.filter(
            (drug) => drug.drugId !== action.payload.drugId
          ),
        };
      }

      case ACTIONS.ADD_RECOMMENDATION: {
        return {
          ...state,
          recommendationArr: action.payload.recommendation,
        };
      }

      case ACTIONS.RESET: {
        return {
          drugs: [],
          data: [],
          drugInstruction: [],
          doseTimeArr: [],
        };
      }

      case ACTIONS.ADD_NEW_DRUG_INSTRUCTION: {
        state.drugs.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.isUpdated = true;
            drug.instructionName = action.payload.instructions;
            drug.drugNameWithDose = action.payload.drugNameWithDose;
            drug.type = action.payload.drugType;
          }
        });

        state.data.map((drug) => {
          if (drug.drugId === action?.payload.drugId) {
            drug.isUpdated = true;
            drug.instructionName = action.payload.instructions;
            drug.drugNameWithDose = action.payload.drugNameWithDose;
            drug.drugType = action.payload.drugType;
          }
        });

        return {
          ...state,
          drugs: state.drugs,
          data: state.data,
        };
      }
    }
  }

  const setNextVisit = async (event) => {
    setNextVisitDate(moment(event).format("DD-MMM-YYYY"), "date");

    let data = {
      id: appointmentId,
      patient_id: patientId,
      next_visit_date: moment(event).format("DD-MMM-YYYY"),
      notificationDay: localStorage.getItem("patientNotificationDay")
        ? Number(localStorage.getItem("patientNotificationDay"))
        : 1,
    };

    if (
      !moment(moment(event).format("DD-MMM-YYYY")).isSame(
        moment(nextVisitDate).format("DD-MMM-YYYY")
      )
    ) {
      let res = await nextVisit(data);
    }
  };

  // const toggleMenuList = () => {
  //   let ele = document.getElementById("divMenuList")

  //   if (ele !== null) {
  //     ele.style.display = ele.style.display === "block" ? "none" : "block"
  //   }

  // }

  const [state, dispatch] = useReducer(reducer, initialState);

  function autocomplete(inp, arr) {
    function closeAllLists(elmnt) {
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }

    var currentFocus;

    var a,
      b,
      i,
      val = inp.value;
    closeAllLists();
    if (!val) {
      return false;
    }
    currentFocus = -1;
    a = document.createElement("DIV");
    a.setAttribute("id", inp.id + "autocomplete-list");
    a.setAttribute("class", "autocomplete-items autocompletedropdownmenu");
    inp.parentNode.appendChild(a);

    for (i = 0; i < arr.length; i++) {
      b = document.createElement("DIV");
      b.setAttribute("class", "");
      b.innerHTML += `${arr[i].drugNameWithDose} (${arr[i].drugType})`;
      b.innerHTML +=
        "<input type='hidden' drugType='" +
        arr[i].drugType +
        "' drugId='" +
        arr[i]._id +
        "' value='" +
        arr[i].drugNameWithDose +
        "'>";
      b.addEventListener("click", function (e) {
        let drugId =
          this.getElementsByTagName("input")[0].getAttribute("drugId");
        getDrugInstructions(
          this.getElementsByTagName("input")[0].getAttribute("drugId")
        );
        closeAllLists();
      });
      a.appendChild(b);
    }
  }

  const inputHandler = (e, field = "") => {
    addNextVisit(e);
  };

  const addNextVisit = (e) => {
    const formData = {
      id: appointmentId,
      patient_id: patientId,
      next_visit_date: e,
      notificationDay: "",
    };

    let resp = API.nextVisit(formData);
  };

  const handleSubmit = async () => {
    if (state.drugs.length > 0) {
      setBtnClicked(true);
      printPreviewButtonClicked = true;
      submitHandler();
    } else {
      setPrescriptionCount(true);
    }
  };

  const submitHandler = async () => {
    let flag = true;
    let flagDuration = true;
    let listdoseRe = document.getElementsByClassName("set-dose");
    for (var i = 0; i < listdoseRe.length; i++) {
      for (var j = 0; j < listdoseRe[i].childNodes.length; j++) {
        if (listdoseRe[i].childNodes[j]?.classList?.contains("required")) {
          printPreviewButtonClicked = false;
          setBtnClicked(false);
          return false;
        }
      }
    }

    state.drugs.map((drug) => {
      if (drug.doseTimeData.doseTimeSlot.length === 0) {
        flag = false;
        let ele = document.getElementById(drug.drugId);
        if (ele !== null) {
          ele.classList.add("change-btn-color");
          printPreviewButtonClicked = false;
          setBtnClicked(false);
          return false;
        }
      }
      if (drug.duration.no != "" && drug.duration.period === "") {
        flagDuration = false;
        printPreviewButtonClicked = false;
        setBtnClicked(false);
        let ele = document.getElementById(drug.drugId + "-period");
        if (ele !== null) ele.classList.add("duration-required");
      } else if (drug.duration.no === "" && drug.duration.period != "") {
        flagDuration = false;
        printPreviewButtonClicked = false;
        setBtnClicked(false);
        let ele = document.getElementById(drug.drugId + "-no");
        if (ele !== null) ele.classList.add("duration-required");
      } else if (drug.duration.no === "" && drug.duration.period === "") {
        let ele = document.getElementById(drug.drugId + "-no");
        if (ele !== null) ele.classList.remove("duration-required");
        ele = document.getElementById(drug.drugId + "-period");
        if (ele !== null) ele.classList.remove("duration-required");
      }
    });
    if (flag && flagDuration) {
      let ele = document.getElementById("btnSavePrescription");
      if (ele !== null) ele.disabled = true;
      let res = await API.addPatientPrescription({
        drugData: state.data,
        isBrandWithIngredients:
          serachType === "barndwithingredients" ? true : false,
      });
      if (res.data.status === "Success" && res.data.statusCode === 200) {
        let tempData = taskScreenData.visitData;
        tempData.flag = "yes";
        tempData.patientStatus = "old";
        dispatchViatlSignRedux({
          type: actionTypes.SET_TASK_SCREEN_DATA,
          payload: tempData,
        });
        //dispatch1({type:actionTypes.SET_EMR_DATA,payload:{patientId:patientId,isEmr:true}})
        if (printPreviewButtonClicked)
          history.push(
            `/print/${appointmentId}/${patientId}/${serachType}/${printLanguage}`
          );
      }
    }
  };

  const submitHandlerAutoSave = async () => {
    if (!printPreviewButtonClicked && state.data.length > 0) {
      // let isPrescriptionUpdated = false
      // state.data.map((ele) => {
      //   if (ele.isUpdated) {
      //     isPrescriptionUpdated = true
      //   }
      // })

      // let allowAutosaveApiCall = false
      // if (!isPrescriptionUpdationDateSameAsToday)
      //   allowAutosaveApiCall = true
      // else if (isPrescriptionUpdationDateSameAsToday && isPrescriptionUpdated) {
      //   allowAutosaveApiCall = true
      // }
      //console.log(isPrescriptionUpdationDateSameAsToday, isPrescriptionUpdated, "ppppppp")
      //if (allowAutosaveApiCall) {
      let res = await API.addPatientPrescription({
        drugData: state.data,
        isBrandWithIngredients:
          serachType === "barndwithingredients" ? true : false,
      });
      if (res.data.status === "Success" && res.data.statusCode === 200) {
        let tempData = taskScreenData.visitData;
        tempData.flag = "yes";
        tempData.patientStatus = "old";
        dispatchViatlSignRedux({
          type: actionTypes.SET_TASK_SCREEN_DATA,
          payload: tempData,
        });
        //}
      }
    }
  };

  const successAlert = (msg) => {
    setAlertMsg(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => {
          setAlertMsg(null);
          let ele = document.getElementById("btnSavePrescription");
          if (ele !== null) ele.disabled = false;
        }}
        confirmBtnBsStyle="info"
      >
        {msg}{" "}
      </SweetAlert>
    );

    setTimeout(function () {
      setAlertMsg(null);
      let ele = document.getElementById("btnSavePrescription");
      if (ele !== null) ele.disabled = false;
    }, 3000);
  };

  const getDoseTimeSlotList = async () => {
    let resp = await API.doseTimeSlotList();
    if (resp.data && resp.data.status === "Success") {
      setDoseTimeSlotList(resp.data.doseTimeSlotList.data);
      setDoseTimeSlotMedian(
        Math.floor(resp.data.doseTimeSlotList.data.length / 2)
      );
    }
  };

  const deleteDrugConfirm = (drugId) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch({
              type: ACTIONS.DELETE_DRUG,
              payload: { drugId: drugId },
            });
            window.scrollTo(0, 0);
            setInsulineAlreadyAdded(false);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getFrequencyList = async () => {
    let resp = await API.doseFrequencyList();
    if (resp.data && resp.data.status === "Success") {
      setDoseFrequencyList(resp.data.doseFrequencyData.data);
    }
  };

  const showDoseTime = async (e, id, mode) => {
    setModalStatusDoseTime(true);
    setDoseTimeMose(mode);
    setSeletedDrugId(id);
    setDoseTimeList([]);
    if (mode === "edit") {
      state.drugs.map((drug) => {
        if (drug.drugId === id) {
          setDoseTimeList([...drug.doseTimeData.doseTimeSlot]);
          setSelectedDosetimeFrequnecy(drug.selectedFrequency);
        }
      });
    }
  };

  const showInstructionList = (e, id, mode) => {
    setDrugInstruction([]);
    if (mode === "edit") {
      state.drugs.map((drug) => {
        if (drug.drugId === id) {
          setDrugInstruction([...drug.instructionName]);
        }
      });
    }
    setInstructionMode(mode);
    setSeletedDrugId(id);
    setModalStatus(true);
  };

  const handleCustomFrequency = (e, drugId, doseTimeSlotId, idx) => {
    let flag = false;
    let key = "";
    key = drugId + "-" + doseTimeSlotId + "-" + idx;
    let temp = freqValue;
    let { value, label, required } = e || {
      value: "",
      label: "",
      required: "1",
    };
    temp[key] = { label, value, required: e.value === "" ? "1" : "" };
    setFreqValue({ ...temp });
    state.drugs.map((drug) => {
      if (drug.drugId === drugId) {
        drug.isUpdated = true;
        drug.doseTimeData.doseTimeSlot.map((item) => {
          if (item.doseTimeSlotId === doseTimeSlotId) {
            item.customizeFrequency.map((data) => {
              if (data.idx === key) flag = true;
            });

            flag === false
              ? item.customizeFrequency.push({ idx: key, key: value })
              : item.customizeFrequency.map((data) => {
                if (data.idx === key) data.key = value;
              });
            item.customizeFrequency.sort(function (a, b) {
              let p = a.idx.substr(a["idx"].length - 1);
              let q = b.idx.substr(b["idx"].length - 1);
              {
                return p > q ? 1 : q > p ? -1 : 0;
              }
            });
          }
        });
      }
    });

    dispatch({ type: ACTIONS.UPDATE_CUSTOMIZE_FREQUENCY });
  };

  const customizeFrequency = (drugId, doseTimeSlotId, idx) => {
    let flag = false;
    let key = "";
    key = drugId + "-" + doseTimeSlotId + "-" + idx;
    state.drugs.map((drug) => {
      if (drug.drugId === drugId) {
        drug.doseTimeData.doseTimeSlot.map((item) => {
          if (item.doseTimeSlotId === doseTimeSlotId) {
            item.customizeFrequency.map((data) => {
              if (data.idx === key) flag = true;
            });
            flag === false
              ? item.customizeFrequency.push({ idx: key, key: 1 })
              : item.customizeFrequency.map((data) => {
                if (data.idx === key) data.key = 1;
              });
          }
        });
      }
    });
  };

  const deleteDoseTimeConfirm = (drugId, doseTimeSlotId, count) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch({
              type: ACTIONS.DELETE_DOSE_TIME_SLOT,
              payload: {
                drugId: drugId,
                doseTimeSlotId: doseTimeSlotId,
                count: count,
              },
            }),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const autoUpdate = (e) => {
    let val =
      e.target.id === "radioBrand"
        ? "brand"
        : e.target.id === "radioBrandWithIngredients"
          ? "barndwithingredients"
          : "ingredientsonly";
    setSearchType(val);
  };

  const getNoOfDoseSelector = (
    val,
    optionDataAarry,
    drugId,
    doseTimeSlotId,
    itm,
    drugType
  ) => {
    let arra = [];
    let options = [{ label: "Select", value: "", required: "1" }];

    optionDataAarry.map((item) => {
      options.push({
        label: item?.value || item,
        value: item?.value || item,
        required: "",
      });
    });
    let tempArrValidation = [];
    let status = [];
    for (let i = 1; i <= val; i++) {
      let key = "";
      let flag = false;
      key = drugId + "-" + doseTimeSlotId + "-" + i;
      if (!tempArrValidation[drugId + "-" + doseTimeSlotId]) {
        tempArrValidation[drugId + "-" + doseTimeSlotId] = [];
      }
      if (!freqValue[key]) {
        let temp = freqValue;
        flag = true;
        temp[key] = {
          label: "",
          value: "",
          required:
            Object.values(tempArrValidation)[0].indexOf(true) >= 0 ? "" : "1",
        };
        setFreqValue({ ...temp });
      }
      tempArrValidation[drugId + "-" + doseTimeSlotId][i] = freqValue[key][
        "value"
      ]
        ? true
        : false;
    }

    for (let i = 1; i <= val; i++) {
      let key = "";
      let flag = false;
      key = drugId + "-" + doseTimeSlotId + "-" + i;

      if (freqValue[key]["value"] === "") {
        if (drugType === "TAB" || drugType === "CAP" || drugType === "SYP")
          customizeFrequency(drugId, doseTimeSlotId, i);
      }

      arra[i] = (
        <Select
          name={`color[${key}]`}
          key={key}
          value={
            (drugType === "TAB" || drugType === "CAP" || drugType === "SYP") &&
              freqValue[key]["value"] === ""
              ? { label: 1, value: 1 }
              : freqValue[key]["value"] !== ""
                ? freqValue[key]
                : ""
          }
          onChange={(e) => {
            handleCustomFrequency(e, drugId, doseTimeSlotId, i);
          }}
          options={options}
          searchable={false}
          clearable={false}
          className={
            Object.values(tempArrValidation)[0].indexOf(true) >= 0 ||
              drugType === "TAB" ||
              drugType === "CAP" ||
              drugType === "SYP"
              ? " col-3 mt-3"
              : " col-3 mt-3 required"
          }
        />
      );
    }
    return arra;
  };

  const handleDuration = (e, key, drugId) => {
    if (key === "no") {
      let ele = document.getElementById(drugId + "-no");
      if (ele != null) ele.classList.remove("duration-required");
    }

    if (key === "period") {
      let ele = document.getElementById(drugId + "-period");
      if (ele != null) ele.classList.remove("duration-required");
    }

    state.drugs.map((itm) => {
      if (itm.drugId === drugId) {
        itm.isUpdated = true;
        if (key === "no") {
          itm.duration.no = e.target.value === "No" ? "" : e.target.value;
          if (e.target.value != "No" && itm.duration.period === "") {
            itm.duration.period = "Day";
            let ele = document.getElementById(drugId + "-period");
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "No" && itm.duration.period != "") {
            itm.duration.period = itm.duration.period;
          } else if (e.target.value === "No") {
            itm.duration.period = "";
          }
        } else if (key === "period") {
          itm.duration.period =
            e.target.value === "D/W/M/Y" ? "" : e.target.value;
          if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
            itm.duration.no = "1";
            let ele = document.getElementById(drugId + "-no");
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
            itm.duration.no = itm.duration.no;
          } else if (e.target.value === "D/W/M/Y") {
            itm.duration.no = "";
          }
        }
      }
    });

    state.data.map((itm) => {
      if (itm.drugId === drugId) {
        itm.isUpdated = true;
        if (key === "no") {
          itm.duration.no = e.target.value === "No" ? "" : e.target.value;
          if (e.target.value != "No" && itm.duration.period === "") {
            itm.duration.period = "Day";
            let ele = document.getElementById(drugId + "-period");
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "No" && itm.duration.period != "") {
            itm.duration.period = itm.duration.period;
          } else if (e.target.value === "No") {
            itm.duration.period = "";
          }
        } else if (key === "period") {
          itm.duration.period =
            e.target.value === "D/W/M/Y" ? "" : e.target.value;
          if (e.target.value != "D/W/M/Y" && itm.duration.no === "") {
            itm.duration.no = "1";
            let ele = document.getElementById(drugId + "-no");
            if (ele != null) ele.classList.remove("duration-required");
          } else if (e.target.value != "D/W/M/Y" && itm.duration.no != "") {
            itm.duration.no = itm.duration.no;
          } else if (e.target.value === "D/W/M/Y") {
            itm.duration.no = "";
          }
        }
      }
    });

    dispatch({
      type: ACTIONS.DRUG_DURATION,
      payload: { drugId: drugId, key: key, value: e.target.value },
    });
  };

  const handlePrintLanguage = (e) => {
    setPrintLanguage(e.target.value);
  };

  const getSelectedFrequency = (optionDataAarry, itm) => {
    let selectedValue = {};
    optionDataAarry.map((arr) => {
      itm.customizeFrequency.map((obj) => {
        if (obj.key === arr?.value || arr)
          selectedValue = { label: obj.key, value: obj.key };
      });
    });

    setFreqValue(selectedValue);
  };

  const getComplaintDiagnosisData = async () => {
    let resp = await API.prescriptionPrint({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (resp.data.status === "Success" && resp.data.statusCode === 200) {
      //   history?.location?.complaintData === undefined ?
      //   setComplaintData(resp.data?.complainData) : setComplaintData(history?.location?.complaintData)
      //  history?.location?.diagnosisData === undefined ?
      //   setDiagnosisData(resp.data?.diagonsisData) : setDiagnosisData(history?.location?.diagnosisData)
      setComplaintData(resp.data?.complainData);
      setDiagnosisData(resp.data?.diagonsisData);
      if (resp.data.patientData.length > 0) {
        setPatientData(resp.data?.patientData[0]);
        setNextVisitDate(
          resp.data?.patientData[0].nextDate
            ? moment(resp.data?.patientData[0].nextDate).format("DD-MMM-YYYY")
            : ""
        );
      }
    }
  };

  useEffect(() => {
    if (isPageLoad) {
      getPatientGeneralInfo();
      getDrugCategoryList();
      getDrugCompanyList();
      getFrequencyList();
      getDoseTimeSlotList();
      getPrintLanguageList();
      getComplaintDiagnosisData();
      setIsPageLoad(false);
      setPrintLanguage(printingLanguage);
      let searchTypeVal =
        localStorage.getItem("is_brand_with_ingredients_disable") == "no"
          ? "barndwithingredients"
          : "brand";
      let eleRadio = document.getElementById("radioBrand");
      if (searchTypeVal === "barndwithingredients")
        eleRadio = document.getElementById("radioBrandWithIngredients");
      if (eleRadio != null) eleRadio.checked = true;
      setSearchType(searchTypeVal);
    }
  });

  useEffect(() => {
    if (!isPageLoad) {
      if (autoSave && !printPreviewButtonClicked) setAuoSave(false);
      else setAuoSave(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!printPreviewButtonClicked) {
      setIsAutoSaveUse(true);
    }
  }, []);
  useAutosave({
    data: autoSave,
    onSave: submitHandlerAutoSave,
    saveOnUnmount: isAutoSaveUse,
  });

  const renderNavTabs = () => {
    let html = ""
    if (userType === "doctor") {
      html = <>
        <li>
          <NavLink
            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Vital Signs</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Lab Tests</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Investigation</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
            className="nav-link"
          >
            <span className="sidebar-mini">Complaint</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
            className="nav-link"
          >
            <span className="sidebar-mini">Diagnosis</span>
          </NavLink>
        </li>
        {/* <li><a>Investigation Advised</a></li>
  <li><a>All Investigations</a></li> */}
        <li className="current">
          <NavLink
            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
          >
            <span className="sidebar-mini">Prescription</span>
          </NavLink>
        </li>
      </>
    }

    if (userType === "receptionist") {
      if (localStorage.getItem("is_prescription_edit_by_receptionist") === "yes") {
        html = <>
          <li>
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
          <li className="current">
            <NavLink
              to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Prescription</span>
            </NavLink>
          </li>
        </>
      } else {
        html = <>
          <li>
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
        </>
      }
    }

    if (userType === "educator") {
      if (localStorage.getItem("is_prescription_edit_by_educator") === "yes") {
        html = <>
          <li>
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
          <li className="current">
            <NavLink
              to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Prescription</span>
            </NavLink>
          </li>
        </>
      } else {
        html = <>
          <li>
            <NavLink
              to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Vital Signs</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Lab Tests</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
            >
              <span className="sidebar-mini">Investigation</span>
            </NavLink>
          </li>
        </>
      }
    }

    return html;

  }

  //console.log(state.data, "stateeeee")

  return (
    <div
      className="main-content emr-wrapper dashboard-2-0"
      style={{ padding: "15px 0px" }}
    >
      <span> {alertMsg}</span>
      <Grid fluid>
        {userType === "doctor" && <Breadcrum />}
        <div>
          <div className="general-information">
            <div className="card_header">
              <div className="card_header_inner">
                <ul className="patient_details">
                  <li>
                    <a className="user-info-img">
                      {/* <span><img src={GeneralUsrImg} alt="" /> </span> */}
                      {generalInformation.patientName}
                    </a>
                  </li>
                  <span className="flex">
                    <li>
                      <a>
                        <span>Age: </span> {generalInformation.age} Y /{" "}
                        {generalInformation.gender === "Male" ? " M" : " F"}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Height{generalInformation.height > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.height > 0
                          ? generalInformation.height
                          : ""}{" "}
                        {generalInformation.height > 0 ? "CM" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.weight > 0
                          ? generalInformation.weight
                          : ""}{" "}
                        {generalInformation.weight > 0 ? "KG" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                        {generalInformation.bmi > 0
                          ? generalInformation.bmi
                          : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.bp !== "/"
                          ? generalInformation.bp
                          : ""}{" "}
                        {generalInformation.bp !== "/" ? " mmHg" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.pulse > 0
                          ? generalInformation.pulse
                          : ""}{" "}
                        {generalInformation.pulse > 0 ? "/min" : ""}
                      </a>
                    </li>
                    <li>
                      <a>
                        <span>
                          Temperature
                          {generalInformation.temperature > 0 ? ":" : ""}{" "}
                        </span>{" "}
                        {generalInformation.temperature > 0
                          ? generalInformation.temperature
                          : ""}{" "}
                        {generalInformation.temperature > 0 ? "F" : ""}{" "}
                      </a>
                    </li>
                    {toggleDuration && (
                      <li>
                        <a>
                          <span>Diabetes Duration: </span>{" "}
                          {emrData
                            ? emrData?.diabetesDuration
                              ? emrData?.diabetesDuration
                              : duration
                            : duration}{" "}
                        </a>
                      </li>
                    )}
                  </span>
                </ul>
                {userType === "doctor" && (
                  <div className="patient_history_btn">
                    <NavLink
                      to={`/patient-details`}
                      style={{ marginRight: "10px" }}
                      className="btn btn-fill btn-light btn-sm"
                    >
                      Go Back
                    </NavLink>

                    <NavLink
                      to={`/history/${appointmentId}/${patientId}`}
                      className="btn btn-fill btn-light pull-right btn-sm"
                    >
                      All Visits
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
              <div className='pending-ic'>
                <img src={PendingIc} alt='' />
              </div>
              <div className="pending-title">
                <h4>Pending Action <strong>3</strong></h4>
              </div>
            </div> */}
          </div>
          <div className="card-content-inner prescription">
            <div className="emr-menu-list">
              <ul>
                {renderNavTabs()}
              </ul>
            </div>
            <div className="emr-tab-content">
              <Row>
                <Col md={8} lg={9}>
                  <div className="center-content">
                    {drugAlreadyAdded && (
                      <div style={{ color: "red" }}>
                        <p>This drug already added.</p>
                      </div>
                    )}
                    {insulineAlreadyAdded && (
                      <div style={{ color: "red" }}>
                        <p>Already added one insulin.</p>
                      </div>
                    )}
                    <div className="center-content-table ">
                      <table class="table table-bordered prescription-table-style">
                        <div
                          className="table-loader"
                          style={
                            isDisplayLoader
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <Loading
                            type="bars"
                            color="#000000"
                            style={{ margin: "0px auto", width: "40px" }}
                          />
                        </div>
                        <thead>
                          <tr>
                            <th scope="col" style={{ minWidth: "30px" }}>
                              #
                            </th>
                            <th scope="col" style={{ minWidth: "60px" }}>
                              Drug type
                            </th>
                            <th scope="col" style={{ minWidth: "140px" }}>
                              Drug name with dose
                            </th>
                            <th scope="col" style={{ minWidth: "220px" }}>
                              Dose time
                            </th>
                            <th scope="col" style={{ minWidth: "120px" }}>
                              Duration
                            </th>
                            <th scope="col" style={{ minWidth: "250px" }}>
                              Instruction
                            </th>
                            <th scope="col" width="50px">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.drugs.map((item, idx) => (
                            <tr key={idx}>
                              <td>{++idx}</td>
                              <td>{item.drugType}</td>
                              <td>
                                {serachType === "brand"
                                  ? item.drugNameWithDose
                                  : serachType === "barndwithingredients"
                                    ? `${item.drugNameWithDose}${item?.drugNameWithIngredients
                                      ? `(${item?.drugNameWithIngredients})`
                                      : ""
                                    }`
                                    : `${item.drugNameWithDose}${item?.drugNameWithIngredients
                                      ? `(${item?.drugNameWithIngredients})`
                                      : ""
                                    }`}
                              </td>
                              <td style={{ width: "400px" }}>
                                <div className="dose-time-wrapperr">
                                  <div
                                    style={{
                                      maxwidth: "300 px",
                                      margin: "0 auto",
                                    }}
                                    id={item.drugId}
                                  >
                                    {item.doseTimeData.doseTimeSlot.length ===
                                      0 ? (
                                      <button
                                        className="addDoseTime"
                                        onClick={(e) =>
                                          showDoseTime(e, item.drugId, "add")
                                        }
                                      >
                                        Add dose time
                                      </button>
                                    ) : (
                                      <>
                                        {item.doseTimeData.doseTimeSlot.map(
                                          (itm, id) => {
                                            return (
                                              <>
                                                <div
                                                  className="dose-time-inner"
                                                  key={id}
                                                >
                                                  <span
                                                    className="dose-time-span"
                                                    style={{
                                                      lineheight:
                                                        "22px important!",
                                                    }}
                                                  >
                                                    {itm.doseTimeSlotName}
                                                  </span>
                                                  <span className="set-dose">
                                                    {item.drugType === "SYP" ? (
                                                      <span
                                                        style={{
                                                          marginRight: "5px",
                                                          display:
                                                            "inline-block",
                                                        }}
                                                      >
                                                        TSF
                                                      </span>
                                                    ) : (
                                                      " "
                                                    )}
                                                    {getNoOfDoseSelector(
                                                      item.noOfDoseSelector,
                                                      item.valueTypeOfDoseSelector.concat(
                                                        item.drugType ===
                                                          "INSULIN" ||
                                                          item.drugType ===
                                                          "INJ"
                                                          ? selectDrugFrequencyInsulinOptions
                                                          : selectDrugFrequencyOptions
                                                      ),
                                                      item.drugId,
                                                      itm.doseTimeSlotId,
                                                      itm,
                                                      item.drugType
                                                    )}
                                                    <span
                                                      onClick={() =>
                                                        deleteDoseTimeConfirm(
                                                          item.drugId,
                                                          itm.doseTimeSlotId,
                                                          item.noOfDoseSelector
                                                        )
                                                      }
                                                      className="dose-time-delete-btn"
                                                    >
                                                      ✕
                                                    </span>
                                                  </span>
                                                </div>
                                              </>
                                            );
                                          }
                                        )}

                                        <div className="dose-time-action">
                                          <span
                                            className="dose-time-edit-btn"
                                            onClick={(e) =>
                                              showDoseTime(
                                                e,
                                                item.drugId,
                                                "edit"
                                              )
                                            }
                                          >
                                            Edit dose time
                                          </span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="durationSelects">
                                  <select
                                    className="daysSelect"
                                    id={item.drugId + "-no"}
                                    onChange={(e) => {
                                      handleDuration(e, "no", item.drugId);
                                    }}
                                    value={item.duration.no}
                                  >
                                    <option value="No">No</option>
                                    {selectDrugDurationDayOptions.map(function (
                                      option
                                    ) {
                                      return (
                                        <option value={option.value}>
                                          {option.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <select
                                    className="weekSelect"
                                    id={item.drugId + "-period"}
                                    onChange={(e) => {
                                      handleDuration(e, "period", item.drugId);
                                    }}
                                    value={item.duration.period}
                                  >
                                    <option value="D/W/M/Y">D/W/M/Y</option>
                                    {selectDrugDurationTimePeriodOptions.map(
                                      function (option) {
                                        return (
                                          <option value={option.value}>
                                            {option.label}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              </td>
                              <td>
                                {item.instructionName.length === 0 ? (
                                  <a href="#">
                                    <div
                                      onClick={(e) =>
                                        showInstructionList(
                                          e,
                                          item.drugId,
                                          "add"
                                        )
                                      }
                                      className="btn-instructions"
                                    >
                                      If Any Instructions, Please Select
                                    </div>
                                  </a>
                                ) : (
                                  <div className="instruction-flex">
                                    <a
                                      href="#"
                                      style={{
                                        float: "right",
                                        marginRight: "2px",
                                      }}
                                    >
                                      <img
                                        src={EditIcon}
                                        alt="edit"
                                        onClick={(e) =>
                                          showInstructionList(
                                            e,
                                            item.drugId,
                                            "edit"
                                          )
                                        }
                                      />
                                    </a>
                                    <div className="instruction-flex-inner">
                                      {item.instructionName.map((itm, id) => {
                                        return (
                                          <>
                                            {itm.instructionName && (
                                              <span>
                                                {itm.instructionName.substring(
                                                  0,
                                                  100
                                                )}
                                              </span>
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </td>

                              <td>
                                <span className="actn-btn">
                                  <a href="#" style={{ color: "red" }}>
                                    <img
                                      src={DeleteIcon}
                                      alt="Delete"
                                      onClick={() =>
                                        deleteDrugConfirm(item.drugId)
                                      }
                                    />
                                  </a>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="prescription-action">
                      <AddDoseTime
                        setModalStatusDoseTime={setModalStatusDoseTime}
                        modalStatusDoseTime={modalStatusDoseTime}
                        dispatch={dispatch}
                        drugId={seletedDrugId}
                        patientId={patientId}
                        drugInstruction={drugInstruction}
                        doseTimeSlotList={doseTimeSlotList}
                        doseFrequencyList={doseFrequencyList}
                        doseTimeSlotMedian={doseTimeSlotMedian}
                        doseTimeList={doseTimeList}
                        selectedDoseTimeFrequency={selectedDoseTimeFrequency}
                        doseTimeMode={doseTimeMode}
                        drugs={state.drugs}
                        freqValue={freqValue}
                        setFreqValue={setFreqValue}
                      />

                      <InstructionList
                        setModal={setModalStatus}
                        ModalStatus={modalStatus}
                        dispatch={dispatch}
                        drugId={seletedDrugId}
                        patientId={patientId}
                        drugInstruction={drugInstruction}
                        instrucrtionMode={instrucrtionMode}
                        parentComponent={"Prescription"}
                      />

                      <Button
                        className="btn btn-fill btn-primary print-button"
                        disabled={btnClicked}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        <span>
                          Print Preview{" "}
                          {btnClicked && <i class="fa fa-spinner fa-spin"></i>}
                        </span>
                      </Button>

                      {prescriptionCount && (
                        <div style={{ textAlign: "center" }}>
                          <p style={{ color: "red" }}>
                            Add at least one medicine for preview
                          </p>
                        </div>
                      )}
                    </div>

                    {compalintData?.length > 0 && (
                      <div
                        className="card_header"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="card_header_inner">
                          <ul className="patient_details">
                            <li style={{ borderRight: "none" }}>
                              <span>
                                <b>Complaints :</b>
                              </span>
                            </li>
                            <span className="flex">
                              {
                                // compalintData.map((complaint, idx) => {
                                //   return (<li key={idx}>{history?.location?.complaintData === undefined ?
                                //     complaint.symptomsSubCategoryName :
                                //     complaint.complaint
                                //   }</li>)
                                // })
                                compalintData.map((complaint, idx) => {
                                  return (
                                    <li key={idx}>
                                      {complaint.symptomsSubCategoryName}
                                    </li>
                                  );
                                })
                              }
                            </span>
                          </ul>
                        </div>
                      </div>
                    )}

                    {diagnosisData?.length > 0 && (
                      <div
                        className="card_header"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="card_header_inner">
                          <ul className="patient_details">
                            <li style={{ borderRight: "none" }}>
                              <span>
                                <b>Diagnosis :</b>
                              </span>
                            </li>
                            <span className="flex">
                              {
                                // diagnosisData.map((diagnosis, idx) => {
                                //   return (<li key={idx}>{history?.location?.diagnosisData === undefined ?
                                //     diagnosis.diagonsisSubCategoryName
                                //     : diagnosis.diagonsisSubCategoryName
                                //   }</li>)
                                // })

                                diagnosisData.map((diagnosis, idx) => {
                                  return (
                                    <li key={idx}>
                                      {diagnosis.diagonsisSubCategoryName}
                                    </li>
                                  );
                                })
                              }
                            </span>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={4} lg={3}>
                  <div className="sytems-wrapper-header">
                    <h5>Medicine Selection</h5>
                    <div className="systems-action">
                      <AddDrug
                        SetModalAddDrug={setModalStatusAddDrug}
                        ModalStatusAddDrug={modalStatusAddDrug}
                        patientId={patientId}
                        drugCategoryList={drugCategoryList}
                        drugCompanyList={drugCompanyList}
                        dispatch={dispatch}
                        drugInstruction={state.drugInstruction}
                        appointmentId={appointmentId}
                        drugs={state.drugs}
                        setDrugAlreadyAdded={setDrugAlreadyAdded}
                      />
                    </div>
                  </div>
                  <div className="prescription-selecton">
                    <form>
                      <div class="form-group autoComplete search-sec">
                        <label for="search-medicine">Search medicine</label>
                        {isSpecialCharacter && (
                          <div style={{ color: "red", fontSize: "13px" }}>
                            Special Characters are not allowed.
                          </div>
                        )}
                        {searchLength && (
                          <div style={{ color: "red", fontSize: "13px" }}>
                            <p>Maximum 100 Characters are allowed.</p>
                          </div>
                        )}
                        <FormGroup>
                          <FormControl
                            type="text"
                            maxlength="100"
                            autocomplete="off"
                            onChange={(e) => {
                              drugSearch(e);
                            }}
                            id="txtsearchmedicine"
                            placeholder="Search Medicine"
                            className="form-control "
                          />
                        </FormGroup>
                      </div>

                      <div className="additional-details">
                        {/* <h5>Additional Details</h5> */}
                        <div className="additional-details-inner">
                          <fieldset class="form-group" id="search-type">
                            <h6>Drug details by</h6>
                            <div className="radio-wrapper">
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  disabled={
                                    localStorage.getItem(
                                      "is_brand_with_ingredients_disable"
                                    ) == "no"
                                      ? true
                                      : false
                                  }
                                  name="search-type"
                                  id="radioBrand"
                                  onClick={(e) => {
                                    autoUpdate(e);
                                  }}
                                  value="brand"
                                />
                                <label
                                  class="form-check-label"
                                  for="radioBrand"
                                >
                                  Brand
                                </label>
                              </div>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  disabled={
                                    localStorage.getItem(
                                      "is_brand_with_ingredients_disable"
                                    ) == "yes"
                                      ? true
                                      : false
                                  }
                                  name="search-type"
                                  onClick={(e) => {
                                    autoUpdate(e);
                                  }}
                                  id="radioBrandWithIngredients"
                                  value="ingredients"
                                />
                                <label
                                  class="form-check-label"
                                  for="radioBrandWithIngredients"
                                >
                                  Brand with Ingredients
                                </label>
                              </div>
                            </div>
                          </fieldset>
                          <AddRecommendationOnPrescription
                            setModalStatusRecommendationOfPrescription={
                              setModalStatusRecommendationOfPrescription
                            }
                            modalStatusRecommendationOfPrescription={
                              modalStatusRecommendationOfPrescription
                            }
                            recommendations={recommendations}
                            dispatch={dispatch}
                            selectedRecommendation={state.recommendationArr}
                            patientId={patientId}
                            appointmentId={appointmentId}
                          />
                        </div>
                      </div>
                      <div class="form-group custom-calender">
                        <h5>Next visit date</h5>
                        <Calendar
                          placeholder="DD-MM-YYYY"
                          selectedDate={nextVisitDate}
                          handleChange={(e) => setNextVisit(e)}
                          data={{
                            min: moment().format("DD-MMM-YYYY"),
                            class: "insulin-input ",
                            valid: true,
                            backDateDisabled: true,
                          }}
                        />
                      </div>
                      <div class="form-group" style={{ marginBottom: "0" }}>
                        <label>Printing Language</label>
                        <select
                          className="form-control"
                          onChange={(e) => {
                            handlePrintLanguage(e);
                          }}
                          value={printLanguage}
                        >
                          {printLanguageOptions.map(function (option) {
                            return (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </form>
                  </div>
                  {/* {prescriptionCount &&
                    <div >
                      <p style={{ 'color': 'red' }}>There is no data to save</p>
                    </div>
                  } */}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default withRouter(Prescription);

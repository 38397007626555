/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import * as API from '../../api/notifications';
import Card from 'Admin/components/Card/Card.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';




const AddEditNotification = (props) => {
    let history = useHistory();

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)
    const [mode, setMode] = useState("add");
    const [adminNotificationId, setAdminNotificationId] = useState("")
    const inputHandler = (e) => {
        //setIsSpecialChar(false)
        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.text && post.text != '') {
            errArr.text = '';
        } else { errArr.text = 'error'; errflag = false; }
        setErrors({ ...errArr });
        return errflag;
    }

    const submitHandler = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        let saveFormData = formData;
        saveFormData["text"] = formData.text?.trim()
        setFormData({ ...formData, ...saveFormData });

        // let regxSpecialChar = /[*|\":<>[\]{}`\\()';@&$!~`#%^_+=?,./]/
        // if (regxSpecialChar.test(formData.text)) {
        //     setIsSpecialChar(true)
        //     return
        // }
        if (validate()) {
            let sentData = new FormData();
            sentData.append('text', formData.text)
            let resp = ""
            if (mode === "add") {
                resp = await API.addAdminNotification(sentData);
                if (resp.data.statusCode == 200) {
                    history.push({ pathname: "/admin/notification-list", from: "add" })
                    setIsLoading(false);
                }
            } else {
                sentData.append('adminNotificationId', adminNotificationId)
                resp = await API.updateAdminNotification(sentData);
                if (resp.data.statusCode == 200) {
                    history.push({ pathname: "/admin/notification-list", from: "edit" })
                }
            }

        }
        setIsLoading(false);
    }


    useEffect(() => {
        let row = props.location.state?.row;
        let saveFormData = formData;
        saveFormData["text"] = "";

        if (row !== undefined && row) {
            setMode("edit");
            setAdminNotificationId(row._id);
            saveFormData["text"] = row.adminNotification;
            setFormData({ ...formData, ...saveFormData });
        }

    }, [])



    return (
        <div className="main-content">

            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={<legend className="line-removes">
                                <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { history.goBack() }}>Back</Button>
                            </legend>}
                            content={
                                <Form horizontal className="add-supportsystem_form">

                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Notification<span className="star">*</span></ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="text"
                                                            id="text"
                                                            placeholder="Notification"
                                                            onChange={e => { inputHandler(e); }}
                                                            autocomplete="off"
                                                            value={formData.text ? formData.text : ''}
                                                            className={'insulin-input ' + (errors.text ? errors.text : '')}
                                                        />
                                                        {isSpecialCharacter &&
                                                            <span className="star">Special Characters Are Not Allowed</span>
                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <FormGroup>
                                            <ControlLabel className="col-sm-2">
                                            </ControlLabel>
                                            <Col sm={12} className="btn_wrap">
                                                <Row>
                                                    <Col md={12}>
                                                        <button
                                                            type="button" className="btn-fill btn-wd btn btn-primary"
                                                            onClick={e => { submitHandler(e) }}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                                                            Submit</button>&nbsp;

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default withRouter(AddEditNotification)
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
    Row,
    Col,
    Modal,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
} from "react-bootstrap";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/home";
import moment from "moment";
import Datetime from "react-datetime";
import { appConstants } from "Front/_constants/app.constants.js";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";



const LabTest = ({ patientLabData, labTestListData, patientId, onDismissLabTests, actionMode, generalInformation, patientLabReportData, handleMode }) => {

    const [formData, setFormData] = useState({});
    const [isSpecialCharacter, setIsSpecialChar] = useState(false);
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [mode, setMode] = useState()
    const [labTestId, setLabTestId] = useState("")
    const [labTestList, setLabTestList] = useState([...labTestListData])
    const [patientTestReport, setPatientTestReport] = useState([])
    const [test_ReportDate, set_TestReportDate] = useState(Object.keys(patientLabData).length > 0 ? moment(patientLabData.testReportDate).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
    const [testReportDate, setTestReportDate] = useState(Object.keys(patientLabData).length > 0 ? moment(patientLabData.testReportDate).format("DD-MM-YYYY") : moment(new Date()).format("DD-MM-YYYY"))
    const [reportAlreadyExist, setReportAlreadyExist] = useState(false)
    const [isDataEntered, setIsDataEntered] = useState(false)
    const [labData, setLabData] = useState({})
    const [patientLabReportDetails, setPatientLabReportDetails] = useState([...patientLabReportData])
    const [formValidation, setFormValidation] = useState({})
    const [isInvalidValidData, setIsInvalidValidData] = useState(false)

    document.addEventListener("keydown", function (event) {
        // Check if the keyCode is 8 (backspace)
        let errValidation = formValidation
        let obj = errValidation[event.target.id]
        if (obj) {
            if (obj.errNumber || event.keyCode != 8)
                return
            if (event.keyCode === 8) {
                obj.errNumber = true
                setFormValidation({ ...errValidation })
            }
        }
    });

    const inputHandler = (e, obj) => {

        let val = e.target.value.replace(/\s+/g, " ");
        val = val.trimStart();
        let errValidation = formValidation
        //console.log(errValidation, "errrrr")
        if (val != "") {
            let regex = new RegExp(/^[0-9<>\.]+$/);
            if (!regex.test(val)) {
                errValidation[obj._id].errNumber = false
                errValidation[obj._id].errNumMsg = "Only Numbers Are Allowed"
                errValidation[obj._id].errValidation = false
                errValidation[obj._id].errValidationMsg = ""
                setFormValidation({ ...errValidation })
                //setIsInvalidValidData(true)
                // return
            } else {
                let str = e.target.value
                let decimalCount = 0
                for (let i = 0; i < str.length; i++) {
                    if (str[i] === '.')
                        decimalCount++;
                }
                setIsInvalidValidData(false)
                let allowLessThanGreaterThan = false
                errValidation[obj._id].errNumber = true
                errValidation[obj._id].errNumMsg = ""
                if (e.target.value.includes('<') || e.target.value.includes('>')) {

                    let specialCharsRegex = /[^0-9.]/g;
                    let specialChars = str.match(specialCharsRegex);
                    let specialCharCount = specialChars ? specialChars.length : 0;
                    //console.log(specialCharCount, decimalCount, "ooooo")
                    if (specialCharCount > 1 || (str.indexOf("<") > 0) || (str.indexOf(">") > 0)) {
                        errValidation[obj._id].errNumber = false
                        errValidation[obj._id].errNumMsg = "Invalid value"
                        //setIsInvalidValidData(true)
                        allowLessThanGreaterThan = true;
                    } else {
                        if (decimalCount > 1) {
                            errValidation[obj._id].errNumber = false
                            errValidation[obj._id].errNumMsg = "Invalid value"
                            //setIsInvalidValidData(true)
                            allowLessThanGreaterThan = true;
                        } else {
                            errValidation[obj._id].errNumber = true
                            errValidation[obj._id].errNumMsg = ""
                            //setIsInvalidValidData(false)
                            allowLessThanGreaterThan = true;
                        }
                    }

                }
                if (!allowLessThanGreaterThan) {
                    if (decimalCount <= 1) {
                        let test = labTestListData.find((ele) => ele._id === obj._id)
                        if (test) {
                            if (!test.autoCalculate && e.target.value != "" && !(test.validationRangeFrom <= e.target.value && test.validationRangeTo >= e.target.value)) {
                                errValidation[test._id].errValidation = true
                                errValidation[test._id].errValidationMsg = `Are you sure on this lab result?`
                            } else {
                                errValidation[test._id].errValidation = false
                                errValidation[test._id].errValidationMsg = ""
                            }
                        }
                    } else {
                        //console.log("lllll")
                        errValidation[obj._id].errNumber = false
                        errValidation[obj._id].errNumMsg = "Invalid value"
                        errValidation[obj._id].errValidation = false
                        errValidation[obj._id].errValidationMsg = ""
                        //setIsInvalidValidData(true)
                    }
                }
                setFormValidation({ ...errValidation })
            }
        } else {
            errValidation[obj._id].errNumber = true
            errValidation[obj._id].errNumMsg = ""
            errValidation[obj._id].errValidation = false
            errValidation[obj._id].errValidationMsg = ""
            setFormValidation({ ...errValidation })
            //setIsInvalidValidData(false)
        }
        let arr = Object.values(errValidation)
        let flagIsInvalid = false
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i].errNumber) {
                flagIsInvalid = true;
                break;
            }
        }

        setIsInvalidValidData(flagIsInvalid);

        setIsSpecialChar(false);
        let saveData = patientTestReport
        for (let i in saveData) {
            if (saveData[i].name === e.target.name) {
                saveData[i].value = val
                break;
            }
        }
        setPatientTestReport([...saveData])
        validate(saveData)
    };

    const validateTestValue = () => {
        let flag = false;
        let errValidation = formValidation
        let arr = Object.values(formValidation)
        //console.log(errValidation, "pp")
        for (let i = 0; i < arr.length; i++) {
            if (!arr[i]?.isHide && arr[i]?.errValidation) {
                flag = true;
                break;
            }
        }

        if (flag) {
            let msg = `Click 'Yes' to save. Click 'No' to edit.`
            flag = confirmTestValue(msg)
            setFormValidation({ ...errValidation })
        } else submitHandler()

        //return flag
    }

    const confirmTestValue = (msg) => {
        confirmAlert({
            title: "Confirm test values",
            message: msg,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        submitHandler()
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return false
                    },
                },
            ],
        });
    };

    const validate = (arr) => {
        let flag = false
        for (let i in arr) {
            if (arr[i].value != "") {
                flag = true
                break
            }
        }
        setIsDataEntered(flag)
    }



    const submitHandler = async (event) => {
        //console.log(validateTestValue(), "validation")
        // return
        let sessionToken = localStorage.getItem("token");
        calculateFib4()
        calculateHomaIR()
        eGFR()
        if (mode === "add") {
            // let res = await API.addPatientLabTestReport({ patientId: patientId, testReportDate: test_ReportDate, patientTestReport: patientTestReport })
            // console.log(res, "res")
            // if (res.data.status === "Success" && res.data.statusCode === 200)
            //     onDismissLabTests(test_ReportDate, res.data._id, mode, patientTestReport)

            let strUrl = appConstants.paAppURL;
            strUrl += "add-patient-test-report";
            axios
                .post(
                    strUrl,
                    { patientId: patientId, testReportDate: test_ReportDate, patientTestReport: patientTestReport },
                    {
                        headers: {
                            Authorization: sessionToken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    onDismissLabTests(test_ReportDate, res.data._id, "add", patientTestReport)

                })
                .catch((error) => {
                    setReportAlreadyExist(true)
                });
        }
        if (mode === "edit") {
            let res = await API.updatePatientLabTestReport({ patientId: patientId, id: labData._id, testReportDate: test_ReportDate, patientTestReport: patientTestReport })
            if (res.data.status === "Success" && res.data.statusCode === 200)
                onDismissLabTests(test_ReportDate, labData._id, "edit", patientTestReport)
        }
    }


    const getLabTestList = (data, mode) => {
        //console.log(data, labTestListData, patientLabReportData, "vvvvvvvvv")
        setMode(mode)
        let tempTests = []
        let objValidations = {}
        let errValidation = false
        let errValidationMsg = ""
        setLabData({ ...data })
        for (let i in labTestListData) {
            errValidation = false
            errValidationMsg = ""
            let obj = { name: labTestListData[i].name, _id: labTestListData[i]._id, value: "", isHide: labTestListData[i].isHide, formulaKey: labTestListData[i]?.formulaKey ? labTestListData[i]?.formulaKey : "", unit: labTestListData[i]?.unit }
            if (data?.patientTestReport?.length > 0) {
                let test = data.patientTestReport.find((ele) => ele._id === labTestListData[i]._id)
                let allowLessThanGreaterThan = false
                if (test) {
                    if (test.value.includes('<') || test.value.includes('>')) {
                        allowLessThanGreaterThan = true;
                    }
                    if (!allowLessThanGreaterThan && !test.autoCalculate && test.value != "" && !(labTestListData[i].validationRangeFrom <= test.value && labTestListData[i].validationRangeTo >= test.value)) {
                        errValidation = true
                        errValidationMsg = `Are you sure on this lab result?`
                    }

                }
            }
            objValidations[obj._id] = { ...obj, errNumber: true, errNumMsg: "Only Numbers Are Allowed", errValidation: errValidation, errValidationMsg: errValidationMsg }

            if (mode === "edit") {
                let result = data.patientTestReport.find(
                    (itm) =>
                        itm._id ===
                        labTestListData[i]._id
                )
                if (result)
                    obj.value = result.value

            }
            tempTests.push(obj)
        }
        validate(tempTests)
        //console.log(tempTests, "tespTests")
        setPatientTestReport([...tempTests])
        setFormValidation(objValidations)
        handleMode(mode)
    }

    const handleLabReportData = async (date) => {

        let data = patientLabReportData
        let localData = {}
        var localindex_index = data
            .map(function (el) {
                return moment(el?.testReportDate).format("DD-MM-YY");
            })
            .indexOf(moment(date).format("DD-MM-YY"));
        let tempMode = "add"
        if (localindex_index != -1) {
            localData = data[localindex_index];
            tempMode = "edit"
        } else {
            let res = await API.getPatientLabTestReport({ patientId: patientId, start_date: moment(date).format('YYYY-MM-DD'), end_date: moment(date).format('YYYY-MM-DD') })
            if (res.data.status === "Success" && res.data.statusCode === 200) {
                if (res.data.data.length > 0) {
                    let data = patientLabReportDetails
                    data.push(res.data.data[0])
                    setPatientLabReportDetails([...data])
                    tempMode = "edit"
                    localData = res.data.data[0]
                }
            }
        }
        getLabTestList(localData, tempMode)

    }

    useEffect(() => {
        handleLabReportData(new Date())
    }, [])

    const handleReportDate = (date) => {
        setReportAlreadyExist(false)

        let selectedDate = moment(date).format('YYYY-MM-DD');
        set_TestReportDate(selectedDate)
        setTestReportDate(moment(date).format('DD-MM-YYYY'))

        handleLabReportData(date)

        // if (mode === "add") {
        //     let selectedDate = moment(date).format('YYYY-MM-DD');
        //     set_TestReportDate(selectedDate)
        //     setTestReportDate(moment(date).format('DD-MM-YYYY'))
        // } else {
        //     let selectedDate = test_ReportDate
        //     set_TestReportDate(selectedDate)
        // }

    }

    const calculateFib4 = () => {
        //console.log(patientTestReport, "9999999")
        let objPlatelets = patientTestReport.find((ele) => ele.formulaKey === "Platelets")
        let objSgot = patientTestReport.find((ele) => ele.formulaKey === "sgot")
        let objSgpt = patientTestReport.find((ele) => ele.formulaKey === "sgpt")

        let result = 0.0
        if (generalInformation.age > 0 && objPlatelets.value != "" && objPlatelets.value > 0 && objSgot.value != "" && objSgpt.value != "" && objSgpt.value > 0) {
            result = (generalInformation.age * objSgot.value) / (objPlatelets.value * Math.sqrt(objSgpt.value))
            result = result.toFixed(2)
        } else result = ""
        let saveData = patientTestReport
        for (let i in saveData) {
            if (saveData[i].formulaKey === "Fib-4")
                saveData[i].value = result
        }
        setPatientTestReport([...saveData])
    }

    const calculateHomaIR = () => {
        let static_value_1 = 405;
        let objFastingGlucose = patientTestReport.find((ele) => ele.formulaKey === "fastingGlucose")
        let objFastingInsulin = patientTestReport.find((ele) => ele.formulaKey === "fastingInsulin")
        let result = 0.0
        if (objFastingInsulin.value != "" && objFastingGlucose.value != "") {
            result = objFastingGlucose.value * objFastingInsulin.value / static_value_1;
            result = result.toFixed(2);
        } else result = ""
        let saveData = patientTestReport
        for (let i in saveData) {
            if (saveData[i].formulaKey === "Homa IR")
                saveData[i].value = result
        }
        setPatientTestReport([...saveData])


    }

    const eGFR = () => {
        let gender = generalInformation.gender
        let age = generalInformation.age
        let objCreatinine = patientTestReport.find((ele) => ele.formulaKey === "creatinine")
        let result = 0.0;
        if (objCreatinine.value != "") {

            let static_value_1 = (gender === 'Male') ? 0.9 : 0.7; //0.9 for male
            let static_value_2 = (gender === 'Male') ? 1 : 1; //1 for male
            let static_value_3 = (gender === 'Male') ? -0.411 : -0.329; //-0.411 for male

            let static_value_4 = (gender === 'Male') ? 0.9 : 0.7; //0.9 for male
            let static_value_5 = (gender === 'Male') ? 1 : 1; //1 for male
            let static_value_6 = (gender === 'Male') ? -1.209 : -1.209; //-1.209 for male

            let static_value_7 = (gender === 'Male') ? 0.993 : 0.993; //0.993 for male
            let static_value_8 = (gender === 'Male') ? 141.0 : 141.0; //141.0 for male

            let static_value_9 = (gender === 'Male') ? 1 : 1.018; //1 for male

            let exp = Math.pow((Math.min((objCreatinine.value / static_value_1), static_value_2)), static_value_3);

            let m = Math.pow((Math.max((objCreatinine.value / static_value_4), static_value_5)), static_value_6);

            let temp = Math.pow(static_value_7, age);

            let fix = static_value_8;

            result = fix * exp * m * temp * static_value_9;
            result = result.toFixed(2)
        } else result = ""

        let saveData = patientTestReport
        for (let i in saveData) {
            if (saveData[i].formulaKey === "eFGR")
                saveData[i].value = result
        }
        setPatientTestReport([...saveData])
    }


    const valid = (current) => {
        let today = moment().format('YYYY-MM-DD');
        current = moment(current).format('YYYY-MM-DD');
        return today >= current;
    }



    //console.log(formValidation, "fromData")
    return (
        <div className="lab_test_modal">
            <span style={{ marginTop: "5px", display: "flex", marginBottom: "10px" }}>
                {mode === "edit" ?
                    <Datetime
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        inputProps={{ placeholder: "DD-MM-YYYY" }}
                        maxDate={new Date()}
                        name={"testReportDate"}
                        value={testReportDate}
                        onChange={e => handleReportDate(e)}
                        closeOnSelect
                        isValidDate={valid}

                    /> : <Datetime
                        timeFormat={false}
                        dateFormat={"DD-MM-YYYY"}
                        inputProps={{ placeholder: "DD-MM-YYYY" }}
                        maxDate={new Date()}
                        name={"testReportDate"}
                        value={testReportDate}
                        onChange={e => handleReportDate(e)}
                        closeOnSelect
                        isValidDate={valid}
                    />
                }

            </span>
            {reportAlreadyExist &&
                <span style={{ color: "red" }}>Report already exist for the selected date,please select other date</span>
            }
            <Row>
                <Col lg={12}>
                    <Form
                        // horizontal
                        noValidate
                        validated={validated}

                    >

                        <Row>

                            {patientTestReport &&
                                patientTestReport.map((obj, key) => {
                                    return (
                                        obj.isHide ? <></> :
                                            <Col sm={4} md={3}>
                                                <FormGroup className="lablist">
                                                    <label>{`${obj.name} ${obj.unit != "" ? `(${obj.unit})` : ""}`}</label>
                                                    <FormControl
                                                        type="text"
                                                        autocomplete="off"
                                                        name={obj.name}
                                                        onChange={(e) => {
                                                            inputHandler(e, obj);
                                                        }}
                                                        placeholder={obj.name}
                                                        value={obj.value}
                                                        id={obj._id}

                                                    />
                                                    {formValidation[obj._id] && (
                                                        <>
                                                            <span className="star" style={{ zIndex: "9999 !important", position: "absolute", display: formValidation[obj._id]?.errNumber ? "none" : "block" }}>
                                                                {formValidation[obj._id]?.errNumMsg}
                                                            </span>
                                                            <span className="warning-span" style={{ zIndex: "9990 !important", position: "absolute", color: "#ffc107!important", display: formValidation[obj._id]?.errValidation ? "block" : "none" }}>
                                                                {formValidation[obj._id]?.errValidationMsg}
                                                            </span>
                                                        </>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                    )
                                })
                            }


                        </Row>
                        <Col sm={12} md={12}>
                            {!isDataEntered &&
                                <span style={{ color: "red", marginRight: '10px' }}>Please Enter Any Data Before Save</span>
                            }
                            <label></label>
                            <Button
                                type="button"
                                className="btn-fill btn btn-primary"
                                onClick={e => validateTestValue()}
                                disabled={reportAlreadyExist || !isDataEntered || isInvalidValidData}
                            >
                                Save
                            </Button>
                        </Col>
                    </Form>
                </Col >
            </Row >

        </div >
    )
}

export default LabTest
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { uploadFileAction } from 'Admin/actions/faq';
import { updateFaqAction } from 'Admin/actions/faq';
import { clinicListAction } from 'Admin/actions/clinic';
import { specializationListAction } from 'Admin/actions/specialization';

var googleTranslate = require('google-translate')(process.env.REACT_APP_GOOGLE_KEY);

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    question_english: 'required',
    question_hindi: 'required',
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateFaq extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clinicList: [],
            specializationList: [],
            specialization: null,
            category: null,
            cardHidden: true,
            formArr: [],
            clinicError: null,
            specializationError: null,
            question_englishError: null,
            fileError: null,
            descriptionError: null,
            orderError: null,
            categoryError: null,
            statusError: null,
            oldspecializations: this.props.location.state.row,
            formData: {
                clinicName: this.props.location.state.row.clinicName,
                clinicId: this.props.location.state.row.clinicId,
                order: this.props.location.state.row.order,
                question_english: this.props.location.state.row.question.english,
                question_hindi: this.props.location.state.row.question.hindi,
                answer_english: this.props.location.state.row.answer.english,
                answer_hindi: this.props.location.state.row.answer.hindi,
                status: this.props.location.state.row.status,
                specializations: [],
                file: this.props.location.state.row.file,
                id: this.props.location.state.row._id
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {}
        }
        this.saveComment = this.saveComment.bind(this);
    }

    componentWillMount() {
        var oldspecializations = this.state.oldspecializations.specializations;
        var x = null
        let Spec = [];
        if (oldspecializations) {
            for (x in oldspecializations) {
                let obj = {};
                obj.label = oldspecializations[x].name;
                obj.value = oldspecializations[x].id;
                Spec.push(obj);
            }
            //this.state.specialization
            this.setState({ specialization: Spec })
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.clinicListAction(data)
        this.props.specializationListAction(data)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isClinicList !== this.props.isClinicList) {

            this.setState({
                clinicList: nextProps.ClinicList.data.data
            });
        }

        if (nextProps.isSpecializationList && nextProps.isSpecializationList !== this.props.isSpecializationList) {
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                this.state.specializationList = nextProps.SpecializationList.data.data.map((key, i) => {
                    return { value: key._id, label: key.name };
                });
            }
        }

        if (nextProps.isUpdateFaqError !== this.props.isUpdateFaqError) {
            if (nextProps.UpdateFaqMessage.errors) {
                nextProps.UpdateFaqMessage.errors.map((key, i) => {

                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }

        }
        if (nextProps.isUploadFile !== this.props.isUploadFile) {

            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg })
                });
            }
        }
        if (nextProps.isUpdateFaq !== this.props.isUpdateFaq) {
            this.props.handleClick('success', nextProps.msg)
            this.props.history.push('/admin/faq-list')

        }
    }

    saveComment(e) {
        if (e.keyCode == 13) {
            let _this = this;
            let fieldName = e.target.name;
            let fieldValue = e.target.value;
            googleTranslate.translate(e.target.value, 'en', 'hi', function (err, translation) {

                let field = _this.state.formData;
                field[fieldName] = translation.translatedText;
                _this.setState({ formData: field });

            });
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleClinic(event) {
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinicId'] = event.target.value
        formData['clinicName'] = event.target[index].text
        this.setState({ formData: formData });

    }

    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];
        this.props.uploadFileAction(file);
        reader.readAsDataURL(file);
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    updateFaq(evt) {
        evt.preventDefault();

        if (this.state.specialization != null) {
            var spArr = this.state.specialization.map((key, i) => {
                return { id: key.value, name: key.label };
            });
            this.state.formData.specializations = spArr;
        }
        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true });
            _this.props.updateFaqAction(this.state);
        }
        //validation.errors()
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <legend className="line-removes">
                                        <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                    </legend>
                                }
                                content={
                                    <Form horizontal>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Clinic
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="clinic" value={this.state.formData.clinicId} onChange={(event) => this.handleClinic(event)}>
                                                    <option value="">Select Clinic</option>
                                                    {this.state.clinicList.map(function (item) {
                                                        return <option key={item._id} value={item._id}>{item.name}</option>
                                                    })}
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Specialization
                                            </Col>
                                            <Col sm={6}>
                                                <Select
                                                    placeholder="Select Specialization"
                                                    name="specialization"
                                                    id="specialization"
                                                    closeOnSelect={false}
                                                    multi={true}
                                                    value={this.state.specialization}
                                                    options={this.state.specializationList}
                                                    onChange={(value) => this.setState({ specialization: value })}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Question English <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" name="question_english" bsClass="form-control" placeholder="Question English" value={this.state.formData.question_english} defaultValue="" onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">{this.state.formArr.question_english && validation.errors.first('question_english')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Question Hindi <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" name="question_hindi" bsClass="form-control" placeholder="Question Hindi" value={this.state.formData.question_hindi} defaultValue="" onKeyDown={e => { this.saveComment(e); }} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">{this.state.formArr.question_hindi && validation.errors.first('question_hindi')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Answer English
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" name="answer_english" bsClass="form-control" placeholder="Answer English" value={this.state.formData.answer_english} defaultValue="" onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Answer Hindi
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" name="answer_hindi" placeholder="Answer Hindi" value={this.state.formData.answer_hindi} bsClass="form-control" defaultValue="" onKeyDown={e => { this.saveComment(e); }} onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Upload File
                                            </Col>
                                            <Col sm={6}>
                                                <input type="hidden" name="id" value={this.state.formData.id} onChange={e => { this.fileChangedHandler(e, "id") }}></input>
                                                <input type="file" name="file" onChange={e => { this.fileChangedHandler(e, "file") }}></input>{this.state.formData.file}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Order
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="number" placeholder="Order" name="order" value={this.state.formData.order} onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Status
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="status" value={this.state.formData.status} onChange={e => { this.handleChange(e); }}>
                                                    <option value="1">Enabled</option>
                                                    <option value="0">Disbled</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.updateFaq.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>

                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.faq.message,
        UpdateFaq: state.faq.UpdateFaq,
        isUpdateFaq: state.faq.isUpdateFaq,
        isUpdateFaqError: state.faq.isUpdateFaqError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        SpecializationList: state.specilization.SpecializationList,
        isSpecializationList: state.specilization.isSpecializationList,
        isSpecializationListError: state.specilization.isSpecializationListError,

        isUploadFile: state.faq.isUploadFile,
        isUploadFileError: state.faq.isUploadFileError,
        uploadFile: state.faq.uploadFile,
    }
}
export default withRouter(connect(mapStateToProps, { updateFaqAction, clinicListAction, specializationListAction, uploadFileAction })(UpdateFaq));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { clinicDocumentUploadListAction } from "Admin/actions/clinic_document_upload";
import { clinicDocumentUploadchangestatusAction } from "Admin/actions/clinic_document_upload";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import { Button } from "react-bootstrap";

class ClinicDocumentUploadList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      status: "",
      clinicShowStatus: "",
      clinicDocumentUploadList: [],
      isLoading: true,
      asc_desc: true,
      userRow: null,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
      formData: {
        direction: "desc",
        order: "createdAt",
        offset: 0,
        limit: 10,
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
  }
  componentDidMount() {
    this.props.clinicDocumentUploadListAction(this.state.formData);
  }

  componentWillReceiveProps(nextProps) {
    let date1 = [];

    if (
      nextProps.isClinicDocumentUploadList !==
      this.props.isClinicDocumentUploadList
    ) {

      if (nextProps.ClinicDocumentUploadList.data) {
        this.setState({
          clinicDocumentUploadList:
            nextProps.ClinicDocumentUploadList.data.data,
          totalCount: nextProps.ClinicDocumentUploadList.data.count,
        });
      }
    }
    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  navigateto() {
    // if (this.clicked !== "Button") {
    window.location.href = "add-clinic-document-upload";
    // }
    // reset
    this.clicked = "";
  }

  statusShow(cell, row, enumObject, rowIndex) {
    return row.clinicShowStatus === true ? "Active" : "Inactive";
  }

  editButton(cell, row, enumObject, rowIndex) {

    return (
      <span>
        <Link
          to={{ pathname: `update-clinic-document-upload/`, state: { row } }}
          onClick={this.toggleEdit.bind(this, row)}
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </Link>
        &nbsp;&nbsp; &nbsp;&nbsp;
        <a
          href="javascript:void(0)"
          onClick={this.statusChangedHandler.bind(this, row)}
        >
          <i
            className={
              row.clinicShowStatus === true ? "fa fa-check" : "fa fa-remove"
            }
            aria-hidden="true"
          ></i>
        </a>
      </span>
    );
  }

  onUserCount(cell, row, enumObject, rowIndex) {
    return (
      (this.state.currentPage - 1) * this.state.sizePerPage + (rowIndex + 1)
    );
  }

  toggleEdit(event) {
    this.setState({
      userRow: event,
    });
  }

  statusChangedHandler(event, elename) {
    let newstatus = {};
    if (event.clinicShowStatus == true) {
      newstatus = false;
    } else {
      newstatus = true;
    }
    event["linkId"] = event._id;
    event["clinicShowStatus"] = newstatus;
    this.props.clinicDocumentUploadchangestatusAction(event);
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    if (this.state.searchText === "") {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    } else {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.clinicDocumentUploadListAction(data);

    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {};
        data["direction"] = this.state.formData.direction;
        data["order"] = this.state.formData.order;
        data["offset"] = this.state.formData.offset;
        data["limit"] = this.state.formData.limit;
        data["search"] = text;
        this.setState({ sizePerPage: 10 });
        this.setState({ currentPage: 1 });
        this.props.clinicDocumentUploadListAction(data);
      }
    } else {
      setTimeout(() => {     
        this.componentDidMount();
      }, 100);
    }
    this.setState({ searchText: text });
  }

  clearSearch(e) {
    this.setState({ searchText: '' });
    this.componentDidMount();
  }

  sortHandle(){
    this.setState({ asc_desc:!this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc? "asc":"desc"
    this.setState({ formData : data });
    this.props.clinicDocumentUploadListAction(data);
  }

  render() {
    const selectRowProp = { mode: "checkbox" };
    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      noDataText: this._setTableOption(),
    };

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={6}>
                        <Button
                          bsStyle="info"
                          className="inacti-button"
                          fill
                          pullRight
                          onClick={() =>
                            this.props.history.replace(
                              "add-clinic-document-upload"
                            )
                          }
                        >
                          Add Clinic Document
                        </Button>
                      </Col>
                    </Row>
                    &nbsp;
                    <div className='search-box-admin'>
                          <div className='search'>
                              <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                              <span className='input-group-btn'>
                              <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button> 
                              </span>
                          </div>
                    </div>
                    <BootstrapTable
                      selectRow="{selectRowProp}"
                      deleteRow={false}
                      data={this.state.clinicDocumentUploadList}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={"Bottom"}
                      remote={true}
                      fetchInfo={{ dataTotalSize: this.state.totalCount }}
                    >
                      <TableHeaderColumn
                        hidden={true}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="invitationId"
                        dataSort={true}
                        isKey
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "5%", textAlign: 'center' }}
                        tdStyle={{ width: "5%", textAlign: 'center' }}
                        tdAttr={{ "data-attr": "#" }}
                        dataField=""
                        dataFormat={this.onUserCount}
                      >
                        #
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "20%", textAlign: 'center' }}
                        tdStyle={{ width: "20%", textAlign: 'center' }}
                        tdAttr={{ "data-attr": "Client Document" }}
                        dataField="title"
                      >
                        Document Name
                        {
                          this.state.asc_desc?
                          <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                          <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "20%", textAlign: 'center' }}
                        tdStyle={{ width: "20%", textAlign: 'center' }}
                        tdAttr={{ "data-attr": "Status" }}
                        dataField="clinicShowStatus"
                        dataFormat={this.statusShow}
                      >
                        Status
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "10%", textAlign: 'center' }}
                        tdStyle={{ width: "10%", textAlign: 'center' }}
                        tdAttr={{ "data-attr": "Action" }}
                        dataFormat={this.editButton.bind(this)}
                      >
                        Action
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ClinicDocumentUploadList: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.ClinicDocumentUploadList
      : [],
    isClinicDocumentUploadList: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.isClinicDocumentUploadList
      : [],
    isClinicDocumentUploadListError: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.isClinicDocumentUploadListError
      : [],
    ClinicDocumentUploadChangeStatus: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.ClinicDocumentUploadChangeStatus
      : [],
    isClinicDocumentUploadChangeStatus: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.isClinicDocumentUploadChangeStatus
      : [],
    isClinicDocumentUploadChangeStatusError: state.clinicDocumentUpload
      ? state.clinicDocumentUpload.isClinicDocumentUploadChangeStatusError
      : [],
  };
}
export default withRouter(
  connect(mapStateToProps, {
    clinicDocumentUploadListAction,
    clinicDocumentUploadchangestatusAction,
  })(ClinicDocumentUploadList)
);

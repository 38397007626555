/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, Form, FormGroup, ControlLabel, Button, Nav, NavItem, NavDropdown, MenuItem, Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
//import Select from 'react-select';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { readyCreatedMealListAction, readyCreatedMealchangestatusAction, updatePriorityAction } from 'Admin/actions/ready_created_meal';
import { dietTimeListAction } from 'Admin/actions/diet_time';
import { treatmentListTypeAction, treatmentListAction } from 'Admin/actions/treatment';
import { regionListAction } from 'Admin/actions/region';
import { foodListAction } from 'Admin/actions/food';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
//import { languageListAction } from 'Admin/actions/language';
import { appConstants } from 'Admin/_constants/app.constants';

var count = 1;

class FoodGroupList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            rcmList: [],
            foodList: [],
            dietTimeList: [],
            regionList: [],
            treatmentList: [],
            treatmentDropdown: [],
            modelViewData: {},
            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            veg_checked: true,
            non_veg_checked: true,
            formData: {
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "1",
                status: "active",
                veg: "",
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
    }

    componentDidMount() {
        this.props.foodListAction({ status: "active", offset: 0, limit: 1000 });
        this.props.treatmentListTypeAction();
        this.props.treatmentListAction();
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        data['direction'] = "asc";
        data['order'] = "orderBy";
        data['offset'] = 0;
        data['limit'] = 10;
        if (this.props.DietTimeList) {
            this.setState({ dietTimeList: this.props.DietTimeList.data.data });
        } else {
            this.props.dietTimeListAction(data);
        }
        data['order'] = "createdAt";

        this.props.regionListAction(data);

        this.props.readyCreatedMealListAction(this.state.formData);
        // this.props.languageListAction(data);        
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isUpdatePriority !== this.props.isUpdatePriority) {
            this.setState({ updatePriority: {} });
            this.props.readyCreatedMealListAction(this.state.formData);
        }

        let i = 0;

        if (nextProps.isFoodList !== this.props.isFoodList) {

            let fList = nextProps.FoodList.data.data;

            let fdList = [];

            for (let k in fList) {
                fdList[fList[k]._id] = fList[k];
            }

            this.setState({ foodList: fdList });
        }
        if (nextProps.isDietTimeList !== this.props.isDietTimeList) {
            this.setState({
                dietTimeList: nextProps.DietTimeList.data.data,
            });
        }

        let entry = [];
        if (nextProps.isRegionList !== this.props.isRegionList) {
            nextProps.RegionList.data.data.map((key, index) =>

                entry.push({
                    "value": key._id,
                    "label": key.name
                })
            );
            this.setState({
                regionList: entry,
            });
        }

        // if (nextProps.isTreatmentListType !== this.props.isTreatmentListType) {
        //     this.setState({
        //         treatmentList: nextProps.TreatmentListType.data.data,
        //     });
        // }

        if (nextProps.isTreatmentList !== this.props.isTreatmentList) {
            let treatment = nextProps.TreatmentList.data.data;
            let treatmentDropdown = [];
            for (i in treatment) {
                if (treatment[i].type == 'non-diabetic') {
                    treatmentDropdown.push(treatment[i]);
                }
            }
            this.setState({
                treatmentList: nextProps.TreatmentList.data.data,
                treatmentDropdown: treatmentDropdown
            });
        }

        let mealList = [];
        if (nextProps.isReadyCreatedMealList !== this.props.isReadyCreatedMealList) {
            let meal_list = nextProps.ReadyCreatedMealList.data.data;

            for (let i in meal_list) {
                if (meal_list[i].dietTimeId && meal_list[i].dietTimeId.length > 0) {
                    meal_list[i].dietTimeData = [];
                    for (let j in meal_list[i].dietTimeId) {
                        var index = this.state.dietTimeList.map(function (el) {
                            return el._id;
                        }).indexOf(meal_list[i].dietTimeId[j]);

                        if (index > -1) {
                            meal_list[i].dietTimeData.push(this.state.dietTimeList[index]);
                        }
                    }
                }

                if (meal_list[i].regionId && meal_list[i].regionId.length > 0) {
                    meal_list[i].regionData = [];
                    for (let k in meal_list[i].regionId) {
                        let region_list = this.state.regionList;
                        var localindex_index = region_list.map(function (el) {
                            return el.value;
                        }).indexOf(meal_list[i].regionId[k]);
                        if (localindex_index != -1) {
                            meal_list[i].regionData.push(region_list[localindex_index]);
                        }
                    }
                }

                // if(meal_list[i].treatmentPlanIds && meal_list[i].treatmentPlanIds.length > 0){
                //     meal_list[i].treatmentData = [];
                //     for (let p in meal_list[i].treatmentPlanIds) {
                //         let treatment_list = this.state.treatmentList;
                //         var localindex = treatment_list.map(function (el) {
                //             return el._id;
                //         }).indexOf(meal_list[i].treatmentPlanIds[p]);
                //         if(localindex != -1){
                //              meal_list[i].treatmentData.push(treatment_list[localindex]);
                //         }
                //     }
                // }

                if (meal_list[i].treatmentPlanIds && meal_list[i].treatmentPlanIds.length > 0) {
                    meal_list[i].treatmentData = [];
                    for (let q in meal_list[i].treatmentPlanIds) {
                        let treatment_list = this.state.treatmentList;
                        for (let r in treatment_list) {
                            var localindex = treatment_list[r].treatmentdata.map(function (el) {
                                return el._id;
                            }).indexOf(meal_list[i].treatmentPlanIds[q]);
                            if (localindex != -1) {
                                treatment_list[r].treatmentdata[localindex].treatment_type_name = treatment_list[r].name;
                                meal_list[i].treatmentData.push(treatment_list[r].treatmentdata[localindex]);
                            }
                        }

                    }
                }
                mealList.push(meal_list[i]);
                // console.log(mealList);
            }

            //console.log(mealList);

            this.setState({
                rcmList: mealList,
                totalCount: nextProps.ReadyCreatedMealList.data.count
            });
        }

        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }

    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    editButton(cell, row, enumObject, rowIndex) {

        return (<p>
            <a href="javascript:void(0)" onClick={this.openMealModal.bind(this, row, rowIndex)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row, rowIndex)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>

        </p>)
    }
    openMealModal(row) {
        row.redirect_url = "food_group_add_edit/";
        if (row.foodType == 'custom') {
            row.redirect_url = "update-ready-created-meal/";
        }
        this.setState({ modelViewData: row, MealModal: true });
    }


    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, index) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.readyCreatedMealchangestatusAction(event);
    }


    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-food";
        this.clicked = "";
    }

    handleLanguage(event) {
        let formData = this.state.formData
        formData['lang'] = event.target.value
        this.setState({ formData: formData });
    }

    handleActiveList(event) {
        let formData = this.state.formData
        formData['status'] = 'active'
        this.setState({ formData: formData });
        this.props.readyCreatedMealListAction(this.state.formData);
    }

    handleInactiveList(event) {
        let formData = this.state.formData
        formData['status'] = 'inactive'
        this.setState({ formData: formData });
        this.props.readyCreatedMealListAction(this.state.formData);
    }

    mealDescription(cell, row, enumObject, rowIndex) {
        //console.log(row.group);foodList
        if (row.group.length > 0) {
            let foodStr = [];
            for (let i in row.group) {
                let food = this.state.foodList[row.group[i].foodId];

                if (food && food.name) {
                    foodStr.push(food.name['1'] + ' ' + row.group[i].qty + ' (' + food.info['1'] + ')');
                }
            }
            return (foodStr.join(' + '));
        } else {
            return row.name;
        }
    }

    handleCalorie(cell, row, enumObject, rowIndex) {
        return (<span><b>{row.calorie} kcal</b><br></br> C : {row.carbohydrate}, P : {row.protein}, F : {row.fat}</span>)
    }
    handleMealType(cell, row, enumObject, rowIndex) {

        let veg = appConstants.vegNonVeg(row.veg);
        let mealCategory = "";
        if (row.mealCategory) {
            mealCategory = row.mealCategory;
        }
        if (mealCategory == 's') {
            mealCategory = "Urban";
        } else if (mealCategory == "l") {
            mealCategory = "Rural";
        } else if (mealCategory == "h") {
            mealCategory = "Ready formula";
        }
        let meal_type = veg;
        if (mealCategory) {

            meal_type = meal_type + "</br>" + "(" + mealCategory + ")"
        }
        return meal_type
    }

    handleChangeDietTime(row) {

        let dietTimeId = [];
        if (this.state.formData.dietTimeId) {
            dietTimeId = this.state.formData.dietTimeId;
        }
        var localindex = dietTimeId.map(function (el) {
            return el;
        }).indexOf(row._id);
        if (localindex != -1) {
            dietTimeId.splice(localindex, 1);
        } else {
            dietTimeId.push(row._id)
        }
        let field = this.state.formData;
        if (dietTimeId.length > 0) {
            field.dietTimeId = dietTimeId;
        } else {
            field.dietTimeId = "";
        }


        this.setState({ formData: field });
        this.props.readyCreatedMealListAction(this.state.formData);
    }
    handleFoodTypeFilter(e) {
        e.preventDefault();
        let field = this.state.formData;
        field.veg = e.target.value;
        this.setState({ formData: field });
        this.props.readyCreatedMealListAction(this.state.formData);
    }

    handleMealCategoryFilter(e) {
        e.preventDefault();
        let field = this.state.formData;
        field.mealCategory = e.target.value;
        this.setState({ formData: field });
        this.props.readyCreatedMealListAction(this.state.formData);

    }

    handleCalorieRange(e) {
        e.preventDefault();
        let calorie_array = e.target.value.split('-');
        let field = this.state.formData;
        field.calorieStart = calorie_array[0];
        field.calorieEnd = calorie_array[1];
        this.setState({ formData: field });
        this.props.readyCreatedMealListAction(this.state.formData);

    }

    handleRegion(e) {
        e.preventDefault();
        let field = this.state.formData;
        if (e.target.value) {
            field['region'] = [];
            field['region'].push(e.target.value);
        } else {
            field['region'] = "";
        }

        this.setState({ formData: field });
        this.props.readyCreatedMealListAction(this.state.formData);
    }

    handleDiet(e) {
        e.preventDefault();
        let field = this.state.formData;
        field['search'] = e.target.value;
        //this.setState({ formData: field });
        //this.props.treatmentListAction(field);
        this.props.readyCreatedMealListAction(this.state.formData);
    }



    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        this.props.readyCreatedMealListAction(formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        }
        data['veg'] = this.state.formData.veg;
        data['region'] = this.state.formData.region;
        data['mealCategory'] = this.state.formData.mealCategory;
        data['calorieStart'] = this.state.formData.calorieStart;
        data['calorieEnd'] = this.state.formData.calorieEnd;
        data['status'] = this.state.formData.status;
        data['dietTimeId'] = this.state.formData.dietTimeId;

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.readyCreatedMealListAction(data)
        
        if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
            eval('$(".ps").scrollTop(0)');
          }
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                data['veg'] = this.state.formData.veg;
                data['region'] = this.state.formData.region;
                data['mealCategory'] = this.state.formData.mealCategory;
                data['calorieStart'] = this.state.formData.calorieStart;
                data['calorieEnd'] = this.state.formData.calorieEnd;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.readyCreatedMealListAction(data);
            }
        } else {
            setTimeout(() => {     
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    sortHandle(){
        this.setState({ asc_desc:!this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc? "asc":"desc"
        this.setState({ formData : data });
        this.props.readyCreatedMealListAction(data);
      }

    handleDiettimeData(cell, row, enumObject, rowIndex) {
        let time_slot = [];

        for (let i in row.dietTimeData) {

            time_slot.push(row.dietTimeData[i].name['1']);
        }
        return time_slot.join(',<br>');
    }

    handleRegionData(cell, row, enumObject, rowIndex) {
        let region_name = "";
        for (let i in row.regionData) {
            if (region_name) {
                region_name = region_name + ",</br>" + row.regionData[i].label;
            } else {
                region_name = row.regionData[i].label;
            }
        }
        return region_name;
    }

    handleFoodType(cell, row, enumObject, rowIndex) {
        let create_type = "Ready create meal";
        if (row.foodType == 'group') {
            create_type = "Create a meal";
        }
        return create_type;
    }

    handlePriorityData(cell, row, enumObject, rowIndex) {

        return <div>
            <select className="form-control" name="priority" placeholder="priority" value={row.priority} onChange={(event) => this.handleChangePriority(event, row)}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
            </select>
            {
                this.state.updatePriority && this.state.updatePriority.id == row._id && <div> <br></br> <button type="button" onClick={() => this.openUpdatePriority(row)} className="btn-fill btn-sm btn btn-primary">update</button></div>
            }

        </div>

    }

    openUpdatePriority() {
        if (this.state.updatePriority) {
            this.props.updatePriorityAction(this.state.updatePriority);
        }

    }

    handleChangePriority(e, row) {
        let rcm_data = this.state.rcmList;
        e.preventDefault();
        let data = {
            "priority": e.target.value,
            id: row._id
        }
        var localindex = this.state.rcmList.map(function (el) {
            return el._id;
        }).indexOf(row._id);
        if (localindex !== -1) {
            rcm_data[localindex].priority = e.target.value;
            this.setState({ updatePriority: data })
        }
        this.setState({ rcmList: rcm_data })
    }

    handleTreatmentData(cell, row, enumObject, rowIndex) {
        // var treatment_data = row.treatmentData[0].treatmentTypedata[0].name + "-" + row.treatmentData[0].name;
        // if (row.treatmentData && row.treatmentData.length > 1) {
        //     let more_button = <div>{treatment_data}  <button type="button" onClick={() => this.openTreatmentModel(row)} className="btn-fill btn-sm btn btn-info">More</button></div>;
        //     treatment_data = more_button;
        // }
        // return treatment_data

        var treatment_data = ""
        if (row.treatmentData && row.treatmentData[0] && row.treatmentData[0].treatment_type_name) {
            treatment_data = row.treatmentData[0].treatment_type_name + "-" + row.treatmentData[0].name;
            if (row.treatmentData && row.treatmentData.length > 1) {
                let more_button = <div>{treatment_data}  <button type="button" onClick={() => this.openTreatmentModel(row)} className="btn-fill btn-sm btn btn-primary">More</button></div>;
                treatment_data = more_button;
            }
        }
        return treatment_data
    }

    openTreatmentModel(row) {
        this.setState({ modelViewData: row, treatmentModel: true });
    }


    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {
        let _this = this;
        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
        };
        const { modelViewData } = this.state;
        return (

            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6}>
                                                <Button className="spcci-rh" bsStyle="info" fill pullRight onClick={e => { this.handleActiveList(e); }}>Active List</Button>
                                                <Button bsStyle="info" fill pullRight onClick={e => { this.handleInactiveList(e); }}>Inactive List</Button>

                                            </Col>
                                        </Row>
                                        <hr></hr>
                                        <Col componentClass={ControlLabel}>
                                            Search by time slot <span className="star">*</span>
                                        </Col>

                                        <Col md={12} className="in-bl">
                                            {this.state.dietTimeList.map(function (timeData) {
                                                return (
                                                    <Col md={4}>
                                                        <div><input type="checkbox" name={"time_" + timeData._id} id={"time_" + timeData._id} onChange={(event) => { _this.handleChangeDietTime(timeData) }} />&nbsp; {timeData.name['1']}<br></br></div>
                                                    </Col>)

                                            })
                                            }
                                        </Col>

                                        <Row>
                                            <Col sm={4} className="spcc">
                                                <div className="form-group">
                                                    <FormControl componentClass="select" name="calorie_range" id="calorie_range" onChange={e => { this.handleRegion(e); }}>
                                                        <option value="" >Select region</option>
                                                        {this.state.regionList.map(function (item) {
                                                            return <option value={item.value} >{item.label}</option>
                                                        })}
                                                    </FormControl>
                                                </div>
                                            </Col>

                                            {/* <Col sm={4} className="spcc">
                                              <div className="form-group">
                                                <FormControl componentClass="select" name="diet_type" id="diet_type" onChange={e => { this.handleDiet(e); }}>
                                                    <option value="" >Select diet</option>
                                                    <option value="diabetic" >Diabetic diet</option>
                                                    {this.state.treatmentDropdown.map(function (item) {
                                                      return  <option value={item.name} >{item.name}</option>   
                                                    })}
                                                </FormControl>
                                              </div>
                                            </Col> */}

                                            <Col sm={4} className="spcc">
                                                <div className="form-group">
                                                    <FormControl componentClass="select" name="calorie_range" id="calorie_range" onChange={e => { this.handleCalorieRange(e); }}>
                                                        <option value="" >Select calorie range</option>
                                                        {appConstants.calorieRange().map(function (item) {

                                                            return <option value={item.range} >{item.range}</option>
                                                        })}
                                                    </FormControl>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: "15px"}}>
                                            <Col md={4}>
                                                <FormControl componentClass="select" name="langname" onChange={e => { this.handleFoodTypeFilter(e); }}>
                                                    <option value="">Select food type</option>
                                                    <option value="veg">Vegetarian</option>
                                                    <option value="non-veg">Non-Vegetarian</option>
                                                </FormControl>
                                            </Col>
                                            <Col md={4}>
                                                <select className="form-control" name="mealCategory" placeholder="Meal Category" onChange={(event) => this.handleMealCategoryFilter(event)}>
                                                    <option value="">Select meal category</option>
                                                    <option value="s">Urban</option>
                                                    <option value="l">Rural</option>
                                                    <option value="h">Ready formula</option>
                                                </select>
                                            </Col>

                                        </Row>&nbsp;
                                        <div className='search-box-admin'>
                                            <div className='search'>
                                                <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                    <span className='input-group-btn'>
                                                    <Button onClick={(e) => { this.onSearchChange(e.target.value, "null")}}>Clear</Button> 
                                                    </span>
                                            </div>
                                        </div>
                                        <BootstrapTable
                                            selectRow="{selectRowProp}"
                                            deleteRow={false}
                                            data={this.state.rcmList}
                                            // search={true}
                                            multiColumnSearch={true}
                                            pagination={true}
                                            options={options}
                                            striped
                                            hover
                                            condensed
                                            scrollTop={'Bottom'}
                                            remote={true}
                                            fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                        >
                                            <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '5%', textAlign: 'center' }} tdStyle={{ width: '5%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '18%', textAlign: 'center'}} tdStyle={{ width: '18%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'name' }} dataField='name' dataFormat={this.mealDescription.bind(this)}  >Meal description
                                            {
                                                this.state.asc_desc?
                                                <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                                                <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                                            }
                                            </TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'calorie' }} dataFormat={this.handleCalorie.bind(this)}  >Calorie</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'veg' }} dataField='veg' dataFormat={this.handleMealType.bind(this)}>Meal Type</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'foodType' }} dataFormat={this.handleFoodType.bind(this)} >Create Type</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'dietTimeData' }} dataFormat={this.handleDiettimeData.bind(this)} >Time slot</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '12%', textAlign: 'center' }} tdStyle={{ width: '12%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'treatmentData' }} dataFormat={this.handleTreatmentData.bind(this)} >Treatment Type</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center'}} tdAttr={{ 'data-attr': 'regionData' }} dataFormat={this.handleRegionData.bind(this)} >Region</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' dataFormat={appConstants.ucFirst} >Status</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='priority' dataFormat={this.handlePriorityData.bind(this)} >Priority</TableHeaderColumn>
                                            <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} >Action</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                }
                            />
                            <Modal show={this.state.MealModal} onHide={() => this.setState({ MealModal: false })} dialogClassName="modal-md">
                                <Modal.Header closeButton >
                                    <Modal.Title id="example-modal-sizes-title-lg">Meal Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="Knowledge-Share card">
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={4}>
                                                    <h6> Meal description :</h6>
                                                </Col>
                                                <Col md={6}>
                                                    {modelViewData.name}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <h6> Calorie :</h6>
                                                </Col>
                                                <Col md={6}>
                                                    {modelViewData.calorie}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <h6> Carbohydrate :</h6>
                                                </Col>
                                                <Col md={6}>
                                                    {modelViewData.carbohydrate}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <h6> Protine :</h6>
                                                </Col>
                                                <Col md={6}>
                                                    {modelViewData.protine}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={4}>
                                                    <h6> Fat :</h6>
                                                </Col>
                                                <Col md={6}>
                                                    {modelViewData.fat}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer >
                                    <Link to={{ pathname: modelViewData.redirect_url + modelViewData._id, state: { modelViewData } }} >  <button type="button" onClick={this.toggleEdit.bind(this, modelViewData)} className="btn-fill btn-wd btn btn-info">Continue</button></Link>&nbsp;
                                    <button type="button" onClick={() => this.setState({ MealModal: false })} className="btn-fill btn-wd btn btn-secondary">Close</button>&nbsp;
                                </Modal.Footer>
                            </Modal>

                            <Modal show={this.state.treatmentModel} onHide={() => this.setState({ treatmentModel: false })} dialogClassName="modal-md">
                                <Modal.Header>
                                    <Row>
                                        <Col md={12}>

                                            <Row>

                                                <Col md={6}>
                                                    <Modal.Title><h5>Treatment</h5></Modal.Title>
                                                </Col>
                                                <Col md={6}>
                                                    <Modal.Title><h5>Sub-Treatment</h5></Modal.Title>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Modal.Header>
                                <Modal.Body className="Knowledge-Share card">
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                {modelViewData && modelViewData.treatmentData && modelViewData.treatmentData.length > 0 &&
                                                    modelViewData.treatmentData.map(function (item) {

                                                        return (<div>
                                                            <Row>
                                                                <Col md={6}>
                                                                    {/* {item.treatmentTypedata && item.treatmentTypedata[0] && item.treatmentTypedata[0].name} */}
                                                                    {item.treatment_type_name}
                                                                </Col>
                                                                <Col md={6}>
                                                                    {item.name}
                                                                </Col>
                                                                <hr></hr>
                                                            </Row>
                                                        </div>)
                                                    })
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" onClick={() => this.setState({ treatmentModel: false })} className="btn-fill btn-wd btn btn-secondary">Close</button>&nbsp;
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        FoodList: state.food.FoodList,
        isFoodList: state.food.isFoodList,
        isFoodListError: state.food.isFoodListError,

        ReadyCreatedMealList: state.readyCreatedMeal.ReadyCreatedMealList,
        isReadyCreatedMealList: state.readyCreatedMeal.isReadyCreatedMealList,
        isReadyCreatedMealListError: state.readyCreatedMeal.isReadyCreatedMealListError,

        DietTimeList: state.dietTime.DietTimeList,
        isDietTimeList: state.dietTime.isDietTimeList,
        isDietTimeListError: state.dietTime.isDietTimeListError,

        ReadyCreatedMealChangeStatus: state.readyCreatedMeal.ReadyCreatedMealChangeStatus,
        isReadyCreatedMealChangeStatus: state.readyCreatedMeal.isReadyCreatedMealChangeStatus,
        isReadyCreatedMealChangeStatusError: state.readyCreatedMeal.isReadyCreatedMealChangeStatusError,

        UpdatePriority: state.readyCreatedMeal.UpdatePriority,
        isUpdatePriority: state.readyCreatedMeal.isUpdatePriority,
        isUpdatePriorityError: state.readyCreatedMeal.isUpdatePriorityError,

        RegionList: state.region.RegionList,
        isRegionList: state.region.isRegionList,
        isRegionListError: state.region.isRegionListError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

        TreatmentListType: state.treatment.TreatmentListType,
        isTreatmentListType: state.treatment.isTreatmentListType,
        isTreatmentListTypeError: state.treatment.isTreatmentListTypeError,

        TreatmentList: state.treatment.TreatmentList,
        isTreatmentList: state.treatment.isTreatmentList,
        isTreatmentListError: state.treatment.isTreatmentListError,
    }
}
export default withRouter(connect(mapStateToProps, { foodListAction, readyCreatedMealListAction, treatmentListTypeAction, treatmentListAction, dietTimeListAction, readyCreatedMealchangestatusAction, updatePriorityAction, regionListAction })(FoodGroupList));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';

import { NavLink } from 'react-router-dom';


const Fibrosis4 = () => {
    const [formData, setFormData] = useState({});
    const [calcualteFib4, setCalcualteFib4] = useState(0.0);
    const [required, setRequired] = useState({});


    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }

    const calculateFun = (e) => {
        e.preventDefault();

        if (formData.age > 0 && formData.platelet > 0 && formData.sgot > 0 && formData.sgpt > 0) {
            let val = (formData.age * formData.sgot) / (formData.platelet * Math.sqrt(formData.sgpt));
            setCalcualteFib4(val.toFixed(2));
        } else {
            setCalcualteFib4(0.0)
        }
    }


    return (<div className="main-content color-notations">
        <Grid fluid>
            <Card
                content={
                    <Row>
                        <Col md={4}>
                            <img src="../../../images/Fib4.png" class="w-100 mx-h-40" alt="FIB4" />
                        </Col>
                        <Col md={2}>
                            <ul className="nav calc-navs">
                                <li>
                                    <NavLink to={'bmi-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">BMI</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'eGFR-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">eGFR</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'homair-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">Homa IR</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">Insulin Sensitivity Indices [3-Points]</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">Insulin Sensitivity Indices [5-Points]</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'bone-age-calculator'} className="nav-link" >
                                        <span className="sidebar-mini">Bone Age Calculator</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'fib-4'} className="nav-link active" >
                                        <span className="sidebar-mini">Fib 4</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </Col>
                        <Col md={6}>
                            <Form horizontal>
                                <h4>Fibrosis 4 Calculator</h4>
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>

                                            <Col sm={12}>
                                                <Col componentClass={ControlLabel}>
                                                    Age (Years.) <span className="star">*</span>
                                                </Col>
                                                <FormControl type="number" autocomplete="off" name="age" id="age" value={formData.age || ''} onChange={e => { inputHandler(e) }} />
                                                <span className="errorMsg" style={{ display: (!formData.age && required.age === '') ? 'block' : 'none' }}>This is required field</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>

                                            <Col sm={12}>
                                                <Col componentClass={ControlLabel}>
                                                    SGOT/ AST <span className="star">*</span>
                                                </Col>
                                                <FormControl type="number" autocomplete="off" minLength="6" maxLength="20" name="sgot" id="sgot" value={formData.sgot || ''} onChange={e => { inputHandler(e) }} />
                                                <span className="errorMsg" style={{ display: (!formData.sgot && required.sgot === '') ? 'block' : 'none' }}>This is required field</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>

                                            <Col sm={12}>
                                                <Col componentClass={ControlLabel}>
                                                    SGPT/ALT  <span className="star">*</span>
                                                </Col>
                                                <FormControl type="number" autocomplete="off" minLength="6" maxLength="20" name="sgpt" id="sgpt" value={formData.sgpt || ''} onChange={e => { inputHandler(e) }} />
                                                <span className="errorMsg" style={{ display: (!formData.sgpt && required.sgpt === '') ? 'block' : 'none' }}>This is required field</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>

                                            <Col sm={12}>
                                                <Col componentClass={ControlLabel}>
                                                    Platelet (Thousand.) <span className="star">*</span>
                                                </Col>
                                                <FormControl type="number" autocomplete="off" minLength="6" maxLength="20" name="platelet" id="platelet" value={formData.platelet || ''} onChange={e => { inputHandler(e) }} />
                                                <span className="errorMsg" style={{ display: (!formData.platelet && required.platelet === '') ? 'block' : 'none' }}>This is required field</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-primary">Calculate</button>
                                            </Col>
                                        </FormGroup>
                                        {calcualteFib4 > 0 &&
                                            <FormGroup className="bmi-count">
                                                <Col componentClass={ControlLabel} sm={6}>
                                                    Fibrosie-4
                                                </Col>
                                                <Col componentClass={ControlLabel} sm={6}>
                                                    {calcualteFib4}
                                                </Col>
                                            </FormGroup>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                }
            />
        </Grid>
    </div>
    )
}

export default Fibrosis4
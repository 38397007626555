/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { foodListAction } from 'Admin/actions/food';
import { addFoodCartAction, updateFoodCartAction } from 'Admin/actions/ready_created_meal';
//import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
//import Loading from 'react-loading';
import { foodCategoryListAction } from 'Admin/actions/food_category';
import { languageListAction } from 'Admin/actions/language';
//import { appConstants } from 'Admin/_constants/app.constants';
import { treatmentListAction } from 'Admin/actions/treatment';
import { dietTimeListAction } from 'Admin/actions/diet_time';
import { regionListAction } from 'Admin/actions/region';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
    veg: 'required',
    mealCategory: 'required',
    // regionId: 'required',
    dietTimeId: 'required',
    treatmentPlanIds: 'required',
    priority: 'required',
};
let mess = {
    required: 'This field is required.',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();
class FoodCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodCategoryList: [],
            languageList: [],
            foodList: [],
            treatmentList: [],
            dietTimeList: [],
            formArr: [],
            regionList: [],
            foodCart: this.props.location.state.foodCart,
            foodCartItem: [],
            totalCalorie: "",
            foodCartDetail: "",
            isLoading: true,
            foodCardViewStatus: true,
            userRow: null,
            mealCategoryError: null,
            priorityError: null,
            priorityError: null,
            // regionError:null,
            searchText: "",
            regionId: this.props.location.state.UpdateData.regionId ? this.props.location.state.UpdateData.regionId : [],
            formData: {
                //  qty: "",
                treatmentPlanIds: this.props.location.state.UpdateData.treatmentPlanIds ? this.props.location.state.UpdateData.treatmentPlanIds : [],
                dietTimeId: this.props.location.state.UpdateData.dietTimeId ? this.props.location.state.UpdateData.dietTimeId : [],
                veg: this.props.location.state.UpdateData.veg ? this.props.location.state.UpdateData.veg : "veg",
                mealCategory: this.props.location.state.UpdateData.mealCategory ? this.props.location.state.UpdateData.mealCategory : "s",
                foodType: "group",
                priority: this.props.location.state.UpdateData.priority ? this.props.location.state.UpdateData.priority : 1,
                regionId: this.props.location.state.UpdateData.regionId ? this.props.location.state.UpdateData.regionId : [],
            },



            listParam: {
                foodCategoryId: "",
                direction: 'asc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "1"
            },
            limitForm: {},
        }
    }

    componentDidMount() {
        var foodCart = this.state.foodCart;
        var foodCartDetail = "";
        var foodCartDetailName = "";
        var group_data = [];
        let totalcal = 0;
        let total_carbohydrate = 0;
        let total_fat = 0;
        let total_protein = 0;
        var plus = "+";
        for (let i in foodCart) {
            for (let j in foodCart[i].foodCardList) {
                if (foodCart[i].foodCardList[j].veg == "non-veg") {
                    let foodType = this.state.formData;
                    foodType['veg'] = 'non-veg';
                    this.setState({ formData: foodType });
                }

                if (i == foodCart.length - 1 && j == foodCart[i].foodCardList.length - 1) {
                    plus = "";
                }
                var info_open = "";
                var info_close = "";
                var info_data = foodCart[i].foodCardList[j].info ? foodCart[i].foodCardList[j].info['1'] : "";
                if (info_data) {
                    info_open = "(";
                    info_close = ")";
                }
                var description_data = foodCart[i].foodCardList[j].description ? foodCart[i].foodCardList[j].description['1'] : "";
                var comma = ""
                if (description_data) {
                    comma = ",";
                }

                if (foodCart[i].foodCardList[j].newQty) {
                    group_data.push({ foodId: foodCart[i].foodCardList[j]._id, qty: foodCart[i].foodCardList[j].newQty });
                    totalcal = totalcal + (foodCart[i].foodCardList[j].calorie / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].newQty;
                    total_carbohydrate = total_carbohydrate + (foodCart[i].foodCardList[j].carbohydrate / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].newQty;
                    total_fat = total_fat + (foodCart[i].foodCardList[j].fat / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].newQty;
                    total_protein = total_protein + (foodCart[i].foodCardList[j].protein / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].newQty;
                    foodCartDetail = <div> {foodCartDetail} {foodCart[i].foodCardList[j].name['1']} {foodCart[i].foodCardList[j].newQty} {foodCart[i].foodCardList[j].servingSizeData[0] ? foodCart[i].foodCardList[j].servingSizeData[0].name['1'] : ""} {info_open} {info_data}  {comma} {description_data} {info_close} {plus} </div>
                } else {
                    group_data.push({ foodId: foodCart[i].foodCardList[j]._id, qty: foodCart[i].foodCardList[j].qty });
                    totalcal = totalcal + (foodCart[i].foodCardList[j].calorie / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].qty;

                    total_carbohydrate = total_carbohydrate + (foodCart[i].foodCardList[j].carbohydrate / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].qty;
                    total_fat = total_fat + (foodCart[i].foodCardList[j].fat / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].qty;
                    total_protein = total_protein + (foodCart[i].foodCardList[j].protein / foodCart[i].foodCardList[j].qty) * foodCart[i].foodCardList[j].qty;
                    foodCartDetail = <div>{foodCartDetail} {foodCart[i].foodCardList[j].name['1']} {foodCart[i].foodCardList[j].qty} {foodCart[i].foodCardList[j].servingSizeData[0] ? foodCart[i].foodCardList[j].servingSizeData[0].name['1'] : ""} {info_open} {info_data}  {comma} {description_data} {info_close} {plus}</div>


                }

                if (foodCart[i].foodCardList[j].name['1']) {
                    foodCartDetailName += " " + foodCart[i].foodCardList[j].name['1'];
                }

                if (foodCart[i].foodCardList[j].qty) {
                    if (foodCart[i].foodCardList[j].newQty) {
                        foodCartDetailName += " " + foodCart[i].foodCardList[j].newQty
                    } else {
                        foodCartDetailName += " " + foodCart[i].foodCardList[j].qty
                    }

                }

                if (foodCart[i].foodCardList[j].servingSizeData[0] && foodCart[i].foodCardList[j].servingSizeData[0].name['1']) {
                    foodCartDetailName += " " + foodCart[i].foodCardList[j].servingSizeData[0].name['1']
                }

                if (info_open) {
                    foodCartDetailName += " " + info_open
                }

                if (info_data) {
                    foodCartDetailName += " " + info_data
                }

                if (comma) {
                    foodCartDetailName += " " + comma
                }

                if (description_data) {
                    foodCartDetailName += " " + description_data
                }

                if (info_close) {
                    foodCartDetailName += " " + info_close
                }

                if (plus) {
                    foodCartDetailName += " " + plus + " "
                }

            }
        }




        let form_data = this.state.formData;
        form_data['calorie'] = totalcal.toFixed(2);
        form_data['carbohydrate'] = total_carbohydrate.toFixed(2);
        form_data['protein'] = total_protein.toFixed(2);
        form_data['fat'] = total_fat.toFixed(2);
        form_data['group'] = group_data;
        form_data['name'] = foodCartDetailName;

        this.setState({
            totalCalorie: totalcal.toFixed(2),
            foodCartDetail: foodCartDetail,
            formData: form_data
        });

        this.props.treatmentListAction(this.state.listParam);
        let data = this.state.limitForm;
        data['direction'] = "asc";
        data['order'] = "orderBy";
        data['offset'] = 0;
        data['limit'] = 10;
        this.props.dietTimeListAction(data);
        data['order'] = "createdAt";
        this.props.regionListAction(data);
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.isTreatmentList !== this.props.isTreatmentList) {
            this.setState({
                treatmentList: nextProps.TreatmentList.data.data,
                totalCount: nextProps.TreatmentList.data.count
            });
            let TreatmentListData = nextProps.TreatmentList.data.data;
            let treatmentPlanIds = this.state.formData.treatmentPlanIds;
            for (let j in treatmentPlanIds) {
                for (let i in TreatmentListData) {
                    var treatment_index = TreatmentListData[i].treatmentdata.map(function (el) {
                        return el._id;
                    }).indexOf(treatmentPlanIds[j]);
                    if (treatment_index != -1) {
                        TreatmentListData[i].treatmentdata[treatment_index].check_status = true;
                        this.setState({ treatmentList: TreatmentListData });
                    }
                }
            }
        }
        if (nextProps.isDietTimeList !== this.props.isDietTimeList) {
            let timeData = nextProps.DietTimeList.data.data;
            let dietTimeIdData = this.state.formData.dietTimeId;
            if (dietTimeIdData.length > 0) {
                for (let i in dietTimeIdData) {
                    var time_index = timeData.map(function (el) {
                        return el._id;
                    }).indexOf(dietTimeIdData[i]);
                    if (time_index != -1) {
                        timeData[time_index].check_status = true;
                    }
                }
            }
            this.setState({
                dietTimeList: nextProps.DietTimeList.data.data,
            });
        }

        let entry = [];
        if (nextProps.isRegionList !== this.props.isRegionList) {
            var regionIdData = this.state.regionId;
            nextProps.RegionList.data.data.map((key, index) =>

                entry.push({
                    "value": key._id,
                    "label": key.name
                })

            );
            if (regionIdData.length > 0) {
                let entryData = [];
                for (let i in regionIdData) {
                    var entry_index = entry.map(function (el) {
                        return el.value;
                    }).indexOf(regionIdData[i]);
                    if (entry_index != -1) {
                        entryData.push(entry[entry_index]);
                    }
                }
                this.setState({ region: entryData });
            }
            this.setState({
                regionList: entry,
            });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {
            this.setState({ languageList: nextProps.LanguageList.data });
        }

        if (nextProps.isAddFoodCart !== this.props.isAddFoodCart) {
            this.props.handleClick('success', nextProps.msg)
            this.props.history.push('/admin/food_group_list')
        }

        if (nextProps.isUpdateFoodCart !== this.props.isUpdateFoodCart) {
            this.props.handleClick('success', nextProps.msg)
            this.props.history.push('/admin/food_group_list')
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }



    handleChangeQty(data, e, categoryName) {
        var foodCart = this.state.foodCart;
        var localindex = foodCart.map(function (el) {
            return el.categoryName;
        }).indexOf(categoryName);
        if (localindex != -1) {
            let cardList = foodCart[localindex].foodCardList
            var index = cardList.map(function (el) {
                return el._id;
            }).indexOf(data._id);
            if (index != -1) {
                foodCart[localindex].foodCardList[index]['newQty'] = e.target.value;
                this.setState({ foodCart: foodCart })
                this.componentDidMount();
            }
        }
    }

    handleDeleteFood(data, categoryName) {
        var foodCart = this.state.foodCart;
        var localindex = foodCart.map(function (el) {
            return el.categoryName;
        }).indexOf(categoryName);
        if (localindex != -1) {
            let cardList = foodCart[localindex].foodCardList
            var index = cardList.map(function (el) {
                return el._id;
            }).indexOf(data._id);
            if (index != -1) {
                foodCart[localindex].foodCardList.splice(index, 1);
                if (foodCart[localindex].foodCardList.length == 0) {
                    foodCart.splice(localindex, 1);
                }
                this.setState({ foodCart: foodCart });
                this.componentDidMount();
            }
        }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    handleChangeDietTime(row) {
        let dietTimeId = this.state.formData.dietTimeId;
        let dietTime = this.state.dietTimeList;
        var localindex = dietTimeId.map(function (el) {
            return el;
        }).indexOf(row._id);
        if (localindex != -1) {
            dietTimeId.splice(localindex, 1);
        } else {
            dietTimeId.push(row._id)
        }
        for (let i in dietTime) {
            if (row._id == dietTime[i]._id) {
                if (dietTime[i].check_status) {
                    dietTime[i].check_status = false;
                } else {
                    dietTime[i].check_status = true;
                }
            }

        }
        this.setState({ dietTimeId: dietTimeId, dietTimeList: dietTime });

    }

    handleChangeSelectAll(value) {
        let selectall = this.state.selectall;
        if (value) {
            selectall = false;
            let field = this.state.formData;
            field['treatmentPlanIds'] = [];
            this.setState({ formData: field });
        } else {
            selectall = true;
        }

        let treatmentList = this.state.treatmentList;
        for (let i in treatmentList) {
            for (let j in treatmentList[i].treatmentdata) {
                if (selectall) {
                    treatmentList[i].treatmentdata[j].check_status = true;
                    let treatmentPlanIds = this.state.formData.treatmentPlanIds;
                    var localindex = treatmentPlanIds.map(function (el) {
                        return el;
                    }).indexOf(treatmentList[i].treatmentdata[j]._id);
                    if (localindex == -1) {
                        treatmentPlanIds.push(treatmentList[i].treatmentdata[j]._id)
                    }
                    let field = this.state.formData;
                    field['treatmentPlanIds'] = treatmentPlanIds;
                    this.setState({ formData: field });
                } else {
                    treatmentList[i].treatmentdata[j].check_status = false;
                }
            }
        }
        this.setState({ treatmentList: treatmentList });
        this.setState({ selectall: selectall })
    }

    handleChangeAddTreatment(row, category) {

        let treatmentPlanIds = this.state.formData.treatmentPlanIds;
        var localindex = treatmentPlanIds.map(function (el) {
            return el;
        }).indexOf(row._id);
        if (localindex != -1) {
            treatmentPlanIds.splice(localindex, 1);
        } else {
            treatmentPlanIds.push(row._id)
        }
        let field = this.state.formData;
        field['treatmentPlanIds'] = treatmentPlanIds;
        this.setState({ formData: field });
        //this.setState({ treatmentPlanIds: treatmentPlanIds });

        let checkStatus = row.check_status
        if (checkStatus) {
            checkStatus = false;
        } else {
            checkStatus = true;
        }
        let treatmentList = this.state.treatmentList;
        var category_index = treatmentList.map(function (el) {
            return el.name;
        }).indexOf(category);
        if (category_index != -1) {
            var treatment_index = treatmentList[category_index].treatmentdata.map(function (el) {
                return el._id;
            }).indexOf(row._id);
            if (treatment_index != -1) {
                treatmentList[category_index].treatmentdata[treatment_index].check_status = checkStatus;
                this.setState({ treatmentList: treatmentList })
            }
        }
    }

    handleRegion(value) {

        let multipleSelect = this.state.region;
        multipleSelect = value;
        this.setState({ region: multipleSelect });

        let formData = this.state.formData;
        let catArr = [];
        if (value && value.length) {
            value.map((key, i) => {
                catArr.push(key.value)
            });
        }
        formData.regionId = catArr;
        this.setState({ formData: formData });
    }

    handleChangeFood(e) {
        e.preventDefault();
        let index = e.target.selectedIndex;
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }

    handleChangeMealCategory(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    addFoodCart(evt) {
        evt.preventDefault();
        const _this = this;
        if (this.allValidate(false)) {
            let field = this.state.formData;
            // _this.setState({ showProcessing: true });
            if (this.props.location.state.UpdateData && this.props.location.state.UpdateData._id) {
                field.id = this.props.location.state.UpdateData._id;
                _this.props.updateFoodCartAction(field)
            } else {
                _this.props.addFoodCartAction(field);
            }

        }
        //validation.errors()
    }

    toggleButton(value) {
        this.setState({ foodCardViewStatus: value });
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }



    render() {
        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        let _this = this;
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        {this.state.foodCardViewStatus &&
                                            <Row >

                                                <Col md={12}>

                                                    <div className="clearfix"></div>
                                                    <div className="low-ff">

                                                        <Col md={8}>
                                                            {this.state.foodCart.map(function (foodCart) {

                                                                return (
                                                                    <Col md={12} >
                                                                        <Row>
                                                                            <Col componentClass={ControlLabel}>
                                                                                {foodCart.categoryName}
                                                                            </Col>
                                                                        </Row>
                                                                        {
                                                                            foodCart.foodCardList.map(function (data) {

                                                                                return (<Col md={4}><div className="inline-bb">
                                                                                    <div>
                                                                                        <div>Food name :  {data.name['1']}</div>
                                                                                        <div>Food type :  {data.veg}</div>
                                                                                        <div>Calorie :  {data.calorie}</div>
                                                                                        <div>QTY : <input type="number" className="small-Input" step="0.5" value={data.newQty} placeholder={data.qty} min="0" name={"qty_" + data._id} id={"qty_" + data._id} onChange={(event) => { _this.handleChangeQty(data, event, foodCart.categoryName) }} /> {data.servingSizeData[0] ? data.servingSizeData[0].name['1'] : ""}</div>
                                                                                        <div><a href="JavaScript:void(0)"
                                                                                            onClick={(event) => { _this.handleDeleteFood(data, foodCart.categoryName) }}>Remove</a></div>
                                                                                    </div>

                                                                                </div></Col>)

                                                                            })
                                                                        }
                                                                    </Col>)
                                                            })}
                                                            <div> Total calorie : {this.state.totalCalorie} kcal</div>
                                                        </Col>

                                                        <Col md={4}>
                                                            <div >
                                                                {this.state.foodCartDetail}
                                                            </div>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <button type="button" onClick={(event) => { _this.toggleButton(false) }} className="btn-fill btn-wd btn btn-primary">Next</button>&nbsp;
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }

                                        {!this.state.foodCardViewStatus &&

                                            <Row>
                                                <Col md={12}>
                                                    <Col componentClass={ControlLabel}>
                                                        {this.state.formData.veg == 'veg' && <h4 style={{ color: 'green' }}> Meal being created is vegetarian </h4>}
                                                        {this.state.formData.veg == 'non-veg' && <h4 style={{ color: 'red' }}> Meal being created is non-vegetarian </h4>}
                                                        {/* <h4  style={{ color: 'red'}}> Meal being created is {this.state.formData.veg == 'veg' ? 'vegetarian' :'non-vegetarian' } </h4>  */}
                                                    </Col>
                                                    <hr></hr>
                                                    <Col componentClass={ControlLabel}>
                                                        Eating timeslot <span className="star">*</span>
                                                    </Col>
                                                    <hr></hr>
                                                    <Col md={12}>
                                                        {this.state.dietTimeList.map(function (timeData) {
                                                            return (<Col md={4}>
                                                                <div><input type="checkbox" name={"time_" + timeData._id} id={"time_" + timeData._id} onChange={(event) => { _this.handleChangeDietTime(timeData) }} checked={timeData.check_status} />&nbsp; {timeData.name['1']}<br></br></div>
                                                            </Col>)

                                                        })
                                                        }
                                                    </Col>

                                                    <span className="errorMsg">
                                                        {this.state.dietTimeIdError}
                                                        {this.state.formArr.dietTimeId && validation.errors.first('dietTimeId')}</span>
                                                    <Row>
                                                        <Col componentClass={ControlLabel}>
                                                            Meal type  <span className="star">*</span>
                                                        </Col>
                                                        <hr></hr>
                                                        <div><input type="checkbox" name="selectall" value={this.state.selectall} id="selectall" onChange={(event) => { _this.handleChangeSelectAll(this.state.selectall) }} />&nbsp; Select all<br></br></div>
                                                        <Col md={12}>
                                                            {this.state.treatmentList.map(function (type) {
                                                                return (<Col md={4}><h5>{type.name}</h5>
                                                                    {
                                                                        type.treatmentdata.map(function (data) {
                                                                            return (<div><input type="checkbox" name={"treatment_" + data._id} id={"treatment_" + data._id} onChange={(event) => { _this.handleChangeAddTreatment(data, type.name) }} checked={data.check_status} />&nbsp; {data.name}<br></br></div>)
                                                                        })
                                                                    }

                                                                </Col>)
                                                            })
                                                            }

                                                        </Col>

                                                        <span className="errorMsg">
                                                            {this.state.treatmentPlanIdsError}
                                                            {this.state.formArr.treatmentPlanIds && validation.errors.first('treatmentPlanIds')}</span>

                                                    </Row>&nbsp;
                                                    <Form horizontal>

                                                        <FormGroup>
                                                            <Col componentClass={ControlLabel} sm={2} >
                                                                Region
                                                            </Col>
                                                            <Col sm={6} className="spcc">
                                                                <Select
                                                                    placeholder="Region"
                                                                    closeOnSelect={false}
                                                                    multi={true}
                                                                    name="regionId"
                                                                    value={this.state.region}
                                                                    key={this.state.region}
                                                                    options={this.state.regionList}
                                                                    onChange={(value) => { this.setState({ region: value }); this.handleRegion(value) }}
                                                                >
                                                                </Select>
                                                                {/* <span className="errorMsg">
                                                                    {this.state.regionError}
                                                                    {this.state.formArr.regionId && validation.errors.first('regionId')}</span> */}
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Col componentClass={ControlLabel} sm={2} >
                                                                Food type <span className="star">*</span>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <select className="form-control" name="veg" placeholder="Vegetarian" value={this.state.formData.veg} onChange={(event) => this.handleChangeFood(event)}>

                                                                    <option value={this.state.formData.veg}>{this.state.formData.veg == 'veg' ? 'Vegetarian' : "Non Vegetarian"}</option>
                                                                    {/* <option value="non-veg">Non Vegetarian</option> */}

                                                                </select>

                                                                <span className="errorMsg">
                                                                    {this.state.vegError}
                                                                    {this.state.formArr.veg && validation.errors.first('veg')}</span>

                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Col componentClass={ControlLabel} sm={2} >
                                                                Meal Cateogry <span className="star">*</span>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <select className="form-control" name="mealCategory" value={this.state.formData.mealCategory} placeholder="Meal Category" onChange={(event) => this.handleChangeMealCategory(event)}>
                                                                    <option value="s">Urban</option>
                                                                    <option value="l">Rural</option>
                                                                    <option value="h">Ready formula</option>

                                                                </select>
                                                                <span className="errorMsg">
                                                                    {this.state.mealCategoryError}
                                                                    {this.state.formArr.mealCategory && validation.errors.first('mealCategory')}</span>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Col componentClass={ControlLabel} sm={2} >
                                                                Priority <span className="star">*</span>
                                                            </Col>
                                                            <Col sm={6}>
                                                                <select className="form-control" name="priority" value={this.state.formData.priority} placeholder="priority" onChange={(event) => this.handleChangeMealCategory(event)}>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                </select>
                                                                <span className="errorMsg">
                                                                    {this.state.priorityError}
                                                                    {this.state.formArr.priority && validation.errors.first('priority')}</span>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Col componentClass={ControlLabel} sm={12} ></Col>
                                                            <Col sm={6}>
                                                                <button type="button" onClick={(event) => { _this.toggleButton(true) }} className="btn-fill btn-wd btn btn-primary">Prev</button>&nbsp;
                                                            </Col>
                                                            <Col sm={6}>

                                                                <button type="button" onClick={this.addFoodCart.bind(this)} className="btn-fill btn-wd btn btn-primary">Save</button>&nbsp;

                                                            </Col>
                                                        </FormGroup>


                                                    </Form>

                                                </Col>
                                            </Row>


                                        }

                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.readyCreatedMeal.message,

        FoodList: state.food.FoodList,
        isFoodList: state.food.isFoodList,
        isFoodListError: state.food.isFoodListError,

        FoodChangeStatus: state.food.FoodChangeStatus,
        isFoodChangeStatus: state.food.isFoodChangeStatus,
        isFoodChangeStatusError: state.food.isFoodChangeStatusError,

        FoodCategoryList: state.foodCategory.FoodCategoryList,
        isFoodCategoryList: state.foodCategory.isFoodCategoryList,
        isFoodCategoryListError: state.foodCategory.isFoodCategoryListError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

        TreatmentList: state.treatment.TreatmentList,
        isTreatmentList: state.treatment.isTreatmentList,
        isTreatmentListError: state.treatment.isTreatmentListError,

        DietTimeList: state.dietTime.DietTimeList,
        isDietTimeList: state.dietTime.isDietTimeList,
        isDietTimeListError: state.dietTime.isDietTimeListError,

        RegionList: state.region.RegionList,
        isRegionList: state.region.isRegionList,
        isRegionListError: state.region.isRegionListError,

        AddFoodCart: state.readyCreatedMeal.AddFoodCart,
        isAddFoodCart: state.readyCreatedMeal.isAddFoodCart,
        isAddFoodCartError: state.readyCreatedMeal.isAddFoodCartError,

        UpdateFoodCart: state.readyCreatedMeal.UpdateFoodCart,
        isUpdateFoodCart: state.readyCreatedMeal.isUpdateFoodCart,
        isUpdateFoodCartError: state.readyCreatedMeal.isUpdateFoodCartError,

    }
}
export default withRouter(connect(mapStateToProps, { foodListAction, foodCategoryListAction, languageListAction, treatmentListAction, dietTimeListAction, regionListAction, addFoodCartAction, updateFoodCartAction })(FoodCart));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Switch,
    Route
} from 'react-router-dom';

import Footer from 'Front/components/HomeFooter/HomeFooter.jsx';
import PagesHeader from 'Front/components/HomeHeader/HomeHeader.jsx';

// dinamically create pages routes
import homesRoutes from 'Front/routes/homes.jsx';
// import pagesRoutes from 'Front/routes/pages.jsx';

import bgImage from 'Front/assets/img/physician_technology.jpg';

class Homes extends Component {

    render() {
        return (
            <div>
                <PagesHeader />
                <div className="wrapper wrapper-full-page">
                    {/* <div className={"full-page"+this.getPageClass()} data-color="black" data-image={bgImage}> */}
                    <div className="content">
                        <Switch>
                            {
                                homesRoutes.map((prop, key) => {
                                    return (
                                        <Route path={prop.path} component={prop.component} key={key} />
                                    );
                                })
                            }
                        </Switch>
                    </div>
                    <Footer transparent />
                    <div className="full-page-background" style={{ backgroundImage: "url(" + bgImage + ")" }}></div>
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

export default Homes;

import React from 'react'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, Media } from 'react-bootstrap';

const ClinicRegistration = () => {
    return (
        <div className='clinic-registraion'>
            <Grid>
                <h2>How can we help you?</h2>
                <Row>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Doctor Name*"
                                type="text"
                                name="doctorName"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Specializations"
                                type="text"
                                name="specialization"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Degree*"
                                type="text"
                                name="degree"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="MCI Registration Number"
                                type="text"
                                name="mciNo"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Clinic Name*"
                                type="text"
                                name="clinicName"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Doctor Email*"
                                type="email"
                                name="email"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                placeholder="Phone*"
                                type="text"
                                name="phone"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="address"
                                placeholder="Address"
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="city"
                                placeholder="City"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <select
                                type='select'
                                name="state"
                                placeholder='state'
                                autoComplete="off"
                                className='form-control'
                            >
                                <option value="">Select State</option>
                                {/* {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })} */}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="pincode"
                                placeholder="PinCode"
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <select
                                type='select'
                                name="morningHourFrom"
                                placeholder='Morning Hour From*'
                                autoComplete="off"
                                className='form-control'
                            >
                                <option value="">Select Morning Hour From</option>
                                {/* {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })} */}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <select
                                type='select'
                                name="morningHourTill"
                                placeholder='Morning Hour Till*'
                                autoComplete="off"
                                className='form-control'
                            >
                                <option value="">Select Morning Hour Till</option>
                                {/* {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })} */}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <select
                                type='select'
                                name="eveningHourFrom"
                                placeholder='Evening Hour From*'
                                autoComplete="off"
                                className='form-control'
                            >
                                <option value="">Select Evening Hour From</option>
                                {/* {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })} */}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col lg={4} md={6}>
                        <FormGroup>
                            <select
                                type='select'
                                name="eveningHourTill"
                                placeholder='Evening Hour Till*'
                                autoComplete="off"
                                className='form-control'
                            >
                                <option value="">Select Evening Hour Till</option>
                            </select>
                        </FormGroup>
                    </Col>
                </Row>
            </Grid>
        </div>
    )
}

export default ClinicRegistration
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isClinicActiveList: false,
  isClinicActiveListError: false,
  clinicActiveList: false,

}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CLINIC_ACTIVE_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isClinicActiveList: false,
        isClinicActiveListError: false,
      }
    case actionTypes.GET_CLINIC_ACTIVE_LIST_SUCCESS:
      return {
        ...state,
        message: 'Clinic List successfully',
        clinicActiveList: action.payload.response,
        isClinicActiveList: true,
        isClinicActiveListError: false,
      }
    case actionTypes.GET_CLINIC_ACTIVE_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicActiveList: false,
        isClinicActiveListError: true
      }



    default:
      return state
  }
}

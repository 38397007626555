/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import login from "./login";
import clinic from "./clinic";
import home from "./home";
import patient from "./patient";
import master from "./master";
import diet from "./diet";
import settings from "./settings";
import commonRequest from "./commonRequest";
import report from "./report";
import language from "./language";
import comment from "./comment";
import taskScreen from "./taskScreen";
import emr from "./emr";

const reducer = combineReducers({
  router: routerReducer,
  login,
  clinic,
  patient,
  home,
  master,
  diet,
  settings,
  commonRequest,
  report,
  language,
  comment,
  taskScreen,
  emr,
});

export default reducer;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { withRouter } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import {
  Row, Col,
  Modal, Form, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import * as API from 'Front/api/emr';
import { Item } from 'react-bootstrap/lib/Breadcrumb';
import NotificationSystem from 'react-notification-system';
import { style } from "Admin/variables/Variables.jsx";
import { ACTIONS } from './Prescription';



const InstructionList = ({ setModal, patientId, ModalStatus, drugId, dispatch, drugInstruction, instrucrtionMode, parentComponent, setDrugInstructionList }) => {

  const [instructionsList, setInstructionsList] = useState([]);
  const [isInstructionList, setIsInstructionList] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isInstructionExist, setInstructionExist] = useState(false)
  const notificationSystem = useRef(null)
  const [arrInstruction, setArrInstruction] = useState([drugInstruction])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isCustomInstruction, setIsCustomInstruction] = useState(false)
  const [isFixedInstruction, setIsFixedInstruction] = useState(false)
  const [maxLength, setMaxLength] = useState(false)

  const showInstructionFun = () => {

    setInstructionsList([])
    setModal(true);
    setIsInstructionList(true)
    setFormData({})
    setErrors({})
    setIsDataLoaded(false)
    setArrInstruction(drugInstruction)
  }

  const inputHandler = (e) => {
    let saveFormData = formData;
    if (e.target.name === "instructionName") {
      if (e.target.value.length >= 300) {
        setMaxLength(true)
        return
      } else {
        setMaxLength(false)
        let str = e.target.value.replace(/\s+/g, " ").substring(0, 300);
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
      }
    }


    setInstructionExist(false)
    validate();
  }

  const validate = () => {

    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.instructionName && post.instructionName != '') {
      errArr.instructionName = '';
    } else { errArr.instructionName = 'error'; errflag = false; }
    setErrors({ ...errArr });
    return errflag;

  }

  const findInstruction = () => {

    instructionsList.map(instruction => {

      arrInstruction.map(item => {

        if (item.instructionId === instruction._id) {

          checkInstruction(item.instructionId)
        }
        setArrInstruction([...arrInstruction])
      })

    })
  }

  const checkInstruction = (instructionId) => {

    let chkInstructions = document.getElementsByName("instruction");
    for (var instruction of chkInstructions) {
      if (instructionId === instruction.getAttribute("id")) {
        instruction.checked = true;
      }
    }
  }


  const getInstructionList = async () => {
    let resp = await API.instructionList({ patientId: patientId, drugId: drugId });
    if (resp.data && resp.data.status === "Success") {
      setInstructionsList(resp.data.instructionData.data)
      setIsDataLoaded(true)

    }
  }

  const hideModal = () => {
    setArrInstruction(drugInstruction)
    setModal(false)
    setIsInstructionList(false)
    setInstructionExist(false)
    setIsFixedInstruction(false)
    setMaxLength(false)
  }

  const addOrDeleteInstruction = (e) => {

    if (e.target.checked) {
      let idx = arrInstruction.length - 1
      let arr = [];
      arr.push({
        "idx": idx + 1,
        "instructionId": e.target.id,
        "instructionName": e.target.value,
        "instructionType": "clinic"
      })

      setArrInstruction([...arr, ...arrInstruction])

    } else {
      deleteInstruction(e)
    }

  }

  const deleteInstruction = (e) => {
    let arr = arrInstruction;
    arr.map((itm, idx) => {
      if (itm.instructionId === e.target.id) {
        arr.splice(idx, 1);
        return;
      }
    })
    setArrInstruction([...arr])
  }



  const addCustomInstruction = async (e) => {
    e.preventDefault();
    let saveFormData = formData;
    saveFormData["instructionName"] = formData.instructionName?.trim();
    setFormData({ ...formData, ...saveFormData });
    if (validate()) {
      try {
        let resp = await API.addCustomInstruction({ instructionName: formData.instructionName, type: "clinic" });
        if (resp.data.status === "Success" && resp.data.statusCode === 200) {
          const notification = notificationSystem.current;
          let msg = "Instrrcution Save Sucessfully"
          notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
              {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
          });

          instructionsList.push({
            "_id": resp.data.customInstructionData._id,
            "instructionName": resp.data.customInstructionData.instructionName,
            "type": resp.data.customInstructionData.type

          })
          setInstructionsList([...instructionsList])

          let arr = [];
          let idx = arrInstruction.length - 1
          arr.push({
            "idx": idx + 1,
            "instructionId": resp.data.customInstructionData._id,
            "instructionName": resp.data.customInstructionData.instructionName,
            "instructionType": "clinic"
          })

          setArrInstruction([...arr, ...arrInstruction])
          setIsCustomInstruction(true)
          setIsFixedInstruction(false)
          setFormData({})
          setErrors({})
        }
      } catch (err) {
        setInstructionExist(true)
      }
    }
  }

  const addToPresciption = (e) => {

    e.preventDefault();
    let arr = arrInstruction;
    arr.map((itm, idx) => {
      if (itm.instructionType === "mandatory") {
        itm.instructionName = formData.instructionName
        itm.instructionType = "patient"
        //itm.instructionId = null
      }
    })

    setArrInstruction([...arr])
    let payloadData = {
      "instructions": arrInstruction,
      "drugId": drugId,
    }

    dispatch({ type: ACTIONS.ADD_INSTRUCTION, payload: payloadData })
    hideModal()
  }


  const submitHandler = (e) => {

    e.preventDefault();
    if (arrInstruction.length >= 0) {
      let payloadData = {
        "instructions": arrInstruction,
        "drugId": drugId
      }
      parentComponent === "Prescription" ? dispatch({ type: ACTIONS.ADD_INSTRUCTION, payload: payloadData }) : setDrugInstructionList([...arrInstruction])
      hideModal();
    } else {
      hideModal();
    }

  }

  useEffect(() => {
    if (isCustomInstruction)
      findInstruction()
    setIsCustomInstruction(false)
  }, [isCustomInstruction])

  useEffect(() => {
    if (isDataLoaded) {
      let str = ""
      drugInstruction.map(instruction => {
        if (instruction.instructionType === "mandatory") {
          str += instruction.instructionName
          setIsFixedInstruction(true)
        }
      })
      let saveFormData = formData;
      saveFormData["instructionName"] = str;
      setFormData({ ...formData, ...saveFormData });
      findInstruction()
    }
  }, [isDataLoaded])

  useEffect(() => {
    if (isInstructionList) {
      getInstructionList();
    }
  }, [isInstructionList])

  useEffect(() => {
    if (ModalStatus)
      showInstructionFun()
  }, [ModalStatus])




  return (
    <span >

      <Modal show={ModalStatus} onHide={hideModal} dialogClassName="modal-xl">
        <Modal.Header closeButton className="bg-thumbcolor">
          <div>
            <NotificationSystem ref={notificationSystem} style={style} />
          </div>
          <Modal.Title id="example-modal-sizes-title-lg">Instruction</Modal.Title>
        </Modal.Header>
        <Modal.Body className="suggetion-box-enlarge">
          <div className="suggestion-lists">
            <div className="selected-complaints">
              <h5>Instructions Selected</h5>
            </div>
            <div className="suggestion-list">

              {instructionsList && instructionsList.map(item => {

                return (<div class="form-check form-check-emr">
                  <input class="form-check-input" type="checkbox" value={item.instructionName} onChange={(e) => { addOrDeleteInstruction(e) }} subcatid="" name="instruction" id={item._id} />
                  <label class="form-check-label" for={item._id}>
                    {item.instructionName && item.instructionName.substring(0, 100)}
                  </label>
                </div>)

              })}
            </div>
            <div className='row'>
              <div className='col-sm-6' style={{ marginTop: '15px' }}>
                <form >
                  {isInstructionExist &&
                    <div style={{ 'color': 'red' }}><p>This instruction already exist.</p></div>
                  }
                  <FormGroup>
                    <Col sm={10} >
                      {maxLength &&
                        <span style={{ 'color': 'red' }}><p>Maximum 300 Characters are allowed.</p></span>
                      }
                      <FormControl rows="3" style={{ marginBottom: "10px" }} maxLength="300" componentClass="textarea" id="txtInstruction" name="instructionName" bsClass="form-control unmandt-f" value={formData.instructionName ? formData.instructionName : ''} placeholder="Instruction" onChange={e => { inputHandler(e); }} className={'' + (errors.instructionName ? errors.instructionName : '')} />
                    </Col>
                    <Col componentClass={ControlLabel} sm={12} >
                      <Button onClick={e => addCustomInstruction(e)} className="btn btn-fill btn-primary"> {parentComponent === "Prescription" ? "Add To Clinic" : "Add"}</Button>

                    </Col>
                  </FormGroup>

                </form>
              </div>
              <div className='col-sm-6 text-right'>
                <Button style={isFixedInstruction === false ? { "display": "none" } : { "display": "inline-block" }} onClick={e => addToPresciption(e)} className="btn btn-primary btn-fill">Save</Button>
                <Button style={isFixedInstruction === true ? { "display": "none" } : { "display": "block", "marginLeft": "auto" }} onClick={e => submitHandler(e)} className="btn btn-primary btn-fill">Save</Button>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </span>
  )
}

export default InstructionList
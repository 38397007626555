/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/diet_time'

/* TreatmentType list  */
export const dietTimeListRequest = () => ({
    type: actionTypes.GET_DIET_TIME_LIST_REQUEST
})
export const dietTimeListSuccess = (response) => ({
    type: actionTypes.GET_DIET_TIME_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const dietTimeListError = (error) => ({
    type: actionTypes.GET_DIET_TIME_LIST_ERROR,
    payload: {
        error
    }
})
export const dietTimeListAction = (data, search) => {
    return dispatch => {
        dispatch(dietTimeListRequest());
        return API.dietTimeList(data)
            .then(response => {
                dispatch(dietTimeListSuccess(response.data))
            })
            .catch(error => {
                dispatch(dietTimeListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const dietTimePlanListRequest = () => ({
    type: actionTypes.GET_DIET_TIME_PLAN_LIST_REQUEST
})
export const dietTimePlanListSuccess = (response) => ({
    type: actionTypes.GET_DIET_TIME_PLAN_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const dietTimePlanListError = (error) => ({
    type: actionTypes.GET_DIET_TIME_PLAN_LIST_ERROR,
    payload: {
        error
    }
})
export const dietTimePlanListAction = (data, search) => {
    return dispatch => {
        dispatch(dietTimePlanListRequest());
        return API.dietTimePlanList(data)
            .then(response => {
                dispatch(dietTimePlanListSuccess(response.data))
            })
            .catch(error => {
                dispatch(dietTimePlanListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


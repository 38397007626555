/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/report'


//-- List
export const reportListRequest = () => ({
    type: actionTypes.GET_REPORT_LIST_REQUEST
})
export const reportListSuccess = (response) => ({
    type: actionTypes.GET_REPORT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const reportListError = (error) => ({
    type: actionTypes.GET_REPORT_LIST_ERROR,
    payload: {
        error
    }
})
export const reportListAction = (data) => {
    return dispatch => {
        dispatch(reportListRequest())
        const FormData = {
            limit: data.limit,
            offset: data.offset,
            order: data.order,
            direction: data.direction,
            status: data.status,
            search: data.search,
            start_date: data.start_date,
            end_date: data.end_date
        }

        if(data?.getPatientCount!=undefined)
          FormData.getPatientCount=data.getPatientCount

         if(data?.getAppCount!=undefined)
           FormData.getAppCount=data.getAppCount

         if(data?.getDietCount!=undefined)
           FormData.getDietCount=data.getDietCount

         if(data?.getInsulinCount!=undefined)
           FormData.getInsulinCount=data.getInsulinCount

         if(data?.getEMRCount!=undefined)
           FormData.getEMRCount=data.getEMRCount

         if(data?.getGlucoseCount!=undefined)
           FormData.getGlucoseCount=data.getGlucoseCount

         if(data?.getReportCount!=undefined)
           FormData.getReportCount=data.getReportCount


        return API.reportList(FormData)
            .then(response => {
                dispatch(reportListSuccess(response.data))
            })
            .catch(error => {
                dispatch(reportListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}
//-- Data Upload List
export const dataUploadReportRequest = () => ({
    type: actionTypes.GET_DATA_UPLOAD_REPORT_REQUEST
})
export const dataUploadReportSuccess = (response) => ({
    type: actionTypes.GET_DATA_UPLOAD_REPORT_SUCCESS,
    payload: {
        response,
    }
})
export const dataUploadReportError = (error) => ({
    type: actionTypes.GET_DATA_UPLOAD_REPORT_ERROR,
    payload: {
        error
    }
})
export const dataUploadReportAction = (data) => {
    return dispatch => {
        dispatch(dataUploadReportRequest())
        // const FormData = {
        //     limit:data.limit,
        //     offset:data.offset,
        //     order:data.order,
        //     direction:data.direction,
        //     status:data.status,
        //     search:data.search,
        //     start_date:data.start_date,
        //     end_date:data.end_date
        // }
        return API.dataUploadReport(data)
            .then(response => {
                dispatch(dataUploadReportSuccess(response.data))
            })
            .catch(error => {
                dispatch(dataUploadReportError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

export const downloadReportListRequest = () => ({
    type: actionTypes.GET_DOWNLOAD_REPORT_LIST_REQUEST
})

export const downloadReportListSuccess = (response) => ({
    type: actionTypes.GET_DOWNLOAD_REPORT_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const downloadReportListError = (error) => ({
    type: actionTypes.GET_DOWNLOAD_REPORT_LIST_ERROR,
    payload: {
        error
    }
})

export const downloadReportListAction = (data) => {
    return dispatch => {
        dispatch(downloadReportListRequest())
        const FormData = {
            start_date: data.start_date,
            end_date: data.end_date
        }

        return API.downloadReportList(FormData)
            .then(response => {
                dispatch(downloadReportListSuccess(response.data))
            })
            .catch(error => {
                dispatch(downloadReportListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}



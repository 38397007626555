/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import { postRequest, postRequestWithToken } from "./helper"


export const signInUser = (data) => postRequest(data, 'login');
export const UserDetail = (data) => postRequestWithToken(data, 'user-detail');
export const forgotPassword = (data) => postRequest(data, 'forgot-password');
export const resetPassword = (data) => postRequest(data, 'reset-password'); 

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/


import React, { forwardRef } from "react";
import { useState, useEffect, useReducer, useRef } from "react";
import { withRouter, useParams, NavLink, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Form,
    FormControl,
    FormGroup,
    ControlLabel,
    InputGroup,
    Grid,
    Modal,
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "Front/elements/CustomButton/CustomButton.jsx";
import Card from "Front/components/Card/Card.jsx";
import * as API from "Front/api/emr";
import * as ApiHome from "Front/api/home"
import Breadcrum from "../../../../components/common/Breadcrum";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../../../actions/actionTypes'
import { taskScreenAction } from "Front/actions/taskScreen";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import Datetime from "react-datetime";
import Calendar from "../../../../components/Calendar.js";
import moment from "moment";
import Loading from "react-loading";
import LabTest from "./LabTest";
import EditIcon from "../../../../assets/img/icons/edit-ic.svg";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import LabTestList from "./LabTestList";
import Select from "react-select/lib/Select";

export const ACTIONS = {
    SET_PATIENT_LAB_TEST_REPORT: "set-patient-lab-test-report",
    SET_PATIENT_LAB_DATA: "set-patient-lab-data",
    ADD_LAB_TEST: "add-lab-test",
    UPDATE_LAB_TEST: "update-lab-test",
    RESET: "reset",

}

const LabTestDetail = forwardRef((props, ref) => {
    let { appointmentId, patientId, printingLanguage } = useParams();
    let userType = localStorage.getItem("userType");
    const emrData = useSelector(store => store.emr)
    const [alertMsg, setAlertMsg] = useState(null);
    const [generalInformation, setGeneralInformation] = useState({
        patientName: emrData?.vitalSignsData ? emrData.vitalSignsData.patientName : "",
        age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
        height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
        weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
        bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
        bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
        gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
        pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
        temperature: emrData?.vitalSignsData ? emrData.vitalSignsData.temperature : "",
        diabetesDuration: emrData?.diabetesDuration ? emrData.vitalSignsData.diabetesDuration : ""
    });
    const [duration, setDuration] = useState(emrData ? emrData.vitalSignsData.diabetesDuration ? emrData.vitalSignsData.diabetesDuration : "Year Month Day" : "Year Month Day");
    const [toggleDuration, setToggleDuration] = useState(emrData ? emrData.vitalSignsData.toggleDuration ? emrData.vitalSignsData.toggleDuration : false : false);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [start_date, setStart_Date] = useState("")
    const [end_date, setEnd_Date] = useState("")
    const [labData, setLabData] = useState({})
    const [reportDates, setReportDates] = useState([])
    const [isNoDataFound, setIsNoDataFound] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [labTestModal, setLabTestModal] = useState(false)
    const [patientLabData, setPatientLabData] = useState({})
    const [labTestList, setLabTestList] = useState([])
    const [mode, setMode] = useState("add")
    const notificationSystem = useRef(null);
    const [patinetData, setPatientData] = useState({});
    const [clearButtonClicked, setClearButtonClicked] = useState(false)
    const dispatchLabDetails = useDispatch();
    const [printColumnsCnt, setPrintColumnsCnt] = useState(3)
    const [startDateEmpty, setStartDateEmpty] = useState(true)
    const [endDateEmpty, setEndDateEmpty] = useState(true)
    const [isPageLoad, setIsPageLoad] = useState(true)
    const [labTestRangeModal, setLabTestRangeModal] = useState(false)
    const [getAllReports, setGetAllReports] = useState(false)
    const patientReduxData = useSelector((store) => store.taskScreen);
    const [hours, setHours] = useState("Select")
    const dispatchLabTestRedux = useDispatch();



    const initialState = {
        patientLabReportData: [],
        labData: {}
    };




    const getPatientGeneralInfo = async () => {
        if (emrData.vitalSignsData && Object.keys(emrData.vitalSignsData).length === 0) {
            let resp = await API.generalInformation({ patientId: patientId });

            if (resp?.data && resp?.data?.status === "Success") {
                setGeneralInformation({
                    patientName: resp?.data?.data?.name,
                    age: resp?.data?.data?.age,
                    height: resp?.data?.data?.height,
                    weight: resp?.data?.data?.weight,
                    bmi: resp?.data?.data?.bmi,
                    bp: resp?.data?.data?.bp,
                    gender: resp?.data?.data?.gender,
                    pulse: resp?.data?.data?.pulse,
                    temperature: resp?.data?.data?.temprature,
                });


                let strDuration = "";
                if (resp?.data?.data?.diabitic === "yes") {

                    if (
                        resp?.data?.data?.diabtiesDuration?.years === 0 &&
                        resp?.data?.data?.diabtiesDuration?.months === 0 &&
                        resp?.data?.data?.diabtiesDuration?.days === 0
                    ) {
                        strDuration = "Day Month Year";
                        //setToggleDuration(false);
                    }
                    //setToggleDuration(true);
                    if (resp?.data?.data?.diabtiesDuration?.years > 0) {
                        strDuration = resp?.data?.data?.diabtiesDuration?.years + " years ";
                    }
                    if (resp?.data?.data?.diabtiesDuration?.months > 0) {
                        strDuration += resp?.data?.data?.diabtiesDuration?.months + " months ";
                    }
                    if (resp?.data?.data?.diabtiesDuration?.days > 0) {
                        strDuration += resp.data.data.diabtiesDuration?.days + " days ";
                    }
                    setDuration(strDuration);
                }

            }
        }
    };

    const getPrintData = async () => {
        let resp = await API.prescriptionPrint({ patientId: patientId, appointmentId: appointmentId });
        if (resp.data.status === "Success" && resp.data.statusCode === 200) {
            if (resp.data.patientData.length > 0)
                setPatientData(resp.data?.patientData[0]);
            if (resp.data?.clinicData[0].prescription_print_margin_left < 15)
                setPrintColumnsCnt(5)
            // if (props)
            //     props.setPrintDataFun(resp.data, reportDates)
        }
    }

    const handleSearch = (e) => {
        setHours(e.target.value)

        // if (startDate === "") {
        //     setStartDateEmpty(true)
        //     return
        // }
        // if (endDate === "") {
        //     setEndDateEmpty(true)
        //     return
        // }
        let start_date = new Date()
        let end_date = new Date()
        let allReports = false
        switch (e.target.value) {
            case "72": {
                let d = new Date();
                d.setDate(d.getDate() - 2);
                start_date = d;
                break;
            }

            case "168": {
                let d = new Date();
                d.setDate(d.getDate() - 6);
                start_date = d;
                break;
            }

            case "360": {
                let d = new Date();
                d.setDate(d.getDate() - 14);
                start_date = d;
                break;
            }

            case "720": {
                let d = new Date();
                d.setDate(d.getDate() - 29);
                start_date = d;
                break;
            }

            case "all": {
                allReports = true;
                break;
            }

        }

        setGetAllReports(allReports)
        setReportDates([])
        dispatch({ type: ACTIONS.RESET });
        setClearButtonClicked(false)
        setStart_Date(moment(start_date).format('YYYY-MM-DD'))
        setEnd_Date(moment(end_date).format('YYYY-MM-DD'))
        if (e.target.value === "recent")
            getPatientLabTestDetail(moment(start_date).format('YYYY-MM-DD'), moment(end_date).format('YYYY-MM-DD'), false, true)
        else getPatientLabTestDetail(moment(start_date).format('YYYY-MM-DD'), moment(end_date).format('YYYY-MM-DD'), allReports)
    }

    const getFilteredLabData = (reportsArr, dates) => {
        let result = {}
        let obj = {}

        for (let k in labTestList) {
            obj = {}
            let arr = reportsArr.filter(
                (obj) =>
                    obj._id ===
                    labTestList[k]._id
            )

            for (let d in dates) {
                obj[dates[d]] = ""
                for (let j in arr) {
                    obj[moment(arr[j].testReportDate).format("DD-MM-YY")] = arr[j].value
                }

            }

            const isEmpty = !Object.values(obj).some(x => (x !== null && x !== ''));

            if (!isEmpty)
                result[labTestList[k]._id] = obj

        }
        return result
    }



    const getPatientLabTestDetail = async (start_date, end_date, isAllReports, isMostRecent) => {
        setIsNoDataFound(false)
        setIsLoading(true);
        let res = {}
        if (isMostRecent)
            res = await ApiHome.getPatientLabTestReport({ patientId: patientId, getSingleRecord: true })
        else if (isAllReports)
            res = await ApiHome.getPatientLabTestReport({ patientId: patientId, allRecord: isAllReports })
        else res = await ApiHome.getPatientLabTestReport({ patientId: patientId, start_date: start_date, end_date: end_date })
        if (res.data.status === "Success" && res.data.statusCode === 200) {
            if (isPageLoad)
                setIsPageLoad(false)
            dispatchLabDetails({ type: actionTypes.SET_LAB_DATA, payload: res.data.data });
            setIsLoading(false);
            if (res.data.data && res.data.data.length) {
                setIsNoDataFound(false)
                //setPatientLabTestReport([...res.data.data])

                dispatch({ type: ACTIONS.SET_PATIENT_LAB_TEST_REPORT, payload: res.data.data });
                let arr = res.data.data
                let dateArr = []
                let tempLabData = []
                let filtereData = {}
                if (arr.length > 0) {
                    for (let i in arr) {
                        dateArr.push(moment(arr[i].testReportDate).format("DD-MM-YY"))
                        let reportArr = arr[i].patientTestReport
                        for (let j in reportArr) {
                            filtereData = getFilteredData(reportArr[j], arr[i].testReportDate)
                            tempLabData.push(filtereData)
                        }

                    }

                }

                let result = getFilteredLabData(tempLabData, dateArr)

                dispatch({ type: ACTIONS.SET_PATIENT_LAB_DATA, payload: result });
                setReportDates([...dateArr])
                //setLabData({ ...result })
            } else {
                setIsNoDataFound(true)
                dispatch({ type: ACTIONS.SET_PATIENT_LAB_DATA, payload: {} });
            }
        }
    }

    useEffect(() => {
        getPatientGeneralInfo()
        getLabTestList()
    }, [])

    useEffect(() => {
        if (isPageLoad && labTestList.length > 0) {
            getPatientLabTestDetail(start_date, end_date, false, true)
        } else if (labTestList.length > 0 && !getAllReports)
            getPatientLabTestDetail(start_date, end_date, false)

    }, [labTestList])



    const getFilteredData = (obj, reportDate) => {
        let res = {}
        let localData = {}
        var localindex_index = labTestList
            .map(function (el) {
                return el._id;
            })
            .indexOf(obj._id);
        if (localindex_index != -1) {
            localData = labTestList[localindex_index];
        }
        if (localData)
            res = { label: obj.name, value: obj.value, testReportDate: reportDate, key: obj.name, _id: obj._id }

        return res

    }


    // const handleStartDate = (date) => {
    //     console.log(date, "hhhhhhhhhhhhhh")
    //     setStartDateEmpty(false)
    //     let selectedDate = moment(date).format('YYYY-MM-DD');
    //     setStart_Date(selectedDate)
    //     setStartDate(moment(date).format('DD-MM-YYYY'))
    // }

    // const handleEndDate = (date) => {
    //     setEndDateEmpty(false)
    //     let selectedDate = moment(date).format('YYYY-MM-DD');
    //     setEnd_Date(selectedDate)
    //     setEndDate(moment(date).format('DD-MM-YYYY'))
    // }

    // const valid = (current) => {
    //     let today = moment().format('YYYY-MM-DD');
    //     current = moment(current).format('YYYY-MM-DD');
    //     return today >= current;
    // }

    // const valid2 = (current) => {
    //     let today = moment().format('YYYY-MM-DD');
    //     current = moment(current).format('YYYY-MM-DD');
    //     let startDate = moment(start_date).format('YYYY-MM-DD');
    //     return today >= current && startDate <= current;
    // }

    const getValue = (key) => {
        let obj = state.labData[key]
        return obj
    }

    const getNameUnitRange = (id) => {
        let localData = {}
        var localindex_index = labTestList
            .map(function (el) {
                return el._id;
            })
            .indexOf(id);
        if (localindex_index != -1) {
            localData = labTestList[localindex_index];
        }

        return `${props.showPatientInfo ? localData.shortName != "" ? localData.shortName : localData.name : localData.name} ${localData.unit ? `(${localData.unit})` : ""} `

    }

    const getReferenceRange = (id, eleId) => {
        let localData = {}
        var localindex_index = labTestList
            .map(function (el) {
                return el._id;
            })
            .indexOf(id);
        if (localindex_index != -1) {
            localData = labTestList[localindex_index];
        }

        let html = "";
        if (localData?.testReportRangeClinic?.length > 0) {
            html = (
                <span>
                    {localData?.testReportRangeClinic &&
                        localData?.testReportRangeClinic?.map((obj, key) => {
                            return <><span style={{ whiteSpace: 'nowrap' }}> {obj?.gender != "" ? obj?.gender === "all" ? "" : `For ${obj.gender} ` : ""}
                                {/* {obj?.ageFrom ? `${obj.ageFrom} - ${obj?.ageTo ? obj.ageTo : ""}` : obj?.ageTo ? `below ${obj.ageTo}` : ""} */}
                                {obj?.ageFrom != null ? (obj.ageFrom !== "" && (obj.ageTo === null || obj.ageTo === "")) ? ` More Than ${obj.ageFrom} ` : ` ${obj.ageFrom} - ${obj.ageTo} ` : obj?.ageTo ? ` UpTo ${obj.ageTo} ` : ""}
                                {obj?.ageType ? obj.ageType : ""}
                                {obj?.rangeFrom != null ? (obj.rangeFrom !== "" && (obj.rangeTo === null || obj.rangeTo === "")) ? ` More Than ${obj.rangeFrom} ` : (obj.rangeFrom === "" && obj.rangeTo !== "") ? ` UpTo ${obj.rangeTo} ` : ` ${obj.rangeFrom} - ${obj.rangeTo} ` : obj?.rangeTo ? ` UpTo ${obj.rangeTo} ` : ""}</span>
                                <br />
                            </>;
                        })}
                </span>
            );
        } else if (localData?.testReportRangeAdmin?.length > 0) {
            html = (
                <span className="">
                    {localData?.testReportRangeAdmin &&
                        localData?.testReportRangeAdmin?.map((obj, key) => {
                            return <><span style={{ whiteSpace: 'nowrap' }}> {obj?.gender != "" ? obj?.gender === "all" ? "" : `For ${obj.gender} ` : ""}
                                {/* {obj?.ageFrom ? `${obj.ageFrom} - ${obj?.ageTo ? obj.ageTo : ""}` : obj?.ageTo ? `below ${obj.ageTo}` : ""} */}
                                {obj?.ageFrom != null ? (obj.ageFrom !== "" && (obj.ageTo === null || obj.ageTo === "")) ? ` More Than ${obj.ageFrom} ` : ` ${obj.ageFrom} - ${obj.ageTo} ` : obj?.ageTo ? ` UpTo ${obj.ageTo} ` : ""}
                                {obj?.ageType ? obj.ageType : ""}
                                {obj?.rangeFrom != null ? (obj.rangeFrom !== "" && (obj.rangeTo === null || obj.rangeTo === "")) ? ` More Than ${obj.rangeFrom} ` : ` ${obj.rangeFrom} - ${obj.rangeTo} ` : obj?.rangeTo ? ` UpTo ${obj.rangeTo} ` : ""}</span>
                                <br />
                            </>;
                        })}
                </span>
            );

        }
        return html;
    }

    const validateRange = (id, value) => {
        let localData = {}
        let origionalValue = value
        var localindex_index = labTestList
            .map(function (el) {
                return el._id;
            })
            .indexOf(id);
        if (localindex_index != -1) {
            localData = labTestList[localindex_index];
        }


        value = value != "" ? parseFloat(value) : value
        let diff_date = new Date(new Date() - new Date(patientReduxData.visitData.dob));
        let num_years = diff_date.getUTCFullYear() - 1970;
        let num_months = 0;
        let d1 = new Date(patientReduxData.visitData.dob)
        let d2 = new Date()
        let ff = d2.getTime() - d1.getTime()
        let num_days = Math.ceil(ff / (1000 * 3600 * 24));
        num_months = num_days < 30 ? 0 : (num_days / 30).toFixed(4);

        //console.log(num_days, num_months, num_years, "uuuu")
        let flag = value === "" ? false : isNaN(value) ? true : false;
        let patientGender = patientReduxData.visitData.gender === "Male" ? "male" : "female"
        if (!flag && localData?.testReportRangeClinic?.length > 0) {
            let arr = localData?.testReportRangeClinic
            for (let i in arr) {
                if ((arr[i].ageFrom === "" || arr[i].ageFrom === null) && (arr[i].ageTo === "" || arr[i].ageTo === null)) {
                    if (arr[i].gender === "") {
                        if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null)) {
                            if ((parseFloat(arr[i].rangeTo) < value)) {
                                flag = true
                                break;
                            }
                        } else if ((arr[i].rangeTo === "" || arr[i].rangeTo === null)) {
                            if ((value <= parseFloat(arr[i].rangeFrom))) {
                                flag = true
                                break;
                            }

                        } else if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null) && (arr[i].rangeTo === "" || arr[i].rangeTo === null))
                            continue
                        else if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                            flag = true
                            break;
                        }

                    } else if (arr[i].gender === patientGender) {
                        if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null)) {
                            if ((parseFloat(arr[i].rangeTo) < value)) {
                                flag = true
                                break;
                            }
                        } else if ((arr[i].rangeTo === "" || arr[i].rangeTo === null)) {
                            if ((value <= parseFloat(arr[i].rangeFrom))) {
                                flag = true
                                break;
                            }

                        } else if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null) && (arr[i].rangeTo === "" || arr[i].rangeTo === null))
                            continue
                        else if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                            flag = true
                            break;
                        }
                    }
                } else {
                    if (arr[i].gender === "") {
                        if (arr[i].ageType === "month") {
                            if ((parseFloat(arr[i].ageFrom) <= num_months && parseFloat(arr[i].ageTo) >= num_months)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= parseFloat(value) && parseFloat(arr[i].rangeTo) >= parseFloat(value))) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                        if (arr[i].ageType === "Year" || arr[i].ageType === "") {
                            if ((parseInt(arr[i].ageFrom) <= num_years && parseInt(arr[i].ageTo) >= num_years)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }


                    } else if (arr[i].gender === patientGender) {

                        if (arr[i].ageType === "month") {

                            if ((parseFloat(arr[i].ageFrom) <= num_months && parseFloat(arr[i].ageTo) >= num_months)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                        if (arr[i].ageType === "Year" || arr[i].ageType === "") {
                            if ((parseInt(arr[i].ageFrom) <= num_years && parseInt(arr[i].ageTo) >= num_years)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                    }
                }
            }

        } else if (!flag && localData?.testReportRangeAdmin?.length > 0) {

            let arr = localData?.testReportRangeAdmin
            for (let i in arr) {
                // console.log(arr[i], "testttttt")
                if ((arr[i].ageFrom === "" || arr[i].ageFrom === null) && (arr[i].ageTo === "" || arr[i].ageTo === null)) {
                    if (arr[i].gender === "") {
                        if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null)) {
                            if ((parseFloat(arr[i].rangeTo) < value)) {
                                flag = true
                                break;
                            }
                        } else if ((arr[i].rangeTo === "" || arr[i].rangeTo === null)) {
                            if ((value <= parseFloat(arr[i].rangeFrom))) {
                                flag = true
                                break;
                            }

                        } else if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null) && (arr[i].rangeTo === "" || arr[i].rangeTo === null))
                            continue
                        else if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                            flag = true
                            break;
                        }

                    } else if (arr[i].gender === patientGender) {
                        if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null)) {
                            if ((parseFloat(arr[i].rangeTo) <= value)) {
                                flag = true
                                break;
                            }
                        } else if ((arr[i].rangeTo === "" || arr[i].rangeTo === null)) {
                            // console.log(arr[i], value, value < parseFloat(arr[i].rangeFrom), "hdl")
                            if ((value <= parseFloat(arr[i].rangeFrom))) {
                                flag = true
                                break;
                            }

                        } else if ((arr[i].rangeFrom === "" || arr[i].rangeFrom === null) && (arr[i].rangeTo === "" || arr[i].rangeTo === null))
                            continue
                        else if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                            flag = true
                            break;
                        }
                    }
                } else {
                    if (arr[i].gender === "") {
                        if (arr[i].ageType === "month") {
                            //console.log(num_months, arr[i], (parseFloat(arr[i].ageFrom) <= parseFloat(num_months) && parseFloat(arr[i].ageTo) >= parseFloat(num_months)), "serum_month")
                            if ((parseFloat(arr[i].ageFrom) <= parseFloat(num_months) && parseFloat(arr[i].ageTo) >= parseFloat(num_months))) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                        if (arr[i].ageType === "Year" || arr[i].ageType === "") {
                            // console.log(num_years, arr[i], (parseInt(arr[i].ageFrom) <= num_years && parseInt(arr[i].ageTo) >= num_years), "serum_year")
                            if ((parseInt(arr[i].ageFrom) <= num_years && parseInt(arr[i].ageTo) >= num_years)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }


                    } else if (arr[i].gender === patientGender) {
                        if (arr[i].ageType === "month") {
                            if ((parseFloat(arr[i].ageFrom) <= num_months && parseFloat(arr[i].ageTo) >= num_months)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                        if (arr[i].ageType === "Year" || arr[i].ageType === "") {
                            if ((parseFloat(arr[i].ageFrom) <= num_years && parseFloat(arr[i].ageTo) >= num_years)) {
                                if (!(parseFloat(arr[i].rangeFrom) <= value && parseFloat(arr[i].rangeTo) >= value)) {
                                    flag = true
                                    break;
                                }
                            }
                        }
                    }
                }
            }

        }



        let strColor = flag ? "red" : "black"
        //console.log(value, origionalValue, "pppppp")
        return <span class={flag ? "testValueNotInRange" : ""} style={{ color: strColor }}>{isNaN(value) ? origionalValue : value}</span>

    }

    const handleLabTest = () => {
        setPatientLabData({})
        setMode("add")
        setLabTestModal(true)
        setClearButtonClicked(false)
    }

    const onDismissLabTests = (reportDate, id, mode, reportData) => {

        if (mode === "add") {
            if (isNoDataFound)
                setIsNoDataFound(false)
            let payload = {}
            payload._id = id
            payload.testReportDate = reportDate
            payload.patientTestReport = reportData
            payload.patientId = patientId
            dispatch({ type: ACTIONS.ADD_LAB_TEST, payload: payload });
            //setStart_Date("")
            //setStartDate("")
            //setEnd_Date("")
            //setEndDate("")
            setTimeout(() => {
                successAlert("Data Added Successfully")
            }, 200)
        } else {
            let payload = {}
            payload._id = id
            payload.testReportDate = reportDate
            payload.patientTestReport = reportData
            dispatch({ type: ACTIONS.UPDATE_LAB_TEST, payload: payload });
            setTimeout(() => {
                successAlert("Data Updated Successfully")
            }, 200)
        }

        setLabTestModal(false)

    }

    const getLabTestList = async () => {
        let res = await ApiHome.labTestList()
        if (res?.data?.status === "Success" && res?.data?.statusCode === 200) {
            if (res?.data?.testReportList && res?.data?.testReportList?.data?.length > 0) {
                let tempList = res?.data?.testReportList?.data
                for (let i in tempList) {
                    let obj = tempList[i]
                    obj.isEmpty = false
                    tempList[i] = obj
                }
                setLabTestList([...tempList])
            }
        }
    }

    const handleReportDate = (date, idx) => {
        let localData = {}
        //console.log(patientLabTestReport, date, "ooooooooo")
        let data = state?.patientLabReportData
        var localindex_index = data
            .map(function (el) {
                return moment(el.testReportDate).format("DD-MM-YY");
            })
            .indexOf(date);
        if (localindex_index != -1) {
            localData = data[localindex_index];

            if (idx + 1 <= printColumnsCnt) {
                return (
                    <th id={idx} style={{ whiteSpace: 'nowrap' }}>
                        {date}
                        {/* {!props.showPatientInfo ?
                            <img
                                src={EditIcon}
                                alt="edit"
                                onClick={(e) =>
                                    editLabTest(localData)
                                }
                                style={{ "cursor": "pointer" }}
                                id={`img-icon-${idx}`}
                            /> : <></>} */}
                    </th>)
            } else if (!props?.showPatientInfo) {
                return (
                    <th id={idx} style={{ whiteSpace: 'nowrap' }}>
                        {date}
                        {/* {!props.showPatientInfo ?
                            <img
                                src={EditIcon}
                                alt="edit"
                                onClick={(e) =>
                                    editLabTest(localData)
                                }
                                style={{ "cursor": "pointer" }}
                                id={`img-icon-${idx}`}
                            /> : <></>} */}
                    </th>)
            }

        } else return <></>

    }

    const editLabTest = (data) => {

        setMode("edit")
        setPatientLabData({ ...data })
        setLabTestModal(true)
    }

    function reducer(state, action) {
        switch (action?.type) {
            case ACTIONS.SET_PATIENT_LAB_TEST_REPORT: {
                return {
                    ...state,
                    patientLabReportData: [...action?.payload],

                };
            }

            case ACTIONS.SET_PATIENT_LAB_DATA: {
                return {
                    ...state,
                    labData: { ...action?.payload },

                };
            }

            case ACTIONS.ADD_LAB_TEST: {
                let arr = state?.patientLabReportData
                arr.push(action?.payload)
                arr = arr.sort(function (a, b) {
                    return new Date(b.testReportDate) - new Date(a.testReportDate);
                });

                let dateArr = []
                let tempLabData = []
                let filtereData = {}
                for (let i in arr) {
                    dateArr.push(moment(arr[i]?.testReportDate).format("DD-MM-YY"))
                    let reportArr = arr[i]?.patientTestReport
                    for (let j in reportArr) {
                        filtereData = getFilteredData(reportArr[j], arr[i]?.testReportDate)
                        tempLabData.push(filtereData)
                    }

                }

                setReportDates([...dateArr])
                let result = getFilteredLabData(tempLabData, dateArr)
                dispatchLabDetails({ type: actionTypes.SET_LAB_DATA, payload: arr });
                return {
                    ...state,
                    patientLabReportData: [...arr],
                    labData: { ...result }
                };
            }

            case ACTIONS.UPDATE_LAB_TEST: {
                let arr = state?.patientLabReportData
                let tempLabData = []
                let filtereData = {}
                let dateArr = []
                if (!reportDates.includes(moment(action?.payload?.testReportDate).format("DD-MM-YY"))) {
                    arr.push(action?.payload)
                    arr = arr.sort(function (a, b) {
                        return new Date(b.testReportDate) - new Date(a.testReportDate);
                    });
                }
                for (let i in arr) {
                    dateArr.push(moment(arr[i]?.testReportDate).format("DD-MM-YY"))
                    if (arr[i]._id === action.payload._id) {
                        arr[i].patientTestReport = action.payload.patientTestReport
                    }
                    let reportArr = arr[i].patientTestReport
                    for (let j in reportArr) {
                        filtereData = getFilteredData(reportArr[j], arr[i].testReportDate)
                        tempLabData.push(filtereData)
                    }

                }
                setReportDates([...dateArr])

                let result = getFilteredLabData(tempLabData, dateArr)



                return {
                    ...state,
                    patientLabReportData: [...arr],
                    labData: { ...result }
                };
            }

            case ACTIONS.RESET: {
                return {
                    patientLabReportData: [],
                    labData: {}
                };
            }
        }
    }

    const successAlert = (msg) => {
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (
                <span
                    data-notify="icon"
                    className="pe-7s-check"
                    style={{
                        fontSize: "30px",
                        color: "#fff",
                        padding: "0px !important",
                        top: "0% !important",
                    }}
                ></span>
            ),
            message: <div>{msg}</div>,
            level: "success",
            position: "tr",
            autoDismiss: 2,
        });
    }

    const clearData = () => {
        //setStart_Date("")
        //setStartDate("")
        //setEnd_Date("")
        //setEndDate("")
        //setStartDateEmpty(true)
        //setEndDateEmpty(true)
        setReportDates([])
        dispatch({ type: ACTIONS.RESET });
        dispatchLabDetails({ type: actionTypes.SET_LAB_DATA, payload: [] });
        setClearButtonClicked(true)
    }




    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        getPrintData()
    }, [props.showPatientInfo])


    const onDismissLabTestDetail = (list) => {
        getPatientLabTestDetail(start_date, end_date, getAllReports)
        setLabTestList([...list])
    }


    useEffect(() => {
        //update lab Data
        //update lab list in redux
        if (emrData?.isLabRangeListChange) {
            setLabTestList([...emrData?.labTestList])
        }
    }, [emrData?.isLabRangeListChange])

    // useEffect(() => {
    //     if (!isNoDataFound && reportDates.length > 0) {
    //         let ele = document.getElementById("btnAddEditLabRange")
    //         if (ele != undefined) {
    //             ele.classList.add("is-data")
    //         } else ele.classList.remove("is-data")
    //         //is-data
    //     }
    // }, [reportDates])



    const renderNavTabs = () => {
        let html = ""
        if (userType === "doctor") {
            html = <>
                <li>
                    <NavLink
                        to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Vital Signs</span>
                    </NavLink>
                </li>
                <li className="current">
                    <NavLink
                        to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Lab Tests</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Investigation</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
                        className="nav-link"
                    >
                        <span className="sidebar-mini">Complaint</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
                        className="nav-link"
                    >
                        <span className="sidebar-mini">Diagnosis</span>
                    </NavLink>
                </li>
                {/* <li><a>Investigation Advised</a></li>
      <li><a>All Investigations</a></li> */}
                <li>
                    <NavLink
                        to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Prescription</span>
                    </NavLink>
                </li>
            </>
        }

        if (userType === "receptionist") {
            if (localStorage.getItem("is_prescription_edit_by_receptionist") === "yes") {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Prescription</span>
                        </NavLink>
                    </li>
                </>
            } else {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                </>
            }
        }

        if (userType === "educator") {
            if (localStorage.getItem("is_prescription_edit_by_educator") === "yes") {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Prescription</span>
                        </NavLink>
                    </li>
                </>
            } else {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                </>
            }
        }

        return html;

    }



    return (
        <div className="main-content emr-wrapper dashboard-2-0">
            <span ref={ref}>
                <NotificationSystem ref={notificationSystem} style={style} />
                {userType === "doctor" &&
                    <div id="divBreadCrumb"> <Breadcrum /></div>
                }
                <div id="divGeneralInformation" className="general-information">
                    <div className="card_header">
                        <div className="card_header_inner">
                            <ul className="patient_details">
                                <li>
                                    <a className="user-info-img">
                                        {/* <span><img src={GeneralUsrImg} alt="" />  </span> */}
                                        {generalInformation.patientName}
                                    </a>
                                </li>
                                <span className="flex">
                                    <li>
                                        <a>
                                            <span>Age: </span> {generalInformation.age} Y /{" "}
                                            {generalInformation.gender === "Male" ? " M" : " F"}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>
                                                Height{generalInformation.height > 0 ? ":" : ""}{" "}
                                            </span>{" "}
                                            {generalInformation.height > 0
                                                ? generalInformation.height
                                                : ""}{" "}
                                            {generalInformation.height > 0 ? "CM" : ""}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>
                                                Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                                            </span>{" "}
                                            {generalInformation.weight > 0
                                                ? generalInformation.weight
                                                : ""}{" "}
                                            {generalInformation.weight > 0 ? "KG" : ""}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                                            {generalInformation.bmi > 0
                                                ? generalInformation.bmi
                                                : ""}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>
                                                BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                                            </span>{" "}
                                            {generalInformation.bp !== "/"
                                                ? generalInformation.bp
                                                : ""}{" "}
                                            {generalInformation.bp !== "/" ? " mmHg" : ""}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>
                                                Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                                            </span>{" "}
                                            {generalInformation.pulse > 0
                                                ? generalInformation.pulse
                                                : ""}{" "}
                                            {generalInformation.pulse > 0 ? "/min" : ""}
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <span>
                                                Temperature
                                                {generalInformation.temperature > 0 ? ":" : ""}{" "}
                                            </span>{" "}
                                            {generalInformation.temperature > 0
                                                ? generalInformation.temperature
                                                : ""}{" "}
                                            {generalInformation.temperature > 0 ? "F" : ""}{" "}
                                        </a>
                                    </li>
                                    {toggleDuration && (
                                        <li>
                                            <a>
                                                <span>Diabetes Duration: </span> {emrData ? emrData?.diabetesDuration ? emrData?.diabetesDuration : duration : duration}{" "}
                                            </a>
                                        </li>
                                    )}
                                </span>
                            </ul>
                            {(userType === "doctor") &&
                                <div className="patient_history_btn">

                                    <NavLink
                                        to={`/patient-details`}
                                        style={{ marginRight: "10px" }}
                                        className="btn btn-fill btn-light btn-sm"
                                    >
                                        Go Back
                                    </NavLink>

                                    <NavLink
                                        to={`/history/${appointmentId}/${patientId}`}
                                        className="btn btn-fill btn-light pull-right btn-sm"
                                    >
                                        All Visits
                                    </NavLink>
                                </div >}
                        </div >
                    </div >
                </div >
                <div className="card-content-inner">
                    <div id="divEmrMenuList" className="emr-menu-list">
                        <ul>
                            {renderNavTabs()}
                        </ul>
                    </div>
                    <div className="emr-tab-content">
                        <div className="center-content">
                            <div id="divDates" style={{ "display": "inline-flex", paddingBottom: '12px', gap: '12px' }}>
                                {/* <div style={{ display: "inline-flex", alignItems: 'center', gap: '5px' }}>
                                    <label>FROM :</label>
                                    
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={"DD-MM-YYYY"}
                                        inputProps={{ placeholder: "DD-MM-YYYY" }}
                                        maxDate={new Date()}
                                        name={"start_date"}
                                        value={startDate ? startDate : ""}
                                        onChange={e => handleStartDate(e)}
                                        isValidDate={valid}
                                        closeOnSelect
                                        renderInput={(props) => {
                                            return <input {...props} value={(startDate) ? props.value : ''} />
                                        }}
                                    />
                                </div> */}
                                {/* <div style={{ display: "inline-flex", alignItems: 'center', gap: '5px' }}>
                                    <label>TO :</label>
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={"DD-MM-YYYY"}
                                        inputProps={{ placeholder: "DD-MM-YYYY" }}
                                        maxDate={new Date()}
                                        name={"end_date"}
                                        value={endDate ? endDate : ""}
                                        onChange={e => handleEndDate(e)}
                                        isValidDate={e => valid2(e)}
                                        closeOnSelect
                                        renderInput={(props) => {
                                            return <input {...props} value={(endDate) ? props.value : ''} />
                                        }}
                                    />
                                </div> */}


                                {/* <div>
                                    <Button
                                        className="btn btn-fill btn-primary"
                                        onClick={() => {
                                            handleSearch();
                                        }}
                                        disabled={(startDateEmpty || endDateEmpty) ? true : false}
                                    >
                                        <span>

                                            Search
                                        </span>
                                    </Button>
                                    {(startDateEmpty && endDateEmpty) ?
                                        <span style={{ color: "red", display: "block", position: "absolute" }}>Please Select Dates</span> :
                                        (startDateEmpty && !endDateEmpty) ?
                                            <span style={{ color: "red", display: "block", position: "absolute" }}>Please Select Start Date</span> :
                                            (!startDateEmpty && endDateEmpty) ?
                                                <span style={{ color: "red", display: "block", position: "absolute" }}>Please Select End Date</span>
                                                : <></>
                                    }
                                </div> */}

                                <Button
                                    className="btn btn-fill btn-primary add-edit-btn"
                                    onClick={() => {
                                        handleLabTest();
                                    }}
                                >
                                    <span>
                                        Add/Edit Lab Report
                                    </span>
                                </Button>

                                <div className="filter-select">
                                    <label  >Filter by Days</label>
                                    <select name="hours" onChange={e => handleSearch(e)} value={hours} >
                                        <option value="recent">Most Recent</option>
                                        <option value="0">Today</option>
                                        <option value="72">3 Days</option>
                                        <option value="168">7 Days</option>
                                        <option value="360">15 Days</option>
                                        <option value="720">30 Days</option>
                                        <option value="all">All</option>
                                    </select>
                                </div>


                                {/* <Button
                                    className="btn btn-fill btn-primary"
                                    onClick={() => {
                                        setGetAllReports(true);
                                        getPatientLabTestDetail(true)
                                    }}
                                >
                                    <span>
                                        All Reports
                                    </span>
                                </Button> */}



                                {/* <Button
                                    className="btn btn-fill btn-primary"
                                    onClick={() => {
                                        clearData();
                                    }}
                                >
                                    <span>
                                        Clear
                                    </span>
                                </Button> */}
                                <Button
                                    className="btn btn-fill btn-primary add-edit-lab "
                                    onClick={() => {
                                        dispatchLabTestRedux({
                                            type: actionTypes.GET_LAB_TEST_LIST_REQUEST,
                                        });
                                        setLabTestRangeModal(true)
                                    }}>
                                    <span>
                                        Add/Edit Lab Range
                                    </span>
                                </Button>


                            </div>
                        </div>
                        <div id="printable" className="lab-test-table">
                            {isLoading ? (
                                <Loading
                                    type="bars"
                                    color="#000000"
                                    style={{ margin: "0px auto", width: "40px" }}
                                />
                            ) :
                                <> {(!isNoDataFound && reportDates.length > 0) ?
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered table-condensed divHead-wrapper">
                                            <thead>
                                                <tr>
                                                    <th colSpan={reportDates.length + 3}>
                                                        <div id="divHead" style={{ display: "none" }}>
                                                            <div className="thead-cols">
                                                                <span
                                                                    className="txt-black px-5 py-0"
                                                                    style={{
                                                                        textAlign: "left",
                                                                        color: "black",
                                                                        padding: "4px 10px",
                                                                        border: "0",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Name:
                                                                    <span className="txt-black">
                                                                        {patinetData.title}. {patinetData.firstName}{" "}
                                                                        {patinetData.lastName}
                                                                    </span>
                                                                </span>
                                                                <span
                                                                    className="txt-black px-5 py-0"
                                                                    style={{
                                                                        border: "0",
                                                                        color: "black",
                                                                        padding: "2px 15px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Age: {patinetData.age}{" "}
                                                                    <span className="txt-black">Y</span> /{" "}
                                                                    {patinetData.gender === "Male" ? " M" : " F"}{" "}
                                                                </span>
                                                                <span
                                                                    className="txt-black px-5 py-0"
                                                                    style={{
                                                                        border: "0",
                                                                        textAlign: "right",
                                                                        color: "black",
                                                                        padding: "4px 10px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Date:
                                                                    {
                                                                        <span className="txt-black">
                                                                            {moment(new Date()).format("DD-MM-YYYY")}{" "}
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="thead-cols">
                                                                <span
                                                                    colSpan={2}
                                                                    className="txt-black px-5 py-0"
                                                                    style={{
                                                                        border: "0",
                                                                        textAlign: "left",
                                                                        color: "black",
                                                                        padding: "4px 10px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    City:
                                                                    <span className="txt-black px-5 py-0">
                                                                        {patinetData.city}
                                                                    </span>
                                                                </span>
                                                                <span
                                                                    className="txt-black px-5 py-0"
                                                                    style={{
                                                                        border: "0",
                                                                        textAlign: "right",
                                                                        color: "black",
                                                                        padding: "4px 10px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    Patient Id:{" "}
                                                                    <span className="txt-black">
                                                                        {patinetData.patientClinicId}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                                {props?.showPatientInfo &&
                                                    <tr id="trPrintHeader"   >
                                                        <th >SNo</th>
                                                        <th>Test (Unit)</th>
                                                        {reportDates.map((date, idx) => {
                                                            return handleReportDate(date, idx)

                                                        })}
                                                        <th>Reference</th>
                                                    </tr>
                                                }

                                            </thead>
                                            <tbody>
                                                {!props?.showPatientInfo &&
                                                    <tr id="trHeader">
                                                        <th>SNo</th>
                                                        <th>Test (Unit)</th>
                                                        {reportDates.map((date, idx) => {
                                                            return handleReportDate(date, idx)

                                                        })}
                                                        <th>Reference</th>
                                                    </tr>
                                                }
                                                {Object.keys(state.labData).map((itm, idx) => {
                                                    if (itm)
                                                        return <tr>
                                                            <td>{++idx}</td>
                                                            <td>{getNameUnitRange(itm)}</td>
                                                            {Object.values(getValue(itm)).map((obj, id) => {
                                                                // if (id + 1 <= printColumnsCnt) {
                                                                //     return <td id={id}>{validateRange(itm, obj)}</td>
                                                                // } else if (!props.showPatientInfo) {
                                                                //     return <td id={id}>{obj}</td>
                                                                // }
                                                                if (props.showPatientInfo) {
                                                                    if (id + 1 <= printColumnsCnt)
                                                                        return <td id={id}>{validateRange(itm, obj)}</td>
                                                                } else return <td id={id}>{validateRange(itm, obj)}</td>
                                                            })}
                                                            <td id={`ref-${itm}`}>{getReferenceRange(itm, `ref-${itm}`)}</td>
                                                        </tr>
                                                })

                                                }
                                            </tbody>
                                        </table>
                                    </div> :
                                    clearButtonClicked ? <></> :
                                        <div className="lab-test"><span style={{ fontWeight: "bold" }}>No Data Found!</span></div>
                                }</>
                            }
                        </div>
                    </div>
                </div>
            </span >
            <Modal
                show={labTestModal}
                onHide={() => setLabTestModal(false)}
                dialogClassName="modal-xl"
                className="Modal_downsearch"
            >
                <Modal.Header
                    closeButton
                    className="bg-thumbcolor"
                >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {mode === "add" ? "Add" : "Upadate"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabTest
                        patientLabData={patientLabData}
                        labTestListData={labTestList}
                        patientId={patientId}
                        onDismissLabTests={onDismissLabTests}
                        generalInformation={generalInformation}
                        patientLabReportData={state.patientLabReportData}
                        handleMode={(mode) => setMode(mode)}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={labTestRangeModal}
                onHide={() => setLabTestRangeModal(false)}
                dialogClassName="modal-xl"
                className="Modal_downsearch"
            >
                <Modal.Header
                    closeButton
                    className="bg-thumbcolor"
                >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Lab Test List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabTestList
                        labTestListData={labTestList}
                        onDismissLabTestDetail={onDismissLabTestDetail}
                    />
                </Modal.Body>
            </Modal>
        </div >
    )
})

export default LabTestDetail




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DoctorScreen from 'Front/views/Home/DoctorScreen.jsx';
import EducatorScreen from 'Front/views/Home/EducatorScreen.jsx';
import ReceptionistScreen from 'Front/views/Home/ReceptionistScreen.jsx';
import history from '../../routes/history';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        this.state = {
            search: '',
            search_date: date,
        };
    }
    Screens() {

        if (localStorage.getItem('userType') === 'doctor') {

            return (<DoctorScreen
                search={this.state.search}
                search_date={this.state.search_date}
            />)

        } else if (localStorage.getItem('userType') === 'educator') {

            return (<EducatorScreen
                search={this.state.search}
                search_date={this.state.search_date}
            />)
        } else if (localStorage.getItem('userType') === 'receptionist') {

            return (<ReceptionistScreen
                search={this.state.search}
                search_date={this.state.search_date}
            />)
        } else {
            history.push({ pathname: "/" });
        }
    }

    render() {

        return (
            <div className="main-content dashboard dashboard-2-0">

                <Grid fluid>

                    {this.Screens()}

                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    }

}
export default withRouter(connect(mapStateToProps, {})(Dashboard));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],
    message: '',
    isAddComment: false,
    isAddCommentError: false,
    AddComment: false,
}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.ADD_COMMENT_MSG_REQUEST:
            return {
                ...state,
                message: '',
                isAddComment: false,
                isAddCommentError: false,
            }
        case actionTypes.ADD_COMMENT_MSG_SUCCESS:
            return {
                ...state,
                message: 'Comment Added Successfully',
                AddComment: action.payload.response,
                isAddComment: true,
                isAddCommentError: false,
            }
        case actionTypes.ADD_COMMENT_MSG_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isAddComment: false,
                isAddCommentError: true
            }

        default:
            return state
    }
}
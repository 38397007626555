/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

/** Updated by ST0101 **/

import React, { Component } from "react";
import { Grid, Row, Col, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { educatorMsgListAction } from "Front/actions/home";
import Card from "Front/components/Card/Card.jsx";
import Checkbox from "Front/elements/CustomCheckbox/CustomCheckbox.jsx";
import Radio from "Front/elements/CustomRadio/CustomRadio.jsx";
import CalorieInfo from "Front/views/TaskScreen/CalorieInfo.jsx";
import KnowledgeShare from "Front/views/TaskScreen/KnowledgeShare.jsx";
import {
  treatmentWithTypesAction,
  addTaskAction,
  timeListAction,
} from "Front/actions/master";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from "react-confirm-alert";
import { taskListAction } from "Front/actions/home";
import { calorieList } from "Front/variables/Variables.jsx";
import History from "Front/views/TaskScreen/History.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import {
  sharetDietAction,
  printDietColorChangeAction,
  cancelDietAction,
} from "Front/actions/diet.js";
import DietChat from "Front/views/DietChart/DietChart";
import Comments from "Front/views/Comments/Comments";
import { languageListAction } from "Front/actions/language";
import $ from "jquery";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import {
  taskScreenAction,
  taskScreenHeightWeightRequireForCaloryAction,
} from "Front/actions/taskScreen";
import * as API from "../../api/emr";
import { emrSetVitalSignsAction } from "Front/actions/emr";
import * as PF from "Front/views/Home/PublicFunction.jsx";

let editApi = false;
let calorieInfo = "";
let errorMsg = "";
let commentData = [];

class TaskScreen extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

    this.state = {
      formArr: [],
      date: date,
      next_visit_dateError: null,
      visitInfo: this.props.taskScreenData,
      educator_msg_list: [],
      treatmentId: "",
      timeList: [],
      languageList: [],
      languageOptions: [],
      formData: {
        visitId: this.props.taskScreenData ? this.props.taskScreenData.id : "",
        patientId: this.props.taskScreenData
          ? this.props.taskScreenData.pId
          : "",
        app: this.props.taskScreenData ? this.props.taskScreenData.app : "",
        notification_token: this.props.taskScreenData
          ? this.props.taskScreenData.notification_token
          : "",
        remark: this.props.taskScreenData
          ? this.props.taskScreenData.remark
          : "",
        tasks: [],
        documents: [],
        videos: [],
        type: "share",
        treatmentType: "",
        optionCount: 2,
        dietType: "veg-s",
        dietLanguage: this.props.taskScreenData?.language ? this.props.taskScreenData.language : "1",
        treatmentId: "",
        treatmentName: "",
        calorie: 0,
        startTimeDelay: 0,
        dietId: "",
        color: "",
        patient: {},
        dietOption: 2,
        mealType: "veg-s",
        selectCalorie: 0,
        email: "",
        firstName: "",
        comments: [],
        file: [],
        mealTime: "09:00 am",
        ptVisitDate: "",
        shareDate: "",
        selectedComments: [],
      },
      showProcessing: false,
      diabeticDiet: [],
      nonDiabeticDiet: [],
      alert: null,
      show: false,
      diabeticTab: true,
      graphModal: false,
      graphType: "",
      graphId: "",
      dietChatModal: false,
      commentModal: false,
      selectedComments: [],
      treatmentList: [],
      shareType: "",
      ptSearch: this.props.ptSearch === undefined ? true : this.props.ptSearch,
      edShareDiet:
        this.props.shareDietByEd === undefined
          ? true
          : this.props.shareDietByEd,
      mealTimeForGraph: "",
      color: "",
      dk: 0,
      calorieOptions: "",
      lastCalorie: "",
      firstMealStatus: false,
      edShareKnowledge: true,
      isHeightWeightValidation: this.props.isHeightWeightValidation,
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.treatmentTabChange = this.treatmentTabChange.bind(this);
    this.dietChart = this.dietChart.bind(this);
    this.backPage = this.backPage.bind(this);
    this.shareDiet = this.shareDiet.bind(this);
    this.nextPageChanges = this.nextPageChanges.bind(this);
    this.successAlert = this.successAlert.bind(this);
    this.changeMealTime = this.changeMealTime.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.handleChangeDiet = this.handleChangeDiet.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);

    let _this = this;
    appConstants.socket.on("updateData", function (res) {
      if (res.type === "educatorMsg") {
        setTimeout(function () {
          _this.props.educatorMsgListAction(this.state);
        }, 1000);
      }
    });
  }

  componentDidMount() {
    this.getGeneralInfo()
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    //console.log('this.state.visitInfo', this.state.visitInfo)
    //console.log('this.state.visitInfo', this.state.visitInfo && this.state.visitInfo.lastDiet && this.state.visitInfo.lastDiet.isShareDate)

    this.props.languageListAction({
      limit: 100,
      offset: 0,
      order: "lanId",
      direction: "asc",
    });

    let field = this.state.formData;

    if (this.state.visitInfo) {
      let visitInfo = this.state.visitInfo;
      field["ptVisitDate"] =
        this.state.visitInfo && this.state.visitInfo.createDate;

      this.setState({ formData: field });
      if (this.state.visitInfo.calorieDetails !== undefined) {
        visitInfo["bmi"] = this.state.visitInfo.bmi
          ? this.state.visitInfo.bmi
          : this.state.visitInfo.calorieDetails.bmi;
        visitInfo["c1"] = Math.ceil(
          this.state.visitInfo.c1
            ? this.state.visitInfo.c1
            : this.state.visitInfo.calorieDetails.c1
        );
        visitInfo["c2"] = Math.ceil(
          this.state.visitInfo.c2
            ? this.state.visitInfo.c2
            : this.state.visitInfo.calorieDetails.c2
        );
        visitInfo["c3"] = Math.ceil(
          this.state.visitInfo.c3
            ? this.state.visitInfo.c3
            : this.state.visitInfo.calorieDetails.c3
        );
      }
      this.setState({ visitInfo: visitInfo });
    }

    if (this.state.visitInfo.lastDiet && this.state.visitInfo.lastDiet["0"]) {
      let lastDiet = this.state.visitInfo.lastDiet["0"];

      field["treatmentType"] = lastDiet.treatmentType;
      field["optionCount"] = lastDiet.optionCount;
      field["dietType"] = lastDiet.dietType;
      field["mealType"] = lastDiet.dietType;
      //field["dietLanguage"] = lastDiet.dietLanguage;
      field["treatmentId"] = lastDiet.treatmentId;
      field["treatmentName"] = lastDiet.treatmentName;
      field["calorie"] = lastDiet.calorie ? lastDiet.calorie : calorieInfo;

      field["dietId"] = lastDiet._id;
      field["color"] = lastDiet.color;
      field["selectCalorie"] = lastDiet.calorie;
      field["patientId"] = lastDiet.patientId;
      field["email"] = this.state.visitInfo.email;
      field["firstName"] = this.state.visitInfo.firstName;
      field["app"] = this.state.visitInfo.app;
      field["mealTime"] = lastDiet.mealTime;
      field["patient"] = this.state.visitInfo;
      field["shareDate"] = lastDiet.isShareDate;
      field["comments"] = lastDiet.comments;
      field["file"] = lastDiet.file;
      this.setState({ lastCalorie: lastDiet.calorie });

      if (lastDiet.treatmentType === "non-diabetic") {
        this.setState({ diabeticTab: false });
      } else {
        this.setState({ diabeticTab: true });
      }
      //this.setState({ formData: field, graphId:lastDiet._id, dietLanguage:lastDiet.dietLanguage });
      this.setState({
        formData: field,
        //dietLanguage: lastDiet.dietLanguage,
        patient: lastDiet,
        color: lastDiet.color,
      });
    } else {
      field["calorie"] = this.state.visitInfo.segCalorie;

      field["selectCalorie"] = this.state.visitInfo.segCalorie;
      field["email"] = this.state.visitInfo.email;
      field["firstName"] = this.state.visitInfo.firstName;
      field["app"] = this.state.visitInfo.app;
      field["patient"] = this.state.visitInfo;
      field["shareDate"] =
        this.state.visitInfo &&
          this.state.visitInfo.lastDiet &&
          this.state.visitInfo.lastDiet["1"]
          ? this.state.visitInfo.lastDiet["1"].isShareDate
          : "";
      field["mealTime"] =
        this.state.visitInfo &&
          this.state.visitInfo.lastDiet &&
          this.state.visitInfo.lastDiet["1"]
          ? this.state.visitInfo.lastDiet["1"].mealTime
          : "09:00 am";
      field["color"] =
        this.state.visitInfo &&
          this.state.visitInfo.lastDiet &&
          this.state.visitInfo.lastDiet["1"]
          ? this.state.visitInfo.lastDiet["1"]
          : "red";
      let color =
        this.state.visitInfo &&
          this.state.visitInfo.lastDiet &&
          this.state.visitInfo.lastDiet["1"]
          ? this.state.visitInfo.lastDiet["1"]
          : "red";
      this.setState({ formData: field, color });
    }

    if (this.props.EducatorMsgList) {
      // this.setState({ educator_msg_list: this.props.EducatorMsgList.messageList });
      let messageData = this.props.EducatorMsgList.messageList;
      for (let i in messageData) {
        if (messageData[i].showClinics.length > 0) {
          let local_index = messageData[i].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index !== -1) {
            messageData[i].clinicShowStatus = false;
          } else {
            messageData[i].clinicShowStatus = true;
          }
        }
      }
      this.setState({ educator_msg_list: messageData });
    } else {
      this.props.educatorMsgListAction(this.state);
    }

    if (this.props.treatmentWithTypeList) {
      let diet = this.props.treatmentWithTypeList.data;

      let i;
      let diabeticDiet = [];
      let nonDiabeticDiet = [];
      if (diet && diet.length) {
        for (i in diet) {
          if (diet[i].type === "diabetic") {
            diabeticDiet.push(diet[i]);
          } else {
            nonDiabeticDiet.push(diet[i]);
          }
        }
      }
      this.setState({
        diabeticDiet: diabeticDiet,
        nonDiabeticDiet: nonDiabeticDiet,
        treatmentList: this.props.treatmentWithTypeList.data,
      });
    } else {
      this.props.treatmentWithTypesAction(this.state);
    }

    this.handleKnowledgeTasks(this.props);

    setTimeout(
      function () {
        this.getCalorieList();
      }.bind(this),
      1000
    );

    setTimeout(
      function () {
        if (this.state.calorieOptions === "") {
          this.getCalorieList();
        }
      }.bind(this),
      5000
    );

    //if (!this.state.timeList) {
    //this.props.timeListAction();
    // }
    $(document).ready(function () {
      $(".select-calorie").change(function () {
        if ($(".select-calorie").val() == 0) {
          $(".select-calorie").css("border", "1px solid #FE0000");
        } else {
          $(".select-calorie").css("border", "");
        }
      });
    });
    setInterval(() => {
      if ($(".select-calorie").val() == 0) {
        $(".select-calorie").css("border", "1px solid #FE0000");
      } else {
        $(".select-calorie").css("border", "");
      }
    }, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLanguageList !== this.props.isLanguageList &&
      this.props.isLanguageList === false
    ) {
      this.setState({
        languageList: nextProps.LanguageList.data,
      });

      let list = nextProps.LanguageList.data;
      let languageOptions = [];
      for (let i in list) {
        languageOptions.push({ value: list[i].lanId, label: list[i].name });
      }

      this.setState({
        languageOptions: languageOptions,
      });

      let languages = localStorage.language
        ? localStorage.language.split(",")
        : [];

      if (languages.length > 0) {
        let languageList = languageOptions.filter((key, i) => {
          return languages.includes(key.value.toString());
        });

        this.setState({ languageList: languageList });
      }
    }

    if (nextProps.timeList) {
      this.setState({ timeList: nextProps.timeList });
      let commentsList = nextProps.timeList.data.comments;
      let commentsArr = [];
      let req = {};
      for (let x in commentsList) {
        if (this.state.formData.comments.length > 0) {
          let lastDietComments = this.state.formData.comments;
          for (let y in lastDietComments) {
            if (
              lastDietComments[y].comment_id === commentsList[x]._id &&
              this.state.formData.treatmentType === commentsList[x].type
            ) {
              req = {
                1: commentsList[x].text["1"],
                2: commentsList[x].text["2"],
                3: commentsList[x].text["3"],
                4: commentsList[x].text["4"],
                comment_id: commentsList[x]._id,
              };

              commentsArr.push(req);
            }
          }
        } else {
          if (
            commentsList[x].auto_select &&
            this.state.formData.treatmentType === commentsList[x].type
          ) {
            req = {
              1: commentsList[x].text["1"],
              2: commentsList[x].text["2"],
              3: commentsList[x].text["3"],
              4: commentsList[x].text["4"],
              comment_id: commentsList[x]._id,
            };

            commentsArr.push(req);
          }
        }
      }
      let fData = this.state.formData;
      fData["comments"] = commentsArr;
      fData["selectedComments"] = commentsArr;
      this.setState({ formData: fData });
      this.setState({ selectedComments: commentsArr });
    }

    if (nextProps.isEducatorMsgList !== this.props.isEducatorMsgList) {
      // this.setState({ educator_msg_list: nextProps.EducatorMsgList.messageList });
      let messageData = nextProps.EducatorMsgList.messageList;
      for (let i in messageData) {
        if (messageData[i].showClinics.length > 0) {
          let local_index = messageData[i].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index !== -1) {
            messageData[i].clinicShowStatus = false;
          } else {
            messageData[i].clinicShowStatus = true;
          }
        }
      }
      this.setState({ educator_msg_list: messageData });
    }

    if (nextProps.treatmentWithTypeList !== this.props.treatmentWithTypeList) {
      let diet = nextProps.treatmentWithTypeList.data;
      let i;
      let diabeticDiet = [];
      let nonDiabeticDiet = [];
      if (diet && diet.length) {
        for (i in diet) {
          let treatmentItem = diet[i].treatments;
          treatmentItem.sort(function (a, b) {
            return a.order - b.order;
          });

          diet[i].treatments = treatmentItem;

          if (diet[i].type === "diabetic") {
            diabeticDiet.push(diet[i]);
          } else {
            nonDiabeticDiet.push(diet[i]);
          }
        }
      }

      this.setState({
        diabeticDiet: diabeticDiet,
        nonDiabeticDiet: nonDiabeticDiet,
        treatmentList: diet,
      });
    }

    if (nextProps.isAddTask !== this.props.isAddTask && editApi) {
      editApi = false;

      this.onDismiss("Task Saved Successfully");

      let taskData = this.state.visitInfo;
      //console.log(taskData,"AAAAAAAAAAAAAA")
      taskData.language = this.state.formData.dietLanguage;
      let temp = {};
      temp.treatmentId = this.state.formData.treatmentId;
      temp.selectCalorie = this.state.formData.selectCalorie;
      temp.dietType = this.state.formData.dietType;
      temp.treatmentType = this.state.formData.treatmentType;
      temp.optionCount = this.state.formData.optionCount;
      temp.mealType = this.state.formData.dietType;
      temp.treatmentName = this.state.formData.treatmentName;
      temp.calorie = this.state.formData.calorie;
      temp.dietId = this.state.formData.dietId;
      temp.color = this.state.formData.color;
      temp.patientId = this.state.formData.patientId;
      temp.email = this.state.formData.email;
      temp.firstName = this.state.formData.firstName;
      temp.app = this.state.formData.app;
      temp.mealTime = this.state.formData.mealTime;
      temp.shareDate = this.state.formData.shareDate;
      temp.comments = this.state.formData.comments;
      temp.file = this.state.formData.file;
      temp.readStatus = this.props.taskScreenData?.lastDiet[0]?.readStatus;
      temp.addedByType = localStorage.getItem("userType");
      taskData.lastDiet["0"] = temp;
      taskData.documentList = this.state.formData.videos;
      //console.log(this.state.formData.tasks,"this.state.formData.tasks")
      taskData.taskList = this.state.formData.tasks;
      let documentArray = [];
      let videoList = this.state.formData.videos;
      for (let i in videoList) {
        let temp = {};
        temp.documentId = videoList[i].id;
        temp.documentName = videoList[i].name;
        temp.image = videoList[i].link;
        temp.docType = "video";
        temp.status = videoList[i].status;
        temp.patientRead = videoList[i].patientRead;
        temp._id = videoList[i]._id;
        temp.addedByType = videoList[i].addedByType;
        documentArray.push(temp);
      }

      let documentList = this.state.formData.documents;
      for (let i in documentList) {
        let temp = {};
        temp.documentId = documentList[i].id;
        temp.documentName = documentList[i].name;
        temp.image = documentList[i].link;
        temp.docType = "document";
        temp.status = documentList[i].status;
        temp.patientRead = documentList[i].patientRead;
        temp._id = documentList[i]._id;
        temp.addedByType = documentList[i].addedByType;
        documentArray.push(temp);
      }

      // console.log(documentArray,"documentArray")
      taskData.documentList = documentArray;
      taskData.isTaskScreenDataSaved = true;

      taskData.segCalorie = this.state.formData.calorie;
      taskData.calorieHieghtWeightFilledOnEdit = false;
      //console.log(taskData, "TaskDatasssssssssss")
      this.props.taskScreenAction(taskData);

      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem("clinicId"),
        };
        appConstants.socket.emit("updateTaskScreen", params);
      }, 1000);
    }
    this.handleKnowledgeTasks(nextProps);

    if (nextProps.isDietShare && this.state.shareType) {
      let msg = "";
      if (this.state.shareType === "email") {
        msg = "Diet shared on email successfully.";
      } else {
        msg = "Diet shared on app successfully.";
      }

      let formData = this.state.formData;
      formData["shareDate"] = new Date();

      this.successAlert(msg);
      this.setState({ shareType: "" });
    }
  }

  onDismiss(msg) {
    this.props.onDismiss1(msg);

    // this.setState({ taskScreen: false, showProcessing: true });
    // this.successAlert('Task Successfully Submitted');
  }

  shareDiet(type) {
    let formData = this.state.formData;

    let sendData = {
      // patientId: this.state.patient._id,
      patientId: formData.patientId,
      patientFirstName: formData.firstName,
      treatmentId: formData.treatmentId,
      treatmentName: formData.treatmentName,
      treatmentType: formData.treatmentType,
      calorie: formData.selectCalorie
        ? formData.selectCalorie
        : formData.calorie,
      dietType: formData.mealType,
      mealTime: formData.mealTime,
      //optionCount: formData.dietOption,
      optionCount: 3,
      dietLanguage: formData.dietLanguage,
      startTimeDelay: 0,
      shareType: type,
      email: formData.email,
      //comments:commentData,
      comments: formData.comments,
      file: formData.file,
    };

    this.props.sharetDietAction(sendData);

    this.setState({ shareType: type });
  }

  dietChart(status = "") {
    if (this.state.firstMealStatus === false && status === "") {
      confirmAlert({
        title: "Confirm time of first meal",
        message: "Do you want to continue with default first meal time?.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.setState({ firstMealStatus: true });
              this.dietChart("true");
            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });
    } else {
      let pId = this.state.patient && this.state.patient._id;
      let data = this.state.formData;

      let deitId = "";
      if (this.state.patient && this.state.patient._id) {
        deitId = this.state.patient && this.state.patient._id;
      }

      if (data.calorie !== 0) {
        errorMsg = "";
        this.setState({ dietChatModal: true });
      } else {
        errorMsg = "Please select calorie first.";
        this.setState({ errorMsgModal: !this.state.errorMsgModal });
      }

      let colorData = {
        newColor: "blue",
        dietId: deitId,
        patient: pId,
      };

      if (localStorage.getItem("userType") === "educator") {
        if (this.state.color === "red" || this.state.color === "") {
          this.props.printDietColorChangeAction(colorData);
        }
      }

      setTimeout(function () {
        let params = {
          clinicId: localStorage.getItem("clinicId"),
          visit_id: pId,
        };
        appConstants.socket.emit("updateTaskScreen", params);
      }, 2000);
    }
  }

  comments() {
    let pId = this.state.patient && this.state.patient.pId;
    this.setState({ commentModal: true });
    setTimeout(function () {
      let params = {
        clinicId: localStorage.getItem("clinicId"),
        visit_id: pId,
      };
      appConstants.socket.emit("updateTaskScreen", params);
    }, 2000);
  }

  backPage() {
    this.setState({ dietChatModal: false });
    this.setState({ commentModal: false });
  }

  handleChangeDiet(data, e) {
    let formData = this.state.formData;
    commentData.push(data);
    //  formData['comments1'] = data;
    formData["comments"] = data;
    formData["selectedComments"] = data;
    this.setState({ formData });
  }

  handleChangeFile(data, e) {
    let formData = this.state.formData;
    formData["file"] = data;
    this.setState({ formData });
  }

  nextPageChanges(data) {
    this.setState(data);
  }

  handleKnowledgeTasks(data) {
    if (
      data.taskScreenData.documentList &&
      data.isAddTask === this.props.isAddTask
    ) {
      let docList = data.taskScreenData.documentList;
      let videos = [];
      let documents = [];
      for (let i in docList) {
        if (docList[i].docType === "document") {
          documents.push({
            id: docList[i].documentId,
            name: docList[i].documentName,
            link: docList[i].image,
          });
        } else {
          videos.push({
            id: docList[i].documentId,
            name: docList[i].documentName,
            link: docList[i].image,
          });
        }
      }
      let field = this.state.formData;
      field["documents"] = documents;
      field["videos"] = videos;
      this.setState({ formData: field });
    }
    if (
      data.taskScreenData.taskList &&
      data.isAddTask === this.props.isAddTask
    ) {
      let { tasks } = this.state.formData;
      let taskList = data.taskScreenData.taskList;
      for (let i in taskList) {
        tasks.push({ id: taskList[i].taskId, name: taskList[i].taskName });
      }
      this.setState({ ...this.state.formData, tasks });
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;

    if (e.target.name === "remark") {
      field[e.target.name] = e.target.value;
    } else {
      field[e.target.name] = parseInt(e.target.value, 10);
    }
    field["selectCalorie"] = parseInt(e.target.value, 10);

    if (e.target.name === "calorie") {
      this.setState({ lastCalorie: parseInt(e.target.value, 10) });
    }

    this.setState({
      formData: field,
    });
  }

  handleTasksChange(id, name) {
    let field = this.state.formData;
    let exist = 0;
    for (let x in field.tasks) {
      if (field.tasks[x].id === id) {
        exist = 1;
        delete field.tasks[x];
      }
    }
    if (exist === 0) {
      field["tasks"].push({ id: id, name: name });
    }
    field["tasks"] = field.tasks.filter(function (el) {
      return el != null;
    });

    this.setState({ formData: field });
    //console.log(this.state.formData);
  }

  handleDietChange(id, name, treatment) {
    let field = this.state.formData;
    //console.log(field, this.state.formData);
    field["treatmentId"] = id;
    field["treatmentName"] = name;
    field["treatmentType"] = treatment.type;

    this.setState({ formData: field });
    //console.log(this.state.formData);
    this.getCalorieList(treatment);
    this.props.timeListAction();
  }

  handleKnowledgeDocChange(row) {
    let field = this.state.formData;
    let exist = 0;
    for (let x in field.documents) {
      if (field.documents[x].id === row._id) {
        exist = 1;
        delete field.documents[x];
      }
    }
    if (exist === 0) {
      field["documents"].push({
        id: row._id,
        name: row.title,
        nameHindi: row.title_hindi,
        link: row.image,
        linkHindi: row.image_hindi,
      });
    }

    field["documents"] = field.documents.filter(function (el) {
      return el != null;
    });

    this.setState({ formData: field });
  }

  handleKnowledgeVideoChange(row) {
    let field = this.state.formData;
    let exist = 0;
    for (let x in field.videos) {
      if (field.videos[x].id === row._id) {
        exist = 1;
        delete field.videos[x];
      }
    }
    if (exist === 0) {
      field["videos"].push({
        id: row._id,
        name: row.title,
        nameHindi: row.title_hindi,
        link: row.image,
        linkHindi: row.image_hindi,
      });
    }
    field["videos"] = field.videos.filter(function (el) {
      return el != null;
    });

    this.setState({ formData: field });
  }

  submitTaskConfirm() {
    if ($(".select-calorie").val() == 0) {
      $(".select-calorie").css("border", "1px solid #FE0000");
      this.setState({ isCalorie: true });
      return false;
    }
    if (
      this.props.vitalSignsData &&
      (this.props.vitalSignsData.height === 0 ||
        this.props.vitalSignsData.height === "" ||
        this.props.vitalSignsData.weight === 0 ||
        this.props.vitalSignsData.weight === "")
    ) {
      let msg = "";
      if (
        (this.props.vitalSignsData.height === 0 ||
          this.props.vitalSignsData.height === "") &&
        this.props.vitalSignsData.weight != 0
      ) {
        msg = `Height Is Required For Calorie Calculation. Click On "Yes" To Fill Height. Click On "No" To Submit Diet`;
      } else if (
        (this.props.vitalSignsData.weight === 0 ||
          this.props.vitalSignsData.weight === "") &&
        this.props.vitalSignsData.height != 0
      ) {
        msg = `Weight Is Required For Calorie Calculation. Click On 'Yes' To Fill Weight. Click On 'No' To Submit Diet`;
      } else {
        msg = `Height And Weight Are Required For Calorie Calculation. Click On 'Yes' To Fill Height And Weight. Click On 'No' To Submit Diet`;
      }

      confirmAlert({
        title: "",
        message: msg,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.taskScreenHeightWeightRequireForCaloryAction(true);
            },
          },
          {
            label: "No",
            onClick: () => {
              this.submitTask();
            },
          },
        ],
      });
    } else {
      this.submitTask();
    }
  }

  submitTask() {
    if ($(".select-calorie").val() == 0) {
      $(".select-calorie").css("border", "1px solid #FE0000");
      return false;
    }
    //  if(this.state.formData.treatmentId !== '' || this.state.formData.documents[0] || this.state.formData.videos[0] || this.state.formData.tasks[0]){
    editApi = true;
    //console.log(this.state.formData);
    let data = this.state.formData;
    data.isKnowledgeShare = true;
    this.props.addTaskAction(data);

    //  }
  }

  successAlert(msg) {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    this.props.history.push("/dashboard");
  }

  getCalorieList(treatment = "") {
    if (
      treatment === "" &&
      this.props.taskScreenData &&
      this.props.taskScreenData.lastDiet &&
      this.props.taskScreenData.lastDiet["0"]
    ) {
      let ttId = this.props.taskScreenData.lastDiet["0"].treatmentId;

      let dList = this.state.treatmentList;
      for (let i in dList) {
        let treatmentItem = dList[i].treatments;

        treatmentItem.map(function (item, k) {
          if (item._id === ttId) {
            treatment = dList[i];
          }
          return "";
        });
      }
    }

    let calorie =
      this.state.formData.selectCalorie && this.state.formData.selectCalorie > 0
        ? this.state.formData.selectCalorie
        : this.state.lastCalorie > 0
          ? this.state.lastCalorie
          : this.state.visitInfo.segCalorie;

    let c = 0;
    var field = this.state.formData;

    if (
      treatment &&
      treatment.range_from &&
      treatment.range_to &&
      treatment.range_from > 0 &&
      treatment.range_to > 0
    ) {
      if (treatment.range_from <= calorie && treatment.range_to >= calorie) {
      } else {
        field["calorie"] = 0;
        field["selectCalorie"] = 0;
      }
    }

    let options = calorieList.map(function (item, i) {
      let selected = "";
      let itemValue = parseInt(item.value, 10);
      if (
        (calorie && calorie >= itemValue && calorie < itemValue + 100) ||
        (calorie && calorie <= itemValue && calorie > itemValue - 100)
      ) {
        if (
          treatment &&
          treatment.range_from &&
          treatment.range_to &&
          treatment.range_from > 0 &&
          treatment.range_to > 0
        ) {
          if (
            treatment.range_from <= item.value &&
            treatment.range_to >= item.value
          ) {
            field["calorie"] = itemValue;
            field["selectCalorie"] = itemValue;
            selected = 'selected="selected"';
            c = item.value;
          }
        } else {
          field["calorie"] = itemValue;
          field["selectCalorie"] = itemValue;
          selected = 'selected="selected"';
          c = item.value;
        }
      }
      if (
        treatment &&
        treatment.range_from &&
        treatment.range_to &&
        treatment.range_from > 0 &&
        treatment.range_to > 0
      ) {
        if (
          treatment.range_from <= item.value &&
          treatment.range_to >= item.value
        ) {
          return (
            <option key={item.value} value={item.value} selected={selected}>
              {item.label}
            </option>
          );
        }
      } else {
        return (
          <option key={item.value} value={item.value} selected={selected}>
            {item.label}
          </option>
        );
      }
      return "";
    });

    this.setState({ formData: field });
    //let formData = this.state.formData.calorie;
    //  this.state.formData.calorie = c;
    calorieInfo = c;
    this.setState({ calorieOptions: options });
    //return options;
  }

  checkTask(id) {
    let tasks = this.state.formData.tasks;
    if (tasks.length > 0) {
      for (let x in tasks) {
        if (tasks[x].id === id) {
          return true;
        }
      }
    }
    return false;
  }

  treatmentTabChange(type) {
    if (type === "diabetic") {
      this.setState({ diabeticTab: true });
    } else {
      this.setState({ diabeticTab: false });
    }
  }

  openGraphModal(e, type) {
    let formData = this.state.formData;

    if (this.state.formData.calorie) {
      this.setState({
        graphType: type,
        graphId: formData.treatmentId,
        graphModal: !this.state.graphModal,
        graphCalorie: formData.calorie,
      });
      errorMsg = "";
    } else {
      errorMsg = "Please select calorie first.";
      this.setState({ errorMsgModal: !this.state.errorMsgModal });
    }
  }

  handelChange(e, name) {
    let data = {};
    data[name] = e.target.value;

    let formData = this.state.formData;
    formData[name] = e.target.value;

    if (name === "mealType") {
      formData["dietType"] = e.target.value;
    }

    this.setState(data);
  }

  handleMealTypeChange(e) {
    e.preventDefault();

    let formData = this.state.formData;
    formData["mealTime"] = e.target.value;

    let mealTimeForGraph = this.state.mealTimeForGraph;
    mealTimeForGraph = e.target.value ? e.target.value.slice(0, -2) : "07:00";

    this.setState({ formData, mealTimeForGraph });
    this.setState({ firstMealStatus: true });
  }

  editDietByEducator(e) {
    this.setState({ edShareDiet: true });
  }

  changeMealTime(time) {
    let formData = this.state.formData;
    formData["mealTime"] = time;
    this.setState({ formData });
  }

  CancelDiet() {
    let field = this.state.formData;

    if (field.patient.dietHistory.length > 0) {
      confirmAlert({
        title: "Confirm to Cancel Diet",
        message: "Do you want to cancel diet?.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              field["treatmentId"] = "";
              field["treatmentName"] = "";
              this.setState({ formData: field });
              this.props.cancelDietAction({ patientId: field.patientId });
              let params = {
                clinicId: localStorage.getItem("clinicId"),
              };
              setTimeout(function () {
                appConstants.socket.emit("updateTaskScreen", params);
              }, 3000);
            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });
    } else {
      field["treatmentId"] = "";
      field["treatmentName"] = "";
      this.setState({ formData: field });
    }
  }

  async getGeneralInfo() {

    let resp = await API.generalInformation({ patientId: this.props.taskScreenData.pId });
    if (resp.data && resp.data.status === "Success") {

      let strDuration = "";
      if (resp.data.data.diabitic === "yes") {


        if (
          resp.data.data.diabtiesDuration?.years === 0 &&
          resp.data.data.diabtiesDuration?.months === 0 &&
          resp.data.data.diabtiesDuration?.days === 0
        ) {
          strDuration = "";
        }
        if (resp.data.data?.diabtiesDuration?.years > 0) {
          strDuration = resp.data.data.diabtiesDuration?.years + " years ";
        }
        if (resp.data.data.diabtiesDuration?.months > 0) {
          strDuration += resp.data.data.diabtiesDuration?.months + " months ";
        }
        if (resp.data.data.diabtiesDuration?.days > 0) {
          strDuration += resp.data.data.diabtiesDuration?.days + " days ";
        }
        //setDuration(strDuration);
      }
      let tempdata = {}
      tempdata.patientName = resp.data.data.firstname + " " + resp.data.data.lastName;
      tempdata.patientId = this.props.taskScreenData.pId;
      tempdata.age = resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "";
      tempdata.height = resp.data.data.height;
      tempdata.weight = resp.data.data.weight;
      tempdata.bmi = resp.data.data.bmi;
      tempdata.bp = resp.data.data.bp;
      tempdata.gender = resp.data.data.gender;
      tempdata.pulse = resp.data.data.pulse;
      tempdata.temperature = resp.data.data.temprature;
      tempdata.diabetesDuration = strDuration;
      tempdata.toggleDuration = resp.data.data.diabitic === "yes" ? true : false;
      //console.log(tempdata, "tttttttttttt")
      this.props.emrSetVitalSignsAction(tempdata);


    }
  };

  render() {
    const bmi = this.state.visitInfo.bmi;
    const c1 = bmi >= 19 && bmi <= 27 ? "weight-case" : "notblink-cases";
    const c2 = bmi > 27 ? "weight-case" : "notblink-cases";
    const c3 = bmi < 19 ? "weight-case" : "notblink-cases";
    // const { formData } = this.state;

    // let diabeticTabActive = "diabetic";
    // if (!this.state.diabeticTab) {
    //     diabeticTabActive = "non-diabetic";
    // }

    return (
      <div
        className="main-content task-screen spc-card"
        style={{ padding: "15px 15px" }}
      >
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Grid fluid>
          <Card
            taskScreen={this.state.taskScreen}
            title={
              <div className="row">
                <div className="col-md-8 col-sm-12 text-left">
                  <div className="title">
                    {this.state.visitInfo.name +
                      "-" +
                      this.state.visitInfo.patientId}
                    &nbsp;&nbsp;&nbsp;&nbsp;|
                    <span>
                      Height{this.state.visitInfo.height != 0 ? ":" : ""}
                      {this.state.visitInfo.height != 0
                        ? `${this.state.visitInfo.height} CM`
                        : ""}
                    </span>
                    |
                    <span>
                      Weight{this.state.visitInfo.weight != 0 ? ":" : ""}
                      {this.state.visitInfo.weight != 0
                        ? `${this.state.visitInfo.weight} KG`
                        : ""}
                    </span>
                    |
                    <span>
                      BMI{this.state.visitInfo.bmi > 0 ? ":" : ""}
                      {this.state.visitInfo.bmi > 0
                        ? this.state.visitInfo.bmi
                        : ""}
                    </span>
                    |
                    <span>
                      Age{this.state.visitInfo.age != 0 ? ":" : ""}
                      {parseFloat(this.state.visitInfo.age).toFixed()} Y /{" "}
                      {this.state.visitInfo.gender === "Male" ? "M" : "F"}
                    </span>
                  </div>
                </div>

                {!this.state.dietChatModal && !this.state.commentModal && (
                  <div className="col-md-4 col-sm-12 text-right historybtn">
                    <History pvDetail={this.state.visitInfo} />
                  </div>
                )}
                {this.props.isHeightWeightValidation && (
                  <div className="col-md-4 col-sm-12 text-right historybtn">
                    <History
                      isOpenModal={this.props.isHeightWeightValidation}
                      pvDetail={this.state.visitInfo}
                    />
                  </div>
                )}
              </div>
            }
            content={
              <div>
                {" "}
                {!this.state.dietChatModal && !this.state.commentModal && (
                  <div>
                    {this.state.ptSearch && (
                      <Row>
                        <Col>
                          <Col className="col-md-3 lineremove flexaling">
                            <legend className="topmessag">
                              <i className="fa fa-envelope"></i>
                              &nbsp;&nbsp;Message For Educator
                            </legend>
                          </Col>

                          <div className="col-md-9">
                            <textarea
                              className="form-control spcmobsn"
                              rows="4"
                              name="remark"
                              id="remark"
                              value={
                                this.state.formData.remark
                                  ? this.state.formData.remark
                                  : ""
                              }
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                              placeholder="Type your own message here..."
                            ></textarea>
                          </div>

                          <div className="col-md-12 col-sm-12">
                            <div className="leftscroll-bar check-spac">
                              {this.state.educator_msg_list.map((key, i) => {
                                if (
                                  key.status === "active" &&
                                  key.clinicShowStatus
                                ) {
                                  //    count++;
                                  // if(count<=12){
                                  return (
                                    <Col sm={3} key={key._id}>
                                      <Checkbox
                                        number={key._id}
                                        checked={this.checkTask(key._id)}
                                        label={key.message}
                                        onClick={(e) => {
                                          this.handleTasksChange(
                                            key._id,
                                            key.message
                                          );
                                        }}
                                      />
                                    </Col>
                                  );
                                  // }
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Col className="lineremove">
                      <div className="bg-Diets ditebar">
                        <div>
                          <legend className="topD">
                            <i className="fa fa-apple"></i>&nbsp;Diet
                          </legend>
                        </div>
                        <div className="spcmobsn mobmange">
                          <CalorieInfo pvDetail={this.state.visitInfo} />
                        </div>

                        {this.state.edShareDiet && (
                          <div className="spcmobsn">
                            <select
                              className="form-control select-calorie"
                              name="calorie"
                              value={this.state.formData.calorie}
                              onChange={(e) => {
                                this.handleChange(e);
                              }}
                            >
                              <option value="0" key={"Calorie"}>
                                {" "}
                                Select Calorie
                              </option>
                              {this.state.calorieOptions}
                            </select>
                          </div>
                        )}

                        {/* <div className="col-md-1 spcmobsn">
                                            <div className="weight-case">
                                                {this.state.visitInfo.calorieType.calorie} Cal.<br></br>
                                                {this.state.visitInfo.calorieType.type}
                                            </div>
                                        </div> */}

                        <div className="meals-sec">
                          <div className="Select_sec">
                            <h4>1st Meal</h4>
                            <div className="weight-case highlight-box">
                              <select
                                className="form-control"
                                name="first_mealtime"
                                id="first_mealtime"
                                value={this.state.formData.mealTime}
                                onChange={(e) => {
                                  this.handleMealTypeChange(e);
                                }}
                              >
                                <option value="" disabled>
                                  1st Meal
                                </option>
                                <option value="07:00 am">07:00 AM</option>
                                <option value="08:00 am">08:00 AM</option>
                                <option value="09:00 am">09:00 AM</option>
                                <option value="10:00 am">10:00 AM</option>
                                <option value="11:00 am">11:00 AM</option>
                              </select>
                            </div>
                          </div>

                          {/* <div className="Select_sec">
                                 <select
                                     className="form-control"
                                     onChange={e=>{this.handelChange(e,'dietOption')}}
                                     name='dietOption'
                                     value={this.state.dietOption}
                                 >
                                   <option value="" disabled selected>Diet Option</option>
                                   <option value="3">3 Option</option>
                                   <option value="2">2 Option</option>
                                   <option value="1">1 Option</option>
                                </select>
                              </div> */}

                          <div className="Select_sec">
                            <h4>Meal Type</h4>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.handelChange(e, "mealType");
                              }}
                              name="mealType"
                              value={this.state.formData.mealType}
                            >
                              <option value="" disabled>
                                Meal Type
                              </option>
                              <option value="veg-s">Vegetarian</option>
                              <option value="non-veg-s">Non Vegetarian</option>
                            </select>
                          </div>

                          <div className="Select_sec">
                            <h4>Diet Language</h4>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.handelChange(e, "dietLanguage");
                              }}
                              name="dietLanguage"
                              value={this.state.dietLanguage}
                            >
                              <option value="" disabled selected>
                                Diet Language
                              </option>
                              {this.state.languageList.length > 0 &&
                                this.state.languageList.map((lang, index) => {
                                  return (
                                    <option value={lang.value} key={index}>
                                      {lang.label}
                                    </option>
                                  );
                                })}
                              {/* <option value="2">Hindi</option>
                                   <option value="1">English</option>
                                   <option value="3">Marathi</option>
                                   <option value="4">Gujarati</option> */}
                            </select>
                          </div>
                        </div>
                        <div className="dite_blue_box">
                          {this.state.visitInfo.c1 != "NaN" &&
                            this.state.visitInfo.c1 != 0 && (
                              <div className="spcmobsn">
                                <div className={c1}>
                                  {this.state.visitInfo.c1} Cal.<br></br>
                                  Maintain Weight
                                </div>
                              </div>
                            )}
                          {this.state.visitInfo.c2 != "NaN" &&
                            this.state.visitInfo.c2 != 0 && (
                              <div className="spcmobsn">
                                <div className={c2}>
                                  {this.state.visitInfo.c2} Cal.<br></br>
                                  Loose Weight
                                </div>
                              </div>
                            )}
                          {this.state.visitInfo.c3 != "NaN" &&
                            this.state.visitInfo.c3 != 0 && (
                              <div className="spcmobsn">
                                <div className={c3}>
                                  {this.state.visitInfo.c3} Cal.<br></br>
                                  Gain Weight
                                </div>
                              </div>
                            )}
                        </div>

                        {/*<div className="spc-tascreans">
                                            <Tab.Container id="tabs-with-dropdown" activeKey={diabeticTabActive}>
                                                <Panel header={
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Nav bsStyle="tabs">
                                                                <NavItem eventKey="diabetic" onClick={e => { this.treatmentTabChange('diabetic') }}>
                                                                    Diabetic Diet
                                                                    {!this.state.diabeticTab &&
                                                                        <i className="fa fa-plus"></i>
                                                                    }
                                                                    {this.state.diabeticTab &&
                                                                        <i className="fa fa-minus"></i>
                                                                    }
                                                                </NavItem>
                                                                <NavItem eventKey="non-diabetic" onClick={e => { this.treatmentTabChange('non-diabetic') }}>
                                                                    Non Diabetic Diet
                                                                    {this.state.diabeticTab &&
                                                                        <i className="fa fa-plus"></i>
                                                                    }
                                                                    {!this.state.diabeticTab &&
                                                                        <i className="fa fa-minus"></i>
                                                                    }
                                                                </NavItem>
                                                            </Nav>
                                                        </Col>
                                                    </Row>
                                                } eventKey="3" />
                                            </Tab.Container>
                                        </div> */}
                        <div className="dite_btn_group">
                          {this.state.edShareDiet && (
                            <div className="mobmange">
                              <Button
                                simple
                                className="right0 btn-fill btn btn-warning pull-right"
                                onClick={() => this.CancelDiet()}
                              >
                                Cancel Diet
                              </Button>
                            </div>
                          )}

                          {/* <div className="mobmange">
                                            <Button simple className="right0 btn-fill btn btn-warning pull-right" onClick={(e) => {
                                                this.openGraphModal(e, 'clock')
                                            }
                                          }>Diet Pattern</Button>

                                        </div> */}

                          {/*<div className="mobmange">
                                            <Button simple className="right0 btn-fill btn btn-warning pull-right" onClick={(e) => {
                                                this.openGraphModal(e, 'graph')
                                            }
                                          }><i className="fa fa-bar-chart" aria-hidden="true"></i><i className="fa fa-clock-o" aria-hidden="true"></i></Button>

                                        </div> */}
                        </div>
                      </div>

                      <div className="tabcont-spc">
                        <div className="sections-scrol">
                          <div>
                            {this.state.diabeticDiet &&
                              this.state.diabeticDiet.map((treatment, i) => {
                                if (treatment.name !== "Pregnancy Diabetes") {
                                  return (
                                    <Col
                                      sm={4}
                                      className="padding-5"
                                      key={treatment.name}
                                    >
                                      <legend>{treatment.name}</legend>
                                      {treatment.treatments &&
                                        treatment.treatments.map((diet, k) => {
                                          return (
                                            <Col
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="spc-Radio"
                                              key={i + k}
                                            >
                                              <Radio
                                                number={diet._id}
                                                key={diet._id}
                                                option={diet._id}
                                                name="treatmentId"
                                                onClick={(e) => {
                                                  this.handleDietChange(
                                                    diet._id,
                                                    diet.name,
                                                    treatment
                                                  );
                                                }}
                                                label={diet.name}
                                                checked={
                                                  diet._id ===
                                                  this.state.formData
                                                    .treatmentId
                                                }
                                                disabled={
                                                  !this.state.edShareDiet &&
                                                  localStorage.getItem(
                                                    "userType"
                                                  ) === "educator" &&
                                                  diet._id !==
                                                  this.state.formData
                                                    .treatmentId
                                                }
                                              />
                                            </Col>
                                          );
                                        })}
                                      {treatment.name ===
                                        "TYPE-2 DM ON OAD" && (
                                          <legend>Pregnancy Diabetes</legend>
                                        )}
                                      {treatment.name === "TYPE-2 DM ON OAD" &&
                                        this.state.diabeticDiet[i + 1]
                                          .treatments &&
                                        this.state.diabeticDiet[
                                          i + 1
                                        ].treatments.map((diet, k) => {
                                          return (
                                            <Col
                                              md={12}
                                              sm={12}
                                              xs={12}
                                              className="spc-Radio"
                                              key={i + k}
                                            >
                                              <Radio
                                                number={diet._id}
                                                key={diet._id}
                                                option={diet._id}
                                                name="treatmentId"
                                                onClick={(e) => {
                                                  this.handleDietChange(
                                                    diet._id,
                                                    diet.name,
                                                    this.state.diabeticDiet[
                                                    i + 1
                                                    ]
                                                  );
                                                }}
                                                label={diet.name}
                                                checked={
                                                  diet._id ===
                                                  this.state.formData
                                                    .treatmentId
                                                }
                                                disabled={
                                                  !this.state.edShareDiet &&
                                                  localStorage.getItem(
                                                    "userType"
                                                  ) === "educator" &&
                                                  diet._id !==
                                                  this.state.formData
                                                    .treatmentId
                                                }
                                              />
                                            </Col>
                                          );
                                        })}
                                    </Col>
                                  );
                                } else {
                                  return "";
                                }
                              })}

                            {this.state.nonDiabeticDiet &&
                              this.state.nonDiabeticDiet.map((treatment) => (
                                <Col
                                  sm={4}
                                  className="padding-5"
                                  key={treatment.name}
                                >
                                  <legend>{treatment.name}</legend>
                                  {treatment.treatments &&
                                    treatment.treatments.map((diet, j) => {
                                      return (
                                        <Col
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className="spc-Radio"
                                          key={diet._id}
                                        >
                                          <Radio
                                            number={diet._id}
                                            key={diet._id}
                                            option={diet._id}
                                            name="treatmentId"
                                            onClick={(e) => {
                                              this.handleDietChange(
                                                diet._id,
                                                diet.name,
                                                treatment
                                              );
                                            }}
                                            label={diet.name}
                                            checked={
                                              diet._id ===
                                              this.state.formData.treatmentId
                                            }
                                            disabled={
                                              !this.state.edShareDiet &&
                                              localStorage.getItem(
                                                "userType"
                                              ) === "educator" &&
                                              diet._id !==
                                              this.state.formData.treatmentId
                                            }
                                          />
                                        </Col>
                                      );
                                    })}
                                </Col>
                              ))}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <div className="bg_greysectionss">
                      {
                        //this.state.edShareDiet &&
                      }

                      <div>
                        <button
                          type="button"
                          className="btn-fill btn btn-primary btn btn-default mangeBtns"
                          onClick={(e) => {
                            this.dietChart();
                          }}
                          disabled={
                            this.state.formData.treatmentId ? false : true
                          }
                        >
                          <img src="../../../images/diet_img.png" alt="" />
                          Diet Chart
                        </button>
                        <button
                          type="button"
                          className="btn-fill btn btn-primary btn btn-default mangeBtns"
                          onClick={(e) => {
                            this.openGraphModal(e, "clock");
                          }}
                          disabled={
                            this.state.formData.treatmentId ? false : true
                          }
                        >
                          <img src="../../../images/diet_pattern.png" alt="" />{" "}
                          Diet Pattern
                        </button>

                        {/* { this.state.edShareDiet &&
                                 <button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e=>{this.comments()}} disabled={this.state.formData.treatmentId?false:true} ><img src="../../../images/comments_ico.png"/>Diet Guideline </button>
                               } */}

                        <button
                          type="button"
                          className="btn-fill btn btn-primary btn btn-default mangeBtns"
                          onClick={(e) => {
                            this.comments();
                          }}
                          disabled={
                            this.state.formData.treatmentId ? false : true
                          }
                        >
                          <img src="../../../images/comments_ico.png" alt="" />
                          Diet Guideline
                        </button>

                        {/* <button
                          type="button"
                          className="btn-fill btn btn-primary btn btn-default mangeBtns"
                          disabled={
                            this.state.formData.app &&
                              this.state.formData.treatmentId
                              ? false
                              : true
                          }
                          onClick={(e) => {
                            this.shareDiet("app");
                          }}
                        >
                          <img src="../../../images/comments_ico.png" alt="" />
                          Share on App
                        </button> */}
                        {/* <button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" disabled={this.state.formData.email && localStorage.getItem("is_email_facility") === "yes" && this.state.formData.treatmentId?false:true} onClick={e=>{this.shareDiet('email')}}><img src="../../../images/comments_ico.png" alt=""/>Share on Email</button>*/}

                        {
                          localStorage.getItem("educatorDietStatus") ===
                          "yes" &&
                          localStorage.getItem("userType") === "educator" && (
                            <button
                              type="button"
                              className="btn-fill btn btn-primary btn btn-default mangeBtns"
                              disabled={
                                this.state.formData.treatmentId ? false : true
                              }
                              onClick={(e) => {
                                this.editDietByEducator(e);
                              }}
                            >
                              <img
                                src="../../../images/comments_ico.png"
                                alt=""
                              />
                              Edit
                            </button>
                          )}
                      </div>
                      <div className="col-md-1">
                        <div className="text-right subbtn">
                          <Button
                            onClick={(e) => {
                              this.submitTaskConfirm(e);
                            }}
                            simple
                            className="btn-fill btn btn-primary"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>

                    {this.state.edShareKnowledge && (
                      <div className="pops-imgcenter educator-end">
                        <Col
                          className="knowledge-share doctor-popss-12 abc lineremove"
                          sm={12}
                        >
                          <legend className="topmessag">My Knowledge</legend>
                          <div>
                            {/* <Col className="col-md-12 lineremove flexaling">
                                  <legend className="topmessag">
                                      My Knowledge
                                  </legend>
                              </Col> */}
                            <div>
                              <KnowledgeShare
                                submitTask={(e) => {
                                  this.submitTask(e);
                                }}
                                handleKnowledgeDocChange={(row) => {
                                  this.handleKnowledgeDocChange(row);
                                }}
                                handleKnowledgeVideoChange={(row) => {
                                  this.handleKnowledgeVideoChange(row);
                                }}
                                pvDetail={this.state.visitInfo}
                                documents={this.state.formData.documents}
                                videos={this.state.formData.videos}
                                treatmentId={this.state.formData.treatmentId}
                              />
                            </div>
                            <div>
                              <div className="text-right subbtn">
                                <Button
                                  onClick={(e) => {
                                    this.submitTaskConfirm(e);
                                  }}
                                  simple
                                  className="btn-fill btn btn-primary"
                                >
                                  Submit
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    )}

                    <Row>
                      <Col sm={12} className="pd00">
                        <Modal
                          show={this.state.graphModal}
                          onHide={() => this.setState({ graphModal: false })}
                          dialogClassName="modal-lg dite_graph_modal"
                        >
                          <Modal.Header closeButton className="bg-thumbcolor">
                            <Modal.Title id="example-modal-sizes-title-lg">
                              {this.state.visitInfo.name +
                                "-" +
                                this.state.visitInfo.patientId}
                              &nbsp;&nbsp;&nbsp;&nbsp;|
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0 5px",
                                }}
                              >
                                Height
                                {this.props.vitalSignsData &&
                                  this.props.vitalSignsData?.height != 0
                                  ? ":"
                                  : this.state.visitInfo.height != 0
                                    ? ":"
                                    : ""}
                                {this.props.vitalSignsData
                                  ? this.props.vitalSignsData.height != ""
                                    ? `${this.props.vitalSignsData.height} CM`
                                    : ""
                                  : this.state.visitInfo.height != 0
                                    ? `${this.state.visitInfo.height} CM`
                                    : ""}
                              </span>
                              |
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0 5px",
                                }}
                              >
                                Weight
                                {this.props.vitalSignsData &&
                                  this.props.vitalSignsData?.weight != 0
                                  ? ":"
                                  : this.state.visitInfo.weight != 0
                                    ? ":"
                                    : ""}
                                {this.props.vitalSignsData
                                  ? this.props.vitalSignsData.weight != ""
                                    ? `${this.props.vitalSignsData.weight} KG`
                                    : ""
                                  : this.state.visitInfo.weight != 0
                                    ? `${this.state.visitInfo.weight} KG`
                                    : ""}
                              </span>
                              |
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0 5px",
                                }}
                              >
                                BMI
                                {this.props.vitalSignsData &&
                                  this.props.vitalSignsData?.bmi > 0
                                  ? ":"
                                  : this.state.visitInfo.bmi > 0
                                    ? ":"
                                    : ""}{" "}
                                {this.props.vitalSignsData.bmi
                                  ? this.props.vitalSignsData.bmi > 0
                                    ? this.props.vitalSignsData.bmi
                                    : ""
                                  : this.state.visitInfo.bmi > 0
                                    ? this.state.visitInfo.bmi
                                    : ""}
                              </span>
                              |
                              <span
                                style={{
                                  display: "inline-block",
                                  margin: "0 5px",
                                }}
                              >
                                Age
                                {this.props.vitalSignsData &&
                                  this.props.vitalSignsData?.age != 0
                                  ? ":"
                                  : this.state.visitInfo.age != 0
                                    ? ":"
                                    : ""}{" "}
                                {parseFloat(
                                  this.props.vitalSignsData.age
                                    ? this.props.vitalSignsData.age
                                    : this.state.visitInfo.age
                                ).toFixed()}
                                {` Y / ${this.state.visitInfo.gender === "Male"
                                  ? "M"
                                  : "F"
                                  }`}
                              </span>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="Knowledge-Share">
                            <Row>
                              <Col md={12}>
                                <Form horizontal>
                                  <Row>
                                    <Col
                                      sm={12}
                                      className="mange-spctop noniborder"
                                    >
                                      <Row>
                                        {/* <iframe
                                          src={
                                            appConstants.paAppURL +
                                            "diet_graph_webview?id=" +
                                            this.state.graphId +
                                            "&calorie=" +
                                            this.state.graphCalorie +
                                            "&lang=" +
                                            this.state.dietLanguage +
                                            "&time=" +
                                            this.state.mealTimeForGraph
                                          }
                                          width="100%"
                                          height="600px"
                                          title="Graph Calorie"
                                        ></iframe> */}
                                        <object data={`${appConstants.paAppURL}diet_graph_webview?id=${this.state.graphId}&calorie=${this.state.graphCalorie}&lang=${this.state.dietLanguage}&time=${this.state.mealTimeForGraph}`} style={{ width: "100%", height: "600px" }}></object>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                            </Row>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="btn-fill btn btn-secondary pull-right"
                              onClick={() =>
                                this.setState({ graphModal: false })
                              }
                            >
                              Cancel
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} className="pd00 error-modal">
                        <Modal
                          show={this.state.errorMsgModal}
                          onHide={() => this.setState({ errorMsgModal: false })}
                          dialogClassName=""
                          className="Modal_downsearch"
                        >
                          <Modal.Header
                            closeButton
                            className="bg-thumbcolor-box"
                          >
                            <Modal.Title id="example-modal-sizes-title-lg">
                              {" "}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="Knowledge-Share">
                            <Row>
                              <Col md={12}>
                                <div className="error-icon">
                                  <i
                                    className="fa fa-exclamation-triangle"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="err-text">{errorMsg}</div>
                              </Col>
                              <Col md={12}></Col>
                            </Row>
                          </Modal.Body>
                          {/*<Modal.Footer>
                                          <Button className="btn-fill btn btn-primary pull-right" onClick={() => this.setState({ errorMsgModal: false })}>OK</Button>
                                      </Modal.Footer> */}
                        </Modal>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            }
          />
        </Grid>

        <Row>
          {this.state.dietChatModal && (
            <div>
              <Col sm={12} className="pd00">
                <Modal
                  show={this.state.dietChatModal}
                  onHide={() => this.setState({ dietChatModal: false })}
                  dialogClassName="modal-lg dite_graph_modal"
                >
                  <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <div className="row">
                        <div className="col-md-8 col-sm-12 text-left">
                          <div className="title">
                            {this.state.visitInfo.name +
                              "-" +
                              this.state.visitInfo.patientId}
                            &nbsp;&nbsp;&nbsp;&nbsp;|
                            <span>
                              {" "}
                              Height{" "}
                              {this.state.visitInfo.height != 0 ? ":" : ""}
                              {this.state.visitInfo.height != 0
                                ? `${this.state.visitInfo.height} CM`
                                : ""}
                            </span>
                            |
                            <span>
                              {" "}
                              Weight{" "}
                              {this.state.visitInfo.weight != 0 ? ":" : ""}
                              {this.state.visitInfo.weight != 0
                                ? `${this.state.visitInfo.weight} KG`
                                : ""}
                            </span>
                            |
                            <span>
                              {" "}
                              BMI {this.state.visitInfo.bmi > 0 ? ":" : ""}
                              {this.state.visitInfo.bmi > 0
                                ? this.state.visitInfo.bmi
                                : ""}
                            </span>
                            |
                            <span>
                              Age {this.state.visitInfo.age != 0 ? ":" : ""}
                              {parseFloat(this.state.visitInfo.age).toFixed()} Y
                              /{" "}
                              {this.state.visitInfo.gender === "Male"
                                ? "M"
                                : "F"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="Knowledge-Share">
                    <DietChat
                      state={this.state.formData}
                      backPage={this.backPage}
                      dietId={this.state.formData.dietId}
                      onClickTime={this.changeMealTime}
                      color={this.state.color}
                    />
                  </Modal.Body>
                </Modal>
              </Col>
            </div>
          )}
          {this.state.commentModal && (
            <div>
              <Col sm={12} className="pd00">
                <Modal
                  show={this.state.commentModal}
                  onHide={() => this.setState({ commentModal: false })}
                  dialogClassName="modal-lg dite_graph_modal"
                >
                  <Modal.Header closeButton className="bg-thumbcolor">
                    <Modal.Title id="example-modal-sizes-title-lg">
                      <div className="row">
                        <div className="col-md-8 col-sm-12 text-left">
                          <div className="title">
                            {this.state.visitInfo.name +
                              "-" +
                              this.state.visitInfo.patientId}
                            &nbsp;&nbsp;&nbsp;&nbsp;|
                            <span>
                              {" "}
                              Height{" "}
                              {this.props.vitalSignsData &&
                                this.props.vitalSignsData?.height != 0
                                ? ":"
                                : this.state.visitInfo.height != 0
                                  ? ":"
                                  : ""}
                              {this.props.vitalSignsData
                                ? this.props.vitalSignsData.height != ""
                                  ? `${this.props.vitalSignsData.height} CM`
                                  : ""
                                : this.state.visitInfo.height != 0
                                  ? `${this.state.visitInfo.height} CM`
                                  : ""}
                            </span>
                            |
                            <span>
                              {" "}
                              Weight
                              {this.props.vitalSignsData &&
                                this.props.vitalSignsData?.weight != 0
                                ? ":"
                                : this.state.visitInfo.weight != 0
                                  ? ":"
                                  : ""}
                              {this.props.vitalSignsData
                                ? this.props.vitalSignsData.weight != ""
                                  ? `${this.props.vitalSignsData.weight} KG`
                                  : ""
                                : this.state.visitInfo.weight != 0
                                  ? `${this.state.visitInfo.weight} KG`
                                  : ""}
                            </span>
                            |
                            <span>
                              {" "}
                              BMI {this.props.vitalSignsData &&
                                this.props.vitalSignsData?.bmi > 0
                                ? ":"
                                : this.state.visitInfo.bmi > 0
                                  ? ":"
                                  : ""}{" "}
                              {this.props.vitalSignsData.bmi
                                ? this.props.vitalSignsData.bmi > 0
                                  ? this.props.vitalSignsData.bmi
                                  : ""
                                : this.state.visitInfo.bmi > 0
                                  ? this.state.visitInfo.bmi
                                  : ""}
                            </span>
                            |
                            <span>
                              Age
                              {this.props.vitalSignsData &&
                                this.props.vitalSignsData?.age != 0
                                ? ":"
                                : this.state.visitInfo.age != 0
                                  ? ":"
                                  : ""}{" "}
                              {parseFloat(
                                this.props.vitalSignsData.age
                                  ? this.props.vitalSignsData.age
                                  : this.state.visitInfo.age
                              ).toFixed()}
                              {` Y / ${this.state.visitInfo.gender === "Male"
                                ? "M"
                                : "F"
                                }`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="Knowledge-Share">
                    <Comments
                      state={this.state.formData}
                      onClickFun={this.handleChangeDiet}
                      onClickFile={this.handleChangeFile}
                      backPage={this.backPage}
                      dietId={this.state.dietId}
                      color={this.state.color}
                      languageList={this.state.languageList}
                      nextPageChanges={this.nextPageChanges}
                    />
                  </Modal.Body>
                </Modal>
              </Col>
            </div>
          )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    EducatorMsgList: state.home.EducatorMsgList,
    isEducatorMsgList: state.home.isEducatorMsgList,
    isEducatorMsgListError: state.home.isEducatorMsgListError,

    treatmentWithTypeList: state.master.treatmentWithTypeList,

    isAddTask: state.master.isAddTask,
    isAddTaskError: state.master.isAddTaskError,
    AddTask: state.master.AddTask,

    TaskList: state.home.TaskList,
    isTaskList: state.home.isTaskList,
    isTaskListError: state.home.isTaskListError,

    isDietShare: state.diet.isDietShare,
    timeList: state.master.timeList,

    LanguageList: state.language.LanguageList,
    isLanguageList: state.language.isLanguageList,
    isLanguageListError: state.language.isLanguageListError,
    vitalSignsData: state.emr.vitalSignsData,
    emrData: state.emr,
    isHeightWeightValidation: state.taskScreen.isHeightWeightValidation,

  };
}
export default withRouter(
  connect(mapStateToProps, {
    addTaskAction,
    treatmentWithTypesAction,
    educatorMsgListAction,
    taskListAction,
    printDietColorChangeAction,
    cancelDietAction,
    sharetDietAction,
    timeListAction,
    languageListAction,
    taskScreenAction,
    taskScreenHeightWeightRequireForCaloryAction,
    emrSetVitalSignsAction
  })(TaskScreen)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import { Row, Col, Grid, FormControl } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/master";
//import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import { appConstants } from "Front/_constants/app.constants.js";
import ViewBox from "../../components/ViewBox";
import { postRequestWithToken } from "../../api/helper";
import ReactPlayer from "react-player";
import moment from "moment";
import UnFavourite from "../../assets/img/icons/un-favorite.svg";
import Favourite from "../../assets/img/icons/favorite.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
const ClinicDocument = () => {
  const [documentList, setDocumentList] = useState([]);
  const [ViewBoxData, setViewBoxData] = useState({
    show: false,
    type: "image",
    path: "",
  });
  const [formData, setFormData] = useState({
    page: 0,
    limit: 10,
    order: "createdAt",
    direction: "desc",
    search: "",
  });
  const [totalCount, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [alert, setAlert] = useState(null);
  const notificationSystem = useState(null);

  const successAlert = (msg) => {
    notificationSystem.current.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
    // setAlert(
    //   <SweetAlert
    //     success
    //     style={{ display: "block", marginTop: "-100px" }}
    //     title="Success"
    //     onConfirm={() => setAlert(null)}
    //     confirmBtnBsStyle="info"
    //   >
    //     {msg}
    //   </SweetAlert>
    // );
    // setTimeout(function () {
    //   setAlert(null);
    // }, 2000);
  };

  const getClinicDocumentListAction = async (data) => {
    setIsLoading(true);
    let resp = await API.getClinicDocumentlist(data);
    let respData = resp.data.data.data;
    if (respData) {
      for (let i in respData) {
        if (respData[i].favourite && respData[i].favourite.length > 0) {
          var localindex_index = respData[i].favourite
            .map(function (el) {
              return el;
            })
            .indexOf(clinicId);
          if (localindex_index != -1) {
            respData[i].isFavourite = true;
          } else {
            respData[i].isFavourite = false;
          }
        } else {
          respData[i].isFavourite = false;
        }
      }
    }

    setIsLoading(false);
    setDocumentList(respData);
    setTotal(resp.data.data.count);
  };

  useEffect(() => {
    getClinicDocumentListAction({ page: 0, limit: 10 });
    let params = {
      clinic_id: localStorage.getItem("clinicId"),
      libraryCount: 0,
    };
    appConstants.socket.emit("countUpdate", params);
  }, []);

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  };

  const onPageChange = (page, sizePerPage) => {
    let data = formData;
    data["page"] = page - 1;
    data["limit"] = sizePerPage;
    data["search"] = data.search;
    setFormData({ ...formData, ...data });
    getClinicDocumentListAction(data);
  };

  const srNo = (cell, row, enumObject, rowIndex) => {
    return rowIndex + 1 + formData.limit * formData.page;
  };

  const viewDocu = (cell, row, enumObject, rowIndex) => {
    //console.log(row);
    if (row.path) {
      return (
        <div>
          <a onClick={(e) => viewDocument(row)}>View Document</a>
        </div>
      );
    }
  };

  const viewDocument = (row) => {
    setViewBoxData({
      show: true,
      type: "image",
      title: "Document",
      path: row.path,
    });
  };

  const onSearchChange = (e) => {
    //if (text !== "") {
    //if (text.length >= 3) {
    let data = formData;
    data["page"] = 0;
    data["limit"] = 10;
    data["search"] = e.target.value;
    setFormData({ ...formData, ...data });
    getClinicDocumentListAction(data);
    // }
    //}
  };

  const onSelectType = (e) => {
    if (e.target.value !== "") {
      let data = formData;
      data["page"] = 0;
      data["limit"] = 10;
      data["type"] = e.target.value;
      setFormData({ ...formData, ...data });
      getClinicDocumentListAction(data);
    }
  };

  const onFavouriteFilter = (e) => {
    let data = formData;
    data["page"] = 0;
    data["limit"] = 10;
    data["isFavourite"] = true;
    setFormData({ ...formData, ...data });
    getClinicDocumentListAction(data);
  };

  const clearSearch = (e) => {
    let data = formData;
    data["page"] = 0;
    data["limit"] = 10;
    data["type"] = "";
    data["search"] = "";
    data["isFavourite"] = false;
    setFormData({ ...formData, ...data });
    getClinicDocumentListAction(data);
  };

  const createCustomClearButton = (onClick) => {
    return (
      <Row>
        <Col md={12}>
          <FormControl
            style={{ width: "200px" }}
            componentClass="select"
            name="slot"
            onChange={(e) => {
              onSelectType(e);
            }}
          >
            <option value="">Select</option>
            <option value="JPGE">JPGE</option>
            <option value="PDF">PDF</option>
            <option value="DOC">DOC</option>
          </FormControl>
        </Col>
      </Row>
    );
  };

  const options = {
    clearSearch: false,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    paginationPosition: "bottom", // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption(),
    //onSearchChange: onSearchChange,
    clearSearchBtn: createCustomClearButton,
    clearSearch: true,
  };

  const hideViewBox = () => {
    setViewBoxData({ show: false, type: "image", path: "" });
  };
  const getSignedUrl = async (path) => {
    let resp = await postRequestWithToken({ filePath: path }, "s3SignedUrl");
    return resp.data.url;
  };

  const addRemoveFavourite = async (id, i) => {
    let data = documentList;
    let msg = data[i].isFavourite
      ? "Removed favourite successfully."
      : "Added favourite successfully.";
    data[i].isFavourite = data[i].isFavourite ? false : true;
    let isFavData = formData;
    if (!data[i].isFavourite && isFavData.isFavourite) {
      data.splice(i, 1);
    }
    setDocumentList(data);
    let resp = await postRequestWithToken({ id: id }, "add-remove-favourite");
    successAlert(msg);
    getClinicDocumentListAction(formData);
  };
  return (
    <div
      className="main-content clinic-document"
      style={{ padding: "15px 0px" }}
    >
      {alert}

      <NotificationSystem ref={notificationSystem} style={style} />
      <Grid fluid>
        {ViewBoxData && ViewBoxData.show && (
          <ViewBox data={ViewBoxData} hideViewBox={(e) => hideViewBox()} />
        )}
        <Row>
          <Col md={12}>
            <Card
              content={
                <div className="fresh-datatables table-responsive-new-withoutScroll clinic_document">
                  <div className="fresh-datatables dashboard">
                    <Row style={{ display: "none" }}>
                      {/* <Row> */}
                      <Col md={6}>
                        <img
                          src="../../../images/library.jpg"
                          class="w-100 mx-h-40"
                          alt="library"
                        />
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <input
                              type="text"
                              onChange={(e) => onSearchChange(e)}
                              className="form-control"
                              value={formData.search ? formData.search : ""}
                            />
                          </Col>
                          <Col md={3}>
                            <FormControl
                              componentClass="select"
                              name="slot"
                              value={formData.type ? formData.type : ""}
                              onChange={(e) => {
                                onSelectType(e);
                              }}
                            >
                              <option disabled value="">
                                Select
                              </option>
                              <option value="JPGE">JPGE</option>
                              <option value="JPG">JPG</option>
                              <option value="PDF">PDF</option>
                              <option value="DOC">DOC</option>
                            </FormControl>
                          </Col>
                          <Col md={3}>
                            <Button onClick={(e) => clearSearch()}>
                              Clear
                            </Button>
                          </Col>
                        </Row>
                        <br />
                        <BootstrapTable
                          deleteRow={false}
                          key={documentList}
                          data={documentList}
                          search={false}
                          pagination={true}
                          options={options}
                          striped
                          hover
                          condensed
                          scrollTop={"Bottom"}
                          remote={true}
                          fetchInfo={{ dataTotalSize: totalCount }}
                          sort={true}
                        >
                          <TableHeaderColumn
                            hidden={true}
                            tdAttr={{ "data-attr": "_id" }}
                            dataField="patientId"
                            dataSort={true}
                            isKey={true}
                            searchable={false}
                          >
                            Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            tdAttr={{ "data-attr": "#" }}
                            thStyle={{ width: "8%" }}
                            tdStyle={{ width: "8%" }}
                            dataField="sn"
                            dataFormat={srNo}
                          >
                            S.No.
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            tdAttr={{ "data-attr": "Name" }}
                            dataField="name"
                          >
                            Document Name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ textAlign: "center" }}
                            tdStyle={{ textAlign: "center" }}
                            tdAttr={{ "data-attr": "Insulin Dose Taken" }}
                            dataField="name"
                            dataFormat={viewDocu}
                          >
                            Document Link
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </Col>
                    </Row>

                    {/* <div
                      className="library-wrapper-outer"
                      style={{ display: "none" }}
                    > */}
                    <div className="library-wrapper-outer">
                      <Row>
                        <Col md={6} lg={6} sm={12}>
                          <div className="note-wrapper">
                            <div class="note-div">
                              <span>*</span> Note -{" "}
                              <img
                                src={
                                  formData.isFavourite ? UnFavourite : Favourite
                                }
                                alt="save"
                              />{" "}
                              ← Click icon to{" "}
                              {formData.isFavourite ? "remove from" : "add in"}
                              <strong>Favourite</strong>
                            </div>
                            <div class="note-div">
                              <span>*</span> Note - Click on the pink area to
                              view the <strong>PDF</strong>
                            </div>
                          </div>
                        </Col>

                        <Col md={6} lg={6} sm={12}>
                          <Row>
                            <Col md={6}>
                              <input
                                type="text"
                                onChange={(e) => onSearchChange(e)}
                                className="form-control"
                                value={formData.search ? formData.search : ""}
                                placeholder="Search.."
                              />
                            </Col>
                            <Col md={2}>
                              <FormControl
                                componentClass="select"
                                name="slot"
                                value={formData.type ? formData.type : ""}
                                onChange={(e) => {
                                  onSelectType(e);
                                }}
                              >
                                <option disabled value="">
                                  Select
                                </option>
                                <option value="JPEG">JPEG</option>
                                <option value="JPG">JPG</option>
                                <option value="png">PNG</option>
                                <option value="PDF">PDF</option>
                                <option value="DOC">DOC</option>
                                <option value="MP4">VIDEO</option>


                              </FormControl>
                            </Col>
                            <Col md={2}>
                              <Button onClick={(e) => clearSearch()}>
                                Clear
                              </Button>
                            </Col>
                            <Col md={2}>
                              {!formData.isFavourite && (
                                <Button
                                  className="btn-fill btn btn-primary"
                                  onClick={(e) => onFavouriteFilter()}
                                >
                                  Favourites
                                </Button>
                              )}
                              {formData.isFavourite && (
                                <Button onClick={(e) => clearSearch()}>
                                  Back
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <section class="library-wrapper">
                        <div class="container-fluid p-0">
                          <div class="row">
                            {documentList &&
                              documentList.length > 0 &&
                              documentList.map((item, i) => {
                                //  let url = getSignedUrl();
                                return (
                                  <div class="col-md-3 col-sm-6">
                                    <div class="main-div" key={i}>
                                      <div class="libary-box">
                                        {item.extension == "jpg" ||
                                          item.extension == "jpeg" ||
                                          item.extension == "png" ? (
                                          <span class="img-span img-type">
                                            Image
                                          </span>
                                        ) : item.extension == "mp4" ? (
                                          <span class="img-span video">
                                            Video
                                          </span>
                                        ) : item.extension == "pdf" ? (
                                          <span class="img-span pdf">PDF</span>
                                        ) : item.extension == "doc" ? (
                                          <span class="img-span doc">Doc</span>
                                        ) : (
                                          ""
                                        )}

                                        {/* {item.extension === "mp4" &&
                                          <div>
                                            <ReactPlayer className='react-player' width='100%' height='100%' url={url} playing controls="true" volume="1" />
                                          </div>
                                        } */}
                                        {/* <img className="card_img" src="../../../images/clinic.jpg" alt="img" /> */}

                                        {/* {item.extension == 'jpg' ? <span class="thumb-type thumb-img"><img className="card_img" src="../../../images/img-type.png" alt="img" /></span> : (item.extension == 'mp4' ? <span class="thumb-type thumb-video"><img className="card_img" src="../../../images/video-type.png" alt="Video" /></span> : (item.extension == 'pdf' ? <span class="thumb-type thumb-pdf"><img className="card_img" src="../../../images/pdf-type.png" alt="PDF" /></span> : (item.extension == 'doc' ? <span class="thumb-type thumb-doc"><img className="card_img" src="../../../images/doc-type.png" alt="Doc" /></span> : (item.extension == 'ppt' ? <span class="thumb-type thumb-ppt"><img className="card_img" src="../../../images/ppt-type.png" alt="PPT" /></span> : <span><img className="card_img" src="../../../images/img-type.png" alt="img" /></span>))))} */}
                                        {item.extension == "jpg" ||
                                          item.extension == "jpeg" ||
                                          item.extension == "png" ? (
                                          <span
                                            class="thumb-type thumb-img"
                                            onClick={(e) => viewDocument(item)}
                                          >
                                            <img
                                              className="card_img"
                                              src={item.url}
                                              alt="img"
                                            />
                                          </span>
                                        ) : item.extension == "mp4" ? (
                                          <span
                                            class="thumb-type thumb-video"
                                            onClick={(e) => viewDocument(item)}
                                          >
                                            <ReactPlayer
                                              className="react-player"
                                              width="100%"
                                              height="100%"
                                              url={item.url}
                                            // playing
                                            // controls="true"
                                            // volume="1"
                                            />
                                          </span>
                                        ) : item.extension == "pdf" ? (
                                          <span
                                            className="thumb-type thumb-pdf"
                                            onClick={(e) => viewDocument(item)}
                                          >
                                            <object
                                              data={item.url}
                                              type="application/pdf"
                                              onClick={(e) =>
                                                viewDocument(item)
                                              }
                                            >
                                              {" "}
                                            </object>

                                            {/* <iframe src={item.url}></iframe> */}
                                          </span>
                                        ) : item.extension == "doc" ? (
                                          <span
                                            class="thumb-type thumb-doc"
                                            onClick={(e) => viewDocument(item)}
                                          >
                                            <img
                                              className="card_img"
                                              src="../../../images/doc-type.png"
                                              alt="Doc"
                                            />
                                          </span>
                                        ) : item.extension == "ppt" ? (
                                          <span
                                            class="thumb-type thumb-ppt"
                                            onClick={(e) => viewDocument(item)}
                                          >
                                            <img
                                              className="card_img"
                                              src="../../../images/ppt-type.png"
                                              alt="PPT"
                                            />
                                          </span>
                                        ) : (
                                          <span>
                                            <img
                                              className="card_img"
                                              src="../../../images/img-type.png"
                                              alt="img"
                                            />
                                          </span>
                                        )}
                                        <div class="libary-detail">
                                          <ul>
                                            <li>
                                              <h3>{item.name}</h3>
                                            </li>
                                            <li class="favorite-ic">
                                              <div
                                                onClick={() => {
                                                  addRemoveFavourite(
                                                    item._id,
                                                    i
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={
                                                    item.isFavourite
                                                      ? UnFavourite
                                                      : Favourite
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            </li>
                                            <li class="upload-date">
                                              {moment(item.createdAt).format(
                                                "D-MMM-YYYY"
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {documentList &&
                              documentList.length == 0 &&
                              !isLoading && (
                                <div style={{ textAlign: "center", margin: "115px" }}><b>No Data Found.</b></div>
                              )}
                            {isLoading && (
                              <div className="col-xs-12 text-center">
                                <Loading
                                  type="bars"
                                  color="#000000"
                                  style={{ margin: "0px auto", width: "40px" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
export default withRouter(ClinicDocument);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from "Front/actions/actionTypes";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import moment from "moment";

const initState = {
  response: [],
  message: "",
  isVisitList: false,
  isVisitListError: false,
  VisitList: [],
  eVisitList: [],
  outPatientList: [],
  visitListActiveTab: "offline",
  otherVisitDate: false,
  visitCount: 0,
  eVisitCount: 0,
  outPatientCount: 0,
  isOutListApiCalled: false,
  isOnilneApiCalled: false,
  isOfflineApiCalled: false,
  isTaskListUpdated: false,
  isDietShared: false,
  isKnowledgeShared: false,
  visitDetail: {},
  isPrescriptionUpdated: false,
  isPatientDetialUpdated: false,
  isVisitListUpdateS3SignedUrl: false,
  isVisitListUpdateS3SignedUrlOnlineList: false,
  isVisitListUpdateS3SignedUrlOutList: false,
  isReportUnreadCount: false,
  isStarStatusList: false,
  isStarStatusListError: false,
  starStatusList: [],
  isReloadHome: false,

  isTagList: false,
  isTagListError: false,
  TagList: [],

  isS3SignedUrlArr: false,
  isS3SignedUrlArrError: false,

  isVisitDetail: false,
  isVisitDetailError: false,

  isPatientRegistration: false,
  isPatientRegistrationError: false,
  PatientRegistration: false,

  isPatientEdit: false,
  isPatientEditError: false,
  PatientEdit: false,

  isPatientOut: false,
  isPatientOutError: false,
  PatientOut: false,

  isNextVisit: false,
  isNextVisitError: false,
  NextVisit: false,

  isHolidayList: false,
  isHolidayListError: false,
  HolidayList: false,

  isEducatorMsgList: false,
  isEducatorMsgListError: false,
  EducatorMsgList: false,

  isCategoryList: false,
  isCategoryListError: false,
  categoryList: false,

  isKnowledgeList: false,
  isKnowledgeListError: false,
  knowledgeList: false,

  isPrintShareDocument: false,
  isPrintShareDocumentError: false,
  printShareDocument: false,

  isTaskList: false,
  isTaskListError: false,
  taskList: false,

  isChangeStatus: false,
  isChangeStatusError: false,
  changeStatus: false,

  isHistory: false,
  isHistoryError: false,
  History: false,

  PatientDetail: false,
  isPatientDetail: false,
  isPatientDetailError: false,

  PatientStatus: false,
  isPatientStatus: false,
  isPatientStatusError: false,

  PatientNotification: false,
  isPatientNotification: false,
  isPatientNotificationError: false,

  myClinicList: false,
  isMyClinicList: false,
  isMyClinicListError: false,

  msgKnowledgeList: false,
  isMsgKnowledgeList: false,
  isMsgKnowledgeListError: false,

  dietHisrotyList: false,
  isdietHisrotyList: false,
  isdietHisrotyListError: false,

  isVisitListUpdated: false,

  isAddToVisitList: false,

  isPatientRemarkUpdated: false,

  isS3SignedUrlArrUploadDocumentClinic: false,
  isS3SignedUrlArrErrorUploadDocumentClinic: false,
  s3SignedUrlArrClinic: [],

  isS3SignedUrlArrUploadDocumentAdmin: false,
  isS3SignedUrlArrErrorUploadDocumentAdmin: false,
  s3SignedUrlArrAdmin: [],
};

const filterVisitList = (data) => {
  let j = 0;
  let tempVisitList = [];

  let blackArr = [];
  let yellowArr = [];
  let whiteArr = [];

  if (data && data.length > 0) {
    for (let x in data) {
      let key = data[x];

      let patient = key.patient_data[0];
      if (patient == undefined) {
        continue;
      }
      let h = patient.height;
      let w = patient.weight;
      let g = patient.gender;
      let row = {
        id: key._id,
        _id: patient._id,
        sn: ++j,
        pId: patient._id,
        patientNo: patient.patientNo,
        patientId: patient.patientClinicId,
        name: patient.title + " " + patient.firstName + " " + patient.lastName,
        firstName: patient.firstName,
        lastName: patient.lastName,
        city: patient.city,
        age_color: patient.age_color,
        share: "",
        read_share: "",
        task: "",
        complete_task: key.complete_tasks_count,
        diet: 0,
        in_time: moment(key.createdAt).format("hh:mm A"),
        next_visit_date: patient.nextDate,
        app: patient.app,
        email: patient.email,
        status: key.status,
        height: h,
        weight: w,
        bmi: PF.getBMI(h, w),
        ibw: PF.getIBW(h, w, g),
        c1: PF.getMaintainWeight(h, w, g),
        c2: PF.getLooseWeight(h, w, g),
        c3: PF.getGainWeight(h, w, g),
        segCalorie: PF.getCalorie(h, w, g),
        calorieType: PF.getCalorieType(h, w, g),
        created_date: moment(key.createdAt).format("YYYY-MM-DD"),
        remark: patient.remark ? patient.remark : "",
        patientRemark: patient.remark,
        taskList: [],
        documentList: [],
        doctorOut: key.doctorOut,
        educatorOut: key.educatorOut,
        lastDiet: patient.lastDiet,
        notification_token: patient.notification_token,
        updateDate: moment(key.updatedAt).format("YYYY-MM-DD"),
        createDate: moment(key.createdAt).format("YYYY-MM-DD"),
        dietHistory: [],
        doseRead: patient.doseRead,
        insulinDose: patient.insulinDose,
        econsultation: key.econsultation,
        reportCount: key.reportCount,
        patientColorCode: key.patientColorCode,
        lastVisitDate: key.lastVisitDate,
        billingCycle: localStorage.getItem("billingCycle"),
        patientStarStatus: patient.patientStarStatus
          ? patient.patientStarStatus
          : 0,
        ...patient,
      };
      row.age = patient.dob ? PF.getAgeByDob(patient.dob) : "";
      row.nextDate = key.nextDate;
      //row.remark = "";
      row.flag = "no";
      row.language = key.language;
      row.patientStatus = key.patientStatus;
      row.validPrescription = 1;
      row.emrVisitId = key._id;
      row.isVisitDetailApiCalled = false;
      row.patientStatus = "new";
      row.is_dob = patient.is_dob;
      row.patientATag = patient.patientTags ? patient.patientTags : [];
      row.patientBTag = patient.patientBTag
        ? getFilteredPatientBTag(key, patient)
        : [];
      row.patientCTag = patient.patientCTag
        ? getFilteredPatientCTag(key, patient)
        : [];
      row.messageForEducator = key?.messageForEducator
        ? key.messageForEducator
        : "";
      if (row.doctorOut === "out" && row.educatorOut !== "out") {
        blackArr.push(row);
      } else if (row.doctorOut === "out" && row.educatorOut === "out") {
        yellowArr.push(row);
      } else {
        whiteArr.push(row);
      }
    }
  }
  //console.log(whiteArr, "whiteArr")
  tempVisitList = whiteArr.concat(blackArr);
  tempVisitList = tempVisitList.concat(yellowArr);
  // <PatientDetails patientVisitData={this.state.visitList}/>
  //tempVisitList = whiteArr;

  return tempVisitList;
};

const getFilteredPatientBTag = (row, patient) => {
  let filetredBtagArr = [];
  let name = patient.title + " " + patient.firstName + " " + patient.lastName;

  let { lastVisitDate } = row;

  let billingCycle = JSON.parse(localStorage.getItem("billingCycle"));
  patient.patientBTag &&
    patient.patientBTag.map((obj, key) => {
      let date1 = new Date(obj.createdAt);
      let date2 = new Date(row.createdAt);

      let tempDate1 = moment(date1).format("YYYY-MM-DD");
      let tempDate2 = moment(date2).format("YYYY-MM-DD");

      if (tempDate1 <= tempDate2) {
        date1 =
          tempDate1 < tempDate2
            ? date1.setDate(date1.getDate() + (billingCycle - 1))
            : date1;

        date1 = moment(date1).format("YYYY-MM-DD");
        date2 = moment(date2).format("YYYY-MM-DD");

        if (date1 >= date2) {
          filetredBtagArr.push(obj);
        }
      }
    });
  return filetredBtagArr;
};

const getFilteredPatientCTag = (row, patient) => {
  let filetredCtagArr = [];

  {
    patient.patientCTag &&
      patient.patientCTag.map((obj, key) => {
        let date1 = moment(obj.createdAt);
        let date2 = moment(new Date());

        let hoursDiffrence = 0;
        if (date1 < date2) hoursDiffrence = date2.diff(date1);
        else hoursDiffrence = date1.diff(date2);
        let patient = row.patient_data[0];
        let name =
          patient.title + " " + patient.firstName + " " + patient.lastName;
        let duration = moment.duration(hoursDiffrence);
        let hours = duration.asHours();

        if (hours <= 74) {
          filetredCtagArr.push(obj);
        }
      });
  }
  return filetredCtagArr;
};

const addToVisitListFilter = (data, visitList) => {
  console.log(data, "-----data---------");

  let j = 0;
  let tempVisitList = visitList;
  let index = tempVisitList.findIndex((ele) => ele.id === data._id);
  //console.log(index,"indexxxxxxxxxxxxxxxx")
  if (index > -1) return tempVisitList;
  let blackArr = [];
  let yellowArr = [];
  let whiteArr = visitList;

  if (data) {
    let key = data;

    let patient = key.patient_data[0];
    //console.log(patient, "patient")
    if (patient != undefined) {
      let h = patient.height;
      let w = patient.weight;
      let g = patient.gender;
      let row = {
        id: key._id,
        _id: patient._id,
        sn: ++j,
        pId: patient._id,
        patientNo: patient.patientNo,
        patientId: patient.patientClinicId,
        name: patient.title + " " + patient.firstName + " " + patient.lastName,
        firstName: patient.firstName,
        lastName: patient.lastName,
        city: patient.city,
        age_color: patient.age_color,
        share: "",
        read_share: "",
        task: "",
        complete_task: key.complete_tasks_count,
        diet: 0,
        in_time: moment(key.createdAt).format("hh:mm A"),
        next_visit_date: patient.nextDate,
        app: patient.app,
        email: patient.email,
        status: key.status,
        height: h,
        weight: w,
        bmi: PF.getBMI(h, w),
        ibw: PF.getIBW(h, w, g),
        c1: PF.getMaintainWeight(h, w, g),
        c2: PF.getLooseWeight(h, w, g),
        c3: PF.getGainWeight(h, w, g),
        segCalorie: PF.getCalorie(h, w, g),
        calorieType: PF.getCalorieType(h, w, g),
        created_date: moment(key.createdAt).format("YYYY-MM-DD"),
        //remark: key?.remark?key.remark:"",
        patientRemark: patient.remark,
        taskList: [],
        documentList: [],
        doctorOut: key.doctorOut,
        educatorOut: key.educatorOut,
        lastDiet: patient.lastDiet,
        notification_token: patient.notification_token,
        updateDate: patient.updatedAt,
        createDate: patient.createdAt,
        dietHistory: [],
        doseRead: patient.doseRead,
        insulinDose: patient.insulinDose,
        econsultation: key.econsultation,
        reportCount: key.reportCount,
        patientColorCode: key.patientColorCode,
        lastVisitDate: key.lastVisitDate,
        billingCycle: localStorage.getItem("billingCycle"),
        patientStarStatus: patient.patientStarStatus
          ? patient.patientStarStatus
          : 0,
        ...patient,
      };
      row.age = PF.getAgeByDob(patient.dob);
      row.nextDate = key.nextDate;
      //row.remark = patient.remark;
      row.flag = "no";
      row.language = key.language;
      row.patientStatus = "new";
      row.validPrescription = 1;
      row.emrVisitId = key._id;
      row.isVisitDetailApiCalled = false;
      row.patientATag = patient.patientTags ? patient.patientTags : [];
      row.patientBTag = key.patientBTag
        ? getFilteredPatientBTag(key, patient)
        : [];
      row.patientCTag = key.patientCTag
        ? getFilteredPatientCTag(key, patient)
        : [];
      row.messageForEducator = key?.messageForEducator
        ? key.messageForEducator
        : "";
      let user = localStorage.getItem("userType");

      if (user === "doctor") {
        if (row.doctorOut === "out" && row.educatorOut !== "out") {
          yellowArr.push(row);
        } else if (row.doctorOut === "out" && row.educatorOut === "out") {
          blackArr.push(row);
        } else {
          whiteArr.unshift(row);
        }
      }
      if (user === "educator" || user === "receptionist") {
        if (row.educatorOut === "out") {
          yellowArr.push(row);
        } else if (row.doctorOut === "out") {
          blackArr.push(row);
        } else {
          whiteArr.unshift(row);
        }
      }
    }
  }

  tempVisitList = whiteArr.concat(blackArr);
  tempVisitList = tempVisitList.concat(yellowArr);

  // <PatientDetails patientVisitData={this.state.visitList}/>
  //tempVisitList = whiteArr;

  //console.log(tempVisitList, "tempVisitList")
  return tempVisitList;
};

const getUpdatedVisitList = (payload, visitList, mode, outList) => {
  //console.log(payload.doctorOut, "dcotorout")
  //console.log(payload.educatorOut, "educatorOut")
  if (payload.hasOwnProperty("doctorOut")) {
    if (mode === "offline") {
      //console.log(payload, "payload")
      let list = visitList.filter((obj) => obj.id !== payload._id);
      return list;
    }
    if (mode === "online") {
      //console.log(payload, "payload")
      let list = visitList.filter((obj) => obj.id !== payload._id);
      return list;
    }
    if (mode === "outpatient") {
      let outPatient = visitList.find((obj) => obj.id === payload._id);
      let list = outList;
      if (outPatient !== undefined) {
        outPatient.doctorOut = payload.doctorOut;
        list.push(outPatient);
      }
      return list;
    }
  }
  if (payload.hasOwnProperty("educatorOut")) {
    let list = outList;
    for (var i in list) {
      if (list[i].id === payload._id) {
        list[i].educatorOut = payload.educatorOut;
      }
    }

    return list;
  }
};

const getUpdatedVisitListEducator = (payload, visitList, mode, outList) => {
  if (payload.hasOwnProperty("doctorOut")) {
    let list = visitList;
    if (mode === "offline") {
      for (var i in list) {
        if (list[i].id === payload._id) {
          list[i].doctorOut = payload.doctorOut;
          //   list.unshift(list[i]);
          //   let inList = list.filter((obj) => obj.doctorOut === "in");
          //   let tempList = [...outList, ...inList];
          return list;
        }
      }
      //   var localindex_index = inList
      //     .map(function (el) {
      //       return el.id;
      //     })
      //     .indexOf(payload._id);
      //   if (localindex_index != -1) {
      //     inList[localindex_index].doctorOut = payload.doctorOut;
      //     if (outList.length > 0) {
      //       outList.unshift(inList[localindex_index]);
      //     } else {
      //       outList.push(inList[localindex_index]);
      //     }

      //     inList.splice(localindex_index, 1);
      //     let tempList = [...outList, ...inList];
      //     console.log("3333333333333", tempList);
      //     return tempList;
      //   } else {
      //     alert("B");
      //     let tempList = [];
      //     return tempList;
      //   }
    }

    if (mode === "online") {
      for (var i in list) {
        if (list[i].id === payload._id) {
          list[i].doctorOut = payload.doctorOut;
        }
      }

      return list;
    }
    if (mode === "outPatient") {
      return outList;
    }

    // if (mode === "outPatient") {
    //     let outPatient = visitList.find(obj => obj.id === payload._id);
    //     let list = outList
    //     if (outPatient !== undefined) {
    //         outPatient.doctorOut = payload.doctorOut
    //         list.push(outPatient)
    //     }
    //     console.log(list, "out list educator screen")
    //     return list;
    // }
    // if(mode === "outPatient")
    // {
    //     let list = outList

    //         for (var i in list) {
    //             if (list[i].id === payload._id) {
    //                 list[i].educatorOut = payload.educatorOut;

    //             }
    //             console.log(list, "outlist educator screen")
    //         }

    //     return list
    // }
  }

  if (payload.hasOwnProperty("educatorOut")) {
    if (mode === "offline") {
      //console.log(payload, "payload")
      let list = visitList.filter((obj) => obj.id !== payload._id);

      return list;
    }
    if (mode === "online") {
      //console.log(payload, "payload")
      let list = visitList.filter((obj) => obj.id !== payload._id);

      return list;
    }

    if (mode === "outPatient") {
      let outPatient = visitList.find((obj) => obj.id === payload._id);
      let list = outList;
      if (outPatient !== undefined) {
        outPatient.educatorOut = payload.educatorOut;
        list.push(outPatient);
      }

      return list;
    }
  }

  //console.log(list, "list")
};

// const manageVisitCount = (payload, currentCount) => {
//     let cnt = 0;
//     if (mode === "offline") {
//         if (currentCount > 0)
//             cnt = currentCount - 1;
//     }

//     if (mode === "online") {
//         if (currentCount > 0)
//             cnt = currentCount - 1;
//     }

//     if (mode === "out") {
//         if (currentCount > 0)
//             cnt = currentCount - 1;
//     }

//     return cnt;
// }

const UpdateVisitListKnowledge = (payload, visitList) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.id === payload.payload.visitId);
  if (obj != undefined) {
    for (var i in list) {
      if (list[i].id === payload.payload.visitId) {
        if (payload.action === "Documents") {
          list[i].documentList = payload.payload.Documents;
          list[i].share =
            payload.payload.Documents.length + " / " + obj.read_share;
        }
        if (payload.action === "ReadByEducator") {
          let docList = list[i].documentList;
          let read_share_cnt = obj.read_share;

          for (var j in docList) {
            if (docList[j]._id === payload.payload._id) {
              docList[j].status = payload.payload.status;
              //read_share_cnt = read_share_cnt + 1;
            }
          }
          list[i].documentList = docList;
          //list[i].share = docList.length + " / " + read_share_cnt
        }
      }
    }
  }
  return list;
};

const UpdateVisitListTask = (payload, visitList) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.id === payload.payload.visitId);
  if (obj != undefined) {
    for (var i in list) {
      if (list[i].id === payload.payload.visitId) {
        if (payload.action === "Task") {
          list[i].taskList = payload.payload.Tasks;
          list[i].task =
            payload.payload.Tasks.length + " / " + obj.complete_task;
        }
        if (payload.action === "ReadByEducator") {
          let taskList = list[i].taskList;
          let completed_task_cnt = obj.complete_task;

          for (var j in taskList) {
            if (taskList[j]._id === payload.payload._id) {
              taskList[j].status = payload.payload.status;
              completed_task_cnt = completed_task_cnt + 1;
            }
          }
          list[i].taskList = taskList;
          list[i].complete_task = completed_task_cnt;
          list[i].task = list[i].taskList.length + " / " + completed_task_cnt;
        }
      }
    }
  }
  return list;
};

const UpdateVisitListDiet = (payload, visitList) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.id === payload.payload.visitId);
  if (obj != undefined) {
    if (payload.action === "Diet") {
      obj.lastDiet = payload.payload.lastDiet;
    }

    for (var i in list) {
      if (list[i].id === payload.payload.visitId) {
        if (payload.action === "Diet") {
          list[i].lastDiet = payload.payload.lastDiet;
        }
        if (payload.action === "ReadByEducator") {
          list[i].lastDiet[0].addedBy = payload.payload.addedBy;
          list[i].lastDiet[0].addedByType = payload.payload.addedByType;
          list[i].lastDiet[0].color = payload.payload.color;
          list[i].lastDiet[0].isPrint = payload.payload.isPrint;
        }
      }
    }
  }
  return list;
};

const UpdateVisitListFromApp = (payload, visitList) => {
  let list = visitList;
  for (var i in list) {
    if (list[i]._id === payload.payload.patientId) {
      if (payload.action === "DietReadByPatient") {
        list[i].lastDiet[0].readStatus = payload.payload.readStatus;
      }
      if (payload.action === "DocumentReadByPatient") {
        let docList = list[i].documentList;

        let read_share_cnt = list[i].read_share;
        for (var j in docList) {
          if (
            docList[j]._id === payload.payload._id &&
            docList[j].patientRead === "unread"
          ) {
            docList[j].patientRead = payload.payload.patientRead;
            read_share_cnt =
              list[i].read_share + 1 <= docList.length
                ? list[i].read_share + 1
                : list[i].read_share;
          }
        }
        list[i].share = docList.length + " / " + read_share_cnt;
        list[i].read_share = read_share_cnt;
      }
    }
  }

  return list;
};

const UpdateVisitListPrescription = (payload, visitList) => {
  let list = visitList;
  for (var i in list) {
    if (list[i].id === payload.visitId) {
      list[i].flag = payload.flag;
      list[i].insulinDrugId = payload.insulinDrugId;
      list[i].validPrescription = payload.validPrescription;
    }
  }

  return list;
};

const UpdateVisitListPatientDetail = (payload, visitList) => {
  let list = visitList;
  for (var i in list) {
    if (list[i].pId === payload.patientId) {
      list[i].firstName = payload.firstName;
      list[i].age = payload.age;
      list[i].age_color = payload.age_color;
      list[i].city = payload.city;
      list[i].dob = payload.dob;
      list[i].econsultation = payload.econsultation;
      list[i].email = payload.email;
      list[i].gender = payload.gender;
      list[i].height = payload.height;
      list[i].is_dob = payload.is_dob;
      list[i].lastName = payload.lastName;
      list[i].phone = payload.phone;
      list[i].remark = payload.remark;
      list[i].title = payload.title;
      list[i].weight = payload.weight;
      list[i].name =
        payload.title + " " + payload.firstName + " " + payload.lastName;
    }
  }

  return list;
};

const UpdateVisitListNextVisitDate = (payload, visitList) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.id === payload.payload.visitId);
  if (obj != undefined) {
    for (var i in list) {
      if (list[i].id === payload.payload.visitId) {
        list[i].next_visit_date = payload.payload.nextDate;
      }
    }
  }
  return list;
};

const UpdateVisitDetail = (payload, visitList) => {
  let list = visitList;
  for (var i in list) {
    if (list[i].id === payload.id) {
      list[i].isVisitDetailApiCalled = true;
      list[i].taskList = payload.taskList;
      list[i].documentList = payload.documentList;
      list[i].dietHistory = payload.dietHistory;
      list[i].patientStatus = payload.patientStatus;
      list[i].flag = payload.flag;
      list[i].share = payload.share;
      list[i].read_share = payload.read_share;
      list[i].task = payload.task;
      list[i].complete_task = payload.complete_task;
      list[i].validPrescription = payload.validPrescription;
      list[i].reportCount = payload.reportCount;
      list[i].nextDate = payload.nextDate;
      list[i].next_visit_date = payload.next_visit_date;
      list[i].patientStatus = payload.patientStatus;
      list[i].language = payload.language;
    }
  }

  return list;
};

const UpdateVisitDetailEducator = (payload, visitList) => {
  let list = visitList;
  for (var i in list) {
    if (list[i].id === payload.id) {
      list[i].isVisitDetailApiCalled = true;
      list[i].taskList = payload.taskList1;
      list[i].documentList = payload.documentList;
      list[i].dietHistory = payload.dietHistory;
      list[i].patientStatus = payload.patientStatus;
      list[i].flag = payload.flag;
      list[i].share = payload.share;
      list[i].read_share = payload.read_share;
      list[i].task = payload.task;
      list[i].complete_task = payload.complete_task;
      list[i].validPrescription = payload.validPrescription;
      list[i].reportCount = payload.reportCount;
      list[i].nextDate = payload.nextDate;
      list[i].next_visit_date = payload.next_visit_date;
    }
  }

  return list;
};

const getUpdatedTagList = (s3SignedArr, tagList) => {
  let list = tagList;
  for (var i in list) {
    for (var j in s3SignedArr) {
      if (list[i]._id === s3SignedArr[j].id) {
        list[i].s3Path = s3SignedArr[j].s3Path;
      }
    }
  }

  return list;
};

const getUpdatedVisitListS3SignedUrl = (visitList, tagList) => {
  let list = visitList;
  let aTagList = tagList.filter((obj) => obj.type === "a");
  let bTagList = tagList.filter((obj) => obj.type === "b");
  let cTagList = tagList.filter((obj) => obj.type === "c");
  for (var i in list) {
    let tempATag = [];
    let tempBTag = [];
    let tempCTag = [];

    if (list[i].patientATag && list[i].patientATag.length > 0) {
      for (var j in list[i].patientATag) {
        for (var k in aTagList) {
          if (list[i].patientATag[j].tagId === aTagList[k]._id) {
            let obj = {
              tagName: list[i].patientATag[j].tagName,
              tagId: list[i].patientATag[j].tagId,
              s3Path: aTagList[k].s3Path,
            };
            tempATag.push(obj);
          }
        }
      }
      list[i].patientATag = tempATag;
    }

    if (list[i].patientBTag && list[i].patientBTag.length > 0) {
      for (var j in list[i].patientBTag) {
        for (var k in bTagList) {
          if (list[i].patientBTag[j].tagId === bTagList[k]._id) {
            let obj = {
              tagName: list[i].patientBTag[j].tagName,
              tagId: list[i].patientBTag[j].tagId,
              s3Path: bTagList[k].s3Path,
              createdAt: list[i].patientBTag[j].createdAt,
            };
            tempBTag.push(obj);
          }
        }
      }
      list[i].patientBTag = tempBTag;
    }

    if (list[i].patientCTag && list[i].patientCTag.length > 0) {
      for (var j in list[i].patientCTag) {
        for (var k in cTagList) {
          if (list[i].patientCTag[j].tagId === cTagList[k]._id) {
            let obj = {
              tagName: list[i].patientCTag[j].tagName,
              tagId: list[i].patientCTag[j].tagId,
              s3Path: cTagList[k].s3Path,
              createdAt: list[i].patientCTag[j].createdAt,
            };
            tempCTag.push(obj);
          }
        }
      }
      list[i].patientCTag = tempCTag;
    }
  }

  return list;
};

const getUpdatedVisitListPatientTags = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.id === payload.visitId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientATag = payload.patientATag;
      }
    }
  }
  return list;
};

const getUpdatedVisitListPatientATag = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientATag = payload.patientTag;
      }
    }
  }
  return list;
};

const getUpdatedVisitListPatientBTag = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientBTag = payload.patientBTag;
      }
    }
  }
  return list;
};

const getUpdatedVisitListPatientCTag = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientCTag = payload.patientCTag;
      }
    }
  }
  return list;
};

const getUpdatedVisitListPatientStarStatus = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientStarStatus = payload.patientStarStatus;
      }
    }
  }
  return list;
};

const getUpdatedVisitListMessageForEducator = (visitList, payload) => {
  //console.log(payload,"messageForEducator")
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].messageForEducator = payload.messageForEducator;
      }
    }
  }
  return list;
};

const getUpdatedVisitListPatientRemark = (visitList, payload) => {
  //console.log(payload,"messageForEducator")
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].patientRemark = payload.remark;
      }
    }
  }
  return list;
};

const getUpdatedVisitInsulineDose = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    for (var i in list) {
      if (payload.patientId === list[i].pId) {
        list[i].insulinDose = payload.insulinDose;
      }
    }
  }
  return list;
};

const getUpdatedVisitListUnreadCount = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    var localindex_index = list
      .map(function (el) {
        return el.pId;
      })
      .indexOf(payload.patientId);
    if (localindex_index != -1) {
      if (list[localindex_index].reportCount)
        list[localindex_index].reportCount.unReadCount = payload.unReadCount;
      else
        list[localindex_index].reportCount = {
          readCount: 0,
          unReadCount: payload.unReadCount,
        };
    }
  }

  return list;
};

const getUpdatedVisitListReportCount = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    var localindex_index = list
      .map(function (el) {
        return el.pId;
      })
      .indexOf(payload.patientId);
    if (localindex_index != -1) {
      if (list[localindex_index].reportCount) {
        list[localindex_index].reportCount.unReadCount =
          list[localindex_index].reportCount.unReadCount - 1;
        list[localindex_index].reportCount.readCount =
          list[localindex_index].reportCount.readCount + 1;
      }
    }
  }

  return list;
};

const getUpdatedVisitListDeleteReport = (visitList, payload) => {
  let list = visitList;
  let obj = visitList.find((obj) => obj.pId === payload.patientId);
  if (obj != undefined) {
    var localindex_index = list
      .map(function (el) {
        return el.pId;
      })
      .indexOf(payload.patientId);
    if (localindex_index != -1) {
      if (list[localindex_index].reportCount) {
        if (payload.isRead)
          list[localindex_index].reportCount.readCount =
            list[localindex_index].reportCount.readCount - 1;
        else
          list[localindex_index].reportCount.unReadCount =
            list[localindex_index].reportCount.unReadCount - 1;
      }
    }
  }

  return list;
};

export default (state = initState, action = {}) => {
  switch (action.type) {
    //Visit List
    case actionTypes.GET_VISIT_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isVisitList: false,
        isVisitListError: false,
      };
    case actionTypes.GET_VISIT_LIST_SUCCESS:
      if (action.payload.patientStatus === "online") {
        return {
          ...state,
          message: "Visit List successfully",
          eVisitList: filterVisitList(action.payload.response.data.data),
          isVisitList: true,
          isVisitListError: false,
          otherVisitDate: action.payload.otherVisitDate,
          isOnilneApiCalled: true,
          visitCount: action.payload.response.data.offlineCount,
          eVisitCount: action.payload.response.data.onlineCount,
          outPatientCount: action.payload.response.data.outCount,
        };
      } else if (action.payload.patientStatus === "OutPatientList") {
        return {
          ...state,
          message: "Visit List successfully",
          outPatientList: filterVisitList(action.payload.response.data.data),
          isVisitList: true,
          isVisitListError: false,
          otherVisitDate: action.payload.otherVisitDate,
          isOutListApiCalled: true,
          visitCount: action.payload.response.data.offlineCount,
          eVisitCount: action.payload.response.data.onlineCount,
          outPatientCount: action.payload.response.data.outCount,
        };
      } else if (action.payload.patientStatus === "searchDate") {
        return {
          ...state,
          message: "Visit List successfully",
          VisitList: filterVisitList(action.payload.response.data.data),
          outPatientList: [],
          eVisitList: [],
          isVisitList: true,
          isVisitListError: false,
          otherVisitDate: action.payload.otherVisitDate,
          visitCount: action.payload.response.data.offlineCount,
          eVisitCount: action.payload.response.data.onlineCount,
          outPatientCount: action.payload.response.data.outCount,
          isOnilneApiCalled: false,
          isOutListApiCalled: false,
          isOfflineApiCalled: true,
        };
      } else if (action.payload.patientStatus === "reloadHome") {
        return {
          ...state,
          message: "Visit List successfully",
          VisitList: filterVisitList(action.payload.response.data.data),
          outPatientList: [],
          eVisitList: [],
          otherVisitDate: action.payload.otherVisitDate,
          visitCount: action.payload.response.data.offlineCount,
          eVisitCount: action.payload.response.data.onlineCount,
          outPatientCount: action.payload.response.data.outCount,
          isOnilneApiCalled: false,
          isOutListApiCalled: false,
          isOfflineApiCalled: true,
          isReloadHome: !state.isReloadHome,
        };
      } else {
        return {
          ...state,
          message: "Visit List successfully",
          VisitList: filterVisitList(action.payload.response.data.data),
          eVisitList: state.otherVisitDate ? [] : state.eVisitList,
          outPatientList: state.otherVisitDate ? [] : state.outPatientList,
          isVisitList: true,
          isVisitListError: false,
          patientStatus: action.payload.patientStatus,
          otherVisitDate: action.payload.otherVisitDate,
          visitCount: action.payload.response.data.offlineCount,
          eVisitCount: action.payload.response.data.onlineCount,
          outPatientCount: action.payload.response.data.outCount,
          isOfflineApiCalled: true,
        };
      }

    case actionTypes.GET_ONLINE_VISIT_LIST_SUCCESS:
      return {
        ...state,
        message: "Visit List successfully",
        eVisitList: filterVisitList(action.payload.response.data.data),
        isVisitList: true,
        isVisitListError: false,
        otherVisitDate: action.payload.otherVisitDate,
        isOnilneApiCalled: true,
        visitCount: action.payload.response.data.offlineCount,
        eVisitCount: action.payload.response.data.onlineCount,
        outPatientCount: action.payload.response.data.outCount,
        VisitList: [],
        outPatientList: [],
        isOfflineApiCalled: false,
        isOutListApiCalled: false,
      };

    case actionTypes.GET_VISIT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isVisitList: false,
        isVisitListError: true,
      };

    case actionTypes.GET_VISIT_DETAIL_REQUEST:
      return {
        ...state,
        message: "",
        isVisitDetail: false,
        isVisitDetailError: false,
      };

    case actionTypes.VISIT_DETAIL_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isVisitDetail: false,
        isVisitDetailError: true,
      };

    case actionTypes.VISIT_DETAIL_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isVisitDetail: true,
        isVisitDetailError: false,
        visitDetail: action.payload.payload.data.data[0],
      };

    case actionTypes.TAG_LIST_ACTION_REQUEST:
      return {
        ...state,
        message: "",
        isTagList: false,
        isTagListError: false,
        isVisitListUpdateS3SignedUrl: false,
      };

    case actionTypes.GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isTagList: true,
        isTagListError: false,
        TagList: action.payload.response.data,
      };

    case actionTypes.GET_TAG_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isTagList: false,
        isTagListError: true,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_REQUEST:
      return {
        ...state,
        message: "",
        isS3SignedUrlArr: false,
        isS3SignedUrlArrError: false,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isS3SignedUrlArr: true,
        isS3SignedUrlArrError: false,
        TagList: [
          ...getUpdatedTagList(
            action.payload.response.data.data,
            state.TagList
          ),
        ],
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isS3SignedUrlArr: false,
        isS3SignedUrlArrError: true,
      };

    case actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListS3SignedUrl(
                  state.VisitList,
                  state.TagList
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListS3SignedUrl(
                  state.eVisitList,
                  state.TagList
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListS3SignedUrl(
                  state.outPatientList,
                  state.TagList
                ),
              ]
            : state.outPatientList,
        isVisitListUpdateS3SignedUrl: true,
      };

    case actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_ONLINE_LIST_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListS3SignedUrl(
                  state.eVisitList,
                  state.TagList
                ),
              ]
            : state.eVisitList,
        isVisitListUpdateS3SignedUrlOnlineList: true,
      };

    case actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_OUT_LIST_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListS3SignedUrl(
                  state.outPatientList,
                  state.TagList
                ),
              ]
            : state.outPatientList,
        isVisitListUpdateS3SignedUrlOutList: true,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_TAGS_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientTags(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientTags(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientTags(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_A_TAG_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientATag(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientATag(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientATag(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_B_TAG_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientBTag(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientBTag(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientBTag(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_C_TAG_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientCTag(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientCTag(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientCTag(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_STAR_STATUS_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientStarStatus(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientStarStatus(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientStarStatus(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_MESSAGE_FOR_EDUCATOR_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListMessageForEducator(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListMessageForEducator(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListMessageForEducator(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_REMARK_REQUEST:
      return {
        ...state,
        isPatientRemarkUpdated: !state.isPatientRemarkUpdated,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientRemark(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListPatientRemark(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListPatientRemark(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_INSULIN_DOSE_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [...getUpdatedVisitInsulineDose(state.VisitList, action.payload)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...getUpdatedVisitInsulineDose(state.eVisitList, action.payload)]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitInsulineDose(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_REPORT_UNREAD_COUNT_SHARE_REPORT_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListUnreadCount(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListUnreadCount(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListUnreadCount(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
        isReportUnreadCount: !state.isReportUnreadCount,
      };

    case actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_VIEW_REPORT_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListReportCount(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListReportCount(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListReportCount(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_DELETE_REPORT_REQUEST:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        VisitList:
          state.VisitList.length > 0
            ? [
                ...getUpdatedVisitListDeleteReport(
                  state.VisitList,
                  action.payload
                ),
              ]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...getUpdatedVisitListDeleteReport(
                  state.eVisitList,
                  action.payload
                ),
              ]
            : state.eVisitList,
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...getUpdatedVisitListDeleteReport(
                  state.outPatientList,
                  action.payload
                ),
              ]
            : state.outPatientList,
      };

    case actionTypes.STAR_STATUS_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isStarStatusList: false,
        isStarStatusListError: false,
      };

    case actionTypes.STAR_STATUS_LIST_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isStarStatusList: true,
        isStarStatusListError: false,
        starStatusList: action.payload.response.starStatusList.data,
      };

    case actionTypes.STAR_STATUS_LIST_SUCCESS:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isStarStatusList: false,
        isStarStatusListError: true,
      };

    case actionTypes.SET_ACTIVE_TAB_REQUEST:
      //console.log(action.payload, "payload")
      return {
        ...state,
        visitListActiveTab: action.payload.payload,
      };

    case actionTypes.UPDATE_VISIT_LIST_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList: state.isOutListApiCalled
          ? [
              ...getUpdatedVisitList(
                action.payload.response,
                action.payload.response.econsultation
                  ? state.eVisitList
                  : state.VisitList,
                "outpatient",
                state.outPatientList
              ),
            ]
          : state.outPatientList,
        VisitList:
          action.payload.response.doctorOut != undefined
            ? !action.payload.response.econsultation
              ? [
                  ...getUpdatedVisitList(
                    action.payload.response,
                    state.VisitList,
                    "offline",
                    state.outPatientList
                  ),
                ]
              : state.VisitList
            : state.VisitList,
        eVisitList:
          action.payload.response.doctorOut != undefined
            ? action.payload.response.econsultation
              ? getUpdatedVisitList(
                  action.payload.response,
                  state.eVisitList,
                  "online",
                  state.outPatientList
                )
              : state.eVisitList
            : state.eVisitList,
        isVisitListUpdated: true,
        visitCount:
          action.payload.response.doctorOut != undefined
            ? !action.payload.response.econsultation
              ? state.visitCount > 0
                ? state.visitCount - 1
                : 0
              : state.visitCount
            : state.visitCount,
        eVisitCount:
          action.payload.response.doctorOut != undefined
            ? action.payload.response.econsultation
              ? state.eVisitCount > 0
                ? state.eVisitCount - 1
                : 0
              : state.eVisitCount
            : state.eVisitCount,
        outPatientCount:
          action.payload.response.doctorOut != undefined
            ? state.outPatientCount + 1
            : state.outPatientCount,
      };

    case actionTypes.UPDATE_VISIT_LIST_KNOWLEDGE_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...UpdateVisitListKnowledge(
                  action.payload,
                  state.outPatientList
                ),
              ]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListKnowledge(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...UpdateVisitListKnowledge(action.payload, state.eVisitList)]
            : state.eVisitList,
        isKnowledgeShared: !state.isKnowledgeShared,
      };

    case actionTypes.UPDATE_VISIT_LIST_TASK_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [...UpdateVisitListTask(action.payload, state.outPatientList)]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListTask(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...UpdateVisitListTask(action.payload, state.eVisitList)]
            : state.eVisitList,
        isTaskListUpdated: !state.isTaskListUpdated,
      };

    case actionTypes.UPDATE_VISIT_LIST_DIET_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [...UpdateVisitListDiet(action.payload, state.outPatientList)]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListDiet(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...UpdateVisitListDiet(action.payload, state.eVisitList)]
            : state.eVisitList,
        isDietShared: !state.isDietShared,
      };

    case actionTypes.UPDATE_VISIT_LIST_APP_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [...UpdateVisitListFromApp(action.payload, state.outPatientList)]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListFromApp(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...UpdateVisitListFromApp(action.payload, state.eVisitList)]
            : state.eVisitList,
      };

    case actionTypes.UPDATE_VISIT_LIST_PRESCRIPTION_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...UpdateVisitListPrescription(
                  action.payload,
                  state.outPatientList
                ),
              ]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListPrescription(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [...UpdateVisitListPrescription(action.payload, state.eVisitList)]
            : state.eVisitList,
        isPrescriptionUpdated: !state.isPrescriptionUpdated,
      };

    case actionTypes.UPDATE_VISIT_LIST_PATIENT_DETAIL_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListPatientDetail(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...UpdateVisitListPatientDetail(
                  action.payload,
                  state.eVisitList
                ),
              ]
            : state.eVisitList,
        isPatientDetialUpdated: !state.isPatientDetialUpdated,
      };

    case actionTypes.SORT_VISIT_LIST_ACTION_ECONSULTATION:
      let list = state.VisitList;
      let eList = state.eVisitList;
      let opdCnt = state.visitCount;
      let onlineCnt = state.eVisitCount;
      if (
        !action.payload.patient.econsultation &&
        action.payload.flag === "online"
      ) {
        let outPatient = state.eVisitList.find(
          (obj) => obj.id === action.payload.patient.emrVisitId
        );
        eList = state.eVisitList.filter(
          (obj) => obj.id !== action.payload.patient.emrVisitId
        );
        list.push(outPatient);
        opdCnt = opdCnt + 1;
        onlineCnt = onlineCnt - 1;
      }
      if (
        action.payload.patient.econsultation &&
        action.payload.flag === "offline"
      ) {
        let outPatient = state.VisitList.find(
          (obj) => obj.id === action.payload.patient.emrVisitId
        );
        list = state.VisitList.filter(
          (obj) => obj.id !== action.payload.patient.emrVisitId
        );
        eList.push(outPatient);
        onlineCnt = onlineCnt + 1;
        opdCnt = opdCnt - 1;
      }
      return {
        ...state,
        message: "Visit List updated successfully",
        VisitList: [...list],
        eVisitList: [...eList],
        visitCount: opdCnt,
        eVisitCount: onlineCnt,
      };

    case actionTypes.UPDATE_VISIT_LIST_REQUEST_EDUCATOR:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList: state.isOutListApiCalled
          ? [
              ...getUpdatedVisitListEducator(
                action.payload.response,
                action.payload.response.econsultation
                  ? state.eVisitList
                  : state.VisitList,
                "outPatient",
                state.outPatientList
              ),
            ]
          : state.outPatientList,
        VisitList: !action.payload.response.econsultation
          ? [
              ...getUpdatedVisitListEducator(
                action.payload.response,
                action.payload.response.econsultation
                  ? state.eVisitList
                  : state.VisitList,
                "offline",
                state.outPatientList
              ),
            ]
          : state.VisitList,
        eVisitList: action.payload.response.econsultation
          ? [
              ...getUpdatedVisitListEducator(
                action.payload.response,
                action.payload.response.econsultation
                  ? state.eVisitList
                  : state.VisitList,
                "online",
                state.outPatientList
              ),
            ]
          : state.eVisitList,
        // isVisitListUpdated: true,
        isVisitListUpdated: state.isVisitListUpdated ? false : true,
        visitCount:
          action.payload.response.educatorOut != undefined
            ? !action.payload.response.econsultation
              ? state.visitCount > 0
                ? state.visitCount - 1
                : 0
              : state.visitCount
            : state.visitCount,
        eVisitCount:
          action.payload.response.educatorOut != undefined
            ? action.payload.response.econsultation
              ? state.eVisitCount > 0
                ? state.eVisitCount - 1
                : 0
              : state.eVisitCount
            : state.eVisitCount,
        outPatientCount:
          action.payload.response.educatorOut != undefined
            ? state.outPatientCount + 1
            : state.outPatientCount,
      };

    case actionTypes.ADD_TO_VISIT_LIST_SUCCESS:
      return {
        ...state,
        message: "Patient Added To Visit List Successfully",
        VisitList: !action.payload.response.econsultation
          ? addToVisitListFilter(action.payload.response.data, state.VisitList)
          : state.VisitList,
        eVisitList: action.payload.response.econsultation
          ? addToVisitListFilter(action.payload.response.data, state.eVisitList)
          : state.eVisitList,
        visitCount: !action.payload.response.econsultation
          ? state.visitCount + 1
          : state.visitCount,
        eVisitCount: action.payload.response.econsultation
          ? state.eVisitCount + 1
          : state.eVisitCount,
        isAddToVisitList: !state.isAddToVisitList,
      };

    case actionTypes.UPDATE_VISIT_LIST_NEXT_VISIT_DATE_REQUEST:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          state.outPatientList.length > 0
            ? [
                ...UpdateVisitListNextVisitDate(
                  action.payload,
                  state.outPatientList
                ),
              ]
            : state.outPatientList,
        VisitList:
          state.VisitList.length > 0
            ? [...UpdateVisitListNextVisitDate(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          state.eVisitList.length > 0
            ? [
                ...UpdateVisitListNextVisitDate(
                  action.payload,
                  state.eVisitList
                ),
              ]
            : state.eVisitList,
      };

    case actionTypes.UPDATE_PATIENT_VISIT_DETAIL:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          action.payload.activeTab === "OutPatientList"
            ? [...UpdateVisitDetail(action.payload, state.outPatientList)]
            : state.outPatientList,
        VisitList:
          action.payload.activeTab === "offline"
            ? [...UpdateVisitDetail(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          action.payload.activeTab === "online"
            ? [...UpdateVisitDetail(action.payload, state.eVisitList)]
            : state.eVisitList,
      };

    case actionTypes.UPDATE_PATIENT_VISIT_DETAIL_EDUCATOR:
      return {
        ...state,
        message: "Visit List updated successfully",
        outPatientList:
          action.payload.activeTab === "OutPatientList"
            ? [
                ...UpdateVisitDetailEducator(
                  action.payload,
                  state.outPatientList
                ),
              ]
            : state.outPatientList,
        VisitList:
          action.payload.activeTab === "offline"
            ? [...UpdateVisitDetailEducator(action.payload, state.VisitList)]
            : state.VisitList,
        eVisitList:
          action.payload.activeTab === "online"
            ? [...UpdateVisitDetailEducator(action.payload, state.eVisitList)]
            : state.eVisitList,
      };

    // case actionTypes.SET_ADD_TO_VISIT_LIST:
    //     console.log("set add to visitlist")
    //     return {
    //         ...state,
    //         message: "Online Api Flag set to false",
    //         isAddToVisitList: false,

    //     };

    case actionTypes.RESET_VISIT_LIST:
      return {
        ...state,
        message: "Visit List Reset Successfully",
        VisitList: [],
        eVisitList: [],
        outPatientList: [],
        visitCount: 0,
        eVisitCount: 0,
        outPatientCount: 0,
      };

    // Patient Registration

    case actionTypes.GET_PATIENT_REGISTRATION_REQUEST:
      return {
        ...state,
        message: "",
        isPatientRegistration: false,
        isPatientRegistrationError: false,
        // isPatientRemarkUpdated:!state.isPatientRemarkUpdated
      };
    case actionTypes.GET_PATIENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        message: "Successfully patient Added!",
        PatientRegistration: action.payload.response,
        isPatientRegistration: true,
        isPatientRegistrationError: false,
      };
    case actionTypes.GET_PATIENT_REGISTRATION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientRegistration: false,
        isPatientRegistrationError: true,
      };

    // Patient Detail

    case actionTypes.GET_PATIENT_DETAIL_REQUEST:
      return {
        ...state,
        message: "",
        isPatientDetail: false,
        isPatientDetailError: false,
        PatientDetail: false,
      };
    case actionTypes.GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        message: "Patient detail get successfully",
        PatientDetail: action.payload.response,
        isPatientDetail: true,
        isPatientDetailError: false,
      };
    case actionTypes.GET_PATIENT_DETAIL_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientDetail: false,
        isPatientDetailError: true,
      };

    // Patient Edit

    case actionTypes.GET_PATIENT_EDIT_REQUEST:
      return {
        ...state,
        message: "",
        isPatientEdit: false,
        isPatientEditError: false,
        patientDetail: {},
      };
    case actionTypes.GET_PATIENT_EDIT_SUCCESS:
      return {
        ...state,
        message: "Patient successfully updated",
        PatientEdit: action.payload.response,
        isPatientEdit: true,
        isPatientEditError: false,
      };
    case actionTypes.GET_PATIENT_EDIT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientEdit: false,
        isPatientEditError: true,
      };
    // patient out
    case actionTypes.GET_PATIENT_OUT_REQUEST:
      return {
        ...state,
        message: "",
        isPatientOut: false,
        isPatientOutError: false,
      };
    case actionTypes.GET_PATIENT_OUT_SUCCESS:
      return {
        ...state,
        message: "Patient successfully out",
        PatientOut: action.payload.response,
        outPatientList: state.outPatientList,
        isPatientOut: true,
        isPatientOutError: false,
        isVisitListUpdated: false,
      };
    case actionTypes.GET_PATIENT_OUT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientOut: false,
        isPatientOutError: true,
      };

    // Next Visit
    case actionTypes.GET_NEXT_VISIT_REQUEST:
      return {
        ...state,
        message: "",
        isNextVisit: false,
        isNextVisitError: false,
      };
    case actionTypes.GET_NEXT_VISIT_SUCCESS:
      return {
        ...state,
        message: "Successfully next visit date seted.",
        NextVisit: action.payload.response,
        isNextVisit: true,
        isNextVisitError: false,
      };
    case actionTypes.GET_NEXT_VISIT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isNextVisit: false,
        isNextVisitError: true,
      };

    // Holiday List
    case actionTypes.GET_HOLIDAY_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isHolidayList: false,
        isHolidayListError: false,
      };
    case actionTypes.GET_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        message: "Successfully .",
        HolidayList: action.payload.response,
        isHolidayList: true,
        isHolidayListError: false,
      };
    case actionTypes.GET_HOLIDAY_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isHolidayList: false,
        isHolidayListError: true,
      };

    // Educator message list
    case actionTypes.GET_EDUCATOR_MSG_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isEducatorMsgList: false,
        isEducatorMsgListError: false,
      };
    case actionTypes.GET_EDUCATOR_MSG_LIST_SUCCESS:
      return {
        ...state,
        message: "Successfully Educator message listed.",
        EducatorMsgList: action.payload.response,
        isEducatorMsgList: true,
        isEducatorMsgListError: false,
      };
    case actionTypes.GET_EDUCATOR_MSG_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isEducatorMsgList: false,
        isEducatorMsgListError: true,
      };

    // category list
    case actionTypes.GET_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isCategoryList: false,
        isCategoryListError: false,
      };
    case actionTypes.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        message: "Plan List successfully",
        categoryList: action.payload.response,
        isCategoryList: true,
        isCategoryListError: false,
      };
    case actionTypes.GET_CATEGORY_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isCategoryList: false,
        isCategoryListError: true,
      };

    // knowledge list
    case actionTypes.GET_KNOWLEDGE_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isKnowledgeList: false,
        isKnowledgeListError: false,
      };
    case actionTypes.GET_KNOWLEDGE_LIST_SUCCESS:
      return {
        ...state,
        message: "Knowledge List successfully",
        knowledgeList: action.payload.response,
        isKnowledgeList: true,
        isKnowledgeListError: false,
      };
    case actionTypes.GET_KNOWLEDGE_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isKnowledgeList: false,
        isKnowledgeListError: true,
      };

    // print document
    case actionTypes.PRINT_SHARE_DOCUMENT_REQUEST:
      return {
        ...state,
        message: "",
        isPrintShareDocument: false,
        isPrintShareDocumentError: false,
      };
    case actionTypes.PRINT_SHARE_DOCUMENT_SUCCESS:
      return {
        ...state,
        message: "Knowledge List successfully",
        printShareDocument: action.payload.response,
        isPrintShareDocument: true,
        isPrintShareDocumentError: false,
      };
    case actionTypes.PRINT_SHARE_DOCUMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPrintShareDocument: false,
        isPrintShareDocumentError: true,
      };

    // Task list
    case actionTypes.GET_TASK_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isTaskList: false,
        isTaskListError: false,
      };
    case actionTypes.GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        message: "TASK List successfully",
        TaskList: action.payload.response,
        isTaskList: true,
        isTaskListError: false,
      };
    case actionTypes.GET_TASK_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isTaskList: false,
        isTaskListError: true,
      };

    // Change Status
    case actionTypes.CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: "",
        isChangeStatus: false,
        isChangeStatusError: false,
      };
    case actionTypes.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: "TASK List successfully",
        ChangeStatus: action.payload.response,
        isChangeStatus: true,
        isChangeStatusError: false,
      };
    case actionTypes.CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isChangeStatus: false,
        isChangeStatusError: true,
      };

    // History
    case actionTypes.HISTORY_REQUEST:
      return {
        ...state,
        message: "",
        isHistory: false,
        isHistoryError: false,
      };
    case actionTypes.HISTORY_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        History: action.payload.response,
        isHistory: true,
        isHistoryError: false,
      };
    case actionTypes.HISTORY_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isHistory: false,
        isHistoryError: true,
      };

    // History
    case actionTypes.UPDATE_PATIENT_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        message: "",
        isPatientStatus: false,
        isPatientStatusError: false,
      };
    case actionTypes.UPDATE_PATIENT_STATUS_CHANGE_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        PatientStatus: action.payload.response,
        isPatientStatus: true,
        isPatientStatusError: false,
      };
    case actionTypes.UPDATE_PATIENT_STATUS_CHANGE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientStatus: false,
        isPatientStatusError: true,
      };

    // Patient Notification
    case actionTypes.GET_PATIENT_NOTIFICATION_REQUEST:
      return {
        ...state,
        message: "",
        isPatientNotification: false,
        isPatientNotificationError: false,
      };
    case actionTypes.GET_PATIENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        PatientNotification: action.payload.response,
        isPatientNotification: true,
        isPatientNotificationError: false,
      };
    case actionTypes.GET_PATIENT_NOTIFICATION_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isPatientNotification: false,
        isPatientNotificationError: true,
      };

    // My Clinic List
    case actionTypes.GET_MYCLINIC_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isMyClinicList: false,
        isMyClinicListError: false,
      };
    case actionTypes.GET_MYCLINIC_LIST_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        myClinicList: action.payload.response,
        isMyClinicList: true,
        isMyClinicListError: false,
      };
    case actionTypes.GET_MYCLINIC_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isMyClinicList: false,
        isMyClinicListError: true,
      };

    // Knowledge Msg List
    case actionTypes.GET_KNOWLEDGE_MSG_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isMsgKnowledgeList: false,
        isMsgKnowledgeListError: false,
      };
    case actionTypes.GET_KNOWLEDGE_MSG_LIST_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        msgKnowledgeList: action.payload.response,
        isMsgKnowledgeList: true,
        isMsgKnowledgeListError: false,
      };
    case actionTypes.GET_KNOWLEDGE_MSG_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isMsgKnowledgeList: false,
        isMsgKnowledgeListError: true,
      };

    // Diet History List
    case actionTypes.GET_DIET_HISTORY_LIST_REQUEST:
      return {
        ...state,
        message: "",
        isdietHisrotyList: false,
        isdietHisrotyListError: false,
      };
    case actionTypes.GET_DIET_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        message: "History List successfully",
        dietHisrotyList: action.payload.response,
        isdietHisrotyList: true,
        isdietHisrotyListError: false,
      };
    case actionTypes.GET_DIET_HISTORY_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isdietHisrotyList: false,
        isdietHisrotyListError: true,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_REQUEST:
      return {
        ...state,
        message: "",
        isS3SignedUrlArrUploadDocumentClinic: false,
        isS3SignedUrlArrErrorUploadDocumentClinic: false,
        s3SignedUrlArrClinic: [],
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isS3SignedUrlArrUploadDocumentClinic: true,
        isS3SignedUrlArrErrorUploadDocumentClinic: false,
        s3SignedUrlArrClinic: action.payload.response.data.data,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isS3SignedUrlArrUploadDocumentClinic: false,
        isS3SignedUrlArrErrorClinic: true,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_REQUEST:
      return {
        ...state,
        message: "",
        isS3SignedUrlArrUploadDocumentAdmin: false,
        isS3SignedUrlArrErrorUploadDocumentAdmin: false,
        s3SignedUrlArrAdmin: [],
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_SUCCESS:
      return {
        ...state,
        message: "Visit Detail Called successfully",
        isS3SignedUrlArrUploadDocumentAdmin: true,
        isS3SignedUrlArrErrorUploadDocumentAdmin: false,
        s3SignedUrlArrAdmin: action.payload.response.data.data,
      };

    case actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isS3SignedUrlArrUploadDocumentAdmin: false,
        isS3SignedUrlArrErrorAdmin: true,
      };

    default:
      return state;
  }
};

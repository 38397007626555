/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useRef, useEffect } from "react";
import { OverlayTrigger, Tooltip, Modal, FormGroup } from "react-bootstrap";
import moment from "moment";
import InsulinDose from "../TaskScreen/InsulinDose";
import CalendarIc from "../../assets/img/icons/calendar_white.png";
import PrescriptionIc from "../../assets/img/icons/prescription_white.png";
import medicalReportIc from "../../assets/img/icons/lab_report_white.png";
import NextVisit from "Front/views/Home/NextVisitPopup.jsx";
import { Link, useHistory } from "react-router-dom";
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "Front/actions/actionTypes";
import { patientVisitDetail, s3SignedUrlArray } from "Front/api/home";
import * as API from "Front/api/home";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import Reports from "../TaskScreen/Reports";

const PatientMoreDetail = (props) => {
  const history = useHistory();
  const [visitDetail, setVisitDetail] = useState({ ...props.moreDetails });
  const homeReduxData = useSelector((store) => store.home);

  const data = useSelector((store) => store.taskScreen);
  const [nextVisitData, setNextVisitData] = useState([]);
  const [nextVisit, setNextVisit] = useState(false);
  const [patientOut, setPatientOut] = useState(false);
  const notificationSystem = useRef(null);
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);
  const [selectedATags, setSelectedATags] = useState([
    ...props.moreDetails.patientATag,
  ]);
  const [selectedBTags, setSelectedBTags] = useState([
    ...props.moreDetails.patientBTag,
  ]);
  const [selectedCTags, setSelectedCTags] = useState([
    ...props.moreDetails.patientCTag,
  ]);
  const [mode, setMode] = useState("Add");
  const [starStaus, setStartStatus] = useState(
    props.moreDetails.patientStarStatus.toString()
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showInformation, setShowInformation] = useState(true);
  const [strShareCount, setStrShareCount] = useState("");
  const [strTaskCount, setStrTaskCount] = useState("");
  const [knowledgeContent, setKnowledgeContenet] = useState("");
  const [appContent, setAppContenet] = useState("");
  const [patientDietContent, setPatientDietContenet] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [reportContent, setReportContent] = useState("");
  const [resportsModal, setReportsModal] = useState(false);
  const [taskScreenData, setTaskScreenData] = useState([]);
  const [only5Tags, setOnly5Tags] = useState(false);
  const [selectedTagsId, setSelectedTagsId] = useState([]);
  const [patientAppLag, setPatientAppLang] = useState(1);
  const [brandType, setBrandType] = useState(
    localStorage.getItem("is_brand_with_ingredients_disable") == "yes"
      ? "brand"
      : "barndwithingredients"
  );
  const getVisitDetail = async () => {
    let visitlist =
      visitDetail.activeTab === "offline"
        ? homeReduxData.VisitList
        : visitDetail.activeTab === "online"
          ? homeReduxData.eVisitList
          : homeReduxData.outPatientList;
    let patient = visitlist.find((obj) => obj.id === visitDetail.id);

    if (!patient.isVisitDetailApiCalled) {
      let response = await patientVisitDetail({
        visitId: visitDetail.id,
        search_date: props.search_date,
      });
      if (response.data.statusCode == 200) {
        let res = response.data.data.data[0];
        let visitData = visitDetail;
        if (res) {
          visitData.lastDiet = res?.patient_data[0]?.lastDiet;
          visitData.language = res?.patient_data[0]?.language;
          visitData.patientStatus = res.patientStatus;
          visitData.flag = res.flag;
          visitData.taskList = res.Tasks;
          visitData.documentList = res.Documents;
          visitData.dietHistory = res.dietHistory;
          visitData.complete_task = res.complete_tasks_count;
          visitData.share = res.doc_count + " / " + res.read_docs_count;
          visitData.read_share = res.read_docs_count;
          visitData.task = res.task_count + " / " + res.complete_tasks_count;
          visitData.validPrescription = res.validPrescription;
          visitData.reportCount = res.hasOwnProperty("reportCount")
            ? res.reportCount
            : visitData.reportCount;
          visitData.nextDate = res.patient_data[0].nextDate;
          visitData.next_visit_date = res.patient_data[0].nextDate;
          setPatientAppLang(visitData.language);
          setVisitDetail({ ...visitData });
          dispatch({
            type: actionTypes.UPDATE_PATIENT_VISIT_DETAIL,
            payload: visitData,
          });
        }
      }
    } else {
      setPatientAppLang(patient.language);
      setVisitDetail(patient);
    }
    // console.log(patient,"patient")
  };

  useEffect(() => {
    //console.log(homeReduxData.TagList,"tagListChanged")
    setTagList([...homeReduxData.TagList]);
    findSelectedTags(homeReduxData.TagList);
  }, [homeReduxData.TagList]);

  const getS3SignedUrl = async (payload) => {
    await dispatch({ type: actionTypes.S3_SIGNED_URL_ARRAY_REQUEST });
    let response = await s3SignedUrlArray({ imageArray: payload });
    if (response.data.statusCode == 200) {
      dispatch({
        type: actionTypes.S3_SIGNED_URL_ARRAY_SUCCESS,
        payload: { response },
      });
    }
  };

  useEffect(() => {
    let tagList = homeReduxData.TagList;
    if (tagList.length > 0) {
      let payload = [];
      for (let i in tagList) {
        let obj = { filePath: tagList[i].image, id: tagList[i]._id };
        payload.push(obj);
      }
      getS3SignedUrl(payload);
    }

    if (
      props.moreDetails.patientATag.length > 0 ||
      props.moreDetails.patientBTag.length > 0 ||
      props.moreDetails.patientCTag.length > 0 ||
      props.moreDetails.patientStarStatus.toString() != "0"
    ) {
      setMode("Edit");
      setIsDisabled(false);
      setShowInformation(false);
    }
    if (!visitDetail.isVisitDetailApiCalled) {
      getVisitDetail();
    } else {
      let visitlist =
        visitDetail.activeTab === "offline"
          ? homeReduxData.VisitList
          : visitDetail.activeTab === "online"
            ? homeReduxData.eVisitList
            : homeReduxData.outPatientList;
      let patient = visitlist.find((obj) => obj.id === visitDetail.id);
      setPatientAppLang(patient.language);
    }
  }, []);

  const isApp = (row) => {
    //console.log(row,"yyyyyyyyyyyyyyyy")
    let strAppContent = "";
    let lastDiet =
      row && row.lastDiet && row.lastDiet.length > 0
        ? row.lastDiet[0].readStatus
        : "";

    //var readStatus = 'read';
    var patientReadStatus = "read";
    for (let i in row.documentList) {
      if (row.documentList[i].patientRead === "unread") {
        patientReadStatus = "unread";
      }
    }

    var html = "";
    //console.log(lastDiet,"chklastDiet")
    if (lastDiet && lastDiet.length > 0) {
      if (lastDiet === "unread") {
        row["remarkHtml"] = "not seen by patient";
        if (row.remarkHtml !== "") {
          html = (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
            >
              <span className="badge bgred drs-left  mobile-red-clr">
                <span className="fa fa-cutlery" aria-hidden="true"></span>
              </span>
            </OverlayTrigger>
          );
        }
      } else {
        row["remarkHtml"] = "seen by patient";
        if (row.remarkHtml !== "") {
          html = (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
            >
              <span className="badge bgred drs-left mobile-green-clr">
                <span className="fa fa-cutlery" aria-hidden="true"></span>
              </span>
            </OverlayTrigger>
          );
        }
      }
    }

    let insulinCal = <InsulinDose visitInfo={row} callType="main" />;

    if (row.documentList["0"]) {
      strAppContent = (
        <span
          className="doc-label-flex"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="doc-label">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARE:
                    <br />
                    {row.documentList.map((value, key) => {
                      return (
                        <span
                          className={
                            value.patientRead === "read"
                              ? "green"
                              : value.addedByType === "educator"
                                ? "pink"
                                : "red"
                          }
                        >
                          <b>{key + 1}. </b>
                          {value.documentName}
                          <br />
                        </span>
                      );
                    })}
                  </b>
                </Tooltip>
              }
            >
              <span
                className={
                  patientReadStatus === "read" ? "badge bggreen" : "badge bgred"
                }
              >
                {row.documentList.length}
              </span>
            </OverlayTrigger>
            {html}
          </div>
          {insulinCal}
        </span>
      );
      setAppContenet(strAppContent);
    } else {
      strAppContent = (
        <span className="doc-label-flex">
          <div className="doc-label">{html}</div>
          {insulinCal}
        </span>
      );
      setAppContenet(strAppContent);
    }
  };

  const nextButton = (row) => {
    let html = "";
    html = (
      <>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="NextVisit">
              <b>
                Next Visit
                <br />
                {
                  <span>
                    {row.next_visit_date !== null && row.next_visit_date
                      ? moment(row.next_visit_date).format("DD-MM-YYYY")
                      : ""}
                  </span>
                }
              </b>
            </Tooltip>
          }
        >
          <span
            onClick={() => {
              setNextVisitData(row);
              setNextVisit(true);
              setPatientOut(false);
            }}
          >
            {" "}
            <img className="table-action-ic" src={CalendarIc} alt="" />{" "}
          </span>
        </OverlayTrigger>
      </>
    );
    return html;
  };

  const emr = (row) => {
    let html = "";
    //   if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {

    //     }
    let emrData = {
      patientId: row._id,
      isEmr: row.patientStatus === "old" && row.flag === "yes" ? true : false,
    };

    html = (
      <Link
        className=""
        onClick={(e) => {
          localStorage.setItem("patientVisit", JSON.stringify(row));
          localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
          localStorage.setItem("is_Visit_Created", true);
          // this.props.taskScreenAction(row);
          // this.props.emrSetAction(emrData);
        }}
        to={{
          pathname:
            row.patientStatus === "old"
              ? row.flag === "yes"
                ? row.validPrescription.toString() === "1"
                  ? `print/${row.id}/${row.pId}/${brandType}/${patientAppLag}`
                  : `prescription/${row.id}/${row.pId}/${patientAppLag}`
                : `vital-sign/${row.id}/${row.pId}/${patientAppLag}`
              : `vital-sign/${row.id}/${row.pId}/${patientAppLag}`,
          state: { row },
        }}
      >
        <img
          alt="Prescription"
          className="table-action-ic"
          title="Prescription"
          src={PrescriptionIc}
        ></img>
      </Link>
    );
    return html;
  };

  const renderLabButton = (row) => {
    let html = "";
    html = (
      <Link
        className=""
        to={{
          pathname: `vital-sign/${row.id}/${row.pId}/${patientAppLag}`,
          state: { row },
        }}
      >
        <img
          alt="Lab Details"
          className="table-action-ic"
          title="Lab Details"
          src={medicalReportIc}
        ></img>
      </Link>
    );
    return html;
  }

  const patientVisitOut = (id, row) => {
    setNextVisit(false);
    let msg = "Patient Out Successfully";
    successAlert(msg);
  };

  const onDismissNextVisit = () => {
    setNextVisit(false);
    let msg = "Next Visit Successfully Submitted";
    successAlert(msg);
  };

  const successAlert = (msg) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  };

  const dietDetail = (row) => {
    var html = "";
    if (row.documentList["0"]) {
      row["remarkHtml"] = row && row.remark ? row.remark : "";
      if (row.remarkHtml !== "") {
        html = (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
          >
            <span className="badge bgred drs-left">
              <span className="" aria-hidden="true">
                Dr
              </span>
            </span>
          </OverlayTrigger>
        );
      }
    }

    var remarkP = "";

    if (
      row.patientRemark &&
      row.patientRemark !== "" &&
      row.created_date === moment(row.createDate).format("YYYY-MM-DD")
    ) {
      remarkP = (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}
        >
          <span className="badge bgred drs-left">
            <span className="" aria-hidden="true">
              R
            </span>
          </span>
        </OverlayTrigger>
      );
    }

    let lastDiets = row.lastDiet;

    let dietContent = "";
    if (lastDiets && lastDiets["1"]) {
      let date1 = moment(lastDiets["0"].createdAt).format("YYYY-MM-DD");
      let date2 = moment(lastDiets["1"].createdAt).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");

      if (today === date1 && today === date2) {
        if (
          lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
          lastDiets["0"].treatmentId === lastDiets["1"].treatmentId
        ) {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              {lastDiets["1"].addedByType === "doctor" ? (
                <span
                  className={lastDiets["1"].color + " font-11"}
                  style={{ display: "inline-block" }}
                >
                  {lastDiets["1"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              ) : (
                <span
                  className={lastDiets["0"].color + " font-11"}
                  style={{ display: "inline-block" }}
                >
                  {lastDiets["0"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              )}
            </OverlayTrigger>
          );
        } else if (
          lastDiets["0"].addedByType !== lastDiets["1"].addedByType &&
          lastDiets["0"].treatmentId !== lastDiets["1"].treatmentId
        ) {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              {lastDiets["1"].addedByType === "doctor" ? (
                <span
                  className={lastDiets["1"].color + " font-11"}
                  style={{ display: "inline-block" }}
                >
                  {lastDiets["1"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              ) : (
                <span
                  className={lastDiets["0"].color + " font-11"}
                  style={{ display: "inline-block" }}
                >
                  {lastDiets["0"].treatmentName}
                  <span className="badge bgred drs-left">
                    <span className="fa fa-history" aria-hidden="true"></span>
                  </span>
                </span>
              )}
            </OverlayTrigger>
          );
        } else {
          dietContent = (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARED BY:
                    <br />
                    {
                      <span
                        className={
                          lastDiets["1"].addedByType === "educator"
                            ? "pink"
                            : "red"
                        }
                      >
                        <b>1. </b>
                        {lastDiets["1"].addedByType} -{" "}
                        {lastDiets["1"].treatmentName}/{" "}
                        {lastDiets["1"].treatmentType}/{" "}
                        {moment(lastDiets["1"].createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                        <br />
                      </span>
                    }
                  </b>
                </Tooltip>
              }
            >
              <span
                className={lastDiets["0"].color + " font-11"}
                style={{ display: "inline-block" }}
              >
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            </OverlayTrigger>
          );
        }
      } else if (today === date1 && today !== date2) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["1"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["1"].addedByType} -{" "}
                      {lastDiets["1"].treatmentName}/{" "}
                      {lastDiets["1"].treatmentType}/{" "}
                      {moment(lastDiets["1"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            {lastDiets["0"] && lastDiets["0"].addedByType === "educator" ? (
              <span
                className={lastDiets["0"].color + " font-11"}
                style={{ display: "inline-block" }}
              >
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            ) : (
              <span
                className={lastDiets["0"].color + " font-11"}
                style={{ display: "inline-block" }}
              >
                {lastDiets["0"].treatmentName}
                <span className="badge bgred drs-left">
                  <span className="fa fa-history" aria-hidden="true"></span>
                </span>
              </span>
            )}
          </OverlayTrigger>
        );
      } else {
        dietContent = "";
      }
    } else if (lastDiets && lastDiets["0"]) {
      let date1 = moment(lastDiets["0"].createdAt).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");
      if (
        lastDiets["0"] &&
        lastDiets["0"].addedByType === "educator" &&
        today === date1
      ) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["0"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["0"].addedByType} -{" "}
                      {lastDiets["0"].treatmentName}/{" "}
                      {lastDiets["0"].treatmentType}/{" "}
                      {moment(lastDiets["0"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            <span
              className={lastDiets["0"].color + " font-11"}
              style={{ display: "inline-block" }}
            >
              {lastDiets["0"].treatmentName}
              <span
                className="badge bgred drs-left"
                onClick={() => getRedirect(row.pId)}
              >
                <span className="fa fa-history" aria-hidden="true"></span>
              </span>
            </span>
          </OverlayTrigger>
        );
      } else if (today === date1) {
        dietContent = (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARED BY:
                  <br />
                  {
                    <span
                      className={
                        lastDiets["0"].addedByType === "educator"
                          ? "pink"
                          : "red"
                      }
                    >
                      <b>1. </b>
                      {lastDiets["0"].addedByType} -{" "}
                      {lastDiets["0"].treatmentName}/{" "}
                      {lastDiets["0"].treatmentType}/{" "}
                      {moment(lastDiets["0"].createdAt).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                      <br />
                    </span>
                  }
                </b>
              </Tooltip>
            }
          >
            <span
              className={lastDiets["0"].color + " font-11"}
              style={{ display: "inline-block" }}
            >
              {lastDiets["0"].treatmentName}
              <span
                className="badge bgred drs-left"
                onClick={() => getRedirect(row.pId)}
              >
                <span className="fa fa-history" aria-hidden="true"></span>
              </span>
            </span>
          </OverlayTrigger>
        );
      } else {
        dietContent = "";
      }
    } else {
      dietContent = "";
    }
    //console.log(dietContent,"dietContenthaghdghasdgh")
    let strDietContent = "";
    if (dietContent != "" || html != "" || remarkP != "") {
      strDietContent = (
        <div className="diet-knowledge-task">
          {dietContent != "" && (
            <span>
              <label>Diet</label>
              <div style={{ display: "flex", gap: "5px" }}>{dietContent}</div>
            </span>
          )}
          {/* {(html != "" || remarkP != "") && (
            <span>
              <label>Remark</label>
              <div style={{ display: "flex", gap: "5px" }}>
                {html}
                {remarkP}
              </div>
            </span>
          )} */}
        </div>
      );
    }
    // console.log(strDietContent,"strDietContent")
    setPatientDietContenet(strDietContent);
  };

  const knowledgeDetial = (row) => {
    let readStatus = "read";
    for (let i in row.documentList) {
      if (row.documentList[i].status === "unread") {
        readStatus = "unread";
      }
    }
    let strHtml = "";

    if (row.documentList.length > 0) {
      strHtml = (
        <div className="doc-label">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARE:
                  <br />
                  {row.documentList.map((value, key) => {
                    return (
                      <span
                        className={
                          value.status === "read"
                            ? "green"
                            : value.addedByType === "educator"
                              ? "pink"
                              : row.app
                                ? "blue"
                                : "red"
                        }
                      >
                        <b>{key + 1}. </b>
                        {value.documentName}
                        <br />
                      </span>
                    );
                  })}
                </b>
              </Tooltip>
            }
          >
            <span
              className={
                readStatus === "read" ? "badge bggreen" : "badge bgred"
              }
            >
              {/*<span className="glyphicon glyphicon-share-alt" aria-hidden="true"></span>*/}
              {row.documentList.length}
            </span>
          </OverlayTrigger>
        </div>
      );
      setStrShareCount(row.share);
      setKnowledgeContenet(strHtml);
    }
  };

  const addOrDeleteTag = (e, tag) => {
    if (e.target.checked) {
      if (
        selectedATags.length + selectedBTags.length + selectedCTags.length + 1 >
        5
      ) {
        // let ele = document.getElementById(tag._id)
        // if (ele !== null)
        //   ele.checked = false
        setOnly5Tags(true);
        return;
      }
      //console.log(e,tag,"eeeeeeeeeeeeeee")
      let tags = [];
      tags.push({
        tagId: tag._id,
        tagName: tag.title,
        s3Path: tag.s3Path,
        createdAt: new Date(),
      });

      let tempSelectedTagsId = selectedTagsId;
      tempSelectedTagsId.push(tag._id);
      setSelectedTagsId([...tempSelectedTagsId]);

      if (tag.type === "a") setSelectedATags([...tags, ...selectedATags]);

      if (tag.type === "b") setSelectedBTags([...tags, ...selectedBTags]);

      if (tag.type === "c") setSelectedCTags([...tags, ...selectedCTags]);

      setIsDisabled(false);
      setShowInformation(false);

      if (
        tags.length +
        selectedATags.length +
        selectedBTags.length +
        selectedCTags.length +
        1 >
        5
      )
        setOnly5Tags(true);
    } else {
      deleteTag(tag);
    }
  };

  const deleteTag = (tag) => {
    setOnly5Tags(false);
    const index = selectedTagsId.indexOf(tag._id);
    if (index > -1) {
      selectedTagsId.splice(index, 1);
    }
    let arr =
      tag.type === "a"
        ? selectedATags
        : tag.type === "b"
          ? selectedBTags
          : selectedCTags;
    arr.map((itm, idx) => {
      if (itm.tagId === tag._id) {
        arr.splice(idx, 1);
        return;
      }
    });
    //console.log(arr,"arr")
    if (mode === "Add") {
      if (tag.type === "a") {
        setSelectedATags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedBTags.length === 0 &&
          selectedCTags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
      if (tag.type === "b") {
        setSelectedBTags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedATags.length === 0 &&
          selectedCTags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
      if (tag.type === "c") {
        setSelectedCTags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedBTags.length === 0 &&
          selectedATags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    //console.log(selectedATags,selectedBTags,selectedCTags,starStaus,"handleSubmit")
    setIsDisabled(true);
    setBtnClicked(true);
    e.preventDefault();

    if (mode === "Add") {
      if (selectedATags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          patientTag: selectedATags,
        };

        let response = await API.addPatientATypeTag(payloadData);

        //hideModal();
      }
      if (selectedBTags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          visitId: visitDetail.id,
          patientBTag: selectedBTags,
        };
        let response = await API.addPatientBTypeTag(payloadData);
      }

      if (selectedCTags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          visitId: visitDetail.id,
          patientCTag: selectedCTags,
        };

        let response = await API.addPatientCTypeTag(payloadData);
      }
      if (starStaus !== "0") {
        let payloadData = {
          patientId: visitDetail.pId,
          starStatusId: starStaus,
        };
        let response = await API.addPatientStarStatus(payloadData);
      }
    } else {
      let payloadData = {
        patientId: visitDetail.pId,
        patientTag: selectedATags,
      };

      let response = await API.addPatientATypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        visitId: visitDetail.id,
        patientBTag: selectedBTags,
      };
      response = await API.addPatientBTypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        visitId: visitDetail.id,
        patientCTag: selectedCTags,
      };

      response = await API.addPatientCTypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        starStatusId: starStaus,
      };
      response = await API.addPatientStarStatus(payloadData);
    }

    setBtnClicked(false);
    setIsDisabled(false);
    setMode("Edit");
    props.onDismissMoreDetail();
    //successAlert("Data Saved Successfully")
  };

  const handleStarStaus = (id) => {
    setStartStatus(id);
    setIsDisabled(false);
    setShowInformation(false);
  };

  const findSelectedTags = (tagListArr) => {
    //console.log(tagListArr,selectedATags,selectedBTags,selectedCTags,"findtags")
    tagListArr.map((tag) => {
      let selectedTags = selectedATags.concat(selectedBTags);
      selectedTags = selectedTags.concat(selectedCTags);
      let tagsId = selectedTags.map(function (tag) {
        return tag.tagId;
      });
      setSelectedTagsId([...tagsId]);
      if (selectedTags.length === 5) setOnly5Tags(true);
      selectedTags.map((item) => {
        if (item.tagId === tag._id) {
          selectTag(item.tagId);
        }
        //setArrInstruction([...arrInstruction])
      });
    });
  };

  const selectTag = (tagId) => {
    let chkTags = document.getElementsByName("tag");
    for (var tag of chkTags) {
      if (tagId === tag.getAttribute("id")) {
        tag.checked = true;
      }
    }
  };

  useEffect(() => {
    knowledgeDetial(props.moreDetails);
    isApp(props.moreDetails);
    dietDetail(props.moreDetails);
    getReports(props.moreDetails);
    if (props.moreDetails.taskList && props.moreDetails.taskList.length > 0)
      setStrTaskCount(props.moreDetails.task);
  }, [props]);

  const getReports = (row) => {
    let html = "";
    let colorCls = "";
    let { reportCount } = row;

    let { readCount, unReadCount } = reportCount || {
      readCount: 0,
      unReadCount: 0,
    };

    let totCount = readCount + unReadCount;

    if (unReadCount === 0) {
      colorCls = "badge";
    } else {
      colorCls = "badge bgred";
    }

    html = (
      <div
        className="doc-label"
        onClick={() => {
          // this.setState({ taskScreenData: row, reportsModel: true })
          setReportsModal(true);
          //setTaskScreenData([...row])
        }}
      >
        <div className={colorCls}>{totCount} </div>{" "}
      </div>
    );
    setReportContent(html);
  };

  const funModelStatus = () => {
    setReportsModal(false);
  };

  const getRedirect = (id) => {
    history.push("/diet-history", { row: id });
  };



  // useEffect(()=>{

  // },[props.moreDetails.share])

  // useEffect(()=>{

  // },[props.moreDetails.documentList])

  // useEffect(()=>{

  // },[props.moreDetails.lastDiet])
  return (
    <div>
      <NotificationSystem ref={notificationSystem} style={style} />

      <div className="more-info-sec star-modal">
        <div className="labels-sec">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              {only5Tags && (
                <span style={{ color: "red" }}>
                  Maximum 5 Tags Have Been Assigned.
                </span>
              )}
            </div>
            {/* <div className="col-xs-12 col-sm-6">
                  <h4
                    style={{
                      margin: "0 !important",
                      fontSize: "18px",
                      margin: "0 !important",
                      fontWeight: "600",
                    }}
                  >
                    Labels
                  </h4>
                </div> */}
            <div className="col-sm-12 col-lg-6">
              <form className="text-right">
                <FormGroup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  {showInformation && (
                    <div style={{ color: "red", marginTop: "10px" }}>
                      Please Select Any Of Icons Or Star Status To Save
                    </div>
                  )}
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    disabled={isDisabled}
                    className="btn btn-fill btn-primary"
                  >
                    {" "}
                    Save
                    {btnClicked && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </FormGroup>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-1">
              <div className="labels-sec-card">
                <div className="star-status">
                  <label>Star Status</label>
                  {/* <select className='form-control' value={starStaus} onChange={e=> handleStarStaus(e)}>
                <option>Select</option>
                {homeReduxData.starStatusList && homeReduxData.starStatusList.map((ele,key)=>{
                  return <option value={ele._id}>{ele.title}</option>
                })}
              </select> */}
                  {homeReduxData.starStatusList &&
                    homeReduxData.starStatusList.map((ele, key) => {
                      return (
                        <div className="divStarStatus" key={ele._id}>
                          <input
                            onClick={(e) => handleStarStaus(ele._id)}
                            type="radio"
                            name={`radio-${ele._id}`}
                            checked={ele._id === starStaus}
                            id={ele._id}
                          />
                          <label for={ele._id}>{ele.title}</label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="labels-sec-card">
                {/* <label>Tags</label> */}
                <label>Permanent</label>
                <div className="tags-list">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "a")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span
                                style={{ fontSize: "13px" }}
                                title={tag.title}
                              >
                                {tag.title}
                              </span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="labels-sec-card card-2">
                {/* <label>Billing Cycle-based</label> */}
                <label>Valid till next visit</label>
                <div className="tags-list">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "b")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span
                                style={{ fontSize: "13px" }}
                                title={tag.title}
                              >
                                {tag.title}
                              </span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5">
              <div className="labels-sec-card card-3">
                <label>Valid for 3 days</label>
                <div className="tags-list three-tags">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "c")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span
                                style={{ fontSize: "13px" }}
                                title={tag.title}
                              >
                                {tag.title}
                              </span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-8">
          {(patientDietContent ||
            knowledgeContent ||
            strShareCount ||
            strTaskCount ||
            reportContent) && (
              <div className="more-info-sec diet-knowledge-task">
                {patientDietContent && <span>{patientDietContent}</span>}

                {knowledgeContent && (
                  <span>
                    <label>Knowledege</label>
                    {knowledgeContent}
                  </span>
                )}
                {strShareCount && (
                  <span>
                    <label>Share</label>
                    {strShareCount}
                  </span>
                )}

                {strTaskCount && (
                  <span>
                    <label>Task</label>
                    {strTaskCount}
                  </span>
                )}
                {reportContent && (
                  <span>
                    <label>Report</label>
                    {reportContent}
                  </span>
                )}
              </div>
            )}
        </div>

        {appContent && (
          <div className="col-xs-12 col-sm-2">
            <div className="more-info-sec">
              <label>App</label>
              <span>{appContent}</span>
            </div>
          </div>
        )}
        <div className="col-xs-12 col-sm-2">
          <div className="more-info-sec">
            <label>Action</label>
            <div className="action-badges">
              <span className="badge">{nextButton(props.moreDetails)}</span>
              <span style={{ backgroundColor: "#080808 !important" }} className={"badge"}>{(renderLabButton(props.moreDetails))}</span>
              <span
                className={`badge ${props.moreDetails.patientStatus === "old"
                  ? props.moreDetails.flag === "yes"
                    ? props.moreDetails.validPrescription.toString() === "1"
                      ? "prescritption-completed"
                      : "prescritption-incompleted"
                    : "prescritption-not-made"
                  : "prescritption-not-made"
                  }`}
              >
                {emr(props.moreDetails)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        className="pa-next-visit"
        show={nextVisit}
        onHide={() => setNextVisit(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {"Set Next Visit"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share ">
          <NextVisit
            nextVisitData={nextVisitData}
            patientOutFun={patientVisitOut}
            patientOut={patientOut}
            onDismissNextVisit={onDismissNextVisit}
            econsultation={visitDetail.econsultation}
            activeTab={visitDetail.activeTab}
          />
        </Modal.Body>
      </Modal>
      <Reports
        visitInfo={props.moreDetails}
        hideButton="true"
        modelStatus={resportsModal}
        funModelStatus={funModelStatus}
      />
    </div>
  );
};

export default PatientMoreDetail;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import {
  NotificationListAction,
  AddNotificationMsgAction,
  EditNotificationMsgAction,
  NotificationMsgStatusAction,
} from "Front/actions/master";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import moment from "moment";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from "react-confirm-alert";
var isActionChanged = false;
let Validator = require("validatorjs");
let formArr = {};
let rules = {
  message: "required",
};
let mess = {
  required: "This field is required",
};
let validation = [];

var googleLoad = false;

let google = window.google;

google.load("elements", "1", {
  packages: "transliteration",
});
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formArr: [],
      notificationList: [],
      isLoading: true,
      alert: null,
      show: false,
      editUserModal: false,
      customMessageError: false,
      formData: {
        message: "",
        id: "",
        status: "",
        direction: "desc",
        order: "createdAt",
        offset: 0,
        limit: 10,
        limitStatus: false,
      },

      successMsg: "",
      _notificationSystem: null,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: "",
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.successDelete = this.successDelete.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getCreateDate = this.getCreateDate.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.successAlert = this.successAlert.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.props.NotificationListAction(this.state.formData);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isNotification !== this.props.isNotification) {
      //if(nextProps.notificationList.data && nextProps.notificationList.data.data && nextProps.notificationList.data.data.length>0){
      this.setState({
        notificationList:
          nextProps.notificationList.data &&
          nextProps.notificationList.data.data,
        totalCount:
          nextProps.notificationList.data &&
          nextProps.notificationList.data.count,
      });
      //}
    }

    if (nextProps.isAddNotificationMsg !== this.props.isAddNotificationMsg) {
      this.setState({
        successMsg: "Notification sucessfully added.",
        formData: { message: "", id: "" },
        formArr: [],
      });
      //   this.successAlert("Notification sucessfully added");
      this.props.NotificationListAction(this.state.limitStatus);
    }
    if (nextProps.isEditNotificationMsg !== this.props.isEditNotificationMsg) {
      this.setState({
        successMsg: "Notification sucessfully updated.",
        formData: { message: "", id: "" },
        formArr: [],
      });
      //   this.successAlert("Notification sucessfully updated");
      this.props.NotificationListAction(this.state.limitStatus);
    }

    if (
      nextProps.isNotificationMsgStatus !== this.props.isNotificationMsgStatus
    ) {
      this.setState({ formData: { message: "", id: "" }, formArr: [] });
      if (isActionChanged) {
        this.successDelete();
        isActionChanged = false;
      }
      this.props.NotificationListAction(this.state.limitStatus);
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  getCreateDate(cell, row, enumObject, rowIndex) {
    return row && row.createdAt
      ? moment(row.createdAt).format("DD-MM-YYYY")
      : "";
  }

  successDelete() {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          <span>{this.state.successMsg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 1000);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }
  successAlert(msg) {
    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  editAction(rowData, e) {
    let formData = this.state.formData;
    formData["message"] = rowData.message;
    formData["id"] = rowData._id;
    formData["status"] = rowData?.status;
    this.setState({
      formData: formData,
      editUserModal: true,
      customMessageError: false,
    });
  }

  actionButton(cell, row, enumObject, rowIndex) {
    let icon = "";
    let style = "";
    let statusText = "";
    let action = "";

    if (row && row.status === "active") {
      // icon = <i className="fa fa-ban"></i>
      // style = 'danger'
      // statusText = 'Disable'
      // action = 'inactive'

      icon = <i className="fa fa-check"></i>;
      style = "success";
      statusText = "Enable";
      action = "inactive";
    } else {
      // icon = <i className="fa fa-check"></i>
      // style = 'success'
      // statusText = 'Enable'
      // action = 'active'

      icon = <i className="fa fa-ban"></i>;
      style = "danger";
      statusText = "Disable";
      action = "active";
    }

    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="status">{statusText}</Tooltip>}
        >
          <Button
            simple
            icon
            bsStyle={style}
            onClick={this.warningWithConfirmMessage.bind(this, action, row._id)}
          >
            {icon}
          </Button>
        </OverlayTrigger>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit">Edit</Tooltip>}
        >
          <Button
            simple
            icon
            bsStyle="success"
            onClick={this.editAction.bind(this, row)}
          >
            <i className="fa fa-edit"></i>
          </Button>
        </OverlayTrigger>
      </div>
    );
  }

  warningWithConfirmMessage(action, id) {
    let msg = "";
    let successMsg = "";

    if (action === "active") {
      successMsg = "Message sucessfully activated.";
      msg = "You want to enable this message!";
    } else {
      successMsg = "Message sucessfully disabled.";
      msg = "You want to disable this message!";
    }
    this.setState({ successMsg: successMsg });

    const params = {
      status: action,
      id: id,
    };

    confirmAlert({
      title: "Are you sure",
      // message: 'Are you sure you want to Close?',
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.NotificationMsgStatusAction(params);
            this.successAlert("Status changed successfully.");
          },
        },
        {
          label: "No",
          onClick: () => this.hideAlert(),
        },
      ],
    });
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       warning
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Are you sure?"
    //       onConfirm={() =>
    //         this.props.NotificationMsgStatusAction(params)(
    //           (isActionChanged = true),
    //           this.hideAlert()
    //         )
    //       }
    //       onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //       cancelBtnBsStyle="danger"
    //       confirmBtnText="Yes"
    //       cancelBtnText="Cancel"
    //       showCancel
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });
  }

  serialNumber(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  }

  addNotification(evt) {
    evt.preventDefault();

    let formData = this.state.formData;
    if (formData?.message == "") {
      this.setState({ customMessageError: true });
    }
    if (this.allValidate(false)) {
      if (formData.id) {
        this.props.EditNotificationMsgAction(this.state.formData);
        this.setState({ editUserModal: false });
        this.successAlert("Notification sucessfully updated");
      }
      // else {
      //   this.props.AddNotificationMsgAction(this.state.formData);
      // }
    }
    //return validation.errors()
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  onPageChange(page, sizePerPage) {
    let data = {};

    if (this.state.searchText === "") {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
      data["limitStatus"] = this.state.formData.limitStatus;
      data["searchText"] = this.state.searchText;
    } else {
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
      data["limitStatus"] = this.state.formData.limitStatus;
      data["searchText"] = this.state.searchText;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.NotificationListAction(data);
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {};
        data["direction"] = this.state.formData.direction;
        data["order"] = this.state.formData.order;
        data["offset"] = this.state.formData.offset;
        data["limit"] = this.state.formData.limit;
        data["limitStatus"] = this.state.formData.limitStatus;
        data["search"] = text;

        this.setState({ sizePerPage: this.state.sizePerPage });
        this.props.NotificationListAction(data);
      }
    } else {
      this.componentDidMount();
    }
    this.setState({ searchText: text });
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  render() {
    const { formData } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      onSearchChange: this.onSearchChange,
      onSizePerPageList: this.sizePerPageListChange,
      noDataText: this._setTableOption(),
    };

    function onLoad() {
      var options = {
        sourceLanguage: google.elements.transliteration.LanguageCode.ENGLISH,
        destinationLanguage: [
          google.elements.transliteration.LanguageCode.HINDI,
        ],
        shortcutKey: "ctrl+g",
        transliterationEnabled: false,
      };

      var control = new google.elements.transliteration.TransliterationControl(
        options
      );

      control.makeTransliteratable(["message"]);
    }
    setTimeout(function () {
      if (googleLoad === false) {
        googleLoad = true;
        google.setOnLoadCallback();
        onLoad();
      }
    }, 5000);

    return (
      <div className="settings-userlist">
        {this.state.alert}
        <NotificationSystem ref="notificationSystem" style={style} />
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="">
              {/* <h4 className="title" style={{ "display": "inline-block" }}>Notification List</h4> */}
              <div className="fresh-datatables table-responsive user-box row">
                <BootstrapTable
                  selectRow="{selectRowProp}"
                  deleteRow={false}
                  data={this.state.notificationList}
                  search={true}
                  multiColumnSearch={true}
                  pagination={true}
                  options={options}
                  striped
                  hover
                  condensed
                  scrollTop={"Bottom"}
                  remote={true}
                  fetchInfo={{ dataTotalSize: this.state.totalCount }}
                >
                  <TableHeaderColumn
                    hidden="true"
                    tdAttr={{ "data-attr": "_id" }}
                    dataField="_id"
                    isKey
                    searchable={false}
                  >
                    Id
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="5%"
                    tdAttr={{ "data-attr": "#" }}
                    dataField="sn"
                    dataFormat={this.serialNumber.bind(this)}
                  >
                    #
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="30%"
                    tdAttr={{ "data-attr": "MESSAGE" }}
                    dataField="message"
                  >
                    Message
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="30%"
                    tdAttr={{ "data-attr": "CREATE DATE" }}
                    dataField="createdAt"
                    dataFormat={this.getCreateDate}
                    dataSort={true}
                  >
                    Create Date
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="10%"
                    tdAttr={{ "data-attr": "STATUS" }}
                    dataField="status"
                  >
                    STATUS
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="10%"
                    tdStyle={{ textAlign: "center" }}
                    tdAttr={{ "data-attr": "Action" }}
                    dataFormat={this.actionButton.bind(this)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.editUserModal}
          onHide={() => this.setState({ editUserModal: false })}
          dialogClassName="modal-md"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-md">
              Edit Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Form horizontal>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <Col
                      sm={12}
                      componentClass={ControlLabel}
                      style={{ marginBottom: "10px" }}
                    >
                      Message <span className="star">*</span>
                    </Col>
                    <Col sm={12}>
                      <FormControl
                        rows="3"
                        componentClass="textarea"
                        name="custom_message"
                        bsClass="form-control"
                        onChange={(e) => {
                          this.setState({
                            formData: {
                              ...formData,
                              message: e.target.value,
                            },
                          });
                        }}
                        value={this.state.formData?.message}
                      />
                      {this.state.customMessageError && (
                        <span className="errorMsg">This Field is required</span>
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col sm={12}>
                  <FormGroup>
                    <Col sm={12}>
                      <button
                        type="button"
                        className="btn-fill btn-wd btn btn-primary"
                        onClick={(e) => {
                          this.addNotification(e);
                        }}
                      >
                        Save
                      </button>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>
                <Button
                  className="btn pull-right btn-secondary"
                  onClick={() => this.setState({ editUserModal: false })}
                >
                  Close
                </Button>
              </Modal.Footer> */}
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificationList: state.master.notificationList,
    isNotification: state.master.isNotification,
    isNotificationError: state.master.isNotificationError,

    isAddNotificationMsg: state.master.isAddNotificationMsg,
    isAddNotificationMsgError: state.master.isAddNotificationMsgError,
    addNotificationMsg: state.master.addNotificationMsg,

    editNotificationMsg: state.master.editNotificationMsg,
    isEditNotificationMsg: state.master.isEditNotificationMsg,
    isEditNotificationMsgError: state.master.isEditNotificationMsgError,

    notificationMsgStatus: state.master.notificationMsgStatus,
    isNotificationMsgStatus: state.master.isNotificationMsgStatus,
    isNotificationMsgStatusError: state.master.isNotificationMsgStatusError,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    NotificationListAction,
    AddNotificationMsgAction,
    EditNotificationMsgAction,
    NotificationMsgStatusAction,
  })(NotificationList)
);

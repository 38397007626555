/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addReadyCreatedMeal = (data) => postRequestWithToken(data, 'admin/add-food-group');
export const updateReadyCreatedMeal = (data) => postRequestWithToken(data, 'admin/update-food-group');
export const changeReadyCreatedMealStatus = (data) => postRequestWithToken(data, 'admin/food-group-change-status');
export const readyCreatedMealList = (data) => postRequestWithToken(data, 'admin/food-group');
export const updatePriority = (data) => postRequestWithToken(data, 'admin/update-food-group-priority');
export const addFoodCart = (data) => postRequestWithToken(data, 'admin/add-food-cart');
export const updateFoodCart = (data) => postRequestWithToken(data, 'admin/update-food-cart');

export const getMealByTreatment = (data) => postRequestWithToken(data, 'admin/get-meal-by-treatment');
export const removeMealOption = (data) => postRequestWithToken(data, 'admin/remove-meal-option');
export const addMealOption = (data) => postRequestWithToken(data, 'admin/add-meal-option');


/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import CloseIc from "../../assets/img/icons/close-ic.svg";
import AdminNotification from "../../views/Home/AdminNotification";
import Schedule from "../../views/Home/Schedule";
import Notes from "../../views/Home/Notes";
import AdminNtoification from "../../views/Home/AdminNotification";
import PendingActions from "../../views/Pages/Emr/PendingActions";

const SideModal = (props) => {
  const [yourSchedule, setYourSchedule] = useState(false);
  const [cliniNotesModel, setCliniNotesModel] = useState(false);
  const [adminNotification, setAdminNotification] = useState(false);
  const [pendingActions, setPendingActions] = useState(false);
  useEffect(() => {
    if (props.componentToShow === "Schedule") setYourSchedule(true);
    if (props.componentToShow === "Notes") setCliniNotesModel(true);
    if (props.componentToShow === "Notification") setAdminNotification(true);
    if (props.componentToShow === "PendingAction") setPendingActions(true);
  }, []);

  return (
    <>
      {yourSchedule && <Schedule modal={() => props.modal()} />}

      {cliniNotesModel && <Notes modal={() => props.modal()} />}

      {adminNotification && <AdminNotification modal={() => props.modal()} />}

      {pendingActions && <PendingActions modal={() => props.modal()} />}
    </>
  );
};

export default SideModal;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { addKnowledgeAction } from "Admin/actions/master";
import { clinicListAction, ClinicSimpleListAction } from "Admin/actions/clinic";
import { uploadMultiFileAction } from "Admin/actions/master";
import { uploadFileAction, categoryListAction } from "Admin/actions/master";
import ClinicHeader from "../ClinicHeader/ClinicHeader";
import { appConstants } from "Admin/_constants/app.constants.js";
import ReactPlayer from "react-player";
import loadingImg from "Admin/assets/img/loading.gif";
import { languageListAction } from "Admin/actions/language";
import { postRequestWithToken } from "../../api/helper.js";
import { clinicDetail } from "../../api/clinic";
let Validator = require("validatorjs");
let formArr = {};
let rules = {
  title: "required",
  //file: 'required',
  category: "required",
};
let mess = {
  required: "This field is required",
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

//let fileSizeError = '';

class AddKnowledge extends Component {
  constructor(props) {
    super(props);
    var clinicid = "";
    var clinicName = "";
    var dropdownDisable = false;
    let edit = this.props.location.state.row
      ? this.props.location.state.row
      : [];
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.clinic_id
    ) {
      clinicid = this.props.location.state.clinic_id;
      clinicName = this.props.location.state.clinicName;
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.clinicDetail &&
      !this.props.location.state.clinicDetail.status
    ) {
      dropdownDisable = true;
    }
    this.state = {
      clinicList: [],
      categoryList: [],
      addStatus: false,
      category: null,
      cardHidden: true,
      formArr: [],
      clinicError: null,
      titleError: null,
      titleHindiError: null,
      fileError: null,
      fileHindiError: null,
      descriptionError: null,
      orderError: null,
      categoryError: null,
      statusError: null,
      fileValidation: false,
      fileValidation_hindi: false,
      fileLang: [],
      formData: {
        title: edit.title ? edit.title : "",
        clinic_name: edit.clinicName ? edit.clinicName : clinicName,
        clinic_id: edit.clinicId ? edit.clinicId : clinicid,
        description: edit.description ? edit.description : "",
        order: edit.order ? edit.order : "",
        category: edit.category ? edit.category : [],
        status: edit.status ? edit.status : "active",
        file: [],
        image: edit.image ? edit.image : "",
        id: edit._id ? edit._id : "",
        type: edit.type ? edit.type : "",
        knowledgeList: edit.knowledges ? edit.knowledges : [],
      },
      isLogin: true,
      showProcessing: false,
      limitForm: {},
      s3url: "",
      modelViewData: {},
      dropdownDisable: dropdownDisable,
      languageOptions: [],
      languageList: [],
      videoModel: false,
    };
  }

  componentDidMount() {
    this.props.languageListAction({
      limit: 100,
      offset: 0,
      order: "lanId",
      direction: "asc",
    });

    let oldCategory = this.props.location.state.row
      ? this.props.location.state.row.category
      : [];
    let Cate = [];
    if (oldCategory && oldCategory.length > 0) {
      for (let x in oldCategory) {
        let obj = {};
        obj.label = oldCategory[x].name;
        obj.value = oldCategory[x].id;
        Cate.push(obj);
      }

      this.setState({ category: Cate });
    }
    let data = this.state.limitForm;
    data["limitStatus"] = true;
    //this.props.clinicListAction(data);
    this.props.categoryListAction(data);
    this.props.ClinicSimpleListAction();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.isLanguageList !== this.props.isLanguageList &&
      this.props.isLanguageList === false
    ) {
      this.setState({
        languageList: nextProps.LanguageList.data,
      });

      let list = nextProps.LanguageList.data;
      let languageOptions = [];
      for (let i in list) {
        languageOptions.push({ value: list[i].lanId, label: list[i].name });
      }

      this.setState({
        languageOptions: languageOptions,
      });

      if (this.state.formData.knowledgeList.length === 0) {
        let formdata = this.state.formData;
        let nkowList = [];
        for (let i in languageOptions) {
          nkowList.push({
            title: "",
            image: "",
            type: "",
            language: languageOptions[i].value,
            file: "",
          });
        }
        formdata.knowledgeList = nkowList;
        this.setState({ formdata: formdata });
      }
    }

    if (
      nextProps.isMultiUploadFile !== this.props.isMultiUploadFile &&
      this.props.isMultiUploadFile === false
    ) {
      //this.imageProgressBar();

      let uploadedFiles = nextProps.MultiUploadFile.file_names;
      let f = 0;

      let formData = this.state.formData;

      for (let k in formData["knowledgeList"]) {
        if (
          formData["knowledgeList"][k].file &&
          formData["knowledgeList"][k].file !== ""
        ) {
          formData["knowledgeList"][k].image = uploadedFiles[f];
          f++;
        }
      }

      //this.setState({ formData: formData });

      if (
        nextProps.MultiUploadFile &&
        nextProps.MultiUploadFile.status === "Success"
      ) {
        let data = { ...formData };

        data.knowledgeList = JSON.stringify(formData.knowledgeList);
        this.props.addKnowledgeAction(data);
        this.setState({ showProcessing: false });
      }
    }

    if (
      nextProps.isMultiUploadFileError !== this.props.isMultiUploadFileError
    ) {
      this.setState({ showProcessing: false });
    }

    if (
      nextProps.isClinicList !== this.props.isClinicList &&
      this.props.isClinicList === false
    ) {
      this.setState({
        clinicList: nextProps.ClinicList.data.data,
      });
    }

    if (
      nextProps.isCategoryList !== this.props.isCategoryList &&
      this.props.isCategoryList === false
    ) {
      let categoryList = nextProps.CategoryList.data.data.map((key, i) => {
        return { value: key._id, label: key.name };
      });

      this.setState({ categoryList: categoryList });
    }

    if (
      nextProps.isAddKnowledge !== this.props.isAddKnowledge &&
      this.props.isAddKnowledge === false
    ) {
      //if(this.state.formData.status == "active"){
      let params = {
        clinic_id: this.state.formData.clinic_id,
        type: "knowledge",
      };
      appConstants.socket.emit("master-update", params);
      //}
      this.props.handleClick("success", nextProps.AddKnowledgeMessage.msg);
      if (this.props.location && this.props.location.state.clinicDetail) {
        this.props.history.push(
          `/admin/knowledge-list`,
          this.props.location.state.clinicDetail
        );
      } else {
        this.props.history.push(`/admin/knowledge-list`);
      }
    }

    if (nextProps.isAddKnowledgeError !== this.props.isAddKnowledgeError) {
      if (nextProps.AddKnowledgeMessage.errors) {
        nextProps.AddKnowledgeMessage.errors.map((key, i) => {
          this.setState({
            [key.param + "Error"]: (
              <small className="text-danger">{key.msg}</small>
            ),
          });
          return "";
        });
      }
    }

    if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
      let list = nextProps.ClinicSimpleList.data;

      this.setState({
        clinicList: nextProps.ClinicSimpleList.data
      });

    }
  }

  handleChange(e, index = "") {
    e.preventDefault();
    let field = this.state.formData;
    if (index === "") {
      field[e.target.name] = e.target.value;
    } else {
      field.knowledgeList[index].title = e.target.value;
      field[e.target.name] = e.target.value;
    }

    this.setState({ formData: field });
  }

  handleClinic(event) {
    let index = event.target.selectedIndex;
    let formData = this.state.formData;
    formData["clinic_id"] = event.target.value;
    formData["clinic_name"] = event.target[index].text;
    //console.log(formData);
    let ClinicDetail = this.state.clinicList.filter((item, index) => {
      return item._id === event.target.value;
    });
    let langList =
      ClinicDetail[0] && ClinicDetail[0].language
        ? ClinicDetail[0].language
        : [1, 2];

    let NkList = formData.knowledgeList;
    let nkowList = [];
    for (let i in langList) {
      let itrm = NkList.filter((row) => {
        return row.language.toString() === langList[i].toString();
      });
      if (itrm && itrm.length > 0) {
        nkowList.push(itrm[0]);
      } else {
        nkowList.push({
          title: "",
          image: "",
          type: "",
          language: langList[i],
          file: "",
        });
      }
    }

    formData["knowledgeList"] = nkowList;

    this.setState({ formData: formData });
  }

  fileChangedHandler(event, elename, indexx) {
    let field = this.state.formData;
    field.knowledgeList[indexx].file = "";
    event.preventDefault();
    //this.setState({ uploaded_file: elename });
    //let reader = new FileReader();
    let file = event.target.files[0];

    var fileName = file.name;
    fileName = fileName.split(".");
    let extensions = fileName[1].toLowerCase();
    if (
      extensions === "jpg" ||
      extensions === "png" ||
      extensions === "jpeg" ||
      extensions === "pdf" ||
      extensions === "mp4"
    ) {
      //field[elename][indexx] = file;
      field.knowledgeList[indexx].file = file;
      field.knowledgeList[indexx].type =
        extensions === "pdf" ? "pdf" : extensions === "mp4" ? "video" : "image";
      //this.props.uploadFileAction(file);
      //reader.readAsDataURL(file);
      if (elename === "file") {
        this.setState({ fileValidation: false });
      } else {
        this.setState({ fileValidation_hindi: false });
      }
    } else {
      if (elename === "file") {
        this.setState({ fileValidation: true });
      } else {
        this.setState({ fileValidation_hindi: true });
      }
    }
    this.setState({ formData: field });
    // if(file && file.size <= 15652261){
    //   fileSizeError = '';
    //   this.props.uploadFileAction(file);
    //   reader.readAsDataURL(file);
    // }else{
    //   fileSizeError = 'Upload video no larger than 15MB.';
    // }
  }

  handleCategory(category) {
    let field = this.state.formData;

    if (category.length > 0) {
      this.setState({ category: category });
      var catArr = category.map((key, i) => {
        return { id: key.value, name: key.label };
      });
      field["category"] = JSON.stringify(catArr);
    } else {
      this.setState({ category: null });
      field["category"] = [];
    }

    this.setState({ formData: field });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  addKnowledge(evt) {
    if (this.state.category != null) {
      var catArr = this.state.category.map((key, i) => {
        return { id: key.value, name: key.label };
      });
      let fData = this.state.formData;
      fData["category"] = JSON.stringify(catArr);
      this.setState({ formData: fData });
      //this.state.formData.category = JSON.stringify(catArr);
    }

    const _this = this;
    if (this.allValidate(false)) {
      this.setState({
        addStatus: true,
      });
      _this.setState({ showProcessing: true });

      let formData = { ...this.state.formData };
      //            console.log(formData);
      let fileArr = [];
      let fileLang = [];
      formData.knowledgeList.map((key, i) => {
        if (key.file) {
          //console.log(key);
          fileLang.push(key.language);
          fileArr.push(key.file);
        }
        return key;
      });
      this.setState({ fileLang: fileLang });
      this.setState({ progressBarModal: true });
      //console.log(fileArr)
      if (fileArr && fileArr.length > 0) {
        //console.log(fileArr)
        this.props.uploadMultiFileAction(fileArr);
        this.setState({ showProcessing: true });
      } else {
        formData.knowledgeList = JSON.stringify(formData.knowledgeList);

        _this.props.addKnowledgeAction(formData);
      }
    }
    //validation.errors()
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  openVideoModel(row) {
    const fetchUserEmail = async (row) => {
      const response = await postRequestWithToken(
        { filePath: row.image },
        "s3SignedUrl"
      );
      row.displayFile = response.data.url;
      this.setState({ modelViewData: row, videoModel: true });
    };
    if (this.state.videoModel === false) {
      fetchUserEmail(row);
    }

    // this.setState({videoModel: true})

    //row.displayFile = row.image;
  }

  render() {
    //console.log(this.state.formData.knowledgeList);
    const modelViewData = this.state.modelViewData;
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <div className="main-content" style={{ padding: "15px 0px" }}>
        <Grid fluid>
          <Row>
            <div
              className="actionProcess"
              style={{ display: this.state.showProcessing ? "block" : "none" }}
            >
              <img src={loadingImg} alt="Loading" width="40px" />
              <center>Logging In - Please Wait</center>
            </div>
            <Col md={12}>
              {this.state.dropdownDisable && (
                <ClinicHeader
                  componentData={{
                    clinicId: this.props.location.state.clinicDetail._id,
                  }}
                />
              )}
              <Card
                title={
                  <legend className="line-removes">
                    {!this.state.dropdownDisable && (
                      <Button
                        className="go-back-btn-1"
                        bsStyle="info"
                        onClick={(e) => {
                          this.goBackFun(e);
                        }}
                      >
                        Back
                      </Button>
                    )}
                  </legend>
                }
                content={
                  <Form horizontal>
                    {!this.state.dropdownDisable && (
                      <div>
                        <FormGroup>
                          <Col
                            componentClass={ControlLabel}
                            sm={2}
                            smOffset={2}
                          >
                            Clinic
                          </Col>
                          <Col sm={6}>
                            <select
                              className="form-control"
                              name="clinic"
                              value={this.state.formData.clinic_id}
                              onChange={(event) => this.handleClinic(event)}
                            >
                              <option value="">All Clinic</option>
                              {this.state.clinicList.map(function (item) {
                                return (
                                  <option key={item._id} value={item._id}>
                                    {item.name}({item.clinicId})
                                  </option>
                                );
                              })}
                            </select>
                          </Col>
                        </FormGroup>
                      </div>
                    )}
                    {this.state.formData.knowledgeList &&
                      this.state.formData.knowledgeList.length > 0 &&
                      this.state.formData.knowledgeList.map((item, index) => {
                        return (
                          <FormGroup key={index}>
                            <Col
                              componentClass={ControlLabel}
                              sm={2}
                              smOffset={2}
                            >
                              Title (
                              {appConstants.getLanguageName(item.language)}){" "}
                              <span className="star">*</span>
                            </Col>

                            <Col sm={3}>
                              <FormControl
                                type="text"
                                name="title"
                                id="title"
                                onChange={(e) => {
                                  this.handleChange(e, index);
                                }}
                                value={item.title}
                              />
                              <span className="errorMsg">
                                {this.state.formArr.title &&
                                  validation.errors.first("title")}
                              </span>
                            </Col>
                            <Col componentClass={ControlLabel} sm={2}>
                              File (
                              {appConstants.getLanguageName(item.language)})
                              <span className="star">*</span>
                            </Col>
                            <Col sm={3}>
                              {item.image && item.image !== "" && (
                                <a
                                  href="javascript:void(0);"
                                  onClick={this.openVideoModel.bind(this, item)}
                                >
                                  {item.image}
                                </a>
                              )}
                              {this.props.location.state.type !== "clinic" && (
                                <Col>
                                  <input
                                    type="file"
                                    name="file"
                                    accept=".jpg,.png,.jpeg,.mp4,.pdf"
                                    onChange={(e) =>
                                      this.fileChangedHandler(e, "file", index)
                                    }
                                  ></input>

                                  <span className="errorMsg">
                                    {this.state.formArr.file &&
                                      validation.errors.first("file")}
                                  </span>
                                  {this.state.fileValidation && (
                                    <span className="errorMsg">
                                      {" "}
                                      Allowed only jpg,png,jpeg,pdf,mp4 format
                                      file.
                                    </span>
                                  )}
                                </Col>
                              )}

                              {this.props.location.state.type === "admin" ? (
                                <Col>
                                  <input
                                    type="hidden"
                                    name="id"
                                    value={this.state.formData.id}
                                    accept=".jpg,.png,.jpeg,.mp4,.pdf"
                                    onChange={(e) => {
                                      this.handleChange(e);
                                    }}
                                  ></input>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Col>
                          </FormGroup>
                        );
                      })}
                    <FormGroup>
                      <Col
                        componentClass={ControlLabel}
                        sm={2}
                        smOffset={2}
                      ></Col>
                      <Col sm={6}>
                        Note: Uploaded video will appear after some time.
                      </Col>
                    </FormGroup>
                    <FormGroup controlId="formControlsTextarea">
                      <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Description
                      </Col>
                      <Col sm={6}>
                        <FormControl
                          rows="2"
                          componentClass="textarea"
                          name="description"
                          value={this.state.formData.description}
                          bsClass="form-control"
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Category <span className="star">*</span>
                      </Col>
                      <Col sm={6}>
                        <Select
                          placeholder="Select Category"
                          name="category"
                          closeOnSelect={false}
                          multi={true}
                          value={this.state.category}
                          options={this.state.categoryList}
                          onChange={(value) => {
                            this.handleCategory(value);
                          }}
                        //onChange={(value) => this.setState({ category: value })}
                        />
                        <span className="errorMsg">
                          {this.state.formArr.category &&
                            validation.errors.first("category")}
                        </span>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Status <span className="star">*</span>
                      </Col>
                      <Col sm={6}>
                        <select
                          className="form-control"
                          name="status"
                          value={this.state.formData.status}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        >
                          {(this.props.location.state.type === "all" ||
                            this.props.location.state.type === "clinic") && (
                              <option value="requested">Requested</option>
                            )}
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Col
                        componentClass={ControlLabel}
                        sm={2}
                        smOffset={2}
                      ></Col>
                      <Col sm={6}>
                        <button
                          type="button"
                          onClick={this.addKnowledge.bind(this)}
                          disabled={
                            this.state.fileValidation
                              ? this.state.fileValidation
                              : this.state.showProcessing
                          }
                          className="btn-fill btn-wd btn btn-info"
                        >
                          Save
                        </button>
                        &nbsp;
                      </Col>
                    </FormGroup>
                  </Form>
                }
              />
            </Col>
            <Col>
              <Modal
                show={this.state.videoModel}
                onHide={() => this.setState({ videoModel: false })}
                dialogClassName="modal-md"
              >
                <Modal.Header>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <Modal.Title>
                            {modelViewData ? modelViewData.clinic_name : ""}{" "}
                            {modelViewData ? modelViewData.title : ""}
                          </Modal.Title>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Modal.Header>
                <Modal.Body className="Knowledge-Share card">
                  <Row>
                    <Col md={12}>
                      <div>
                        <div>
                          <Row>
                            <Col md={12}>
                              {modelViewData &&
                                modelViewData.type &&
                                modelViewData.type === "video" && (
                                  <ReactPlayer
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    url={
                                      this.state.s3url +
                                      modelViewData.displayFile
                                    }
                                    playing
                                    controls="true"
                                    volume="1"
                                  />
                                )}
                              {modelViewData &&
                                modelViewData.type &&
                                modelViewData.type === "image" && (
                                  <img
                                    src={
                                      this.state.s3url +
                                      modelViewData.displayFile
                                    }
                                    width="70%"
                                    height="100%"
                                    alt={modelViewData.type}
                                  />
                                )}
                              {modelViewData &&
                                modelViewData.type &&
                                modelViewData.type === "pdf" && (
                                  <a
                                    href={
                                      this.state.s3url +
                                      modelViewData.displayFile
                                    }
                                    target="_blank"
                                  >
                                    {" "}
                                    Download pdf
                                  </a>
                                )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  {/* <button type="button" onClick={() => this.statusChangedKnowledge(modelViewData)} className="btn-fill btn-wd btn btn-info">Approve</button>&nbsp; */}
                  <button
                    type="button"
                    onClick={() => this.setState({ videoModel: false })}
                    className="btn-fill btn-wd btn btn-secondary"
                  >
                    Close
                  </button>
                  &nbsp;
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //console.log(state);
  return {
    AddKnowledgeMessage: state.master.AddKnowledge,
    isAddKnowledge: state.master.isAddKnowledge,
    isAddKnowledgeError: state.master.isAddKnowledgeError,

    ClinicList: state.clinic.ClinicList,
    isClinicList: state.clinic.isClinicList,
    isClinicListError: state.clinic.isClinicListError,

    isUploadFile: state.master.isUploadFile,
    isUploadFileError: state.master.isUploadFileError,
    uploadFile: state.master.uploadFile,

    CategoryList: state.master.categoryList,
    isCategoryList: state.master.isCategoryList,
    isCategoryListError: state.master.isCategoryListError,

    isMultiUploadFile: state.master.isMultiUploadFile,
    isMultiUploadFileError: state.master.isMultiUploadFileError,
    MultiUploadFile: state.master.MultiUploadFile,

    LanguageList: state.language.LanguageList,
    isLanguageList: state.language.isLanguageList,
    isLanguageListError: state.language.isLanguageListError,

    ClinicSimpleList: state.clinic.ClinicSimpleList,
    isClinicSimpleList: state.clinic.isClinicSimpleList,
    isClinicSimpleListError: state.clinic.isClinicSimpleListError
  };
}
export default withRouter(
  connect(mapStateToProps, {
    addKnowledgeAction,
    clinicListAction,
    uploadFileAction,
    categoryListAction,
    uploadMultiFileAction,
    languageListAction,
    ClinicSimpleListAction
  })(AddKnowledge)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import {
    Row, Grid, Form, Col, FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';

import { NavLink } from 'react-router-dom';

const InsulineSensitivityIndex5PointsCalculator = () => {
    const [formData, setFormData] = useState({});
    const [calculateResult1, setCalculateResult1] = useState(0.0);
    const [calculateResult2, setCalculateResult2] = useState(0.0);
    const [calculateResult3, setCalculateResult3] = useState(0.0);
    const [calculateResult4, setCalculateResult4] = useState(0.0);
    const [required, setRequired] = useState({});

    const inputHandler = (e) => {
        let saveFormData = formData;
        saveFormData[e.target.name] = e.target.value;
        setFormData({ ...formData, ...saveFormData });
        setRequired({ ...required, ...saveFormData });
    }

    const calculateFun = (e) => {
        e.preventDefault();
        let glucose_fasting = parseFloat(formData.glucose_fasting);
        let insulin_fasting = parseFloat(formData.insulin_fasting);
        let glucose_30_min = parseFloat(formData.glucose_30_min);
        let insulin_30_min = parseFloat(formData.insulin_30_min);
        let glucose_60_min = parseFloat(formData.glucose_60_min);
        let insulin_60_min = parseFloat(formData.insulin_60_min);
        let glucose_90_min = parseFloat(formData.glucose_90_min);
        let insulin_90_min = parseFloat(formData.insulin_90_min);
        let glucose_120_min = parseFloat(formData.glucose_120_min);
        let insulin_120_min = parseFloat(formData.insulin_120_min);

        if (!glucose_fasting || !insulin_fasting || !glucose_30_min || !insulin_30_min || !glucose_120_min || !insulin_120_min || !glucose_60_min || !insulin_60_min || !glucose_90_min || !insulin_90_min) {
            alert("All (*) fields are mandatory");
            return false;
        }

        let result_1 = 0;
        let result_2 = 0;
        let result_3 = 0;
        let result_4 = 0;

        let glucose_avg = (glucose_fasting + glucose_30_min + glucose_60_min + glucose_90_min + glucose_120_min) / 5;
        let insulin_avg = (insulin_fasting + insulin_30_min + insulin_60_min + insulin_90_min + insulin_120_min) / 5;

        let static_value_1 = 405;
        let static_value_2 = 10000;

        result_1 = glucose_fasting * insulin_fasting / static_value_1;
        result_2 = static_value_2 / Math.sqrt(glucose_fasting * insulin_fasting * glucose_avg * insulin_avg);
        result_3 = (insulin_30_min - insulin_fasting) / (glucose_30_min - glucose_fasting);
        result_4 = result_2 * result_3;

        setCalculateResult1(isNaN(result_1) ? result_1 : parseFloat(result_1.toFixed(8)))
        setCalculateResult2(isNaN(result_2) ? result_2 : parseFloat(result_2.toFixed(8)))
        setCalculateResult3(isNaN(result_3) ? result_3 : parseFloat(result_3.toFixed(8)))
        setCalculateResult4(isNaN(result_4) ? result_4 : parseFloat(result_4.toFixed(8)))

    }

    return (
        <div className="main-content color-notations">
            <Grid fluid>
                <Card
                    content={
                        <Row>
                            <Col md={4}>
                                <img src="../../../images/Insulin-Sensitivity-Index-Calculator.jpg" class="w-100 mx-h-40" alt="Insulin Sensitivity Indx [5-Points]" />
                            </Col>
                            <Col md={2}>
                                <ul className="nav calc-navs">
                                    <li>
                                        <NavLink to={'bmi-calculator'} className="nav-link " >
                                            <span className="sidebar-mini">BMI</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'eGFR-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">eGFR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'homair-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Homa IR</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'gly-frac-diabetes-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Gly Frac. Diabetes</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-3-points-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indx [3-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'insuline-sensitivity-index-5-points-calculator'} className="nav-link active" >
                                            <span className="sidebar-mini">Insulin Sensitivity Indx [5-Points]</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'bone-age-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Bone Age Calculator</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'fib-4-calculator'} className="nav-link" >
                                            <span className="sidebar-mini">Fib 4</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <Form horizontal>
                                    <h4>Insulin Sensitivity Indices [5-Points] Calculator</h4>
                                    <Row>
                                        <Col sm={12}>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                </Col>
                                                <Col className='col-sm-4 control-label'> Glucose Value (mg/dL)</Col>
                                                <Col className='col-sm-4 control-label text-unset'>Insulin Value (µIU/ml)</Col>
                                            </FormGroup>



                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    Fasting<span className="star">*</span>
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="glucose_fasting" id="glucose_fasting" value={formData.glucose_fasting || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose_30_min && required.glucose_fasting === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="insulin_fasting" id="insulin_fasting" value={formData.insulin_fasting || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin_30_min && required.insulin_fasting === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                            </FormGroup>


                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    30 Min <span className="star">*</span>
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="glucose_30_min" id="glucose_30_min" value={formData.glucose_30_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose_30_min && required.glucose_30_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="insulin_30_min" id="insulin_30_min" value={formData.insulin_30_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin_30_min && required.insulin_30_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>


                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    60 Min <span className="star">*</span>
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="glucose_60_min" id="glucose_60_min" value={formData.glucose_60_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose_60_min && required.glucose_60_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="insulin_60_min" id="insulin_60_min" value={formData.insulin_60_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin_60_min && required.insulin_60_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>


                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    90 Min <span className="star">*</span>
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="glucose_90_min" id="glucose_90_min" value={formData.glucose_90_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose_90_min && required.glucose_90_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="insulin_90_min" id="insulin_90_min" value={formData.insulin_90_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin_90_min && required.insulin_90_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>


                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    120 Min <span className="star">*</span>
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="glucose_120_min" id="glucose_120_min" value={formData.glucose_120_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.glucose_120_min && required.glucose_120_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>
                                                <Col className='col-sm-4'>
                                                    <FormControl type="number" autocomplete="off" maxLength="10" name="insulin_120_min" id="insulin_120_min" value={formData.insulin_120_min || ''} onChange={e => { inputHandler(e) }} />
                                                    <span className="errorMsg" style={{ display: (!formData.insulin_120_min && required.insulin_120_min === '') ? 'block' : 'none' }}>This is required field</span>
                                                </Col>


                                            </FormGroup>


                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    Homa IR
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="text" maxLength="10" name="result_1" id="result_1" placeholder='Homa IR' value={calculateResult1 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />
                                                </Col>
                                                <Col className='col-sm-5 control-label'>
                                                    <span> Healthy Range: 1.0 (0.5-1.4)</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    Matsuda Index
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="text" maxLength="10" name="result_2" id="result_2" placeholder='Matsuda Index' value={calculateResult2 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />
                                                </Col>
                                                <Col className='col-sm-5 control-label'>
                                                    <span> Healthy Range: {'>'} 2.5</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    Insulinogenic Index
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="text" maxLength="10" name="result_3" id="result_3" placeholder='Insulinogenic Index' value={calculateResult3 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />
                                                </Col>
                                                <Col className='col-sm-5 control-label'>
                                                    <span> Defect in insulin secretion: {'<'} 0.4</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} className='col-sm-3'>
                                                    Disposition Index
                                                </Col>

                                                <Col className='col-sm-4'>
                                                    <FormControl type="text" maxLength="10" name="result_4" id="result_4" placeholder='Disposition Index' value={calculateResult4 || ''} disabled="disabled" onChange={e => { inputHandler(e) }} />
                                                </Col>
                                                <Col className='col-sm-5 control-label'>
                                                    <span> Normal: More than 1</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col sm={12}>
                                                    <button type="submit" onClick={e => { calculateFun(e) }} className="btn-fill btn-wd btn btn-primary">Calculate</button>
                                                </Col>
                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    }
                />
            </Grid>
        </div>
    );
}


export default withRouter(InsulineSensitivityIndex5PointsCalculator)

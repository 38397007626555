/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { treatmentListAction, treatmentChangeStatusAction } from 'Admin/actions/treatment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { appConstants } from 'Admin/_constants/app.constants';
import BSTable from './BSTable';

var count = 1;

class TreatmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            treatmentList: [],
            isLoading: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                foodCategoryId: "",
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "english"
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);

    }

    componentDidMount() {
        this.props.treatmentListAction(this.state.formData);

    }

    componentWillReceiveProps(nextProps) {
        let listData = [];
        let i = 0;

        if (nextProps.isTreatmentChangeOrder !== this.props.isTreatmentChangeOrder) {
            this.props.treatmentListAction(this.state.formData)
        }

        if (nextProps.isTreatmentList !== this.props.isTreatmentList) {

            this.setState({
                treatmentList: nextProps.TreatmentList.data.data,
                totalCount: nextProps.TreatmentList.data.count
            });
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }

    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    editButton(cell, row, enumObject, rowIndex) {
        return (<p><Link to={{ pathname: `update-comment/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
        </p>)
    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        // if (event.status == 'active') {
        //     newstatus = 'inactive';
        // } else {
        //     newstatus = 'active';
        // }
        if (event.status == 'true') {
            newstatus = 'false';
        } else {
            newstatus = 'true';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.treatmentChangeStatusAction(event);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-food";
        this.clicked = "";
    }


    handleLanguage(event) {
        let formData = this.state.formData
        formData['lang'] = event.target.value
        this.setState({ formData: formData });
    }

    handleText(cell, row, enumObject, rowIndex) {
        return row.text[this.state.formData.lang]
    }


    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;
        this.props.treatmentListAction(formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        }

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.treatmentListAction(data)
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                this.setState({ sizePerPage: 10 })
                this.props.treatmentListAction(data);
            }
        } else {
            this.componentDidMount();
        }
        this.setState({ searchText: text });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    handleTreatment(cell, row, enumObject, rowIndex) {
        return (<h5>{row.name} {"(" + row.type + ")"}</h5>);
    }


    isExpandableRow(row) {
        return true;
    }
    idCount(cell, row, enumObject, rowIndex) {
        return rowIndex + 1;
    }
    expandComponent(row) {
        return (
            <BSTable data={row.treatmentdata} />);


    }
    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = (isExpanded ? <h5>-</h5> : <h5>+</h5>);
        return content
    }

    render() {

        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
            // expandBy: 'column',
            expandRowBgColor: 'rgb(242, 255, 163)'
        };

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6}>
                                                <Button bsStyle="info" fill pullRight onClick={() => this.props.history.replace('add-treatment')}>Add Treatment</Button>
                                            </Col>
                                        </Row>&nbsp;

                                        <div className="table-resposive educator-L">
                                            <BootstrapTable data={this.state.treatmentList}
                                                selectRow="{selectRowProp}"
                                                options={options}
                                                headerStyle={{ display: "none" }}
                                                striped
                                                expandableRow={this.isExpandableRow}
                                                expandComponent={this.expandComponent}
                                                expandColumnOptions={{
                                                    expandColumnVisible: true,
                                                    expandColumnComponent: this.expandColumnComponent,
                                                }}

                                            >
                                                <TableHeaderColumn dataField='_id' hidden isKey={true}>Product ID</TableHeaderColumn>
                                                {/* <TableHeaderColumn  dataFormat={this.handleO.bind(this)}>Product Name</TableHeaderColumn> */}
                                                <TableHeaderColumn thStyle={{ width: '90%' }} tdStyle={{ width: '90%' }} dataFormat={this.handleTreatment.bind(this)}>Product Name</TableHeaderColumn>


                                            </BootstrapTable>
                                        </div>

                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

/*class BSTable extends React.Component {
    constructor(props) {
        super(props);
        
    }
    editButton(cell, row, enumObject, rowIndex) {
    
        return (<p><Link to={{ pathname: `update-treatment/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
        </p>)
    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        // if (event.status == 'active') {
        //     newstatus = 'inactive';
        // } else {
        //     newstatus = 'active';
        // }
        if (event.status == 'true') {
            newstatus = 'false';
        } else {
            newstatus = 'true';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.treatmentChangeStatusAction(event);
    }
    idCount(cell, row, enumObject, rowIndex){
        return rowIndex+1;
    }

    render() {
        if (this.props.data) {
            return (
                <BootstrapTable data={this.props.data}>
                    <TableHeaderColumn dataField='id' hidden isKey={true}>Product ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '2%' }} tdStyle={{ width: '2%' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.idCount.bind(this)}>#</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'name' }} dataField='name'>Product Name</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'instruction' }} dataField='instruction'>Instruction</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status'  >Status</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'Action' }}  dataFormat={this.editButton.bind(this)}>Action</TableHeaderColumn>
             </BootstrapTable>);
        } else {
            return (<p>?</p>);
        }
    }
}*/

function mapStateToProps(state) {
    return {

        TreatmentList: state.treatment.TreatmentList,
        isTreatmentList: state.treatment.isTreatmentList,
        isTreatmentListError: state.treatment.isTreatmentListError,

        TreatmentChangeStatus: state.treatment.TreatmentChangeStatus,
        isTreatmentChangeStatus: state.treatment.isTreatmentChangeStatus,
        isTreatmentChangeStatusError: state.treatment.isTreatmentChangeStatusError,

        isTreatmentChangeOrder: state.treatment.isTreatmentChangeOrder,
        isTreatmentChangeOrderError: state.treatment.isTreatmentChangeOrderError
    }
}

export default withRouter(connect(mapStateToProps, { treatmentListAction, treatmentChangeStatusAction })(TreatmentList));

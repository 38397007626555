/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, Media } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import loadingImg from 'Front/assets/img/loading.gif';

import { addContactUsAction } from 'Front/actions/settings';
import SweetAlert from 'react-bootstrap-sweetalert';

import medzing from 'Front/assets/img/medzing.jpg';
import touch from 'Front/assets/img/touch.jpg';
import design from 'Front/assets/img/web-design.png';
import aahar from 'Front/assets/img/aahar.jpg';
import medzingbg from 'Front/assets/img/medzing-bg.jpg';
import touchbg from 'Front/assets/img/touch-bg.jpg';
import touch_icon from 'Front/assets/img/touch_icon.png';
import aahar_icon from 'Front/assets/img/aahar_icon.png';
import medzing_icon from 'Front/assets/img/medzing_icon.png';
import erisLogo from 'Front/assets/img/eris.png';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
  username: 'required',
  password: 'required',
};
let mess = {
  required: 'This field is required',
};
let validation = [];


let contactFormArr = {}
let cRules = {
  name: 'required',
  email: 'required|email',
  phone: 'required|numeric',
};

let validationCnt = [];


class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      contactFormArr: [],
      formData: {
        username: "",
        password: ""
      },
      contactFormData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isLogin: true,
      showProcessing: false,
      passwordShown: false,
    }

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleChange = this.handleChange.bind(this);
    this.onForgotPwd = this.onForgotPwd.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }



  b64EncodeUnicode(str) {
    return window.btoa((encodeURIComponent(str)));
  }

  UnicodeDecodeB64(str) {

    if (str === 'admin') {
      return str;
    } else {
      return decodeURIComponent((window.atob(str)));
    }
  }


  componentDidMount() {
    let formData = this.state.formData;
    formData['username'] = this.UnicodeDecodeB64(this.getCookie('username'));
    formData['password'] = this.getCookie('password');
    this.setState({ formData: formData });
    window.scrollTo(0, 0)
  }



  componentWillReceiveProps(nextProps) {


    if (nextProps.isLogin !== this.props.isLogin) {
      //console.log('login nextProps', nextProps)
      this.setCookie('username', this.b64EncodeUnicode(this.state.formData.username), 364);
      this.setCookie('password', this.b64EncodeUnicode(this.state.formData.passwordOld), 364);
      this.setCookie('Cookie2', this.b64EncodeUnicode('username'), 364);
      this.setCookie('Cookie4', this.b64EncodeUnicode('password'), 364);

      localStorage.setItem('_id', nextProps.LoginData.data._id)
      localStorage.setItem('chatModel', false)
      localStorage.setItem('userName', nextProps.LoginData.data.userName)
      localStorage.setItem('userType', nextProps.LoginData.data.userType)
      localStorage.setItem('clinicId', nextProps.LoginData.data.clinicId)
      localStorage.setItem('clinicName', nextProps.LoginData.data.clinicName)
      localStorage.setItem('clinicNumber', nextProps.LoginData.data.clinicNumber)
      localStorage.setItem('email', nextProps.LoginData.data.email)
      localStorage.setItem('status', nextProps.LoginData.data.status)
      localStorage.setItem('token', nextProps.LoginData.data.token)
      localStorage.setItem('degree', nextProps.LoginData.data.degree)
      localStorage.setItem('title', nextProps.LoginData.data.title)
      localStorage.setItem('firstName', nextProps.LoginData.data.firstName)
      localStorage.setItem('lastName', nextProps.LoginData.data.lastName)
      localStorage.setItem('profilePhoto', nextProps.LoginData.data.image)
      localStorage.setItem('profilePhoto', nextProps.LoginData.data.image)
      localStorage.setItem("billingCycle", nextProps.LoginData.data.billingCycle);
      localStorage.setItem("report_count", nextProps.LoginData.data?.report_count);
      localStorage.setItem('loginTime', new Date())
      if (nextProps.LoginData.data.clinic) {

        localStorage.setItem('logo', nextProps.LoginData.data.clinic.logo_image);
        localStorage.setItem('diet_print_format', nextProps.LoginData.data.clinic.diet_print_format);
        localStorage.setItem('print_header_margin', nextProps.LoginData.data.clinic.print_header_margin);
        localStorage.setItem('print_bottom_margin', nextProps.LoginData.data.clinic.bottom_margin);
        localStorage.setItem('left_margin', nextProps.LoginData.data.clinic.left_margin);
        localStorage.setItem('right_margin', nextProps.LoginData.data.clinic.right_margin);
        localStorage.setItem('clinicAddress', nextProps.LoginData.data.clinic.address.address + ',' + nextProps.LoginData.data.clinic.address.city);
        localStorage.setItem('Address', nextProps.LoginData.data.clinic.address.address + ',' + nextProps.LoginData.data.clinic.address.city);
        localStorage.setItem('clinicPhone', nextProps.LoginData.data.clinic.phone ? nextProps.LoginData.data.clinic.phone : nextProps.LoginData.data.clinic.address.phone);
        localStorage.setItem('Phone', nextProps.LoginData.data.clinic.phone ? nextProps.LoginData.data.clinic.phone : nextProps.LoginData.data.clinic.address.phone);
        localStorage.setItem('clinicEmail', nextProps.LoginData.data.clinic.email);
        localStorage.setItem('isEmailFacility', nextProps.LoginData.data.clinic.is_email_facility);
        localStorage.setItem('is_email_facility', nextProps.LoginData.data.clinic.is_email_facility);
        localStorage.setItem('is_chat_facility', nextProps.LoginData.data.clinic.is_chat_facility);
        localStorage.setItem('is_print_vital_complaint_diagnosis', nextProps.LoginData.data.clinic.is_print_vital_complaint_diagnosis);
        localStorage.setItem('is_print_doctor_signature', nextProps.LoginData.data.clinic.is_print_doctor_signature);
        localStorage.setItem('is_prescription_share_on_app', nextProps.LoginData.data.clinic.is_prescription_share_on_app);
        localStorage.setItem('is_prescription_edit_by_educator', nextProps.LoginData.data.clinic.is_prescription_edit_by_educator);
        localStorage.setItem('is_prescription_edit_by_receptionist', nextProps.LoginData.data.clinic.is_prescription_edit_by_receptionist);
        localStorage.setItem('is_week_off', JSON.stringify(nextProps.LoginData.data.clinic.week_off));
        localStorage.setItem('holiday_date', JSON.stringify(nextProps.LoginData.data.clinic.holiday_date));
        localStorage.setItem('patientNotificationDay', nextProps.LoginData.data.clinic.patientNotificationDay);
        localStorage.setItem('educatorDietStatus', nextProps.LoginData.data.clinic.educatorDietStatus);
        localStorage.setItem('language', nextProps.LoginData.data.clinic.language);
        localStorage.setItem('insulinCalculator', nextProps.LoginData.data.clinic.insulinCalculator);
        localStorage.setItem('is_calendar_facility', nextProps.LoginData.data.clinic.is_calendar_facility);
        localStorage.setItem('is_report_folder_facility', nextProps.LoginData.data.clinic.is_report_folder_facility);
        localStorage.setItem('birthdayWish', nextProps.LoginData.data.clinic.birthdayWish);
        localStorage.setItem('prescription_print_margin_top', nextProps.LoginData.data.clinic.prescription_print_margin_top);
        localStorage.setItem('prescription_print_margin_bottom', nextProps.LoginData.data.clinic.prescription_print_margin_bottom);
        localStorage.setItem('prescription_print_margin_left', nextProps.LoginData.data.clinic.prescription_print_margin_left);
        // localStorage.setItem(
        //   "is_print_complaint",
        //   nextProps.LoginData.data.clinic.is_print_complaint !== undefined ? nextProps.LoginData.data.clinic.is_print_complaint : "no"
        // );

        // localStorage.setItem(
        //   "is_print_diagnosis",
        //   nextProps.LoginData.data.clinic.is_print_diagnosis !== undefined ? nextProps.LoginData.data.clinic.is_print_diagnosis : "no"
        // );

        // localStorage.setItem(
        //   "prescriptionPadSize",
        //   nextProps.LoginData.data.clinic.prescriptionPadSize !== undefined ? nextProps.LoginData.data.clinic.prescriptionPadSize : "a4"
        // );
        localStorage.setItem('is_mobile_no_mandatory',
          nextProps?.LoginData?.data?.clinic?.is_mobile_no_mandatory !== undefined ? nextProps?.LoginData?.data?.clinic?.is_mobile_no_mandatory : "no"
        );
      }

      if (nextProps.LoginData.data.userType == 'admin' && nextProps.LoginData.data.userCounts) {
        if (nextProps.LoginData.data.userCounts.length) {
          let userCounts = JSON.stringify(nextProps.LoginData.data.userCounts)
          localStorage.setItem('userCounts', userCounts)
        }

        localStorage.setItem('clinicCount', nextProps.LoginData.data.clinicCount)
      }

      if (nextProps.LoginData.data.userType === 'admin') {
        window.location.href = '/admin/dashboard';
      }
      else {
        localStorage.setItem('logo', nextProps.LoginData.data.clinic.logo_image)
        window.location.href = '/dashboard';
      }
    }

    if (nextProps.isLoginError !== this.props.isLoginError) {
      this.setState({ alert: nextProps.loginMessage.msg, showProcessing: false });
    }

    if (nextProps.isAddContactUs !== this.props.isAddContactUs) {
      this.successAlert();
      this.setState({
        contactFormData: {
          name: "",
          email: "",
          phone: "",
          message: ""
        },
        contactFormArr: []
      });
    }

    if (nextProps.isAddContactUsError !== this.props.isAddContactUsError) {
      this.errorAlert();
    }

  }

  setCookie(cname, cvalue, exdays) {
    cname = ((cname === 'username') ? 'Cookie1' : (cname === 'password') ? 'Cookie3' : cname);
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;Secure;HttpOnly";
  }

  getCookie(cname) {
    cname = ((cname === 'username') ? 'Cookie1' : (cname === 'password') ? 'Cookie3' : cname);
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  onForgotPwd() {
    window.location.href = '/forgot-password';
  }

  userLogin(evt) {

    evt.preventDefault();
    const _this = this;
    if (_this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      let formData = _this.state.formData;
      let password = (_this.getCookie('password') === formData['password']) ? _this.UnicodeDecodeB64(_this.getCookie('password')) : formData['password'];
      _this.setState({ formData: { ...formData, passwordOld: password } });
      _this.props.loginAction({ formData: { ...formData, password } });
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = []
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({
      formData: field
    });
  };

  onKeyDownSubmit(event) {
    let key = event.which || event.keyCode;
    if (key === 13) {
      document.getElementById('login-btn').click();
    }
  }

  //Contact

  handleContactChange(e) {
    e.preventDefault();
    let field = this.state.contactFormData;
    field[e.target.name] = e.target.value;
    this.setState({
      contactFormData: field
    });
  };

  addContact(evt) {
    evt.preventDefault();
    const _this = this;

    if (this.allValidateContact(false)) {
      if (this.state.contactFormData) {
        this.props.addContactUsAction(this.state.contactFormData);
      } else {
        this.setState({ contactErr: true });
      }
    }
  }

  allValidateContact(check) {
    if (!check) {
      contactFormArr = []
      Object.keys(cRules).forEach(function (key) {
        contactFormArr[key] = "TT";
      });
      this.setState({
        contactFormArr
      });
    }
    if (validationCnt.passes()) {
      return 1;
    }
  }

  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          //onConfirm={() => this.hideAlert()}
          //onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Data added Successfully!
        </SweetAlert>
      )
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  errorAlert() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Something went wrong :)
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    let contactFormData = this.state.contactFormData;
    contactFormData['message'] = '';
    this.setState({
      alert: null,
      contactFormData: contactFormData
    });
    this.setState({ showForm: false });
    let _this = this;
    setTimeout(function () {
      _this.setState({ showForm: true });
    }, 300);
  }

  render() {

    const { formData, contactFormData } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    validationCnt = new Validator(this.state.contactFormData, cRules, mess);
    validationCnt.passes();
    validationCnt.fails();

    return (
      <Media>

        <Row>
          <div className="home-ban">
            <div className="banner_content banner-Homep">
              <Grid>
                <Row>
                  <Col md={6} sm={8} mdOffset={3} smOffset={2}>
                    <form>
                      <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                        <img src={loadingImg} alt="Loading" width="40px" />
                        <center>Logging In - Please Wait</center>
                      </div>
                      <Card
                        hidden={this.state.cardHidden}
                        textCenter
                        title="Login"
                        content={
                          <div>
                            <Row>
                              <Col md={6}>
                                <FormGroup>
                                  <ControlLabel className="float-texts">User Name</ControlLabel>
                                  <FormControl
                                    placeholder="Enter User Name"
                                    type="email"
                                    id="username"
                                    name="username"
                                    autoComplete="off"
                                    value={this.state.formData.username}
                                    onChange={e => { this.handleChange(e); }}
                                    onKeyDown={e => { this.onKeyDownSubmit(e); }}
                                  />
                                  <span className="errorMsg">{this.state.formArr.username && validation.errors.first('username')}</span>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup style={{ position: 'relative' }}>
                                  <ControlLabel className="float-texts">Password</ControlLabel>
                                  <FormControl
                                    placeholder="Password"
                                    type={this.state.passwordShown ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    value={this.state.formData.password}
                                    autoComplete="off"
                                    onChange={e => { this.handleChange(e); }}
                                    onKeyDown={e => { this.onKeyDownSubmit(e); }}
                                  />
                                  <span className="errorMsg">{this.state.formArr.password && validation.errors.first('password')}</span>
                                  <span className='showPassword'>
                                    {this.state?.passwordShown && this.state.passwordShown ?
                                      <i className="fa fa-eye" onClick={() => this.setState({ passwordShown: !this.state.passwordShown })} aria-hidden="true"></i>
                                      : <i className="fa fa-eye-slash" onClick={() => this.setState({ passwordShown: !this.state.passwordShown })} aria-hidden="true"></i>
                                    }


                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>

                            <FormGroup>
                              <Row className="flex-property">
                                <Col md={6} className="forgot-pwd-left">
                                  <Checkbox number="1" label="Keep me signed in" />
                                </Col>
                                <Col md={6} className="forgot-pwd">
                                  <a onClick={this.onForgotPwd}  >Forgot Password?</a>
                                </Col>
                              </Row>
                            </FormGroup>
                            {this.state.alert &&
                              <div className="errorMsg"> {this.state.alert} </div>
                            }
                          </div>
                        }
                        legend={
                          <Button id={'login-btn'} onClick={this.userLogin.bind(this)} bsStyle="info" fill wd>Login</Button>
                        }
                        ftTextCenter
                      />
                    </form>
                  </Col>
                </Row>
              </Grid>

            </div>
            <div className="whats-app-fixed">
              <a href="https://api.whatsapp.com/send?phone=919644433305" target="_blank">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                  </svg>
                </span>
                +91-9644-433-305
              </a>
            </div>
          </div>
        </Row>

        <div className="container middle-ban-adjust">
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-bluebox max-w">
                <h3>Supoorted By</h3>
                <div className="text-center">
                  <img src={erisLogo} alt="Eris" width="120" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
          <Grid>
            <Row>
              <Col md={12} sm={12} className="form-contact-f">
                <div className="form-contT">
                  <form>
                    <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                      <img src={loadingImg} alt="Loading" width="40px" />
                      <center>Logging In - Please Wait</center>
                    </div>
                    <Card
                      hidden={this.state.cardHidden}
                      textCenter
                      title="How can we help you?"
                      content={
                        <div>
                          <Row>
                            <Col md={12}>
                              <FormGroup>
                                <FormControl
                                  placeholder="Name*"
                                  type="text"
                                  name="name"
                                  value={contactFormData.name}
                                  onChange={e => { this.handleContactChange(e); }}
                                />
                                <span className="errorMsg">{this.state.contactFormArr.name && validationCnt.errors.first('name')}</span>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <FormControl
                                  placeholder="E-Mail*"
                                  type="email"
                                  name="email"
                                  value={contactFormData.email}
                                  onChange={e => { this.handleContactChange(e); }}
                                />
                                <span className="errorMsg">{this.state.contactFormArr.email && validationCnt.errors.first('email')}</span>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <FormControl
                                  placeholder="Phone*"
                                  type="text"
                                  name="phone"
                                  maxLength={10}
                                  value={contactFormData.phone}
                                  onChange={e => { this.handleContactChange(e); }}
                                />
                                <span className="errorMsg">{this.state.contactFormArr.phone && validationCnt.errors.first('phone')}</span>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormControl
                                componentClass="textarea"
                                name="message"
                                placeholder="Message"
                                rows="5"
                                value={contactFormData.message}
                                onChange={e => { this.handleContactChange(e); }}
                              />
                            </Col>
                          </Row>

                          {this.state.alert &&
                            <div className="errorMsg"> {this.state.alert} </div>
                          }
                        </div>
                      }
                      legend={
                        <Button id={'login-btn'} onClick={this.addContact.bind(this)} bsStyle="info" fill wd>Submit</Button>
                      }
                      ftTextCenter
                    />
                  </form>
                </div>

              </Col>
            </Row>
          </Grid>
        </div> */}



        <div className="middle-spc"></div>
        <div className={"container" + (this.props.fluid !== undefined ? "-fluid" : "")}>
          <div className="row">
            <div className="ourcustom">
              <h2>Our Custom Healthcare Services</h2>
            </div>
          </div>
        </div>

        <Grid>
          <Row>
            <Col lg={12} md={12}>
              <Col lg={6} md={6} className="img-fixheight bg-colorimg">
                <img width={'100%'} height={'100%'} src={medzing} />
              </Col>

              <Col lg={6} md={6}>
                <div className="application-d">
                  <h3>
                    Application Development
                  </h3>
                  <ul>
                    <li>Interactive Web Solutions</li>
                    <li>Appointment Scheduler </li>
                    <li>Consultation and Billing</li>
                    <li>EHR &amp; EMR</li>
                    <li>SAAS Solutions</li>
                  </ul>
                </div>
              </Col>
            </Col>

            <Col lg={12} md={12}>
              <Col lg={6} md={6} className="img-fixheight mob-touch-right bg-colorimg">
                <img
                  width={'100%'}
                  height={'100%'}
                  src={touch}
                />
              </Col>

              <Col lg={6} md={6}>
                <div className="application-d">
                  <h3>
                    Mobile Application Development
                  </h3>

                  <ul>
                    <li>IOS, Android and Hybrid Apps</li>
                    <li>Consultation via Mobile App</li>
                    <li>Feature-rich Applications</li>
                    <li>Patient Educaton and Document Sharing</li>
                    <li>Integration of Mobile Wallets</li>
                  </ul>
                </div>
              </Col>

            </Col>

            <Col lg={12} md={12}>
              <Col lg={6} md={6} className="img-fixheight bg-colorimg">
                <img
                  width={'100%'}
                  height={'100%'}
                  src={design}
                />
              </Col>
              <Col lg={6} md={6}>
                <div className="application-d">
                  <h3>
                    Design and Development Services
                  </h3>
                  <ul>
                    <li>Responsive Website Development </li>
                    <li>UI and UX Services</li>
                    <li>Custom Development Services</li>
                  </ul>
                </div>
              </Col>
            </Col>
          </Row>

          <div className="middle-spc"></div>
          <div className="we-are-practiceaid">
            <div className="ourcustom">
              <h2>We are PracticeAid!</h2>
            </div>
            <div className="pera-text">
              <p>Practice-Aid Software offers a comprehensive suite of tools designed to enhance the focus on essential aspects such as patient care. Tailored for clinics, doctors, receptionists, and educators, our platform aims to streamline various operations and optimize overall efficiency. Here's a breakdown of the key features:</p>
            </div>
            <div className="middle-spc"></div>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Virtual Dietitian</h3>
                    <p>Practice-Aid Software extends its functionality to include a "Virtual Dietitian" feature, enhancing the overall healthcare experience</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Knowledge Sharing</h3>
                    <p>Practice-Aid Software has inbuilt video and document library that you can share with your patient, we also encourage you to create your own content in your own language to educate your patients.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Go Paperless</h3>
                    <p>Embrace a digital workflow by eliminating traditional paper-based processes. This not only reduces environmental impact but also enhances organizational efficiency</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Industry-Leading Security</h3>
                    <p>Prioritize the confidentiality and security of sensitive medical information with our state-of-the-art security measures, ensuring compliance with industry standards.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Reduce No Shows</h3>
                    <p>Employ strategies to minimize patient no-shows through effective appointment reminders and scheduling features.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Streamline Your Front Office</h3>
                    <p>Enhance front-office operations by simplifying tasks such as appointment booking, patient check-ins, and administrative processes.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Electronic Medical Records (EMR)</h3>
                    <p>Digitize and centralize patient records, making them easily accessible to authorized healthcare professionals. This promotes a more organized and efficient healthcare delivery system.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Prescription Management</h3>
                    <p>Simplify the prescription process with electronic prescription capabilities, improving accuracy, and reducing the risk of errors.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Clinic Reporting & Analytics</h3>
                    <p>Access comprehensive reports and analytics to gain valuable insights into clinic performance, patient trends, and other key metrics for informed decision-making.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Various Types of Calculators</h3>
                    <p>Integrate various calculators to assist healthcare professionals in making precise calculations related to patient care and treatment plans.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>ePrescription Sharing with Patients</h3>
                    <p>Enable seamless sharing of electronic prescriptions with patients via their mobile devices, fostering better communication and adherence to prescribed treatments.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className="card">
                  <div className="card-body">
                    <h3>Visual Symbolism for Patient Understanding</h3>
                    <p>Utilize various symbols and visual aids to enhance patient comprehension, making it easier for them to understand medical information and instructions.</p>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="pera-text">
              <p>Practice-Aid Software aims to revolutionize the healthcare experience by providing a user-friendly and efficient platform that caters to the diverse needs of clinics, doctors, receptionists, and educators. With a focus on digitization, security, and enhanced patient engagement, our software empowers healthcare professionals to deliver optimal care.</p>
            </div>
            <div className="middle-spc"></div>
          </div>
        </Grid>
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

    isAddContactUs: state.settings.isAddContactUs,
    isAddContactUsError: state.settings.isAddContactUsError,
    AddContactUs: state.settings.AddContactUs,

  }
}

// export default GoogleApiWrapper({
//   apiKey: "YOUR_API_KEY_HERE"
// });
export default withRouter(connect(mapStateToProps, { loginAction, addContactUsAction })(Contact));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import loadingImg from '../../../assets/img/loading.gif';
import moment from 'moment';
import axios from 'axios';
import { useState, useEffect } from 'react';

const ClinicPerformanceReport = () => {
    const [showProcessing, setShowProcessing] = useState(true);
    const [totalPatientCount, setTotalPatientCount] = useState(0);
    const [appInstallCount, setAppInstallCount] = useState(0);
    const [dietPrintCount, setDietPrintCount] = useState(0);
    const [dietSharedCount, setDietSharedCount] = useState(0);
    const [totalVideoShareCount, setTotalVideoShareCount] = useState(0);
    const [totalVideoUploadedByAdminShareOnApp, setTotalVideoUploadedByAdminShareOnApp] = useState(0);
    const [totalVideoUploadedByCliniCShareOnApp, setTotalVideoUploadedByCliniCShareOnApp] = useState(0);
    const [totalDocumentShareCount, setTotalDocumentShareCount] = useState(0);
    const [totalDocumentUploadedByAdminShareOnApp, setTotalDocumentUploadedByAdminShareOnApp] = useState(0);
    const [totalDocumentUploadedByCliniCShareOnApp, setTotalDocumentUploadedByCliniCShareOnApp] = useState(0);
    const [totalPrescriptionPrintedCount, setTotalPrescriptionPrintedCount] = useState(0);
    const [totalPrescriptionSharedCount, setTotalPrescriptionSharedCount] = useState(0);
    const [totalNumberOfSharedReport, setTotalNumberOfSharedReport] = useState(0);
    const [totalNumberOfReportInSavedInApp, setTotalNumberOfReportInSavedInApp] = useState(0);
    const [totalNumberOfPatientsUsingGlucoseDiary, setTotalNumberOfPatientsUsingGlucoseDiary] = useState(0);
    const [totalInsulinCalculatorOn, setTotalInsulinCalculatorOn] = useState(0);
    const [totalClinicInformationInMyClinic, setTotalClinicInformationInMyClinic] = useState(0);
    const [totalPatientUsesChatFacilitey, setTotalPatientUsesChatFacilitey] = useState(0);
    const [lastNewsVisit, setlastNewsVisit] = useState(0);
    const [totalBirthdayWishCount, setTotalBirthdayWishCount] = useState(0);
    const [videoSeenByPatientCount, setVideoSeenByPatientCount] = useState(0);
    const [videoNotSeenByPatientCount, setVideoNotSeenByPatientCount] = useState(0);
    const [documentsSeenByPatientCount, setDocumentsSeenByPatientCount] = useState(0);
    const [documentNotSeenByPatientCount, setDocumentsNotSeenByPatientCount] = useState(0);
    const [documentsPatientCount, setDocumentsPatientCount] = useState(0);
    const [totalAppPatientBelow20Year, setTotalAppPatientBelow20Year] = useState(0);
    const [totalAppPatientBetween20And30, setTotalAppPatientBetween20And30] = useState(0);
    const [totalAppPatientBetween30And40, setTotalAppPatientBetween30And40] = useState(0);
    const [totalAppPatientBetween40And50, setTotalAppPatientBetween40And50] = useState(0);
    const [totalAppPatientBetween50And60, setTotalAppPatientBetween50And60] = useState(0);
    const [totalAppPatientAbove60, setTotalAppPatientAbove60] = useState(0);
    const [videoSeenByEducatorCount, setVideoSeenByEducatorCount] = useState(0);
    const [videoNotSeenByEducatorCount, setVideoNotSeenByEducatorCount] = useState(0);
    const [documentSeenByEducatorCount, setDocumentSeenByEducatorCount] = useState(0);
    const [documentNotSeenByEducatorCount, setDocumentNotSeenByEducatorCount] = useState(0);
    const [videoShownToEducatorCount, setVideoShownToEducatorCount] = useState(0);
    const [totalDocumentShareToEducatorCount, setTotalDocumentShareToEducatorCount] = useState(0);
    const [patientNextVisitCount, setPatientNextVisitCount] = useState(0);
    const [totalDocumentShareFromAdminSeenByPatient, setTotalDocumentShareFromAdminSeenByPatient] = useState(0);
    const [totalVideoUploadedByAdminSeenByPatient, setTotalVideoUploadedByAdminSeenByPatient] = useState(0);
    const [totalDocumentUploadedByCliniCSeenByPatient, setTotalDocumentUploadedByCliniCSeenByPatient] = useState(0);
    const [totalVideoUploadedByCliniCSeenByPatients, setTotalVideoUploadedByCliniCSeenByPatients] = useState(0);
    const [totalShareFromClinic, setTotalShareFromClinic] = useState(0);
    const [totalShareFromLibrary, setTotalShareFromLibrary] = useState(0);
    const [totalAveragePatientGlucoseCount, setTotalAveragePatientGlucoseCount] = useState(0);
    const [totalInsulinCalculatorUser, setTotalInsulinCalculatorUser] = useState(0);
    const [totalInsulinnUnitPerPatient, settotalInsulinnUnitPerPatient] = useState(0);
    const [dietPrintNonAppCount, setDietPrintNonAppCount] = useState(0);
    const [totalStarStatusPatientCount, setTotalStarStatusPatientCount] = useState(0);
    const [totalGivenIconPatientCount, setTotalGivenIconPatientCount] = useState(0);
    const [totalNewsSeenPatientCount, setTotalNewsSeenPatientCount] = useState(0);
    const [totalAppointmentCount, setTotalAppointmentCount] = useState(0);
    // const [videoCount, setVideoCount] = useState(0);


    // const [documentCount, setDocumentCount] = useState(0);
    // const [documentsEducatorCount, setDocumentsEducatorCount] = useState(0);
    // const [documentsNotSeenEducatorCount, setDocumentsNotSeenEducatorCount] = useState(0);

    // const [documentsNotSeenPatientCount, setDocumentsNotSeenPatientCount] = useState(0);


    //const [totalclinicWisePatientInsulinCount, setTotalClinicWisePatientInsulinCount] = useState(0);
    //const [totalChatFaciliteyCount, setTotalChatFaciliteyCount] = useState(0);


    //const [totalClinicUploadedDocumentInMyknowledge, setTotalClinicUploadedDocumentInMyknowledge] = useState(0);
    //const [totalClinicUploadedVideosInMyKnowledge, setTotalClinicUploadedVideosInMyKnowledge] = useState(0);
    //const [totalPatientShareInformationInReport, setTotalPatientShareInformationInReport] = useState(0);

    //const [totalNoOfDocAndVideoShared, settotalNoOfDocAndVideoShared] = useState(0);
    //const [totalNoOfDocAndVideoShownByEducator, settotalNoOfDocAndVideoShownByEducator] = useState(0);
    //const [totalInsulinCount, setTotalInsulinCount] = useState(0);
    //const [totalclinicWiseInsulinCount, setTotalclinicWiseInsulinCount] = useState(0);





    const getReportList = async (from = {}) => {
        let sessionToken = localStorage.getItem('token');
        let resp = await axios({
            method: 'post',
            url: process.env.REACT_APP_PERFORMANCE_REPORT_URL + 'report/clinic',
            headers: {
                "Authorization": sessionToken,
                "Content-Type": "application/json"
            }
        })
        if (resp && resp.data) {
            setShowProcessing(false);

            setTotalPatientCount(resp.data.totalPatientCount ? resp.data.totalPatientCount : 0);

            setAppInstallCount(resp.data.appInstallCount ? resp.data.appInstallCount : 0);

            setDietPrintCount(resp.data.dietPrintCount ? resp.data.dietPrintCount : 0);

            setDietSharedCount(resp.data.dietSharedCount ? resp.data.dietSharedCount : 0);

            setDietPrintNonAppCount(resp.data.totalStarStatusPatientCount ? resp.data.totalStarStatusPatientCount : 0)

            setTotalGivenIconPatientCount(resp.data.totalGivenIconPatientCount ? resp.data.totalGivenIconPatientCount : 0)

            setTotalNewsSeenPatientCount(resp.data.totalNewsSeenPatientCount ? resp.data.totalNewsSeenPatientCount : 0)

            setTotalAppointmentCount(resp.data.totalAppointmentCount ? resp.data.totalAppointmentCount : 0)

            setTotalStarStatusPatientCount(resp.data.dietPrintNonAppCount ? resp.data.dietPrintNonAppCount : 0)

            setTotalVideoShareCount(resp.data.totalVideoShareCount ? resp.data.totalVideoShareCount : 0)

            setTotalVideoUploadedByAdminShareOnApp(resp.data.totalVideoUploadedByAdminShareOnApp ? resp.data.totalVideoUploadedByAdminShareOnApp : 0)

            setTotalVideoUploadedByCliniCShareOnApp(resp.data.totalVideoUploadedByCliniCShareOnApp ? resp.data.totalVideoUploadedByCliniCShareOnApp : 0)

            setTotalDocumentUploadedByAdminShareOnApp(resp.data.totalDocumentUploadedByAdminShareOnApp ? resp.data.totalDocumentUploadedByAdminShareOnApp : 0)

            setTotalDocumentUploadedByCliniCShareOnApp(resp.data.totalDocumentUploadedByCliniCShareOnApp ? resp.data.totalDocumentUploadedByCliniCShareOnApp : 0)

            setTotalVideoShareCount(resp.data.totalVideoShareCount ? resp.data.totalVideoShareCount : 0)

            setTotalClinicInformationInMyClinic(resp.data.totalMyClinicInformation ? resp.data.totalMyClinicInformation : 0);

            if (resp.data.totalDocumentUploadedByCliniCShareOnApp && resp.data.totalDocumentUploadedByAdminShareOnApp)
                setTotalDocumentShareCount(resp.data.totalDocumentUploadedByCliniCShareOnApp + resp.data.totalDocumentUploadedByAdminShareOnApp)
            else if (resp.data.totalDocumentUploadedByCliniCShareOnApp)
                setTotalDocumentShareCount(resp.data.totalDocumentUploadedByCliniCShareOnApp)
            else if (resp.data.totalDocumentUploadedByAdminShareOnApp)
                setTotalDocumentShareCount(resp.data.totalDocumentUploadedByAdminShareOnApp)
            else setTotalDocumentShareCount(0);

            setTotalNumberOfSharedReport(resp.data.totalNumberOfSharedReport ? resp.data.totalNumberOfSharedReport : 0)

            setTotalNumberOfReportInSavedInApp(resp.data.totalNumberOfReportInSavedInApp ? resp.data.totalNumberOfReportInSavedInApp : 0)

            setTotalNumberOfPatientsUsingGlucoseDiary(resp.data.totalNumberOfPatientsUsingGlucoseDiary ? resp.data.totalNumberOfPatientsUsingGlucoseDiary : 0)

            setTotalPrescriptionPrintedCount(resp.data.totalEMRPrescriptionPrint ? resp.data.totalEMRPrescriptionPrint : 0);

            setTotalPrescriptionSharedCount(resp.data.totalEMRPrescriptionShare ? resp.data.totalEMRPrescriptionShare : 0);

            setTotalInsulinCalculatorOn(resp.data.totalInsulinCalculatorOn ? resp.data.totalInsulinCalculatorOn : 0);

            setTotalInsulinCalculatorUser(resp.data.totalInsulinCalculatorUser ? resp.data.totalInsulinCalculatorUser : 0);

            settotalInsulinnUnitPerPatient(resp.data.totalInsulinnUnitPerPatient ? resp.data.totalInsulinnUnitPerPatient : 0);

            setTotalPatientUsesChatFacilitey(resp.data.totalPatientUsesChatFacilitey ? resp.data.totalPatientUsesChatFacilitey : 0);

            setlastNewsVisit(resp.data.lastNewsVisit ? resp.data.lastNewsVisit : 0);

            setTotalBirthdayWishCount(resp.data.totalBithdayWish ? resp.data.totalBithdayWish : 0);

            setVideoSeenByPatientCount(resp.data.videoShownByPatientCount ? resp.data.videoShownByPatientCount : 0);

            setVideoNotSeenByPatientCount(resp.data.videoNotSeenByPatientCount ? resp.data.videoNotSeenByPatientCount : 0);

            setDocumentsSeenByPatientCount(resp.data.documentsSeenByPatientCount ? resp.data.documentsSeenByPatientCount : 0);

            setDocumentsNotSeenByPatientCount(resp.data.documentNotSeenByPatientCount ? resp.data.documentNotSeenByPatientCount : 0);


            setTotalAppPatientBelow20Year(resp.data.totalAppPatientBelow20Year ? resp.data.totalAppPatientBelow20Year : 0);

            setTotalAppPatientBetween20And30(resp.data.totalAppPatientBetween20And30 ? resp.data.totalAppPatientBetween20And30 : 0);

            setTotalAppPatientBetween30And40(resp.data.totalAppPatientBetween30And40 ? resp.data.totalAppPatientBetween30And40 : 0);

            setTotalAppPatientBetween40And50(resp.data.totalAppPatientBetween40And50 ? resp.data.totalAppPatientBetween40And50 : 0);

            setTotalAppPatientBetween50And60(resp.data.totalAppPatientBetween50And60 ? resp.data.totalAppPatientBetween50And60 : 0);

            setVideoSeenByEducatorCount(resp.data.videoSeenByEducatorCount ? resp.data.videoSeenByEducatorCount : 0);

            setVideoNotSeenByEducatorCount(resp.data.videoNotSeenByEducatorCount ? resp.data.videoNotSeenByEducatorCount : 0);

            setDocumentSeenByEducatorCount(resp.data.documentsSeenByEducatorCount ? resp.data.documentsSeenByEducatorCount : 0);

            setDocumentNotSeenByEducatorCount(resp.data.documentsNotSeenByEducatorCount ? resp.data.documentsNotSeenByEducatorCount : 0);

            setVideoShownToEducatorCount(resp.data.videoShownToEducatorCount ? resp.data.videoShownToEducatorCount : 0);

            setTotalDocumentShareToEducatorCount(resp.data.totalDocumentShareToEducatorCount ? resp.data.totalDocumentShareToEducatorCount : 0);

            setPatientNextVisitCount(resp.data.patientNextVisitCount ? resp.data.patientNextVisitCount : 0);

            setTotalDocumentShareFromAdminSeenByPatient(resp.data.totalDocumentShareFromAdminSeenByPatient ? resp.data.totalDocumentShareFromAdminSeenByPatient : 0);

            setTotalVideoUploadedByAdminSeenByPatient(resp.data.totalVideoUploadedByAdminSeenByPatient ? resp.data.totalVideoUploadedByAdminSeenByPatient : 0);

            setTotalDocumentUploadedByCliniCSeenByPatient(resp.data.totalDocumentUploadedByCliniCSeenByPatient ? resp.data.totalDocumentUploadedByCliniCSeenByPatient : 0);

            setTotalVideoUploadedByCliniCSeenByPatients(resp.data.totalVideoUploadedByCliniCSeenByPatients ? resp.data.totalVideoUploadedByCliniCSeenByPatients : 0);

            if (resp.data.totalDocumentUploadedByCliniCShareOnApp && resp.data.totalVideoUploadedByCliniCShareOnApp)
                setTotalShareFromClinic(resp.data.totalDocumentUploadedByCliniCShareOnApp + resp.data.totalVideoUploadedByCliniCShareOnApp)
            else if (resp.data.totalDocumentUploadedByCliniCShareOnApp)
                setTotalShareFromClinic(resp.data.totalDocumentUploadedByCliniCShareOnApp)
            else if (resp.data.totalVideoUploadedByCliniCShareOnApp)
                setTotalShareFromClinic(resp.data.totalVideoUploadedByCliniCShareOnApp)
            else setTotalShareFromClinic(0);

            if (resp.data.totalVideoUploadedByAdminShareOnApp && resp.data.totalDocumentUploadedByAdminShareOnApp)
                setTotalShareFromLibrary(resp.data.totalVideoUploadedByAdminShareOnApp + resp.data.totalDocumentUploadedByAdminShareOnApp)
            else if (resp.data.totalVideoUploadedByAdminShareOnApp)
                setTotalShareFromLibrary(resp.data.totalVideoUploadedByAdminShareOnApp)
            else if (resp.data.totalDocumentUploadedByAdminShareOnApp)
                setTotalShareFromLibrary(resp.data.totalDocumentUploadedByAdminShareOnApp)
            else setTotalShareFromLibrary(0);

            setTotalAveragePatientGlucoseCount(resp.data.totalAveragePatientGlucoseCount ? resp.data.totalAveragePatientGlucoseCount : 0)

            setTotalAppPatientAbove60(resp.data.totalAppPatientAbove60 ? resp.data.totalAppPatientAbove60 : 0);

            // if (resp.data.videoCount > 0) {
            //     setVideoCount(resp.data.videoCount);
            //     setVideoShownByEducatorCount(resp.data.videoShownByEducatorCount);
            //     
            // }

            // if (resp.data.documentCount > 0) {
            //     setDocumentCount(resp.data.documentCount);
            //     setDocumentsEducatorCount(resp.data.documentsEducatorCount);
            //     setDocumentsNotSeenEducatorCount(resp.data.documentsNotSeenEducatorCount);
            //     setDocumentsNotSeenPatientCount(resp.data.documentsNotSeenPatientCount);
            //     setDocumentsPatientCount(resp.data.documentsPatientCount);
            // }



            // setTotalClinicWisePatientInsulinCount(resp.data.totalclinicWisePatientInsulinCount ? resp.data.totalclinicWisePatientInsulinCount : 0);

            //setTotalChatFaciliteyCount(resp.data.totalChatFaciliteyCount ? resp.data.totalChatFaciliteyCount : 0);



            //setTotalClinicUploadedVideosInMyKnowledge(resp.data.totalClinicUploadedVideosInMyKnowledge ? resp.data.totalClinicUploadedVideosInMyKnowledge : 0);

            //setTotalClinicUploadedDocumentInMyknowledge(resp.data.totalClinicUploadedDocumentInMyknowledge ? resp.data.totalClinicUploadedDocumentInMyknowledge : 0);



            //setTotalPatientShareInformationInReport(resp.data.totalPatientShareInformationInReport ? resp.data.totalPatientShareInformationInReport : 0);



            //settotalNoOfDocAndVideoShared(resp.data.totalNoOfDocAndVideoShared ? resp.data.totalNoOfDocAndVideoShared : 0);

            //settotalNoOfDocAndVideoShownByEducator(resp.data.totalNoOfDocAndVideoShownByEducator ? resp.data.totalNoOfDocAndVideoShownByEducator : 0);

            //setTotalclinicWiseInsulinCount(resp.data.totalclinicWiseInsulinCount ? resp.data.totalclinicWiseInsulinCount : 0);

            //setTotalInsulinCount(resp.data.totalInsulinCount ? resp.data.totalInsulinCount : 0);




        }


    }

    useEffect(() => {
        getReportList();
    }, []);
    return (
        <>
            <div className='main-content clinic-performance-report'>
                <div className="actionProcess" style={{ display: showProcessing ? 'block' : 'none', height: "200%" }}>
                    <img src={loadingImg} alt="Loading" width="40px" />
                    <center> Please Wait</center>
                </div>
                <div className='container-fluid'>
                    <Row>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Patients</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Total number of patients</span>
                                            <span className='lable-value'>{totalPatientCount ? totalPatientCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>App</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>App Users</span>
                                            <span className='lable-value'>{appInstallCount ? appInstallCount : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Non App users</span>
                                            <span className='lable-value'>{totalPatientCount ? totalPatientCount - appInstallCount : 0}</span>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Diet Chart</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Diet Charts Shared</span>
                                            <span className='lable-value'>{dietSharedCount ? dietSharedCount : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Diet Charts Printed</span>
                                            <span className='lable-value'>{dietPrintCount ? dietPrintCount : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Diet Charts Printed For Non App Users</span>
                                            <span className='lable-value'>{dietPrintNonAppCount ? dietPrintNonAppCount : 0}</span>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Video & Doc  Share From Library</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Doc Shared From Library</span>
                                            <span className='lable-value'>{totalDocumentUploadedByAdminShareOnApp ? totalDocumentUploadedByAdminShareOnApp : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Doc Seen By Patient</span>
                                            <span className='lable-value'>{totalDocumentShareFromAdminSeenByPatient ? totalDocumentShareFromAdminSeenByPatient : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Video Shared From Library</span>
                                            <span className='lable-value'>{totalVideoUploadedByAdminShareOnApp ? totalVideoUploadedByAdminShareOnApp : 0}</span>
                                        </li>

                                        <li>
                                            <span className='card-lable'>Video Seen By Patient</span>
                                            <span className='lable-value'>{totalVideoUploadedByAdminSeenByPatient ? totalVideoUploadedByAdminSeenByPatient : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Share From Library</span>
                                            <span className='lable-value'>{totalShareFromLibrary ? totalShareFromLibrary : 0}</span>
                                        </li>


                                        {/* <li>
                                <span className='card-lable'>Video Shared</span>
                                <span className='lable-value'>{totalVideoShareCount ? totalVideoShareCount : 0}</span>
                            </li>
                            
                            <li>
                                <span className='card-lable'>Video Not Seen By Patient</span>
                                <span className='lable-value'>{videoNotSeenByPatientCount ? videoNotSeenByPatientCount : 0}</span>
                            </li> */}
                                    </ul>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Star Status</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Total patients given star status</span>
                                            <span className='lable-value'>{totalStarStatusPatientCount ? totalStarStatusPatientCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Icons</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Total patient given icon facility</span>
                                            <span className='lable-value'>{totalGivenIconPatientCount ? totalGivenIconPatientCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        {/* <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>News Report</p>
                        <ul>
                            <li>
                                <span className='card-lable'>News Room Viewership No</span>
                                <span className='lable-value'>{lastNewsVisit ? lastNewsVisit : 0}</span>
                            </li>
                        </ul>
                    </div>
                
                </div>
            </Col> */}
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>News Room</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>No. of patients have seen news</span>
                                            <span className='lable-value'>{totalNewsSeenPatientCount ? totalNewsSeenPatientCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Appointment</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>No. of appointment given</span>
                                            <span className='lable-value'>{totalAppointmentCount ? totalAppointmentCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Video & Doc From Self-Creation</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Doc Shared</span>
                                            <span className='lable-value'>{totalDocumentUploadedByCliniCShareOnApp ? totalDocumentUploadedByCliniCShareOnApp : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Doc Seen By Patient</span>
                                            <span className='lable-value'>{totalDocumentUploadedByCliniCSeenByPatient ? totalDocumentUploadedByCliniCSeenByPatient : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Video Shared</span>
                                            <span className='lable-value'>{totalVideoUploadedByCliniCShareOnApp ? totalVideoUploadedByCliniCShareOnApp : 0}</span>
                                        </li>

                                        <li>
                                            <span className='card-lable'>Video Seen By Patient</span>
                                            <span className='lable-value'>{totalVideoUploadedByCliniCSeenByPatients ? totalVideoUploadedByCliniCSeenByPatients : 0}</span>
                                        </li>

                                        <li>
                                            <span className='card-lable'>Total Share From  Self-Creation</span>
                                            <span className='lable-value'>{totalShareFromClinic ? totalShareFromClinic : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Emr</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>No. of Prescription Printed</span>
                                            <span className='lable-value'>{totalPrescriptionPrintedCount ? totalPrescriptionPrintedCount : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>No. of Prescription Shared</span>
                                            <span className='lable-value'>{totalPrescriptionSharedCount ? totalPrescriptionSharedCount : 0}</span>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </Col>
                        {/* <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>News Report</p>
                        <ul>
                            <li>
                                <span className='card-lable'>News Room Viewership No</span>
                                <span className='lable-value'>{lastNewsVisit ? lastNewsVisit : 0}</span>
                            </li>
                        </ul>
                    </div>
                
                </div>
            </Col> */}
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Glucose Diary</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>No. of Patients Using Glucose Diary</span>
                                            <span className='lable-value'>{totalNumberOfPatientsUsingGlucoseDiary ? totalNumberOfPatientsUsingGlucoseDiary : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>No of Glucose Reading Per Patient Per Week </span>
                                            <span className='lable-value'>{totalAveragePatientGlucoseCount ? totalAveragePatientGlucoseCount.toFixed(2) : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Insulin Calculator</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Insulin Calculator On</span>
                                            <span className='lable-value'>{totalInsulinCalculatorOn ? totalInsulinCalculatorOn : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Insulin Calculator User</span>
                                            <span className='lable-value'>{totalInsulinCalculatorUser ? totalInsulinCalculatorUser : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Insulin unit per patient </span>
                                            <span className='lable-value'>{totalInsulinnUnitPerPatient ? totalInsulinnUnitPerPatient.toFixed(2) : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Medical Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Reports Saved In App</span>
                                            <span className='lable-value'>{totalNumberOfReportInSavedInApp ? totalNumberOfReportInSavedInApp : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Reports Shared To Doctor</span>
                                            <span className='lable-value'>{totalNumberOfSharedReport ? totalNumberOfSharedReport : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Chat Facility Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Patients Using Chat</span>
                                            <span className='lable-value'>{totalPatientUsesChatFacilitey ? totalPatientUsesChatFacilitey : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Birthday Wish Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Birthday Wishes Sent</span>
                                            <span className='lable-value'>{totalBirthdayWishCount ? totalBirthdayWishCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Visit Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Next Visit Date Given</span>
                                            <span className='lable-value'>{patientNextVisitCount ? patientNextVisitCount : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        {/* <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>Video Shared Report (Educator)</p>
                        <ul>
                            <li>
                                <span className='card-lable'>Video Shared</span>
                                <span className='lable-value'>{videoShownToEducatorCount ? videoShownToEducatorCount : 0}</span>
                            </li>
                            <li>
                                <span className='card-lable'>Video Seen By Educator</span>
                                <span className='lable-value'>{videoSeenByEducatorCount ? videoSeenByEducatorCount : 0}</span>
                            </li>
                            <li>
                                <span className='card-lable'>Video Not Seen By Educator</span>
                                <span className='lable-value'>{videoNotSeenByEducatorCount ? videoNotSeenByEducatorCount : 0}</span>
                            </li>
                        </ul>
                    </div>
                
                </div>
            </Col>
            
            <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>Document Shared Report (Educator)</p>
                        <ul>
                            <li>
                                <span className='card-lable'>Document Shared</span>
                                <span className='lable-value'>{totalDocumentShareToEducatorCount ? totalDocumentShareToEducatorCount : 0}</span>
                            </li>
                            <li>
                                <span className='card-lable'>Document Seen By Educator</span>
                                <span className='lable-value'>{documentSeenByEducatorCount ? documentSeenByEducatorCount : 0}</span>
                            </li>
                            <li>
                                <span className='card-lable'>Document Not Seen By Educator</span>
                                <span className='lable-value'>{documentNotSeenByEducatorCount ? documentNotSeenByEducatorCount : 0}</span>
                            </li>
                        </ul>
                    </div>
                
                </div>
            </Col> */}
                    </Row>

                    <Row>

                        <Col lg={3} sm={4} >
                            <div className="custom-state-card card card-stats">
                                <div className='content'>
                                    <p>Patient Age Wise Report</p>
                                    <ul>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient Below 20 Year</span>
                                            <span className='lable-value'>{totalAppPatientBelow20Year ? totalAppPatientBelow20Year : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient Between 21 And 30 Year</span>
                                            <span className='lable-value'>{totalAppPatientBetween20And30 ? totalAppPatientBetween20And30 : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient Between 31 And 40 Year</span>
                                            <span className='lable-value'>{totalAppPatientBetween30And40 ? totalAppPatientBetween30And40 : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient Between 41 and 50 Year</span>
                                            <span className='lable-value'>{totalAppPatientBetween40And50 ? totalAppPatientBetween40And50 : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient Between 51 and 60 Year</span>
                                            <span className='lable-value'>{totalAppPatientBetween50And60 ? totalAppPatientBetween50And60 : 0}</span>
                                        </li>
                                        <li>
                                            <span className='card-lable'>Total Number Of Patient More Than 60 Year</span>
                                            <span className='lable-value'>{totalAppPatientAbove60 ? totalAppPatientAbove60 : 0}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </Col>

                        {/* <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>Total Video & Documents Shared</p>
                        <ul>
                            
                            <li>
                                <span className='card-lable'>All Video Shared</span>
                                <span className='lable-value'>{totalVideoShareCount ? totalVideoShareCount : 0}</span>
                            </li>
                            <li>
                                <span className='card-lable'>All Document Shared</span>
                                <span className='lable-value'>{totalDocumentShareCount ? totalDocumentShareCount : 0}</span>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </Col>

            <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>Video Shared</p>
                        <ul>
                            
                            
                            
                        </ul>
                    </div>
                </div>
            </Col>

            <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>Document Shared</p>
                        <ul>
                            
                            
                            
                            
                        </ul>
                    </div>
                </div>
            </Col> */}



                    </Row>
                    {/* <Row>
        
           
            
            
            <Col lg={3} sm={4} >
                <div className="custom-state-card card card-stats">
                    <div  className='content'>
                        <p>My Clinic</p>
                        <ul>
                            <li>
                                <span className='card-lable'>Clinic Information</span>
                                <span className='lable-value'>{totalClinicInformationInMyClinic ? totalClinicInformationInMyClinic : 0}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Col>
        </Row> */}






                </div>
            </div>
        </>
    )
}


export default withRouter(ClinicPerformanceReport)


/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddServingSize: false,
  isAddServingSizeError: false,
  AddServingSize: false,
  isUpdateServingSize: false,
  isUpdateServingSizeError: false,
  UpdateServingSize: false,
  isServingSizeChangeStatus: false,
  isServingSizeChangeStatusError: false,
  ServingSizeChangeStatus: false,
  isServingSizeList: false,
  isServingSizeListError: false,
  ServingSizeList: false,

  isServingSizeImageStatus: false,
  isServingSizeImageStatusError: false,
  ServingSizeImageStatus: false,
  // isUploadFile:false,
  // isUploadFileError: false,
  // uploadFile: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_SERVING_SIZE_REQUEST:
      return {
        ...state,
        message: '',
        isAddServingSize: false,
        isAddServingSizeError: false,
      }
    case actionTypes.GET_ADD_SERVING_SIZE_SUCCESS:
      return {
        ...state,
        message: 'Serving Size Added Successfully',
        AddServingSize: action.payload.response,
        isAddServingSize: true,
        isAddServingSizeError: false,
      }
    case actionTypes.GET_ADD_SERVING_SIZE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddServingSize: false,
        isAddServingSizeError: true
      }

    //Serving Size Update

    case actionTypes.GET_UPDATE_SERVING_SIZE_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateServingSize: false,
        isUpdateServingSizeError: false,
      }
    case actionTypes.GET_UPDATE_SERVING_SIZE_SUCCESS:
      return {
        ...state,
        message: 'Serving Size Updated Successfully',
        UpdateServingSize: action.payload.response,
        isUpdateServingSize: true,
        isUpdateServingSizeError: false,
      }
    case actionTypes.GET_UPDATE_SERVING_SIZE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateServingSize: false,
        isUpdateServingSizeError: true
      }

    //Link List
    case actionTypes.GET_SERVING_SIZE_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isServingSizeList: false,
        isServingSizeListError: false,
      }
    case actionTypes.GET_SERVING_SIZE_LIST_SUCCESS:
      return {
        ...state,
        message: '',
        ServingSizeList: action.payload.response,
        isServingSizeList: true,
        isServingSizeListError: false,
      }
    case actionTypes.GET_SERVING_SIZE_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isServingSizeList: false,
        isServingSizeListError: true
      }


    //Serving Size Status Change
    case actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isServingSizeChangeStatus: false,
        isServingSizeChangeStatusError: false,
      }
    case actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Serving Size Status Changed',
        ServingSizeChangeStatus: action.payload.response,
        isServingSizeChangeStatus: true,
        isServingSizeChangeStatusError: false,
      }
    case actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isServingSizeChangeStatus: false,
        isServingSizeChangeStatusError: true
      }

    //Serving Size Status Change
    case actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isServingSizeImageStatus: false,
        isServingSizeImageStatusError: false,
      }
    case actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Serving Size Status Changed',
        ServingSizeImageStatus: action.payload.response,
        isServingSizeImageStatus: true,
        isServingSizeImageStatusError: false,
      }
    case actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isServingSizeImageStatus: false,
        isServingSizeImageStatusError: true
      }
    default:
      return state


  }
}

/********* Show list of Link */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { treatmentListAction, treatmentChangeStatusAction, treamentChangeOrderAction } from 'Admin/actions/treatment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { appConstants } from 'Admin/_constants/app.constants';

class BSTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treatmentData: this.props.data,
            treatmentOrderValues: {}
        }
        this.handleOrder = this.handleOrder.bind(this);
    }

    componentDidMount() {
        let obj = {}
        for (let i in this.props.data) {
            obj["txt-" + this.props.data[i]._id] = this.props.data[i].order
        }
        this.setState({ treatmentOrderValues: obj })
    }



    editButton(cell, row, enumObject, rowIndex) {

        return (<p><Link to={{ pathname: `update-treatment/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
        </p>)
    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, elename) {
        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        // if (event.status == 'true') {
        //     newstatus = 'false';
        // } else {
        //     newstatus = 'true';
        // }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.treatmentChangeStatusAction(event);
    }
    idCount(cell, row, enumObject, rowIndex) {
        return rowIndex + 1;
    }

    renderOrder(cell, row, enumObject, rowIndex) {

        return (<p><input type="number" value={this.state.treatmentOrderValues["txt-" + row._id]} onChange={(e) => this.handleOrder(row, e)} />
            &nbsp;&nbsp; &nbsp;&nbsp;

        </p>)
    }

    handleOrder(row, event) {
        let data = {}
        data.id = row._id
        data.order = event.target.value
        let tempTreatmentOrderValues = this.state.treatmentOrderValues
        tempTreatmentOrderValues["txt-" + row._id] = parseInt(event.target.value)
        this.setState({ treatmentOrderValues: tempTreatmentOrderValues })
        if (event.target.value != "") {
            this.props.treamentChangeOrderAction(data)
        }
    }

    render() {
        if (this.props.data) {
            return (
                <BootstrapTable data={this.state.treatmentData}>
                    <TableHeaderColumn dataField='id' hidden isKey={true}>Product ID</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '4%' }} tdStyle={{ width: '4%' }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.idCount.bind(this)}>#</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '30%' }} tdStyle={{ width: '30%' }} tdAttr={{ 'data-attr': 'name' }} dataField='name'>Product Name</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '30%' }} tdStyle={{ width: '30%' }} tdAttr={{ 'data-attr': 'instruction' }} dataField='instruction'>Instruction</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Status' }} dataField='status'  >Status</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '16%' }} tdStyle={{ width: '16%' }} tdAttr={{ 'data-attr': 'Status' }} dataFormat={this.renderOrder.bind(this)} dataField='order'  >Order</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)}>Action</TableHeaderColumn>
                </BootstrapTable>);
        } else {
            return (<p>?</p>);
        }
    }
}

function mapStateToProps(state) {
    return {

        TreatmentList: state.treatment.TreatmentList,
        isTreatmentList: state.treatment.isTreatmentList,
        isTreatmentListError: state.treatment.isTreatmentListError,

        TreatmentChangeStatus: state.treatment.TreatmentChangeStatus,
        isTreatmentChangeStatus: state.treatment.isTreatmentChangeStatus,
        isTreatmentChangeStatusError: state.treatment.isTreatmentChangeStatusError,


    }
}

export default withRouter(connect(mapStateToProps, { treatmentListAction, treatmentChangeStatusAction, treamentChangeOrderAction })(BSTable));

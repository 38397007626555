/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
//import Card from 'Front/components/Card/Card.jsx';
import loadingImg from '../../../assets/img/loading.gif';
//import { Pie } from "react-chartjs-2";
import * as API from 'Front/api/report';
//import { getClientReportAction } from 'Front/actions/ClientReport';
import Datetime from 'react-datetime';
import moment from 'moment';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { appConstants } from '../../../_constants/app.constants';

const Report = () => {
    const [showProcessing, setShowProcessing] = useState(true);
    const [totalPatientCount, setTotalPatientCount] = useState(0);
    const [appInstallCount, setAppInstallCount] = useState(0);
    const [dietPrintCount, setDietPrintCount] = useState(0);
    const [dietSharedCount, setDietSharedCount] = useState(0);
    const [videoCount, setVideoCount] = useState(0);
    const [videoShownByEducatorCount, setVideoShownByEducatorCount] = useState(0);
    const [videoShownByPatientCount, setVideoShownByPatientCount] = useState(0);
    const [documentCount, setDocumentCount] = useState(0);
    const [documentsEducatorCount, setDocumentsEducatorCount] = useState(0);
    const [documentsNotSeenEducatorCount, setDocumentsNotSeenEducatorCount] = useState(0);
    const [documentsPatientCount, setDocumentsPatientCount] = useState(0);
    const [documentsNotSeenPatientCount, setDocumentsNotSeenPatientCount] = useState(0);
    const [patientNextVisitCount, setPatientNextVisitCount] = useState(0);
    const [totalBirthdayWishCount, setTotalBirthdayWishCount] = useState(0);
    const [totalclinicWisePatientInsulinCount, setTotalClinicWisePatientInsulinCount] = useState(0);
    const [totalChatFaciliteyCount, setTotalChatFaciliteyCount] = useState(0);
    const [totalAppPatientBelow20Year, setTotalAppPatientBelow20Year] = useState(0);
    const [totalAppPatientBetween20And30, setTotalAppPatientBetween20And30] = useState(0);
    const [totalAppPatientBetween30And40, setTotalAppPatientBetween30And40] = useState(0);
    const [totalAppPatientBetween40And50, setTotalAppPatientBetween40And50] = useState(0);
    const [totalAppPatientBetween50And60, setTotalAppPatientBetween50And60] = useState(0);
    const [totalClinicInformationInMyClinic, setTotalClinicInformationInMyClinic] = useState(0);
    const [totalClinicUploadedDocumentInMyknowledge, setTotalClinicUploadedDocumentInMyknowledge] = useState(0);
    const [totalClinicUploadedVideosInMyKnowledge, setTotalClinicUploadedVideosInMyKnowledge] = useState(0);
    const [totalPatientShareInformationInReport, setTotalPatientShareInformationInReport] = useState(0);
    const [lastNewsVisit, setlastNewsVisit] = useState(0);
    const [totalNoOfDocAndVideoShared, settotalNoOfDocAndVideoShared] = useState(0);
    const [totalNoOfDocAndVideoShownByEducator, settotalNoOfDocAndVideoShownByEducator] = useState(0);
    const [totalInsulinCount, setTotalInsulinCount] = useState(0);
    const [totalclinicWiseInsulinCount, setTotalclinicWiseInsulinCount] = useState(0);

    //const [pieReport1,setPieReport1]=useState(null);


    const [formData, setFormData] = useState({
        startDate: moment().format('YYYY-MM-DD'),
        start_date: moment().format('DD-MM-YYYY'),
        endDate: moment().format('YYYY-MM-DD'),
        end_date: moment().format('DD-MM-YYYY'),
    });

    const getReportList = async (from = {}) => {
        let sessionToken = localStorage.getItem('token');

        let resp = await axios({
            method: 'post',
            url: process.env.REACT_APP_PERFORMANCE_REPORT_URL + 'report/clinic',
            headers: {
                "Authorization": sessionToken,
                "Content-Type": "application/json"
            }
        })
        if (resp && resp.data) {
            if (resp.data.totalPatientCount > 0) {
                setShowProcessing(false);
                setTotalPatientCount(resp.data.totalPatientCount);
                setAppInstallCount(resp.data.appInstallCount);
            }

            if (resp.data.dietPrintCount > 0 || resp.data.dietSharedCount > 0) {
                setDietPrintCount(resp.data.dietPrintCount);
                setDietSharedCount(resp.data.dietSharedCount);
            }

            if (resp.data.videoCount > 0) {
                setVideoCount(resp.data.videoCount);
                setVideoShownByEducatorCount(resp.data.videoShownByEducatorCount);
                setVideoShownByPatientCount(resp.data.videoShownByPatientCount);
            }

            if (resp.data.documentCount > 0) {
                setDocumentCount(resp.data.documentCount);
                setDocumentsEducatorCount(resp.data.documentsEducatorCount);
                setDocumentsNotSeenEducatorCount(resp.data.documentsNotSeenEducatorCount);
                setDocumentsNotSeenPatientCount(resp.data.documentsNotSeenPatientCount);
                setDocumentsPatientCount(resp.data.documentsPatientCount);
            }
            if (resp.data.totalBirthdayWishCount > 0) {
                setTotalBirthdayWishCount(resp.data.totalBirthdayWishCount);
            }

            if (resp.data.totalclinicWisePatientInsulinCount > 0) {
                setTotalClinicWisePatientInsulinCount(resp.data.totalclinicWisePatientInsulinCount);
            }


            if (resp.data.totalChatFaciliteyCount > 0) {
                setTotalChatFaciliteyCount(resp.data.totalChatFaciliteyCount);
            }
            if (resp.data.totalAppPatientBelow20Year > 0) {
                setTotalAppPatientBelow20Year(resp.data.totalAppPatientBelow20Year);
            }
            if (resp.data.totalAppPatientBetween20And30 > 0) {
                setTotalAppPatientBetween20And30(resp.data.totalAppPatientBetween20And30);
            }
            if (resp.data.totalAppPatientBetween30And40 > 0) {
                setTotalAppPatientBetween30And40(resp.data.totalAppPatientBetween30And40);
            }
            if (resp.data.totalAppPatientBetween40And50 > 0) {
                setTotalAppPatientBetween40And50(resp.data.totalAppPatientBetween40And50);
            }
            if (resp.data.totalAppPatientBetween50And60 > 0) {
                setTotalAppPatientBetween50And60(resp.data.totalAppPatientBetween50And60);
            }
            if (resp.data.totalClinicUploadedVideosInMyKnowledge > 0) {
                setTotalClinicUploadedVideosInMyKnowledge(resp.data.totalClinicUploadedVideosInMyKnowledge);
            }
            if (resp.data.totalClinicUploadedDocumentInMyknowledge > 0) {
                setTotalClinicUploadedDocumentInMyknowledge(resp.data.totalClinicUploadedDocumentInMyknowledge);
            }

            if (resp.data.totalClinicInformationInMyClinic > 0) {
                setTotalClinicInformationInMyClinic(resp.data.totalClinicInformationInMyClinic);
            }
            if (resp.data.totalPatientShareInformationInReport > 0) {
                setTotalPatientShareInformationInReport(resp.data.totalPatientShareInformationInReport);
            }
            if (resp.data.lastNewsVisit > 0) {
                setlastNewsVisit(resp.data.lastNewsVisit);
            }
            if (resp.data.totalNoOfDocAndVideoShared > 0) {
                settotalNoOfDocAndVideoShared(resp.data.totalNoOfDocAndVideoShared);
            }
            if (resp.data.totalNoOfDocAndVideoShownByEducator > 0) {
                settotalNoOfDocAndVideoShownByEducator(resp.data.totalNoOfDocAndVideoShownByEducator);
            }
            if (resp.data.totalclinicWiseInsulinCount > 0) {
                setTotalclinicWiseInsulinCount(resp.data.totalclinicWiseInsulinCount);
            }
            if (resp.data.totalInsulinCount > 0) {
                setTotalInsulinCount(resp.data.totalInsulinCount);
            }

            setPatientNextVisitCount(resp.data.patientNextVisitCount ? resp.data.patientNextVisitCount : 0);

        }


    }

    useEffect(() => {
        getReportList();
    }, []);

    return (
        <>
            <div className="main-content report-topp">

                <div className="actionProcess" style={{ display: showProcessing ? 'block' : 'none', height: "200%" }}>
                    <img src={loadingImg} alt="Loading" width="40px" />
                    <center> Please Wait</center>
                </div>

                <Grid fluid>

                    <Row className="aling-Flexs">
                        {/* <Col sm={6} className="fxl">
                    <div className="bgwhite_graph">
                       <h4>Patients Visit Report</h4>

                       <Row className="report-filter">
                         <Col sm={6}>
                             Start Date<span className="star">*</span>
                             <Datetime
                                 timeFormat={false}
                                 inputProps={{ placeholder: 'DD-MM-YYYY' }}
                                 maxDate={new Date()}
                                 name="startDate"
                                 value={this.state.formData.start_date}
                                 max={new Date()}
                                onChange={e=>this.handleDate(e,'startDate')}
                                isValidDate={ this.validDate}
                             />
                           
                         </Col>
                         <Col sm={6}>
                             End Date<span className="star">*</span>                                  
                             <Datetime
                                 timeFormat={false}
                                 inputProps={{ placeholder: 'DD-MM-YYYY' }}
                                 maxDate={new Date()}
                                 name="endDate"
                                 value={this.state.formData.end_date}
                                 max={new Date()}
                                 onChange={e=>this.handleDate(e, 'endDate')}
                                 isValidDate={ this.validDate}
                             />
                         </Col>
                       </Row>


                         <Col md={7}>
                           <div className="titleBox">
                               <ul className="list-group">
                                   <li className="list-group-item">Total number of patients visited the clinic till date (<Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalPatientCount?totalPatientCount:0}</Link>)</li>
                                   <li className="list-group-item">Number of patients visited the clinic in the particular date range (<Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{this.state.dateRangeVisitCount}</Link>)</li>
                               </ul>
                           </div>
                         </Col>
                         <Col md={5}>
                           <div className="graph-textalign">                                           
                               
                               {pieReport5?
                                   <Pie data={this.state.pieReport5}
                                   options={{ legend: {
                                    display: false
                                  }}}
                                       //options={{  responsive: true }}                                            
                                       height={250} width={300}
                                   />
                               :<div className="No_data_section">No data to display</div>}
                            </div>
                         </Col>
                   </div>
                </Col> */}

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>App Installed Report</h4>
                                <ul className="report-list">
                                    <li>Total number of patient registered with the clinic <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalPatientCount ? totalPatientCount : 0}</Link></li>
                                    <li>Number of patients who have downloaded the app <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{appInstallCount ? appInstallCount : 0}</Link></li>
                                    <li>Number of patients who have not downloaded the app <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{totalPatientCount ? totalPatientCount - appInstallCount : 0}</Link></li>
                                </ul>
                                {/* <Col md={5}>
                                  <div className="graph-textalign">
                                    <div> <span class="y-box"></span>App Downloaded </div>  
                                    <div> <span class="b-box"></span>App Not Downloaded</div>  
                                      {pieReport1?
                                          <Pie data={pieReport1}
                                             options={{ legend: {
                                                display: false
                                              }}}
                                              height={250} width={300}
                                          />
                                      :<div className="No_data_section">No data to display</div>}
                                   </div>
                                </Col> */}

                            </div>
                        </Col>


                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Diet Chart Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of diet charts shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{dietSharedCount ? dietSharedCount : 0}</Link></li>
                                    <li className="">Number of diet charts printed <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{dietPrintCount ? dietPrintCount : 0}</Link></li>
                                </ul>

                            </div>
                        </Col>
                    </Row>

                    <Row className="aling-Flexs">

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Video Shared Report (Educator)</h4>
                                <ul className="report-list">
                                    <li className="">Total number of videos shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{videoCount ? videoCount : 0}</Link></li>
                                    <li className="">Number of videos seen by educator <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{videoShownByEducatorCount ? videoShownByEducatorCount : 0}</Link></li>
                                    <li className="">Number of videos not seen by educator <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{videoCount ? videoCount - videoShownByEducatorCount : 0}</Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Video Shared Report (Patient)</h4>
                                <ul className="report-list">
                                    <li className="">Total number of videos shared <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{videoCount ? videoCount : 0}</Link></li>
                                    <li className="">Number of videos seen by patient   <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{videoShownByPatientCount ? videoShownByPatientCount : 0}</Link></li>
                                    <li className="">Number of videos not seen by the patient <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{videoCount ? videoCount - videoShownByPatientCount : 0}</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="aling-Flexs">

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Document Shared Report (Educator)</h4>
                                <ul className="report-list">
                                    <li className="">Total number of documents shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{documentCount ? documentCount : 0}</Link></li>
                                    <li className="">Number of documents seen by educator <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{documentsEducatorCount ? documentsEducatorCount : 0}</Link></li>
                                    <li className="">Number of documents not seen by educator <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{documentsNotSeenEducatorCount ? documentsNotSeenEducatorCount : 0}</Link></li>
                                    <li className="">Total no of document and video shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalNoOfDocAndVideoShared ? totalNoOfDocAndVideoShared : 0}</Link></li>
                                    <li className="">Total no of document and video shown by educator <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalNoOfDocAndVideoShownByEducator ? totalNoOfDocAndVideoShownByEducator : 0}</Link></li>
                                </ul>

                            </div>
                        </Col>


                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Document Shared Report (Patient)</h4>
                                <ul className="report-list">
                                    <li className="">Total number of documents shared <Link to={{ pathname: 'patient-search', state: '' }} className="report_a" bsstyle="success" >{documentCount ? documentCount : 0}</Link></li>
                                    <li className="">Number of documents seen by patient <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{documentsPatientCount ? documentsPatientCount : 0}</Link></li>
                                    <li className="">Number of documents not seen by patient <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{documentsNotSeenPatientCount ? documentsNotSeenPatientCount : 0}</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>



                    <Row className="aling-Flexs">

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Clinic Wise Report</h4>
                                <ul className="report-list">
                                    <li className="">Total clinic wise patient insulin shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalclinicWisePatientInsulinCount ? totalclinicWisePatientInsulinCount : 0}</Link></li>
                                    <li className="">Total clinic wise insulin count <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalclinicWiseInsulinCount ? totalclinicWiseInsulinCount : 0}</Link></li>
                                    <li className="">Total insulin count<Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalInsulinCount ? totalInsulinCount : 0}</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Clinic Information Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of clinic information in my clinic  <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalClinicInformationInMyClinic ? totalClinicInformationInMyClinic : 0}</Link></li>
                                    <li className="">Total number of clinic uploaded video in my knowledge <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalClinicUploadedVideosInMyKnowledge ? totalClinicUploadedVideosInMyKnowledge : 0}</Link></li>
                                    <li className="">Total number of clinic uploaded documents in my knowledge <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalClinicUploadedDocumentInMyknowledge ? totalClinicUploadedDocumentInMyknowledge : 0}</Link></li>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="aling-Flexs">
                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>News Report</h4>
                                <ul className="report-list">
                                    <li className="">Number of patients who have seen news room <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{lastNewsVisit ? lastNewsVisit : 0}</Link></li>

                                </ul>
                            </div>
                        </Col>
                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Visit Report</h4>
                                <ul className="report-list">
                                    <li className="">Number of patients who have next visit date <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{patientNextVisitCount ? patientNextVisitCount : 0}</Link></li>

                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className="aling-Flexs">
                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Chat Facility Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of chat facilty shared <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalChatFaciliteyCount ? totalChatFaciliteyCount : 0}</Link></li>


                                </ul>

                            </div>
                        </Col>

                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Patient Share Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of patient shared information  <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalPatientShareInformationInReport ? totalPatientShareInformationInReport : 0}</Link></li>


                                </ul>

                            </div>
                        </Col>
                    </Row>
                    <Row className="aling-Flexs">


                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Total Patient Below Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of patient below 20 year <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalAppPatientBelow20Year ? totalAppPatientBelow20Year : 0}</Link></li>
                                    <li className="">Total number of patient between 20 and 30 year <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalAppPatientBetween20And30 ? totalAppPatientBetween20And30 : 0}</Link></li>
                                    <li className="">Total number of patient between 30 and 40 year <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalAppPatientBetween30And40 ? totalAppPatientBetween30And40 : 0}</Link></li>
                                    <li className="">Total number of patient between 40 and 50 year <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalAppPatientBetween40And50 ? totalAppPatientBetween40And50 : 0}</Link></li>
                                    <li className="">Total number of patient between 50 and 60 year <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalAppPatientBetween50And60 ? totalAppPatientBetween50And60 : 0}</Link></li>


                                </ul>
                            </div>
                        </Col>
                        <Col sm={6} className="fxl">
                            <div className="notation-card report-list-card">
                                <h4 className='notation-header notations-title'>Birthday Wish Report</h4>
                                <ul className="report-list">
                                    <li className="">Total number of birthday wish <Link to={{ pathname: 'patient-search', state: '' }} bsstyle="success" >{totalBirthdayWishCount ? totalBirthdayWishCount : 0}</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>



                </Grid>
            </div>
        </>
    );

}

export default withRouter(Report)
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/serving_size'
/* Add Serving  list and Add form */

export const addServingSizeRequest = () => ({
    type: actionTypes.GET_ADD_SERVING_SIZE_REQUEST
})
export const addServingSizeSuccess = (response) => ({
    type: actionTypes.GET_ADD_SERVING_SIZE_SUCCESS,
    payload: {
        response,
    }
})
export const addServingSizeError = (error) => ({
    type: actionTypes.GET_ADD_SERVING_SIZE_ERROR,
    payload: {
        error,
    }
})

export const addServingSizeAction = (data) => {
    return dispatch => {
        dispatch(addServingSizeRequest())
        return API.addServingSize(data)
            .then(response => {
                dispatch(addServingSizeSuccess(response.data))
            })
            .catch(error => {
                dispatch(addServingSizeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Link list and Add form */
export const updateServingSizeRequest = () => ({
    type: actionTypes.GET_UPDATE_SERVING_SIZE_REQUEST
})
export const updateServingSizeSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_SERVING_SIZE_SUCCESS,
    payload: {
        response,
    }
})
export const updateServingSizeError = (error) => ({
    type: actionTypes.GET_UPDATE_SERVING_SIZE_ERROR,
    payload: {
        error,
    }
})
export const updateServingSizeAction = (data) => {
    return dispatch => {
        dispatch(updateServingSizeRequest())

        return API.updateServingSize(data)
            .then(response => {
                dispatch(updateServingSizeSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateServingSizeError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link list  */
export const ServingSizeListRequest = () => ({
    type: actionTypes.GET_SERVING_SIZE_LIST_REQUEST
})
export const ServingSizeListSuccess = (response) => ({
    type: actionTypes.GET_SERVING_SIZE_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const ServingSizeListError = (error) => ({
    type: actionTypes.GET_SERVING_SIZE_LIST_ERROR,
    payload: {
        error
    }
})
export const servingSizeListAction = (data, limitStatus) => {
    return dispatch => {
        dispatch(ServingSizeListRequest());
        let FormData = '';
        if (data) {
            FormData = data;
        } else {
            FormData = {
                limit: 10,
                offset: 0,
                order: "createdAt",
                direction: "desc",
            }

        }
        if (limitStatus) {
            FormData.limitStatus = limitStatus;
        }

        return API.servingSizeList(FormData)
            .then(response => {
                dispatch(ServingSizeListSuccess(response.data))
            })
            .catch(error => {
                dispatch(ServingSizeListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link Status Change  */
export const ServingSizeStatusRequest = () => ({
    type: actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_REQUEST
})
export const ServingSizeChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const ServingSizeChangeStatusError = (error) => ({
    type: actionTypes.GET_SERVING_SIZE_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const servingSizeStatusAction = (data) => {
    return dispatch => {
        dispatch(ServingSizeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeServingSizeStatus(FormData)
            .then(response => {

                dispatch(ServingSizeChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(ServingSizeChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}


export const ServingSizeImageStatusRequest = () => ({
    type: actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_REQUEST
})
export const ServingSizeImageStatusSuccess = (response) => ({
    type: actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const ServingSizeImageStatusError = (error) => ({
    type: actionTypes.GET_SERVING_SIZE_IMAGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const servingSizeImageStatusAction = (data) => {

    return dispatch => {
        dispatch(ServingSizeImageStatusRequest())
        if (data.show_image) {
            data.show_image = false;
        } else {
            data.show_image = true;
        }
        const FormData = {
            id: data._id,
            show_image: data.show_image
        }
        return API.changeServingSizeImageStatus(FormData)
            .then(response => {

                dispatch(ServingSizeImageStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(ServingSizeImageStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}




//Upload File
// export const uploadFileRequest = () => ({
//     type: actionTypes.POST_UPLOAD_FILE_REQUEST
// })
// export const uploadFileSuccess = (response) => ({
//     type: actionTypes.GET_UPLOAD_FILE_SUCCESS,
//     payload: {
//         response,
//     }
// })
// export const uploadFileError = (error) => ({
//     type: actionTypes.GET_UPLOAD_FILE_ERROR,
//     payload: {
//         error
//     }
// })
// export const uploadFileAction = (data) => {
//     return dispatch => {
//         dispatch(uploadFileRequest())
//         // const FormData = {
//         //     file:data
//         // }

//         let formData = new FormData();
//         formData.append('file',data);
//         return API.uploadFile(formData)
//         .then(response => {
//             dispatch(uploadFileSuccess(response.data))
//         })
//         .catch(error => {
//             dispatch(uploadFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
//         })
//     }
// }

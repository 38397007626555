/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import {
    Row, Col, FormControl, Grid, Modal
} from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Admin/elements/CustomButton/CustomButton.jsx';
import * as API from '../../api/starStatus';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import moment from 'moment';
import ViewBox from '../../../Front/components/ViewBox';
import ImageComponent from '../../components/Common/ImageComponent.js';
import NotificationSystem from 'react-notification-system';
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';

const StarStatusList = (props) => {
    let history = useHistory();
    let { mode } = useParams()
    const [formData, setFormData] = useState({ limit: 10, page: 0, direction: '' })
    const [isLoading, setIsLoading] = useState(false)
    const [sortData, setSortData] = useState(false);
    const [totalCount, setTotalCount] = useState(0)
    const [scroll, setScroll] = useState(0);
    const [list, setList] = useState([]);
    const notificationSystem = useRef(null)

    const getListAction = async (from = {}) => {
        setIsLoading(true);

        let resp = await API.list({ "limit": formData.limit, "offset": formData.page, "order": "createdAt", direction: formData.direction, "search": from.search });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            setIsLoading(false);
            setList(resp.data.data);
            setTotalCount(resp.data.count);
        }

    }

    const handleToastifyMessage = (data) => {
        let msg = ""
        if (data === "add")
            msg = "Star Status Added Successfully"
        if (data === "edit")
            msg = "Star Status Edited Successfully"
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }

    useEffect(() => {
        if (history?.location?.from === "add" || history?.location?.from === "edit")
            handleToastifyMessage(history?.location?.from)
        getListAction();
    }, []);

    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }





    const statusChangedHandler = async (event, index) => {
        let resp = await API.changeStatus({ id: event._id, status: event.status === "active" ? "inactive" : "active" })
        if (resp.data.status === "Success") {
            let msg = ""
            msg = "Status has been updated"
            const notification = notificationSystem.current;
            notification.addNotification({
                title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
                message: <div>
                    {msg}
                </div>,
                level: 'success',
                position: 'tr',
                autoDismiss: 2,
            });

            let listData = list
            if (listData[index].status == 'active') {
                listData[index].status = 'inactive';
            } else {
                listData[index].status = 'active';
            }
            setList([...listData]);
        }
    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
        return (<><Link to={{ pathname: `/admin/edit-star-status/` + row._id, state: row }}>  Edit </Link><a href="javascript:void(0)" onClick={(e) => statusChangedHandler(row, rowIndex)} ><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a><span className='cursor-pointer-link' variant="outline-light" onClick={e => getConfirmFun(row._id)}> delete</span></>)
    }

    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getListAction(data);
        let scrollBar = 50;  
        if(scroll == page &&  sizePerPage < scrollBar ) {
        eval('$(".ps").scrollTop(0)');
        } else if( totalCount < page * sizePerPage){
        eval('$(".ps").scrollTop(0)');
        }
        setScroll(page)
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }

    const formatDescription = (row) => {
        let str = row.substring(0, 100);
        return <span>{str}</span>
    }

    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption(),
    };

    const getConfirmFun = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleterRowAction(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const deleterRowAction = async (id) => {
        let resp = await API.deleteStarStatus({ id: id });
        if (resp) {
            let listData = list.filter(item => item._id !== id)
            props.handleClick('success', 'Deleted successfully.')
            setList([...listData]);
        }
    };

    const onSearchChange = (e, field = "") => {

        let saveFormData = formData;
        saveFormData[field] = e.trimStart();
        saveFormData["page"] = 0;
        setFormData({ ...formData, ...saveFormData });
        getListAction(saveFormData);
    }

    return (<div className="main-content" >
        <div>
            <NotificationSystem ref={notificationSystem} style={style} />
        </div>
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <Card
                        content={
                            <div className="fresh-datatables ">
                                <Row>
                                    <Col md={6}>
                                        <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-star-status")}>Add Star Status</Button>
                                    </Col>
                                    {/* <Col sm={6} className="form-group">
            <FormControl
              type="text"
              autocomplete="off"
              name="search"
              id="search"
              value={formData.search ? formData.search : ""}
              onChange={(e) => {
                onSearchChange(e.target.value, "search");
              }}
              placeholder="Search here ..."
            />
            </Col> */}
                                </Row>&nbsp;
                                <BootstrapTable
                                    deleteRow={false}
                                    key={list}
                                    data={list}
                                    search={false}
                                    multiColumnSearch={true}
                                    pagination={true}
                                    options={options}
                                    striped hover condensed scrollTop={'Bottom'}
                                    remote={true}
                                    fetchInfo={{ dataTotalSize: totalCount }}
                                    trStyle={rowStyleFormat}
                                >

                                    <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' isKey searchable={false}>Id</TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ 'data-attr': '#' }} thStyle={{ width: '5%', textAlign: 'center' }} tdStyle={{ width: '5%', textAlign: 'center' }} dataField='sn' dataFormat={srNo}>S.No.</TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ 'data-attr': 'title' }} thStyle={{ width: '20%', textAlign: 'center' }} tdStyle={{ width: '20%', textAlign: 'center' }} dataField='title' >Title </TableHeaderColumn>
                                    <TableHeaderColumn tdAttr={{ 'data-attr': 'advertisement' }} thStyle={{ width: '40%', textAlign: 'center' }} tdStyle={{ width: '40%', textAlign: 'center' }} dataFormat={formatDescription} dataField='description' >Description</TableHeaderColumn>
                                    <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': '_id' }} dataField='' dataFormat={actionButton}>Action</TableHeaderColumn>

                                </BootstrapTable>
                            </div>
                        }
                    />
                </Col>
            </Row>
        </Grid></div>)
}

export default StarStatusList
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { treatmentListAction } from 'Admin/actions/treatment';
import { dietTimeListAction } from 'Admin/actions/diet_time';
import { addReadyCreatedMealAction } from 'Admin/actions/ready_created_meal';
import { languageListAction } from 'Admin/actions/language';
import { regionListAction } from 'Admin/actions/region';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import Loading from 'react-loading';
// import { appConstants } from 'Admin/_constants/app.constants';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name_1: 'required',
    veg: 'required',
    carbohydrate: 'required',
    protein: 'required',
    fat: 'required',
    calorie: 'required',
    mealCategory: 'required',
    regionId: 'required',
    dietTimeId: 'required',
    treatmentPlanIds: 'required',
    priority: 'required',
};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();
class ReadyCreatedMeal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageList: [],
            treatmentList: [],
            dietTimeList: [],
            formArr: [],
            regionList: [],
            region: [],
            isLoading: true,
            selectall: false,
            name_englishError: null,
            vegError: null,
            carbohydrateError: null,
            proteinError: null,
            fatError: null,
            calorieError: null,
            mealCategoryError: null,
            regionError: null,
            dietTimeIdError: null,
            treatmentPlanIdsError: null,
            formData: {
                treatmentPlanIds: [],
                dietTimeId: [],
                name_1: "",
                name: {},
                veg: "veg",
                mealCategory: "s",
                foodType: "custom",
                priority: 1,

            },
            listParam: {
                foodCategoryId: "",
                direction: 'asc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: "1"
            },

            limitForm: {},
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        this.props.treatmentListAction(this.state.listParam);

        data['direction'] = "asc";
        data['order'] = "orderBy";
        data['offset'] = 0;
        data['limit'] = 10;
        this.props.dietTimeListAction(data);
        data['order'] = "createdAt";
        this.props.regionListAction(data);
        data['limitStatus'] = true;
        this.props.languageListAction(data);
        //this.props.handleClick('success', "Sucessfully saved");
    }

    componentWillReceiveProps(nextProps) {
        let listData = [];
        let i = 0;

        if (nextProps.isTreatmentList !== this.props.isTreatmentList) {
            // for (let i in nextProps.TreatmentList.data.data){
            //    for (let j in nextProps.TreatmentList.data.data[i].treatmentdata){
            //     nextProps.TreatmentList.data.data[i].treatmentdata[j].check_status = true;
            //    }
            // }
            this.setState({
                treatmentList: nextProps.TreatmentList.data.data,
                totalCount: nextProps.TreatmentList.data.count
            });
        }

        if (nextProps.isDietTimeList !== this.props.isDietTimeList) {
            this.setState({
                dietTimeList: nextProps.DietTimeList.data.data,
            });

        }


        if (nextProps.isAddReadyCreatedMeal !== this.props.isAddReadyCreatedMeal) {
            //this.props.handleClick('success', nextProps.msg);
            this.props.history.push('/admin/food_group_list')
        }


        let entry = [];
        if (nextProps.isRegionList !== this.props.isRegionList) {
            nextProps.RegionList.data.data.map((key, index) =>

                entry.push({
                    "value": key._id,
                    "label": key.name
                })
            );
            this.setState({
                regionList: entry,
            });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {
            this.setState({ languageList: nextProps.LanguageList.data });
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }



    handleChangeAddTreatment(row, category) {
        let treatmentPlanIds = this.state.formData.treatmentPlanIds;
        var localindex = treatmentPlanIds.map(function (el) {
            return el;
        }).indexOf(row._id);
        if (localindex != -1) {
            treatmentPlanIds.splice(localindex, 1);
        } else {
            treatmentPlanIds.push(row._id)
        }
        let field = this.state.formData;
        field['treatmentPlanIds'] = treatmentPlanIds;
        this.setState({ formData: field });
        //this.setState({ treatmentPlanIds: treatmentPlanIds });

        let checkStatus = row.check_status
        if (checkStatus) {
            checkStatus = false;
        } else {
            checkStatus = true;
        }
        let treatmentList = this.state.treatmentList;
        var category_index = treatmentList.map(function (el) {
            return el.name;
        }).indexOf(category);
        if (category_index != -1) {
            var treatment_index = treatmentList[category_index].treatmentdata.map(function (el) {
                return el._id;
            }).indexOf(row._id);
            if (treatment_index != -1) {
                treatmentList[category_index].treatmentdata[treatment_index].check_status = checkStatus;
                this.setState({ treatmentList: treatmentList })
            }
        }
    }

    handleChangeSelectAll(value) {
        let selectall = this.state.selectall;
        if (value) {
            selectall = false;
            let field = this.state.formData;
            field['treatmentPlanIds'] = [];
            this.setState({ formData: field });
        } else {
            selectall = true;
        }

        let treatmentList = this.state.treatmentList;
        for (let i in treatmentList) {
            for (let j in treatmentList[i].treatmentdata) {
                if (selectall) {
                    treatmentList[i].treatmentdata[j].check_status = true;
                    let treatmentPlanIds = this.state.formData.treatmentPlanIds;
                    var localindex = treatmentPlanIds.map(function (el) {
                        return el;
                    }).indexOf(treatmentList[i].treatmentdata[j]._id);
                    if (localindex == -1) {
                        treatmentPlanIds.push(treatmentList[i].treatmentdata[j]._id)
                    }
                    let field = this.state.formData;
                    field['treatmentPlanIds'] = treatmentPlanIds;
                    this.setState({ formData: field });
                } else {
                    treatmentList[i].treatmentdata[j].check_status = false;
                }

            }
        }
        this.setState({ treatmentList: treatmentList });
        this.setState({ selectall: selectall })
    }

    handleChangeDietTime(row) {
        let dietTimeId = this.state.formData.dietTimeId;
        var localindex = dietTimeId.map(function (el) {
            return el;
        }).indexOf(row._id);
        if (localindex != -1) {
            dietTimeId.splice(localindex, 1);
        } else {
            dietTimeId.push(row._id)
        }
        this.setState({ dietTimeId: dietTimeId });
    }

    handleChangeName(e, language) {
        e.preventDefault();
        let field = this.state.formData;
        field.name[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleChangeMealCategory(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleRegion(value) {
        let multipleSelect = this.state.region;
        multipleSelect = value;
        this.setState({ region: multipleSelect });

        let formData = this.state.formData;
        let catArr = [];
        if (value && value.length) {
            value.map((key, i) => {
                catArr.push(key.value)
            });
        }
        formData.regionId = catArr;
        this.setState({ formData: formData });
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        if (e.target.name == 'carbohydrate') {
            field['carbohydrate_cal'] = (e.target.value * 4);
        }
        if (e.target.name == 'protein') {
            field['protein_cal'] = (e.target.value * 4);
        }
        if (e.target.name == 'fat') {
            field['fat_cal'] = (e.target.value * 9);
        }
        let total_cal = field.carbohydrate_cal + field.protein_cal + field.fat_cal;
        let calorie_diff = ((total_cal) * 15) / 100;
        let calorie = field.calorie;
        if (calorie > 100 && calorie <= 300) {
            calorie_diff = (total_cal * 10) / 100;
        }
        else if (calorie > 300) {
            calorie_diff = (total_cal * 5) / 100;
        }
        let min_cal = (total_cal) - calorie_diff;
        let max_cal = (total_cal) + calorie_diff;
        if (calorie >= min_cal && calorie <= max_cal) {
            field.calorieError = null;
            this.setState({ calorieError: null })
        } else {
            field.calorieError = "Put total of carbohydrate, protein and fats calories in Calories field."
            this.setState({ calorieError: "Put total of carbohydrate, protein and fats calories in Calories field." })
            field.calorie = "";
        }

        this.setState({ formData: field });
    };

    handleChangeFood(e) {
        e.preventDefault();
        let index = e.target.selectedIndex;
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addReadyCreatedMeal(evt) {
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let field = this.state.formData;
            _this.setState({ showProcessing: true });
            _this.props.addReadyCreatedMealAction(field);
        }
        //validation.errors()
    }


    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {
        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        let _this = this;
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={12} className="m-b-rw">
                                                <Col componentClass={ControlLabel} className="line-base">
                                                    Eating timeslot <span className="star">*</span>
                                                </Col>

                                                <Col md={12}>
                                                    {this.state.dietTimeList.map(function (timeData) {
                                                        return (<Col md={4}>
                                                            <div><input type="checkbox" name={"time_" + timeData._id} id={"time_" + timeData._id} onChange={(event) => { _this.handleChangeDietTime(timeData) }} />&nbsp; {timeData.name['1']}<br></br></div>
                                                        </Col>)

                                                    })
                                                    }
                                                </Col>

                                                <span className="errorMsg">
                                                    {this.state.dietTimeIdError}
                                                    {this.state.formArr.dietTimeId && validation.errors.first('dietTimeId')}
                                                </span>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Col componentClass={ControlLabel} className="line-base">
                                                    Meal type <span className="star">*</span>
                                                </Col>

                                                <div className="selt-c">
                                                    <input type="checkbox" name="selectall" value={this.state.selectall} id="selectall" onChange={(event) => { _this.handleChangeSelectAll(this.state.selectall) }} /> Select all
                                                </div>

                                                <div className="row type-nn">
                                                    {this.state.treatmentList.map(function (type) {
                                                        return (

                                                            <Col md={4}>
                                                                <div className="lis-typi">
                                                                    <h5>{type.name}</h5>
                                                                    {
                                                                        type.treatmentdata.map(function (data) {
                                                                            return (
                                                                                <div className="v-ful"><input type="checkbox" name={"treatment_" + data._id} id={"treatment_" + data._id} onChange={(event) => { _this.handleChangeAddTreatment(data, type.name) }} checked={data.check_status} />&nbsp; {data.name}<br></br></div>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </Col>)
                                                    })
                                                    }
                                                </div>
                                                <span className="errorMsg">
                                                    {this.state.treatmentPlanIdsError}
                                                    {this.state.formArr.treatmentPlanIds && validation.errors.first('treatmentPlanIds')}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Form horizontal>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} >
                                                    Name  <span className="star">*</span>
                                                </Col>
                                                <Col sm={6} className="spcc">
                                                    {this.state.languageList.map(function (lang) {
                                                        return <><label>{lang.name}</label><FormControl className="spc-in" type="text" name={'name_' + lang.lanId} id={'name_' + lang.lanId} placeholder={lang.name} onChange={(event) => { _this.handleChangeName(event, lang.lanId) }} /></>
                                                    })}
                                                    <span className="errorMsg">
                                                        {this.state.name_englishError}
                                                        {this.state.formArr.name_1 && validation.errors.first('name_1')}</span>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} >
                                                    Region  <span className="star">*</span>
                                                </Col>
                                                <Col sm={6} className="spcc">
                                                    <Select
                                                        placeholder="Region"
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        name="regionId"
                                                        value={this.state.region}
                                                        key={this.state.region}
                                                        options={this.state.regionList}
                                                        onChange={(value) => { this.setState({ region: value }); this.handleRegion(value) }}
                                                    >
                                                    </Select>
                                                    <span className="errorMsg">
                                                        {this.state.regionError}
                                                        {this.state.formArr.regionId && validation.errors.first('regionId')}</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>
                                                    Carbohydrate (g) <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl type="text" name="carbohydrate" placeholder="carbohydrate" id="carbohydrate" onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">
                                                        {this.state.carbohydrateError}
                                                        {this.state.formArr.carbohydrate && validation.errors.first('carbohydrate')}</span>
                                                </Col>
                                                ( {this.state.formData.carbohydrate_cal} kcal)
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>
                                                    Protein (g) <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl type="text" name="protein" id="protein" placeholder="protein" onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">
                                                        {this.state.proteinError}
                                                        {this.state.formArr.protein && validation.errors.first('protein')}</span>
                                                </Col>
                                                ( {this.state.formData.protein_cal} kcal)
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>
                                                    Fat (g) <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl type="text" name="fat" id="fat" placeholder="fat" onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">
                                                        {this.state.fatError}
                                                        {this.state.formArr.fat && validation.errors.first('fat')}</span>
                                                </Col>
                                                ( {this.state.formData.fat_cal} kcal)
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2}>
                                                    Calorie (kcals)  <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <FormControl type="text" name="calorie" id="calorie" placeholder="calorie" onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">
                                                        {this.state.calorieError}
                                                        {this.state.formArr.calorie && validation.errors.first('calorie')}</span>
                                                    {/* <span className="errorMsg">
                                                    {this.state.formData.calorie_error}</span> */}
                                                </Col>
                                            </FormGroup>



                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} >
                                                    Food type <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <select className="form-control" name="veg" placeholder="Vegetarian" onChange={(event) => this.handleChangeFood(event)}>

                                                        <option value="veg">Vegetarian</option>
                                                        <option value="non-veg">Non Vegetarian</option>

                                                    </select>

                                                    <span className="errorMsg">
                                                        {this.state.vegError}
                                                        {this.state.formArr.veg && validation.errors.first('veg')}</span>

                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} >
                                                    Meal Cateogry <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <select className="form-control" name="mealCategory" placeholder="Meal Category" onChange={(event) => this.handleChangeMealCategory(event)}>
                                                        <option value="s">Urban</option>
                                                        <option value="l">Rural</option>
                                                        <option value="h">Ready formula</option>

                                                    </select>
                                                    <span className="errorMsg">
                                                        {this.state.mealCategoryError}
                                                        {this.state.formArr.mealCategory && validation.errors.first('mealCategory')}</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} >
                                                    Priority <span className="star">*</span>
                                                </Col>
                                                <Col sm={6}>
                                                    <select className="form-control" name="priority" placeholder="priority" onChange={(event) => this.handleChangeMealCategory(event)}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>
                                                    <span className="errorMsg">
                                                        {this.state.priorityError}
                                                        {this.state.formArr.priority && validation.errors.first('priority')}</span>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup>
                                                <Col componentClass={ControlLabel} sm={2} ></Col>
                                                <Col sm={6}>
                                                    <button type="button" onClick={this.addReadyCreatedMeal.bind(this)} className="btn-fill btn-wd btn btn-primary">Save</button>&nbsp;
                                                </Col>
                                            </FormGroup>


                                        </Form>

                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        msg: state.readyCreatedMeal.message,

        TreatmentList: state.treatment.TreatmentList,
        isTreatmentList: state.treatment.isTreatmentList,
        isTreatmentListError: state.treatment.isTreatmentListError,

        DietTimeList: state.dietTime.DietTimeList,
        isDietTimeList: state.dietTime.isDietTimeList,
        isDietTimeListError: state.dietTime.isDietTimeListError,

        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

        RegionList: state.region.RegionList,
        isRegionList: state.region.isRegionList,
        isRegionListError: state.region.isRegionListError,

        AddReadyCreatedMeal: state.readyCreatedMeal.AddReadyCreatedMeal,
        isAddReadyCreatedMeal: state.readyCreatedMeal.isAddReadyCreatedMeal,
        isAddReadyCreatedMealError: state.readyCreatedMeal.isAddReadyCreatedMealError,

    }
}

export default withRouter(connect(mapStateToProps, { addReadyCreatedMealAction, treatmentListAction, dietTimeListAction, languageListAction, regionListAction })(ReadyCreatedMeal));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import {
  Row,
  Col,
  FormControl,
  Grid,
  Form,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import * as API from "Admin/api/supportsystem.js";
import moment from "moment";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { confirmAlert } from "react-confirm-alert";
import { appConstants } from "Front/_constants/app.constants.js";
import ViewBox from "../../../Front/components/ViewBox";
import ImageComponent from "../../components/Common/ImageComponent.js";
const SupportSystemView = () => {
  let { id } = useParams();
  const ref = useRef();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [formDataFile, setFormDataFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(null);
  const [ViewBoxData, setViewBoxData] = useState({
    show: false,
    type: "image",
    path: "",
  });
  const getListAction = async (from = {}) => {
    setIsLoading(true);
    let resp = await API.viewTicketDetails({ ticketId: id });
    setIsLoading(false);
    setTicketDetails(resp.data.data);
  };

  useEffect(() => {
    getListAction();
  }, []);

  const handleChange = (e, field = "") => {
    let saveFormData = formData;
    saveFormData[field] = e;
    setFormData({ ...formData, ...saveFormData });
    getListAction(saveFormData);
  };
  const inputHandler = (e) => {
    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    setFormData({ ...formData, ...saveFormData });
    validate();
  };
  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.description && post.description != "") {
      errArr.description = "";
    } else {
      errArr.description = "error";
      errflag = false;
    }
    setErrors({ ...errArr });
    return errflag;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(false);
      let sentData = new FormData();
      sentData.append("description", formData.description);
      sentData.append("ticketId", id);
      sentData.append("file", formDataFile);
      let resp = await API.commentReply(sentData);
      successAlert("You have successfully added Reply");
      setTicketDetails(resp.data.data);
      setFormData({ file: "" });
      setFormDataFile("");
      ref.current.value = "";
      setIsLoading(false);
      document.getElementById("fileControl").value = "";
    }
  };

  const changeHandlerFile = (event) => {
    let formDataTemp = event.target.files[0];
    setFormDataFile(formDataTemp);
    setFormData({ file: event.target.files, ...formData });
  };

  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="info"
      >
        {msg}
      </SweetAlert>
    );
    setTimeout(function () {
      setAlert(null);
    }, 3000);
  };

  const closedTicket = async (id) => {
    const sendData = {
      status: "close",
      ticketId: id,
      authorId: ticketDetails.authorId,
      ClinicName: ticketDetails.clinicName,
      clinicNumber: ticketDetails.clinicNumber,
      city: ticketDetails.city,
    };
    let resp = await API.changeStatusTicket(sendData);
    if (resp.status === 200) {
      successAlert("Successfully Closed.");
      getListAction();
    }
  };

  const updateStatus = (id) => {
    confirmAlert({
      title: "Confirm to Close",
      message: "Are you sure you want to Closed?",
      buttons: [
        {
          label: "Yes",
          onClick: () => closedTicket(id),
        },
        {
          label: "No",
          //onClick: () => this.resetFormData()
        },
      ],
    });
  };

  const hideViewBox = () => {
    setViewBoxData({ show: false, type: "image", path: "" });
  };

  return (
    <div className="main-content color-notations">
      {ViewBoxData && ViewBoxData.show && (
        <ViewBox data={ViewBoxData} hideViewBox={(e) => hideViewBox()} />
      )}
      <Grid fluid>
        <Row>
          <Col md={12}>
            <h4 className="notation-title">{`Ticket Details`}</h4>
            <Card
              content={
                <div className="flex-box">
                  <Row>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          Ticket no:
                          <b> #{ticketDetails.ticketId} </b>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          Created By :<b> {ticketDetails.authorName} </b>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          Created Date :
                          <b>
                            {" "}
                            {moment(ticketDetails.createdAt).format(
                              "DD-MMM-YYYY"
                            )}{" "}
                          </b>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          Current Status:
                          {ticketDetails.status === "open" && (
                            <b
                              className="ticket-status-color-red"
                              onClick={(e) => updateStatus(id)}
                            >
                              {" "}
                              {ticketDetails.status} <i class="fa fa-edit"></i>
                            </b>
                          )}
                          {ticketDetails.status !== "open" && (
                            <b className="ticket-status-color-green">
                              {" "}
                              {ticketDetails.status}
                            </b>
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          clinic Id:
                          <b> {ticketDetails.clinicNumber} </b>
                        </p>
                      </div>
                    </Col>

                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          Clinic Name:
                          <b> {ticketDetails.clinicName} </b>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">
                          city:
                          <b> {ticketDetails.city} </b>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12} sm={12}>
                      <div className="card">
                        <p className="notations-title">
                          <b>{ticketDetails.title}</b>
                        </p>
                        <div className="content notation-Col">
                          <p> {ticketDetails.description} </p>
                        </div>
                        {ticketDetails.filePath && (
                          <div className="">
                            <b>Attachment File:</b>
                            <p>
                              <ImageComponent
                                style={{ width: "100px", height: "100px" }}
                                data={ticketDetails.filePath}
                                setViewBoxData={setViewBoxData}
                              //onClick={e => setViewBoxData({ show: true, type: "image", title: "Report", path: appConstants.s3UploadUrl + item.filePath })}
                              />
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={12}>
                      <div className="card">
                        <p className="notations-title">Commnets</p>
                      </div>
                    </Col>
                  </Row>
                  {ticketDetails?.comment &&
                    ticketDetails.comment.map((item) => {
                      return (
                        <>
                          <Row className="commentDeatils">
                            <Col lg={6} md={6} sm={6} xs={12}>
                              <div className="">
                                Comment By :<b> {item.authorName} </b>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                              className="float-right"
                            >
                              <div className="">
                                Date :
                                <b>
                                  {" "}
                                  {moment(item.createdAt).format(
                                    "DD-MMM-YYYY h:s a"
                                  )}{" "}
                                </b>
                              </div>
                            </Col>

                            <Col md={12} xs={12} sm={12}>
                              <div className="">
                                <b>Message :</b>
                                <p> {item.description} </p>
                              </div>
                              {item.filePath && (
                                <div className="">
                                  <b>Attachment File:</b>
                                  <p>
                                    <ImageComponent
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      data={item.filePath}
                                      setViewBoxData={setViewBoxData}
                                    //onClick={e => setViewBoxData({ show: true, type: "image", title: "Report", path: appConstants.s3UploadUrl + item.filePath })}
                                    />{" "}
                                  </p>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                </div>
              }
            />
            {ticketDetails.status === "open" && (
              <Card
                class="comment-form"
                content={
                  <Row>
                    <Col md={12}>
                      <Form
                        horizontal
                        noValidate
                        validated={validated}
                        onSubmit={(e) => submitHandler(e)}
                      >
                        <Col>
                          <div className="card">
                            <div className="notations-title">
                              Reply Your Comments
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={12}>
                          <FormGroup>
                            <Col componentClass={ControlLabel} sm={12} md={4}>
                              Description <span className="star">*</span>
                            </Col>
                            <Col sm={12} md={8}>
                              <FormControl
                                componentClass="textarea"
                                name="description"
                                style={{ resize: "none" }}
                                value={
                                  formData.description
                                    ? formData.description
                                    : ""
                                }
                                onChange={(e) => {
                                  inputHandler(e);
                                }}
                                placeholder="Description"
                                className={
                                  "-input " +
                                  (errors.description ? errors.description : "")
                                }
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={12}>
                          <FormGroup>
                            <Col componentClass={ControlLabel} sm={12} md={4}>
                              Attachment
                            </Col>
                            <Col sm={12} md={6}>
                              <FormControl
                                placeholder="Upload File"
                                aria-label="File"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  changeHandlerFile(e);
                                }}
                                name="file"
                                type="file"
                                accept=".png"
                                allowedFileTypes={["image/png"]}
                                ref={ref}
                                id="fileControl"
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={12}>
                          <Col
                            componentClass={ControlLabel}
                            sm={12}
                            md={4}
                          ></Col>
                          <Col sm={12} md={6}>
                            <Button
                              type="submit"
                              className="btn-fill btn btn-primary"
                              disabled={isLoading}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Col>
                      </Form>
                    </Col>
                  </Row>
                }
              />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
export default withRouter(SupportSystemView);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Front/actions/actionTypes'
const initState = {
    response: [],

    message: '',
    isCountryList: false,
    isCountryListError: false,
    countryList: false,

    isStateList: false,
    isStateListError: false,
    stateList: false,

    isPlanList: false,
    isPlanListError: false,
    planList: false,

    isKnowledgeList: false,
    isKnowledgeListError: false,
    KnowledgeList: false,

    isUploadFile: false,
    isUploadFileError: false,
    uploadFile: false,

    isCategoryList: false,
    isCategoryListError: false,
    categoryList: false,

    isAddTask: false,
    isAddTaskError: false,
    AddTask: false,

    isMultiUploadFile: false,
    isMultiUploadFileError: false,
    MultiUploadFile: false,

    isUserList: false,
    isUserListError: false,
    UserList: false,

    isChatUserList: false,
    isChatUserListError: false,
    ChatUserList: false,

    isGetPatientChat: false,
    isGetPatientChatError: false,
    GetPatientChat: false,

    isSpecialization: false,
    isSpecializationError: false,
    SpecializationList: false,

    isChangeStatus: false,
    isChangeStatusError: false,
    ChangeStatus: false,

    isSendMail: false,
    isSendMailError: false,
    SendMail: false,

    isGetChatCount: false,
    isGetChatCountError: false,
    GetChatCount: false,
    isReduceChatCount:false,

    isReadChat: false,
    isReadChatError: false,
    ReadChat: false,

    isFileList: false,
    isFileListError: false,
    fileList: false,

    isAdditionalComment: false,
    isAdditionalCommentError: false,
    AdditionalComment: false,

    isNotification: false,
    isNotificationError: false,
    notification: false,

    isAddNotificationMsg: false,
    isAddNotificationMsgError: false,
    addNotificationMsg: false,

    isEditNotificationMsg: false,
    isEditNotificationMsgError: false,
    editNotificationMsg: false,

    isNotificationMsgStatus: false,
    isNotificationMsgStatusError: false,
    notificationMsgStatus: false,

    isChatTemplateList: false,
    isChatTemplateListError: false,
    ChatTemplateList: false,

    isEditChatTemplate: false,
    isEditChatTemplateError: false,
    editChatTemplate: false,

    isDeleteChatTemplate: false,
    isDeleteChatTemplateError: false,
    deleteChatTemplate: false,
    
    PatientChatList: [],
    allChatCount:localStorage.getItem("AllCount")


}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRY_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isCountryList: false,
                isCountryListError: false,
            }
        case actionTypes.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                message: 'Country List successfully',
                countryList: action.payload.response,
                isCountryList: true,
                isCountryListError: false,
            }
        case actionTypes.GET_COUNTRY_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isCountryList: false,
                isCountryListError: true
            }

        case actionTypes.GET_STATE_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isStateList: false,
                isStateListError: false,
            }
        case actionTypes.GET_STATE_LIST_SUCCESS:
            return {
                ...state,
                message: 'Country List successfully',
                stateList: action.payload.response,
                isStateList: true,
                isStateListError: false,
            }
        case actionTypes.GET_STATE_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isStateList: false,
                isStateListError: true
            }
        //File
        case actionTypes.GET_FILE_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isFileList: false,
                isFileListError: false,
            }
        case actionTypes.GET_FILE_LIST_SUCCESS:
            return {
                ...state,
                message: 'File List successfully',
                fileList: action.payload.response,
                isFileList: true,
                isFileListError: false,
            }
        case actionTypes.GET_FILE_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isFileList: false,
                isFileListError: true
            }
        //Knowledge List
        case actionTypes.GET_KNOWLEDGE_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isKnowledgeList: false,
                isKnowledgeListError: false,
            }
        case actionTypes.GET_KNOWLEDGE_LIST_SUCCESS:
            return {
                ...state,
                message: 'Knowledge Listed successfully',
                KnowledgeList: action.payload.response,
                isKnowledgeList: true,
                isKnowledgeListError: false,
            }
        case actionTypes.GET_KNOWLEDGE_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isKnowledgeList: false,
                isKnowledgeListError: true
            }
        //Plan
        case actionTypes.GET_PLAN_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isPlanList: false,
                isPlanListError: false,
            }
        case actionTypes.GET_PLAN_LIST_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                planList: action.payload.response,
                isPlanList: true,
                isPlanListError: false,
            }
        case actionTypes.GET_PLAN_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isPlanList: false,
                isPlanListError: true
            }
        //Upload file
        case actionTypes.UPLOAD_FILE_REQUEST:
            return {
                ...state,
                message: '',
                isUploadFile: false,
                isUploadFileError: false,
            }
        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                message: 'File Uploaded',
                uploadFile: action.payload.response,
                isUploadFile: true,
                isUploadFileError: false,
            }
        case actionTypes.UPLOAD_FILE_ERROR:
            return {
                ...state,
                uploadFile: action.payload.error,
                message: action.payload.error,
                isUploadFile: false,
                isUploadFileError: true
            }
        // category list
        case actionTypes.GET_CATEGORY_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isCategoryList: false,
                isCategoryListError: false,
            }
        case actionTypes.GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                categoryList: action.payload.response,
                isCategoryList: true,
                isCategoryListError: false,
            }
        case actionTypes.GET_CATEGORY_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isCategoryList: false,
                isCategoryListError: true
            }
        // Treatment with Types
        case actionTypes.GET_TREATMENT_WITH_TYPES_REQUEST:
            return {
                ...state,
                message: '',
                isTreatementList: false,
                isTreatementListError: false,
            }
        case actionTypes.GET_TREATMENT_WITH_TYPES_SUCCESS:
            return {
                ...state,
                message: 'Treatment List successfully',
                treatmentWithTypeList: action.payload.response,
                isTreatementList: true,
                isTreatementListError: false,
            }
        case actionTypes.GET_TREATMENT_WITH_TYPES_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTreatementList: false,
                isTreatementListError: true
            }
        // Unit list
        case actionTypes.GET_UNIT_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isUnitList: false,
                isUnitListError: false,
            }
        case actionTypes.GET_UNIT_LIST_SUCCESS:
            return {
                ...state,
                message: 'Unit List successfully',
                unitList: action.payload.response,
                isUnitList: true,
                isUnitListError: false,
            }
        case actionTypes.GET_UNIT_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUnitList: false,
                isUnitListError: true
            }
        // Time list
        case actionTypes.GET_TIME_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isTimeList: false,
                isTimeListError: false,
            }
        case actionTypes.GET_TIME_LIST_SUCCESS:
            return {
                ...state,
                message: 'Time List successfully',
                timeList: action.payload.response,
                isTimeList: true,
                isTimeListError: false,
            }
        case actionTypes.GET_TIME_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isTimeList: false,
                isTimeListError: true
            }
        // ADD TASK ERROR
        case actionTypes.ADD_TASK_REQUEST:
            return {
                ...state,
                message: '',
                isAddTask: false,
                isAddTaskError: false,
            }
        case actionTypes.ADD_TASK_SUCCESS:
            return {
                ...state,
                message: 'Task added successfully',
                AddTask: action.payload.response,
                isAddTask: true,
                isAddTaskError: false,
            }
        case actionTypes.ADD_TASK_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isAddTask: false,
                isAddTaskError: true
            }

        //Multi Upload file
        case actionTypes.UPLOAD_MULTIFILE_REQUEST:
            return {
                ...state,
                message: '',
                isMultiUploadFile: false,
                isMultiUploadFileError: false,
            }
        case actionTypes.UPLOAD_MULTIFILE_SUCCESS:
            return {
                ...state,
                message: 'Files Uploaded',
                MultiUploadFile: action.payload.response,
                isMultiUploadFile: true,
                isMultiUploadFileError: false,
            }
        case actionTypes.UPLOAD_MULTIFILE_ERROR:
            return {
                ...state,
                uploadFile: action.payload.error,
                message: action.payload.error,
                isMultiUploadFile: false,
                isMultiUploadFileError: true
            }

        case actionTypes.GET_USER_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isUserList: false,
                isUserListError: false,
            }
        case actionTypes.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                message: 'User List successfully',
                UserList: action.payload.response,
                isUserList: true,
                isUserListError: false,
            }
        case actionTypes.GET_USER_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUserList: false,
                isUserListError: true
            }

        case actionTypes.CHAT_USER_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isChatUserList: false,
                isChatUserListError: false,
            }
        case actionTypes.CHAT_USER_LIST_SUCCESS:
            return {
                ...state,
                message: 'Chat User List successfully',
                ChatUserList: action.payload.response,
                isChatUserList: true,
                isChatUserListError: false,
            }
        case actionTypes.CHAT_USER_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isChatUserList: false,
                isChatUserListError: true
            }

            case actionTypes.PATIENT_CHAT_LIST_SUCCESS:
            return {
                ...state,
                message: 'PAtient Chat List successfully',
                PatientChatList: action.payload.response,
            }

            

        case actionTypes.GET_PATIENT_CHAT_REQUEST:
            return {
                ...state,
                message: '',
                isGetPatientChat: false,
                isGetPatientChatError: false,
            }
        case actionTypes.GET_PATIENT_CHAT_SUCCESS:
            return {
                ...state,
                message: 'Get Patient Chat successfully',
                GetPatientChat: action.payload.response,
                isGetPatientChat: true,
                isGetPatientChatError: false,
            }
        case actionTypes.GET_PATIENT_CHAT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isGetPatientChat: false,
                isGetPatientChatError: true
            }

        case actionTypes.GET_SPECIALIZATIONS_REQUEST:
            return {
                ...state,
                message: '',
                isSpecialization: false,
                isSpecializationError: false,
            }
        case actionTypes.GET_SPECIALIZATIONS_SUCCESS:
            return {
                ...state,
                SpecializationList: action.payload.response,
                isSpecialization: true,
                isSpecializationError: false,
            }
        case actionTypes.GET_SPECIALIZATIONS_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isSpecialization: false,
                isSpecializationError: true
            }

        case actionTypes.UPDATE_USER_REQUEST:
            return {
                ...state,
                message: '',
                isUpdateUser: false,
                isUpdateUserError: false,
            }
        case actionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                UpdateUser: action.payload.response,
                isUpdateUser: true,
                isUpdateUserError: false,
            }
        case actionTypes.UPDATE_USER_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUpdateUser: false,
                isUpdateUserError: true
            }

        case actionTypes.SEND_MAIL_REQUEST:
            return {
                ...state,
                message: '',
                isSendMail: false,
                isSendMailError: false,
            }
        case actionTypes.SEND_MAIL_SUCCESS:
            return {
                ...state,
                SendMail: action.payload.response,
                isSendMail: true,
                isSendMailError: false,
            }
        case actionTypes.SEND_MAIL_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isSendMail: false,
                isSendMailError: true
            }

        case actionTypes.CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                message: '',
                isChangeStatus: false,
                isChangeStatusError: false,
            }
        case actionTypes.CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                ChangeStatus: action.payload.response,
                isChangeStatus: true,
                isChangeStatusError: false,
            }
        case actionTypes.CHANGE_USER_STATUS_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isChangeStatus: false,
                isChangeStatusError: true
            }

        case actionTypes.FORWARD_CHAT_REQUEST:
            return {
                ...state,
                message: '',
                isChatForward: false,
                isChatForwardError: false,
            }
        case actionTypes.FORWARD_CHAT_SUCCESS:
            return {
                ...state,
                ChatForward: action.payload.response,
                isChatForward: true,
                isChatForwardError: false,
            }
        case actionTypes.FORWARD_CHAT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isChatForward: false,
                isChatForwardError: true
            }

        case actionTypes.GET_CHAT_COUNT_REQUEST:
            return {
                ...state,
                message: '',
                isGetChatCount: false,
                isGetChatCountError: false,
                //isReduceChatCount:false
            }
        case actionTypes.GET_CHAT_COUNT_SUCCESS:
            return {
                ...state,
                GetChatCount: action.payload.response,
                isGetChatCount: true,
                isGetChatCountError: false,
                //isReduceChatCount:action.payload.data?true:false,
            }
        case actionTypes.GET_CHAT_COUNT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isGetChatCount: false,
                isGetChatCountError: true
            }

            case actionTypes.RESET_IS_REDUCE_CHAT_COUNT:
                return {
                    ...state,
                    isReduceChatCount:!state.isReduceChatCount,
                    allChatCount:action.payload.payload
                }
            

        case actionTypes.READ_CHAT_REQUEST:
            return {
                ...state,
                message: '',
                isReadChat: false,
                isReadChatError: false,
            }
        case actionTypes.READ_CHAT_SUCCESS:
            return {
                ...state,
                ReadChat: action.payload.response,
                isReadChat: true,
                isReadChatError: false,
            }
        case actionTypes.READ_CHAT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isReadChat: false,
                isReadChatError: true
            }

        case actionTypes.UPDATE_ADDITIONAL_COMMENT_REQUEST:
            return {
                ...state,
                message: '',
                isAdditionalComment: false,
                isAdditionalCommentError: false,
                AdditionalComment: false,
            }
        case actionTypes.UPDATE_ADDITIONAL_COMMENT_SUCCESS:
            return {
                ...state,
                isAdditionalComment: true,
                isAdditionalCommentError: false,
                AdditionalComment: action.payload.response,
            }
        case actionTypes.UPDATE_ADDITIONAL_COMMENT_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isAdditionalComment: false,
                isAdditionalCommentError: true,
                AdditionalComment: false,
            }

        //Notification by Meghna
        case actionTypes.GET_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isNotification: false,
                isNotificationError: false,
                notificationList: false,
            }
        case actionTypes.GET_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                notificationList: action.payload.response,
                isNotification: true,
                isNotificationError: false,
            }
        case actionTypes.GET_NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                notificationList: false,
                isNotification: false,
                isNotificationError: true
            }

        // Add Notification by Meghna
        case actionTypes.ADD_NOTIFICATION_MSG_REQUEST:
            return {
                ...state,
                message: '',
                isAddNotificationMsg: false,
                isAddNotificationMsgError: false,
                addNotificationMsg: false,
            }
        case actionTypes.ADD_NOTIFICATION_MSG_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                addNotificationMsg: action.payload.response,
                isAddNotificationMsg: true,
                isAddNotificationMsgError: false,
            }
        case actionTypes.ADD_NOTIFICATION_MSG_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                addNotificationMsg: false,
                isAddNotificationMsg: false,
                isAddNotificationMsgError: true
            }

        // Edit Notification by Meghna
        case actionTypes.EDIT_NOTIFICATION_MSG_REQUEST:
            return {
                ...state,
                message: '',
                isEditNotificationMsg: false,
                isEditNotificationMsgError: false,
                editNotificationMsg: false,
            }
        case actionTypes.EDIT_NOTIFICATION_MSG_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                editNotificationMsg: action.payload.response,
                isEditNotificationMsg: true,
                isEditNotificationMsgError: false,
            }
        case actionTypes.EDIT_NOTIFICATION_MSG_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                editNotificationMsg: false,
                isEditNotificationMsg: false,
                isEditNotificationMsgError: true
            }

        // Change status of Notification by Meghna
        case actionTypes.NOTIFICATION_MSG_STATUS_REQUEST:
            return {
                ...state,
                message: '',
                isNotificationMsgStatus: false,
                isNotificationMsgStatusError: false,
                notificationMsgStatus: false,
            }
        case actionTypes.NOTIFICATION_MSG_STATUS_SUCCESS:
            return {
                ...state,
                message: 'Plan List successfully',
                notificationMsgStatus: action.payload.response,
                isNotificationMsgStatus: true,
                isNotificationMsgStatusError: false,
            }
        case actionTypes.NOTIFICATION_MSG_STATUS_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                notificationMsgStatus: false,
                isNotificationMsgStatus: false,
                isNotificationMsgStatusError: true
            }

        case actionTypes.CHAT_TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isChatTemplateList: false,
                isChatTemplateListError: false,
            }
        case actionTypes.CHAT_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                message: 'ChatTemplate List successfully',
                ChatTemplateList: action.payload.response,
                isDeleteChatTemplate: false,
                isChatTemplateList: true,
                isChatTemplateListError: false,
            }
        case actionTypes.CHAT_TEMPLATE_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isChatTemplateList: false,
                isChatTemplateListError: true
            }

        case actionTypes.EDIT_CHAT_TEMPLATE_REQUEST:
            return {
                ...state,
                message: '',
                isEditChatTemplate: false,
                isEditChatTemplateError: false,
            }
        case actionTypes.EDIT_CHAT_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: 'User List successfully',
                editChatTemplate: action.payload.response,
                isChatTemplateList: false,
                isEditChatTemplate: true,
                isEditChatTemplateError: false,
            }
        case actionTypes.EDIT_CHAT_TEMPLATE_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isEditChatTemplate: false,
                isEditChatTemplateError: true
            }

        case actionTypes.DELETE_CHAT_TEMPLATE_REQUEST:
            return {
                ...state,
                message: '',
                isDeleteChatTemplate: false,
                isDeleteChatTemplateError: false,
            }
        case actionTypes.DELETE_CHAT_TEMPLATE_SUCCESS:
            //console.log("isDEleted")
            return {
                ...state,
                message: 'Chate Template Deleted Successfully',
                deleteChatTemplate: action.payload.response,
                isChatTemplateList: false,
                isDeleteChatTemplate: true,
                isDeleteChatTemplateError: false,
            }
        case actionTypes.DELETE_CHAT_TEMPLATE_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isDeleteChatTemplate: false,
                isDeleteChatTemplateError: true
            }



        default:
            return state
    }
}
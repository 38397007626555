/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { foodListAction, foodchangestatusAction } from 'Admin/actions/food';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { foodCategoryListAction } from 'Admin/actions/food_category';
import { languageListAction } from 'Admin/actions/language';
import { appConstants } from 'Admin/_constants/app.constants';

var count = 1;

class FoodList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foodCategoryList: [],
            languageList: [],
            foodList: [],
            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            veg_checked: true,
            non_veg_checked: true,
            formData: {
                foodCategoryId: "",
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                lang: 1,
                status: "active",
                veg: ""
            },
            limitForm: {},
        }

        this.onSerailNum = this.onSerailNum.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
    }

    componentDidMount() {
        this.props.foodListAction(this.state.formData);
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.foodCategoryListAction(data);
        this.props.languageListAction(data);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isFoodList !== this.props.isFoodList) {

            this.setState({
                foodList: nextProps.FoodList.data.data,
                totalCount: nextProps.FoodList.data.count
            });
        }

        if (nextProps.isFoodCategoryList !== this.props.isFoodCategoryList) {
            this.setState({ foodCategoryList: nextProps.FoodCategoryList.data.data });
        }

        if (nextProps.isLanguageList !== this.props.isLanguageList) {

            this.setState({ languageList: nextProps.LanguageList.data });
        }


        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }
    }

    onSerailNum(cell, row, enumObject, rowIndex) {
        return count++;
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    editButton(cell, row, enumObject, rowIndex) {

        return (<p><Link to={{ pathname: `update-food/` + row._id, state: { row } }} onClick={this.toggleEdit.bind(this, row)} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <a href="javascript:void(0)" onClick={this.statusChangedHandler.bind(this, row, rowIndex)}><i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} aria-hidden="true"></i></a>
        </p>)
    }

    toggleEdit(event) {
        this.setState({
            userRow: event
        });
    }

    statusChangedHandler(event, index) {

        let newstatus = {};
        if (event.status == 'active') {
            newstatus = 'inactive';
        } else {
            newstatus = 'active';
        }
        event['id'] = event._id;
        event['status'] = newstatus;
        this.props.foodchangestatusAction(event);
        let food_list = this.state.foodList
        food_list.splice(index, 1);
        this.setState({ foodList: food_list })

    }


    _setTableOption() {
        if (this.state.isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    navigateto() {
        window.location.href = "Admin/add-food";
        this.clicked = "";
    }

    handleFoodCategory(event) {

        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['foodCategoryId'] = event.target.value
        this.setState({ formData: formData });
    }

    handleLanguage(event) {
        let formData = this.state.formData
        formData['lang'] = event.target.value
        this.setState({ formData: formData });
    }
    handleActiveList(event) {
        let formData = this.state.formData
        formData['status'] = 'active'
        this.setState({ formData: formData });
        this.props.foodListAction(this.state.formData);
    }

    handleInactiveList(event) {
        let formData = this.state.formData
        formData['status'] = 'inactive'
        this.setState({ formData: formData });
        this.props.foodListAction(this.state.formData);
    }

    handleName(cell, row, enumObject, rowIndex) {
        return row.name[this.state.formData.lang]
    }

    handleDescription(cell, row, enumObject, rowIndex) {

        if (row.info) {
            return row.info[this.state.formData.lang]
        } else {
            return "";
        }

    }

    handleCategory(cell, row, enumObject, rowIndex) {
        if (row.categoryData.length > 0) {
            return row.categoryData[0].name
        }

    }

    handleCalorie(cell, row, enumObject, rowIndex) {
        return (<span>{row.calorie} kcal <br></br> C : {row.carbohydrate}, P : {row.protein}, F : {row.fat}</span>)
    }

    handleServing(cell, row, enumObject, rowIndex) {
        if (row.servingSizeData.length > 0) {
            return row.qty + " " + (row.servingSizeData[0].name[this.state.formData.lang] ? row.servingSizeData[0].name[this.state.formData.lang] : '')
        } else { return row.qty; }
    }



    onSearch(e) {
        e.preventDefault();
        let formData = this.state.formData;

        this.props.foodListAction(formData)
    }

    onPageChange(page, sizePerPage) {
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
        }
        data['veg'] = this.state.formData.veg;
        data['status'] = this.state.formData.status;
        // data['foodCategoryId'] = this.state.formData.foodCategoryId;

        this.setState({ sizePerPage: sizePerPage });
        this.setState({ currentPage: page });
        this.props.foodListAction(data)

        if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
            eval('$(".ps").scrollTop(0)');
        }
    }

    handleVegCheck() {
        let formData = this.state.formData;
        formData['veg'] = '';
        if (this.state.veg_checked) {
            this.setState({ veg_checked: false });

            if (this.state.non_veg_checked) {
                formData['veg'] = 'non-veg-s';
            }
        } else {
            this.setState({ veg_checked: true });
            if (!this.state.non_veg_checked) {
                formData['veg'] = 'veg-s';
            }
        }
        this.setState({ formData: formData });
        this.props.foodListAction(this.state.formData);
    }

    handleNonVegCheck() {
        let formData = this.state.formData;
        formData['veg'] = '';
        if (this.state.non_veg_checked) {
            this.setState({ non_veg_checked: false });
            if (this.state.veg_checked) {
                formData['veg'] = 'veg-s';
            }
        } else {
            this.setState({ non_veg_checked: true });
            if (!this.state.veg_checked) {
                formData['veg'] = 'non-veg-s';
            }
        }
        this.setState({ formData: formData });
        this.props.foodListAction(this.state.formData);
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                data['veg'] = this.state.formData.veg;
                data['lang'] = this.state.formData.lang;
                data['foodCategoryId'] = this.state.formData.foodCategoryId;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 });
                this.props.foodListAction(data);
            }
        } else {
            setTimeout(() => {
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    sortHandle() {
        this.setState({ asc_desc: !this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc ? "asc" : "desc"
        this.setState({ formData: data });
        this.props.foodListAction(data);
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {


        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            noDataText: this._setTableOption(),
        };

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables">
                                        <Row>
                                            <Col md={6}>
                                                <Button bsStyle="info" fill pullRight onClick={() => this.props.history.replace('add-food')}>Add Food</Button>
                                            </Col>

                                        </Row>&nbsp;

                                        <Row>
                                            <Col md={3}>
                                                <Col componentClass={ControlLabel}>
                                                    Food Category
                                                </Col>
                                                <FormControl componentClass="select" name="foodCategory" onChange={e => { this.handleFoodCategory(e); }}>
                                                    <option value="">Select Food Category</option>
                                                    {this.state.foodCategoryList.map(function (item) {
                                                        return <option key={item._id} value={item._id}>{item.name}</option>
                                                    })}
                                                </FormControl>
                                            </Col>

                                            <Col md={2}>
                                                <Button bsStyle="info searchBtn" onClick={e => { this.onSearch(e); }} >Search</Button>
                                            </Col>
                                            <Col md={3}>
                                                <Col componentClass={ControlLabel}>
                                                    Language
                                                </Col>

                                                <FormControl componentClass="select" name="langname" value={this.state.formData.lang} onChange={e => { this.handleLanguage(e); }}>
                                                    <option value="">Select Language</option>
                                                    {this.state.languageList.map(function (item) {
                                                        return <option key={item._id} value={item.lanId}>{appConstants.ucFirst(item.name)}</option>
                                                    })}
                                                </FormControl>
                                            </Col>
                                            <Col md={4} className="searchBtn spctb-m">
                                                <Button bsStyle="info" fill pullRight onClick={e => { this.handleActiveList(e); }}>Active List</Button>
                                                <Button bsStyle="info" fill pullRight onClick={e => { this.handleInactiveList(e); }}>Inactive List</Button>
                                            </Col>
                                        </Row>&nbsp;
                                        <div>
                                            <Col>
                                                <input type="checkbox" name="veg" id="veg" onChange={e => { this.handleVegCheck(e); }} defaultChecked={this.state.veg_checked} /> Vegetarian

                                                <input className="slp-o" type="checkbox" name="nonveg" id="nonveg" onChange={e => { this.handleNonVegCheck(e); }} defaultChecked={this.state.non_veg_checked} /> Non Vegetarian
                                            </Col>
                                        </div>
                                        <div className="table-resposive educator-L">
                                            <div className='search-box-admin'>
                                                <div className='search'>
                                                    <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                    <span className='input-group-btn'>
                                                        <Button onClick={(e) => { this.onSearchChange(e.target.value, "null") }}>Clear</Button>
                                                    </span>
                                                </div>
                                            </div>
                                            <BootstrapTable
                                                selectRow="{selectRowProp}"
                                                deleteRow={false}
                                                data={this.state.foodList}
                                                // search={true}
                                                multiColumnSearch={true}
                                                pagination={true}
                                                options={options}
                                                striped
                                                hover
                                                condensed
                                                scrollTop={'Bottom'}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '3%', textAlign: "center" }} tdStyle={{ width: '3%', textAlign: "center" }} tdAttr={{ 'data-attr': '#' }} dataField='' dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: "center" }} tdStyle={{ width: '10%', textAlign: "center" }} tdAttr={{ 'data-attr': 'name' }} dataFormat={this.handleName.bind(this)} >Name
                                                    {
                                                        this.state.asc_desc ?
                                                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                                                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                                                    }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: "center" }} tdStyle={{ width: '10%', textAlign: "center" }} tdAttr={{ 'data-attr': 'category' }} dataFormat={this.handleCategory.bind(this)} >Category</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%', textAlign: "center" }} tdStyle={{ width: '15%', textAlign: "center" }} tdAttr={{ 'data-attr': 'calorie' }} dataFormat={this.handleCalorie.bind(this)} >Calorie</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: "center" }} tdStyle={{ width: '10%', textAlign: "center" }} tdAttr={{ 'data-attr': 'serving' }} dataFormat={this.handleServing.bind(this)} >Serving Qty</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: "center" }} tdStyle={{ width: '10%', textAlign: "center" }} tdAttr={{ 'data-attr': 'veg' }} dataField='veg' dataFormat={appConstants.vegNonVeg}>Food Type</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: "center" }} tdStyle={{ width: '10%', textAlign: "center" }} tdAttr={{ 'data-attr': 'description' }} dataFormat={this.handleDescription.bind(this)} >Description</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: "center" }} tdStyle={{ width: '8%', textAlign: "center" }} tdAttr={{ 'data-attr': 'Status' }} dataField='status' dataFormat={appConstants.ucFirst}>Status</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: "center" }} tdStyle={{ width: '8%', textAlign: "center" }} tdAttr={{ 'data-attr': 'Action' }} dataFormat={this.editButton.bind(this)} dataSort={false}>Action</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        FoodList: state.food.FoodList,
        isFoodList: state.food.isFoodList,
        isFoodListError: state.food.isFoodListError,

        FoodChangeStatus: state.food.FoodChangeStatus,
        isFoodChangeStatus: state.food.isFoodChangeStatus,
        isFoodChangeStatusError: state.food.isFoodChangeStatusError,

        FoodCategoryList: state.foodCategory.FoodCategoryList,
        isFoodCategoryList: state.foodCategory.isFoodCategoryList,
        isFoodCategoryListError: state.foodCategory.isFoodCategoryListError,


        LanguageList: state.language.LanguageList,
        isLanguageList: state.language.isLanguageList,
        isLanguageListError: state.language.isLanguageListError,

    }
}
export default withRouter(connect(mapStateToProps, { foodListAction, foodchangestatusAction, foodCategoryListAction, languageListAction })(FoodList));

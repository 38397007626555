/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { postRequestWithToken } from "../../api/helper.js"
const ViewBox = ({ data, hideViewBox }) => {

  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState('image');
  const [rotateDeg, setRotateDeg] = useState(0);
  const [imgStyle, setImgStyle] = useState({ height: 100, width: 100 });
  const [imagePath, setImagePath] = useState('')

  // Create reference of DOM object
  const imgRef = useRef({ clientHeight: 600, clientWidth: 600 })

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {

    setShow(false);
    hideViewBox();
  };

  const rotateImg = () => {
    let deg = ((rotateDeg == 270) ? 0 : (rotateDeg + 90));
    setRotateDeg(deg);
  }

  const getSignedUrl = async (path) => {
    let resp = await postRequestWithToken({ filePath: path }, 's3SignedUrl');
    setImagePath(resp.data.url)
  };

  // Event handler callback for zoom in
  const handleZoomIn = () => {
    // Increase dimension(Zooming)
    setImgStyle({
      height: imgStyle.height + 100,
      width: imgStyle.width + 100,
    })
  }

  // Event handler callback zoom out
  const handleZoomOut = () => {
    // Assigning original height and width
    if (imgStyle.height > 100) {
      setImgStyle({
        height: imgStyle.height - 100,
        width: imgStyle.width - 100,
      })
    }
  }

  useEffect(() => {

    let type = 'image';

    let arr = data.path.split('.');
    let output = arr.pop();
    if (output === "mp4") {
      type = "video";
    } else if (output === "pdf") {
      type = "pdf";

    } else {
      type = "image";
    }

    setReportType(type);
    handleOpen();
    getSignedUrl(data.path)



    setImgStyle({
      height: imgRef.current.clientHeight,
      width: imgRef.current.clientWidth,
    })

  }, []);


  return (
    <>
      <Modal show={show} onHide={e => handleClose()} dialog className="modal-100w" >

        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">{data.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="Knowledge-Share card img-ccent">

          {(data.type === "video" || data.type === "mp4") && data.type !== "pdf" && (data.type !== "jpeg" || data.type !== "png") &&
            <div>
              <ReactPlayer className='react-player' width='100%' height='100%' url={imagePath} playing controls="true" volume="1" />
            </div>
          }
          {data.type !== "pdf" && data.type !== "video" && data.type !== "mp4" &&
            <>
              <div className="zoom-btns">
                <Button className="btn btn-primary zoom-in" title="IN ZOOM" onClick={e => handleZoomIn()}><i className="fa fa-plus"></i> ZOOM</Button>
                <Button className="btn btn-primary zoom-out" title="OUT ZOOM" onClick={e => handleZoomOut()}><i className="fa fa-minus"></i> ZOOM </Button>
              </div>
              <Button className="btn btn-primary btn-rotate" title="Rotate" onClick={e => rotateImg()}><i className="fa fa-refresh"></i> Rotate </Button>

              <div style={{ "padding": "10px" }}>
                <img style={imgStyle} ref={imgRef} src={imagePath} alt='gfg' className={"rotate-" + rotateDeg} />
              </div>
            </>
          }

          {data.type === "pdf" &&
            <div>
              {/* <a href={ data.path} target="_blank"> Download pdf</a>
                               {data.path} */}
              {/* <iframe src={`${imagePath}#view=fitH`} style={{ width: "100%", height: "calc(100vh - 48px)" }}></iframe> */}
              <object data={`${imagePath}#view=fitH`} style={{ width: "100%", height: "calc(100vh - 48px)" }}></object>
            </div>
          }

        </Modal.Body>
        {/* <Modal.Footer>
              <button type="button" onClick={e => handleClose()} className="btn-fill btn-wd btn btn-default">Close</button>&nbsp;
          </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default ViewBox;
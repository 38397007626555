/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import Dashboard from 'Admin/views/Dashboard/Dashboard.jsx';
import UserPage from 'Admin/views/Pages/UserPage.jsx';
import ClinicInvitation from 'Admin/views/ClinicInvitation/ClinicInvitation.jsx';
import AddInvitation from 'Admin/views/ClinicInvitation/AddInvitation.jsx';
import ClinicList from 'Admin/views/ManageClinic/ClinicList.jsx';
import AddClinic from 'Admin/views/ManageClinic/AddClinic.jsx';
import DemoClinic from 'Admin/views/ManageClinic/DemoClinic.jsx';
import ClinicSubscription from 'Admin/views/ManageClinic/ClinicSubscription.jsx';
import AddClinicSubscription from 'Admin/views/ManageClinic/AddClinicSubscription.jsx';
import EditClinicSubscription from 'Admin/views/ManageClinic/EditClinicSubscription.jsx';
import UpdateClinic from 'Admin/views/ManageClinic/UpdateClinic.jsx';
import UserList from 'Admin/views/ManageUser/UserList.jsx';
import AddUser from 'Admin/views/ManageUser/AddUser.jsx';
import UpdateUser from 'Admin/views/ManageUser/UpdateUser.jsx';
import SpecializationList from 'Admin/views/ManageMaster/SpecializationList.jsx';
import AddSpecialization from 'Admin/views/ManageMaster/AddSpecilization.jsx';
import UpdateSpecialization from 'Admin/views/ManageMaster/UpdateSpecilization.jsx';
import AddKnowledge from 'Admin/views/ManageMaster/AddKnowledge.jsx';
//import UpdateKnowledge from 'Admin/views/ManageMaster/UpdateKnowledge.jsx';
import KnowledgeList from 'Admin/views/ManageMaster/KnowledgeList.jsx';
import AddFaq from 'Admin/views/ManageMaster/AddFaq.jsx';
import UpdateFaq from 'Admin/views/ManageMaster/UpdateFaq.jsx';
import AddEducator from 'Admin/views/ManageMaster/AddEducator.jsx';
import UpdateEducator from 'Admin/views/ManageMaster/UpdateEducator.jsx';
import EducatorList from 'Admin/views/ManageMaster/EducatorList.jsx';
import pagesRoutes from './pages.jsx';
import AddPlan from 'Admin/views/ManageMaster/AddPlan.jsx';
import UpdatePlan from 'Admin/views/ManageMaster/UpdatePlan.jsx';
import LinkList from 'Admin/views/ManageMaster/LinkList.jsx';
import LanguageList from 'Admin/views/ManageMaster/LanguageList.jsx';
//import FaqList from 'Admin/views/ManageMaster/FaqList.jsx';
import AddLink from 'Admin/views/ManageMaster/AddLink.jsx';
import UpdateLink from 'Admin/views/ManageMaster/UpdateLink.jsx';
import PlanList from 'Admin/views/ManageMaster/PlanList.jsx';
//import ClinicCalendar from 'Admin/views/ManageClinic/ClinicCalendar.jsx';
import AddClinicCalendar from 'Admin/views/ManageClinic/AddClinicCalendar.jsx';
import UpdateClinicCalendar from 'Admin/views/ManageClinic/UpdateClinicCalendar.jsx';
import UpdateTermcondition from 'Admin/views/ManageMaster/UpdateTermcondition.jsx';
import ClinicActiveList from 'Admin/views/ManageClinic/ClinicActiveList.jsx';
import ClinicRequestList from 'Admin/views/ClinicRequest/ClinicRequest.jsx';
//import PatientRequestList from 'Admin/views/PatientRequest/PatientRequest.jsx';
import ReportList from 'Admin/views/Report/ReportList.jsx';
import UploadData from 'Admin/views/Report/UploadData.jsx';
import AddClinicDocument from 'Admin/views/ManageMaster/AddClinicDocument.jsx';
import ClinicDocumentList from 'Admin/views/ManageMaster/ClinicDocumentList.jsx';
import UpdateClinicDocument from 'Admin/views/ManageMaster/UpdateClinicDocument.jsx';

import AddClinicDocumentUpload from 'Admin/views/ManageMaster/AddClinicDocumentUpload.jsx';
import ClinicDocumentUploadList from 'Admin/views/ManageMaster/ClinicDocumentUploadList.jsx';
import UpdateClinicDocumentUpload from 'Admin/views/ManageMaster/UpdateClinicDocumentUpload.jsx';

import SupportList from 'Admin/views/ManageMaster/SupportList.jsx';
import AddSupportSystem from 'Admin/views/ManageMaster/AddSupportSystem.jsx';
import UpdateSupportSystem from 'Admin/views/ManageMaster/UpdateSupportSystem.jsx';
import SupportSystemView from 'Admin/views/ManageMaster/SupportSystemView.js';
import IconsList from '../views/ManageMaster/IconsList.jsx';
// import AddSupportDocument from 'Admin/views/ManageMaster/AddSupportDocument.jsx';
// import UpdateSupportDocument from 'Admin/views/ManageMaster/UpdateSupportDocument.jsx';
// import SupportSystemList from 'Admin/views/ManageMaster/SupportSystemList.jsx';
// import SupportSystemListUpload from 'Admin/views/ManageMaster/SupportSystemListUpload.jsx';
// import UpdateSupportDocumentUpload from 'Admin/views/ManageMaster/UpdateSupportDocumentUpload.jsx';
// import AddSupportDocumentUpload from 'Admin/views/ManageMaster/AddSupportDocumentUpload.jsx';

// diet start
import FoodCategoryList from 'Admin/views/ManageDiet/FoodCategory/FoodCategoryList.jsx';
import AddFoodCategory from 'Admin/views/ManageDiet/FoodCategory/AddFoodCategory.jsx';
import UpdateFoodCategory from 'Admin/views/ManageDiet/FoodCategory/UpdateFoodCategory.jsx';
import ServingSizeList from 'Admin/views/ManageDiet/ServingSize/ServingSizeList.jsx';
import AddServingSize from 'Admin/views/ManageDiet/ServingSize/AddServingSize.jsx';
import UpdateServingSize from 'Admin/views/ManageDiet/ServingSize/UpdateServingSize.jsx';
import FoodList from 'Admin/views/ManageDiet/Food/FoodList.jsx';
import AddFood from 'Admin/views/ManageDiet/Food/AddFood.jsx';
import UpdateFood from 'Admin/views/ManageDiet/Food/UpdateFood.jsx';
import RegionList from 'Admin/views/ManageDiet/Region/RegionList.jsx';
import AddRegion from 'Admin/views/ManageDiet/Region/AddRegion.jsx';
import UpdateRegion from 'Admin/views/ManageDiet/Region/UpdateRegion.jsx';
import AddAttachment from 'Admin/views/ManageDiet/Attachment/AddAttachment.jsx';
import AttachmentList from 'Admin/views/ManageDiet//Attachment/AttachmentList.jsx';
import UpdateAttachment from 'Admin/views/ManageDiet/Attachment/UpdateAttachment.jsx';
import LowCalorieFoodList from 'Admin/views/ManageDiet/LowCalorieFood/LowCalorieFoodList.jsx';
import AddLowCalorieFood from 'Admin/views/ManageDiet/LowCalorieFood/AddLowCalorieFood.jsx';
import UpdateLowCalorieFood from 'Admin/views/ManageDiet/LowCalorieFood/UpdateLowCalorieFood.jsx';
import CommentList from 'Admin/views/ManageDiet/Comments/CommentList.jsx';
import AddComment from 'Admin/views/ManageDiet/Comments/AddComment.jsx';
import UpdateComment from 'Admin/views/ManageDiet/Comments/UpdateComment.jsx';
import TreatmentTypeList from 'Admin/views/ManageDiet/TreatmentType/TreatmentTypeList.jsx';
import AddTreatmentType from 'Admin/views/ManageDiet/TreatmentType/AddTreatmentType.jsx';
import UpdateTreatmentType from 'Admin/views/ManageDiet/TreatmentType/UpdateTreatmentType.jsx';
import InstructionList from 'Admin/views/ManageDiet/Instruction/InstructionList.jsx';
import AddInstruction from 'Admin/views/ManageDiet/Instruction/AddInstruction.jsx';
import UpdateInstruction from 'Admin/views/ManageDiet/Instruction/UpdateInstruction.jsx';
import AddTreatment from 'Admin/views/ManageDiet/Treatment/AddTreatment.jsx';
import TreatmentList from 'Admin/views/ManageDiet/Treatment/TreatmentList.jsx';
import UpdateTreatment from 'Admin/views/ManageDiet/Treatment/UpdateTreatment.jsx';
import CreateMeal from 'Admin/views/ManageDiet/Meal/CreateMeal/CreateMeal.jsx';
import UpdateCreateMeal from 'Admin/views/ManageDiet/Meal/CreateMeal/UpdateCreateMeal.jsx';
import FoodCart from 'Admin/views/ManageDiet/Meal/CreateMeal/FoodCart.jsx';
import ReadyCreatedMeal from 'Admin/views/ManageDiet/Meal/ReadyCreatedMeal.jsx';
import FoodGroupList from 'Admin/views/ManageDiet/Meal/FoodGroupList.jsx';
import UpdateReadyCreatedMeal from 'Admin/views/ManageDiet/Meal/UpdateReadyCreatedMeal.jsx';
import MealAnalysis from 'Admin/views/ManageDiet/Meal/MealAnalysis.jsx';

import AddNews from 'Admin/views/NewsRoom/AddNews.jsx';
import ClinicNews from 'Admin/views/NewsRoom/ClinicNews.jsx';
import EditNews from 'Admin/views/NewsRoom/EditNews.jsx';
import NotificationList from '../views/ManageMaster/NotificationList.jsx';
import AdvertisementList from '../views/ManageMaster/AdvertisementList.jsx';
import AddEditNotification from '../views/ManageMaster/AddEditNotification.jsx';
import AddEditAdvertisement from '../views/ManageMaster/AddEditAdvertisement.jsx';
import DrugList from '../views/ManageMaster/Emr/Drug/DrugList.jsx';
import DrugCategoryList from '../views/ManageMaster/Emr/Drug/DrugCategoryList.jsx';
import AddEditDrugCategory from '../views/ManageMaster/Emr/Drug/AddEditDrugCategory.jsx';
import DrugCompanyList from '../views/ManageMaster/Emr/Drug/DrugCompanyList.jsx';
import AddEditDrugCompany from '../views/ManageMaster/Emr/Drug/AddEditDrugCompany.jsx';
import RecommendationList from '../views/ManageMaster/Emr/Drug/RecommendationList.jsx';
import AddEditRecoomendation from '../views/ManageMaster/Emr/Drug/AddEditRecoomendation.jsx';
import DrugInstructionList from '../views/ManageMaster/Emr/Drug/DrugInstructionList.jsx';
import AddEditDrugInstruction from '../views/ManageMaster/Emr/Drug/AddEditDrugInstruction.jsx';
import AddEditDrug from '../views/ManageMaster/Emr/Drug/AddEditDrug.jsx';
import MasterSymptomCategoryList from '../views/ManageMaster/Emr/Complaint/MasterSymptomCategoryList.jsx';
import AddEditSymptomCategory from '../views/ManageMaster/Emr/Complaint/AddEditSymptomCategory.jsx';
import MasterSymptomSubCategoryList from '../views/ManageMaster/Emr/Complaint/MasterSymptomSubCategoryList.jsx';
import AddEditSymptomSubCategory from '../views/ManageMaster/Emr/Complaint/AddEditSymptomSubCategory.jsx';
import DiagnosisCategoryList from '../views/ManageMaster/Emr/Diagnosis/DiagnosisCategoryList.jsx';
import AddEditDiagnosisCategory from '../views/ManageMaster/Emr/Diagnosis/AddEditDiagnosisCategory.jsx';
import EditDiagnosisSubCategorySequence from '../views/ManageMaster/Emr/Diagnosis/EditDiagnosisSubCategorySequence.jsx';
import DiagnosisSubcategoryList from '../views/ManageMaster/Emr/Diagnosis/DiagnosisSubcategoryList.jsx';
import AddEditDiagnosisSubCategory from '../views/ManageMaster/Emr/Diagnosis/AddEditDiagnosisSubCategory.jsx';
import ClinicPerformanceList from '../views/ManageMaster/ClinicPerformance.jsx'
import appVersion from '../views/ManageMaster/AppVersion.jsx';
import AddEditIcons from '../views/ManageMaster/AddEditIcons.jsx';
import StarStatusList from '../views/ManageMaster/StarStatusList.jsx';
import AddEditStarStatus from '../views/ManageMaster/AddEditStarStatus.jsx';
import LabTestList from '../views/ManageMaster/Emr/PathLab/LabTestList.jsx';
import AddEditLabTest from '../views/ManageMaster/Emr/PathLab/AddEditLabTest.jsx';
import AddEditLabTestRange from '../views/ManageMaster/Emr/PathLab/AddEditLabTestRange.jsx';
// import MigrateClinic from '../views/ManageClinic/MigrateClinic.jsx';
// diet end
//   import ClinicHeader from 'Admin/views/ClinicHeader/ClinicHeader.jsx';



var pages = [
    { path: "/update-specialization/", name: "Edit Specialization", mini: "US", component: UpdateSpecialization },
    { path: "/admin/update-educator", name: "Edit Educator Message", mini: "UE", component: UpdateEducator },
    { path: "/admin/update-plan", name: "Edit Master Plan", mini: "UP", component: UpdatePlan },
    { path: "/admin/update-faq/", name: "Edit FAQ", mini: "UF", component: UpdateFaq },
    { path: "/admin/update-link", name: "Edit Clinic Link", mini: "UL", component: UpdateLink },
    { path: "/admin/update-user", name: "Edit User", mini: "AU", component: UpdateUser },
    { path: "/admin/update-knowledge", name: "Edit Knowledge", mini: "UK", component: AddKnowledge },
    { path: "/admin/update-calendar", name: "Edit Calendar", mini: "UF", component: UpdateClinicCalendar },
    { path: "/admin/update-clinic", name: "Edit Clinic", mini: "UC", component: UpdateClinic },
    { path: "/pages/user-page", name: "User Page", mini: "UP", component: UserPage },

    { path: "/admin/add-faq", name: "Add FAQ", mini: "AF", component: AddFaq },
    { path: "/admin/add-clinic-subscription", name: "Add Clinic Subscription", mini: "CS", component: AddClinicSubscription },
    { path: "/admin/edit-clinic-subscription", name: "Edit Clinic Subscription", mini: "CS", component: EditClinicSubscription },
    { path: "/add-specialization", name: "Add Specialization", mini: "AS", component: AddSpecialization },
    { path: "/admin/add-educator", name: "Add Educator Message", mini: "AE", component: AddEducator },
    { path: "/admin/add-knowledge", name: "Add Knowledge", mini: "AK", component: AddKnowledge },
    { path: "/admin/add-link", name: "Add Clinic Link", mini: "Al", component: AddLink },
    { path: "/admin/add-plan", name: "Add Subscription", mini: "KL", component: AddPlan },
    { path: "/admin/add-clinic-calendar", name: "Add Clinic Calendar", mini: "ACC", component: AddClinicCalendar },
    { path: "/admin/update-clinic-document", name: "Update Clinic Document", mini: "UD", component: UpdateClinicDocument },
    { path: "/admin/clinic-request", name: "Clinc Request List", mini: "UL", component: ClinicRequestList },
    { path: "/admin/add-clinic", name: "Add Clinic", mini: "AC", component: AddClinic },
    { path: "/admin/add-clinic-subscription", name: "Add Subscription", mini: "CS", component: AddClinicSubscription },
    { path: "/admin/add-user", name: "Add User", mini: "AU", component: AddUser },
    { path: "/admin/add-invitation", name: "Add Invitations", mini: "ACI", component: AddInvitation },
    { path: "/admin/clinic-list/:id", name: "Clinic", mini: "CL", component: ClinicList },
    { path: "/support-list/:id", name: "Support Ticket", mini: "SS", component: SupportList },

    { path: "/update-supportsystem/", name: "Edit SupportSystem", mini: "US", component: UpdateSupportSystem },
    { path: "/add-supportsystem", name: "Add Support Ticket", mini: "AS", component: AddSupportSystem },
    { path: "/support-system-view/:id", name: "Support System View", mini: "SS", component: SupportSystemView },
    // { path: "/admin/support-system-list", name: "Support System", mini: "SS", component: SupportSystemList },
    // { path: "/admin/add-support-document", name: "Support System", mini: "SS", component: AddSupportDocument },
    // {path: "/admin/update-support-document", name: "Update Support System", mini: "US", component: UpdateSupportDocument },
    { path: "/admin/add-notification", name: "Add Notification", mini: "AN", component: AddEditNotification },
    { path: "/admin/edit-notification/", name: "Edit Notification", mini: "EN", component: AddEditNotification },

    { path: "/admin/drug-list", name: "Drug List", mini: "DL", component: DrugList },
    { path: "/admin/add-drug", name: "Add Drug", mini: "DL", component: AddEditDrug },
    { path: "/admin/edit-drug/", name: "Edit Drug", mini: "ED", component: AddEditDrug },

    { path: "/admin/drugCategory-list", name: "Drug Category List", mini: "DCL", component: DrugCategoryList },
    { path: "/admin/add-drug-category", name: "Add Drug Category", mini: "ADC", component: AddEditDrugCategory },
    { path: "/admin/edit-drug-category/", name: "Edit Drug Category", mini: "EDC", component: AddEditDrugCategory },

    { path: "/admin/drugCompany-list", name: "Drug Company List", mini: "DCL", component: DrugCompanyList },
    { path: "/admin/add-drug-company", name: "Add Drug Company", mini: "ADC", component: AddEditDrugCompany },
    { path: "/admin/edit-drug-company/", name: "Edit Drug Company", mini: "EDC", component: AddEditDrugCompany },

    { path: "/admin/recommendation-list", name: "Recommendation List", mini: "RL", component: RecommendationList },
    { path: "/admin/add-recommendation", name: "Add Recommendation", mini: "AR", component: AddEditRecoomendation },
    { path: "/admin/edit-recommendation/", name: "Edit Recommendation", mini: "ER", component: AddEditRecoomendation },

    { path: "/admin/drug-instruction-list", name: "Drug Instruction List", mini: "DIL", component: DrugInstructionList },
    { path: "/admin/add-drugInstruction", name: "Add Drug Instruction", mini: "ADI", component: AddEditDrugInstruction },
    { path: "/admin/edit-drugInstruction", name: "Edit Drug Instruction", mini: "EDI", component: AddEditDrugInstruction },

    { path: "/admin/symptom-category-list", name: "Complaint Categroy List", mini: "CCL", component: MasterSymptomCategoryList },
    { path: "/admin/add-symptom-category", name: "Add Complaint Category", mini: "ACC", component: AddEditSymptomCategory },
    { path: "/admin/edit-symptom-category/", name: "Edit Complaint Category", mini: "ECC", component: AddEditSymptomCategory },

    { path: "/admin/symptom-sub-category-list", name: "Complaint List", mini: "CL", component: MasterSymptomSubCategoryList },
    { path: "/admin/add-symptom-sub-category", name: "Add Complaint", mini: "AC", component: AddEditSymptomSubCategory },
    { path: "/admin/edit-symptom-sub-category/", name: "Edit Complaint", mini: "EC", component: AddEditSymptomSubCategory },

    { path: "/admin/diagnosis-category-list", name: "Diagnosis Categroy List", mini: "DCL", component: DiagnosisCategoryList },
    { path: "/admin/add-diagnosis-category", name: "Add Diagnosis Category", mini: "ADC", component: AddEditDiagnosisCategory },
    { path: "/admin/edit-diagnosis-category/", name: "Edit Diagnosis Category", mini: "EDC", component: AddEditDiagnosisCategory },
    { path: "/admin/edit-diagnosis-sub-category-sequence/", name: "Edit Diagnosis Sub Category Sequence ", mini: "EDC", component: EditDiagnosisSubCategorySequence },

    { path: "/admin/diagnosis-sub-category-list", name: "Diagnosis Sub Categroy List", mini: "DCL", component: DiagnosisSubcategoryList },
    { path: "/admin/add-diagnosis-sub-category", name: "Add Diagnosis", mini: "AD", component: AddEditDiagnosisSubCategory },
    { path: "/admin/edit-diagnosis-sub-category/", name: "Edit Diagnosis", mini: "ED", component: AddEditDiagnosisSubCategory },
    { path: "/admin/edit-app-version", name: "Edit App Version", mini: "AV", component: appVersion },

    { path: "/admin/add-advertisement", name: "Add Advertisement", mini: "AN", component: AddEditAdvertisement },
    { path: "/admin/edit-advertisement/", name: "Edit Advertisement", mini: "EN", component: AddEditAdvertisement },

    { path: "/admin/add-icons", name: "Add Icons", mini: "AI", component: AddEditIcons },
    { path: "/admin/edit-icons", name: "Edit Icons", mini: "AE", component: AddEditIcons },


    { path: "/admin/add-star-status", name: "Add Icons", mini: "ASS", component: AddEditStarStatus },
    { path: "/admin/edit-star-status", name: "Edit Icons", mini: "ESS", component: AddEditStarStatus },



    { path: "/admin/clinic-performance-list/:id", name: "Clinic Performance List", mini: "CPL", component: ClinicPerformanceList },

    { path: "/admin/lab-test-list", name: "Lab Test List", mini: "LTL", component: LabTestList },
    { path: "/admin/add-lab-test", name: "Add Lab Test", mini: "ALT", component: AddEditLabTest },
    { path: "/admin/edit-lab-test/", name: "Edit Lab Test", mini: "ELT", component: AddEditLabTest },
    { path: "/admin/add-lab-test-range/", name: "Add Lab Test Range", mini: "ALTR", component: AddEditLabTestRange },
    { path: "/admin/edit-lab-test-range/", name: "Edit Lab Test Range", mini: "ELTR", component: AddEditLabTestRange },





    //diet start
    { path: "/admin/add-food-category", name: "Add Food Category", mini: "AF", component: AddFoodCategory },
    { path: "/admin/update-food-category", name: "Edit Food Category", mini: "UF", component: UpdateFoodCategory },
    { path: "/admin/add-serving-size", name: "Add Serving Size", mini: "ASS", component: AddServingSize },
    { path: "/admin/update-serving-size", name: "Edit Serving Size", mini: "USS", component: UpdateServingSize },
    { path: "/admin/food-list", name: "Food List", mini: "FL", component: FoodList },
    { path: "/admin/add-food", name: "Add Food", mini: "AF", component: AddFood },
    { path: "/admin/update-food", name: "Update Food", mini: "AF", component: UpdateFood },
    { path: "/admin/region-list", name: "Region List", mini: "RL", component: RegionList },
    { path: "/admin/add-region", name: "Add Region", mini: "AR", component: AddRegion },
    { path: "/admin/update-region", name: "Edit Region", mini: "UR", component: UpdateRegion },
    { path: "/admin/add-attachment", name: "Add Attachment", mini: "AA", component: AddAttachment },
    { path: "/admin/attachment-list", name: "Attachment List", mini: "AL", component: AttachmentList },
    { path: "/admin/update-attachment", name: "Edit Attachment", mini: "UA", component: UpdateAttachment },
    { path: "/admin/low-calorie-food", name: "Low Calorie Food List", mini: "LCFL", component: LowCalorieFoodList },
    { path: "/admin/add-low-calorie-food", name: "Add Low Calorie Food", mini: "ALCF", component: AddLowCalorieFood },
    { path: "/admin/update-low-calorie-food", name: "Update Low Calorie Food", mini: "ELCF", component: UpdateLowCalorieFood },
    { path: "/admin/comment-list", name: "Comment List", mini: "CL", component: CommentList },
    { path: "/admin/add-comment", name: "Add Comment", mini: "AC", component: AddComment },
    { path: "/admin/update-comment", name: "Edit Comment", mini: "UC", component: UpdateComment },
    { path: "/admin/treatment-type", name: "Treatment Type List", mini: "TTL", component: TreatmentTypeList },
    { path: "/admin/add-treatment-type", name: "Add Treatment Type", mini: "ATT", component: AddTreatmentType },
    { path: "/admin/update-treatment-type", name: "Edit Treatment Type", mini: "ETT", component: UpdateTreatmentType },
    { path: "/admin/instruction", name: "Instruction List", mini: "IL", component: InstructionList },
    { path: "/admin/add-instruction", name: "Add Instruction", mini: "AI", component: AddInstruction },
    { path: "/admin/update-instruction", name: "Edit Instruction", mini: "EI", component: UpdateInstruction },
    { path: "/admin/add-treatment", name: "Add Treatment", mini: "AT", component: AddTreatment },
    { path: "/admin/treatment", name: "Treatment List", mini: "TL", component: TreatmentList },
    { path: "/admin/update-treatment", name: "Edit Treatment", mini: "EL", component: UpdateTreatment },
    { path: "/admin/food_group_add_edit", name: "Create Meal", mini: "CM", component: CreateMeal },
    { path: "/admin/food_group_edit", name: "Create Meal", mini: "CM", component: UpdateCreateMeal },
    { path: "/admin/food-cart", name: "Food Cart", mini: "FC", component: FoodCart },
    { path: "/admin/ready-created-meal", name: "Ready Created Meal", mini: "RCM", component: ReadyCreatedMeal },
    { path: "/admin/food_group_list", name: "List Of Meals", mini: "LOM", component: FoodGroupList },
    { path: "/admin/update-clinic-document-upload", name: "Update Clinic Document Upload", mini: "AD", component: UpdateClinicDocumentUpload },
    { path: "/admin/update-ready-created-meal", name: "Edit Ready Created Meal", mini: "ERCM", component: UpdateReadyCreatedMeal },
    { path: "/admin/add-clinic-document", name: "Add Library Document", mini: "AD", component: AddClinicDocument },
    { path: "/admin/add-news-room", name: "Add News Room", mini: "ANR", component: AddNews },
    { path: "/admin/edit-news-room", name: "Edit News Room", mini: "ANR", component: EditNews },
    { path: "/admin/news-room", name: "News Room", mini: "NR", component: ClinicNews },
    { path: "/admin/add-clinic-document-upload", name: "Add Clinic Document", mini: "AD", component: AddClinicDocumentUpload },


    // { path: "/admin/support-system-list-upload", name: "Add Support System", mini: "SU", component: SupportSystemListUpload },
    // { path: "/admin/update-support-document-upload", name: "update-support-document-upload", mini: "SS", component: UpdateSupportDocumentUpload },
    // { path: "/admin/add-support-document-upload", name: "add-support-document-upload", mini: "SS", component: AddSupportDocumentUpload },

].concat(pagesRoutes);

var dashRoutes = [
    { path: "/admin/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: Dashboard },

    /*{ path: "/admin/clinic-invitation", name: "Clinic Invitation", icon: "pe-7s-next-2", component: ClinicInvitation },*/
    {
        collapse: true, path: "/clinic-invitation", name: "Clinic Invitation", state: "openPages", icon: "fa fa-share-square-o", views: [
            { path: "/admin/clinic-invitation", name: "Invitations", mini: "CI", component: ClinicInvitation },
        ]
    },

    {
        collapse: true, path: "/manage-clinic", name: "Manage Clinic", state: "openManageClinic", icon: "fa fa-hospital-o", views: [

            { path: "/admin/clinic-list", name: "Clinic", mini: "CL", component: ClinicList },


            // { path: "/admin/clinic-calendar", name: "Clinic Calendar", mini: "CC", component: ClinicCalendar },
            { path: "/admin/user-list", name: "User", mini: "UL", component: UserList },
            // { path: "/admin/add-clinic", name: "Add Clinic", mini: "AC", component: AddClinic },
            //{ path: "/admin/update-clinic", name: "Update Clinic", mini: "UC", component: UpdateClinic },
            // { path: "/admin/add-clinic-subscription", name: "Add Clinic Subscription", mini: "CS", component: AddClinicSubscription },

            { path: "/admin/demo-clinic", name: "Demo Clinic", mini: "DC", component: DemoClinic },
            // { path: "/admin/migrate-clinic", name: "Clinic Migration", mini: "CM", component: MigrateClinic },
        ]
    },
    {
        collapse: true, path: "/manage-master", name: "Manage Master", state: "openManageMaster", icon: "pe-7s-box1", views: [

            {
                collapse: true, path: "/Emr-Master", name: "Emr", state: "openEmrMaster", icon: "pe-7s-news-paper", views: [
                    { path: "/admin/drug-list", name: "Drug List", mini: "DL", component: DrugList },
                    { path: "/admin/drugCategory-list", name: "Drug Category List", mini: "DCL", component: DrugCategoryList },
                    { path: "/admin/drugCompany-list", name: "Drug Company List", mini: "DCL", component: DrugCompanyList },
                    { path: "/admin/recommendation-list", name: "Recommendation List", mini: "RL", component: RecommendationList },
                    { path: "/admin/drug-instruction-list", name: "Drug Instruction List", mini: "DIL", component: DrugInstructionList },
                    { path: "/admin/symptom-category-list", name: "Complaint Categroy List", mini: "CCL", component: MasterSymptomCategoryList },
                    { path: "/admin/symptom-sub-category-list", name: "Complaint List", mini: "CL", component: MasterSymptomSubCategoryList },
                    { path: "/admin/diagnosis-category-list", name: "Diagnosis Categroy List", mini: "DCL", component: DiagnosisCategoryList },
                    { path: "/admin/diagnosis-sub-category-list", name: "Diagnosis List", mini: "DL", component: DiagnosisSubcategoryList },
                    { path: "/admin/lab-test-list", name: "Lab Test List", mini: "LTL", component: LabTestList },
                ]
            },
            { path: "/specialization-list", name: "Specialization", mini: "AL", component: SpecializationList },
            // { path: "/admin/faq-list", name: "FAQ", mini: "FL", component: FaqList},
            { path: "/admin/educator-list", name: "Educator Message", mini: "EL", component: EducatorList },
            { path: "/admin/knowledge-list", name: "Knowledge", mini: "KL", component: KnowledgeList },
            { path: "/admin/link-list", name: "My Clinic", mini: "MC", component: LinkList },
            { path: "/admin/plan-list", name: "Subscription", mini: "PL", component: PlanList },
            { path: "/admin/language-list", name: "Language", mini: "LL", component: LanguageList },
            { path: "/admin/update-termCondition", name: "Terms & Conditions", mini: "UT", component: UpdateTermcondition },
            { path: "/admin/clinic-document-upload-list", name: "Clinic Document Upload", mini: "CL", component: ClinicDocumentUploadList },
            { path: "/admin/clinic-document-list", name: "Library Document", mini: "CL", component: ClinicDocumentList },
            { path: "/admin/news-room", name: "News Room", mini: "NR", component: ClinicNews },
            // { path: "/admin/support-system-list", name: "Support System", mini: "SS", component: SupportSystemList },
            { path: "/support-list", name: "Support Ticket", mini: "SS", component: SupportList },

            { path: "/admin/notification-list", name: "Notification List", mini: "NL", component: NotificationList },
            { path: "/admin/advertisement-list", name: "Advertisement List", mini: "AL", component: AdvertisementList },
            { path: "/admin/icons-list", name: "Icons-List", mini: "IL", component: IconsList },
            { path: "/admin/star-status-list", name: "Star-Status-List", mini: "SSL", component: StarStatusList }


            // { path: "/admin/clinic-request", name: "Clinc Request List", mini: "UL", component: ClinicRequestList },
            //{ path: "/add-specialization", name: "Add Specialization", mini: "AS", component: AddSpecialization },
            //{ path: "/update-specialization", name: "Update Specialization", mini: "US", component: UpdateSpecialization },
            // { path: "/admin/add-knowledge", name: "Add Knowledge", mini: "AK", component: AddKnowledge },
            //{ path: "/admin/add-educator", name: "Add Educator", mini: "AE", component: AddEducator},
            //{ path: "/admin/update-faq", name: "Update Faq", mini: "UF", component: UpdateFaq},
            // { path: "/admin/add-plan", name: "Add Plan", mini: "KL", component: AddPlan },
            // { path: "/admin/add-link", name: "Add Link", mini: "Al", component: AddLink },
            // { path: "/admin/add-clinic-calendar", name: "Add Clinic Calendar", mini: "ACC", component: AddClinicCalendar },
            // { path: "/admin/patient-request", name: "Patient Request List", mini: "PR", component: PatientRequestList },
        ]
    },
    // { collapse: true, path: "/manage-user", name: "Manage User", state: "openManageUser", icon: "pe-7s-users", views:[
    //     { path: "/admin/user-list", name: "User List", mini: "UL", component: UserList },
    //     { path: "/admin/add-user", name: "Add User", mini: "AU", component: AddUser },
    //     ]
    // },

    // start diet
    // {
    //     collapse: true, path: "/diet", name: "Manage Diet Plan", state: "openDiet", icon: "pe-7s-users", views: [
    //         { path: "/admin/food-category-list", name: "Food Category", mini: "FC", component: FoodCategoryList },
    //         { path: "/admin/serving-size-list", name: "Serving Size", mini: "SS", component: ServingSizeList },
    //         { path: "/admin/region-list", name: "Region", mini: "RL", component: RegionList },
    //         { path: "/admin/attachment-list", name: "Attachment", mini: "AL", component: AttachmentList },
    //         { path: "/admin/food-list", name: "Food", mini: "FL", component: FoodList },
    //         { path: "/admin/comment-list", name: "Comment", mini: "CL", component: CommentList },
    //         { path: "/admin/low-calorie-food", name: "Low Calorie Food", mini: "LCFL", component: LowCalorieFoodList },
    //         { path: "/admin/treatment-type", name: "Treatment Type", mini: "TTL", component: TreatmentTypeList },
    //         { path: "/admin/treatment", name: "Treatment", mini: "TL", component: TreatmentList },
    //         //   { path: "/admin/instruction", name: "Instruction", mini: "IL", component: InstructionList },
    //         // { path: "/admin/add-user", name: "Serving Size Master", mini: "SS", component: AddUser },
    //     ]
    // },
    // {
    //     collapse: true, path: "/meal", name: "Creating a meal", state: "openMeal", icon: "pe-7s-users", views: [
    //         { path: "/admin/food_group_add_edit", name: "Create Meal", mini: "CM", component: CreateMeal },
    //         // { path: "/admin/food_group_edit", name: "Create Meal", mini: "CM", component: UpdateCreateMeal },
    //         { path: "/admin/ready-created-meal", name: "Ready Created Meal", mini: "RCM", component: ReadyCreatedMeal },
    //         { path: "/admin/food_group_list", name: "List Of Meals", mini: "LOM", component: FoodGroupList },

    //     ]
    // },

    {
        collapse: true, path: "/diet", name: "Manage Diet", state: "openDiet", icon: "pe-7s-users", views: [
            {
                collapse: true, path: "/diet1", name: "Food item details", state: "openDiet1", icon: "pe-7s-users", views: [
                    { path: "/admin/food-list", name: "Food", mini: "FL", component: FoodList },
                ]
            },
            {
                collapse: true, path: "/meal", name: "Creating a meal", state: "openMeal", icon: "pe-7s-users", views: [
                    { path: "/admin/food_group_add_edit", name: "Create Meal", mini: "CM", component: CreateMeal },
                    // { path: "/admin/food_group_edit", name: "Create Meal", mini: "CM", component: UpdateCreateMeal },
                    { path: "/admin/ready-created-meal", name: "Ready Created Meal", mini: "RCM", component: ReadyCreatedMeal },
                    { path: "/admin/food_group_list", name: "List Of Meals", mini: "LOM", component: FoodGroupList },
                    { path: "/admin/meal-analysis", name: "Meal Analysis", mini: "MA", component: MealAnalysis },
                ]
            },
            { path: "/admin/treatment-type", name: "Treatment Type", mini: "TTL", component: TreatmentTypeList }, // Diet Main Category
            { path: "/admin/treatment", name: "Treatment", mini: "TL", component: TreatmentList }, //Treatment regimen
            { path: "/admin/region-list", name: "Region", mini: "RL", component: RegionList },  //Region list

            { path: "/admin/comment-list", name: "Comment", mini: "CL", component: CommentList },  //Additional instructions to print
            { path: "/admin/low-calorie-food", name: "Low Calorie Food", mini: "LCFL", component: LowCalorieFoodList }, //Low calorie food
            { path: "/admin/serving-size-list", name: "Serving Size", mini: "SS", component: ServingSizeList }, //Serving size master
            { path: "/admin/food-category-list", name: "Food Category", mini: "FC", component: FoodCategoryList }, //Food category
            { path: "/admin/attachment-list", name: "Attachment", mini: "AL", component: AttachmentList },

        ]
    },




    // end diet

    {
        collapse: true, path: "/report", name: "Report", state: "openReport", icon: "pe-7s-users", views: [
            { path: "/admin/report-list", name: "Clinic Uses", mini: "CU", component: ReportList },
            { path: "/admin/clinic-active-list", name: "Clinic Active List", mini: "CAL", component: ClinicActiveList },
            { path: "/admin/clinic-subscription", name: "Clinic Subscription", mini: "CS", component: ClinicSubscription },
            // { path: "/admin/clinic-subscription-expired", name: "Clinic Subscription", mini: "CS", component: ClinicSubscription },
            { path: "/admin/clinic-uploads", name: "Data Upload Report", mini: "DUR", component: UploadData },

        ]
    },

    /*{ collapse: true, path: "/components", name: "Components", state: "openComponents", icon: "pe-7s-plugin", views:[
        { path: "/components/buttons", name: "Buttons", mini: "B", component: Buttons },
        { path: "/components/grid-system", name: "Grid System", mini: "GS", component: GridSystem },
        { path: "/components/panels", name: "Panels", mini: "P", component: Panels },
        { path: "/components/sweet-alert", name: "Sweet Alert", mini: "SA", component: SweetAlert },
        { path: "/components/notifications", name: "Notifications", mini: "N", component: Notifications },
        { path: "/components/icons", name: "Icons", mini: "I", component: Icons },
        { path: "/components/typography", name: "Typography", mini: "T", component: Typography }]
    },
    { collapse: true, path: "/forms", name: "Forms", state: "openForms", icon: "pe-7s-note2", views:
        [{ path: "/forms/regular-forms", name: "Regular Forms", mini: "RF", component: RegularForms },
        { path: "/forms/extended-forms", name: "Extended Forms", mini: "EF", component: ExtendedForms },
        { path: "/forms/validation-forms", name: "Validation Forms", mini: "VF", component: ValidationForms },
        { path: "/forms/wizard", name: "Wizard", mini: "W", component: Wizard }]
    },
    { collapse: true, path: "/tables", name: "Tables", state: "openTables", icon: "pe-7s-news-paper", views:
        [{ path: "/tables/regular-tables", name: "Regular Tables", mini: "RT", component: RegularTables },
        { path: "/tables/extended-tables", name: "Extended Tables", mini: "ET", component: ExtendedTables },
        { path: "/tables/data-tables", name: "Data Tables", mini: "DT", component: DataTables }]
    },
    { collapse: true, path: "/maps", name: "Maps", state: "openMaps", icon: "pe-7s-map-marker", views:
        [{ path: "/maps/google-maps", name: "Google Maps", mini: "GM", component: GoogleMaps },
        { path: "/maps/full-screen-maps", name: "Full Screen Map", mini: "FSM", component: FullScreenMap },
        { path: "/maps/vector-maps", name: "Vector Map", mini: "VM", component: VectorMap }]
    },
    { path: "/charts", name: "Charts", icon: "pe-7s-graph1", component: Charts },
    { path: "/calendar", name: "Calendar", icon: "pe-7s-date", component: Calendar },*/
    {
        collapse: true, path: "/pages", name: "Pages", state: "openPages", icon: "pe-7s-gift", views:
            pages
    },
    { redirect: true, path: "/", pathTo: "/admin/dashboard", name: "Dashboard" }
];
export default dashRoutes;

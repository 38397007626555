/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import Pages from "Admin/containers/Pages/Pages.jsx";
import Dash from "Admin/containers/Dash/Dash.jsx";

var appRoutes = [
  { path: "/login", name: "Pages", component: Pages },
  { path: "/pages/register-page", name: "Pages", component: Pages },
  { path: "/pages/lock-screen-page", name: "Pages", component: Pages },
  { path: "/", name: "Home", component: Dash },
];

export default appRoutes;

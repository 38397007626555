/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
//import * as PF from "Front/views/Home/PublicFunction.jsx"
import history from "../../routes/history";
//import Chat from './Chat';
//import PatientRegistration from 'Front/views/Home/PatientRegistrationPopup.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LinkContainer } from 'react-router-bootstrap';
import { getChatCountAction } from 'Front/actions/master';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//import { appConstants } from 'Front/_constants/app.constants.js';
class HeaderLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chatModal: false,
      open_our_products: false,
      showInternetSpeed: false
    }
    this.onDismiss = this.onDismiss.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.openChatModel = this.openChatModel.bind(this);
    this.hideChatModel = this.hideChatModel.bind(this);
    this.open_our_products = this.open_our_products.bind(this);
    this.close_our_products = this.close_our_products.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
  }

  open_our_products() {
    this.setState({ open_our_products: true });
  }
  close_our_products() {
    this.setState({ open_our_products: false });
  }

  componentDidMount() {
    // this.props.getChatCountAction();
    let _this = this
    setInterval(() => {
      _this.setState({ showInternetSpeed: true })
    }, 60 * 1000)

    setInterval(() => {
      _this.setState({ showInternetSpeed: false })
    }, 65 * 1000)

  }


  componentWillReceiveProps(nextProps) {

    if (nextProps.isGetChatCount !== this.props.isGetChatCount) {
      let chatCounts = nextProps.GetChatCount.chatCounts;
      let totalCount = 0;
      let AllChatCount = 0;
      let forwardedChatCount = 0;
      let byEducatorChatCount = 0;
      for (let i in chatCounts) {
        if (chatCounts[i]._id === 'chat') {
          totalCount = totalCount + chatCounts[i].count;
          AllChatCount = chatCounts[i].count;
        }
        else if (chatCounts[i]._id === 'forwarded') {
          totalCount = totalCount + chatCounts[i].count;
          forwardedChatCount = chatCounts[i].count;
        }
        else if (chatCounts[i]._id === 'byEducator') {
          totalCount = totalCount + chatCounts[i].count;
          byEducatorChatCount = chatCounts[i].count;
        }
      }
      localStorage.setItem('AllChatCount', AllChatCount)
      localStorage.setItem('forwardedChatCount', forwardedChatCount)
      localStorage.setItem('byEducatorChatCount', byEducatorChatCount)
      //console.log(totalCount, "totalCount")
      localStorage.setItem('AllCount', totalCount)
    }
  }


  Logout() {
    localStorage.removeItem('_id')
    localStorage.removeItem('userName')
    localStorage.removeItem('userType')
    localStorage.removeItem('email')
    localStorage.removeItem('status')
    localStorage.removeItem('token')
    localStorage.removeItem('degree')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    localStorage.clear();
    history.push({ pathname: "/login" });
  }

  // componentDidMount() {
  //   this.props.getChatCountAction();
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isGetChatCount !== this.props.isGetChatCount) {
      let chatCounts = nextProps.GetChatCount.chatCounts;
      let totalCount = 0;
      let AllChatCount = 0;
      let forwardedChatCount = 0;
      let byEducatorChatCount = 0;
      for (let i in chatCounts) {
        if (chatCounts[i]._id === "chat") {
          totalCount = totalCount + chatCounts[i].count;
          AllChatCount = chatCounts[i].count;
        } else if (chatCounts[i]._id === "forwarded") {
          totalCount = totalCount + chatCounts[i].count;
          forwardedChatCount = chatCounts[i].count;
        } else if (chatCounts[i]._id === "byEducator") {
          totalCount = totalCount + chatCounts[i].count;
          byEducatorChatCount = chatCounts[i].count;
        }
      }
      localStorage.setItem("AllChatCount", AllChatCount);
      localStorage.setItem("forwardedChatCount", forwardedChatCount);
      localStorage.setItem("byEducatorChatCount", byEducatorChatCount);
      localStorage.setItem("AllCount", totalCount);
    }
  }

  Logout() {
    localStorage.removeItem("_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("email");
    localStorage.removeItem("status");
    localStorage.removeItem("token");
    localStorage.removeItem("degree");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    history.push({ pathname: "/" });
    window.location.reload(true);
  }

  navigateTo(page) {
    history.push({ pathname: page });
    this.mobileSidebarToggle();
  }

  navigateToOnly(page) {
    history.push({ pathname: page });
  }

  refreshPage(e) {
    e.preventDefault();

    window.location.reload(true);
  }

  onDismiss() {
    this.setState({ patientModal: false });
    this.successAlert("Patient successfully registered");
  }

  successAlert(msg) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Success"
          //onConfirm={() => this.hideAlert()}
          //onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {msg}
        </SweetAlert>
      ),
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  openChatModel() {
    this.setState({ chatModal: true });

    this.props.chatModel(true);
  }

  hideChatModel() {
    this.setState({ chatModal: false });

    this.props.chatModel(false);
  }

  mobileSidebarToggle() {
    document.documentElement.classList.toggle("nav-open");
  }

  render() {


    return (
      <div className=" menu-f menuout-home">
        {this.state.alert}
        <Nav pullRight>
          {/* <NavDropdown
            onMouseEnter={this.open_our_products}
            onMouseLeave={this.close_our_products}
            open={this.state.open_our_products}
            eventKey={2}
            onClick={this.navigateToOnly.bind(this, 'our_product')}
            title={(
              <div>
                Our Products
                <p className="hidden-md hidden-lg">

                  <b className="caret"></b>
                </p>
              </div>
            )} noCaret id="basic-nav-dropdown-1">
            <MenuItem onClick={this.navigateTo.bind(this, 'practiceaid')} eventKey={2.1}>Practice Aid</MenuItem>
            <MenuItem onClick={this.navigateTo.bind(this, 'Aahar')} eventKey={2.2}>Aahar</MenuItem>
            <MenuItem onClick={this.navigateTo.bind(this, 'Medzing')} eventKey={2.2}>Medzing</MenuItem>
          </NavDropdown> */}
          {/* <LinkContainer to="/clinic-registration">
            <NavItem eventKey={3} onClick={() => this.mobileSidebarToggle()}>
              <span>Register</span>
            </NavItem>
          </LinkContainer> */}

          <LinkContainer to="/about">
            <NavItem eventKey={3} onClick={() => this.mobileSidebarToggle()}>
              <span>About Us</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/contact">
            <NavItem eventKey={4} onClick={() => this.mobileSidebarToggle()}>
              <span>Contact</span>
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/login">
            <NavItem eventKey={4} onClick={() => this.mobileSidebarToggle()}>
              <span>Login</span>
            </NavItem>
          </LinkContainer>
        </Nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isGetChatCount: state.master.isGetChatCount,
    isGetChatCountError: state.master.isGetChatCountError,
    GetChatCount: state.master.GetChatCount,
  };
}
export default withRouter(
  connect(mapStateToProps, { getChatCountAction })(HeaderLinks)
);

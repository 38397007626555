/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button, Form, FormGroup, ControlLabel, FormControl, InputGroup } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import { dietListAction } from 'Front/actions/diet';
import { unitListAction, timeListAction, fileListAction } from 'Front/actions/master';
import { printDietAction, printDietColorChangeAction } from 'Front/actions/diet.js';
import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import CalGraph from 'Front/views/CalGraph/CalGraph';
import 'Front/views/DietChart/style.css';
import { appConstants } from 'Front/_constants/app.constants.js';
import PdfComponent from "../../components/common/commonPdf.js"
import { addCommentAction } from 'Front/actions/comment';
let fileData = [];

class DietChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            patient: {},
            calorie: null,
            selectCalorie: null,
            calorieType: null,
            treatmentList: [],
            treatmentType: null,
            dietOption: this.props.state.mealType,
            mealType: this.props.state.mealType,
            dietLanguage: this.props.state.dietLanguage,
            treatmentId: "",
            treatmentName: "",
            dietList: null,
            dietListApi: false,
            options: {},
            timeList: this.props.timeList ? this.props.timeList : "",
            timeWithPercent: [],
            suggestions: [],
            startTimeDelay: 0,
            printHeader: true,
            clinicName: '',
            clinicAddress: '',
            clinicEmail: '',
            clinicPhone: '',
            mealTime: "",
            color: '',
            dietId: '',
            newColor: '',
            fileList: [],
            commentsList: [],
            commentsArr: [],
            commentsIds: [],
            graphModal: false,
            dietChartModal: false,
            filesList: [],
            lastDietId: "",
            languageList: this.props.languageList,
            customComment: "",
            selectedLanguage: "1",
            formData: {
                text: {},
                type: "",

            },
            commentEror: false,
            comments: [],

        }
        this.dietChart = this.dietChart.bind(this);
        this.backPage = this.backPage.bind(this);
    }

    setDietTimePercent(timeListOld) {
        let dietTimePercent = {};
        for (let x in timeListOld.plan) {
            if (timeListOld.plan[x].treatmentId === this.state.treatmentId) {
                dietTimePercent[timeListOld.plan[x].dietTimeId] = timeListOld.plan[x];
            }
        }

        let timeWithPercent = []
        for (let x in timeListOld.data) {
            let row = timeListOld.data[x];
            row.percent = 0;
            row.calorie = 0;
            if (dietTimePercent[row._id]) {
                row.percent = dietTimePercent[row._id].percent;
                row.calorie = this.state.selectCalorie * row.percent / 100;
            }
            timeWithPercent.push(row);
        }
        this.setState({ timeWithPercent });
    }

    componentDidMount() {

        this.setState(this.props.state);
        let tempState = this.props.state
        setTimeout(() => {
            if (tempState.comments) {
                let cIds = [];
                for (let i in tempState.comments) {
                    cIds.push(tempState.comments[i].comment_id);
                }
                this.setState({ commentsIds: cIds });
            }
        }, 500)

        if (!this.state.timeList) {
            this.props.timeListAction();
        }
        else {
            this.setDietTimePercent(this.state.timeList.data);
        }
        this.props.fileListAction();

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.timeList) {
            this.setState({ timeList: nextProps.timeList, comments: nextProps.timeList.data.comments });
            this.setDietTimePercent(nextProps.timeList.data);
        }

        if (nextProps.isFileList !== this.props.isFileList) {
            this.setState({
                fileList: nextProps.fileList.data
            });
        }

        if (nextProps.isAddComment) {

            const _this = this;
            let timeList = this.state.timeList;
            if (this.state.timeList.data.comments.find(x => x._id === nextProps.AddComment.comment._id) === undefined) {
                let obj = {
                    "_id": nextProps.AddComment.comment._id,
                    "updatedAt": nextProps.AddComment.comment.updatedAt,
                    "createdAt": nextProps.AddComment.comment.createdAt,
                    "text": nextProps.AddComment.comment.text,
                    "clinicId": nextProps.AddComment.comment.clinicId,
                    "__v": nextProps.AddComment.comment.__v,
                    "addedBy": nextProps.AddComment.comment.addedBy,
                    "deleted": nextProps.AddComment.comment.deleted,
                    "status": nextProps.AddComment.comment.status,
                    "type": nextProps.AddComment.comment.type,
                    "auto_select": nextProps.AddComment.comment.auto_select
                }
                timeList['data']['comments'].push(obj)

                let field = {
                    text: {},
                    type: "",
                };

                this.handleChangeDiet(obj, "")
                _this.setState({ formData: field, customComment: "", timeList });
            }

        }

        if (nextProps.state) {
            this.setState({ lastDietId: (nextProps.state.patientId ? nextProps.state.patientId : nextProps.state.patient.lastDiet[0]._id) });
        }
    }

    handleChangeDiet(data, e) {

        let commentsIds = this.state.commentsIds;
        if (e) {
            if (e.target.checked) {
                commentsIds.push(data._id);
            } else {
                commentsIds = commentsIds.filter(e => e !== data._id);
            }
        } else {
            commentsIds.push(data._id);
        }


        this.setState({ commentsIds: commentsIds });

        data.auto_select = e ? e.target.checked : true;

        let commentsList = [];
        let commentsArr = [];
        let req = {};
        for (let x in this.state.timeList.data.comments) {
            let item = this.state.timeList.data.comments[x];
            if (commentsIds.includes(item._id)) {

                commentsList.push(item);

                //  if(this.state.timeList.data.comments[x]._id === data._id){
                req = {
                    1: item.text['1'],
                    2: item.text['2'],
                    "comment_id": item._id,
                }

                //comments = req;
                //console.log(this.state.timeList.data.comments[x].auto_select)
                //  }

                commentsArr.push(req);

            }
        }

        // console.log('****', commentsArr)

        this.setState({ commentsList });
        this.setState({ commentsArr });
        //this.props.onClickFun(comments);
        this.props.onClickFun(commentsArr);
        this.props.nextPageChanges({ selectedComments: commentsList });
    }

    handleChangeFile(data, indx) {

        let req = {
            "file": data.file[0],
            "file_id": data._id,
            "name": data.name
        }

        var localindex_index = this.state.fileList.files.map(function (el) {
            return el._id;
        }).indexOf(data._id);

        if (localindex_index !== -1) {

            var filelists = this.state.fileList;

            if (this.state.fileList.files[localindex_index].auto_select) {


                filelists.files[localindex_index].auto_select = false;
                this.setState({ filelist: filelists });

                if (fileData.length) {
                    for (var i = 0; i < fileData.length; i++) {
                        if (fileData[i].file_id === data._id) {
                            fileData.splice(i, 1);
                            break;
                        }
                    }
                }

            } else {

                fileData.push(req);
                filelists.files[localindex_index].auto_select = true;
                this.setState({ filelist: filelists });
            }

            this.state.filesList.push(data);
            this.setState(this.state);
            this.props.onClickFile(fileData);
        }

    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    graph() {
        //let pId = this.state.patient.pId;
        this.setState({ graphModal: true });
        // setTimeout(function(){
        //     let params = {
        //         clinicId:localStorage.getItem('clinicId'),
        //         visit_id:pId,
        //     }
        //     appConstants.socket.emit('updateTaskScreen', params);
        // },2000);
    }

    handelChangeLang(e, name) {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
        this.props.nextPageChanges(data);
        // return data;
    }

    handleChange(e) {
        e.preventDefault();
        const _this = this;
        _this.setState({ selectedLanguage: e.target.value });
        let field = this.state.formData;
        field.text = {}
        _this.setState({ formData: field });

    }

    hadleCustomComment(e) {
        e.preventDefault();
        const _this = this;
        _this.setState({ customComment: e.target.value });
        e.target.value === "" ? _this.setState({ commentEror: true }) : _this.setState({ commentEror: false })
    }

    allValidate() {
        const _this = this;
        if (this.state.customComment.trim() === "") {
            _this.setState({ commentEror: true });
            return false;
        } else {
            _this.setState({ commentEror: false });
            return true;
        }
    }


    addComment(evt) {
        evt.preventDefault();
        const _this = this;
        if (this.allValidate()) {
            let field = this.state.formData;
            field['type'] = "diabetic";
            field.text[this.state.selectedLanguage] = this.state.customComment.trim();
            this.setState({ formData: field });
            _this.props.addCommentAction(field);

            //_this.props.timeListAction();
        }
        //validation.errors()
    }

    backPage() {
        // this.setState({ dietChatModal: true });
        this.setState({ commentModal: true });
        this.setState({ graphModal: false });

    }

    dietChart() {
        this.props.dietChart();
        this.setState({ dietChartModal: true });
    }


    render() {
        return (
            <div>
                {!this.state.graphModal && !this.state.dietChartModal &&

                    <Grid fluid >
                        <Card content={
                            <div>
                                <Col sm={12} className="lisulahar">
                                    <div>
                                        <h4 style={{ fontWeight: '600' }}>Additional Commentss</h4>
                                        <ul className='taskscreen_aditional-comments'>

                                            {
                                                this.state.timeList && this.state.timeList.data && this.state.timeList.data.comments.map((key, i) => {
                                                    if (key.type.toLowerCase() === this.props.state.treatmentType.toLowerCase()) {
                                                        let data;
                                                        if (key.text != undefined) {
                                                            var lang = key.text['1'];
                                                            let checked = false;
                                                            let flag = true
                                                            if (this.state.commentsIds.includes(key._id)) {
                                                                checked = true;
                                                            }

                                                            // key.text[this.state.dietLanguage] !== undefined ? lang = key.text[this.state.dietLanguage] :
                                                            //     key.text['1'] !== undefined ? lang = key.text['1'] : flag = false
                                                            key.text['1'] !== undefined ? lang = key.text['1'] : flag = false

                                                            // if (this.state.dietLanguage === "1") {
                                                            //     lang = key.text['1']
                                                            // }
                                                            // if (this.state.dietLanguage === "3") {
                                                            //     lang = key.text['3']
                                                            // }
                                                            // if (this.state.dietLanguage === "4") {
                                                            //     lang = key.text['4']
                                                            // }
                                                            flag ? data = (
                                                                <li key={key._id}>
                                                                    <Checkbox
                                                                        number={key._id}
                                                                        label={lang}
                                                                        checked={checked}
                                                                        onClick={e => { this.handleChangeDiet(key, e); }}
                                                                    />
                                                                </li>
                                                            ) : data = ""
                                                            return data;

                                                        }

                                                    } else { return ''; }
                                                })
                                            }

                                        </ul>
                                    </div>
                                    <div>
                                        <div>
                                            <h4 style={{ fontWeight: '600' }}>Additional Files</h4>
                                        </div>
                                        <ul className='taskscreen_aditional-comments'>

                                            {
                                                this.state.fileList && this.state.fileList.files && this.state.fileList.files.map((key, i) => {
                                                    let data;
                                                    let checked = false;
                                                    this.props.state.file.filter((val) => {
                                                        if (val.file_id === key._id) {
                                                            checked = true;
                                                            key['auto_select'] = true;
                                                        }
                                                        return '';
                                                    });
                                                    if (key.auto_select) {
                                                        checked = true;
                                                    }
                                                    data = (
                                                        <li key={key._id}>
                                                            <div className="relat">
                                                                <Checkbox
                                                                    number={key._id}
                                                                    label={key.name}
                                                                    checked={checked}
                                                                    onClick={e => { this.handleChangeFile(key, i); }}
                                                                />
                                                                {/* <span className="pdf-ri">
                                                                    <a href={appConstants.s3UploadUrl + key.file} target="_blank" download><i className="fa fa-file-pdf-o"></i></a>
                                                                </span> */}
                                                                <PdfComponent
                                                                    data={key.file[0]}
                                                                    type="pdf"
                                                                />
                                                            </div>
                                                        </li>

                                                    )
                                                    return data;
                                                })
                                            }


                                            {/*<div className="row top-mdirt">
                                                    <div className="form-group">
                                                        <div className="col-md-5">
                                                            <label> Diet Option</label></div>
                                                        <div className="col-md-7">
                                                            <select
                                                                className="form-control"
                                                                onChange={e => { this.handelChangeLang(e, 'dietOption') }}
                                                                name='dietOption'
                                                                value={this.state.dietOption}
                                                            >
                                                                <option value="3">3 Option</option>
                                                                <option value="2" selected>2 Option</option>
                                                                <option value="1">1 Option</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row top-mdirt">
                                                    <div className="form-group">
                                                        <div className="col-md-5">
                                                            <label> Meal Type</label></div>
                                                        <div className="col-md-7">
                                                            <select
                                                                className="form-control"
                                                                onChange={e => { this.handelChangeLang(e, 'mealType') }}
                                                                name='mealType'
                                                                value={this.state.mealType}
                                                            >
                                                                <option value="veg-s">Vegetarian</option>
                                                                <option value="non-veg-s">Non Vegetarian</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row top-mdirt">
                                                    <div className="form-group">
                                                        <div className="col-md-5">
                                                            <label> Diet Language </label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <select
                                                                className="form-control"
                                                                onChange={e => { this.handelChangeLang(e, 'dietLanguage') }}
                                                                name='dietLanguage'
                                                                value={this.state.dietLanguage}
                                                            >
                                                                <option value="hindi">Hindi</option>
                                                                <option value="english">English</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </ul>
                                        <Row className="next-Arrow">
                                            <div className="text-right">
                                                <Button onClick={this.props.backPage.bind(this)} >Save</Button>{'  '}
                                            </div>
                                            {/*<Col md={3} >
                                                <Button onClick={e => { this.graph() }} disabled={this.state.treatmentId ? false : true} className="btn-fill btn btn-primary">Next</Button>
                                            </Col> */}
                                        </Row>
                                    </div>
                                </Col>
                                <Col sm={12}>


                                    <Col sm={6}>

                                        <Row className='add-custom-comment'>
                                            <FormGroup className='clearfix'>
                                                <Col componentClass={ControlLabel} md={3} className="comment-form-label" >
                                                    Add Comment <span className="star">*</span>
                                                </Col>
                                                <Col md={9}>
                                                    <FormControl className="comment-field" rows="4" placeholder='Add Comment' componentClass="textarea" name="comment" bsClass="form-control unmandt-f" value={this.state.customComment} onChange={(e) => { this.hadleCustomComment(e) }} autoComplete="off" />
                                                    {this.state.commentEror &&
                                                        <span className="errorMsg">
                                                            This Field is required</span>
                                                    }
                                                </Col>
                                            </FormGroup>
                                            {/* <FormGroup className='clearfix'>
                                                <Col componentClass={ControlLabel} md={3} className="comment-form-label">
                                                    Select Language
                                                </Col>
                                                <Col sm={9}>
                                                    <select className="form-control" value={this.state.selectedLanguage} name="language" onChange={(e) => { this.handleChange(e) }} placeholder="Seelct Language" >
                                                        {this.state.languageList.map(function (lang) {
                                                            return <option value={lang.value} >{lang.label}</option>
                                                        })}


                                                    </select>
                                                </Col>
                                            </FormGroup> */}
                                            <FormGroup style={{ display: "none" }} className='clearfix'>
                                                <Col sm={6}>
                                                    <select className="form-control" name="type" placeholder="Type" >
                                                        <option value="">Select Type</option>
                                                        <option value="diabetic">Diabetic</option>
                                                        <option value="non-diabetic">Non Diabetic</option>

                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className='clearfix'>
                                                <Col componentClass={ControlLabel} sm={3} className="comment-form-label"></Col>
                                                <Col sm={9}>
                                                    <button type="button" onClick={this.addComment.bind(this)} className="btn-fill btn-wd btn btn-primary pull-right">Add</button>&nbsp;
                                                </Col>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Col>
                            </div>
                        } />
                    </Grid>
                }
                {this.state.graphModal && !this.state.dietChartModal &&
                    <CalGraph state={this.state} dietChart={this.dietChart} backPage={this.backPage} dietId={this.state.dietId} color={this.state.color} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dietList: state.diet.dietList,
        unitList: state.master.unitList,
        timeList: state.master.timeList,

        printDiet: state.diet.printDiet,
        isDietPrint: state.diet.isDietPrint,
        isDietPrintError: state.diet.isDietPrintError,

        isDietPrintColor: state.diet.isDietPrintColor,
        isDietPrintColorError: state.diet.isDietPrintColorError,
        printDietColor: state.diet.printDietColor,

        isFileList: state.master.isFileList,
        isFileListError: state.master.isFileListError,
        fileList: state.master.fileList,

        isAddComment: state.comment.isAddComment,
        isAddCommentError: state.comment.isAddCommentError,
        AddComment: state.comment.AddComment,
    }
}
export default withRouter(connect(mapStateToProps, { dietListAction, unitListAction, fileListAction, timeListAction, printDietAction, printDietColorChangeAction, addCommentAction })(DietChart));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';

import { clinicListAction, ChangeDemoAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import ClinicHeader from '../ClinicHeader/ClinicHeader';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    demoId: 'required',
    clinicId: 'required',
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class ChangeDemo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clinicList: [],
            demoList: [],
            cardHidden: true,
            isLoading: false,
            formArr: [],
            formData: {

                clinicId: '',
                clinicName: '',
                demoId: "",
            },
            isLogin: true,
            showProcessing: false,
        }
    }

    componentDidMount() {
        let data = this.state.formData;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        this.props.ClinicSimpleListAction({ isPatientCount: true });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList && this.props.isClinicSimpleList === false) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }

        if (nextProps.isChangeDemo !== this.props.isChangeDemo && this.props.isChangeDemo === false) {

            this.props.handleClick('success', 'Successfully clinic id changed');

            this.props.history.push(`/admin/clinic-list`);
        }


        if (nextProps.isChangeDemoError !== this.props.isisChangeDemoError) {
            if (nextProps.isChangeDemo.errors) {

                nextProps.isChangeDemo.errors.map((key, i) => {
                    this.setState({ msgError: key.msg })
                });
            }
        }
    }

    changeDemoNumber(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        if (this.allValidate(false)) {

            this.props.ChangeDemoAction(this.state.formData);
        }
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    handleClinicChange(event) {

        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinicId'] = event.target.value
        formData['clinicName'] = event.target[index].text
        this.setState({ formData: formData });
        let list = this.state.clinicList;

        let lastClinicId = list[0].clinicId;
        let ClinicIdList = [];

        for (let j in list) {

            ClinicIdList.push(list[j].clinicId);
            if (lastClinicId < list[j].clinicId) {
                lastClinicId = list[j].clinicId;
            }
        }

        let demoList = [];
        let id = 113;
        while (id < 1000) {
            if (!ClinicIdList.includes(id)) {
                demoList.push(id);
            }
            id = id + 100;
        }
        this.setState({ demoList: demoList });

    }

    handleChange(e) {
        e.preventDefault();

        let formData = this.state.formData;
        formData[e.target.name] = e.target.value;
        this.setState({ formData: formData });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>

                            <Card
                                title={
                                    <legend className="line-removes">

                                        <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>

                                    </legend>
                                }
                                content={
                                    <Form horizontal>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Clinic
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl componentClass="select" name="clinicId" value={this.state.formData.clinicId} onChange={e => { this.handleChange(e); this.handleClinicChange(e); }}>
                                                    <option value="">Select clinic</option>
                                                    {this.state.clinicList.map(function (item) {
                                                        if (item.patient == 0) {
                                                            return <option key={item._id} value={item._id}>{item.name}({item.clinicId})</option>
                                                        }
                                                    })}
                                                </FormControl>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Demo Id
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="demoId" value={this.state.value} onChange={(event) => this.handleChange(event)}>
                                                    <option value="">Select Demo Id</option>
                                                    {this.state.demoList.map(function (item) {
                                                        return <option key={item} value={item}>{item}</option>
                                                    })}
                                                </select>

                                            </Col>
                                        </FormGroup>


                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.changeDemoNumber.bind(this)}
                                                    className="btn-fill btn-wd btn btn-info"
                                                    disabled={isLoading || this.props.disabled}
                                                >
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Submit
                                                </button> &nbsp;
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ChangeDemo: state.clinic.ChangeDemo,
        isChangeDemo: state.clinic.isChangeDemo,
        isChangeDemoError: state.clinic.isChangeDemoError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError
    }
}
export default withRouter(connect(mapStateToProps, { ChangeDemoAction, clinicListAction, ClinicSimpleListAction })(ChangeDemo));

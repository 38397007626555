/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import axios from "axios";
import { appConstants } from "Front/_constants/app.constants.js";
import history from "../routes/history";

var CancelToken = axios.CancelToken;
let wj_cancel, wjc_cancel;

export const request = (data, action, method) => {
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL + action,
    headers: {
      "Content-Type": "application/json",
      apiKey: "Sys@1d90vKC",
    },
    data: data,
  });
  apiCall.catch((error, data) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};

export const requestWithToken = (data, action, method) => {
  let sessionToken = localStorage.getItem("token");
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL + action,
    headers: {
      Authorization: sessionToken,
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
      apiKey: "Sys@1d90vKC",
    },
    data: data,
  });
  apiCall.catch((error, data) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};
export const requestWithTokenFile = (data, action, method) => {
  let sessionToken = localStorage.getItem("token");
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL + action,
    headers: {
      Authorization: sessionToken,
      "Content-Type": "multipart/form-data",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
      apiKey: "Sys@1d90vKC",
    },
    data: data,
  });
  apiCall.catch((error, data) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};

export const requestWithCancelToken = (data, action, method) => {
  let sessionToken = localStorage.getItem("token");
  wj_cancel && wj_cancel();
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL + action,
    headers: {
      Authorization: sessionToken,
      "Content-Type": "application/json",
      apiKey: "Sys@1d90vKC",
    },
    data: data,
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      wj_cancel = c;
      // console.log("wj_cancel", wj_cancel);
    }),
  });

  apiCall.catch((error, data) => {
    if (error?.response?.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};

export const testWithToken = (data, action, method) => {
  let sessionToken = localStorage.getItem("token");
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL,
    headers: {
      Authorization: sessionToken,
      "Content-Type": "application/json",
    },
    data: data,
  });
  apiCall.catch((error, data) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};

export const requestWithTokenExtraParams = (data, action, method, config) => {
  let sessionToken = localStorage.getItem("token");
  const apiCall = axios({
    method: method,
    url: appConstants.paAppURL + action,
    headers: {
      Authorization: sessionToken,
      "Content-Type": "application/json",
      apiKey: "Sys@1d90vKC",
    },
    data: data,
    ...config,
  });

  apiCall.catch((error, data) => {
    if (error.response.status === 401) {
      window.location.href = "/login";
    }
  });
  return apiCall;
};

export const getRequest = (data, action) => request(data, action, "GET");
export const postRequest = (data, action) => request(data, action, "POST");
export const patchRequest = (data, action) => request(data, action, "PATCH");
export const deleteRequest = (data, action) => request(data, action, "DELETE");
export const putRequest = (data, action) => request(data, action, "PUT");

export const getRequestWithToken = (data, action) =>
  requestWithToken(data, action, "GET");
export const postRequestWithToken = (data, action) =>
  requestWithToken(data, action, "POST");
export const patchRequestWithToken = (data, action) =>
  requestWithToken(data, action, "PATCH");
export const deleteRequestWithToken = (data, action) =>
  requestWithToken(data, action, "DELETE");
export const putRequestWithToken = (data, action) =>
  requestWithToken(data, action, "PUT");

export const postTestWithToken = (data, action) =>
  testWithToken(data, action, "POST");

export const postRequestWithCancelToken = (data, action) =>
  requestWithCancelToken(data, action, "POST");

export const postRequestWithTokenExtraParams = (data, action, config) =>
  requestWithTokenExtraParams(data, action, "POST", config);

export const postRequestWithTokenFile = (data, action) =>
  requestWithTokenFile(data, action, "POST");

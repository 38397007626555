/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import * as API from "Front/api/home";
import { postRequestWithToken } from "../../api/helper.js"
import AdImg from "../../assets/img/ad-img.svg";
import AdImg01 from "../../assets/img/ad-slide-01.jpg";
import AdImg02 from "../../assets/img/ad-slide-03.jpg";
import AdImg03 from "../../assets/img/ad-slide-02.jpg";
import AdImg04 from "../../assets/img/ad-slide-04.jpg";

const AdSlider = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const [imagePathArr, setImagePathArr] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    setDirection(e.direction);
  };

  const getImageList = async () => {
    let resp = await API.advertismentList()
    if (resp.data.status === "Success" && resp.data.statusCode == 200) {
      if (resp.data.data) {
        let imgArr = []
        resp.data.data.map(async (img, key) => {
          if (img.imgPath) {
            let resp = await postRequestWithToken({ filePath: img.imgPath }, 's3SignedUrl');
            if (resp.data.statusCode == 200)
              imgArr.push(resp.data.url)
          }
        })
        setImagePathArr(imgArr)
      }
    }
  }



  useEffect(() => {
    if (isDataLoaded) {
      setIsPageLoaded(true)
    }

  }, [isDataLoaded])

  useEffect(() => {
    getImageList()
    setTimeout(() => {
      setIsDataLoaded(true)
    }, 2000)
  }, []);

  return (

    <div className="ad-carousel">
      <div className="ad-carousel-item">
        {isPageLoaded && <Carousel
        // slide={true}
        // activeIndex={index}
        // direction={direction}
        // onSelect={handleSelect}

        >{imagePathArr && imagePathArr.length > 0 ?
          imagePathArr.map((path, key) => {
            return (<Carousel.Item interval={300} >
              <div className="slider-img">

                <img alt="ad banner" key={key} src={path} />
              </div>

            </Carousel.Item>)
          }) : <Carousel.Item >
            <div className="slider-img">
              <img alt="ad banner" src={AdImg04} />
            </div>
          </Carousel.Item>
          }
        </Carousel>
        }
      </div>
    </div>
  );
};

export default AdSlider;

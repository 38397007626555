/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";

import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { addClinicDocumentUploadAction } from "Admin/actions/clinic_document_upload";
import * as API from "Admin/api/clinic_document_upload";
import Loading from "react-loading";
require("datatables.net-responsive");
$.DataTable = require("datatables.net-bs");
const AddClinicDocumentUploadData = (props) => {

  const [formData, setFormData] = useState({});
  const [clinic, setClinic] = useState([]);
  const [clinicIds, setClinicIds] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [errors, setErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [documentFiles, setDocumentFile] = useState(false);
  const [documentFilesHindi, setDocumentFileHindi] = useState(false);

  const inputHandler = (e) => {

    let saveFormData = formData;
    let imageExt = [];
    if (e.target.type === "file" && e.target.name === "documentFile") {
      let errArr = errors;
      setDocumentFile(true);
      for (let i = 0; i < e.target.files.length; i++) {
        imageExt[i] = isImage(e.target.files[i].name);
      }
      if (imageExt.includes(false)) {
        errArr.documentFile = "error";
      } else {
        errArr.documentFile = "";
      }
      setErrors({ ...errArr });
      saveFormData[e.target.name] = e.target.files;
    } else if (
      e.target.type === "file" &&
      e.target.name === "documentFileHindi"
    ) {
      let errArr = errors;
      setDocumentFileHindi(true);
      for (let i = 0; i < e.target.files.length; i++) {
        imageExt[i] = isImage(e.target.files[i].name);
      }
      if (imageExt.includes(false)) {
        errArr.documentFileHindi = "error";
      } else {
        errArr.documentFileHindi = "";
      }
      //setErrors({ ...errArr });
      saveFormData[e.target.name] = e.target.files;

    } else {
      saveFormData[e.target.name] = e.target.value;
    }
    setFormData({ ...formData, ...saveFormData });

  };
  const goBackFun = (e) => {
    props.history.replace(sessionStorage.getItem("prvUrl"));
  };
  const validate = () => {
    let post = formData;
    let errArr = errors;
    // if (
    //   typeof post.documentFile !== "undefined" &&
    //   post.documentFile.length > 0
    // ) {
    //   errArr.documentFile = "";
    // } else {
    //   errArr.documentFile = "error";
    // }
    // if (
    //   typeof post.documentFileHindi !== "undefined" &&
    //   post.documentFileHindi.length > 0
    // ) {
    //   errArr.documentFileHindi = "";
    // } else {
    //   errArr.documentFileHindi = "error";
    // }
    if (typeof post.name !== "undefined" && post.name !== "") {
      errArr.name = "";
    } else {
      errArr.name = "error";
    }
    if (typeof post.name_hindi !== "undefined" && post.name_hindi !== "") {
      errArr.name_hindi = "";
    } else {
      errArr.name_hindi = "error";
    }
    if (typeof post.description !== "undefined" && post.description !== "") {
      errArr.description = "";
    } else {
      errArr.description = "error";
    }
    if (typeof post.link !== "undefined" && post.link !== "") {
      errArr.link = "";
    } else {
      errArr.link = "error";
    }
    if (typeof post.link_hindi !== "undefined" && post.link_hindi !== "") {
      errArr.link_hindi = "";
    } else {
      errArr.link_hindi = "error";
    }
    if (typeof post.order !== "undefined" && post.order !== "") {
      errArr.order = "";
    } else {
      errArr.order = "error";
    }

    if (
      typeof post.description_hindi !== "undefined" &&
      post.description_hindi !== ""
    ) {
      errArr.description_hindi = "";
    } else {
      errArr.description_hindi = "error";
    }
    if (typeof post.clinic !== "undefined" && post.clinic !== "") {
      errArr.clinic = "";
    } else {
      errArr.clinic = "error";
    }
    setErrors({ ...errArr });
  };

  const submitHandler = async (event) => {
    validate();
    const form = event.currentTarget;
    setIsLoading(true);
    if (form.checkValidity() === false) {
      setIsLoading(false);
      event.preventDefault();
      setValidated(true);
    } else {
      event.preventDefault();
      let form = new FormData();
      form.append("title", formData.name);
      form.append("title_hindi", formData.name_hindi);
      form.append("link", formData.link);
      form.append("link_hindi", formData.link_hindi);
      form.append("description", formData.description);
      form.append("description_hindi", formData.description_hindi);
      form.append("order", formData.order);
      form.append("showType", "file");
      for (const i in clinicIds) {
        form.append("clinicId", clinicIds[i]);
      }
      if (documentFiles) {
        for (let i = 0; i < formData.documentFile.length; i++) {
          form.append(
            "image",
            formData.documentFile[i],
            formData.documentFile[i].name
          );
        }
      }
      if (documentFilesHindi) {
        for (let i = 0; i < formData.documentFileHindi.length; i++) {
          form.append(
            "image_hindi",
            formData.documentFileHindi[i],
            formData.documentFileHindi[i].name
          );
        }
      }
      let resp = await API.addClinicDocumentUpload(form);
      if (resp) {
        setIsLoading(false);
        setSuccessMsg("Data Added successfully.");
        setFormData({});
        setTimeout(function () {
          setSuccessMsg("");
        }, 3000);
      } else {
        setIsLoading(false);
        setSuccessMsg("Something went wrong.");
        setTimeout(function () {
          setSuccessMsg("");
        }, 3000);
      }
      setIsLoading(false);
    }
  };

  const isImage = (icon) => {
    const ext = [
      "jpg",
      "png",
      "jpeg",
      "mp4",
      "avi",
      "flv",
      "wmv",
      "pdf",
      "xlsx",
      "xls",
      "csv",
      "docx",
      "doc",
    ];
    return ext.some((el) => icon.endsWith(el));
  };
  const handleClinic = (value) => {
    let multipleSelect = clinic;
    multipleSelect = value;
    setClinic(multipleSelect);

    let data = multipleSelect;
    const tempClinic = [...new Set(data && data.map((item) => item.value))];
    setClinicIds(tempClinic);
  };
  const clinicListAction = async (page) => {
    let resp = await API.clinicList(page);
    let data = resp.data.data;

    const tempList = [...new Set(data && data.map((item) => item))].map(
      function (item) {
        return {
          label: item.name,
          value: item._id,
        };
      }
    );
    setClinicList(tempList);
  };
  //const orderList = async (page) => {};

  useEffect(() => {
    clinicListAction();
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      goBackFun(e);
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form
                  horizontal
                  noValidate
                  validated={validated}
                  onSubmit={(e) => submitHandler(e)}
                  className="add-clinic_document_upload_form"
                >
                  <fieldset>
                    <FormGroup>
                      <Col md={6}>
                        <Col componentClass={ControlLabel}>Clinic</Col>
                        <Select
                          placeholder="Select clinic"
                          name="clinic"
                          id="clinic"
                          closeOnSelect={false}
                          multi={true}
                          value={clinic}
                          options={clinicList}
                          //className={"form-control " + (errors.clinic || "")}
                          onChange={(e) => {
                            handleClinic(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Title of Document</ControlLabel>
                            <FormControl
                              type="text"
                              name="name"
                              id="title"
                              placeholder="title"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={"form-control " + (errors.name || "")}
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>
                              Title of Document (Hindi)
                            </ControlLabel>
                            <FormControl
                              type="text"
                              name="name_hindi"
                              id="title"
                              placeholder="title"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={
                                "form-control " + (errors.name_hindi || "")
                              }
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document upload</ControlLabel>
                            <FormControl
                              type="file"
                              //multiple
                              //key={formData.documentFile || ""}
                              name="documentFile"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              placeholder="File"
                              className={
                                "form-control " + (errors.documentFile || "")
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document upload (Hindi)</ControlLabel>
                            <FormControl
                              type="file"
                              //multiple
                              //key={formData.documentFile || ""}
                              name="documentFileHindi"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              placeholder="File"
                              className={
                                "form-control " +
                                (errors.documentFileHindi || "")
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Link</ControlLabel>
                            <FormControl
                              type="text"
                              name="link"
                              id="link"
                              placeholder="Link"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={"form-control " + (errors.link || "")}
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Link (Hindi)</ControlLabel>
                            <FormControl
                              type="text"
                              name="link_hindi"
                              id="link"
                              placeholder="Link"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={
                                "form-control " + (errors.link_hindi || "")
                              }
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col md={6}>
                        <Col componentClass={ControlLabel}>Order</Col>
                        <FormControl
                          type="number"
                          name="order"
                          id="order"
                          placeholder="order"
                          autoComplete="off"
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          className={"form-control " + (errors.order || "")}
                          min="1"
                          required
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col md={6}>
                        <Col componentClass={ControlLabel}>Show Type</Col>
                        <FormControl
                          componentClass="select"
                          name="showtype"
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                        >
                          <option value="file">File</option>
                        </FormControl>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Description</ControlLabel>
                            <FormControl
                              type="text"
                              name="description"
                              id="description"
                              placeholder="Description"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={
                                "form-control " + (errors.description || "")
                              }
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>

                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Description (Hindi)</ControlLabel>
                            <FormControl
                              type="text"
                              name="description_hindi"
                              id="description"
                              placeholder="Description"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={
                                "form-control " +
                                (errors.description_hindi || "")
                              }
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <Button
                              type="submit"
                              className="btn-fill btn btn-primary"
                              disabled={isLoading}
                            >
                              {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                              Save
                            </Button>
                            <Col md={12}>
                              {successMsg !== "" && (
                                <p
                                  className="alert-heading"
                                  style={{ color: "#155724" }}
                                >
                                  {successMsg}
                                </p>
                              )}
                              {isLoading ? (
                                <Loading
                                  type="bars"
                                  color="#000000"
                                  style={{ margin: "0px auto", width: "40px" }}
                                />
                              ) : (
                                ""
                              )}
                            </Col>
                            &nbsp;
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    msg: state.clinicDocumentUpload.message,
    AddClinicDocumentUpload:
      state.clinicDocumentUpload.isAddClinicDocumentUpload,
    isAddClinicDocumentUpload:
      state.clinicDocumentUpload.isAddClinicDocumentUpload,
    isAddClinicDocumentUploadError:
      state.clinicDocumentUpload.isAddClinicDocumentUploadError,
  };
}
export default withRouter(
  connect(mapStateToProps, { addClinicDocumentUploadAction })(
    AddClinicDocumentUploadData
  )
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import banner_img from 'Front/assets/img/banner_1.png';
import aahar1 from 'Front/assets/img/aahar1.png';
import aahar2 from 'Front/assets/img/aahar2.png';
import Touch_img from 'Front/assets/img/touch_background.png';
import touch_icon from 'Front/assets/img/touch_icon.png';
import aahar from 'Front/assets/img/aahar.jpg';
import aahar_icon from 'Front/assets/img/aahar_icon.png';
import medzingbg from 'Front/assets/img/medzing-bg.jpg';
import medzing_icon from 'Front/assets/img/medzing_icon.png';
import Card from 'Front/components/Card/Card.jsx';


import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';

class Aahar extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (
      <Media>
        <div className="product-ban">
          <div class="banner_content">
            <h2>Digitizing Healthcare</h2>
            <p>Cutting-edge Software Products for Healthcare Industry</p>
          </div>
        </div>

        <Grid>
          <div className="about-us">
            <h2>Our Products</h2>
          </div>

          <div className="PracticeAid-pera">
            <p>The Healthcare software solutions by ProductAid accelerate innovation in cutting-edge healthcare technology projects. Our customized Healthcare IT solutions  leverage    the latest technology to enhance wellness and improve patient care.
            </p>
          </div>


          <div className="healthcare">
            <h4>Healthcare IT Services:</h4>
          </div>


          <Col className="sm-w it_service">
            <div className="boxhealthcare">
              <div className="inner-boxhealthcare">
                <p>Business Analysis</p>
              </div>
            </div>
          </Col>

          <Col className="sm-w it_service">
            <div className="boxhealthcare">
              <div className="inner-boxhealthcare">
                <p>Architecture and System Design</p>
              </div>
            </div>
          </Col>

          <Col className="sm-w it_service">
            <div className="boxhealthcare">
              <div className="inner-boxhealthcare">
                <p>System Implementation /Customization</p>
              </div>
            </div>
          </Col>

          <Col className="sm-w it_service">
            <div className="boxhealthcare">
              <div className="inner-boxhealthcare">
                <p>Deployment and Integration</p>
              </div>
            </div>
          </Col>

          <Col className="sm-w it_service">
            <div className="boxhealthcare">
              <div className="inner-boxhealthcare">
                <p>Maintenance</p>
              </div>
            </div>
          </Col>

          <div className="about-us">
            <h2>Product Offerings:</h2>
          </div>


          <div className="full-box">
            <Row>
              <Col lg={4} md={4}>
                <a href="/practiceaid">
                  <div className="imghover">
                    <img width="100%" height="100%" src={Touch_img} alt="our Product" />
                    <div className="icon-hover">
                      <img src={touch_icon} />
                    </div>
                  </div></a>
              </Col>

              <Col lg={8} md={8}>
                <div className="box_content">
                  <h4>PracticeAid</h4>
                  <p>Mobile Application that connects doctors and patients</p>
                  <div className="btnmore">
                    <a href="/practiceaid">Read More </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>



          <div className="full-box">
            <Row>
              <Col lg={4} md={4}>
                <a href="/Aahar">
                  <div className="imghover">
                    <div className="imgoverlay">
                      <img width="100%" height="100%" src={aahar} alt="our Product" />
                    </div>
                    <div className="icon-hover">
                      <img src={aahar_icon} />
                    </div>
                  </div>
                </a>
              </Col>

              <Col lg={8} md={8}>
                <div className="box_content">
                  <h4>Aahar</h4>
                  <p>Diet Planning for Diabetes</p>
                  <div className="btnmore">
                    <a href="/Aahar">Read More </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="full-box">
            <Row>
              <Col lg={4} md={4}>
                <a href="/Medzing">
                  <div className="imghover">
                    <div className="imgoverlay">
                      <img width="100%" height="100%" src={medzingbg} alt="our Product" />
                    </div>
                    <div className="icon-hover">
                      <img src={medzing_icon} />
                    </div>
                  </div>
                </a>
              </Col>

              <Col lg={8} md={8}>
                <div className="box_content">
                  <h4>Medzing</h4>
                  <p>An Integrated Clinic Management System</p>
                  <div className="btnmore">
                    <a href="/Medzing">Read More </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="middle-spc"></div>
        </Grid>
      </Media>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(Aahar));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col, FormGroup, ControlLabel, Button, Panel, Nav, NavItem, Tab } from 'react-bootstrap';
import Radio from 'Front/elements/CustomRadio/CustomRadio.jsx';
import Card from 'Front/components/Card/Card.jsx';
import { getCalorieType } from 'Front/views/Home/PublicFunction.jsx';
import { treatmentWithTypesAction } from 'Front/actions/master';
import DietChat from 'Front/views/DietChart/DietChart';
import Comments from 'Front/views/Comments/Comments';
import { appConstants } from 'Front/_constants/app.constants.js';
import CalorieInfo from 'Front/views/TaskScreen/CalorieInfo.jsx';
import * as PF from "Front/views/Home/PublicFunction.jsx"
import { sharetDietAction, printDietColorChangeAction } from 'Front/actions/diet.js';
import SweetAlert from 'react-bootstrap-sweetalert';

class Diet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            patient: this.props.patient,
            calorie: null,
            selectCalorie: 800,
            calorieType: "Maintain",
            treatmentList: [],
            treatmentType: "diabetic",
            dietOption: 2,
            mealType: 'veg-s',
            dietLanguage: "hindi",
            treatmentId: "",
            treatmentName: "",
            selectedComments: [],
            dietChatModal: false,
            commentModal: false,
            diabeticTab: true,
            shareType: "",
            alert: null,
            dietId: '',
            color: '',
        }
        this.calorieOptions = [];
        this.backPage = this.backPage.bind(this);
        this.treatmentTabChange = this.treatmentTabChange.bind(this);
        this.shareDiet = this.shareDiet.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.successAlert = this.successAlert.bind(this);
        this.dietChart = this.dietChart.bind(this);
        this.nextPageChanges = this.nextPageChanges.bind(this);


    }





    nextPageChanges(data) {
        this.setState(data);
    }

    // handelChange(e,name){
    //     let data = {};
    //     data[name] = e.target.value;
    //     this.setState(data);
    // }



    componentDidMount() {
        // this.setTitle();
        this.setCalorieOptions();
        this.calorie();

        if (this.props.treatmentWithTypeList) {
            this.setState({
                treatmentList: this.props.treatmentWithTypeList.data
            });
        }
        else {
            this.props.treatmentWithTypesAction();
        }

        if (this.props.patient) {
            if (this.props.patient.lastDiet && this.props.patient.lastDiet['1'] && this.props.patient.lastDiet['1'].addedByType === 'doctor') {
                let lastDiet = this.props.patient.lastDiet['1'];
                let setState = {
                    patient: this.props.patient,
                    treatmentType: lastDiet.treatmentType,
                    dietOption: lastDiet.optionCount,
                    mealType: lastDiet.dietType,
                    dietLanguage: lastDiet.dietLanguage,
                    treatmentId: lastDiet.treatmentId,
                    treatmentName: lastDiet.treatmentName,
                    selectCalorie: lastDiet.calorie,
                };
                this.setState(setState);

                if (lastDiet.treatmentType === 'non-diabetic') {
                    this.setState({ diabeticTab: false });
                } else {
                    this.setState({ diabeticTab: true });
                }

                this.setState({ dietId: lastDiet._id, color: lastDiet.color });

            } else if (this.props.patient.lastDiet && this.props.patient.lastDiet['0']) {
                let lastDiet = this.props.patient.lastDiet['0'];
                let setState = {
                    patient: this.props.patient,
                    treatmentType: lastDiet.treatmentType,
                    dietOption: lastDiet.optionCount,
                    mealType: lastDiet.dietType,
                    dietLanguage: lastDiet.dietLanguage,
                    treatmentId: lastDiet.treatmentId,
                    treatmentName: lastDiet.treatmentName,
                    selectCalorie: lastDiet.calorie,
                };
                this.setState(setState);

                if (lastDiet.treatmentType === 'non-diabetic') {
                    this.setState({ diabeticTab: false });
                } else {
                    this.setState({ diabeticTab: true });
                }

                this.setState({ dietId: lastDiet._id, color: lastDiet.color });
            }

            let patient = this.state.patient;
            patient.bmi = PF.getBMI(patient.height, patient.weight);
            patient.bmi = PF.getBMI(patient.height, patient.weight);
            patient.ibw = PF.getIBW(patient.height, patient.weight, patient.gender);
            patient.c1 = PF.getMaintainWeight(patient.height, patient.weight, patient.gender);
            patient.c2 = PF.getLooseWeight(patient.height, patient.weight, patient.gender);
            patient.c3 = PF.getGainWeight(patient.height, patient.weight, patient.gender);
            this.setState({ patient });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.treatmentWithTypeList) {
            this.setState({
                treatmentList: nextProps.treatmentWithTypeList.data
            });
        }

        if (nextProps.isDietShare && this.state.shareType) {
            let msg = "";
            if (this.state.shareType === "email") {
                msg = "Diet shared on email successfully.";
            }
            else {
                msg = "Diet shared on app successfully.";
            }

            this.successAlert(msg);
            this.setState({ shareType: "" });
        }
    }

    setCalorieOptions() {
        for (let x = 800; x <= 3000; x += 200) {
            this.calorieOptions.push(x);
        }
    }

    calorie() {
        let calorieType = getCalorieType(this.state.patient.height, this.state.patient.weight, this.state.patient.gender);


        if (calorieType.calorie) {
            let selectCalorie = Math.round(calorieType.calorie / 200) * 200;

            this.setState({
                selectCalorie,
                calorie: calorieType.calorie,
                calorieType: calorieType.type
            });
        }

    }

    handelChange(e, name) {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
    }

    handelChangeTeatment(id, name, treatmentType) {
        let treatmentId = id;
        let treatmentName = name;

        this.setState({
            treatmentId: treatmentId,
            treatmentName: treatmentName,
            treatmentType: treatmentType
        });

        setTimeout(function () {
            document.getElementById("Diet-" + id).checked = true;
        }, 200);
    }

    comments() {
        let pId = this.state.patient.pId;
        this.setState({ commentModal: true });
        setTimeout(function () {
            let params = {
                clinicId: localStorage.getItem('clinicId'),
                visit_id: pId,
            }
            appConstants.socket.emit('updateTaskScreen', params);
        }, 2000);
    }

    backPage() {
        this.setState({ dietChatModal: false });
        this.setState({ commentModal: false });
    }


    dietChart() {
        let pId = this.state.patient;


        let deitId = "";
        if (this.state.patient && this.state.patient.lastDiet && this.state.patient.lastDiet[0] && this.state.patient.lastDiet[0]._id) {
            deitId = this.state.patient && this.state.patient.lastDiet && this.state.patient.lastDiet[0]._id;
        }
        this.setState({ dietChatModal: true });


        let formData = {
            newColor: 'blue',
            dietId: deitId,
            patient: pId
        }

        if (this.state.color === 'red' || this.state.color === '') {
            this.props.printDietColorChangeAction(formData);
        }

        setTimeout(function () {
            let params = {
                clinicId: localStorage.getItem('clinicId'),
                visit_id: pId,
            }
            appConstants.socket.emit('updateTaskScreen', params);
        }, 2000);

    }


    shareDiet(type) {
        let sendData = {
            patientId: this.state.patient._id,
            patientFirstName: this.state.patient.firstName,
            treatmentId: this.state.treatmentId,
            treatmentName: this.state.treatmentName,
            treatmentType: this.state.treatmentType,
            calorie: this.state.selectCalorie,
            dietType: this.state.mealType,
            optionCount: this.state.dietOption,
            dietLanguage: this.state.dietLanguage,
            startTimeDelay: 0,
            shareType: type,
            email: this.state.patient.email
        };

        this.props.sharetDietAction(sendData);

        this.setState({ shareType: type });
    }

    successAlert(msg) {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", "z-index": 500 }}
                    title="Success"
                    //onConfirm={() => this.hideAlert()}
                    //onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    {msg}
                </SweetAlert>
            )
        });

        let _this = this;
        setTimeout(function () {
            _this.hideAlert();
        }, 3000);
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    treatmentTabChange(type) {
        if (type === "diabetic") {
            this.setState({ diabeticTab: true });
        }
        else {
            this.setState({ diabeticTab: false });
        }
    }

    render() {

        let diabeticTabActive = "diabetic";
        if (!this.state.diabeticTab) {
            diabeticTabActive = "non-diabetic";
        }




        return (
            <div className="main-content" style={{ padding: '0px 0px' }}>
                {this.state.alert}
                {!this.state.dietChatModal && !this.state.commentModal &&
                    <Grid fluid>
                        <Card
                            // title={this.state.title}
                            content={
                                <Row>
                                    <Col sm={12}>
                                        <FormGroup>
                                            <Row>
                                                <Col componentClass={ControlLabel} sm={1} className="m0b15">
                                                    <CalorieInfo pvDetail={this.state.patient} />
                                                </Col>

                                                <Col sm={2} className="m0b15">
                                                    <select
                                                        className="form-control"
                                                        onChange={e => { this.handelChange(e, 'selectCalorie') }}
                                                        name='selectCalorie'
                                                        value={this.state.selectCalorie}
                                                    >
                                                        {this.calorieOptions.map(value =>
                                                            <option value={value} key={value}>{value}</option>
                                                        )}
                                                    </select>
                                                </Col>

                                                <Col sm={2} className="m0b15" style={{ backgroundColor: "yellow" }}>
                                                    <b>
                                                        {this.state.calorie} Cal. <br></br>

                                                        {this.state.calorieType} Wt.
                                                    </b>
                                                </Col>

                                                <div className="col-md-4 diet-screen m0b15">
                                                    <Tab.Container id="tabs-with-dropdown" activeKey={diabeticTabActive}>
                                                        <Panel header={
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Nav bsStyle="tabs">
                                                                        <NavItem eventKey="diabetic" onClick={e => { this.treatmentTabChange('diabetic') }}>
                                                                            Diabetic Diet
                                                                            {!this.state.diabeticTab &&
                                                                                <i class="fa fa-plus"></i>
                                                                            }
                                                                            {this.state.diabeticTab &&
                                                                                <i class="fa fa-minus"></i>
                                                                            }

                                                                        </NavItem>
                                                                        <NavItem eventKey="non-diabetic" onClick={e => { this.treatmentTabChange('non-diabetic') }}>
                                                                            Non Diabetic Diet
                                                                            {this.state.diabeticTab &&
                                                                                <i class="fa fa-plus"></i>
                                                                            }
                                                                            {!this.state.diabeticTab &&
                                                                                <i class="fa fa-minus"></i>
                                                                            }

                                                                        </NavItem>
                                                                    </Nav>
                                                                </Col>
                                                            </Row>
                                                        } eventKey="3" />
                                                    </Tab.Container>
                                                </div>

                                                <Col sm={3}>
                                                    <Button className="btn-fill btn btn-warning pull-right" onClick={() => this.setState({ treatmentId: '', treatmentName: '' })}>Cancel Diet</Button>
                                                </Col>


                                            </Row>

                                            {/* <Row className="spc-charts"> */}

                                            <Row>
                                                <div className="sections-dietss">
                                                    <div style={{ display: (this.state.diabeticTab) ? "block" : "none" }}>
                                                        {this.state.treatmentList.map(value =>
                                                            <div key={value.name}>
                                                                {value.type === "diabetic" &&
                                                                    <Col sm={3} className="padding-5 m-bR">
                                                                        <legend>{value.name}</legend>
                                                                        {value.treatments.map(treatment =>
                                                                            <Radio
                                                                                number={"Diet-" + treatment._id}
                                                                                label={treatment.name}
                                                                                key={treatment._id}
                                                                                name="treatmentId"
                                                                                option={treatment._id}
                                                                                onClick={e => { this.handelChangeTeatment(treatment._id, treatment.name, value.type) }}
                                                                                checked={treatment._id === this.state.treatmentId}
                                                                                disabled={treatment._id !== this.state.treatmentId && (localStorage.getItem("educatorDietStatus") === "no") ? true : false}
                                                                            />
                                                                        )}
                                                                    </Col>
                                                                }
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div style={{ display: (this.state.diabeticTab) ? "none" : "block" }}>
                                                        {this.state.treatmentList.map(value =>
                                                            <div key={value.name}>
                                                                {value.type === "non-diabetic" &&
                                                                    <Col sm={3} className="padding-5 m-bR">
                                                                        <legend>{value.name}</legend>
                                                                        {value.treatments.map(treatment =>
                                                                            <div>
                                                                                <Radio
                                                                                    number={"Diet-" + treatment._id}
                                                                                    key={treatment._id}
                                                                                    label={treatment.name}
                                                                                    name="treatmentId"
                                                                                    option={treatment._id}
                                                                                    onClick={e => { this.handelChangeTeatment(treatment._id, treatment.name, value.type) }}
                                                                                    checked={treatment._id === this.state.treatmentId}
                                                                                    disabled={treatment._id !== this.state.treatmentId && (localStorage.getItem("educatorDietStatus") === "no") ? true : false}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row>
                                                <Col md={4} className="no-padding">
                                                    <Col md={4} style={{ fontWeight: "bold" }} className="no-padding text-right padding-top-10 t-left">Diet Option</Col>
                                                    <Col md={8}>
                                                        <select
                                                            className="form-control"
                                                            onChange={e => { this.handelChange(e, 'dietOption') }}
                                                            name='dietOption'
                                                            value={this.state.dietOption}
                                                        >
                                                            <option value="3">3 Option</option>
                                                            <option value="2" selected>2 Option</option>
                                                            <option value="1">1 Option</option>
                                                        </select>
                                                    </Col>
                                                </Col>

                                                <Col md={4} className="no-padding">
                                                    <Col md={4} className="no-padding text-right padding-top-10 t-left" style={{ fontWeight: "bold" }}>Meal Type</Col>
                                                    <Col md={8}>
                                                        <select
                                                            className="form-control"
                                                            onChange={e => { this.handelChange(e, 'mealType') }}
                                                            name='mealType'
                                                            value={this.state.mealType}
                                                        >
                                                            <option value="veg-s">Vegetarian</option>
                                                            <option value="non-veg-s">Non Vegetarian</option>
                                                        </select>
                                                    </Col>
                                                </Col>

                                                <Col md={4} className="no-padding">
                                                    <Col md={4} style={{ fontWeight: "bold" }} className="no-padding text-right padding-top-10 t-left">Diet Language</Col>
                                                    <Col md={8}>
                                                        <select
                                                            className="form-control"
                                                            onChange={e => { this.handelChange(e, 'dietLanguage') }}
                                                            name='dietLanguage'
                                                            value={this.state.dietLanguage}
                                                        >
                                                            <option value="hindi">Hindi</option>
                                                            <option value="english">English</option>
                                                        </select>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="diet-chart-btn-div">
                                                <Col md={4} className="no-padding">
                                                    <Button onClick={e => { this.dietChart() }} disabled={this.state.treatmentId ? false : true} className="btn-fill btn btn-primary">Diet chart</Button>
                                                </Col>
                                                <Col md={4} className="no-padding">
                                                    {this.state.patient.app &&
                                                        <Button onClick={e => { this.shareDiet('app') }} disabled={this.state.treatmentId ? false : true} className="btn-fill btn btn-primary">Share on App</Button>
                                                    }
                                                    &nbsp;&nbsp;&nbsp;
                                                    {this.state.patient.email && (localStorage.getItem("is_email_facility") === "yes") &&
                                                        <Button onClick={e => { this.shareDiet('email') }} disabled={this.state.treatmentId ? false : true} className="btn-fill btn btn-primary">Share on Email</Button>
                                                    }
                                                </Col>

                                                <Col md={4} className="no-padding">
                                                    <Button onClick={e => { this.comments() }} disabled={this.state.treatmentId ? false : true} className="btn-fill btn btn-primary">Next</Button>
                                                </Col>
                                                {/* <Col md={3} className="no-padding">
                                                <Button onClick={e=>{this.dietChart()}} disabled={this.state.treatmentId?false:true} className="btn-fill btn btn-primary pull-right">Diet chart</Button>
                                            </Col> */}
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        />
                    </Grid>
                }
                {this.state.dietChatModal &&
                    <DietChat state={this.state} backPage={this.backPage} dietId={this.state.dietId} color={this.state.color} />
                }

                {this.state.commentModal &&
                    <Comments state={this.state} dietChart={this.dietChart} backPage={this.backPage} dietId={this.state.dietId} color={this.state.color} nextPageChanges={this.nextPageChanges} />
                }


                {/* DietChat model */}
                {/* <Modal className="pa-task-screen" show={this.state.dietChatModal} onHide={() => this.setState({ dietChatModal: false  })} dialogClassName="modal-lg">

                     <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">{this.state.dietTitle}</Modal.Title>
                     </Modal.Header>

                     <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <DietChat state={this.state}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal> */}
                {/* /DietChat model */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        treatmentWithTypeList: state.master.treatmentWithTypeList,
        isDietShare: state.diet.isDietShare,
    }
}
export default withRouter(connect(mapStateToProps, { treatmentWithTypesAction, sharetDietAction, printDietColorChangeAction })(Diet));

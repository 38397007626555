/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, Link } from "react-router-dom";
import * as API from 'Admin/api/attachment';
import Card from 'Admin/components/Card/Card.jsx';
import { Grid, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { appConstants } from 'Admin/_constants/app.constants.js';
import Common from 'Admin/components/Common/Common';
import ViewBox from 'Admin/components/Common/ViewBox';
import Switch from 'react-bootstrap-switch';
import { confirmAlert } from 'react-confirm-alert';
const ClinicNewRoomPage = (props) => {
  const [newsRoomList, setNewsRoomList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortData, setSortData] = useState(false);
  const [ViewBoxData, setViewBoxData] = useState({ show: false, type: "image", path: "" });
  const [ViewBoxDataHindi, setViewBoxDataHindi] = useState({ show: false, type: "image", path: "" });
  const [formData, setFormData] = useState({
    direction: 'desc',
    order: "createdAt",
    offset: 0,
    limit: 10
  });

  useEffect(() => {
    const newsRoomListAction = async (page) => {
      let resp = await API.newsRoomList();
      setNewsRoomList(resp.data.data.data);
      setTotalCount(resp.data.data.count);
      setIsLoading(false)
    }
    newsRoomListAction();
  }, []);


  const onSortChange = async (e) => {

  }

  const onPageChange = async (page, sizePerPage) => {
    let data = {};
    if (searchText === "") {
      data['direction'] = formData.direction;
      data['order'] = formData.order;
      data['offset'] = (page === -1) ? formData.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = formData.direction;
      data['order'] = formData.order;
      data['offset'] = (page === -1) ? formData.offset : page - 1;
      data['limit'] = sizePerPage;
    }
    setSizePerPage(sizePerPage);
    setCurrentPage(page);
    let resp = await API.newsRoomList(data);
    setNewsRoomList(resp.data.data.data);
    setTotalCount(resp.data.data.count);
    
    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  }

  const onSearchChange = async (text) => {
    let data = {}
      data['direction'] = formData.direction;
      data['order'] = formData.order;
      data['offset'] = formData.offset;
      data['limit'] = formData.limit;
    if (text !== "") {
      if (text.length >= 1) {
        
        data['search'] = text;
        setSizePerPage(sizePerPage);
        setCurrentPage(1);
        let resp = await API.newsRoomList(data);
        setNewsRoomList(resp?.data?.data?.data);
        setTotalCount(resp?.data?.data?.count);
      }
    }
    setSearchText(text);
        let resp = await API.newsRoomList(data);
        setNewsRoomList(resp?.data?.data?.data);
        setTotalCount(resp?.data?.data?.count);
  }

  const sortHandle = async() => { 
    let data = formData;
    data['direction'] =  sortData? "desc": "asc";
    setFormData({ ...formData, ...data });
    setSortData(!sortData )
    let resp = await API.newsRoomList(data);
    setNewsRoomList(resp.data.data.data);
    setTotalCount(resp.data.data.count);
  }

  const onUserCount = (cell, row, enumObject, rowIndex) => {
   
     return (currentPage - 1) * (sizePerPage) + (rowIndex + 1);
  }
// 


  const _setTableOption = () => {
    if (isLoading) {
      return (<Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />);
    } else {
      return <div className="no-data"><h5>No data found!</h5></div>
    }
  }

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return (<span><Link to={{ pathname: `/admin/edit-news-room/`, state: row }}>  Edit </Link><span style={{ marginLeft: "5px" }}></span><span className='cursor-pointer-link' variant="outline-light" onClick={e => getConfirmFun(row._id)}> delete</span></span>)
  }

  const taskView = (cell, row, enumObject, rowIndex) => {
    return (<span><span className='cursor-pointer-link' onClick={e => viewDocument(row)} title={row.title ? row.title : row.titles ? row.titles[1] : ""}> View  English / </span><span className='cursor-pointer-link' onClick={e => viewDocumentHindi(row)} title={row.title ? row.title : row.titles ? row.titles[2] : ""}> View Hindi </span></span>)
  }

  const actionviewShare = (cell, row, enumObject, rowIndex) => {
    return (<span>
      <Switch defaultValue={(row.status === 'active') ? true : false} onChange={e => SharedRowAction(row._id, row.status)} />{(row && row.newsActiveDate && row.status === 'active') ? " " + Common.formatDate(row.newsActiveDate) : ''} </span>)
  }

  const getConfirmFun = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleterRowAction(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }

  const SharedRowAction = async (id, status) => {

    let sendData = {
      "newsId": id,
      "status": (status === 'active') ? "inactive" : "active"
    }

    let resp = await API.sharedNewsRoom(sendData);

    if (resp) {
      props.handleClick('success', (sendData.status === 'active') ? "Shared successfully" : "Unshared successfully")
      let resp = await API.newsRoomList();
      setTotalCount(resp.data.data.count);
      setNewsRoomList(resp.data.data.data);
    }
  }



  const options = {
    onSortChange: onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    sizePerPage: sizePerPage,
    onPageChange: onPageChange,
    page: currentPage,
    // onSearchChange: onSearchChange,
    noDataText: _setTableOption(),
  };


  const deleterRowAction = async (newsId) => {
    let form = new FormData();
    form.append('newsId', newsId)
    let resp = await API.deleteNews(form);
    if (resp) {
      props.handleClick('success', 'Deleted successfully.')
      setNewsRoomList(newsRoomList.filter(item => item._id !== newsId));
    }
  };

  const onCreateDate = (cell, row, enumObject, rowIndex) => {
    return ((row && row.createdAt) ? Common.formatDate(row.createdAt) : '');
  }

  const viewDocument = (row) => {
    let fileExtension = row.fileExtension ? row.fileExtension : row.files ? row.files[0].fileType : "image";
    let filePath = row.filePath ? row.filePath : row.files ? row.files[0].fileName : "";
    setViewBoxData({ show: true, type: fileExtension, title: "Document", path: filePath });
  }

  const viewDocumentHindi = (row) => {
    let fileExtension = row.fileExtension ? row.fileExtension : row.files ? row.files[1].fileType : "image";
    let filePath = row.filePath ? row.filePath : row.files ? row.files[1].fileName : "";
    setViewBoxData({ show: true, type: fileExtension, title: "Document", path: filePath });
  }

  const hideViewBox = () => {
    setViewBoxData({ show: false, type: "image", path: "" });
  };

  const titleInEnglish = (cell, row, enumObject, rowIndex) => {

    let html = "";
    if (row?.title)
      html = <span>{row?.title}</span>
    else if (row?.titles) {
      html = <span>{row?.titles[1]}</span>
    }

    return html;
  }

  // const titleInHindi=(cell, row, enumObject, rowIndex) => {
  //   console.log(row,"Row")
  //   let html="";
  //     if(row.title)
  //      html=<span>{row.title}</span>
  //      else if(row.titles)
  //      {
  //       html=<span>{row.titles[1]}</span>
  //      }

  //  return html; 
  // }


  const getFileExtension = (cell, row, enumObject, rowIndex) => {

    let html = "";
    if (row?.fileExtension)
      html = <span>{row?.fileExtension}</span>
    else if (row?.files) {
      html = <span>{row.files[0]?.fileType}</span>
    }

    return html;
  }



  return (
    <div className="main-content">
      {ViewBoxData && ViewBoxData.show &&
        <ViewBox data={ViewBoxData} hideViewBox={e => hideViewBox()} />
      }
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card content={
              <div className="fresh-datatables">
                <Row>
                  <Col md={6}>
                    <ButtonToolbar>
                      <Button className="inacti-button" onClick={() => props.history.replace('/admin/add-news-room')}>Add News Room</Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
                <hr></hr>

                <div className="resp-t table-resposive educator-L news_room">
                    <div className='search-box-admin'>
                      <div className='search'>
                        <input type="text" name="search" className="form-control" onChange={e => { onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={searchText} placeholder="Search" />
                          <span className='input-group-btn'>
                          <Button className='search-clear' onClick={(e)=> onSearchChange(e.target.value, "null") }>Clear</Button>
                          </span>
                        </div>
                    </div>
                  <BootstrapTable
                    deleteRow={false}
                    data={newsRoomList}
                     multiColumnSearch={true}
                    pagination={true} options={options}
                    striped hover condensed
                    scrollTop={'Bottom'}
                    remote={true}
                    sort={true}

                    fetchInfo={{ dataTotalSize: totalCount }}>
                    <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '3%', textAlign: 'center' }} tdStyle={{ width: '3%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'News Id' }} dataFormat={onUserCount}>#</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Date' }} dataFormat={onCreateDate} >Date</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '12%', textAlign: 'center' }} tdStyle={{ width: '12%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'category' }} dataField='category' >News Category
                    {
                      !sortData?
                      <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                      <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                    }
                    </TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'News Title' }} dataField='titles' dataFormat={titleInEnglish}>News Title</TableHeaderColumn>
                    {/* <TableHeaderColumn thStyle={{ width: '10%', textAlign:'center' }} tdStyle={{ width: '10%', textAlign:'center' }} tdAttr={{ 'data-attr': 'News Title' }} dataField='titles' dataFormat={titleInHindi}>News Title In Hindi</TableHeaderColumn> */}
                    <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'News Type (text/Image/PDF/video)' }} dataFormat={getFileExtension} dataField='fileExtension' >News Type (text/Image/PDF/video)</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '11%', textAlign: 'center' }} tdStyle={{ width: '11%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataFormat={actionviewShare} >Share</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='' dataFormat={taskView}  >Task View</TableHeaderColumn>
                    <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='' dataFormat={actionButton}  >Action</TableHeaderColumn>

                  </BootstrapTable>
                </div>
              </div>
            }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
export default withRouter(ClinicNewRoomPage);



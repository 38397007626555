/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddLowCalorieFood: false,
  isAddLowCalorieFoodError: false,
  AddLowCalorieFood: false,
  isUpdateLowCalorieFood: false,
  isUpdateLowCalorieFoodError: false,
  UpdateLowCalorieFood: false,
  isLowCalorieFoodChangeStatus: false,
  isLowCalorieFoodChangeStatusError: false,
  LowCalorieFoodChangeStatus: false,
  isLowCalorieFoodList: false,
  isLowCalorieFoodListError: false,
  LowCalorieFoodList: false,

  isLowCalorieFoodDelete: false,
  isLowCalorieFoodDeleteError: false,
  LowCalorieFoodDelete: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_LOW_CALORIE_FOOD_REQUEST:
      return {
        ...state,
        message: '',
        isAddLowCalorieFood: false,
        isAddLowCalorieFoodError: false,
      }
    case actionTypes.GET_ADD_LOW_CALORIE_FOOD_SUCCESS:
      return {
        ...state,
        message: 'Low Calorie Food Added Successfully',
        AddLowCalorieFood: action.payload.response,
        isAddLowCalorieFood: true,
        isAddLowCalorieFoodError: false,
      }
    case actionTypes.GET_ADD_LOW_CALORIE_FOOD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddLowCalorieFood: false,
        isAddLowCalorieFoodError: true
      }

    //Low Calorie Food Update

    case actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateLowCalorieFood: false,
        isUpdateLowCalorieFoodError: false,
      }
    case actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_SUCCESS:
      return {
        ...state,
        message: 'Low Calorie Food Updated Successfully',
        UpdateLowCalorieFood: action.payload.response,
        isUpdateLowCalorieFood: true,
        isUpdateLowCalorieFoodError: false,
      }
    case actionTypes.GET_UPDATE_LOW_CALORIE_FOOD_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateLowCalorieFood: false,
        isUpdateLowCalorieFoodError: true
      }

    //Link List
    case actionTypes.GET_LOW_CALORIE_FOOD_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isLowCalorieFoodList: false,
        isLowCalorieFoodListError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_LIST_SUCCESS:
      return {
        ...state,
        message: '',
        LowCalorieFoodList: action.payload.response,
        isLowCalorieFoodList: true,
        isLowCalorieFoodListError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLowCalorieFoodList: false,
        isLowCalorieFoodListError: true
      }


    //Low Calorie Food Status Change
    case actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isLowCalorieFoodChangeStatus: false,
        isLowCalorieFoodChangeStatusError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Low Calorie Food Status Changed',
        LowCalorieFoodChangeStatus: action.payload.response,
        isLowCalorieFoodChangeStatus: true,
        isLowCalorieFoodChangeStatusError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLowCalorieFoodChangeStatus: false,
        isLowCalorieFoodChangeStatusError: true
      }

    //Low Calorie Food Delete
    case actionTypes.GET_LOW_CALORIE_FOOD_DELETE_REQUEST:
      return {
        ...state,
        message: '',
        isLowCalorieFoodDelete: false,
        isLowCalorieFoodDeleteError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_DELETE_SUCCESS:
      return {
        ...state,
        message: 'Low Calorie Food Delete',
        LowCalorieFoodDelete: action.payload.response,
        isLowCalorieFoodDelete: true,
        isLowCalorieFoodDeleteError: false,
      }
    case actionTypes.GET_LOW_CALORIE_FOOD_DELETE_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isLowCalorieFoodDelete: false,
        isLowCalorieFoodDeleteError: true
      }

    //   //Low Calorie Food Status Change
    //   case actionTypes.GET_LOW_CALORIE_FOOD_IMAGE_STATUS_REQUEST:
    //   return {
    //     ...state,
    //     message: '',
    //     isLowCalorieFoodImageStatus: false,
    //     isLowCalorieFoodImageStatusError: false,
    //   }
    // case actionTypes.GET_LOW_CALORIE_FOOD_IMAGE_STATUS_SUCCESS:
    // return {
    //   ...state,
    //   message: 'Low Calorie Food Status Changed',
    //   LowCalorieFoodImageStatus: action.payload.response,
    //   isLowCalorieFoodImageStatus: true,
    //   isLowCalorieFoodImageStatusError: false,
    // }
    // case actionTypes.GET_LOW_CALORIE_FOOD_IMAGE_STATUS_ERROR:
    // return {
    //   ...state,
    //   response: action.payload.error,
    //   message: action.payload.error,
    //   isLowCalorieFoodImageStatus: false,
    //   isLowCalorieFoodImageStatusError: true
    // }
    default:
      return state


  }
}

/********* Show list of Link */




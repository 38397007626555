/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper"


export const userList = (data) => postRequestWithToken(data, 'admin/user-list');

export const addUser = (data) => postRequestWithToken(data, 'admin/add-user');

export const updateUser = (data) => postRequestWithToken(data, 'admin/update-user');

export const userDetail = (data) => postRequestWithToken(data, 'admin/user-detail');

export const uploadFile = (data) => postRequestWithTokenFile(data, 'upload-file');

export const userStatus = (data) => postRequestWithToken(data, 'admin/change-user-status');

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/food_category'
/* Add Food Category list and Add form */

export const addFoodCategoryRequest = () => ({
    type: actionTypes.GET_ADD_FOOD_CATEGORY_REQUEST
})
export const addFoodCategorySuccess = (response) => ({
    type: actionTypes.GET_ADD_FOOD_CATEGORY_SUCCESS,
    payload: {
        response,
    }
})
export const addFoodCategoryError = (error) => ({
    type: actionTypes.GET_ADD_FOOD_CATEGORY_ERROR,
    payload: {
        error,
    }
})

export const addFoodCategoryAction = (data) => {
    return dispatch => {
        dispatch(addFoodCategoryRequest())
        const FormData = data.formData
        return API.addFoodCategory(FormData)
            .then(response => {

                dispatch(addFoodCategorySuccess(response.data))
            })
            .catch(error => {
                dispatch(addFoodCategoryError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Link list and Add form */
export const updateFoodCategoryRequest = () => ({
    type: actionTypes.GET_UPDATE_FOOD_CATEGORY_REQUEST
})
export const updateFoodCategorySuccess = (response) => ({
    type: actionTypes.GET_UPDATE_FOOD_CATEGORY_SUCCESS,
    payload: {
        response,
    }
})
export const updateFoodCategoryError = (error) => ({
    type: actionTypes.GET_UPDATE_FOOD_CATEGORY_ERROR,
    payload: {
        error,
    }
})
export const updateFoodCategoryAction = (data) => {
    return dispatch => {
        dispatch(updateFoodCategoryRequest())
        const FormData = data.formData
        return API.updateFoodCategory(FormData)
            .then(response => {
                dispatch(updateFoodCategorySuccess(response.data))
            })
            .catch(error => {
                dispatch(updateFoodCategoryError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link list  */
export const FoodCategoryListRequest = () => ({
    type: actionTypes.GET_FOOD_CATEGORY_LIST_REQUEST
})
export const FoodCategoryListSuccess = (response) => ({
    type: actionTypes.GET_FOOD_CATEGORY_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const FoodCategoryListError = (error) => ({
    type: actionTypes.GET_FOOD_CATEGORY_LIST_ERROR,
    payload: {
        error
    }
})
export const foodCategoryListAction = (data, limitStatus) => {
    return dispatch => {
        dispatch(FoodCategoryListRequest());
        let FormData = '';
        if (data) {
            FormData = data;
        } else {
            FormData = {
                limit: 10,
                offset: 0,
                order: "name",
                direction: "asc",
            }

        }
        if (limitStatus) {
            FormData.limitStatus = limitStatus;
        }

        return API.foodCategoryList(FormData)
            .then(response => {
                dispatch(FoodCategoryListSuccess(response.data))
            })
            .catch(error => {
                dispatch(FoodCategoryListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Link Status Change  */
export const FoodCategoryStatusRequest = () => ({
    type: actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_REQUEST
})
export const FoodCategoryChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const FoodCategoryChangeStatusError = (error) => ({
    type: actionTypes.GET_FOOD_CATEGORY_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const foodCategoryStatusAction = (data) => {

    return dispatch => {
        dispatch(FoodCategoryStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeFoodCategoryStatus(FormData)
            .then(response => {

                dispatch(FoodCategoryChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(FoodCategoryChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}



//Upload File
export const uploadFileRequest = () => ({
    type: actionTypes.POST_UPLOAD_FILE_REQUEST
})
export const uploadFileSuccess = (response) => ({
    type: actionTypes.GET_UPLOAD_FILE_SUCCESS,
    payload: {
        response,
    }
})
export const uploadFileError = (error) => ({
    type: actionTypes.GET_UPLOAD_FILE_ERROR,
    payload: {
        error
    }
})
export const uploadFileAction = (data) => {
    return dispatch => {
        dispatch(uploadFileRequest())
        // const FormData = {
        //     file:data
        // }

        let formData = new FormData();
        formData.append('file', data);
        return API.uploadFile(formData)
            .then(response => {
                dispatch(uploadFileSuccess(response.data))
            })
            .catch(error => {
                dispatch(uploadFileError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

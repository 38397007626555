/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
// user
export const GET_SIGNIN_USER_SUCCESS = "GET_SIGNIN_USER_SUCCESS"
export const GET_SIGNIN_USER_REQUEST = "GET_SIGNIN_USER_REQUEST"
export const GET_SIGNIN_USER_ERROR = "GET_SIGNIN_USER_ERROR"

export const GET_CLINIC_LIST_REQUEST = "GET_CLINIC_LIST_REQUEST"
export const GET_CLINIC_LIST_SUCCESS = "GET_CLINIC_LIST_SUCCESS"
export const GET_CLINIC_LIST_ERROR = "GET_CLINIC_LIST_ERROR"

export const GET_CLINIC_DETAIL_REQUEST = "GET_CLINIC_DETAIL_REQUEST"
export const GET_CLINIC_DETAIL_SUCCESS = "GET_CLINIC_DETAIL_SUCCESS"
export const GET_CLINIC_DETAIL_ERROR = "GET_CLINIC_DETAIL_ERROR"

export const GET_REPORT_LIST_REQUEST = "GET_REPORT_LIST_REQUEST"
export const GET_REPORT_LIST_SUCCESS = "GET_REPORT_LIST_SUCCESS"
export const GET_REPORT_LIST_ERROR = "GET_REPORT_LIST_ERROR"

export const GET_ADD_USER_REQUEST = "GET_ADD_USER_REQUEST"
export const GET_ADD_USER_SUCCESS = "GET_ADD_USER_SUCCESS"
export const GET_ADD_USER_ERROR = "GET_ADD_USER_ERROR"

export const GET_UPDATE_USER_REQUEST = "GET_UPDATE_USER_REQUEST"
export const GET_UPDATE_USER_SUCCESS = "GET_UPDATE_USER_SUCCESS"
export const GET_UPDATE_USER_ERROR = "GET_UPDATE_USER_ERROR"

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR"

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR"

export const GET_USER_STATUS_REQUEST = "GET_USER_STATUS_REQUEST"
export const GET_USER_STATUS_SUCCESS = "GET_USER_STATUS_SUCCESS"
export const GET_USER_STATUS_ERROR = "GET_USER_STATUS_ERROR"

export const GET_CLINIC_CHANGE_STATUS_REQUEST = "GET_CLINIC_CHANGE_STATUS_REQUEST"
export const GET_CLINIC_CHANGE_STATUS_SUCCESS = "GET_CLINIC_CHANGE_STATUS_SUCCESS"
export const GET_CLINIC_CHANGE_STATUS_ERROR = "GET_CLINIC_CHANGE_STATUS_ERROR"

export const GET_IMAGE_REMOVE_REQUEST = "GET_IMAGE_REMOVE_REQUEST"
export const GET_IMAGE_REMOVE_SUCCESS = "GET_IMAGE_REMOVE_SUCCESS"
export const GET_IMAGE_REMOVE_ERROR = "GET_IMAGE_REMOVE_ERROR"


export const GET_SUBSCRIPTION_CHANGE_STATUS_REQUEST = "GET_SUBSCRIPTION_CHANGE_STATUS_REQUEST"
export const GET_SUBSCRIPTION_CHANGE_STATUS_SUCCESS = "GET_SUBSCRIPTION_CHANGE_STATUS_SUCCESS"
export const GET_SUBSCRIPTION_CHANGE_STATUS_ERROR = "GET_SUBSCRIPTION_CHANGE_STATUS_ERROR"

export const GET_CLINIC_SUBSCRIPTION_REQUEST = "GET_CLINIC_SUBSCRIPTION_REQUEST"
export const GET_CLINIC_SUBSCRIPTION_SUCCESS = "GET_CLINIC_SUBSCRIPTION_SUCCESS"
export const GET_CLINIC_SUBSCRIPTION_ERROR = "GET_CLINIC_SUBSCRIPTION_ERROR"
//MASTER

export const GET_UPDATE_TERMCONDITION_REQUEST = "GET_UPDATE_TERMCONDITION_REQUEST"
export const GET_UPDATE_TERMCONDITION_SUCCESS = "GET_UPDATE_TERMCONDITION_SUCCESS"
export const GET_UPDATE_TERMCONDITION_ERROR = "GET_UPDATE_TERMCONDITION_ERROR"

export const GET_TERMCONDITION_LIST_REQUEST = "GET_TERMCONDITION_LIST_REQUEST"
export const GET_TERMCONDITION_LIST_SUCCESS = "GET_TERMCONDITION_LIST_SUCCESS"
export const GET_TERMCONDITION_LIST_ERROR = "GET_TERMCONDITION_LIST_ERROR"

export const GET_STATE_LIST_REQUEST = "GET_STATE_LIST_REQUEST"
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS"
export const GET_STATE_LIST_ERROR = "GET_STATE_LIST_ERROR"

export const GET_COUNTRY_LIST_REQUEST = "GET_COUNTRY_LIST_REQUEST"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR"

export const GET_PLAN_LIST_REQUEST = "GET_PLAN_LIST_REQUEST"
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS"
export const GET_PLAN_LIST_ERROR = "GET_PLAN_LIST_ERROR"

export const GET_SPECILIZATION_LIST_REQUEST = "GET_SPECILIZATION_LIST_REQUEST"
export const GET_SPECILIZATION_LIST_SUCCESS = "GET_SPECILIZATION_LIST_SUCCESS"
export const GET_SPECILIZATION_LIST_ERROR = "GET_SPECILIZATION_LIST_ERROR"

export const ADD_SPECILIZATION_REQUEST = "ADD_SPECILIZATION_REQUEST"
export const ADD_SPECILIZATION_SUCCESS = "ADD_SPECILIZATION_SUCCESS"
export const ADD_SPECILIZATION_ERROR = "ADD_SPECILIZATION_ERROR"

export const GET_UPDATE_SPECILIZATION_REQUEST = "GET_UPDATE_SPECILIZATION_REQUEST"
export const GET_UPDATE_SPECILIZATION_SUCCESS = "GET_UPDATE_SPECILIZATION_SUCCESS"
export const GET_UPDATE_SPECILIZATION_ERROR = "GET_UPDATE_SPECILIZATION_ERROR"

export const GET_SPECILIZATION_CHANGE_STATUS_REQUEST = "GET_SPECILIZATION_CHANGE_STATUS_REQUEST"
export const GET_SPECILIZATION_CHANGE_STATUS_SUCCESS = "GET_SPECILIZATION_CHANGE_STATUS_SUCCESS"
export const GET_SPECILIZATION_CHANGE_STATUS_ERROR = "GET_SPECILIZATION_CHANGE_STATUS_ERROR"

export const GET_ADD_FAQ_REQUEST = "GET_ADD_FAQ_REQUEST"
export const GET_ADD_FAQ_SUCCESS = "GET_ADD_FAQ_SUCCESS"
export const GET_ADD_FAQ_ERROR = "GET_ADD_FAQ_ERROR"

export const GET_UPDATE_FAQ_REQUEST = "GET_UPDATE_FAQ_REQUEST"
export const GET_UPDATE_FAQ_SUCCESS = "GET_UPDATE_FAQ_SUCCESS"
export const GET_UPDATE_FAQ_ERROR = "GET_UPDATE_FAQ_ERROR"

export const GET_FAQ_CHANGE_STATUS_REQUEST = "GET_FAQ_CHANGE_STATUS_REQUEST"
export const GET_FAQ_CHANGE_STATUS_SUCCESS = "GET_FAQ_CHANGE_STATUS_SUCCESS"
export const GET_FAQ_CHANGE_STATUS_ERROR = "GET_FAQ_CHANGE_STATUS_ERROR"

export const GET_FAQ_LIST_REQUEST = "GET_FAQ_LIST_REQUEST"
export const GET_FAQ_LIST_SUCCESS = "GET_FAQ_LIST_SUCCESS"
export const GET_FAQ_LIST_ERROR = "GET_FAQ_LIST_ERROR"

export const GET_PATIENT_LIST_REQUEST = "GET_PATIENT_LIST_REQUEST"
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS"
export const GET_PATIENT_LIST_ERROR = "GET_PATIENT_LIST_ERROR"

export const GET_ADD_EDUCATOR_REQUEST = "GET_ADD_EDUCATOR_REQUEST"
export const GET_ADD_EDUCATOR_SUCCESS = "GET_ADD_EDUCATOR_SUCCESS"
export const GET_ADD_EDUCATOR_ERROR = "GET_ADD_EDUCATOR_ERROR"

export const GET_UPDATE_EDUCATOR_REQUEST = "GET_UPDATE_EDUCATOR_REQUEST"
export const GET_UPDATE_EDUCATOR_SUCCESS = "GET_UPDATE_EDUCATOR_SUCCESS"
export const GET_UPDATE_EDUCATOR_ERROR = "GET_UPDATE_EDUCATOR_ERROR"

export const GET_EDUCATOR_LIST_REQUEST = "GET_EDUCATOR_LIST_REQUEST"
export const GET_EDUCATOR_LIST_SUCCESS = "GET_EDUCATOR_LIST_SUCCESS"
export const GET_EDUCATOR_LIST_ERROR = "GET_EDUCATOR_LIST_ERROR"

export const GET_EDUCATOR_REQUEST_LIST = "GET_EDUCATOR_LIST_REQUEST"
export const GET_EDUCATOR_REQUEST_LIST_SUCCESS = "GET_EDUCATOR_LIST_SUCCESS"
export const GET_EDUCATOR_REQUEST_LIST_ERROR = "GET_EDUCATOR_LIST_ERROR"

export const GET_EDUCATOR_CHANGE_STATUS_REQUEST = "GET_EDUCATOR_CHANGE_STATUS_REQUEST"
export const GET_EDUCATOR_CHANGE_STATUS_SUCCESS = "GET_EDUCATOR_CHANGE_STATUS_SUCCESS"
export const GET_EDUCATOR_CHANGE_STATUS_ERROR = "GET_EDUCATOR_CHANGE_STATUS_ERROR"

export const GET_UPDATE_LINK_REQUEST = "GET_UPDATE_LINK_REQUEST"
export const GET_UPDATE_LINK_SUCCESS = "GET_UPDATE_LINK_SUCCESS"
export const GET_UPDATE_LINK_ERROR = "GET_UPDATE_LINK_ERROR"

export const GET_ADD_LINK_REQUEST = "GET_ADD_LINK_REQUEST"
export const GET_ADD_LINK_SUCCESS = "GET_ADD_LINK_SUCCESS"
export const GET_ADD_LINK_ERROR = "GET_ADD_LINK_ERROR"


export const GET_LINK_LIST_REQUEST = "GET_LINK_LIST_REQUEST"
export const GET_LINK_LIST_SUCCESS = "GET_LINK_LIST_SUCCESS"
export const GET_LINK_LIST_ERROR = "GET_LINK_LIST_ERROR"

export const GET_LINK_CHANGE_STATUS_REQUEST = "GET_LINK_CHANGE_STATUS_REQUEST"
export const GET_LINK_CHANGE_STATUS_SUCCESS = "GET_LINK_CHANGE_STATUS_SUCCESS"
export const GET_LINK_CHANGE_STATUS_ERROR = "GET_LINK_CHANGE_STATUS_ERROR"


export const GET_LANGUAGE_LIST_REQUEST = "GET_LANGUAGE_LIST_REQUEST"
export const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS"
export const GET_LANGUAGE_LIST_ERROR = "GET_LANGUAGE_LIST_ERROR"

export const GET_LANGUAGE_CHANGE_STATUS_REQUEST = "GET_LANGUAGE_CHANGE_STATUS_REQUEST"
export const GET_LANGUAGE_CHANGE_STATUS_SUCCESS = "GET_LANGUAGE_CHANGE_STATUS_SUCCESS"
export const GET_LANGUAGE_CHANGE_STATUS_ERROR = "GET_LANGUAGE_CHANGE_STATUS_ERROR"


export const POST_ADD_PLAN_REQUEST = "POST_ADD_PLAN_REQUEST"
export const GET_ADD_PLAN_SUCCESS = "GET_ADD_PLAN_SUCCESS"
export const GET_ADD_PLAN_ERROR = "GET_ADD_PLAN_ERROR"

export const POST_UPDATE_PLAN_REQUEST = "POST_UPDATE_PLAN_REQUEST"
export const GET_UPDATE_PLAN_SUCCESS = "GET_UPDATE_PLAN_SUCCESS"
export const GET_UPDATE_PLAN_ERROR = "GET_UPDATE_PLAN_ERROR"

export const GET_PLAN_CHANGE_STATUS_REQUEST = "GET_PLAN_CHANGE_STATUS_REQUEST"
export const GET_PLAN_CHANGE_STATUS_SUCCESS = "GET_PLAN_CHANGE_STATUS_SUCCESS"
export const GET_PLAN_CHANGE_STATUS_ERROR = "GET_PLAN_CHANGE_STATUS_ERROR"


export const POST_ADD_CLINIC_REQUEST = "POST_ADD_CLINIC_REQUEST"
export const GET_ADD_CLINIC_SUCCESS = "GET_ADD_CLINIC_SUCCESS"
export const GET_ADD_CLINIC_ERROR = "GET_ADD_CLINIC_ERROR"

export const ADD_CLINIC_CSV_REQUEST = "ADD_CLINIC_CSV_REQUEST"
export const ADD_CLINIC_CSV_SUCCESS = "ADD_CLINIC_CSV_SUCCESS"
export const ADD_CLINIC_CSV_ERROR = "ADD_CLINIC_CSV_ERROR"

export const POST_UPDATE_CLINIC_REQUEST = "POST_UPDATE_CLINIC_REQUEST"
export const GET_UPDATE_CLINIC_SUCCESS = "GET_UPDATE_CLINIC_SUCCESS"
export const GET_UPDATE_CLINIC_ERROR = "GET_UPDATE_CLINIC_ERROR"

export const GET_ADD_KNOWLEDGE_REQUEST = "GET_ADD_KNOWLEDGE_REQUEST"
export const GET_ADD_KNOWLEDGE_SUCCESS = "GET_ADD_KNOWLEDGE_SUCCESS"
export const GET_ADD_KNOWLEDGE_ERROR = "GET_ADD_KNOWLEDGE_ERROR"

export const GET_UPDATE_KNOWLEDGE_REQUEST = "GET_UPDATE_KNOWLEDGE_REQUEST"
export const GET_UPDATE_KNOWLEDGE_SUCCESS = "GET_UPDATE_KNOWLEDGE_SUCCESS"
export const GET_UPDATE_KNOWLEDGE_ERROR = "GET_UPDATE_KNOWLEDGE_ERROR"

export const GET_KNOWLEDGE_LIST_REQUEST = "GET_KNOWLEDGE_LIST_REQUEST"
export const GET_KNOWLEDGE_LIST_SUCCESS = "GET_KNOWLEDGE_LIST_SUCCESS"
export const GET_KNOWLEDGE_LIST_ERROR = "GET_KNOWLEDGE_LIST_ERROR"

export const GET_KNOWLEDGE_CHANGE_STATUS_REQUEST = "GET_KNOWLEDGE_CHANGE_STATUS_REQUEST"
export const GET_KNOWLEDGE_CHANGE_STATUS_SUCCESS = "GET_KNOWLEDGE_CHANGE_STATUS_SUCCESS"
export const GET_KNOWLEDGE_CHANGE_STATUS_ERROR = "GET_KNOWLEDGE_CHANGE_STATUS_ERROR"

export const POST_UPLOAD_FILE_REQUEST = "POST_UPLOAD_FILE_REQUEST"
export const GET_UPLOAD_FILE_SUCCESS = "GET_UPLOAD_FILE_SUCCESS"
export const GET_UPLOAD_FILE_ERROR = "GET_UPLOAD_FILE_ERROR"

export const POST_UPLOAD_PDF_REQUEST = "POST_UPLOAD_PDF_REQUEST"
export const GET_UPLOAD_PDF_SUCCESS = "GET_UPLOAD_PDF_SUCCESS"
export const GET_UPLOAD_PDF_ERROR = "GET_UPLOAD_PDF_ERROR"

export const POST_ADD_CLINIC_SUBSCRIPTION_REQUEST = "POST_ADD_CLINIC_SUBSCRIPTION_REQUEST"
export const GET_ADD_CLINIC_SUBSCRIPTION_SUCCESS = "GET_ADD_CLINIC_SUBSCRIPTION_SUCCESS"
export const GET_ADD_CLINIC_SUBSCRIPTION_ERROR = "GET_ADD_CLINIC_SUBSCRIPTION_ERROR"

export const POST_UPDATE_CLINIC_SUBSCRIPTION_REQUEST = "POST_UPDATE_CLINIC_SUBSCRIPTION_REQUEST"
export const GET_UPDATE_CLINIC_SUBSCRIPTION_SUCCESS = "GET_UPDATE_CLINIC_SUBSCRIPTION_SUCCESS"
export const GET_UPDATE_CLINIC_SUBSCRIPTION_ERROR = "GET_UPDATE_CLINIC_SUBSCRIPTION_ERROR"

export const GET_CLINIC_CALENDAR_LIST_REQUEST = "GET_CLINIC_CALENDAR_LIST_REQUEST"
export const GET_CLINIC_CALENDAR_LIST_SUCCESS = "GET_CLINIC_CALENDAR_LIST_SUCCESS"
export const GET_CLINIC_CALENDAR_LIST_ERROR = "GET_CLINIC_CALENDAR_LIST_ERROR"

export const POST_ADD_CLINIC_CALENDAR_REQUEST = "POST_ADD_CLINIC_CALENDAR_REQUEST"
export const GET_ADD_CLINIC_CALENDAR_SUCCESS = "GET_ADD_CLINIC_CALENDAR_SUCCESS"
export const GET_ADD_CLINIC_CALENDAR_ERROR = "GET_ADD_CLINIC_CALENDAR_ERROR"

export const GET_CLINIC_ACTIVE_LIST_REQUEST = "GET_CLINIC_ACTIVE_LIST_REQUEST"
export const GET_CLINIC_ACTIVE_LIST_SUCCESS = "GET_CLINIC_ACTIVE_LIST_SUCCESS"
export const GET_CLINIC_ACTIVE_LIST_ERROR = "GET_CLINIC_ACTIVE_LIST_ERROR"

export const GET_CATEGORY_LIST_REQUEST = "GET_CATEGORY_LIST_REQUEST"
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS"
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR"

export const GET_CALENDAR_CHANGE_STATUS_REQUEST = "GET_CALENDAR_CHANGE_STATUS_REQUEST"
export const GET_CALENDAR_CHANGE_STATUS_SUCCESS = "GET_CALENDAR_CHANGE_STATUS_SUCCESS"
export const GET_CALENDAR_CHANGE_STATUS_ERROR = "GET_CALENDAR_CHANGE_STATUS_ERROR"

export const GET_ADD_FOOD_CATEGORY_REQUEST = "GET_ADD_FOOD_CATEGORY_REQUEST"
export const GET_ADD_FOOD_CATEGORY_SUCCESS = "GET_ADD_FOOD_CATEGORY_SUCCESS"
export const GET_ADD_FOOD_CATEGORY_ERROR = "GET_ADD_FOOD_CATEGORY_ERROR"

export const GET_FOOD_CATEGORY_LIST_REQUEST = "GET_FOOD_CATEGORY_LIST_REQUEST"
export const GET_FOOD_CATEGORY_LIST_SUCCESS = "GET_FOOD_CATEGORY_LIST_SUCCESS"
export const GET_FOOD_CATEGORY_LIST_ERROR = "GET_FOOD_CATEGORY_LIST_ERROR"

export const GET_FOOD_CATEGORY_CHANGE_STATUS_REQUEST = "GET_FOOD_CATEGORY_CHANGE_STATUS_REQUEST"
export const GET_FOOD_CATEGORY_CHANGE_STATUS_SUCCESS = "GET_FOOD_CATEGORY_CHANGE_STATUS_SUCCESS"
export const GET_FOOD_CATEGORY_CHANGE_STATUS_ERROR = "GET_FOOD_CATEGORY_CHANGE_STATUS_ERROR"

export const GET_UPDATE_FOOD_CATEGORY_REQUEST = "GET_UPDATE_FOOD_CATEGORY_REQUEST"
export const GET_UPDATE_FOOD_CATEGORY_SUCCESS = "GET_UPDATE_FOOD_CATEGORY_SUCCESS"
export const GET_UPDATE_FOOD_CATEGORY_ERROR = "GET_UPDATE_FOOD_CATEGORY_ERROR"

export const GET_ADD_SERVING_SIZE_REQUEST = "GET_ADD_SERVING_SIZE_REQUEST"
export const GET_ADD_SERVING_SIZE_SUCCESS = "GET_ADD_SERVING_SIZE_SUCCESS"
export const GET_ADD_SERVING_SIZE_ERROR = "GET_ADD_SERVING_SIZE_ERROR"

export const GET_SERVING_SIZE_LIST_REQUEST = "GET_SERVING_SIZE_LIST_REQUEST"
export const GET_SERVING_SIZE_LIST_SUCCESS = "GET_SERVING_SIZE_LIST_SUCCESS"
export const GET_SERVING_SIZE_LIST_ERROR = "GET_SERVING_SIZE_LIST_ERROR"

export const GET_SERVING_SIZE_CHANGE_STATUS_REQUEST = "GET_SERVING_SIZE_CHANGE_STATUS_REQUEST"
export const GET_SERVING_SIZE_CHANGE_STATUS_SUCCESS = "GET_SERVING_SIZE_CHANGE_STATUS_SUCCESS"
export const GET_SERVING_SIZE_CHANGE_STATUS_ERROR = "GET_SERVING_SIZE_CHANGE_STATUS_ERROR"

export const GET_UPDATE_SERVING_SIZE_REQUEST = "GET_UPDATE_SERVING_SIZE_REQUEST"
export const GET_UPDATE_SERVING_SIZE_SUCCESS = "GET_UPDATE_SERVING_SIZE_SUCCESS"
export const GET_UPDATE_SERVING_SIZE_ERROR = "GET_UPDATE_SERVING_SIZE_ERROR"

export const GET_SERVING_SIZE_IMAGE_STATUS_REQUEST = "GET_SERVING_SIZE_IMAGE_STATUS_REQUEST"
export const GET_SERVING_SIZE_IMAGE_STATUS_SUCCESS = "GET_SERVING_SIZE_IMAGE_STATUS_SUCCESS"
export const GET_SERVING_SIZE_IMAGE_STATUS_ERROR = "GET_SERVING_SIZE_IMAGE_STATUS_ERROR"

export const GET_UPDATE_FOOD_REQUEST = "GET_UPDATE_FOOD_REQUEST"
export const GET_UPDATE_FOOD_SUCCESS = "GET_UPDATE_FOOD_SUCCESS"
export const GET_UPDATE_FOOD_ERROR = "GET_UPDATE_FOOD_ERROR"

export const GET_ADD_FOOD_REQUEST = "GET_ADD_FOOD_REQUEST"
export const GET_ADD_FOOD_SUCCESS = "GET_ADD_FOOD_SUCCESS"
export const GET_ADD_FOOD_ERROR = "GET_ADD_FOOD_ERROR"

export const GET_FOOD_LIST_REQUEST = "GET_FOOD_LIST_REQUEST"
export const GET_FOOD_LIST_SUCCESS = "GET_FOOD_LIST_SUCCESS"
export const GET_FOOD_LIST_ERROR = "GET_FOOD_LIST_ERROR"

export const GET_FOOD_CART_LIST_REQUEST = "GET_FOOD_CART_LIST_REQUEST"
export const GET_FOOD_CART_LIST_SUCCESS = "GET_FOOD_CART_LIST_SUCCESS"
export const GET_FOOD_CART_LIST_ERROR = "GET_FOOD_CART_LIST_ERROR"

export const GET_FOOD_CHANGE_STATUS_REQUEST = "GET_FOOD_CHANGE_STATUS_REQUEST"
export const GET_FOOD_CHANGE_STATUS_SUCCESS = "GET_FOOD_CHANGE_STATUS_SUCCESS"
export const GET_FOOD_CHANGE_STATUS_ERROR = "GET_FOOD_CHANGE_STATUS_ERROR"

export const GET_ADD_REGION_REQUEST = "GET_ADD_REGION_REQUEST"
export const GET_ADD_REGION_SUCCESS = "GET_ADD_REGION_SUCCESS"
export const GET_ADD_REGION_ERROR = "GET_ADD_REGION_ERROR"

export const GET_REGION_LIST_REQUEST = "GET_REGION_LIST_REQUEST"
export const GET_REGION_LIST_SUCCESS = "GET_REGION_LIST_SUCCESS"
export const GET_REGION_LIST_ERROR = "GET_REGION_LIST_ERROR"

export const GET_REGION_CHANGE_STATUS_REQUEST = "GET_REGION_CHANGE_STATUS_REQUEST"
export const GET_REGION_CHANGE_STATUS_SUCCESS = "GET_REGION_CHANGE_STATUS_SUCCESS"
export const GET_REGION_CHANGE_STATUS_ERROR = "GET_REGION_CHANGE_STATUS_ERROR"

export const GET_UPDATE_REGION_REQUEST = "GET_UPDATE_REGION_REQUEST"
export const GET_UPDATE_REGION_SUCCESS = "GET_UPDATE_REGION_SUCCESS"
export const GET_UPDATE_REGION_ERROR = "GET_UPDATE_REGION_ERROR"

export const GET_ADD_ATTACHMENT_REQUEST = "GET_ADD_ATTACHMENT_REQUEST"
export const GET_ADD_ATTACHMENT_SUCCESS = "GET_ADD_ATTACHMENT_SUCCESS"
export const GET_ADD_ATTACHMENT_ERROR = "GET_ADD_ATTACHMENT_ERROR"

export const GET_ATTACHMENT_LIST_REQUEST = "GET_ATTACHMENT_LIST_REQUEST"
export const GET_ATTACHMENT_LIST_SUCCESS = "GET_ATTACHMENT_LIST_SUCCESS"
export const GET_ATTACHMENT_LIST_ERROR = "GET_ATTACHMENT_LIST_ERROR"

export const GET_ATTACHMENT_CHANGE_STATUS_REQUEST = "GET_ATTACHMENT_CHANGE_STATUS_REQUEST"
export const GET_ATTACHMENT_CHANGE_STATUS_SUCCESS = "GET_ATTACHMENT_CHANGE_STATUS_SUCCESS"
export const GET_ATTACHMENT_CHANGE_STATUS_ERROR = "GET_ATTACHMENT_CHANGE_STATUS_ERROR"

export const GET_UPDATE_ATTACHMENT_REQUEST = "GET_UPDATE_ATTACHMENT_REQUEST"
export const GET_UPDATE_ATTACHMENT_SUCCESS = "GET_UPDATE_ATTACHMENT_SUCCESS"
export const GET_UPDATE_ATTACHMENT_ERROR = "GET_UPDATE_ATTACHMENT_ERROR"

export const GET_ATTACHMENT_AUTO_SELECT_STATUS_REQUEST = "GET_ATTACHMENT_AUTO_SELECT_STATUS_REQUEST"
export const GET_ATTACHMENT_AUTO_SELECT_STATUS_SUCCESS = "GET_ATTACHMENT_AUTO_SELECT_STATUS_SUCCESS"
export const GET_ATTACHMENT_AUTO_SELECT_STATUS_ERROR = "GET_ATTACHMENT_AUTO_SELECT_STATUS_ERROR"

/** Low Calorie Food */
export const GET_ADD_LOW_CALORIE_FOOD_REQUEST = "GET_ADD_LOW_CALORIE_FOOD_REQUEST"
export const GET_ADD_LOW_CALORIE_FOOD_SUCCESS = "GET_ADD_LOW_CALORIE_FOOD_SUCCESS"
export const GET_ADD_LOW_CALORIE_FOOD_ERROR = "GET_ADD_LOW_CALORIE_FOOD_ERROR"

export const GET_LOW_CALORIE_FOOD_LIST_REQUEST = "GET_LOW_CALORIE_FOOD_LIST_REQUEST"
export const GET_LOW_CALORIE_FOOD_LIST_SUCCESS = "GET_LOW_CALORIE_FOOD_LIST_SUCCESS"
export const GET_LOW_CALORIE_FOOD_LIST_ERROR = "GET_LOW_CALORIE_FOOD_LIST_ERROR"

export const GET_LOW_CALORIE_FOOD_CHANGE_STATUS_REQUEST = "GET_LOW_CALORIE_FOOD_CHANGE_STATUS_REQUEST"
export const GET_LOW_CALORIE_FOOD_CHANGE_STATUS_SUCCESS = "GET_LOW_CALORIE_FOOD_CHANGE_STATUS_SUCCESS"
export const GET_LOW_CALORIE_FOOD_CHANGE_STATUS_ERROR = "GET_LOW_CALORIE_FOOD_CHANGE_STATUS_ERROR"

export const GET_UPDATE_LOW_CALORIE_FOOD_REQUEST = "GET_UPDATE_LOW_CALORIE_FOOD_REQUEST"
export const GET_UPDATE_LOW_CALORIE_FOOD_SUCCESS = "GET_UPDATE_LOW_CALORIE_FOOD_SUCCESS"
export const GET_UPDATE_LOW_CALORIE_FOOD_ERROR = "GET_UPDATE_LOW_CALORIE_FOOD_ERROR"

export const GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_REQUEST = "GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_REQUEST"
export const GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_SUCCESS = "GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_SUCCESS"
export const GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_ERROR = "GET_LOW_CALORIE_FOOD_AUTO_SELECT_STATUS_ERROR"

export const GET_LOW_CALORIE_FOOD_DELETE_REQUEST = "GET_LOW_CALORIE_FOOD_DELETE_REQUEST"
export const GET_LOW_CALORIE_FOOD_DELETE_SUCCESS = "GET_LOW_CALORIE_FOOD_DELETE_SUCCESS"
export const GET_LOW_CALORIE_FOOD_DELETE_ERROR = "GET_LOW_CALORIE_FOOD_DELETE_ERROR"

export const GET_UPDATE_COMMENT_REQUEST = "GET_UPDATE_COMMENT_REQUEST"
export const GET_UPDATE_COMMENT_SUCCESS = "GET_UPDATE_COMMENT_SUCCESS"
export const GET_UPDATE_COMMENT_ERROR = "GET_UPDATE_COMMENT_ERROR"

export const GET_ADD_COMMENT_REQUEST = "GET_ADD_COMMENT_REQUEST"
export const GET_ADD_COMMENT_SUCCESS = "GET_ADD_COMMENT_SUCCESS"
export const GET_ADD_COMMENT_ERROR = "GET_ADD_COMMENT_ERROR"

export const GET_COMMENT_LIST_REQUEST = "GET_COMMENT_LIST_REQUEST"
export const GET_COMMENT_LIST_SUCCESS = "GET_COMMENT_LIST_SUCCESS"
export const GET_COMMENT_LIST_ERROR = "GET_COMMENT_LIST_ERROR"

export const GET_COMMENT_CHANGE_STATUS_REQUEST = "GET_COMMENT_CHANGE_STATUS_REQUEST"
export const GET_COMMENT_CHANGE_STATUS_SUCCESS = "GET_COMMENT_CHANGE_STATUS_SUCCESS"
export const GET_COMMENT_CHANGE_STATUS_ERROR = "GET_COMMENT_CHANGE_STATUS_ERROR"

export const GET_COMMENT_AUTO_SELECT_STATUS_REQUEST = "GET_COMMENT_AUTO_SELECT_STATUS_REQUEST"
export const GET_COMMENT_AUTO_SELECT_STATUS_SUCCESS = "GET_COMMENT_AUTO_SELECT_STATUS_SUCCESS"
export const GET_COMMENT_AUTO_SELECT_STATUS_ERROR = "GET_COMMENT_AUTO_SELECT_STATUS_ERROR"

export const GET_UPDATE_TREATMENT_TYPE_REQUEST = "GET_UPDATE_TREATMENT_TYPE_REQUEST"
export const GET_UPDATE_TREATMENT_TYPE_SUCCESS = "GET_UPDATE_TREATMENT_TYPE_SUCCESS"
export const GET_UPDATE_TREATMENT_TYPE_ERROR = "GET_UPDATE_TREATMENT_TYPE_ERROR"

export const GET_ADD_TREATMENT_TYPE_REQUEST = "GET_ADD_TREATMENT_TYPE_REQUEST"
export const GET_ADD_TREATMENT_TYPE_SUCCESS = "GET_ADD_TREATMENT_TYPE_SUCCESS"
export const GET_ADD_TREATMENT_TYPE_ERROR = "GET_ADD_TREATMENT_TYPE_ERROR"

export const GET_TREATMENT_TYPE_LIST_REQUEST = "GET_TREATMENT_TYPE_LIST_REQUEST"
export const GET_TREATMENT_TYPE_LIST_SUCCESS = "GET_TREATMENT_TYPE_LIST_SUCCESS"
export const GET_TREATMENT_TYPE_LIST_ERROR = "GET_TREATMENT_TYPE_LIST_ERROR"

export const GET_TREATMENT_TYPE_CHANGE_STATUS_REQUEST = "GET_TREATMENT_TYPE_CHANGE_STATUS_REQUEST"
export const GET_TREATMENT_TYPE_CHANGE_STATUS_SUCCESS = "GET_TREATMENT_TYPE_CHANGE_STATUS_SUCCESS"
export const GET_TREATMENT_TYPE_CHANGE_STATUS_ERROR = "GET_TREATMENT_TYPE_CHANGE_STATUS_ERROR"

export const GET_UPDATE_INSTRUCTION_REQUEST = "GET_UPDATE_INSTRUCTION_REQUEST"
export const GET_UPDATE_INSTRUCTION_SUCCESS = "GET_UPDATE_INSTRUCTION_SUCCESS"
export const GET_UPDATE_INSTRUCTION_ERROR = "GET_UPDATE_INSTRUCTION_ERROR"

export const GET_ADD_INSTRUCTION_REQUEST = "GET_ADD_INSTRUCTION_REQUEST"
export const GET_ADD_INSTRUCTION_SUCCESS = "GET_ADD_INSTRUCTION_SUCCESS"
export const GET_ADD_INSTRUCTION_ERROR = "GET_ADD_INSTRUCTION_ERROR"

export const GET_INSTRUCTION_LIST_REQUEST = "GET_INSTRUCTION_LIST_REQUEST"
export const GET_INSTRUCTION_LIST_SUCCESS = "GET_INSTRUCTION_LIST_SUCCESS"
export const GET_INSTRUCTION_LIST_ERROR = "GET_INSTRUCTION_LIST_ERROR"

export const GET_INSTRUCTION_CHANGE_STATUS_REQUEST = "GET_INSTRUCTION_CHANGE_STATUS_REQUEST"
export const GET_INSTRUCTION_CHANGE_STATUS_SUCCESS = "GET_INSTRUCTION_CHANGE_STATUS_SUCCESS"
export const GET_INSTRUCTION_CHANGE_STATUS_ERROR = "GET_INSTRUCTION_CHANGE_STATUS_ERROR"

export const GET_DIET_TIME_LIST_REQUEST = "GET_DIET_TIME_LIST_REQUEST"
export const GET_DIET_TIME_LIST_SUCCESS = "GET_DIET_TIME_LIST_SUCCESS"
export const GET_DIET_TIME_LIST_ERROR = "GET_DIET_TIME_LIST_ERROR"

export const GET_DIET_TIME_PLAN_LIST_REQUEST = "GET_DIET_TIME_PLAN_LIST_REQUEST"
export const GET_DIET_TIME_PLAN_LIST_SUCCESS = "GET_DIET_TIME_PLAN_LIST_SUCCESS"
export const GET_DIET_TIME_PLAN_LIST_ERROR = "GET_DIET_TIME_PLAN_LIST_ERROR"

export const GET_UPDATE_TREATMENT_REQUEST = "GET_UPDATE_TREATMENT_REQUEST"
export const GET_UPDATE_TREATMENT_SUCCESS = "GET_UPDATE_TREATMENT_SUCCESS"
export const GET_UPDATE_TREATMENT_ERROR = "GET_UPDATE_TREATMENT_ERROR"

export const GET_ADD_TREATMENT_REQUEST = "GET_ADD_TREATMENT_REQUEST"
export const GET_ADD_TREATMENT_SUCCESS = "GET_ADD_TREATMENT_SUCCESS"
export const GET_ADD_TREATMENT_ERROR = "GET_ADD_TREATMENT_ERROR"

export const GET_TREATMENT_LIST_REQUEST = "GET_TREATMENT_LIST_REQUEST"
export const GET_TREATMENT_LIST_SUCCESS = "GET_TREATMENT_LIST_SUCCESS"
export const GET_TREATMENT_LIST_ERROR = "GET_TREATMENT_LIST_ERROR"

export const GET_TREATMENT_CHANGE_STATUS_REQUEST = "GET_TREATMENT_CHANGE_STATUS_REQUEST"
export const GET_TREATMENT_CHANGE_STATUS_SUCCESS = "GET_TREATMENT_CHANGE_STATUS_SUCCESS"
export const GET_TREATMENT_CHANGE_STATUS_ERROR = "GET_TREATMENT_CHANGE_STATUS_ERROR"

export const GET_UPDATE_READY_CREATED_MEAL_REQUEST = "GET_UPDATE_READY_CREATED_MEAL_REQUEST"
export const GET_UPDATE_READY_CREATED_MEAL_SUCCESS = "GET_UPDATE_READY_CREATED_MEAL_SUCCESS"
export const GET_UPDATE_READY_CREATED_MEAL_ERROR = "GET_UPDATE_READY_CREATED_MEAL_ERROR"

export const GET_ADD_READY_CREATED_MEAL_REQUEST = "GET_ADD_READY_CREATED_MEAL_REQUEST"
export const GET_ADD_READY_CREATED_MEAL_SUCCESS = "GET_ADD_READY_CREATED_MEAL_SUCCESS"
export const GET_ADD_READY_CREATED_MEAL_ERROR = "GET_ADD_READY_CREATED_MEAL_ERROR"

export const GET_READY_CREATED_MEAL_LIST_REQUEST = "GET_READY_CREATED_MEAL_LIST_REQUEST"
export const GET_READY_CREATED_MEAL_LIST_SUCCESS = "GET_READY_CREATED_MEAL_LIST_SUCCESS"
export const GET_READY_CREATED_MEAL_LIST_ERROR = "GET_READY_CREATED_MEAL_LIST_ERROR"

export const GET_READY_CREATED_MEAL_CHANGE_STATUS_REQUEST = "GET_READY_CREATED_MEAL_CHANGE_STATUS_REQUEST"
export const GET_READY_CREATED_MEAL_CHANGE_STATUS_SUCCESS = "GET_READY_CREATED_MEAL_CHANGE_STATUS_SUCCESS"
export const GET_READY_CREATED_MEAL_CHANGE_STATUS_ERROR = "GET_READY_CREATED_MEAL_CHANGE_STATUS_ERROR"


export const GET_UPDATE_PRIORITY_REQUEST = "GET_UPDATE_PRIORITY_REQUEST"
export const GET_UPDATE_PRIORITY_SUCCESS = "GET_UPDATE_PRIORITY_SUCCESS"
export const GET_UPDATE_PRIORITY_ERROR = "GET_UPDATE_PRIORITY_ERROR"

export const GET_TREATMENT_LIST_TYPE_REQUEST = "GET_TREATMENT_LIST_TYPE_REQUEST"
export const GET_TREATMENT_LIST_TYPE_SUCCESS = "GET_TREATMENT_LIST_TYPE_SUCCESS"
export const GET_TREATMENT_LIST_TYPE_ERROR = "GET_TREATMENT_LIST_TYPE_ERROR"

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR"

export const GET_CLINIC_INVITATION_REQUEST = "GET_CLINIC_INVITATION_REQUEST"
export const GET_CLINIC_INVITATION_SUCCESS = "GET_CLINIC_INVITATION_SUCCESS"
export const GET_CLINIC_INVITATION_ERROR = "GET_CLINIC_INVITATION_ERROR"

export const ADD_CLINIC_INVITATION_REQUEST = "ADD_CLINIC_INVITATION_REQUEST"
export const ADD_CLINIC_INVITATION_SUCCESS = "ADD_CLINIC_INVITATION_SUCCESS"
export const ADD_CLINIC_INVITATION_ERROR = "ADD_CLINIC_INVITATION_ERROR"

export const EDIT_CLINIC_INVITATION_REQUEST = "EDIT_CLINIC_INVITATION_REQUEST"
export const EDIT_CLINIC_INVITATION_SUCCESS = "EDIT_CLINIC_INVITATION_SUCCESS"
export const EDIT_CLINIC_INVITATION_ERROR = "EDIT_CLINIC_INVITATION_ERROR"

export const INVITATION_SENDMAIL_REQUEST = "INVITATION_SENDMAIL_REQUEST"
export const INVITATION_SENDMAIL_SUCCESS = "INVITATION_SENDMAIL_SUCCESS"
export const INVITATION_SENDMAIL_ERROR = "INVITATION_SENDMAIL_ERROR"

export const GET_UPDATE_FOOD_CART_REQUEST = "GET_UPDATE_FOOD_CART_REQUEST"
export const GET_UPDATE_FOOD_CART_SUCCESS = "GET_UPDATE_FOOD_CART_SUCCESS"
export const GET_UPDATE_FOOD_CART_ERROR = "GET_UPDATE_FOOD_CART_ERROR"

export const GET_ADD_FOOD_CART_REQUEST = "GET_ADD_FOOD_CART_REQUEST"
export const GET_ADD_FOOD_CART_SUCCESS = "GET_ADD_FOOD_CART_SUCCESS"
export const GET_ADD_FOOD_CART_ERROR = "GET_ADD_FOOD_CART_ERROR"

export const GET_DATA_UPLOAD_REPORT_REQUEST = "GET_DATA_UPLOAD_REPORT_REQUEST"
export const GET_DATA_UPLOAD_REPORT_SUCCESS = "GET_DATA_UPLOAD_REPORT_SUCCESS"
export const GET_DATA_UPLOAD_REPORT_ERROR = "GET_DATA_UPLOAD_REPORT_ERROR"

export const GET_PATTERN_LIST_REQUEST = "GET_PATTERN_LIST_REQUEST"
export const GET_PATTERN_LIST_SUCCESS = "GET_PATTERN_LIST_SUCCESS"
export const GET_PATTERN_LIST_ERROR = "GET_PATTERN_LIST_ERROR"


export const GET_CLINIC_DELETED_REQUEST = "GET_CLINIC_DELETED_REQUEST"
export const GET_CLINIC_DELETED_SUCCESS = "GET_CLINIC_DELETED_SUCCESS"
export const GET_CLINIC_DELETED_ERROR = "GET_CLINIC_DELETED_ERROR"


export const GET_CHANGE_DEMO_REQUEST = "GET_CHANGE_DEMO_REQUEST"
export const GET_CHANGE_DEMO_SUCCESS = "GET_CHANGE_DEMO_SUCCESS"
export const GET_CHANGE_DEMO_ERROR = "GET_CHANGE_DEMO_ERROR"

export const SEND_LOGIN_DETAIL_REQUEST = "SEND_LOGIN_DETAIL_REQUEST"
export const SEND_LOGIN_DETAIL_SUCCESS = "SEND_LOGIN_DETAIL_SUCCESS"
export const SEND_LOGIN_DETAIL_ERROR = "SEND_LOGIN_DETAIL_ERROR"


export const UPLOAD_MULTIFILE_REQUEST = "UPLOAD_MULTIFILE_REQUEST"
export const UPLOAD_MULTIFILE_SUCCESS = "UPLOAD_MULTIFILE_SUCCESS"
export const UPLOAD_MULTIFILE_ERROR = "UPLOAD_MULTIFILE_ERROR"

export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_REQUEST"
export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_SUCCESS"
export const GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR = "GET_CLINIC_DOCUMENT_UPLOAD_LIST_ERROR"

export const ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST = "ADD_CLINIC_DOCUMENT_UPLOAD_REQUEST"
export const ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS = "ADD_CLINIC_DOCUMENT_UPLOAD_SUCCESS"
export const ADD_CLINIC_DOCUMENT_UPLOAD_ERROR = "ADD_CLINIC_DOCUMENT_UPLOAD_ERROR"

export const GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_REQUEST = "GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_REQUEST"
export const GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_SUCCESS = "GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_SUCCESS"
export const GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_ERROR = "GET_UPDATE_CLINIC_DOCUMENT_UPLOAD_ERROR"

export const GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_REQUEST = "GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_REQUEST"
export const GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_SUCCESS = "GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_SUCCESS"
export const GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_ERROR = "GET_CLINIC_DOCUMENT_UPLOAD_CHANGE_STATUS_ERROR"

export const GET_CLINIC_DOCUMENT_LIST_REQUEST = "GET_CLINIC_DOCUMENT_LIST_REQUEST"
export const GET_CLINIC_DOCUMENT_LIST_SUCCESS = "GET_CLINIC_DOCUMENT_LIST_SUCCESS"
export const GET_CLINIC_DOCUMENT_LIST_ERROR = "GET_CLINIC_DOCUMENT_LIST_ERROR"

export const ADD_CLINIC_DOCUMENT_REQUEST = "ADD_CLINIC_DOCUMENT_REQUEST"
export const ADD_CLINIC_DOCUMENT_SUCCESS = "ADD_CLINIC_DOCUMENT_SUCCESS"
export const ADD_CLINIC_DOCUMENT_ERROR = "ADD_CLINIC_DOCUMENT_ERROR"

export const GET_UPDATE_CLINIC_DOCUMENT_REQUEST = "GET_UPDATE_CLINIC_DOCUMENT_REQUEST"
export const GET_UPDATE_CLINIC_DOCUMENT_SUCCESS = "GET_UPDATE_CLINIC_DOCUMENT_SUCCESS"
export const GET_UPDATE_CLINIC_DOCUMENT_ERROR = "GET_UPDATE_CLINIC_DOCUMENT_ERROR"

export const GET_CLINIC_DOCUMENT_CHANGE_STATUS_REQUEST = "GET_CLINIC_DOCUMENT_CHANGE_STATUS_REQUEST"
export const GET_CLINIC_DOCUMENT_CHANGE_STATUS_SUCCESS = "GET_CLINIC_DOCUMENT_CHANGE_STATUS_SUCCESS"
export const GET_CLINIC_DOCUMENT_CHANGE_STATUS_ERROR = "GET_CLINIC_DOCUMENT_CHANGE_STATUS_ERROR"

export const GET_SUPPORTSYSTEM_LIST_REQUEST = "GET_SUPPORTSYSTEM_LIST_REQUEST"
export const GET_SUPPORTSYSTEM_LIST_SUCCESS = "GET_SUPPORTSYSTEM_LIST_SUCCESS"
export const GET_SUPPORTSYSTEM_LIST_ERROR = "GET_SUPPORTSYSTEM_LIST_ERROR"

export const ADD_SUPPORTSYSTEM_REQUEST = "ADD_SUPPORTSYSTEM_REQUEST"
export const ADD_SUPPORTSYSTEM_SUCCESS = "ADD_SUPPORTSYSTEM_SUCCESS"
export const ADD_SUPPORTSYSTEM_ERROR = "ADD_SUPPORTSYSTEM_ERROR"

export const GET_UPDATE_SUPPORTSYSTEM_REQUEST = "GET_UPDATE_SUPPORTSYSTEM_REQUEST"
export const GET_UPDATE_SUPPORTSYSTEM_SUCCESS = "GET_UPDATE_SUPPORTSYSTEM_SUCCESS"
export const GET_UPDATE_SUPPORTSYSTEM_ERROR = "GET_UPDATE_SUPPORTSYSTEM_ERROR"

export const TREATMENT_CHANGE_ORDER_REQUEST = "TREATMENT_CHANGE_ORDER_REQUEST"
export const TREATMENT_CHANGE_ORDER_SUCCESS = "TREATMENT_CHANGE_ORDER_SUCCESS"
export const TREATMENT_CHANGE_ORDER_ERROR = "TREATMENT_CHANGE_ORDER_ERROR"

export const GET_CLINIC_SIMPLE_LIST_REQUEST = "GET_CLINIC_SIMPLE_LIST_REQUEST"
export const GET_CLINIC_SIMPLE_LIST_SUCCESS = "GET_CLINIC_SIMPLE_LIST_SUCCESS"
export const GET_CLINIC_SIMPLE_LIST_ERROR = "GET_CLINIC_SIMPLE_LIST_ERROR"

export const GET_DOWNLOAD_REPORT_LIST_REQUEST = "GET_DOWNLOAD_REPORT_LIST_REQUEST"
export const GET_DOWNLOAD_REPORT_LIST_SUCCESS = "GET_DOWNLOAD_REPORT_LIST_SUCCESS"
export const GET_DOWNLOAD_REPORT_LIST_ERROR = "GET_DOWNLOAD_REPORT_LIST_ERROR"
 
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import Pages from 'Front/containers/Pages/Pages.jsx';
import Homes from 'Front/containers/Homes/Homes.jsx';
import Dash from 'Front/containers/Dash/Dash.jsx';
import ClinicInvitationRequest from 'Front/containers/ClinicInvitationRequest/AddInvitation.jsx';

let defaultLayout = Homes;
if (localStorage.getItem('userType')) {
      defaultLayout = Dash;
}

var appRoutes = [
      { path: "/login", name: "Pages", component: Pages },
      { path: "/forgot-password", name: "Pages", component: Pages },
      { path: "/reset-password/:verificationToken/:email", name: "Pages", component: Pages },

      { path: "/clinic-invitation-request", name: "about", mini: "AB", component: ClinicInvitationRequest },
      { path: "/", name: "Home", component: defaultLayout },
];

export default appRoutes;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import { dietListAction } from 'Front/actions/diet';
import { unitListAction, timeListAction, fileListAction } from 'Front/actions/master';
import { printDietAction, printDietColorChangeAction } from 'Front/actions/diet.js';
//import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import 'Front/views/DietChart/style.css';
//import { DietChartTable } from 'Front/components/DietChartTable/DietChartTable.jsx';
//import * as PF from "Front/views/Home/PublicFunction.jsx"
import { appConstants } from 'Front/_constants/app.constants.js';
//import ReactHighcharts from 'react-highcharts';
//import Comments from 'Front/views/Comments/Comments';


class DietChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            patient: {},
            calorie: null,
            selectCalorie: null,
            calorieType: null,
            treatmentList: [],
            treatmentType: null,
            dietOption: this.props.state.mealType,
            mealType: this.props.state.mealType,
            dietLanguage: this.props.state.dietLanguage,
            treatmentId: "",
            treatmentName: "",
            dietList: null,
            options: {},
            timeList: this.props.timeList ? this.props.timeList : "",
            dietTimePercent: {},
            suggestions: [],
            startTimeDelay: 0,
            printHeader: true,
            clinicName: '',
            clinicAddress: '',
            clinicEmail: '',
            clinicPhone: '',
            mealTime: "",
            color: '',
            dietId: '',
            newColor: '',
            fileList: [],
            commentsList: [],
            commentModal: false
        }

    }

    componentDidMount() {
        this.setState(this.props.state);
        if (!this.state.timeList) {
            this.props.timeListAction();
        }
        this.props.fileListAction();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.timeList) {
            this.setState({ timeList: nextProps.timeList });
        }

        if (nextProps.isFileList !== this.props.isFileList) {
            this.setState({
                fileList: nextProps.fileList.data
            });
        }
    }

    handleChange(data) {
        //var commentsList = [];
        var localindex_index = this.state.timeList.data.comments.map(function (el) {
            return el._id;
        }).indexOf(data._id);
        if (localindex_index !== -1) {
            if (this.state.timeList.data.comments[localindex_index].auto_select) {
                this.state.timeList.data.comments[localindex_index].auto_select = false;
            } else {
                this.state.timeList.data.comments[localindex_index].auto_select = true;
            }
            this.state.commentsList.push(data);
            this.setState(this.state);
            return data;

        }
    }

    handelChangeLang(e, name) {
        let data = {};
        data[name] = e.target.value;
        this.setState(data);
        // return data;
    }

    backPage() {

        this.setState({ graphModal: false });
        this.setState({ commentModal: true });
    }

    render() {

        let graphData = [];
        for (let i in this.props.state.timeWithPercent) {
            let data = {
                name: this.props.state.timeWithPercent[i].name[this.props.state.dietLanguage] + "(" + this.props.state.timeWithPercent[i].time + ")",
                y: this.props.state.timeWithPercent[i].calorie,
                percent: this.props.state.timeWithPercent[i].percent,
            }

            if (this.props.state.timeWithPercent[i].calorie !== 0) {
                graphData.push(data);
            }
        }
        // let config = {
        //     chart: {
        //         type: 'column'
        //     },
        //     title: {
        //         text: 'Diet Pattern - ' + this.props.state.treatmentName + " (" + this.props.state.selectCalorie + " Cal.)"
        //     },

        //     xAxis: {
        //         type: 'category'
        //     },
        //     yAxis: {
        //         title: {
        //             text: 'Calories in %'
        //         }

        //     },
        //     legend: {
        //         enabled: false
        //     },
        //     plotOptions: {
        //         series: {
        //             borderWidth: 0,
        //             dataLabels: {
        //                 enabled: true,
        //                 format: '{point.y} Cal'
        //             }
        //         }
        //     },

        //     tooltip: {
        //         headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        //         pointFormat: '<span style="color:{point.color}">{point.name}</span>: calorie %: <b>{point.percent:.2f}</b> <br/>'
        //     },

        //     series: [
        //         {
        //             colorByPoint: true,
        //             data: graphData
        //         }
        //     ],
        // };

        return (
            <div>
                {/* this.state.graphModal &&
               <ReactHighcharts config = {config}></ReactHighcharts>
            */}
                {this.state.graphModal &&

                    <Grid fluid>
                        <Card content={
                            <Row>

                                {/*<Col sm={8}>
                            </Col>
                          <div className="bt-rights">
                              <Col sm={2}>
                                <Button onClick={e => { this.props.backPage()}} className="btn-fill btn btn-primary">Back</Button>
                              </Col>
                              <Col sm={2}>
                                  <Button onClick={e=>{this.props.dietChart()}} disabled={this.state.treatmentId?false:true} className="btn-fill btn btn-primary">Diet Chart</Button>
                              </Col>
                           </div> */}

                                <Col sm={12} className="mange-spctop">
                                    <Row>
                                        {/* <iframe src={appConstants.paAppURL + "diet_graph_webview?id=5dde240366c10ed40d0b1f3c&lang=english"} width="100%" height="600px" title="diet_graph_webview">
                                            <p>Your browser does not support iframes.</p>
                                        </iframe> */}
                                        <object data={`${appConstants.paAppURL}diet_graph_webview?id=5dde240366c10ed40d0b1f3c&lang=english`} style={{ width: "100%", height: "calc(100vh - 48px)" }}>
                                            <p>Your browser does not support iframes.</p>
                                        </object>
                                    </Row><br></br>
                                </Col>

                            </Row>
                        } />
                    </Grid>
                }
                {/* { this.state.commentModal &&
                    <Comments state={this.state} backPage={this.backPage} dietId={this.state.dietId} color={this.state.color} />
                } */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        dietList: state.diet.dietList,
        unitList: state.master.unitList,
        timeList: state.master.timeList,

        printDiet: state.diet.printDiet,
        isDietPrint: state.diet.isDietPrint,
        isDietPrintError: state.diet.isDietPrintError,

        isDietPrintColor: state.diet.isDietPrintColor,
        isDietPrintColorError: state.diet.isDietPrintColorError,
        printDietColor: state.diet.printDietColor,

        isFileList: state.master.isFileList,
        isFileListError: state.master.isFileListError,
        fileList: state.master.fileList
    }
}
export default withRouter(connect(mapStateToProps, { dietListAction, unitListAction, fileListAction, timeListAction, printDietAction, printDietColorChangeAction })(DietChart));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useRef, useEffect } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Modal,
  ControlLabel,
  FormGroup,
  Col,
  Row,
} from "react-bootstrap";
import moment from "moment";
import InsulinDose from "../TaskScreen/InsulinDose";
import CalendarIc from "../../assets/img/icons/calendar_white.png";
import PrescriptionIc from "../../assets/img/icons/prescription_white.png";
import medicalReportIc from "../../assets/img/icons/lab_report_white.png";
import NextVisit from "Front/views/Home/NextVisitPopup.jsx";
import { Link } from "react-router-dom";
import { style } from "Admin/variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "Front/actions/actionTypes";
import { patientVisitDetail, s3SignedUrlArray } from "Front/api/home";
import * as API from "Front/api/home";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { postRequestWithToken } from "../../api/helper.js";
import DietIc from "../../assets/img/icons/diet.png";
import DietChat from "Front/views/DietChart/DietChart";

const PatientMoreDetailEducator = (props) => {

  const request = require("request");
  const [visitDetail, setVisitDetail] = useState({ ...props.moreDetails });
  const homeReduxData = useSelector((store) => store.home);

  const data = useSelector((store) => store.taskScreen);
  const [nextVisitData, setNextVisitData] = useState([]);
  const [nextVisit, setNextVisit] = useState(false);
  const [patientOut, setPatientOut] = useState(false);
  const notificationSystem = useRef(null);
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);
  const [selectedATags, setSelectedATags] = useState([
    ...props.moreDetails.patientATag,
  ]);
  const [selectedBTags, setSelectedBTags] = useState([
    ...props.moreDetails.patientBTag,
  ]);
  const [selectedCTags, setSelectedCTags] = useState([
    ...props.moreDetails.patientCTag,
  ]);
  const [mode, setMode] = useState("Add");
  const [starStaus, setStartStatus] = useState(
    props.moreDetails.patientStarStatus.toString()
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showInformation, setShowInformation] = useState(true);
  const [knowledgeContent, setKnowledgeContenet] = useState("");
  const [appContent, setAppContenet] = useState("");
  const [patientDietContent, setPatientDietContenet] = useState("");
  const [dietChatModal, setDietChatModal] = useState(false);
  const [dietChartTableData, setDietChratTableData] = useState({
    visitId: "",
    patientId: "",
    app: "",
    notification_token: "",
    remark: "",
    type: "",
    treatmentType: "",
    optionCount: "",
    dietType: "",
    dietLanguage: "",
    treatmentId: "",
    treatmentName: "",
    startTimeDelay: 0,
    dietId: "",
    color: "",
    patient: {},
    dietOption: 2,
    mealType: "veg-s",
    selectCalorie: 0,
    email: "",
    firstName: "",
    comments: [],
    file: [],
    mealTime: "09:00 am",
    ptVisitDate: "",
    shareDate: "",
  });
  const [dietId, setDietId] = useState("");
  const [only5Tags, setOnly5Tags] = useState(false)
  const [selectedTagsId, setSelectedTagsId] = useState([])
  const [patientAppLag, setPatientAppLang] = useState(1)
  const [brandType, setBrandType] = useState(
    localStorage.getItem("is_brand_with_ingredients_disable") == "yes"
      ? "brand"
      : "barndwithingredients"
  );
  const getVisitDetail = async () => {
    let visitlist =
      visitDetail.activeTab === "offline"
        ? homeReduxData.VisitList
        : visitDetail.activeTab === "online"
          ? homeReduxData.eVisitList
          : homeReduxData.outPatientList;

    let patient = visitlist.find((obj) => obj.id === visitDetail.id);
    if (!patient.isVisitDetailApiCalled) {
      let response = await patientVisitDetail({
        visitId: visitDetail.id,
        search_date: props.search_date,
      });
      if (response.data.statusCode == 200) {

        let res = response.data.data.data[0];
        let visitData = visitDetail;
        //visitData.activeTab=data.visitData.activeTab
        //  visitData.emrVisitId=data.visitData.emrVisitId
        //  visitData.pId=data.visitData.pId
        visitData.lastDiet = res.patient_data[0].lastDiet;
        visitData.language = res.patient_data[0].language;
        visitData.patientStatus = res.patientStatus;
        visitData.flag = res.flag;
        visitData.taskList = res.Tasks;
        visitData.documentList = res.Documents;
        visitData.dietHistory = res.dietHistory;
        visitData.complete_task = res.complete_tasks_count;
        visitData.share = res.doc_count + " / " + res.read_docs_count;
        visitData.read_share = res.read_docs_count;
        visitData.task = res.task_count + " / " + res.complete_tasks_count;
        visitData.validPrescription = res.validPrescription;
        visitData.reportCount = res.hasOwnProperty("reportCount")
          ? res.reportCount
          : visitData.reportCount;
        visitData.nextDate = res.patient_data[0].nextDate;
        visitData.next_visit_date = res.patient_data[0].nextDate;
        setPatientAppLang(visitData.language)
        setVisitDetail({ ...visitData });
        dispatch({
          type: actionTypes.UPDATE_PATIENT_VISIT_DETAIL,
          payload: visitData,
        });
      }
    } else {

      setPatientAppLang(patient.language)
      setVisitDetail(patient);
    }

  };

  useEffect(() => {

    setTagList([...homeReduxData.TagList]);
    findSelectedTags(homeReduxData.TagList);
  }, [homeReduxData.TagList]);

  const getS3SignedUrl = async (payload) => {
    await dispatch({ type: actionTypes.S3_SIGNED_URL_ARRAY_REQUEST });
    let response = await s3SignedUrlArray({ imageArray: payload });
    if (response.data.statusCode == 200) {
      dispatch({
        type: actionTypes.S3_SIGNED_URL_ARRAY_SUCCESS,
        payload: { response },
      });
    }
  };

  useEffect(() => {
    let tagList = homeReduxData.TagList;
    if (tagList.length > 0) {
      let payload = [];
      for (let i in tagList) {
        let obj = { filePath: tagList[i].image, id: tagList[i]._id };
        payload.push(obj);
      }
      getS3SignedUrl(payload);
    }

    if (
      props.moreDetails.patientATag.length > 0 ||
      props.moreDetails.patientBTag.length > 0 ||
      props.moreDetails.patientCTag.length > 0 ||
      props.moreDetails.patientStarStatus.toString() != "0"
    ) {
      setMode("Edit");
      setIsDisabled(false);
      setShowInformation(false);
    }
    if (!visitDetail.isVisitDetailApiCalled) {
      getVisitDetail();
    } else {
      let visitlist =
        visitDetail.activeTab === "offline"
          ? homeReduxData.VisitList
          : visitDetail.activeTab === "online"
            ? homeReduxData.eVisitList
            : homeReduxData.outPatientList;
      let patient = visitlist.find((obj) => obj.id === visitDetail.id);

      setPatientAppLang(patient.language)
    }
  }, []);

  const isApp = (row) => {
    let userType = localStorage.getItem("userType");


    let strAppContent = "";
    let lastDiet =
      row && row.lastDiet && row.lastDiet.length > 0
        ? row.lastDiet[0].readStatus
        : "";

    //var readStatus = 'read';
    var patientReadStatus = "read";
    for (let i in row.documentList) {
      if (row.documentList[i].patientRead === "unread") {
        patientReadStatus = "unread";
      }
    }

    var html = "";
    //console.log(lastDiet,"chklastDiet")
    if (lastDiet && lastDiet.length > 0) {
      if (lastDiet === "unread") {
        row["remarkHtml"] = "not seen by patient";
        if (row.remarkHtml !== "") {
          html = (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
            >
              <span className="badge bgred drs-left  mobile-red-clr">
                <span className="fa fa-cutlery" aria-hidden="true"></span>
              </span>
            </OverlayTrigger>
          );
        }
      } else {
        row["remarkHtml"] = "seen by patient";
        if (row.remarkHtml !== "") {
          html = (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
            >
              <span className="badge bgred drs-left mobile-green-clr">
                <span className="fa fa-cutlery" aria-hidden="true"></span>
              </span>
            </OverlayTrigger>
          );
        }
      }
    }

    if (userType === "receptionist") {
      setAppContenet(html);
      return;
    }

    //   let insulinCal = <InsulinDose visitInfo={row} callType="main"/>

    if (row.documentList["0"]) {
      strAppContent = (
        <span
          className="doc-label-flex"
          style={{ justifyContent: "flex-start" }}
        >
          <div className="doc-label">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="Name">
                  <b>
                    SHARE:
                    <br />
                    {row.documentList.map((value, key) => {
                      return (
                        <span
                          className={
                            value.patientRead === "read"
                              ? "green"
                              : value.addedByType === "educator"
                                ? "pink"
                                : "red"
                          }
                        >
                          <b>{key + 1}. </b>
                          {value.documentName}
                          <br />
                        </span>
                      );
                    })}
                  </b>
                </Tooltip>
              }
            >
              <span
                className={
                  patientReadStatus === "read" ? "badge bggreen" : "badge bgred"
                }
              >
                {row.documentList.length}
              </span>
            </OverlayTrigger>
            {html}
          </div>
        </span>
      );
      setAppContenet(strAppContent);
    } else {
      strAppContent = (
        <span className="doc-label-flex">
          <div className="doc-label">{html}</div>
        </span>
      );
      setAppContenet(strAppContent);
    }
  };

  const nextButton = (row) => {
    let html = "";
    html = (
      <>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="NextVisit">
              <b>
                Next Visit
                <br />
                {
                  <span>
                    {row.next_visit_date !== null && row.next_visit_date
                      ? moment(row.next_visit_date).format("DD-MM-YYYY")
                      : ""}
                  </span>
                }
              </b>
            </Tooltip>
          }
        >
          <span
            onClick={() => {
              setNextVisitData(row);
              setNextVisit(true);
              setPatientOut(false);
            }}
          >
            {" "}
            <img className="table-action-ic" src={CalendarIc} alt="" />{" "}
          </span>
        </OverlayTrigger>
      </>
    );
    return html;
  };

  const emr = (row) => {
    let html = "";
    //   if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {

    //     }
    let emrData = {
      patientId: row._id,
      isEmr: row.patientStatus === "old" && row.flag === "yes" ? true : false,
    };

    html = (
      <Link
        className=""
        onClick={(e) => {
          localStorage.setItem("patientVisit", JSON.stringify(row));
          localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
          localStorage.setItem("is_Visit_Created", true);
          // this.props.taskScreenAction(row);
          // this.props.emrSetAction(emrData);
        }}
        to={{
          pathname:
            row.patientStatus === "old"
              ? row.flag === "yes"
                ? row.validPrescription.toString() === "1"
                  ? `print/${row.id}/${row.pId}/${brandType}/${patientAppLag}`
                  : `prescription/${row.id}/${row.pId}/${patientAppLag}`
                : `vital-sign/${row.id}/${row.pId}/${patientAppLag}`
              : `vital-sign/${row.id}/${row.pId}/${patientAppLag}`,
          state: { row },
        }}
      >
        <img
          alt="Prescription"
          className="table-action-ic"
          title="Prescription"
          src={PrescriptionIc}
        ></img>
      </Link>
    );
    return html;
  };

  const renderViatalSigns = (row) => {
    let html = "";
    html = (
      <Link
        className=""
        to={{
          pathname: `vital-sign/${row.id}/${row.pId}/${patientAppLag}`,
          state: { row },
        }}
      >
        <img
          alt="Lab Details"
          className="table-action-ic"
          title="Lab Details"
          src={medicalReportIc}
        ></img>
      </Link>
    );
    return html;
  }


  const patientVisitOut = (id, row) => {
    setNextVisit(false);
    let msg = "Patient Out Successfully";
    successAlert(msg);
  };

  const onDismissNextVisit = () => {
    setNextVisit(false);
    let msg = "Next Visit Successfully Submitted";
    successAlert(msg);
  };

  const successAlert = (msg) => {
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  };

  const dietDetail = (row) => {
    var html = "";
    if (row.documentList["0"]) {
      row["remarkHtml"] = row && row.remark ? row.remark : "";
      if (row.remarkHtml !== "") {
        html = (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
          >
            <span className="badge bgred drs-left">
              <span className="glyphicon" aria-hidden="true">
                Dr
              </span>
            </span>
          </OverlayTrigger>
        );
      }
    }

    var remarkP = "";

    if (
      row.patientRemark &&
      row.patientRemark !== "" &&
      row.created_date === moment(row.createDate).format("YYYY-MM-DD")
    ) {
      remarkP = (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}
        >
          <span className="badge bgred drs-left">
            <span className="glyphicon" aria-hidden="true">
              R
            </span>
          </span>
        </OverlayTrigger>
      );
    }

    let dietContent = "";
    row.lastDiet.length != 0
      ? (dietContent = (
        <Link onClick={(e) => getRedirect(row)}>
          <img
            className="table-action-ic"
            alt="Diet"
            title="Diet"
            src={DietIc}
          ></img>
        </Link>
      ))
      : (dietContent = "");


    let strDietContent = "";
    if (dietContent != "" || html != "" || remarkP != "") {
      strDietContent = (
        <div className="diet-knowledge-task">
          {dietContent != "" && (
            <span>
              <label>Diet</label>
              <div style={{ display: "flex", gap: "5px" }}>{dietContent}</div>
            </span>
          )}
          {/* {(html != "" || remarkP != "") && (
            <span>
              <label>Remark</label>
              <div style={{ display: "flex", gap: "5px" }}>
                {html}
                {remarkP}
              </div>
            </span>
          )} */}
        </div>
      );
    }

    setPatientDietContenet(strDietContent);
  };

  const getRedirect = (row) => {
    let tempDietChartTableData = {};
    let tempPatienData = {
      name: row.name,
      patientId: row._id,
      age: row.age,
      weight: row.weight,
      height: row.height,
      gender: row.gender,
      patientClinicId: row.patientClinicId,
    };
    tempDietChartTableData.visitId = row.id;
    tempDietChartTableData.patientId = row._id;
    tempDietChartTableData.patientClinicId = row.patientClinicId;
    tempDietChartTableData.app = row.app;
    tempDietChartTableData.notification_token = row.notification_token;
    tempDietChartTableData.remark = row.remark;
    tempDietChartTableData.type = "";
    tempDietChartTableData.treatmentType = row.lastDiet[0].treatmentType;
    tempDietChartTableData.optionCount = row.lastDiet[0].optionCount;
    tempDietChartTableData.dietType = row.lastDiet[0].dietType;
    tempDietChartTableData.dietLanguage = row.lastDiet[0].dietLanguage;
    tempDietChartTableData.treatmentId = row.lastDiet[0].treatmentId;
    tempDietChartTableData.treatmentName = row.lastDiet[0].treatmentName;
    tempDietChartTableData.startTimeDelay = row.lastDiet[0].startTimeDelay;
    tempDietChartTableData.dietId = row.lastDiet[0]._id;
    tempDietChartTableData.color = row.lastDiet[0].color;
    tempDietChartTableData.patient = tempPatienData;
    tempDietChartTableData.dietOption = 2;
    tempDietChartTableData.mealType = row.lastDiet[0].dietType;
    tempDietChartTableData.selectCalorie = row.lastDiet[0].calorie;
    tempDietChartTableData.email = row.email;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.comments = row.lastDiet[0].comments;
    tempDietChartTableData.firstName = row.firstName;
    tempDietChartTableData.mealTime = row.lastDiet[0].mealTime;
    tempDietChartTableData.ptVisitDate = row.lastDiet[0].createdAt;
    tempDietChartTableData.shareDate = row.lastDiet[0].isShareDate;
    tempDietChartTableData.dietLanguage = row.language ? row.language : "1";
    tempDietChartTableData.selectedComments = row.lastDiet[0].comments;
    setDietChratTableData(tempDietChartTableData);
    setDietId(row.lastDiet[0]._id);
    setDietChatModal(true);
  };

  const knowledgeDetial = (row) => {
    let readStatus = "read";
    for (let i in row.documentList) {
      if (row.documentList[i].status === "unread") {
        readStatus = "unread";
      }
    }
    let strHtml = "";

    if (row.documentList.length > 0) {
      strHtml = (
        <div className="doc-label">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="Name">
                <b>
                  SHARE:
                  <br />
                  {row.documentList.map((value, key) => {
                    return (
                      <span
                        className={
                          value.status === "read"
                            ? "green"
                            : value.addedByType === "educator"
                              ? "pink"
                              : row.app
                                ? "blue"
                                : "red"
                        }
                      >
                        <b>{key + 1}. </b>
                        {value.documentName}
                        <br />
                      </span>
                    );
                  })}
                </b>
              </Tooltip>
            }
          >
            <span
              className={
                readStatus === "read" ? "badge bggreen" : "badge bgred"
              }
            >
              {/*<span className="glyphicon glyphicon-share-alt" aria-hidden="true"></span>*/}
              {row.documentList.length}
            </span>
          </OverlayTrigger>
        </div>
      );
      setKnowledgeContenet(strHtml);
    }
  };

  const addOrDeleteTag = (e, tag) => {
    if (e.target.checked) {
      if (
        selectedATags.length + selectedBTags.length + selectedCTags.length + 1 >
        5
      ) {
        // let ele = document.getElementById(tag._id)
        // if (ele !== null)
        //   ele.checked = false
        setOnly5Tags(true);
        return;
      }

      let tags = [];
      tags.push({
        tagId: tag._id,
        tagName: tag.title,
        s3Path: tag.s3Path,
        createdAt: new Date(),
      });

      let tempSelectedTagsId = selectedTagsId;
      tempSelectedTagsId.push(tag._id);

      setSelectedTagsId([...tempSelectedTagsId]);

      if (tag.type === "a") setSelectedATags([...tags, ...selectedATags]);

      if (tag.type === "b") setSelectedBTags([...tags, ...selectedBTags]);

      if (tag.type === "c") setSelectedCTags([...tags, ...selectedCTags]);

      setIsDisabled(false);
      setShowInformation(false);

      if (
        tags.length +
        selectedATags.length +
        selectedBTags.length +
        selectedCTags.length +
        1 >
        5
      )
        setOnly5Tags(true);
    } else {
      deleteTag(tag);
    }
  };

  const deleteTag = (tag) => {
    setOnly5Tags(false);
    const index = selectedTagsId.indexOf(tag._id);
    if (index > -1) {
      selectedTagsId.splice(index, 1);
    }
    let arr =
      tag.type === "a"
        ? selectedATags
        : tag.type === "b"
          ? selectedBTags
          : selectedCTags;
    arr.map((itm, idx) => {
      if (itm.tagId === tag._id) {
        arr.splice(idx, 1);
        return;
      }
    });

    if (mode === "Add") {
      if (tag.type === "a") {
        setSelectedATags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedBTags.length === 0 &&
          selectedCTags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
      if (tag.type === "b") {
        setSelectedBTags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedATags.length === 0 &&
          selectedCTags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
      if (tag.type === "c") {
        setSelectedCTags([...arr]);
        if (
          starStaus === "0" &&
          arr.length === 0 &&
          selectedBTags.length === 0 &&
          selectedATags.length === 0
        ) {
          setIsDisabled(true);
          setShowInformation(true);
        } else {
          setIsDisabled(false);
          setShowInformation(false);
        }
      }
    }
  };

  const handleSubmit = async (e) => {

    setIsDisabled(true);
    setBtnClicked(true);
    e.preventDefault();

    if (mode === "Add") {
      if (selectedATags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          patientTag: selectedATags,
        };

        let response = await API.addPatientATypeTag(payloadData);

        //hideModal();
      }
      if (selectedBTags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          visitId: visitDetail.id,
          patientBTag: selectedBTags,
        };
        let response = await API.addPatientBTypeTag(payloadData);
      }

      if (selectedCTags.length > 0) {
        let payloadData = {
          patientId: visitDetail.pId,
          visitId: visitDetail.id,
          patientCTag: selectedCTags,
        };

        let response = await API.addPatientCTypeTag(payloadData);
      }
      if (starStaus !== "0") {
        let payloadData = {
          patientId: visitDetail.pId,
          starStatusId: starStaus,
        };
        let response = await API.addPatientStarStatus(payloadData);
      }
    } else {
      let payloadData = {
        patientId: visitDetail.pId,
        patientTag: selectedATags,
      };

      let response = await API.addPatientATypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        visitId: visitDetail.id,
        patientBTag: selectedBTags,
      };
      response = await API.addPatientBTypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        visitId: visitDetail.id,
        patientCTag: selectedCTags,
      };

      response = await API.addPatientCTypeTag(payloadData);

      payloadData = {
        patientId: visitDetail.pId,
        starStatusId: starStaus,
      };
      response = await API.addPatientStarStatus(payloadData);
    }

    setBtnClicked(false);
    setIsDisabled(false);
    setMode("Edit");
    props.onDismissMoreDetail();
    //successAlert("Data Saved Successfully")
  };

  // const handleStarStaus = (e) => {
  //   e.preventDefault();
  //   setStartStatus(e.target.value);
  //   if (
  //     e.target.value === "0" &&
  //     selectedATags.length === 0 &&
  //     selectedBTags.length === 0 &&
  //     selectedCTags.length === 0
  //   ) {
  //     setShowInformation(true);
  //     setIsDisabled(true);
  //   } else {
  //     setIsDisabled(false);
  //     setShowInformation(false);
  //   }
  // };

  const handleStarStaus = (id) => {
    setStartStatus(id);
    setIsDisabled(false);
    setShowInformation(false);
  };

  const findSelectedTags = (tagListArr) => {

    tagListArr.map((tag) => {
      let selectedTags = selectedATags.concat(selectedBTags);
      selectedTags = selectedTags.concat(selectedCTags);
      let tagsId = selectedTags.map(function (tag) {
        return tag.tagId;
      });
      setSelectedTagsId([...tagsId]);
      if (selectedTags.length === 5) setOnly5Tags(true);
      selectedTags.map((item) => {
        if (item.tagId === tag._id) {
          selectTag(item.tagId);
        }
        //setArrInstruction([...arrInstruction])
      });
    });
  };

  const selectTag = (tagId) => {
    let chkTags = document.getElementsByName("tag");
    for (var tag of chkTags) {
      if (tagId === tag.getAttribute("id")) {
        tag.checked = true;
      }
    }
  };

  useEffect(() => {
    knowledgeDetial(props.moreDetails);
    isApp(props.moreDetails);
    dietDetail(props.moreDetails);
  }, [props]);

  // useEffect(()=>{

  // },[props.moreDetails.share])

  // useEffect(()=>{

  // },[props.moreDetails.documentList])

  // useEffect(()=>{

  // },[props.moreDetails.lastDiet])

  const backPage = () => {
    setDietChatModal(false);
  };

  const changeMealTime = (time) => {
    let formData = dietChartTableData;
    formData["mealTime"] = time;
    setDietChratTableData({ ...formData });
  };

  return (
    <div>
      <NotificationSystem ref={notificationSystem} style={style} />

      <div className="more-info-sec star-modal">
        <div className="labels-sec">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              {only5Tags && (
                <span style={{ color: "red" }}>
                  Maximum 5 Tags Have Been Assigned.
                </span>
              )}
            </div>
            {/* <div className="col-xs-12 col-sm-6">
                <h4
                  style={{
                    margin: "0 !important",
                    fontSize: "18px",
                    margin: "0 !important",
                    fontWeight: "600",
                  }}
                >
                  Labels
                </h4>
              </div> */}
            <div className="col-sm-12 col-lg-6">
              <form className="text-right">
                <FormGroup>
                  {showInformation && (
                    <span style={{ color: "red", marginRight: '10px' }}>
                      Please Select Any Of The Above Icons Or Star Status To
                      Save
                    </span>
                  )}
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    disabled={isDisabled}
                    className="btn btn-fill btn-primary"
                  >
                    {" "}
                    Save
                    {btnClicked && <i class="fa fa-spinner fa-spin"></i>}
                  </Button>
                </FormGroup>
              </form>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-1">
              <div className="labels-sec-card">
                <div className="star-status">
                  <label>Star Status</label>
                  {/* <select value={starStaus} onChange={(e) => handleStarStaus(e)}>
                <option>Select</option>
                {homeReduxData.starStatusList &&
                  homeReduxData.starStatusList.map((ele, key) => {
                    return <option value={ele._id}>{ele.title}</option>;
                  })}
              </select> */}
                  {homeReduxData.starStatusList &&
                    homeReduxData.starStatusList.map((ele, key) => {
                      return (
                        <div className="divStarStatus" key={ele._id}>
                          <input
                            onClick={(e) => handleStarStaus(ele._id)}
                            type="radio"
                            name={`radio-${ele._id}`}
                            checked={ele._id === starStaus}
                            id={ele._id}
                          />
                          <label for={ele._id}>{ele.title}</label>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="labels-sec-card">
                <label>Permanent</label>
                <div className="tags-list">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "a")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span style={{ fontSize: '13px' }} title={tag.title}>{tag.title}</span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-3">
              <div className="labels-sec-card">
                <label>Valid till next visit</label>
                <div className="tags-list">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "b")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span style={{ fontSize: '13px' }} title={tag.title}>{tag.title}</span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5">
              <div className="labels-sec-card">
                <label>Valid for 3 days</label>
                <div className="tags-list three-tags">
                  {tagList &&
                    tagList
                      .filter((obj) => obj.type === "c")
                      .map((tag, key) => {
                        return (
                          <div class="tags-check" id={key}>
                            <input
                              type="checkbox"
                              disabled={
                                only5Tags
                                  ? selectedTagsId.includes(tag._id)
                                    ? false
                                    : true
                                  : false
                              }
                              onChange={(e) => {
                                addOrDeleteTag(e, tag);
                              }}
                              value={tag.title}
                              name="tag"
                              id={tag._id}
                            />
                            <label class="label" for={tag._id}>
                              <img
                                src={tag.s3Path}
                                alt="tags"
                                title={tag.title}
                                width={20}
                                style={{ maxHeight: "20px" }}
                              />
                              <span style={{ fontSize: '13px' }} title={tag.title}>{tag.title}</span>
                              {/* <span style={{"color":"red"}}>{tag.title}</span> */}
                            </label>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          {(patientDietContent || knowledgeContent) && (
            <div className="more-info-sec diet-knowledge-task">
              {patientDietContent && <span>{patientDietContent}</span>}

              {knowledgeContent && (
                <span>
                  <label>Knowledege</label>
                  {knowledgeContent}
                </span>
              )}
            </div>
          )}
        </div>
        {appContent && (
          <div className="col-xs-12 col-sm-2">
            <div className="more-info-sec">
              <label>App</label>
              <span>{appContent}</span>
            </div>
          </div>
        )}
        <div className="col-xs-12 col-sm-2">
          <div className="more-info-sec">
            <label>Action</label>
            <div className="action-badges">
              <span className="badge">{nextButton(visitDetail)}</span>
              <span className={"badge"}>{(renderViatalSigns(props.moreDetails))}</span>
              <span
                className={`badge ${visitDetail.patientStatus === "old"
                  ? visitDetail.flag === "yes"
                    ? visitDetail.validPrescription.toString() === "1"
                      ? "prescritption-completed"
                      : "prescritption-incompleted"
                    : "prescritption-not-made"
                  : "prescritption-not-made"
                  }`}
              >
                {emr(visitDetail)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        backdrop="static"
        className="pa-next-visit"
        show={nextVisit}
        onHide={() => setNextVisit(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {"Set Next Visit"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share ">
          <NextVisit
            nextVisitData={nextVisitData}
            patientOutFun={patientVisitOut}
            patientOut={patientOut}
            onDismissNextVisit={onDismissNextVisit}
            econsultation={visitDetail.econsultation}
            activeTab={visitDetail.activeTab}
          />
        </Modal.Body>
      </Modal>

      <Modal
        backdrop="static"
        className="pa-task-screen"
        show={dietChatModal}
        onHide={backPage}
        dialogClassName="modal-lg"
      >
        <button type="button" className="close" onClick={backPage}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <Modal.Body className="Knowledge-Share ">
          <Row>
            <Col md={12}>
              {dietChatModal && (
                <DietChat
                  state={dietChartTableData}
                  backPage={backPage}
                  dietId={dietId}
                  onClickTime={changeMealTime}
                  color={dietChartTableData.color}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PatientMoreDetailEducator;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { postRequestWithToken } from "../api/helper.js"
const ViewBox = ({ data, hideViewBox }) => {

  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState('image');
  const [rotateDeg, setRotateDeg] = useState(0);
  const [imagePath, setImagePath] = useState('');
  const transformComponentRef = useRef(null);

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {

    setShow(false);
    hideViewBox();
  };

  const rotateImg = () => {
    let deg = ((rotateDeg == 270) ? 0 : (rotateDeg + 90));
    setRotateDeg(deg);
  }

  const getSignedUrl = async (path) => {
    let resp = await postRequestWithToken({ filePath: path }, 's3SignedUrl');
    setImagePath(resp.data.url)
  };

  const handleMoveTo = () => {
    if (transformComponentRef.current) {
      const { centerView } = transformComponentRef.current;
      centerView(1, 0, 0);
    }
  };

  useEffect(() => {
    let type = 'image';
    let arr = data.path.split('.');
    let output = arr.pop();
    if (output === "mp4") {
      type = "video";
    } else if (output === "pdf") {
      type = "pdf";
    } else {
      type = "image";
    }
    setReportType(type);
    handleOpen();
    getSignedUrl(data.path)
  }, []);

  return (
    <>
      <Modal show={show} onHide={e => handleClose()} dialogClassName="modal-100w modal_custom">

        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">{data.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="Knowledge-Share card text-center 10000000">
          {reportType === "video" &&
            <div>
              <ReactPlayer className='react-player' width='100%' height='100%' url={imagePath} playing controls="true" volume="1" />
            </div>
          }
          {reportType === "image" &&
            <div style={{ "padding": "10px" }}>
              <TransformWrapper
                ref={transformComponentRef}
                initialScale={1}
                centerContent={true}
                onInit={() => handleMoveTo()}
              >
                {({ zoomIn, zoomOut, ...rest }) => (
                  <React.Fragment>
                    <div className="tools">
                      <button className='btn btn-primary btn btn-default' onClick={() => zoomIn()}>+ ZOOM</button>

                      <button className='btn btn-primary  btn btn-default' onClick={() => zoomOut()}>- ZOOM</button>
                      <button className='btn btn-primary btn-rotate btn btn-default' onClick={() => rotateImg()}> Rotate</button>
                    </div>
                    <TransformComponent>
                      <div className="draggable-element"
                        style={{ width: "800px", height: "600px", background: "lightgray", display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <img id="target" src={imagePath} alt='Image' className={`rotate-${rotateDeg}`} style={{ display: 'block', margin: '0 auto' }} />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>

            </div>
          }
          {reportType === "pdf" &&
            <div>
              {/* <a href={ data.path} target="_blank"> Download pdf</a>
                               {data.path} */}
              <embed type="text/html" src={`${imagePath}#view=fitH`} style={{ width: "100%", height: "calc(100vh - 48px)" }}></embed>
              {/* <object data={`${imagePath}#view=fitH`} style={{ width: "100%", height: "calc(100vh - 48px)" }}></object> */}
            </div>
          }

        </Modal.Body>
        {/* <Modal.Footer>
              <button type="button" onClick={e => handleClose()} className="btn-fill btn-wd btn btn-default">Close</button>&nbsp;
          </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default ViewBox;
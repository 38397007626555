/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import UserPage from 'Front/views/Pages/UserPage.jsx';
//import HomesPage from 'Front/views/Homes/HomesPage.jsx';
import pagesRoutes from './pages.jsx';
//import homesRoutes from './homes.jsx';

var pages = [{ path: "/pages/user-page", name: "User Page", mini: "UP", component: UserPage }].concat(pagesRoutes);
//var homes = [{ path: "/homes/home-page", name: "User Page", mini: "UP", component: HomesPage }].concat(homesRoutes);

var dashRoutes = [

    {
        collapse: true, path: "/pages", name: "Pages", state: "openPages", icon: "pe-7s-gift", views:
            pages
    },
    // { collapse: true, path: "/homes", name: "Homes", state: "openHomes", icon:"pe-7s-gift", views:
    //     homes
    // },
    // { redirect: true, path: "/", pathTo: "/login", name: "Dashboard" },
];
export default dashRoutes;

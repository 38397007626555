/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Grid, Row, Col, Table, Modal
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Front/components/Card/Card.jsx';
import { taskListAction, changeStatusAction, printShareDocumentAction } from 'Front/actions/home';
import VideoDocumentView from 'Front/views/TaskScreen/VideoDocumentView.jsx';
import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import KnowledgeShare from 'Front/views/TaskScreen/KnowledgeShare.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import History from 'Front/views/TaskScreen/History.jsx';
import Diet from 'Front/views/Diet/Diet';
import { getBMI } from 'Front/views/Home/PublicFunction.jsx';
import moment from 'moment';
import { appConstants } from 'Front/_constants/app.constants.js';
import loadingImg from '../../assets/img/loading.gif';
import TaskScreen from 'Front/views/TaskScreen/TaskScreenPopup.jsx';
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
var isKnowledgeShared = false;
class EducatorTaskScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            noWork: false,
            formArr: [],
            date: new Date(),
            visitInfo: this.props.row,
            lastDiet: false,
            showProcessing: false,
            visit_id: '',
            TaskList: [],
            alert: null,
            classstatus: true,
            currentPatient: '',
            dietTitle: '',
            dietModal: false,
            formData: {
                visit_id: '',
                patient_id: '',
                documents: [],
                videos: [],
                type: 'share',
            },
            dietStatus: false,
            documentUpdate: false,
            colorType: '',
            taskScreen: false,
            taskScreenData: [],
            dietFromPtSeatch: true,
            dietFromEducator: false,
            edToggleStatus: true,


        };

        this.updateDocuments = this.updateDocuments.bind(this);
        this.onDismiss = this.onDismiss.bind(this);

        let _this = this;
        appConstants.socket.on('updateTaskScreen', function (responce) {
            setTimeout(function () {
                _this.setState({ documentUpdate: true });
            }, 1000);
        });

    }

    componentDidMount() {

        this.noWorkingPening(this.props.row);
        //this.setState({ showProcessing: false });
    }

    componentWillReceiveProps(nextProps) {
        let visitInfo = nextProps.row;


        if (nextProps.isVisitDetail !== this.props.isVisitDetail) {
            let tempTaskList = this.state.TaskList
            tempTaskList.documents = nextProps.visitDetail.Documents
            tempTaskList.tasks = nextProps.visitDetail.Tasks
            let tempLastDiet = nextProps.visitDetail.patient_data[0].lastDiet
            let documents = nextProps.visitDetail.Documents.filter((obj) => obj.docType === "documents")
            for (let i in documents) {
                documents[i].id = documents[i].documentId
            }
            let videos = nextProps.visitDetail.Documents.filter((obj) => obj.docType === "videos")
            for (let i in videos) {
                videos[i].id = videos[i].documentId
            }
            //let videos=nextProps.visitDetail.Documents.filter((obj)=>ovj.docType==="videos")
            this.setState({ TaskList: tempTaskList, lastDiet: tempLastDiet });
            let tempFormData = this.state.formData
            tempFormData.documents = documents
            tempFormData.videos = videos

            this.setState({ formData: tempFormData })

        }

        if (nextProps.isKnowledgeShared !== this.props.isKnowledgeShared) {

            let tempTaskList = this.state.TaskList
            let obj = nextProps.VisitList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                tempTaskList.documents = obj.documentList
            else {
                obj = nextProps.eVisitList.find(obj => obj.id === this.state.visit_id);
                if (obj != undefined)
                    tempTaskList.documents = obj.documentList
                else obj = nextProps.outPatientList.find(obj => obj.id === this.state.visit_id);
                if (obj != undefined)
                    tempTaskList.documents = obj.documentList
            }

            this.setState({ TaskList: tempTaskList });

            if (obj != undefined) {

                let documents = obj.documentList.filter((itm) => itm.docType === "document")

                for (let i in documents) {
                    documents[i].id = documents[i].documentId
                }
                let videos = obj.documentList.filter((itm) => itm.docType === "videos")

                for (let i in videos) {
                    videos[i].id = videos[i].documentId
                }

                let tempFormData = this.state.formData
                tempFormData.documents = documents
                tempFormData.videos = videos

                this.setState({ formData: tempFormData })
            }
        }

        if (nextProps.isDietShared !== this.props.isDietShared) {
            let obj = nextProps.VisitList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                this.setState({ lastDiet: obj.lastDiet })
            obj = nextProps.eVisitList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                this.setState({ lastDiet: obj.lastDiet })
            obj = nextProps.outPatientList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                this.setState({ lastDiet: obj.lastDiet })
        }

        if (nextProps.isTaskListUpdated !== this.props.isTaskListUpdated) {
            //console.log(nextProps.VisitList[0],this.state,"********")
            let tempTaskList = this.state.TaskList
            let obj = nextProps.VisitList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                tempTaskList.tasks = obj.taskList
            obj = nextProps.eVisitList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                tempTaskList.tasks = obj.taskList
            obj = nextProps.outPatientList.find(obj => obj.id === this.state.visit_id);
            if (obj != undefined)
                tempTaskList.tasks = obj.taskList
            this.setState({ TaskList: tempTaskList });
        }

        this.setState({ edToggleStatus: true })
        if (nextProps.isTaskList !== this.props.isTaskList) {
            if (nextProps.TaskList.status === "Success") {
                this.setState({ TaskList: nextProps.TaskList });
                this.setState({ showProcessing: false });
            }
        }

        if (nextProps.isPrintShareDocument !== this.props.isPrintShareDocument) {
            if (isKnowledgeShared) {
                isKnowledgeShared = false;
                this.successAlert("You have successfully Shared Knowledge!");
            }
        }

        if (nextProps.isChangeStatus !== this.props.isChangeStatus) {
            setTimeout(function () {
                let params = {
                    clinicId: localStorage.getItem('clinicId'),
                }
                appConstants.socket.emit('updateTaskScreen', params);
            }, 1000);
        }

        if (visitInfo.id && nextProps.isPrintShareDocument === this.props.isPrintShareDocument) {

            if (visitInfo.lastDiet) {

                this.setState({ lastDiet: visitInfo.lastDiet });
                let lastDiets = visitInfo.lastDiet;
                this.setState({ dietStatus: false });

                if (lastDiets && lastDiets.length > 0) {

                    let date_first = lastDiets['0'] ? moment(lastDiets['0'].createdAt).format('YYYY-MM-DD') : '';
                    let date_second = lastDiets['1'] ? moment(lastDiets['1'].createdAt).format('YYYY-MM-DD') : '';
                    let today = moment().format('YYYY-MM-DD');
                    let dietStatus = false;

                    if (lastDiets['1'] && today === date_first && today === date_second) {


                        if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId === lastDiets['1'].treatmentId) {

                            dietStatus = true;
                        } if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId !== lastDiets['1'].treatmentId) {

                            dietStatus = true;
                        } else {

                            dietStatus = true;
                        }
                    } else if (lastDiets['0'] && today === date_first) {

                        if (lastDiets['0'] && lastDiets['0'].addedByType === 'educator') {
                            dietStatus = true;
                        } else {
                            dietStatus = true;
                        }
                    } else {
                        dietStatus = false;
                    }

                    if (dietStatus) {
                        let dietColor = lastDiets['0'].color;
                        if (lastDiets['1'] && lastDiets['1'].addedByType === 'doctor') {
                            dietColor = lastDiets['1'].color
                        }

                        this.setState({ colorType: dietColor });
                    }

                    this.setState({ dietStatus });
                }
            }

            if (this.state.visit_id !== visitInfo.id || this.state.documentUpdate) {
                this.updateDocuments(visitInfo);
            }
        }

        this.noWorkingPening(visitInfo);
    }

    noWorkingPening(row) {

        let noWork = false;
        for (let i in row.documentList) {
            if (row.documentList[i].status === 'unread') {
                noWork = true;
            }
        }

        for (let i in row.taskList) {
            if (row.taskList[i].status === 'uncomplete') {
                noWork = true;
            }
        }

        for (let i in row.lastDiet) {
            if (row.lastDiet[i].color === 'red' && moment().format("YYYY-MM-DD") === moment(row.lastDiet[i].createdAt).format("YYYY-MM-DD")) {
                noWork = true;
            }
        }
        //console.log(noWork);
        this.setState({ noWork: noWork });
    }

    updateDocuments(visitInfo) {

        this.setState({ visit_id: visitInfo.id });
        this.setState({ visitInfo: visitInfo });
        this.setState({ documentUpdate: false });

        let TaskList = [];
        TaskList['tasks'] = visitInfo.taskList;
        TaskList['documents'] = visitInfo.documentList;
        this.setState({ TaskList: TaskList });
        let Videos = [];
        let Documents = [];
        visitInfo.documentList && visitInfo.documentList.map((key, i) => {

            if (key.docType === 'videos') {
                return Videos.push({ id: key.documentId, name: key.documentName, link: key.image });
            } else {
                return Documents.push({ id: key.documentId, name: key.documentName, link: key.image });
            }
        });

        let field = this.state.formData;
        field['videos'] = Videos;
        field['documents'] = Documents;
        this.setState({ formData: field });
    }

    ChangeStatus(id, type) {
        this.props.changeStatusAction(id, type);
        var element = document.getElementById(id);
        element.classList.add("green");
        if (type === 'task') {
            element = document.getElementById(id + 'task');
            element.disabled = true;
        }
    }

    addClass(key) {
        //  let status = (key.addedByType === 'educator')?'pink':((key.status === 'unread' || key.status === 'uncomplete')?'red':'green');
        let status = ((key.status === 'unread' || key.status === 'uncomplete') ? ((key.addedByType === 'educator') ? 'pink' : 'red') : 'green');
        return "pull-left " + status;
    }


    handleKnowledgeDocChange(row) {

        let field = this.state.formData;
        let exist = 0;
        for (let x in field.documents) {
            if (field.documents[x].id === row._id) {
                exist = 1;
                delete field.documents[x];
            }
        }
        if (exist === 0) {
            field['documents'].push({ id: row._id, name: row.title, nameHindi: row.title_hindi, link: row.image, linkHindi: row.image_hindi });
        }

        field['documents'] = field.documents.filter(function (el) {
            return el != null;
        });

        this.setState({ formData: field });
    }

    handleKnowledgeVideoChange(row) {

        let field = this.state.formData;
        let exist = 0;
        for (let x in field.videos) {
            if (field.videos[x].id === row._id) {
                exist = 1;
                delete field.videos[x];
            }
        }
        if (exist === 0) {
            field['videos'].push({ id: row._id, name: row.title, nameHindi: row.title_hindi, link: row.image, linkHindi: row.image_hindi });
        }
        field['videos'] = field.videos.filter(function (el) {
            return el != null;
        });

        this.setState({ formData: field });

    }

    successAlert(msg) {
        // this.setState({
        //     alert: (
        //         <SweetAlert
        //             success
        //             style={{ display: "block", marginTop: "-100px" }}
        //             title="Success"
        //             //onConfirm={() => this.hideAlert()}
        //             //onCancel={() => this.hideAlert()}
        //             confirmBtnBsStyle="info"
        //         >
        //             You have successfully Shared Knowledge!
        //         </SweetAlert>
        //     )
        // });
        let _this = this;


        _this.refs.notificationSystem.addNotification({
            title: (
                <span
                    data-notify="icon"
                    className="pe-7s-check"
                    style={{
                        fontSize: "30px",
                        color: "#fff",
                        padding: "0px !important",
                        top: "0% !important",
                    }}
                ></span>
            ),
            message: (
                <div>
                    {/* <p>{from}</p> */}
                    <span>{msg}</span>
                </div>
            ),
            level: "success",
            position: "tr",
            autoDismiss: 2,
        });


        setTimeout(function () {
            _this.hideAlert();
        }, 3000);
    }

    hideAlert() {
        this.setState({
            alert: null,
            showProcessing: true
        });
        this.props.taskListAction(this.state.visitInfo);
    }

    dietBox(patient) {
        //this.setState({ dietModal: true, currentPatient: patient });
        this.setState({ taskScreen: true, taskScreenData: patient, dietFromPtSeatch: false, dietFromEducator: false });
        this.setDietTitle(patient);
    }

    setDietTitle(patient) {
        let title = "Patient Diet (" + patient.name + " - " + patient.patientNo + ")";
        title += " | H:" + patient.height;
        title += " | W:" + patient.weight;
        title += " | BMI:" + getBMI(patient.height, patient.weight);
        title += " | Age:" + patient.age;
        this.setState({ dietTitle: title });
    }

    submitTask() {
        let field = this.state.formData;
        field['patient_id'] = this.state.visitInfo.pId;
        field['visit_id'] = this.state.visitInfo.id;
        field['notification_token'] = this.state.visitInfo.notification_token;
        isKnowledgeShared = true;
        this.props.printShareDocumentAction(field);
    }

    dietDetail(lastDiets) {
        let date1 = lastDiets['0'] ? moment(lastDiets['0'].createdAt).format('YYYY-MM-DD') : '';
        let date2 = lastDiets['1'] ? moment(lastDiets['1'].createdAt).format('YYYY-MM-DD') : '';
        let today = moment().format('YYYY-MM-DD');
        let class_name = '';
        let deit = false;
        if (lastDiets['1'] && today === date1 && today === date2) {
            if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId === lastDiets['1'].treatmentId) {
                class_name = 'green';
                deit = true;
            } else if (lastDiets['0'].addedByType !== lastDiets['1'].addedByType && lastDiets['0'].treatmentId !== lastDiets['1'].treatmentId) {
                class_name = 'blue';

                deit = true;
            } else {
                //  class_name = 'black';
                class_name = 'red';
                deit = true;
            }
        } else if (lastDiets['0'] && today === date1) {


            if (lastDiets['0'] && lastDiets['0'].addedByType === 'educator') {
                class_name = 'green';
                deit = true;
            } else {
                class_name = 'red';
                deit = true;
            }
        } else {

            return '';
        }

        if (class_name !== '' && deit === true) {
            return (<span onClick={this.dietBox.bind(this, this.state.visitInfo)} className={this.state.colorType}>{lastDiets[0].treatmentName}</span>);
            // return (<span onClick={this.dietBox.bind(this, this.state.visitInfo)} className={class_name}>{lastDiets[0].treatmentName}</span>);
        } else {

            return '';
        }

    }

    onDismiss(msg) {

        this.setState({ taskScreen: false });
        //this.setState({ taskScreen: false, showProcessing: true });
        this.successAlert(msg);


    }

    render() {

        if (this.state.visitInfo !== this.props.row) {
            this.setState({ visitInfo: this.props.row });
        }

        if (this.state.visitInfo.name) {
            let Message;
            if (this.state.visitInfo.messageForEducator) {
                Message = (<Row>
                    <Col md={12}><b>Message - </b>{this.state.visitInfo.messageForEducator}</Col>
                </Row>)
            }

            return (

                <div className="main-content educator-task-screen card0pad" style={{ padding: '0px' }}>
                    {this.state.alert}
                    <NotificationSystem ref="notificationSystem" style={style} />
                    <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                        <img src={loadingImg} alt="Loading" width="40px" />
                        <center> Please Wait</center>
                    </div>
                    {this.state.edToggleStatus &&
                        <Grid fluid className="educator-header">
                            <Card
                                title={this.state.visitInfo.name + ' - ' + this.state.visitInfo.patientId}
                                content={
                                    <div>
                                        <div>
                                            {this.state.noWork === false &&
                                                <div className="spc-box-textstop text-center green"><b>No Work Pending</b></div>
                                            }
                                            {(this.state.dietStatus) &&
                                                <div className="spc-box-textstop"><b>Diet Plan</b></div>
                                            }
                                            {this.state.TaskList && this.state.TaskList.documents && this.state.TaskList.documents.length > 0 &&
                                                <div className="text-center"><b>{!this.state.visitInfo.app ? "Show Video to Patient." : ""}</b></div>
                                            }
                                            <button type="button" className="close" onClick={() => this.setState({ edToggleStatus: false })} ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                                        </div>
                                        <Row className="py-5 consultation-CTA">

                                            {(this.state.dietStatus) ?
                                                <Col md={8} lg={9} xl={10} className="diet-title">
                                                    {this.dietDetail(this.state.lastDiet)}
                                                </Col>
                                                :
                                                <Col md={8} lg={9} xl={10}>
                                                    {/* <p className="noDataBox">No data available.</p> */}
                                                </Col>
                                            }

                                            <Col md={4} lg={3} xl={2}>
                                                <History pvDetail={this.state.visitInfo} />
                                            </Col>
                                        </Row>
                                        {Message}
                                        {this.state.TaskList && this.state.TaskList.tasks && this.state.TaskList.tasks.length > 0 &&
                                            <Row>
                                                <Col md={12} className="no-padding hiddelie">
                                                    <Table responsive>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2" className="labelbt-spc">

                                                                    <div className="spc-textstop"><b>Educator Messages</b></div>
                                                                    {
                                                                        this.state.TaskList.tasks && this.state.TaskList.tasks.map((key, i) => {

                                                                            if (key.status !== 'uncomplete') {
                                                                                return (
                                                                                    <Col sm={6} className={this.addClass(key)} key={key._id}>
                                                                                        <Checkbox
                                                                                            disabled
                                                                                            checked={true}
                                                                                            number={key._id}
                                                                                            label={key.taskName}
                                                                                        />
                                                                                    </Col>)
                                                                            } else {
                                                                                return (
                                                                                    <Col sm={6} className={this.addClass(key)} id={key._id} key={key._id}>
                                                                                        <Checkbox
                                                                                            number={key._id + 'task'}
                                                                                            label={key.taskName}
                                                                                            onClick={e => { this.ChangeStatus(key._id, 'task') }}
                                                                                        />
                                                                                    </Col>)
                                                                            }

                                                                        })
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }

                                        {this.state.TaskList && this.state.TaskList.documents && this.state.TaskList.documents.length > 0 &&
                                            <Row>
                                                <Col md={12} className="no-padding hiddelie">
                                                    <Table responsive>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="2" className="labelbt-spc">
                                                                    <div className="spc-textstop">
                                                                        <b> Videos/Documents</b></div>
                                                                    <div className="wi50">
                                                                        {
                                                                            this.state.TaskList.documents && this.state.TaskList.documents.map((key, i) => {
                                                                                // let date = moment(key.createdAt).format('YYYY-MM-DD');
                                                                                // let today = moment().format('YYYY-MM-DD');
                                                                                let data;
                                                                                if (key.docType === 'videos') {

                                                                                    data = (<Row key={key._id}>
                                                                                        <Col sm={12} className={this.addClass(key)} id={key._id}>
                                                                                            <VideoDocumentView
                                                                                                ChangeStatus={(id, type) => { this.ChangeStatus(id, 'document') }}
                                                                                                pvDetail={this.state.visitInfo}
                                                                                                name={key.documentName}
                                                                                                id={key._id}
                                                                                                link={key.image}
                                                                                                type={'video'}
                                                                                                status={1}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    )
                                                                                }
                                                                                return data;

                                                                            })
                                                                        }
                                                                    </div>

                                                                    <div className="loop-videos wi50">
                                                                        {
                                                                            this.state.TaskList.documents && this.state.TaskList.documents.map((key, i) => {
                                                                                // let date = moment(key.createdAt).format('YYYY-MM-DD');
                                                                                // let today = moment().format('YYYY-MM-DD');
                                                                                let data;
                                                                                if (key.docType === 'document') {

                                                                                    data = (<Row key={key._id}>
                                                                                        <Col sm={12} className={this.addClass(key)} id={key._id}>
                                                                                            <VideoDocumentView
                                                                                                ChangeStatus={(id, type) => { this.ChangeStatus(id, 'document') }}
                                                                                                pvDetail={this.state.visitInfo}
                                                                                                name={key.documentName}
                                                                                                id={key._id}
                                                                                                link={key.image}
                                                                                                type={'image'}
                                                                                                status={1}
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                    )
                                                                                }
                                                                                return data;
                                                                            })

                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col className="knowledge-share botom-icons">
                                                <KnowledgeShare submitTask={(e) => { this.submitTask(e) }} handleKnowledgeDocChange={(id, title, link) => { this.handleKnowledgeDocChange(id, title, link) }} handleKnowledgeVideoChange={(id, title, link) => { this.handleKnowledgeVideoChange(id, title, link) }}
                                                    pvDetail={this.state.visitInfo}
                                                    documents={this.state.formData.documents}
                                                    videos={this.state.formData.videos}
                                                    edType="educatorType"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            />

                        </Grid>
                    }
                    {/* Diet model */}
                    <Modal className="pa-diet-screen" show={this.state.dietModal} onHide={() => this.setState({ dietModal: false })} dialogClassName="modal-lg">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">{this.state.dietTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Diet patient={this.state.currentPatient} />
                                </Col>
                            </Row>

                        </Modal.Body>
                    </Modal>

                    <Modal className="pa-task-screen" show={this.state.taskScreen} onHide={() => this.setState({ taskScreen: false })} dialogClassName="modal-lg">
                        <button type="button" className="close" onClick={() => this.setState({ taskScreen: false })} ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                        <Modal.Body className="Knowledge-Share ">
                            <Row>
                                <Col md={12}>
                                    <TaskScreen taskScreenData={this.state.taskScreenData} ptSearch={this.state.dietFromPtSeatch} shareDietByEd={this.state.dietFromEducator} onDismiss1={this.onDismiss} />
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>

                    {/* /Diet model */}
                </div>
            );
        } else {
            return ('');
        }
    }
}

function mapStateToProps(state) {

    return {
        TaskList: state.home.TaskList,
        isTaskList: state.home.isTaskList,
        isTaskListError: state.home.isTaskListError,
        isTaskListUpdated: state.home.isTaskListUpdated,
        isDietShared: state.home.isDietShared,
        VisitList: state.home.VisitList,
        eVisitList: state.home.eVisitList,
        outPatientList: state.home.outPatientList,
        isKnowledgeShared: state.home.isKnowledgeShared,

        isVisitDetail: state.home.isVisitDetail,
        isVisitDetailError: state.home.isVisitDetailError,
        visitDetail: state.home.visitDetail,

        ChangeStatus: state.home.ChangeStatus,
        isChangeStatus: state.home.isChangeStatus,
        isChangeStatusError: state.home.isChangeStatusError,

        PrintShareDocument: state.home.printShareDocument,
        isPrintShareDocument: state.home.isPrintShareDocument,
        isPrintShareDocumentError: state.home.isPrintShareDocumentError,

    }
}
export default withRouter(connect(mapStateToProps, { taskListAction, changeStatusAction, printShareDocumentAction })(EducatorTaskScreen));

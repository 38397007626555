/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    FormGroup, FormControl
} from 'react-bootstrap';

import Button from 'Admin/elements/CustomButton/CustomButton.jsx';

import avatar from 'Admin/assets/img/default-avatar.png';

class LockScreenPage extends Component {
    render() {
        return (
            <form className="ng-untouched ng-pristine ng-valid">
                <div className="user-profile">
                    <div className="author">
                        <img alt="..." className="avatar" src={avatar} />
                    </div>
                    <h4>Tania Andrew</h4>
                    <FormGroup>
                        <FormControl
                            type="password"
                            placeholder="Enter Password"
                        />
                    </FormGroup>
                    <Button wd neutral round>
                        Unlock
                    </Button>
                </div>
            </form>
        );
    }
}

export default LockScreenPage;

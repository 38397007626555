/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import EditIcon from '../../assets/img/icons/edit-ic.svg'
import DeleteIcon from '../../assets/img/icons/table-delete-ic.svg'

const PatientPrescription = () => {
    return (
        <div>
            <Table bordered>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Long Term Diagnosis</th>
                        <th>Added On</th>
                        <th>Duration</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Irregular Cycles</td>
                        <td>22 Days</td>
                        <td>22 Days</td>
                        <td>
                            <div className='table-action'>
                                <a href='#'><img src={EditIcon} alt='Edit' title='Edit' /></a>
                                <a href='#'><img src={DeleteIcon} alt='Delete' title='Delete' /></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Weakness</td>
                        <td>1 Week</td>
                        <td>1 Week</td>
                        <td>
                            <div className='table-action'>
                                <a href='#'><img src={EditIcon} alt='Edit' title='Edit' /></a>
                                <a href='#'><img src={DeleteIcon} alt='Delete' title='Delete' /></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Fever</td>
                        <td>4 Days</td>
                        <td>4 Days</td>
                        <td>
                            <div className='table-action'>
                                <a href='#'><img src={EditIcon} alt='Edit' title='Edit' /></a>
                                <a href='#'><img src={DeleteIcon} alt='Delete' title='Delete' /></a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Headache</td>
                        <td>2 Months</td>
                        <td>2 Months</td>
                        <td>
                            <div className='table-action'>
                                <a href='#'><img src={EditIcon} alt='Edit' title='Edit' /></a>
                                <a href='#'><img src={DeleteIcon} alt='Delete' title='Delete' /></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default PatientPrescription



/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

/* eslint-disable no-unused-expressions */
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";

import App from "./Front/containers/App/App";
import AdminApp from "./Admin/containers/App/App";

// import indexRoutes from './Front/routes/index.jsx';
// import adminRoutes from './Admin/routes/index.jsx';

// import registerServiceWorker from './registerServiceWorker';

import "./Front/assets/css/bootstrap.min.css";
import "./Front/assets/sass/light-bootstrap-dashboard.css";
import "./Front/assets/css/demo.css";
import "./Front/assets/css/pe-icon-7-stroke.css";
import "./Front/assets/css/custom.css";
import "./Front/assets/css/new-dashboard.css";

import { Provider } from "react-redux";
import { ConnectedRouter, routerMiddleware } from "react-router-redux";
import history from "./Front/routes/history";
import adminHistory from "./Admin/routes/history";

import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./Front/reducers";
import adminReducer from "./Admin/reducers";
/*
ReactDOM.render((
    <HashRouter>
        <Switch>
            {
                indexRoutes.map((prop,key) => {
                    return (
                        <Route path={prop.path} component={prop.component}  key={key}/>
                    );
                })
            }
        </Switch>
    </HashRouter>
), document.getElementById('root'));
registerServiceWorker();*/

let userType = localStorage.getItem("userType");

let historyMiddleware = null;

let realReducer = reducer;

let can_render = true;
if (localStorage.getItem("token")) {
  let loginTime = localStorage.getItem("loginTime") ? new Date(localStorage.getItem("loginTime")) : null;
  loginTime = new Date(loginTime.getTime() + 15 * 60000);

  if (new Date() > loginTime) {
    can_render = false;
    localStorage.clear();
    window.location.href = "/login";
  }
}

if (userType === "admin") {
  historyMiddleware = routerMiddleware(adminHistory);
  realReducer = adminReducer;
} else {
  historyMiddleware = routerMiddleware(history);
  realReducer = reducer;
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const moment = require('moment-timezone')

// moment.tz.setDefault('Asia/Calcutta')
export const store = createStore(
  realReducer,
  composeEnhancers(applyMiddleware(historyMiddleware, thunk))
);

if (can_render) {
  if (userType === "admin") {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <AdminApp />
        </Router>
      </Provider>,

      document.getElementById("root")
    );
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>,

      document.getElementById("root")
    );
  }
}
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addspecialization = (data) => postRequestWithToken(data, 'admin/add-specialization');
export const updateSpecialization = (data) => postRequestWithToken(data, 'admin/update-specialization');
export const changeSpecializationStatus = (data) => postRequestWithToken(data, 'admin/change-status-specialization');
export const specializationList = (data) => postRequestWithToken(data, 'specialization-list');
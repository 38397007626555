/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { uploadFileAction } from 'Admin/actions/link';
import { addLinkAction } from 'Admin/actions/link';
import { clinicListAction, ClinicSimpleListAction } from 'Admin/actions/clinic';
import { specializationListAction } from 'Admin/actions/specialization';
import ClinicHeader from '../ClinicHeader/ClinicHeader';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    title: 'required'
};
let mess = {
    required: 'This field is required',
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

let fileSizeError = '';

class AddLink extends Component {

    constructor(props) {
        super(props);
        var clinicid = "";
        var clinicName = "";
        var dropdownDisable = false;
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.clinic_id) {
            clinicid = this.props.location.state.clinic_id;
            clinicName = this.props.location.state.clinicName;
        }

        if (this.props && this.props.location && this.props.location.state && this.props.location.state.clinicDetail && !this.props.location.state.clinicDetail.status) {
            dropdownDisable = true;
        }

        this.state = {
            clinicList: [],
            specializationList: [],
            specialization: null,
            category: null,
            cardHidden: true,
            isLoading: false,
            formArr: [],
            clinicError: null,
            specializationError: null,
            titleError: null,
            fileError: null,
            descriptionError: null,
            orderError: null,
            categoryError: null,
            formData: {
                title: "",
                clinic_name: clinicName,
                clinic_id: clinicid,
                description: "",
                order: "",
                link: "",
                status: "active",
                specializations: [],
                file: []
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
            dropdownDisable: dropdownDisable
        }
    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        //this.props.clinicListAction(data);
        // this.props.specializationListAction(data);
        this.props.ClinicSimpleListAction();

    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.isClinicList !== this.props.isClinicList) {
        //     this.setState({
        //         clinicList: nextProps.ClinicList.data.data
        //     });
        // }

        if (nextProps.isClinicSimpleList !== this.props.isClinicSimpleList) {
            let list = nextProps.ClinicSimpleList.data;

            this.setState({
                clinicList: nextProps.ClinicSimpleList.data
            });

        }


        if (nextProps.isSpecializationList && nextProps.isSpecializationList !== this.props.isSpecializationList) {
            if (nextProps.SpecializationList && nextProps.SpecializationList.data && nextProps.SpecializationList.data.data && nextProps.SpecializationList.data.data.length > 0) {
                this.state.specializationList = nextProps.SpecializationList.data.data.map((key, i) => {
                    return { value: key._id, label: key.name };
                });
            }
        }

        if (nextProps.isAddLinkError !== this.props.isAddLinkError) {
            if (nextProps.AddLinkMessage.errors) {
                nextProps.AddLinkMessage.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }

        if (nextProps.isUploadFile !== this.props.isUploadFile) {
            let uploaded_file = this.state.uploaded_file;
            let field = this.state.formData;
            field[uploaded_file] = nextProps.uploadFile.file_name;
            this.setState({ formData: field });
        }

        if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
            if (nextProps.uploadFile.errors) {
                let uploaded_file = this.state.uploaded_file;
                nextProps.uploadFile.errors.map((key, i) => {
                    this.setState({ [uploaded_file + "Error"]: key.msg })
                });
            }
        }


        if (nextProps.AddLink !== this.props.AddLink) {
            this.props.handleClick('success', 'Link added successfully')
            if (this.props.location && this.props.location.state.clinicDetail) {
                this.props.history.push(`/admin/link-list`, this.props.location.state.clinicDetail);
            } else {
                this.props.history.push(`/admin/link-list`);
            }
        }
    }

    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    };

    handleClinic(event) {
        // let index = event.target.selectedIndex;
        // this.state.formData.clinic_id = event.target.value
        // this.state.formData.clinic_name = event.target[index].text
        let index = event.target.selectedIndex;
        let formData = this.state.formData;
        formData['clinic_id'] = event.target.value
        formData['clinic_name'] = event.target[index].text
        this.setState({ formData: formData });
    }

    fileChangedHandler(event, elename) {
        event.preventDefault();
        this.setState({ uploaded_file: elename });
        let reader = new FileReader();
        let file = event.target.files[0];
        /*if(elename==="file"){
          reader.onloadend = () => {
            this.setState({imagePreviewUrl: reader.result});
          }
        }*/

        if (file && file.size <= 15652261) {
            fileSizeError = '';
            this.props.uploadFileAction(file);
            reader.readAsDataURL(file);
        } else {
            fileSizeError = 'Upload video no larger than 15MB.';
        }


    }

    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addLink(evt) {
        this.setState({ isLoading: true });
        evt.preventDefault();
        if (this.state.specialization != null) {
            var spArr = this.state.specialization.map((key, i) => {
                return { id: key.value, name: key.label };
            });
            this.state.formData.specializations = spArr;
        }

        const _this = this;
        if (this.allValidate(false)) {
            _this.setState({ showProcessing: true });
            _this.props.addLinkAction(this.state);
        }
        setTimeout(() => {
            // Once the action is complete, enable the button again
            this.setState({ isButtonDisabled: false });
            this.setState({ isLoading: false });
        }, 100);
        this.props.onClickAction()
            .then(() => {
                // After the action is completed, reset the loading state to false
                this.setState({ isLoading: false });
            })
            .catch(() => {
                // Handle any errors and reset loading state
                this.setState({ isLoading: false });
            });
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();
        const { isLoading } = this.state;

        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            {this.state.dropdownDisable &&
                                <ClinicHeader componentData={{ clinicId: this.props.location.state.clinicDetail._id }} />
                            }
                            <Card
                                title={<legend className="line-removes">
                                    {!this.state.dropdownDisable &&
                                        <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                    }
                                </legend>}
                                content={
                                    <Form horizontal>
                                        {!this.state.dropdownDisable &&
                                            <div>
                                                <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                        Clinic:
                                                    </Col>
                                                    <Col sm={6}>
                                                        <select className="form-control" name="clinic" value={this.state.formData.clinic_id} onChange={(event) => this.handleClinic(event)}>
                                                            <option value="">Select Clinic</option>
                                                            {this.state.clinicList.map(function (item) {
                                                                return <option key={item._id} value={item._id}>{item.name}</option>
                                                            })}
                                                        </select>
                                                        {this.state.clinicError}
                                                    </Col>
                                                </FormGroup>
                                                {/* <FormGroup>
                                                    <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                        Specialization:
                                        </Col>
                                                    <Col sm={6}>

                                                        <Select
                                                            placeholder="Select Specialization"
                                                            name="specialization"
                                                            id="specialization"
                                                            closeOnSelect={false}
                                                            multi={true}
                                                            value={this.state.specialization}
                                                            options={this.state.specializationList}
                                                            onChange={(value) => this.setState({ specialization: value })}
                                                        />
                                                        {this.state.specializationError}
                                                    </Col>
                                                </FormGroup> */}
                                            </div>
                                        }
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Title <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>

                                                <FormControl type="text" name="title" id="title" onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.titleError}
                                                    {this.state.formArr.title && validation.errors.first('title')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Link
                                            </Col>
                                            <Col sm={6}>

                                                <FormControl type="text" name="link" id="link" onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                File
                                            </Col>
                                            <Col sm={6}>
                                                <input type="file" name="file" accept=".jpg,.png,.jpeg,.mp3,.mp4,.pdf" onChange={e => { this.fileChangedHandler(e, "file") }}></input>
                                                <div><span>NOTE : For uploading video you can use only mp4 format file and file size should not be larger than 15MB.</span></div>
                                                <span className="errorMsg">{fileSizeError}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup controlId="formControlsTextarea">
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl rows="2" componentClass="textarea" name="description" bsClass="form-control" defaultValue="" onChange={e => { this.handleChange(e); }} />
                                            </Col>
                                        </FormGroup>

                                        {/*<FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Order
                                            </Col>
                                            <Col sm={6}>

                                                <FormControl type="number" name="order" onChange={e => { this.handleChange(e); }}/>
                                                {this.state.orderError}
                                            </Col>
                                        </FormGroup> */}

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Status
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="status" value={this.state.value} onChange={e => { this.handleChange(e); }}>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button
                                                    type="button"
                                                    onClick={this.addLink.bind(this)}
                                                    disabled={isLoading || this.props.disabled}
                                                    className="btn-fill btn-wd btn btn-primary">
                                                    {isLoading ? (
                                                        <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                                                    ) : (
                                                        this.props.children
                                                    )}
                                                    Save
                                                </button>&nbsp;
                                            </Col>
                                        </FormGroup>

                                    </Form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.link.message,
        AddLink: state.link.AddLink,
        isAddLink: state.link.isAddLink,
        isAddLinkError: state.link.isAddLinkError,

        ClinicList: state.clinic.ClinicList,
        isClinicList: state.clinic.isClinicList,
        isClinicListError: state.clinic.isClinicListError,

        SpecializationList: state.specilization.SpecializationList,
        isSpecializationList: state.specilization.isSpecializationList,
        isSpecializationListError: state.specilization.isSpecializationListError,

        isUploadFile: state.link.isUploadFile,
        isUploadFileError: state.link.isUploadFileError,
        uploadFile: state.link.uploadFile,

        ClinicSimpleList: state.clinic.ClinicSimpleList,
        isClinicSimpleList: state.clinic.isClinicSimpleList,
        isClinicSimpleListError: state.clinic.isClinicSimpleListError


    }
}
export default withRouter(connect(mapStateToProps, { addLinkAction, clinicListAction, specializationListAction, uploadFileAction, ClinicSimpleListAction })(AddLink));

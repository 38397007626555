/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"


export const addTreatment = (data) => postRequestWithToken(data, 'admin/add-treatment');
export const updateTreatment = (data) => postRequestWithToken(data, 'admin/update-treatment');
export const changeTreatmentStatus = (data) => postRequestWithToken(data, 'admin/treatment-change-status');
export const treatmentList = (data) => postRequestWithToken(data, 'admin/treatment-list');
export const treatmentListType = (data) => postRequestWithToken(data, 'admin/treatment-list-type');
export const treatmentChangeOrder = (data) => postRequestWithToken(data, 'admin/treatment-change-order');
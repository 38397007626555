/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const VisitListTableReceptioonist = ({ visitList, options, getIndex, getPatientOutClass, taskScreen, getOutClass, tagsContent, nameContent, ageContent, cityContent, isApp, action }) => {

  return (
    <div className="visit-list list-ww recepnist  table-w min-hieght">
      <BootstrapTable
        data={visitList}
        search={true}
        multiColumnSearch={true}
        options={options}
        striped
        hover
        condensed
      >
        <TableHeaderColumn
          hidden={true}
          tdAttr={{ "data-attr": "id" }}
          dataField="id"
          dataSort={true}
          isKey
          searchable={false}
        >
          Id
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "5%" }}
          tdStyle={{ width: "5%" }}
          tdAttr={{ "data-attr": "#" }}
          dataField="sn"
          dataFormat={getIndex}
          columnClassName={getPatientOutClass}
        >
          #
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "10%" }}
          tdStyle={{ width: "10%", whiteSpace: "nowrap" }}
          tdAttr={{ "data-attr": "PATIENT ID" }}
          dataField="patientId"
          dataFormat={taskScreen}
          columnClassName={getOutClass}
        >
          Patient ID
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "20%" }}
          tdStyle={{ width: "20%" }}
          tdAttr={{ "data-attr": "NAME" }}
          dataField="name"
          dataFormat={nameContent}
        >
          NAME
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "5%" }}
          tdStyle={{ width: "5%" }}
          dataFormat={ageContent}
        >
          Age
        </TableHeaderColumn>

        {/* <TableHeaderColumn thStyle={{ width: '41px' }} tdStyle={{ width: '41px' }} tdAttr={{ 'data-attr': 'OUT' }} dataField='' dataFormat={this.outButton.bind(this)}>OUT</TableHeaderColumn> */}
        <TableHeaderColumn
          thStyle={{ width: "13%" }}
          tdStyle={{ width: "13%" }}
          dataFormat={cityContent}
        >
          City
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "30%", fontWeight: "600" }}
          tdStyle={{ width: "30%", fontWeight: "600" }}
          tdAttr={{ "data-attr": "NAME" }}
          dataField="name"
          dataFormat={tagsContent}
        >
          Labels
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "5%" }}
          tdStyle={{ width: "5%" }}
          tdAttr={{ "data-attr": "APP" }}
          dataField=""
          dataFormat={isApp}
        >
          APP
        </TableHeaderColumn>

        {/* <TableHeaderColumn thStyle={{ width: '40px' }} tdStyle={{ width: '40px' }} tdAttr={{ 'data-attr': 'Diet' }} dataField='' dataFormat={this.diet.bind(this)}>DIET</TableHeaderColumn> */}
        {/* <TableHeaderColumn thStyle={{ width: '30px' }} tdStyle={{ width: '30px' }} tdAttr={{ 'data-attr': 'NEXT V' }} dataField='' dataFormat={this.emr.bind(this)}>Rx</TableHeaderColumn>

                                <TableHeaderColumn thStyle={{ width: '60px' }} tdStyle={{ width: '60px' }} tdAttr={{ 'data-attr': 'Password' }} dataField='' dataFormat={this.password.bind(this)}>Password</TableHeaderColumn>

                                <TableHeaderColumn thStyle={{ width: '90px' }} tdStyle={{ width: '90px' }} tdAttr={{ 'data-attr': 'NEXT V' }} dataField='' dataFormat={this.nextButton.bind(this)}>NEXT VISIT</TableHeaderColumn>

                                <TableHeaderColumn thStyle={{ width: '35px' }} tdStyle={{ width: '35px' }} tdAttr={{ 'data-attr': 'OUT' }} dataField='' dataFormat={this.outButton.bind(this)}>Out</TableHeaderColumn> */}


        <TableHeaderColumn
          thStyle={{ width: "10%" }}
          tdStyle={{ width: "10%" }}
          dataFormat={action}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  )
}

export default VisitListTableReceptioonist
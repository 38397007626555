/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import 'Front/views/DietChart/style.css';
import moment from 'moment';
import { appConstants } from 'Front/_constants/app.constants.js';
//import { Row, Col, Form } from 'react-bootstrap';

export class PrintDietChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bmi: 0,
            patientId: ""
        }
    }

    componentDidMount() {

        if (this.props.printData.patient.height !== 0 && this.props.printData.patient.weight !== 0) {
            let height_in_m = this.props.printData.patient.height / 100;
            if (height_in_m > 0 && this.props.printData.patient.weight > 0) {
                let bmiData = this.props.printData.patient.weight / (height_in_m * height_in_m);
                this.setState({ bmi: bmiData.toFixed(2) });
            }
        }

        localStorage.getItem('userType') === "doctor" ? this.setState({ patientId: this.props.printData.patient.patientId }) : this.setState({ patientId: this.props.printData.patient.patientClinicId })

    }


    render() {
        let firstTime = 0;
        let totalDiff = 0;
        let objCalorie = {
            1: { calorieTxt: "Total Calorie", carb: "Total Carbohydrate", prot: "Total Protien", fat: "Total Fat" },
            2: { calorieTxt: "कुल कैलोरी", carb: "कुल कार्बोहाइड्रेट", prot: "कुल प्रोटीन", fat: "कुल वसा" },
            3: { calorieTxt: "एकूण कॅलरी", carb: "एकूण कार्बोहायड्रेट", prot: "एकूण प्रथिने", fat: "एकूण चरबी" },
            4: { calorieTxt: "કુલ કેલરી", carb: "કુલ કાર્બોહાઇડ્રેટ", prot: "કુલ પ્રોટીન", fat: "કુલ ચરબી" },
            5: { calorieTxt: "മൊത്തം കലോറി", carb: "മൊത്തം കാർബോഹൈഡ്രേറ്റ്", prot: "മൊത്തം പ്രോട്ടീൻ", fat: "മൊത്തം കൊഴുപ്പ്" },
            6: { calorieTxt: "மொத்த கலோரி", carb: "மொத்த கார்போஹைட்ரேட்", prot: "மொத்த புரதம்", fat: "மொத்த கொழுப்பு" },
            7: { calorieTxt: "ಒಟ್ಟು ಕ್ಯಾಲೋರಿ", carb: "ಒಟ್ಟು ಕಾರ್ಬೋಹೈಡ್ರೇಟ್", prot: "ಒಟ್ಟು ಪ್ರೋಟೀನ್", fat: "ಒಟ್ಟು ಕೊಬ್ಬು" },
            8: { calorieTxt: "మొత్తం క్యాలరీ", carb: "మొత్తం కార్బోహైడ్రేట్", prot: "మొత్తం ప్రోటీన్", fat: "మొత్తం కొవ్వు" },
            9: { calorieTxt: "মোট ক্যালোরি", carb: "সম্পূর্ণ কার্বোহাইড্রেট", prot: "মোট প্রোটিন", fat: "মোট চর্বি" },
            10: { calorieTxt: "ਕੁੱਲ ਕੈਲੋਰੀ", carb: "ਕੁੱਲ ਕਾਰਬੋਹਾਈਡਰੇਟ", prot: "ਕੁੱਲ ਪ੍ਰੋਟੀਨ", fat: "ਕੁੱਲ ਚਰਬੀ" }
        }

        const printState = this.props.printData;
        let options = printState.options;
        let lang = printState.dietLanguage;
        const _this = this;
        //let graphTime = '';
        let TData = [];

        let logo = '';
        if (localStorage.getItem('diet_print_format') === 'yes') {
            if (localStorage.getItem('logo') !== '') {
                logo = appConstants.s3UploadUrl + localStorage.getItem('logo')
            } else {
                //logo = "images/logo.png"
            }
        }



        function formatTime(showTimeObj) {
            var hours = parseInt(showTimeObj.getHours(), 10);
            var min = parseInt(showTimeObj.getMinutes(), 10);
            var mid = 'am';
            if (hours === 0) {
                hours = 12;
            }
            else if (hours > 12) {
                hours = hours - 12;
                mid = 'pm';
            }

            if (hours < 10) {
                hours = "0" + hours;
            }

            if (min < 10) {
                min = "0" + min;
            }

            return hours + ":" + min + " " + mid;
        }

        function timeName(cell, row) {
            //let txt = "<div>"+cell[lang]?cell[lang]:cell.english+"</div>";
            //let txt = "<div>"+cell[lang==='english'?'1':'2']?cell[lang==='english'?'1':'2']:cell[lang]+"</div>";

            let txt = "<div>" + cell[lang] ? cell[lang] : cell['1'] + "</div>";

            if (printState) {
                if (printState.dietTimePercent[row._id]) {
                    let showTime = printState.dietTimePercent[row._id].time;
                    if (firstTime === 1) {

                        if (!printState.mealTime) {
                            _this.props.first_mealtime(printState.dietTimePercent[row._id].time);
                            printState.mealTime = printState.dietTimePercent[row._id].time;
                        }

                        showTime = printState.mealTime;
                        let meailTimeObj = new Date(moment(printState.mealTime, ["h:mm A"])); //moment().format("2019-01-01 "+printState.mealTime);

                        let currentTimeObj = new Date(moment(printState.dietTimePercent[row._id].time, ["h:mm A"]));
                        totalDiff = meailTimeObj - currentTimeObj;
                    }
                    else if (firstTime > 1) {
                        let currentTimeObj = new Date(moment(printState.dietTimePercent[row._id].time, ["h:mm A"]));
                        let showTimeObj = new Date(currentTimeObj.getTime() + totalDiff);
                        showTime = formatTime(showTimeObj);
                    }
                    firstTime++;

                    txt += "<div>" + showTime + "</div>";

                    //graphTime = showTime;

                    let calorie = printState.dietTimePercent[row._id].percent * printState.selectCalorie / 100;

                    let orangePercent = printState.dietTimePercent[row._id].percent;

                    let style = "background: -webkit-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: -moz-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: -ms-linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";
                    style += "background: linear-gradient(right, orange " + orangePercent + "%, #dedede 0%);";

                    txt += "<div style='" + style + "'>";
                    txt += calorie;
                    txt += " KCAL</div>";
                }
            }

            return <div dangerouslySetInnerHTML={{ __html: txt }} />;
        }

        function dietChatShow(cell) {
            let dietTxt = [];
            //let classValue = '';
            for (let x in options[cell]) {
                let row = '';

                if (options[cell] && options[cell].length > 0) {
                    if (!TData[x]) {
                        TData[x] = { cal: 0, c: 0, f: 0, p: 0 };
                    }
                    TData[x]['cal'] = (TData[x].cal ? TData[x].cal : 0) + options[cell][x].calorie;
                    TData[x]['c'] = (TData[x].c ? TData[x].c : 0) + options[cell][x].carbohydrate;
                    TData[x]['f'] = (TData[x].f ? TData[x].f : 0) + options[cell][x].fat;
                    TData[x]['p'] = (TData[x].p ? TData[x].p : 0) + options[cell][x].protein;
                }

                if (options[cell] && options[cell].length >= 3) {
                    row = "<div class='dietWidth1'>";
                    row += options[cell][x].foodText;
                    row += "</div>";
                } else if (options[cell] && options[cell].length === 2) {
                    row = "<div class='dietWidth5'>";
                    row += options[cell][x].foodText;
                    row += "</div>";
                } else {
                    row = "<div>";
                    row += options[cell][x].foodText;
                    row += "</div>";
                }

                dietTxt.push(row);
            }

            return <div style={{ "float": "left", "width": "100%" }} dangerouslySetInnerHTML={{ __html: dietTxt.join("<div class='OR'> OR </div>") }} />;
        }

        function getTotalCalorieText() {
            let txt = "Total Calorie"
            if (printState?.dietLanguage)
                txt = objCalorie[printState?.dietLanguage]["calorieTxt"]

            return <div>{txt}<br />
                {printState?.selectCalorie} KCAL
            </div>
        }

        function getDietDetial(item) {
            return <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["carb"] : objCalorie[1]["carb"]}</th>
                        <th>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["prot"] : objCalorie[1]["prot"]}</th>
                        <th>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["fat"] : objCalorie[1]["fat"]}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{item.c.toFixed(2)}({Math.round((item.c * 400) / item.cal)}%)</td>
                        <td>{item.p.toFixed(2)}({Math.round((item.p * 400) / item.cal)}%)</td>
                        <td>{item.f.toFixed(2)}({Math.round((item.f * 900) / item.cal)}%)</td>
                    </tr>
                </tbody>
            </table>
        }

        // const printPadding =
        //     "@media print {.get-diet-details-inner-div{padding-left: 50px;}}";


        return (
            <div id="printable">
                <div>
                    <table class="table pt-info-box">
                        <thead>
                            <tr>
                                <td colSpan="4" style={{ border: "none" }}>

                                    <div className="only-print" hidden={printState.printHeader}>

                                        <h1>
                                            <center>
                                                <div className="logoname" style={{ padding: "3px", display: "inline-block", verticalAlign: "middle" }}> {printState.clinicName}</div>
                                            </center>
                                        </h1>
                                        <div style={{ fontSize: "14px" }}><center>{printState.clinicAddress} . Ph. : {printState.clinicPhone} · {printState.clinicEmail}</center></div>
                                    </div>
                                </td>
                            </tr>
                        </thead>

                        <tr>
                            <th className="p5" style={{ width: "102px", padding: "3px", fontSize: "14px", textAlign: "left" }}>Name</th>
                            <th colSpan="3" className="p5" style={{ width: '495px', padding: "3px", fontSize: "14px", textAlign: "left" }}>{printState.patient.name + "-" + this.state.patientId}&nbsp;&nbsp;&nbsp;|<span> {printState.patient.height !== 0 ? "Height" : ""}{printState.patient.height !== 0 ? ":" : ""}{printState.patient.height !== 0 ? " | " : ""}{printState.patient.height !== 0 ? `${printState.patient.height} CM` : ""}</span>{printState.patient.height !== 0 ? " | " : ""}<span>{printState.patient.weight !== 0 ? "Weight" : ""}{printState.patient.weight !== 0 ? ":" : ""}{printState.patient.weight !== 0 ? " | " : ""}{printState.patient.weight !== 0 ? `${printState.patient.weight} KG` : ""}</span>{printState.patient.weight !== 0 ? " | " : ""}<span>  {this.state.bmi !== 0 ? "BMI" : ""}{this.state.bmi !== 0 ? ":" : ""}{this.state.bmi !== 0 ? " | " : ""}{this.state.bmi !== 0 ? this.state.bmi : ""}</span>{this.state.bmi !== 0 ? " | " : ""}<span> {printState.patient.age !== 0 ? "Age" : ""}{printState.patient.age !== 0 ? ":" : ""}{printState.patient.age !== 0 ? `${printState.patient.age} Y / ${printState.patient.gender === "Male" ? "M" : "F"}` : ""}</span></th>
                        </tr>

                        <tr>
                            <th className="p5" style={{ width: '104px', padding: "5px", fontSize: "14px", textAlign: "left" }}>Times</th>
                            <th className="p5" style={{ width: '235px', fontSize: "14px", textAlign: "left" }}>{printState.treatmentName}</th>
                            <th className="p5" style={{ width: '130px', fontSize: "14px", textAlign: "left" }}>{printState.selectCalorie} KCAL</th>
                            <th className="p5" style={{ width: '130px', fontSize: "14px", textAlign: "left" }}>Date: {(printState.shareDate === null) ? moment().format('DD-MM-YYYY') : moment().format('DD-MM-YYYY')}</th>

                        </tr>

                        {printState.timeList.map(row => {

                            if (options[row._id] && options[row._id].length > 0) {

                                return <tr hidden={!row.show} key={row._id}>
                                    <td className="p5" style={{ width: '100px', padding: "5px", fontSize: "14px", textAlign: "right" }}>{timeName(row.name, row)}</td>
                                    <td colSpan="3" className="p5" style={{ width: '495px', padding: "3px", fontSize: "14px" }}>{dietChatShow(row._id)}
                                    </td>
                                </tr>
                            } else { return ''; }

                        })}

                        <tr>
                            <td className="get-diet-details" style={{ fontWeight: "bold", fontSize: "14px", textAlign: "right" }}>{getTotalCalorieText()}</td>
                            {TData.map((item, idx) => {
                                return <>
                                    {/* <style scoped>{printPadding}</style> */}
                                    <td style={{ fontWeight: "bold", fontSize: "14px" }} colSpan={idx === 1 ? 2 : ""} className="get-diet-details-inner-div">
                                        <div style={{ paddingLeft: '0px' }}>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["carb"] : objCalorie[1]["carb"]}: <span style={{ fontWeight: "300" }}>{item.c.toFixed(2)}({Math.round((item.c * 400) / item.cal)}%)</span></div>
                                        <div style={{ paddingLeft: '0px' }}>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["prot"] : objCalorie[1]["prot"]}: <span style={{ fontWeight: "300" }}>{item.p.toFixed(2)}({Math.round((item.p * 400) / item.cal)}%)</span></div>
                                        <div style={{ paddingLeft: '0px' }}>{printState?.dietLanguage != undefined ? objCalorie[printState?.dietLanguage]["fat"] : objCalorie[1]["fat"]}: <span style={{ fontWeight: "300" }}>{item.f.toFixed(2)}({Math.round((item.f * 900) / item.cal)}%)</span></div>
                                    </td>
                                </>
                                // return <td>{getDietDetial(item)}</td>
                            })
                            }

                        </tr>

                    </table>
                    <table>
                        <tr>
                            <td colSpan="4" style={{ border: "none" }}>
                                <ul style={{ 'padding': '0px 0px 0px 20px', margin: "0px", fontSize: "14px", textAlign: "left" }}>
                                    {printState.suggestions.map(row => {

                                        if (row !== 'hindi' && row !== 'english2hhh') {
                                            return <li key={row}>{row}</li>
                                        } else { return ''; }
                                    })}
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>
        );
    }
}

export default PrintDietChart;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import HeaderLinks from "Admin/components/Header/HeaderLinks.jsx";
// backgroundImage for Sidebar
import image from "Admin/assets/img/full-screen-image-3.jpg";
// image for avatar in Sidebar
import avatar from "Admin/assets/img/default_avatar-2.gif";
// logo for sidebar
// import logo from "logo.svg";
import logo from "Admin/assets/img/p-dp.png";
import dashRoutes from "Admin/routes/dash.jsx";

import $ from "jquery";

const bgImage = { backgroundImage: "url(" + image + ")" };
let lastParent = "";
let ps = null;
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components") !== "" ? true : false,
      openManageClinic:
        this.activeRoute("/manage-clinic") !== "" ? true : false,
      openManageMaster:
        this.activeRoute("/manage-master") !== "" ? true : false,
      openForms: this.activeRoute("/forms") !== "" ? true : false,
      openTables: this.activeRoute("/tables") !== "" ? true : false,
      openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute("/pages") !== "" ? true : false,
      isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
      width: window.innerWidth,
      openDiet: false,
      openDiet1: false,
      openMeal: false,
      openReport: false,
      openEmrMaster: false,
      activeTab: "",
    };
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname === routeName ? "active" : "";
  }
  // if the windows width changes CSS has to make some changes
  // this functions tell react what width is the window
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    // add event listener for windows resize
    window.addEventListener("resize", this.updateDimensions.bind(this));
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        wheelSpeed: 2,
        suppressScrollX: true,
      });
    }
  }
  componentDidUpdate() {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      setTimeout(() => {
        ps.update(this.refs.sidebarWrapper);
      }, 350);
    }
  }
  // function that creates perfect scroll bar for windows users (it creates a scrollbar that looks like the one from apple devices)
  isMac() {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  Logout() {
    localStorage.removeItem("_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("email");
    localStorage.removeItem("status");
    localStorage.removeItem("token");

    window.location.href = "/";
  }

  addActiceDropDownClass(key, isChildRoutes, lastParentData) {
    for (let i = 1; i < dashRoutes.length; i++) {
      let ele = document.getElementById(
        isChildRoutes ? `${lastParentData}-${i}` : `li-${i}`
      );
      if (ele !== null) ele.classList.remove("activeDropDownClass");
    }

    let ele = document.getElementById(
      isChildRoutes ? `${lastParentData}-${key + 1}` : `li-${key}`
    );
    if (ele !== null) ele.classList.add("activeDropDownClass");

    //         let currentTab=`li-${key}`

    //  if(this.state.previousActiveTab)
    //  {
    //      if(this.state.previousActiveTab!=currentTab)
    //      {
    //         if($("#" + this.state.previousActiveUl))
    //         {   if($("#" + this.state.previousActiveUl).hasClass("in"))
    //               {
    //                 $("#" + this.state.previousActiveUl).removeClass("in")

    //               }
    //             //eleChildUl.classList.add("ABC");
    //         }
    //      }
    //  }

    //  this.setState({previousActiveTab:currentTab,previousActiveUl:`li-ul-${key}`})
  }

  resetTab(prop) {
    dashRoutes.map((obj, idx) => {
      let tempTab = obj.state;
      if (obj.state === prop.state)
        this.setState({ [`${tempTab}`]: this.state[tempTab] ? false : true });
      else {
        if (prop.state === "openDiet1" && obj.state === "openDiet")
          this.setState({
            openMeal: false,
            openDiet1: this.state.openDiet1 ? false : true,
          });
        else if (prop.state === "openMeal" && obj.state === "openDiet")
          this.setState({
            openDiet1: false,
            openMeal: this.state.openMeal ? false : true,
          });
        else if (
          prop.state === "openEmrMaster" &&
          obj.state === "openManageMaster"
        )
          this.setState({ openEmrMaster: !this.state.openEmrMaster });
        else this.setState({ [`${tempTab}`]: false });
      }
    });
  }

  mapRoutes(prop, key, isChildRoutes) {
    var st = {};
    st[prop["state"]] = !this.state[prop.state];
    lastParent = isChildRoutes ? lastParent : `li-${key}`;
    let childId = isChildRoutes ? lastParent : `li-${key}`;
    if (prop.collapse) {
      let hidePage = false;

      if (prop.name == "Pages") hidePage = true;
      else hidePage = false;

      return (
        <li
          className={this.activeRoute(prop.path)}
          id={isChildRoutes ? `${lastParent}-${key + 1}` : `li-${key}`}
          key={key}
          style={{ display: hidePage ? "none" : "block" }}
        >
          <a
            onClick={() => {
              this.resetTab(prop);
              this.setState({ activeTab: prop.state });
              this.addActiceDropDownClass(key, isChildRoutes, childId);
            }}
          >
            <i className={prop.icon}></i>
            <p>
              {prop.name}
              <b
                className={
                  this.state[prop.state] ? "caret rotate-180" : "caret"
                }
              ></b>
            </p>
          </a>
          <Collapse in={this.state[prop.state]}>
            <ul id={`li-ul-${key}`} className="nav">
              {prop.collapse &&
                prop.views &&
                prop.views.length &&
                prop.views.map((prop, key) => {
                  if (prop.collapse && prop.views && prop.views.length) {
                    return this.mapRoutes(prop, key, true);
                  } else {
                    return (
                      <li className={this.activeRoute(prop.path)} key={key}>
                        <NavLink
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                        >
                          <span className="sidebar-mini">{prop.mini}</span>
                          <span className="sidebar-normal">{prop.name}</span>
                        </NavLink>
                      </li>
                    );
                  }
                })}
            </ul>
          </Collapse>
        </li>
      );
    } else {
      if (prop.redirect) {
        return null;
      } else {
        return (
          <li className={this.activeRoute(prop.path)} key={key}>
            <NavLink
              to={prop.path}
              className="nav-link"
              activeClassName="active"
            >
              <i className={prop.icon}></i>
              <p>{prop.name}</p>
            </NavLink>
          </li>
        );
      }
    }
  }

  render() {
    let userName = localStorage.getItem("userName");
    return (
      <div className="sidebar" data-color="black" data-image={image}>
        <div className="sidebar-background" style={bgImage}></div>
        <div className="logo">
          {/*<a href="/dashboard" className="simple-text logo-mini"> */}
          <a href="/dashboard" className="simple-text logo-mini">
            <div className="logo-img-das">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a href="/dashboard" className="simple-text logo-normal"></a>
          <div className="clearFix"></div>
        </div>
        <div className="clearFix"></div>
        <div className="sidebar-wrapper" ref="sidebarWrapper">
          <div className="user">
            <div className="photo">
              <img src={avatar} alt="Avatar" />
            </div>
            <div className="info">
              <a
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {userName}
                  {/*<b className={this.state.openAvatar ? "caret rotate-180":"caret"}></b> */}
                </span>
              </a>
              {/*<Collapse in={this.state.openAvatar}>
                                <ul className="nav">
                        					<li>
                        						<a>
                        							<span className="sidebar-mini">S</span>
                        							<span className="sidebar-normal">Settings</span>
                        						</a>
                        					</li>
                                </ul>
                            </Collapse> */}
            </div>
          </div>

          <ul className="nav">
            {/* If we are on responsive, we want both links from navbar and sidebar
                            to appear in sidebar, so we render here HeaderLinks */}
            {this.state.width <= 992 ? <HeaderLinks /> : null}
            {/*
                            here we render the links in the sidebar
                            if the link is simple, we make a simple link, if not,
                            we have to create a collapsible group,
                            with the speciffic parent button and with it's children which are the links
                        */}
            {dashRoutes.map((prop, key) => {
              return this.mapRoutes(prop, key, false);
            })}
            <li>
              <NavLink
                to=""
                className="nav-link"
                activeClassName="active"
                onClick={this.Logout.bind(this)}
              >
                <span className="sidebar-normal">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Card from 'Front/components/Card/Card.jsx';
import Button from 'Front/elements/CustomButton/CustomButton.jsx';
import Checkbox from 'Front/elements/CustomCheckbox/CustomCheckbox.jsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetPasswordAction } from 'Front/actions/login';
import loadingImg from 'Front/assets/img/loading.gif';


let Validator = require('validatorjs');
let formArr = {}
let rules = {
  confirmPassword: 'required',
  password: 'required',
};
let mess = {
  required: 'This field is required',
};
let validation = [];


class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      formArr: [],
      passwordError: null,
      confirm_passwordError: null,
      formData: {
        email: "",
        verificationToken: "",
        password: "",
        confirmPassword: ""
      },
      showProcessing: false,
      successMag: "",
      errMag: "",
    }

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    var formData = this.state.formData;
    var str = this.props.location.pathname;
    var urlData = str.split("/");

    if (urlData && urlData[2]) {
      formData['verificationToken'] = urlData[2];
    }

    if (urlData && urlData[3]) {
      formData['email'] = urlData[3];
    }

    this.setState({ formData });

    setTimeout(function () { this.setState({ cardHidden: false }); }.bind(this), 700);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isresetPWD !== this.props.isresetPWD) {
      let successMag = "Password has been set successfully.";
      this.setState({ showProcessing: false, successMag: successMag });

      setTimeout(function () { window.location.href = '/login'; }.bind(this), 1500);
    }

    if (nextProps.isresetPWDError !== this.props.isresetPWDError) {
      this.setState({ errMag: nextProps.loginMessage.message, showProcessing: false });
    }

  }

  resetPassword(evt) {
    evt.preventDefault();

    const _this = this;

    let formData = this.state.formData;

    if (this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      _this.props.resetPasswordAction(formData);
    }
  }

  allValidate(check) {
    if (!check) {
      formArr = []
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;

    if (e.target.name === 'password') {
      if (e.target.value && e.target.value.length < 5 || (field.password && field.password.length) < 5) {
        this.setState({ passwordError: (<small className="text-danger">You must enter a password of at least 6 to 12 characters.</small>) })
      } else if ((e.target.value && e.target.value.length > 11) && (field.password && field.password.length > 11)) {
        this.setState({ passwordError: (<small className="text-danger">You must enter a password of at least 6 to 12 characters.</small>) })
      } else {
        this.setState({ passwordError: null })
      }
    }

    if (e.target.name === 'confirmPassword') {
      if (e.target.value !== field.password) {
        this.setState({ confirm_passwordError: (<small className="text-danger">Passwords do not match.</small>) })
      } else {
        this.setState({ confirm_passwordError: null })
      }
    }

    field[e.target.name] = e.target.value;

    this.setState({
      formData: field
    });
  };

  onKeyDownSubmit(event) {
    let key = event.which || event.keyCode;
    if (key === 13) {
      document.getElementById('login-btn').click();
    }
  }

  render() {

    const { passwordError, confirm_passwordError, formData } = this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <Grid>
        <Row>

          <Col md={4} sm={6} mdOffset={4} smOffset={3}>

            <form>
              <div className="actionProcess" style={{ display: this.state.showProcessing ? 'block' : 'none' }}>
                <img src={loadingImg} alt="Loading" width="40px" />
                <center>Logging In - Please Wait</center>
              </div>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Reset Password"
                content={
                  <div>
                    {this.state.successMag ?
                      <div className="successMsg"> {this.state.successMag} </div>
                      :
                      <div className="errorMsg"> {this.state.errMag} </div>
                    }
                    <FormGroup>
                      <ControlLabel>New Password</ControlLabel>
                      <FormControl
                        placeholder="New Password"
                        type="password"
                        id="password"
                        name="password"
                        onChange={e => { this.handleChange(e); }}
                        onKeyDown={e => { this.onKeyDownSubmit(e); }}
                      />
                      <span className="errorMsg">{this.state.passwordError}{this.state.formArr.password && validation.errors.first('password')}</span>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Confirm Password</ControlLabel>
                      <FormControl
                        placeholder="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={e => { this.handleChange(e); }}
                        onKeyDown={e => { this.onKeyDownSubmit(e); }}
                      />
                      <span className="errorMsg">{this.state.confirm_passwordError}{this.state.formArr.confirmPassword && validation.errors.first('confirmPassword')}</span>
                    </FormGroup>
                  </div>
                }
                legend={
                  <Button id={'login-btn'} onClick={this.resetPassword.bind(this)} disabled={passwordError || confirm_passwordError} bsStyle="info" fill wd>Submit</Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {

    loginMessage: state.login.message,
    resetPWD: state.login.resetPWD,
    isresetPWD: state.login.isresetPWD,
    isresetPWDError: state.login.isresetPWDError,

  }
}
export default withRouter(connect(mapStateToProps, { resetPasswordAction })(ResetPassword));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import history from "../../routes/history";
import { Switch, Route, Redirect } from "react-router-dom";

import $ from "jquery";
//import history from '../../routes/history';

// dinamically create app routes
import appRoutes from "Front/routes/app.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import * as API from "Front/api/home";
const accessToken = localStorage.getItem("token");

// appConstants.socket.on("connect", function () {
//   const params = {
//     userId: localStorage.getItem("_id"),
//     clinicId: localStorage.getItem("clinicId"),
//     userType: localStorage.getItem("userType"),
//   };
//   appConstants.socket.emit("addClinic", params);
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.logoutIntervalFlag = null;
    this.autoLogOut = this.autoLogOut.bind(this);
    this.logoutInterval = this.logoutInterval.bind(this);
    this.Logout = this.Logout.bind(this);
    this.logoutIntervalJwt = this.logoutIntervalJwt.bind(this);
    this.logoutIntervalFlagJwt = null;
    if (accessToken) {
      appConstants.socket.on("connect", function () {
        const params = {
          userId: localStorage.getItem("_id"),
          clinicId: localStorage.getItem("clinicId"),
          userType: localStorage.getItem("userType"),
        };
        appConstants.socket.emit("addClinic", params);
      });
    }
  }
  componentDidUpdate(e) {
    // if(window.innerWidth < 993 && e.history.action === "PUSH" && document.documentElement.className.indexOf('nav-open') !== -1){
    //     document.documentElement.classList.toggle('nav-open');
    // }
    if (
      window.innerWidth < 320 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.autoLogOut();
      this.logoutInterval();
      this.logoutIntervalFlagJwt = setInterval(() => {
        let currentDate = new Date();
        let loginTime = new Date(localStorage.getItem("loginTime"));
        var minute = (currentDate - loginTime) / (1000 * 60);
        if (minute >= 13.5) {
          if (localStorage.getItem("token")) {
            this.logoutIntervalJwt();
          }

        }
      }, 1000);
    }
  }

  autoLogOut() {
    let obj = this;
    $(document).ready(function () {
      $("body").click(function () {
        clearInterval(obj.logoutIntervalFlag);
        obj.logoutInterval();
      });

      $("body").keyup(function () {
        clearInterval(obj.logoutIntervalFlag);
        obj.logoutInterval();
      });

      $("body").mousemove(function () {
        clearInterval(obj.logoutIntervalFlag);
        obj.logoutInterval();
      });
    });
  }

  logoutInterval() {
    let obj = this;
    this.logoutIntervalFlag = setInterval(function () {
      obj.Logout();
    }, 14400000);
  }

  async logoutIntervalJwt() {
    let response = await API.decodeToken({
      token: localStorage.getItem("token"),
    });
    if (response.data.statusCode === 200) {
      if (response.data.tokenExpire === false) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("loginTime", new Date());
      } else this.Logout();
    }
  }

  Logout() {
    localStorage.removeItem("_id");
    localStorage.removeItem("userName");
    localStorage.removeItem("userType");
    localStorage.removeItem("email");
    localStorage.removeItem("status");
    localStorage.removeItem("token");
    localStorage.removeItem("degree");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.clear();
    history.push({ pathname: "/login" });
  }

  componentWillMount() {
    var str = window.location.pathname;
    var urlData = str.split("/");
    if (
      window.location.pathname != "/dashboard" &&
      window.location.pathname != "/login" &&
      window.location.pathname != "/" &&
      window.location.pathname != "/forgot-password" &&
      window.location.pathname != "/privacy-policy" &&
      window.location.pathname != "/terms-condition" &&
      window.location.pathname != "/our_product" &&
      window.location.pathname != "/about" &&
      window.location.pathname != "/contact" &&
      window.location.pathname != "/view-box" &&
      urlData &&
      urlData[1] != "reset-password" &&
      urlData &&
      urlData[1] != "clinic-invitation-request"
    ) {
      window.location.href = "/dashboard";
    }
  }

  render() {

    return (
      <Switch>
        {appRoutes.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}

        {accessToken ? (
          <Redirect from="/" to="/dashboard" />
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
    );
  }
}

export default App;

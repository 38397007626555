/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithToken } from "./helper"

export const addFoodCategory = (data) => postRequestWithToken(data, 'admin/add-food-category');
export const updateFoodCategory = (data) => postRequestWithToken(data, 'admin/update-food-category');
export const changeFoodCategoryStatus = (data) => postRequestWithToken(data, 'admin/change-status-category');
export const foodCategoryList = (data) => postRequestWithToken(data, 'admin/food-category-list');
export const uploadFile = (data) => postRequestWithToken(data, 'upload-file');

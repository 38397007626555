/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { Grid, Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { clinicInvitationListAction, invitationSendMailAction } from 'Admin/actions/clinic_invitation';
import { confirmAlert } from 'react-confirm-alert';


class ClinicInvitationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asc_desc: true,
      clinicListData: [],
      isLoading: true,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      status: 'active',
      searchText: "",
      formData: {
        direction: 'desc',
        order: "createdAt",
        offset: 0,
        limit: 10,
        send: this.props.location && this.props.location.state && this.props.location.state.send,
        mailReplied: this.props.location && this.props.location.state && this.props.location.state.mailRepliedStatus
      },
    }
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
    this.getConfirmFun = this.getConfirmFun.bind(this);
    this.mailStatus = this.mailStatus.bind(this);
    this.sortHandle = this.sortHandle.bind(this);
  }

  componentDidMount() {

    this.props.clinicInvitationListAction(this.state.formData);
  }

  componentWillReceiveProps(nextProps) {

    switch (nextProps.responseType) {
      case "listing":
        if (nextProps.responseData && nextProps.responseData.statusCode === 200) {
          this.setState({
            clinicListData: nextProps.responseData.data.data,
            totalCount: nextProps.responseData.data.count
          })
          if (this.state.isLoading === true) { this.setState({ isLoading: false }); }
        }
        break;
      case "invitationSendMail":
        if (nextProps.responseData && nextProps.responseData.statusCode === 200) {
          this.props.handleClick('success', nextProps.responseData.msg)
          this.componentDidMount();
        }
        break;
      default:
        return true;
    }
    if (this.state.isLoading === true) { this.setState({ isLoading: false }); }
    if (nextProps && nextProps.responseData && nextProps.responseData.code === 500) {
      this.props.handleClick('errors', nextProps.responseData.msg)
    }
  }

  actionButton(cell, row, enumObject, rowIndex) {
    if (row.mailRepliedStatus) {
      let rowData = row.clinic[0];
      return (<span className="mail-status"><Link to={{ pathname: `update-clinic/`, state: rowData }}>Add to Clinic</Link></span>);

    } else if (row.mailSendStatus) {
      return (<span className="mail-status"><Link to={{ pathname: `add-clinic/`, state: row }}>Add to Clinic</Link><Link to={{ pathname: `add-invitation/`, state: row }} className="edit-inv"> Edit </Link></span>);

    } else {
      return (<span><Link to={{ pathname: `add-invitation/`, state: row }}> Edit </Link></span>)
    }
  }

  mailStatus(cell, row, enumObject, rowIndex) {
    if (row.mailSendStatus) {
      if (row.mailSendStatus && row.mailRepliedStatus) {
        return (<span>Received</span>)
      } else {
        return (<span>Sent</span>)
      }
    } else {
      return (<span>Not Send</span>)
    }
  }

  emailStatus(cell, row, enumObject, rowIndex) {
    //let statusMsg = "Are you sure? \nYou wish to send a mail.";
    //  return <Button className='acti-button' onClick={()=> {if(window.confirm(statusMsg)) this.emailStatusChange(row, rowIndex)} }>{row.mailSendStatus===true?'Resend':'Send'}</Button>
    return <Button className='acti-button' onClick={() => this.getConfirmFun(row, rowIndex)}>{row.mailSendStatus === true ? 'Resend' : 'Send'}</Button>
  }


  getConfirmFun(row, rowIndex) {

    confirmAlert({
      title: 'Confirm to send mail',
      message: 'Are you sure you wish to send a mail?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.emailStatusChange(row, rowIndex)
        },
        {
          label: 'No',
          //onClick: () => this.resetFormData()
        }
      ]
    })

  }

  emailStatusChange(row, rowIndex) {
    let reqObj = {
      id: row._id,
      email: row.email,
      host: window.location.protocol + '//' + window.location.host,
      doctorName: row.doctorName
    }
    this.props.invitationSendMailAction(reqObj);
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field });
  };

  _setTableOption() {
    if (this.state.isLoading) {
      return (<Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />);
    } else {
      return <div className="no-data"><h5>No data found!</h5></div>
    }
  }

  onUserCount(cell, row, enumObject, rowIndex) {
    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  onPageChange(page, sizePerPage) {
    let data = {};

    data['clinic_id'] = this.state.clinicId ? this.state.clinicId : '';
    if (this.state.searchText === "") {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    }
    data['send'] = this.state.formData.send;
    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    this.props.clinicInvitationListAction(data);
    
    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['clinic_id'] = this.state.clinicId ? this.state.clinicId : '';
        data['direction'] = this.state.direction;
        data['order'] = this.state.order;
        data['offset'] = this.state.offset;
        data['limit'] = this.state.limit;
        data['status'] = this.state.status;
        data['userType'] = this.state.userType;
        data['search'] = text;
        data['send'] = this.state.formData.send;
        this.setState({ sizePerPage: 10 })
        this.setState({ currentPage: 1 })
        this.props.clinicInvitationListAction(data);
      }
    } else {   
        this.componentDidMount();
    }
    this.setState({ searchText: text });
  }
  clearSearch(e) {
    this.setState({ searchText: '' });
    this.componentDidMount();
  }

  sortHandle(){
      
      this.setState({ asc_desc:!this.state.asc_desc });
      let data = this.state.formData;
      data.direction = this.state.asc_desc? "asc":"desc"
      this.setState({ formData : data });
      this.props.clinicInvitationListAction(data);
  }

  render() {
    const { clinicListData } = this.state;

    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      // onSearchChange: this.onSearchChange,
      noDataText: this._setTableOption(),
    };

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card content={
                <div className="fresh-datatables">
                  <Row>
                    <Col md={6}>
                      <ButtonToolbar>
                        <Button className="inacti-button" onClick={() => this.props.history.replace('add-invitation')}>Add Invitation</Button>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <hr></hr>

                  <div className="resp-t table-resposive educator-L">
                    <div className='search-box-admin'>
                      <div className='search'>
                        <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                          <span className='input-group-btn'>
                          <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button> 
                          </span>
                        </div>
                    </div>
                    <BootstrapTable
                      deleteRow={false}
                      data={clinicListData}
                      search={false} multiColumnSearch={true}
                      pagination={true} options={options}
                      striped hover condensed
                      scrollTop={'Bottom'}
                      remote={true}
                     
                      fetchInfo={{ dataTotalSize: this.state.totalCount }}>
                      <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '4%', textAlign: 'center' }} tdStyle={{ width: '4%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataField=''  dataFormat={this.onUserCount}># </TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '12%', textAlign: 'center' }} tdStyle={{ width: '12%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Name' }} dataField='clinicName' >Clinic Name
                      {
                        this.state.asc_desc?
                        <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                        <i onClick={(e) => {this.sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                      }
                       </TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'doctor Name' }} dataField='doctorName' >Doctor Name</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '14%', textAlign: 'center' }} tdStyle={{ width: '14%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic Email' }} dataField='email' >Clinic Email</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Phone' }} dataField='phone' >Contact</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'City' }} dataField='city' >City</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Status' }} dataField='' dataFormat={this.mailStatus} >Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Email Status' }} dataField='mailSendStatus' dataFormat={this.emailStatus.bind(this)} >Email Status</TableHeaderColumn>
                      <TableHeaderColumn thStyle={{ width: '14%', textAlign: 'center' }} tdStyle={{ width: '14%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Action' }} dataField='mailRepliedStatus' dataFormat={this.actionButton.bind(this)} >Action</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    responseType: state.clinicInvitation.type,
    responseData: state.clinicInvitation.response,
  }
}
export default withRouter(connect(mapStateToProps, { clinicInvitationListAction, invitationSendMailAction })(ClinicInvitationPage));

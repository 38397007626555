/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginAction } from 'Front/actions/login';
import {
  Grid, Row, Col,
  FormGroup, ControlLabel, FormControl, Media
} from 'react-bootstrap';
import banner_img from 'Front/assets/img/banner_3.png';
import jayesh from 'Front/assets/img/jayesh.png';
import drMsuniJain from 'Front/assets/img/sunil-jain.png';

class About extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Media>
        <div className="bg-about-banner">
          <div className="banner_content">
            <h2>Digitizing Healthcare</h2>
            <p>Cutting-edge Software Products for Healthcare Industry</p>
          </div>
        </div>

        <div className={"container1" + (this.props.fluid !== undefined ? "-fluid" : "")}>
          <Grid>
            <Row className="about-us">
              <h2>About Us</h2>
            </Row>

            <Row className="pera-about">
              <p>PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software     development. These experts have  come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.</p>
            </Row>

            <Col lg={12} md={12}>
              <div className="vision-bg background_reverse">
                <div className="founder-img">
                  <img src={drMsuniJain} alt="Logo" />
                </div>
                <div className="bg-mob content-text">
                  <h4>Dr. Sunil M Jain</h4>
                  <h6> <span>Director,</span> TOTALL Diabetes Hormone Institute, Indore</h6>
                  <h6> <span>Founder,</span> Practice-Aid</h6>
                  <p>Dr. Sunil M Jain, a distinguished expert in diabetes care. He is well known Endocrinologist in India. He has interest in developing technology solutions for doctors. Dr Sunil Mm Jain is involved in software solutions since 1999. His nearly 25 years experience in field of technology had given the concept of “Practice Aid”. His own center- TOTALL Diabetes Hormone Institute is one of the most digitalized center of India. Practice Aid is brain child of Dr Sunil M Jain created with objective to make patient management easy for doctors of all specialties. He understands the barriers and difficulties in developing an easy solution. He also knows that any technology solution is worth only when it saves time as well makes work more enjoyable and improve patient satisfaction. He keeps adding enhancements and wish that Practice Aid will be the technology of choice for all doctors in India.</p>
                </div>
              </div>
            </Col>

            <Col lg={12} md={12}>
              <div className="vision-bg vision-2 background_reverse">
                <div className="founder-img">
                  <img src={jayesh} alt="Logo" />
                </div>
                <div className="bg-mob content-text">
                  <h4>Mr. Jayesh Totla</h4>
                  <h6> <span>Developer</span> - PracticeAid</h6>
                  <p>Jayesh is an entrepreneur with around 18 years of experience in Software, Business Consulting and Program Management. He has successfully led his team in executing large projects in ERP, Enterprise Web, Ecommerce and Mobile domains; and has gathered considerable exposure of global clients in multiple geographical locations.
                    Jayesh co-founded SynergyTop, a company that delivers innovation and technology services. SynergyTop is currently engaged with clients in Healthcare, Manufacturing, Automotive,
                    Semi Conductor, Retail, Financial Services domain.</p>
                </div>
              </div>
            </Col>


            {/* <Col lg={12} md={12}>
                <div className="vision-bg">
                  <div className="founder-left">
                    <img  src={jatin} alt="Logo"/>
                     </div>
                    <div className="colo-bg-reverse bg-mob">
                    <h4>Mr. Jatin Nahar</h4>
                    <h6> <span>Founder</span> - PracticeAid</h6>
                    <p>Jatin is a result-oriented technology industry leader with vast experience in architecting and managing complex software/IT solutions.
                    He has strong background in creation and management of multi-disciplinary engineering teams, mobile & software product development and
                    building as well as sustaining customer relationships. Jatin has a penchant to create simple yet powerful technological solutions that
                    harnesses the synergies in an organization while driving growth.</p> 
                </div>
              </div>
            </Col> */}
          </Grid>
        </div>
        <div className="middle-spc"></div>
      </Media>
    );
  }
}

function mapStateToProps(state) {

  return {
    loginMessage: state.login.message,
    isLogin: state.login.isLogin,
    LoginData: state.login.LoginData,
    isLoginError: state.login.isLoginError,

  }
}
export default withRouter(connect(mapStateToProps, { loginAction })(About));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { Grid, Row, Col, FormControl, Form, FormGroup, ControlLabel, Button, Nav, NavItem, NavDropdown, MenuItem, Modal } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';

// import { connect } from 'react-redux';
// //import Select from 'react-select';
// import { withRouter, Link } from 'react-router-dom';

// import { readyCreatedMealListAction, readyCreatedMealchangestatusAction, updatePriorityAction } from 'Admin/actions/ready_created_meal';
// import { dietTimeListAction } from 'Admin/actions/diet_time';
// import { treatmentListTypeAction, treatmentListAction } from 'Admin/actions/treatment';
// import { regionListAction } from 'Admin/actions/region';
// import { foodListAction } from 'Admin/actions/food';
// import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import Loading from 'react-loading';
// //import { languageListAction } from 'Admin/actions/language';
// import { appConstants } from 'Admin/_constants/app.constants';

import { regionList } from 'Admin/api/region';
import { treatmentList } from 'Admin/api/treatment';
import { dietTimePlanList } from 'Admin/api/diet_time';
import { foodList } from 'Admin/api/food';
import { servingSizeList } from 'Admin/api/serving_size';
import { getMealByTreatment, readyCreatedMealList, removeMealOption, addMealOption } from 'Admin/api/ready_created_meal';


const MealAnalysis = (props) => {

    const [formData, setFormData] = useState({ mealType: 's' });
    const [regionLists, setRegionLists] = useState([]);
    const [treatmentLists, setTreatmentLists] = useState([]);
    const [subTreatmentLists, setSubTreatmentLists] = useState([]);
    const [dietTimeLists, setDietTimeLists] = useState([]);
    const [foodLists, setFoodLists] = useState([]);
    const [foodGroupLists, setFoodGroupLists] = useState([]);
    const [mealData, setMealData] = useState([]);
    const [servingSizeLists, setServingSizeLists] = useState([]);

    const [CalorieSlots] = useState([800, 1000, 1200, 1400, 1600, 1800, 2000, 2200, 2400, 2600, 2800, 3000]);

    const [isUpdate, setIsUpdate] = useState(false);
    const [show, setShow] = useState(false);
    const [dietInfo, setDietInfo] = useState({});
    const [treatmentType, setTreatmentType] = useState({});


    const handleInput = (e, field) => {

        let data = { ...formData };

        data[field] = e.target.value;

        if (field === 'Treatment') {
            let value = e.target.value;
            let tType = treatmentLists.find(s => s._id === e.target.value);
            setTreatmentType(tType);

            let list = treatmentLists.filter(item => item._id === value);
            if (list.length > 0) {
                setSubTreatmentLists(list[0].treatmentdata);

            } else {
                setSubTreatmentLists([]);
            }

        }
        setFormData(data);
        setIsUpdate(!isUpdate);

        if (field === 'SubTreatment') {
            getMeals(data);
        }
    }

    const getMeals = async (data) => {

        let timeResp = await dietTimePlanList({ treatmentId: data.SubTreatment });
        setDietTimeLists(timeResp.data.data.data);

        let resp = await getMealByTreatment(data);

        let list = resp.data.data;
        setMealData(list);
        // for(let i in list){
        //     if(list[i] && list[i].food_group){
        //         console.log(list[i].food_group);
        //     }
        // }

    }

    const getData = async () => {
        let regionResp = await regionList();
        setRegionLists(regionResp.data.data.data);

        let treatmentResp = await treatmentList();
        setTreatmentLists(treatmentResp.data.data.data);

        let foodGroupResp = await readyCreatedMealList({ offset: 0, limit: 5000, status: "active" });
        setFoodGroupLists(foodGroupResp.data.data.data);

        let foodResp = await foodList({
            direction: 'desc',
            order: "createdAt",
            offset: 0,
            limit: 1000,
            lang: 1,
            status: "active",
            veg: ""
        });

        let fList = foodResp.data.data.data;

        let fdList = [];

        for (let k in fList) {
            fdList[fList[k]._id] = fList[k];
        }
        setFoodLists(fdList);

        let Resp = await servingSizeList({
            direction: 'desc',
            order: "createdAt",
            offset: 0,
            limit: 1000,
            lang: 1,
            status: "active"
        });

        let sList = Resp.data.data.data;

        let ssList = [];

        for (let k in sList) {
            ssList[sList[k]._id] = sList[k];
        }
        setServingSizeLists(ssList);

        setIsUpdate(!isUpdate);
    }

    useEffect(() => {

        getData();

    }, []);

    const openDietBox = async (data) => {

        let sid = data.mtp_id;
        let subTreatment = subTreatmentLists.find(st => st._id === sid);
        data.subTreatment = subTreatment;
        data.headerText = data.headerText + ' ' + subTreatment.name;
        setShow(true);
        setDietInfo(data);
        //console.log(data);
    }

    const editMeal = (row) => {
        row.redirect_url = "food_group_add_edit/";
        if (row.foodType == 'custom') {
            row.redirect_url = "update-ready-created-meal/";
        }

        props.history.push({ pathname: row.redirect_url + row._id, state: { modelViewData: row } })

        //console.log(row);

        //to={{ pathname: modelViewData.redirect_url + modelViewData._id, state: { modelViewData } }}
    }

    const removeMeal = async (meal, type = 'veg') => {

        const resp = await removeMealOption({ id: meal._id });
        if (resp.data.statusCode === 200) {

            if (type === 'veg' && dietInfo.mealArr && dietInfo.mealArr.vegSelectd.length > 0) {
                let vegSelectd = dietInfo.mealArr.vegSelectd.filter(veg => veg._id !== meal._id);
                let data = { ...dietInfo };
                data.mealArr.vegSelectd = vegSelectd;

                let uvegSelectd = dietInfo.mealArr.uveg;
                uvegSelectd.push(meal.food_group[0]);
                data.mealArr.uveg = uvegSelectd;
                setDietInfo(data);
            }

            if (type === 'nveg' && dietInfo.mealArr && dietInfo.mealArr.nvegSelectd.length > 0) {
                let nvegSelectd = dietInfo.mealArr.nvegSelectd.filter(veg => veg._id !== meal._id);
                let data = { ...dietInfo };
                data.mealArr.nvegSelectd = nvegSelectd;

                let unveg = dietInfo.mealArr.unveg;
                unveg.push(meal.food_group[0]);
                data.mealArr.unveg = unveg;
                setDietInfo(data);
            }

            //setShow(false);
            //setDietInfo({});
            getMeals(formData);

        }
    }

    const addMeal = async (row, type = 'veg-s') => {

        let post = { treatmentPlanId: dietInfo.mtp_id, foodGroupId: row._id, dietTimeId: dietInfo.mdt_id, calorie: dietInfo.mpm_calorie, veg: type }

        const resp = await addMealOption(post);
        if (resp.data.statusCode === 200) {

            setShow(false);
            setDietInfo({});
            getMeals(formData);

        }
    }

    return <div className="main-content">
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <Card
                        content={
                            <div className="fresh-datatables">
                                <Row>
                                    <Col md={3}>
                                        <select className="form-control" name="mealType" onChange={(event) => handleInput(event, 'mealType')} value={formData.mealType}>
                                            <option value="">Meal Type</option>
                                            <option value="s">Urban</option>
                                            <option value="l">Rural</option>
                                            <option value="h">Ready formula</option>
                                        </select>
                                    </Col>
                                    <Col sm={3} className="spcc">
                                        <div className="form-group">
                                            <FormControl componentClass="select" name="Treatment" id="Treatment" onChange={e => { handleInput(e, 'Treatment'); }}>
                                                <option value="" >Treatment plan</option>
                                                {treatmentLists.length > 0 && treatmentLists.map(function (item) {
                                                    return <option value={item._id} key={item._id}>{item.name}</option>
                                                })}
                                            </FormControl>
                                        </div>
                                    </Col>
                                    <Col sm={3} className="spcc">
                                        <div className="form-group">
                                            <FormControl componentClass="select" name="SubTreatment" id="SubTreatment" onChange={e => { handleInput(e, 'SubTreatment'); }}>
                                                <option value="" >Sub Treatment plan</option>
                                                {subTreatmentLists.length > 0 && subTreatmentLists.map(function (item) {
                                                    return <option value={item._id} key={item._id}>{item.name}</option>
                                                })}
                                            </FormControl>
                                        </div>
                                    </Col>
                                    <Col sm={3} className="spcc">
                                        <div className="form-group">
                                            <FormControl componentClass="select" name="Region" id="Region" onChange={e => { handleInput(e, 'Region'); }}>
                                                <option value="" >Select region</option>
                                                {regionLists.length > 0 && regionLists.map(function (item) {
                                                    return <option value={item._id} key={item._id} >{item.name}</option>
                                                })}
                                            </FormControl>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    {dietTimeLists.length > 0 && dietTimeLists.map((time) => {
                                                        return <th key={time._id}>{time.name['1']}&nbsp;{time.dietPlanData && time.dietPlanData[0] && time.dietPlanData[0].percent ? time.dietPlanData[0].percent : '0'}%</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CalorieSlots.map((calorie, cIndex) => {

                                                    if (treatmentType && treatmentType.range_from <= calorie && treatmentType.range_to >= calorie) {

                                                        return <tr key={cIndex}>
                                                            <th key={calorie}>{calorie}</th>
                                                            {dietTimeLists.length > 0 && dietTimeLists.map((time, tIndex) => {


                                                                let percent = time.dietPlanData && time.dietPlanData[0] && time.dietPlanData[0].percent ? time.dietPlanData[0].percent : 0;

                                                                var _calorie = calorie * percent / 100;

                                                                let calorie_diff = (_calorie * 15) / 100;

                                                                if (_calorie > 100 && _calorie <= 300) {
                                                                    calorie_diff = (_calorie * 10) / 100;
                                                                }
                                                                else if (_calorie > 300) {
                                                                    calorie_diff = (_calorie * 5) / 100;
                                                                }
                                                                let min_cal = _calorie - calorie_diff - 0.5;
                                                                let max_cal = _calorie + calorie_diff + 0.5;

                                                                let min_cal2 = _calorie - calorie_diff - 0.5;
                                                                let max_cal2 = _calorie + calorie_diff + 0.5;

                                                                let vegC = 0;
                                                                let vegCount = 0;
                                                                let nvegC = 0;
                                                                let nvegCount = 0;
                                                                let mealArr = { vegSelectd: [], nvegSelectd: [], veg: [], nveg: [], all: [], uveg: [], unveg: [], };
                                                                let vegSelectd = [];
                                                                let nvegSelectd = [];


                                                                for (let m in mealData) {
                                                                    let meal = mealData[m];
                                                                    if (calorie === meal.calorie && time._id === meal.dietTimeId) {
                                                                        let foodGroupList = meal.food_group;
                                                                        for (let x in foodGroupList) {
                                                                            let item = foodGroupList[x];
                                                                            if (min_cal2 <= item.calorie && max_cal2 >= item.calorie && item.dietTimeId.includes(time._id)) {

                                                                                let ty = formData.mealType;

                                                                                if (item.treatmentPlanIds.includes(formData.SubTreatment) && time.dietPlanData[0].treatmentId === formData.SubTreatment) {

                                                                                    if ((meal.veg === 'non-veg-s' && ty === 's') || (meal.veg === 'non-veg-l' && ty === 'l') || (meal.veg === 'non-veg-h' && ty === 'h')) {
                                                                                        nvegC++;
                                                                                        mealArr.nvegSelectd.push(meal);
                                                                                        nvegSelectd.push(item._id);
                                                                                    } else if ((meal.veg === 'veg-s' && ty === 's') || (meal.veg === 'veg-l' && ty === 'l') || (meal.veg === 'veg-h' && ty === 'h')) {
                                                                                        vegC++;
                                                                                        mealArr.vegSelectd.push(meal);
                                                                                        vegSelectd.push(item._id);
                                                                                    }

                                                                                }

                                                                            }

                                                                        }
                                                                    }
                                                                }



                                                                for (let x in foodGroupLists) {
                                                                    let item = foodGroupLists[x];

                                                                    if (min_cal <= item.calorie && max_cal >= item.calorie && item.dietTimeId.includes(time._id) && item.treatmentPlanIds.includes(formData.SubTreatment)) {

                                                                        let ty = formData.mealType;

                                                                        if (ty === item.mealCategory && (item.veg === 'non-veg-s' || item.veg === 'non-veg-l' || item.veg === 'non-veg-h')) {
                                                                            nvegCount++;
                                                                            mealArr.nveg.push(item);
                                                                            mealArr.all.push(item);
                                                                            if (!nvegSelectd.includes(item._id)) {
                                                                                mealArr.unveg.push(item);
                                                                            }

                                                                        } else if (ty === item.mealCategory && (item.veg === 'veg-s' || item.veg === 'veg-l' || item.veg === 'veg-h')) {
                                                                            vegCount++;
                                                                            mealArr.veg.push(item);
                                                                            mealArr.all.push(item);
                                                                            if (!nvegSelectd.includes(item._id)) {
                                                                                mealArr.unveg.push(item);
                                                                            }
                                                                            if (!vegSelectd.includes(item._id)) {
                                                                                mealArr.uveg.push(item);
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                let calr = (percent * calorie) / 100;

                                                                let headerText = time.name['1'] + ' ' + ((percent * calorie) / 100) + ' kcal (' + calorie + ' kcal)';

                                                                let dietBox = {
                                                                    headerText: headerText,
                                                                    mpm_calorie: calorie,
                                                                    calorie: calr,
                                                                    mtp_id: formData.SubTreatment,
                                                                    mdt_id: time._id,
                                                                    formData: formData,
                                                                    mealArr: mealArr
                                                                };

                                                                return <td key={time._id} className="meal-ana-box" onClick={() => openDietBox(dietBox)}>
                                                                    <div className="cursor">
                                                                        <div className="veg-food">
                                                                            veg={vegC}/{vegCount}
                                                                        </div>
                                                                        <div className="non-veg-food">
                                                                            nonveg={nvegC}/{nvegCount}
                                                                        </div>
                                                                        <div className="calorie">
                                                                            ({(percent * calorie) / 100}kcal)
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            })}
                                                        </tr>
                                                    } else { return ''; }
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </div>
                        }
                    />
                </Col>
                {dietInfo &&
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title"
                        className="diet-analysis-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title">
                                {dietInfo.headerText}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md="6">
                                    <div className="veg-diet-box">

                                        {dietInfo.mealArr && dietInfo.mealArr.vegSelectd.length > 0 && dietInfo.mealArr.vegSelectd.map((meal, index) => {
                                            let row = meal.food_group[0];
                                            let foodStr = [];
                                            for (let i in row.group) {
                                                let food = foodLists[row.group[i].foodId];

                                                if (food && food.name) {
                                                    let servStr = '';
                                                    if (food.unitId && servingSizeLists[food.unitId]) {
                                                        servStr = " " + servingSizeLists[food.unitId].name['1'];
                                                    }
                                                    foodStr.push(food.name['1'] + ' ' + row.group[i].qty + servStr + (food.info['1'] && food.info['1'] !== '' ? ' (' + food.info['1'] + ')' : ""));
                                                }
                                            }
                                            let mlClass = "ml-box";

                                            return <div className={mlClass} key={index}>
                                                <p>{foodStr.join(' + ')}</p>
                                                <span className="remove_diet" onClick={e => removeMeal(meal, 'veg')}>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                                <span className="hand" onClick={e => editMeal(row)}>
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                            </div>
                                        })}

                                    </div>
                                    <div className="un-selected-veg"><br />
                                        {dietInfo.mealArr && dietInfo.mealArr.uveg.length > 0 && dietInfo.mealArr.uveg.map((row, index) => {

                                            let foodStr = [];
                                            for (let i in row.group) {
                                                let food = foodLists[row.group[i].foodId];

                                                if (food && food.name) {
                                                    let servStr = '';
                                                    if (food.unitId && servingSizeLists[food.unitId]) {
                                                        servStr = " " + servingSizeLists[food.unitId].name['1'];
                                                    }
                                                    foodStr.push(food.name['1'] + ' ' + row.group[i].qty + servStr + (food.info['1'] && food.info['1'] !== '' ? ' (' + food.info['1'] + ')' : ""));
                                                }
                                            }
                                            let mlClass = "ml-box";

                                            return <div className={mlClass} key={index}>
                                                <p>{foodStr.join(' + ')}</p>
                                                <span className="remove_diet" onClick={e => addMeal(row, 'veg-s')}>
                                                    <i className="fa fa-plus"></i>
                                                </span>
                                                <span className="hand" onClick={e => editMeal(row)}>
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="nveg-diet-box">
                                        {dietInfo.mealArr && dietInfo.mealArr.nvegSelectd.length > 0 && dietInfo.mealArr.nvegSelectd.map((meal, index) => {
                                            let row = meal.food_group[0];
                                            let foodStr = [];
                                            for (let i in row.group) {
                                                let food = foodLists[row.group[i].foodId];

                                                if (food && food.name) {
                                                    let servStr = '';
                                                    if (food.unitId && servingSizeLists[food.unitId]) {
                                                        servStr = " " + servingSizeLists[food.unitId].name['1'];
                                                    }
                                                    foodStr.push(food.name['1'] + ' ' + row.group[i].qty + servStr + (food.info['1'] && food.info['1'] !== '' ? ' (' + food.info['1'] + ')' : ""));
                                                }
                                            }
                                            let mlClass = "ml-box";
                                            if (row.veg === 'non-veg-s' || row.veg === 'non-veg-l' || row.veg === 'non-veg-h') {
                                                mlClass = mlClass + " non-mls";
                                            }

                                            return <div className={mlClass} key={index}>
                                                <p>{foodStr.join(' + ')}</p>
                                                <span className="remove_diet" onClick={e => removeMeal(meal, 'nveg')}>
                                                    <i className="fa fa-times"></i>
                                                </span>
                                                <span className="hand" onClick={e => editMeal(row)}>
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                    <div className="un-selected-nveg"><br />
                                        {dietInfo.mealArr && dietInfo.mealArr.unveg.length > 0 && dietInfo.mealArr.unveg.map((row, index) => {

                                            let foodStr = [];
                                            for (let i in row.group) {
                                                let food = foodLists[row.group[i].foodId];

                                                if (food && food.name) {
                                                    let servStr = '';
                                                    if (food.unitId && servingSizeLists[food.unitId]) {
                                                        servStr = " " + servingSizeLists[food.unitId].name['1'];
                                                    }
                                                    foodStr.push(food.name['1'] + ' ' + row.group[i].qty + servStr + (food.info['1'] && food.info['1'] !== '' ? ' (' + food.info['1'] + ')' : ""));
                                                }
                                            }
                                            let mlClass = "ml-box";
                                            if (row.veg === 'non-veg-s' || row.veg === 'non-veg-l' || row.veg === 'non-veg-h') {
                                                mlClass = mlClass + " non-mls";
                                            }

                                            return <div className={mlClass} key={index}>
                                                <p>{foodStr.join(' + ')}</p>
                                                <span className="remove_diet" onClick={e => addMeal(row, 'non-veg-s')}>
                                                    <i className="fa fa-plus"></i>
                                                </span>
                                                <span className="hand" onClick={e => editMeal(row)}>
                                                    <i className="fa fa-edit"></i>
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="text-center">
                                    <button className="add-meal-btn" onClick={e => props.history.push({ pathname: "food_group_add_edit/" })}><i className="fa fa-plus"></i></button><br />
                                    ADD Meal
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                }
            </Row>
        </Grid>
    </div>

}
export default MealAnalysis;
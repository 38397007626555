/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/emr";
import {
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { isString } from "highcharts";
const AddRareComplaints = ({
  setModalStatusRareComplaints,
  modalStatusRareComplaints,
  addRareComplaints,
  setAddRareComplaints,
  props,
  complaintData,
  complaintDuration,
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isComplaintExist, setIsComplaintExist] = useState(false);
  const [stringLimit, setStringLimit] = useState(false);
  const [AlreadyExistMessage, SetAlreadyExistMessage] = useState("");
  const addRareComplaintFun = () => {
    setModalStatusRareComplaints(true);
    setFormData({});
    setStringLimit(false);
  };

  const inputHandler = (e) => {
    let saveFormData = formData;
    if (e.target.value.length > 100) setStringLimit(true);
    else {
      setStringLimit(false);
      let str = e.target.value.replace(/\s+/g, " ");
      saveFormData[e.target.name] = str.trimStart();
      setFormData({ ...formData, ...saveFormData });
    }
    setIsComplaintExist(false);
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = true;
    if (post.symptomsSubCategoryName && post.symptomsSubCategoryName != "") {
      errArr.symptomsSubCategoryName = "";
    } else {
      errArr.symptomsSubCategoryName = "error";
      errflag = false;
    }
    setErrors({ ...errArr });
    return errflag;
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      submitHandler(e);
    }
  };

  const submitHandler = async (e) => {
    let testRes = "";
    e.preventDefault();
    let saveFormData = formData;
    saveFormData["symptomsSubCategoryName"] =
      formData.symptomsSubCategoryName?.trim();
    setFormData({ ...formData, ...saveFormData });
    let subCatId = "";
    let subCatName = "";
    if (validate()) {
      let resp = "";

      if (addRareComplaints === true) {
        resp = await API.addClinicSymptoms(formData);
      } else {
        let req = {
          patientId: complaintData.patientId,
          symptomsSubCategoryId: complaintData.symptomsSubCategoryId,
          symptomsSubCategoryName: formData.symptomsSubCategoryName,
          appointmentId: complaintData.appointmentId,
        };
        resp = await API.editComplaint(req);
      }
      if (resp.data.status === "Success" && resp.data.statusCode === 200) {
        if (addRareComplaints === true) {
          if (resp.data.flag === "existing") {
            SetAlreadyExistMessage(
              `${resp.data.msg} ${resp.data.symptomsCategoryName}.`
            );
            setIsComplaintExist(true);
            return;
          } else {
            subCatId = resp.data.clinicSymptom._id;
            subCatName = resp.data.clinicSymptom.symptomsSubCategoryName;
          }
        } else {
          if (resp.data.flag === "add") {
            subCatId = resp.data.subCategoryData._id;
            subCatName = resp.data.subCategoryData.symptomsSubCategoryName;
          } else if (resp.data.flag === "edit") {
            subCatId = resp.data.clinicSymptomsId;
            subCatName = formData.symptomsSubCategoryName;
          } else if (resp.data.flag === "existing") {
            SetAlreadyExistMessage(
              `${resp.data.msg} ${resp.data.symptomsCategoryName}.`
            );
            setIsComplaintExist(true);
            return;
          }
        }
        closeModal();
        props(
          complaintData.symptomsSubCategoryId,
          subCatId,
          subCatName,
          addRareComplaints === true ? "addRareComplaint" : resp.data.flag,
          complaintDuration
        );
      }
    }
  };

  const closeModal = () => {
    setErrors({});
    setIsComplaintExist(false);
    setModalStatusRareComplaints(false);
    setAddRareComplaints(true);
    setStringLimit(false);
  };

  useEffect(() => {
    if (!addRareComplaints) {
      setFormData(complaintData);
    }
  });

  return (
    <>
      <Button
        type="button"
        className="btn-link"
        onClick={(e) => addRareComplaintFun()}
      >
        Add New Symptoms
      </Button>
      <Modal
        show={modalStatusRareComplaints}
        onHide={closeModal}
        dialogClassName="modal-md modal-dialog-centered"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            {addRareComplaints === true ? "Add Complaints" : "Edit Complaint"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="body-parts">
          <form onKeyDown={(e) => onKeyDownHandler(e)}>
            {isComplaintExist && (
              <div style={{ color: "red" }}>
                <p>{AlreadyExistMessage}</p>
              </div>
            )}
            <div class="form-group">
              <label for="rareComplaint">Complaint</label>
              <FormControl
                type="textArea"
                autocomplete="off"
                name="symptomsSubCategoryName"
                value={formData.symptomsSubCategoryName}
                onChange={(e) => {
                  inputHandler(e);
                }}
                placeholder="Complaint"
                className={
                  "insulin-input " +
                  (errors.symptomsSubCategoryName
                    ? errors.symptomsSubCategoryName
                    : "")
                }
              />
            </div>
            {stringLimit && (
              <div style={{ color: "red" }}>
                <p>Only 100 Characters are allowed.</p>
              </div>
            )}
            <Button
              onClick={(e) => submitHandler(e)}
              className="btn-fill btn-primary"
            >
              {addRareComplaints === true ? "Add To My List" : "Save"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddRareComplaints;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddAttachment: false,
  isAddAttachmentError: false,
  AddAttachment: false,
  isUpdateAttachment: false,
  isUpdateAttachmentError: false,
  UpdateAttachment: false,
  isAttachmentChangeStatus: false,
  isAttachmentChangeStatusError: false,
  AttachmentChangeStatus: false,
  isAttachmentList: false,
  isAttachmentListError: false,
  AttachmentList: false,

  isAttachmentAutoSelectStatus: false,
  isAttachmentAutoSelectStatusError: false,
  AttachmentAutoSelectStatus: false,
  // isUploadFile:false,
  // isUploadFileError: false,
  // uploadFile: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_ADD_ATTACHMENT_REQUEST:
      return {
        ...state,
        message: '',
        isAddAttachment: false,
        isAddAttachmentError: false,
      }
    case actionTypes.GET_ADD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        message: 'Attachment Added Successfully',
        AddAttachment: action.payload.response,
        isAddAttachment: true,
        isAddAttachmentError: false,
      }
    case actionTypes.GET_ADD_ATTACHMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddAttachment: false,
        isAddAttachmentError: true
      }

    //Attachment Update

    case actionTypes.GET_UPDATE_ATTACHMENT_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateAttachment: false,
        isUpdateAttachmentError: false,
      }
    case actionTypes.GET_UPDATE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        message: 'Attachment Updated Successfully',
        UpdateAttachment: action.payload.response,
        isUpdateAttachment: true,
        isUpdateAttachmentError: false,
      }
    case actionTypes.GET_UPDATE_ATTACHMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateAttachment: false,
        isUpdateAttachmentError: true
      }

    //Link List
    case actionTypes.GET_ATTACHMENT_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isAttachmentList: false,
        isAttachmentListError: false,
      }
    case actionTypes.GET_ATTACHMENT_LIST_SUCCESS:
      return {
        ...state,
        message: '',
        AttachmentList: action.payload.response,
        isAttachmentList: true,
        isAttachmentListError: false,
      }
    case actionTypes.GET_ATTACHMENT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAttachmentList: false,
        isAttachmentListError: true
      }


    //Attachment Status Change
    case actionTypes.GET_ATTACHMENT_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isAttachmentChangeStatus: false,
        isAttachmentChangeStatusError: false,
      }
    case actionTypes.GET_ATTACHMENT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Attachment Status Changed',
        AttachmentChangeStatus: action.payload.response,
        isAttachmentChangeStatus: true,
        isAttachmentChangeStatusError: false,
      }
    case actionTypes.GET_ATTACHMENT_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAttachmentChangeStatus: false,
        isAttachmentChangeStatusError: true
      }

    //Attachment Status Change
    case actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isAttachmentAutoSelectStatus: false,
        isAttachmentAutoSelectStatusError: false,
      }
    case actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Attachment Status Changed',
        AttachmentAutoSelectStatus: action.payload.response,
        isAttachmentAutoSelectStatus: true,
        isAttachmentAutoSelectStatusError: false,
      }
    case actionTypes.GET_ATTACHMENT_AUTO_SELECT_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAttachmentAutoSelectStatus: false,
        isAttachmentAutoSelectStatusError: true
      }
    default:
      return state


  }
}

/********* Show list of Link */




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper"


export const addSupportSystem = (data) => postRequestWithTokenFile(data, 'admin/support-add');

export const updateSupportSystem = (data) => postRequestWithTokenFile(data, 'admin/update-specialization');

export const Supportlist = (data) => postRequestWithToken(data, 'admin/support-list');

export const commentReply = (data) => postRequestWithToken(data, 'admin/support-reply');

export const changeStatusTicket = (data) => postRequestWithToken(data, 'admin/support-status-change');

export const viewTicketDetails = (data) => postRequestWithToken(data, 'admin/support-commentList');

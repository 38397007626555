/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken, getRequestWithToken } from "./helper";


export const addStarStatus = (data) => postRequestWithTokenFile(data, "admin/add-star-status");

export const updateStarStatus = (data) => postRequestWithTokenFile(data, "admin/update-star-status");

export const list = (data) => postRequestWithToken(data, "admin/star-status-list");

export const changeStatus = (data) => postRequestWithToken(data, "admin/star-status");

export const deleteStarStatus = (data) => postRequestWithToken(data, "admin/star-status-delete");
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState } from 'react'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

const VisitListTable = ({ visitList, options, getIndex, getPatientOutClass, taskScreen, getOutClass, isHighlight, tagsContent, nameContent, get_age_by_dob, isApp, action, }) => {




  //console.log(visitListArr,"visitListArr")
  return (
    <div className="visit-list dashboard-table doctor-screen-table p-low-btn">
      <BootstrapTable
        data={visitList}
        search={true}
        multiColumnSearch={true}
        options={options}
        striped
        hover
        condensed
      >
        <TableHeaderColumn
          hidden={true}
          tdAttr={{ "data-attr": "id" }}
          dataField="id"
          dataSort={true}
          isKey
          searchable={false}
        >
          Id
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "3%" }}
          tdStyle={{ width: "3%" }}
          tdAttr={{ "data-attr": "#" }}
          dataField="sn"
          dataFormat={getIndex}
          columnClassName={getPatientOutClass}
        >
          #
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "9%" }}
          tdStyle={{ width: "9%" }}
          tdAttr={{ "data-attr": "PATIENT ID" }}
          dataField="patientId"
          dataSort={true}
          dataFormat={taskScreen}
          columnClassName={getOutClass}
        >
          Patient ID
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "21%", fontWeight: "600" }}
          tdStyle={{ width: "21%", fontWeight: "600" }}
          tdAttr={{ "data-attr": "NAME" }}
          dataField="name"
          columnClassName={isHighlight}
          dataFormat={nameContent}
          dataSort={true}
        >
          Patient Name
        </TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "5%" }}
          tdStyle={{ width: "5%" }}
          tdAttr={{ "data-attr": "AGE" }}
          dataField=""
          dataFormat={get_age_by_dob}
        >
          Age
        </TableHeaderColumn>

        <TableHeaderColumn thStyle={{ width: '8%' }} tdStyle={{ width: '8%' }} tdAttr={{ 'data-attr': 'CITY' }} dataField='city'>City</TableHeaderColumn>

        <TableHeaderColumn
          thStyle={{ width: "24%", fontWeight: "600" }}
          tdStyle={{ width: "24%", fontWeight: "600" }}
          tdAttr={{ "data-attr": "NAME" }}
          dataField="name"
          columnClassName={isHighlight}
          dataFormat={tagsContent}
        >
          Labels
        </TableHeaderColumn>

        {/* <TableHeaderColumn thStyle={{ width: '7%' }} tdStyle={{ width: '7%' }} tdAttr={{ 'data-attr': 'IN TIME' }} dataField='in_time'>In Time</TableHeaderColumn> */}

        {/* <TableHeaderColumn thStyle={{ width: '9%' }} tdStyle={{ width: '9%' }} tdAttr={{ 'data-attr': 'NEXT V' }} dataField='' dataFormat={this.emr.bind(this)}>Prescription</TableHeaderColumn> */}

        {/* <TableHeaderColumn thStyle={{ width: '7%' }} tdStyle={{ width: '7%' }} tdAttr={{ 'data-attr': 'NEXT V' }} dataField='' dataFormat={this.getPatientOutClass.bind(this)}>Visit</TableHeaderColumn> */}


        {/* <TableHeaderColumn
                      thStyle={{ width: "6%", minWidth: "80px" }}
                      tdStyle={{ width: "6%", minWidth: "80px" }}
                      tdAttr={{ "data-attr": "NEXT V" }}
                      dataField=""
                      dataFormat={getReports}
                    >
                      Reports
                    </TableHeaderColumn> */}
        <TableHeaderColumn
          thStyle={{ width: "5%", minWidth: "150px" }}
          tdStyle={{ width: "5%", minWidth: "150px" }}
          tdAttr={{ "data-attr": "APP" }}
          dataField=""
          dataFormat={isApp}
        >
          App
        </TableHeaderColumn>
        <TableHeaderColumn
          thStyle={{ width: "7%", minWidth: "150px" }}
          tdStyle={{ width: "7%", minWidth: "150px" }}
          dataFormat={action}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  )
}

export default VisitListTable
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from "react";
import CloseIc from "../../assets/img/icons/close-ic.svg";
import CalenderImg from "../../assets/img/calendar.jpg";
import ClinicCalender from "../Pages/ClinicCalender/ClinicCalender";
const Schedule = (props) => {
  const [activeScheduleTab, setActiveScheduleTab] = useState(true);

  const handleScheduleTab = (activeTabStatus) => {
    setActiveScheduleTab(activeTabStatus);
  };
  return (
    <>
      <div className="side-modal side-modal-wrapper">
        <div className="calendar">
          <div className="side-modal-header">
            <h2>Your Schedule</h2>
            <div className="side-modal-header-action">
              {/* <a href="#" className="btn-link">
                                Add
                            </a> */}
              <span className="close-btn">
                <img
                  src={CloseIc}
                  alt="close"
                  onClick={() => props.modal(false)}
                />
              </span>
            </div>
          </div>
          <div className="dashboard-2-0-tabs">
            <div className="dashboard-2-0-tabs-inner">
              {/* <button
                class={`btn-tab ${!activeScheduleTab ? "btn-tab-active" : ""}`}
                type="button"
                onClick={(e) => handleScheduleTab(false)}
              >
                <span>Today</span>
              </button>
              <button
                class={`btn-tab ${activeScheduleTab ? "btn-tab-active" : ""}`}
                type="button"
                onClick={(e) => handleScheduleTab(true)}
              >
                <span>Holiday Schedule</span>
              </button> */}
            </div>
          </div>
          <div className="side-modal-content">
            {!activeScheduleTab && (
              <img className="cal-img" src={CalenderImg} alt="Calender Image" />
            )}
            {
              activeScheduleTab && <ClinicCalender />
              // <div className="content-items-wrapper">

              //     <div className="content-item">
              //         <div className="content-item-col">
              //             <h4>10 Patients visit scheduled</h4>
              //             <p className='content-item-p schedule-time'>12:00-03:30 PM</p>
              //             <p className='content-item-p schedule-time'>Apollo Clinic, Ram nagar</p>
              //         </div>
              //         <div className="content-item-col item-action">
              //             <a href='#' className='btn-link'>Update</a>
              //             <a href='#' className='btn-link'>Delete</a>
              //         </div>
              //     </div>
              //     <div className="content-item">
              //         <div className="content-item-col">
              //             <h4>10 Patients visit scheduled</h4>
              //             <p className='content-item-p schedule-time'>12:00-03:30 PM</p>
              //             <p className='content-item-p schedule-time'>Apollo Clinic, Ram nagar</p>
              //         </div>
              //         <div className="content-item-col item-action">
              //             <a href='#' className='btn-link'>Update</a>
              //             <a href='#' className='btn-link'>Delete</a>
              //         </div>
              //     </div>
              //     <div className="content-item">
              //         <div className="content-item-col">
              //             <h4>10 Patients visit scheduled</h4>
              //             <p className='content-item-p schedule-time'>12:00-03:30 PM</p>
              //             <p className='content-item-p schedule-time'>Apollo Clinic, Ram nagar</p>
              //         </div>
              //         <div className="content-item-col item-action">
              //             <a href='#' className='btn-link'>Update</a>
              //             <a href='#' className='btn-link'>Delete</a>
              //         </div>
              //     </div>
              // </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;

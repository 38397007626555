/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState } from "react";
import AddAppointment from "../../views/Pages/Appointment/AddAppointment";
import { Link, useHistory } from "react-router-dom";

const Breadcrum = (props) => {
  return (
    <>
      <div className="welcome-user">
        <div className="flex-col left-info">
          <div className="breadcrum">
            {
              window.location.pathname == "/patient-details" ? (
                <>
                  {" "}
                  <span>
                    <Link to="/">Dashboard</Link>
                  </span>{" "}
                  <span>Patient Details</span>
                </>
              ) : (
                <>
                  <span>
                    <Link to="/">Dashboard</Link>
                  </span>{" "}
                  <span>
                    <Link to="/patient-details">Patient Details</Link>
                  </span>{" "}
                  <span>EMR</span>
                </>
              )
              // window.location.pathname == "/patient-details" ? (
              //   <>
              //     {" "}
              //     <span>
              //       <Link to="/patient-details">Dashboardss</Link>
              //     </span>{" "}
              //     <span>Patient Details</span>
              //   </>
              // ) : (
              //   <>
              //     {" "}
              //     <span>
              //       <Link to="/">Dashboard</Link>
              //     </span>{" "}
              //     <span>Patient Details</span>
              //   </>
              // )
            }
          </div>

          {/* <h1>Patient Details</h1> */}
        </div>
        {props.ParentComponent === "PatientDetails" ? (
          <AddAppointment
            data={props.data}
            setModal={props.setModal}
            ModalStatus={props.ModalStatus}
            addAppointment={props.addAppointment}
            setAddAppointment={props.setAddAppointment}
            fromPatientDetail={true}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Breadcrum;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper";

export const uploadFile = (data) => postRequestWithTokenFile(data, "upload-file");

export const stateList = (data) => postRequestWithToken(data, "state-list");

export const countryList = (data) => postRequestWithToken(data, "country-list");

export const planList = (data) => postRequestWithToken(data, "admin/plan-list");
export const addPlan = (data) => postRequestWithToken(data, "admin/add-plan");
export const updatePlan = (data) =>
  postRequestWithToken(data, "admin/update-plan");
export const changePlanStatus = (data) =>
  postRequestWithToken(data, "admin/change-plan-status");

export const addKnowledge = (data) =>
  postRequestWithToken(data, "admin/add-knowledge");
export const updateKnowledge = (data) =>
  postRequestWithToken(data, "admin/update-knowledge");
export const changeKnowledgeStatus = (data) =>
  postRequestWithToken(data, "admin/change-knowledge-status");

export const knowledgeList = (data) =>
  postRequestWithToken(data, "admin/knowledge-list");
export const deleteKnowldge = (data) =>
  postRequestWithToken(data, "admin/knowledge-delete");

export const categoryList = (data) =>
  postRequestWithToken(data, "category-list");

export const updateTermcondition = (data) =>
  postRequestWithToken(data, "admin/update-termCondition");

export const termconditionList = (data) =>
  postRequestWithToken(data, "termCondition");

export const dashboard = (data) =>
  postRequestWithToken(data, "admin/dashboard");

export const patternList = (data) =>
  postRequestWithToken(data, "admin/check-pattern");

export const uploadMultiFile = (data) =>
  postRequestWithTokenFile(data, "multi-upload-file");

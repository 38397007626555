/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Grid,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { updateUserAction, uploadFileAction } from "Admin/actions/user";
import { clinicListAction } from "Admin/actions/clinic";
import { specializationListAction } from "Admin/actions/specialization";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { titleOptions } from "../../../Front/variables/Variables.jsx";
import loadingImg from "Admin/assets/img/loading.gif";
import { appConstants } from "../../../Front/_constants/app.constants.js";
import ClinicHeader from "../ClinicHeader/ClinicHeader";
import ImageComponent from "../../components/Common/ImageComponent.js";
let Validator = require("validatorjs");
let formArr = {};
let rules = {
  title: "required",
  user_name: "required",
  email: "required|email",
  group: "required",
  clinic_name: "required",
  status: "required",
  first_name: "required|string",
  last_name: "required|string",
  phone: "required|numeric",
};
let mess = {
  required: "This field is required",
  numeric: "Enter numeric digits",
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateUser extends Component {
  constructor(props) {
    super(props);

    var dropdownDisable = false;
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.clinicDetail
    ) {
      dropdownDisable = true;
    }

    this.vForm = this.refs.vForm;
    this.state = {
      clinicList: [],
      isEditing: false,
      onPageLoad: false,
      passwordError: null,
      confirm_passwordError: null,
      specializationList: [],
      clinic: "",
      phoneError: null,
      specializations: [],
      formArr: [],
      photo: null,
      limitStatus: true,
      imagePreviewUrl: this.props.location.state.row.image
        ? appConstants.s3UploadUrl + this.props.location.state.row.image
        : "",
      formData: {
        title: this.props.location.state.row.title
          ? this.props.location.state.row.title
          : "",
        email: this.props.location.state.row.email,
        password: this.props.location.state.row.show_password,
        confirm_password: this.props.location.state.row.show_password,
        specializations: this.props.location.state.row.specializations
          ? this.props.location.state.row.specializations
          : [{ label: "", value: "" }],
        file: this.props.location.state.row.image,
        id: this.props.location.state.row._id,
        clinic_name: this.props.location.state.row.clinicName,
        clinic_id: this.props.location.state.row.clinicId,
        user_name: this.props.location.state.row.userName,
        status: this.props.location.state.row.status,
        phone: this.props.location.state.row.phone,
        email: this.props.location.state.row.email,
        group: this.props.location.state.row.userType,
        user_name: this.props.location.state.row.userName,
        first_name: this.props.location.state.row.firstName,
        last_name: this.props.location.state.row.lastName,
        degree: this.props.location.state.row.degree,
        specializations_add: this.props.location.state.row.specializations_add,
      },
      dropdownDisable: dropdownDisable,
      tempFile: "",
      limitData: {
        direction: "desc",
        order: "createdAt",
        searchText: "",
      },
      clinicHeaderView:
        this.props &&
          this.props.location &&
          this.props.location.clinicDetail &&
          this.props.location.clinicDetail._id
          ? this.props.location.clinicDetail._id
          : "",
    };

    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    this.props.clinicListAction(this.state);
    let limitData = this.state.limitData;
    limitData["lilitStatus"] = true;
    this.props.specializationListAction(this.state.limitData);

    let specialization =
      this.props.location.state.row &&
        this.props.location.state.row.specializations
        ? this.props.location.state.row.specializations
        : [{ label: "", value: "" }];
    let entry = [];
    if (specialization && specialization.length > 0) {
      specialization.map((key, index) =>
        entry.push({ value: key.id, label: key.name })
      );
    }
    this.setState({
      specializations: entry,
      clinic: this.props.location.state.row.clinicId,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdateUserError !== this.props.isUpdateUserError) {
      if (nextProps.updateUserMsg.errors) {
        nextProps.updateUserMsg.errors.map((key, i) => {
          this.setState({
            [key.param + "Error"]: (
              <small className="text-danger">{key.msg}</small>
            ),
          });
        });
      }
      this.setState({ showProcessing: false });
    }

    if (nextProps.isUpdateUser !== this.props.isUpdateUser) {
      this.props.handleClick("success", "User updated successfully.");
      if (this.props.location.clinicRedirect) {
        this.props.history.push(
          `/admin/user-list/` + this.props.location.state.row.clinicId,
          this.props.location.clinicDetail
        );
      } else {
        this.props.history.push(`/admin/user-list`);
      }
    }

    if (nextProps.isUploadFile !== this.props.isUploadFile) {
      const _this = this;

      // let uploaded_file = this.state.uploaded_file;
      let field = this.state.formData;
      field["file"] = nextProps.uploadFile.file_name;
      this.setState({ formData: field });

      //this.setState({ photo: nextProps.uploadFile.file_name });
    }

    if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
      if (nextProps.uploadFile.errors) {
        let uploaded_file = this.state.uploaded_file;
        nextProps.uploadFile.errors.map((key, i) => {
          this.setState({ [uploaded_file + "Error"]: key.msg });
        });
      }
    }

    if (nextProps.isClinicList !== this.props.isClinicList) {
      this.setState({
        clinicList: nextProps.ClinicList.data.data,
      });
    }

    if (
      nextProps.isSpecializationList &&
      nextProps.isSpecializationList !== this.props.isSpecializationList
    ) {
      if (
        nextProps.SpecializationList &&
        nextProps.SpecializationList.data &&
        nextProps.SpecializationList.data.data &&
        nextProps.SpecializationList.data.data.length > 0
      ) {
        this.state.specializationList =
          nextProps.SpecializationList.data.data.map((key, i) => {
            return { value: key._id, label: key.name };
          });
      }
    }
  }

  fileChangedHandler(event, elename) {
    // let file = e.target.files[0];
    // if(file){

    //   this.props.uploadFileAction(file);
    //  }

    let file = event.target.files[0];

    let formData = this.state.formData;
    formData["photo"] = file.name;
    this.setState({ tempFile: file, formData: formData, onPageLoad: true });

    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ imagePreviewUrl: reader.result });
    };

    if (file) {
      this.props.uploadFileAction(file);
      reader.readAsDataURL(file);
    }
  }

  handleChange(e) {
    e.preventDefault();

    let field = this.state.formData;

    if (e.target.name === "password") {
      if (
        e.target.value &&
        !e.target.value.match(appConstants.PswValidCharecters)
      ) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else if (e.target.value && e.target.value.length < 6) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else if (e.target.value && e.target.value.length > 12) {
        this.setState({
          passwordError: (
            <small className="text-danger">
              6 to 12 characters which contain at least one lowercase letter,
              one uppercase letter, one numeric digit, and one special
              character.
            </small>
          ),
        });
      } else {
        this.setState({ passwordError: null });
      }

      if (e.target.value !== field.password) {
        this.setState({
          confirm_passwordError: (
            <small className="text-danger">Passwords do not match.</small>
          ),
        });
      } else {
        this.setState({ confirm_passwordError: null });
      }
    }

    if (e.target.name === "confirm_password") {
      if (e.target.value !== field.password) {
        this.setState({
          confirm_passwordError: (
            <small className="text-danger">Passwords do not match.</small>
          ),
        });
      } else {
        this.setState({ confirm_passwordError: null });
      }
    }

    if (e.target.name === "phone") {
      if (e.target.value && e.target.value.length < 10) {
        this.setState({
          phoneError: (
            <small className="text-danger">
              Phone number has to be 10-12 digits.
            </small>
          ),
        });
      } else {
        this.setState({ phoneError: null });
      }
    }

    field[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      field[e.target.name] = e.target.value ? e.target.value.trim() : "";
    }

    this.setState({ formData: field });
  }

  handleSpecialization(field) {
    let entry = [];
    let data = this.state.formData;
    field.map((key, index) =>
      entry.push({
        id: key.value,
        name: key.label,
      })
    );
    data["specializations"] = entry;
    this.setState({ formData: data });
  }

  handleClinic(e) {
    let field = this.state.formData;
    let clinic = this.state.clinic;

    let index = e.target.selectedIndex;
    field["clinic_id"] = e.target.value;
    field["clinic_name"] = e.target[index].text;
    clinic = e.target[index].value;

    this.setState({ formData: field, clinic });
  }

  editUserHandler(evt) {
    const _this = this;
    if (this.allValidate(false)) {
      _this.setState({ showProcessing: true });
      _this.props.updateUserAction(this.state.formData);
    }
    //validation.errors()
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }
  render() {
    const { phoneError, formData, passwordError, confirm_passwordError } =
      this.state;

    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    return (
      <div className="main-content" style={{ padding: "15px 0px" }}>
        {/*<Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Edit User"
                                content={
                                    <form>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Username: <span className="star">*</span></ControlLabel>
                                                    <FormControl type="text" name="user_name" onChange={e => { this.handleChange(e); }} value={this.state.formData.user_name} />
                                                    <span className="errorMsg">{this.state.user_nameError}{this.state.formArr.user_name && validation.errors.first('user_name')}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Email adress: <span className="star">*</span></ControlLabel>
                                                    <FormControl type="text" name="email" value={this.state.formData.email} onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">{this.state.emailError}{this.state.formArr.email && validation.errors.first('email')}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <ControlLabel>Group: <span className="star">*</span></ControlLabel>
                                                    <select className="form-control" name="group" value={this.state.formData.group} onChange={e => { this.handleChange(e); }} >
                                                        <option value="">Select Group</option>
                                                        <option value="educator">Educator</option>
                                                        <option value="doctor">Doctor</option>
                                                        <option value="receptionist">Receptionist</option>
                                                    </select>
                                                    <span className="errorMsg">{this.state.groupError}{this.state.formArr.group && validation.errors.first('group')}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <ControlLabel>Clinic: <span className="star">*</span></ControlLabel>
                                                    <select className="form-control" name="clinic_name" value={this.state.clinic} onChange={e => { this.handleClinic(e); }} >
                                                        <option value="">Select Clinic</option>
                                                        {this.state.clinicList.map(function (item) {
                                                            return <option key={item._id} name={item.name} value={item._id}>{item.name}</option>
                                                        })}
                                                    </select>
                                                    <span className="errorMsg">{this.state.clinicError}{this.state.formArr.clinic_name && validation.errors.first('clinic_name')}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Status: <span className="star">*</span></ControlLabel>
                                                    <select className="form-control" name="status" value={this.state.formData.status} onChange={e => { this.handleChange(e); }}>
                                                        <option value="active">Enabled</option>
                                                        <option value="inactive">Disbled</option>
                                                    </select>
                                                    <span className="errorMsg">{this.state.statusError}{this.state.formArr.status && validation.errors.first('status')}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                          <Col md={3}>
                                              <FormGroup>
                                                <ControlLabel>Title: <span className="star">*</span></ControlLabel>
                                                <select className="form-control" name="title" value={this.state.formData.title} onChange={(event) => this.handleChange(event)}>
                                                    <option value="">Select title</option>
                                                    {

                                                        titleOptions.map(function (item) {
                                                            let selected = false;
                                                            if (formData.title === item.label) {
                                                                selected = true;
                                                            }
                                                            return <option key={item.value} value={item.label} selected={selected}>{item.label}</option>
                                                        })
                                                    }
                                                </select>
                                                <span className="errorMsg">
                                                    {this.state.titleError}
                                                    {this.state.formArr.title && validation.errors.first('title')}</span>
                                              </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <ControlLabel>First Name: <span className="star">*</span></ControlLabel>
                                                    <FormControl type="text" name="first_name" value={this.state.formData.first_name} onChange={e => { this.handleChange(e); }}/>
                                                    <span className="errorMsg">{this.state.first_nameError}{this.state.formArr.first_name && validation.errors.first('first_name')}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <ControlLabel>Last Name: <span className="star">*</span></ControlLabel>
                                                    <FormControl type="text" name="last_name" value={this.state.formData.last_name} onChange={e => { this.handleChange(e); }}/>
                                                    <span className="errorMsg">{this.state.last_nameError}{this.state.formArr.last_name && validation.errors.first('last_name')}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <ControlLabel>Phone: <span className="star">*</span></ControlLabel>
                                                    <FormControl type="text" name="phone" value={this.state.formData.phone} onChange={e => { this.handleChange(e); }} />
                                                    <span className="errorMsg">{this.state.phoneError}{this.state.formArr.phone && validation.errors.first('phone')}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <label className="control-label">specializations</label>
                                                    <Select
                                                        placeholder="Select specializations"
                                                        name="specializations"
                                                        id="specializations"
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        value={this.state.specializations}
                                                        options={this.state.specializationList}
                                                        onChange={(value) => { this.setState({ specializations: value});this.handleSpecialization(value)}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Degree:</ControlLabel>
                                                    <FormControl type="text" name="degree" value={this.state.formData.degree} onChange={e => { this.handleChange(e); }} />

                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Profile Photo:</ControlLabel>

                                                    <input type="file" name="file"  onChange={e => { this.fileChangedHandler(e, "file") }}></input>{this.state.formData.file}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Button type="button" bsStyle="info" fill pullRight onClick={this.editUserHandler.bind(this)} disabled={ phoneError}>
                                            Save
                                            </Button>
                                        <div className="clearfix"></div>
                                    </form>
                                }
                            />
                        </Col>
                    </Row>
                </Grid> */}

        <Grid fluid>
          <Row>
            <Col md={12}>
              {this.state.dropdownDisable && (
                <ClinicHeader
                  componentData={{
                    clinicId: this.props.location.state.clinicDetail._id,
                  }}
                />
              )}
              <Form horizontal>
                <div
                  className="actionProcess"
                  style={{
                    display: this.state.showProcessing ? "block" : "none",
                  }}
                >
                  <img src={loadingImg} alt="Loading" width="40px" />
                  <center>Logging In - Please Wait</center>
                </div>
                <Card
                  title={
                    <legend>
                      Account Information
                      {!this.state.dropdownDisable && (
                        <Button
                          className="go-back-btn-2"
                          bsStyle="info"
                          onClick={(e) => {
                            this.goBackFun(e);
                          }}
                        >
                          Back
                        </Button>
                      )}
                      {/* <Button className="go-back-btn-2" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button> */}
                    </legend>
                  }
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>
                            Username: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="user_name"
                            value={this.state.formData.user_name}
                            disabled={this.state.formData.user_name}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.user_nameError}
                            {this.state.formArr.user_name &&
                              validation.errors.first("user_name")}
                          </span>
                        </Col>

                        <Col sm={3}>
                          <ControlLabel>Password:</ControlLabel>
                          <FormControl
                            type="text"
                            name="password"
                            autoComplete="off"
                            value={this.state.formData.password}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.passwordError}
                            {this.state.formArr.password &&
                              validation.errors.first("password")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>Confirm Password:</ControlLabel>
                          <FormControl
                            type="text"
                            name="confirm_password"
                            autoComplete="off"
                            value={this.state.formData.confirm_password}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.confirm_passwordError}
                            {this.state.formArr.confirm_password &&
                              validation.errors.first("confirm_password")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Email Address: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="email"
                            value={this.state.formData.email}
                            disabled={this.state.formData.email}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.emailError}
                            {this.state.formArr.email &&
                              validation.errors.first("email")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>
                            Group: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            name="group"
                            value={this.state.formData.group}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="">Select Group</option>
                            <option value="educator">Educator</option>
                            <option value="doctor">Doctor</option>
                            <option value="receptionist">Receptionist</option>
                          </select>
                          <span className="errorMsg">
                            {this.state.groupError}
                            {this.state.formArr.group &&
                              validation.errors.first("group")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Clinic:<span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            disabled={this.state.dropdownDisable}
                            name="clinic_name"
                            value={this.state.clinic}
                            onChange={(e) => {
                              this.handleClinic(e);
                            }}
                          >
                            <option value="">Select Clinic</option>
                            {this.state.clinicList.map(function (item) {
                              return (
                                <option
                                  key={item._id}
                                  name={item.name}
                                  value={item._id}
                                >
                                  {item.name}({item.clinicId})
                                </option>
                              );
                            })}
                          </select>
                          <span className="errorMsg">
                            {this.state.clinicError}
                            {this.state.formArr.clinic_name &&
                              validation.errors.first("clinic_name")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Status: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.formData.status}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <span className="errorMsg">
                            {this.state.statusError}
                            {this.state.formArr.status &&
                              validation.errors.first("status")}
                          </span>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />

                <Card
                  title={<legend>Personal Information</legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <ControlLabel>Profile Photo:</ControlLabel>

                          {this.state.onPageLoad === false &&
                            this.state.imagePreviewUrl && (
                              <ImageComponent
                                style={{ width: "140px", height: "140px" }}
                                data={this.props.location.state.row.image}
                                alt="Logo"
                              />
                            )}

                          {this.state.onPageLoad === true &&
                            this.state.imagePreviewUrl && (
                              <img
                                src={this.state.imagePreviewUrl}
                                width="100px"
                                height="100px"
                                style={{ margin: "5px" }}
                              />
                            )}

                          <FormControl
                            type="file"
                            name="file"
                            onChange={(e) => {
                              this.fileChangedHandler(e);
                            }}
                          />

                          {/*<input type="file" name="file" onChange={e => { this.fileChangedHandler(e) }}></input> */}
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Title: <span className="star">*</span>
                          </ControlLabel>
                          <select
                            className="form-control"
                            name="title"
                            value={this.state.formData.title}
                            onChange={(event) => this.handleChange(event)}
                          >
                            <option value="">Select Title</option>
                            {titleOptions.map(function (item) {
                              let selected = false;
                              if (formData.title === item.label) {
                                selected = true;
                              }
                              return (
                                <option
                                  key={item.value}
                                  value={item.label}
                                  selected={selected}
                                >
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <span className="errorMsg">
                            {this.state.titleError}
                            {this.state.formArr.title &&
                              validation.errors.first("title")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            First Name: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="first_name"
                            autoComplete="off"
                            value={this.state.formData.first_name}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <small>
                            Note:-Do not write any title in the first name,
                            please select it from the title drop down.
                          </small>
                          <span className="errorMsg">
                            {this.state.first_nameError}
                            {this.state.formArr.first_name &&
                              validation.errors.first("first_name")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Last Name: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="last_name"
                            autoComplete="off"
                            value={this.state.formData.last_name}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.last_nameError}
                            {this.state.formArr.last_name &&
                              validation.errors.first("last_name")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}></Col>
                        <Col sm={3}>
                          <ControlLabel>
                            Phone: <span className="star">*</span>
                          </ControlLabel>
                          <FormControl
                            type="text"
                            name="phone"
                            autoComplete="off"
                            maxLength={12}
                            value={this.state.formData.phone}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.phoneError}
                            {this.state.formArr.phone &&
                              validation.errors.first("phone")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <ControlLabel>Specialization:</ControlLabel>
                          <FormControl
                            type="text"
                            name="specializations_add"
                            autoComplete="off"
                            value={this.state.formData.specializations_add}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.formArr.specializations_add &&
                              validation.errors.first("specializations_add")}
                          </span>
                        </Col>
                        {/*<Col sm={3}>
                                                    <label className="control-label">specializations</label>
                                                    <Select
                                                        placeholder="Select specializations"
                                                        name="specializations"
                                                        id="specializations"
                                                        closeOnSelect={false}
                                                        multi={true}
                                                        value={this.state.specializations}
                                                        options={this.state.specializationList}
                                                        onChange={(value) => { this.setState({ specializations: value }); this.handleSpecialization(value) }}
                                                    />
                                                </Col>*/}
                        <Col sm={3}>
                          <ControlLabel>Degree:</ControlLabel>
                          <FormControl
                            type="text"
                            name="degree"
                            autoComplete="off"
                            value={this.state.formData.degree}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  }
                  ftTextCenter
                  legend={
                    <div>
                      <Button
                        fill
                        bsStyle="info"
                        type="button"
                        onClick={this.editUserHandler.bind(this)}
                        disabled={
                          passwordError || confirm_passwordError || phoneError
                        }
                      >
                        Submit
                      </Button>
                      &nbsp;
                      {/*<Button fill bsStyle="info" type="button" onClick={() => this.props.history.replace('clinic-list')}>Back to List</Button> */}
                    </div>
                  }
                />
              </Form>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    updateUserMsg: state.user.updateUserMsg,
    isUpdateUser: state.user.isUpdateUser,
    UpdateUserData: state.user.UpdateUserData,
    isUpdateUserError: state.user.isUpdateUserError,

    ClinicList: state.clinic.ClinicList,
    isClinicList: state.clinic.isClinicList,
    isClinicListError: state.clinic.isClinicListError,

    SpecializationList: state.specilization.SpecializationList,
    isSpecializationList: state.specilization.isSpecializationList,
    isSpecializationListError: state.specilization.isSpecializationListError,

    isUploadFile: state.user.isUploadFile,
    isUploadFileError: state.user.isUploadFileError,
    uploadFile: state.user.uploadFile,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    updateUserAction,
    clinicListAction,
    specializationListAction,
    uploadFileAction,
  })(UpdateUser)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

var patternOptions = [
  { value: '1', label: 'Pattern 1' },
  { value: '2', label: 'Pattern 2' },
  { value: '3', label: 'Pattern 3' },
  { value: '4', label: 'Pattern 4' },
  { value: '5', label: 'Pattern 5' },
  { value: '6', label: 'Pattern 6' },
  { value: '7', label: 'Pattern 7' },
  { value: '8', label: 'Pattern 8' }
];

var appTimeSlot = [
  { value: '6:00 AM', label: '06:00 AM' },
  { value: '6:30 AM', label: '06:30 AM' },
  { value: '7:00 AM', label: '07:00 AM' },
  { value: '7:30 AM', label: '07:30 AM' },
  { value: '8:00 AM', label: '08:00 AM' },
  { value: '8:30 AM', label: '08:30 AM' },
  { value: '9:00 AM', label: '09:00 AM' },
  { value: '9:30 AM', label: '09:30 AM' },
  { value: '10:00 AM', label: '10:00 AM' },
  { value: '10:30 AM', label: '10:30 AM' },
  { value: '11:00 AM', label: '11:00 AM' },
  { value: '11:30 AM', label: '11:30 AM' },
  { value: '12:00 PM', label: '12:00 PM' },
  { value: '12:30 PM', label: '12:30 PM' },
  { value: '1:00 PM', label: '01:00 PM' },
  { value: '1:30 PM', label: '01:30 PM' },
  { value: '2:00 PM', label: '02:00 PM' },
  { value: '2:30 PM', label: '02:30 PM' },
  { value: '3:00 PM', label: '03:00 PM' },
  { value: '3:30 PM', label: '03:30 PM' },
  { value: '4:00 PM', label: '04:00 PM' },
  { value: '4:30 PM', label: '04:30 PM' },
  { value: '5:00 PM', label: '05:00 PM' },
  { value: '5:30 PM', label: '05:30 PM' },
  { value: '6:00 PM', label: '06:00 PM' },
  { value: '6:30 PM', label: '06:30 PM' },
  { value: '7:00 PM', label: '07:00 PM' },
  { value: '7:30 PM', label: '07:30 PM' },
  { value: '8:00 PM', label: '08:00 PM' },
  { value: '8:30 PM', label: '08:30 PM' },
  { value: '9:00 PM', label: '09:00 PM' },
  { value: '9:30 PM', label: '09:30 PM' },
  { value: '10:00 PM', label: '10:00 PM' },
  { value: '10:30 PM', label: '10:30 PM' },
  { value: '11:00 PM', label: '11:00 PM' },
  { value: '11:30 PM', label: '11:30 PM' },
  { value: '12:00 AM', label: '12:00 AM' }

];


var patternFontOptions = [
  { value: 'medium', label: 'medium' },
  { value: 'xx-small', label: 'xx-small' },
  { value: 'x-small', label: 'x-small' },
  { value: 'small', label: 'small' },
  { value: 'large', label: 'large' },
  { value: 'x-large', label: 'x-large' },
  { value: 'xx-large', label: 'xx-large' },
  { value: 'smaller', label: 'smaller' },
  { value: 'larger', label: 'larger' },
  { value: 'initial', label: 'initial' },
  { value: 'inherit', label: 'inherit' }
];

var weekOptions = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '0', label: 'Sunday' }

];

//
// //
// // // For Add User form group select
// //
//

var knowledgeCategoryList = [
  { value: '1', label: 'General' },
  { value: '2', label: 'Insulin Training' },
  { value: '3', label: 'Pregnancy Diabetes' },
  { value: '4', label: 'T1' },
  { value: '5', label: 'T2' }
];

var selectSpecializations = [
  { value: '1', label: 'Diagnostic' },
  { value: '2', label: 'Diabetes' },
  { value: '3', label: 'Thyroid' }
];

//
// //
// // // For ExtendedForms view Select
// //
//

var selectOptions = [
  { value: 'id', label: 'Bahasa Indonesia' },
  { value: 'ms', label: 'Bahasa Melayu' },
  { value: 'ca', label: 'Català' },
  { value: 'da', label: 'Dansk' },
  { value: 'de', label: 'Deutsch' },
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
  { value: 'el', label: 'Eλληνικά' },
  { value: 'fr', label: 'Français' },
  { value: 'it', label: 'Italiano' },
  { value: 'hu', label: 'Magyar' },
  { value: 'nl', label: 'Nederlands' },
  { value: 'no', label: 'Norsk' },
  { value: 'pl', label: 'Polski' },
  { value: 'pt', label: 'Português' },
  { value: 'fi', label: 'Suomi' },
  { value: 'sv', label: 'Svenska' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'is', label: 'Íslenska' },
  { value: 'cs', label: 'Čeština' },
  { value: 'ru', label: 'Русский' },
  { value: 'th', label: 'ภาษาไทย' },
  { value: 'zh', label: '中文 (简体)' },
  { value: 'zh-TW', label: '中文 (繁體)' },
  { value: 'ja', label: '日本語' },
  { value: 'ko', label: '한국어' }
];
var selectAddSpecilization = [
  { value: '1', label: 'Enable' },
  { value: '0', label: 'Disable' }
];
//
// //
// // // For Calendar view events
// //
//
var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();
var d = today.getDate();

const events = [
  {
    'title': 'All Day Event',
    'allDay': true,
    'start': new Date(y, m, 0),
    'end': new Date(y, m, 1)
  },
  {
    'title': 'Long Event',
    'start': new Date(y, m, 7),
    'end': new Date(y, m, 10)
  },

  {
    'title': 'DTS STARTS',
    'start': new Date(y, m, 13),
    'end': new Date(y, m, 20)
  },

  {
    'title': 'Some Event',
    'start': new Date(y, m, 9),
    'end': new Date(y, m, 9)
  },
  {
    'title': 'Conference',
    'start': new Date(y, m, 11),
    'end': new Date(y, m, 13),
    desc: 'Big conference for important people'
  },
  {
    'title': 'Meeting',
    'start': new Date(y, m + 1, d, 7, 0, 0),
    'end': new Date(y, m + 1, d, 10, 30, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting'
  }
];

//
// //
// // // For vector map row in Dashboard view
// //
//

const us_flag = require("../assets/img/flags/US.png");
const de_flag = require("../assets/img/flags/DE.png");
const au_flag = require("../assets/img/flags/AU.png");
const gb_flag = require("../assets/img/flags/GB.png");
const ro_flag = require("../assets/img/flags/RO.png");
const br_flag = require("../assets/img/flags/BR.png");

const table_data = [
  { "flag": us_flag, "country": "USA", "count": "2.920", "percentage": "53.23%" },
  { "flag": de_flag, "country": "Germany", "count": "1.300", "percentage": "20.43%" },
  { "flag": au_flag, "country": "Australia", "count": "760", "percentage": "10.35%" },
  { "flag": gb_flag, "country": "United Kingdom", "count": "690", "percentage": "7.87%" },
  { "flag": ro_flag, "country": "Romania", "count": "600", "percentage": "5.94%" },
  { "flag": br_flag, "country": "Brasil", "count": "550", "percentage": "4.34%" }
];


//
// //
// // // For notifications
// //
//
var defaultWidth = window.screen.width > 768 ? window.screen.width * 1 / 3 : window.screen.width;

var style = {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      position: 'fixed',
      width: defaultWidth,
      padding: '10px 10px 10px 20px',
      zIndex: 9998,
      WebkitBoxSizing: '',
      MozBoxSizing: '',
      boxSizing: '',
      height: 'auto',
      display: 'inline-block',
      border: '0',
      fontSize: '14px',
      WebkitFontSmoothing: "antialiased",
      fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',
      fontWeight: '400',
      color: '#FFFFFF'

    },

    tl: {
      top: '0px',
      bottom: 'auto',
      left: '0px',
      right: 'auto'
    },

    tr: {
      top: '0px',
      bottom: 'auto',
      left: 'auto',
      right: '0px'
    },

    tc: {
      top: '0px',
      bottom: 'auto',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2)
    },

    bl: {
      top: 'auto',
      bottom: '0px',
      left: '0px',
      right: 'auto'
    },

    br: {
      top: 'auto',
      bottom: '0px',
      left: 'auto',
      right: '0px'
    },

    bc: {
      top: 'auto',
      bottom: '0px',
      margin: '0 auto',
      left: '50%',
      marginLeft: -(defaultWidth / 2)
    }

  },

  NotificationItem: {
    DefaultStyle: {
      position: 'relative',
      width: '100%',
      cursor: 'pointer',
      borderRadius: '4px',
      fontSize: '14px',
      margin: '10px 0 0',
      padding: '10px',
      display: 'block',
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      opacity: 0,
      transition: 'all 0.5s ease-in-out',
      WebkitTransform: 'translate3d(0, 0, 0)',
      transform: 'translate3d(0, 0, 0)',
      willChange: 'transform, opacity',

      isHidden: {
        opacity: 0
      },

      isVisible: {
        opacity: 1
      }
    },

    success: {
      borderTop: 0,
      backgroundColor: '#a1e82c',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    error: {
      borderTop: 0,
      backgroundColor: '#fc727a',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    warning: {
      borderTop: 0,
      backgroundColor: '#ffbc67',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    info: {
      borderTop: 0,
      backgroundColor: '#63d8f1',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    }
  },

  Title: {
    DefaultStyle: {
      fontSize: '30px',
      margin: '0',
      padding: 0,
      fontWeight: 'bold',
      color: '#FFFFFF',
      display: 'block',
      left: '15px',
      position: 'absolute',
      top: '50%',
      marginTop: '-15px'
    }

  },

  MessageWrapper: {
    DefaultStyle: {
      marginLeft: '55px',
      marginRight: '30px',
      padding: '0 12px 0 0',
      color: '#FFFFFF',
      maxWidthwidth: '89%'
    }
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: 'inherit',
      fontSize: '21px',
      color: '#000',
      float: 'right',
      position: 'absolute',
      right: '10px',
      top: '50%',
      marginTop: '-13px',
      backgroundColor: '#FFFFFF',
      display: 'block',
      borderRadius: '50%',
      opacity: '.4',
      lineHeight: '11px',
      width: '25px',
      height: '25px',
      outline: '0 !important',
      textAlign: 'center',
      padding: '6px 3px 3px 3px',
      fontWeight: '300',
      marginLeft: '65px'
    },

    success: {
      // color: '#f0f5ea',
      // backgroundColor: '#a1e82c'
    },

    error: {
      // color: '#f4e9e9',
      // backgroundColor: '#fc727a'
    },

    warning: {
      // color: '#f9f6f0',
      // backgroundColor: '#ffbc67'
    },

    info: {
      // color: '#e8f0f4',
      // backgroundColor: '#63d8f1'
    }
  },

  Action: {
    DefaultStyle: {
      background: '#ffffff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0
    },

    success: {
      backgroundColor: '#a1e82c',
      color: '#ffffff'
    },

    error: {
      backgroundColor: '#fc727a',
      color: '#ffffff'
    },

    warning: {
      backgroundColor: '#ffbc67',
      color: '#ffffff'
    },

    info: {
      backgroundColor: '#63d8f1',
      color: '#ffffff'
    }
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0
    }
  }
}

//
// //
// // // For tables
// //
//
const thArray = ["ID", "Name", "Salary", "Country", "City"];
const tdArray = [
  ["Dakota Rice", "$36,738", "Niger", "Oud-Turnhout"],
  ["Minerva Hooper", "$23,789", "Curaçao", "Sinaai-Waas"],
  ["Sage Rodriguez", "$56,142", "Netherlands", "Baileux"],
  ["Philip Chaney", "$38,735", "Korea, South", "Overland Park"],
  ["Doris Greene", "$63,542", "Malawi", "Feldkirchen in Kärnten"],
  ["Mason Porter", "$78,615", "Chile", "Gloucester"],
  ["Mike Chaney", "$38,735", "Romania", "Bucharest"]
];


//
// //
// // // For icons
// //
//
const iconsArray = [
  "pe-7s-album",
  "pe-7s-arc",
  "pe-7s-back-2",
  "pe-7s-bandaid",
  "pe-7s-car",
  "pe-7s-diamond",
  "pe-7s-door-lock",
  "pe-7s-eyedropper",
  "pe-7s-female",
  "pe-7s-gym",
  "pe-7s-hammer",
  "pe-7s-headphones",
  "pe-7s-helm",
  "pe-7s-hourglass",
  "pe-7s-leaf",
  "pe-7s-magic-wand",
  "pe-7s-male",
  "pe-7s-map-2",
  "pe-7s-next-2",
  "pe-7s-paint-bucket",
  "pe-7s-pendrive",
  "pe-7s-photo",
  "pe-7s-piggy",
  "pe-7s-plugin",
  "pe-7s-refresh-2",
  "pe-7s-rocket",
  "pe-7s-settings",
  "pe-7s-shield",
  "pe-7s-smile",
  "pe-7s-usb",
  "pe-7s-vector",
  "pe-7s-wine",
  "pe-7s-cloud-upload",
  "pe-7s-cash",
  "pe-7s-close",
  "pe-7s-bluetooth",
  "pe-7s-cloud-download",
  "pe-7s-way",
  "pe-7s-close-circle",
  "pe-7s-id",
  "pe-7s-angle-up",
  "pe-7s-wristwatch",
  "pe-7s-angle-up-circle",
  "pe-7s-world",
  "pe-7s-angle-right",
  "pe-7s-volume",
  "pe-7s-angle-right-circle",
  "pe-7s-users",
  "pe-7s-angle-left",
  "pe-7s-user-female",
  "pe-7s-angle-left-circle",
  "pe-7s-up-arrow",
  "pe-7s-angle-down",
  "pe-7s-switch",
  "pe-7s-angle-down-circle",
  "pe-7s-scissors",
  "pe-7s-wallet",
  "pe-7s-safe",
  "pe-7s-volume2",
  "pe-7s-volume1",
  "pe-7s-voicemail",
  "pe-7s-video",
  "pe-7s-user",
  "pe-7s-upload",
  "pe-7s-unlock",
  "pe-7s-umbrella",
  "pe-7s-trash",
  "pe-7s-tools",
  "pe-7s-timer",
  "pe-7s-ticket",
  "pe-7s-target",
  "pe-7s-sun",
  "pe-7s-study",
  "pe-7s-stopwatch",
  "pe-7s-star",
  "pe-7s-speaker",
  "pe-7s-signal",
  "pe-7s-shuffle",
  "pe-7s-shopbag",
  "pe-7s-share",
  "pe-7s-server",
  "pe-7s-search",
  "pe-7s-film",
  "pe-7s-science",
  "pe-7s-disk",
  "pe-7s-ribbon",
  "pe-7s-repeat",
  "pe-7s-refresh",
  "pe-7s-add-user",
  "pe-7s-refresh-cloud",
  "pe-7s-paperclip",
  "pe-7s-radio",
  "pe-7s-note2",
  "pe-7s-print",
  "pe-7s-network",
  "pe-7s-prev",
  "pe-7s-mute",
  "pe-7s-power",
  "pe-7s-medal",
  "pe-7s-portfolio",
  "pe-7s-like2",
  "pe-7s-plus",
  "pe-7s-left-arrow",
  "pe-7s-play",
  "pe-7s-key",
  "pe-7s-plane",
  "pe-7s-joy",
  "pe-7s-photo-gallery",
  "pe-7s-pin",
  "pe-7s-phone",
  "pe-7s-plug",
  "pe-7s-pen",
  "pe-7s-right-arrow",
  "pe-7s-paper-plane",
  "pe-7s-delete-user",
  "pe-7s-paint",
  "pe-7s-bottom-arrow",
  "pe-7s-notebook",
  "pe-7s-note",
  "pe-7s-next",
  "pe-7s-news-paper",
  "pe-7s-musiclist",
  "pe-7s-music",
  "pe-7s-mouse",
  "pe-7s-more",
  "pe-7s-moon",
  "pe-7s-monitor",
  "pe-7s-micro",
  "pe-7s-menu",
  "pe-7s-map",
  "pe-7s-map-marker",
  "pe-7s-mail",
  "pe-7s-mail-open",
  "pe-7s-mail-open-file",
  "pe-7s-magnet",
  "pe-7s-loop",
  "pe-7s-look",
  "pe-7s-lock",
  "pe-7s-lintern",
  "pe-7s-link",
  "pe-7s-like",
  "pe-7s-light",
  "pe-7s-less",
  "pe-7s-keypad",
  "pe-7s-junk",
  "pe-7s-info",
  "pe-7s-home",
  "pe-7s-help2",
  "pe-7s-help1",
  "pe-7s-graph3",
  "pe-7s-graph2",
  "pe-7s-graph1",
  "pe-7s-graph",
  "pe-7s-global",
  "pe-7s-gleam",
  "pe-7s-glasses",
  "pe-7s-gift",
  "pe-7s-folder",
  "pe-7s-flag",
  "pe-7s-filter",
  "pe-7s-file",
  "pe-7s-expand1",
  "pe-7s-exapnd2",
  "pe-7s-edit",
  "pe-7s-drop",
  "pe-7s-drawer",
  "pe-7s-download",
  "pe-7s-display2",
  "pe-7s-display1",
  "pe-7s-diskette",
  "pe-7s-date",
  "pe-7s-cup",
  "pe-7s-culture",
  "pe-7s-crop",
  "pe-7s-credit",
  "pe-7s-copy-file",
  "pe-7s-config",
  "pe-7s-compass",
  "pe-7s-comment",
  "pe-7s-coffee",
  "pe-7s-cloud",
  "pe-7s-clock",
  "pe-7s-check",
  "pe-7s-chat",
  "pe-7s-cart",
  "pe-7s-camera",
  "pe-7s-call",
  "pe-7s-calculator",
  "pe-7s-browser",
  "pe-7s-box2",
  "pe-7s-box1",
  "pe-7s-bookmarks",
  "pe-7s-bicycle",
  "pe-7s-bell",
  "pe-7s-battery",
  "pe-7s-ball",
  "pe-7s-back",
  "pe-7s-attention",
  "pe-7s-anchor",
  "pe-7s-albums",
  "pe-7s-alarm",
  "pe-7s-airplay"
];

//
// //
// // // // For dashboard's charts
// //
//
// Data for Pie Chart
var dataPie = {
  labels: ['40%', '20%', '40%'],
  series: [40, 20, 40]
};

// Data for Line Chart
var dataSales = {
  labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
  series: [
    [287, 385, 490, 492, 554, 586, 698, 695],
    [67, 152, 143, 240, 287, 335, 435, 437],
    [23, 113, 67, 108, 190, 239, 307, 308]
  ]
};
var optionsSales = {
  low: 0,
  high: 800,
  showArea: false,
  height: "245px",
  axisX: {
    showGrid: false,
  },
  lineSmooth: true,
  showLine: true,
  showPoint: true,
  fullWidth: true,
  chartPadding: {
    right: 50
  }
};
var responsiveSales = [
  ['screen and (max-width: 640px)', {
    axisX: {
      labelInterpolationFnc: function (value) {
        return value[0];
      }
    }
  }]
];

// Data for Bar Chart
var dataBar = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  series: [
    [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
    [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
  ]
};
var optionsBar = {
  seriesBarDistance: 10,
  axisX: {
    showGrid: false
  },
  height: "245px"
};
var responsiveBar = [
  ['screen and (max-width: 640px)', {
    seriesBarDistance: 5,
    axisX: {
      labelInterpolationFnc: function (value) {
        return value[0];
      }
    }
  }]
];

var languageOptions = [
  { value: '1', label: 'English' },
  { value: '2', label: 'Hindi' },
  { value: '3', label: 'Marathi' },
  { value: '4', label: 'Gujrati' },
  { value: '5', label: 'Malyalam' },
  { value: '6', label: 'Tamil' },
  { value: '7', label: 'Kannada' },
  { value: '8', label: 'Telgu' },
  { value: '9', label: 'Bangla' },
  { value: '10', label: 'Punjabi' },
];

var featureWiseReportOptions = [
  { value: 'patient', label: 'Patients' },
  { value: 'app', label: 'App' },
  { value: 'emr', label: 'EMR' },
  { value: 'dietChart', label: 'Diet Chart' },
  { value: 'glucoseDiary', label: 'Glucose Diary' },
  { value: 'report', label: 'Report' },
  { value: 'insulinCalculator', label: 'Insulin Calculator' },
  // { value: 'specialization', label: 'Specialization' },
];

module.exports = {
  patternOptions,
  appTimeSlot,
  weekOptions,
  patternFontOptions,
  selectOptions, // For selets in ExtendedForms view
  selectSpecializations, // For select Specialization in AddUser view
  selectAddSpecilization, // For selets in ExtendedForms view
  knowledgeCategoryList, // for knowledge
  events, // For calendar in Calendar view
  table_data, // For vector map on Dashboard view
  style, // For notifications (App container and Notifications view)
  thArray, tdArray, // For tables (TableList view)
  iconsArray, // For icons (Icons view)
  dataPie, dataSales, optionsSales, responsiveSales, dataBar, optionsBar, responsiveBar, languageOptions, featureWiseReportOptions // For charts (Dashboard view)
};

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';
import * as API from '../../../../api/emr'
import Loading from "react-loading";
import Select from 'react-select';

import { selectDrugTypeOptions } from '../../../../../Front/variables/Variables'

const AddEditDrug = (props) => {
    let history = useHistory();
    let previousName = "";
    const [formData, setFormData] = useState({ "insulinCalculator": false, valueTypeOfDoseSelector: [] });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)
    const [mode, setMode] = useState("add");
    const [drugId, setDrugId] = useState("")
    const [drugAlreadyExist, setDrugAlreadyExist] = useState(false)
    const [drugAlreadyExistMsg, setDrugAlreadyExistMsg] = useState(false)
    const [drugCompanyList, setDrugCompanyList] = useState([])
    const [drugCategoryList, setDrugCategoryList] = useState([])
    const [drugInstrutionList, setDruginstructionList] = useState([])
    const [drugInstrutionListOptions, setDruginstructionListOptions] = useState([])
    const [drugCompanyListOption, setDrugCompanyListOption] = useState([])
    const [btnClicked, setBtnClicked] = useState(false);
    const inputHandler = (e, field) => {
        if (field === "drugName" && drugAlreadyExist) {
            setDrugAlreadyExist(false)
            setDrugAlreadyExistMsg("")
            setBtnClicked(false);
        }
        let saveFormData = formData;

        if (field === "instructionName") {
            let tempObj = {}
            tempObj = drugInstrutionList.find(x => x.value === e.value)
            saveFormData["instructionInDiffLanguage"] = JSON.stringify(tempObj.instructionInDiffLanguage)
            saveFormData["instructionName"] = e.value

        }

        if (field === "insulinCalculator") {
            saveFormData[e.target.name] = e.target.checked;
        }

        if (field === "drugCategoryId") {

            let tempObj = {}
            tempObj = drugCategoryList.find(x => x.value === e.value)
            saveFormData["drugCategoryName"] = tempObj.label.trimStart()
            saveFormData[field] = e.value
        }

        if (field === "drugCompanyId") {
            let tempObj = {}
            tempObj = drugCompanyList.find(x => x.value === e.value)
            saveFormData["companyName"] = tempObj.label.trimStart()
            saveFormData[field] = e.value

        }

        if (field === "drugName") {
            let str = e.target.value.replace(/\s+/g, " ");
            saveFormData[e.target.name] = str.trimStart()
        }

        if (field === "drugNameWithIngredients") {
            let str = e.target.value.replace(/\s+/g, " ");
            saveFormData[e.target.name] = str.trimStart()
        }

        if (field === "unit") {
            let str = e.target.value.replace(/\s+/g, " ");
            saveFormData[e.target.name] = str.trimStart()
        }

        if (field === "noOfDoseSelector") {
            let str = e.target.value.replace(/\s+/g, " ");
            saveFormData[e.target.name] = str.trimStart()
        }

        if (field === "drugType") {
            saveFormData["drugType"] = e.value.trimStart()
        }


        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.drugNameWithIngredients && post.drugNameWithIngredients != '') {
            errArr.drugNameWithIngredients = '';
        } else { errArr.drugNameWithIngredients = 'error'; errflag = false; }

        if (post.drugName && post.drugName != '') {
            errArr.drugName = '';
        } else { errArr.drugName = 'error'; errflag = false; }

        if (post.drugCategoryId && post.drugCategoryId != '') {
            errArr.drugCategoryId = '';
        } else { errArr.drugCategoryId = 'error'; errflag = false; }

        if (post.drugCompanyId && post.drugCompanyId != '') {
            errArr.drugCompanyId = '';
        } else { errArr.drugCompanyId = 'error'; errflag = false; }

        if (post.drugType && post.drugType != '') {
            errArr.drugType = '';
        } else { errArr.drugType = 'error'; errflag = false; }

        if (post.noOfDoseSelector && post.noOfDoseSelector != '') {
            errArr.noOfDoseSelector = '';
        } else { errArr.noOfDoseSelector = 'error'; errflag = false; }

        if (post.instructionName && post.instructionName != '') {
            errArr.instructionName = '';
        } else { errArr.instructionName = 'error'; errflag = false; }

        setErrors({ ...errArr });
        return errflag;
    }

    const submitHandler = async (event) => {
        event.preventDefault();

        if (validate()) {
            setBtnClicked(true);
            let sentData = new FormData();
            sentData.append('drugName', formData.drugName)
            sentData.append('drugNameWithIngredients', formData.drugNameWithIngredients)
            sentData.append('drugCategoryId', formData.drugCategoryId)
            sentData.append('drugCompanyId', formData.drugCompanyId)
            sentData.append('drugType', formData.drugType)
            sentData.append('noOfDoseSelector', formData.noOfDoseSelector)
            let tempArr = []
            sentData.append('valueTypeOfDoseSelector', formData.valueTypeOfDoseSelector)
            sentData.append('insulinCalculator', formData.insulinCalculator)
            sentData.append('unit', formData.unit)
            sentData.append('instructionName', formData.instructionName)
            sentData.append('drugCategoryName', formData.drugCategoryName)
            sentData.append('companyName', formData.companyName)
            sentData.append('instructionInDiffLanguage', formData.instructionInDiffLanguage)
            let resp = ""
            if (mode === "add") {
                resp = await API.addDrug(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    setBtnClicked(false);
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setDrugAlreadyExist(true)
                        setDrugAlreadyExistMsg(`${resp.data.msg} ${resp.data.drugCategoryName}`)
                        setBtnClicked(false)
                    }
                    else history.push({ pathname: "/admin/drug-list", from: "add" })

                }
            } else {
                sentData.append('drugId', drugId)
                sentData.append('previousName', formData.previousName)
                sentData.append('flag', formData.flag)
                resp = await API.updateDrug(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setDrugAlreadyExist(true)
                        setDrugAlreadyExistMsg(`${resp.data.msg} ${resp.data.drugCategoryName}`)
                        setBtnClicked(false)
                    } else history.push({ pathname: "/admin/drug-list", from: "edit" })
                }
            }
            setIsLoading(false);
        }
    }

    const getDrugCompanyList = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.drugCompanyListWithoutPagination();
        if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
            setDrugCompanyList(resp.data.drugCompanyData.data.map((obj, key) => {
                return { value: obj._id, label: obj.companyName };
            }));

        }

    }

    const getDrugCategoryList = async () => {
        let resp = await API.drugCategoryListWithoutPagination();
        if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {
            setDrugCategoryList(resp.data.drugCategoryData.data.map((obj, key) => {
                return { value: obj._id, label: obj.name };
            }));
        }

    }


    const getDrugInstrcutionList = async (row) => {
        let resp = await API.masterInstructionList();
        if (resp?.data?.status === "Success" && resp?.data?.statusCode == 200) {

            setDruginstructionList(resp.data.instructionData.data.map((obj, key) => {
                return { value: obj._id, label: obj.instructionName, instructionInDiffLanguage: obj.instructionInDiffLanguage };
            }));
            if (row !== undefined && row) {
                let saveFormData = formData;
                setMode("edit");
                setDrugId(row._id);
                saveFormData["drugNameWithIngredients"] = row.drugNameWithIngredients;
                previousName = row.drugNameWithDose;
                saveFormData["drugName"] = row.drugNameWithDose;
                saveFormData["previousName"] = row.drugNameWithDose
                saveFormData["drugCompanyId"] = row.companyId;
                saveFormData["drugCategoryId"] = row.drugCategoryId;
                saveFormData["drugType"] = row.drugType;
                saveFormData["insulinCalculator"] = row.insulinCalculator;
                saveFormData["noOfDoseSelector"] = row.noOfDoseSelector;
                saveFormData["valueTypeOfDoseSelector"] = row.valueTypeOfDoseSelector;
                saveFormData["companyName"] = row.companyName;
                saveFormData["drugCategoryName"] = row.drugCategoryName;

                saveFormData["flag"] = row.type;
                saveFormData["unit"] = row.unit;
                if (row.instructionData && row.instructionData.length > 0) {
                    if (row.instructionData[0].instructionName && row.instructionData[0].instructionName.length > 0) {
                        if (row.instructionData[0].instructionName[0].instructionType === "mandatory") {
                            let tempObj = resp.data.instructionData.data.find(x => x.instructionName === row.instructionData[0].instructionName[0].instructionName)
                            if (tempObj) {
                                saveFormData["instructionName"] = tempObj._id;
                                saveFormData["instructionInDiffLanguage"] = JSON.stringify(row.instructionData[0].instructionInDiffLanguage);
                            } else {
                                saveFormData["instructionName"] = ""
                                saveFormData["instructionInDiffLanguage"] = []
                            }
                        } else {
                            saveFormData["instructionName"] = ""
                            saveFormData["instructionInDiffLanguage"] = []
                        }

                    }
                }
                setFormData({ ...formData, ...saveFormData });
            }
            setIsLoading(false)

        }

    }


    useEffect(() => {
        let row = props.location.state?.row;
        getDrugCompanyList()
        getDrugCategoryList()
        getDrugInstrcutionList(row)
    }, [])
    return (
        <div className="main-content">

            <Grid fluid>
                <Row>
                    {isLoading &&
                        <Loading className='spin-loader' type='spin' color='#000000' style={{ margin: '0px auto' }} />
                    }
                    <Col md={12}>
                        <Card
                            title={<legend className="line-removes">
                                <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { history.goBack() }}>Back</Button>
                            </legend>}
                            content={
                                <Form className="add-supportsystem_form">
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Drug<span className="star">*</span></ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="drugName"
                                                    id="drugName"
                                                    placeholder="Drug"
                                                    onChange={e => { inputHandler(e, "drugName"); }}
                                                    autocomplete="off"
                                                    value={formData.drugName ? formData.drugName : ''}
                                                />
                                                {
                                                    errors.drugName &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }
                                                {drugAlreadyExist &&
                                                    <span className="validation_error">{drugAlreadyExistMsg}</span>
                                                }

                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Ingredients With Dose<span className="star">*</span></ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="drugNameWithIngredients"
                                                    id="drugNameWithIngredients"
                                                    placeholder="Ingredients"
                                                    onChange={e => { inputHandler(e, "drugNameWithIngredients"); }}
                                                    autocomplete="off"
                                                    value={formData.drugNameWithIngredients ? formData.drugNameWithIngredients : ''}
                                                />
                                                {
                                                    errors.drugNameWithIngredients &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                {/* <Col componentClass={ControlLabel} >
                                                Company <span className="star">*</span>
                                            </Col> */}
                                                <ControlLabel>Company<span className="star">*</span></ControlLabel>
                                                {/* <select
                                                className={'insulin-input form-control ' + (errors.drugCompanyId ? errors.drugCompanyId : '')}
                                                name="drugCompanyId"
                                                id="drugCompanyId"
                                                closeOnSelect={false}
                                                multi={true}
                                                value={formData.drugCompanyId}
                                                onChange={(e) => {
                                                    inputHandler(e);
                                                }}
                                            >
                                                <option value="">Select Company</option>
                                                {
                                                    drugCompanyList.map(function (item) {
                                                        return <option value={item._id}>{item.companyName}</option>
                                                    })
                                                }
                                            </select> */}
                                                <Select
                                                    placeholder="Select Company"
                                                    name="drugCompanyId"
                                                    id="drugCompanyId"
                                                    closeOnSelect={true}
                                                    multi={false}
                                                    value={formData.drugCompanyId}
                                                    options={drugCompanyList}
                                                    onChange={(e) => {
                                                        inputHandler(e, "drugCompanyId");
                                                    }}
                                                />
                                                {
                                                    errors.drugCompanyId &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }

                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Category<span className="star">*</span></ControlLabel>
                                                {/* <select
                                                    className={'insulin-input form-control ' + (errors.drugCategoryId ? errors.drugCategoryId : '')}
                                                    name="drugCategoryId"
                                                    id="drugCategoryId"
                                                    closeOnSelect={false}
                                                    multi={true}
                                                    value={formData.drugCategoryId}
                                                    onChange={(e) => {
                                                        inputHandler(e);
                                                    }}
                                                >
                                                    <option value="">Select Category</option>
                                                    {
                                                        drugCategoryList.map(function (item) {
                                                            return <option value={item._id}>{item.name}</option>
                                                        })
                                                    }
                                                </select> */}
                                                <Select
                                                    placeholder="Select Catgeory"
                                                    name="drugCategoryId"
                                                    id="drugCategoryId"
                                                    closeOnSelect={true}
                                                    multi={false}
                                                    value={formData.drugCategoryId}
                                                    options={drugCategoryList}
                                                    onChange={(e) => {
                                                        inputHandler(e, "drugCategoryId");
                                                    }}
                                                />
                                                {errors.drugCategoryId &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }

                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Type<span className="star">*</span></ControlLabel>
                                                {/* <select
                                                    className={'insulin-input form-control ' + (errors.drugType ? errors.drugType : '')}
                                                    name="drugType"
                                                    id="drugType"
                                                    closeOnSelect={false}
                                                    multi={true}
                                                    value={formData.drugType}
                                                    onChange={(e) => {
                                                        inputHandler(e);
                                                    }}
                                                >
                                                    <option value="">Select Type</option>
                                                    {
                                                        selectDrugTypeOptions.map(function (item) {
                                                            return <option value={item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </select> */}
                                                <Select
                                                    placeholder="Select Type"
                                                    name="drugType"
                                                    id="drugType"
                                                    closeOnSelect={true}
                                                    multi={false}
                                                    value={formData.drugType}
                                                    options={selectDrugTypeOptions}
                                                    onChange={(e) => {
                                                        inputHandler(e, "drugType");
                                                    }}
                                                />
                                                {errors.drugType &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }

                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Instruction<span className="star">*</span></ControlLabel>
                                                {/* <select
                                                    className={'insulin-input form-control ' + (errors.instructionName ? errors.instructionName : '')}
                                                    name="instructionName"
                                                    id="instructionName"
                                                    closeOnSelect={false}
                                                    multi={true}
                                                    value={formData.instructionName}
                                                    onChange={(e) => {
                                                        inputHandler(e);
                                                    }}
                                                >
                                                    <option value="">Select Instruction</option>
                                                    {
                                                        drugInstrutionList.map(function (item) {
                                                            return <option value={item._id}>{item.instructionName}</option>
                                                        })
                                                    }
                                                </select> */}
                                                <Select
                                                    placeholder="Select Instruction"
                                                    name="instructionName"
                                                    id="instructionName"
                                                    closeOnSelect={true}
                                                    multi={false}
                                                    value={formData.instructionName}
                                                    options={drugInstrutionList}
                                                    onChange={(e) => {
                                                        inputHandler(e, "instructionName");
                                                    }}
                                                />
                                                {errors.instructionName &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Dose Selector<span className="star">*</span></ControlLabel>
                                                <select name="noOfDoseSelector" id="selNoOfDoseSelector" onChange={e => { inputHandler(e, "noOfDoseSelector"); }} value={formData.noOfDoseSelector} className={'form-control'} >
                                                    <option selected value="">Select No Of Rows *</option>
                                                    <option value="1">1 Preferred For Tablet</option>
                                                    <option value="2">2 Preferred For Syrup</option>
                                                    <option value="3">3 Preferred For Insulin/GLP-1</option>
                                                </select>
                                                {errors.noOfDoseSelector &&
                                                    <span className="validation_error">This Field Is Required.</span>
                                                }
                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <ControlLabel>Unit<span className="star">*</span></ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    name="unit"
                                                    id="unit"
                                                    placeholder="Unit"
                                                    onChange={e => { inputHandler(e, "unit"); }}
                                                    autocomplete="off"
                                                    value={formData.unit ? formData.unit : ''}
                                                />


                                            </FormGroup>
                                        </Col>

                                        <Col sm={6}>
                                            <FormGroup>
                                                <Row>
                                                    <ControlLabel className='col-sm-2'>Insulin</ControlLabel>
                                                    <FormControl
                                                        type="checkbox"
                                                        name="insulinCalculator"
                                                        id="insulinCalculator"
                                                        placeholder="Insulin Calculator"
                                                        onChange={e => { inputHandler(e, "insulinCalculator"); }}
                                                        autocomplete="off"
                                                        checked={formData.insulinCalculator}
                                                        value={formData.insulinCalculator ? formData.insulinCalculator : ''}
                                                        style={{ width: "20px", height: "15px" }}
                                                    />
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={12} className="text-right">
                                            <button disabled={btnClicked} type="button" className="btn-fill btn-wd btn btn-primary" onClick={e => { submitHandler(e) }}>Submit{btnClicked && <i class="fa fa-spinner fa-spin"></i>}</button>
                                        </Col>
                                    </Row>

                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default AddEditDrug


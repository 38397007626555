/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { supportListAction } from 'Admin/actions/supportsystem';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import moment from 'moment';
import { Button } from 'react-bootstrap';

class SupportList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: "",
      status: "",
      supportList: [],
      isLoading: true,
      asc_desc: true,
      userRow: null,
      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      searchText: '',
      formData: {
        direction: 'desc',
        order: "createdAt",
        offset: 0,
        limit: 10
      }
    }

    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onUserCount = this.onUserCount.bind(this);
  }

  componentDidMount() {
    let tempObj = this.state.formData;
    if (this.props.location.pathname === "/support-list/open") {
      tempObj.flag = "open";
      this.state.formData = tempObj;
    } else if (this.props.location.pathname === "/support-list/close") {
      tempObj.flag = "close";
      this.state.formData = tempObj;
    }

    this.props.supportListAction(this.state.formData)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isSupportList === true && nextProps.isSupportList !== this.props.isSupportList) {
      this.setState({ supportList: nextProps.SupportList.data, totalCount: nextProps.SupportList.count });
    }
    if (this.state.isLoading == true) {
      this.setState({ isLoading: false });
    }
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  navigateto() {
    // if (this.clicked !== "Button") {
    window.location.href = "Admin/add-supportsystem";
    // }
    // reset
    this.clicked = "";
  }


  srNo(cell, row, enumObject, rowIndex) {
    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  appDate(cell, row, enumObject, rowIndex) {
    return moment(row.date).format('DD-MMM-YYYY');
  }

  Action(cell, row, enumObject, rowIndex) {
    return (<span>
      <Link to={{ pathname: `/support-system-view/${row.ticketId}/`, state: { row } }}><i class="fa fa-eye" aria-hidden="true"></i></Link>
    </span>)
  }


  useraction(cell, row, enumObject, rowIndex) {
    let subStrTitle = cell;
    if (cell != '' && cell.length >= 200) {
      subStrTitle = `${cell.substring(0, 200)}...`;
    }
    return (<span><Link to={{ pathname: `support-system-view/${row.ticketId}/`, state: { row } }} onClick={this.toggleEdit.bind(this, row)}  >{subStrTitle}</Link>
      &nbsp;&nbsp; &nbsp;&nbsp;

    </span>)
  }

  _setStatusColor = (cell, row, enumObject, rowIndex) => {

    if (row.status === "open") {
      return (
        <span className='ticket-status-color-red'>{row.status}</span>
      )

    } else {
      return (
        <span className='ticket-status-color-green'>{row.status}</span>
      )

    }

  }

  onUserCount(cell, row, enumObject, rowIndex) {

    return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
  }

  toggleEdit(event) {
    this.setState({
      userRow: event
    });
  }


  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"))
  }

  onPageChange(page, sizePerPage) {
    let data = {};
    if (this.state.searchText === "") {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    } else {
      data['direction'] = this.state.formData.direction;
      data['order'] = this.state.formData.order;
      data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
      data['limit'] = sizePerPage;
    }

    this.setState({ sizePerPage: sizePerPage })
    this.setState({ currentPage: page })
    if (this.props.location.pathname === "/support-list/open") {
      data['flag'] = "open";
    } else if (this.props.location.pathname === "/support-list/close") {
      data['flag'] = "close";
    }
    this.props.supportListAction(data)
    
    if(this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage)  {
      eval('$(".ps").scrollTop(0)');
    }
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {}
        data['direction'] = this.state.formData.direction;
        data['order'] = this.state.formData.order;
        data['offset'] = this.state.formData.offset;
        data['limit'] = this.state.formData.limit;
        data['search'] = text;
        this.setState({ sizePerPage: 10 })
        this.setState({ currentPage: 1 })
        if (this.props.location.pathname === "/support-list/open") {
          data['flag'] = "open";
        } else if (this.props.location.pathname === "/support-list/close") {
          data['flag'] = "close";
        }
        this.props.supportListAction(data);
      }
    } else {
      setTimeout(() => {
        this.componentDidMount();
      }, 100);
    }
    this.setState({ searchText: text })
  }

  clearSearch(e) {
    this.setState({ searchText: '' });
    this.componentDidMount();
  }

  sortHandle() {
    this.setState({ asc_desc: !this.state.asc_desc });
    let data = this.state.formData;
    data.direction = this.state.asc_desc ? "asc" : "desc"
    this.setState({ formData: data });
    this.props.supportListAction(data);
  }

  render() {

    const selectRowProp = { mode: 'checkbox' };
    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3,  // the pagination bar size.
      prePage: 'Prev', // Previous page button text
      nextPage: 'Next', // Next page button text
      firstPage: 'First', // First page button text
      lastPage: 'Last', // Last page button text
      paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      paginationPosition: 'bottom',  // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      // onSearchChange: this.onSearchChange,
      noDataText: this._setTableOption(),
    };


    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={6}>
                        <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => this.props.history.replace('add-supportsystem')}>Add Support Ticket</Button>
                      </Col>
                    </Row>
                    <div className='table-responsive'>
                      <div className='search-box-admin'>
                        <div className='search'>
                          <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                          <span className='input-group-btn'>
                            <Button onClick={(e) => { this.clearSearch(e) }}>Clear</Button>
                          </span>
                        </div>
                      </div>
                      <BootstrapTable
                        selectRow="{selectRowProp}"
                        deleteRow={false}
                        data={this.state.supportList}
                        // search={true}
                        multiColumnSearch={true}
                        pagination={true}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={'Bottom'}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.state.totalCount }} useraction
                      >
                        <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' isKey searchable={false}>Id</TableHeaderColumn>
                        <TableHeaderColumn tdAttr={{ 'data-attr': '#' }} thStyle={{ width: '35px', textAlign: 'center' }} tdStyle={{ width: '35px', textAlign: 'center' }} dataField='sn' dataFormat={this.srNo.bind(this)}>S.No.</TableHeaderColumn>
                        <TableHeaderColumn tdAttr={{ 'data-attr': 'Id' }} thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} dataField='ticketId' >Ticket No.
                          {
                            this.state.asc_desc ?
                              <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                              <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                          }
                        </TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'createdAt' }} dataFormat={this.appDate.bind(this)} dataField='createdAt' >Create Date</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Title' }} dataFormat={this.useraction.bind(this)} dataField='title' >Title</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Clinic ID' }} dataField='clinicNumber' >Clinic ID</TableHeaderColumn>
                        <TableHeaderColumn tdAttr={{ 'data-attr': 'Id' }} thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} dataField='clinicName' >Clinic Name</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '50px', textAlign: 'center' }} tdStyle={{ width: '50px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'City' }} dataField='city' >City</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '50px', textAlign: 'center' }} tdStyle={{ width: '50px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'status' }} dataFormat={this._setStatusColor} dataField='status' >Status</TableHeaderColumn>
                        <TableHeaderColumn tdAttr={{ 'data-attr': 'Created By' }} thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} dataField='authorName' >Created By</TableHeaderColumn>
                        <TableHeaderColumn thStyle={{ width: '80px', textAlign: 'center' }} tdStyle={{ width: '80px', textAlign: 'center' }} tdAttr={{ 'data-attr': 'Patient ID,' }} dataField='' dataFormat={this.Action.bind(this)}>Action</TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    SupportList: state.supportsystem?.SupportList || [],
    isSupportList: state.supportsystem?.isSupportList || false,
    isSupportListError: state.supportlist?.isSupportListError || []
  }
}
export default withRouter(connect(mapStateToProps, { supportListAction })(SupportList));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from './actionTypes'
export const setTaskScreenRequest = (response) => ({
    type: actionTypes.SET_TASK_SCREEN_DATA,
    payload: response
})

export const setTaskScreenHeightWeightCaloryRequest = (response) => ({
    type: actionTypes.SET_TASK_HEIGHT_WEIGHT_DATA,
    payload: response
})

export const taskScreenAction = (data) => {
    //console.log(data, "dataaaaaaaaaaaaaaaaaaa")
    return dispatch => {
        dispatch(setTaskScreenRequest(data))
    }
}

export const taskScreenHeightWeightRequireForCaloryAction = (data) => {
    //console.log(data, "dataaaaaaaaaaaaaaaaaaa")
    return dispatch => {
        dispatch(setTaskScreenHeightWeightCaloryRequest(data))
    }
}
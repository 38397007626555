/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/region'
/* Add Region  list and Add form */

export const addRegionRequest = () => ({
    type: actionTypes.GET_ADD_REGION_REQUEST
})
export const addRegionSuccess = (response) => ({
    type: actionTypes.GET_ADD_REGION_SUCCESS,
    payload: {
        response,
    }
})
export const addRegionError = (error) => ({
    type: actionTypes.GET_ADD_REGION_ERROR,
    payload: {
        error,
    }
})

export const addRegionAction = (data) => {
    return dispatch => {
        dispatch(addRegionRequest())
        return API.addRegion(data)
            .then(response => {
                dispatch(addRegionSuccess(response.data))
            })
            .catch(error => {
                dispatch(addRegionError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Region list and Add form */
export const updateRegionRequest = () => ({
    type: actionTypes.GET_UPDATE_REGION_REQUEST
})
export const updateRegionSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_REGION_SUCCESS,
    payload: {
        response,
    }
})
export const updateRegionError = (error) => ({
    type: actionTypes.GET_UPDATE_REGION_ERROR,
    payload: {
        error,
    }
})
export const updateRegionAction = (data) => {
    return dispatch => {
        dispatch(updateRegionRequest())
        return API.updateRegion(data)
            .then(response => {
                dispatch(updateRegionSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateRegionError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Region list  */
export const RegionListRequest = () => ({
    type: actionTypes.GET_REGION_LIST_REQUEST
})
export const RegionListSuccess = (response) => ({
    type: actionTypes.GET_REGION_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const RegionListError = (error) => ({
    type: actionTypes.GET_REGION_LIST_ERROR,
    payload: {
        error
    }
})
export const regionListAction = (data, limitStatus) => {
    return dispatch => {
        dispatch(RegionListRequest());
        let FormData = '';
        if (data) {
            FormData = data;
        } else {
            FormData = {
                limit: 10,
                offset: 0,
                order: "name",
                direction: "asc",
            }

        }
        if (limitStatus) {
            FormData.limitStatus = limitStatus;
        }

        return API.regionList(FormData)
            .then(response => {
                dispatch(RegionListSuccess(response.data))
            })
            .catch(error => {
                dispatch(RegionListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Region Status Change  */
export const RegionStatusRequest = () => ({
    type: actionTypes.GET_REGION_CHANGE_STATUS_REQUEST
})
export const RegionChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_REGION_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const RegionChangeStatusError = (error) => ({
    type: actionTypes.GET_REGION_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const regionStatusAction = (data) => {
    return dispatch => {
        dispatch(RegionStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeRegionStatus(FormData)
            .then(response => {
                dispatch(RegionChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(RegionChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}






/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from './actionTypes'
import * as API from 'Admin/api/food'
/* Add Food list and Add form */

export const addFoodRequest = () => ({
    type: actionTypes.GET_ADD_FOOD_REQUEST
})
export const addFoodSuccess = (response) => ({
    type: actionTypes.GET_ADD_FOOD_SUCCESS,
    payload: {
        response,
    }
})
export const addFoodError = (error) => ({
    type: actionTypes.GET_ADD_FOOD_ERROR,
    payload: {
        error,
    }
})
export const addFoodAction = (data) => {
    return dispatch => {
        dispatch(addFoodRequest())
        return API.addFood(data)
            .then(response => {

                dispatch(addFoodSuccess(response.data))
            })
            .catch(error => {
                dispatch(addFoodError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Update Food list and Add form */
export const updateFoodRequest = () => ({
    type: actionTypes.GET_UPDATE_FOOD_REQUEST
})
export const updateFoodSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_FOOD_SUCCESS,
    payload: {
        response,
    }
})
export const updateFoodError = (error) => ({
    type: actionTypes.GET_UPDATE_FOOD_ERROR,
    payload: {
        error,
    }
})
export const updateFoodAction = (data) => {
    return dispatch => {
        dispatch(updateFoodRequest())

        return API.updateFood(data)
            .then(response => {

                dispatch(updateFoodSuccess(response.data))
            })
            .catch(error => {
                dispatch(updateFoodError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Food list  */
export const FoodListRequest = () => ({
    type: actionTypes.GET_FOOD_LIST_REQUEST
})
export const FoodListSuccess = (response) => ({
    type: actionTypes.GET_FOOD_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const FoodListError = (error) => ({
    type: actionTypes.GET_FOOD_LIST_ERROR,
    payload: {
        error
    }
})
export const foodListAction = (data, search) => {
    return dispatch => {
        dispatch(FoodListRequest());
        return API.foodList(data)
            .then(response => {
                dispatch(FoodListSuccess(response.data))
            })
            .catch(error => {
                dispatch(FoodListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Food cart list  */
export const FoodCartListRequest = () => ({
    type: actionTypes.GET_FOOD_CART_LIST_REQUEST
})
export const FoodCartListSuccess = (response) => ({
    type: actionTypes.GET_FOOD_CART_LIST_SUCCESS,
    payload: {
        response,
    }
})
export const FoodCartListError = (error) => ({
    type: actionTypes.GET_FOOD_CART_LIST_ERROR,
    payload: {
        error
    }
})
export const foodCartListAction = (data, search) => {
    return dispatch => {
        dispatch(FoodCartListRequest());
        return API.foodCartList(data)
            .then(response => {
                dispatch(FoodCartListSuccess(response.data))
            })
            .catch(error => {
                dispatch(FoodCartListError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}

/* Food Status Change  */
export const FoodChangeStatusRequest = () => ({
    type: actionTypes.GET_FOOD_CHANGE_STATUS_REQUEST
})
export const FoodChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_FOOD_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    }
})
export const FoodChangeStatusError = (error) => ({
    type: actionTypes.GET_FOOD_CHANGE_STATUS_ERROR,
    payload: {
        error
    }
})
export const foodchangestatusAction = (data) => {

    return dispatch => {
        dispatch(FoodChangeStatusRequest())
        const FormData = {
            id: data._id,
            status: data.status
        }
        return API.changeFoodStatus(FormData)
            .then(response => {

                dispatch(FoodChangeStatusSuccess(response.data))
            })
            .catch(error => {
                dispatch(FoodChangeStatusError(error.response !== undefined ? error.response.data : "Internet Connection Error"))
            })
    }
}




/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from "./actionTypes";
import * as API from "Front/api/home";
import moment from "moment";

//Patient Visit List
export const visitListRequest = () => ({
    type: actionTypes.GET_VISIT_LIST_REQUEST,
});
export const visitListSuccess = (response, patientStatus, otherVisitDate) => ({
    type: actionTypes.GET_VISIT_LIST_SUCCESS,
    payload: {
        response,
        patientStatus,
        otherVisitDate
    },
});


export const getOnlineVisitListSuccess = (response) => ({
    type: actionTypes.GET_ONLINE_VISIT_LIST_SUCCESS,
    payload: {
        response,
    },
});



export const setAddToVistListRequest = (payload) => ({
    type: actionTypes.SET_ADD_TO_VISIT_LIST,
    payload: {
        payload
    }
});


export const resetVisitListRequest = () => ({
    type: actionTypes.RESET_VISIT_LIST,
});

export const updateVisitListKnowledgeRequest = (payload, action) => ({
    type: actionTypes.UPDATE_VISIT_LIST_KNOWLEDGE_REQUEST,
    payload: {
        payload,
        action
    }
});

export const updateVisitListTaskRequest = (payload, action) => ({
    type: actionTypes.UPDATE_VISIT_LIST_TASK_REQUEST,
    payload: {
        payload,
        action
    }
});

export const updateVisitListDietActionReuest = (payload, action) => ({
    type: actionTypes.UPDATE_VISIT_LIST_DIET_REQUEST,
    payload: {
        payload,
        action
    }
});


export const updateVisitListAppActionRequest = (payload, action) => ({
    type: actionTypes.UPDATE_VISIT_LIST_APP_REQUEST,
    payload: {
        payload,
        action
    }
});


export const updateVisitListNextVisitDateActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_NEXT_VISIT_DATE_REQUEST,
    payload: {
        payload,
    }
});


export const updateVisitListPrescriptionActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PRESCRIPTION_REQUEST,
    payload,
});


export const UpdateVisitListPatientDetailActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_DETAIL_REQUEST,
    payload,
});

export const visitDetailRequest = () => ({
    type: actionTypes.GET_VISIT_DETAIL_REQUEST,
});


export const visitDetailSuccess = (payload) => ({
    type: actionTypes.VISIT_DETAIL_SUCCESS,
    payload: {
        payload,
    }
});

export const visitDetailError = (error) => ({
    type: actionTypes.VISIT_DETAIL_ERROR,
    payload: {
        error,
    },
});






export const addToVisitListSuccess = (response) => ({
    type: actionTypes.ADD_TO_VISIT_LIST_SUCCESS,
    payload: {
        response,
    },
});


export const visitListError = (error) => ({
    type: actionTypes.GET_VISIT_LIST_ERROR,
    payload: {
        error,
    },
});

export const setVisitListActiveTabRequest = (payload) => ({
    type: actionTypes.SET_ACTIVE_TAB_REQUEST,
    payload: {
        payload
    }
})

export const updateVisitListRequest = (response) => ({
    type: actionTypes.UPDATE_VISIT_LIST_REQUEST,
    payload: {
        response
    }
})


export const updateVisitListRequestEducator = (response) => ({
    type: actionTypes.UPDATE_VISIT_LIST_REQUEST_EDUCATOR,
    payload: {
        response,
    }
})


export const updateVisitDetailActionRequest = (payload) => ({
    type: actionTypes.UPDATE_PATIENT_VISIT_DETAIL_EDUCATOR,
    payload,

})


export const sortVisitListActionEconsultationRequest = (patient, flag) => ({
    type: actionTypes.SORT_VISIT_LIST_ACTION_ECONSULTATION,
    payload: {
        patient,
        flag
    },

})

export const tagListActionRequest = () => ({
    type: actionTypes.TAG_LIST_ACTION_REQUEST,
})

export const tagListSuccess = (response) => ({
    type: actionTypes.GET_TAG_LIST_SUCCESS,
    payload: {
        response,
    },
})

export const tagListError = (error) => ({
    type: actionTypes.GET_TAG_LIST_ERROR,
    payload: {
        error,
    },
});


export const starStatusListRequest = () => ({
    type: actionTypes.STAR_STATUS_LIST_REQUEST,
})

export const starStatusSuccess = (response) => ({
    type: actionTypes.STAR_STATUS_LIST_SUCCESS,
    payload: {
        response,
    },
})

export const starStatusListError = (error) => ({
    type: actionTypes.STAR_STATUS_LIST_ERROR,
    payload: {
        error,
    },
});


export const s3SiginedUrlArrayActionRequest = () => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_REQUEST,
})

export const s3SignedUrlArraySuccess = (response) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_SUCCESS,
    payload: {
        response,
    },
})

export const s3SignedUrlArrayError = (error) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_ERROR,
    payload: {
        error,
    },
});

export const UpdateVisitListS3SiginedUrlArrayRequest = () => ({
    type: actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_REQUEST,
})

export const UpdateVisitListS3SiginedUrlArrayOnlineListRequest = () => ({
    type: actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_ONLINE_LIST_REQUEST,
})

export const UpdateVisitListS3SiginedUrlArrayOutListRequest = () => ({
    type: actionTypes.UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_OUT_LIST_REQUEST
})

export const UpdateVisitListPatientTagsRequest = () => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_TAGS_REQUEST,
})

export const UpdateVisitListPatientATagActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_A_TAG_REQUEST,
    payload
})


export const UpdateVisitListPatientBTagActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_B_TAG_REQUEST,
    payload
})


export const UpdateVisitListPatientCTagActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_C_TAG_REQUEST,
    payload
})

export const UpdateVisitListPatientStarStatusActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_STAR_STATUS_REQUEST,
    payload
})

export const UpdateVisitListPatientInsulinDoseActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_INSULIN_DOSE_REQUEST,
    payload
})



export const UpdateVisitListReportUnreadCountShareReportActionRequest = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_REPORT_UNREAD_COUNT_SHARE_REPORT_REQUEST,
    payload
})

export const UpdateVisitListMessageForEducatorAction = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_MESSAGE_FOR_EDUCATOR_REQUEST,
    payload
})


export const UpdateVisitListPatientRemarkAction = (payload) => ({
    type: actionTypes.UPDATE_VISIT_LIST_PATIENT_REMARK_REQUEST,
    payload
})





export const visitListAction = (search_date, limit, skip, patientStatus = "offline") => {

    return (dispatch) => {
        dispatch(visitListRequest());



        let FormData = {
            search_date: search_date,
            search: "",
            limit: limit ? limit : 0,
            skip: skip ? skip : 0,
        };
        if (patientStatus && patientStatus === "online")
            FormData = {
                search_date: search_date,
                search: "",
                limit: limit ? limit : 0,
                skip: skip ? skip : 0,
                onlineConsultation: true
            };
        if (patientStatus && patientStatus === "OutPatientList")
            FormData = {
                search_date: search_date,
                search: "",
                limit: limit ? limit : 0,
                skip: skip ? skip : 0,
                outPatient: "out"
            };
        return API.visitList(FormData)
            .then((response) => {
                dispatch(visitListSuccess(response.data, patientStatus, !moment(search_date).isSame(moment(new Date()).format("YYYY-MM-DD"))));
            })
            .catch((error) => {
                dispatch(
                    visitListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

export const getOnlineVisitListAction = (search_date, limit, skip) => {

    return (dispatch) => {
        dispatch(visitListRequest());



        let FormData = {
            search_date: search_date,
            search: "",
            limit: limit ? limit : 0,
            skip: skip ? skip : 0,
            onlineConsultation: true
        };

        return API.visitList(FormData)
            .then((response) => {
                dispatch(getOnlineVisitListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    visitListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

export const filteredVisitListAction = (payload) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.GET_FILTERED_VISIT_LIST,
            payload: payload,
        })
    }
}

//Patient Registraction
export const patientRegistrationRequest = () => ({
    type: actionTypes.GET_PATIENT_REGISTRATION_REQUEST,
});
export const patientRegistrationSuccess = (response) => ({
    type: actionTypes.GET_PATIENT_REGISTRATION_SUCCESS,
    payload: {
        response,
    },
});
export const patientRegistrationError = (error) => ({
    type: actionTypes.GET_PATIENT_REGISTRATION_ERROR,
    payload: {
        error,
    },
});
export const patientRegistrationAction = (data) => {
    return (dispatch) => {
        dispatch(patientRegistrationRequest());
        let formData = new FormData();
        //formData.append('patient_id', data.formData.patient_id);
        formData.append("title", data.formData.title);
        formData.append("first_name", data.formData.first_name);
        formData.append(
            "last_name",
            data.formData.last_name ? data.formData.last_name : ""
        );
        formData.append("gender", data.formData.gender);
        formData.append("height", data.formData.height);
        formData.append("weight", data.formData.weight);
        formData.append("dob", data.formData.dob);
        formData.append("is_dob", data.formData.is_dob);
        formData.append("age", data.formData.age);
        formData.append("age_color", data.formData.age_color);
        formData.append("email", data.formData.email ? data.formData.email : "");
        formData.append("city", data.formData.city ? data.formData.city : "");
        formData.append("remark", data.formData.remark ? data.formData.remark : "");
        formData.append("phone", data.formData.phone ? data.formData.phone : "");
        formData.append(
            "econsultation",
            data.formData.econsultation ? data.formData.econsultation : ""
        );
        formData.append(
            "appointmentId",
            data.formData.appointmentId ? data.formData.appointmentId : ""
        );
        formData.append("fasting", data.formData.fasting ? data.formData.fasting : "");
        formData.append("pp", data.formData.pp ? data.formData.pp : "");
        formData.append("random", data.formData.random ? data.formData.random : "");
        formData.append("hbA1C", data.formData.hbA1C ? data.formData.hbA1C : "");
        formData.append("totalCholesterol", data.formData.hbA1C ? data.formData.totalCholesterol : "");
        formData.append("triglyceride", data.formData.triglyceride ? data.formData.triglyceride : "");
        formData.append("hdl", data.formData.hdl ? data.formData.hdl : "");
        formData.append("ldl", data.formData.ldl ? data.formData.ldl : "");
        formData.append("serumCreatinine", data.formData.serumCreatinine ? data.formData.serumCreatinine : "");
        formData.append("eFGR10", data.formData.eFGR10 ? data.formData.eFGR10 : "");
        formData.append("bun", data.formData.bun ? data.formData.bun : "");
        formData.append("acr", data.formData.acr ? data.formData.acr : "");
        formData.append("hb", data.formData.hb ? data.formData.hb : "");
        formData.append("tlc", data.formData.tlc ? data.formData.tlc : "");
        formData.append("platelet", data.formData.platelet ? data.formData.platelet : "");
        formData.append("sgot", data.formData.sgot ? data.formData.sgot : "");
        formData.append("sgpt", data.formData.sgpt ? data.formData.sgpt : "");
        formData.append("fib4", data.formData.fib4 ? data.formData.fib4 : "");
        formData.append("uricAcid", data.formData.uricAcid ? data.formData.uricAcid : "");
        formData.append("freeT4", data.formData.freeT4 ? data.formData.freeT4 : "");
        formData.append("t4", data.formData.t4 ? data.formData.t4 : "");
        formData.append("t3", data.formData.t3 ? data.formData.t3 : "");
        formData.append("tsh", data.formData.tsh ? data.formData.tsh : "");
        formData.append("cPeptideFasting", data.formData.cPeptideFasting ? data.formData.cPeptideFasting : "");
        formData.append("cPeptideStimulated", data.formData.cPeptideStimulated ? data.formData.cPeptideStimulated : "");
        formData.append("islabTests", data.formData.islabTests ? data.formData.islabTests : "");


        return API.patientRegistration(formData)
            .then((response) => {
                dispatch(patientRegistrationSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    patientRegistrationError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Patient Edit
export const patientEditRequest = () => ({
    type: actionTypes.GET_PATIENT_EDIT_REQUEST,
});
export const patientEditSuccess = (response) => ({
    type: actionTypes.GET_PATIENT_EDIT_SUCCESS,
    payload: {
        response,
    },
});
export const patientEditError = (error) => ({
    type: actionTypes.GET_PATIENT_EDIT_ERROR,
    payload: {
        error,
    },
});

//Patient Detail
export const patientDetailRequest = () => ({
    type: actionTypes.GET_PATIENT_DETAIL_REQUEST,
});
export const patientDetailSuccess = (response) => ({
    type: actionTypes.GET_PATIENT_DETAIL_SUCCESS,
    payload: {
        response,
    },
});
export const patientDetailError = (error) => ({
    type: actionTypes.GET_PATIENT_DETAIL_ERROR,
    payload: {
        error,
    },
});

export const getPatientDetailAction = (data) => {
    return (dispatch) => {
        dispatch(patientDetailRequest());
        return API.patientDetail(data)
            .then((response) => {
                dispatch(patientDetailSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    patientDetailError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

export const patientEditAction = (data) => {
    return (dispatch) => {
        dispatch(patientEditRequest());
        const params = {
            id: data.formData.patientId,
            title: data.formData.title,
            first_name: data.formData.first_name,
            last_name: data.formData.last_name,
            gender: data.formData.gender,
            height: data.formData.height,
            weight: data.formData.weight,
            dob: data.formData.dob,
            is_dob: data.formData.is_dob,
            age: data.formData.age,
            email: data.formData.email,
            city: data.formData.city,
            remark: data.formData.remark,
            phone: data.formData.phone,
            appointmentId: data.formData.appointmentId,
            econsultation: data.formData.econsultation,
            visitId: data.formData.visitId
        };

        return API.patientEdit(params)
            .then((response) => {
                dispatch(patientEditSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    patientEditError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Patient Out
export const patientOutRequest = () => ({
    type: actionTypes.GET_PATIENT_OUT_REQUEST,
});
export const patientOutSuccess = (response) => ({
    type: actionTypes.GET_PATIENT_OUT_SUCCESS,
    payload: {
        response,
    },
});
export const patientOutError = (error) => ({
    type: actionTypes.GET_PATIENT_OUT_ERROR,
    payload: {
        error,
    },
});
export const patientOutAction = (_id, econsultation) => {
    //console.log(econsultation, "econsultation")
    return (dispatch) => {
        dispatch(patientOutRequest());
        const formData = {
            id: _id,
            econsultation: econsultation
        };
        return API.patientOut(formData)
            .then((response) => {
                //console.log(response, "response111")
                dispatch(patientOutSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    patientOutError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Next Visit Date
export const nextVisitRequest = () => ({
    type: actionTypes.GET_NEXT_VISIT_REQUEST,
});
export const nextVisitSuccess = (response) => ({
    type: actionTypes.GET_NEXT_VISIT_SUCCESS,
    payload: {
        response,
    },
});
export const nextVisitError = (error) => ({
    type: actionTypes.GET_NEXT_VISIT_ERROR,
    payload: {
        error,
    },
});
export const nextVisitAction = (data) => {
    return (dispatch) => {
        dispatch(nextVisitRequest());
        const formData = {
            id: data.id,
            patient_id: data.patient_id,
            next_visit_date: data.next_visit_date,
            notificationDay: data.notificationDay,
            activeTab: data.activeTab
        };

        return API.nextVisit(formData)
            .then((response) => {
                dispatch(nextVisitSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    nextVisitError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//holiday list
export const holidayListRequest = () => ({
    type: actionTypes.GET_HOLIDAY_LIST_REQUEST,
});
export const holidayListSuccess = (response) => ({
    type: actionTypes.GET_HOLIDAY_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const holidayListError = (error) => ({
    type: actionTypes.GET_HOLIDAY_LIST_ERROR,
    payload: {
        error,
    },
});
export const holidayListAction = () => {
    return (dispatch) => {
        dispatch(holidayListRequest());

        return API.holidayList()
            .then((response) => {
                dispatch(holidayListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    holidayListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Get educator message list
export const educatorMsgListRequest = () => ({
    type: actionTypes.GET_EDUCATOR_MSG_LIST_REQUEST,
});
export const educatorMsgListSuccess = (response) => ({
    type: actionTypes.GET_EDUCATOR_MSG_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const educatorMsgListError = (error) => ({
    type: actionTypes.GET_EDUCATOR_MSG_LIST_ERROR,
    payload: {
        error,
    },
});
export const educatorMsgListAction = (_id) => {
    return (dispatch) => {
        dispatch(educatorMsgListRequest());

        return API.educatorMsgList()
            .then((response) => {
                dispatch(educatorMsgListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    educatorMsgListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Category List
export const categoryListRequest = () => ({
    type: actionTypes.GET_CATEGORY_LIST_REQUEST,
});
export const categoryListSuccess = (response) => ({
    type: actionTypes.GET_CATEGORY_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const categoryListError = (error) => ({
    type: actionTypes.GET_CATEGORY_LIST_ERROR,
    payload: {
        error,
    },
});

export const categoryListAction = (data) => {
    return (dispatch) => {
        dispatch(categoryListRequest());
        const FormData = {
            limit: 10,
            offset: 0,
            order: "name",
            direction: "asc",
        };
        return API.categoryList(FormData)
            .then((response) => {
                dispatch(categoryListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    categoryListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Knowledge List
export const knowledgeListRequest = () => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_REQUEST,
});
export const knowledgeListSuccess = (response) => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const knowledgeListError = (error) => ({
    type: actionTypes.GET_KNOWLEDGE_LIST_ERROR,
    payload: {
        error,
    },
});

export const knowledgeListAction = (data) => {
    return (dispatch) => {
        dispatch(knowledgeListRequest());
        const FormData = {
            limit: 1000,
            offset: 0,
            order: "name",
            direction: "asc",
            search: "",
        };
        return API.knowledgeList(FormData)
            .then((response) => {
                dispatch(knowledgeListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    knowledgeListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Print Document
export const printShareDocumentRequest = () => ({
    type: actionTypes.PRINT_SHARE_DOCUMENT_REQUEST,
});
export const printShareDocumentSuccess = (response) => ({
    type: actionTypes.PRINT_SHARE_DOCUMENT_SUCCESS,
    payload: {
        response,
    },
});
export const printShareDocumentError = (error) => ({
    type: actionTypes.PRINT_SHARE_DOCUMENT_ERROR,
    payload: {
        error,
    },
});

export const printShareDocumentAction = (data) => {
    return (dispatch) => {
        dispatch(printShareDocumentRequest());
        const FormData = {
            patient_id: data.patient_id,
            notification_token: data.notification_token,
            visit_id: data.visit_id,
            type: data.type,
            documents: data.documents,
            videos: data.type === "share" ? data.videos : [],
        };
        return API.printShareDocument(FormData)
            .then((response) => {
                dispatch(printShareDocumentSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    printShareDocumentError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Task List
export const taskListRequest = () => ({
    type: actionTypes.GET_TASK_LIST_REQUEST,
});
export const taskListSuccess = (response) => ({
    type: actionTypes.GET_TASK_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const taskListError = (error) => ({
    type: actionTypes.GET_TASK_LIST_ERROR,
    payload: {
        error,
    },
});

export const taskListAction = (data) => {
    return (dispatch) => {
        dispatch(taskListRequest());
        const FormData = {
            search_date: data.created_date,
            search: "",
            patient_id: data.pId,
        };
        return API.taskList(FormData)
            .then((response) => {
                dispatch(taskListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    taskListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Document/Video And Task status change
export const changeStatusRequest = () => ({
    type: actionTypes.CHANGE_STATUS_REQUEST,
});
export const changeStatusSuccess = (response) => ({
    type: actionTypes.CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    },
});
export const changeStatusError = (error) => ({
    type: actionTypes.CHANGE_STATUS_ERROR,
    payload: {
        error,
    },
});

export const changeStatusAction = (id, type) => {
    return (dispatch) => {
        dispatch(changeStatusRequest());
        const FormData = {
            id: id,
            type: type,
        };
        return API.changeStatus(FormData)
            .then((response) => {
                dispatch(changeStatusSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    changeStatusError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//get shared history
export const historyRequest = () => ({
    type: actionTypes.HISTORY_REQUEST,
});
export const historySuccess = (response) => ({
    type: actionTypes.HISTORY_SUCCESS,
    payload: {
        response,
    },
});
export const historyError = (error) => ({
    type: actionTypes.HISTORY_ERROR,
    payload: {
        error,
    },
});

export const historyAction = (id) => {
    return (dispatch) => {
        dispatch(historyRequest());
        const FormData = {
            patientId: id,
        };
        return API.history(FormData)
            .then((response) => {
                dispatch(historySuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    historyError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Change Patient Status
export const patientStatusChangeRequest = () => ({
    type: actionTypes.UPDATE_PATIENT_STATUS_CHANGE_REQUEST,
});
export const patientStatusChangeSuccess = (response) => ({
    type: actionTypes.UPDATE_PATIENT_STATUS_CHANGE_SUCCESS,
    payload: {
        response,
    },
});
export const patientStatusChangeError = (error) => ({
    type: actionTypes.UPDATE_PATIENT_STATUS_CHANGE_ERROR,
    payload: {
        error,
    },
});
export const patientStatusChangeAction = (data) => {
    return (dispatch) => {
        dispatch(patientStatusChangeRequest());

        const formData = {
            id: data.patient_id,
            status: data.status,
        };

        return API.changePatientStatus(formData)
            .then((response) => {
                dispatch(patientStatusChangeSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    patientStatusChangeError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//send notification
export const getPatientNotificationRequest = () => ({
    type: actionTypes.GET_PATIENT_NOTIFICATION_REQUEST,
});
export const getPatientNotificationSuccess = (response) => ({
    type: actionTypes.GET_PATIENT_NOTIFICATION_SUCCESS,
    payload: {
        response,
    },
});
export const getPatientNotificationError = (error) => ({
    type: actionTypes.GET_PATIENT_NOTIFICATION_ERROR,
    payload: {
        error,
    },
});
export const patientNotificationAction = (data) => {
    return (dispatch) => {
        dispatch(getPatientNotificationRequest());
        return API.sendNotification(data)
            .then((response) => {
                dispatch(getPatientNotificationSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    getPatientNotificationError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//My Clinic List
export const myClinicListRequest = () => ({
    type: actionTypes.GET_MYCLINIC_LIST_REQUEST,
});
export const myClinicListSuccess = (response) => ({
    type: actionTypes.GET_MYCLINIC_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const myClinicListError = (error) => ({
    type: actionTypes.GET_MYCLINIC_LIST_ERROR,
    payload: {
        error,
    },
});

export const myClinicListAction = (data) => {
    return (dispatch) => {
        dispatch(myClinicListRequest());
        const FormData = {
            // limit:1000,
            // offset:0,
            // order:"name",
            // direction:"asc",
            // search:""
        };
        return API.clinicList(FormData)
            .then((response) => {
                dispatch(myClinicListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    myClinicListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//My Knowlage Msg List
export const knowledgeMsgListRequest = () => ({
    type: actionTypes.GET_KNOWLEDGE_MSG_LIST_REQUEST,
});
export const knowledgeMsgListSuccess = (response) => ({
    type: actionTypes.GET_KNOWLEDGE_MSG_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const knowledgeMsgListError = (error) => ({
    type: actionTypes.GET_KNOWLEDGE_MSG_LIST_ERROR,
    payload: {
        error,
    },
});

export const knowledgeMsgListAction = (data) => {
    return (dispatch) => {
        dispatch(knowledgeMsgListRequest());
        const FormData = {
            // limit:1000,
            // offset:0,
            // order:"name",
            // direction:"asc",
            // search:""
        };
        return API.knowlageMsgList(FormData)
            .then((response) => {
                dispatch(knowledgeMsgListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    knowledgeMsgListAction(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Det history
export const dietHistoryListRequest = () => ({
    type: actionTypes.GET_DIET_HISTORY_LIST_REQUEST,
});
export const dietHistoryListSuccess = (response) => ({
    type: actionTypes.GET_DIET_HISTORY_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const dietHistoryListError = (error) => ({
    type: actionTypes.GET_DIET_HISTORY_LIST_ERROR,
    payload: {
        error,
    },
});

export const dietHistoryListAction = (data) => {
    return (dispatch) => {
        dispatch(dietHistoryListRequest());
        // const FormData = {
        //     // limit:1000,
        //     // offset:0,
        //     // order:"name",
        //     // direction:"asc",
        //     // search:""
        // }
        return API.dietHistoryList(data)
            .then((response) => {
                dispatch(dietHistoryListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    dietHistoryListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

export const setVisitListActiveTabAction = (data) => {
    //console.log(data, "data")
    return dispatch => {
        dispatch(setVisitListActiveTabRequest(data))
    }
}

export const updateVisitListAction = (response) => {

    return dispatch => {

        let userType = localStorage.getItem("userType");
        if (userType === "doctor")
            dispatch(updateVisitListRequest(response))
        else dispatch(updateVisitListRequestEducator(response))



    }
}


export const addToVisitListAction = (data) => {
    //console.log(data, "data")
    return dispatch => {
        dispatch(addToVisitListSuccess(data))
    }
}

// export const setAddToVistListAction = () => {
//     return dispatch => {
//         dispatch(setAddToVistListRequest(false))
//     }
// }

export const resetVisitListAction = () => {
    return dispatch => {
        dispatch(resetVisitListRequest())
    }
}

export const updateVisitListKnowledgeAction = (payload, action) => {
    return dispatch => {
        dispatch(updateVisitListKnowledgeRequest(payload, action))
    }
}

export const updateVisitListTaskAction = (payload, action) => {
    return dispatch => {
        dispatch(updateVisitListTaskRequest(payload, action))
    }
}


export const updateVisitListDietAction = (payload, action) => {
    return dispatch => {
        dispatch(updateVisitListDietActionReuest(payload, action))
    }
}


export const UpdateVisitListFromAppAction = (payload, action) => {
    return dispatch => {
        dispatch(updateVisitListAppActionRequest(payload, action))
    }
}

export const UpdateVisitListNextVisitDateAction = (payload) => {
    return dispatch => {
        dispatch(updateVisitListNextVisitDateActionRequest(payload))
    }
}



export const visitDetailAction = (payload) => {

    return (dispatch) => {
        dispatch(visitDetailRequest());
        let FormData = {
            visitId: payload.id,
            search_date: payload.search_date
        };

        return API.patientVisitDetail(FormData)
            .then((response) => {
                dispatch(visitDetailSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    visitDetailError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};


export const updateVisitDetailAction = (payload) => {

    return dispatch => {
        dispatch(updateVisitDetailActionRequest(payload))
    }
};



export const UpdateVisitListPrescriptionAction = (payload) => {

    return dispatch => {
        dispatch(updateVisitListPrescriptionActionRequest(payload))
    }
};



export const UpdateVisitListPatientDetailAction = (payload) => {

    return dispatch => {
        dispatch(UpdateVisitListPatientDetailActionRequest(payload))
    }
};

export const sortVisitListEconsultationAction = (patient, flag) => {
    return dispatch => {
        dispatch(sortVisitListActionEconsultationRequest(patient, flag))
    }
}


export const tagListAction = () => {
    return (dispatch) => {
        dispatch(tagListActionRequest())
        return API.tagList()
            .then((response) => {
                dispatch(tagListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    tagListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    }
}


export const s3SiginedUrlArrayAction = (payload) => {
    return (dispatch) => {
        dispatch(s3SiginedUrlArrayActionRequest())
        return API.s3SignedUrlArray(payload)
            .then((response) => {
                dispatch(s3SignedUrlArraySuccess(response));
            })
            .catch((error) => {
                dispatch(
                    s3SignedUrlArrayError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    }
}


export const UpdateVisitListS3SiginedUrlArrayAction = () => {
    return dispatch => {
        dispatch(UpdateVisitListS3SiginedUrlArrayRequest())
    }
}

export const UpdateVisitListS3SiginedUrlArrayOnlineListAction = () => {
    return dispatch => {
        dispatch(UpdateVisitListS3SiginedUrlArrayOnlineListRequest())
    }
}

export const UpdateVisitListS3SiginedUrlArrayOutListAction = () => {
    return dispatch => {
        dispatch(UpdateVisitListS3SiginedUrlArrayOutListRequest())
    }
}

export const UpdateVisitListPatientATagAction = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientATagActionRequest(payload))
    }
}

export const UpdateVisitListPatientBTagAction = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientBTagActionRequest(payload))
    }
}

export const UpdateVisitListPatientCTagAction = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientCTagActionRequest(payload))
    }
}

export const UpdateVisitListPatientStarStatusAction = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientStarStatusActionRequest(payload))
    }
}



export const UpdateVisitListInsulinDoseAction = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientInsulinDoseActionRequest(payload))
    }
}

export const UpdateVisitListReportUnreadCountShareReportAction = (payload) => {

    return dispatch => {
        dispatch(UpdateVisitListReportUnreadCountShareReportActionRequest(payload))
    }
}

export const updateVisitListMessageForEducator = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListMessageForEducatorAction(payload))
    }
}

export const updateVisitListPatientRemark = (payload) => {
    return dispatch => {
        dispatch(UpdateVisitListPatientRemarkAction(payload))
    }
}






export const starStatusListAction = () => {
    return (dispatch) => {
        dispatch(starStatusListRequest())
        return API.starStatusList()
            .then((response) => {
                dispatch(starStatusSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    starStatusListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    }
}

export const s3SiginedUrlArrayActionUploadDocumentClinicRequest = () => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_REQUEST,
})

export const s3SignedUrlArrayUploadDocumentClinicSuccess = (response) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_SUCCESS,
    payload: {
        response,
    },
})

export const s3SignedUrlArrayUploadDocumentClinicError = (error) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_ERROR,
    payload: {
        error,
    },
});

export const s3SiginedUrlArrayActionUploadDocumentClinic = (payload) => {
    return (dispatch) => {
        dispatch(s3SiginedUrlArrayActionUploadDocumentClinicRequest())
        return API.s3SignedUrlArray(payload)
            .then((response) => {
                dispatch(s3SignedUrlArrayUploadDocumentClinicSuccess(response));
            })
            .catch((error) => {
                dispatch(
                    s3SignedUrlArrayUploadDocumentClinicError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    }
}



export const s3SiginedUrlArrayActionUploadDocumentAdminRequest = () => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_REQUEST,
})

export const s3SignedUrlArrayUploadDocumentAdminSuccess = (response) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_SUCCESS,
    payload: {
        response,
    },
})

export const s3SignedUrlArrayUploadDocumentAdminError = (error) => ({
    type: actionTypes.S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_ERROR,
    payload: {
        error,
    },
});


export const s3SiginedUrlArrayActionUploadDocumentAdmin = (payload) => {
    return (dispatch) => {
        dispatch(s3SiginedUrlArrayActionUploadDocumentAdminRequest())
        return API.s3SignedUrlArray(payload)
            .then((response) => {
                dispatch(s3SignedUrlArrayUploadDocumentAdminSuccess(response));
            })
            .catch((error) => {
                dispatch(
                    s3SignedUrlArrayUploadDocumentAdminError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    }
}
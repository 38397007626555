/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";

import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import Select from "react-select";
import "react-select/dist/react-select.css";
import {
  patternFontOptions,
  weekOptions,
  appTimeSlot,
} from "Admin/variables/Variables.jsx";
import {
  uploadFileAction,
  countryListAction,
  stateListAction,
  patternListAction,
} from "Admin/actions/master";
import { specializationListAction } from "Admin/actions/specialization";
import { addClinicAction } from "Admin/actions/clinic";
import loadingImg from "Admin/assets/img/loading.gif";
//import avatar from 'Admin/assets/img/avatar.png';
import moment from "moment";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import PatternPreview from "./PatternPreview.jsx";

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  clinic_name: "required",
  email: "required|email",
  pattern: "required",
  appointment_notification: "numeric",
  latitude: "numeric",
  longitude: "numeric",
  margin_top: "numeric",
  margin_bottom: "numeric",
  margin_left: "numeric",
  margin_right: "numeric",
  limit: "numeric",
  doctorName: "required",
  degree: "required",
  address11: "required",
  city: "required",
  state: "required",
  pin_code: "numeric",
  phone: "required|digits_between:10,12",
  mobile: "digits_between:10,12",
  appointmentno: "digits_between:10,12",
  phone22: "digits_between:10,12",
  mobile22: "digits_between:10,12",
  appointmentno22: "digits_between:10,12",
};
let mess = {
  required: "This field is required",
  email: "Invalid Email",
  numeric: "Enter numeric digits",
  digits: "Enter numeric 10 digits",
  digits_between: "Phone no. must be between 10 and 12 digits",
};
let validation = [];

let newObj = {};
let newObj1 = {};

class AddClinic extends Component {
  constructor(props) {
    super(props);
    this.vForm = this.refs.vForm;
    this.state = {
      formArr: [],
      isLogin: true,
      isLoading: false,
      showProcessing: false,
      week_off: null,
      specialization: null,
      specializations: null,
      clinicFont: null,
      addressFont: null,
      imagePreviewUrl: "",
      formData: {
        clinic_name: "",
        clinic_email: "",
        short_name: "",
        doctorName: "",
        specializations: "",
        degree: "",
        clinic_website: "",
        phoneno: "",
        mobile_no: "",
        appointment_no: "",
        emergency_no: "",
        email: "",
        pattern: "",
        email_facility: "No",
        chat_facility: "Yes",
        data_updated: "No",
        status: "active",
        appointment_notification: "",
        latitude: "",
        longitude: "",
        diet_print_format: "No",
        margin_top: "",
        margin_bottom: "",
        margin_left: "",
        margin_right: "",
        week_off: [],
        specialization: [],
        holiday_date: [],
        background_image: "",
        contact_image: "",
        logo_image: "",
        doctor_image: "",
        logo: "",
        description: "",
        insulinCalculator: "",
        clinic_name_font: "",
        address_font: "",
        limit: "",
        slot: "",
        country_id: "",
        appointmentNoShow: false,
        phoneNoShow: false,
        mobileNoShow: false,
        emergencyShow: false,
        mci_number: "",
        address: {
          evening_till: "",
          evening_from: "",
          morning_till: "",
          morning_from: "",
          pin_code: "",
          state: "",
          stateId: "",
          country: "",
          countryId: "",
          city: "",
          address: "",
          phone: "",
          mobile: "",
          appointmentno: "",
          appointmentNoShow: false,
          phoneNoShow: false,
          mobileNoShow: false,
        },
        address2: {
          evening_till: "",
          evening_from: "",
          morning_till: "",
          morning_from: "",
          pin_code: "",
          state: "",
          stateId: "",
          country: "",
          countryId: "",
          city: "",
          address: "",
          phone: "",
          mobile: "",
          appointmentno: "",
          appointmentNoShow: false,
          phoneNoShow: false,
          mobileNoShow: false,
        },
      },
      startTime1: moment().startOf("day").add(8, "hours"),
      endTime1: "",
      startTime2: "",
      endTime2: "",
      morning_from1: "",
      morning_till1: "",
      evening_from1: "",
      evening_till1: "",
      stateList: [],
      countryList: [],
      SpecializationList: [],
      country_id: "",
      uploaded_file: "",
      multiInput: false,
      selectedDays: [],
      imagePreviewUrlClinic: "",
      imagePreviewUrlContact: "",
      imagePreviewUrlDoctor: "",
      phone_cb: false,
      mobile_cb: false,
      appointment_cb: false,
      emergency_cb: false,
      phone_cb1: false,
      mobile_cb1: false,
      appointment_cb1: false,
      emergency_cb1: false,
      endTime1Error: "",
      endTime2Error: "",
      start1Error: "",
      start2Error: "",
      end1Error: "",
      end2Error: "",
      morningFormError: "",
      morningTillError: "",
      eveningFormError: "",
      eveningTillError: "",
      limitForm: {},
      patternList: [],
      city: "",
      address11: "",
      phone: "",
      mobile: "",
      appointmentno: "",
      phone22: "",
      mobile22: "",
      appointmentno22: "",
      clinic_websiteError: "",
      validationError: false,
      saveBtnClicked: false,
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.selectMultiData = this.selectMultiData.bind(this);
    this.handleClinicFontChange = this.handleClinicFontChange.bind(this);
    this.handleAddressFontChange = this.handleAddressFontChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.openPatternModal = this.openPatternModal.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleAddress2Change = this.handleAddress2Change.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleCheckboxChange1 = this.handleCheckboxChange1.bind(this);

    this.box = React.createRef();
    this.holidayCalender = React.createRef();
    /*let _this = this;
        $(document).mouseup(function (e) {
            var container = $(".multiDateP");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                _this.setState({ multiInput: false });
            }
        });*/
  }

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick);
    let formData1 = this.state.formData;
    newObj = {
      morning_from: "",
      morning_till: "",
      evening_from: "",
      evening_till: "",
      address: "",
      city: "",
      state: "",
      stateId: "",
      country: "",
      countryId: "",
      pin_code: "",
      phone: "",
      mobile: "",
      appointmentno: "",
      appointmentNoShow: false,
      phoneNoShow: false,
      mobileNoShow: false,
    };
    newObj1 = {
      morning_from: "",
      morning_till: "",
      evening_from: "",
      evening_till: "",
      address: "",
      city: "",
      state: "",
      stateId: "",
      country: "",
      countryId: "",
      pin_code: "",
      phone: "",
      mobile: "",
      appointmentno: "",
      appointmentNoShow: false,
      phoneNoShow: false,
      mobileNoShow: false,
    };

    formData1["address"] = newObj;
    formData1["address2"] = newObj1;

    this.setState({ formData: formData1 });

    this.props.countryListAction(this.state);
    let data = this.state.limitForm;
    data["limitStatus"] = true;
    this.props.specializationListAction(data);
    //this.setState({ imagePreviewUrl: avatar });
    this.setState({ imagePreviewUrl: "" });

    if (this.props && this.props.location && this.props.location.state) {
      let formData = this.state.formData;
      formData.invitation_id = this.props.location.state._id;
      formData.clinic_name = this.props.location.state.clinicName;
      formData.email = this.props.location.state.email;
      formData.address = this.props.location.state.address;
      formData.phoneno = this.props.location.state.phone;
      formData.city = this.props.location.state.city;
      formData.appointment_no = this.props.location.state.appointmentno;
      this.setState({ formData: formData });
      if (
        this.props.location.state.city &&
        this.props.location.state.city !== ""
      ) {
        this.props.patternListAction({ city: this.props.location.state.city });
      } else {
        this.props.patternListAction({ city: "" });
      }
    } else {
      this.props.patternListAction({ city: "" });
    }
  }

  componentWillReceiveProps(nextProps) {
    let formData = this.state.formData;
    if (nextProps.isCountryList !== this.props.isCountryList) {
      this.setState({
        countryList: nextProps.countryList.data.data,
      });
      let country = {};
      if (
        nextProps.countryList.data &&
        nextProps.countryList.data.data &&
        nextProps.countryList.data.data.length > 0
      ) {
        nextProps.countryList.data.data.map((v, i) => {
          if (v.short_name === "India") {
            country = { id: v._id, name: v.short_name };
          }
        });
        formData.country_id = country.id;
        formData.country = country.name;

        newObj["country"] = country.id;
        newObj["countryId"] = country.name;
        newObj1["country"] = country.id;
        newObj1["countryId"] = country.name;
        formData["address"] = newObj;
        formData["address2"] = newObj1;

        this.setState({ formData: formData });
      }
      this.props.stateListAction(country.id);
    }

    if (
      nextProps.isStateList !== this.props.isStateList &&
      this.props.isStateList === false
    ) {
      this.setState({
        stateList: nextProps.stateList.data.data,
      });
    }

    if (
      nextProps.isSpecializationList &&
      nextProps.isSpecializationList !== this.props.isSpecializationList
    ) {
      if (
        nextProps.SpecializationList &&
        nextProps.SpecializationList.data &&
        nextProps.SpecializationList.data.data &&
        nextProps.SpecializationList.data.data.length > 0
      ) {
        this.state.specializationList =
          nextProps.SpecializationList.data.data.map((key, i) => {
            return { value: key._id, label: key.name };
          });
      }
    }

    if (nextProps.isAddClinicError !== this.props.isAddClinicError) {
      this.setState({ showProcessing: false });
      if (nextProps.addClinicResponse.errors) {
        nextProps.addClinicResponse.errors.map((key, i) => {
          this.setState({ [key.param + "Error"]: key.msg, saveBtnClicked: false });
        });
      }
    }
    if (
      nextProps.isAddClinic !== this.props.isAddClinic &&
      nextProps.addClinicResponse.status === "Success" &&
      this.state.formData.clinic_name !== ""
    ) {
      this.props.handleClick("success", nextProps.addClinicResponse.msg);
      this.setState({ saveBtnClicked: false });
      this.props.history.push(`/admin/clinic-list`);
    }

    if (nextProps.isUploadFile !== this.props.isUploadFile) {
      let uploaded_file = this.state.uploaded_file;
      let field = this.state.formData;
      field[uploaded_file] = nextProps.uploadFile.file_name;
      this.setState({ formData: field });
    }

    if (nextProps.isUploadFileError !== this.props.isUploadFileError) {
      if (nextProps.uploadFile.errors) {
        let uploaded_file = this.state.uploaded_file;
        nextProps.uploadFile.errors.map((key, i) => {
          this.setState({ [uploaded_file + "Error"]: key.msg });
        });
      }
    }

    if (nextProps.isPatternList !== this.props.isPatternList) {
      this.setState({
        patternList: nextProps.patternList.data,
      });
    }
  }

  onDismiss() {
    this.setState({ previewModal: false });
    this.successAlert("Patient successfully registered");
  }

  handleOutsideClick = (event) => {
    if (this.box && !this.box.current.contains(event.target)) {
      if (this.holidayCalender.current.style.display === "block") {
        this.setState({ multiInput: false });
      }
    }
  };

  openPatternModal() {
    this.setState({ previewModal: true });
  }

  addClinic(evt) {
    this.setState({ isLoading: true });
    evt.preventDefault();
    const _this = this;
    if (this.allValidate(false)) {
      _this.setState({ showProcessing: true, saveBtnClicked: true });
      _this.props.addClinicAction(this.state);
    }
    //validation.errors()
    setTimeout(() => {
      // Once the action is complete, enable the button again
      this.setState({ isButtonDisabled: false });
      this.setState({ isLoading: false });
    }, 100);
    this.props.onClickAction()
      .then(() => {
        // After the action is completed, reset the loading state to false
        this.setState({ isLoading: false });
      })
      .catch(() => {
        // Handle any errors and reset loading state
        this.setState({ isLoading: false });
      });
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;

    field[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      if (e.target.value !== "")
        this.setState({ emailError: "" })
      field[e.target.name] = e.target.value ? e.target.value.trim() : "";
    } else if (e.target.name === "appointment_no") {
      let val = /^[a-z\d\-_\s]+$/i;
      field[e.target.name] = e.target.value ? e.target.value.trim() : "";
    } else if (e.target.name === "clinic_website") {
      let domain = e.target.value;
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      if (domain.match(re) || domain == "") {
        this.setState({ clinic_websiteError: "", validationError: false });
      } else {
        this.setState({ validationError: true });
      }
    }
    this.setState({ formData: field });
  }

  websiteValidation(e) {
    e.preventDefault();

    if (e.target.name === "clinic_website") {
      let domain = e.target.value;
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      if (domain.match(re) || domain == "") {
        this.setState({ clinic_websiteError: "", validationError: false });
      } else {
        this.setState({
          clinic_websiteError: "Enter valid webiste.",
          validationError: true,
        });
      }
    }
  }

  handleCountryChange(event) {
    let data = this.state.formData;
    newObj["country"] = event.target[event.target.selectedIndex].text;
    newObj["countryId"] = event.target.value;
    data["address"] = newObj;

    this.setState({ formData: data });
    this.props.stateListAction(event.target.value);
  }

  handleStateChange(event) {
    let data = this.state.formData;
    newObj["state"] = event.target[event.target.selectedIndex].text;
    newObj["stateId"] = event.target.value;
    data["address"] = newObj;
    this.setState({ formData: data });
  }

  handleState2Change(event) {
    let data = this.state.formData;
    newObj1["state"] = event.target[event.target.selectedIndex].text;
    newObj1["stateId"] = event.target.value;
    data["address2"] = newObj1;
    this.setState({ formData: data });
  }

  handlepatternChange(event) {
    let data = this.state.formData;
    data["pattern"] = event && event.value ? event.value : "";
    this.setState({ formData: data });
  }

  handleClinicFontChange(event, name) {
    let data = this.state.formData;
    let clinicFont = this.state.clinicFont;
    data[name] = event && event.value;
    clinicFont = event;
    this.setState({ formData: data, clinicFont });
  }

  handleAddressFontChange(event, name) {
    let data = this.state.formData;
    let addressFont = this.state.addressFont;
    data[name] = event && event.value;
    addressFont = event;
    this.setState({ formData: data, addressFont });
  }

  validateSlot(newObj, type) {
    if (type === 1) {
      this.setState({ start2Error: "" });
    }

    if (type === 2) {
      this.setState({ eveningFormError: "" });
    }

    if (
      newObj["evening_from"] &&
      newObj["evening_from"] !== "" &&
      newObj["evening_till"] &&
      newObj["evening_till"] !== "" &&
      newObj["morning_from"] &&
      newObj["morning_from"] !== "" &&
      newObj["morning_till"] &&
      newObj["morning_till"] !== "" &&
      moment(newObj["evening_from"], ["h:mm A"]).format("x") <
      moment(newObj["morning_till"], ["h:mm A"]).format("x")
    ) {
      if (type === 1) {
        this.setState({
          start2Error: "Evening from is not less then morning till.",
        });
      } else {
        this.setState({
          eveningFormError: "Evening from is not less then morning till.",
        });
      }
    }
  }

  handleChangeTime(data, name) {
    let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
    let data1 = this.state.formData;
    data1[name] = data.target.value;

    if (name === "morning_from") {
      newObj[name] = data.target.value;
      data1["morning_till"] = moment(dateObject)
        .add(1, "hours")
        .format("h:mm A");
      newObj["morning_till"] = data1["morning_till"];
      data1["address"] = newObj;
      this.setState({ formData: data1, startTime1: dateObject });

      this.validateSlot(newObj, 1);
    } else if (name === "morning_from2") {
      newObj1["morning_from"] = data.target.value;
      data1["morning_till2"] = moment(dateObject)
        .add(1, "hours")
        .format("h:mm A");
      newObj1["morning_till"] = data1["morning_till2"];
      data1["address2"] = newObj1;
      this.setState({ formData: data1, morning_from1: dateObject });
      this.validateSlot(newObj1, 2);
    }
  }

  handleChangeTime2(data, name) {
    let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
    let data1 = this.state.formData;
    data1[name] = data.target.value;

    if (name === "morning_till") {
      newObj[name] = data.target.value;
      data1["address"] = newObj;
      this.setState({ formData: data1, endTime1: dateObject });

      this.validateSlot(newObj, 1);
    } else if (name === "morning_till2") {
      newObj1["morning_till"] = data.target.value;
      data1["address2"] = newObj1;
      this.setState({ formData: data1, morning_till1: dateObject });
      this.validateSlot(newObj1, 2);
    }
  }

  handleChangeTime3(data, name) {
    let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
    let data1 = this.state.formData;
    data1[name] = data.target.value;

    if (name === "evening_from") {
      newObj[name] = data.target.value;
      data1["evening_till"] = moment(dateObject)
        .add(1, "hours")
        .format("h:mm A");

      newObj["evening_till"] = data1["evening_till"];
      data1["address"] = newObj;

      this.setState({ formData: data1, startTime2: dateObject });

      this.validateSlot(newObj, 1);
    } else if (name === "evening_from2") {
      newObj1["evening_from"] = data.target.value;
      data1["evening_till2"] = moment(dateObject)
        .add(1, "hours")
        .format("h:mm A");

      newObj1["evening_till"] = data1["evening_till2"];
      data1["address2"] = newObj1;
      this.setState({ formData: data1, evening_from1: dateObject });
      this.validateSlot(newObj1, 2);
    }
  }

  handleChangeTime4(data, name) {
    let dateObject = new Date(moment(data.target.value, ["h:mm A"]));
    let data1 = this.state.formData;
    data1[name] = data.target.value;

    if (name === "evening_till") {
      newObj[name] = data.target.value;
      data1["address"] = newObj;
      this.setState({ formData: data1, endTime2: dateObject });
      this.validateSlot(newObj, 1);
    } else if (name === "evening_till2") {
      newObj1["evening_till"] = data.target.value;
      data1["address2"] = newObj1;
      this.setState({ formData: data1, evening_till1: dateObject });
      this.validateSlot(newObj1, 2);
    }
  }

  handleDateChange(date) {
    let data = this.state.formData;
    data["holiday_date"] = date;
    this.setState({ formData: data });
  }

  handleWeekOff(field) {
    let entry = [];
    let data = this.state.formData;
    field.map((key, index) =>
      entry.push({
        day: key.value,
        name: key.label,
      })
    );
    if (entry) {
      for (let i in entry) {
        if (entry[i].day == 7) {
          entry[i].day = 0;
        }
      }
    }

    data["week_off"] = entry;
    this.setState({ formData: data });
  }

  handleSpecialization(field) {
    let entry = [];
    let data = this.state.formData;
    field.map((key, index) =>
      entry.push({
        id: key.value,
        name: key.label,
      })
    );
    data["specialization"] = entry;
    this.setState({ formData: data });
  }

  handleCheckboxChange(e, name) {
    let data = this.state.formData;

    if (name === "phoneNoShow") {
      this.setState({ phone_cb: e.target.checked });
    }

    if (name === "mobileNoShow") {
      this.setState({ mobile_cb: e.target.checked });
    }

    if (name === "appointmentNoShow") {
      this.setState({ appointment_cb: e.target.checked });
    }

    if (name === "emergencyShow") {
      this.setState({ emergency_cb: e.target.checked });
    }

    newObj[e.target.name] = e.target.checked;
    data["address"] = newObj;
    this.setState({ formData: data });
  }

  handleCheckboxChange1(e, name) {
    let data = this.state.formData;

    if (name === "phoneNoShow") {
      this.setState({ phone_cb1: e.target.checked });
    }

    if (name === "mobileNoShow") {
      this.setState({ mobile_cb1: e.target.checked });
    }

    if (name === "appointmentNoShow") {
      this.setState({ appointment_cb1: e.target.checked });
    }

    if (name === "emergencyShow") {
      this.setState({ emergency_cb1: e.target.checked });
    }

    newObj1[e.target.name] = e.target.checked;
    data["address2"] = newObj1;
    this.setState({ formData: data });
  }

  handleChangeAddress(data) {
    let { formData } = this.state;
    formData = data;
    let country = {};
    if (this.state.countryList && this.state.countryList.length > 0) {
      this.state.countryList.map((v, i) => {
        if (v.short_name === formData.country) {
          country = { id: v._id, name: v.short_name };
        }
      });

      formData.country_id = country.id;
      formData.country = country.name;
      this.setState({ formData });
    }
    this.props.stateListAction(country.id);
  }

  getNumberValidation(e, name, type) {
    if (name === "phone") {
      if (e.target.value && e.target.value.length !== 10) {
        if (type === "address1") {
          //this.setState({ phoneError: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
        } else {
          //this.setState({ phoneError1: (<small className="text-danger">Phone number has to be 10 digits.</small>) })
        }
      } else {
        if (type === "address1") {
          this.setState({ phoneError: null });
        } else {
          this.setState({ phoneError1: null });
        }
      }
    }
    if (name === "mobile") {
      if (e.target.value && e.target.value.length !== 10) {
        if (type === "address1") {
          //this.setState({ mobileError: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
        } else {
          //this.setState({ mobileError1: (<small className="text-danger">Contact number has to be 10 digits.</small>) })
        }
      } else {
        if (type === "address1") {
          this.setState({ mobileError: null });
        } else {
          this.setState({ mobileError1: null });
        }
      }
    }
    if (name === "appointmentno") {
      if (e.target.value && e.target.value.length !== 10) {
        if (type === "address1") {
          //this.setState({ appointmentnoError: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
        } else {
          //this.setState({ appointmentnoError1: (<small className="text-danger">Whatsapp number has to be 10 digits.</small>) })
        }
      } else {
        if (type === "address1") {
          this.setState({ appointmentnoError: null });
        } else {
          this.setState({ appointmentnoError1: null });
        }
      }
    }
  }

  handleAddressChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    this.getNumberValidation(e, e.target.name, "address1");
    newObj[e.target.name] = e.target.value;
    field["address"] = newObj;
    field[e.target.name === "address" ? e.target.name + "11" : e.target.name] =
      e.target.value;
    this.setState({ formData: field });
  }

  handlePatternCheck(e) {
    e.preventDefault();
    if (e.target.name === "city") {
      this.props.patternListAction({ city: e.target.value });
    }
  }

  handleAddress2Change(e) {
    e.preventDefault();
    let field = this.state.formData;

    this.getNumberValidation(e, e.target.name, "address2");

    newObj1[e.target.name] = e.target.value;
    field["address2"] = newObj1;
    field[e.target.name + "22"] = e.target.value;
    this.setState({ formData: field });
  }

  fileChangedHandler(event, elename) {
    event.preventDefault();

    this.setState({ uploaded_file: elename });
    let reader = new FileReader();
    let file = event.target.files[0];

    if (elename === "logo_image") {
      reader.onloadend = () => {
        this.setState({ imagePreviewUrl: reader.result });
      };
    }
    if (elename === "background_image") {
      reader.onloadend = () => {
        this.setState({ imagePreviewUrlClinic: reader.result });
      };
    }
    if (elename === "contact_image") {
      reader.onloadend = () => {
        this.setState({ imagePreviewUrlContact: reader.result });
      };
    }
    if (elename === "doctor_image") {
      reader.onloadend = () => {
        this.setState({ imagePreviewUrlDoctor: reader.result });
      };
    }
    if (file) {
      this.props.uploadFileAction(file);
      reader.readAsDataURL(file);
    }
  }

  selectMultiData() {
    if (this.state.multiInput === false) this.setState({ multiInput: true });
    else this.setState({ multiInput: false });
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });

    let days = "";
    let dateArr = [];

    for (let i in selectedDays) {
      var s1 = [
        selectedDays[i].getDate(),
        selectedDays[i].getMonth() + 1,
        selectedDays[i].getFullYear(),
      ].join("-");
      if (i !== 0) days += "," + s1;
      else days += s1;

      let mytime = moment(new Date(selectedDays[i])).format("MM-DD-YYYY");
      dateArr.push(mytime);
    }

    let formData = this.state.formData;
    formData["holiday_date"] = dateArr;
    this.setState({ formData: formData });
  }

  goBackFun(e) {
    this.props.history.replace(sessionStorage.getItem("prvUrl"));
  }

  onResetTime(e, typ = "") {
    if (typ !== "" && this.state.formData) {
      var formdata = this.state.formData;

      if (
        typ === 1 &&
        formdata &&
        formdata.address &&
        formdata.address.morning_from
      ) {
        formdata.address.morning_from = "";
        formdata.address.morning_till = "";
        this.setState({ formdata: formdata });
      }

      if (
        typ === 2 &&
        formdata &&
        formdata.address &&
        formdata.address.evening_from
      ) {
        formdata.address.evening_from = "";
        formdata.address.evening_till = "";
        this.setState({ formdata: formdata });
      }

      if (typ === 1 || typ === 2) {
        this.setState({ start2Error: "" });
      }

      if (typ === 3 || typ === 4) {
        this.setState({ eveningFormError: "" });
      }

      if (
        typ === 3 &&
        formdata &&
        formdata.address2 &&
        formdata.address2.morning_from
      ) {
        formdata.address2.morning_from = "";
        formdata.address2.morning_till = "";
        this.setState({ formdata: formdata });
      }

      if (
        typ === 4 &&
        formdata &&
        formdata.address2 &&
        formdata.address2.evening_from
      ) {
        formdata.address2.evening_from = "";
        formdata.address2.evening_till = "";
        this.setState({ formdata: formdata });
      }
    }
  }

  render() {
    const {
      endTime1Error,
      endTime2Error,
      start1Error,
      start2Error,
      formData,
      morningTillError,
      morningFormError,
      eveningFormError,
      eveningTillError,
      validationError,
    } = this.state;
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();
    const { isLoading } = this.state;

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Form horizontal>
                <div
                  className="actionProcess"
                  style={{
                    display: this.state.showProcessing ? "block" : "none",
                  }}
                >
                  <img src={loadingImg} alt="Loading" width="40px" />
                  <center>Logging In - Please Wait</center>
                </div>
                <Card
                  title={
                    <legend>
                      General information
                      <Button
                        className="go-back-btn-2"
                        bsStyle="info"
                        onClick={(e) => {
                          this.goBackFun(e);
                        }}
                      >
                        Back
                      </Button>
                    </legend>
                  }
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Doctor Name <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="doctorName"
                            value={this.state.formData.doctorName}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Doctor Name"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.clinic_nameError}
                            {this.state.formArr.doctorName &&
                              validation.errors.first("doctorName")}
                          </span>
                        </Col>
                        {
                          <Col sm={3}>
                            <label className="control-label">
                              Specializations
                            </label>
                            <Select
                              placeholder="Select specializations"
                              autoComplete="off"
                              name="specializations"
                              id="specializations"
                              closeOnSelect={false}
                              multi={true}
                              value={this.state.specializations}
                              options={this.state.specializationList}
                              onChange={(value) => {
                                this.setState({ specializations: value });
                                this.handleSpecialization(value);
                              }}
                            />
                          </Col>
                        }
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Degree <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="degree"
                            value={this.state.formData.degree}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Degree"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.clinic_nameError}
                            {this.state.formArr.degree &&
                              validation.errors.first("degree")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            MCI Registration Number
                          </Col>
                          <FormControl
                            type="text"
                            name="mci_number"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.mci_number}
                            placeholder="MCI Registation Number"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.mci_numberError}
                            {this.state.formArr.mci_number &&
                              validation.errors.first("mci_number")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Website</Col>
                          <FormControl
                            type="text"
                            name="clinic_website"
                            onBlur={(e) => {
                              this.websiteValidation(e);
                            }}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Website"
                            value={this.state.formData.clinic_website}
                            autoComplete="off"
                          />

                          <span className="errorMsg">
                            {this.state.clinic_websiteError}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Clinic Name <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="clinic_name"
                            value={this.state.formData.clinic_name}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Clinic Name"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.clinic_nameError}
                            {this.state.formArr.clinic_name &&
                              validation.errors.first("clinic_name")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Doctor Email <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="email"
                            value={this.state.formData.email}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Doctor Email"
                            autoComplete="off"
                          />
                          <span className="errorMsg" refs="short_name">
                            {this.state.emailError}
                            {this.state.formArr.email &&
                              validation.errors.first("email")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Clinic Status</Col>
                          <FormControl
                            componentClass="select"
                            name="status"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </FormControl>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Patient can Email for Queries
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="email_facility"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </FormControl>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Pattern <span className="star">*</span>
                            {this.state.formData.pattern && (
                              <a
                                className="patternP"
                                onClick={() => this.openPatternModal()}
                              >
                                Preview{" "}
                              </a>
                            )}
                          </Col>
                          <Select
                            placeholder="Please Select Pattern"
                            autoComplete="off"
                            name="pattern"
                            value={this.state.formData.pattern}
                            key={this.state.formData.pattern}
                            options={this.state.patternList}
                            onChange={(e) => {
                              this.handlepatternChange(e);
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.patternError}
                            {this.state.formArr.pattern &&
                              validation.errors.first("pattern")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Chat</Col>
                          <FormControl
                            componentClass="select"
                            name="chat_facility"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </FormControl>
                          <small>
                            Note:- Do you need a chat facility with your
                            patient.
                          </small>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Diet Plan Decision by Educator
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="educatorDietStatus"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </FormControl>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Next Visit Notification
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="patientNotificationDay"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Print with Format"
                          >
                            <option value="1">1 day</option>
                            <option value="2">2 days</option>
                            <option value="3">3 days</option>
                            <option value="5">5 days</option>
                            <option value="7">7 days</option>
                            <option value="10">10 days</option>
                          </FormControl>
                          <small>
                            Note:- "How many days prior to next expected visit"
                            , a automatic message should go to patient to take
                            appointment with you.
                          </small>
                        </Col>
                        {/* <Col sm={6}>
                                                    <Col componentClass={ControlLabel}>
                                                        Description
                                                       </Col>
                                                    <FormControl rows="4" componentClass="textarea" name="description" bsClass="form-control" placeholder="Description" value={this.state.formData.description} onChange={e => { this.handleChange(e); }} />
                                                </Col> */}
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Insulin Calculator
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="insulinCalculator"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            value={this.state.formData.insulinCalculator}
                          >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </FormControl>
                        </Col>
                      </FormGroup>
                      <FormGroup className="relativeCss">
                        <Col sm={4}>
                          <Col componentClass={ControlLabel}>Clinic Logo</Col>
                          <Col componentClass={ControlLabel}>
                            {this.state.imagePreviewUrl && (
                              <img
                                src={this.state.imagePreviewUrl}
                                width="100px"
                                height="100px"
                                style={{ margin: "5px" }}
                              />
                            )}

                            <FormControl
                              type="file"
                              name="logo_image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) => {
                                this.fileChangedHandler(e, "logo_image");
                              }}
                            />
                            <span className="errorMsg">
                              {this.state.logo_imageError}
                            </span>
                          </Col>
                        </Col>

                        <Col sm={4}>
                          <Col componentClass={ControlLabel}>Clinic Image</Col>
                          <Col componentClass={ControlLabel}>
                            {this.state.imagePreviewUrlClinic && (
                              <img
                                src={this.state.imagePreviewUrlClinic}
                                width="100px"
                                height="100px"
                                style={{ margin: "5px" }}
                              />
                            )}
                            <FormControl
                              type="file"
                              name="background_image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) =>
                                this.fileChangedHandler(e, "background_image")
                              }
                            />
                            <span className="errorMsg">
                              {this.state.background_imageError}
                            </span>
                          </Col>
                        </Col>
                        <Col sm={4}>
                          <Col componentClass={ControlLabel}>Doctor Image</Col>
                          <Col componentClass={ControlLabel}>
                            {this.state.imagePreviewUrlDoctor && (
                              <img
                                src={this.state.imagePreviewUrlDoctor}
                                width="100px"
                                height="100px"
                                style={{ margin: "5px" }}
                              />
                            )}
                            <FormControl
                              type="file"
                              name="doctor_image"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) =>
                                this.fileChangedHandler(e, "doctor_image")
                              }
                            />
                            <span className="errorMsg">
                              {this.state.contact_imageError}
                            </span>
                          </Col>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />
                <Card
                  title={<legend>Contact Information </legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Address <span className="star">*</span>
                          </Col>
                          <FormControl
                            componentClass="textarea"
                            name="address"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.address
                                ? this.state.formData.address.address
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Address"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.addressError}
                            {this.state.formArr.address11 &&
                              validation.errors.first("address11")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            City <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="city"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.city
                                ? this.state.formData.address.city
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            onBlur={(e) => {
                              this.handlePatternCheck(e);
                            }}
                            placeholder="City"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.cityError}
                            {this.state.formArr.city &&
                              validation.errors.first("city")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            State <span className="star">*</span>
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="state"
                            onChange={(e) => {
                              this.handleAddressChange(e);
                              this.handleStateChange(e);
                            }}
                          >
                            <option value="">Please select</option>
                            {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </FormControl>
                          <span className="errorMsg">
                            {this.state.stateError}
                            {this.state.formArr.state &&
                              validation.errors.first("state")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Pin Code</Col>
                          <FormControl
                            type="text"
                            name="pin_code"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.pin_code
                                ? this.state.formData.address.pin_code
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            maxLength={8}
                            placeholder="Pin Code"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.pin_codeError}
                            {this.state.formArr.pin_code &&
                              validation.errors.first("pin_code")}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Latitude</Col>
                          <FormControl
                            type="text"
                            name="latitude"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.latitude
                                ? this.state.formData.address.latitude
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Latitude"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.latitudeError}
                            {this.state.formArr.latitude &&
                              validation.errors.first("latitude")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Longitude</Col>
                          <FormControl
                            type="text"
                            name="longitude"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.longitude
                                ? this.state.formData.address.longitude
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Longitude"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.longitudeError}
                            {this.state.formArr.longitude &&
                              validation.errors.first("longitude")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Clinic Email</Col>
                          <FormControl
                            type="text"
                            name="clinic_email"
                            value={this.state.formData.clinic_email}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Clinic Email"
                            autoComplete="off"
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Phone No. for Appointment{" "}
                            <span className="star">*</span>
                          </Col>
                          <FormControl
                            type="text"
                            name="phone"
                            maxLength={12}
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.phone
                                ? this.state.formData.address.phone
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Phone No. for Appointment"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.phoneError}
                            {this.state.formArr.phone &&
                              validation.errors.first("phone")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Phone No. for Clinic
                          </Col>
                          <FormControl
                            type="text"
                            name="mobile"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.mobile
                                ? this.state.formData.address.mobile
                                : ""
                            }
                            maxLength={12}
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Phone No. for Clinic"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.mobileError}
                            {this.state.formArr.mobile &&
                              validation.errors.first("mobile")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Whatsapp No.
                          </Col>
                          <FormControl
                            type="text"
                            name="appointmentno"
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.appointmentno
                                ? this.state.formData.address.appointmentno
                                : ""
                            }
                            maxLength={12}
                            onChange={(e) => {
                              this.handleAddressChange(e);
                            }}
                            placeholder="Whatsapp No."
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.appointmentnoError}
                            {this.state.formArr.appointmentno &&
                              validation.errors.first("appointmentno")}
                          </span>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />
                <Card
                  title={<legend>Diet Print Format</legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Print with Format
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="diet_print_format"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Print with Format"
                          >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </FormControl>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Clinic Name Font
                          </Col>
                          <Select
                            placeholder="Select Font"
                            name="clinic_name_font"
                            value={this.state.clinicFont}
                            key={this.state.clinicFont}
                            options={patternFontOptions}
                            disabled={
                              this.state.formData.diet_print_format === "no"
                            }
                            onChange={(e) => {
                              this.handleClinicFontChange(
                                e,
                                "clinic_name_font"
                              );
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.formArr.clinic_name_font &&
                              validation.errors.first("clinic_name_font")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Clinic Address Font
                          </Col>
                          <Select
                            placeholder="Select Font"
                            name="address_font"
                            value={this.state.addressFont}
                            key={this.state.addressFont}
                            options={patternFontOptions}
                            disabled={
                              this.state.formData.diet_print_format === "no"
                            }
                            onChange={(e) => {
                              this.handleAddressFontChange(e, "address_font");
                            }}
                          />
                          <span className="errorMsg">
                            {this.state.formArr.address_font &&
                              validation.errors.first("address_font")}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Margin Top</Col>
                          <FormControl
                            type="text"
                            name="margin_top"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Margin Top"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.margin_topError}
                            {this.state.formArr.margin_top &&
                              validation.errors.first("margin_top")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Margin Bottom</Col>
                          <FormControl
                            type="text"
                            name="margin_bottom"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                            placeholder="Margin Bottom"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.margin_bottomError}
                            {this.state.formArr.margin_bottom &&
                              validation.errors.first("margin_bottom")}
                          </span>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />

                <Card
                  title={<legend>Consulting Hours</legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Morning hour from
                          </Col>

                          <select
                            className="form-control"
                            name="morning_from"
                            onChange={(e) => {
                              this.handleChangeTime(e, "morning_from");
                            }}
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.morning_from
                                ? this.state.formData.address.morning_from
                                : ""
                            }
                          >
                            <option value="">Select Morning hour from</option>
                            {appTimeSlot.map(function (item) {
                              if (
                                moment(item.value, "h:mm a").format("x") >=
                                moment("6:00 AM", "h:mm a").format("x") &&
                                moment(item.value, "h:mm a").format("x") <=
                                moment("5:00 PM", "h:mm a").format("x")
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {start1Error}
                            {this.state.formArr.start_time &&
                              validation.errors.first("start_time")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Morning hour till
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime2(e, 'morning_till'); }}
                                                        dateFormat={false}
                                                        value={(this.state.formData.address && this.state.formData.address.morning_till?this.state.formData.address.morning_till:'')}
                                                        inputProps={{ placeholder: "Morning hour till", name: 'morning_till', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="morning_till"
                            onChange={(e) => {
                              this.handleChangeTime2(e, "morning_till");
                            }}
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.morning_till
                                ? this.state.formData.address.morning_till
                                : ""
                            }
                          >
                            <option value="">Select Morning hour till</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj["morning_from"]
                                ? newObj["morning_from"]
                                : "6:00 AM";
                              if (
                                moment(item.value, "h:mm a").format("x") >
                                moment(m1, "h:mm a").format("x") &&
                                moment(item.value, "h:mm a").format("x") <=
                                moment("5:00 PM", "h:mm a").format("x")
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {endTime1Error}
                            {this.state.formArr.end_time &&
                              validation.errors.first("end_time")}
                          </span>
                        </Col>

                        <Col sm={1}>
                          <br />
                          <br />
                          <Button
                            className="go-back-btn-2"
                            bsStyle="info"
                            onClick={(e) => {
                              this.onResetTime(e, 1);
                            }}
                          >
                            Clear
                          </Button>
                        </Col>
                        <Col sm={2}>
                          <Col componentClass={ControlLabel}>
                            Slot Time <span className="star">*</span>
                          </Col>
                          <FormControl
                            componentClass="select"
                            name="slot"
                            value={this.state.formData.slot}
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          >
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                            <option value="60">60</option>
                          </FormControl>
                          <span className="errorMsg">
                            {this.state.formArr.slot &&
                              validation.errors.first("slot")}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Evening hour from
                          </Col>

                          <select
                            className="form-control"
                            name="evening_from"
                            onChange={(e) => {
                              this.handleChangeTime3(e, "evening_from");
                            }}
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.evening_from
                                ? this.state.formData.address.evening_from
                                : ""
                            }
                          >
                            <option value="">Select Evening hour from</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj["morning_till"]
                                ? newObj["morning_till"]
                                : "1:00 PM";

                              if (
                                (moment(item.value, "h:mm a").format("x") >=
                                  moment(m1, "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") >=
                                  moment("1:00 PM", "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") <=
                                  moment("11:30 PM", "h:mm a").format("x")) ||
                                "12:00 AM" === item.value
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {start2Error}
                            {this.state.formArr.start_time2 &&
                              validation.errors.first("start_time2")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Evening hour till
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime4(e, 'evening_till'); }}
                                                        dateFormat={false}
                                                        value={(this.state.formData.address && this.state.formData.address.evening_till?this.state.formData.address.evening_till:'')}
                                                        inputProps={{ placeholder: "Evening hour till", name: 'evening_till', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="evening_till"
                            onChange={(e) => {
                              this.handleChangeTime4(e, "evening_till");
                            }}
                            value={
                              this.state.formData.address &&
                                this.state.formData.address.evening_till
                                ? this.state.formData.address.evening_till
                                : ""
                            }
                          >
                            <option value="">Select Evening hour till</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj["evening_from"]
                                ? newObj["evening_from"]
                                : "1:00 PM";

                              if (
                                (moment(item.value, "h:mm a").format("x") >
                                  moment(m1, "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") >=
                                  moment("1:00 PM", "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") <=
                                  moment("11:30 PM", "h:mm a").format("x")) ||
                                "12:00 AM" === item.value
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {endTime2Error}
                            {this.state.formArr.end_time2 &&
                              validation.errors.first("end_time2")}
                          </span>
                        </Col>
                        <Col sm={1}>
                          <br />
                          <br />
                          <Button
                            className="go-back-btn-2"
                            bsStyle="info"
                            onClick={(e) => {
                              this.onResetTime(e, 2);
                            }}
                          >
                            Clear
                          </Button>
                        </Col>
                        <Col sm={2}>
                          <Col componentClass={ControlLabel}>Week Off</Col>
                          <Select
                            placeholder="Select Day"
                            closeOnSelect={false}
                            multi={true}
                            name="week_off"
                            value={this.state.week_off}
                            key={this.state.week_off}
                            options={weekOptions}
                            onChange={(value) => {
                              this.setState({ week_off: value });
                              this.handleWeekOff(value);
                            }}
                          />
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Holiday Dates</Col>
                          <div ref={this.box}>
                            <FormControl
                              type="text"
                              readOnly
                              placeholder="Select Holiday Date"
                              name="holiday_date"
                              value={this.state.formData.holiday_date}
                              onClick={(e) => {
                                this.selectMultiData(e);
                              }}
                            />
                            <span className="errorMsg">
                              {this.state.formArr.holiday_date &&
                                validation.errors.first("holiday_date")}
                            </span>
                            <div
                              ref={this.holidayCalender}
                              className="multiDateP"
                              style={{
                                display: this.state.multiInput
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <DayPicker
                                selectedDays={this.state.selectedDays}
                                onDayClick={this.handleDayClick}
                              />
                            </div>
                          </div>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                />

                <Card
                  title={<legend>Clinic Information 2</legend>}
                  content={
                    <div>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Address</Col>
                          <FormControl
                            componentClass="textarea"
                            name="address"
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.address
                                ? this.state.formData.address2.address
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            placeholder="Address"
                            autoComplete="off"
                          />
                          <span className="errorMsg"></span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>City</Col>
                          <FormControl
                            type="text"
                            name="city"
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.city
                                ? this.state.formData.address2.city
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            placeholder="City"
                            autoComplete="off"
                          />
                          <span className="errorMsg"></span>
                        </Col>

                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>State</Col>
                          <FormControl
                            componentClass="select"
                            name="state"
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                              this.handleState2Change(e);
                            }}
                            autoComplete="off"
                          >
                            <option value="">Please select</option>
                            {this.state.stateList.map(function (item) {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </FormControl>
                          <span className="errorMsg"></span>
                        </Col>

                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>Pin Code</Col>
                          <FormControl
                            type="text"
                            name="pin_code"
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.pin_code
                                ? this.state.formData.address2.pin_code
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            maxLength={8}
                            placeholder="Pin Code"
                            autoComplete="off"
                          />
                          <span className="errorMsg"></span>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Morning hour from
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime(e, 'morning_from2'); }}
                                                        dateFormat={false}
                                                        viewDate={new Date(moment().startOf('day').add(8, 'hours').format('YYYY-MM-DD h:mm A'))}
                                                        value={(this.state.formData.address2 && this.state.formData.address2.morning_from?this.state.formData.address2.morning_from:'')}
                                                        inputProps={{ placeholder: "Morning hour from", name: 'morning_from2', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="morning_from2"
                            onChange={(e) => {
                              this.handleChangeTime(e, "morning_from2");
                            }}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.morning_from
                                ? this.state.formData.address2.morning_from
                                : ""
                            }
                          >
                            <option value="">Select Morning hour from</option>
                            {appTimeSlot.map(function (item) {
                              if (
                                moment(item.value, "h:mm a").format("x") >=
                                moment("6:00 AM", "h:mm a").format("x") &&
                                moment(item.value, "h:mm a").format("x") <=
                                moment("5:00 PM", "h:mm a").format("x")
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {morningFormError}
                            {this.state.formArr.start_time &&
                              validation.errors.first("start_time")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Morning hour till
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime2(e, 'morning_till2'); }}
                                                        dateFormat={false}
                                                        value={(this.state.formData.address2 && this.state.formData.address2.morning_till?this.state.formData.address2.morning_till:'')}
                                                        inputProps={{ placeholder: "Morning hour till", name: 'morning_till2', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="morning_till2"
                            onChange={(e) => {
                              this.handleChangeTime2(e, "morning_till2");
                            }}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.morning_till
                                ? this.state.formData.address2.morning_till
                                : ""
                            }
                          >
                            <option value="">Select Morning hour till</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj1["morning_from"]
                                ? newObj1["morning_from"]
                                : "6:00 AM";
                              if (
                                moment(item.value, "h:mm a").format("x") >
                                moment(m1, "h:mm a").format("x") &&
                                moment(item.value, "h:mm a").format("x") <=
                                moment("5:00 PM", "h:mm a").format("x")
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {morningTillError}
                            {this.state.formArr.end_time &&
                              validation.errors.first("end_time")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Button
                            className=""
                            bsStyle="info"
                            onClick={(e) => {
                              this.onResetTime(e, 3);
                            }}
                          >
                            Clear
                          </Button>
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Evening hour from
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime3(e, 'evening_from2'); }}
                                                        dateFormat={false}
                                                        viewDate={new Date(moment().startOf('day').add(13, 'hours').format('YYYY-MM-DD h:mm A'))}
                                                        value={(this.state.formData.address2 && this.state.formData.address2.evening_from?this.state.formData.address2.evening_from:'')}
                                                        inputProps={{ placeholder: "Evening hour from", name: 'evening_from2', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="evening_from2"
                            onChange={(e) => {
                              this.handleChangeTime3(e, "evening_from2");
                            }}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.evening_from
                                ? this.state.formData.address2.evening_from
                                : ""
                            }
                          >
                            <option value="">Select Evening hour from</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj1["morning_till"]
                                ? newObj1["morning_till"]
                                : "1:00 PM";
                              if (
                                (moment(item.value, "h:mm a").format("x") >=
                                  moment(m1, "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") >=
                                  moment("1:00 PM", "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") <=
                                  moment("11:30 PM", "h:mm a").format("x")) ||
                                "12:00 AM" === item.value
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {eveningFormError}
                            {this.state.formArr.start_time2 &&
                              validation.errors.first("start_time2")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col componentClass={ControlLabel}>
                            Evening hour till
                          </Col>
                          {/*<Datetime onChange={e => { this.handleChangeTime4(e, 'evening_till2'); }}
                                                        dateFormat={false}
                                                        value={(this.state.formData.address2 && this.state.formData.address2.evening_till?this.state.formData.address2.evening_till:'')}
                                                        inputProps={{ placeholder: "Evening hour till", name: 'evening_till2', readOnly: true }}
                                                    />*/}
                          <select
                            className="form-control"
                            name="evening_till2"
                            onChange={(e) => {
                              this.handleChangeTime4(e, "evening_till2");
                            }}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.evening_till
                                ? this.state.formData.address2.evening_till
                                : ""
                            }
                          >
                            <option value="">Select Evening hour till</option>
                            {appTimeSlot.map(function (item) {
                              let m1 = newObj1["evening_from"]
                                ? newObj1["evening_from"]
                                : "1:00 PM";
                              if (
                                (moment(item.value, "h:mm a").format("x") >
                                  moment(m1, "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") >
                                  moment("1:00 PM", "h:mm a").format("x") &&
                                  moment(item.value, "h:mm a").format("x") <=
                                  moment("11:30 PM", "h:mm a").format("x")) ||
                                "12:00 AM" === item.value
                              ) {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          <span className="errorMsg">
                            {eveningTillError}
                            {this.state.formArr.end_time2 &&
                              validation.errors.first("end_time2")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Button
                            className=""
                            bsStyle="info"
                            onClick={(e) => {
                              this.onResetTime(e, 4);
                            }}
                          >
                            Clear
                          </Button>
                        </Col>
                      </FormGroup>

                      <FormGroup>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Phone No. for Appointment
                          </Col>
                          <FormControl
                            type="text"
                            name="phone"
                            maxLength={12}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.phone
                                ? this.state.formData.address2.phone
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            placeholder="Phone No. for Appointment"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.phoneError1}
                            {this.state.formArr.phone22 &&
                              validation.errors.first("phone22")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Phone No. for Clinic
                          </Col>
                          <FormControl
                            type="text"
                            name="mobile"
                            maxLength={12}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.mobile
                                ? this.state.formData.address2.mobile
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            placeholder="Phone No. for Clinic"
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.mobileError1}
                            {this.state.formArr.mobile22 &&
                              validation.errors.first("mobile22")}
                          </span>
                        </Col>
                        <Col sm={3}>
                          <Col
                            className="inline-g"
                            componentClass={ControlLabel}
                          >
                            Whatsapp No.
                          </Col>
                          <FormControl
                            type="text"
                            name="appointmentno"
                            maxLength={12}
                            value={
                              this.state.formData.address2 &&
                                this.state.formData.address2.appointmentno
                                ? this.state.formData.address2.appointmentno
                                : ""
                            }
                            onChange={(e) => {
                              this.handleAddress2Change(e);
                            }}
                            placeholder="Whatsapp No."
                            autoComplete="off"
                          />
                          <span className="errorMsg">
                            {this.state.appointmentnoError1}
                            {this.state.formArr.appointmentno22 &&
                              validation.errors.first("appointmentno22")}
                          </span>
                        </Col>
                      </FormGroup>
                    </div>
                  }
                  ftTextCenter
                  legend={
                    <div>
                      <Button
                        fill
                        bsStyle="info"
                        type="button"
                        onClick={this.addClinic.bind(this)}
                        disabled={
                          isLoading || this.props.disabled ||
                          endTime1Error ||
                          endTime2Error ||
                          start1Error ||
                          start2Error ||
                          eveningFormError ||
                          validationError ||
                          this.state.saveBtnClicked
                        }
                      >
                        {isLoading ? (
                          <span class="fa fa-spinner fa-spin spinner-btn" role="status" aria-hidden="true"></span>
                        ) : (
                          this.props.children
                        )}
                        Submit
                        {this.state.saveBtnClicked && (
                          <i class="fa fa-spinner fa-spin"></i>
                        )}
                      </Button>
                      &nbsp;
                    </div>
                  }
                />
              </Form>
            </Col>
          </Row>
        </Grid>

        <Modal
          className="pa-patient-registration"
          show={this.state.previewModal}
          onHide={() => this.setState({ previewModal: false })}
          dialogClassName="modal-md"
        >
          <Modal.Header closeButton className="Patient-headers">
            <Modal.Title id="example-modal-sizes-title-lg">
              Pattern Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share card">
            <Row>
              <Col md={12}>
                <PatternPreview
                  onDismiss={this.onDismiss}
                  pattern={this.state.formData.pattern}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    stateList: state.master.stateList,
    isStateList: state.master.isStateList,
    isStateListError: state.master.isStateListError,

    countryList: state.master.countryList,
    isCountryList: state.master.isCountryList,
    isCountryListError: state.master.isCountryListError,

    patternList: state.master.patternList,
    isPatternList: state.master.isPatternList,
    isPatternListError: state.master.isPatternListError,

    SpecializationList: state.specilization.SpecializationList,
    isSpecializationList: state.specilization.isSpecializationList,
    isSpecializationListError: state.specilization.isSpecializationListError,

    isAddClinic: state.clinic.isAddClinic,
    isAddClinicError: state.clinic.isAddClinicError,
    addClinicResponse: state.clinic.addClinicResponse,

    isUploadFile: state.master.isUploadFile,
    isUploadFileError: state.master.isUploadFileError,
    uploadFile: state.master.uploadFile,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    countryListAction,
    stateListAction,
    addClinicAction,
    specializationListAction,
    uploadFileAction,
    patternListAction,
  })(AddClinic)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
//import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
//import { addTreatmentAction } from 'Admin/actions/treatment';
import { updateTreatmentAction } from 'Admin/actions/treatment';
import { treatmentTypeListAction } from 'Admin/actions/treatment_type';
import { dietTimePlanListAction } from 'Admin/actions/diet_time';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { appConstants } from 'Admin/_constants/app.constants';

let Validator = require('validatorjs');
let formArr = {}
let rules = {
    name: 'required',
    treatmentTypeId: 'required',
    instruction: 'required',

};
let mess = {
    required: 'This field is required.',
    // calrequired:'Put total of carbohydrate, protein and fats calories in Calories field.'
};
let validation = [];
validation = new Validator({}, rules, mess);
validation.passes();
validation.fails();

class UpdateTreatment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treatmentTypeList: [],
            dietTimeList: [],
            dietTimeData: [],
            cardHidden: true,
            formArr: [],
            treatmentTypeError: null,
            instructionError: null,
            nameError: null,

            formData: {
                name: this.props.location.state.row.name,
                treatmentTypeId: this.props.location.state.row.treatmentTypeId,
                instruction: this.props.location.state.row.instruction,
            },
            isLogin: true,
            showProcessing: false,
            limitForm: {},
        }


    }

    componentDidMount() {
        let data = this.state.limitForm;
        data['limitStatus'] = true;
        this.props.treatmentTypeListAction(data);
        let list = {
            direction: 'asc',
            order: "orderBy",
            offset: 0,
            limit: 10,
            treatmentId: this.props.location.state.row._id

        }
        this.props.dietTimePlanListAction(list)

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isTreatmentTypeList !== this.props.isTreatmentTypeList) {

            this.setState({
                treatmentTypeList: nextProps.TreatmentTypeList.data.data,
                totalCount: nextProps.TreatmentTypeList.data.count
            });
        }



        if (nextProps.isDietTimePlanList !== this.props.isDietTimePlanList) {

            let dietTimeList2 = [];
            for (let i in nextProps.DietTimePlanList.data.data) {
                let value = {};
                value.percent = nextProps.DietTimePlanList.data.data[i].dietPlanData[0].percent;;
                value.time = nextProps.DietTimePlanList.data.data[i].dietPlanData[0].time;
                value.optional = nextProps.DietTimePlanList.data.data[i].dietPlanData[0].optional;
                value.dietTimeId = nextProps.DietTimePlanList.data.data[i].dietPlanData[0].dietTimeId;
                value.name = nextProps.DietTimePlanList.data.data[i].name;
                dietTimeList2.push(value);
                //this.state.dietTimeData.push(value);

            }
            this.setState({
                dietTimeData: dietTimeList2,
                dietTimeList: nextProps.DietTimePlanList.data.data,
            });
        }



        if (nextProps.isUpdateTreatmentError !== this.props.isUpdateTreatmentError) {
            if (nextProps.msg.errors) {
                nextProps.msg.errors.map((key, i) => {
                    this.setState({ [(key.param) + "Error"]: <small className="text-danger">{key.msg}</small> })
                });
            }
        }
        if (nextProps.isUpdateTreatment !== this.props.isUpdateTreatment) {
            this.props.handleClick('success', nextProps.UpdateTreatment.msg)
            this.props.history.push('/admin/treatment')
        }
    }



    handleChange(e) {
        e.preventDefault();
        let field = this.state.formData;
        //field.text[language] = e.target.value;
        field[e.target.name] = e.target.value;
        this.setState({ formData: field });
    }


    allValidate(check) {
        if (!check) {
            formArr = []
            Object.keys(rules).forEach(function (key) {
                formArr[key] = "TT";
            });
            this.setState({
                formArr
            });
        }
        if (validation.passes()) {
            return 1;
        }
    }

    addTreatment(evt) {
        evt.preventDefault();
        const _this = this;

        if (this.allValidate(false)) {
            let treatment_data = {}
            treatment_data = this.state.formData;
            treatment_data.dietTime = this.state.dietTimeData;
            treatment_data.id = this.props.location.state.row._id
            _this.setState({ showProcessing: true });
            _this.props.updateTreatmentAction(treatment_data);
        }
        //validation.errors()
    }
    handleName(cell, row, enumObject, rowIndex) {
        return row.name['1'] + " :-"
    }

    handleTime(cell, row, enumObject, rowIndex) {

        return (<FormControl componentClass="select" name="time" id="time" value={row.time} onChange={e => { this.handleChangeDietValue(e, rowIndex); }}>
            {appConstants.time().map(function (item) {
                return <option value={item}>{item}</option>
            })}
        </FormControl>)
    }


    handlePercent(cell, row, enumObject, rowIndex) {
        return (<FormControl type="text" name="percent" placeholder="Diet in %" id={"percent_" + row.dietTimeId} value={row.percent} onChange={e => { this.handleChangeDietValue(e, rowIndex); }} />)
    }

    handleOptional(cell, row, enumObject, rowIndex) {
        return (<input type="checkbox" name="optional" id={"optional_" + row.dietTimeId} onChange={(event) => { this.handleChangeCheckbox(row.optional, rowIndex); }} defaultChecked={row.optional} />)
    }

    handleChangeCheckbox(optional, index) {
        if (optional) {
            optional = false;
        } else {
            optional = true;
        }
        let field = this.state.dietTimeData;
        field[index]['optional'] = optional;
        this.setState({ dietTimeData: field });

    }


    handleChangeDietValue(e, index) {

        e.preventDefault();
        let field = this.state.dietTimeData;
        field[index][e.target.name] = e.target.value;
        this.setState({ dietTimeData: field });

    }


    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    render() {

        validation = new Validator(this.state.formData, rules, mess);
        validation.passes();
        validation.fails();

        let _this = this;
        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<legend className="line-removes">
                                    <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { this.goBackFun(e) }}>Back</Button>
                                </legend>}
                                content={
                                    <Form horizontal>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Sub Treatment Name <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="name" id="name" placeholder=" Sub Treatment Name" value={this.state.formData.name} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.nameError}
                                                    {this.state.formArr.name && validation.errors.first('name')}</span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Treatment  <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <select className="form-control" name="treatmentTypeId" value={this.state.formData.treatmentTypeId} onChange={(event) => this.handleChange(event)}>
                                                    <option value="">Select Treatment Type</option>
                                                    {this.state.treatmentTypeList.map(function (item) {
                                                        return <option key={item._id} value={item._id}>{item.name}</option>
                                                    })}
                                                </select>

                                                <span className="errorMsg">
                                                    {this.state.treatmentTypeError}
                                                    {this.state.formArr.treatmentTypeId && validation.errors.first('treatmentTypeId')}</span>
                                            </Col>
                                        </FormGroup>

                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                                                Description <span className="star">*</span>
                                            </Col>
                                            <Col sm={6}>
                                                <FormControl type="text" name="instruction" id="instruction" placeholder="Instruction" value={this.state.formData.instruction} onChange={e => { this.handleChange(e); }} />
                                                <span className="errorMsg">
                                                    {this.state.instructionError}
                                                    {this.state.formArr.instruction && validation.errors.first('instruction')}</span>
                                            </Col>
                                        </FormGroup>
                                        <div className="fresh-datatables">
                                            <BootstrapTable
                                                selectRow="{selectRowProp}"
                                                // deleteRow={false}
                                                data={this.state.dietTimeData}
                                            // multiColumnSearch={true}
                                            // pagination={true}

                                            // striped
                                            // hover
                                            // condensed
                                            // scrollTop={'Bottom'}
                                            // remote={true}
                                            // fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='invitationId' dataSort={true} isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'name' }} dataField='name' dataFormat={this.handleName.bind(this)} >Name</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'name' }} dataField='dietTimeId' dataFormat={this.handlePercent.bind(this)} >Percent Calorie</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%' }} tdStyle={{ width: '15%' }} tdAttr={{ 'data-attr': 'name' }} dataField='dietTimeId' dataFormat={this.handleTime.bind(this)} >Time</TableHeaderColumn>
                                                {/* <TableHeaderColumn thStyle={{ width: '10%' }} tdStyle={{ width: '10%' }} tdAttr={{ 'data-attr': 'name' }} dataField='dietTimeId' dataFormat={this.handleOptional.bind(this)} >Option</TableHeaderColumn> */}
                                            </BootstrapTable>
                                        </div>
                                        <FormGroup>
                                            <Col componentClass={ControlLabel} sm={2} smOffset={2}></Col>
                                            <Col sm={6}>
                                                <button type="button" onClick={this.addTreatment.bind(this)} className="btn-fill btn-wd btn btn-info">Save</button>&nbsp;
                                            </Col>
                                        </FormGroup>


                                    </Form>


                                }
                            />

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {
        msg: state.treatment.message,
        UpdateTreatment: state.treatment.UpdateTreatment,
        isUpdateTreatment: state.treatment.isUpdateTreatment,
        isUpdateTreatmentError: state.treatment.isUpdateTreatmentError,

        TreatmentTypeList: state.treatmentType.TreatmentTypeList,
        isTreatmentTypeList: state.treatmentType.isTreatmentTypeList,
        isTreatmentTypeListError: state.treatmentType.isTreatmentTypeListError,

        DietTimePlanList: state.dietTime.DietTimePlanList,
        isDietTimePlanList: state.dietTime.isDietTimePlanList,
        isDietTimePlanListError: state.dietTime.isDietTimePlanListError,

    }
}
export default withRouter(connect(mapStateToProps, { updateTreatmentAction, treatmentTypeListAction, dietTimePlanListAction })(UpdateTreatment));

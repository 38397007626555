/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
  response: [],
  message: '',
  isAddClinicDocument: false,
  isAddClinicDocumentError: false,
  AddClinicDocument: false,

  isUpdateClinicDocument: false,
  isUpdateClinicDocumentError: false,
  UpdateClinicDocument: false,

  isClinicDocumentChangeStatus: false,
  isClinicDocumentChangeStatusError: false,
  ClinicDocumentChangeStatus: false,

  isClinicDocumentList: false,
  isClinicDocumentListError: false,
  ClinicDocumentList: false,
}

export default (state = initState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_CLINIC_DOCUMENT_REQUEST:
      return {
        ...state,
        message: '',
        isisAddClinicDocumen: false,
        isisAddClinicDocumenError: false,
      }
    case actionTypes.ADD_CLINIC_DOCUMENT_SUCCESS:
      return {
        ...state,
        message: 'Data Added Successfully',
        isAddClinicDocumData: action.payload.response,
        isAddClinicDocumen: true,
        isAddClinicDocumenError: false,
      }
    case actionTypes.ADD_CLINIC_DOCUMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isAddClinicDocumen: false,
        isAddClinicDocumenError: true
      }

    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_REQUEST:
      return {
        ...state,
        message: '',
        isUpdateClinicDocument: false,
        isUpdateClinicDocumenError: false,
      }
    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_SUCCESS:
      return {
        ...state,
        message: 'Data Updated Successfully',
        isUpdateSpeciliData: action.payload.response,
        isUpdateClinicDocument: true,
        isUpdateClinicDocumenError: false,
      }
    case actionTypes.GET_UPDATE_CLINIC_DOCUMENT_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isUpdateSClinicDocument: false,
        isUpdateClinicDocumenError: true
      }

    case actionTypes.GET_CLINIC_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        message: '',
        isClinicDocumentList: false,
        isClinicDocumentListError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        message: 'Specialization List',
        ClinicDocumentList: action.payload.response,
        isClinicDocumentList: true,
        isClinicDocumentListError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicDocumentList: false,
        isClinicDocumentListError: true
      }

    case actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        message: '',
        isClinicDocumentChangeStatus: false,
        isClinicDocumentChangeStatusError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Clinic Document List',
        ClinicDocumentChangeStatus: action.payload.response,
        isClinicDocumentChangeStatus: true,
        isClinicDocumentChangeStatusError: false,
      }
    case actionTypes.GET_CLINIC_DOCUMENT_CHANGE_STATUS_ERROR:
      return {
        ...state,
        response: action.payload.error,
        message: action.payload.error,
        isClinicDocumentChangeStatus: false,
        isClinicDocumentChangeStatusError: true
      }


    default:
      return state
  }
}

/********* Show list of CLINIC_DOCUMENT */

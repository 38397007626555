/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/


import React, { Component } from 'react';
import { Grid, Row, Col, FormControl, ControlLabel, FormGroup, Form, Button } from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Card from 'Admin/components/Card/Card.jsx';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-loading';
import { dataUploadReportAction } from 'Admin/actions/report';
import { CSVLink } from 'react-csv';
import icons8 from 'Front/assets/img/download.png';
class UploadData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportList: [],
            headers: [
                { label: 'CLINIC ID', key: 'clinicId' },
                { label: 'DOCTOR NAME', key: 'doctorName' },
                { label: 'REGISTRATION DATE', key: 'createdAt' },
                { label: 'CLINIC NAME', key: 'name' },
                { label: 'DOCUMENTS/VIDEO', key: 'knowledgeDocumentCount' },
                { label: 'EDUCATOR MESSAGE', key: 'educatorMessagesCount' },
                { label: 'MY CLINIC', key: 'linkCount' },

            ],

            isLoading: true,
            asc_desc: true,
            userRow: null,
            totalCount: 0,
            currentPage: 1,
            sizePerPage: 10,
            searchText: "",
            formData: {
                direction: 'desc',
                order: "createdAt",
                offset: 0,
                limit: 10,
                start_date: '',
                end_date: '',
            },
            limitForm: {},
            startDate: '',
            endDate: ''
        }

        this.onPageChange = this.onPageChange.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onUserCount = this.onUserCount.bind(this);
        this.handleStartDate = this.handleStartDate.bind(this);
        this.handleEndDate = this.handleEndDate.bind(this);
        // this.dateFilter = this.dateFilter.bind(this);
        this.formatDataForCSV = this.formatDataForCSV.bind(this);
    }

    componentDidMount() {
        this.props.dataUploadReportAction(this.state.formData);
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.isUploadReport !== this.props.isUploadReport) {
            this.setState({
                reportList: nextProps?.uploadReport?.data && nextProps?.uploadReport?.data?.data?.length ? nextProps?.uploadReport?.data?.data : [],
                totalCount: nextProps?.uploadReport?.data && nextProps?.uploadReport?.data?.count ? nextProps?.uploadReport?.data?.count : []
            });
        }

        if (this.state.isLoading == true) {
            this.setState({ isLoading: false });
        }

    }

    handleStartDate(date) {
        let field = this.state.formData;
        field['start_date'] = moment(date).format('YYYY-MM-DD');
        this.setState({ formData: field, startDate: moment(date).format('DD-MM-YYYY') });
    }

    handleEndDate(date) {

        let field = this.state.formData;
        field['end_date'] = moment(date).format('YYYY-MM-DD');
        this.setState({ formData: field, endDate: moment(date).format('DD-MM-YYYY') });
    }

    dateFilter(isClear) {
        let payLoad = { ...this.state.formData, start_date: isClear ? "" : moment(this.state.formData.start_date).format('YYYY-MM-DD'), end_date: isClear ? "" : moment(this.state.formData.end_date).format('YYYY-MM-DD') }
        this.setState({ formData: payLoad, startDate: isClear ? "" : this.state.startDate, endDate: isClear ? "" : this.state.endDate })
        this.props.dataUploadReportAction(payLoad);
    }

    valid2(current, state) {
        let today = moment().format('YYYY-MM-DD');
        current = moment(current).format('YYYY-MM-DD');
        let startDate = moment(state.formData.start_date).format('YYYY-MM-DD');
        return today >= current && startDate <= current;
    }

    onPageChange(page, sizePerPage) {
        this.setState({ isLoading: true });
        let data = {};
        if (this.state.searchText === "") {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
        } else {
            data['direction'] = this.state.formData.direction;
            data['order'] = this.state.formData.order;
            data['offset'] = (page === -1) ? this.state.formData.offset : page - 1;
            data['limit'] = sizePerPage;
            data['start_date'] = this.state.formData.start_date;
            data['end_date'] = this.state.formData.end_date;
        }

        this.setState({ sizePerPage: sizePerPage })
        this.setState({ currentPage: page })
        this.props.dataUploadReportAction(data)

        if (this.state.sizePerPage !== sizePerPage || this.state.totalCount < page * sizePerPage) {
            eval('$(".ps").scrollTop(0)');
        }
    }

    onSearchChange(text) {
        if (text !== "") {
            if (text.length >= 1) {
                let data = {}
                data['direction'] = this.state.formData.direction;
                data['order'] = this.state.formData.order;
                data['offset'] = this.state.formData.offset;
                data['limit'] = this.state.formData.limit;
                data['search'] = text;
                data['start_date'] = this.state.formData.start_date;
                data['end_date'] = this.state.formData.end_date;
                this.setState({ sizePerPage: 10 })
                this.setState({ currentPage: 1 })
                this.props.dataUploadReportAction(data);

            }
        } else {
            setTimeout(() => {
                this.componentDidMount();
            }, 100);
        }
        this.setState({ searchText: text });
    }

    sortHandle() {
        this.setState({ asc_desc: !this.state.asc_desc });
        let data = this.state.formData;
        data.direction = this.state.asc_desc ? "asc" : "desc"
        this.setState({ formData: data });
        this.props.dataUploadReportAction(data);
    }

    onUserCount(cell, row, enumObject, rowIndex) {
        return (this.state.currentPage - 1) * (this.state.sizePerPage) + (rowIndex + 1);
    }

    _setTableOption() {
        if (this.state.isLoading) {
            return (<Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return (<Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        }
    }

    goBackFun(e) {
        this.props.history.replace(sessionStorage.getItem("prvUrl"))
    }

    formatDataForCSV = () => {
        const { reportList } = this.state;
        if (!reportList || reportList.length === 0) {
            return []; // Return an empty array if apiData is undefined or empty
        }
        return reportList.map(item => ({
            ...item,
            createdAt: moment(item?.createdAt).format('YYYY-MM-DD')
        }));
    };


    render() {

        const selectRowProp = { mode: 'checkbox' };
        const options = {
            onSortChange: this.onSortChange,
            clearSearch: true,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom',  // default is bottom, top and both is all available
            sizePerPage: this.state.sizePerPage,
            onPageChange: this.onPageChange,
            page: this.state.currentPage,
            // onSearchChange: this.onSearchChange,
            onSizePerPageList: this.sizePerPageListChange,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            },
            {
                text: '30', value: 30
            },
            {
                text: '50', value: 50
            },
            {
                text: 'All', value: 1000000000
            }],
            noDataText: this._setTableOption(),
        };

        const getDrugCreationDate = (cell, row, enumObject, rowIndex) => {
            return <span>{moment(row.createdAt).format("DD-MMM-YYYY")}</span>;
        };

        const { headers } = this.state;



        return (
            <div className="main-content" style={{ padding: '15px 0px' }}>
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <div className="fresh-datatables upload-data">
                                        <Row>
                                            <Col>

                                                <FormGroup>
                                                    <Col className="spc-mangess" componentClass={ControlLabel} sm={1}>
                                                        From
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Datetime
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            inputProps={{ placeholder: "DD-MM-YYYY" }}
                                                            maxDate={new Date()}
                                                            name={"from_date"}
                                                            value={this.state.startDate}
                                                            onChange={this.handleStartDate}
                                                            isValidDate={this.valid}
                                                            key={'startDate' + this.state.startDate}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Col className="spc-mangess" componentClass={ControlLabel} sm={1}>
                                                        To
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Datetime
                                                            timeFormat={false}
                                                            dateFormat={"DD-MM-YYYY"}
                                                            inputProps={{ placeholder: "DD-MM-YYYY" }}
                                                            maxDate={new Date()}
                                                            name={"to_date"}
                                                            value={this.state.endDate}
                                                            onChange={this.handleEndDate}
                                                            isValidDate={e => this.valid2(e, this.state)}
                                                            key={'endDate' + this.state.endDate}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className='search-clear-btn' >
                                                    <Col sm={2}>
                                                        <button type="button" onClick={this.dateFilter.bind(this, false)} className="btn-fill btn-wd btn btn-primary spc-treport">Search</button>
                                                    </Col>
                                                    <Col sm={2}>
                                                        <button type="button" onClick={this.dateFilter.bind(this, true)} className="btn-fill btn-wd btn spc-treport">Clear</button>
                                                    </Col>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <div className="table-resposive educator-L clinic-uploads-table">
                                            <div className='search-box-admin clinic-uploads-search'>
                                                <div className='search'>
                                                    <input type="text" name="search" className="form-control" onChange={e => { this.onSearchChange(e.target.value.trimStart().replace(/\s+/g, " ")); }} value={this.state.searchText} placeholder="Search" />
                                                    <span className='input-group-btn'>
                                                        <Button onClick={(e) => { this.onSearchChange(e.target.value, "null") }}>Clear</Button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className='download-btn'>
                                                <CSVLink
                                                    data={this.formatDataForCSV()}
                                                    headers={headers}
                                                    target="_blank"
                                                    filename={'Upload_Report.csv'}>

                                                    Download < img src={icons8} />
                                                </CSVLink>
                                            </div>

                                            <BootstrapTable
                                                deleteRow={false}
                                                multiColumnSearch={true}
                                                data={this.state.reportList}
                                                // search={true}
                                                selectRow="{selectRowProp}"
                                                pagination={true}
                                                options={options}
                                                striped hover condensed scrollTop={'Bottom'}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: this.state.totalCount }}
                                                sort={true}
                                            >
                                                <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': 'clinicId' }} dataField='clinicId' isKey searchable={false}>Id</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '3%', textAlign: 'center' }} tdStyle={{ width: '3%', textAlign: 'center' }} tdAttr={{ 'data-attr': '#' }} dataFormat={this.onUserCount}>#</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '7%', textAlign: 'center' }} tdStyle={{ width: '7%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'CLINIC ID' }} dataField='clinicId' >CLINIC ID</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'doctorName' }} dataField='doctorName' >Doctor Name
                                                    {
                                                        this.state.asc_desc ?
                                                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                                                            <i onClick={(e) => { this.sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                                                    }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn tdAttr={{ "data-attr": "Registration date" }} thStyle={{ width: "10%", textAlign: "center" }} tdStyle={{ width: "10%", textAlign: "center" }} dataField="createdAt" dataFormat={getDrugCreationDate} >Registration date</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '15%', textAlign: 'center' }} tdStyle={{ width: '15%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'CLINIC NAME' }} dataField='name' >CLINIC NAME</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': ' DOCUMENTS/VIDEO' }} dataField='knowledgeDocumentCount' >DOCUMENTS/VIDEO</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '10%', textAlign: 'center' }} tdStyle={{ width: '10%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'EDUCATOR MESSAGE' }} dataField='educatorMessagesCount' >EDUCATOR MESSAGE</TableHeaderColumn>
                                                <TableHeaderColumn thStyle={{ width: '8%', textAlign: 'center' }} tdStyle={{ width: '8%', textAlign: 'center' }} tdAttr={{ 'data-attr': 'MY CLINIC' }} dataField='linkCount'>MY CLINIC</TableHeaderColumn>



                                            </BootstrapTable>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {

    return {

        uploadReport: state.report.uploadReport,
        isUploadReport: state.report.isUploadReport,
        isUploadReportError: state.report.isUploadReportError,

    }
}
export default withRouter(connect(mapStateToProps, { dataUploadReportAction })(UploadData));

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useRef, useEffect } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import * as API from "Front/api/home";

const LabelAndStarStatus = () => {
  const [aTagList, setATagList] = useState([]);
  const [bTagList, setBTagList] = useState([]);
  const [cTagList, setCtagList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [starStatusList, setStarStatusList] = useState([
    { title: "*", desc: "Economy" },
    { title: "**", desc: "Average" },
    { title: "***", desc: "Affording" },
    { title: "*****", desc: "VIP" },
    { title: "#", desc: "Relative" },
    { title: "@", desc: "FOC" },
  ]);
  const getTagList = async () => {
    let res = await API.tagList();
    setTagList([...res.data.data]);
    let payload = [];
    for (let i in res.data.data) {
      let obj = { filePath: res.data.data[i].image, id: res.data.data[i]._id };
      payload.push(obj);
    }
    if (payload.length > 0)
      getS3SignedUrlImgArray({ imageArray: payload }, res.data.data);
  };

  const getStarStatusList = async () => {
    let res = await API.starStatusList();
    setStarStatusList([...res.data.starStatusList.data]);
  };

  const getS3SignedUrlImgArray = async (data, tagList) => {
    let res = await API.s3SignedUrlArray(data);

    let tempTagList = [];
    for (var i in tagList) {
      for (var j in res.data.data) {
        if (tagList[i]._id === res.data.data[j].id) {
          let obj = {
            tagName: tagList[i].title,
            tagId: tagList[i]._id,
            s3Path: res.data.data[j].s3Path,
            desc: tagList[i].description,
            type: tagList[i].type,
          };
          tempTagList.push(obj);
        }
      }
    }
    setTagList([...tempTagList]);
    setATagList([...tempTagList.filter((obj) => obj.type === "a")]);
    setBTagList([...tempTagList.filter((obj) => obj.type === "b")]);
    setCtagList([...tempTagList.filter((obj) => obj.type === "c")]);
  };

  useEffect(() => {
    getTagList();
    //getStarStatusList()
  }, []);

  return (
    <div className="color-notations">
      <div className="flex-box labels-wrapper">
        <Row>
          <Col lg={2} md={3} xs={12}>
            <div className="star-status-card">
              <div className="notation-header notations-title">Star Status</div>
              <div className="notation-card">
                {starStatusList &&
                  starStatusList.map((ele, key) => {
                    return (
                      <div className="star-status">
                        <label>{ele.title}</label>
                        <span style={{ marginLeft: "5px" }}>{ele.desc}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col lg={10} md={9} xs={12}>
            <div className="notation-header notations-title">Labels</div>
            <Row>
              <Col xs={12}>
                <div className="notation-card">
                  <span
                    className="notation-list notation-list-title"
                    style={{ display: "flex", fontSize: "14px", flex: '100%' }}
                  >
                    <strong>Permanent</strong>
                  </span>
                  {/* <br /> */}
                  {aTagList &&
                    aTagList.map((ele, key) => {
                      return (
                        <div
                          className="notation-list"
                          style={{ marginBottom: "10px" }}
                        >
                          <img src={ele.s3Path} title={ele.title} width="20" height="20"></img>
                          <span> {ele.desc}</span>
                          {/* <br /> */}
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Col xs={12}>
                <div className="notation-card card2">
                  <span
                    className="notation-list notation-list-title"
                    style={{ display: "flex", fontSize: "14px", flex: '100%' }}
                  >
                    <strong>Valid Till Next Visit</strong>
                  </span>
                  {/* <br /> */}
                  {bTagList &&
                    bTagList.map((ele, key) => {
                      return (
                        <div
                          className="notation-list"
                          style={{ marginBottom: "10px" }}
                        >
                          <img src={ele.s3Path} title={ele.title} width="20" height="20"></img>
                          <span> {ele.desc}</span>
                          {/* <br /> */}
                        </div>
                      );
                    })}
                </div>
              </Col>
              <Col xs={12}>
                <div className="notation-card card3">
                  <span
                    className="notation-list notation-list-title"
                    style={{ display: "flex", fontSize: "14px", flex: '100%' }}
                  >
                    <strong>Valid For 3 Days</strong>
                  </span>
                  {/* <br /> */}
                  {cTagList &&
                    cTagList.map((ele, key) => {
                      return (
                        <div
                          className="notation-list"
                          style={{ marginBottom: "10px" }}
                        >
                          <img src={ele.s3Path} title={ele.title} width="20" height="20"></img>
                          <span> {ele.desc}</span>
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div >
    </div>
  );
};

export default LabelAndStarStatus;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import * as API from '../../api/clinic';
import { Row, Col, FormControl, Grid } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";


const ClinicPerformance = () => {
  const [formData, setFormData] = useState({ limit: 10, page: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [clinicList, setClinicList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getClinicList = async (from = {}) => {
    setIsLoading(true);
    let resp = {}
    resp = await API.clinicPerformanceList({ "limit": formData.limit, "offset": formData.page, "order": "createdAt", "performanceStatus": formData.performanceStatus });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setClinicList(resp.data.data);
      setTotalCount(resp.data.count);
    }

  }

  const srNo = (cell, row, enumObject, rowIndex) => {
    return (rowIndex + 1 + (formData.limit * formData.page))
  }

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }



  const getClinicSearchSortList = async (from = {}) => {
    setIsLoading(true);
    let resp = {}
    resp = await API.clinicPerformanceList({ "limit": formData.limit, "offset": formData.page, "order": "createdAt", "performanceStatus": formData.performanceStatus });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setClinicList(resp.data.data);
      setTotalCount(resp.data.count);
    }
  }


  const onPageChange = (page, sizePerPage) => {
    let data = formData;
    data['page'] = page - 1;
    data['limit'] = sizePerPage;
    setFormData({ ...formData, ...data });
    getClinicSearchSortList(data);
  }

  const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
      return { backgroundColor: '#f3f1b0' };
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    let data = formData;
    data['performanceStatus'] = e.target.value;
    data['page'] = 0;
    setFormData({ ...formData, ...data });
    getClinicList()
  };


  const options = {
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption()

  };

  useEffect(() => {
    var str = window.location.pathname;
    var urlData = str.split("/");
    let data = formData;
    data['performanceStatus'] = urlData[urlData.length - 1];
    setFormData({ ...formData, ...data });
    getClinicList()
  }, [])
  return (
    <div className="main-content">
      {/* <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div> */}
      <Grid fluid>

        <Row>
          <Col md={12}>
            <Card
              content={
                <div className="fresh-datatables">
                  <Row>
                    <Col sm={4} className="form-group">
                      <FormControl componentClass="select" name="Performance" value={formData.performanceStatus} onChange={e => { handleChange(e); }}>
                        <option value="Frequent">Frequent</option>
                        <option value="Occasional">Occasional</option>
                        <option value="Never">Never</option>
                      </FormControl>
                    </Col>
                  </Row>
                  <div className='table-responsive'>
                    <BootstrapTable
                      deleteRow={false}
                      key={clinicList}
                      data={clinicList}
                      search={false}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={"Bottom"}
                      remote={true}
                      fetchInfo={{ dataTotalSize: totalCount }}
                      sort={true}
                      trStyle={rowStyleFormat}
                    >
                      <TableHeaderColumn
                        hidden={true}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="_id"
                        dataSort={true}
                        isKey
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "#" }}
                        thStyle={{ width: "5%", textAlign: "center" }}
                        tdStyle={{ width: "5%", textAlign: "center" }}
                        dataField="sn"
                        dataFormat={srNo}
                      >
                        S.No.
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "15%", textAlign: "center" }}
                        tdStyle={{ width: "15%", textAlign: "center" }}
                        dataField="clinicId"
                      >
                        ID
                      </TableHeaderColumn>



                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="doctorName"
                      >
                        DR.NAME
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="name"
                      >
                        CLINIC NAME
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="city"
                      >
                        CITY
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="performanceStatus"
                      >
                        Performance Status
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="status"
                      >
                        STATUS
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default ClinicPerformance

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import * as actionTypes from 'Admin/actions/actionTypes.js'
const initState = {
    response: [],
    message: '',
    isAddSupportSystem: false,
    isAddSupportSystemError: false,
    AddSupportSystem: false,

    isUpdateSupportSystem: false,
    isUpdateSupportSystemError: false,
    UpdateSupportSystem: false,

    isSupportList: false,
    isSupportListError: false,
    SupportList: [],
}

export default (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.ADD_SUPPORTSYSTEM_REQUEST:
            return {
                ...state,
                message: '',
                isAddSupportSystem: false,
                isAddSupportSystemError: false,
            }
        case actionTypes.ADD_SUPPORTSYSTEM_SUCCESS:
            return {
                ...state,
                message: 'Data Added Successfully',
                addSupportSystem: action.payload.response,
                isAddSupportSystem: true,
                isAddSupportSystemError: false,
            }
        case actionTypes.ADD_SUPPORTSYSTEM_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isAddSupportSystem: false,
                isAddSupportSystemError: true
            }

        case actionTypes.GET_UPDATE_SUPPORTSYSTEM_REQUEST:
            return {
                ...state,
                message: '',
                isUpdateSupportSystem: false,
                isUpdateSupportSystemError: false,
            }
        case actionTypes.GET_UPDATE_SUPPORTSYSTEM_SUCCESS:
            return {
                ...state,
                message: 'Data Updated Successfully',
                isUpdateSupportSystemData: action.payload.response,
                isUpdateSupportSystem: true,
                isUpdateSupportSystemError: false,
            }
        case actionTypes.GET_UPDATE_SUPPORTSYSTEM_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isUpdateSupportSystem: false,
                isUpdateSupportSystemError: true
            }

        case actionTypes.GET_SUPPORTSYSTEM_LIST_REQUEST:
            return {
                ...state,
                message: '',
                isSupportList: false,
                isSupportListError: false,
            }
        case actionTypes.GET_SUPPORTSYSTEM_LIST_SUCCESS:

            return {
                ...state,
                message: 'SupportSystem List',
                SupportList: action.payload.response,
                isSupportList: true,
                isSupportListError: false,
            }
        case actionTypes.GET_SUPPORTSYSTEM_LIST_ERROR:
            return {
                ...state,
                response: action.payload.error,
                message: action.payload.error,
                isSupportList: false,
                isSupportListError: true
            }




        default:
            return state
    }
}

/********* Show list of SupportSystem */
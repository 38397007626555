/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import axios from "axios";
import { appConstants } from "Front/_constants/app.constants.js";
export const request = (data, action, method) => {
    return axios({
        method: method,
        url: appConstants.paAppURL + action,
        headers: {
            "Content-Type": "application/json",
            apiKey: "Sys@1d90vKC",
        },
        data: data,
    }).onUploadProgress(function (progressEvent) {
        // Do whatever you want with the native progress event
    });
};

export const requestWithToken = (data, action, method) => {
    /*if(method === 'POST'){
        console.log('bhim');
        console.log(data);
        var formData = new FormData();
            for(let index in data){
              formData.append(index, data[index]);
            }
            
      }else{
        var formData = data;
      }*/
    let sessionToken = localStorage.getItem("token");
    return axios({
        method: method,
        url: appConstants.paAppURL + action,
        headers: {
            Authorization: sessionToken,
            "Content-Type": "application/json",
            apiKey: "Sys@1d90vKC",
        },
        data: data,
    });
};

// export const requestWithTokenFile = (data, action, method) => {
//   let sessionToken = localStorage.getItem("token");
//   return axios({
//     method: method,
//     url: appConstants.paAppURL + action,
//     headers: {
//       Authorization: sessionToken,
//       "Content-Type": "multipart/form-data",
//       apiKey: "Sys@1d90vKC",
//     },
//     data: data,
//   });
// };

export const requestWithTokenFile = (data, action, method) => {
    let sessionToken = localStorage.getItem("token");
    const apiCall = axios({
        method: method,
        url: appConstants.paAppURL + action,
        headers: {
            Authorization: sessionToken,
            "Content-Type": "multipart/form-data",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            apiKey: "Sys@1d90vKC",
        },
        data: data,
    });
    apiCall.catch((error, data) => {
        if (error.response.status === 401) {
            window.location.href = "/login";
        }
    });
    return apiCall;
};

export const testWithToken = (data, action, method) => {
    let sessionToken = localStorage.getItem("token");
    return axios({
        method: method,
        url: appConstants.paAppURL,
        headers: {
            Authorization: sessionToken,
            "Content-Type": "application/json",
        },
        data: data,
    });
};

export const getRequest = (data, action) => request(data, action, "GET");
export const postRequest = (data, action) => request(data, action, "POST");
export const patchRequest = (data, action) => request(data, action, "PATCH");
export const deleteRequest = (data, action) => request(data, action, "DELETE");
export const putRequest = (data, action) => request(data, action, "PUT");

export const getRequestWithToken = (data, action) =>
    requestWithToken(data, action, "GET");
export const postRequestWithToken = (data, action) =>
    requestWithToken(data, action, "POST");
export const patchRequestWithToken = (data, action) =>
    requestWithToken(data, action, "PATCH");
export const deleteRequestWithToken = (data, action) =>
    requestWithToken(data, action, "DELETE");
export const putRequestWithToken = (data, action) =>
    requestWithToken(data, action, "PUT");

export const postTestWithToken = (data, action) =>
    testWithToken(data, action, "POST");

export const postRequestWithTokenFile = (data, action) =>
    requestWithTokenFile(data, action, "POST");
import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "../../../../api/emr"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';

const MasterSymptomCategoryList = () => {
  let history = useHistory();
  const [formData, setFormData] = useState({ limit: 10, page: 0,direction: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [symptomCategoryList, setSymptomCategoryList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [sortData, setSortData] = useState(false);
  const notificationSystem = useRef(null);
  const getSymptomCategoryList = async (from = {}) => {
    setIsLoading(true);
    let resp = await API.masterSymptomCategoryList({ "limit": formData.limit, "offset":formData.page, "direction": formData.direction, "order": "sequence", "search": from.search });
    if(resp?.data?.status==="Success" && resp?.data?.statusCode)
    {
     setIsLoading(false);
     setSymptomCategoryList(resp.data.data); 
     setTotalCount(resp.data.count); 
    }
    
 }

 const handleToastifyMessage=(data)=>{
  let msg=""
  if(data==="add")
  msg="Symptom Category Added Successfully"
  if(data==="edit")
  msg="Symptom Category Edit Successfully"
  if(data==="delete")
  msg="Symptom Category Deleted Successfully"
  if(data==="status")
  msg="Symptom Category Status Changed Successfully"
  const notification = notificationSystem.current; 
  notification.addNotification({
      title: (<span data-notify="icon" className="pe-7s-check" style={{fontSize:'30px',color:'#fff',padding:'0px !important',top:'0% !important'}}></span>),
      message:<div>
                    {msg}
                </div>,
        level: 'success',
        position: 'tr',
        autoDismiss: 2,
    });
}


 const srNo = (cell, row, enumObject, rowIndex) => {
    return (rowIndex + 1+(formData.limit * formData.page))
}

const _setTableOption = () => {
    if (isLoading) {
        return (
            <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
        );
    } else {
        return "No data found!";
    }
}

// const actionButton = (cell, row, enumObject, rowIndex) => { 
//     return (<><Link to={{ pathname: `/admin/edit-drug-category/`+ row._id, state: {row} }}>  Edit </Link></>)
//   }

  const actionButton =  (cell, row, enumObject, rowIndex) => { 
    return (<span><Link to={{ pathname: `/admin/edit-symptom-category/` + row._id, state: { row }}} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
    &nbsp;&nbsp; &nbsp;&nbsp;
      <i className={(row.enabled === "1") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row,rowIndex)} aria-hidden="true"></i>
    &nbsp;&nbsp; &nbsp;&nbsp;
    <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i>
    </span>)
  }

  const statusChangedHandler = async (row,index) => {
    let resp = await API.statusChangeSymptomsCategory({ "id": row._id,"enabled":row.enabled ==="1"? "0":"1"});
    if(resp?.data?.status==="Success" && resp?.data?.statusCode){
        handleToastifyMessage('status')
        let categoryList = symptomCategoryList;
        let count = totalCount + 1;
        if (row.enabled === "1") {
          categoryList[index].enabled = "0"
        } else {
          categoryList[index].enabled = "1"
        }
        setSymptomCategoryList(categoryList); 
        setTotalCount(count); 
    }
  }

  const getConfirmFun = (id) => { 
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCategory(id)
        },
        {
          label: 'No',
        }
      ]
    })  
  }


const deleteCategory = async(id) => {
    let resp = await API.deleteSymptomCategory({ "id": id });
    if(resp?.data?.status==="Success" && resp?.data?.statusCode){
      handleToastifyMessage('delete')
      setSymptomCategoryList(symptomCategoryList.filter(item => item._id !== id));
     }
};

const onPageChange = (page, sizePerPage) => {
    let data = formData;
    data['page'] = page - 1;
    data['limit'] = sizePerPage;
    setFormData({ ...formData, ...data });
    getSymptomCategoryList(data);

    let scrollBar = 50;  
    if(scroll == page &&  sizePerPage < scrollBar ) {
      eval('$(".ps").scrollTop(0)');
    } else if( totalCount < page * sizePerPage){
      eval('$(".ps").scrollTop(0)');
    }
    setScroll(page)
}

const onSearchChange=(e, field = "")=>{
  let saveFormData = formData;
    saveFormData[field] = e.trimStart();
    saveFormData["page"] = 0;
    setFormData({ ...formData, ...saveFormData });
    getSymptomCategoryList(saveFormData);
}

function sortHandle(e){ 
  let data = formData;
  data['direction'] =  sortData? "desc": "asc";
  setFormData({ ...formData, ...data });
  setSortData(!sortData )
  getSymptomCategoryList(data);
}

const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
        return { backgroundColor: '#f3f1b0' };
    }
}


  const options = {
    //onSortChange: this.onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption()
    
};

useEffect(()=>{
  if(history?.location?.from==="add"|| history?.location?.from==="edit")
      handleToastifyMessage(history?.location?.from)
   getSymptomCategoryList()
},[])

  return (
    <div className="main-content">
       <div>
         <NotificationSystem  ref={notificationSystem} style={style} />
       </div>
        <Grid fluid>
       
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables">
                    <Row>
                      <Col md={6}>
                        <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-symptom-category")}>Add Complaint Category</Button>
                      </Col>
                      <Col sm={6} className="form-group">
            <FormControl
              type="text"
              autocomplete="off"
              name="search"
              id="search"
              value={formData.search ? formData.search : ""}
              onChange={(e) => {
                onSearchChange(e.target.value.replace(/\s+/g, " "), "search");
              }}
              placeholder="Search here ..."
            />
            </Col>
                    </Row>
                    <div className='table-responsive'>
                  <BootstrapTable
                    deleteRow={false}
                    key={symptomCategoryList}
                    data={symptomCategoryList}
                    search={false}
                    multiColumnSearch={true}
                    pagination={true}
                    options={options}
                    striped
                    hover
                    condensed
                    scrollTop={"Bottom"}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalCount }}
                    trStyle={rowStyleFormat}
                  >
                    <TableHeaderColumn
                      hidden={true}
                      tdAttr={{ "data-attr": "_id" }}
                      dataField="_id"
                      isKey
                      searchable={false}
                    >
                      Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "#" }}
                      thStyle={{ width: "5%", textAlign: "center" }}
                      tdStyle={{ width: "5%", textAlign: "center" }}
                      dataField="sn"
                      dataFormat={srNo}
                    >
                      S.No.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "title" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="name"
                    >
                      Category
                      {
                          !sortData?
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "title" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="sequence"
                    >
                      Sequence
                    </TableHeaderColumn>

                     <TableHeaderColumn
                      thStyle={{ width: "6%", textAlign: "center" }}
                      tdStyle={{ width: "6%", textAlign: "center" }}
                      tdAttr={{ "data-attr": "_id" }}
                      dataField=""
                      dataFormat={actionButton}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>

  )
}

export default MasterSymptomCategoryList
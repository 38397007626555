/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from '../../../../api/emr'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
import LabTestRangeList from "./LabTestRangeList";
import { useDispatch, useSelector } from "react-redux";

const LabTestList = ({ labTestListData, onDismissLabTestDetail }) => {
    let history = useHistory();
    const [formData, setFormData] = useState({ limit: 10, page: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [labTestList, setLabTestList] = useState([...labTestListData]);
    const [totalCount, setTotalCount] = useState(0);
    const [labTestOrderValues, setLabTestOrderVAlues] = useState({});
    const notificationSystem = useRef(null);
    const [labTestRageListModal, setLabTestRangeListModal] = useState(false)
    const [labTestData, setLabTestData] = useState({})
    const emrData = useSelector((store) => store.emr);


    //console.log(labTestListData, "labTestListData")
    const getLabTestList = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.labTestFrontList({ "limit": formData.limit, "offset": formData.page, "order": "createdAt", "search": from.search });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            setIsLoading(false);
            setLabTestList(resp.data.data);
            setTotalCount(resp.data.count);
        }

    }



    const handleToastifyMessage = (data) => {
        let msg = ""
        if (data === "add")
            msg = "Test Added Successfully"
        if (data === "edit")
            msg = "Test Edit Successfully"
        if (data === "delete")
            msg = "Test Deleted Successfully"
        if (data === "status")
            msg = "Test Status Changed Successfully"
        if (data === "order")
            msg = "Test Order Changed Successfully"
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }


    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
        let rangeSaved = false
        return (<span>
            {<Link Title="Range List" onClick={(e) => { setLabTestData(row); setLabTestRangeListModal(true) }}><i className={rangeSaved ? "fa fa-pencil" : "fa fa-plus"} aria-hidden="true"></i></Link>
            }
            &nbsp;&nbsp; &nbsp;&nbsp;
            {/* <Link to={{ pathname: `/admin/edit-lab-test/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link> */}
            {/* {!row.autoCalculate ?
                <Link title="Edit Lab Test" to={{ pathname: `/admin/edit-lab-test/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                : ""
            } */}
            {/* &nbsp;&nbsp; &nbsp;&nbsp;
            <i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row, rowIndex)} aria-hidden="true"></i>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i> */}
        </span>)
    }

    const statusChangedHandler = async (row, index) => {
        // let resp = await API.statusChangeLabTest({ "id": row._id, "status": row.status === "inactive" ? "active" : "inactive" });
        // if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
        //     handleToastifyMessage('status')
        //     let list = labTestList;
        //     let count = totalCount + 1;
        //     if (row.status === "active") {
        //         list[index].status = "inactive"
        //     } else {
        //         list[index].status = "active"
        //     }
        //     setLabTestList(list);
        //     setTotalCount(count);
        // }
    }

    const getConfirmFun = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteLabTest(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }


    const deleteLabTest = async (id) => {
        // let resp = await API.deleteLabTest({ "id": id });
        // if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
        //     handleToastifyMessage('delete')
        //     setLabTestList(labTestList.filter(item => item._id !== id));
        // }
    };

    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getLabTestList(data);
    }

    const onSearchChange = (e, field = "") => {

        let saveFormData = formData;
        saveFormData[field] = e.trimStart();
        saveFormData["page"] = 0;
        setFormData({ ...formData, ...saveFormData });
        getLabTestList(saveFormData);
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }


    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption()

    };

    useEffect(() => {
        //getLabTestList()
    }, [])

    const handleUnit = (cell, row, enumObject, rowIndex) => {
        //console.log(row, "rowwwwwwwwwwwwww")
        return row.unit != undefined ? row.unit : ""

    }

    const handleRange = (cell, row, enumObject, rowIndex) => {
        return row.range != undefined ? row.range : ""
    }

    const onDisMissLabTestModal = (list) => {
        // let tempData = labTestData
        // tempData.testReportRangeClinic = list
        // setLabTestData({ ...tempData })
        // let localData = {}
        // let tempLabTestListData = labTestListData
        // var localindex_index = labTestListData
        //     .map(function (el) {
        //         return el._id;
        //     })
        //     .indexOf(tempData._id);
        // if (localindex_index != -1) {
        //     tempLabTestListData[localindex_index] = tempData
        // }
        // onDismissLabTestDetail(tempLabTestListData)
    }


    useEffect(() => {
        //update lab Data
        //update lab list in redux
        if (emrData.isLabRangeListChange) {
            setLabTestList([...emrData.labTestList])
        }
    }, [emrData.isLabRangeListChange])


    //console.log(labTestData, "labTestData")
    return (
        <div className="main-content">
            <div>
                <NotificationSystem ref={notificationSystem} style={style} />
            </div>
            <div className="fresh-datatables">
                <Row>
                    {/* <Col md={6}>
                                            <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-lab-test")}>Add Lab Test</Button>
                                        </Col> */}
                    {/* <Col sm={6} className="form-group">
                                            <FormControl
                                                type="text"
                                                autocomplete="off"
                                                name="search"
                                                id="search"
                                                value={formData.search ? formData.search : ""}
                                                onChange={(e) => {
                                                    onSearchChange(e.target.value, "search");
                                                }}
                                                placeholder="Search here ..."
                                            />
                                        </Col> */}
                </Row>
                <div className='table-responsive'>
                    <BootstrapTable
                        deleteRow={false}
                        key={labTestList}
                        data={labTestList}
                        search={false}
                        multiColumnSearch={true}
                        pagination={false}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={"Bottom"}
                        remote={true}
                        fetchInfo={{ dataTotalSize: totalCount }}
                        sort={false}
                        trStyle={rowStyleFormat}
                    >
                        <TableHeaderColumn
                            hidden={true}
                            tdAttr={{ "data-attr": "_id" }}
                            dataField="_id"
                            dataSort={true}
                            isKey
                            searchable={false}
                        >
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "#" }}
                            thStyle={{ width: "5%", textAlign: "center" }}
                            tdStyle={{ width: "5%", textAlign: "center" }}
                            dataField="sn"
                            dataFormat={srNo}
                        >
                            S.No.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "40%", textAlign: "center" }}
                            tdStyle={{ width: "40%", textAlign: "center" }}
                            dataField="name"
                        >
                            Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "shortName" }}
                            thStyle={{ width: "25%", textAlign: "center" }}
                            tdStyle={{ width: "25%", textAlign: "center" }}
                            dataField="shortName"
                        >
                            Short Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            tdAttr={{ "data-attr": "title" }}
                            thStyle={{ width: "15%", textAlign: "center" }}
                            tdStyle={{ width: "15%", textAlign: "center" }}
                            dataField=""
                            dataFormat={handleUnit}
                        >
                            Unit
                        </TableHeaderColumn>

                        {/* <TableHeaderColumn
                                                tdAttr={{ "data-attr": "title" }}
                                                thStyle={{ width: "15%", textAlign: "center" }}
                                                tdStyle={{ width: "15%", textAlign: "center" }}
                                                dataField=""
                                                dataFormat={handleRange}
                                            >
                                                Range
                                            </TableHeaderColumn> */}

                        {/* <TableHeaderColumn
                                                tdAttr={{ "data-attr": "title" }}
                                                thStyle={{ width: "10%", textAlign: "center" }}
                                                tdStyle={{ width: "10%", textAlign: "center" }}
                                                dataField="order"
                                            // dataFormat={getSequence}
                                            >
                                                Order
                                            </TableHeaderColumn> */}

                        <TableHeaderColumn
                            thStyle={{ width: "5%", textAlign: "center" }}
                            tdStyle={{ width: "5%", textAlign: "center" }}
                            tdAttr={{ "data-attr": "_id" }}
                            dataField=""
                            dataFormat={actionButton}
                        >
                            Action
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
            <Modal
                show={labTestRageListModal}
                onHide={() => setLabTestRangeListModal(false)}
                dialogClassName="modal-xl"
                className="Modal_downsearch"
            >
                <Modal.Header
                    closeButton
                    className="bg-thumbcolor"
                >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {`${labTestData.name} Range List`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabTestRangeList
                        labTestData={labTestData}
                        labTestList={labTestList}
                        onDisMissLabTestModal={onDisMissLabTestModal}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LabTestList
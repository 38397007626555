/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import { Col, Row, Grid } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { visitListAction, dietHistoryListAction } from "Front/actions/home";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "react-loading";
import moment from "moment";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import loadingImg from "../../assets/img/loading.gif";

class DietHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dietList: [],
      search: this.props.search,
      showProcessing: false,
      search_date: this.props.search_date,
      outId: "",
      nextVisit: false,
      taskScreenData: [],
      nextVisitData: [],
      patientData: {},
      patientOut: false,
    };

    this.get_age_by_dob = this.get_age_by_dob.bind(this);
    this.getTimeDate = this.getTimeDate.bind(this);
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.row
    )
      this.props.dietHistoryListAction({ id: this.props.location.state.row });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isdietHisrotyList !== this.props.isdietHisrotyList) {


      if (
        nextProps.dietHisrotyList &&
        nextProps.dietHisrotyList.data &&
        nextProps.dietHisrotyList.data.data &&
        nextProps.dietHisrotyList.data.data.length > 0
      ) {
        this.setState({
          showProcessing: false,
          dietList: nextProps.dietHisrotyList.data.data,
        });
      }
    }

    if (this.state.isLoading === true) {
      this.setState({ isLoading: false });
    }
  }

  getPatientId(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.patientData &&
      row.patientData.length > 0 &&
      row.patientData[0]
    ) {
      return <div className="">{row.patientData[0].patientClinicId}</div>;
    }
  }

  getPatientName(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.patientData &&
      row.patientData.length > 0 &&
      row.patientData[0]
    ) {
      return (
        <div className="">
          {row.patientData[0].firstName} {row.patientData[0].lastName}
        </div>
      );
    }
  }

  getTimeDate(cell, row, enumObject, rowIndex) {
    return <div>{moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</div>;
  }

  getName(cell, row, enumObject, rowIndex) {
    return (
      <div>
        {row.addedByType}{" "}
        <span className={row.addedByType === "educator" ? "pink" : "red"}>
          {row.addedByName ? "/ " + row.addedByName : ""}
        </span>
      </div>
    );
  }

  getDietName(cell, row, enumObject, rowIndex) {
    return <div>{row.treatmentName}</div>;
  }

  getDietType(cell, row, enumObject, rowIndex) {
    return <div>{row.treatmentType}</div>;
  }

  get_age_by_dob(cell, row, enumObject, rowIndex) {
    if (
      row &&
      row.patientData &&
      row.patientData.length > 0 &&
      row.patientData[0]
    ) {
      return (
        <div class={"ageColor_" + row.patientData[0].age_color}>
          {row.patientData[0] && row.patientData[0].age
            ? parseInt(row.patientData[0].age)
            : ""}
        </div>
      );
    }
  }

  getCount(cell, row, enumObject, rowIndex) {
    return <div>{rowIndex + 1}</div>;
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  }

  render() {
    const options = {
      noDataText: this._setTableOption(),
      //searchPanel: (props) => (this.search_date(props)),
      searchPosition: "left",
    };

    return (
      <div className="main-content" style={{ padding: "15px 0" }}>
        <Grid fluid>
          <Card
            content={
              <Row>
                {this.state.alert}
                <div
                  className="actionProcess"
                  style={{
                    display: this.state.showProcessing ? "block" : "none",
                  }}
                >
                  <img src={loadingImg} alt="Loading" width="40px" />
                  <center> Please Wait</center>
                </div>
                <Col md={12} className="">
                  <div className="visit-list table-responsive table-responsive-new table_doctor_login dashboard">
                    <h4>Diet Type Shared History</h4>
                    <BootstrapTable
                      data={this.state.dietList}
                      search={false}
                      multiColumnSearch={false}
                      options={options}
                      striped
                      hover
                      condensed
                    >
                      <TableHeaderColumn
                        hidden={true}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="_id"
                        dataSort={true}
                        isKey
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "3%" }}
                        tdStyle={{ width: "3%" }}
                        thAttr={{ "data-attr": "#" }}
                        dataField="sn"
                        dataFormat={this.getCount.bind(this)}
                      >
                        #1
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "9%" }}
                        tdStyle={{ width: "9%" }}
                        tdAttr={{ "data-attr": "PATIENT ID" }}
                        dataField=""
                        dataSort={true}
                        dataFormat={this.getPatientId.bind(this)}
                      >
                        PATIENT ID
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "12%" }}
                        tdStyle={{ width: "12%" }}
                        tdAttr={{ "data-attr": "NAME" }}
                        dataField=""
                        dataSort={true}
                        dataFormat={this.getPatientName.bind(this)}
                      >
                        PATIENT NAME
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "5%" }}
                        tdStyle={{ width: "5%" }}
                        tdAttr={{ "data-attr": "AGE" }}
                        dataField=""
                        dataFormat={this.get_age_by_dob.bind(this)}
                      >
                        AGE
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "18%" }}
                        tdStyle={{ width: "18%" }}
                        tdAttr={{ "data-attr": "DIET" }}
                        dataField=""
                        dataFormat={this.getDietName.bind(this)}
                      >
                        DIET
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "18%" }}
                        tdStyle={{ width: "18%" }}
                        tdAttr={{ "data-attr": "DIET" }}
                        dataField=""
                        dataFormat={this.getDietType.bind(this)}
                      >
                        TREATMENT TYPE
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "18%" }}
                        tdStyle={{ width: "18%" }}
                        tdAttr={{ "data-attr": "IN TIME" }}
                        dataField=""
                        dataFormat={this.getName.bind(this)}
                      >
                        DIET CHART CHANGED BY
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ width: "20%" }}
                        tdStyle={{ width: "20%" }}
                        tdAttr={{ "data-attr": "DATE & TIME" }}
                        dataField="createdAt"
                        dataFormat={this.getTimeDate.bind(this)}
                      >
                        DATE & TIME
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </Col>
              </Row>
            }
          />
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    VisitList: state.home.VisitList,
    isVisitList: state.home.isVisitList,
    isVisitListError: state.home.isVisitListError,

    dietHisrotyList: state.home.dietHisrotyList,
    isdietHisrotyList: state.home.isdietHisrotyList,
    isdietHisrotyListError: state.home.isdietHisrotyListError,
  };
}
export default withRouter(
  connect(mapStateToProps, { visitListAction, dietHistoryListAction })(
    DietHistory
  )
);

import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/emr";
import * as API_MASTER from "Front/api/master";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from "react-redux";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import ReportTitle from "../../../TaskScreen/ReportTitle";
import ViewBox from "../../../../components/ViewBox";
import AnchorComponent from "../../../../components/common/AnchorComponent";
import * as actionTypes from "Front/actions/actionTypes";
import Breadcrum from "../../../../components/common/Breadcrum";
import { NavLink } from "react-router-dom";

const Investigation = () => {
    let history = useHistory();
    let userType = localStorage.getItem("userType");
    const [formData, setFormData] = useState({ limit: 10, page: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const emrData = useSelector((store) => store.emr);
    const [generalInformation, setGeneralInformation] = useState({
        patientName: emrData?.vitalSignsData
            ? emrData.vitalSignsData.patientName
            : "",
        age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
        height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
        weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
        bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
        bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
        gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
        pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
        temperature: emrData?.vitalSignsData
            ? emrData.vitalSignsData.temperature
            : "",
    });
    const [toggleDuration, setToggleDuration] = useState(
        emrData
            ? emrData.vitalSignsData.toggleDuration
                ? emrData.vitalSignsData.toggleDuration
                : false
            : false
    );

    const [duration, setDuration] = useState(
        emrData ? emrData.vitalSignsData.diabetesDuration : "Year Month Day"
    );
    const [alertMsg, setAlertMsg] = useState(null);
    const notificationSystem = useRef(null);
    const [noDataFound, setNoDataFound] = useState(false);
    const [reportList, setReportList] = useState([])
    const [totalCount, setTotalCount] = useState(0);
    const [ViewBoxData, setViewBoxData] = useState({
        show: false,
        type: "image",
        path: "",
    });
    const [checkReadStatus, setCheckReadStatus] = useState(false);
    const [formDataFile, setFormDataFile] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true)
    const [title, setTitle] = useState("")
    const [start_date, setStart_Date] = useState("")
    const [end_date, setEnd_Date] = useState("")
    const [hours, setHours] = useState("Select")
    let { appointmentId, patientId, printingLanguage } = useParams();

    const dispatch = useDispatch();

    const getGeneralInfo = async () => {
        if (
            emrData.vitalSignsData &&
            Object.keys(emrData.vitalSignsData).length === 0
        ) {
            let resp = await API.generalInformation({ patientId: patientId });
            if (resp.data && resp.data.status === "Success") {
                setGeneralInformation({
                    patientName: resp.data.data.name,
                    age: resp.data.data.age,
                    height: resp.data.data.height,
                    weight: resp.data.data.weight,
                    bmi: resp.data.data.bmi,
                    bp: resp.data.data.bp,
                    gender: resp.data.data.gender,
                    pulse: resp.data.data.pulse,
                    temperature: resp.data.data.temprature,
                });
                if (resp.data.data.diabitic === "yes") {
                    let chkToggleDuration = document.getElementById("customSwitch1");
                    if (chkToggleDuration !== null) {
                        chkToggleDuration.checked = true;
                        setToggleDuration(true);
                    }

                    let strDuration = "";

                    if (
                        resp.data.data.diabtiesDuration?.years === 0 &&
                        resp.data.data.diabtiesDuration?.months === 0 &&
                        resp.data.data.diabtiesDuration?.days === 0
                    ) {
                        strDuration = "";
                    }
                    if (resp.data.data?.diabtiesDuration?.years > 0) {
                        strDuration = resp.data.data.diabtiesDuration?.years + " years ";
                    }
                    if (resp.data.data.diabtiesDuration?.months > 0) {
                        strDuration += resp.data.data.diabtiesDuration?.months + " months ";
                    }
                    if (resp.data.data.diabtiesDuration?.days > 0) {
                        strDuration += resp.data.data.diabtiesDuration?.days + " days ";
                    }
                    setDuration(strDuration);
                }
            }
        } else {
            if (emrData.vitalSignsData.toggleDuration) {
                let chkToggleDuration = document.getElementById("customSwitch1");
                if (chkToggleDuration !== null) {
                    chkToggleDuration.checked = true;
                }
            }
        }
    };

    const getReportList = async (from = {}) => {
        setIsLoading(true);
        let resp = await await API_MASTER.getReportList({ patientId: patientId, page: formData.page, limit: formData.limit, start_date: start_date, end_date: end_date });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            setIsLoading(false);
            setReportList(resp.data.data.data);
            setTotalCount(resp.data.data.count);
        }

    }

    const getReportListDateFilter = async (start_date, end_date, isAllReports) => {
        //console.log(data, "data")
        let resp = {}
        if (isAllReports)
            resp = await API_MASTER.getReportList({ patientId: patientId, page: 0, limit: formData.limit });
        else resp = await API_MASTER.getReportList({ patientId: patientId, page: 0, limit: formData.limit, start_date: start_date, end_date: end_date });
        if (resp) {
            if (resp.data.data.length === 0)
                setNoDataFound(true);
            else setNoDataFound(false);

            setTotalCount(resp.data.data.count);
            setReportList(resp.data.data.data);
        }
    };

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const onPageChange = (page, sizePerPage) => {
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getReportList(data);
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }

    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const createdAtFun = (cell, row) => {
        return moment(row.createdAt).format("D-MMM-YYYY");
    };
    const uploadedByFun = (cell, row) => {
        return row.uploadedBy;
    };

    const titleFun = (cell, row) => {
        return (
            <ReportTitle item={row} getReport={getReportList} parent="invstigation" />
        );
    };

    const hideViewBox = () => {
        setViewBoxData({ show: false, type: "image", path: "" });
    };

    const viewFun = (cell, row) => {
        if (row && row.files.length > 0) {
            return (
                <>
                    {" "}
                    {row.files.map((d, i) => {
                        return getFileIcon(d, row);
                    })}
                </>
            );
        }
    };

    const getFileIcon = (data, report) => {
        let icon = "";
        switch (data.fileType) {
            case "jpg" || "png" || "jpeg":
                icon = `fa-image`;
                break;
            case "mp4" || "avi" || "flv" || "wmv":
                icon = "fa-film";
                break;
            case "pdf":
                icon = "fa-file-pdf-o";
                break;
            default:
                icon = "fa-file-o";
                break;
        }

        let color = data.read ? "green" : "red";

        return (
            <>
                {" "}
                <AnchorComponent
                    data={data.fileName}
                    className="cursor-pointer-link"
                    setReadFile={setReadFile}
                    title="click"
                    file={data}
                    id={report._id}
                    color={color}
                >
                    <i className={`fa ${icon}`}></i>
                </AnchorComponent>
            </>
        );

        // <span className='cursor-pointer-link'  onClick={e=>setReadFile(data,report._id)} style={{"font-size":"20px","padding":"2px", "color":color}} href={`${appConstants.s3UploadUrl+data.fileName}`} title="click" target='_blank'></span>;
    };

    const setReadFile = async (file, reportId, path = "") => {
        let res = await API_MASTER.setReadFile({
            fileId: file._id,
            reportId: reportId,
        });

        const objIndex = reportList.findIndex((obj) => obj._id === reportId);

        let filId = reportList[objIndex]["files"].findIndex(
            (obj) => obj._id === file._id
        );
        if (!reportList[objIndex]["files"][filId]["read"]) {
            dispatch({
                type: actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_VIEW_REPORT_REQUEST,
                payload: { patientId: patientId },
            });
        }
        reportList[objIndex]["files"][filId]["read"] = true;
        reportList[objIndex]["read"] = true;
        let newReportList = reportList;
        setReportList([...reportList]);


        setViewBoxData({
            show: false,
            type: "image",
            title: "Report",
            path: file.fileName,
        });

        var local_index = newReportList
            .map(function (el) {
                return el.read;
            })
            .indexOf(false);
        if (local_index != -1) {
            setCheckReadStatus(true);
        } else {
            setCheckReadStatus(false);
        }

        if (file.fileType != "pdf") {
            let tempData = { type: file.fileType, title: "Report", path: file.fileName }
            const url = `/view-box`;
            var newWindow = window.open(url);
            newWindow.params = tempData;

        }
        // window.open(
        //     appConstants.s3UploadUrl+file.fileName,
        //     '_blank'
        // );
    };

    const deleteFun = (cell, row) => {
        return (
            <a
                className="inactive-color"
                onClick={(e) => DeleteReportConfirm(row._id)}
            >
                Delete
            </a>
        );
    };

    const DeleteReportConfirm = (id) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => DeleteReport(id),
                },
                {
                    label: "No",
                    //onClick: () => this.resetFormData()
                },
            ],
        });
    };

    const DeleteReport = async (id) => {
        const report = reportList.find((obj) => obj._id === id);

        //let isRead=reportList[objIndex]['files'][filId]['read']
        let resp = await API_MASTER.deleteReport({ reportId: id });

        if (resp.status === 200) {
            dispatch({
                type: actionTypes.UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_DELETE_REPORT_REQUEST,
                payload: { patientId: patientId, isRead: report.read },
            });
            successAlert("Report Successfully Deleted.");

            getReportList();
        }
    };

    const successAlert = (msg) => {
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (
                <span
                    data-notify="icon"
                    className="pe-7s-check"
                    style={{
                        fontSize: "30px",
                        color: "#fff",
                        padding: "0px !important",
                        top: "0% !important",
                    }}
                ></span>
            ),
            message: <div>{msg}</div>,
            level: "success",
            position: "tr",
            autoDismiss: 2,
        });
    };

    const changeHandlerFile = (event) => {
        if (event.target.files.length > 0) {
            let formDataTemp = event.target.files[0];
            setFormDataFile(formDataTemp);
            setIsDisabled(false);
        }
    };

    const handleSubmit = async (event) => {
        let sentData = new FormData();
        sentData.append("patientId", patientId);
        sentData.append("files", formDataFile);
        let res = await API.addReport(sentData);
        if (res.data.status === "Success" && res.data.statusCode === 200) {
            successAlert("Report Uploaded Successfully");
            document.getElementById("fileControl1").value = "";
            let list = reportList;
            list.unshift(res.data.report);
            setReportList([...list]);
            setFormDataFile(null);
            setIsDisabled(true);
            setTitle("");
        }
    };

    const handleSearch = (e) => {


        setHours(e.target.value)

        setReportList([])
        // if (startDate === "") {
        //     setStartDateEmpty(true)
        //     return
        // }
        // if (endDate === "") {
        //     setEndDateEmpty(true)
        //     return
        // }
        let start_date = new Date()
        let end_date = new Date()
        let allReports = false
        switch (e.target.value) {
            case "72": {
                let d = new Date();
                d.setDate(d.getDate() - 2);
                start_date = d;
                break;
            }

            case "168": {
                let d = new Date();
                d.setDate(d.getDate() - 6);
                start_date = d;
                break;
            }

            case "360": {
                let d = new Date();
                d.setDate(d.getDate() - 14);
                start_date = d;
                break;
            }

            case "720": {
                let d = new Date();
                d.setDate(d.getDate() - 29);
                start_date = d;
                break;
            }

            case "all": {
                allReports = true;
                start_date = "";
                end_date = "";
                break;
            }

            case "recent": {
                start_date = "";
                end_date = "";
                break;
            }

        }

        if (start_date != "") {
            start_date = moment(start_date).format('YYYY-MM-DD')
            setStart_Date(moment(start_date).format('YYYY-MM-DD'))
        } else setStart_Date("")

        if (end_date != "") {
            end_date = moment(end_date).format('YYYY-MM-DD')
            setEnd_Date(moment(end_date).format('YYYY-MM-DD'))
        } else setEnd_Date("")

        setFormData({ ...formData, page: 0 })
        getReportListDateFilter(start_date, end_date, allReports)
    }

    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption()

    };

    useEffect(() => {
        getGeneralInfo();
        getReportList();
    }, [])

    const renderNavTabs = () => {
        let html = ""
        if (userType === "doctor") {
            html = <>
                <li>
                    <NavLink
                        to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Vital Signs</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Lab Tests</span>
                    </NavLink>
                </li>
                <li className="current">
                    <NavLink
                        to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Investigation</span>
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        to={`/complaints/${appointmentId}/${patientId}/${printingLanguage}`}
                        className="nav-link"
                    >
                        <span className="sidebar-mini">Complaint</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={`/diagnosis/${appointmentId}/${patientId}/${printingLanguage}`}
                        className="nav-link"
                    >
                        <span className="sidebar-mini">Diagnosis</span>
                    </NavLink>
                </li>
                {/* <li><a>Investigation Advised</a></li>
      <li><a>All Investigations</a></li> */}
                <li>
                    <NavLink
                        to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                    >
                        <span className="sidebar-mini">Prescription</span>
                    </NavLink>
                </li>
            </>
        }

        if (userType === "receptionist") {
            if (localStorage.getItem("is_prescription_edit_by_receptionist") === "yes") {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Prescription</span>
                        </NavLink>
                    </li>
                </>
            } else {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                </>
            }
        }

        if (userType === "educator") {
            if (localStorage.getItem("is_prescription_edit_by_educator") === "yes") {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/prescription/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Prescription</span>
                        </NavLink>
                    </li>
                </>
            } else {
                html = <>
                    <li>
                        <NavLink
                            to={`/vital-sign/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Vital Signs</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/print-labs/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Lab Tests</span>
                        </NavLink>
                    </li>
                    <li className="current">
                        <NavLink
                            to={`/investigation/${appointmentId}/${patientId}/${printingLanguage}`}
                        >
                            <span className="sidebar-mini">Investigation</span>
                        </NavLink>
                    </li>
                </>
            }
        }

        return html;

    }

    return (
        <div className="main-content emr-wrapper dashboard-2-0"
            style={{ padding: "15px 0px" }}
        >
            {/* {ViewBoxData && ViewBoxData.show && (
                <ViewBox data={ViewBoxData} hideViewBox={(e) => hideViewBox()} />
            )} */}
            <div>
                <NotificationSystem ref={notificationSystem} style={style} />
            </div>
            <span> {alertMsg}</span>
            <Grid fluid>
                {userType === "doctor" &&
                    <div id="divBreadCrumb"> <Breadcrum /></div>
                }
                <div>
                    <div className="general-information">
                        <div className="card_header">
                            <div className="card_header_inner">
                                <ul className="patient_details">
                                    <li>
                                        <a className="user-info-img">
                                            {/* <span><img src={GeneralUsrImg} alt="" />  </span> */}
                                            {generalInformation.patientName}
                                        </a>
                                    </li>
                                    <span className="flex">
                                        <li>
                                            <a>
                                                <span>Age: </span> {generalInformation.age} Y /{" "}
                                                {generalInformation.gender === "Male" ? " M" : " F"}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>
                                                    Height{generalInformation.height > 0 ? ":" : ""}{" "}
                                                </span>{" "}
                                                {generalInformation.height > 0
                                                    ? generalInformation.height
                                                    : ""}{" "}
                                                {generalInformation.height > 0 ? "CM" : ""}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>
                                                    Weight{generalInformation.weight > 0 ? ":" : ""}{" "}
                                                </span>{" "}
                                                {generalInformation.weight > 0
                                                    ? generalInformation.weight
                                                    : ""}{" "}
                                                {generalInformation.weight > 0 ? "KG" : ""}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                                                {generalInformation.bmi > 0
                                                    ? generalInformation.bmi
                                                    : ""}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>
                                                    BP{generalInformation.bp !== "/" ? ":" : ""}{" "}
                                                </span>{" "}
                                                {generalInformation.bp !== "/"
                                                    ? generalInformation.bp
                                                    : ""}{" "}
                                                {generalInformation.bp !== "/" ? " mmHg" : ""}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>
                                                    Pulse{generalInformation.pulse > 0 ? ":" : ""}{" "}
                                                </span>{" "}
                                                {generalInformation.pulse > 0
                                                    ? generalInformation.pulse
                                                    : ""}{" "}
                                                {generalInformation.pulse > 0 ? "/min" : ""}
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <span>
                                                    Temperature
                                                    {generalInformation.temperature > 0 ? ":" : ""}{" "}
                                                </span>{" "}
                                                {generalInformation.temperature > 0
                                                    ? generalInformation.temperature
                                                    : ""}{" "}
                                                {generalInformation.temperature > 0 ? "F" : ""}{" "}
                                            </a>
                                        </li>
                                        {toggleDuration && (
                                            <li>
                                                <a>
                                                    <span>Diabetes Duration: </span>{" "}
                                                    {emrData
                                                        ? emrData?.diabetesDuration
                                                            ? emrData?.diabetesDuration
                                                            : duration
                                                        : duration}{" "}
                                                </a>
                                            </li>
                                        )}
                                    </span>
                                </ul>
                                <div className="patient_history_btn">
                                    <NavLink
                                        to={`/patient-details`}
                                        style={{ marginRight: "10px" }}
                                        className="btn btn-fill btn-light btn-sm"
                                    >
                                        Go Back
                                    </NavLink>

                                    <NavLink
                                        to={`/history/${appointmentId}/${patientId}/${printingLanguage}`}
                                        className="btn btn-fill btn-light pull-right btn-sm"
                                    >
                                        All Visit
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        {/* <div className="pending-action" onClick={() => this.handleSideModal("PendingAction")}>
              <div className='pending-ic'>
                <img src={PendingIc} alt='' />
              </div>
              <div className="pending-title">
                <h4>Pending Action <strong>3</strong></h4>
              </div>
            </div> */}
                    </div>
                    <div className="card-content-inner">
                        <div className="emr-menu-list">
                            <ul>
                                {renderNavTabs()}
                            </ul>
                        </div>
                        <div className="emr-tab-content tab-content1">
                            <Row>
                                <Col lg={12} md={12}>
                                    <div className="center-content h5-invet">
                                        <div className="investigation-cta">
                                            <div className="file-intves file-investigation">
                                                <div>
                                                    <input
                                                        type="file"
                                                        accept=".png, .jpg , .pdf"
                                                        maxLength={12}
                                                        name="files"
                                                        id="fileControl1"
                                                        onChange={(e) => {
                                                            changeHandlerFile(e);
                                                        }}
                                                        autoComplete="off"
                                                        placeholder="File"
                                                    />
                                                </div>
                                                <Button
                                                    style={{ marginLeft: "10px" }}
                                                    type="button"
                                                    className="btn btn-fill btn-primary"
                                                    onClick={(e) => {
                                                        handleSubmit(e);
                                                    }}
                                                    disabled={isDisabled}
                                                >
                                                    <span>
                                                        Upload Report
                                                    </span>
                                                </Button>
                                            </div>
                                            <div className="filter-select">
                                                <label  >Filter by Days</label>
                                                <select name="hours" onChange={e => handleSearch(e)} value={hours} >
                                                    <option value="recent">Most Recent</option>
                                                    <option value="0">Today</option>
                                                    <option value="72">3 Days</option>
                                                    <option value="168">7 Days</option>
                                                    <option value="360">15 Days</option>
                                                    <option value="720">30 Days</option>
                                                    <option value="all">All</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col md={12}>
                        <Card
                            content={
                                <div className="fresh-datatables">
                                    <div className='table-responsive'>
                                        <BootstrapTable
                                            deleteRow={false}
                                            key={reportList}
                                            data={reportList}
                                            search={false}
                                            multiColumnSearch={true}
                                            pagination={true}
                                            options={options}
                                            striped
                                            hover
                                            condensed
                                            scrollTop={"Bottom"}
                                            remote={true}
                                            fetchInfo={{ dataTotalSize: totalCount }}
                                            sort={true}
                                            trStyle={rowStyleFormat}
                                        >
                                            <TableHeaderColumn
                                                hidden={true}
                                                tdAttr={{ "data-attr": "_id" }}
                                                dataField="_id"
                                                dataSort={true}
                                                isKey
                                                searchable={false}
                                            >
                                                Id
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                tdAttr={{ "data-attr": "#" }}
                                                thStyle={{ width: "10%", textAlign: "center" }}
                                                tdStyle={{ width: "10%", textAlign: "center" }}
                                                dataField="sn"
                                                dataFormat={srNo}
                                            >
                                                S.No.
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                thStyle={{ width: "30%" }}
                                                tdStyle={{ width: "30%" }}
                                                tdAttr={{ "data-attr": "glucoseDate" }}
                                                dataFormat={titleFun}
                                                dataField="glucoseDate"
                                            >
                                                Title
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                thStyle={{ width: "10%" }}
                                                tdStyle={{ width: "10%" }}
                                                tdAttr={{ "data-attr": "glucoseValue" }}
                                                dataFormat={viewFun}
                                            >
                                                VIEW{" "}
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                thStyle={{ width: "20%" }}
                                                tdStyle={{ width: "20%" }}
                                                tdAttr={{ "data-attr": "suggestedDose" }}
                                                dataFormat={createdAtFun}
                                            >
                                                INVESTIGATION DATE
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                thStyle={{ width: "20%" }}
                                                tdStyle={{ width: "20%" }}
                                                tdAttr={{ "data-attr": "uploadedBy" }}
                                                dataFormat={uploadedByFun}
                                            >
                                                UPLOADED BY
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                thStyle={{ width: "10%" }}
                                                tdStyle={{ width: "10%" }}
                                                tdAttr={{ "data-attr": "suggestedDose" }}
                                                dataFormat={deleteFun}
                                            >
                                                Action
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    )
}

export default Investigation
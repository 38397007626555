/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";

import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Admin/components/Card/Card.jsx";
import { addClinicDocumentAction } from "Admin/actions/clinic_document";
import * as API from "Admin/api/clinic_document";
import Loading from "react-loading";
import { appConstants } from "Front/_constants/app.constants.js";

require("datatables.net-responsive");
$.DataTable = require("datatables.net-bs");
const PreferenceToSet = (props) => {
  const [formData, setFormData] = useState({});
  const [specializationList, setSpecializationList] = useState([]);
  const [specialization, setSpecialization] = useState([]);
  const [specializationIds, setSpecializationIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputHandler = (e) => {
    let saveFormData = formData;
    let errArr = errors;
    let imageExt = [];
    if (e.target.type === "file") {
      for (let i = 0; i < e.target.files.length; i++) {
        imageExt[i] = isImage(e.target.files[i].name);
      }

      if (imageExt.includes(false)) {
        errArr.documentFile = "error";
      } else {
        errArr.documentFile = "";
      }
      setErrors({ ...errArr });
      saveFormData[e.target.name] = e.target.files;
    } else {
      saveFormData[e.target.name] = e.target.value;
    }
    setFormData({ ...formData, ...saveFormData });

  };
  const goBackFun = (e) => {
    props.history.replace(sessionStorage.getItem("prvUrl"));
  };
  const validate = () => {
    let post = formData;
    let errArr = errors;
    if (
      typeof post.documentFile !== "undefined" &&
      post.documentFile.length > 0
    ) {
      errArr.documentFile = "";
    } else {
      errArr.documentFile = "error";
    }
    if (typeof post.name !== "undefined" && post.name !== "") {
      errArr.name = "";
    } else {
      errArr.name = "error";
    }
    setErrors({ ...errArr });
  };

  const submitHandler = async (event) => {
    validate();
    setIsLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      setValidated(true);
      setIsLoading(false);
    } else {
      event.preventDefault();
      let form = new FormData();
      form.append("name", formData.name);
      for (const i in specializationIds) {
        form.append("specializationIds", specializationIds[i]);
      }
      for (let i = 0; i < formData.documentFile.length; i++) {
        form.append(
          "file",
          formData.documentFile[i],
          formData.documentFile[i].name
        );
      }
      let resp = await API.addClinicDocument(form);

      if (resp) {
        appConstants.socket.emit("newDocument", {
          specializationIds: specializationIds,
        });

        //setFormData({});

        props.handleClick("success", "Document added successfully.");
        props.history.push(`/admin/clinic-document-list`);

        //setSuccessMsg("Document Added successfully.");

        // setTimeout(function () {
        //   setIsLoading(false);
        //   setSuccessMsg("");
        //   props.handleClick('success', 'Document Added successfully.');
        //   props.history.push(`/admin/clinic-document-list`);
        // }, 2000);
      } else {
        setSuccessMsg("Something went wrong.");
        setTimeout(function () {
          setSuccessMsg("");
        }, 2000);
      }
      setIsLoading(false);
    }
  };

  const isImage = (icon) => {
    const ext = [
      "jpg",
      "png",
      "jpeg",
      "mp4",
      "avi",
      "flv",
      "wmv",
      "pdf",
      "xlsx",
      "xls",
      "csv",
      "docx",
      "doc",
    ];
    return ext.some((el) => icon.endsWith(el));
  };

  const handleSpecialization = (value) => {
    let multipleSelect = specialization;
    multipleSelect = value;
    setSpecialization(multipleSelect);
    let data = multipleSelect;
    const tempClinic = [...new Set(data && data.map((item) => item.value))];
    let dataSpecialization = {
      specialization_ids: tempClinic,
    };
    setSpecializationIds(tempClinic);
  };

  const specializationListAction = async (page) => {
    let resp = await API.specializationList();
    let data = resp.data.data;
    const tempList = [...new Set(data && data.map((item) => item))].map(
      function (item) {
        return {
          label: item.name,
          value: item._id,
        };
      }
    );
    setSpecializationList(tempList);
  };

  useEffect(() => {
    specializationListAction();
  }, []);

  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <legend className="line-removes">
                  <Button
                    className="go-back-btn-1"
                    bsStyle="info"
                    onClick={(e) => {
                      goBackFun(e);
                    }}
                  >
                    Back
                  </Button>
                </legend>
              }
              content={
                <Form
                  horizontal
                  noValidate
                  validated={validated}
                  onSubmit={(e) => submitHandler(e)}
                  className="add-specialization_form"
                >
                  <fieldset>
                    <FormGroup>
                      <Col md={6}>
                        <Col componentClass={ControlLabel}>Specialization</Col>
                        <Select
                          placeholder="Select specializations"
                          name="specialization"
                          id="specialization"
                          closeOnSelect={false}
                          multi={true}
                          value={specialization}
                          options={specializationList}
                          onChange={(e) => {
                            handleSpecialization(e);
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document Name</ControlLabel>
                            <FormControl
                              type="text"
                              name="name"
                              id="description"
                              placeholder="Description"
                              autoComplete="off"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              className={"form-control " + (errors.name || "")}
                              required
                              maxlength="250"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <Col sm={12}>
                        <Row>
                          <Col md={6}>
                            <ControlLabel>Document upload</ControlLabel>
                            <FormControl
                              type="file"
                              //multiple
                              //key={formData.documentFile || ""}
                              name="documentFile"
                              onChange={(e) => {
                                inputHandler(e);
                              }}
                              placeholder="File"
                              className={
                                "form-control " + (errors.documentFile || "")
                              }
                              required
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                  <fieldset>
                    <FormGroup>
                      <ControlLabel className="col-sm-2"></ControlLabel>
                      <Col sm={12} className="btn_wrap">
                        <Row>
                          <Col md={12}>
                            <Button
                              type="submit"
                              className="btn-fill btn btn-primary"
                              disabled={isLoading}
                            >
                              {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                              Save
                            </Button>
                            <Col md={12}>
                              {successMsg !== "" && (
                                <p
                                  className="alert-heading"
                                  style={{ color: "#155724" }}
                                >
                                  {successMsg}
                                </p>
                              )}
                            </Col>
                            &nbsp;
                          </Col>
                          {isLoading ? (
                            <Loading
                              type="bars"
                              color="#000000"
                              style={{ margin: "0px auto", width: "40px" }}
                            />
                          ) : (
                            ""
                          )}
                        </Row>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    msg: state.clinicDocument.message,
    AddClinicDocument: state.clinicDocument.isAddClinicDocument,
    isAddClinicDocument: state.clinicDocument.isAddClinicDocument,
    isAddClinicDocumentError: state.clinicDocument.isAddClinicDocumentError,
  };
}
export default withRouter(
  connect(mapStateToProps, { addClinicDocumentAction })(PreferenceToSet)
);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

// user
export const GET_SIGNIN_USER_SUCCESS = "GET_SIGNIN_USER_SUCCESS"
export const GET_SIGNIN_USER_REQUEST = "GET_SIGNIN_USER_REQUEST"
export const GET_SIGNIN_USER_ERROR = "GET_SIGNIN_USER_ERROR"

export const GET_CLINIC_LIST_REQUEST = "GET_CLINIC_LIST_REQUEST"
export const GET_CLINIC_LIST_SUCCESS = "GET_CLINIC_LIST_SUCCESS"
export const GET_CLINIC_LIST_ERROR = "GET_CLINIC_LIST_ERROR"

export const GET_HOLIDAY_LIST_REQUEST = "GET_HOLIDAY_LIST_REQUEST"
export const GET_HOLIDAY_LIST_SUCCESS = "GET_HOLIDAY_LIST_SUCCESS"
export const GET_HOLIDAY_LIST_ERROR = "GET_HOLIDAY_LIST_ERROR"

export const GET_PATIENT_LIST_REQUEST = "GET_PATIENT_LIST_REQUEST"
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS"
export const GET_PATIENT_LIST_ERROR = "GET_PATIENT_LIST_ERROR"

export const GET_PATIENT_REGISTRATION_REQUEST = "GET_PATIENT_REGISTRATION_REQUEST"
export const GET_PATIENT_REGISTRATION_SUCCESS = "GET_PATIENT_REGISTRATION_SUCCESS"
export const GET_PATIENT_REGISTRATION_ERROR = "GET_PATIENT_REGISTRATION_ERROR"

export const GET_PATIENT_EDIT_REQUEST = "GET_PATIENT_EDIT_REQUEST"
export const GET_PATIENT_EDIT_SUCCESS = "GET_PATIENT_EDIT_SUCCESS"
export const GET_PATIENT_EDIT_ERROR = "GET_PATIENT_EDIT_ERROR"

export const GET_PATIENT_DETAIL_REQUEST = "GET_PATIENT_DETAIL_REQUEST"
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS"
export const GET_PATIENT_DETAIL_ERROR = "GET_PATIENT_DETAIL_ERROR"

export const GET_PATIENT_OUT_REQUEST = "GET_PATIENT_LIST_REQUEST"
export const GET_PATIENT_OUT_SUCCESS = "GET_PATIENT_LIST_SUCCESS"
export const GET_PATIENT_OUT_ERROR = "GET_PATIENT_LIST_ERROR"

export const GET_NEXT_VISIT_REQUEST = "GET_NEXT_VISIT_REQUEST"
export const GET_NEXT_VISIT_SUCCESS = "GET_NEXT_VISIT_SUCCESS"
export const GET_NEXT_VISIT_ERROR = "GET_NEXT_VISIT_ERROR"

export const GET_VISIT_LIST_REQUEST = "GET_VISIT_LIST_REQUEST"
export const GET_VISIT_LIST_SUCCESS = "GET_VISIT_LIST_SUCCESS"
export const GET_VISIT_LIST_ERROR = "GET_VISIT_LIST_ERROR"
export const GET_FILTERED_VISIT_LIST = "GET_FILTERED_VISIT_LIST"

export const SET_ACTIVE_TAB_REQUEST = "SET_ACTIVE_TAB_REQUEST"
export const SET_ACTIVE_TAB_SUCCESS = "SET_ACTIVE_TAB_SUCCESS"
export const SET_ACTIVE_TAB_ERROR = "SET_ACTIVE_TAB_ERROR"

export const UPDATE_VISIT_LIST_REQUEST = "UPDATE_VISIT_LIST_REQUEST"
export const UPDATE_VISIT_LIST_SUCCESS = "UPDATE_VISIT_LIST_SUCCESS"
export const UPDATE_VISIT_LIST_ERROR = "UPDATE_VISIT_LIST_ERROR"

export const UPDATE_VISIT_LIST_REQUEST_EDUCATOR = "UPDATE_VISIT_LIST_REQUEST_EDUCATOR"
export const UPDATE_VISIT_LIST_EDUCATOR_SUCCESS = "UPDATE_VISIT_LIST_EDUCATOR_SUCCESS"
export const UPDATE_VISIT_LIST_EDUCATOR_ERROR = "UPDATE_VISIT_LIST_EDUCATOR_ERROR"

export const ADD_TO_VISIT_LIST_SUCCESS = "Add_TO_VISIT_LIST_SUCCESS"

export const SET_ADD_TO_VISIT_LIST = "SET_ADD_TO_VISIT_LIST"

export const RESET_VISIT_LIST = "RESET_VISIT_LIST"

export const UPDATE_VISIT_LIST_KNOWLEDGE_REQUEST = "UPDATE_VISIT_LIST_KNOWLEDGE_REQUEST"

export const UPDATE_VISIT_LIST_TASK_REQUEST = "UPDATE_VISIT_LIST_TASK_REQUEST"

export const UPDATE_VISIT_LIST_DIET_REQUEST = "UPDATE_VISIT_LIST_DIET_REQUEST"

export const UPDATE_VISIT_LIST_APP_REQUEST = "UPDATE_VISIT_LIST_APP_REQUEST"

export const UPDATE_PATIENT_VISIT_DETAIL = "UPDATE_PATIENT_VISIT_DETAIL"

export const UPDATE_PATIENT_VISIT_DETAIL_EDUCATOR = "UPDATE_PATIENT_VISIT_DETAIL_EDUCATOR"

export const UPDATE_VISIT_LIST_NEXT_VISIT_DATE_REQUEST = "UPDATE_VISIT_LIST_NEXT_VISIT_DATE_REQUEST"

export const UPDATE_VISIT_LIST_PRESCRIPTION_REQUEST = "UPDATE_VISIT_LIST_PRESCRIPTION_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_DETAIL_REQUEST = "UPDATE_VISIT_LIST_PATIENT_DETAIL_REQUEST"

export const SORT_VISIT_LIST_ACTION_ECONSULTATION = "SORT_VISIT_LIST_ACTION_ECONSULTATION"

export const TAG_LIST_ACTION_REQUEST = "TAG_LIST_ACTION_REQUEST"

export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS"

export const GET_TAG_LIST_ERROR = "GET_TAG_LIST_ERROR"

export const S3_SIGNED_URL_ARRAY_REQUEST = "S3_SIGNED_URL_ARRAY_REQUEST"

export const S3_SIGNED_URL_ARRAY_SUCCESS = "S3_SIGNED_URL_ARRAY_SUCCESS"

export const S3_SIGNED_URL_ARRAY_ERROR = "S3_SIGNED_URL_ARRAY_ERROR"

export const UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_REQUEST = "UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_REQUEST"

export const UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_ONLINE_LIST_REQUEST = "UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_ONLINE_LIST_REQUEST"

export const UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_OUT_LIST_REQUEST = "UPDATE_VISIT_LIST_S3_SIGNED_URL_ARRAY_OUT_LIST_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_TAGS_REQUEST = "UPDATE_VISIT_LIST_PATIENT_TAGS_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_A_TAG_REQUEST = "UPDATE_VISIT_LIST_PATIENT_A_TAG_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_B_TAG_REQUEST = "UPDATE_VISIT_LIST_PATIENT_B_TAG_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_C_TAG_REQUEST = "UPDATE_VISIT_LIST_PATIENT_C_TAG_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_STAR_STATUS_REQUEST = "UPDATE_VISIT_LIST_PATIENT_STAR_STATUS_REQUEST"

export const UPDATE_VISIT_LIST_MESSAGE_FOR_EDUCATOR_REQUEST = "UPDATE_VISIT_LIST_MESSAGE_FOR_EDUCATOR_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_REMARK_REQUEST = "UPDATE_VISIT_LIST_PATIENT_REMARK_REQUEST"

export const UPDATE_VISIT_LIST_PATIENT_INSULIN_DOSE_REQUEST = "UPDATE_VISIT_LIST_PATIENT_INSULIN_DOSE_REQUEST"

export const UPDATE_VISIT_LIST_REPORT_UNREAD_COUNT_SHARE_REPORT_REQUEST = "UPDATE_VISIT_LIST_REPORT_UNREAD_COUNT_SHARE_REPORT_REQUEST"

export const UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_VIEW_REPORT_REQUEST = "UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_VIEW_REPORT_REQUEST"

export const UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_DELETE_REPORT_REQUEST = "UPDATE_VISIT_LIST_REPORT_REPORT_COUNT_DELETE_REPORT_REQUEST"

export const STAR_STATUS_LIST_REQUEST = "STAR_STATUS_LIST_REQUEST"

export const STAR_STATUS_LIST_SUCCESS = "STAR_STATUS_LIST_SUCCESS"

export const STAR_STATUS_LIST_ERROR = "STAR_STATUS_LIST_ERROR"

export const GET_ONLINE_VISIT_LIST_SUCCESS = "GET_ONLINE_VISIT_LIST_SUCCESS"

export const VISIT_DETAIL_SUCCESS = "VISIT_DETAIL_SUCCESS"
export const GET_VISIT_DETAIL_REQUEST = "GET_VISIT_DETAIL_REQUEST"
export const VISIT_DETAIL_ERROR = "VISIT_DETAIL_ERROR"

export const GET_ADD_VISIT_REQUEST = "GET_ADD_VISIT_REQUEST"
export const GET_ADD_VISIT_SUCCESS = "GET_ADD_VISIT_SUCCESS"
export const GET_ADD_VISIT_ERROR = "GET_ADD_VISIT_ERROR"

export const GET_EDUCATOR_MSG_LIST_REQUEST = "GET_EDUCATOR_MSG_LIST_REQUEST"
export const GET_EDUCATOR_MSG_LIST_SUCCESS = "GET_EDUCATOR_MSG_LIST_SUCCESS"
export const GET_EDUCATOR_MSG_LIST_ERROR = "GET_EDUCATOR_MSG_LIST_ERROR"

export const GET_CATEGORY_LIST_REQUEST = "GET_CATEGORY_LIST_REQUEST"
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS"
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR"

export const GET_KNOWLEDGE_LIST_REQUEST = "GET_KNOWLEDGE_LIST_REQUEST"
export const GET_KNOWLEDGE_LIST_SUCCESS = "GET_KNOWLEDGE_LIST_SUCCESS"
export const GET_KNOWLEDGE_LIST_ERROR = "GET_KNOWLEDGE_LIST_ERROR"

export const GET_STATE_LIST_REQUEST = "GET_STATE_LIST_REQUEST"
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS"
export const GET_STATE_LIST_ERROR = "GET_STATE_LIST_ERROR"

export const GET_COUNTRY_LIST_REQUEST = "GET_COUNTRY_LIST_REQUEST"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR"

export const GET_PLAN_LIST_REQUEST = "GET_PLAN_LIST_REQUEST"
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS"
export const GET_PLAN_LIST_ERROR = "GET_PLAN_LIST_ERROR"

export const GET_FILE_LIST_REQUEST = "GET_PLAN_LIST_REQUEST"
export const GET_FILE_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS"
export const GET_FILE_LIST_ERROR = "GET_PLAN_LIST_ERROR"

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"

export const GET_TREATMENT_WITH_TYPES_REQUEST = "GET_TREATMENT_WITH_TYPES_REQUEST"
export const GET_TREATMENT_WITH_TYPES_SUCCESS = "GET_TREATMENT_WITH_TYPES_SUCCESS"
export const GET_TREATMENT_WITH_TYPES_ERROR = "GET_TREATMENT_WITH_TYPES_ERROR"

export const GET_UNIT_LIST_REQUEST = "GET_UNIT_LIST_REQUEST"
export const GET_UNIT_LIST_SUCCESS = "GET_UNIT_LIST_SUCCESS"
export const GET_UNIT_LIST_ERROR = "GET_UNIT_LIST_ERROR"

export const GET_TIME_LIST_REQUEST = "GET_TIME_LIST_REQUEST"
export const GET_TIME_LIST_SUCCESS = "GET_TIME_LIST_SUCCESS"
export const GET_TIME_LIST_ERROR = "GET_TIME_LIST_ERROR"

export const GET_ADD_COMMENT_REQUEST = "GET_ADD_COMMENT_REQUEST"
export const GET_ADD_COMMENT_SUCCESS = "GET_ADD_COMMENT_SUCCESS"
export const GET_ADD_COMMENT_ERROR = "GET_ADD_COMMENT_ERROR"

//Diet
export const GET_DIET_LIST_SUCCESS = "GET_DIET_LIST_SUCCESS"
export const GET_DIET_LIST_ERROR = "GET_DIET_LIST_ERROR"

export const PRINT_DIET_REQUEST = "PRINT_DIET_REQUEST"
export const PRINT_DIET_SUCCESS = "PRINT_DIET_SUCCESS"
export const PRINT_DIET_ERROR = "PRINT_DIET_ERROR"

export const SHARE_DIET_REQUEST = "SHARE_DIET_REQUEST"
export const SHARE_DIET_SUCCESS = "SHARE_DIET_SUCCESS"
export const SHARE_DIET_ERROR = "SHARE_DIET_ERROR"

export const ADD_TASK_REQUEST = "ADD_TASK_REQUEST"
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS"
export const ADD_TASK_ERROR = "ADD_TASK_ERROR"

export const PRINT_SHARE_DOCUMENT_REQUEST = "PRINT_SHARE_DOCUMENT_REQUEST"
export const PRINT_SHARE_DOCUMENT_SUCCESS = "PRINT_SHARE_DOCUMENT_SUCCESS"
export const PRINT_SHARE_DOCUMENT_ERROR = "PRINT_SHARE_DOCUMENT_ERROR"

export const GET_TASK_LIST_REQUEST = "GET_TASK_LIST_REQUEST"
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS"
export const GET_TASK_LIST_ERROR = "GET_TASK_LIST_ERROR"

export const ADD_FAQ_REQUEST = "ADD_FAQ_REQUEST"
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS"
export const ADD_FAQ_ERROR = "ADD_FAQ_ERROR"

export const UPLOAD_MULTIFILE_REQUEST = "UPLOAD_MULTIFILE_REQUEST"
export const UPLOAD_MULTIFILE_SUCCESS = "UPLOAD_MULTIFILE_SUCCESS"
export const UPLOAD_MULTIFILE_ERROR = "UPLOAD_MULTIFILE_ERROR"

export const ADD_KNOWLEDGE_REQUEST = "ADD_KNOWLEDGE_REQUEST"
export const ADD_KNOWLEDGE_SUCCESS = "ADD_KNOWLEDGE_SUCCESS"
export const ADD_KNOWLEDGE_ERROR = "ADD_KNOWLEDGE_ERROR"

export const ADD_CLINIC_LINK_REQUEST = "ADD_CLINIC_LINK_REQUEST"
export const ADD_CLINIC_LINK_SUCCESS = "ADD_CLINIC_LINK_SUCCESS"
export const ADD_CLINIC_LINK_ERROR = "ADD_CLINIC_LINK_ERROR"

export const ADD_EDUCATOR_MSG_REQUEST = "ADD_EDUCATOR_MSG_REQUEST"
export const ADD_EDUCATOR_MSG_SUCCESS = "ADD_EDUCATOR_MSG_SUCCESS"
export const ADD_EDUCATOR_MSG_ERROR = "ADD_EDUCATOR_MSG_ERROR"

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR"

export const CHANGE_STATUS_REQUEST = "CHANGE_STATUS_REQUEST"
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS"
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"

export const CHANGE_USER_PASSWORD_REQUEST = "CHANGE_USER_PASSWORD_REQUEST"
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS"
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR"

export const GET_TASK_SCREEN_DATA = "GET_TASK_SCREEN_DATA"
export const SET_TASK_SCREEN_DATA = "SET_TASK_SCREEN_DATA"

export const GET_TASK_HIEGHT_WEIGHT_DATA = "GET_TASK_HIEGHT_WEIGHT_DATA"
export const SET_TASK_HEIGHT_WEIGHT_DATA = "SET_TASK_HEIGHT_WEIGHT_DATA"

export const GET_EMR_DATA = "GET_EMR_DATA"
export const SET_EMR_DATA = "SET_EMR_DATA"
export const SET_VITAL_SIGNS_DATA = "SET_VITAL_SIGNS_DATA"
export const SET_LAB_DATA = "SET_LAB_DATA"

export const ADD_CLINIC_HOLIDAY_REQUEST = "ADD_CLINIC_HOLIDAY_REQUEST"
export const ADD_CLINIC_HOLIDAY_SUCCESS = "ADD_CLINIC_HOLIDAY_SUCCESS"
export const ADD_CLINIC_HOLIDAY_ERROR = "ADD_CLINIC_HOLIDAY_ERROR"

export const CHAT_USER_LIST_REQUEST = "CHAT_USER_LIST_REQUEST"
export const CHAT_USER_LIST_SUCCESS = "CHAT_USER_LIST_SUCCESS"
export const CHAT_USER_LIST_ERROR = "CHAT_USER_LIST_ERROR"

export const GET_PATIENT_CHAT_REQUEST = "GET_PATIENT_CHAT_REQUEST"
export const GET_PATIENT_CHAT_SUCCESS = "GET_PATIENT_CHAT_SUCCESS"
export const GET_PATIENT_CHAT_ERROR = "GET_PATIENT_CHAT_ERROR"

export const GET_SPECIALIZATIONS_REQUEST = "GET_SPECIALIZATIONS_REQUEST"
export const GET_SPECIALIZATIONS_SUCCESS = "GET_SPECIALIZATIONS_SUCCESS"
export const GET_SPECIALIZATIONS_ERROR = "GET_SPECIALIZATIONS_ERROR"

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const CHANGE_USER_STATUS_REQUEST = "CHANGE_USER_STATUS_REQUEST"
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS"
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR"

export const HISTORY_REQUEST = "HISTORY_REQUEST"
export const HISTORY_SUCCESS = "HISTORY_SUCCESS"
export const HISTORY_ERROR = "HISTORY_ERROR"

export const FORWARD_CHAT_REQUEST = "FORWARD_CHAT_REQUEST"
export const FORWARD_CHAT_SUCCESS = "FORWARD_CHAT_SUCCESS"
export const FORWARD_CHAT_ERROR = "FORWARD_CHAT_ERROR"

export const GET_CHAT_COUNT_REQUEST = "GET_CHAT_COUNT_REQUEST"
export const GET_CHAT_COUNT_SUCCESS = "GET_CHAT_COUNT_SUCCESS"
export const GET_CHAT_COUNT_ERROR = "GET_CHAT_COUNT_ERROR"
export const RESET_IS_REDUCE_CHAT_COUNT = "RESET_IS_REDUCE_CHAT_COUNT"
export const PATIENT_CHAT_LIST_SUCCESS = "PATIENT_CHAT_LIST_SUCCESS"



export const READ_CHAT_REQUEST = "READ_CHAT_REQUEST"
export const READ_CHAT_SUCCESS = "READ_CHAT_SUCCESS"
export const READ_CHAT_ERROR = "READ_CHAT_ERROR"

export const ADD_CONTACT_US_REQUEST = "ADD_CONTACT_US_REQUEST"
export const ADD_CONTACT_US_SUCCESS = "ADD_CONTACT_US_SUCCESS"
export const ADD_CONTACT_US_ERROR = "ADD_CONTACT_US_ERROR"

export const UPDATE_PATIENT_STATUS_CHANGE_REQUEST = "UPDATE_PATIENT_STATUS_CHANGE_REQUEST"
export const UPDATE_PATIENT_STATUS_CHANGE_SUCCESS = "UPDATE_PATIENT_STATUS_CHANGE_SUCCESS"
export const UPDATE_PATIENT_STATUS_CHANGE_ERROR = "UPDATE_PATIENT_STATUS_CHANGE_ERROR"

export const GET_PATIENT_NOTIFICATION_REQUEST = "GET_PATIENT_NOTIFICATION_REQUEST"
export const GET_PATIENT_NOTIFICATION_SUCCESS = "GET_PATIENT_NOTIFICATION_SUCCESS"
export const GET_PATIENT_NOTIFICATION_ERROR = "GET_PATIENT_NOTIFICATION_ERROR"

export const PRINT_DIET_COLOR_CHANGE_REQUEST = "PRINT_DIET_COLOR_CHANGE_REQUEST"
export const PRINT_DIET_COLOR_CHANGE_SUCCESS = "PRINT_DIET_COLOR_CHANGE_SUCCESS"
export const PRINT_DIET_COLOR_CHANGE_ERROR = "PRINT_DIET_COLOR_CHANGE_ERROR"

export const GET_FORGOT_PASSWARD_REQUEST = "GET_FORGOT_PASSWARD_REQUEST"
export const GET_FORGOT_PASSWARD_SUCCESS = "GET_FORGOT_PASSWARD_SUCCESS"
export const GET_FORGOT_PASSWARD_ERROR = "GET_FORGOT_PASSWARD_ERROR"

export const GET_RESET_PASSWARD_REQUEST = "GET_RESET_PASSWARD_REQUEST"
export const GET_RESET_PASSWARD_SUCCESS = "GET_RESET_PASSWARD_SUCCESS"
export const GET_RESET_PASSWARD_ERROR = "GET_RESET_PASSWARD_ERROR"

export const GET_MYCLINIC_LIST_REQUEST = "GET_MYCLINIC_LIST_REQUEST"
export const GET_MYCLINIC_LIST_SUCCESS = "GET_MYCLINIC_LIST_SUCCESS"
export const GET_MYCLINIC_LIST_ERROR = "GET_MYCLINIC_LIST_ERROR"

export const GET_KNOWLEDGE_MSG_LIST_REQUEST = "GET_KNOWLEDGE_MSG_LIST_REQUEST"
export const GET_KNOWLEDGE_MSG_LIST_SUCCESS = "GET_KNOWLEDGE_MSG_LIST_SUCCESS"
export const GET_KNOWLEDGE_MSG_LIST_ERROR = "GET_KNOWLEDGE_MSG_LIST_ERROR"

export const GET_USER_DETAIL_REQUEST = "GET_USER_DETAIL_REQUEST"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR"

export const GET_KNOWLEDGE_CLINIC_SHOW_STATUS_REQUEST = "GET_KNOWLEDGE_CLINIC_SHOW_STATUS_REQUEST"
export const GET_KNOWLEDGE_CLINIC_SHOW_STATUS_SUCCESS = "GET_KNOWLEDGE_CLINIC_SHOW_STATUS_SUCCESS"
export const GET_KNOWLEDGE_CLINIC_SHOW_STATUS_ERROR = "GET_KNOWLEDGE_CLINIC_SHOW_STATUS_ERROR"

export const GET_LINK_CLINIC_SHOW_STATUS_REQUEST = "GET_LINK_CLINIC_SHOW_STATUS_REQUEST"
export const GET_LINK_CLINIC_SHOW_STATUS_SUCCESS = "GET_LINK_CLINIC_SHOW_STATUS_SUCCESS"
export const GET_LINK_CLINIC_SHOW_STATUS_ERROR = "GET_LINK_CLINIC_SHOW_STATUS_ERROR"

export const GET_MESSAGE_CLINIC_SHOW_STATUS_REQUEST = "GET_MESSAGE_CLINIC_SHOW_STATUS_REQUEST"
export const GET_MESSAGE_CLINIC_SHOW_STATUS_SUCCESS = "GET_MESSAGE_CLINIC_SHOW_STATUS_SUCCESS"
export const GET_MESSAGE_CLINIC_SHOW_STATUS_ERROR = "GET_MESSAGE_CLINIC_SHOW_STATUS_ERROR"

export const UPDATE_ADDITIONAL_COMMENT_REQUEST = "UPDATE_ADDITIONAL_COMMENT_REQUEST"
export const UPDATE_ADDITIONAL_COMMENT_SUCCESS = "UPDATE_ADDITIONAL_COMMENT_SUCCESS"
export const UPDATE_ADDITIONAL_COMMENT_ERROR = "UPDATE_ADDITIONAL_COMMENT_ERROR"

export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST"
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"

export const ADD_CLINIC_INVITATION_REQUEST = "ADD_CLINIC_INVITATION_REQUEST"
export const ADD_CLINIC_INVITATION_SUCCESS = "ADD_CLINIC_INVITATION_SUCCESS"
export const ADD_CLINIC_INVITATION_ERROR = "ADD_CLINIC_INVITATION_ERROR"

export const GET_GRAPH_REPORT_REQUEST = "GET_GRAPH_REPORT_REQUEST"
export const GET_GRAPH_REPORT_SUCCESS = "GET_GRAPH_REPORT_SUCCESS"
export const GET_GRAPH_REPORT_ERROR = "GET_GRAPH_REPORT_ERROR"

//Meghna
export const GET_NOTIFICATION_LIST_REQUEST = "GET_NOTIFICATION_LIST_REQUEST"
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS"
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR"

export const ADD_NOTIFICATION_MSG_REQUEST = "ADD_NOTIFICATION_MSG_REQUEST"
export const ADD_NOTIFICATION_MSG_SUCCESS = "ADD_NOTIFICATION_MSG_SUCCESS"
export const ADD_NOTIFICATION_MSG_ERROR = "ADD_NOTIFICATION_MSG_ERROR"

export const EDIT_NOTIFICATION_MSG_REQUEST = "EDIT_NOTIFICATION_MSG_REQUEST"
export const EDIT_NOTIFICATION_MSG_SUCCESS = "EDIT_NOTIFICATION_MSG_SUCCESS"
export const EDIT_NOTIFICATION_MSG_ERROR = "EDIT_NOTIFICATION_MSG_ERROR"

export const NOTIFICATION_MSG_STATUS_REQUEST = "NOTIFICATION_MSG_STATUS_REQUEST"
export const NOTIFICATION_MSG_STATUS_SUCCESS = "NOTIFICATION_MSG_STATUS_SUCCESS"
export const NOTIFICATION_MSG_STATUS_ERROR = "NOTIFICATION_MSG_STATUS_ERROR"

export const GET_DIET_HISTORY_LIST_REQUEST = "GET_DIET_HISTORY_LIST_REQUEST"
export const GET_DIET_HISTORY_LIST_SUCCESS = "GET_DIET_HISTORY_LIST_SUCCESS"
export const GET_DIET_HISTORY_LIST_ERROR = "GET_DIET_HISTORY_LIST_ERROR"

export const GET_CLINIC_HOLIDAY_HISTORY_REQUEST = "GET_CLINIC_HOLIDAY_HISTORY_REQUEST"
export const GET_CLINIC_HOLIDAY_HISTORY_SUCCESS = "GET_CLINIC_HOLIDAY_HISTORY_SUCCESS"
export const GET_CLINIC_HOLIDAY_HISTORY_ERROR = "GET_CLINIC_HOLIDAY_HISTORY_ERROR"

export const SEND_MAIL_REQUEST = "SEND_MAIL_REQUEST"
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS"
export const SEND_MAIL_ERROR = "SEND_MAIL_ERROR"


export const CANCEL_DIET_REQUEST = "CANCEL_DIET_REQUEST"
export const CANCEL_DIET_SUCCESS = "CANCEL_DIET_SUCCESS"
export const CANCEL_DIET_ERROR = "CANCEL_DIET_ERROR"

export const GET_LANGUAGE_LIST_REQUEST = "GET_LANGUAGE_LIST_REQUEST"
export const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS"
export const GET_LANGUAGE_LIST_ERROR = "GET_LANGUAGE_LIST_ERROR"

export const ADD_COMMENT_MSG_REQUEST = "ADD_COMMENT_MSG_REQUEST"
export const ADD_COMMENT_MSG_SUCCESS = "ADD_COMMENT_MSG_SUCCESS"
export const ADD_COMMENT_MSG_ERROR = "ADD_COMMENT_MSG__ERROR"

export const CHAT_TEMPLATE_LIST_REQUEST = "CHAT_TEMPLATE_LIST_REQUEST"
export const CHAT_TEMPLATE_LIST_SUCCESS = "CHAT_TEMPLATE_LIST_SUCCESS"
export const CHAT_TEMPLATE_LIST_ERROR = "CHAT_TEMPLATE_LIST_ERROR"

export const EDIT_CHAT_TEMPLATE_REQUEST = "EDIT_CHAT_TEMPLATE_REQUEST"
export const EDIT_CHAT_TEMPLATE_SUCCESS = "EDIT_CHAT_TEMPLATE_SUCCESS"
export const EDIT_CHAT_TEMPLATE_ERROR = "EDIT_CHAT_TEMPLATE_ERROR"

export const DELETE_CHAT_TEMPLATE_REQUEST = "DELETE_CHAT_TEMPLATE_REQUEST"
export const DELETE_CHAT_TEMPLATE_SUCCESS = "DELETE_CHAT_TEMPLATE_SUCCESS"
export const DELETE_CHAT_TEMPLATE_ERROR = "DELETE_CHAT_TEMPLATE_ERROR"

export const GET_LAB_TEST_LIST_REQUEST = "GET_LAB_TEST_LIST_REQUEST"
export const SET_LAB_TEST_DATA = "SET_LAB_TEST_DATA"
export const SET_LAB_TEST_LIST_DATA = "SET_LAB_TEST_LIST_DATA"

export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_REQUEST = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_REQUEST"
export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_SUCCESS = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_SUCCESS"
export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_ERROR = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_CLINIC_ERROR"

export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_REQUEST = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_REQUEST"
export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_SUCCESS = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_SUCCESS"
export const S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_ERROR = "S3_SIGNED_URL_ARRAY_UPLOAD_DOCUMENT_ADMIN_ERROR"

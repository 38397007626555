/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from 'react';
import { postRequestWithToken } from "../../api/helper.js"
import { withRouter } from "react-router-dom";


const ImageComponent = (props) => {
    const [imagePath, setImagePath] = useState('')

    let strUrl = ""
    useEffect(() => {
        const getSignedUrl = async () => {
            let resp = await postRequestWithToken({ filePath: props.data }, 's3SignedUrl');
            setImagePath(resp.data.url)
        };
        getSignedUrl();
    }, []);
    return (< img {...props}
        src={imagePath}
    />)
}

export default withRouter(ImageComponent);
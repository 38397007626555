/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Col,
  OverlayTrigger,
  Tooltip,
  Row,
  FormGroup,
  Modal,
} from "react-bootstrap";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  visitListAction,
  patientOutAction,
  holidayListAction,
  setVisitListActiveTabAction,
  updateVisitListAction,
  tagListAction,
  s3SiginedUrlArrayAction,
  UpdateVisitListS3SiginedUrlArrayAction,
  UpdateVisitListS3SiginedUrlArrayOnlineListAction,
  UpdateVisitListS3SiginedUrlArrayOutListAction,
  getOnlineVisitListAction,
  starStatusListAction,
} from "Front/actions/home";
import { taskScreenAction } from "Front/actions/taskScreen";
import { emrSetAction } from "Front/actions/emr";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "react-loading";
import moment from "moment";
import TaskScreen from "Front/views/TaskScreen/TaskScreenPopup.jsx";
import NextVisit from "Front/views/Home/NextVisitPopup.jsx";
import loadingImg from "../../assets/img/loading.gif";
import mobileImg from "../../assets/img/mobile-r.png";
import mobilegreenImg from "../../assets/img/mobile-g.png";
import "react-datepicker/dist/react-datepicker.css";
import Reports from "../TaskScreen/Reports";
import Calendar from "Front/components/Calendar";
import EventEmitter from "Front/actions/events.js";
import SideModal from "../../components/common/SideModal";
import AdSlider from "./AdSlider";
import CardIcon from "../../assets/img/icons/card-icon.svg";
import ScheduleIc from "../../assets/img/icons/calendar-ic.svg";
import NotificationIc from "../../assets/img/icons/notification-ic.svg";
import CalendarIc from "../../assets/img/icons/calendar-ic.svg";
import DeleteIc from "../../assets/img/icons/out-btn.png";
import PrescriptionGreenIc from "../../assets/img/icons/PrescriptionGreen.png";
import PrescriptionRedIc from "../../assets/img/icons/PrescriptionRed.png";
import PrescriptionYellowIc from "../../assets/img/icons/PrescOrangeIc.png";
import MoreIc from "../../assets/img/icons/more.svg";
import PasswordIc from "../../assets/img/icons/password.png";
import DoctorDashboard from "../../api/doctorDashboard";
import $ from "jquery";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import VisitListButton from "./VisitListButton";
import VisitListTable from "./VisitListTable";
import PatientMoreDetail from "./PatientMoreDetail";

class DoctorScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visitList: this.props.otherVisitDate ? [] : this.props.VisitList,
      allvisitList: this.props.otherVisitDate ? [] : this.props.VisitList,
      search: this.props.search,
      showProcessing: false,
      search_date: this.props.search_date,
      outId: "",
      taskScreen: false,
      nextVisit: false,
      taskScreenData: [],
      nextVisitData: [],
      patientData: {},
      patientOut: false,
      holidayList: [],
      econsultation:
        this.props.history?.location?.econsultation === true ? true : false,
      eVisitCount: this.props.otherVisitDate ? 0 : this.props.eVisitCount,
      visitCount: this.props.otherVisitDate ? 0 : this.props.visitCount,
      reportsModel: false,
      openSideModel: false,
      componentToShow: "Schedule",
      patientPasswordData: {},
      isLoading: true,
      isNoDataFound: false,
      _notificationSystem: null,
      isVisitListCalled: false,
      visitLimit: 10,
      activeTab:
        this.props.history?.location?.econsultation === true
          ? "online"
          : "offline",
      outPatientCount: this.props.otherVisitDate
        ? 0
        : this.props.outPatientCount,
      moreDetails: false,
      moreDetailsData: {},
      starStatus: "",
    };

    this.onDismiss = this.onDismiss.bind(this);
    this.onDismissNextVisit = this.onDismissNextVisit.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.patientVisitOut = this.patientVisitOut.bind(this);
    this.getRedirect = this.getRedirect.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.sortvisitListData = this.sortvisitListData.bind(this);
    this.funModelStatus = this.funModelStatus.bind(this);
    this.handleSideModal = this.handleSideModal.bind(this);
    this.handleCommon = this.handleCommon.bind(this);
    this.tagsContent = this.tagsContent.bind(this);
    this.s3UrlIntervalFlag = null;

    //this.refresh = this.refresh.bind(this);
    EventEmitter.subscribe(
      "reload_home",
      this.fetchReloadDashboardFun.bind(this)
    );
    this.showPassword = this.showPassword.bind(this);
    this.password = this.password.bind(this);
    this.showCalander = this.showCalander.bind(this);
    this.getVisitList = this.getVisitList.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.taskScreen = this.taskScreen.bind(this);
    this.nameContent = this.nameContent.bind(this);
    this.get_age_by_dob = this.get_age_by_dob.bind(this);
    this.isApp = this.isApp.bind(this);
    this.action = this.action.bind(this);
    this.moreDetailsButton = this.moreDetailsButton.bind(this);
    this.onDismissMoreDetail = this.onDismissMoreDetail.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isTagList) this.props.tagListAction();

    if (!this.props.isStarStatusList) this.props.starStatusListAction();

    if (this.props.history?.location?.goToHome) {
      // let visitData =   !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let visitData = [];
      if (this.props.history?.location?.activeTab === "OutPatientList") {
        visitData = this.props.outPatientList;
        this.setState({ activeTab: "OutPatientList" });
      } else if (this.props.history?.location?.activeTab === "online") {
        visitData = this.props.eVisitList;
        this.setState({ activeTab: "online" });
      } else {
        visitData = this.state.visitList;
        this.setState({ activeTab: "offline" });
      }
      // let visitData =  this.props.history?.location?.activeTab === 'OutPatientList' ? this.state.outPatientList : !this.state.econsultation ? this.state.visitList:this.props.eVisitList;
      let homePid = this.props.history?.location?.goToHome;
      var localindex_index = visitData
        .map(function (el) {
          return el.pId;
        })
        .indexOf(homePid);
      if (localindex_index != -1) {
        let localData = visitData[localindex_index];
        visitData.splice(localindex_index, 1);
        visitData.unshift(localData);
      }
      this.setState({ visitList: visitData });
    }
    this.s3UrlIntervalFlag = setInterval(() => {
      if (this.props.TagList.length > 0) {
        let payload = [];
        for (let i in this.props.TagList) {
          let obj = {
            filePath: this.props.TagList[i].image,
            id: this.props.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }
    }, 810000);

    this.setState({ _notificationSystem: this.refs.notificationSystem });
    // let response = await DoctorDashboard.getIsNewNotes();
    //     if (response.statusCode == 200) {
    //         this.setState({ newNotes: response.newNotes });
    //     }

    let response = await DoctorDashboard.getIsNewNotification();
    if (response.statusCode == 200) {
      this.setState({
        newNotification: response.flag === "new" ? true : false,
      });
    }
    this.props.holidayListAction();
    let callVisit = true;
    let _this = this;
    const search_date = this.props.search_date;
    // appConstants.socket.on("updateTaskScreen", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //       _this.setState({isVisitListCalled:true})
    //     _this.props.visitListAction(search_date);

    //   }, 1000);
    // });

    // appConstants.socket.on("addPatient", function (responce) {
    //   callVisit = false;
    //   setTimeout(function () {
    //     _this.props.visitListAction(search_date);
    //   }, 1000);
    // });

    //  appConstants.socket.on("newPatientVisitData", function (response) {
    //         console.log('++++++++++++++++++++++++',response)
    //         _this.props.addToVisitListAction(response)

    //     });
    if (callVisit) {
      if (this.props.otherVisitDate) {
        if (this.state.econsultation)
          this.props.getOnlineVisitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD"),
            "",
            ""
          );
        else
          this.props.visitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD"),
            "",
            "",
            "searchDate"
          );
      } else {
        if (
          this.props.VisitList.length === 0 &&
          !this.props.isOfflineApiCalled
        ) {
          //console.log(this.state.search_date,"called from did mount")
          this.props.visitListAction(
            moment(this.state.search_date).format("YYYY-MM-DD")
          );
        } else {
          if (this.props.TagList.length > 0) {
            let payload = [];
            for (let i in this.props.TagList) {
              let obj = {
                filePath: this.props.TagList[i].image,
                id: this.props.TagList[i]._id,
              };
              payload.push(obj);
            }

            this.props.s3SiginedUrlArrayAction({ imageArray: payload });
          }
          if (this.state.isLoading === true) {
            this.setState({ isLoading: false });
          }
        }
      }
    }
    this.handleCommon();
    this.showCalander();
  }

  showCalander() {
    $(".cs-datepick input").removeAttr("readonly");
    $(".cs-datepick input").click(function (e) {
      if ($(e.target).attr("type") == "text") {
        $(".cs-datepick").addClass("rdtOpen");
      }
    });
    $("body").delegate(".rdtDays .rdtDay", "click", function () {
      $(this).parents(".rdtOpen").removeClass("rdtOpen");
    });
  }

  handleCommon() {
    let obj = this;
    $(document).ready(function () {
      $("body").click(function (e) {
        if (
          $(e.target).hasClass("calendar") == false &&
          $(e.target).parents(".calendar").length == 0 &&
          $(e.target).hasClass("note-modal") == false &&
          $(e.target).parents(".note-modal").length == 0 &&
          $(e.target).parents(".aside-panel").length == 0 &&
          $(e.target).hasClass("aside-panel") == false
        ) {
          obj.setState({ openSideModel: false });
        }
      });
    });
  }

  componentWillUnmount() {
    clearInterval(this.s3UrlIntervalFlag);
  }

  componentWillReceiveProps(nextProps) {
    let { allvisitList } = this.state;

    // if(nextProps.isVisitListUpdateS3SignedUrlOnlineList !== this.props.isVisitListUpdateS3SignedUrlOnlineList){
    //   this.setState({visitList:nextProps.eVisitList})
    // }

    // if(nextProps.isVisitListUpdateS3SignedUrlOutList !== this.props.isVisitListUpdateS3SignedUrlOutList){
    //   //console.log(nextProps,"nextpropsoutlist")
    //   this.setState({visitList:nextProps.outPatientList})
    // }

    if (nextProps.isReloadHome !== this.props.isReloadHome) {
      this.setState({
        activeTab: "offline",
        search_date: nextProps.search_date,
        visitList: nextProps.VisitList,
        econsultation: false,
      });
      if (nextProps.TagList.length > 0) {
        let payload = [];
        for (let i in nextProps.TagList) {
          let obj = {
            filePath: nextProps.TagList[i].image,
            id: nextProps.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }
    }

    if (
      nextProps.isVisitListUpdateS3SignedUrl !==
      this.props.isVisitListUpdateS3SignedUrl
    ) {
      let vistList =
        this.state.activeTab === "offline"
          ? nextProps.VisitList
          : this.state.activeTab === "online"
            ? nextProps.eVisitList
            : nextProps.outPatientList;
      this.setState({ visitList: vistList });
    }

    if (nextProps.isS3SignedUrlArr !== this.props.isS3SignedUrlArr) {
      this.props.UpdateVisitListS3SiginedUrlArrayAction();
    }

    // if(nextProps.isTagList !== this.props.isTagList){
    //   if(nextProps.TagList.length>0){
    //    let payload=[]
    //    for (let i in nextProps.TagList) {
    //      let obj={"filePath":nextProps.TagList[i].image,"id":nextProps.TagList[i]._id}
    //       payload.push(obj)
    //    }
    //   this.props.s3SiginedUrlArrayAction({"imageArray":payload})
    //   }
    // }

    if (nextProps.isAddToVisitList !== this.props.isAddToVisitList) {
      if (nextProps.TagList.length > 0) {
        let payload = [];
        for (let i in nextProps.TagList) {
          let obj = {
            filePath: nextProps.TagList[i].image,
            id: nextProps.TagList[i]._id,
          };
          payload.push(obj);
        }
        this.props.s3SiginedUrlArrayAction({ imageArray: payload });
      }

      if (this.state.activeTab === "online") {
        this.setState({
          visitList: nextProps.eVisitList,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        });
      }

      if (this.state.activeTab === "offline") {
        this.setState({
          visitList: nextProps.VisitList,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        });
      }
    }

    if (
      nextProps.isVisitListUpdated !== this.props.isVisitListUpdated &&
      this.props.isVisitListUpdated === false
    ) {
      this.state.activeTab === "online"
        ? this.setState({
          visitList: nextProps.eVisitList,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        })
        : this.setState({
          visitList: nextProps.VisitList,
          visitCount: nextProps.visitCount,
          eVisitCount: nextProps.eVisitCount,
          outPatientCount: nextProps.outPatientCount,
        });
    }

    if (
      nextProps.isVisitList !== this.props.isVisitList &&
      this.props.isVisitList === false
    ) {
      if (this.state.activeTab === "online") {
        this.setState({ visitList: nextProps.eVisitList, econsultation: true });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }
      if (this.state.activeTab === "offline") {
        this.setState({ visitList: nextProps.VisitList, econsultation: false });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }
      if (this.state.activeTab === "OutPatientList") {
        this.setState({ visitList: nextProps.outPatientList });
        if (nextProps.TagList.length > 0) {
          let payload = [];
          for (let i in nextProps.TagList) {
            let obj = {
              filePath: nextProps.TagList[i].image,
              id: nextProps.TagList[i]._id,
            };
            payload.push(obj);
          }
          this.props.s3SiginedUrlArrayAction({ imageArray: payload });
        }
      }

      this.setState({
        eVisitCount: nextProps.eVisitCount,
        visitCount: nextProps.visitCount,
        outPatientCount: nextProps.outPatientCount,
        showProcessing: false,
      });
      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }

      // if(this.state.visitLimit > 0 && nextProps.VisitList.data.count > 10){
      //   this.props.visitListAction(this.state.search_date);
      //   this.setState({visitLimit : 0});
      // }
    }

    if (
      nextProps.isHolidayList !== this.props.isHolidayList &&
      this.props.isHolidayList === false
    ) {
      this.setState({ holidayList: nextProps.HolidayList.list });
    }

    if (nextProps.isPatientOut !== this.props.isPatientOut) {
      const outId = this.state.outId;
      this.setState({ outId: "" });
      //console.log(nextProps,outId,"nextProps1111")
      //this.props.updateVisitListAction(nextProps.PatientOut.visitId,this.state.activeTab==="online"?true:false)

      let visitList = this.state.visitList;
      for (let x in visitList) {
        if (this.state.outId === visitList[x].id) {
          visitList[x].doctorOut = "out";
        }
      }

      allvisitList = visitList;

      this.setState({ visitList, allvisitList });

      // setTimeout(function () {
      //   let params = {
      //     clinicId: localStorage.getItem("clinicId"),
      //   };
      //   appConstants.socket.emit("updateTaskScreen", params);
      // }, 1000);
    }
  }

  password(cell, row) {
    let html = "";
    html = (
      <Link className="" onClick={(e) => this.showPassword(cell, row)}>
        <img
          className="table-action-ic"
          alt="Password"
          title="Password"
          src={PasswordIc}
        ></img>
      </Link>
    );
    return html;
  }

  showPassword(cell, row) {
    let tempObj = {
      patientId: row.patientId,
      name: `${row.firstName} ${row.lastName}`,
      password: row.show_password,
    };
    this.setState({
      showPassword: !this.state.showPassword,
      patientPasswordData: tempObj,
    });
  }

  isApp(cell, row) {
    let lastDiet =
      row && row.lastDiet && row.lastDiet.length > 0
        ? row.lastDiet[0].readStatus
        : "";

    let app = "";

    if (row.app) {
      app = (
        <span className="doc-label-flex doc-label" style={{ flexWrap: "wrap" }}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="remove">
                <span className="fa fa-check check" aria-hidden="true"></span>
              </Tooltip>
            }
          >
            <span className="">
              <img src={mobilegreenImg} alt="App" />
            </span>
          </OverlayTrigger>
        </span>
      );
    } else {
      app = (
        <span className="doc-label-flex doc-label" style={{ flexWrap: "wrap" }}>
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="remove">
                <span className="fa fa-times cross" aria-hidden="true"></span>
              </Tooltip>
            }
          >
            <span className="">
              <img src={mobileImg} alt="App" />
            </span>
          </OverlayTrigger>
        </span>
      );
    }

    return app;
    //var readStatus = 'read';
    // var patientReadStatus = "read";
    // for (let i in row.documentList) {
    //   if (row.documentList[i].patientRead === "unread") {
    //     patientReadStatus = "unread";
    //   }
    // }

    // var html = "";
    // if (lastDiet && lastDiet.length > 0) {
    //   if (lastDiet === "unread") {
    //     row["remarkHtml"] = "not seen by patient";
    //     if (row.remarkHtml !== "") {
    //       html = (
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
    //         >
    //           <span className="badge bgred drs-left  mobile-red-clr">
    //             <span className="fa fa-cutlery" aria-hidden="true"></span>
    //           </span>
    //         </OverlayTrigger>
    //       );
    //     }
    //   } else {
    //     row["remarkHtml"] = "seen by patient";
    //     if (row.remarkHtml !== "") {
    //       html = (
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={<Tooltip id="remove">{row.remarkHtml}</Tooltip>}
    //         >
    //           <span className="badge bgred drs-left mobile-green-clr">
    //             <span className="fa fa-cutlery" aria-hidden="true"></span>
    //           </span>
    //         </OverlayTrigger>
    //       );
    //     }
    //   }
    // }

    // let insulinCal = <InsulinDose visitInfo={row} callType="main" />;

    // if (row.documentList["0"]) {
    //   return (
    //     <span className="doc-label-flex">
    //       <div
    //         className="doc-label"
    //         onClick={() =>
    //           this.setState({ taskScreen: false, taskScreenData: row })
    //         }
    //       >
    //         {app}
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={
    //             <Tooltip id="Name">
    //               <b>
    //                 SHARE:
    //                 <br />
    //                 {row.documentList.map((value, key) => {
    //                   return (
    //                     <span
    //                       className={
    //                         value.patientRead === "read"
    //                           ? "green"
    //                           : value.addedByType === "educator"
    //                           ? "pink"
    //                           : "red"
    //                       }
    //                     >
    //                       <b>{key + 1}. </b>
    //                       {value.documentName}
    //                       <br />
    //                     </span>
    //                   );
    //                 })}
    //               </b>
    //             </Tooltip>
    //           }
    //         >
    //           <span
    //             className={
    //               patientReadStatus === "read" ? "badge bggreen" : "badge bgred"
    //             }
    //           >
    //             {row.documentList.length}
    //           </span>
    //         </OverlayTrigger>
    //         {html}
    //       </div>
    //       {insulinCal}
    //     </span>
    //   );
    // } else {
    //   return (
    //     <span className="doc-label-flex">
    //       <div
    //         className="doc-label"
    //         onClick={() =>
    //           this.setState({ taskScreen: false, taskScreenData: row })
    //         }
    //       >
    //         {app}
    //         {html}
    //       </div>
    //       {insulinCal}
    //     </span>
    //   );
    // }
  }

  outButton(cell, row) {
    if (row.educatorOut !== "out" && row.doctorOut !== "out") {
      return (
        <Link
          onClick={() =>
            this.setState({
              nextVisit: true,
              nextVisitData: row,
              patientOut: true,
            })
          }
        >
          <img
            title="Out Patient"
            className="table-action-ic"
            src={DeleteIc}
            alt="delete"
          />
        </Link>
      );
    } else {
      return "";
    }
  }

  moreDetailsButton(cell, row) {
    return (
      <Link
        onClick={() => {
          let objStarStatus = {};
          let emrData = {};
          let strPatientStarStatus = "";
          this.props.taskScreenAction(row);
          if (this.props.patientEmrData) {
            if (this.props.patientEmrData.patientId === row._id)
              emrData = {
                patientId: this.props.patientEmrData.patientId,
                isEmr: this.props.patientEmrData.isEmr,
              };
          }
          this.props.emrSetAction(emrData);
          if (row?.patientStarStatus)
            objStarStatus = this.props.starStatusList.find(
              (obj) => obj._id === row.patientStarStatus
            );
          if (objStarStatus) {
            strPatientStarStatus = objStarStatus.title;
          }
          this.setState({
            moreDetails: true,
            moreDetailsData: row,
            starStatus: strPatientStarStatus,
          });
        }}
      >
        <img
          title="More Details"
          className="table-action-ic"
          src={MoreIc}
          alt="more"
        />
      </Link>
    );
  }

  patientVisitOut(id, row) {
    //console.log(this.state.econsultation,"8888888")
    this.setState({ outId: id, nextVisit: false });
    this.props.patientOutAction(id, this.state.econsultation);
    this.setState({ nextVisit: false });
    let msg = "";
    msg = this.state.patientOut
      ? "Patient Out Successfully"
      : "Next Visit Successfully Submitted";
    this.successAlert(msg);
    // confirmAlert({
    //     title: 'Confirm to out',
    //     message: 'Are you sure to do this.',
    //     buttons: [
    //         {
    //             label: 'Yes',
    //             onClick: () => this.props.patientOutAction(id)
    //         },
    //         {
    //             label: 'No',
    //         }
    //     ]
    // })
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else if (this.state.isNoDataFound) {
      return "No data found!";
    }
  }

  getOutClass(cell, row) {
    let { doctorOut, educatorOut, id } = row;
    if (doctorOut === "out" && educatorOut !== "out") {
      return id + " Dr-Out";
    } else if (doctorOut === "out" && educatorOut === "out") {
      return id + " Ed-Out";
    } else {
      return id;
    }
  }

  getPatientOutClass(cell, row) {
    let { doctorOut, educatorOut, id, lastVisitDate, billingCycle, addVisit } =
      row;
    // if (doctorOut === 'out' && educatorOut === 'out') {
    //     return id + " Ed-Out";
    // }
    // else {
    let date1 = new Date(lastVisitDate);
    let date2 = new Date(addVisit);
    var paetientColor = "";
    if (billingCycle) {
      billingCycle = JSON.parse(billingCycle);
    }
    date1 = date1.setDate(date1.getDate() + billingCycle);
    date1 = moment(date1).format("YYYY-MM-DD");
    date2 = moment(date2).format("YYYY-MM-DD");
    if (lastVisitDate == null) {
      paetientColor = "register-patient";
    }
    // else if(new Date(date1) > new Date(date2)){
    else if (date1 > date2) {
      paetientColor = "reported-patient";
    } else {
      paetientColor = "followup-patient";
    }
    return id + " " + paetientColor;
    // }
  }

  // getPatientOutClass(cell, row) {
  //     let { doctorOut, educatorOut, id, lastVisitDate, billingCycle, addVisit } = row;
  //     let date1 = new Date(lastVisitDate);
  //     let date2 = new Date(addVisit);
  //     var paetientColor = "";
  //     date1 = date1.setDate(date1.getDate() + billingCycle);
  //     date1 = moment(date1).format('YYYY-MM-DD');
  //     date2 = moment(date2).format('YYYY-MM-DD');
  //     if (lastVisitDate == null) {
  //         paetientColor = 'New';
  //     }
  //     // else if(new Date(date1) > new Date(date2)){
  //     else if (date1 > date2) {
  //         paetientColor = 'Report';
  //     } else {
  //         paetientColor = 'Followup';
  //     }
  //     return paetientColor;
  // }

  nextButton(cell, row, enumObject, rowIndex) {
    return (
      <>
        {/* <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="NextVisit">Next Visit</Tooltip>}
        >
          <span
            style={{ display: "inline-block", marginRight: "5px" }}
            onClick={() =>
              this.setState({
                nextVisit: true,
                nextVisitData: row,
                patientOut: false,
              })
            }
          >
            <img className="table-action-ic" src={CalendarIc} alt="" />{" "}
            {row.next_visit_date !== null && row.next_visit_date
              ? moment(row.next_visit_date).format("DD-MM-YYYY")
              : ""}
          </span>
        </OverlayTrigger>
        <span style={{ display: "inline-block", marginRight: "3px" }}>
          {this.emr(cell, row)}
        </span> */}
        <span style={{ display: "inline-block", marginRight: "3px" }}>
          {this.password(cell, row)}
        </span>
        {this.outButton(cell, row)}
        {this.moreDetailsButton(cell, row)}
      </>
    );
  }

  action(cell, row, enumObject, rowIndex) {
    return this.nextButton(cell, row, enumObject, rowIndex);
  }

  handleSideModal(componentToShow) {
    this.setState({ openSideModel: true, componentToShow: componentToShow });
  }

  emr(cell, row, enumObject, rowIndex) {
    let html = "";
    //   if (row.educatorOut !== 'out' && row.doctorOut !== 'out') {

    //     }
    let emrData = {
      patientId: row._id,
      isEmr: row.patientStatus === "old" && row.flag === "yes" ? true : false,
    };

    html = (
      <Link
        className=""
        onClick={(e) => {
          localStorage.setItem("patientVisit", JSON.stringify(row));
          localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
          localStorage.setItem("is_Visit_Created", true);
          this.props.taskScreenAction(row);
          this.props.emrSetAction(emrData);
        }}
        to={{
          pathname:
            row.patientStatus === "old"
              ? row.flag === "yes"
                ? row.validPrescription.toString() === "1"
                  ? `print/${row.id}/${row.pId}/brand/${row.language}`
                  : `prescription/${row.id}/${row.pId}/${row.language}`
                : `vital-sign/${row.id}/${row.pId}/${row.language}`
              : `vital-sign/${row.id}/${row.pId}/${row.language}`,
          state: { row },
        }}
      >
        <img
          alt="Prescription"
          className="table-action-ic"
          title="Prescription"
          src={
            row.patientStatus === "old"
              ? row.flag === "yes"
                ? row.validPrescription.toString() === "1"
                  ? PrescriptionGreenIc
                  : PrescriptionYellowIc
                : PrescriptionRedIc
              : PrescriptionRedIc
          }
        ></img>
      </Link>
    );
    return html;
  }

  fetchReloadDashboardFun() {
    let _this = this;
    const search_date = _this.props.search_date;
    _this.setState({ search_date: search_date, activeTab: "offline" });
    _this.props.visitListAction(search_date, "", "", "searchDate");
  }

  // refresh() {
  //   this.props.visitListAction(this.state.search_date);
  // }

  getIndex(cell, row, enumObject, rowIndex) {
    return rowIndex + 1;
  }

  getRedirect(id) {
    this.props.history.push("/diet-history", { row: id });
  }

  taskScreen(cell, row, enumObject, rowIndex) {
    let emrData = {
      patientId: row._id,
      isEmr: row.patientStatus === "old" && row.flag === "yes" ? true : false,
    };
    return (
      <div
        className="doc-label"
        onClick={() => {
          localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
          localStorage.setItem("is_Visit_Created", true);
          this.props.taskScreenAction(row);
          this.props.emrSetAction(emrData);
          this.props.history.push("/patient-details", {
            patientVisitData: row,
          });
        }}
      >
        {row.patientId}
      </div>
    );
  }

  nameContent(cell, row, enumObject, rowIndex) {
    //console.log(row, "rowwwwww")
    //var readStatus = "read";
    let emrData = {
      patientId: row._id,
      isEmr: row.patientStatus === "old" && row.flag === "yes" ? true : false,
    };

    let colorCls = "";

    if (row.doctorOut === "out" && row.educatorOut === "out") {
      colorCls = "pt-black-color";
    } else {
      colorCls = "pt-blue-color";
    }
    let _row = row;
    _row.activeTab = this.state.activeTab;

    let strPatientStarStatus = "";
    // row.patientStarStatus.toString() === "1" ? "***" :
    //   row.patientStarStatus.toString() === "2" ? "#" :
    //     row.patientStarStatus.toString() === "3" ? "@" :
    //       row.patientStarStatus.toString() === "4" ? "*****" :
    //         <span style={{ "color": "red" }}>***</span>
    let objStarStatus = {};
    if (row?.patientStarStatus)
      objStarStatus = this.props.starStatusList.find(
        (obj) => obj._id === row.patientStarStatus
      );
    if (objStarStatus) {
      strPatientStarStatus = objStarStatus.title;
    }
    var remarkDr = "";

    if (row.messageForEducator && row.messageForEducator !== "") {
      remarkDr = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.messageForEducator}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">DR</span></span>
      </OverlayTrigger>
    }

    var remarkP = "";

    if (row.patientRemark && row.patientRemark !== "") {
      remarkP = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.patientRemark}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">R</span></span>
      </OverlayTrigger>
    }
    return (
      <>
        <div
          className="doc-label"
          onClick={() => {
            localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
            localStorage.setItem("is_Visit_Created", true);
            this.props.taskScreenAction(_row);
            if (this.props.patientEmrData) {
              if (this.props.patientEmrData.patientId === row._id)
                emrData = {
                  patientId: this.props.patientEmrData.patientId,
                  isEmr: this.props.patientEmrData.isEmr,
                };
            }
            this.props.emrSetAction(emrData);
            this.props.history.push("/patient-details", {
              patientVisitData: _row,
              search_date: this.state.search_date,
            });
          }}
        >
          <div className={!row.app ? colorCls : ""}>
            {row.name}
            {strPatientStarStatus}{" "}
            {(row.patientRemark != "" || row.messageForEducator) &&
              <span style={{ paddingLeft: "15px", display: "flex" }}>
                {remarkDr != "" && <span> {remarkDr}</span>}
                {row.patientRemark != "" &&
                  <span>{remarkP}</span>
                }
              </span>
            }

          </div>
        </div>
      </>
    );
    // if (row.documentList["0"]) {
    //   return (
    //     <>
    //       <div
    //         className="doc-label"
    //         onClick={() => {
    //           localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
    //           localStorage.setItem("is_Visit_Created", true);
    //           this.props.taskScreenAction(row);
    //           //    if(this.props.patientEmrData)
    //           //     {
    //           //       if(this.props.patientEmrData.patientId===row._id)
    //           //          emrData={patientId:this.props.patientEmrData.patientId,isEmr:this.props.patientEmrData.isEmr};
    //           //     }
    //           this.props.emrSetAction(emrData);
    //           this.props.history.push("/patient-details", {
    //             patientVisitData: row,
    //           });
    //         }}
    //       >
    //         <div className={!row.app ? colorCls : ""}>{row.name} </div>
    //       </div>
    //       <div
    //         className="doc-label"
    //         onClick={() =>
    //           this.setState({ taskScreen: false, taskScreenData: row })
    //         }
    //       >
    //         <OverlayTrigger
    //           placement="bottom"
    //           overlay={
    //             <Tooltip id="Name">
    //               <b>
    //                 SHARE:
    //                 <br />
    //                 {row.documentList.map((value, key) => {
    //                   return (
    //                     <span
    //                       className={
    //                         value.status === "read"
    //                           ? "green"
    //                           : value.addedByType === "educator"
    //                           ? "pink"
    //                           : row.app
    //                           ? "blue"
    //                           : "red"
    //                       }
    //                     >
    //                       <b>{key + 1}. </b>
    //                       {value.documentName}
    //                       <br />
    //                     </span>
    //                   );
    //                 })}
    //               </b>
    //             </Tooltip>
    //           }
    //         >
    //           <span
    //             className={
    //               readStatus === "read" ? "badge bggreen" : "badge bgred"
    //             }
    //           >
    //             {/*<span className="glyphicon glyphicon-share-alt" aria-hidden="true"></span>*/}
    //             {row.documentList.length}
    //           </span>
    //         </OverlayTrigger>
    //         {html}
    //         {remarkP}
    //       </div>
    //     </>
    //   );
    // } else {
    //   return (
    //     <div
    //       className="doc-label"
    //       onClick={() => {
    //         localStorage.setItem("is_Navigate_From_Doctor_Screen", true);
    //         localStorage.setItem("is_Visit_Created", true);
    //         this.props.taskScreenAction(row);
    //         // if(this.props.patientEmrData)
    //         //  {
    //         //    if(this.props.patientEmrData.patientId===row._id)
    //         //      emrData={patientId:this.props.patientEmrData.patientId,isEmr:this.props.patientEmrData.isEmr};
    //         //  }
    //         this.props.emrSetAction(emrData);
    //         this.props.history.push("/patient-details", {
    //           patientVisitData: row,
    //         });
    //       }}
    //     >
    //       <div className={!row.app ? colorCls : ""}>
    //         {row.name}
    //         {remarkP}
    //       </div>
    //     </div>
    //   );
    // }
  }

  handalstorage(data) { }
  handleSearch(data) {
    let search_date = moment(data).format("YYYY-MM-DD");
    this.setState({
      search_date: search_date,
      isLoading: true,
      isNoDataFound: false,
      visitCount: 0,
      eVisitCount: 0,
      outPatientCount: 0,
      activeTab: "offline",
    });
    this.props.visitListAction(search_date, "", "", "searchDate");
  }

  get_age_by_dob(cell, row, enumObject, rowIndex) {
    // return (row && row.age)?parseInt(row.age):'';
    return (
      <div className={"ageColor_" + row.age_color}>
        {row && row.age === "" ? "" : parseInt(row.age, 10)}
      </div>
    );
  }

  renderDay(props, currentDate, selectedDate) {
    let list = this.state.holidayList;
    let holiday_date = JSON.parse(localStorage.getItem("holiday_date"));
    let calenderDate = moment(currentDate).format("YYYY-MM-DD");
    let todayDate = moment().format("YYYY-MM-DD");

    var extClass = "";

    let exit = 0;
    let exit1 = 0;
    let currentDate2 = 0;
    for (let x in list) {
      let dates = list[x].calendarDate;
      if (dates[0]) {
        for (let j in dates) {
          let eventDate = moment(dates[j]).format("YYYY-MM-DD");
          let newDate = moment(currentDate).format("YYYY-MM-DD");
          if (eventDate === newDate) {
            exit = 1;
            props["title"] = list[x].message;
            if (list[x].type === "leave") {
              exit1 = 1;
            }
          }
        }
      }
    }
    //clinic leave admin side
    if (holiday_date?.length > 0) {
      for (let p in holiday_date) {
        let holidayDate = moment(holiday_date[p]).format("YYYY-MM-DD");
        let newDate = moment(currentDate).format("YYYY-MM-DD");
        if (holidayDate === newDate) {
          exit = 1;
          exit1 = 1;

          extClass = " weekoffPink-bg";
        }
      }
    }
    //end clinic leave admin side
    // current date color
    if (calenderDate === todayDate) {
      exit = 1;
      currentDate2 = 1;
    }
    // current date color end
    if (exit === 1) {
      if (exit1 === 1) {
        extClass = extClass ? extClass : " red-bg";
      } else if (currentDate2 === 1) {
        extClass = " green-bg";
      } else {
        extClass = extClass ? extClass : " red-bg";
      }
    } else {
      extClass = " gray";
    }

    let weekDays = JSON.parse(localStorage.getItem("is_week_off"));

    // if (weekDays.length > 0) {
    //     for (let i in weekDays) {
    //         if (weekDays[i].day === currentDate.day()) {

    //             extClass = ' weekoffPink-bg'
    //         }
    //     }
    // }

    if (weekDays?.length > 0) {
      for (let i in weekDays) {
        if (weekDays[i].day == moment(currentDate).day()) {
          extClass = " weekoffPink-bg";
        }
      }
    }
    props["className"] = props["className"] + extClass;

    return <td {...props}>{currentDate.date()}</td>;
  }

  sortvisitListData(econsStatus) {
    let { allvisitList, econsultation, eVisitCount, visitCount } = this.state;
    econsultation = econsStatus;
    if (allvisitList !== undefined) {
      let visitList = [];
      if (econsStatus === true) {
        visitList = allvisitList.filter((item) => item.econsultation);
        eVisitCount = visitList.filter(
          (item) => item.doctorOut === "in"
        ).length;
        visitCount = allvisitList.filter(
          (item) => !item.econsultation && item.doctorOut === "in"
        ).length;
      }

      if (econsStatus === false) {
        visitList = allvisitList.filter((item) => !item.econsultation);
        visitCount = visitList.filter((item) => item.doctorOut === "in").length;
        eVisitCount = allvisitList.filter(
          (item) => item.econsultation && item.doctorOut === "in"
        ).length;
      }

      if (this.props.history?.location?.goToHome) {
        let homePid = this.props.history?.location?.goToHome;
        var localindex_index = visitList
          .map(function (el) {
            return el.pId;
          })
          .indexOf(homePid);
        if (localindex_index != -1) {
          let localData = visitList[localindex_index];
          visitList.splice(localindex_index, 1);
          visitList.unshift(localData);
        }
      }

      this.setState({
        ...this.state,
        econsultation,
        visitList,
        eVisitCount,
        visitCount,
      });
    }
  }

  getVisitList(mode) {
    this.setState({ activeTab: mode });
    if (mode === "offline") {
      //console.log(this.props.VisitList,"visitList")
      !this.props.isOfflineApiCalled && this.props.VisitList.length === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD")
        )
        : this.setState({
          visitList: this.props.VisitList,
          econsultation: false,
          isLoading: false,
        });
    }

    if (mode === "online") {
      !this.props.isOnilneApiCalled && this.props.eVisitList.length === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD"),
          "",
          "",
          "online"
        )
        : this.setState({
          visitList: this.props.eVisitList,
          econsultation: true,
          isLoading: false,
        });
    }

    if (mode === "OutPatientList") {
      !this.props.isOutListApiCalled && this.props.outPatientList.length === 0
        ? this.props.visitListAction(
          moment(this.state.search_date).format("YYYY-MM-DD"),
          "",
          "",
          "OutPatientList"
        )
        : this.setState({
          visitList: this.props.outPatientList,
          isLoading: false,
        });
    }
  }

  tagsContent(cell, row, enumObject, rowIndex) {
    //console.log(row,"row")
    let html = "";
    html = (
      <span className="doc-label-flex doc-label" style={{ flexWrap: "wrap" }}>
        {row.patientATag &&
          row.patientATag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}
        {row.patientBTag &&
          row.patientBTag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}
        {row.patientCTag &&
          row.patientCTag.map((obj, key) => {
            return <img title={obj.tagName} src={obj.s3Path}></img>;
          })}

        {/* {row.patientCTag && row.patientCTag.map((obj,key)=>{
       let date1 = moment(obj.createdAt);
       let date2=moment(row.createDate);
       let dateDiffrence=0
       if(date1<date2)
          dateDiffrence =date2.diff(date1, 'days')
      else dateDiffrence =date1.diff(date2, 'days') 
       
        console.log(date1,date2,dateDiffrence,row.name,"3 days")
       if(dateDiffrence<=3){
          filteredCtagArr.push(obj)
          return (<img  title={obj.tagName} src={obj.s3Path}></img>)
       }
          
      //return (<img  src={obj.s3Path}></img>)
      })
     } */}
      </span>
    );

    return html;
  }

  search_date(props) {
    return (
      <FormGroup className="mop-0" style={{ margin: "0" }}>
        <Row>
          <Col
            lg={5}
            md={5}
            sm={12}
            className="form-liness-outline w550 sm-wwi btn-Mng"
          >
            <VisitListButton
              visitCount={this.props.visitCount}
              eVisitCount={this.props.eVisitCount}
              outPatientCount={this.props.outPatientCount}
              getVisitList={this.getVisitList}
              activedTab={this.state.activeTab}
            />
            {/* <Calendar selectedDate={this.state.search_date} handleChange={e => this.handleSearch(e, 'date')} /> */}
            <span className="visit-calander">
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "DD-MM-YYYY", readOnly: true }}
                dateFormat={"DD-MM-YYYY"}
                maxDate={new Date()}
                // value={new Date()}
                value={moment(this.state.search_date).format("DD-MM-YYYY")}
                renderDay={this.renderDay}
                onChange={(event) => this.handleSearch(event)}
                className="cs-datepick"
              />
            </span>
            {props.searchField}
            {props.clearBtn}
            {/* <button
              class={`btn btn-default ${this.state.activeTab === "offline" ? "btn-fill btn-primary" : ""
                }`}
              type="button"
              onClick={(e) => {this.setState({...{activeTab:"offline"}});
              this.getVisitList();
              }
            }
            >
              <span>
                OPD Consultation{" "}
                {this.state.visitCount > 0
                  ? "(" + this.state.visitCount + ")"
                  : ""}
              </span>
            </button>
            <button
              class={`btn btn-default ${this.state.activeTab === "online" ? "btn-fill btn-primary" : ""
                }`}
              type="button"
              onClick={(e) =>{this.setState({activeTab:"online"});
              this.props.eVisitList.length === 0 ?
               this.props.visitListAction(this.state.search_date,"","","online")
               :this.setState({visitList:this.props.eVisitList})
              }
            }
            >
              <span>
                Online Consultation{" "}
                {this.state.eVisitCount > 0
                  ? "(" + this.state.eVisitCount + ")"
                  : ""}
              </span>
            </button>
            <button
              class={`btn btn-default ${this.state.activeTab === "OutPatientList" ? "btn-fill btn-primary" : ""
                }`}
              type="button"
              onClick={(e) =>{this.setState({activeTab:"OutPatientList"});
              this.props.outPatientList.length === 0 ?  this.props.visitListAction(this.state.search_date,"","","OutPatientList"):
              this.setState({visitList:this.props.outPatientList})
              }}
            >
              <span>
                Out List{" "}
                {this.state.outPatientCount > 0
                  ? "(" + this.state.outPatientCount + ")"
                  : ""}
              </span>
            </button> */}

            {/* <Datetime
                        timeFormat={false}
                        inputProps={{ placeholder: "DD-MM-YYYY",readOnly:true }}
                        dateFormat={"DD-MM-YYYY"}
                        maxDate={new Date()}
                        defaultValue={new Date()}
                        renderDay={ this.renderDay }
                        onChange={(event) => this.handleSearch(event)}
                        className='cs-datepick'
                    /> */}
          </Col>
        </Row>
      </FormGroup>
    );
  }

  onDismiss() {
    this.setState({ taskScreen: false });
    //this.setState({ taskScreen: false, showProcessing: true });
    this.successAlert("You have successfully submitted task.");
  }

  onDismissNextVisit() {
    this.setState({ nextVisit: false });
    let msg = "";
    msg = this.state.patientOut
      ? "Patient Out Successfully"
      : "Next Visit Successfully Submitted";
    this.successAlert(msg);
  }

  successAlert(msg) {
    // this.setState({
    //   alert: (
    //     <SweetAlert
    //       success
    //       style={{ display: "block", marginTop: "-100px" }}
    //       title="Success"
    //       onConfirm={() => this.hideAlert()}
    //       //onCancel={() => this.hideAlert()}
    //       confirmBtnBsStyle="info"
    //     >
    //       {msg}
    //     </SweetAlert>
    //   ),
    // });

    // let _this = this;
    // setTimeout(function () {
    //   _this.hideAlert();
    // }, 3000);

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  }

  hideAlert() {
    this.setState({
      alert: false,
    });
  }

  getPatinetOutData() {
    const outStr = (
      <span>
        Out
        <span style={{ paddingLeft: "10px" }}>
          PatientId: {this.state.nextVisitData?.patientId}
        </span>
        <span style={{ paddingLeft: "10px" }}>
          Name: {this.state.nextVisitData?.firstName}
        </span>
      </span>
    );
    return outStr;
  }

  funModelStatus() {
    this.setState({ reportsModel: false });
  }

  onDismissMoreDetail() {
    this.setState({
      moreDetails: false,
    });
    this.successAlert("Data Saved Successfully");
  }

  render() {
    if (this.state.taskScreenData.length > 0) {
      this.setState({ taskScreen: false, showProcessing: true });
    }
    const options = {
      noDataText: this._setTableOption(),
      searchPanel: (props) => this.search_date(props),
      searchPosition: "left",
      clearSearch: true,
      noDataText: this._setTableOption(),
    };

    const reportModal = (
      <Reports
        visitInfo={this.state.taskScreenData}
        hideButton="true"
        modelStatus={this.state.reportsModel}
        funModelStatus={this.funModelStatus}
      />
    );

    return (
      <>
        {/* <Welcome_User /> */}

        <div className="dashboard-grid" onScroll={this.handleScroll}>
          <div className="grid-column">
            {this.state.alert}
            <NotificationSystem ref="notificationSystem" style={style} />
            <div
              className="actionProcess"
              style={{ display: this.state.showProcessing ? "block" : "none" }}
            >
              <img src={loadingImg} alt="Loading" width="40px" />
              <center> Please Wait</center>
            </div>
            {localStorage.getItem("is_report_folder_facility") &&
              localStorage.getItem("is_report_folder_facility") === "yes" &&
              reportModal}
            <div className="table-card">
              <div className="table-card-header">
                {/* <h4>Your Visits for Today</h4> */}
                {/* <Link className='btn-link' onClick={this.refresh}>Refresh</Link> */}
              </div>
              <div
                className="table-responsive table-responsive-new "
                style={{ overflow: "visible" }}
              >
                <VisitListTable
                  visitList={this.state.visitList}
                  options={options}
                  getIndex={this.getIndex}
                  getPatientOutClass={this.getPatientOutClass}
                  taskScreen={this.taskScreen}
                  getOutClass={this.getOutClass}
                  isHighlight={this.isHighlight}
                  tagsContent={this.tagsContent}
                  nameContent={this.nameContent}
                  get_age_by_dob={this.get_age_by_dob}
                  isApp={this.isApp}
                  action={this.action}
                />
              </div>
            </div>
            <Modal
              backdrop="static"
              className="pa-task-screen"
              show={this.state.taskScreen}
              onHide={() => this.setState({ taskScreen: false })}
              dialogClassName="modal-lg"
            >
              <button
                type="button"
                className="close"
                onClick={() => this.setState({ taskScreen: false })}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body className="Knowledge-Share ">
                <TaskScreen
                  taskScreenData={this.state.taskScreenData}
                  onDismiss1={this.onDismiss}
                />
              </Modal.Body>
            </Modal>
            <Modal
              backdrop="static"
              className="pa-next-visit"
              show={this.state.nextVisit}
              onHide={() => this.setState({ nextVisit: false })}
              dialogClassName="modal-lg"
            >
              <Modal.Header closeButton className="bg-thumbcolor">
                <Modal.Title id="example-modal-sizes-title-lg">
                  {this.state.patientOut
                    ? this.getPatinetOutData()
                    : "Set Next Visit"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="Knowledge-Share ">
                <NextVisit
                  nextVisitData={this.state.nextVisitData}
                  patientOutFun={this.patientVisitOut}
                  patientOut={this.state.patientOut}
                  onDismissNextVisit={this.onDismissNextVisit}
                  econsultation={this.state.econsultation}
                  activeTab={this.state.activeTab}
                />
              </Modal.Body>
            </Modal>
            <Modal
              backdrop="static"
              className="pa-next-visit modal-more-info"
              show={this.state.moreDetails}
              onHide={() => this.setState({ moreDetails: false })}
              dialogClassName="modal-xl"
            >
              <Modal.Header closeButton className="bg-thumbcolor">
                <Modal.Title id="example-modal-sizes-title-lg">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 text-left">
                      <div className="title">
                        {`${this.state.moreDetailsData.firstName}
                                            - 
                                        ${this.state.moreDetailsData.patientId} \u00A0\u00A0`}
                        {this.state.starStatus !== "" && (
                          <>
                            |
                            <span
                              style={{
                                display: "inline-block",
                                margin: "0 5px",
                              }}
                            >
                              {this.state.starStatus}
                            </span>{" "}
                          </>
                        )}
                        |
                        <span
                          style={{
                            display: "inline-block",
                            margin: "0 5px",
                          }}
                        >
                          Age
                          {this.state.moreDetailsData &&
                            this.state.moreDetailsData?.age != 0
                            ? ":"
                            : ""}{" "}
                          {parseFloat(this.state.moreDetailsData.age).toFixed()}
                          {` Y / ${this.state.moreDetailsData.gender === "Male"
                            ? "M"
                            : "F"
                            }`}
                        </span>
                        {this.state.moreDetailsData.phone !== "" && (
                          <>
                            |
                            <span
                              style={{
                                display: "inline-block",
                                margin: "0 5px",
                              }}
                            >
                              contact: {this.state.moreDetailsData.phone}
                            </span>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="Knowledge-Share ">
                <PatientMoreDetail
                  moreDetails={this.state.moreDetailsData}
                  search_date={this.state.search_date}
                  onDismissMoreDetail={this.onDismissMoreDetail}
                />
              </Modal.Body>
            </Modal>
            <Modal
              className="pa-patient-password"
              show={this.state.showPassword}
              onHide={() =>
                this.setState({ patientPasswordData: {}, showPassword: false })
              }
            >
              <Modal.Header closeButton className="bg-thumbcolor">
                <Modal.Title id="example-modal-sizes-title-lg">
                  User Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="Knowledge-Share card">
                <div className="clinic-user-password">
                  <div>
                    <b>Patient Id: </b>
                    <span>{this.state.patientPasswordData.patientId}</span>
                  </div>
                  <div>
                    <b>Patient Name: </b>
                    <span>{this.state.patientPasswordData.name}</span>
                  </div>
                  <div>
                    <b>Password: </b>
                    <span>{this.state.patientPasswordData.password}</span>
                  </div>
                </div>
              </Modal.Body>
            </Modal>{" "}
          </div>

          <div className="aside-panel">
            <div className="aside-cards">
              <div
                className="aside-card"
                onClick={() => this.handleSideModal("Schedule")}
              >
                <div className="card-icon">
                  <img src={ScheduleIc} alt="" />
                </div>
                <h4>
                  Your Schedule{" "}
                  <span>
                    <img src={CardIcon} alt="" />
                  </span>
                </h4>
              </div>

              {/* <div className='aside-card' onClick={() => { this.handleSideModal("Notes"); this.setState({ newNotes: false }) }}>
                                <div className='card-icon'>
                                    <img src={NoteIc} alt='' />
                                </div>
                                <h4>Notes <span><img src={CardIcon} alt='' /></span></h4>
                                {
                                    this.state.newNotes && <div className="card-badge">New</div>
                                }

                            </div> */}

              <div
                className="aside-card"
                onClick={() => {
                  this.handleSideModal("Notification");
                  this.setState({ newNotification: false });
                }}
              >
                <div className="card-icon">
                  <img src={NotificationIc} alt="" />
                </div>
                <h4>
                  Admin Notifications{" "}
                  <span>
                    <img src={CardIcon} alt="" />
                  </span>
                </h4>
                {this.state.newNotification && (
                  <div className="card-badge">New</div>
                )}
              </div>
            </div>
            <AdSlider />
          </div>
          {this.state.openSideModel && (
            <SideModal
              componentToShow={this.state.componentToShow}
              modal={() => this.setState({ openSideModel: false })}
            />
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  //console.log(state,"state")
  return {
    VisitList: state.home.VisitList,
    isVisitList: state.home.isVisitList,
    isVisitListError: state.home.isVisitListError,
    eVisitList: state.home.eVisitList,
    outPatientList: state.home.outPatientList,
    visitListActiveTab: state.home.visitListActiveTab,
    otherVisitDate: state.home.otherVisitDate,
    isVisitListUpdated: state.home.isVisitListUpdated,
    visitCount: state.home.visitCount,
    eVisitCount: state.home.eVisitCount,
    outPatientCount: state.home.outPatientCount,
    isAddToVisitList: state.home.isAddToVisitList,
    isOutListApiCalled: state.home.isOutListApiCalled,
    isOnilneApiCalled: state.home.isOnilneApiCalled,
    isOfflineApiCalled: state.home.isOfflineApiCalled,
    isTagList: state.home.isTagList,
    isTagListError: state.home.isTagListError,
    TagList: state.home.TagList,
    PatientOut: state.home.PatientOut,
    isPatientOut: state.home.isPatientOut,
    isPatientOutError: state.home.isPatientOutError,
    isS3SignedUrlArr: state.home.isS3SignedUrlArr,
    isVisitListUpdateS3SignedUrlOnlineList:
      state.home.isVisitListUpdateS3SignedUrlOnlineList,
    isVisitListUpdateS3SignedUrlOutList:
      state.home.isVisitListUpdateS3SignedUrlOutList,
    s3UrlArryCallTime: state.home.s3UrlArryCallTime,
    isReportUnreadCount: state.home.isReportUnreadCount,
    isStarStatusList: state.home.isStarStatusList,
    isStarStatusListError: state.home.isStarStatusListError,
    starStatusList: state.home.starStatusList,
    isReloadHome: state.home.isReloadHome,

    HolidayList: state.home.HolidayList,
    isHolidayList: state.home.isHolidayList,
    isHolidayListError: state.home.isHolidayListError,

    patientEmrData: state.emr.patientEmrData,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    visitListAction,
    patientOutAction,
    holidayListAction,
    taskScreenAction,
    emrSetAction,
    setVisitListActiveTabAction,
    updateVisitListAction,
    s3SiginedUrlArrayAction,
    tagListAction,
    UpdateVisitListS3SiginedUrlArrayAction,
    UpdateVisitListS3SiginedUrlArrayOutListAction,
    UpdateVisitListS3SiginedUrlArrayOnlineListAction,
    getOnlineVisitListAction,
    starStatusListAction,
  })(DoctorScreen)
);

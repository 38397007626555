/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import {
  postRequestWithToken,
  postRequestWithTokenExtraParams,
  postRequestWithTokenFile
} from "./helper";

export const uploadFile = (data) => postRequestWithToken(data, "upload-file");

export const stateList = (data) => postRequestWithToken(data, "state-list");

export const countryList = (data) => postRequestWithToken(data, "country-list");

export const planList = (data) => postRequestWithToken(data, "admin/plan-list");

export const knowledgeList = (data) =>
  postRequestWithToken(data, "admin/knowledge-list");

export const categoryList = (data) =>
  postRequestWithToken(data, "category-list");

export const treatmentWithType = (data) =>
  postRequestWithToken(data, "treatment-with-type");

export const unitList = (data) => postRequestWithToken(data, "unit-list");

export const timeList = (data) => postRequestWithToken(data, "time-list");

export const addTask = (data) =>
  postRequestWithToken(data, "submit-task-screen");

export const uploadMultiFile = (data) =>
  postRequestWithTokenFile(data, "multi-upload-file");

export const userList = (data) => postRequestWithToken(data, "user-list");

export const chatUserList = (data) =>
  postRequestWithToken(data, "chat-user-list");

export const getPatientChat = (data) => postRequestWithToken(data, "chat-list");

export const getSpecializations = (data) =>
  postRequestWithToken(data, "specialization-list");

export const updateUser = (data) => postRequestWithToken(data, "user-update");

export const sendUserDetail = (data) =>
  postRequestWithToken(data, "send-user-detail");

export const changeUserStatus = (data) =>
  postRequestWithToken(data, "user-change-status");

export const forwardChat = (data) => postRequestWithToken(data, "chat-forward");

export const getChatCount = (data) => postRequestWithToken(data, "chat-count");

export const readChat = (data) => postRequestWithToken(data, "read-chat");

export const fileList = (data) => postRequestWithToken(data, "file-list");

export const updateComment = (data) =>
  postRequestWithToken(data, "time-list/comment-auto-select");

export const getNotification = (data) =>
  postRequestWithToken(data, "notification_message_list");

export const addNotificationMsg = (data) =>
  postRequestWithToken(data, "add_notification_message");

export const editNotificationMsg = (data) =>
  postRequestWithToken(data, "update_notification_message");

export const notificationMsgStatus = (data) =>
  postRequestWithToken(data, "notification_message_change_status");

export const getGlucoseDetail = (data) =>
  postRequestWithToken(data, "get-insulin-detail");

export const getInsulinList = (data) =>
  postRequestWithToken(data, "insulin-list");

export const updateInsulinDose = (data) =>
  postRequestWithToken(data, "update-glucose");

export const updatePatientPrescription = (data) =>
  postRequestWithToken(data, "updatePatientPrescription");

export const getGlucoselist = (data) =>
  postRequestWithToken(data, "glucose-list");

export const getPatientGlucoselist = (data) =>
  postRequestWithToken(data, "patient-glucose-list");

export const preferenceToSet = (data) =>
  postRequestWithToken(data, "change-clinic-settings");

export const prescriptionSetting = (data) =>
  postRequestWithToken(data, "change-clinic-print-settings");

export const getCategoryList = (data) =>
  postRequestWithToken(data, "report-category-list");

export const addReports = (data, config) =>
  postRequestWithTokenExtraParams(data, "add-report", config);

export const getReportList = (data) =>
  postRequestWithToken(data, "report-list");

export const deleteReport = (data) =>
  postRequestWithToken(data, "delete-report");

export const getClinicDocumentlist = (data) =>
  postRequestWithToken(data, "clinic-document-list");

export const setReadFile = (data) => postRequestWithToken(data, "read-report");

export const getAllReportList = (data) =>
  postRequestWithToken(data, "get-all-unread-report");

export const deleteEducatorMsgAction = (data) =>
  postRequestWithToken(data, "delete-educator-message");

export const updateReportTitle = (data) =>
  postRequestWithToken(data, "update-report-title");

export const chatTemplateList = (data) =>
  postRequestWithToken(data, "chat-template-list");

export const editChatTemplate = (data) =>
  postRequestWithToken(data, "edit-chat-template");

export const deleteChatTemplate = (data) =>
  postRequestWithToken(data, "delete-chat-template");

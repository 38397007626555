/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

// import history from '../../routes/history';

// dinamically create app routes
import appRoutes from 'Admin/routes/app.jsx';
import * as API from 'Front/api/home';

const accessToken = localStorage.getItem('token');

class App extends Component {

    constructor(props) {
        super(props);
        this.Logout = this.Logout.bind(this);
        this.logoutIntervalJwt = this.logoutIntervalJwt.bind(this);
        this.logoutIntervalFlagJwt = null;
    }

    componentDidUpdate(e) {
        if (window.innerWidth < 993 && e.history && e.history.action && e.history.action === "PUSH" && document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
        }
    }

    componentWillMount() {
        if (!accessToken) {
            window.location.href = "/login";
        }
    }

    componentDidMount() {
        if (localStorage.getItem('token')) {
            this.logoutIntervalFlagJwt = setInterval(() => {
                let currentDate = new Date();
                let loginTime = new Date(localStorage.getItem("loginTime"))
                var minute = (currentDate - loginTime) / (1000 * 60);
                if (minute >= 13.5) {
                    if (localStorage.getItem("token")) {
                        this.logoutIntervalJwt()
                    }
                }
            }, 1000);
        }

    }

    async logoutIntervalJwt() {
        let response = await API.decodeToken({ token: localStorage.getItem("token") });
        if (response.data.statusCode === 200) {
            if (response.data.tokenExpire === false) {
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('loginTime', new Date())
            }
            else this.Logout()
        }
    }

    Logout() {
        localStorage.removeItem('_id')
        localStorage.removeItem('userName')
        localStorage.removeItem('userType')
        localStorage.removeItem('email')
        localStorage.removeItem('status')
        localStorage.removeItem('token')

        window.location.href = "/";
    }


    render() {


        return (
            <Switch>
                {
                    appRoutes.map((prop, key) => {
                        return (
                            <Route path={prop.path} component={prop.component} key={key} />
                        );
                    })
                }

                {accessToken &&
                    <Redirect from="/" to="/dashboard" />
                }


            </Switch>
        );
    }
}

export default App;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className={"footer" + (this.props.transparent !== undefined ? " footer-transparent" : "")}>
                <div className={"container" + (this.props.fluid !== undefined ? "-fluid" : "")}>
                    {/*<nav className="pull-left">
                        <ul>
                            <li>
                                <a href="#pablo">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="#pablo">
                                    Company
                                </a>
                            </li>
                            <li>
                                <a href="#pablo">
                                    Portfolio
                                </a>
                            </li>
                            <li>
                                <a href="#pablo">
                                    Blog
                                </a>
                            </li>
                        </ul>
                    </nav>*/}
                    <p className="copyright pull-right">
                        &copy; {1900 + (new Date()).getYear()} PracticeAid, Powered by <i className="fa fa-globe heart"></i> <a target="_blank" href="http://synergytop.com" rel="noopener noreferrer">SynergyTop</a>
                    </p>
                </div>
            </footer>
        );
    }
}
export default Footer;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React from "react";
import { useState, useEffect } from "react";
import { withRouter, useParams, useLocation, NavLink } from "react-router-dom";
import * as API from "../../api/emr";
import GeneralUsrImg from "../../assets/img/default-avatar.png";
import PendingIc from "../../assets/img/icons/pending-action.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from '../../actions/actionTypes'
import * as PF from "Front/views/Home/PublicFunction.jsx";

const PatinetInformation = (props) => {

  const data = useSelector(store => store.taskScreen)
  const emrData = useSelector(store => store.emr)
  const [duration, setDuration] = useState(emrData ? emrData.vitalSignsData.diabetesDuration ? emrData.vitalSignsData.diabetesDuration : "Year Month Day" : "Year Month Day");
  const [patientData, setPatientData] = useState(data.visitData)
  const [generalInformation, setGeneralInformation] = useState({});
  const [toggleDuration, setToggleDuration] = useState(
    emrData
      ? emrData.vitalSignsData.toggleDuration
        ? emrData.vitalSignsData.toggleDuration
        : false
      : false
  );
  const dispatch = useDispatch();

  const getGeneralInfo = async () => {

    if (emrData.vitalSignsData && Object.keys(emrData.vitalSignsData).length === 0) {
      let resp = await API.generalInformation({ patientId: props?.patientId });
      if (resp.data && resp.data.status === "Success") {
        setGeneralInformation({
          patientName: resp.data.data.name,
          age: resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "",
          height: resp.data.data.height,
          weight: resp.data.data.weight,
          bmi: resp.data.data.bmi,
          bp: resp.data.data.bp,
          gender: resp.data.data.gender,
          pulse: resp.data.data.pulse,
          temperature: resp.data.data.temprature,
        });
        let strDuration = "";
        if (resp.data.data.diabitic === "yes") {


          if (
            resp.data.data.diabtiesDuration?.years === 0 &&
            resp.data.data.diabtiesDuration?.months === 0 &&
            resp.data.data.diabtiesDuration?.days === 0
          ) {
            strDuration = "Day Month Year";
            setToggleDuration(false);
          }
          setToggleDuration(true);
          if (resp.data.data?.diabtiesDuration?.years > 0) {
            strDuration = resp.data.data.diabtiesDuration?.years + " years ";
          }
          if (resp.data.data.diabtiesDuration?.months > 0) {
            strDuration += resp.data.data.diabtiesDuration?.months + " months ";
          }
          if (resp.data.data.diabtiesDuration?.days > 0) {
            strDuration += resp.data.data.diabtiesDuration?.days + " days ";
          }
          setDuration(strDuration);
        }
        let tempdata = {}
        tempdata.patientName = resp.data.data.firstname + " " + resp.data.data.lastName;
        tempdata.patientId = props?.patientId;
        tempdata.age = resp.data.data.age != undefined ? resp.data.data.dob != undefined ? PF.getAgeByDob(resp.data.data.dob) : resp.data.data.age : "";
        tempdata.height = resp.data.data.height;
        tempdata.weight = resp.data.data.weight;
        tempdata.bmi = resp.data.data.bmi;
        tempdata.bp = resp.data.data.bp;
        tempdata.gender = resp.data.data.gender;
        tempdata.pulse = resp.data.data.pulse;
        tempdata.temperature = resp.data.data.temprature;
        tempdata.diabetesDuration = strDuration;
        tempdata.toggleDuration = resp.data.data.diabitic === "yes" ? true : false;
        dispatch({ type: actionTypes.SET_VITAL_SIGNS_DATA, payload: tempdata })
      }
    }
  };

  useEffect(() => {
    getGeneralInfo();
  }, []);

  useEffect(() => {
    setGeneralInformation(emrData.vitalSignsData ? {
      ...generalInformation,
      patientName: emrData?.vitalSignsData ? emrData.vitalSignsData.patientName : "",
      age: emrData?.vitalSignsData ? emrData.vitalSignsData.age : "",
      height: emrData?.vitalSignsData ? emrData.vitalSignsData.height : "",
      weight: emrData?.vitalSignsData ? emrData.vitalSignsData.weight : "",
      bmi: emrData?.vitalSignsData ? emrData.vitalSignsData.bmi : "",
      bp: emrData?.vitalSignsData ? emrData.vitalSignsData.bp : "",
      gender: emrData?.vitalSignsData ? emrData.vitalSignsData.gender : "",
      pulse: emrData?.vitalSignsData ? emrData.vitalSignsData.pulse : "",
      temperature: emrData?.vitalSignsData ? emrData.vitalSignsData.temperature : "",
      diabetesDuration: emrData?.diabetesDuration ? emrData.vitalSignsData.diabetesDuration : ""
    } : {})
  }, [props]);


  return (

    <div className="general-information">
      <div className="card_header">
        <div className="card_header_inner">
          <ul className="patient_details">
            <li>
              <a className="user-info-img">
                {/* <span><img src={GeneralUsrImg} alt="" /> </span> */}
                {generalInformation.patientName}
              </a>
            </li>
            <span className="flex">
              <li>
                <a>
                  <span>Age: </span> {generalInformation.age} Y /{" "}
                  {generalInformation.gender === "Male" ? " M" : " F"}
                </a>
              </li>
              <li>
                <a>
                  <span>Height{generalInformation.height > 0 ? ":" : ""} </span>{" "}
                  {generalInformation.height > 0
                    ? generalInformation.height
                    : ""}{" "}
                  {generalInformation.height > 0 ? "CM" : ""}
                </a>
              </li>
              <li>
                <a>
                  <span>Weight{generalInformation.weight > 0 ? ":" : ""} </span>{" "}
                  {generalInformation.weight > 0
                    ? generalInformation.weight
                    : ""}{" "}
                  {generalInformation.weight > 0 ? "KG" : ""}
                </a>
              </li>
              <li>
                <a>
                  <span>BMI{generalInformation.bmi > 0 ? ":" : ""} </span>{" "}
                  {generalInformation.bmi > 0 ? generalInformation.bmi : ""}
                </a>
              </li>
              <li>
                <a>
                  <span>BP{generalInformation.bp !== "/" ? ":" : ""} </span>{" "}
                  {generalInformation.bp !== "/" ? generalInformation.bp : ""}{" "}
                  {generalInformation.bp !== "/" ? " mmHg" : ""}
                </a>
              </li>
              <li>
                <a>
                  <span>Pulse{generalInformation.pulse > 0 ? ":" : ""} </span>{" "}
                  {generalInformation.pulse > 0 ? generalInformation.pulse : ""}{" "}
                  {generalInformation.pulse > 0 ? "/min" : ""}
                </a>
              </li>
              <li>
                <a>
                  <span>
                    Temperature{generalInformation.temperature > 0 ? ":" : ""}{" "}
                  </span>{" "}
                  {generalInformation.temperature > 0
                    ? generalInformation.temperature
                    : ""}{" "}
                  {generalInformation.temperature > 0 ? "F" : ""}{" "}
                </a>
              </li>
              {toggleDuration && (
                      <li>
                        <a>
                          <span>Diabetes Duration: </span>{" "}
                          {emrData
                            ? emrData?.diabetesDuration
                              ? emrData?.diabetesDuration
                              : duration
                            : duration}{" "}
                        </a>
                      </li>
                    )}
            </span>
          </ul>
          {/* <div className="patient_history_btn">
                        <NavLink to={`/history/${props?.patientId}`} className="btn btn-fill btn-secondary pull-right">All Visit</NavLink>
                    </div> */}
        </div>
      </div>
    </div>

  );
};

export default PatinetInformation;

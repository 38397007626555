/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
} from "react-bootstrap";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "Front/api/master";
import * as EmrAPI from "Front/api/emr";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
//import Loading from 'react-loading';
import moment from "moment";
// import { insulinBrand } from "Front/variables/Variables.jsx";
import { style } from "Admin/variables/Variables.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import GlucoseDiaryChart from "../Home/GlucoseDiaryChart";
import InsulinIcon from "../../assets/img/icons/insulin.svg";
import CardIcon from "../../assets/img/icons/card-icon.svg";
import NotificationSystem from "react-notification-system";
import Switch from "react-bootstrap-switch";
import { Right } from "react-bootstrap/lib/Media";
const InsulinDose = (props) => {
  let visitInfo = props.visitInfo;
  let callType = props.callType ? props.callType : "";
  // var prescriptionPrint = props.prescriptionPrint
  //   ? props.prescriptionPrint
  //   : [];
  const [prescriptionPrint, setPrescriptionPrint] = useState(
    props.prescriptionPrint ? props.prescriptionPrint : []
  );
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [insulinModel, setInsulinModel] = useState(false);
  const [glucoseModal, setGlucoseModal] = useState(false);
  const [glucoseList, setGlucoseList] = useState([]);
  const [glucoseListFasting, setGlucoseListFasting] = useState([]);
  const [btnClicked, setBtnClicked] = useState(false);
  const [isInsulinDoseStart, setIsInsulinDoseStart] = useState(false);
  const [disableInsulinSaveButton, setDisableInsulinSaveButton] =
    useState(true);

  const notificationSystem = useRef(null);
  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 1,
    sizePerPage: 10,
  });
  const [totalCount, setTotalCount] = useState(0)
  const [validated, setValidated] = useState(false);

  const [alert, setAlert] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [warningModal, setWarningModel] = useState(false);
  const [insulinBrand, setInsulinBrand] = useState();
  const [pageLoad, setPageLoad] = useState(false);
  const [oldBrand, setOldBrand] = useState();
  const [insulinCalculator, setInsulinCalculator] = useState(
    prescriptionPrint.length > 0 ? true : false
  );
  const [isInsulineDoseGreater, setIsInsulinDoseGreater] = useState(false);
  const inputHandler = (e) => {
    let saveFormData = formData;
    saveFormData[e.target.name] = e.target.value;
    if (e.target.name === "weightBasedDose") {
      saveFormData["insulinDose"] = Math.round(
        formData.weight * e.target.value
      );
    }

    if (e.target.name === "maxInsulinDose") {
      saveFormData["maxInsulinDose"] = parseFloat(e.target.value);
    }

    if (e.target.name == "brand") {
      formData.brand = e.target.value;
      // var local_index = insulinBrand
      //   .map(function (el) {
      //     return el.drugNameWithDose;
      //   })
      //   .indexOf(e.target.value);
      // if (local_index != -1) {
      //   formData.brand = e.target.value;
      //   formData.insulinDrugId = insulinBrand[local_index]._id;
      // }
    }
    setFormData({ ...formData, ...saveFormData });
    validate();
  };

  const validate = () => {
    let post = formData;
    let errArr = errors;
    let errflag = [];
    if (post.glucoseRangeFrom > 0) {
      errArr.glucoseRangeFrom = "";
    } else {
      errArr.glucoseRangeFrom = "error";
      errflag.push({ name: "glucoseRangeFrom", err: false });
    }

    if (post.glucoseRangeTo > 0) {
      errArr.glucoseRangeTo = "";
    } else {
      errArr.glucoseRangeTo = "error";
      errflag.push({ name: "glucoseRangeTo", err: false });
    }

    if (post.insulinDose > 0) {
      errArr.insulinDose = "";
    } else {
      errArr.insulinDose = "error";
      errflag.push({ name: "insulinDose", err: false });
    }

    if (post.maxInsulinDose > 0) {
      errArr.maxInsulinDose = "";
    } else {
      errArr.maxInsulinDose = "error";
      errflag.push({ name: "maxInsulinDose", err: false });
    }

    if (post.maxInsulinDose >= post.insulinDose && post.insulinDose !== 0) {
      //errArr.insulinDose = '';
      setIsInsulinDoseGreater(false);
    } else {
      setIsInsulinDoseGreater(true);
      errArr.insulinDose = "error";
      errflag.push({ name: "insulinDose", err: false });
    }
    if (post.brand !== "") {
      errArr.brand = "";
    } else {
      errArr.brand = "error";
      errflag.push({ name: "brand", err: false });
    }

    setErrors({ ...errArr });
    return !errflag.length;
  };
  const submitHandler = async (event) => {
    event.preventDefault();

    if (validate()) {
      setBtnClicked(true);
      let data = formData;
      if (data.brand === "Other") {
        data.brand = data.otherBrand;
      }

      if (data.maxInsulinDose >= data.insulinDose) {
      } else {
        if (data.insulinDose) {
          setWarningModel(true);
        }
      }
      data.insulinDose = JSON.parse(JSON.stringify(data.insulinDose));
      var changeInsulinDose = ("" + data.insulinDose).split("");
      if (
        data.insulinDose &&
        prescriptionPrint &&
        prescriptionPrint.length > 0
      ) {
        for (let i in prescriptionPrint) {
          let doseTimeSlot = prescriptionPrint[i].doseTimeSlot;
          if (prescriptionPrint[i].insulinCalculator) {
            // if (data.brand != oldBrand) {
            //   prescriptionPrint[i].isDrugChange = true;
            //   var local_index = insulinBrand
            //     .map(function (el) {
            //       return el._id;
            //     })
            //     .indexOf(data.insulinDrugId);
            //   if (local_index != -1) {
            //     prescriptionPrint[i].drugId = insulinBrand[local_index]._id;
            //     prescriptionPrint[i].drugNameWithDose =
            //       insulinBrand[local_index].drugNameWithDose;
            //     prescriptionPrint[i].drugNameWithIngredients =
            //       insulinBrand[local_index].drugNameWithIngredients;
            //     prescriptionPrint[i].companyId =
            //       insulinBrand[local_index].companyId;
            //     prescriptionPrint[i].companyName =
            //       insulinBrand[local_index].companyName;
            //     prescriptionPrint[i].drugCategoryId =
            //       insulinBrand[local_index].drugCategoryId;
            //     prescriptionPrint[i].drugCategoryName =
            //       insulinBrand[local_index].drugCategoryName;
            //     prescriptionPrint[i].drugType =
            //       insulinBrand[local_index].drugType;
            //     prescriptionPrint[i].noOfDoseSelector =
            //       insulinBrand[local_index].noOfDoseSelector;
            //     prescriptionPrint[i].valueTypeOfDoseSelector =
            //       insulinBrand[local_index].valueTypeOfDoseSelector;
            //     prescriptionPrint[i].insulinCalculator =
            //       insulinBrand[local_index].insulinCalculator;
            //   }
            // }

            if (doseTimeSlot.length > 0) {
              for (let j in doseTimeSlot) {
                if (
                  doseTimeSlot[j].customizeFrequency &&
                  doseTimeSlot[j].customizeFrequency.length > 0
                ) {
                  let customizeFrequency = doseTimeSlot[j].customizeFrequency;
                  if (customizeFrequency.length === changeInsulinDose.length) {
                    for (let p in customizeFrequency) {
                      prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                        p
                      ].key = changeInsulinDose[p];
                      prescriptionPrint[i].doseTimeChange = true;
                      let idx =
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          p
                        ].idx;
                      idx = idx.split("-");
                      idx[0] = prescriptionPrint[i].drugId;
                      prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                        p
                      ].idx = idx.join("-");
                    }
                  } else if (
                    changeInsulinDose.length > customizeFrequency.length
                  ) {
                    for (let x in changeInsulinDose) {
                      // if (prescriptionPrint[i].isDrugChange) {
                      //   prescriptionPrint[i].doseTimeChange = true;

                      //   let idx =
                      //     prescriptionPrint[i].doseTimeSlot[j]
                      //       .customizeFrequency[x].idx;
                      //   idx = idx.split("-");
                      //   let z = x;
                      //   z = parseInt(z);
                      //   idx[idx.length - 1] = z + 1;
                      //   idx[0] = prescriptionPrint[i].drugId;
                      //   let datas = {
                      //     idx: idx.join("-"),
                      //     key: changeInsulinDose[x],
                      //   };
                      //   prescriptionPrint[i].doseTimeSlot[
                      //     j
                      //   ].customizeFrequency.push(datas);
                      //   if (changeInsulinDose.length - 1 == x) {
                      //     prescriptionPrint[i].doseTimeSlot[
                      //       j
                      //     ].customizeFrequency.splice(0, 1);
                      //   }
                      // } else {
                      if (
                        prescriptionPrint[i].doseTimeSlot[j]
                          .customizeFrequency &&
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                        x
                        ] &&
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          x
                        ].key
                      ) {
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          x
                        ].key = changeInsulinDose[x];
                        prescriptionPrint[i].doseTimeChange = true;
                      } else {
                        prescriptionPrint[i].doseTimeChange = true;
                        let idx =
                          prescriptionPrint[i].doseTimeSlot[j]
                            .customizeFrequency[x - 1].idx;
                        idx = idx.split("-");
                        let y = x;
                        y = parseInt(y);
                        idx[idx.length - 1] = y + 1;
                        idx[0] = prescriptionPrint[i].drugId;
                        let datas = {
                          idx: idx.join("-"),
                          key: changeInsulinDose[x],
                        };
                        prescriptionPrint[i].doseTimeSlot[
                          j
                        ].customizeFrequency.push(datas);
                      }
                      // }
                    }
                  } else {
                    for (let p in customizeFrequency) {
                      if (changeInsulinDose[p]) {
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          p
                        ].key = changeInsulinDose[p];
                      } else {
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          p
                        ].key = "";
                      }
                      prescriptionPrint[i].doseTimeChange = true;
                      let idx =
                        prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                          p
                        ].idx;
                      idx = idx.split("-");
                      idx[0] = prescriptionPrint[i].drugId;
                      prescriptionPrint[i].doseTimeSlot[j].customizeFrequency[
                        p
                      ].idx = idx.join("-");
                    }
                  }
                }
              }
            }
            if (
              prescriptionPrint[i].doseTimeChange ||
              prescriptionPrint[i].isDrugChange
            ) {
              let res = await API.updatePatientPrescription(
                prescriptionPrint[i]
              );
              if (res) {
                if (callType == "") {
                  props.changePrescriptionPrint(false);
                }
                if (callType != "name" && callType != "patientId") {
                  props.changePrescriptionPrint(true);
                }
              }
            }
            prescriptionPrint[i].isDrugChange = false;
            prescriptionPrint[i].doseTimeChange = false;
          }
        }
      }
      data.isInsulinDoseStart = isInsulinDoseStart;
      let resp = await API.updateInsulinDose(data);
      if (resp) {
        getGlucoseDetailAction();
        getGlucoseListAction(1);

        setInsulinModel(false);
        const notification = notificationSystem.current;
        let msg = "Insulin initial dose set successfully.";
        notification.addNotification({
          title: (
            <span
              data-notify="icon"
              className="pe-7s-check"
              style={{
                fontSize: "30px",
                color: "#fff",
                padding: "0px !important",
                top: "0% !important",
              }}
            ></span>
          ),
          message: <div>{msg}</div>,
          level: "success",
          position: "tr",
          autoDismiss: 2,
        });
        //successAlert("Insulin initial dose set successfully");
      }
    }
    setBtnClicked(false);
  };
  const successAlert = (msg) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success"
        onConfirm={() => setAlert(null)}
        //onCancel={() =>setInsulinModel(false)}
        confirmBtnBsStyle="info"
      >
        {msg}
      </SweetAlert>
    );
    setInsulinModel(false);
    setTimeout(function () {
      setAlert(null);
    }, 2000);
  };

  // const getInsulinList = async () => {
  //   let result = await API.getInsulinList();
  //   if (result && result.status == 200) {
  //     if (result.data.count > 0) {
  //       // let insulinData = result.data.drugData.map((item) => ({
  //       //   value: item.drugNameWithDose,
  //       //   label: item.drugNameWithDose,
  //       // }));
  //       // if (insulinData) {
  //       //   setInsulinBrand(insulinData);
  //       // }
  //       let insulinData = result.data.drugData;
  //       setInsulinBrand(insulinData);
  //     }
  //   }
  // };

  const getGlucoseDetailAction = async () => {
    let resp = await API.getGlucoseDetail({ pid: visitInfo.pId });
    setIsInsulinDoseStart(resp.data.data.isInsulinDoseStart);
    if (resp.data.data.isInsulinDoseStart) {
      setDisableInsulinSaveButton(false);
    }
    let fData = resp.data.data;
    if (fData && fData.brand) {
      let brand = fData.brand;
      // let insulinBrandData = insulinBrand;
      // var localindex_index = insulinBrandData
      //   .map(function (el) {
      //     return el._id;
      //   })
      //   .indexOf(fData.insulinDrugId);
      // if (localindex_index == -1) {
      //   fData.otherBrand = fData.brand;
      //   fData.brand = "Other";
      // }
      setOldBrand(fData.brand);
      // insulinBrandData.find((item) => {
      //   if (item.value === brand) {
      //     fData.brand = item.value;
      //   } else {
      //     fData.otherBrand = fData.brand;
      //     fData.brand = "Other";
      //     setFormData({ ...fData });
      //   }
      //   // return item.value === brand;
      // });

      // if (check) {
      // } else if (fData.brand !== "") {
      //   fData.otherBrand = fData.brand;
      //   fData.brand = "Other";
      // }
    }

    // let formDataNew = resp.data.data;
    // if (!formDataNew["maxInsulinDose"]) {
    //   formDataNew["maxInsulinDose"] = formDataNew.weight * 0.3;
    // }

    if (!fData["maxInsulinDose"]) {
      fData["maxInsulinDose"] = fData.weight * 0.3;
    }

    setFormData({ ...fData });
  };

  const getGlucoseListAction = async (data = {}) => {
    let resp = await API.getGlucoselist({
      pid: visitInfo.pId,
      page:
        Object.keys(data).length === 0 && data.constructor === Object
          ? paginationInfo.currentPage - 1
          : data.currentPage - 1,
      limit:
        Object.keys(data).length === 0 && data.constructor === Object
          ? paginationInfo.sizePerPage
          : data.sizePerPage,
    });
    if (resp) {
      // if (glucoseList.length === 0)
      //   setPaginationInfo({
      //     ...paginationInfo,
      //     totalRow: resp.data.data.count,
      //   });

      // setGlucoseList(resp.data.data.data);
      // let arrFasting = [];
      // resp.data.data.data.map((obj, idx) => {
      //   if (obj.glucoseTime === "Fasting") {
      //     arrFasting.push(obj);
      //   }
      // });
      // setGlucoseListFasting(arrFasting);
      // setPaginationInfo({
      //   ...paginationInfo,
      //   totalRow: resp.data.response.count,
      // });
      setTotalCount(resp.data.response.count)
      setGlucoseList(resp.data.response.data);
      setGlucoseListFasting(resp.data.response.data);
    }
  };

  const getPrescriptionPrint = async () => {
    let patientId = visitInfo.pId;
    let appointmentId = visitInfo.id;
    if (callType === "name" || callType === "patientId") {
      patientId = visitInfo._id;
      appointmentId = visitInfo.appointmentId[0];
    }
    let respData = await EmrAPI.prescriptionPrint({
      patientId: patientId,
      appointmentId: appointmentId,
    });
    if (
      respData.data.status === "Success" &&
      respData.data.statusCode === 200
    ) {
      let patientPresciptionData = respData.data?.patientPresciptionData;
      if (patientPresciptionData.length > 0) {
        var local_index = patientPresciptionData
          .map(function (el) {
            return el.insulinCalculator;
          })
          .indexOf(true);
        if (local_index != -1) {
          setInsulinCalculator(true);
        }
      }
      setPrescriptionPrint(patientPresciptionData);
    }
  };

  useEffect(() => {
    if (insulinModel === true) {
      getGlucoseDetailAction();
      getGlucoseListAction();
    }
  }, [insulinModel]);

  // useEffect(() => {
  //   if (!pageLoad && callType == "prescriptionPrint") {
  //     getInsulinList();
  //     setPageLoad(true);
  //   }
  // }, []);

  let doseStatus =
    visitInfo.doseRead === false
      ? "badge bgred drs-left  mobile-red-clr"
      : "badge bgred drs-left mobile-green-clr";

  if (insulinModel === true) {
    visitInfo.doseRead = true;
  }

  const onPageChange = (page, sizePerPage) => {
    setPaginationInfo({
      ...paginationInfo,
      currentPage: page,
      sizePerPage: sizePerPage,
    });
    getGlucoseListAction({
      ...paginationInfo,
      currentPage: page,
      sizePerPage: sizePerPage,
    });
  };

  const glucoseDateFun = (cell, row, enumObject, rowIndex) => {
    return moment(row.glucoseDate).format("D-MMM-YYYY");
  };

  const targetRangeFun = (cell, row, enumObject, rowIndex) => {
    return `(${row.rangeFrom} - ${row.rangeTo})`;
  };

  const currentDoseValue = (cell, row, enumObject, rowIndex) => {
    return row.currentDose == 0 ? "-" : row.currentDose;
  };

  const insulinOffDateFun = (cell, row, enumObject, rowIndex) => {
    return row.offDate ? moment(row.offDate).format("D-MMM-YYYY") : "";
  };

  const options = {
    clearSearch: true,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: "Prev",
    nextPage: "Next",
    firstPage: "First",
    lastPage: "Last",
    paginationShowsTotal: totalCount, // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    sizePerPage: paginationInfo.sizePerPage,
    onPageChange: onPageChange,
    page: paginationInfo.currentPage,
  };

  return (
    <span>
      {alert}
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      {localStorage.getItem("insulinCalculator") &&
        localStorage.getItem("insulinCalculator") === "yes" &&
        callType === "" && (
          //<button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e => { setInsulinModel(true) }}>Insulin Calculator</button>
          <div
            className="aside-card "
            onClick={(e) => {
              setInsulinModel(true);
              getPrescriptionPrint();
            }}
          >
            <div className="card-icon">
              <img src={InsulinIcon} alt="" />
            </div>
            <h4>
              Insulin Calculator{" "}
              <span>
                <img src={CardIcon} alt="" />
              </span>
            </h4>
          </div>
        )}

      {localStorage.getItem("insulinCalculator") &&
        localStorage.getItem("insulinCalculator") === "yes" &&
        callType === "prescriptionPrint" && (
          //<button type="button" className="btn-fill btn btn-primary btn btn-default mangeBtns" onClick={e => { setInsulinModel(true) }}>Insulin Calculator</button>
          <div
            className="insulin-box"
            onClick={(e) => {
              setInsulinModel(true);
            }}
          >
            <div className="card-icon">
              <img src={InsulinIcon} alt="" />
            </div>
            <h4>
              Insulin Calculator{" "}
              {/* <span>
                <img src={CardIcon} alt="" />
              </span> */}
            </h4>
          </div>
        )}

      {localStorage.getItem("insulinCalculator") &&
        localStorage.getItem("insulinCalculator") === "yes" &&
        callType === "main" && (
          <span
            className={doseStatus}
            onClick={(e) => {
              setInsulinModel(true);
            }}
            title="Insulin Calculator"
          >
            {visitInfo.insulinDose}
          </span>
        )}

      {localStorage.getItem("insulinCalculator") &&
        localStorage.getItem("insulinCalculator") === "yes" &&
        (callType === "name" || callType === "patientId") && (
          <span
            className="cursor-pointer-link"
            onClick={(e) => {
              setInsulinModel(true);
              getPrescriptionPrint();
            }}
            title="Insulin Calculator"
          >
            <div className={!visitInfo.app ? "pt-blue-color" : ""}>
              {callType === "patientId"
                ? visitInfo.patientClinicId
                : visitInfo.title +
                " " +
                visitInfo.firstName +
                " " +
                visitInfo.lastName}
            </div>
          </span>
        )}
      {localStorage.getItem("insulinCalculator") &&
        localStorage.getItem("insulinCalculator") === "yes" &&
        callType === "glucose" && (
          <span
            className="cursor-pointer-link"
            onClick={(e) => {
              setGlucoseModal(true);
            }}
            title="Insulin Calculator"
          >
            <div className={!visitInfo.app ? "pt-blue-color" : ""}>
              Glucose Diary
            </div>
          </span>
        )}
      <Modal
        show={warningModal}
        onHide={() => setWarningModel(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share">
          <Row>
            <Col md={12}>
              <FormGroup>
                <div id="message">
                  <p
                    className="alert-heading text-center"
                    style={{ color: "#FF0000" }}
                  >
                    Please Revise Max Dose
                  </p>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Modal.Footer>
            <Button
              className="btn-fill btn-secondary pull-right"
              onClick={() => setWarningModel(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

      <Modal
        className="pa-patient-registration"
        show={glucoseModal}
        onHide={() => setGlucoseModal(false)}
        dialogClassName="modal-full modal-xl"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            Glucose Chart (
            {visitInfo.firstName +
              " " +
              visitInfo.lastName +
              " - " +
              visitInfo.patientClinicId}
            )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share card">
          <Row>
            <Col md={12}>
              <GlucoseDiaryChart patientData={visitInfo} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={insulinModel}
        onHide={() => {
          setInsulinModel(false);
          if (disableInsulinSaveButton) {
            setIsInsulinDoseStart(false);
          }
        }}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton className="bg-thumbcolor">
          <Modal.Title id="example-modal-sizes-title-lg">
            Insulin Calculator
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Knowledge-Share card insulin-dose-modal">
          <Row>
            <Col md={12}>
              {formData.brand && formData.brand != "" && insulinCalculator && (
                <Form horizontal noValidate validated={validated}>
                  <Col className="mange-spctop noniborder">
                    {successMsg !== "" && (
                      <p className="alert-heading" style={{ color: "#155724" }}>
                        {successMsg}
                      </p>
                    )}
                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={2}>
                        Body Weight
                      </Col>
                      <Col componentClass={ControlLabel} sm={4}>
                        Weight Based Dose
                      </Col>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: "10px" }}>
                      <Col sm={2}>
                        <FormControl
                          type="number"
                          name="weight"
                          value={formData.weight ? formData.weight : ""}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          placeholder="Body Weight"
                          min="1"
                          className="insulin-input"
                          readOnly
                        />
                      </Col>
                      <Col sm={4}>
                        <FormControl
                          componentClass="select"
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          name="weightBasedDose"
                          placeholder="Weight Based Dose"
                          value={
                            formData.weightBasedDose
                              ? formData.weightBasedDose
                              : ""
                          }
                          className={
                            "insulin-input " +
                            (errors.weightBasedDose
                              ? errors.weightBasedDose
                              : "")
                          }
                        >
                          <option value="0">Select</option>
                          <option value="0.05">0.05</option>
                          <option value="0.1">0.10</option>
                          <option value="0.15">0.15</option>
                          <option value="0.2">0.20</option>
                          <option value="0.25">0.25</option>
                          <option value="0.3">0.30</option>
                          <option value="0.35">0.35</option>
                          <option value="0.4">0.40</option>
                          <option value="0.45">0.45</option>
                          <option value="0.5">0.50</option>
                        </FormControl>
                      </Col>

                      <Col sm={6} style={{ textAlign: "right" }}>
                        {formData.brand &&
                          formData.brand != "" &&
                          insulinCalculator && (
                            // isInsulinDoseStart && (
                            <Button
                              disabled={btnClicked}
                              type="button"
                              className="btn-fill btn-primary"
                              onClick={(e) => submitHandler(e)}
                            >
                              Save
                              {btnClicked && (
                                <i class="fa fa-spinner fa-spin"></i>
                              )}
                            </Button>
                          )}
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Col componentClass={ControlLabel} sm={2}>
                        Insulin Name <span className="star">*</span>
                      </Col>
                      <Col componentClass={ControlLabel} sm={4}>
                        Target Range <span className="star">*</span>
                      </Col>
                      <Col
                        componentClass={ControlLabel}
                        className="highlight"
                        sm={2}
                      >
                        Insulin Dose <span className="star">*</span>
                      </Col>
                      {/* <Col componentClass={ControlLabel} sm={2}>
                                        Fasting Sugar
                                    </Col> */}

                      {
                        <Col
                          componentClass={ControlLabel}
                          className="highlight"
                          sm={2}
                        >
                          Max Dose{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="status">
                                Initial maximum dose calculated as .3 U per Kg.
                                This is editable . When Insulin requirement
                                based on fasting glucose reaches to this dose,
                                app will stop suggesting further change in dose
                                and will ask patient to consult the doctor.
                                Doctor should keep this number as per their
                                choice .
                              </Tooltip>
                            }
                          >
                            <Button simple icon bsStyle="status-color">
                              <i
                                className="fa fa-info-circle"
                                aria-hidden="true"
                              ></i>
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      }

                      <Col componentClass={ControlLabel} sm={1}></Col>
                    </FormGroup>
                    <FormGroup>
                      <Col sm={2}>
                        <div style={{ padding: "10px 0" }}>
                          {formData.brand ? formData.brand : ""}
                        </div>
                        {/* <FormControl
                        componentClass="select"
                        onChange={(e) => {
                          inputHandler(e);
                        }}
                        name="brand"
                        placeholder="Insulin"
                        value={formData.brand ? formData.brand : ""}
                        className={
                          "insulin-input " + (errors.brand ? errors.brand : "")
                        }
                        required
                        disabled={prescriptionPrint.length == 0}
                      >
                        <option value="">Select</option>
                        {insulinBrand &&
                          insulinBrand.map((item, index) => {
                            return (
                              <option value={item.drugNameWithDose} key={index}>
                                {item.drugNameWithDose}
                              </option>
                            );
                          })}
                      </FormControl>
                      {formData.brand && formData.brand === "Other" && (
                        <FormControl
                          type="text"
                          name="otherBrand"
                          value={formData.otherBrand ? formData.otherBrand : ""}
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          placeholder="Insulin Name"
                          disabled={prescriptionPrint.length == 0}
                        />
                      )} */}
                      </Col>

                      <Col sm={2}>
                        <FormControl
                          type="number"
                          name="glucoseRangeFrom"
                          value={
                            formData.glucoseRangeFrom
                              ? formData.glucoseRangeFrom
                              : ""
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          min="1"
                          placeholder="From"
                          className={
                            "insulin-input " +
                            (errors.glucoseRangeFrom
                              ? errors.glucoseRangeFrom
                              : "")
                          }
                          required
                        />
                      </Col>
                      <Col sm={2}>
                        <FormControl
                          type="number"
                          name="glucoseRangeTo"
                          value={
                            formData.glucoseRangeTo
                              ? formData.glucoseRangeTo
                              : ""
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          min="1"
                          placeholder="To"
                          className={
                            "insulin-input " +
                            (errors.glucoseRangeTo ? errors.glucoseRangeTo : "")
                          }
                          required
                        />
                      </Col>

                      <Col sm={2}>
                        <FormControl
                          type="number"
                          name="insulinDose"
                          value={
                            formData.insulinDose ? formData.insulinDose : ""
                          }
                          onChange={(e) => {
                            inputHandler(e);
                          }}
                          placeholder="Dose"
                          min="1"
                          className={
                            "insulin-input " +
                            (errors.insulinDose ? errors.insulinDose : "")
                          }
                          required
                        />
                      </Col>

                      {/* <Col sm={2}>

                                        <FormControl type="number" name="glucoseLast" value={formData.glucoseLast?formData.glucoseLast:''} onChange={e => { inputHandler(e); }} placeholder="Glucose" />
                                        
                                    </Col> */}
                      {
                        <Col sm={2}>
                          <FormControl
                            type="number"
                            name="maxInsulinDose"
                            value={
                              typeof formData.maxInsulinDose === "number" &&
                                formData.maxInsulinDose
                                ? formData.maxInsulinDose.toFixed()
                                : ""
                            }
                            onChange={(e) => {
                              inputHandler(e);
                            }}
                            placeholder="Max Dose"
                            min="1"
                            className={
                              "insulin-input " +
                              (errors.maxInsulinDose
                                ? errors.maxInsulinDose
                                : "")
                            }
                            required
                          />
                        </Col>
                      }

                      <Col
                        sm={2}
                        style={{ textAlign: "right", paddingRight: "18px" }}
                      >
                        <Switch
                          className="insulin-switch"
                          onChange={(e) => {
                            setIsInsulinDoseStart(
                              isInsulinDoseStart ? false : true
                            );
                            // setIsInsulinDoseStart(!isInsulinDoseStart);
                          }}
                          defaultValue={isInsulinDoseStart}
                        // readonly={
                        //   isInsulinDoseStart && formData.isInsulinDoseStart
                        // }
                        />
                      </Col>
                      {/* <span>{isInsulinDoseStart}</span> */}
                    </FormGroup>
                    <FormGroup>
                      <Col sm={8}></Col>
                      <Col sm={4}>
                        {isInsulineDoseGreater && (
                          <span className="star">
                            * Please Increase Maximum Dose
                          </span>
                        )}
                      </Col>
                    </FormGroup>
                  </Col>
                </Form>
              )}
            </Col>

            <Col md={12} className="fresh-datatables table-responsive">
              <BootstrapTable
                selectRow="{selectRowProp}"
                deleteRow={false}
                data={glucoseListFasting}
                pagination={true}
                options={options}
                striped
                hover
                condensed
                scrollTop={"Bottom"}
                remote={true}
                fetchInfo={{ dataTotalSize: totalCount }}
              >
                <TableHeaderColumn
                  hidden={true}
                  tdAttr={{ "data-attr": "_id" }}
                  dataField="invitationId"
                  isKey
                  searchable={false}
                >
                  Id
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "120px" }}
                  tdStyle={{ width: "120px" }}
                  tdAttr={{ "data-attr": "brand" }}
                  dataField="brand"
                >
                  Insulin Advised
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "60px" }}
                  tdStyle={{ width: "60px" }}
                  tdAttr={{ "data-attr": "glucoseDate" }}
                  dataFormat={glucoseDateFun}
                  dataField="glucoseDate"
                >
                  Date{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "glucoseValue" }}
                  dataField="glucoseValue"
                >
                  Fasting Glucose{" "}
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "suggestedDose" }}
                  dataField="suggestedDose"
                >
                  Insulin Dose Advice
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "suggestedDose" }}
                  dataField="currentDose"
                  dataFormat={currentDoseValue}
                >
                  Insulin Dose Injected
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "suggestedDose" }}
                  dataField="remark"
                >
                  Remark
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "targetRange" }}
                  dataFormat={targetRangeFun}
                >
                  Target Range
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "100px" }}
                  tdStyle={{ width: "100px" }}
                  tdAttr={{ "data-attr": "suggestedDose" }}
                  dataField="doseChange"
                >
                  Insulin Dose (+/-)
                </TableHeaderColumn>
                <TableHeaderColumn
                  thStyle={{ width: "60px" }}
                  tdStyle={{ width: "60px" }}
                  tdAttr={{ "data-attr": "offDate" }}
                  dataFormat={insulinOffDateFun}
                >
                  Insulin OFF Date
                </TableHeaderColumn>
              </BootstrapTable>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/* {formData.brand && formData.brand != "" && insulinCalculator && (
            <Button
              disabled={btnClicked}
              type="button"
              className="btn-fill btn-primary"
              onClick={(e) => submitHandler(e)}
            >
              Save
              {btnClicked && <i class="fa fa-spinner fa-spin"></i>}
            </Button>
          )} */}
          <Button
            className="btn-fill btn btn-secondary"
            onClick={() => {
              setInsulinModel(false);
              if (disableInsulinSaveButton) {
                setIsInsulinDoseStart(false);
              }
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};
export default withRouter(InsulinDose);

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  Modal,
  FormControl,
  FormGroup,
  ControlLabel,
  Table,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import { patientListAction } from "Front/actions/patient";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import moment from "moment";
import DropDownSearch from "Front/views/PatientSearch/DropDownSearch.jsx";
import RecDropDownSearch from "Front/views/PatientSearch/ReceptionistDropDownSearch.jsx";
// import KnowledgeShare from 'Front/views/PatientSearch/KnowledgeShare.jsx';
import {
  printShareDocumentAction,
  getPatientDetailAction,
} from "Front/actions/home";
import Chat from "../../components/Header/Chat";
import Diet from "Front/views/Diet/Diet";
import Radio from "Front/elements/CustomRadio/CustomRadio.jsx";
import { getBMI, getAgeByDob } from "Front/views/Home/PublicFunction.jsx";
import { appConstants } from "Front/_constants/app.constants.js";
import VideoDocumentView from "Front/views/TaskScreen/VideoDocumentView.jsx";
import {
  knowledgeListAction,
  patientNotificationAction,
} from "Front/actions/home";
import SweetAlert from "react-bootstrap-sweetalert";
import Checkbox from "Front/elements/CustomCheckbox/CustomCheckbox.jsx";
import TaskScreen from "Front/views/TaskScreen/TaskScreenPopup.jsx";
import {
  NotificationListAction,
  AddNotificationMsgAction,
} from "Front/actions/master";
import * as PF from "Front/views/Home/PublicFunction.jsx";
import Reports from "Front/views/TaskScreen/Reports";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";

var sortingorder = "";
var call_validate_cost_timer = null;

let Validator = require("validatorjs");
let formArr = {};
let rules = {
  message_english: "required",
  // message_hindi: 'required'
};
let mess = {
  required: "This field is required",
  email: "Invalid Email",
  numeric: "Enter numeric digits",
};
let validation = [];

class PatientSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientList: [],
      isLoading: true,
      isNoDataFound: false,
      chatModal: false,
      chatModelApi: false,
      chatPatientId: "",
      chatPatientDetails: "",
      patientInfo: [],
      knowledgeModal: false,
      notificationModal: false,
      formData: {
        patient_id: "",
        mobile_number: "",
        city: "",
        first_name: "",
        last_name: "",
        direction: "desc",
        order: "createdAt",
        offset: 0,
        limit: 10,
        message_hindi: "",
        message_english: "",
        selected_type: "selected",
        type: "dd",
      },

      totalCount: 0,
      currentPage: 1,
      sizePerPage: 10,
      fetchRequest: false,
      dietModal: false,
      currentPatient: null,
      dietTitle: "",
      knowledgeList: [],
      categoryList: [],
      patientName: "",
      patientId: "",
      knowledgeCategory: "",
      knowledgeModal: false,
      documents: [],
      videos: [],
      alert: null,
      isSelected: [],
      formArr: [],
      notificationErrorMsg: null,
      bmi: 0,
      taskScreen: false,
      taskScreenData: [],
      dietFromPtSeatch: true,
      isSelectStatus: false,
      notificationList: [],
      hideText: false,
      hideDD: true,
      selected: [],
      reportsModel: false,
      cssCustomMessage: "none",
      customMessage: "",
      customMessageEror: false,
      selectedMessage: "",
      _notificationSystem: null,
      showNotificationProcessing: false,
      sendButtonDisabled: true,
    };

    validation = new Validator(this.state, rules, mess);
    validation.passes();
    validation.fails();

    this.chatBox = this.chatBox.bind(this);
    this.dietBox = this.dietBox.bind(this);
    this.knowledgeShare = this.knowledgeShare.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.hideChatModel = this.hideChatModel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPatientList = this.getPatientList.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.taskScreenModal = this.taskScreenModal.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.getName = this.getName.bind(this);
    this.onBootstrapTableRef = this.onBootstrapTableRef.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleClearBtn = this.handleClearBtn.bind(this);
    this.funModelStatus = this.funModelStatus.bind(this);
    this.getPatientTodayVisitClass = this.getPatientTodayVisitClass.bind(this);
    this.patientUpdate = this.patientUpdate.bind(this);
    this.call_api = this.call_api.bind(this);
  }

  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.getPatientList();

    let data = {
      limitStatus: true,
    };
    this.props.NotificationListAction(data);
  }

  clearSelectedRow() {
    this.bootstrapTableRef.cleanSelected();
  }

  onBootstrapTableRef(instance) {
    this.bootstrapTableRef = instance;
  }

  getPatientList() {
    let data = {};
    data["patient_id"] = this.state.formData.patient_id;
    data["phone"] = this.state.formData.phone;
    data["city"] = this.state.formData.city;
    data["first_name"] = this.state.formData.first_name;
    data["last_name"] = this.state.formData.last_name;
    data["direction"] = this.state.formData.direction;
    data["order"] = this.state.formData.order;
    data["offset"] = this.state.formData.offset;
    //  data['limit'] = this.state.formData.limit;
    data["limit"] = this.state.sizePerPage;
    this.call_api(data);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.chatModelApi && nextProps.patientDetails) {
      this.setState({ chatModelApi: false });
      this.setState({
        chatModal: true,
        chatPatientDetails: nextProps.patientDetails.data,
      });
    }

    if (nextProps.isPatientList !== this.props.isPatientList) {
      this.setState({ fetchRequest: true });
    }

    if (nextProps.isPatientList !== this.props.isPatientList) {
      let j = this.state.currentPage * 10 - 10;
      let patientList = [];
      if (
        nextProps.patientList.data &&
        nextProps.patientList.data.data &&
        nextProps.patientList.data.data.length > 0
      ) {
        patientList =
          nextProps.patientList.data.data &&
          nextProps.patientList.data.data.map((key, i) => {
            let row = {
              id: key._id,
              pId: key._id,
              sn: ++j,
              patientId: key.patientClinicId,
              name: key.title + " " + key.firstName + " " + key.lastName,
              dob: key.dob,
              city: key.city,
              share_video: key.share_video_count + " / " + key.view_video_count,
              share_document: key.share_count + " / " + key.view_count,
              task: 0,
              diet: 0,
              created_date: moment(key.createdAt).format("DD-MM-YYYY"),
              next_visit:
                key.nextDate && key.nextDate !== null && key.nextDate !== ""
                  ? moment(key.nextDate).format("DD-MM-YYYY")
                  : null,
              app: key.app,
              status: key.status,
              documentList: key.Documents,
              dietCount:
                key.dietPrintCount +
                " / " +
                key.dietShareCount +
                " / " +
                key.dietEmailCount,
            };

            return { ...key, ...row };
          });
      }

      if (this.state.isLoading === true) {
        this.setState({ isLoading: false });
      }

      if (patientList.length === 0) this.setState({ isNoDataFound: true });

      // this.setState({patientList:patientList});
      this.setState({
        patientList: patientList,
        totalCount:
          nextProps.patientList.data && nextProps.patientList.data.count,
        fetchRequest: true,
      });
    }

    // if (this.state.isLoading === true) {
    //     this.setState({ isLoading: false });
    // }

    if (nextProps.isKnowledgeList !== this.props.isKnowledgeList) {
      let knowledgeData = nextProps.KnowledgeList.data.data;
      for (let x in knowledgeData) {
        if (knowledgeData[x].showClinics.length > 0) {
          let local_index = knowledgeData[x].showClinics.indexOf(
            localStorage.getItem("clinicId")
          );
          if (local_index != -1) {
            knowledgeData[x].clinicShowStatus = false;
          } else {
            knowledgeData[x].clinicShowStatus = true;
          }
        }
      }

      this.setState({ knowledgeList: knowledgeData });

      let docList = this.state.patientInfo.documentList;
      let videos = [];
      let documents = [];
      for (let i in docList) {
        if (docList[i].docType === "document") {
          documents.push({
            id: docList[i].documentId,
            name: docList[i].documentName,
            link: docList[i].image,
          });
        } else {
          videos.push({
            id: docList[i].documentId,
            name: docList[i].documentName,
            link: docList[i].image,
          });
        }
      }

      this.setState({ documents: documents });
      this.setState({ videos: videos });
    }

    if (nextProps.isPrintShareDocument !== this.props.isPrintShareDocument) {
      this.successAlert("You have successfully Share knowledge!");
    }

    if (
      nextProps.isPatientNotification !== this.props.isPatientNotification &&
      this.props.isPatientNotification === false
    ) {
      this.setState({ showNotificationProcessing: false });
      this.successAlert("Notification Send Successfully");
      this.setState({
        notificationModal: false,
        isSelected: [],
        isSelectStatus: false,
        selectedMessage: "",
        sendButtonDisabled: true,
      });
      this.clearSelectedRow();
      this.setState({
        formData: {
          message_hindi: "",
          message_english: "",
          selected_type: "selected",
          type: "dd",
        },
        formArr: [],
      });
    }

    if (nextProps.isNotification !== this.props.isNotification) {
      this.setState({
        notificationList:
          nextProps.notificationList.data &&
          nextProps.notificationList.data.data,
      });
    }

    if (nextProps.isAddNotificationMsg !== this.props.isAddNotificationMsg) {
      const _this = this;
      let field = this.state.formData;
      let tempList = this.state.notificationList;
      tempList.push({ message: this.state.customMessage });
      _this.setState({
        notificationList: tempList,
        formData: field,
        selectedMessage: this.state.customMessage,
        cssCustomMessage: "none",
        sendButtonDisabled: false,
      });
      //this.props.NotificationListAction(this.state.limitStatus);
    }
  }

  knowledgeListFun(data) {
    let knowledgeList = [];
    for (let x in data) {
      for (let y in data[x].category) {
        if (!knowledgeList[data[x].category[y].id]) {
          knowledgeList[data[x].category[y].id] = [];
        }
        knowledgeList[data[x].category[y].id].push(data[x]);
      }
    }
    this.setState({ knowledgeList });
  }

  _setTableOption() {
    if (this.state.isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else if (this.state.isNoDataFound) {
      return "No data found!";
    }
  }

  handleKnowledgeDocChange(id, name, link) {
    let documents = this.state.documents;
    let exist = 0;
    for (let x in documents) {
      if (documents[x].id === id) {
        exist = 1;
        delete documents[x];
      }
    }
    if (exist === 0) {
      documents.push({ id: id, name: name, link: link });
    }
    documents = documents.filter(function (el) {
      return el != null;
    });

    this.setState({ documents: documents });
  }

  handleKnowledgeVideoChange(id, name, link) {
    let videos = this.state.videos;
    let exist = 0;
    for (let x in videos) {
      if (videos[x].id === id) {
        exist = 1;
        delete videos[x];
      }
    }
    if (exist === 0) {
      videos.push({ id: id, name: name, link: link });
    }
    videos = videos.filter(function (el) {
      return el != null;
    });

    this.setState({ videos: videos });
  }

  viewVideoDocument(link, type) {
    return (
      <VideoDocumentView
        patientInfo={this.props.patientInfo}
        link={link}
        type={type}
        ViewModal={true}
      />
    );
  }

  documentPrint() {
    this.setState({ knowledgeModal: true });
    let documents = this.state.documents;
    let formData = [];
    formData["patient_id"] = this.state.patientInfo._id;
    formData["notification_token"] = this.state.patientInfo.notification_token;
    formData["visit_id"] = "";
    formData["type"] = "print";
    formData["documents"] = documents;
    formData["videos"] = [];

    this.props.printShareDocumentAction(formData);
    debugger;

    let newWin = window.open("", "Print-Window");
    newWin.document.open();
    newWin.document.write('<html><body onload="window.print()">');
    for (let x in documents) {
      newWin.document.write(
        '<img style="width:100%;margin:20px;" src="' +
        appConstants.s3UploadUrl +
        documents[x].link +
        '"/>'
      );
    }
    newWin.document.write("</body></html>");
    newWin.document.close();
    setTimeout(function () {
      newWin.close();
    }, 10);
  }

  submitTask() {
    this.setState({ knowledgeModal: false });
    let documents = this.state.documents;
    let videos = this.state.videos;
    let formData = [];
    formData["patient_id"] = this.state.patientInfo._id;
    formData["notification_token"] = this.state.patientInfo.notification_token;
    formData["visit_id"] = "";
    formData["type"] = "share";
    formData["documents"] = documents;
    formData["videos"] = videos;
    this.props.printShareDocumentAction(formData);

    let data = {};
    data["patient_id"] = this.state.formData.patient_id;
    data["phone"] = this.state.formData.phone;
    data["city"] = this.state.formData.city;
    data["first_name"] = this.state.formData.first_name;
    data["last_name"] = this.state.formData.last_name;
    data["direction"] = this.state.formData.direction;
    data["order"] = this.state.formData.order;
    data["offset"] = this.state.formData.offset;
    data["limit"] = this.state.formData.limit;
    this.props.patientListAction(data);
  }

  successAlert(msg) {
    this.setState({ knowledgeModal: false });
    // this.setState({
    //     alert: (
    //         <SweetAlert
    //             success
    //             style={{ display: "block", marginTop: "-100px" }}
    //             title="Success"
    //             //onConfirm={() => this.hideAlert()}
    //             //onCancel={() => this.hideAlert()}
    //             confirmBtnBsStyle="info"
    //         >
    //             {msg}
    //         </SweetAlert>
    //     )
    // });

    this.state._notificationSystem.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: (
        <div>
          {/* <p>{from}</p> */}
          <span>{msg}</span>
        </div>
      ),
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });

    let _this = this;
    setTimeout(function () {
      _this.hideAlert();
    }, 3000);
  }

  hideAlert() {
    // this.setState({
    //     alert: null
    // });
    this.getPatientList();
    this.props.history.push("/patient-search");
  }

  onDismiss() {
    this.setState({ taskScreen: false, showProcessing: true });
    this.successAlert("Task Successfully Submitted");
  }

  onPageChange(page, sizePerPage) {
    let data = {};

    if (this.state.searchText === "") {
      data["patient_id"] = this.state.formData.patient_id;
      data["city"] = this.state.formData.city;
      data["phone"] = this.state.formData.phone;
      data["first_name"] = this.state.formData.first_name;
      data["last_name"] = this.state.formData.last_name;
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    } else {
      data["patient_id"] = this.state.formData.patient_id;
      data["city"] = this.state.formData.city;
      data["phone"] = this.state.formData.phone;
      data["first_name"] = this.state.formData.first_name;
      data["last_name"] = this.state.formData.last_name;
      data["direction"] = this.state.formData.direction;
      data["order"] = this.state.formData.order;
      data["offset"] = page === -1 ? this.state.formData.offset : page - 1;
      data["limit"] = sizePerPage;
    }

    this.setState({ sizePerPage: sizePerPage });
    this.setState({ currentPage: page });
    this.props.patientListAction(data);
  }

  onSearchChange(text) {
    if (text !== "") {
      if (text.length >= 1) {
        let data = {};
        data["patient_id"] = this.state.formData.patient_id;
        data["city"] = this.state.formData.city;
        data["phone"] = this.state.formData.phone;
        data["first_name"] = this.state.formData.first_name;
        data["last_name"] = this.state.formData.last_name;
        data["direction"] = this.state.formData.direction;
        data["order"] = this.state.formData.order;
        data["offset"] = this.state.formData.offset;
        data["limit"] = this.state.formData.limit;
        this.setState({ sizePerPage: 10 });
        this.call_api(data)

      }
    } else {
      this.componentDidMount();
      this.setState({ searchText: text });
    }
  }

  onSortChange(sortName, sortOrder) {
    if (
      this.state &&
      this.state.patientList &&
      this.state.patientList.length > 0
    ) {
      this.setState({ fetchRequest: true });
      let data = {};
      data["order"] = sortName;
      if (sortName === "patientId") {
        data["order"] = "patientClinicId";
      }

      if (sortName === "name") {
        data["order"] = "firstName";
      }

      if (sortOrder === "asc") {
        data["direction"] = "asc";
        sortingorder = "-asc";
      } else {
        data["direction"] = "desc";
        sortingorder = "-desc";
      }

      data["offset"] = this.state.formData.offset;
      data["limit"] = this.state.formData.limit;

      this.props.patientListAction(data);
    }
  }

  get_age_by_dob(cell, row, enumObject, rowIndex) {
    if (row.dob) {
      var dob = moment(row.dob, "YYYY-MM-DD").subtract(1, "days");
      var now = moment(new Date()); //todays date
      var end = moment(dob); // another date
      var duration = moment.duration(now.diff(end));
      var years = duration.years();
      var months = duration.months();
      //return years + (months > 0 ? '.' + months : '');
      // return years;

      return <div className={"ageColor_" + row.age_color}>{years}</div>;
    } else {
      return "";
    }
  }

  getName(cell, row, enumObject, rowIndex) {
    let objStarStatus = {};
    let strPatientStarStatus = "";
    if (row?.patientStarStatus)
      objStarStatus = this.props.starStatusList.find(
        (obj) => obj._id === row.patientStarStatus
      );
    if (objStarStatus) {
      strPatientStarStatus = objStarStatus.title;
    }
    var remarkP = "";

    if (row.remark && row.remark !== "") {
      remarkP = <OverlayTrigger placement="bottom" overlay={<Tooltip id="remove">{row.remark}</Tooltip>}>
        <span className="badge bgred drs-left" ><span className="glyphicon" aria-hidden="true">R</span></span>
      </OverlayTrigger>
    }
    return <div className={!row.app ? "pt-blue-color" : ""}><span>{row.name}{strPatientStarStatus}</span>
      {remarkP != "" &&
        <><br />
          <span style={{ paddingLeft: "10px" }}>{remarkP}</span>
        </>}
    </div>;
  }

  getPatientTodayVisitClass(cell, row, enumObject, rowIndex) {
    var todayVisitColor = "";
    if (row.todayVisit) {
      todayVisitColor = "today_Visit_Color";
    }
    return row.id + " " + todayVisitColor;
    // }
  }

  isApp(cell, row, enumObject, rowIndex) {
    if (row && row.app) {
      return <i className="fa fa-check" aria-hidden="true"></i>;
    } else {
      return <i className="fa fa-close fa-app-color" aria-hidden="true"></i>;
    }
  }

  chatBox(patientId, chatPatientDetails, e) {
    // localStorage.setItem('chatModel',true)
    // this.setState({chatModal:true,chatPatientId:patientId,chatPatientDetails:chatPatientDetails});
    this.setState({ chatPatientId: patientId, chatModelApi: true });
    this.props.getPatientDetailAction({
      clinic_id: localStorage.getItem("clinicId"),
      id: chatPatientDetails.patientClinicId,
    });
  }

  dietBox(patient) {
    this.setState({ dietModal: true, currentPatient: patient });
    this.setDietTitle(patient);
  }

  setDietTitle(patient) {
    let bmi = getBMI(patient.height, patient.weight);
    this.setState({ bmi });
    let title =
      "Patient Diet (" + patient.name + " - " + patient.patientNo + ")";
    title += "&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; H:" + patient.height;
    title += "&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; W:" + patient.weight;
    title += "&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; BMI:" + bmi;
    title +=
      "&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Age:" + getAgeByDob(patient.dob);
    this.setState({ dietTitle: title });
  }

  knowledgeShare(patient) {
    this.setState({ patientInfo: patient });
    this.setState({ knowledgeModal: true });
    this.props.knowledgeListAction(this.state);
  }

  taskScreenModal(taskScreen) {
    this.setState({
      taskScreen: true,
      taskScreenData: taskScreen,
      dietFromPtSeatch: false,
    });
  }

  actionButton(cell, row, enumObject, rowIndex) {
    let h = row.height;
    let w = row.weight;
    let g = row.gender;
    row.segCalorie = PF.getCalorie(h, w, g);
    row.age = PF.getAgeByDob(row.dob)
    return (
      <DropDownSearch
        openNotificationModal2={(row) => {
          this.openNotificationModal2(row);
        }}
        patient={row}
        taskScreen={row}
        chatBox={this.chatBox}
        chatPatientId={row._id}
        chatPatientDetails={row}
        knowledgeShare={(patient) => {
          this.knowledgeShare(patient);
        }}
        dietBox={this.dietBox}
        taskScreenModal={(taskScreen) => {
          this.taskScreenModal(taskScreen);
        }}
        patientUpdate={(data) => this.patientUpdate(data)}
      />
    );
  }

  handleChange(e) {
    e.preventDefault();
    let field = this.state.formData;
    field[e.target.name] = e.target.value;
    this.setState({ formData: field, isLoading: true, isNoDataFound: false });
    this.onSearchChange(e.target.value);
  }

  handleRadio(event) {
    const target = event.target;
    let field = this.state.formData;
    field[target.name] = target.value;
    this.setState({
      formData: field,
      cssCustomMessage: "none",
      selectedMessage: "",
      customMessage: "",
    });
  }

  handleChangeNotification(e) {
    e.preventDefault();
    const _this = this;
    let field = this.state.formData;

    if (e.target.name === "message_english_text") {
      field[e.target.name] = e.target.value;
      field["message_english"] = e.target.value;
      if (e.target.value === "") _this.setState({ sendButtonDisabled: true })
      else _this.setState({ sendButtonDisabled: false })
    } else {
      if (e.target.value === "Add Custom Message") {
        _this.setState({
          cssCustomMessage: "block",
          customMessage: "",
          selectedMessage: e.target.value,
          sendButtonDisabled: true,
        });
      } else {
        _this.setState({
          cssCustomMessage: "none",
          customMessage: "",
          selectedMessage: e.target.value,
          sendButtonDisabled: false,
        });
      }
      field[e.target.name] = e.target.value;
    }
    _this.setState({ formData: field });
  }

  handleNotificationType(value) {
    let field = this.state.formData;
    let isSelected = this.state.isSelected;

    if (this.state.isSelected && this.state.isSelected.length > 0) {
      this.setState({ notificationErrorMsg: "" });
    } else {
      if (value === "selected") {
        if (!this.state.isSelectStatus) {
          this.setState({
            notificationErrorMsg: "Please select patient first.",
          });
        } else {
          this.setState({
            notificationErrorMsg: "App is not downloaded for selected patient.",
          });
        }
      } else if (value === "all") {
        this.setState({ notificationErrorMsg: "" });

        // let selected = [];
        //
        // let rows = this.state.patientList;
        // for (let i = 0; i < rows.length; i++) {
        //
        //     if (rows[i].notification_token) {
        //         selected.push(rows[i]._id)
        //         //selected.push(i+1)
        //     }
        // }
        //    console.log('New value',selected)
        //
        //    this.setState({selected});
      }
    }

    field["selected_type"] = value;
    this.setState({ formData: field });
  }

  hideChatModel() {
    this.setState({ chatModal: false });
    localStorage.setItem("chatModel", false);
    let addUser = {
      userId: localStorage.getItem("_id"),
    };
    appConstants.socket.emit("screenClose", addUser);
  }

  hadleCustomMessage(e) {
    e.preventDefault();
    const _this = this;
    _this.setState({ customMessage: e.target.value });
    e.target.value === ""
      ? _this.setState({ customMessageEror: true })
      : _this.setState({ customMessageEror: false });
  }

  onRowSelect({ id, notification_token }, isSelected) {
    let selected = this.state.isSelected;
    if (notification_token) {
      if (isSelected) {
        selected.push({ patient_id: id });
      } else {
        const filteredArray = selected.filter((obj) => obj.patient_id !== id);
        selected = filteredArray;
      }
    }

    this.setState({
      isSelected: selected,
      isSelectStatus: false,
      isNotificationToken: true,
    });
    if (isSelected) {
      this.setState({
        isSelected: selected,
        isSelectStatus: true,
        isNotificationToken: true,
      });
    }
    if (notification_token) {
      this.setState({ isNotificationToken: false });
    }
  }

  onSelectAll(isSelected, rows) {
    let selected = [];
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].notification_token) {
        selected.push({ patient_id: rows[i].id });
      }
    }

    if (!isSelected) {
      this.setState({ isSelected: [], isSelectStatus: false });
    } else {
      this.setState({ isSelected: selected, isSelectStatus: true });
    }
  }

  openNotificationModal(e) {
    //e.preventDefault();

    let isSelected = this.state.isSelected;
    this.setState({
      notificationModal: true,
      formArr: [],
      notificationErrorMsg: "",
    });

    if (this.state.isSelected && this.state.isSelected.length > 0) {
      this.setState({ notificationErrorMsg: "" });
    } else {
      if (
        !this.state.isSelectStatus &&
        this.state.formData.selected_type === "selected"
      ) {
        this.setState({ notificationErrorMsg: "Please select patient first." });
      } else {
        if (this.state.isNotificationToken) {
          this.setState({
            notificationErrorMsg: "App is not downloaded for selected patient.",
          });
        }
      }
    }
  }

  openNotificationModal2(row) {
    this.onRowSelect(
      {
        id: row._id,
        notification_token: row.notification_token
          ? row.notification_token
          : "",
      },
      true
    );
    this.openNotificationModal(row);
  }

  sendNotification(e) {
    e.preventDefault();
    let formData = this.state.formData;
    let data = {};

    let msg = {
      hindi: formData.message_english,
      english: formData.message_english,
    };

    data["message"] = msg;
    data["status"] = formData.selected_type;

    if (formData.selected_type === "selected") {
      data["patient"] = this.state.isSelected;
    } else {
      data["patient"] = [];
    }

    const _this = this;
    if (this.allValidate(false)) {
      this.setState({ showNotificationProcessing: true });
      _this.props.patientNotificationAction(data);

      // _this.setState({isSelected: []})
    }
    //validation.errors()
  }

  allValidate(check) {
    if (!check) {
      formArr = [];
      Object.keys(rules).forEach(function (key) {
        formArr[key] = "TT";
      });
      this.setState({
        formArr,
      });
    }
    if (validation.passes()) {
      return 1;
    }
  }

  customMessageValidate() {
    const _this = this;
    if (this.state.customMessage === "") {
      _this.setState({ customMessageEror: true });
      return false;
    } else {
      _this.setState({ customMessageEror: false });
      return true;
    }
  }

  handleClearBtn(e) {
    let formData = this.state.formData;
    formData["patient_id"] = "";
    formData["first_name"] = "";
    formData["last_name"] = "";
    formData["city"] = "";
    formData["phone"] = "";

    this.setState({ formData: formData });
    this.getPatientList();
  }

  funModelStatus() {
    this.setState({ reportsModel: false });
  }

  getReports(cell, row, enumObject, rowIndex) {
    let colorCls = "";

    let { reportCount } = row;

    let { readCount, unReadCount } = reportCount || {
      readCount: 0,
      unReadCount: 0,
    };

    let totCount = readCount + unReadCount;

    if (unReadCount === 0) {
      colorCls = "badge";
    } else {
      colorCls = "badge bgred";
    }

    return (
      <div
        className="doc-label"
        onClick={() =>
          this.setState({ taskScreenData: row, reportsModel: true })
        }
      >
        <div className={colorCls}>{totCount} </div>{" "}
      </div>
    );
  }

  addCustomMessage(evt) {
    evt.preventDefault();
    const _this = this;
    if (this.customMessageValidate()) {
      let field = {
        direction: "desc",
        id: "",
        order: "createdAt",
        offset: 0,
        limit: 10,
        limitStatus: false,
        message: this.state.customMessage,
      };
      let fields = this.state.formData;
      fields["message_english"] = this.state.customMessage;
      _this.setState({ formData: fields });
      _this.props.AddNotificationMsgAction(field);
      //_this.setState({ customMessage: "" });

      //_this.props.timeListAction();
    }
    //validation.errors()
  }

  patientUpdate(data) {
    if (data) {
      let patientData = this.state.patientList;
      var localindex_index = patientData
        .map(function (el) {
          return el._id;
        })
        .indexOf(data.patient_id);

      if (localindex_index != -1) {
        patientData[localindex_index]["name"] =
          data.title + " " + data.first_name + " " + data.last_name;
        patientData[localindex_index]["age"] = data.age;
        patientData[localindex_index]["city"] = data.city;
        patientData[localindex_index]["firstName"] = data.first_name;
        patientData[localindex_index]["lastName"] = data.last_name;
        patientData[localindex_index]["title"] = data.title;
        patientData[localindex_index]["gender"] = data.gender;
        patientData[localindex_index]["_id"] = data.patient_id;
        patientData[localindex_index]["dob"] = data.dob;
        patientData[localindex_index]["econsultation"] = data.econsultation;
        patientData[localindex_index]["height"] = data.height;
        patientData[localindex_index]["weight"] = data.weight;
        patientData[localindex_index]["phone"] = data.phone;
        patientData[localindex_index]["remark"] = data.remark;
        patientData[localindex_index]["is_dob"] = data.is_dob;
        patientData[localindex_index]["age_color"] = data.age_color;
        this.setState({ patientList: patientData });
        //onPageChange(formData.page+1,formData.limit);
      }
    }
  }

  call_api(data) {
    let _this = this
    if (call_validate_cost_timer) {
      clearTimeout(call_validate_cost_timer);
    }

    call_validate_cost_timer = setTimeout(async function () {
      _this.props.patientListAction(data)
    }, 700);
  }

  render() {
    validation = new Validator(this.state.formData, rules, mess);
    validation.passes();
    validation.fails();

    const selectRowProp = {
      mode: "checkbox",
      // clickToSelect: true,  // enable click to select
      // selected: ["5ebd2adf855392459110990a", "5eb906e18553924591109883"],

      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
    };

    const options = {
      onSortChange: this.onSortChange,
      clearSearch: true,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      prePage: "Prev", // Previous page button text
      nextPage: "Next", // Next page button text
      firstPage: "First", // First page button text
      lastPage: "Last", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      paginationPosition: "bottom", // default is bottom, top and both is all available
      sizePerPage: this.state.sizePerPage,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      noDataText: this._setTableOption(),
    };

    const reportModal = (
      <Reports
        visitInfo={this.state.taskScreenData}
        hideButton="true"
        modelStatus={this.state.reportsModel}
        funModelStatus={this.funModelStatus}
      />
    );



    return (
      <div
        className="main-content patient-search"
        style={{ padding: "15px 0px" }}
      >
        <NotificationSystem ref="notificationSystem" style={style} />
        {localStorage.getItem("is_report_folder_facility") &&
          localStorage.getItem("is_report_folder_facility") === "yes" &&
          reportModal}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div className="fresh-datatables   table-responsive-new-withoutScroll">
                    <Row className="search-section patient_search">
                      <Col sm={6} md={1} className="form-group">
                        <FormControl
                          type="text"
                          name="patient_id"
                          autocomplete="off"
                          id="patient_id"
                          value={this.state.formData.patient_id}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="Patient Id"
                        />
                      </Col>
                      <Col sm={6} md={2} className="form-group">
                        <FormControl
                          type="text"
                          name="first_name"
                          autocomplete="off"
                          id="first_name"
                          value={this.state.formData.first_name}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="First Name"
                        />
                      </Col>
                      <Col sm={6} md={2} className="form-group">
                        <FormControl
                          type="text"
                          name="last_name"
                          autocomplete="off"
                          id="last_name"
                          value={this.state.formData.last_name}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="Last Name"
                        />
                      </Col>

                      <Col sm={6} md={2} className="form-group">
                        <FormControl
                          type="text"
                          name="phone"
                          autocomplete="off"
                          id="phone"
                          value={this.state.formData.phone}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="Mobile Number"
                        />
                      </Col>
                      <Col sm={6} md={2} className="form-group">
                        <FormControl
                          type="text"
                          name="city"
                          autocomplete="off"
                          id="city"
                          value={this.state.formData.city}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                          placeholder="City"
                        />
                      </Col>
                      <Col sm={6} md={1} className="form-group btn-hold">
                        <Button
                          type="button"
                          onClick={(e) => {
                            this.handleClearBtn(e);
                          }}
                        >
                          Clear
                        </Button>
                      </Col>
                      {/*<Button className="btn-fill btn btn-warning" onClick={() => this.setState({ notificationModal: true, notificationErrorMsg:'' })}>Send Notification</Button> */}
                      <Col
                        sm={6}
                        md={2}
                        className="form-group pull-right btn-hold"
                      >
                        <Button
                          className="btn-fill btn btn-primary  pull-right "
                          onClick={(e) => {
                            this.openNotificationModal(e);
                          }}
                        >
                          Send Notification
                        </Button>
                      </Col>
                    </Row>

                    <div className="fresh-datatables dashboard table-responsive">
                      {/*  <BootstrapTable remote={true}  fetchInfo={ { dataTotalSize: this.state.totalCount } } key={ this.state.patientList } data={this.state.patientList} pagination={true} options={options} striped hover condensed scrollTop={'Bottom'}>
                                              <TableHeaderColumn hidden={true} tdAttr={{ 'data-attr': '_id' }} dataField='_id' isKey searchable={false} dataSort={true}>Id</TableHeaderColumn> */}

                      <BootstrapTable
                        deleteRow={false}
                        key={this.state.patientList}
                        data={this.state.patientList}
                        search={false}
                        selectRow={selectRowProp}
                        pagination={true}
                        options={options}
                        striped
                        hover
                        condensed
                        scrollTop={"Bottom"}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.state.totalCount }}
                        sort={true}
                        ref={this.onBootstrapTableRef}
                      >
                        <TableHeaderColumn
                          hidden={true}
                          tdAttr={{ "data-attr": "_id" }}
                          dataField="patientId"
                          dataSort={true}
                          isKey={true}
                          searchable={false}
                        >
                          Id
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          thStyle={{ width: "3%" }}
                          tdStyle={{ width: "3%" }}
                          tdAttr={{ "data-attr": "#" }}
                          columnClassName={this.getPatientTodayVisitClass}
                          dataField="sn"
                        >
                          #
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          thStyle={{ width: "8%" }}
                          tdStyle={{ width: "8%" }}
                          tdAttr={{ "data-attr": "DATE" }}
                          dataField="created_date"
                        >
                          DATE
                        </TableHeaderColumn>

                        <TableHeaderColumn
                          thStyle={{ width: "7%" }}
                          tdStyle={{ width: "7%" }}
                          tdAttr={{ "data-attr": "ID" }}
                          dataField="patientId"
                          dataSort
                        >
                          ID &nbsp;
                          <i
                            className={"fa fa-sort" + sortingorder}
                            aria-hidden="true"
                          ></i>
                        </TableHeaderColumn>

                        {localStorage.getItem("userType") !==
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "15%" }}
                              tdStyle={{ width: "15%" }}
                              tdAttr={{ "data-attr": "NAME" }}
                              dataField="name"
                              dataFormat={this.getName}
                              dataSort
                            >
                              NAME&nbsp;
                              <i
                                className={"fa fa-sort" + sortingorder}
                                aria-hidden="true"
                              ></i>
                            </TableHeaderColumn>
                          )}

                        {localStorage.getItem("userType") ===
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "40%" }}
                              tdStyle={{ width: "40%" }}
                              tdAttr={{ "data-attr": "NAME" }}
                              dataField="name"
                              dataFormat={this.getName}
                              dataSort
                            >
                              NAME&nbsp;
                              <i
                                className={"fa fa-sort" + sortingorder}
                                aria-hidden="true"
                              ></i>
                            </TableHeaderColumn>
                          )}

                        <TableHeaderColumn
                          thStyle={{ width: "5%" }}
                          tdStyle={{ width: "5%" }}
                          tdAttr={{ "data-attr": "APP" }}
                          dataField=""
                          dataFormat={this.isApp.bind(this)}
                        >
                          APP
                        </TableHeaderColumn>

                        {localStorage.getItem("userType") !==
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "10%" }}
                              tdStyle={{ width: "10%" }}
                              tdAttr={{ "data-attr": " DOCUMENTS (S/V)" }}
                              dataField="share_document"
                            >
                              {" "}
                              DOCUMENTS (S/V)
                            </TableHeaderColumn>
                          )}
                        {localStorage.getItem("userType") !==
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "8%" }}
                              tdStyle={{ width: "8%" }}
                              tdAttr={{ "data-attr": "VIDEO (S/V)" }}
                              dataField="share_video"
                            >
                              VIDEO (S/V)
                            </TableHeaderColumn>
                          )}
                        {localStorage.getItem("userType") !==
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "5%" }}
                              tdStyle={{ width: "5%" }}
                              tdAttr={{ "data-attr": "TASK" }}
                              dataField="task_count"
                            >
                              TASK
                            </TableHeaderColumn>
                          )}
                        {localStorage.getItem("userType") !==
                          "receptionist" && (
                            <TableHeaderColumn
                              thStyle={{ width: "8%" }}
                              tdStyle={{ width: "8%" }}
                              tdAttr={{ "data-attr": "DIET CHART (P/S/E)" }}
                              dataField="dietCount"
                            >
                              DIET CHART (P/S/E)
                            </TableHeaderColumn>
                          )}

                        <TableHeaderColumn
                          thStyle={{ width: "8%" }}
                          tdStyle={{ width: "8%" }}
                          tdAttr={{ "data-attr": "CITY" }}
                          dataField="city"
                        >
                          CITY
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "5%" }}
                          tdStyle={{ width: "5%" }}
                          tdAttr={{ "data-attr": "AGE" }}
                          dataField=""
                          dataFormat={this.get_age_by_dob.bind(this)}
                        >
                          AGE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "80px" }}
                          tdStyle={{ width: "80px" }}
                          tdAttr={{ "data-attr": "NEXT VISIT" }}
                          dataFormat={this.getReports.bind(this)}
                        >
                          Reports
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          thStyle={{ width: "8%" }}
                          tdStyle={{ width: "8%" }}
                          tdAttr={{ "data-attr": "NEXT VISIT" }}
                          dataField="next_visit"
                        >
                          NEXT VISIT
                        </TableHeaderColumn>
                        <TableHeaderColumn
                          className=""
                          thStyle={{ width: "12%", minWidth: "100px" }}
                          tdStyle={{ width: "12%", minWidth: "100px" }}
                          tdAttr={{ "data-attr": "Action" }}
                          dataFormat={this.actionButton.bind(this)}
                        >
                          Action
                        </TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>

        <Modal
          className="pa-chat-md"
          show={this.state.chatModal}
          onHide={this.hideChatModel.bind(this)}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body className="Knowledge-Share">
            <Row>
              <Col md={12}>
                <Chat
                  chatPatientId={this.state.chatPatientId}
                  chatPatientDetails={this.state.chatPatientDetails}
                  activeAllChat={true}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          className="pa-task-screen"
          show={this.state.taskScreen}
          onHide={() => this.setState({ taskScreen: false })}
          dialogClassName="modal-lg"
        >
          <button
            type="button"
            className="close"
            onClick={() => this.setState({ taskScreen: false })}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <Modal.Body className="Knowledge-Share ">
            <Row>
              <Col md={12}>
                <TaskScreen
                  taskScreenData={this.state.taskScreenData}
                  ptSearch={this.state.dietFromPtSeatch}
                  shareDietByEd={localStorage.userType === "educator" ? false : true}
                  onDismiss1={this.onDismiss}
                />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.notificationModal}
          onHide={() =>
            this.setState({
              notificationModal: false,
              cssCustomMessage: "none",
              selectedMessage: "",
              customMessage: "",
              sendButtonDisabled: true,
            })
          }
          className="notification-popup"
        >
          <Modal.Header closeButton className="bg-thumbcolor">
            <Modal.Title id="example-modal-sizes-title-lg">
              Send Notification to Patient
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="send-notification form-horizontal">
            <Row>
              <Col md={12}>
                <p className="errorMsg notifyErrBox">
                  {this.state.notificationErrorMsg}
                </p>
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Type
                  </Col>
                  <Col sm={9} className="Radio-mmd inlline-n">
                    <Radio
                      number="6"
                      option="dd"
                      name="type"
                      onChange={this.handleRadio}
                      checked={this.state.formData.type === "dd"}
                      label="Select Message from Dropdown"
                    />

                    <Radio
                      number="5"
                      option="text"
                      name="type"
                      onChange={this.handleRadio}
                      checked={this.state.formData.type === "text"}
                      label="Select to Type Your Message"
                    />
                  </Col>
                </FormGroup>

                {this.state.formData.type === "dd" && (
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                          Select Message <span className="star">*</span>
                        </Col>
                        <Col sm={9}>
                          {/*<FormControl rows="3" componentClass="textarea" name="message_english" bsClass="form-control" defaultValue={this.state.formData.message_english} onChange={e => { this.handleChangeNotification(e); }} /> */}

                          <FormControl
                            componentClass="select"
                            name="message_english"
                            value={this.state.selectedMessage}
                            onChange={(e) => {
                              this.handleChangeNotification(e);
                            }}
                          >
                            <option value="">Please select</option>
                            {this.state.notificationList &&
                              this.state.notificationList.length > 0 &&
                              this.state.notificationList.map(function (item) {
                                return (
                                  <option key={item._id} value={item.message}>
                                    {item.message}
                                  </option>
                                );
                              })}
                            <option value="Add Custom Message">
                              <strong>Add Custom Message</strong>
                            </option>
                          </FormControl>

                          <span className="errorMsg">
                            {this.state.message_englishError}
                            {this.state.formArr.message_english &&
                              validation.errors.first("message_english")}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup
                        style={{ display: this.state.cssCustomMessage }}
                      >
                        <Col componentClass={ControlLabel} sm={3}>
                          Add Custom Message <span className="star">*</span>
                        </Col>
                        <Col sm={9}>
                          <FormControl
                            rows="3"
                            componentClass="textarea"
                            name="custom_message"
                            bsClass="form-control"
                            onChange={(e) => {
                              this.hadleCustomMessage(e);
                            }}
                            value={this.state.customMessage}
                          />
                          {this.state.customMessageEror && (
                            <span className="errorMsg">
                              This Field is required
                            </span>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup
                        style={{ display: this.state.cssCustomMessage }}
                      >
                        <Col componentClass={ControlLabel} sm={4}></Col>
                        <Col sm={8}>
                          {/*<Button className="btn-fill btn btn-warning pull-right" onClick={() => this.setState({ notificationModal: false  })}>Send</Button> */}
                          <Button
                            className="btn-fill btn btn-warning pull-right"
                            onClick={this.addCustomMessage.bind(this)}
                          >
                            Add{" "}
                          </Button>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {this.state.formData.type === "text" && (
                  <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                      Type Message <span className="star">*</span>
                    </Col>
                    <Col sm={9}>
                      <FormControl
                        rows="3"
                        componentClass="textarea"
                        name="message_english_text"
                        bsClass="form-control"
                        defaultValue={this.state.formData.message_english_text}
                        onChange={(e) => {
                          this.handleChangeNotification(e);
                        }}
                      />
                      <span className="errorMsg">
                        {this.state.message_englishError}
                        {this.state.formArr.message_english &&
                          validation.errors.first("message_english")}
                      </span>
                    </Col>
                  </FormGroup>
                )}

                <FormGroup>
                  <Col componentClass={ControlLabel} sm={3}>
                    Select Type <span className="star">*</span>
                  </Col>
                  <Col sm={4}>
                    <Radio
                      number={"all"}
                      key={"all"}
                      option={"all"}
                      name={"selected_type"}
                      checked={
                        this.state.formData.selected_type === "all"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        this.handleNotificationType("all");
                      }}
                      label={"All Patient"}
                    />
                  </Col>
                  <Col sm={4}>
                    <Radio
                      number={"selected"}
                      key={"selected"}
                      option={"selected"}
                      name={"selected_type"}
                      checked={
                        this.state.formData.selected_type === "selected"
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        this.handleNotificationType("selected");
                      }}
                      label={"Selected Patient"}
                    />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Col componentClass={ControlLabel} sm={4}></Col>
                  <Col sm={8}>
                    {/*<Button className="btn-fill btn btn-warning pull-right" onClick={() => this.setState({ notificationModal: false  })}>Send</Button> */}
                    <Button
                      className="btn-fill btn btn-warning pull-right"
                      onClick={(e) => {
                        this.sendNotification(e);
                      }}
                      disabled={
                        this.state.notificationErrorMsg ||
                        this.state.sendButtonDisabled ||
                        this.state.showNotificationProcessing
                      }
                    >
                      Send
                      {this.state.showNotificationProcessing && (
                        <i class="fa fa-spinner fa-spin"></i>
                      )}
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        {/* Diet model */}
        <Modal
          className="pa-diet-screen"
          show={this.state.dietModal}
          onHide={() => this.setState({ dietModal: false })}
          dialogClassName="modal-lg"
        >
          <Modal.Header closeButton className="header-dietpop">
            <Modal.Title
              id="example-modal-sizes-title-lg"
              dangerouslySetInnerHTML={{ __html: this.state.dietTitle }}
            />
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Diet patient={this.state.currentPatient} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        {/* /Diet model */}

        <div>
          {this.state.alert}
          <Modal
            show={this.state.knowledgeModal}
            onHide={() => this.setState({ knowledgeModal: false })}
            dialogClassName="modal-lg"
          >
            <Modal.Header closeButton className="crosscalo">
              <Modal.Title
                id="example-modal-sizes-title-lg"
                className="spc-btom "
              >
                Patient Knowledge Center 2({this.state.patientInfo.name} -{" "}
                {this.state.patientInfo.patientId})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="Knowledge-Share table-responsive">
              <Table className="table table-striped table-hover table-bordered popline-boxes">
                <thead className="flip-content bordered-palegreen">
                  <tr>
                    <th className="W50">VIDEOS</th>
                    <th className="W50">DOCUMENTS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="W50 right-lines">
                      {this.state.knowledgeList &&
                        this.state.knowledgeList.length > 0 &&
                        this.state.knowledgeList.map((key, i) => {
                          let data;
                          if (key.title && key.type === "video") {
                            let checked = false;
                            for (let x in this.state.videos) {
                              if (this.state.videos[x].id === key._id) {
                                checked = true;
                              }
                            }
                            data = (
                              <Row>
                                <Col sm={12}>
                                  {key.clinicShowStatus ? (
                                    <span>
                                      <Checkbox
                                        number={key._id}
                                        label={key.title}
                                        checked={checked}
                                        onClick={(e) => {
                                          this.handleKnowledgeVideoChange(
                                            key._id,
                                            key.title,
                                            key.image
                                          );
                                        }}
                                      />

                                      <VideoDocumentView
                                        pvDetail={this.state.patientInfo}
                                        link={key.image}
                                        title={key.title}
                                        type={"video"}
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            );
                          }
                          return data;
                        })}
                    </td>
                    <td className="W50 sapcelineheight">
                      {this.state.knowledgeList &&
                        this.state.knowledgeList.length > 0 &&
                        this.state.knowledgeList.map((key, i) => {
                          let data;
                          if (key.title && key.type === "image") {
                            let checked = false;
                            for (let x in this.state.documents) {
                              if (this.state.documents[x].id === key._id) {
                                checked = true;
                              }
                            }
                            data = (
                              <Row>
                                <Col sm={12}>
                                  {key.clinicShowStatus ? (
                                    <span>
                                      <Checkbox
                                        number={key._id}
                                        label={key.title}
                                        checked={checked}
                                        onClick={(e) => {
                                          this.handleKnowledgeDocChange(
                                            key._id,
                                            key.title,
                                            key.image
                                          );
                                        }}
                                      />

                                      <VideoDocumentView
                                        pvDetail={this.state.patientInfo}
                                        link={key.image}
                                        title={key.title}
                                        type={"image"}
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            );
                          }
                          return data;
                        })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-fill btn-wd btn btn-default pull-left"
                onClick={(e) => {
                  this.documentPrint();
                }}
              >
                Print
              </Button>
              <Button
                className="btn-fill btn btn-primary pull-right"
                onClick={(e) => {
                  this.submitTask(e);
                }}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientList: state.patient.patientList,
    isPatientList: state.patient.isPatientList,
    isPatientListError: state.patient.isPatientListError,

    PrintShareDocument: state.home.printShareDocument,
    isPrintShareDocument: state.home.isPrintShareDocument,
    isPrintShareDocumentError: state.home.isPrintShareDocumentError,

    KnowledgeList: state.home.knowledgeList,
    isKnowledgeList: state.home.isKnowledgeList,
    isKnowledgeListError: state.home.isKnowledgeListError,

    PrintShareDocument: state.home.printShareDocument,
    isPrintShareDocument: state.home.isPrintShareDocument,
    isPrintShareDocumentError: state.home.isPrintShareDocumentError,

    PatientNotification: state.home.PatientNotification,
    isPatientNotification: state.home.isPatientNotification,
    isPatientNotificationError: state.home.isPatientNotificationError,

    patientDetails: state.home.PatientDetail,

    notificationList: state.master.notificationList,
    isNotification: state.master.isNotification,
    isNotificationError: state.master.isNotificationError,

    isAddNotificationMsg: state.master.isAddNotificationMsg,
    isAddNotificationMsgError: state.master.isAddNotificationMsgError,
    addNotificationMsg: state.master.addNotificationMsg,

    isPatientStatus: state.home.isPatientStatus,

    starStatusList: state.home.starStatusList,
  };
}
export default withRouter(
  connect(mapStateToProps, {
    knowledgeListAction,
    patientListAction,
    printShareDocumentAction,
    patientNotificationAction,
    getPatientDetailAction,
    NotificationListAction,
    AddNotificationMsgAction,
  })(PatientSearch)
);

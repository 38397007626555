/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import {
    Grid,
    Row,
    Col,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Button,
    Carousel,
} from "react-bootstrap";
import * as API from "../../api/starStatus";
import Card from "Admin/components/Card/Card.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

const AddEditStarStatus = (props) => {
    let history = useHistory();

    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [mode, setMode] = useState("add");
    const [id, setId] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);


    const inputHandler = (e) => {
        //setIsSpecialChar(false)
        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart();
        setFormData({ ...formData, ...saveFormData });
        validate();
    };
    const inputHandlerDrop = (e) => {
        let saveFormData = formData;
        let str = e.target.value;
        saveFormData[e.target.name] = str;
        setFormData({ ...formData, ...saveFormData });
        validate();
    };

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;
        if (post.title && post.title != "") {
            errArr.title = "";
        } else {
            errArr.title = "error";
            errflag = false;
        }

        if (post.description && post.description != "") {
            errArr.description = "";
        } else {
            errArr.description = "error";
            errflag = false;
        }

        setErrors({ ...errArr });
        return errflag;
    };


    const submitHandler = async (event) => {
        event.preventDefault();
        let saveFormData = formData;
        saveFormData["title"] = formData.title?.trim();
        saveFormData["description"] = formData.description?.trim();
        setFormData({ ...formData, ...saveFormData });
        if (validate()) {
            setIsDisabled(true);
            setIsLoading(false);
            let resp = "";
            if (mode === "add") {
                resp = await API.addStarStatus(saveFormData);
                setIsDisabled(false);
                if (resp.status == 200) {
                    history.push({ pathname: "/admin/star-status-list", from: "add" });
                }
            } else {
                // sentData.append("id", iconsId);
                saveFormData["id"] = id;
                resp = await API.updateStarStatus(saveFormData);
                setIsDisabled(false);
                if (resp.status == 200) {
                    history.push({ pathname: "/admin/star-status-list", from: "edit" });
                }
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let row = props.location.state;
        let saveFormData = formData;
        saveFormData["title"] = "";
        saveFormData["description"] = "";

        if (row !== undefined && row) {
            setMode("edit");
            setId(row._id);
            let tempData = {}
            tempData.title = props.location.state.title
            tempData.description = props.location.state.description
            setFormData({ ...tempData });
        }
    }, []);


    return (
        <div className="main-content">
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={
                                <legend className="line-removes">
                                    <Button
                                        className="go-back-btn-1"
                                        bsStyle="info"
                                        onClick={(e) => {
                                            history.goBack();
                                        }}
                                    >
                                        Back
                                    </Button>
                                </legend>
                            }
                            content={
                                <Form horizontal className="add-supportsystem_form">
                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>
                                                            Title<span className="star">*</span>
                                                        </ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="title"
                                                            id="tile"
                                                            placeholder="Title"
                                                            onChange={(e) => {
                                                                inputHandler(e);
                                                            }}
                                                            autocomplete="off"
                                                            value={formData.title ? formData.title : ""}
                                                            className={
                                                                "insulin-input " +
                                                                (errors.title ? errors.title : "")
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Description<span className="star">*</span></ControlLabel>
                                                        <FormControl
                                                            rows="5"
                                                            componentClass="textarea"
                                                            name="description"
                                                            id="description"
                                                            placeholder="Description"
                                                            onChange={e => { inputHandler(e); }}
                                                            value={formData.description ? formData.description : ""}
                                                            className={(errors.description ? errors.description : "")
                                                            }
                                                        />

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <FormGroup>
                                            <ControlLabel className="col-sm-2"></ControlLabel>
                                            <Col sm={12} className="btn_wrap">
                                                <Row>
                                                    <Col md={12}>
                                                        <button
                                                            type="button"
                                                            className="btn-fill btn-wd btn btn-primary"
                                                            onClick={(e) => {
                                                                submitHandler(e);
                                                            }}
                                                            disabled={isDisabled}
                                                        >
                                                            {isDisabled && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                                                            Submit
                                                        </button>
                                                        &nbsp;
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    )
}

export default AddEditStarStatus
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import * as actionTypes from "./actionTypes";
import * as API from "Admin/api/clinic";
import axios from "axios";
//-- List
export const clinicListRequest = () => ({
    type: actionTypes.GET_CLINIC_LIST_REQUEST,
});
export const clinicListSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_LIST_SUCCESS,
    payload: {
        response,
    },
});
export const clinicListError = (error) => ({
    type: actionTypes.GET_CLINIC_LIST_ERROR,
    payload: {
        error,
    },
});
export const clinicListAction = (data) => {
    return async (dispatch) => {
        dispatch(clinicListRequest());
        const FormData = {
            limit: data.limit,
            offset: data.offset,
            order: data.order,
            direction: data.direction,
            subscription_ending: data.subscription_ending ?
                Number(data.subscription_ending) : "",
            status: data.status,
            data_updated: data.data_updated,
            subscription_status: data.subscription_status,
            limitStatus: data.limitStatus,
            search: data.search,
            clinic_id: data.clinic_id,
            clinic_name: data.clinic_name,
            city: data.city,
            searchText: data.searchText,
            state: data.state,
            specialization: data.specialization,
            imageType: data.imageType
        };
        let sessionToken = localStorage.getItem("token");
        // return API.clinicList(FormData)
        return await axios({
            method: "post",
            url: process.env.REACT_APP_PERFORMANCE_REPORT_URL + "admin/clinicList",
            headers: {
                Authorization: sessionToken,
                "Content-Type": "application/json",
            },
            data: FormData,
        })
            .then((response) => {
                dispatch(clinicListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    clinicListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//-- Detail
export const clinicDetailRequest = () => ({
    type: actionTypes.GET_CLINIC_DETAIL_REQUEST,
});
export const clinicDetailSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DETAIL_SUCCESS,
    payload: {
        response,
    },
});
export const clinicDetailError = (error) => ({
    type: actionTypes.GET_CLINIC_DETAIL_ERROR,
    payload: {
        error,
    },
});
export const clinicDetailAction = (data) => {
    return (dispatch) => {
        dispatch(clinicDetailRequest());
        const FormData = {
            id: data.id,
        };
        return API.clinicDetail(FormData)
            .then((response) => {
                dispatch(clinicDetailSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    clinicDetailError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//--Add Clinic
export const addClinicRequest = () => ({
    type: actionTypes.POST_ADD_CLINIC_REQUEST,
});
export const addClinicSuccess = (response) => ({
    type: actionTypes.GET_ADD_CLINIC_SUCCESS,
    payload: {
        response,
    },
});
export const addClinicError = (error) => ({
    type: actionTypes.GET_ADD_CLINIC_ERROR,
    payload: {
        error,
    },
});
export const addClinicAction = (data) => {
    return (dispatch) => {
        dispatch(addClinicRequest());

        if (data.formData) {
            var formData = {
                invitation_id: data.formData.invitation_id,
                doctorName: data.formData.doctorName,
                degree: data.formData.degree,
                clinic_name: data.formData.clinic_name,
                short_name: data.formData.short_name,
                specializations_add: data.formData.specializations_add,
                web: data.formData.clinic_website,

                phone: data.formData.phoneno,
                mobile: data.formData.mobile_no,
                appointmentno: data.formData.appointment_no,
                emergency: data.formData.emergency_no,
                email: data.formData.email,
                pattern: data.formData.pattern,
                is_email_facility: data.formData.email_facility,
                is_chat_facility: data.formData.chat_facility,
                data_updated: data.formData.data_updated,
                status: data.formData.status,
                appointment_reminder: data.formData.appointment_notification,
                background_image: data.formData.background_image,
                contact_image: data.formData.contact_image,
                logo_image: data.formData.logo_image,
                lat: data.formData.lat,
                long: data.formData.lng,
                diet_print_format: data.formData.diet_print_format,
                print_header_margin: data.formData.margin_top,
                top_margin: data.formData.margin_top,
                bottom_margin: data.formData.margin_bottom,
                left_margin: data.formData.margin_left,
                right_margin: data.formData.margin_right,
                week_off: data.formData.week_off,
                holiday_date: data.formData.holiday_date,
                specializations: data.formData.specialization,
                appointmentNoShow: data.formData.appointmentNoShow,
                phoneNoShow: data.formData.phoneNoShow,
                mobileNoShow: data.formData.mobileNoShow,
                emergencyShow: data.formData.emergencyShow,
                description: data.formData.description,
                clinic_name_font: data.formData.clinic_name_font,
                address_font: data.formData.address_font,
                limit: data.formData.limit ? Number(data.formData.limit) : "",
                slot: data.formData.slot ? Number(data.formData.slot) : "",
                educatorDietStatus: data.formData.educatorDietStatus ?
                    data.formData.educatorDietStatus : "",
                patientNotificationDay: data.formData.patientNotificationDay ?
                    Number(data.formData.patientNotificationDay) : 1,
                address: data.formData.address,
                address2: data.formData.address2,
                doctor_image: data.formData.doctor_image,
                mci_number: data.formData.mci_number,
                insulinCalculator: data.formData.insulinCalculator,
                clinic_email: data.formData.clinic_email,
            };
        }

        return API.addClinic(formData)
            .then((response) => {
                dispatch(addClinicSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    addClinicError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//--Add Clinic Csv
export const addClinicCsvRequest = () => ({
    type: actionTypes.ADD_CLINIC_CSV_REQUEST,
});
export const addClinicCsvSuccess = (response) => ({
    type: actionTypes.ADD_CLINIC_CSV_SUCCESS,
    payload: {
        response,
    },
});
export const addClinicCsvError = (error) => ({
    type: actionTypes.ADD_CLINIC_CSV_ERROR,
    payload: {
        error,
    },
});
export const addClinicCsvAction = (data) => {
    return (dispatch) => {
        dispatch(addClinicCsvRequest());

        return API.addClinicCsv(data)
            .then((response) => {
                dispatch(addClinicCsvSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    addClinicCsvError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

/* Clinic Status Change  */
export const ClinicChangeStatusRequest = () => ({
    type: actionTypes.GET_CLINIC_CHANGE_STATUS_REQUEST,
});
export const ClinicChangeStatusSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_CHANGE_STATUS_SUCCESS,
    payload: {
        response,
    },
});
export const ClinicChangeStatusError = (error) => ({
    type: actionTypes.GET_CLINIC_CHANGE_STATUS_ERROR,
    payload: {
        error,
    },
});
export const clinicchangestatusAction = (data) => {
    return (dispatch) => {
        dispatch(ClinicChangeStatusRequest());
        const FormData = {
            id: data._id,
            status: data.status,
        };
        return API.changeClinicStatus(FormData)
            .then((response) => {
                dispatch(ClinicChangeStatusSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    ClinicChangeStatusError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

/* Clinic Deleted  */
export const ClinicDeletedequest = () => ({
    type: actionTypes.GET_CLINIC_DELETED_REQUEST,
});
export const ClinicDeletedSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_DELETED_SUCCESS,
    payload: {
        response,
    },
});
export const ClinicDeletedError = (error) => ({
    type: actionTypes.GET_CLINIC_DELETED_ERROR,
    payload: {
        error,
    },
});
export const clinicDeletedAction = (data) => {
    return (dispatch) => {
        dispatch(ClinicDeletedequest());

        return API.clinicDeleted(data)
            .then((response) => {
                dispatch(ClinicDeletedSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    ClinicDeletedError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

/* Clinic Deleted  */
export const SendLoginDetailRequest = () => ({
    type: actionTypes.SEND_LOGIN_DETAIL_REQUEST,
});
export const SendLoginDetailSuccess = (response) => ({
    type: actionTypes.SEND_LOGIN_DETAIL_SUCCESS,
    payload: {
        response,
    },
});
export const SendLoginDetailError = (error) => ({
    type: actionTypes.SEND_LOGIN_DETAIL_ERROR,
    payload: {
        error,
    },
});

export const sendLoginDetailAction = (data) => {
    return (dispatch) => {
        dispatch(SendLoginDetailRequest());

        return API.sendLoginDetail(data)
            .then((response) => {
                dispatch(SendLoginDetailSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    SendLoginDetailError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

/* Clinic Image Remove*/
export const ImageRemoveRequest = () => ({
    type: actionTypes.GET_IMAGE_REMOVE_REQUEST,
});
export const ImageRemoveSuccess = (response) => ({
    type: actionTypes.GET_IMAGE_REMOVE_SUCCESS,
    payload: {
        response,
    },
});
export const ImageRemoveError = (error) => ({
    type: actionTypes.GET_IMAGE_REMOVE_ERROR,
    payload: {
        error,
    },
});
export const ImageRemoveAction = (data) => {
    return (dispatch) => {
        dispatch(ImageRemoveRequest());
        const FormData = {
            id: data._id,
            image_type: data.image_type,
        };
        return API.ImageRemove(FormData)
            .then((response) => {
                dispatch(ImageRemoveSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    ImageRemoveError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

//Update CLINIC

export const updateClinicRequest = () => ({
    type: actionTypes.POST_UPDATE_CLINIC_REQUEST,
});
export const updateClinicSuccess = (response) => ({
    type: actionTypes.GET_UPDATE_CLINIC_SUCCESS,
    payload: {
        response,
    },
});

export const updateClinicError = (error) => ({
    type: actionTypes.GET_UPDATE_CLINIC_ERROR,
    payload: {
        error,
    },
});

export const updateClinicAction = (data) => {
    return (dispatch) => {
        dispatch(updateClinicRequest());

        const holiday_date = data.formData.holiday_date.split(",");

        const formData = {
            id: data.formData.id,
            sendMail: data.sendMail,
            doctorName: data.formData.doctorName,
            degree: data.formData.degree,
            clinic_name: data.formData.clinic_name,
            short_name: data.formData.short_name,
            specializations_add: data.formData.specializations_add,
            web: data.formData.clinic_website,
            phone: data.formData.phoneno,
            mobile: data.formData.mobile_no,
            appointmentno: data.formData.appointment_no,
            emergency: data.formData.emergency_no,
            email: data.formData.email,
            pattern: data.formData.pattern,
            is_email_facility: data.formData.email_facility,
            is_chat_facility: data.formData.chat_facility,
            data_updated: data.formData.data_updated,
            status: data.formData.status,
            appointment_reminder: data.formData.appointment_notification,
            background_image: data.formData.background_image,
            contact_image: data.formData.contact_image,
            logo_image: data.formData.logo_image,
            lat: data.formData.lat,
            long: data.formData.lng,
            diet_print_format: data.formData.diet_print_format,
            print_header_margin: data.formData.margin_top,
            bottom_margin: data.formData.margin_bottom,
            top_margin: data.formData.margin_top,
            left_margin: data.formData.margin_left,
            right_margin: data.formData.margin_right,
            week_off: data.formData.week_off,
            holiday_date: holiday_date,
            specializations: data.formData.specialization,
            appointmentNoShow: data.formData.appointmentNoShow,
            phoneNoShow: data.formData.phoneNoShow,
            mobileNoShow: data.formData.mobileNoShow,
            emergencyShow: data.formData.emergencyShow,
            description: data.formData.description,
            clinic_name_font: data.formData.clinic_name_font,
            address_font: data.formData.address_font,
            limit: data.formData.limit ? Number(data.formData.limit) : "",
            slot: data.formData.slot ? Number(data.formData.slot) : "",
            educatorDietStatus: data.formData.educatorDietStatus ?
                data.formData.educatorDietStatus : "",
            patientNotificationDay: data.formData.patientNotificationDay ?
                Number(data.formData.patientNotificationDay) : 1,
            address: data.formData.address,
            address2: data.formData.address2,
            doctor_image: data.formData.doctor_image,
            mci_number: data.formData.mci_number,
            birthdayWish: data.formData.birthdayWish,
            birthdayContent: data.formData.birthdayContent,
            language: data.formData.language,
            insulinCalculator: data.formData.insulinCalculator,
            clinic_email: data.formData.clinic_email,
        };

        return API.editClinic(formData)
            .then((response) => {
                dispatch(updateClinicSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    updateClinicError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};

/* demo clinic number change Remove*/
export const ChangeDemoRequest = () => ({
    type: actionTypes.GET_CHANGE_DEMO_REQUEST,
});
export const ChangeDemoSuccess = (response) => ({
    type: actionTypes.GET_CHANGE_DEMO_SUCCESS,
    payload: {
        response,
    },
});
export const ChangeDemoError = (error) => ({
    type: actionTypes.GET_CHANGE_DEMO_ERROR,
    payload: {
        error,
    },
});
export const ChangeDemoAction = (data) => {
    return (dispatch) => {
        dispatch(ChangeDemoRequest());

        return API.ChangeDemo(data)
            .then((response) => {
                dispatch(ChangeDemoSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    ChangeDemoError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};


export const ClinicSimpleListRequest = () => ({
    type: actionTypes.GET_CLINIC_SIMPLE_LIST_REQUEST,
});

export const ClinicSimpleListSuccess = (response) => ({
    type: actionTypes.GET_CLINIC_SIMPLE_LIST_SUCCESS,
    payload: {
        response,
    },
});

export const ClinicSimpleListError = (error) => ({
    type: actionTypes.GET_CLINIC_SIMPLE_LIST_ERROR,
    payload: {
        error,
    },
});

export const ClinicSimpleListAction = (data) => {
    return (dispatch) => {
        dispatch(ClinicSimpleListRequest());

        return API.clinicSimpleList(data != undefined ? data : {})
            .then((response) => {
                dispatch(ClinicSimpleListSuccess(response.data));
            })
            .catch((error) => {
                dispatch(
                    ClinicSimpleListError(
                        error.response !== undefined ?
                            error.response.data :
                            "Internet Connection Error"
                    )
                );
            });
    };
};
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, FormControl, Button, Carousel } from 'react-bootstrap';
import Card from 'Admin/components/Card/Card.jsx';
import * as API from '../../../../api/emr'

const AddEditDrugCompany = (props) => {
    let history = useHistory();

    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState(null);
    const [isSpecialCharacter, setIsSpecialChar] = useState(false)
    const [mode, setMode] = useState("add");
    const [drugCompanyId, setDrugCompanyId] = useState("")
    const [isCompanyAlredyExist, setIsCompanyAlreadyExist] = useState(false)
    const [companyAlreadyExistMsg, setCompanyAlreadyExistMsg] = useState("")
    const inputHandler = (e) => {
        if (isCompanyAlredyExist) {
            setIsCompanyAlreadyExist(false)
            setCompanyAlreadyExistMsg("")
        }
        let saveFormData = formData;
        let str = e.target.value.replace(/\s+/g, " ");
        saveFormData[e.target.name] = str.trimStart()
        setFormData({ ...formData, ...saveFormData });
        validate();
    }

    const validate = () => {
        let post = formData;
        let errArr = errors;
        let errflag = true;

        if (post.companyName && post.companyName != '') {
            errArr.companyName = '';
        } else { errArr.companyName = 'error'; errflag = false; }

        setErrors({ ...errArr });
        return errflag;
    }

    const submitHandler = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        if (validate()) {
            let sentData = new FormData();
            sentData.append('companyName', formData.companyName)
            let resp = ""
            if (mode === "add") {
                resp = await API.addDrugCompany(sentData);
                setIsLoading(false);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setIsCompanyAlreadyExist(true)
                        setCompanyAlreadyExistMsg(resp.data.msg)
                    }
                    else history.push({ pathname: "/admin/drugCompany-list", from: "add" })
                }
            } else {
                sentData.append('id', drugCompanyId)
                resp = await API.updateDrugCompany(sentData);
                if (resp.data.statusCode == 200 && resp.data.status === "Success") {
                    if (resp.data.flag && resp.data.flag === "existing") {
                        setIsCompanyAlreadyExist(true)
                        setCompanyAlreadyExistMsg(resp.data.msg)
                    }
                    else history.push({ pathname: "/admin/drugCompany-list", from: "edit" })
                }
            }
            setIsLoading(false);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        let row = props.location.state?.row;
        let saveFormData = formData;
        saveFormData["companyName"] = "";

        if (row !== undefined && row) {
            setMode("edit");
            setDrugCompanyId(row._id);
            saveFormData["companyName"] = row.companyName;

            setFormData({ ...formData, ...saveFormData });
        }

    }, [])
    return (
        <div className="main-content">

            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title={<legend className="line-removes">
                                <Button className="go-back-btn-1" bsStyle="info" onClick={(e) => { history.goBack() }}>Back</Button>
                            </legend>}
                            content={
                                <Form horizontal className="add-supportsystem_form">

                                    <fieldset>
                                        <FormGroup>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <ControlLabel>Drug Company<span className="star">*</span></ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            name="companyName"
                                                            id="companyName"
                                                            placeholder="Company"
                                                            onChange={e => { inputHandler(e); }}
                                                            autocomplete="off"
                                                            value={formData.companyName ? formData.companyName : ''}
                                                            className={'insulin-input ' + (errors.companyName ? errors.companyName : '')}
                                                        />
                                                        {isCompanyAlredyExist &&
                                                            <span className="star">{companyAlreadyExistMsg}</span>
                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>

                                    <fieldset>
                                        <FormGroup>
                                            <ControlLabel className="col-sm-2">
                                            </ControlLabel>
                                            <Col sm={12} className="btn_wrap">
                                                <Row>
                                                    <Col md={12}>
                                                        <button
                                                            type="button"
                                                            className="btn-fill btn-wd btn btn-primary"
                                                            onClick={e => { submitHandler(e) }}
                                                            disabled={isLoading}
                                                        >
                                                            {isLoading && <span class="fa fa-spinner fa-spin spinner-btn"></span>}
                                                            Submit
                                                        </button>&nbsp;

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </Form>
                            }
                        />
                    </Col>
                </Row>
            </Grid>

        </div>
    )
}

export default AddEditDrugCompany
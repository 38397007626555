/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from "../../api/icons.js";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import moment from "moment";
import ViewBox from "../../../Front/components/ViewBox";
import ImageComponent from "../../components/Common/ImageComponent.js";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from "react-confirm-alert";

const IconsList = (props) => {
  let history = useHistory();
  let { mode } = useParams();
  const [formData, setFormData] = useState({ limit: 10, page: 0, direction: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [iconsList, setIconsList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [sortData, setSortData] = useState(false);
  const [addAdvertisement, setAddAdvertisement] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [ViewBoxData, setViewBoxData] = useState({
    show: false,
    type: "image",
    path: "",
  });
  const notificationSystem = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [sizePerPage, setSizePerPage] = useState(10);

  const getNotificationListAction = async (from = {}) => {
    setIsLoading(true);
    let resp = await API.iconsList({
      limit: formData.limit,
      offset: formData.page,
      direction: formData.direction,
      order: "createdAt",
      search: from.search,
    });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setIconsList(resp.data.data);
      setTotalCount(resp.data.count);
    }
  };

  const handleToastifyMessage = (data) => {
    let msg = "";
    if (data === "add") msg = "Icons Added Successfully";
    if (data === "edit") msg = "Icons Edit Successfully";
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (
        <span
          data-notify="icon"
          className="pe-7s-check"
          style={{
            fontSize: "30px",
            color: "#fff",
            padding: "0px !important",
            top: "0% !important",
          }}
        ></span>
      ),
      message: <div>{msg}</div>,
      level: "success",
      position: "tr",
      autoDismiss: 2,
    });
  };

  useEffect(() => {
    if (history?.location?.from === "add" || history?.location?.from === "edit")
      handleToastifyMessage(history?.location?.from);
    getNotificationListAction();
  }, []);

  const srNo = (cell, row, enumObject, rowIndex) => {
    return rowIndex + 1 + formData.limit * formData.page;
  };

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading
          type="bars"
          color="#000000"
          style={{ margin: "0px auto", width: "40px" }}
        />
      );
    } else {
      return "No data found!";
    }
  };

  const hideViewBox = () => {
    setViewBoxData({ show: false, type: "image", path: "" });
  };

  const statusChangedHandler = async (event, index) => {
    let resp = await API.iconsChangeStatus({
      id: event._id,
      status: event.status === "active" ? "inactive" : "active",
    });
    if (resp.data.status === "Success") {
      let msg = "";
      msg = "Status has been updated";
      const notification = notificationSystem.current;
      notification.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: <div>{msg}</div>,
        level: "success",
        position: "tr",
        autoDismiss: 2,
      });

      let listData = iconsList;
      if (listData[index].status == "active") {
        listData[index].status = "inactive";
      } else {
        listData[index].status = "active";
      }
      setIconsList([...listData]);
    }
  };

  const viewDocument = (row) => {
    setViewBoxData({
      show: true,
      type: row.imgExtension,
      title: "Document",
      path: row.image,
    });
  };

  const deleterRowAction = async (id) => {
    let resp = await API.iconsDelete({ id: id });
    if (resp) {
      let listData = iconsList.filter((item) => item._id !== id);
      props.handleClick("success", "Deleted successfully.");
      setIconsList([...listData]);
    }
  };

  const getConfirmFun = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleterRowAction(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const actionButton = (cell, row, enumObject, rowIndex) => {
    return (
      <>
        <span
          className="cursor-pointer-link"
          onClick={(e) => viewDocument(row)}
          title={row.title}
        >
          {" "}
          View{" "}
        </span>
        <Link to={{ pathname: `/admin/edit-icons/` + row._id, state: row }}>
          {" "}
          Edit{" "}
        </Link>
        <a
          href="javascript:void(0)"
          onClick={(e) => statusChangedHandler(row, rowIndex)}
        >
          <i
            className={row.status === "active" ? "fa fa-check" : "fa fa-remove"}
            aria-hidden="true"
          ></i>
        </a>
        <span
          className="cursor-pointer-link"
          variant="outline-light"
          onClick={(e) => getConfirmFun(row._id)}
        >
          {" "}
          delete
        </span>
      </>
    );
  };

  const onPageChange = (page, sizePerPage) => {
    if (searchText === "") {
      let data = formData;
      data["page"] = page - 1;
      data["limit"] = sizePerPage;
      setFormData({ ...formData, ...data });
      getNotificationListAction();
    }
    let scrollBar = 50;  
    if(scroll == page &&  sizePerPage < scrollBar ) {
      eval('$(".ps").scrollTop(0)');
    } else if( totalCount < page * sizePerPage){
      eval('$(".ps").scrollTop(0)');
    }
    setScroll(page)
  };

  const onSearchChange = async (e, field = "") => {
    let saveFormData = formData;
    saveFormData[field] = e.trimStart();
    saveFormData["page"] = 0;
    setFormData({ ...formData, ...saveFormData });
    getNotificationListAction(saveFormData);
  };

  function sortHandle(){ 
    let data = formData;
    data['direction'] =  sortData? "desc": "asc";
    setFormData({ ...formData, ...data });
    setSortData(!sortData )
    getNotificationListAction()
  }

  const onUserCount = (cell, row, enumObject, rowIndex) => {
    return parseInt(rowIndex) + parseInt(1);
  };

  const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
      return { backgroundColor: "#f3f1b0" };
    }
  };

  const editKnowledge = (cell, row, enumObject, rowIndex) => {
    return (
      <span>
        <a
          href="javascript:void(0);"
          onClick={() => {
            openVideoModel(row);
          }}
        >
          <i className="fa fa-eye" aria-hidden="true"></i>
        </a>
      </span>
    );
  };

  const openVideoModel = (row) => {
    let arr = row.image.split(".");
    let output = arr.pop();
    if (output == "mp4") {
      row.type = "video";
    } else if (output == "pdf") {
      row.type = "pdf";
    } else {
      row.type = "image";
    }

    this.setState({ modelViewData: row, videoModel: true });
  };

  const options = {
    //onSortChange: this.onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3, // the pagination bar size.
    prePage: "Prev", // Previous page button text
    nextPage: "Next", // Next page button text
    firstPage: "First", // First page button text
    lastPage: "Last", // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: "bottom", // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    onSearchChange: onSearchChange,
    noDataText: _setTableOption(),
  };

  return (
    <div className="main-content">
      {ViewBoxData && ViewBoxData.show && (
        <ViewBox data={ViewBoxData} hideViewBox={(e) => hideViewBox()} />
      )}
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div className="fresh-datatables ">
                  <Row>
                    <Col md={6}>
                      <Button
                        bsStyle="info"
                        className="inacti-button"
                        fill
                        pullRight
                        onClick={() => history.push("/admin/add-icons")}
                      >
                        Add Icons
                      </Button>
                    </Col>
                    <Col sm={6} className="form-group">
                      <FormControl
                        type="text"
                        autocomplete="off"
                        name="search"
                        id="search"
                        value={formData.search ? formData.search : ""}
                        onChange={(e) => {
                          onSearchChange(e.target.value.replace(/\s+/g, " "), "search");
                        }}
                        placeholder="Search here ..."
                      />
                    </Col>
                  </Row>
                  &nbsp;
                  <BootstrapTable
                    deleteRow={false}
                    key={iconsList}
                    data={iconsList}
                    search={false}
                    multiColumnSearch={true}
                    pagination={true}
                    options={options}
                    striped
                    hover
                    condensed
                    scrollTop={"Bottom"}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalCount }}
                    sort={true}
                    trStyle={rowStyleFormat}
                  >
                    <TableHeaderColumn
                      hidden={true}
                      tdAttr={{ "data-attr": "_id" }}
                      dataField="_id"
                      isKey
                      searchable={false}
                    >
                      Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "#" }}
                      thStyle={{ width: "5%", textAlign: "center" }}
                      tdStyle={{ width: "5%", textAlign: "center" }}
                      dataField="sn"
                      dataFormat={srNo}
                    >
                      S.No.
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "title" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="title"
                    >
                      Title
                      {
                          !sortData?
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "advertisement" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="description"
                    >
                      Description
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "advertisement" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="type"
                    >
                      Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "advertisement" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="tagOrder"
                    >
                      Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      tdAttr={{ "data-attr": "advertisement" }}
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      dataField="status"
                    >
                      Status
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{ width: "20%", textAlign: "center" }}
                      tdStyle={{ width: "20%", textAlign: "center" }}
                      tdAttr={{ "data-attr": "_id" }}
                      dataField=""
                      dataFormat={actionButton}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default IconsList;

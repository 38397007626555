/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useEffect, useState, useRef } from "react";
import CloseIc from "../../assets/img/icons/close-ic.svg";
import ClinicNotesApi from "../../api/clinicNotesApi";
import moment from "moment";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
const Notes = (props) => {
  const [clinicNotesList, setClinicNotesList] = useState([]);
  const [newNotes, setNewNotes] = useState("");
  const [stringLimit, setStringLimit] = useState(false);
  const notificationSystem = useRef(null);
  const onSubmit = async (e) => {
    let data = {
      notes: newNotes,
    };
    let response = await ClinicNotesApi.createClinicNotes(data);
    if (response.statusCode == 200) {
      data.createdAt = new Date();
      data.clinicName = localStorage.getItem("clinicName");
      let clinicNotes = clinicNotesList;
      clinicNotes.unshift(data);
      // clinicNotes.push(data);
      setClinicNotesList(clinicNotes);
      setNewNotes("");
      const notification = notificationSystem.current;
      notification.addNotification({
        title: (
          <span
            data-notify="icon"
            className="pe-7s-check"
            style={{
              fontSize: "30px",
              color: "#fff",
              padding: "0px !important",
              top: "0% !important",
            }}
          ></span>
        ),
        message: <div>{response.msg}</div>,
        level: "success",
        position: "tr",
        autoDismiss: 2,
      });
    }
  };

  const inputHandler = (e) => {
    if (e.target.value.length > 1000) setStringLimit(true);
    else {
      setStringLimit(false);
      let str = e.target.value.replace(/\s+/g, " ").substring(0, 1000);
      str = str.trimStart();
      setNewNotes(str.charAt(0).toUpperCase() + str.slice(1));
    }
  };

  useEffect(() => {
    (async () => {
      let response = await ClinicNotesApi.getClinicNotes();
      if (response.statusCode == 200) {
        setClinicNotesList(response.list);
      }
      await ClinicNotesApi.editNotesReadDate();
    })();
  }, []);
  return (
    <div className="side-modal side-modal-wrapper">
      <div className="note-modal">
        <div>
          <NotificationSystem ref={notificationSystem} style={style} />
        </div>
        <div className="side-modal-header">
          <h2>Note</h2>
          <div className="side-modal-header-action">
            <span className="close-btn">
              <span className="close-btn">
                <img
                  src={CloseIc}
                  alt="close"
                  onClick={() => props.modal(false)}
                />
              </span>
            </span>
          </div>
        </div>
        <div className="side-modal-content">
          <div className="add-note">
            <textarea
              rows={4}
              className="form-control"
              value={newNotes}
              placeholder="Type note here and tag doctor, patient, receptionist or educator..."
              onChange={(e) => inputHandler(e)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && newNotes != "") {
                  onSubmit();
                }
              }}
            ></textarea>

            <button
              disabled={newNotes == ""}
              className="btn btn-fill btn-secondary btn-add-note"
              onClick={(e) => {
                onSubmit(e);
              }}
            >
              Add
            </button>
          </div>
          {stringLimit && (
            <div style={{ color: "red" }}>
              <p>Only 1000 Characters are allowed.</p>
            </div>
          )}
          <div className="content-items-wrapper note-list">
            <h3>Notes List</h3>
            {clinicNotesList.length > 0 && (
              <>
                {clinicNotesList.map((data, index) => (
                  <div className="content-item" key={index}>
                    <div className="content-item-col">
                      <h4>{data.notes}</h4>
                      <p className="content-item-p schedule-time">
                        {moment(data.createdAt).format("DD-MM-YYYY HH:mm a")}
                      </p>
                      <p className="content-item-p dr-inf">{data.clinicName}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/


import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from '../../../../api/emr'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
import LabTestRangeList from "./LabTestRangeList";

const LabTestList = () => {
    let history = useHistory();
    const [formData, setFormData] = useState({ limit: 10, page: 0, direction: 'desc' });
    const [isLoading, setIsLoading] = useState(false);
    const [labTestList, setLabTestList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [labTestOrderValues, setLabTestOrderVAlues] = useState({});
    const notificationSystem = useRef(null);
    const [labTestRageListModal, setLabTestRangeListModal] = useState(false)
    const [sortData, setSortData] = useState(false);
    const [labTestData, setLabTestData] = useState({})


    const getLabTestList = async (from = {}) => {
        setIsLoading(true);
        let resp = await API.labTestList({ "limit": formData.limit, "offset": formData.page, "order": "name", "direction": formData.direction, "search": from.search });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            setIsLoading(false);
            setLabTestList(resp.data.data);
            setTotalCount(resp.data.count);
            if (resp.data.data && resp.data.data.length > 0) {
                let obj = {}
                for (let i in resp.data.data) {
                    obj["txt-" + resp.data.data[i]._id] = resp.data.data[i].order
                }
                setLabTestOrderVAlues({ ...obj })
            }
        }

    }

    const handleToastifyMessage = (data) => {
        let msg = ""
        if (data === "add")
            msg = "Test Added Successfully"
        if (data === "edit")
            msg = "Test Edit Successfully"
        if (data === "delete")
            msg = "Test Deleted Successfully"
        if (data === "status")
            msg = "Test Status Changed Successfully"
        if (data === "order")
            msg = "Test Order Changed Successfully"
        const notification = notificationSystem.current;
        notification.addNotification({
            title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
            message: <div>
                {msg}
            </div>,
            level: 'success',
            position: 'tr',
            autoDismiss: 2,
        });
    }


    const srNo = (cell, row, enumObject, rowIndex) => {
        return (rowIndex + 1 + (formData.limit * formData.page))
    }

    const _setTableOption = () => {
        if (isLoading) {
            return (
                <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
            );
        } else {
            return "No data found!";
        }
    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
        let rangeSaved = false
        return (<span>
            {<Link Title="Range List" onClick={(e) => { setLabTestData(row); setLabTestRangeListModal(true) }}><i className={rangeSaved ? "fa fa-pencil" : "fa fa-plus"} aria-hidden="true"></i></Link>
            }
            &nbsp;&nbsp; &nbsp;&nbsp;
            {/* <Link to={{ pathname: `/admin/edit-lab-test/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link> */}
            {!row.autoCalculate ?
                <><Link title="Edit Lab Test" to={{ pathname: `/admin/edit-lab-test/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                </>
                : ""
            }
            <i className={(row.status === "active") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row, rowIndex)} aria-hidden="true"></i>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i>
        </span>)
    }

    const statusChangedHandler = async (row, index) => {
        let resp = await API.statusChangeLabTest({ "id": row._id, "status": row.status === "inactive" ? "active" : "inactive" });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            handleToastifyMessage('status')
            let list = labTestList;
            let count = totalCount + 1;
            if (row.status === "active") {
                list[index].status = "inactive"
            } else {
                list[index].status = "active"
            }
            setLabTestList(list);
            setTotalCount(count);
        }
    }

    const getConfirmFun = (id) => {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteLabTest(id)
                },
                {
                    label: 'No',
                }
            ]
        })
    }


    const deleteLabTest = async (id) => {
        let resp = await API.deleteLabTest({ "id": id });
        if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
            handleToastifyMessage('delete')
            setLabTestList(labTestList.filter(item => item._id !== id));
        }
    };

    const onPageChange = (page, sizePerPage) => {
        let scrollBar = 50;
        if (sizePerPage < scrollBar) {
            eval('$(".ps").scrollTop(0)');
        }
        let data = formData;
        data['page'] = page - 1;
        data['limit'] = sizePerPage;
        setFormData({ ...formData, ...data });
        getLabTestList(data);
    }

    const onSearchChange = (e, field = "") => {

        let saveFormData = formData;
        saveFormData[field] = e.trimStart();
        saveFormData["page"] = 0;
        setFormData({ ...formData, ...saveFormData });
        getLabTestList(saveFormData);
    }

    function sortHandle() {
        let data = formData;
        data['direction'] = sortData ? "desc" : "asc";
        setFormData({ ...formData, ...data });
        setSortData(!sortData)
        getLabTestList(data);
    }

    const rowStyleFormat = (row, rowIdx) => {
        if (row && row.visited) {
            return { backgroundColor: '#f3f1b0' };
        }
    }


    const options = {
        //onSortChange: this.onSortChange,
        clearSearch: true,
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 3,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        paginationPosition: 'bottom',  // default is bottom, top and both is all available
        sizePerPage: formData.limit,
        onPageChange: onPageChange,
        page: formData.page + 1,
        noDataText: _setTableOption()

    };

    useEffect(() => {
        if (history?.location?.from === "add" || history?.location?.from === "edit")
            handleToastifyMessage(history?.location?.from)
        getLabTestList()
    }, [])

    const getSequence = (cell, row, enumObject, rowIndex) => {
        return (<p><input type="number" value={labTestOrderValues["txt-" + row._id]} onChange={(e) => handleOrder(e, row)} />
            &nbsp;&nbsp; &nbsp;&nbsp;

        </p>)

    }

    const handleOrder = async (event, row) => {
        let data = {}
        data.id = row._id
        data.order = event.target.value
        let tempLabTestOrderValues = labTestOrderValues
        tempLabTestOrderValues["txt-" + row._id] = parseInt(event.target.value)
        setLabTestOrderVAlues({ ...tempLabTestOrderValues })
        if (event.target.value != "") {
            let resp = await API.changeLabTestOrder({ id: row._id, order: parseInt(event.target.value) })

        }
    }

    const handleUnit = (cell, row, enumObject, rowIndex) => {
        return row.unit != undefined ? row.unit : ""

    }

    const handleRange = (cell, row, enumObject, rowIndex) => {
        return row.range != undefined ? row.range : ""
    }

    return (
        <div className="main-content">
            <div>
                <NotificationSystem ref={notificationSystem} style={style} />
            </div>
            <Grid fluid>

                <Row>
                    <Col md={12}>
                        <Card
                            content={
                                <div className="fresh-datatables">
                                    <Row>
                                        <Col md={6}>
                                            <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-lab-test")}>Add Lab Test</Button>
                                        </Col>
                                        <Col sm={6} className="form-group">
                                            <FormControl
                                                type="text"
                                                autocomplete="off"
                                                name="search"
                                                id="search"
                                                value={formData.search ? formData.search : ""}
                                                onChange={(e) => {
                                                    onSearchChange(e.target.value.replace(/\s+/g, " "), "search");
                                                }}
                                                placeholder="Search here ..."
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className='table-responsive'>
                                            <BootstrapTable
                                                deleteRow={false}
                                                key={labTestList}
                                                data={labTestList}
                                                search={false}
                                                multiColumnSearch={true}
                                                pagination={true}
                                                options={options}
                                                striped
                                                hover
                                                condensed
                                                scrollTop={"Bottom"}
                                                remote={true}
                                                fetchInfo={{ dataTotalSize: totalCount }}
                                                sort={true}
                                                trStyle={rowStyleFormat}
                                            >
                                                <TableHeaderColumn
                                                    hidden={true}
                                                    tdAttr={{ "data-attr": "_id" }}
                                                    dataField="_id"
                                                    dataSort={true}
                                                    isKey
                                                    searchable={false}
                                                >
                                                    Id
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "#" }}
                                                    thStyle={{ width: "5%", textAlign: "center" }}
                                                    tdStyle={{ width: "5%", textAlign: "center" }}
                                                    dataField="sn"
                                                    dataFormat={srNo}
                                                >
                                                    S.No.
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "name" }}
                                                    thStyle={{ width: "25%", textAlign: "center" }}
                                                    tdStyle={{ width: "25%", textAlign: "center" }}
                                                    dataField="name"
                                                >
                                                    Name
                                                    {
                                                        !sortData ?
                                                            <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-up" aria-hidden="true"></i> :
                                                            <i onClick={(e) => { sortHandle() }} style={{ cursor: 'pointer' }} class="fa fa-caret-down" aria-hidden="true"></i>
                                                    }
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "shortName" }}
                                                    thStyle={{ width: "10%", textAlign: "center" }}
                                                    tdStyle={{ width: "10%", textAlign: "center" }}
                                                    dataField="shortName"
                                                >
                                                    Short Name
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "title" }}
                                                    thStyle={{ width: "10%", textAlign: "center" }}
                                                    tdStyle={{ width: "10%", textAlign: "center" }}
                                                    dataField=""
                                                    dataFormat={handleUnit}
                                                >
                                                    Unit
                                                </TableHeaderColumn>

                                                {/* <TableHeaderColumn
                                                tdAttr={{ "data-attr": "title" }}
                                                thStyle={{ width: "15%", textAlign: "center" }}
                                                tdStyle={{ width: "15%", textAlign: "center" }}
                                                dataField=""
                                                dataFormat={handleRange}
                                            >
                                                Range
                                            </TableHeaderColumn> */}

                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "title" }}
                                                    thStyle={{ width: "5%", textAlign: "center" }}
                                                    tdStyle={{ width: "5%", textAlign: "center" }}
                                                    dataField="order"
                                                // dataFormat={getSequence}
                                                >
                                                    Order
                                                </TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "validationRangeFrom" }}
                                                    thStyle={{ width: "15%", textAlign: "center" }}
                                                    tdStyle={{ width: "15%", textAlign: "center" }}
                                                    dataField="validationRangeFrom"
                                                // dataFormat={getSequence}
                                                >
                                                    Validation Range From
                                                </TableHeaderColumn>

                                                <TableHeaderColumn
                                                    tdAttr={{ "data-attr": "title" }}
                                                    thStyle={{ width: "15%", textAlign: "center" }}
                                                    tdStyle={{ width: "15%", textAlign: "center" }}
                                                    dataField="validationRangeTo"
                                                // dataFormat={getSequence}
                                                >
                                                    Validation Range To
                                                </TableHeaderColumn>

                                                <TableHeaderColumn
                                                    thStyle={{ width: "15%", textAlign: "center" }}
                                                    tdStyle={{ width: "15%" }}
                                                    tdAttr={{ "data-attr": "_id" }}
                                                    dataField=""
                                                    dataFormat={actionButton}
                                                >
                                                    Action
                                                </TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </Row>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
            <Modal
                show={labTestRageListModal}
                onHide={() => setLabTestRangeListModal(false)}
                dialogClassName="modal-xl"
                className="Modal_downsearch"
            >
                <Modal.Header
                    closeButton
                    className="bg-thumbcolor"
                >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        {`${labTestData.name} Range List`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabTestRangeList
                        labTestData={labTestData}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LabTestList
/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { Component } from 'react';


export class StatsCard extends Component {
    render() {
        return (
            <div className="card card-stats">
                <div className="content">
                    <div className="row">
                        <div className="col-xs-5">
                            <div className="icon-big text-center icon-warning">
                                {this.props.bigIcon}
                            </div>
                        </div>
                        <div className="col-xs-7">
                            <div className="numbers">
                                <p>{this.props.statsText}</p>
                                {this.props.statsValue}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <hr />
                    <div className="stats">
                        {this.props.statsIcon}{" "}{this.props.statsIconText}
                    </div>
                </div>
            </div>
        );
    }
}

export default StatsCard;

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/
import { postRequestWithTokenFile, postRequestWithToken } from "./helper";

export const addClinicDocumentUpload = (data) =>
  postRequestWithTokenFile(data, "admin/add-clinic-links");
export const updateClinicDocumentUpload = (data) =>
  postRequestWithTokenFile(data, "admin/update-clinic-links");
export const changeClinicDocumentUploadStatus = (data) =>
  postRequestWithToken(data, "admin/change-status-clinic-links");
export const clinicDocumentUploadList = (data) =>
  postRequestWithToken(data, "admin/clinic-link-list");
export const specializationList = (data) =>
  postRequestWithToken(data, "admin/specialization-list");
export const clinicList = (data) =>
  postRequestWithToken(data, "admin/clinic-list-simple");

/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

import React, { useState, useEffect, useRef } from "react";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { Row, Col, FormControl, Grid, Modal } from "react-bootstrap";
import Card from "Front/components/Card/Card.jsx";
import Button from "Admin/elements/CustomButton/CustomButton.jsx";
import * as API from '../../../../api/emr'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-loading";
import NotificationSystem from "react-notification-system";
import { style } from "Admin/variables/Variables.jsx";
import { confirmAlert } from 'react-confirm-alert';
const DrugCompanyList = (props) => {
  let history = useHistory();
  let { mode } = useParams();
  const [formData, setFormData] = useState({ limit: 10, page: 0, direction: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [drugCompanyList, setDrugCompanyList] = useState([]);
  const [sortData, setSortData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [scroll, setScroll] = useState(0);
  const notificationSystem = useRef(null);

  const getDrugCompanyList = async (from = {}) => {
    setIsLoading(true);
    let resp = await API.drugCompanyList({ "limit": formData.limit, "offset": formData.page, "direction": formData.direction, "order": "createdAt", "search": from.search });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      setIsLoading(false);
      setDrugCompanyList(resp.data.data);
      setTotalCount(resp.data.count);
    }

  }

  const handleToastifyMessage = (data) => {
    let msg = ""
    if (data === "add")
      msg = "Drug Company Added Successfully"
    if (data === "edit")
      msg = "Drug Company Edit Successfully"
    if (data === "delete")
      msg = "Drug Company deleted Successfully"
    if (data === "status")
      msg = "Drug Company status changed Successfully"
    const notification = notificationSystem.current;
    notification.addNotification({
      title: (<span data-notify="icon" className="pe-7s-check" style={{ fontSize: '30px', color: '#fff', padding: '0px !important', top: '0% !important' }}></span>),
      message: <div>
        {msg}
      </div>,
      level: 'success',
      position: 'tr',
      autoDismiss: 2,
    });
  }


  const srNo = (cell, row, enumObject, rowIndex) => {
    return (rowIndex + 1 + (formData.limit * formData.page))
  }

  const _setTableOption = () => {
    if (isLoading) {
      return (
        <Loading type='bars' color='#000000' style={{ margin: '0px auto', width: "40px" }} />
      );
    } else {
      return "No data found!";
    }
  }

  const actionButton = (cell, row, enumObject, rowIndex) => {
    // return (<><Link to={{ pathname: `/admin/edit-drug-company/`+ row._id, state: {row} }}>  Edit </Link></>)
    return (<span><Link to={{ pathname: `/admin/edit-drug-company/` + row._id, state: { row } }} ><i className="fa fa-pencil" aria-hidden="true"></i></Link>
      &nbsp;&nbsp; &nbsp;&nbsp;
      {/* <a href="javascript:void(0);" onClick={e => statusChangedHandler(row,rowIndex)}> */}
      <i className={(row.enabled === "1") ? ('fa fa-check') : ('fa fa-remove')} onClick={e => statusChangedHandler(row, rowIndex)} aria-hidden="true"></i>
      {/* </a> */}
      {/* <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i> */}
      &nbsp;&nbsp; &nbsp;&nbsp;
      <i class="fa fa-trash" aria-hidden="true" onClick={e => getConfirmFun(row._id)}></i>
    </span>)
  }

  const statusChangedHandler = async (row, index) => {
    let data = {
      "id": row._id,
      "status": row.enabled === "1" ? "0" : "1"
    }
    let resp = await API.statusChangeDrugCompany(data);
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      handleToastifyMessage('status')
      let drugList = drugCompanyList;
      let count = totalCount + 1;
      if (row.enabled === "1") {
        drugList[index].enabled = "0"
      } else {
        drugList[index].enabled = "1"
      }
      setDrugCompanyList(drugList);
      setTotalCount(count);
    }
  }

  const getConfirmFun = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteCompany(id)
        },
        {
          label: 'No',
        }
      ]
    })
  }


  const deleteCompany = async (id) => {
    let resp = await API.deleteDrugCompany({ "id": id });
    if (resp?.data?.status === "Success" && resp?.data?.statusCode) {
      handleToastifyMessage('delete')
      setDrugCompanyList(drugCompanyList.filter(item => item._id !== id));
    }
  };

  const onPageChange = (page, sizePerPage) => {
    let data = formData;
    data['page'] = page - 1;
    data['limit'] = sizePerPage;
    setFormData({ ...formData, ...data });
    getDrugCompanyList(data);

    let scrollBar = 50;  
    if(scroll == page &&  sizePerPage < scrollBar ) {
      eval('$(".ps").scrollTop(0)');
    } else if( totalCount < page * sizePerPage){
      eval('$(".ps").scrollTop(0)');
    }
    setScroll(page)
  }

  const onSearchChange = (e, field = "") => {
      let saveFormData = formData;
      saveFormData[field] = e.trimStart();
      saveFormData["page"] = 0;
      setFormData({ ...formData, ...saveFormData });
      getDrugCompanyList(saveFormData);
  }

  function sortHandle(e){ 
    let data = formData;
    data['direction'] =  sortData? "desc": "asc";
    setFormData({ ...formData, ...data });
    setSortData(!sortData )
    getDrugCompanyList(data);
  }

  const rowStyleFormat = (row, rowIdx) => {
    if (row && row.visited) {
      return { backgroundColor: '#f3f1b0' };
    }
  }


  const options = {
    //onSortChange: this.onSortChange,
    clearSearch: true,
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
    firstPage: 'First', // First page button text
    lastPage: 'Last', // Last page button text
    //paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    sizePerPage: formData.limit,
    onPageChange: onPageChange,
    page: formData.page + 1,
    noDataText: _setTableOption()

  };

  useEffect(() => {
    if (history?.location?.from === "add" || history?.location?.from === "edit")
      handleToastifyMessage(history?.location?.from)
    getDrugCompanyList()
  }, [])

  return (
    <div className="main-content">
      <div>
        <NotificationSystem ref={notificationSystem} style={style} />
      </div>
      <Grid fluid>

        <Row>
          <Col md={12}>
            <Card
              content={
                <div className="fresh-datatables">
                  <Row>
                    <Col md={6}>
                      <Button bsStyle="info" className="inacti-button" fill pullRight onClick={() => history.push("/admin/add-drug-company")}>Add Drug Company</Button>
                    </Col>
                    <Col sm={6} className="form-group">
                      <FormControl
                        type="text"
                        autocomplete="off"
                        name="search"
                        id="search"
                        value={formData.search ? formData.search : ""}
                        onChange={(e) => {
                          onSearchChange(e.target.value.replace(/\s+/g, " "), "search");
                        }}
                        placeholder="Search here ..."
                      />
                    </Col>
                  </Row>
                  <div className='table-responsive'>
                    <BootstrapTable
                      deleteRow={false}
                      key={drugCompanyList}
                      data={drugCompanyList}
                      search={false}
                      multiColumnSearch={true}
                      pagination={true}
                      options={options}
                      striped
                      hover
                      condensed
                      scrollTop={"Bottom"}
                      remote={true}
                      fetchInfo={{ dataTotalSize: totalCount }}
                      trStyle={rowStyleFormat}
                    >
                      <TableHeaderColumn
                        hidden={true}
                        tdAttr={{ "data-attr": "_id" }}
                        dataField="_id"
                        isKey
                        searchable={false}
                      >
                        Id
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "#" }}
                        thStyle={{ width: "5%", textAlign: "center" }}
                        tdStyle={{ width: "5%", textAlign: "center" }}
                        dataField="sn"
                        dataFormat={srNo}
                      >
                        S.No.
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        tdAttr={{ "data-attr": "title" }}
                        thStyle={{ width: "20%", textAlign: "center" }}
                        tdStyle={{ width: "20%", textAlign: "center" }}
                        dataField="companyName"
                      >
                        Name
                        {
                          !sortData?
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-up" aria-hidden="true"></i>:
                          <i onClick={(e) => {sortHandle()}} style={{cursor : 'pointer'}} class="fa fa-caret-down" aria-hidden="true"></i>
                        }
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ width: "6%", textAlign: "center" }}
                        tdStyle={{ width: "6%", textAlign: "center" }}
                        tdAttr={{ 'data-attr': 'Action' }}
                        // tdAttr={{ "data-attr": "_id" }}
                        // dataField=""
                        dataFormat={actionButton}
                      >
                        Action
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default DrugCompanyList